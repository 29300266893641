'use strict';

import './payment.link.modal.less';
import {
  cleanObj,
  getSafely,
  isConcreteValue,
  isNonEmptyObject,
  isNonEmptyString, isNumber
} from "@tomeravni/easybizy-js-common/common";
import { extractError } from "@tomeravni/easybizy-js-common/errors";
import Moment from "moment";

angular.module('easybizy.easybizyModalViewsModel').controller('PaymentLinkController',
  function (whatsapp, $scope, $modalInstance, sessionId, customer, configuration, pci, localize, Repository, onCreateDelegate) {
    $scope.cancel = function () {
      $modalInstance.dismiss();
    };

    $scope.adaptedInvoiceResult = {
      items: [],
      Customer: {
        MobileFirst: customer.mobile,
      }
    }

    const configurations = configuration.get();

    $scope.includeAddress = configurations.InvoiceSettings.IncludeCustomerAddressOnInvoice;
    $scope.includeIdentifyNumber = configurations.InvoiceSettings.IncludeCustomerIdNumberOnInvoice;
    const paymentLinkSettings = configurations.PaymentLinkSettings || {};
    let notifyBusinessConfigurations = getSafely(['MobileNumbersToNotifyPaymentLinkPaid', 'Value'], paymentLinkSettings);
    let expirationInDays = getSafely(['DefaultExpirationOfPaymentLink', 'Value'], paymentLinkSettings);
    let snoozeMessageFromConfig = getSafely(['ReminderMessageContentAboutPaymentLink', 'Value'], paymentLinkSettings);
    let snoozingDaysIntervalFromConfig = getSafely(['RemindCustomersAboutPaymentLink', 'Value'], paymentLinkSettings);
    let supportsGiftCard = paymentLinkSettings.SupportsGiftCardOnPaymentLink;
    let defaultMaxAmountOfPayments = getSafely(['CashRegisterSettings', 'MaximumNumberOfPayments', 'Value'], configurations);
    const whatsappConfig = getSafely(['loggedInUser', 'whatsappProps'], configuration, {});
    $scope.sendPaymentLinkUsingWhatsapp = configuration.loggedInUser?.isWhatsappSupported && whatsappConfig?.sendPaymentLinkViaWhatsapp;
    $scope.sendMainButton = localize.getLocalizedString(!$scope.sendPaymentLinkUsingWhatsapp ? '_SendBySms_' : '_SendByWhatsapp_');

    let notifyOwnerTo = {
      to: notifyBusinessConfigurations ? notifyBusinessConfigurations.split(',').map((x) => x.trim()) : [],
      method: 'sms'
    }

    if (defaultMaxAmountOfPayments > 1) {
      $scope.installmentValues = Array.from({ length: defaultMaxAmountOfPayments - 1 }, (v, k) => ({
        name: localize.getLocalizedString('_NumOfPayments_', k + 1),
        value: k + 1
      }));

      $scope.installmentValues[0].name = localize.getLocalizedString('_NoPayments_');
      $scope.maxNumberOfInstallments = { selected: $scope.installmentValues[0] }
    }


    const snoozeMessage = isNonEmptyString(snoozeMessageFromConfig) ? snoozeMessageFromConfig : localize.getLocalizedString('_SnoozePaymentLinkMessage_')

    /**
     * TODO set default from config.
     * TODO Add expiration.
     *
     */
    $scope.paymentLink = {
      title: localize.getLocalizedString('_PaymentRequest_'),
      invoiceRemarks: configurations.CashRegisterSettings.InvoiceRemarksTemplate?.Value,
      customerName: customer.customerName,
      mobile: customer.mobile,
      address: customer.address,
      supportsGiftCard: supportsGiftCard,
      identifyNumber: customer.identifyNumber
    };

    const notifyCustomerOfPaymentMessage = configurations.CashRegisterSettings.PaymentLinkMessage || localize.getLocalizedString('_DefaultPaymentLinkCustomerMessageText_');

    const fieldsNameMap = {
      title: localize.getLocalizedString('_PriceQuoteTitle_'),
      customerName: localize.getLocalizedString('_CustomerName_'),
      mobile: localize.getLocalizedString('_MobileFirst_'),
    };

    $scope.recurrenceSnoozeValues = [
      {
        name: localize.getLocalizedString('_None_'),
        value: null,
        interval: 0,
      },
      {
        name: localize.getLocalizedString('_Daily_'),
        value: 'FREQ=DAILY',
        interval: 1
      },
      {
        name: localize.getLocalizedString('_Every3Days_'),
        value: 'FREQ=DAILY;INTERVAL=3',
        interval: 3
      },
      {
        name: localize.getLocalizedString('_ByWeekly_'),
        value: 'FREQ=WEEKLY',
        interval: 7
      },
      {
        name: localize.getLocalizedString('_Weekly_'),
        value: 'FREQ=WEEKLY;INTERVAL=2',
        interval: 14
      },
      {
        name: localize.getLocalizedString('_Monthly_'),
        value: 'FREQ=MONTHLY',
        interval: 30
      }
    ];

    $scope.snoozeWrapper = {
      selected: $scope.recurrenceSnoozeValues.filter((x) => {
        return x.interval?.toString() === snoozingDaysIntervalFromConfig
      })[0]
    };

    $scope.savingWrapper = {
      saving: false
    };

    /*EmployeeId*/

    $scope.sendPaymentLink = (isWhatsapp = false) => {
      const someInvalid = Object.keys($scope.paymentLink).some((field) => {
        if (!isConcreteValue($scope.paymentLink[field]) && fieldsNameMap[field]) {
          toastr.error(`${ fieldsNameMap[field] } - ` + localize.getLocalizedString('_ThisFieldIsRequired_'))
          return true;
        }

        return false;
      })

      if (someInvalid) {
        return;
      }

      $scope.savingWrapper.saving = true;
      let snooze = getSafely(['snoozeWrapper', 'selected', 'value'], $scope);
      if (isConcreteValue(snooze)) {
        snooze = {
          to: $scope.paymentLink.mobile.split(',').map((phone) => phone.trim()),
          message: {
            he_IL: snoozeMessage,
            en_US: snoozeMessage
          },
          recurrence: snooze,
          method: 'sms'
        }
      }

      const phoneNumbers = $scope.paymentLink.mobile.split(',').map((phone) => phone.trim());
      let invoiceDetails = cleanObj({
        address: $scope.paymentLink.address,
        identifyNumber: $scope.paymentLink.identifyNumber,
      });


      const toSend = {
        snooze,
        paymentType: 'payment',
        supportsGiftCard: !!$scope.paymentLink.supportsGiftCard,
        notifyCustomer: isWhatsapp ? null : {
          message: {
            he_IL: notifyCustomerOfPaymentMessage,
            en_US: notifyCustomerOfPaymentMessage
          },
          to: phoneNumbers,
          method: 'sms'
        },
        customerName: $scope.paymentLink.customerName,
        sendInvoiceTo: {
          to: phoneNumbers,
          method: 'sms'
        },
        notifyOwnerTo,
        descriptionText: {
          he_IL: $scope.paymentLink.title,
          en_US: $scope.paymentLink.title
        },
        employeeId: configuration.defaultEmployee().id,
        invoiceDetails: isNonEmptyObject(invoiceDetails) ? invoiceDetails : invoiceDetails
      };

      if (isNumber(parseInt(expirationInDays))) {
        /// The extra 10 minutes is important, since otherwise the recurrence is too soon.
        toSend.expiresOn = Moment().add(expirationInDays, 'days').add(10, 'minutes').toDate();
      }

      if (isNonEmptyString($scope.paymentLink.invoiceRemarks)) {
        toSend.invoiceRemarks = {
          he_IL: $scope.paymentLink.invoiceRemarks,
          en_US: $scope.paymentLink.invoiceRemarks
        };
      }

      let maxNumOfInstallments = getSafely(['maxNumberOfInstallments', 'selected', 'value'], $scope)
      if (maxNumOfInstallments > 1) {
        toSend.maxNumOfInstallments = maxNumOfInstallments;
      }

      Repository.Custom('Sessions').createOrUpdatePaymentLink(sessionId, cleanObj(toSend))
        .then((res) => {
          onCreateDelegate(res);
          toastr.success(localize.getLocalizedString('_MessageSent_'));
          if (isWhatsapp) {
            whatsapp.open(phoneNumbers[0], `${ notifyCustomerOfPaymentMessage } ${ res.paymentLink.shortUrl }`);
          }

          $scope.cancel();
        })
        .catch((err) => {
          $scope.savingWrapper.saving = false;
          toastr.error(extractError(err.data));
        })
    }


  });
