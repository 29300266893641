import {
  DATE_DISPLAY_FORMAT,
  ODATA_DATE_ONLY_FORMAT,
  ODATA_DATE_TIME_FORMAT,
  PATH_TO_API,
  SERVER_DATA_DATE_FORMAT
} from "../../constants";
import './expenses.less';

(function () {
  angular.module('easybizy.expenses')
    .controller('ExpensesController', ExpensesController);

  function ExpensesController($scope, toolbar, confirmService, Repository, localize, $modal, $q, iCalService, configuration) {
    $scope.clearSearchFuncWrapper = {};
    $scope.totalForPeriod = 0;
    var k_nonRepeatingString = localize.getLocalizedString("_NotRepeating_");

    var toolbarActions = [
      {
        name: 'add',
        action: createNewExpenseOrUpdate,
        data: {
          template: require('../../modal-views/add-expense/add.expense.modal.tpl.html'),
          controller: 'AddExpenseModalController'
        }
      },
      {
        name: 'search', action: function (newValue) {
          $scope.valueWrapper.value = newValue;
        },
        data: {
          delay: 500,
          clearWrapper: $scope.clearSearchFuncWrapper,
          setSearchWrapper: $scope.clearSearchFuncWrapper
        }
      },
      {
        name: 'custom',
        icon: 'icon icon-excel',
        action: function () {
          // const api = $scope.api;
          // const currentQuery = api.getQuery().replace(api.getBaseQuery(), '');
          Repository.Custom('Expenses').export($scope.dateRangeFilter);
        },
        disabled: false,
        tooltip: localize.getLocalizedString('_Export_')
      }
    ];

    toolbar.set(toolbarActions);
    var configurations = configuration.get();
    var vat = configurations.CashRegisterSettings.VAT.Value;
    $scope.filtersModel = {};
    $scope.valueWrapper = {};
    $scope.filtersModel.filters = [
      { type: "expenseCategory", allowMultiple: false },
      {
        type: "search",
        params: { fields: "ExpenseTitle", type: "Expense" },
        valueWrapper: $scope.valueWrapper,
        clearWrapper: $scope.clearSearchFuncWrapper
      },
      {
        type: 'dateRange',
        params: { defaultRange: { from: moment().startOf('month'), to: moment() } }
      }
    ];

    $scope.trackByFunc = function (entity) {
      return entity.ExpenseId + entity.LastUpdated + entity.EffectivePaymentDate;
    };

    //todo: this is not suppose to be here.. temporary fix.. should be part of the filter logic
    // var dateRangeFilter = 'start=' + moment($scope.filtersModel.filters[2].params.defaultRange.from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) + '&end=' + moment($scope.filtersModel.filters[2].params.defaultRange.to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
    $scope.dateRangeFilter = ''
    $scope.filtersModel.filterStateChanged = function (newFiltersState) {
      if (!$scope.api) {
        $scope.api = initApi();
      }

      $scope.api.filters.length = 0;
      var forceUpdate = null;
      angular.forEach(newFiltersState, function (filterValue, filterKey) {
        if (filterValue) {
          if (filterKey === 'dateRange') {
            $scope.api.queryParams('', filterValue)
            $scope.dateRangeFilter = filterValue;
            // false meaning the filter has been changed meaning no need to force update.
            if (forceUpdate !== false) {
              forceUpdate = true;
            }
          } else {
            forceUpdate = true;
            $scope.api.filters.push(filterValue);
          }
        }
      });

      if ($scope.api.forceUpdate && forceUpdate) {
        $scope.api.forceUpdate();
      }

    };

    $scope.expenses = [];

    $scope.totalSumOfSelectedExpenses = 0;
    $scope.$watch('expenses', function (expenses) {
      $scope.totalSumOfSelectedExpenses = 0;
      expenses.forEach(function (expense) {
        if (expense.isSelected) {
          $scope.totalSumOfSelectedExpenses += parseFloat(expense.Amount);
        }
      })
    }, true);

    $scope.fields = [
      {
        name: localize.getLocalizedString("_None_"),
        type: 'Checkbox',
        allowSelectAll: true,
        selectOnDirty: true,
        sortable: false,
        fieldName: 'isSelected'
      },
      {
        name: localize.getLocalizedString("_ExpenseTitle_"),
        sortable: true,
        fieldName: 'ExpenseTitle',
        command: {
          sort: function () {
            return 'ExpenseTitle';
          }
        }
      },
      {
        name: localize.getLocalizedString('_Amount_'),
        type: 'Money',
        fieldName: 'Amount',
        sortable: true,
        command: {
          sort: function () {
            return 'Amount';
          }
        }
      },
      {
        name: localize.getLocalizedString('_Payments_'),
        fieldName: 'NumberOfPayments',
        sortable: true,
        command: {
          get: function (entity) {
            return entity.NumberOfPayments || 1;
          }
        }
      },
      {
        name: localize.getLocalizedString("_ExpenseType_"),
        sortable: false,
        fieldName: 'ExpenseType',
        command: {
          get: function (entity) {
            return entity.Recurrence ? iCalService.rRuleToString(entity.Recurrence) : k_nonRepeatingString;
          }
        }
      },
      {
        name: localize.getLocalizedString("_Category_"),
        sortable: false,
        fieldName: 'Category',
        command: {
          get: function (entity) {
            return entity.ExpensesCategory.ExpensesCategoryName;
          }
        }
      },
      {
        name: localize.getLocalizedString("_EffectivePaymentDate_"),
        sortable: true, orderedBy: true, isDecending: true,
        fieldName: 'EffectivePaymentDate',
        command: {
          sort: function () {
            return 'EffectivePaymentDate';
          },
          get: function (entity) {
            return moment(entity.EffectivePaymentDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT);
          }
        }
      },
      {
        name: localize.getLocalizedString("_Importance_"),
        sortable: true,
        fieldName: 'Importance',
        type: 'Rank',
        command: {
          sort: function () {
            return "Rank";
          },
          readonly: true,
        }
      },
      {
        name: localize.getLocalizedString("_Actions_"),
        type: "Custom",
        template: '<div class="custom-actions">\
                      <div ng-click="value.duplicate()" class="icon icon-duplicate"></div> \
                      <div ng-click="value.remove()" class="icon icon-remove"></div> \
                      <div ng-click="value.edit()" class="icon icon-edit"></div> \
                   </div>',
        command: {
          get: function (entity) {
            return {
              duplicate: function () {
                $scope.$emit('fire-toolbar-command', {
                  name: "add", params: {
                    expense: entity,
                    duplicate: true
                  }
                });
              },
              remove: function () {
                confirmService.confirm(localize.getLocalizedString('_ApproveDeleteExpense_'),
                  null,
                  function () {
                    return deleteExpense(entity);
                  },
                  function () {

                  }
                );
              },
              edit: function () {
                $scope.$emit('fire-toolbar-command', {
                  name: "add", params: {
                    expense: entity
                  }
                });
              }
            };
          }
        }
      }

    ];

    var widths = ['4%', '12%', '8%', '6%', '10%', '14%', '12%', '14%', '12%'];
    $scope.fields.forEach(function (field, idx) {
      field.width = widths[idx];
    });

    var totalRepository = Repository.Custom('Expenses');

    function getTotalForPeriod() {
      totalRepository.getTotal($scope.dateRangeFilter).then(function (result) {

        $scope.$applyIfNeeded(function () {
          $scope.totalForPeriod = result.Total;
          $scope.vatForPeriod = result.Vat;
        });
      });
    }

    function deleteExpense(expense) {
      return Repository.Entity("Expense").remove(expense.ExpenseId).then(function () {
        getTotalForPeriod();
        $scope.$applyIfNeeded(function () {
          $scope.expenses.splice($scope.expenses.indexOf(expense), 1);
        });
      }).catch(function (err) {
        toastr.error(localize.getLocalizedString('_Error'));
      });
    }


    function createNewExpenseOrUpdate(expense, existingExpense) {
      var defered = $q.defer();
      expense.EffectivePaymentDate = expense.EffectivePaymentDate ? moment(expense.EffectivePaymentDate, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) : null;
      if (expense.EndsOn) {
        if (expense.Recurrence.charAt(expense.Recurrence.length - 1) !== ';') {
          expense.Recurrence += ';';
        }

        expense.Recurrence += 'UNTIL=' + moment(expense.EndsOn, DATE_DISPLAY_FORMAT).format('YYYYMMDD');
        delete expense.EndsOn;
      }
      if (!existingExpense) {
        delete expense.CreatedOn;
        expense.Vat = vat;
      } else {
        expense.CreatedOn = moment(expense.CreatedOn).format(ODATA_DATE_TIME_FORMAT);
      }
      expense.Amount = expense.Amount.toString();
      expense.Vat = expense.Vat ? expense.Vat.toString() : null;
      expense.VatPortion = expense.VatPortion ? expense.VatPortion.toString() : null;

      Repository.Entity("Expense")[existingExpense ? 'update' : 'create'](expense).expand('ExpensesCategory').post()
        .then(function (expense) {
          expense.EffectivePaymentDate = moment(expense.EffectivePaymentDate, ODATA_DATE_ONLY_FORMAT).format(SERVER_DATA_DATE_FORMAT);
          getTotalForPeriod();
          if (existingExpense) {
            $scope.expenses[$scope.expenses.indexOf(existingExpense)] = expense;
          } else {
            $scope.expenses.push(expense);
          }

          defered.resolve(expense);
        })
        .catch(function (err) {
          defered.reject(err);
        });

      return defered.promise;
    }


    function initApi() {
      const api = Repository.Entity('Expense').query();
      api.modelsDecorator = function (data) {
        return data.results;
      };

      api.getBaseQuery = function () {
        return baseUrl + PATH_TO_API + 'ExpensesManager';
      };

      api.doneLoadingCallback = function (expenses) {
        $scope.noItemsFound = expenses.length < 1;
        getTotalForPeriod();
      };

      api.filters = [];
      api.pageSize = 100;
      return api;
    }

  }

}());
