import './customers.picker.modal.less'

angular.module('easybizy.easybizyModalViewsModel').controller('PickACustomerModalController',
  function ($scope, $modalInstance, saveAction) {
    $scope.selectedCustomerWrapper = {};

    $scope.save = function () {
      saveAction($scope.selectedCustomerWrapper.customer, $scope.cancel, $scope.toggleLoading);
    };

    $scope.loadingWrapper = { loading: false };
    $scope.toggleLoading = function (isLoading) {
      $scope.loadingWrapper.loading = isLoading;
      $scope.$digestIfNeeded()
    };

    $scope.selectedCustomerWrapper.invoke = $scope.save;

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.allowSave = function () {
      return $scope.selectedCustomerWrapper.customer;
    };

    $scope.customerTooltip = function (customer) {
      return customer.Remarks;
    };
  });
