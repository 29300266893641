import {
  COLUMN_EDITOR_TYPE,
  GRID_FILTER_TYPE,
  GridApiResult,
  GridColumn,
  DatePickerLabels,
  LazyGridDataSource,
  SortByColumn
} from "@tomeravni/easybizy-vue-components";
import { isEmptyString, isNonEmptyArray, isNonEmptyString, isConcreteValue } from "@tomeravni/easybizy-js-common/common";
import { unique } from "@tomeravni/easybizy-js-common/array";
import {
  ODATA_DATE_TIME_FORMAT,
  SERVER_DATA_DATE_FORMAT,
  DATE_DISPLAY_FORMAT,
  RTL_DATE_TIME_DISPLAY
} from "../../constants";
import Moment from 'moment';

const columns = {
  image: (localize) => {
    const imageCol = new GridColumn('DefaultImagePath', localize('_Image_'), COLUMN_EDITOR_TYPE.IMAGE)
    imageCol.freeze = true;
    imageCol.width = 100
    return imageCol;
  },
  name: (localize, editMode) => {
    const nameCol = new GridColumn('Label', localize('_ProductOrServiceName_'), editMode ? COLUMN_EDITOR_TYPE.EDITABLE_TEXT : COLUMN_EDITOR_TYPE.DEFAULT);
    nameCol.width = 300;
    nameCol.filterType = GRID_FILTER_TYPE.STRING;
    nameCol.filterArgs = { label: localize('Search'), debounce: true };
    return nameCol;
  },
  quantity: (localize, editMode) => {
    const quantityColumn = new GridColumn('CurrentInventory', localize('_Quantity_'), editMode ? COLUMN_EDITOR_TYPE.NUMERIC : COLUMN_EDITOR_TYPE.DEFAULT, true);
    quantityColumn.width = editMode ? 200 : 100;
    quantityColumn.customAttributes = {
      debounce: true,
      integer: true,
      valueAsString: true
    };
    quantityColumn.isEditorDisabledResolver = (entity) => {
      return entity.ConcreteType === 'ServiceMetadata';
    };

    return quantityColumn;
  },
  quantityAdded: (localize) => {
    const quantityColumn = new GridColumn('QuantityAdded', localize('_Bought_'), COLUMN_EDITOR_TYPE.NUMERIC);
    quantityColumn.width = 200;
    return quantityColumn;
  },
  price: (localize, editMode) => {
    const priceColumn = new GridColumn('RetailPrice', localize('_Price_'), editMode ? COLUMN_EDITOR_TYPE.NUMERIC : COLUMN_EDITOR_TYPE.DEFAULT, true);
    priceColumn.freeze = true;
    priceColumn.customAttributes = {
      debounce: true,
      decimal: true,
      valueAsString: true
    };
    priceColumn.width = editMode ? 200 : 100;
    return priceColumn;
  },
  cost: (localize, editMode, isService) => {
    const costColumn = new GridColumn('Cost', isService ? localize('_ServiceCost_') : localize('_Cost_'), editMode ? COLUMN_EDITOR_TYPE.NUMERIC : COLUMN_EDITOR_TYPE.DEFAULT, true);
    costColumn.width = editMode ? 200 : 100;
    costColumn.customAttributes = {
      debounce: true,
      decimal: true,
      valueAsString: true
    };
    return costColumn;
  },
  priceList: (localize, editMode, name, id) => {
    const priceColumn = new GridColumn('PriceList_' + id, name, editMode ? COLUMN_EDITOR_TYPE.NUMERIC : COLUMN_EDITOR_TYPE.DEFAULT, true);
    priceColumn.priceListId = id;
    priceColumn.freeze = true;
    priceColumn.customAttributes = {
      debounce: true,
      decimal: true,
      valueAsString: true
    };
    priceColumn.width = editMode ? 200 : 100;
    priceColumn.isEditorDisabledResolver = (entity) => {
      return entity.ConcreteType === 'ProductMetadata' && entity.IngredientOnly;
    };

    return priceColumn;
  },
  sold: (localize) => {
    const soldColumn = new GridColumn('QuantitySold', localize('_Sold_'), false, true);
    soldColumn.width = 100;
    return soldColumn;
  },
  duration: (localize, editMode, mediator) => {
    const durationOptions = [];
    let currentStart = mediator.serviceTimeInterval;
    while (currentStart < 5 * 60) {
      let minutes = currentStart % 60;
      let hours = Math.floor(currentStart / 60);
      durationOptions.push({
        text: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`,
        value: `PT${hours > 0 ? `${hours}H` : ''}${minutes > 0 ? `${minutes}M` : ''}`
      });

      currentStart += mediator.serviceTimeInterval;
    }

    const durationColumn = new GridColumn('Duration', localize('_Duration_'), editMode ? COLUMN_EDITOR_TYPE.SINGLE_SELECT : COLUMN_EDITOR_TYPE.DEFAULT);
    durationColumn.width = 120;
    durationColumn.options = durationOptions;
    durationColumn.isEditorDisabledResolver = (entity) => {
      return entity.ConcreteType !== 'ServiceMetadata';
    };

    return durationColumn;
  },
  manufacturer: (localize, editMode, Repository) => {
    const manufacturerColumn = new GridColumn(editMode ? 'ManufacturerId' : 'ManufacturerName', localize('_Manufacturer_'), editMode ? COLUMN_EDITOR_TYPE.SINGLE_SELECT : COLUMN_EDITOR_TYPE.DEFAULT, true);
    manufacturerColumn.isEditorDisabledResolver = (entity) => {
      return entity.ConcreteType !== 'ProductMetadata';
    };

    Repository.Entity('Manufacturer').query().get().then(({ value }) => {
      const manufacturers = value.map((manufacturer) => ({
        text: manufacturer.ManufacturerName,
        value: manufacturer.ManufacturerId
      }));

      manufacturerColumn.options = [...manufacturers];
      manufacturerColumn.filterArgs = {
        placeholder: localize('_Manufacturer_'),
        options: manufacturers.map((manufacturer) => ({
          text: manufacturer.text,
          value: `ManufacturerId eq ${manufacturer.value}`
        }))
      };

    });

    manufacturerColumn.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
    return manufacturerColumn;
  },
  productCategory: (localize, editMode, Repository) => {
    const productCategoryColumn = new GridColumn('CategoryId', localize('_Category_'), editMode ? COLUMN_EDITOR_TYPE.SINGLE_SELECT : COLUMN_EDITOR_TYPE.DEFAULT, true);
    Repository.Entity('ProductCategory').query().get().then(({ value }) => {
      const categories = value.map((category) => ({
        text: category.ProductCategoryName,
        value: category.ProductCategoryId
      }));

      productCategoryColumn.options = [...categories];
      productCategoryColumn.filterArgs = {
        placeholder: localize('_Category_'),
        options: categories.map((category) => ({
          text: category.text,
          value: `CategoryId eq ${category.value}`
        }))
      };
    });

    productCategoryColumn.filterType = GRID_FILTER_TYPE.SINGLE_SELECT;
    return productCategoryColumn;
  },
  serviceCategory: (localize, editMode, Repository) => {
    const serviceCategoryColumn = new GridColumn('CategoryId', localize('_Category_'), editMode ? COLUMN_EDITOR_TYPE.SINGLE_SELECT : COLUMN_EDITOR_TYPE.DEFAULT, true);
    Repository.Entity('ServiceCategory').query().get().then(({ value }) => {
      const categories = value.map((category) => ({
        text: category.ServiceCategoryName,
        value: category.ServiceCategoryId
      }));


      serviceCategoryColumn.options = unique([...categories], 'text');
      serviceCategoryColumn.filterArgs = {
        placeholder: localize('_Category_'),
        options: unique(categories.map((category) => ({
          text: category.text,
          value: `CategoryId eq ${category.value}`
        })), 'text')
      };
    });

    serviceCategoryColumn.filterType = GRID_FILTER_TYPE.SINGLE_SELECT;
    return serviceCategoryColumn;
  },
  externalVoucherCategory: (localize, editMode, Repository) => {
    const externalVoucherColumn = new GridColumn('CategoryId', localize('_Category_'), editMode ? COLUMN_EDITOR_TYPE.SINGLE_SELECT : COLUMN_EDITOR_TYPE.DEFAULT, true);
    Repository.Entity('ExternalVoucherVendor').query().get().then(({ value }) => {
      const categories = value.map((category) => ({
        text: category.ExternalVoucherVendorName,
        value: category.ExternalVoucherVendorId
      }));

      externalVoucherColumn.options = [...categories];
      externalVoucherColumn.filterArgs = {
        placeholder: localize('_Category_'),
        options: categories.map((category) => ({
          text: category.text,
          value: `CategoryId eq ${category.value}`
        }))
      };
    });

    externalVoucherColumn.filterType = GRID_FILTER_TYPE.SINGLE_SELECT;
    return externalVoucherColumn;
  },
  category: (localize, Repository, includeProducts, includeServices) => {
    const categoryColumn = new GridColumn('CategoryName', localize('_Category_'));
    categoryColumn.filterType = GRID_FILTER_TYPE.MULTI_SELECT;

    const productsPromise = includeProducts ? Repository.Custom('CategoriesEditorRepository').get('ProductCategories') : new Promise((resolve) => resolve([]));
    const servicesPromise = includeServices ? Repository.Custom('CategoriesEditorRepository').get('ServiceCategories') : new Promise((resolve) => resolve([]));

    Promise.all([productsPromise, servicesPromise]).then((res) => {
      const options = res[0].map((productCategory) => ({
        text: `${(includeProducts && includeServices) ? (localize('_Product_') + ' - ') : ''}${productCategory.Name}`,
        value: `P_${productCategory.Id}`
      })).concat(
        res[1].map((serviceCategory) => ({
          text: `${(includeProducts && includeServices) ? (localize('_Service_') + ' - ') : ''}${serviceCategory.Name}`,
          value: `S_${serviceCategory.Id}`
        })))

      categoryColumn.filterArgs = {
        placeholder: localize('_Category_'),
        options
      };
    })

    return categoryColumn;
  },
  hiddenColumn: (localize, editMode) => {
    const hiddenColumn = new GridColumn('Hidden', localize('_Hidden_'), editMode ? COLUMN_EDITOR_TYPE.TOGGLE : COLUMN_EDITOR_TYPE.DEFAULT);
    hiddenColumn.width = 100;
    return hiddenColumn;
  },
  validForPointsColumn: (localize, editMode) => {
    const pointsColumn = new GridColumn('IsValidForPoints', localize('_IsValidForPoints_'), editMode ? COLUMN_EDITOR_TYPE.TOGGLE : COLUMN_EDITOR_TYPE.DEFAULT);
    pointsColumn.width = 100;
    return pointsColumn;
  },
  barcodeColumn: (localize, editMode) => {
    const barcodeColumn = new GridColumn('SerialNumber', localize('_BarcodeNumber_'), editMode ? COLUMN_EDITOR_TYPE.EDITABLE_TEXT : COLUMN_EDITOR_TYPE.DEFAULT);
    barcodeColumn.isEditorDisabledResolver = (entity) => {
      return entity.ConcreteType !== 'ProductMetadata';
    };
    barcodeColumn.filterType = GRID_FILTER_TYPE.STRING;
    barcodeColumn.filterArgs = { label: localize('Search'), debounce: true };
    return barcodeColumn;
  },
  kindColumn: (localize) => {
    const kindColumn = new GridColumn('Kind', localize('_Kind_'), undefined, true);
    kindColumn.sortByField = 'ConcreteType';
    kindColumn.width = 150;
    kindColumn.filterType = GRID_FILTER_TYPE.SINGLE_SELECT;
    kindColumn.filterArgs = {
      placeholder: localize('_BothProductsAndServices_'),
      options: [
        {
          text: localize('_OnlyProducts_'),
          value: '(ConcreteType eq \'ProductMetadata\' and IngredientOnly eq false)'
        },
        {
          text: localize('_OnlyServices_'),
          value: '(ConcreteType eq \'ServiceMetadata\')'
        }
      ]
    };

    return kindColumn;
  },
  linkToProductOrService: (localize, $state, mediator, removeFunc) => {
    const linkColumn = new GridColumn('link', localize('_Link_'), COLUMN_EDITOR_TYPE.LAZY, false);
    linkColumn.width = 150;
    linkColumn.customRenderFunc = (entity, cd, column, h) => {
      return h('div', {}, [
        h('button', {
          class: `go-to-item-link text--primary icon icon-remove px-1 ms-1`,
          on: {
            click() {
              mediator.remove(entity).then(() => {
                removeFunc(entity);
              });
            }
          }
        }),
        h('button', {
          class: `go-to-item-link text--primary ${localize.isRTL ? 'icon icon-go-right' : 'icon icon-go-left'}`,
          on: {
            click() {
              let state;
              let id = {};
              if (entity.ConcreteType === 'ServiceMetadata') {
                state = 'ServiceDetails';
                id['serviceId'] = entity.Id;
              } else if (entity.IngredientOnly) {
                state = 'IngredientDetails';
                id['ingredientId'] = entity.Id;
              } else if (entity.ConcreteType === 'ExternalVoucherMetadata') {
                state = 'ExternalVoucherDetails';
                id['voucherId'] = entity.Id;
              } else {
                state = 'ProductDetails';
                id['productId'] = entity.Id;
              }

              $state.go(state, id);
            }
          }
        })]);
    }

    return linkColumn;
  },
  color: (localize) => {
    const colorColumn = new GridColumn('Color', localize('_Color_'), COLUMN_EDITOR_TYPE.COLOR_PICKER);
    colorColumn.placeholder = localize('_Color_');
    colorColumn.isEditorDisabledResolver = (entity) => {
      return entity.ConcreteType !== 'ServiceMetadata';
    };
    return colorColumn;
  },
  general: (label, fieldName, width) => {
    const generalColumn = new GridColumn(fieldName, label, COLUMN_EDITOR_TYPE.DEFAULT);
    generalColumn.width = width;
    return generalColumn;
  },
  indexEntityId: (localize, editMode, Repository, entity) => {
    const indexEntityIdColumn = new GridColumn('IndexIdentifier', localize('_IndexIndentifier_'), editMode ? COLUMN_EDITOR_TYPE.SINGLE_SELECT : COLUMN_EDITOR_TYPE.DEFAULT, true);

    const adaptCategoryName = (categoryName) => {
      if (!isNonEmptyString(categoryName)) {
        return '';
      }

      if (categoryName.indexOf(' ') < 1) {
        return categoryName;
      }

      const split = categoryName.split(' ');
      return split.map((curr, index) => {
        return index < split.length - 1 ? curr.charAt(0) : curr;
      }).join('.');

    }

    indexEntityIdColumn.options = [];
    Repository.Custom('Search').allItems(entity).then((res) => {
      const entities = res.map((indexEntity) => ({
        text: `${adaptCategoryName(indexEntity.categories)}/${indexEntity.name}`,
        value: indexEntity.id,
        category: indexEntity.categories
      }));

      entities.sort((a, b) => a.category.localeCompare(b.category));
      indexEntityIdColumn.options = unique([...entities], 'text');
    });

    indexEntityIdColumn.placeholder = localize('_IndexIndentifier_');
    return indexEntityIdColumn;
  },
  suggestedIndexItem: (localize, editMode, Repository, entity) => {
    const suggestedIndexItemColumn = new GridColumn('SuggestedIndexItem', localize('_IndexIndentifier_'), COLUMN_EDITOR_TYPE.LAZY, true);
    suggestedIndexItemColumn.customRenderFunc = (entity, changeDelegate, column, h) => {
      let val = entity[column.fieldName];
      if (!isConcreteValue(val)) {
        return h('div', '');
      }

      let adaptedValue = val;
      if (!isNonEmptyArray(val)) {
        adaptedValue = [val];
      }

      const vNodes = adaptedValue.map((item) => {
        return h('button', {
          class: 'suggest-item-button', on: {
            click: () => {
              entity.IndexIdentifier = item.id;
              Repository.Entity(entity.ConcreteType).patch(entity.Id, { IndexIdentifier: item.id }).post().catch((err) => {
                toastr.error(localize("_ErrorUpdatingItems_"));
              });
            }
          }
        }, item.name)
      })

      return h('div', { class: 'flex flex-col' }, vNodes);
    }

    return suggestedIndexItemColumn;
    // Repository.Custom('Search').findSimilarOnIndex().then((res) => {
  }

}

class AbstractInventoryDataSource extends LazyGridDataSource {
  constructor(api, localize, mediator, $state, indexSuggestionsApi) {
    super();
    this._api = api;
    this.$state = $state;
    this._mediator = mediator;
    this._indexSuggestionsApi = indexSuggestionsApi;

    this.productTypeName = localize('_Product_');
    this.serviceTypeName = localize('_Service_');
    this.externalVoucherTypeName = localize('_ExternalVoucher_');
  }


  getItemId(item) {
    return `${item.Id}${item.ConcreteType}`;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
    this._mediator.notify('sortChange', iColumn);

  }

  get api() {
    return this._api;
  }

  reverseFilter(queryAndCategories) {
    const { ids, adaptedFilters } = queryAndCategories;

    const adapted = {};
    let currentColumns = this.columns;
    if (adaptedFilters) {
      adaptedFilters.map((filter) => {
        if (Array.isArray(filter)) {
          filter = filter.pop();
        }

        if (filter.includes('ConcreteType')) {
          const column = currentColumns.find((x) => x.fieldName === 'Kind');
          if (column) {
            adapted['Kind'] = filter;
          }
        } else if (filter.includes('indexof')) {
          const [field, value] = filter.replace(/.*indexof\(/, '').replace(/\).*/, '').split(',').map((x) => x.replace(/\'/gi, ''));
          const column = currentColumns.find((x) => x.fieldName === field);
          if (column) {
            adapted[field] = value;
          }
        } else if (filter.includes('ManufacturerId')) {
          const values = filter.replace('(', '').replace(')', '').split(' or ');
          const column = currentColumns.find((column) => ['ManufacturerId', 'ManufacturerName'].includes(column.fieldName));
          if (column) {
            adapted[column.fieldName] = values;
          }
        }
      })
    }

    if (ids) {
      const categoryFilter = currentColumns.find((x) => x.fieldName === 'CategoryName');
      if (categoryFilter && !isEmptyString(ids)) {
        const twoFiltersSeparator = ids.indexOf('],');
        let seperated = [ids];
        if (twoFiltersSeparator > 0) { // both categories filter
          const first = ids.substr(0, twoFiltersSeparator + 1);
          const second = ids.substr(twoFiltersSeparator + 2);
          seperated = [first, second];
        }

        const categories = seperated.map((category) => {
          const [cType, concrete] = category.split(':');
          let letter = cType.charAt(0) === 'S' ? 'S_' : 'P_';
          return concrete.replace(/\]|\[/gi, '').split(',').map((x) => letter + x.trim());
        }).reduce((acc, curr) => ([...acc, ...curr]), []);

        adapted['CategoryName'] = categories;
      }
    }

    this.filterValues = adapted;
  }

  reverseSort(sort) {
    if (sort) {
      let sortedByColumn = this.columns.find((x) => x.fieldName === sort.field);
      if (sortedByColumn) {
        this._sortByColumn = new SortByColumn(sortedByColumn, sort.desc);
      }
    }
  }

  filterChange(adaptedFilter) {
    super.filterChange(adaptedFilter);
    this._mediator.notify('filterChange', this.adaptFiltersToQuery(adaptedFilter));
  }

  adaptFiltersToQuery(filter) {
    let ids = '';
    let adaptedFiltersToSet = [];

    if (isNonEmptyArray(filter) || isNonEmptyArray(this._constantFilters)) {

      const adaptedFilters = filter.concat(this._constantFilters || []).filter((x) => x.column.fieldName !== 'CategoryName').map((x) => {
        if (x.column.fieldName === 'Label' || x.column.fieldName === 'SerialNumber') {
          return `(indexof(${x.column.fieldName},'${x.filterValue}') gt -1)`
        } else {
          if (isNonEmptyArray(x.filterValue) && x.filterValue.length > 1) {
            return `(${x.filterValue.join(' or ')})`;
          }

          return x.filterValue;
        }
      });

      adaptedFiltersToSet = adaptedFilters;

      const categoryFilter = filter.find((x) => x.column.fieldName === 'CategoryName');
      if (categoryFilter) {
        const asObj = categoryFilter.filterValue.reduce((acc, curr) => {
          const split = curr.split('_');
          const type = split[0] === 'P' ? 'ProductCategories' : 'ServiceCategories';
          const id = split.pop();
          acc[type].push(id);
          return { ...acc };
        }, { ServiceCategories: [], ProductCategories: [] });

        if (asObj.ServiceCategories.length === 0) {
          delete asObj.ServiceCategories;
        }

        if (asObj.ProductCategories.length === 0) {
          delete asObj.ProductCategories;
        }

        ids = `${JSON.stringify(asObj).replace(/\"/gi, '').replace('{', '').replace('}', '')}`;
      }
    }

    return { ids, adaptedFilters: adaptedFiltersToSet };
  }


  loadOnRemote(top, skip, sort, filter) {
    this._api.clear(true);
    this._api.take(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }

    const { ids, adaptedFilters } = this.adaptFiltersToQuery(filter);
    if (ids.length > 0) {
      this._api.ids(ids);
    }

    if (isNonEmptyArray(adaptedFilters)) {
      this._api.setFilter(adaptedFilters);
    }

    return Promise.all([this._api.get(), this._commonPromise].filter((x) => !!x)).then((result) => {
      const [res, columns] = result;
      return new GridApiResult(res.results.map((item) => {
        let kind;

        switch (item.ConcreteType) {
          case 'ProductMetadata':
            kind = this.productTypeName;
            break;
          case 'ServiceMetadata':
            kind = this.serviceTypeName;
            break;
          case 'ExternalVoucherMetadata':
            kind = this.externalVoucherTypeName;
            break;
        }

        if (item.DefaultImagePath) {
          item.DefaultImagePath = item.DefaultImagePath.replace(/ /gi, '');
        }

        let itemToReturn = {
          ...item,
          RetailPrice: parseFloat(item.RetailPrice),
          Cost: parseFloat(item.Cost),
          QuantityAdded: 0,
          CurrentInventory: parseFloat(item.CurrentInventory),
          DefaultImagePath: (item.DefaultImagePath && item.DefaultImagePath.indexOf('http') !== 0)
            ? `${window.filesURI}${item.DefaultImagePath}`
            : item.DefaultImagePath,
          Kind: kind
        };

        if (Array.isArray(columns)) {
          columns.forEach((priceListColumn) => {
            const definedPriceListForItem =
              itemToReturn.PriceLists.find((x) => x.PriceListId === priceListColumn.priceListId);
            itemToReturn[priceListColumn.fieldName] =
              definedPriceListForItem ? parseFloat(definedPriceListForItem.RetailPrice) : itemToReturn.RetailPrice;

            if (definedPriceListForItem) {
              itemToReturn[`ItemIdPriceList_${definedPriceListForItem.PriceListId}`] =
                definedPriceListForItem.PriceListItemId;

            }
          });
        }

        if (this._indexSuggestionsApi && this.hasDynamicIndexValue && !item.IndexIdentifier) {
          itemToReturn['SuggestedIndexItem'] = this._indexSuggestionsApi(itemToReturn.Label).then((res) => {
            if (isNonEmptyArray(res.results)) {
              let smallerResults = res.results.filter((x) => x.score > 2).slice(0, 5);
              if (smallerResults.length === 0) {
                return null;
              }

              if (smallerResults.length === 1) {
                return itemToReturn['SuggestedIndexItem'] = {
                  name: smallerResults[0].serviceName,
                  id: smallerResults[0].id
                };
              }

              return itemToReturn['SuggestedIndexItem'] = smallerResults.slice(1).reduce(({
                items,
                latestScore
              }, curr) => {
                if (latestScore > 0 && latestScore / curr.score < 1.2) {
                  return { items: [...items, curr], latestScore: curr.score };
                } else {
                  return { items: items, latestScore: 0 };
                }
              }, { items: [smallerResults[0]], latestScore: smallerResults[0].score }).items.map((x) => ({
                name: x.serviceName,
                id: x.id
              }));
            }

          });
        }

        return itemToReturn;
      }),
        top,
        skip,
        res.total);
    });

  }
}

//currently not in use
export class DefaultInventoryDS extends AbstractInventoryDataSource {
  constructor(api, localize, mediator, Repository, $state) {
    super(api, localize, mediator, $state);
    const imageCol = columns.image(localize);
    const nameCol = columns.name(localize, true);
    const quantityColumn = columns.quantity(localize, false);
    const priceColumn = columns.price(localize, true);
    const soldColumn = columns.sold(localize);
    const durationColumn = columns.duration(localize, true, mediator);
    const manufacturerColumn = columns.manufacturer(localize, true, Repository);
    const categoryColumn = columns.category(localize, Repository, true, true);
    const hiddenColumn = columns.hiddenColumn(localize, true);
    const colorColumn = columns.color(localize);

    let barcodeColumn;
    if (mediator.enableBarcode) {
      barcodeColumn = columns.barcodeColumn(localize, true);
    }
    let pointsColumn;
    if (mediator.enablePoints) {
      pointsColumn = columns.validForPointsColumn(localize, true);
    }

    const kindColumn = columns.kindColumn(localize);
    const link = columns.linkToProductOrService(localize, $state, mediator, this.pullItem.bind(this));

    this.columns = [
      imageCol,
      nameCol,
      priceColumn,
      kindColumn,
      categoryColumn,
      quantityColumn,
      manufacturerColumn,
      durationColumn,
      colorColumn,
      soldColumn,
      barcodeColumn,
      pointsColumn,
      hiddenColumn,
      link
    ].filter((x) => !!x);

    this._sortByColumn = new SortByColumn(kindColumn, false);
  }
}


export class ServiceMetadatasDS extends AbstractInventoryDataSource {
  constructor(api, localize, mediator, Repository, $state) {
    super(api, localize, mediator, $state, Repository.Custom('Search').findSimilarOnIndex);
    this._constantFilters = [{
      column: {},
      filterValue: `(ConcreteType eq 'ServiceMetadata')`
    }];

    this.hasDynamicIndexValue = true;

    const imageCol = columns.image(localize);
    const nameCol = columns.name(localize, true);
    nameCol.freeze = true;
    const priceColumn = columns.price(localize, true);
    const soldColumn = columns.sold(localize);
    const durationColumn = columns.duration(localize, true, mediator);
    const categoryColumn = columns.serviceCategory(localize, true, Repository);
    const hiddenColumn = columns.hiddenColumn(localize, true);
    const colorColumn = columns.color(localize);
    let indexEntityId;
    let suggestedIndexItem;
    if (mediator.isAdmin || window.envName === 'dev') {
      indexEntityId = columns.indexEntityId(localize, true, Repository, 'services');
      suggestedIndexItem = columns.suggestedIndexItem(localize, true, Repository, 'services');
    }

    let costColumn;
    if (mediator.showServiceCost) {
      costColumn = columns.cost(localize, true, true);
    }
    let pointsColumn;
    if (mediator.enablePoints) {
      pointsColumn = columns.validForPointsColumn(localize, true);
    }
    const link = columns.linkToProductOrService(localize, $state, mediator, this.pullItem.bind(this));

    this.columns = [
      imageCol,
      nameCol,
      priceColumn,
      indexEntityId,
      suggestedIndexItem,
      categoryColumn,
      durationColumn,
      colorColumn,
      soldColumn,
      pointsColumn,
      costColumn,
      hiddenColumn,
      link
    ].filter((x) => !!x);

    this._sortByColumn = new SortByColumn(nameCol, false);
  }
}

export class ProductMetadatasDS extends AbstractInventoryDataSource {
  constructor(api, localize, mediator, Repository, $state) {
    super(api, localize, mediator, $state);
    this._constantFilters = [{
      column: {},
      filterValue: `(ConcreteType eq 'ProductMetadata' and IngredientOnly eq false)`
    }];
    const imageCol = columns.image(localize);
    const nameCol = columns.name(localize, true);
    nameCol.freeze = true;
    const quantityColumn = columns.quantity(localize, false);
    const priceColumn = columns.price(localize, true);
    const soldColumn = columns.sold(localize);
    const manufacturerColumn = columns.manufacturer(localize, true, Repository);
    const categoryColumn = columns.productCategory(localize, true, Repository);
    const hiddenColumn = columns.hiddenColumn(localize, true);

    let barcodeColumn;
    if (mediator.enableBarcode) {
      barcodeColumn = columns.barcodeColumn(localize, true);
    }
    let pointsColumn;
    if (mediator.enablePoints) {
      pointsColumn = columns.validForPointsColumn(localize, true);
    }

    let indexEntityId;
    if (mediator.isAdmin) {
      indexEntityId = columns.indexEntityId(localize, true, Repository, 'products');
    }

    const link = columns.linkToProductOrService(localize, $state, mediator, this.pullItem.bind(this));

    this.columns = [
      imageCol,
      nameCol,
      priceColumn,
      indexEntityId,
      categoryColumn,
      manufacturerColumn,
      quantityColumn,
      soldColumn,
      barcodeColumn,
      pointsColumn,
      hiddenColumn,
      link
    ].filter((x) => !!x);

    this._sortByColumn = new SortByColumn(nameCol, false);
  }
}

export class IngredientsDS extends AbstractInventoryDataSource {
  constructor(api, localize, mediator, Repository, $state) {
    super(api, localize, mediator, $state);
    this._constantFilters = [{
      column: {},
      filterValue: `(ConcreteType eq 'ProductMetadata' and IngredientOnly eq true)`
    }];
    const imageCol = columns.image(localize);
    const nameCol = columns.name(localize, true);
    const categoryColumn = columns.productCategory(localize, true, Repository);
    const hiddenColumn = columns.hiddenColumn(localize, true);

    const link = columns.linkToProductOrService(localize, $state, mediator, this.pullItem.bind(this));

    this.columns = [
      imageCol,
      nameCol,
      categoryColumn,
      hiddenColumn,
      link
    ].filter((x) => !!x);

    this._sortByColumn = new SortByColumn(categoryColumn, false);
  }
}

export class ExternalVouchersDS extends AbstractInventoryDataSource {
  constructor(api, localize, mediator, Repository, $state) {
    super(api, localize, mediator, $state);
    this._constantFilters = [{
      column: {},
      filterValue: `(ConcreteType eq 'ExternalVoucherMetadata')`
    }];
    const imageCol = columns.image(localize);
    const nameCol = columns.name(localize, true);
    const priceColumn = columns.price(localize, true);
    const soldColumn = columns.sold(localize);
    const categoryColumn = columns.externalVoucherCategory(localize, true, Repository);
    const hiddenColumn = columns.hiddenColumn(localize, true);

    const link = columns.linkToProductOrService(localize, $state, mediator, this.pullItem.bind(this));

    this.columns = [
      imageCol,
      nameCol,
      priceColumn,
      categoryColumn,
      soldColumn,
      hiddenColumn,
      link
    ].filter((x) => !!x);

    this._sortByColumn = new SortByColumn(categoryColumn, false);
  }
}


export class PriceListInventoryDS extends AbstractInventoryDataSource {
  constructor(api, localize, mediator, Repository, $state) {
    super(api, localize, mediator, $state);
    const imageCol = columns.image(localize);
    const nameCol = columns.name(localize);
    const priceColumn = columns.price(localize, true);
    this._commonPromise = new Promise((resolve, reject) => {
      Repository.Entity('PriceList', true).query().get()
        .then((data) => {
          const priceListColumns = data.results.map((x) => {
            return columns.priceList(localize, true, x.PriceListName, x.PriceListId)
          });
          this.columns = [...this.columns, ...priceListColumns];
          resolve(priceListColumns);
        }).catch((err) => {
          reject(err);
        });
    });

    const categoryColumn = columns.category(localize, Repository, true, true);
    const kindColumn = columns.kindColumn(localize);

    this.columns = [
      imageCol,
      nameCol,
      kindColumn,
      categoryColumn,
      priceColumn
    ].filter((x) => !!x);

    this._sortByColumn = new SortByColumn(kindColumn, false);
  }
}

export class UpdateInventoryDS extends AbstractInventoryDataSource {
  constructor(api, localize, mediator, Repository, $state) {
    super(api, localize, mediator, $state);
    this._constantFilters = [{
      column: {},
      filterValue: `(ConcreteType eq 'ProductMetadata' and IngredientOnly eq false)`
    }]
    const imageCol = columns.image(localize);
    const nameCol = columns.name(localize);
    const quantityColumn = columns.quantity(localize);
    quantityColumn.freeze = true;
    const quantityAddedColumn = columns.quantityAdded(localize);
    quantityAddedColumn.freeze = true;
    const priceColumn = columns.price(localize);
    const categoryColumn = columns.category(localize, Repository, true, true);
    const manufacturerColumn = columns.manufacturer(localize, false, Repository);
    let barcodeColumn;
    if (mediator.enableBarcode) {
      barcodeColumn = columns.barcodeColumn(localize, false);
    }

    this.columns = [
      imageCol,
      nameCol,
      quantityColumn,
      quantityAddedColumn,
      priceColumn,
      manufacturerColumn,
      categoryColumn,
      barcodeColumn
    ].filter((x) => !!x);

    this.columns.forEach((x) => x.freeze = false);
  }
}


export class InventoryHistoryDS extends LazyGridDataSource {
  constructor(api, localize, mediator, Repository, $state) {
    super(api, localize, mediator, $state);
    super();
    this._api = api;

    const createdOn = new GridColumn('CreatedOn', localize('_CreatedOn_'), COLUMN_EDITOR_TYPE.LAZY);
    // createdOn.width = 200;
    createdOn.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    createdOn.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()] };
    createdOn.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.CreatedOn ? Moment(entity.CreatedOn, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT) : "  ");
    };
    const SupplyRelatedInvoiceCol = columns.general(localize('_SupplyRelatedInvoice_'), 'SupplyRelatedInvoice', 300);
    SupplyRelatedInvoiceCol.filterType = GRID_FILTER_TYPE.STRING;
    SupplyRelatedInvoiceCol.filterArgs = { label: localize('Search'), debounce: true };
    const totalProductsCol = columns.general(localize('_TotalProductsType_'), 'TotalProductsType', 200);
    const totalQuntityCol = columns.general(localize('_TotalQuantitySupplied_'), 'TotalQuantitySupplied', 200);
    const remarksCol = columns.general(localize('_Remarks_'), 'Remarks', 200);

    this.columns = [
      createdOn,
      SupplyRelatedInvoiceCol,
      totalProductsCol,
      totalQuntityCol,
      remarksCol,
    ].filter((x) => !!x);

    this._sortByColumn = new SortByColumn(createdOn, true);
  }

  getExpansionContent(item) {
    return {
      render(createElement) {
        const vNodes = item.ProductSupplyHistories
          .map((x) => createElement('div', { class: 'mb-2' }, x.QuantitySupplied + '   ----- ' + x.ProductLabel
            + '   -----   (' + x.QuantityBefore + ' + ' + x.QuantitySupplied + ' => ' + x.TotalAfterAdd + ')'));
        return createElement('div', {
          class: 'px-4 pb-2'
        }, vNodes);
      }
    }
  }

  isExpandable(item) {
    return true;
  }

  getItemId(item) {
    return item.ProductInventoryUpdatedId;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  get api() {
    return this._api;
  }

  filterChange(filter) {
    super.filterChange(this.adaptFiltersToQuery(filter));
  }

  adaptFiltersToQuery(filter) {
    let adaptedFiltersToSet = [];

    if (isNonEmptyArray(filter) || isNonEmptyArray(this._constantFilters)) {
      const adaptedFilters = filter.concat(this._constantFilters || []).map((x) => {
        if (x.column.fieldName === 'SupplyRelatedInvoice') {
          return `(indexof(SupplyRelatedInvoice,'${x.filterValue}') gt -1)`
        } else if (x.column.fieldName === 'CreatedOn' && x.filterValue.length) {
          return `(${x.column.fieldName} ge DateTime'${moment(x.filterValue[0]).format(ODATA_DATE_TIME_FORMAT)}' and ${x.column.fieldName} le DateTime'${moment(x.filterValue[1]).format(ODATA_DATE_TIME_FORMAT)}')`
        } else {
          if (isNonEmptyArray(x.filterValue) && x.filterValue.length > 1) {
            return `(${x.filterValue.join(' or ')})`;
          }

          return x.filterValue;
        }
      });

      adaptedFiltersToSet = adaptedFilters;
    }

    return adaptedFiltersToSet;
  }

  loadOnRemote(top, skip, sort, filter) {
    this._api.top(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }
    const adaptedFilters = this.adaptFiltersToQuery(filter);
    this._api.filter(adaptedFilters);

    return this._api.get().then((result) => {
      return new GridApiResult(result.map((item) => {
        return item;
      }),
        top,
        skip,
        null);
    });
  }

}


class DefaultDatePickerLabels extends DatePickerLabels {
  constructor(localize) {
    super();
    this._inputPlaceholder = localize('_ChooseDate_');
    this._selectADate = localize('_ChooseDate_');
    this._pickAStartTime = localize('_StartTime_');
  }

  get inputPlaceholder() {
    return this._inputPlaceholder;
  }

  get selectADate() {
    return this._selectADate;
  }

  get pickAStartTime() {
    return this._pickAStartTime;
  }
}


