import './voip.config.directive.less'
import {digitsOnly} from "@tomeravni/easybizy-js-common/common";
import {extractError} from "@tomeravni/easybizy-js-common/errors";

angular.module('easybizy.common.toolbar.actions')
  .directive('voidOptions', function (Repository, configuration, localize) {
    return {
      restrict: 'E',
      scope: {},
      replace: true,
      template: require('./voip.config.directive.tpl.html'),
      link: function (scope, element, attrs) {
        scope.toggleCallerId = (val) => {
          Repository.Custom('Configurations').preventCallerId(!val)
          .then(() => {
            toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'))
          }).catch((err) => {
          toastr.error(extractError(err))
        })

        }
        configuration.getPersonalAsync().then(function (personalConfigurations) {
          scope.activeCallerId = !personalConfigurations.userData?.PreventCallerId
        })

        scope.extensionWrapper = {
          extension: null
        }

        scope.extensionField = {
          fieldName: 'extension', icon: "icon icon-phone", placeholder: localize.getLocalizedString("_Extension_")
        };

        let originalExtension = null
        configuration.loggedInUserAsPromise.then((user) => {
          if (!user?.operator) {
            delete scope.extensionWrapper
          } else {
            scope.extensionWrapper.extension = user?.operator?.extension || ''
            originalExtension = scope.extensionWrapper.extension
          }
        })

        scope.updateExtension = () => {
          const asDigitsOnly = digitsOnly(scope.extensionWrapper.extension)
          if (asDigitsOnly !== scope.extensionWrapper.extension) {
            scope.extensionWrapper.extension = asDigitsOnly
          }

          Repository.Custom('Configurations').patchExtension(asDigitsOnly)
            .then(() => {
              toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'))
            }).catch((err) => {
            toastr.error(extractError(err))
          })
        }

      }
    }
  })
