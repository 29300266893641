import './unified.structured.report.directive.less'
import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT } from "../../../constants";

angular.module('easybizy.common.custom.reports.actions')
  .directive('unifiedStructuredReport', ['localize', 'Repository', '$timeout', 'printerMediator', function (localize, Repository, $timeout, printerMediator) {
    return {
      restrict: 'E',
      scope: {},
      replace: true,
      template: require('./unified.structured.report.directive.tpl.html'),
      link: function (scope, element, attrs) {
        scope.wasReportGenerated = false;
        scope.rangeMetadataFrom = {
          fieldName: 'from',
          icon: "icon icon-calendar",
          placeholder: "Start Date",
          validation: "{'required': true}",
          type: 'date'
        };
        scope.rangeMetadataTo = {
          fieldName: 'to',
          icon: "icon icon-calendar",
          placeholder: "End Date",
          validation: "{'required': true}",
          type: 'date'
        };
        scope.selectedRange = {};
        var now = moment();
        scope.selectedRange.from = moment().date(1).month(now.month() - 1);
        scope.selectedRange.to = moment().date(1).month(now.month());

        scope.selectedRange.from = scope.selectedRange.from.format(DATE_DISPLAY_FORMAT);
        scope.selectedRange.to = scope.selectedRange.to.format(DATE_DISPLAY_FORMAT);
        scope.isGeneratingReportWrapper = {};
        scope.isGeneratingReportWrapper.inProgress = false;

        var recordsIframeEl = element.find('.unified-structured-report-left-result');
        var documentsIframeEl = element.find('.unified-structured-report-right-result');
        //var generatedReportId = null;
        var generatedResult;
        recordsIframeEl.contents().find('body');
        scope.produceReport = function () {
          scope.isGeneratingReportWrapper.inProgress = true;
          Repository.Custom("ReportsRepository").unifiedStructuredFilesReport(
            moment(scope.selectedRange.from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT),
            moment(scope.selectedRange.to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT))
            .then(function (result) {
              scope.wasReportGenerated = true;
              $timeout(function () {
                loadIFrameContent(recordsIframeEl, result.Records);
                loadIFrameContent(documentsIframeEl, result.Documents);
              }, 200);

              generatedResult = result;
              scope.isGeneratingReportWrapper.inProgress = false;
              scope.$digestIfNeeded();
            })
            .catch(function (err) {
              toastr.error(localize.getLocalizedString("_ErrorProducing_", localize.getLocalizedString("_Reports_")) + " - " + err.data.ExceptionMessage);
              scope.isGeneratingReportWrapper.inProgress = false;
              scope.$digestIfNeeded();
            });
        };

        scope.downloadReport = function () {
          Repository.Custom("ReportsRepository").unifiedStructuredFiles(generatedResult.ReportId);
        };

        scope.printLeft = function () {
          printerMediator.print(generatedResult.Records);
        };

        scope.printRight = function () {
          printerMediator.print(generatedResult.Documents);
        };

        function loadIFrameContent(iframe, content) {
          iframe.contents().find('body').html(content);
        }
      }
    }
  }]);
