import './customer.source.picker.directive.less'

angular.module('easybizy.common.common-controls').directive('customerSourcePicker',
  function (Repository, localize) {
    return {
      restrict: 'E',
      scope: {
        customer: '=',
        sourceField: '@',
        extraValueField: '@',
        textValueWrapper: '='
      },
      replace: true,
      template: require('../customer-source-picker/customer.source.picker.directive.tpl.html'),
      link: function (scope) {
        var sourcesLoaded = false;


        scope.sources = [
          { name: localize.getLocalizedString("_PickTrafficSource_"), value: 0 }
        ];
        Repository.Entity('ArrivalSource').query().get().then(function (result) {
          sourcesLoaded = true;
          result.value.forEach(function (source) {
            scope.sources.push({ name: source.ArrivalSourceName, value: source.ArrivalSourceId });
          });

          if (scope.customer && scope.customer.ArrivalSourceId) {
            updateSourceById(scope.customer.ArrivalSourceId);
          }
        });

        scope.customerSource = { source: scope.sources[0] };

        scope.$watch('customer.ArrivalSourceId', function (newVal, oldVal) {
          if (newVal && newVal !== oldVal) { // We only care about existing customer.
            updateSourceById(newVal);
          }
        });

        scope.addNewArrivalSourceAction = function (newSource) {
          scope.sources.push({ name: newSource.name, id: -1 });
        };
        scope.$watch('customerSource.source', function (newVal) {
          if (newVal && newVal.value) {
            scope.customer.ArrivalSource = { ArrivalSourceName: newVal.name, ArrivalSourceId: newVal.value };
            scope.customer.ReferralCustomerId = null;
          }
        });

        var customerReferral = null;
        scope.$watch('customerSource.customer', function (newVal, oldVal) {
          if (newVal && newVal != oldVal) {
            customerReferral = newVal;
            scope.customer.ReferralCustomerId = newVal.CustomerId;
            setCustomerReferral(newVal);

          }
        });

        scope.$watch('customer.ReferralCustomerId', function (newVal) {
            if (newVal) {
              if (customerReferral && customerReferral.CustomerId === newVal.CustomerId) {
                setCustomerReferral(customerReferral);
              } else {
                Repository.Entity("Customer").query().id(newVal).expand("Images").get().then(function (data) {
                  setCustomerReferral(data);
                })
              }

            } else {
              setCustomerReferral(null);
            }
          }
        );

        function updateSourceById(arrivalSourceId) {
          if (!sourcesLoaded) {
            return;
          }

          scope.customerSource.source = scope.sources.filter(function (source) {
            return source.value === arrivalSourceId;
          })[0] || scope.sources[0];

          if (scope.textValueWrapper) {
            scope.textValueWrapper.text = scope.customerSource.source.value ? scope.customerSource.source.name : null;
          }
        }

        function setCustomerReferral(customer) {
          if (scope.textValueWrapper) {
            scope.textValueWrapper.referringCustomer = customer;
          }

          scope.customerSource.customer = customer;
        }
      }
    };
  });
