import './promotion.quick.sender.modal.less'

angular.module('easybizy.easybizyModalViewsModel').controller('PromotionQuickSenderModalController',
  function ($scope, $modalInstance, parameters, localize, $timeout, Repository) {
    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.contentEditorsResolver = {};
    $scope.selectedMedium = parameters.messageType;
    $scope.customer = parameters.customer;
    $scope.messageWrapper = {};
    $scope.validateActionWrapper = {};
    $scope.sendToValidatorAggregator = {};

    $scope.messageWrapper.PhoneNumber = $scope.customer.MobileFirst;
    $scope.messageWrapper.EmailAddress = $scope.customer.EmailAddress;

    $scope.sendToField = $scope.selectedMedium === "Sms" ?
      {
        fieldName: 'PhoneNumber', icon: "icon icon-sms", placeholder: localize.getLocalizedString("_Phone_"),
        validation: "{'max-length': 10, 'min-length': 9, 'digits': true, 'required': true}"
      } :
      {
        fieldName: 'EmailAddress', icon: "icon icon-mail-outline", placeholder: localize.getLocalizedString("_Email_"),
        validation: "{'email': true, 'required': true}"
      };

    var sendToLocalize = $scope.selectedMedium === "Sms" ? localize.getLocalizedString("_WrongPhone_")
      : localize.getLocalizedString("_WrongEmail_");


    $scope.send = function () {
      var validationFields = $scope.validateActionWrapper.validate();
      if (validationFields.length !== 0) {
        var wrongFieldMessage = $scope.selectedMedium === "Sms" ? localize.getLocalizedString("_WrongPhone_")
          : localize.getLocalizedString("_WrongEmail_");

        toastr.error(wrongFieldMessage);
        return;
      }

      var editorValidation = $scope.contentEditorsResolver.resolver.validate();
      if (editorValidation) {
        toastr.error(editorValidation);
        return;
      }

      $scope.isLoading = true;
      var fakePromotion = {};
      $scope.contentEditorsResolver.resolver.resolve(fakePromotion);
      if ($scope.messageWrapper.PhoneNumber === $scope.customer.MobileFirst
        && $scope.messageWrapper.EmailAddress === $scope.customer.EmailAddress) {
        fakePromotion.CustomersId = [$scope.customer.CustomerId];
      } else {
        fakePromotion.DirectRecipients = $scope.selectedMedium === "Sms" ? $scope.messageWrapper.PhoneNumber : $scope.messageWrapper.EmailAddress;
      }
      fakePromotion.IsServiceEngagementType = true;
      Repository.Custom("PromotionsRepository").create(fakePromotion)
        .then(function (data) {
          $scope.isLoading = false;
          toastr.success(localize.getLocalizedString('_MessageCreated_'));
          $modalInstance.dismiss('cancel');
        })
        .catch(function (e) {
          $scope.isLoading = false;
          toastr.error(localize.getLocalizedString('_FailedToCreateMessage_'));
        })

    };

  });
