import {
  COLUMN_EDITOR_TYPE, GRID_FILTER_TYPE,
  GridApiResult,
  GridColumn,
  LazyGridDataSource,
  DatePickerLabels,
  SortByColumn
} from "@tomeravni/easybizy-vue-components";

import Moment from "moment"
import { isNonEmptyArray } from "@tomeravni/easybizy-js-common/common";
import {
  SERVER_DATA_DATE_FORMAT,
  RTL_DATE_TIME_DISPLAY,
  ODATA_DATE_TIME_FORMAT
} from "../../constants";

export default class MeetingHistoryDS extends LazyGridDataSource {
  constructor(api, localize, stateManager, mediator, Repository, printerMediator) {
    super();
    this._api = api;
    this._meetingId = mediator.meetingId;

    const createdOn = new GridColumn('ActionTime', localize('_ActionTime_'), COLUMN_EDITOR_TYPE.LAZY);
    createdOn.width = 150;
    if (!this._meetingId) {
      createdOn.filterType = GRID_FILTER_TYPE.DATE_RANGE;
      createdOn.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true }
    } else {
      api.id(mediator.meetingId);
    }
    createdOn.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', Moment(entity.ActionTime, SERVER_DATA_DATE_FORMAT).format(RTL_DATE_TIME_DISPLAY));
    };

    const actionType = new GridColumn('ActionType', localize('_ActionType_'), COLUMN_EDITOR_TYPE.LAZY);
    actionType.width = 120;
    actionType.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', localize(`_${entity.ActionType}_`));
    };
    actionType.filterType = GRID_FILTER_TYPE.SINGLE_SELECT;
    const actionTypes = [{ text: localize('_Add_'), value: 'Add' }, { text: localize('_Update_'), value: 'Update' }, { text: localize('_Delete_'), value: 'Delete' }];
    actionType.options = actionTypes;
    actionType.filterArgs = {
      placeholder: localize('_All_'),
      options: actionTypes.map((action) => ({
        text: action.text,
        value: `ActionType eq '${action.value}'`
      }))
    };

    const userName = new GridColumn('UserName', localize('_UserName_'), false, true);
    userName.width = 120;


    const nameCol = new GridColumn('CustomerName', localize('_Customer_'), COLUMN_EDITOR_TYPE.LAZY);
    nameCol.width = 150;
    nameCol.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.CustomerName ?? entity.CalendarEventName);
    };
    const meetingStartTime = new GridColumn('StartTime', localize('_StartTime_'), COLUMN_EDITOR_TYPE.LAZY);
    meetingStartTime.width = 150;
    meetingStartTime.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', Moment(entity.StartTime, SERVER_DATA_DATE_FORMAT).format(RTL_DATE_TIME_DISPLAY));
    };

    const meetingEndTime = new GridColumn('EndTime', localize('_EndTime_'), COLUMN_EDITOR_TYPE.LAZY);
    meetingEndTime.width = 150;
    meetingEndTime.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', Moment(entity.EndTime, SERVER_DATA_DATE_FORMAT).format(RTL_DATE_TIME_DISPLAY));
    };

    const meetingEmployeeName = new GridColumn('EmployeeName', localize('_EmployeeName_'), COLUMN_EDITOR_TYPE.LAZY);
    meetingEmployeeName.width = 150;

    const remarks = new GridColumn('Remarks', localize('_Remarks_'), COLUMN_EDITOR_TYPE.LAZY);
    remarks.width = 150;

    const status = new GridColumn('MeetingState', localize('_Status_'), COLUMN_EDITOR_TYPE.LAZY);
    status.width = 120;
    status.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', localize(`_${entity.MeetingState}_`));
    };

    const paid = new GridColumn('Paid', localize('_Paid_'), COLUMN_EDITOR_TYPE.LAZY);
    paid.width = 100;
    paid.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.Paid ? localize('_Paid_') : localize('_NotPaid_'));
    };


    this.columns = [
      createdOn,
      actionType,
      userName,
      nameCol,
      meetingStartTime,
      meetingEndTime,
      status,
      paid,
      remarks,
      meetingEmployeeName
    ].filter((x) => !!x)

    this._sortByColumn = new SortByColumn(createdOn, true);

    mediator.exportToExcel = () => {
      this._api.export()
    };

  }


  getItemId(item) {
    return item.AuditId;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  get api() {
    return this._api;
  }

  filterChange(filter) {
    super.filterChange(this.adaptFiltersToQuery(filter));
  }

  adaptFiltersToQuery(filter) {
    let adaptedFiltersToSet = [];

    if (isNonEmptyArray(filter) || isNonEmptyArray(this._constantFilters)) {
      const adaptedFilters = filter.concat(this._constantFilters || []).map((x) => {
        if (isNonEmptyArray(x.filterValue) && x.filterValue.length > 1) {
          return `(${x.filterValue.join(' or ')})`;
        }

        return x.filterValue;
      });

      adaptedFiltersToSet = adaptedFilters;
    }

    return adaptedFiltersToSet;
  }

  loadOnRemote(top, skip, sort, filter) {
    if (!this._meetingId) {
      if (isNonEmptyArray(filter)) {
        let rangeFilter = filter.find((x) => x.column.fieldName === 'ActionTime')
        if (rangeFilter) {
          var range = filter.find((x) => x.column.fieldName === 'ActionTime').filterValue
          this.from = moment(range[0]);
          this.to = range[1] != null ? moment(range[1]) : moment(range[0]).endOf('day');
          filter.remove(rangeFilter)
        }
      }

      this._api.from(this.from.format(ODATA_DATE_TIME_FORMAT));
      this._api.to(this.to.format(ODATA_DATE_TIME_FORMAT));
    }

    this._api.top(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }
    const adaptedFilters = this.adaptFiltersToQuery(filter);
    this._api.filter(adaptedFilters);

    return this._api.get().then((result) => {
      return new GridApiResult(result.map((item) => {
        let merged = { ...item, ...(JSON.parse(item.Meeting)) };
        return merged;
      }),
        top,
        skip,
        null);
    });
  }
}

class DefaultDatePickerLabels extends DatePickerLabels {
  constructor(localize) {
    super();
    this._inputPlaceholder = localize('_ChooseDate_');
    this._selectADate = localize('_ChooseDate_');
    this._pickAStartTime = localize('_StartTime_');
  }

  get inputPlaceholder() {
    return this._inputPlaceholder;
  }

  get selectADate() {
    return this._selectADate;
  }

  get pickAStartTime() {
    return this._pickAStartTime;
  }
}
