import {extractError} from "@tomeravni/easybizy-js-common/errors";
import {
  cleanObj, getSafely,
  isConcreteValue, isNonEmptyArray,
  isNonEmptyObject,
  isNonEmptyString,
  valueOrDefault
} from "@tomeravni/easybizy-js-common/common";
import {isNonZeroNumber} from "@tomeravni/easybizy-js-common/numbers";

const GENERAL_FAQ_KEY = 'general';

(function () {
  angular.module('easybizy.configurations').directive('botProps',
    function (Repository, localize, configuration, confirmService) {
      return {
        restrict: 'E',
        replace: true,
        link: function (scope) {
          scope.validateGeneralActionWrapper = {}; // this is an object to wrap an action, so the form validator can
          scope.validateGeneralAggregator = {}; // this is an object to wrap an action, so the form validator can
          scope.generalBotValues = {};
          scope.isPublicApiEnabled = false;

          const isGiftCardSupportedFieldProps = {
            fieldName: 'isGiftCardPurchaseSupported',
            placeholder: localize.getLocalizedString("_GiftCardPurchaseSupported_"),
            description: localize.getLocalizedString("_PublicApiMustBeEnabled_"),
            type: 'radio',
            values: [
              {name: localize.getLocalizedString("_On_"), value: true},
              {name: localize.getLocalizedString("_Off_"), value: false}
            ]
          };

          Object.defineProperty(scope, 'isGiftCardPurchaseSupported', {
            get: () => scope.generalBotValues.isGiftCardPurchaseSupported?.value === true
          })

          scope.generalBotProps = [isGiftCardSupportedFieldProps];

          scope.validateFaqActionWrapper = {}; // this is an object to wrap an action, so the form validator can
          scope.validateFaqAggregator = {}; // this is an object to wrap an action, so the form validator can
          scope.faqValues = {};

          scope.giftCardActionWrapper = {};
          scope.giftCardValues = {};
          scope.validateGiftCardAggregator = {};

          const defaultFaqValuesByKey = {};
          let currentLoggedInUser;

          Promise.all([
            configuration.loadBotConfigurations(),
            Repository.Entity("OnlineBookingServiceMetadata").query().expand("ServiceMetadatas").get(),
            configuration.loggedInUser,
            Repository.Custom('PublicApi').getToken().catch(() => null)
          ]).then(([botProps, onlineBookingServices, loggedInUser, publicToken]) => {
            scope.generalBotValues = {
              isGiftCardPurchaseSupported: isGiftCardSupportedFieldProps.values.find((x) => x.value === botProps.config.isGiftCardPurchaseSupported)
            }

            currentLoggedInUser = loggedInUser;
            scope.isPublicApiEnabled = publicToken?.status === 'active' && botProps.config?.containsApiCredentials;

            Object.assign(scope.faqValues, valueOrDefault(botProps.config.faqResponses, {}));
            isGiftCardSupportedFieldProps.disabled = valueOrDefault(publicToken?.contextName, '').toLowerCase()
              !== valueOrDefault(loggedInUser?.context?.name, '_').toLowerCase() || !scope.isPublicApiEnabled;

            const {businessType: generalBusinessType, botBusinessType} = loggedInUser;
            const businessType = valueOrDefault(botBusinessType, generalBusinessType);
            scope.generalFields = [];

            const contextName = loggedInUser.context.name.toLowerCase();
            const businessSpecificFaq = getSafely(['faq', 'business_specific', contextName], botProps);
            if (isNonEmptyObject(businessSpecificFaq)) {
              const specificFields = Object.keys(businessSpecificFaq).map((key) => {
                let field = businessSpecificFaq[key];
                let adaptedKey = `faq_business_specific_${contextName}_${key}`;
                scope.faqValues[adaptedKey] = valueOrDefault(scope.faqValues[adaptedKey], field.answer);
                defaultFaqValuesByKey[adaptedKey] = field.answer;

                return {
                  type: 'textarea',
                  fieldName: adaptedKey,
                  icon: "icon icon-magic-add",
                  placeholder: field.question,
                  validation: "{'max-length': 30}"
                }
              });

              scope.generalFields = scope.generalFields.concat(specificFields);
            }

            scope.generalFields = scope.generalFields.concat(Object.keys(botProps.faq[GENERAL_FAQ_KEY]).map((key) => {
              let field = botProps.faq[GENERAL_FAQ_KEY][key];
              let adaptedKey = `faq_general_${key}`;
              scope.faqValues[adaptedKey] = valueOrDefault(scope.faqValues[adaptedKey], field.answer);
              defaultFaqValuesByKey[adaptedKey] = field.answer;

              return {
                type: 'textarea',
                fieldName: adaptedKey,
                icon: "icon icon-magic-add",
                placeholder: field.question,
                validation: "{'max-length': 250}"
              }
            }));

            if (isNonEmptyObject(botProps.faq[businessType])) {
              const specificFields = Object.keys(botProps.faq[businessType]).map((key) => {
                let field = botProps.faq[businessType][key];
                let adaptedKey = `faq_${businessType}_${key}`;
                scope.faqValues[adaptedKey] = valueOrDefault(scope.faqValues[adaptedKey], field.answer);
                defaultFaqValuesByKey[adaptedKey] = field.answer;

                return {
                  type: 'textarea',
                  fieldName: adaptedKey,
                  icon: "icon icon-magic-add",
                  placeholder: field.question,
                  validation: "{'max-length': 30}"
                }
              });

              scope.generalFields = scope.generalFields.concat(specificFields);
            }

            scope.giftCardValues = valueOrDefault(botProps.config.giftCardCustomModifiers, {});
            scope.giftCardFields = []

            const labelHelper = localize.getLocalizedString("_PriceQuoteTitle_");
            const actionHelper = localize.getLocalizedString("_Buttons_");
            const helperTextHelper = localize.getLocalizedString("_HelperText_");

            Object.keys(botProps.giftCardDefaults).forEach((screen) => {
              Object.keys(botProps.giftCardDefaults[screen]).forEach((key) => {
                const adaptedKey = `${screen}_${key}`;
                const isHelperText = key.includes('HelperText');
                const isLabel = key.includes('Label');
                const isAction = key.includes('action');

                const fieldLength = isHelperText ? 30 : 20;
                const isTextArea = key.includes('Description');
                let placeholder = botProps.giftCardDefaults[screen][key];
                let label = placeholder;

                if (isAction) {
                  label += ` (${actionHelper})`;
                } else if (isLabel) {
                  label += ` (${labelHelper})`;
                } else if (isHelperText) {
                  label += ` (${helperTextHelper})`;
                }

                if (key !== 'screen' && isNonEmptyString(placeholder)) {
                  if (!isTextArea) {
                    scope.giftCardFields.push({
                      label,
                      placeholder,
                      fieldName: adaptedKey,
                      icon: "icon icon-gift-card",
                      validation: `{'max-length': ${fieldLength}}`
                    })
                  } else {
                    scope.giftCardFields.push({
                      type: 'textarea',
                      fieldName: adaptedKey,
                      icon: "icon icon-gift-card",
                      placeholder: placeholder,
                      validation: `{'max-length': 250}`
                    })
                  }
                }
              });
            });

          })

          scope.patchGeneralSettings = () => {
            const validationFields = scope.validateGeneralActionWrapper.validate();
            if (isNonEmptyArray(validationFields)) { // is valid form?
              const wrongFieldName = scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
              const localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
              toastr.error(localizedError);
              return;
            }

            Repository.Custom('Configurations').patchBotConfigurations({isGiftCardPurchaseSupported: scope.generalBotValues.isGiftCardPurchaseSupported.value}).then((res) => {
              scope.isSaving = false;
              toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'))
            })
              .catch((err) => {
                scope.isSaving = false;
                toastr.error(extractError(err.data))
              })
          }

          scope.patchGiftCard = () => {
            const validationFields = scope.giftCardActionWrapper.validate();
            if (isNonEmptyArray(validationFields)) { // is valid form?
              const wrongFieldName = scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
              const localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
              toastr.error(localizedError);
              return;
            }

            scope.isSaving = true;

            Repository.Custom('Configurations').patchBotConfigurations({giftCardCustomModifiers: cleanObj(scope.giftCardValues)}).then((res) => {
              scope.isSaving = false;
              toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'))
            })
              .catch((err) => {
                scope.isSaving = false;
                toastr.error(extractError(err.data))
              })
          }

          scope.patchFaq = () => {
            const validationFields = scope.validateFaqActionWrapper.validate();
            if (isNonEmptyArray(validationFields)) { // is valid form?
              const wrongFieldName = scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
              const localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
              toastr.error(localizedError);
              return;
            }

            const adaptedFaqValues = Object.keys(scope.faqValues).reduce((acc, key) => {
              const value = scope.faqValues[key];
              if (isNonEmptyString(value) && value !== defaultFaqValuesByKey[key]) {
                acc[key] = value;
              }

              return acc;
            }, {});

            scope.isSaving = true;

            Repository.Custom('Configurations').patchBotConfigurations({faqResponses: cleanObj(adaptedFaqValues)}).then((res) => {
              scope.isSaving = false;
              toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'))
            })
              .catch((err) => {
                scope.isSaving = false;
                toastr.error(extractError(err.data))
              })
          }

          scope.enableApi = () => {
            const wrapWithCatch = (promise) => {
              return promise.catch((err) => {
                scope.enablingApi = false;
                toastr.error(extractError(err.data))
              })
            }


            confirmService.confirm(localize.getLocalizedString('_EnableApi_'),
              localize.getLocalizedString('_EnableApiDisclaimer_'), () => {
                scope.enablingApi = true;
                wrapWithCatch(Repository.Custom('PublicApi').enable(currentLoggedInUser.context.id, currentLoggedInUser.context.name)).then((res) => {
                  wrapWithCatch(Repository.Custom('PublicApi').getToken()).then((tokenRes) => {
                    wrapWithCatch(Repository.Custom('Configurations').patchBotConfigurations({
                      publicApiCredentials: {
                        'x-api-key': tokenRes['x-api-key'],
                        'x-api-secret': tokenRes['x-api-secret']
                      }
                    })).then(() => {
                      scope.enablingApi = false;
                      scope.isPublicApiEnabled = true;
                      isGiftCardSupportedFieldProps.disabled = false;
                    });
                  });
                })
              }, () => {
              });
          }


        },
        template: require('./bot.props.directive.tpl.html')
      };
    });
}());
