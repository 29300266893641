import './authorize.user.modal.less'

angular.module('easybizy.easybizyModalViewsModel')
  .factory('authenticationService', ['$modal', '$q', function ($modal, $q) {
    return {
      isAuthenticated: isAuthenticated,
      authenticate: authenticate
    };

    function isAuthenticated() {
      return $.cookie('authenticated') == "true";
    }

    function authenticate() {
      var deferred = $q.defer();
      var opts = {
        backdrop: 'static',
        keyboard: false,
        backdropClick: false,
        template: require('./authorize.user.modal.tpl.html'),
        controller: 'AuthorizeUserController',
        resolve: {
          saveAction: function () {
            return function (newOrUpdatedMembership, closeDialogDelegate) {
            };
          },
          promise: function () {
            return deferred;
          }
        }
      };

      $modal.open(opts);
      return deferred.promise;
    }
  }]);


angular.module('easybizy.easybizyModalViewsModel').controller('AuthorizeUserController',
  function ($scope, $modalInstance, saveAction, promise, $timeout, localize, Repository) {
    $scope.passwordWrapper = {};
    $scope.passwordWrapper.password = "";
    $scope.testIt = function () {

    };
    $scope.continue = function () {
      Repository.Custom("Configurations").authorize($scope.passwordWrapper.password)
        .then(function () {
          $modalInstance.close();
          promise.resolve({});
        })
        .catch(function () {
          toastr.error(localize.getLocalizedString("_WrongPassword_"));
        });
    };

    $scope.cancel = function () {
      $modalInstance.close();
      promise.reject({});

    }
  });
