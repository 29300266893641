import './circle.material.loader.less';

angular.module('easybizy.common.common-controls').directive('circleMaterialLoader', function () {
  return {
    restrict: 'E',
    replace: true,
    compile: function (telement) {
      const defaultSize = 22;
      const viewBox = `0 0 ${ defaultSize } ${ defaultSize }`;
      const size = `${ defaultSize - 1 }px`;
      const stroke = defaultSize / 11;
      const c = defaultSize / 2;
      const r = defaultSize * 0.45;


      const elementString = `
        <svg class="spinner" width="${ size }" height="${ size }" viewBox="${ viewBox }" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="${ stroke }" stroke-viewBox="round"
           cx="${ c }" cy="${ c }" r="${ r }"></circle>
        </svg>
      `;


      $(telement).append(elementString)
      return () => {
      }
    },
    template: `<div class="material-progress-circular">

               </div>`
  };
});
