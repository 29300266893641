import './manually.shift.modal.less'
import { HOURS_AND_MINUTES_FORMAT, ODATA_DATE_TIME_FORMAT } from "../../constants";

angular.module('easybizy.easybizyModalViewsModel').controller('ManuallyShiftModalController',
  function ($scope, $modalInstance, saveAction, localize, Repository, parameters, $q) {

    $scope.shift = {};
    $scope.shift.startTime = parameters.checkedInTime !== null ?
      moment(parameters.checkedInTime).format(HOURS_AND_MINUTES_FORMAT) : moment().format(HOURS_AND_MINUTES_FORMAT);
    $scope.shift.modifyEndTime = false;
    $scope.shift.endTime = moment().format(HOURS_AND_MINUTES_FORMAT);
    $scope.wasEndTimeModified = false;

    var watchEndTime = $scope.$watch('shift.endTime', function (newVal, oldVal) {
      if (newVal !== oldVal) {
        $scope.wasEndTimeModified = true;
        watchEndTime();
      }
    });

    function getDateTimeFromHour(selectedHour) {
      var split = selectedHour.split(':');
      var returnDateTime = moment().hour(split[0]).minute(split[1]);
      return returnDateTime.format(ODATA_DATE_TIME_FORMAT);
    }

    $scope.dynamicStartTimeMax = function () {
      return $scope.shift.endTime;
    };

    $scope.dynamicEndTimeMax = function () {
      return moment().format(HOURS_AND_MINUTES_FORMAT);
    };

    $scope.dynamicEndTimeMin = function () {
      if ($scope.shift.startTime < moment().format(HOURS_AND_MINUTES_FORMAT)) {
        return $scope.shift.startTime;
      } else {
        return moment().format(HOURS_AND_MINUTES_FORMAT);
      }
    };

    $scope.save = function () {
      $scope.isSaving = true;
      var deferred = $q.defer();

      if ($scope.shift.modifyEndTime) {
        saveAction(getDateTimeFromHour($scope.shift.startTime), getDateTimeFromHour($scope.shift.endTime), deferred);
      } else {
        saveAction(getDateTimeFromHour($scope.shift.startTime), null, deferred);
      }

      deferred.promise.catch(function () {
        $scope.isSaving = false;
      });
      deferred.promise.then(function () {
        $modalInstance.close();
      });
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  });
