import './customer.visit.history.modal.less'
angular.module('easybizy.easybizyModalViewsModel').controller('CustomerVisitHistoryController',
  function ($scope, customerId, $rootScope, $modalInstance) {

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.mediator = {
      close: $scope.cancel,
      customerId: customerId
    }
  });
