import { HOURS_AND_MINUTES_FORMAT } from "../../constants";

(function () {
  angular.module('easybizy.common.common-controls').directive('shiftRow', ['configuration',
    'localize', function (configuration, localize) {
      var settings = configuration.get();
      var minutesBetween = settings.CalendarSettings.MinimumMeetingLengthInMinutes.Value;

      var priorityOptions = [
        { name: localize.getLocalizedString('_Low_'), value: 0 },
        { name: localize.getLocalizedString('_Medium_'), value: 1 },
        { name: localize.getLocalizedString('_High_'), value: 2 }
      ];

      var approvalOptions = [
        { name: localize.getLocalizedString('_NoApproval_'), value: 0 },
        { name: localize.getLocalizedString('_ForceApproval_'), value: 1 },
        { name: localize.getLocalizedString('_ForceCredit_'), value: 2 },
        { name: localize.getLocalizedString('_ForceApprovalAndCredit'), value: 3 }
      ];

      return {
        restrict: 'E',
        replace: true,
        scope: {
          minStartTime: '=',
          maxEndTime: '=',
          priority: '=',
          from: '=',
          to: '=',
          unableRemove: '=',
          approval: '=',
          remove: '&'
        },
        link: function (scope) {
          scope.priorities = priorityOptions.clone();
          scope.approvals = approvalOptions.clone();
          updateRange();
          scope.$watch('maxEndTime', function (newVal, oldVal) {
            if (newVal.toString() !== oldVal.toString()) {
              updateRange();
            }
          });

          Object.defineProperty(scope, 'fromTime', (function () {
            var _value = scope.from, _wrappedValue = null;
            return {
              get: function () {

                if (_wrappedValue && _value.isSameTime(scope.from)) {
                  return _wrappedValue;
                }

                _wrappedValue = getWrappedValueForTime(_value, scope.range.from);
                return _wrappedValue;

              },
              set: function (value) {
                scope.from = _value = value.value;
                _wrappedValue = null;
              }
            }
          }()));

          Object.defineProperty(scope, 'toTime', (function () {
            var _value = scope.to, _wrappedValue = null;
            return {
              get: function () {
                if (_wrappedValue && _value.isSameTime(scope.to)) {
                  return _wrappedValue;
                }

                _wrappedValue = getWrappedValueForTime(_value, scope.range.to);
                return _wrappedValue;
              },
              set: function (value) {
                scope.to = _value = value.value;
                _wrappedValue = null;
              }
            }
          }()));

          Object.defineProperty(scope, 'priorityOption', (function () {
            return {
              get: function () {
                return priorityOptions[scope.priority];
              },
              set: function (value) {
                scope.priority = value.value;
              }
            }
          }()));

          Object.defineProperty(scope, 'approvalOption', (function () {
            return {
              get: function () {
                return approvalOptions[scope.approval || 0];
              },
              set: function (value) {
                scope.approval = value.value;
              }
            }
          }()));

          function updateRange() {
            var globalRange = calculateGlobalRange();
            scope.range = { from: globalRange.clone(), to: globalRange.slice(1) };
          }


          function getWrappedValueForTime(time, list) {
            var targetIndex = Math.floor(Math.abs(moment().grabTime(list[0].value).diff(moment().grabTime(time), 'minutes')) / minutesBetween); // target index.
            return list[Math.min(targetIndex, list.length - 1)];
          }

          function calculateGlobalRange() {
            var itr = scope.minStartTime.clone(), toReturn = [];
            // We want to make sure it handles the 24:00 time.
            var safeMaxEndTime = scope.maxEndTime.clone(); //.add(1, 'minute');
            if (safeMaxEndTime.isSame(scope.maxEndTime, 'date')) {
              safeMaxEndTime = safeMaxEndTime.add(1, 'day');
            }

            while (itr.isBefore(safeMaxEndTime)) {
              var toPush = { name: itr.format(HOURS_AND_MINUTES_FORMAT), value: itr.clone() };
              toReturn.push(toPush);
              itr = itr.add(minutesBetween, 'minutes');
            }

            return toReturn;
          }

        },
        template: '<div class="shift-row">\
                      <combobox values="range.from" selected="fromTime" lazy="true" \
                             list-mode="true"></combobox>\
                      <combobox values="range.to" selected="toTime" lazy="true" \
                             list-mode="true"></combobox> \
                      <combobox values="priorities" selected="priorityOption" lazy="true" \
                             list-mode="true"></combobox> \
                      <combobox values="approvals" selected="approvalOption" lazy="true"\
                              list-mode="true"></combobox>\
                      <submit-button ng-if="unableRemove" click="remove()" class="warning-submit" text="_Remove_"></submit-button> \
                             </div>'
      }
    }]);

}());


