import './add-customer.less';
import {PATH_TO_API} from "../../constants";
import {isNonEmptyString} from "@tomeravni/easybizy-js-common/common";

angular.module('easybizy.easybizyModalViewsModel').controller('AddCustomerModalController',
  function ($scope, $modalInstance, saveAction, $timeout, $http, localize, printerMediator, inlineForms, parameters) {
    $scope.customer = {};
    $scope.customer.FirstName = "";
    $scope.customer.LastName = "";
    $scope.customer.EnableFacebookSending = true;
    $scope.customer.EnablePromotionSending = true;
    $scope.customer.EnableSmsSending = true;
    $scope.customer.EnableMailSending = true;

    if (isNonEmptyString(parameters.fullName)) {
      const split = parameters.fullName.split(' ');
      $scope.customer.FirstName = split[0];
      $scope.customer.LastName = split.slice(1).join(' ');
    }

    if (isNonEmptyString(parameters.mobileFirst)) {
      $scope.customer.MobileFirst = parameters.mobileFirst;
    }


    $scope.possibleImages = {};
    $scope.lastSearchedName = "";

    $scope.dateTime = new Date();
    $scope.isSubmittingSms = false;
    $scope.isSubmittingLead = false;
    //$scope.customer.Gender = 'Female';
    $scope.dialControls = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '<', '0', 'C'];
    $scope.smsValue = {};
    $scope.smsValue.value = "";
    // New customer validation fields
    $scope.newCustomerFieldsToValidate = {}; // just initialize fields to validate.
    $scope.validateActionWrapper = {}; // this is an object to wrap an action, so the form validator can
    // add a function pointer to be called before saving customer.
    // SMS validation
    $scope.smsFieldValidator = {};
    $scope.smsValidateAction = {};

    $scope.CustomerPhoneNumberPlaceHolder = "_CustomerPhoneNumber_";

    $scope.dialClicked = function (numberAction) {
      var number = numberAction.control;
      var currentValue = $scope.smsValue.value;
      if (number === '<') {
        $scope.smsValue.value = currentValue.substring(0, currentValue.length - 1);
      } else if (number === 'C') {
        $scope.smsValue.value = "";
      } else {
        $scope.smsValue.value += number;
      }
    };
    var lastSearchedText = "";

    $scope.manipulatedImage = [];
    $scope.$watchCollection('possibleImages', function (newVal) {
      if (newVal) {
        $scope.manipulatedImage.length = 0;
        for (var key in $scope.possibleImages) {
          $scope.manipulatedImage.push($scope.possibleImages[key]);
        }
      }
    });

    $scope.selectImage = function (value) {
      value.isSelected = !value.isSelected;
    };

    $scope.fields = [
      {
        fieldName: 'FirstName', icon: "icon icon-clients-ol", placeholder: localize.getLocalizedString("_FirstName_"),
        validation: "{'required': true, 'max-length': 20}"
      },
      {
        fieldName: 'LastName', icon: "icon icon-client", placeholder: localize.getLocalizedString("_LastName_"),
        validation: "{'required': true, 'max-length': 20}"
      },
      {
        fieldName: 'MobileFirst', icon: "icon icon-sms", placeholder: localize.getLocalizedString("_Phone_"),
        validation: "{'max-length': 10, 'min-length': 9, 'digits': true}"
      },
      {
        fieldName: 'EmailAddress', icon: "icon icon-mail-outline", placeholder: localize.getLocalizedString("_Email_"),
        validation: "{'email': true}"
      },
      {
        fieldName: 'DateOfBirth', icon: "icon icon-gift", placeholder: localize.getLocalizedString("_DateOfBirth_"),
        validation: "{'date': true, 'birthday': true}", type: 'date'
      },
      {
        fieldName: 'Gender', icon: "glyphicon glyphicon-glass", placeholder: localize.getLocalizedString("_Gender_"),
        validation: "{'required': true}", type: 'radio', values: [
          {name: localize.getLocalizedString("_Female_"), value: 'Female'},
          {name: localize.getLocalizedString("_Male_"), value: 'Male'}
        ]
      }
    ];

    $scope.hasImages = function () {
      return Object.keys($scope.possibleImages).length > 0 && getFullCustomerName();
    };

    $scope.customImageSorter = function (image) {
      return image.order;
    };

    $scope.printNewCustomerForm = function () {
      inlineForms.newCustomerForm().then(function (template) {
        printerMediator.print(template);
      });
    };

    $scope.sendSms = function () {
      var validationFields = $scope.smsValidateAction.validate();
      if (validationFields.length != 0) { // is valid form?
        toastr.error(localize.getLocalizedString("_InvalidPhoneNumber_"));
        return;
      }

      $scope.isSubmittingSms = true;
      $http({
        url: baseUrl + PATH_TO_API + 'FbSmsSender?mobile=' + $scope.smsValue.value,
        method: "POST"
      }).then(function ({status}) {
        if (status === 200) {
          toastr.success(localize.getLocalizedString("_MessageSent_"));
        } else {
          toastr.error(localize.getLocalizedString("_ErrorSendingSmsToCustomer_", data));
        }

        $scope.isSubmittingSms = false;
        $modalInstance.close();
      }).catch(function (data) {
        toastr.error(localize.getLocalizedString("_ErrorSendingSmsToCustomer_", data));
        $scope.isSubmittingSms = false;
        $modalInstance.close();
      });
    };

    // saveAction is resolved on the toolbar so the modal can have a result when the dialog changed.
    $scope.save = function () {
      var validationFields = $scope.validateActionWrapper.validate();
      if (validationFields.length != 0) { // is valid form?
        var wrongFieldName = $scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
        var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
        toastr.error(localizedError);
        return;
      }

      var customerToPost = $.extend({}, $scope.customer);
      customerToPost.Gender = customerToPost.Gender.value;
      if ($scope.hasImages()) {
        customerToPost.Images = [];
        angular.forEach($scope.manipulatedImage, function (selectedImage) {
          if (selectedImage.isSelected) {
            var image = {};
            image.FilePath = selectedImage.url;
            customerToPost.Images.push(image);
            if (selectedImage.facebookId) {
              customerToPost.FacebookUserId = selectedImage.facebookId;
            }
          }

        });
      }


      $scope.isSubmittingLead = true;
      saveAction(customerToPost).then((/*customer*/) => {
        toastr.success(localize.getLocalizedString("_CustomerCreatedSuccessfully_"));
        $scope.isSubmittingLead = false;
        $modalInstance.close(customerToPost);
      }).catch(err => {
        toastr.error(localize.getLocalizedString("_ErrorSavingCustomer_") + " - " + (err.Message || err));
        $scope.isSubmittingLead = false;
      })
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.$on('$locationChangeStart', function (scope, next, current) {
      $modalInstance.dismiss('cancel');
    });

    $scope.today = function () {
      $scope.dt = new Date();
    };
    $scope.today();

    $scope.showWeeks = true;
    $scope.toggleWeeks = function () {
      $scope.showWeeks = !$scope.showWeeks;
    };

    $scope.clear = function () {
      $scope.dt = null;
    };

    // Disable weekend selection
    $scope.disabled = function (date, mode) {
      return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
    };

    $scope.toggleMin = function () {
      $scope.minDate = ($scope.minDate) ? null : new Date();
    };


    $scope.open = function () {
      $timeout(function () {
        $scope.opened = true;
        $scope.toggleMin();
      });
    };

    $scope.dateOptions = {
      'year-format': "'yy'",
      'starting-day': 1
    };

    function getFullCustomerName() {
      var toReturn = null;
      if ($scope.customer.FirstName.length > 0 && $scope.customer.LastName.length > 0) {
        toReturn = $scope.customer.FirstName + " " + $scope.customer.LastName;
      }

      return toReturn;
    }


  });
