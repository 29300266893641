import './tile.directive.less';
import './tiles.directive.less';

angular.module('easybizy.common.tiles', ['easybizy.filters',
  'easybizy.common.common-services', 'localization'])
  .directive('tiles', function () {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: {
        positionMode: '@position',
        sortMode: '=',
        onSortChangeDelegate: '='
      },
      controller: ['$scope', function tilesController($scope) {
        $scope.innerTiles = [];
        var that = this;
        this.addTile = function (tile) {
          $scope.innerTiles.push(tile);
          updateTilesSize();

          /* This method goes through the current tiles and calculates the max number of tiles on both row and column */
          function updateTilesSize() {
            var rowsWidth = [];
            var columnHeight = [];

            angular.forEach($scope.innerTiles, function (tile) {
              rowsWidth[tile.row] = rowsWidth[tile.row] ? rowsWidth[tile.row] + tile.tileWidth : tile.tileWidth;
              columnHeight[tile.column] = columnHeight[tile.column] ? columnHeight[tile.column] + tile.tileHeight :
                tile.tileHeight;
            });

            var maxWidth = 0;
            var maxHeight = 0;
            for (var val in rowsWidth) {
              if (rowsWidth[val] > maxWidth) {
                maxWidth = rowsWidth[val];
              }
            }

            for (var val in columnHeight) {
              if (columnHeight[val] > maxHeight) {
                maxHeight = columnHeight[val];
              }
            }

            that.tilesWidth = maxWidth;
            that.tilesHeight = maxHeight;
          }
        };

        Object.defineProperty(this, 'positionMode', {
          get: function () {
            return $scope.positionMode;
          }
        })

      }],
      require: '^tiles',
      template: require('./tiles.directive.html'),
      link: function (scope, element, attrs) {
        scope.positionMode = attrs.position;
        var wasSortModeInitilized = false;
        scope.$watch('sortMode', function (newVal) {
          if (!newVal && wasSortModeInitilized) {
            element.find('.tiles-concrete-container').sortable('disable');
            element.find('.tile:first-of-type').removeClass('swing');
          } else if (wasSortModeInitilized) {
            element.find('.tile:first-of-type').addClass('swing');
            element.find('.tiles-concrete-container').sortable('enable');
          } else if (newVal) {
            wasSortModeInitilized = true;
            var tilesContainer = element.find('.tiles-concrete-container');
            tilesContainer.sortable({
              update: (e, ui) => {
                if (scope.onSortChangeDelegate) {
                  scope.onSortChangeDelegate(Array.prototype.slice.call(e.target.querySelectorAll('div.tile')).map((el) => angular.element(el).scope()));
                }
              }
            });
            tilesContainer.sortable("option", "disabled", false);
            tilesContainer.disableSelection();
            element.find('.tile:first-of-type').addClass('swing');
          }
        });
      }
    };
  })
  .directive('tile', function () {
    return {
      restrict: 'E',
      transclude: true,
      replace: true,
      require: '^tiles',
      link: function (scope, element, attrs, tilesCtrl) {
        var row = parseInt(attrs.row);
        var column = parseInt(attrs.column);
        var tileWidth = parseInt(attrs.tileWidth);
        var tileHeight = parseInt(attrs.tileHeight);

        tilesCtrl.addTile({
          el: element,
          row: row,
          column: column,
          tileWidth: tileWidth,
          tileHeight: tileHeight
        });

        if (tilesCtrl.positionMode == 'absolute') {
          element.addClass('absolute');
          scope.$watch(tilesCtrl.tilesWidth, updateTileDimension);
          scope.$watch(tilesCtrl.tilesHeight, updateTileDimension);
        }

        function updateTileDimension() {
          var eachPartWidth = 100.0 / tilesCtrl.tilesWidth * 1.0;
          var eachPartHeight = 100.0 / tilesCtrl.tilesHeight * 1.0;

          element.css("width", (tileWidth * eachPartWidth) + "%");
          element.css("height", (tileHeight * eachPartHeight) + "%");
          element.css("left", ((column - 1) * eachPartWidth) + "%");
          element.css("top", ((row - 1) * eachPartHeight) + "%");
        }
      },
      template: require('./tile.directive.html')
    };
  });

