<template>
  <div class="vue-time-picker-input" :class="disabled && 'disabled'">
    <div class="vue-time-picker-input__arrow icon icon-dropup" @click="increase">
    </div>
    <input v-mask="'##'" maxlength="2" v-model="internalValue" @blur="adaptModifiedValue" @focus="selectAll"
           ref="input"
           :disabled="disabled"
           autocomplete="off"/>
    <div class="vue-time-picker-input__arrow icon icon-dropdown" @click="decrease">
    </div>
  </div>
</template>

<script>

import { isNumber, valueOrDefault } from "../../../common/common-js-functions/common-js-new";

export default {
  props: ['value', 'min', 'max', 'disabled'],
  data() {
    return {
      internalValue: ''
    }
  },
  watch: {
    value(newValue) {
      if (newValue !== this.internalValue) {
        this.internalValue = this.adaptValueToIncludeZeros(newValue);
      }
    },
  },
  methods: {
    adaptValueToIncludeZeros(val) {
      const adapted = (val || '').toString().trim() || '0';

      if (adapted.length < 2) {
        return `0${ adapted }`;
      }

      return adapted;
    },
    increase() {
      this.modifyValue(1);

    },
    decrease() {
      this.modifyValue(-1);
    },
    modifyValue(addition) {
      let current = parseInt(this.internalValue) + addition;
      this.adaptModifiedValue(current);
      setTimeout(() => {
        this.selectAll();
      });

    },
    adaptModifiedValue(value) {
      let current = isNumber(value) ? value : this.internalValue;
      if (current === this.value) {
        return;
      }

      if (isNumber(this.max) && current > this.max) {
        current = this.min || 0;
      }

      if (isNumber(this.min) && current < this.min) {
        current = this.max || 0;
      }

      this.internalValue = this.adaptValueToIncludeZeros(current);
      this.$emit('change', this.internalValue);
    },
    selectAll(evt) {
      if (this.disabled) {
        return;
      }

      const inputField = valueOrDefault(evt, { target: this.$refs.input }).target;
      inputField.focus();
      inputField.select();
    }
  }

}
</script>

<style lang="less">
@import "../../../../styles/variables";


.vue-time-picker-input {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    width: 30px;
    padding: 5px;
    font-size: 16px;
    border: @tile-border-width solid @tile-border-color;
    border-radius: 3px;
  }

  &__arrow {
    font-size: 12px;
    padding: 2px;
    .CubicAnimation(0.2s, all);

    &:hover {
      cursor: pointer;
      color: @submit-color;
      transform: scale(1.2);
    }
  }

  &.disabled {
    opacity: 0.5;

    .vue-time-picker-input__arrow {
      visibility: hidden;
      .NoTransition();
    }
  }
}
</style>
