import { DATE_DISPLAY_FORMAT } from "../../../constants";

(function () {
  angular.module('easybizy.calendar').factory('calendarColumn', ['holidaysCalendar', 'calendarMetadata',
    function (holidaysCalendar, calendarMetadata) {
      var holidays;
      holidaysCalendar.get().then(function (data) {
        holidays = data;
      }).catch(function (err) {
        toastr(localize.getLocalizedString('_ErrorGettingHolidays_', err.Message));
      });

      var toReturn = {
        Column: Column
      };

      function Column(date, employee, filteredEmployeeId, statusId, shiftsExceptions, room) {
        this.date = date.clone();
        this.formattedDate = date.format(calendarMetadata.formatKeys.columnKey);
        this.label = (employee && employee.EmployeeName) ||
          (room && room.name) ||
          date.format(calendarMetadata.formatKeys.fullDayFormat);
        this.employee = employee;
        this.filteredEmployeeId = filteredEmployeeId;
        this.status = statusId;
        this.exceptions = shiftsExceptions;
        this.room = room;
      }

      Column.prototype = {
        get holiday() {
          return holidays && holidays[this.date.format(DATE_DISPLAY_FORMAT)];
        }
      };

      return toReturn;

    }]);
}());
