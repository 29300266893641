var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-time-picker" },
    [
      _c("TimePickerInput", {
        attrs: { min: 0, max: 23, value: _vm.hours, disabled: _vm.disabled },
        on: { change: _vm.hoursChanged },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "vue-time-picker__split" }, [_vm._v(":")]),
      _vm._v(" "),
      _c("TimePickerInput", {
        attrs: { min: 0, max: 59, value: _vm.minutes, disabled: _vm.disabled },
        on: { change: _vm.minutesChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }