import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT } from "../../../constants";
import './external.accounting.wizard.less'

angular.module('easybizy.common.custom.reports.actions')
  .directive('externalAccountingWizard', [
    'localize', 'Repository', '$timeout', 'printerMediator', function (localize, Repository, $timeout, configuration) {
      return {
        restrict: 'E',
        scope: {
          cancel: '&'
        },
        replace: true,
        template: require('./external.accounting.wizard.tpl.html'),
        link: function (scope, element, attrs) {
          scope.rangeMetadataFrom = {
            fieldName: 'from',
            icon: "icon icon-calendar",
            placeholder: "Start Date",
            validation: "{'required': true}",
            type: 'date'
          };
          scope.rangeMetadataTo = {
            fieldName: 'to',
            icon: "icon icon-calendar",
            placeholder: "End Date",
            validation: "{'required': true}",
            type: 'date'
          };

          scope.sendByMailEmail = {
            fieldName: 'EmailAddress',
            icon: "icon icon-mail-outline",
            placeholder: localize.getLocalizedString("_Email_"),
            validation: "{'email': true, 'required': true}"
          };

          scope.generationMethod = [
            {
              name: localize.getLocalizedString("_Download_"), value: 'download', isDisabled: function () {
                return false;
              }
            },
            {
              name: localize.getLocalizedString("_SendByEmail_"), value: 'send', isDisabled: function () {
                return scope.emailValidatorAction.validate().length !== 0;
              }
            }
          ];

          scope.exportMethod = [
            {
              name: localize.getLocalizedString("_FlexibleMethod_"), value: 'flexible', isDisabled: function () {
                return false;
              }
            },
            {
              name: localize.getLocalizedString("_ShortMethod_"), value: 'short', isDisabled: function () {
                return false;
              }
            },
            {
              name: localize.getLocalizedString("_DetailedMethod_"), value: 'detailed', isDisabled: function () {
                return false;
              }
            }
          ];

          scope.selectedGenerationMethod = { value: scope.generationMethod[0] };
          scope.selectedDetailLevel = { value: scope.exportMethod[0] };

          scope.selectedRange = {};
          var now = moment();
          scope.selectedRange.from = moment().date(1).month(now.month() - 1);
          scope.selectedRange.to = moment().date(1).month(now.month()).subtract(1, 'days');
          scope.selectedRange.from = scope.selectedRange.from.format(DATE_DISPLAY_FORMAT);
          scope.selectedRange.to = scope.selectedRange.to.format(DATE_DISPLAY_FORMAT);

          scope.sendByMailWrapper = {};
          scope.sendByMailWrapper.sendByMail = false;

          scope.$watch("sendByMailWrapper.sendByMail", function (newVal) {
            if (newVal) {
              scope.sendText = localize.getLocalizedString("_SendByMail_");
            } else {
              scope.sendText = localize.getLocalizedString("_Print_");
            }
          });

          Repository.Custom("Configurations").mailForZByDate().then(function (result) {
            scope.sendByMailWrapper.EmailAddress = result.Message;
          });

          scope.emailValidator = {};
          scope.emailValidatorAction = {};
          scope.isGeneratingReportWrapper = {};
          scope.isGeneratingReportWrapper.inProgress = false;

          scope.sendOrDownload = function () {
            if (!moment(scope.selectedRange.from, DATE_DISPLAY_FORMAT).isBefore(moment(scope.selectedRange.to, DATE_DISPLAY_FORMAT))) {
              toastr.error(localize.getLocalizedString("_DateRangeIsIncorrect_"));
              return;
            }

            scope.isGeneratingReportWrapper.inProgress = true;

            var method = scope.selectedGenerationMethod.value.value;
            var exportMethod = scope.selectedDetailLevel.value.value;

            var from = moment(scope.selectedRange.from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
            var to = moment(scope.selectedRange.to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
            switch (method) {
              case "download":
                Repository.Custom("ReportsRepository").downloadHashavshevet(from, to, exportMethod);
                scope.isGeneratingReportWrapper.inProgress = false;
                scope.$digestIfNeeded();
                break;
              case "send":
                Repository.Custom("ReportsRepository").sendHashavshevet(from, to, exportMethod, scope.sendByMailWrapper.EmailAddress)
                  .then(function (result) {
                    if (result) {
                      toastr.success(localize.getLocalizedString("_ReportSent_") + ".");
                      scope.cancel();
                    } else {
                      toastr.error(localize.getLocalizedString("_ErrorProducing_", localize.getLocalizedString("_Reports_")) + " - " + err.ExceptionMessage);
                    }

                    scope.isGeneratingReportWrapper.inProgress = false;
                  })
                  .catch(function (err) {
                    toastr.error(localize.getLocalizedString("_ErrorProducing_", localize.getLocalizedString("_Reports_")) + " - " + err.ExceptionMessage);
                    scope.isGeneratingReportWrapper.inProgress = false;
                  })

                break;
              default:
            }
          };
        }
      };
    }]);
