import './pick.punchcard.less';

angular.module('easybizy.easybizyModalViewsModel').controller('PickPunchcardController',
  function ($scope, $modalInstance, saveAction, employees, subscription) {
    $scope.employees = employees;
    $scope.subscription = subscription;
    $scope.selectedEmployeeWrapper = { selected: employees[0] };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.save = function () {
      var usagesToCreate = [{
        SubscriptionId: subscription.SubscriptionId,
        EmployeeId: $scope.selectedEmployeeWrapper.selected.value,
        ItemId: subscription.ItemId,
        ItemType: subscription.ItemType,
        Quantity: 1
      }];

      $scope.isSaving = true;
      saveAction(usagesToCreate, $scope.cancel);
    };
  });
