import './create.punchcard.customer.less'
import { DATE_DISPLAY_FORMAT } from "../../constants";

angular.module('easybizy.easybizyModalViewsModel').controller('CreatePunchcardCustomerController',
  function ($scope, $modalInstance, saveAction, localize, Repository, configuration) {

    const configurations = configuration.get();
    const defaultSubscriptionExpirationDateInMonths = configurations.ProductsAndServicesSettings.DefaultSubscriptionExpirationDateInMonths?.Value ?? 12;
    $scope.considerSubscriptionsExpirationDate = configurations.ProductsAndServicesSettings.ConsiderSubscriptionsExpirationDate;

    Repository.Entity("Employee").query().filter("IsDeactiveted eq false").get().then(function (employees) {
      $scope.employees = employees.results.map(function (employee) {
        return {
          name: employee.FirstName + " " + employee.LastName,
          value: employee.EmployeeId
        };
      });

      $scope.selectedEmployeeWrapper = { selected: employees[0] };
    }).catch(function (err) {
      toastr.error('error loading employees' + err.Message);
    });

    $scope.services = [
      { name: localize.getLocalizedString("_SelectAService_"), value: null, defaultValue: true }
    ];

    $scope.punchcard = { service: $scope.services[0], totalQuntity: 1, usages: [] ,expiration: moment().add(defaultSubscriptionExpirationDateInMonths, 'M').format(DATE_DISPLAY_FORMAT)};
    $scope.servicesAPI = Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory(true).entity("service");

    $scope.addUsage = function () {
      $scope.punchcard.usages.push({
        employee: $scope.employees[0],
        date: moment().format(DATE_DISPLAY_FORMAT)
      });
    };

    $scope.expirationDate = {
      fieldName: 'expiration',
      icon: "icon icon-calendar",
      placeholder: localize.getLocalizedString("_ExpirationDate_"),
      validation: "{'date': true, 'required': true}",
      yearRange: "0:+5",
      type: 'date'
    };

    $scope.usageDate = {
      fieldName: 'date',
      icon: "icon icon-calendar",
      placeholder: localize.getLocalizedString('_DateOfUsage_'),
      validation: "{'required': true}",
      yearRange: "-5:+1",
      type: 'date'
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.another = function () {
      $scope.punchcard.service = $scope.services[0];
      $scope.isSaving = false;
    };

    $scope.removeUsage = function (usage) {
      $scope.punchcard.usages.remove(usage);
    };

    $scope.save = function () {
      $scope.isSaving = true;
      saveAction($scope.punchcard, $scope.cancel);
    };

    $scope.saveAndAddAnother = function () {
      $scope.isSaving = true;
      saveAction($scope.punchcard, $scope.another);
    };
  });
