import './entity.custom.note.less'
import {DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT} from "../../constants";
import {DelayedFunction} from "../../general/helpers";


angular.module('easybizy.easybizyModalViewsModel').controller('EntityCustomNoteController',
  function (
    $scope, $modalInstance, saveAction, localize, parameters, Repository, $timeout, $compile,
    employees, printerMediator
  ) {
    var kStorageKey = 'customer-details-note';
    $scope.cancel = function () {
      $.sessionStorage.remove(kStorageKey);
      $modalInstance.dismiss('cancel');
    };

    $scope.timesToRemind = [
      {name: localize.getLocalizedString("_In1Hour_"), value: 60},
      {name: localize.getLocalizedString("_In2Hours_"), value: 120},
      {name: localize.getLocalizedString("_In1Day_"), value: 24 * 60},
      {name: localize.getLocalizedString("_In2Days_"), value: 24 * 2 * 60},
      {name: localize.getLocalizedString("_In1Week_"), value: 24 * 7 * 60},
      {
        name: localize.getLocalizedString("_In1Month_"),
        value: 24 * 60 * moment().add(1, 'month').diff(moment(), 'days')
      },
      {name: localize.getLocalizedString("_ManuallyChargeCredit_"), value: null}
    ];

    $scope.reminder = {};

    $scope.remindMe = parameters.reminderType === 'me'; // this indicates the reminder should be to me and not to the
    // customer.


    const storageData = !parameters.forceNew && ($.sessionStorage.isSet(kStorageKey) && $.sessionStorage.get(kStorageKey))
    const savedInMemoryContent = ((parameters.noteToEdit && parameters.noteToEdit.Content) || storageData);

    $scope.reminder.content = "";
    if (savedInMemoryContent) {
      if (savedInMemoryContent.indexOf('ql-editor') >= 0) {
        $scope.reminder.content = savedInMemoryContent;
      } else {
        var jContent = $('<div>' + savedInMemoryContent + '</div>');
        jContent.find('*').addClass('ql-align-right ql-direction-rtl');
        $scope.reminder.content = jContent.html();
      }

      $scope.reminder.text = $("<div>" + savedInMemoryContent + "</div>").text()
    } else {
      $.sessionStorage.remove(kStorageKey);
    }

    $scope.reminder.isToRemind = false;
    $scope.reminder.fixValue = $scope.timesToRemind[2];
    $scope.employees = [];
    $scope.selectedEmployee = {};
    employees.resolve($scope.employees, $scope.selectedEmployee);

    $scope.saveButtonText = localize.getLocalizedString("_Save_");

    $scope.manualReminderField = {
      fieldName: 'manualDateToRemind',
      icon: "icon icon-calendar",
      placeholder: localize.getLocalizedString("_ChooseDate_"),
      validation: "{'date': true, 'required': true}",
      yearRange: "-0:+1",
      type: 'date'
    };

    $scope.reminder.reminderSms = "";
    $scope.smsContentField = {
      fieldName: 'reminderSms', icon: "icon icon-sms", placeholder: localize.getLocalizedString("_SmsContent_"),
      validation: "{'required': true, 'max-length': 70}"
    };

    $scope.reminder.manualDateToRemind = moment().add(1, 'd').format(DATE_DISPLAY_FORMAT);
    $scope.invalidContentWrapper = {};

    $scope.sendByEmailTo = parameters.email ? parameters.email() : null;


    var saveToDiskNoteDelegate = new DelayedFunction(function () {
      $.sessionStorage.set(kStorageKey, $scope.reminder.content);
    }, 500);


    $scope.$watch('reminder.content', function (newVal) {
      if ($scope.reminder.reminderSms.length === 0 && newVal.length >= 70) {
        $scope.reminder.reminderSms = $("<div>" + newVal + "</div>").text().substr(0, 65);
      }


      saveToDiskNoteDelegate.set(newVal);

    });

    $scope.addNewCategoryAction = function (category) {
    };

    $scope.addImageToEditorWrapper = {};
    $scope.addImage = function (image) {
      if (image.disabled) {
        return;
      }

      image.disabled = true;
      Repository.Custom('General').toBase64(image.Path).then(function (base64String) {
        $scope.addImageToEditorWrapper.addImage(base64String);
        image.disabled = false;

      }).catch(function (err) {
        image.disabled = false;
        toastr.error(localize.getLocalizedString("_CouldntAddImage_"));
      })
    };

    $scope.deleteImage = function ($event, image) {
      $event.stopPropagation();
      $event.preventDefault();

      if (image.disabled) {
        return;
      }

      var currentImageIndex = $scope.appImages.indexOf(image);
      $scope.appImages.splice(currentImageIndex, 1);

      Repository.Entity("Image").remove(image.ImageId)
        .catch(function (e) {
          $scope.appImages.splice(currentImageIndex, 0, image);
          toastr.error(localize.getLocalizedString("_ErrorRemovingImage"));
        });
    };

    $scope.insertImageWrapper = {};


    $scope.infoCategories = [
      {name: localize.getLocalizedString("_GeneralCategory_"), value: null}
    ];
    $scope.selectedInfoCategoriesWrapper = {};
    $scope.selectedInfoCategoriesWrapper.selected = $scope.infoCategories[0];

    Repository.Custom("CustomTimelineItem").getCategories()
      .then(function (categories) {
        angular.forEach(categories, function (category) {
          var categoryItem = $scope.infoCategories[0];
          if (categoryItem.name != category) {
            categoryItem = {name: category, value: category};
            $scope.infoCategories.push(categoryItem);
          }

          if (parameters.noteToEdit && parameters.noteToEdit.Category == category) {
            $scope.selectedInfoCategoriesWrapper.selected = categoryItem;
          }
        });
        $scope.$digestIfNeeded();
      }).catch(function (err) {
      toastr.error(localize.getLocalizedString("_ErrorLoadingCutomItemCategories_"), err);
    });

    if (parameters.noteToEdit) {
      $scope.saveButtonText = localize.getLocalizedString("_Update_");
      var existingNote = parameters.noteToEdit;
      if (existingNote.Remainder) {
        $scope.reminder.isToRemind = true;
        $scope.reminder.reminderSms = existingNote.RemainderContent;
        $scope.reminder.fixValue = $scope.timesToRemind[$scope.timesToRemind.length - 1];
        $scope.reminder.manualDateToRemind = moment(existingNote.Remainder).format(DATE_DISPLAY_FORMAT);
      }
    }

    $scope.appImages = [];

    function loadImagesFromApp() {
      Repository.Entity("Image", true).query().filter("IsFromMobileApp eq true and IsTemporary eq true and IsForImagePicker eq true").get()
        .then(function (data) {
          data.results.forEach(function (img) {
            $scope.appImages.push({
              id: img.ImageId,
              ImageId: img.ImageId,
              name: img.ImageName,
              Path: baseUrl + img.FilePath
            });

            $scope.hasImagesFromApp = $scope.appImages.length > 0;
          });
        })
        .catch(function (err) {
          toastr.error(localize.getLocalizedString("_CouldntGetImagesFromApp_"));
        });
    }

    loadImagesFromApp();

    $scope.save = function (lock) {
      $scope.isSavingCustomNote = true;
      $.sessionStorage.set(kStorageKey, $scope.reminder.content);
      saveAction(generateCustomNote(lock)).then(function () {
        $.sessionStorage.remove(kStorageKey);
      }).finally(function () {
        $scope.isSavingCustomNote = false;
        $modalInstance.dismiss('cancel');
      });
    };

    $scope.print = function () {
      printerMediator.print($scope.reminder.content);
    }

    $scope.sendByEmail = function () {
      Repository.Custom("CustomTimelineItem").sendEmail({
        To: $scope.sendByEmailTo,
        Content: $scope.reminder.content
      }).then(function (templates) {
        toastr.success(localize.getLocalizedString('_EmailSent_'));
      }).catch(function (err) {
        toastr.error(localize.getLocalizedString('_ErrorSendingEmail_'));
      });
    }

    /********************************** TEMPLATE **********************************/
    $scope.templates = [
      {name: localize.getLocalizedString("_PickATemplate_"), value: null}
    ];

    $scope.selectedTemplatesWrapper = {selected: $scope.templates[0]};

    Repository.Custom("CustomTimelineItem").getTemplates()
      .then(function (templates) {
        templates.value.forEach(function (template) {
          $scope.templates.push(templateItemToComboboxItem(template));
        });
      }).catch(function (err) {
      toastr.error(localize.getLocalizedString('_ErrorLoadingTemplates_'));
    });

    $scope.addNewTemplateAction = function () {
      $scope.mode = 'templateCreation';

    };

    $scope.manuallyTemplateSelected = function (template) {
      if (!template.value) {
        return;
      }

      $scope.editorDelegates.paste(template.value);
    };

    $scope.editorDelegates = {};

    $scope.createTemplate = function () {
      var templateToCreate = {Name: $scope.selectedTemplatesWrapper.selected.name, Content: $scope.reminder.content};
      $scope.isSavingTemplateWrapper = {isSaving: true};
      Repository.Custom("CustomTimelineItem").createTemplate(templateToCreate)
        .then(function (savedTemplate) {
          $scope.$evalAsync(function () {
            var newComboItem = templateItemToComboboxItem(savedTemplate);
            $scope.templates.splice($scope.templates.indexOf($scope.selectedTemplatesWrapper.selected), 1, newComboItem);
            $scope.selectedTemplatesWrapper.selected = newComboItem;
            $scope.isSavingTemplateWrapper.isSaving = false;
            $timeout(function () {
              delete $scope.mode;
            })
          });

        }).catch(function () {
        $scope.isSavingTemplateWrapper.isSaving = false;

        toastr.error(localize.getLocalizedString('_ErrorSavingTemplate_'));
      });
    };

    var deleteText = localize.getLocalizedString('_Delete_');
    var approvalText = localize.getLocalizedString('_Sure_');

    $scope.decorateTemplateCombo = function (scope, element) {
      var templateComboItem = scope.item;
      if (templateComboItem.id) {
        scope.deleteText = deleteText;
        scope.approvalText = approvalText;

        var submitButton = '<div ng-click="removeTemplate($event, item)">{{approvalMode ? approvalText : deleteText}}</div>';

        scope.removeTemplate = function (e, item) {
          e.preventDefault();
          e.stopPropagation();

          if (!scope.approvalMode) {
            scope.approvalMode = true;
            return $timeout(function () {
              delete scope.approvalMode;
            }, 10000);
          }

          $scope.templates.splice($scope.templates.indexOf(item), 1);
          Repository.Custom("CustomTimelineItem").deleteTemplate(item.id)
            .catch(function () {
              toastr.error(localize.getLocalizedString('_ErrorDeletingTemplate_'));
            });
        };

        var decorationItem = '<div class="combobox-template-remove-decorator">' + submitButton + '</div>';
        element.find('.combobox-list-item-concrete-value').append($compile(decorationItem)(scope));
      }
    };

    function templateItemToComboboxItem(template) {
      return {
        name: template.Name, value: template.Content, id: template.CustomTimelineItemPatternId
      };
    }

    /********************************** END OF TEMPLATE **********************************/


    function validate() {
      var isValid = true;
      if ($scope.reminder.isToRemind && $scope.reminder.reminderSms.length < 3) {
        isValid = false;
        toastr.error(localize.getLocalizedString("_ReminderMustHaveRemainderContent_"));
      } else if ($scope.reminder.content.length < 3) {
        isValid = false;
        toastr.error(localize.getLocalizedString("_ReminderMustHaveContent_"));
      }

      return isValid;
    }

    function generateCustomNote(lock) {
      if ($scope.reminder.reminderSms.length == 0 && $scope.reminder.isToRemind) {
        $scope.reminder.reminderSms = $("<div>" + $scope.reminder.content + "</div>").text().substr(0, 65);
      }

      if (!validate()) {
        return false;
      }

      var toSend = {};
      toSend.OwnerEntityId = parameters.noteToEdit ? -1 : parameters.getOwnerId();
      toSend.EmployeeId = $scope.selectedEmployee.selected.id ? $scope.selectedEmployee.selected.id : $scope.selectedEmployee.selected.value;
      toSend.EntityId = parameters.noteToEdit ? parameters.noteToEdit.Id : -1;
      toSend.Content = '<div class="ql-editor">' + $scope.reminder.content + '</div>';
      toSend.RemainderContent = $scope.reminder.reminderSms;
      if ($scope.reminder.isToRemind) {
        toSend.ReminderType = parameters.reminderType;
        toSend.Remainder = $scope.reminder.fixValue.value;
        if (!$scope.reminder.fixValue.value) {
          toSend.ManualRemainder = moment($scope.reminder.manualDateToRemind, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
        }
      }
      toSend.Lock = !!lock
      toSend.Category = $scope.selectedInfoCategoriesWrapper.selected.name;
      return toSend;
    }

  });
