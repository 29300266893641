<template>
  <div class="linear-progress-bar" v-if="isLoading">
    <div class="linear-progress-bar__line"></div>
    <div class="linear-progress-bar__subline linear-progress-bar__subline--inc"></div>
    <div class="linear-progress-bar__subline linear-progress-bar__subline--dec"></div>
  </div>
</template>

<script>
export default {
  mounted() {
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="less">
@color: #42A3FC;
@height: 5px;

.linear-progress-bar {
  position: absolute;
  width: 100%;
  height: @height;
  overflow-x: hidden;

  &__line {
    position: absolute;
    opacity: 0.4;
    background: @color;
    width: 150%;
    height: @height;
  }

  &__subline {
    position: absolute;
    background: @color;
    height: @height;

    &--inc {
      animation: increase 2s infinite;
    }

    &--dec {
      animation: decrease 2s 0.5s infinite;
    }
  }

  @keyframes increase {
    from {
      left: -5%;
      width: 5%;
    }
    to {
      left: 130%;
      width: 100%;
    }
  }

  @keyframes decrease {
    from {
      left: -80%;
      width: 80%;
    }
    to {
      left: 110%;
      width: 10%;
    }
  }
}


</style>
