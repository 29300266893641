import './manual.repeating.directive.less'
import { DATE_DISPLAY_FORMAT } from "../../../constants";

angular.module('easybizy.easybizyModalViewsModel').directive("manualRepeating", ['localize', 'configuration', 'iCalService',
  function (localize, configuration, iCalService) {
    return {
      restrict: 'E',
      replace: true,
      template: require('./manual.repeating.directive.tpl.html'),
      scope: {
        meetingDate: '=',
        repeatStringWrapper: '=',
        close: '&'
      },
      link: function (scope, element, attrs) {
        scope.repeat = {};
        var maxRepeatEveryValue = 30;
        scope.repeat.afterXOccurrences = 1;
        scope.repeat.endsMode = 'never';
        scope.repeatsMode = [
          {
            name: localize.getLocalizedString("_Daily_"),
            value: 'DAILY',
            add: 'd',
            addAmount: 15,
            every: localize.getLocalizedString("_Days_")
          },
          {
            name: localize.getLocalizedString("_Weekly_"),
            value: 'WEEKLY',
            add: 'w',
            addAmount: 8,
            every: localize.getLocalizedString("_Weeks_")
          },
          {
            name: localize.getLocalizedString("_Monthly_"),
            value: 'MONTHLY',
            add: 'M',
            addAmount: 3,
            every: localize.getLocalizedString("_Months_")
          },
          {
            name: localize.getLocalizedString("_Yearly_"),
            value: 'YEARLY',
            add: 'y',
            addAmount: 1,
            every: localize.getLocalizedString("_Years_")
          }
        ];

        scope.repeat.selectedRepeatsMode = scope.repeatsMode[1];
        scope.repeat.monthlyRepeat = 'BYMONTHDAY';

        scope.repeatsEvery = [];
        for (var i = 1; i <= maxRepeatEveryValue; i++) {
          scope.repeatsEvery.push({ name: i, value: i });
        }

        // WHEN the repeat type changes, we initialize these values.
        scope.repeatValueChanged = initializeRepeatEveryAndEndsOn;
        initializeRepeatEveryAndEndsOn();

        scope.$watchOnce('repeatStringWrapper', function (newVal) {
          if (!newVal.value) {
            scope.repeatValueChanged(scope.repeat.selectedRepeatsMode);
          } else {
            deserialize(newVal.value);
          }
        });

        scope.repeat.daysOfWork = [];

        for (var i = 0; i < 7; i++) {
          scope.repeat.daysOfWork.push({
            Index: i,
            IsEnabled: false,
            RRDay: moment().lang("en").set('d', i).format("dd").toUpperCase()
          });
        }

        scope.$watch('meetingDate', function (newVal) {
          var currentWeekDay = moment(scope.meetingDate, DATE_DISPLAY_FORMAT).day();
          scope.repeat.daysOfWork[currentWeekDay].IsEnabled = true;
        });

        scope.validateActionWrapper = {};
        scope.endDateValidation = {};

        scope.repeatEndDateField = {
          fieldName: 'EndsOn',
          placeholder: localize.getLocalizedString("_DateEnd_"),
          validation: "{'date': true}",
          type: 'date',
          yearRange: "-1:+5"
        };

        scope.endsModeChanged = function (mode) {
          scope.repeat.endsMode = mode;
        };

        scope.$watch('repeat', function () {
          // watch any repeat change and create RRULE.
          var result = "RRULE:";
          var repeatMode = scope.repeat.selectedRepeatsMode.value;
          var interval = parseInt(scope.repeat.repeatsEveryValue.value);


          result += "FREQ=" + repeatMode + ";";
          if (interval > 1) {
            result += "INTERVAL=" + interval + ";";
          }

          //                    scope.monthExplanation = "";
          if (repeatMode == 'WEEKLY') {
            var selectedDays = [];
            var daysString = "";
            scope.repeat.daysOfWork.forEach(function (day) {
              if (day.IsEnabled) {
                selectedDays.push(day.RRDay);
                daysString += daysString.length > 0 ? ("," + day.RRDay) : day.RRDay;
              }
            });

            var meetingDay = moment(scope.meetingDate, DATE_DISPLAY_FORMAT)
              .lang("en").format("dd").toUpperCase();
            if (selectedDays.length > 1 || (selectedDays.length == 1 && selectedDays[0] != meetingDay)) {
              result += "BYDAY=" + daysString + ";";
            }
          } else if (repeatMode == 'MONTHLY') {
            if (scope.repeat.monthlyRepeat == 'BYMONTHDAY') {
              result += "BYMONTHDAY=" + moment(scope.meetingDate, DATE_DISPLAY_FORMAT).format("DD") + ";";
              //                            scope.monthExplanation = localize.getLocalizedString("_ByMonthDay_", moment(scope.meetingDate, dateDisplayFormat).format("DD"));
            } else {
              var weekOfMonth = Math.ceil(moment(scope.meetingDate, DATE_DISPLAY_FORMAT).format("DD") / 7);
              result += "BYDAY=" + weekOfMonth +
                moment(scope.meetingDate, DATE_DISPLAY_FORMAT).lang("en").format("dd").toUpperCase() + ";";
              //                            scope.monthExplanation = localize.getLocalizedString("_ByDay_", moment(scope.meetingDate, dateDisplayFormat).format("dddd"), weekOfMonth);

            }
          }

          if (scope.repeat.endsMode == 'occurrences') {
            result += "COUNT=" + scope.repeat.afterXOccurrences;
          } else if (scope.repeat.endsMode == 'date') {
            result += "UNTIL=" + moment(scope.repeat.EndsOn, DATE_DISPLAY_FORMAT).format("YYYYMMDD");
          }

          if (result[result.length - 1] == ';') {
            scope.repeatStringWrapper.value = result.substring(0, result.length - 1);
          } else {
            scope.repeatStringWrapper.value = result;
          }

          scope.repeatString = iCalService.rRuleToString(scope.repeatStringWrapper.value);

        }, true);

        $(document).on('keydown.escape', function (e) {
          if (e.which == 27) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            scope.closeDialog();
          }
        });

        scope.closeDialog = function () {
          scope.close()();
        };

        scope.toggleEndingMode = function (mode) {
          scope.repeat.endsMode = mode;
        };

        scope.toggleMonthlyMode = function (mode) {
          scope.repeat.monthlyRepeat = mode;
        };

        scope.$on('$destroy', function () {
          $(document).off('keydown.escape');
        });

        scope.save = function () {
          scope.closeDialog();
        };

        function initializeRepeatEveryAndEndsOn(newVal) {
          scope.repeat.repeatsEveryValue = scope.repeatsEvery[0];
          if (!newVal) {
            newVal = scope.repeat.selectedRepeatsMode;
          }

          scope.repeat.EndsOn = moment(scope.meetingDate, DATE_DISPLAY_FORMAT).add(newVal.addAmount, newVal.add); // Take the defaults of repeating meeting.
          scope.repeat.EndsOn = scope.repeat.EndsOn.add(1, 'd'); // this addition is because Until doesn't include the Until date itself. This way it does!
          scope.repeat.EndsOn = scope.repeat.EndsOn.format(DATE_DISPLAY_FORMAT);
        }

        function deserialize(rule) {
          var splitted = rule.replace('RRULE:', '').split(';');

          var rulesKeyValue = {};
          splitted.forEach(function (subRule) {
            var splittedRule = subRule.split('=');
            rulesKeyValue[splittedRule[0]] = splittedRule[1];
          });

          console.log(rulesKeyValue);

          if (rulesKeyValue['FREQ'] == 'DAILY') {
            scope.repeat.selectedRepeatsMode = scope.repeatsMode[0];
          } else if (rulesKeyValue['FREQ'] == 'WEEKLY') {
            scope.repeat.selectedRepeatsMode = scope.repeatsMode[1];
            if (rulesKeyValue.hasOwnProperty('BYDAY')) {
              var includedDays = rulesKeyValue['BYDAY'];
              scope.repeat.daysOfWork.forEach(function (day) {
                if (includedDays.indexOf(day.RRDay) >= 0) {
                  day.IsEnabled = true;
                } else {
                  day.IsEnabled = false;
                }
              });
            }
          } else if (rulesKeyValue['FREQ'] == 'MONTHLY') {
            scope.repeat.selectedRepeatsMode = scope.repeatsMode[2];
            if (rulesKeyValue.hasOwnProperty('BYDAY')) {
              scope.toggleMonthlyMode('BYDAY');
            }

          } else if (rulesKeyValue['FREQ'] == 'YEARLY') {
            scope.repeat.selectedRepeatsMode = scope.repeatsMode[3];
          }

          if (rulesKeyValue.hasOwnProperty('INTERVAL')) {
            var interval = parseInt(rulesKeyValue['INTERVAL']);
            if (interval > maxRepeatEveryValue) {
              var customEveryValue = { name: interval, value: interval };
              scope.repeatsEvery.push(customEveryValue);
              scope.repeat.repeatsEveryValue = customEveryValue;
            } else {
              scope.repeat.repeatsEveryValue = scope.repeatsEvery[interval - 1];
            }

          }

          if (rulesKeyValue.hasOwnProperty('COUNT')) {
            scope.repeat.afterXOccurrences = parseInt(rulesKeyValue['COUNT']);
            scope.toggleEndingMode('occurrences');
          } else if (rulesKeyValue.hasOwnProperty('UNTIL')) {
            scope.repeat.EndsOn = moment(rulesKeyValue['UNTIL'], "YYYYMMDD").format(DATE_DISPLAY_FORMAT);
            scope.toggleEndingMode('date');
          }
        }
      }
    };
  }]);
