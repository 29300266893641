<template>
  <div>
    <div class="flex items-center mb-4 justify-between">
      <div class="flex items-center">
        <label class="me-2">{{ $t('_View_') }}:</label>
        <ButtonsGroup :options="viewTypeOptions" v-model="viewType" :bordered="false" :spaceEvenly="true"
                      class="evc-background--default me-4"/>
      </div>
      <div class="flex items-center justify-start">
        <button class="flex items-center justify-start me-4" @click="mediator.print()">
          <div class="icon icon-print me-2"></div><div>{{$t('_Print_')}}</div>
        </button>
        <button class="flex items-center justify-start" @click="mediator.exportToExcel()">
          <div class="icon icon-excel me-2"></div><div>{{ $t('_Export_')}}</div>
        </button>
      </div>
    </div>
    <Grid :dataSource="dataSource" v-bind="gridProps" :height="'70vh'"></Grid>
  </div>
</template>

<script>
import {ButtonsGroup, Grid, GridLabels} from "@tomeravni/easybizy-vue-components";
import {CustomerVisitHistoryDS,CustomerMeetingsDS,CustomerInvoicesDS,CustomerPurchasesDS} from "./customer.visit.history.ds";
import Moment from 'moment';
import {resolveDefaultGridLabels} from "../../vue/vue.helpers";

export default {
  components: {
    Grid,
    ButtonsGroup,
  },
  props: {
    mediator: {
      type: Object
    }
  },
  data() {
    const labels = resolveDefaultGridLabels(this.$t);
    const viewType = 'groupByDate';
    const viewTypeOptions = [
      {
        text: this.$t('_VisitHistoryByDate_'), value: 'groupByDate'
      },
      {
        text: this.$t('_Invoices_'), value: 'invoices'
      },
      {
        text: this.$t('_Meetings_'), value: 'meetings'
      } ,
      {
        text: this.$t('_Purchases_'), value: 'purchases'
      }      
    ];



    return {
      viewTypeOptions,
      viewType,
      dataSource: null,
      gridProps: {
        freezeHeader: true,
        hideBorder: false,
        elevated: true,
        pickColumns: true,
        rowHeightMode: 'tight',
        backgroundColor: 'auto',
        preventScrollTopButton: false,
        enableFilter: true,
        isFilterVisible: true,
        forceFullHeight: true,
        labels
      }
    }
  },

  mounted() {

  },
  methods: {
    updateDS(viewType) {
      let api;
      let dataSource;
      if(viewType === 'groupByDate') {
          api = this.Repository.Custom("QueryableTable").customerVisitHistory(this.mediator.customerId);
          api.pageSize = 100;
          api.id(this.mediator.customerId);
          dataSource = new CustomerVisitHistoryDS(api, this.$t, this.$stateManager, this.mediator, this.Repository, this.printerMediator);
          dataSource.filterValues = {
            Date: [Moment().startOf('year').add(-2, 'years').toDate(), new Date()]
          }
        } else if(viewType === 'invoices') {
          api = this.Repository.Custom("QueryableTable").customerInvoices(this.mediator.customerId);
          api.pageSize = 100;
          api.id(this.mediator.customerId);
          dataSource = new CustomerInvoicesDS(api, this.$t, this.$stateManager, this.mediator, this.Repository, this.printerMediator);
          dataSource.filterValues = {
            Date: [Moment().startOf('year').add(-2, 'years').toDate(), new Date()]
          }
        } else if(viewType === 'meetings') {
          api = this.Repository.Custom("QueryableTable").customerMeetings(this.mediator.customerId)
          api.pageSize = 100;
          api.id(this.mediator.customerId);
          dataSource = new CustomerMeetingsDS(api, this.$t, this.$stateManager, this.mediator, this.Repository, this.printerMediator);
          dataSource.filterValues = {
            MeetingStartTime: [Moment().startOf('year').add(-2, 'years').toDate(), new Date()],MeetingCreatedOn:[]
          }
        } else if(viewType === 'purchases') {
          api = this.Repository.Custom("QueryableTable").customerPurchases(this.mediator.customerId)
          api.pageSize = 100;
          api.id(this.mediator.customerId);
          dataSource = new CustomerPurchasesDS(api, this.$t, this.$stateManager, this.mediator, this.Repository, this.printerMediator);
          dataSource.filterValues = {
            CreatedOn: [Moment().startOf('year').add(-2, 'years').toDate(), new Date()]
          }
        }

        this.dataSource = dataSource;
    }
  },
  watch: {
    viewType: {
      immediate: true,
      handler(viewType, prev) {
        this.updateDS(viewType);
      }
    },
  }

}
</script>
