import './online.scheduling.services.directive.less'
import { ObservableProperties } from "../../../general/helpers";
import { cleanObj, isNonEmptyObject } from "@tomeravni/easybizy-js-common/common";

(function () {
  angular.module('easybizy.online.scheduling').directive('onlineSchedulingServices', [
    'Repository', 'localize', 'confirmService', '$rootScope',
    function (Repository, localize, confirmService, $rootScope) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          mediator: '=',
        },
        link: function (scope) {
        },
        template: require('./online.scheduling.services.directive.html')
      };
    }]);

}());
