import './radial.progress.bar.less'

angular.module('easybizy.common.common-controls')
  .directive('radialProgressBar',
    function () {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          progressValue: '='
        },
        template: require('./radial.progress.bar.tpl.html'),
        link: function (scope, element, attrs) {
          scope.$watch('progressValue', function (newVal) {
            if (newVal && newVal > 0) {
              element.attr('data-progress-percentage', newVal);
            }
          });
        }
      };
    });

