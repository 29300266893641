import './items.assigner.directive.less'
import { guid } from '../../../general/helpers';

angular.module('easybizy.resources.management').directive('itemsAssigner', [
  '$timeout',
  'localize', function ($timeout, localize) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        itemsByCategories: '=',
        selectedItems: '=',
        itemsHeader: '=',
        selectedItemsHeader: '=',
        selectedItemsChanged: '=',
        emptyPlaceholder: '=',
        addResourceDelegate: '=',
      },
      link: function (scope, element, attrs) {
        scope.randomId = guid();

        Object.defineProperty(scope, 'isEmpty', {
          get: function () {
            if (scope.selectedItems) {
              for (var itemCategoryIdx = 0; itemCategoryIdx < scope.selectedItems.length; itemCategoryIdx++) {
                if (scope.selectedItems[itemCategoryIdx].items.length > 0) {
                  return false;
                }
              }
            }

            return true;
          }
        });

        var _isAllItemsAssigned = false;
        Object.defineProperty(scope, 'isAllItemsAssigned', {
          get: function () {
            return _isAllItemsAssigned;
          }
        });

        scope.$watchCollection('itemsByCategories', function (newVal) {
          if (!newVal || newVal.length < 1) {
            return;
          }

          calculateIsAllItemsAssigned();

          scope.$evalAsync(function () {
            element.find('.dictionary-side li:not(.ui-draggable)[guid="' + scope.randomId + '"]').draggable({
              helper: 'clone',
              appendTo: element,
              containment: element,
              revert: 'invalid',
              scroll: true,
              start: function (_, ui) {
                $(this).css('visibility', 'hidden');
                ui.helper.css('width', $(this).css('width'));
                ui.helper.css('min-width', $(this).css('width'));
              },
              stop: function (_, ui) {
                $(this).css('visibility', '');
                ui.helper.css('width', '');
              }
            })
              .on('dblclick.items-doubleClick', function (e) {
                e.stopPropagation();
                e.preventDefault();
                addItems(angular.element(this).scope());
              });

            makeDroppable()
          });

        });

        function calculateIsAllItemsAssigned() {
          if (!scope.itemsByCategories) {
            return _isAllItemsAssigned = false;
          }

          for (var categoryIdx = 0; categoryIdx < scope.itemsByCategories.length; categoryIdx++) {
            if (scope.itemsByCategories[categoryIdx].items.length > 0) {
              return _isAllItemsAssigned = false;
            }
          }

          _isAllItemsAssigned = true;
        }


        function makeDroppable() {
          element.find('.items-picker.receiver[guid="' + scope.randomId + '"]').droppable({
            revert: true,
            accept: 'li[guid="' + scope.randomId + '"]',
            placeholder: 'item-placeholder',
            hoverClass: 'drop-hover',
            drop: function (_, ui) {
              addItems(angular.element(ui.draggable[0]).scope());
            },
            activeClass: 'item-on-class'
          })
        }

        function addItems(itemScope) {
          if (itemScope.hasOwnProperty('item')) {
            itemOrItemsDropped([itemScope.item]);
          } else if (itemScope.$parent.category) {
            itemOrItemsDropped(itemScope.$parent.category.items);
          } else {
            toastr.error(localize.getLocalizedString('_ErrorHandlingServices_'));
          }

          notify()
        }

        scope.remove = function (item) {
          item.selectedContainer.remove(item);
          item.container.push(item);
          notify()
        };

        function notify() {
          $timeout(function () {
            scope.selectedItemsChanged(scope.selectedItems)
          });

          calculateIsAllItemsAssigned();
        }

        function itemOrItemsDropped(items) {
          items.forEach(function (item) {
            scope.selectedItems.some(function (selectedItemWrapper) {
              if (selectedItemWrapper.name === item.category.name) {
                scope.$evalAsync(function () {
                  item.container.remove(item);
                  item.selectedContainer = selectedItemWrapper.items;
                  return selectedItemWrapper.items.push(item)
                });
              }
            });
          })
        }
      },
      template: require('./items.assigner.directive.tpl.html')
    };
  }]);
