import { ObservableProperties } from "../../../general/helpers";
import {
  DATE_DISPLAY_FORMAT,
  ODATA_DATE_ONLY_FORMAT,
  ODATA_DATE_TIME_FORMAT,
  HOURS_AND_MINUTES_FORMAT
} from "../../../constants";

(function () {
  angular.module('easybizy.calendar').service('calendarColumns', [
    'calendarMetadata', 'Repository', 'calendarColumn', 'localize', '$rootScope', '$q', '$transitions',
    function (calendarMetadata, Repository, calendarColumn, localize, $rootScope, $q, $transitions) {
      ObservableProperties(this);
      calendarMetadata.on('calendarStateChanged', loadMetadataAndGenerateColumns);
      const Column = calendarColumn.Column;
      const dayIndexKeyFormat = calendarMetadata.formatKeys.columnKey;
      var loadingDaysOfWorkDelegates = [];
      var employees = {}, exceptions = {}, currentlyLoading = {};
      var columns;

      Object.defineProperty(this, 'visibleColumns',
        {
          get: function () {
            return columns;
          }
        });

      $transitions.onExit({ exiting: 'Calendar' }, () => {
        this.clearObservers();
      });

      var lastEditedExceptionsWrapper = { lastEditedExceptions: {} };
      Object.defineProperty(this, 'lastEditedExceptions',
        {
          set: function (updatedExceptions) {
            var actuallyUpdated = {};
            lastEditedExceptionsWrapper.lastEditedExceptions = {};

            if (updatedExceptions) {
              for (var formattedDateKey in updatedExceptions) {
                if (!isSameExceptions(updatedExceptions[formattedDateKey], exceptions[formattedDateKey])) {
                  actuallyUpdated[formattedDateKey] = updatedExceptions[formattedDateKey]
                }
              }
            }

            Object.assign(lastEditedExceptionsWrapper.lastEditedExceptions, actuallyUpdated);
          },
          get: function () {
            return lastEditedExceptionsWrapper.lastEditedExceptions;
          }
        });

      $transitions.onEnter({}, stateChanged.bind(this));

      this.exceptionsUpdated = (function (updatedExceptions) {
        this.lastEditedExceptions = null;
        Object.assign(exceptions, updatedExceptions);
      }).bind(this);

      function stateChanged(trans) {
        const currentStateIsCalendar = trans.targetState().state().name === 'Calendar';
        if (!currentStateIsCalendar) {
          loadingDaysOfWorkDelegates = [];
          employees = {};
          exceptions = {};
          this.lastEditedExceptions = {};
          currentlyLoading = {};
        }
      }

      function loadMetadataAndGenerateColumns() {
        const formattedRequestedDate = moment(calendarMetadata.getCurrentDay()).format(dayIndexKeyFormat);
        if (exceptions.hasOwnProperty(formattedRequestedDate) && (!calendarMetadata.currentRangeView.endDate ||
          exceptions.hasOwnProperty(moment(calendarMetadata.currentRangeView.endDate, DATE_DISPLAY_FORMAT).format(dayIndexKeyFormat)))) {
          generateColumns();
        } else {
          const currentRangeView = JSON.stringify(calendarMetadata.currentRangeView);
          const startDate = calendarMetadata.getCurrentDay().weekday(calendarMetadata.startingDay);
          const endDate = startDate.clone().add(1, 'w');
          const doneLoadingDelegate = () => {
            if (currentRangeView === JSON.stringify(calendarMetadata.currentRangeView)) {
              generateColumns();
              extendRangeIfNeeded(startDate);
            } else {
              console.log('prevented!');
            }
          };

          if (currentlyLoading.hasOwnProperty(startDate.format(dayIndexKeyFormat)) && currentlyLoading.hasOwnProperty(endDate.format(dayIndexKeyFormat))) {
            loadingDaysOfWorkDelegates.push({ startDate, endDate, delegate: doneLoadingDelegate });
            return;
          }

          loadDaysOfWork(startDate, endDate, doneLoadingDelegate);
        }
      }

      const generateColumns = (function () {
        var currentDay = calendarMetadata.getCurrentDay();
        var columnsToSet = [];
        const filteredEmployeeId = calendarMetadata.filteredEmployeeId;
        if (calendarMetadata.viewType === 'd') {
          columnsToSet.push(new Column(currentDay, null, filteredEmployeeId, calendarMetadata.filteredStatus));
        } else if (calendarMetadata.viewType === 'employees') {
          var employeesForDate = getEmployeesForDate(currentDay);
          employeesForDate.forEach(function (employee) {
            columnsToSet.push(new Column(currentDay, employee, calendarMetadata.filteredEmployeeId, calendarMetadata.filteredStatus, getExceptionsByDateAndEmployee(currentDay, employee.EmployeeId)));
          })
        } else if (calendarMetadata.viewType === 'rooms') {
          // TODO: handle async loaded rooms if needed.
          calendarMetadata.rooms.forEach(function (room) {
            columnsToSet.push(new Column(currentDay, null, calendarMetadata.filteredEmployeeId, calendarMetadata.filteredStatus, null, room));
          })
        } else if (calendarMetadata.viewType === 'w') {
          var dayItr = calendarMetadata.firstDayOfTheWeek;
          for (var i = 0; i < calendarMetadata.daysInWeek; i++) {
            const employeeToFilter = filteredEmployeeId || (calendarMetadata.isSingleWeeklyEmployeeMode ? Object.values(employees)[0].id : null);
            const exceptions = employeeToFilter ? getExceptionsByDateAndEmployee(dayItr, employeeToFilter) : null;
            columnsToSet.push(new Column(dayItr, null, filteredEmployeeId,
              calendarMetadata.filteredStatus, exceptions));
            dayItr = dayItr.add(1, 'd');
          }
        }

        columns = columnsToSet;
        if (columns.length === 0) {
          if (currentDay.isBefore(moment(calendarMetadata.getPreviousState().params.date, 'YY-MM-DD'))) {
            console.log('unavailable date! - Taking previous date.');
            return calendarMetadata.prevPeriod();
          } else {
            console.log('unavailable date! - Taking next date.');
            return calendarMetadata.nextPeriod();
          }
        }

        notify();
      }).bind(this);

      const notify = (function () {
        this.notify('columnsChanged', columns);
      }).bind(this);

      const getExceptionsByDate = (function (date) {
        const formattedRequestedDate = moment(date).format(dayIndexKeyFormat);
        return this.lastEditedExceptions.hasOwnProperty(formattedRequestedDate) ? this.lastEditedExceptions[formattedRequestedDate] : exceptions[formattedRequestedDate];
      }).bind(this);

      const getExceptionsByDateAndEmployee = (function (date, employeeId) {
        return getExceptionsByDate(date)[employeeId];
      }).bind(this);

      this.employeesOrderChanged = (function (newOrder) {
        this.employeesSort = newOrder;
        generateColumns();
      });

      function loadDaysOfWork(startDate, endDate, successCallback, loadEmployees) {
        Repository.Custom('Calendar').daysOfWork(startDate.format(ODATA_DATE_ONLY_FORMAT),
          endDate.format(ODATA_DATE_TIME_FORMAT), loadEmployees)
          .then(function (results) {
            doneLoadingDaysOfWork(results, startDate, endDate);
            if (successCallback) {
              successCallback(startDate.clone(), endDate.clone());
            }
          })
          .catch(function (err) {
            errorLoadingDaysOfWorkExceptions(err, startDate, endDate);
          });

        calendarMetadata.iterateDatePeriod(startDate, endDate, function (startDate) {
          var startDateFormatted = startDate.format(dayIndexKeyFormat);
          if (!currentlyLoading.hasOwnProperty(startDateFormatted)) {
            currentlyLoading[startDateFormatted] = true;
          }
        });
      }

      function doneLoadingDaysOfWork(results, startDate, endDate) {
        if (results.Employees) {
          results.Employees.forEach(function (employee) {
            employees[employee.EmployeeId] = Object.assign({ id: employee.EmployeeId }, employee);
          });
        }

        // TODO: Remove when possible. It handles exceptions that have start and endtime the same.
        if (results.Exceptions) {
          for (var exceptionDate in results.Exceptions) {
            var exceptionsForDate = results.Exceptions[exceptionDate]
            for (var employeeId in exceptionsForDate) {
              var exceptionsForEmployee = exceptionsForDate[employeeId];
              var onlyValidExceptions = [];
              exceptionsForEmployee.forEach(function (exception) {
                if (exception.start !== exception.end) {
                  onlyValidExceptions.push(exception);
                }
              });

              exceptionsForDate[employeeId] = onlyValidExceptions;
            }
          }

          Object.assign(exceptions, results.Exceptions);
        }

        calendarMetadata.iterateDatePeriod(startDate, endDate, function (startDate) {
          const startDateFormatted = startDate.format(dayIndexKeyFormat);
          delete currentlyLoading[startDateFormatted];
        });

        loadingDaysOfWorkDelegates.clone().forEach((iWaitingDelegate) => {
          const { startDate: iStartDate, endDate: iEndDate, delegate } = iWaitingDelegate;
          if (!iStartDate.isBefore(startDate) && !iEndDate.isAfter(endDate)) {
            loadingDaysOfWorkDelegates.remove(iWaitingDelegate);
            console.log('Reloaded from delegate on days of work');
            delegate();
          }
        })
      }

      const getEmployeesForDate = (function (date) {
        var employeesToReturn = [];
        var exceptionsForDate = getExceptionsByDate(date);
        for (var employeeId in exceptionsForDate) {
          var exceptionsArr = exceptionsForDate[employeeId];
          var disabled = false;
          if (exceptionsArr.length > 0) {
            for (var idx = 0; idx < exceptionsArr.length; idx++) {
              if (!calendarMetadata.editMode && exceptionsArr[idx].disabled) {
                disabled = true;
                break;
              }
            }
          }

          if (!disabled) {
            employeesToReturn.push(employees[employeeId]);
          }
        }

        var order = this.employeesSort || calendarMetadata.employeesOrder;
        if (order) {
          var sortFunc = sortEmployees.bind(this, order);
          employeesToReturn.sort(sortFunc);
        }

        return employeesToReturn;
      }).bind(this);

      function sortEmployees(order, employeeA, employeeB) {
        return order.indexOf(employeeA.id) - order.indexOf(employeeB.id);
      }

      this.isValidMeetingPosition = (function (employeeId, date, startTime, endTime) {
        if (!employeeId) {
          return true;
        }

        var toReturn = true;
        var employeeExceptions = getExceptionsByDateAndEmployee(date, employeeId);
        if (!Array.isArray(employeeExceptions)) {
          // It means is was not loaded yet!
          throw 'Not in cached range!';
        }


        // Format startTime to ignore dates.
        var formattedStartTime = moment(calendarMetadata.startTime.format(HOURS_AND_MINUTES_FORMAT), HOURS_AND_MINUTES_FORMAT);
        var startTimeAsIndex = moment(startTime.format(HOURS_AND_MINUTES_FORMAT), HOURS_AND_MINUTES_FORMAT).diff(formattedStartTime, 'm') / calendarMetadata.minutesBetweenMeetings;
        var endTimeAsIndex = moment(endTime.format(HOURS_AND_MINUTES_FORMAT), HOURS_AND_MINUTES_FORMAT).diff(formattedStartTime, 'm') / calendarMetadata.minutesBetweenMeetings;

        for (var i = 0; i < employeeExceptions.length; i++) {
          var exception = employeeExceptions[i];


          if (startTimeAsIndex < exception.end && exception.start < endTimeAsIndex) {
            toReturn = false;
            break;
          }
        }


        return toReturn;
      }).bind(this);

      this.isValidMeetingPositionAsync = (function (employeeId, date, startTime, endTime, isAllDay) {
        var deferred = $q.defer();
        try {
          var result = isAllDay || this.isValidMeetingPosition(employeeId, date, startTime, endTime);
          deferred.resolve(result);
        } catch (e) {
          var formattedStartTime = moment(calendarMetadata.startTime.format(HOURS_AND_MINUTES_FORMAT), HOURS_AND_MINUTES_FORMAT);
          var startTimeAsIndex = moment(startTime.format(HOURS_AND_MINUTES_FORMAT), HOURS_AND_MINUTES_FORMAT).diff(formattedStartTime, 'm') / calendarMetadata.minutesBetweenMeetings;
          var endTimeAsIndex = moment(endTime.format(HOURS_AND_MINUTES_FORMAT), HOURS_AND_MINUTES_FORMAT).diff(formattedStartTime, 'm') / calendarMetadata.minutesBetweenMeetings;

          Repository.Custom('Calendar').validateMeetingPosition(employeeId, date, startTimeAsIndex, endTimeAsIndex)
            .then(function (result) {
              deferred.resolve(result);
            }).catch(function (err) {

          });
        }

        return deferred.promise;
      }).bind(this);

      function extendRangeIfNeeded(dateRequested) {
        // forward
        var desiredForwardDateThatShouldBeAvailable = dateRequested.clone().add(calendarMetadata.daysToKeepInMemory.forward, 'd');
        var formattedRequestedDate = desiredForwardDateThatShouldBeAvailable.format(dayIndexKeyFormat);
        if (!exceptions.hasOwnProperty(formattedRequestedDate)
          && !currentlyLoading.hasOwnProperty(formattedRequestedDate)) {
          var datePivot = desiredForwardDateThatShouldBeAvailable.clone();
          while (!exceptions.hasOwnProperty(datePivot.add(-1, 'd').format(dayIndexKeyFormat))) {
          }

          loadDaysOfWork(datePivot, datePivot.clone().add(calendarMetadata.daysToKeepInMemory.forward, 'd'));
        }

        // backward
        var desiredBackwardDateThatShouldBeAvailable = dateRequested.clone().add(calendarMetadata.daysToKeepInMemory.backward, 'd');
        formattedRequestedDate = desiredBackwardDateThatShouldBeAvailable.format(dayIndexKeyFormat);
        if (!exceptions.hasOwnProperty(formattedRequestedDate)
          && !currentlyLoading.hasOwnProperty(formattedRequestedDate)) {
          var datePivot = desiredBackwardDateThatShouldBeAvailable.clone();
          while (!exceptions.hasOwnProperty(datePivot.add(1, 'd').format(dayIndexKeyFormat))) {
          }

          loadDaysOfWork(datePivot.clone().add(calendarMetadata.daysToKeepInMemory.backward, 'd'), datePivot);
        }
      }

      function errorLoadingDaysOfWorkExceptions(err, startDate, endDate) {
        calendarMetadata.iterateDatePeriod(startDate, endDate, function (startDate) {
          exceptions[startDate.format(dayIndexKeyFormat)] = [];
          // delete doneLoadingRangeDelegate[startDate.format(dayIndexKeyFormat)];
        });

        toastr.error(localize.getLocalizedString('_ErrorLoadingCalendar_', err.Message));
      }

      function toCleanedException(exception) {
        return {
          start: exception.start,
          end: exception.end
        }

      }

      function isSameExceptions(exceptionSetA, exceptionSetB) {
        var cleanedResultA = {}, cleanedResultB = {};
        for (var employeeId in exceptionSetA) {
          cleanedResultA[employeeId] = exceptionSetA[employeeId].map(toCleanedException);
        }

        for (var employeeId in exceptionSetB) {
          cleanedResultB[employeeId] = exceptionSetB[employeeId].map(toCleanedException);
        }

        return JSON.stringify(cleanedResultA) === JSON.stringify(cleanedResultB);
      }


    }]);

}());
