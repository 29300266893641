import { performIfDelegate } from '@tomeravni/easybizy-js-common/common';
import './online.scheduling.add.service.less'

(function () {
  angular.module('easybizy.easybizyModalViewsModel').controller('OnlineSchedulingAddServiceController',
    function ($scope, $modalInstance, saveAction, parameters, $timeout, localize, onlineSchedulingAvailabilityStore, Repository, configuration, timeConverter) {
      $scope.loadingWrapper = { loading: false };
      $scope.header = localize.getLocalizedString('_AddOnlineSchedulingServiceModalHeader_');
      var configurations = configuration.get();
      $scope.servicesByCategories = configurations.CalendarSettings.GetServicesByCategories;
      var minutesBetween = configurations.CalendarSettings.MinimumMeetingLengthInMinutes.Value;
      // ROOT ENTITY.
      $scope.onlineSchedulingService = { services: [] };
      // Wrapper for original values
      $scope.originalOnlineSchedulingService = {};

      // PLACEHOLDERS
      $scope.inputPlaceholders = {
        ServiceRepresentativeName: localize.getLocalizedString('_ServiceRepresentativeName_'),
        Description: localize.getLocalizedString('_Description_'),
        RemarksPlaceHolder: localize.getLocalizedString('_RemarksPlaceholderInfo_')
      };

      $scope.services = [
        { name: localize.getLocalizedString("_SelectServices_"), value: null, defaultValue: true }
      ];

      $scope.durations = [];

      var andText = localize.getLocalizedString('_And_');
      $scope.manuallySelectedService = function (iService) {
        var services = angular.isArray(iService) ? iService : [{ id: iService.value }];
        if (iService.defaultValue) {
          $scope.onlineSchedulingService.ServiceRepresentativeName = null;
        } else {
          var mappedName = '', duration = 0, fromPrice = 0;
          services.forEach(function (serviceKeyValue) {
            var service = serviceKeyValue.id;
            mappedName = mappedName ? mappedName + ' ' + andText + service.Name : service.Name;
            duration += moment.duration(service.Duration).asMinutes();
            fromPrice += service.Price;
          });

          $scope.onlineSchedulingService.ServiceRepresentativeName = mappedName;
          $scope.onlineSchedulingService.Description = mappedName;
          var adapted = adaptDuration(duration);

          $scope.originalOnlineSchedulingService.duration = adapted.name;
          $scope.onlineSchedulingService.PriceFrom = fromPrice;
          $scope.onlineSchedulingService.PriceTo = fromPrice;
        }
      };

      $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
      };

      $scope.save = function () {
        var toSend = Object.assign({}, $scope.onlineSchedulingService);
        if (toSend.ServiceRepresentativeName.length < 2 || toSend.ServiceRepresentativeName.length < 2) {
          return toastr.error(localize.getLocalizedString('_AllFieldsAreMandatory_'));
        }
        if (toSend.service && toSend.services.length === 0) {
          toSend.services = [toSend.service];
        }

        toSend.ServiceMetadatas = toSend.services.map(function (service) {
          var concreteService = service.value;
          return {
            ServiceMetadataId: concreteService.ServiceMetadataId,
            RetailPrice: concreteService.Price,
            ServiceName: concreteService.Name
          }
        });

        delete toSend.services;
        delete toSend.service;
        delete toSend.dirty;

        toSend.Duration = toSend.duration.value;
        delete toSend.duration;

        if (toSend.MaximumPerDay < 1) {
          toSend.MaximumPerDay = null;
        }


        $scope.loadingWrapper.loading = true;
        saveAction(toSend).then(function (service) {
          $scope.loadingWrapper.loading = false;
          $scope.cancel();
          performIfDelegate(parameters.onUpdateCallback, service)
        }).catch(function (err) {
          $scope.loadingWrapper.loading = false;
          toastr.error(localize.getLocalizedString('_ErrorSavingEntity_') + ' - ' + err.Message);
          $scope.$digestIfNeeded();
        });
      };


      // Set Services.
      loadServices();
      // Set durations.
      generateDurations();

      var serviceToEdit = parameters.service;
      if (serviceToEdit) {
        $scope.header = localize.getLocalizedString('_EditOnlineSchedulingServiceModalHeader_', serviceToEdit.ServiceRepresentativeName);
        // Price from:
        $scope.onlineSchedulingService.PriceFrom = parseFloat(serviceToEdit.PriceFrom);
        $scope.onlineSchedulingService.PriceTo = parseFloat(serviceToEdit.PriceTo);
        $scope.onlineSchedulingService.ServiceRepresentativeName = serviceToEdit.ServiceRepresentativeName;
        $scope.onlineSchedulingService.Description = serviceToEdit.Description;
        $scope.onlineSchedulingService.NeedsApproval = serviceToEdit.NeedsApproval;
        $scope.onlineSchedulingService.NeedsCreditToken = !!serviceToEdit.NeedsCreditToken;
        $scope.onlineSchedulingService.MaximumPerDay = serviceToEdit.MaximumPerDay;
        $scope.onlineSchedulingService.OnlineBookingServiceMetadataId = serviceToEdit.OnlineBookingServiceMetadataId;
        $scope.onlineSchedulingService.ForceRemarks = serviceToEdit.ForceRemarks;
        $scope.onlineSchedulingService.RemarksPlaceHolder = serviceToEdit.RemarksPlaceHolder;

        var idx = 0, selectedDuration = null;
        while (idx < $scope.durations.length && (!selectedDuration || selectedDuration.value <= serviceToEdit.Duration)) {
          if ($scope.durations[idx].value === serviceToEdit.Duration) {
            selectedDuration = $scope.durations[idx];
            break;
          }

          idx++;
        }

        if (selectedDuration) {
          $scope.onlineSchedulingService.duration = selectedDuration;

        }

      }


      function loadServices() {
        Repository.Custom('Calendar').resolveResources().then(function (results) {
          $scope.$evalAsync(function () {
            setServices(results.byCategories || results.serviceMetadatas);
          });
        });

        function setServices(serviceMetadatas) {
          $scope.services.splice(1);
          var allServiceMetadatas = [];
          serviceMetadatas.forEach(function (customServicePresentation) {
            var serviceComboboxItem;
            if (!$scope.servicesByCategories) {
              serviceComboboxItem = serviceMetadataToComboboxItem(customServicePresentation)
              allServiceMetadatas.push(serviceComboboxItem);
            } else {
              var serviceComboItems = customServicePresentation.ServiceMetadatas.map(serviceMetadataToComboboxItem);
              allServiceMetadatas.pushAll(serviceComboItems);
              serviceComboboxItem = {
                valueType: 'Category',
                name: customServicePresentation.CategoryName,
                value: serviceComboItems
              };
            }

            if (parameters.service) {
              var selectedIds = parameters.service.ServiceMetadatas.map(function (serviceMetadata) {
                return serviceMetadata.ServiceMetadataId
              });

              var selectedItems = allServiceMetadatas.filter(function (comboItem) {
                return selectedIds.indexOf(comboItem.value.ServiceMetadataId) > -1;
              });


              $scope.$evalAsync(function () {
                $scope.onlineSchedulingService.services = selectedItems;
              });
            }

            $scope.services.push(serviceComboboxItem);
          });

          function serviceMetadataToComboboxItem(serviceMetadata) {
            return {
              name: serviceMetadata.Name,
              forceUniqueSelection: false,
              value: serviceMetadata
            }
          }
        }
      }

      function generateDurations() {
        let number = minutesBetween <= 10 ? 37 : 17;
        for (var i = 1; i < number; i++) {
          var minutes = i * minutesBetween;
          $scope.durations.push(minutesToDurationValue(minutes));
        }
      }

      function minutesToDurationValue(minutes) {
        return {
          name: timeConverter.secondsToString(minutes * 60),
          value: minutes
        }
      }

      function adaptDuration(duration) {
        var desiredValue = $scope.durations[duration / minutesBetween - 1];
        if (!desiredValue || desiredValue.value !== duration) {
          $scope.durations.push(minutesToDurationValue(duration));
        } else {
          $scope.onlineSchedulingService.duration = desiredValue;
        }

        return desiredValue;
      }

    });

}());
