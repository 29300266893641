<template>
  <div>
    <Grid :dataSource="dataSource" v-bind="gridProps" :height="'70vh'"></Grid>
  </div>
</template>

<script>
import { Grid, GridLabels } from "@tomeravni/easybizy-vue-components";

import PaymentLinksDS from "./payment.links.ds";
import {resolveDefaultGridLabels} from "../../vue/vue.helpers";

export default {
  components: {
    Grid,
  },
  props: {
    mediator: {
      type: Object
    }
  },
  data() {
    const labels = resolveDefaultGridLabels(this.$t);
    const api = this.Repository.Custom('Sessions').getPaymentLinks;

    return {
      dataSource: new PaymentLinksDS(api, this.$t, this.$stateManager, this.mediator, this.confirmService),
      gridProps: {
        freezeHeader: true,
        hideBorder: false,
        elevated: true,
        pickColumns: true,
        rowHeightMode: 'tight',
        backgroundColor: 'auto',
        preventScrollTopButton: false,
        enableFilter: true,
        isFilterVisible: true,
        forceFullHeight: true,
        labels
      }
    }
  },
  mounted() {

  },
  methods: {
  },

}
</script>
