import './create.punchcard.less';
import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT } from "../../constants";

angular.module('easybizy.easybizyModalViewsModel').controller('CreatePunchcardController',
  function ($scope, $modalInstance, saveAction, parameters, $timeout, $compile, localize, Repository, configuration) {
    var itemType = parameters.item.ConcreteType === "ServiceMetadata" ? localize.getLocalizedString("_Service_") : localize.getLocalizedString("_Product_");
    $scope.dialogTitle = localize.getLocalizedString("_CreatePunchcard_", itemType, parameters.item.Label);

    const configurations = configuration.get();
    const defaultSubscriptionExpirationDateInMonths = configurations.ProductsAndServicesSettings.DefaultSubscriptionExpirationDateInMonths?.Value ?? 12;
    $scope.punchCardProperties = {};
    $scope.punchCardTemplates = [{ name: localize.getLocalizedString("_SubscriptionTemplate_") }];
    $scope.punchCardProperties.buy = 5;
    $scope.punchCardProperties.get = 1;
    $scope.punchCardProperties.ExpirationDate = moment().add(defaultSubscriptionExpirationDateInMonths, 'M').format(DATE_DISPLAY_FORMAT);
    $scope.punchCardProperties.usePunchCard = !configurations.CashRegisterSettings.PreventFirstUsageOnCreatingSubscription;
    $scope.selectedInfoCategoriesWrapper = { selected: null };
    $scope.$watch('selectedInfoCategoriesWrapper.selected', updatePunchCardByTemplate);


    $scope.addNewCategoryAction = function (category) {
      var template = {
        name: category.name,
        title: $scope.punchCardProperties.Label,
        given: $scope.punchCardProperties.get,
        purchace: $scope.punchCardProperties.buy,
        price: $scope.punchCardProperties.finalPrice,
        ItemMetadataType: parameters.item.ConcreteType,
        ItemMetadataId: parameters.item.Id
      }

      Repository.Custom("CommonSubscriptions").post(template)
        .then(function (res) {
          let newTemplate = $scope.punchCardTemplates.find(x => x.name === res.Name);
          newTemplate.value = res;
          newTemplate.id = res.SubscriptionTemplateId;
        });
    };


    Repository.Custom("CommonSubscriptions").get(parameters.item.ConcreteType, parameters.item.Id)
      .then(function (res) {
        if (res.commonSubscriptions) {
          var result = res.commonSubscriptions;
          $scope.punchCardProperties.buy = result.PurchasedQuantity;
          $scope.punchCardProperties.get = result.GivenQuantity;
          //$scope.punchCardProperties.finalPrice = result.TotalPrice;
        }
        res.templates.forEach(function (template) {
          $scope.punchCardTemplates.push({
            name: template.Name,
            value: template,
            id: template.SubscriptionTemplateId
          });

        });
      });

    $scope.buyField = {
      fieldName: 'buy',
      model: $scope.punchCardProperties, type: "integer", min: 1, max: 100, delta: 1
    };

    $scope.getField = {
      fieldName: 'get',
      model: $scope.punchCardProperties, type: "integer", min: 0, max: 100, delta: 1
    };

    $scope.finalPrice = {
      fieldName: 'finalPrice',
      model: $scope.punchCardProperties, type: "float", min: 0, delta: 1
    };

    $scope.$watch('punchCardProperties.buy', function (newVal) {
      if (!$scope.onTemplateUpdate) {
        $scope.punchCardProperties.finalPrice = newVal * (parameters.item.PriceLists && parameters.item.PriceLists.length > 0 ? parameters.item.RetailPrice : parameters.item.OriginalRetailPrice);
        updatePunchCard();
      }
    });

    $scope.$watch('punchCardProperties.get', updatePunchCard);

    $scope.punchCardNameFieldMetadata = {
      fieldName: 'Label', icon: "icon icon-gift",
      placeholder: localize.getLocalizedString("_InvoiceTitle_"),
      validation: "{'required': true, 'max-length': 50}"
    };

    $scope.punchCardDateFieldMetadata = {
      fieldName: 'ExpirationDate', icon: "icon icon-calendar",
      placeholder: localize.getLocalizedString("_ExpirationDate_"),
      validation: "{'date': true, 'required': true}", type: 'date',
      yearRange: "0:+3",
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.customerName = parameters.customer.FirstName + " " + parameters.customer.LastName;

    $scope.validateActionWrapper = {};
    $scope.punchCardValidator = {};

    $scope.save = function () {
      var validationFields = $scope.validateActionWrapper.validate();
      if (validationFields.length != 0) { // is valid form?
        var localizedError = localize.getLocalizedString("_FieldIsIncorrect_",
          $scope.punchCardNameFieldMetadata.placeholder, validationFields[0][$scope.punchCardNameFieldMetadata.fieldName]);
        toastr.error(localizedError);
        return;
      }


      $scope.punchCardProperties.CustomerId = parameters.customer.CustomerId;
      
      saveAction({
        ...$scope.punchCardProperties,
        ExpirationDate: moment($scope.punchCardProperties.ExpirationDate, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT)
      });
      $scope.cancel();

    };

    function updatePunchCard() {
      if (!$scope.onTemplateUpdate) {
        if ($scope.punchCardProperties.get > 0) {
          $scope.punchCardProperties.Label = localize.getLocalizedString("_PunchcardTitle_", parameters.item.Label, (", " + $scope.punchCardProperties.get + " + " + $scope.punchCardProperties.buy));
        } else {
          $scope.punchCardProperties.Label = localize.getLocalizedString("_PunchcardTitle_", " " + $scope.punchCardProperties.buy + " ", parameters.item.Label);
        }
      }
    }

    function updatePunchCardByTemplate(template) {
      if (template && template.value && template.value.PurchasedQuantity) {
        $scope.onTemplateUpdate = true;
        $scope.punchCardProperties.buy = template.value.PurchasedQuantity;
        $scope.punchCardProperties.get = template.value.GivenQuantity;
        $scope.punchCardProperties.finalPrice = template.value.TotalPrice;
        $scope.punchCardProperties.Label = template.value.Title;
        $timeout(() => {
          $scope.onTemplateUpdate = false;
        });
      }
    }

    var deleteText = localize.getLocalizedString('_Delete_');
    var approvalText = localize.getLocalizedString('_Sure_');

    $scope.decorateTemplateCombo = function (scope, element) {
      var templateComboItem = scope.item;
      if (templateComboItem && templateComboItem.id) {
        scope.deleteText = deleteText;
        scope.approvalText = approvalText;

        var submitButton = '<div ng-click="removeTemplate($event, item)">{{approvalMode ? approvalText : deleteText}}</div>';

        scope.removeTemplate = function (e, item) {
          e.preventDefault();
          e.stopPropagation();

          if (!scope.approvalMode) {
            scope.approvalMode = true;
            return $timeout(function () {
              delete scope.approvalMode;
            }, 10000);
          }

          $scope.punchCardTemplates.splice($scope.punchCardTemplates.indexOf(item), 1);
          Repository.Custom("CommonSubscriptions").remove(item.id)
            .catch(function () {
              toastr.error(localize.getLocalizedString('_ErrorDeletingTemplate_'));
            });
        };

        var decorationItem = '<div class="combobox-template-remove-decorator">' + submitButton + '</div>';
        element.find('.combobox-list-item-concrete-value').append($compile(decorationItem)(scope));
      }
    };


  });
