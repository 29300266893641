import PNotify from 'pnotify/dist/es/PNotify';
import PNotifyButtons from 'pnotify/dist/es/PNotifyButtons';

(function () {
  // DEFINE TOASTR
  var toastr = {
    set options(value) {
      PNotify.defaults = Object.assign({}, PNotify.defaults, value);
    }
  };

  window.toastr = toastr;
  PNotifyButtons;


  // PNotify.prototype.options.title = false;
  // PNotify.prototype.options.hide = true;

  addToastrMethod('info');
  addToastrMethod('warning');
  addToastrMethod('error');
  addToastrMethod('success');

  function addToastrMethod(type) {
    toastr[type] = (function () {
      return function (text) {
        const notice = PNotify.alert({
          text: text,
          type: type !== 'warning' ? type : 'notice',
          addclass: 'stack-topleft alert-type-' + type,
          animate: {
            animate: true,
            in_class: 'zoomInLeft',
            out_class: 'zoomOutRight'
          }
        });


        $(notice.refs.elem).on('click', () => notice.remove());
      }
    }())
  }
}());
