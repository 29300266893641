import './meeting.history.modal.less'
angular.module('easybizy.easybizyModalViewsModel').controller('MeetingHistoryModalController',
  function ($scope, meetingId, $modalInstance) {

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
    
    $scope.mediator = {
      close: $scope.cancel,
      meetingId: meetingId
    }
  });
