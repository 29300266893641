import './price.quotes.modal.less'
import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT, SERVER_DATA_DATE_FORMAT } from "../../constants";
import { DelayedFunction } from "../../general/helpers";

angular.module('easybizy.easybizyModalViewsModel').controller('PriceQuotesModal',
  function ($scope, $modalInstance, localize, Repository, employees, digitalInvoiceCommon, stateManager, contextManager, $state, $timeout) {
    $scope.employees = [];
    $scope.selectedEmployee = {};
    employees.resolve($scope.employees, $scope.selectedEmployee, true);

    $scope.priceQuoteTypes = [
      { name: localize.getLocalizedString('_AllTypes_'), value: null },
      { name: localize.getLocalizedString('_OpenQuote_'), value: 'Open' },
      { name: localize.getLocalizedString('_ClosedQuote_'), value: 'Closed' },
      { name: localize.getLocalizedString('_CancelledQuote_'), value: 'Cancelled' }
    ];

    $scope.selectedPriceQuote = { selected: $scope.priceQuoteTypes[0] }
    $scope.filterByInvoicesDates = { value: false };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.dateRangePresets = [];
    generateImmediateRanges();
    generateMonthsInRange();
    $scope.dateRangeSelectedPresetWrapper = {};
    $scope.dateRangeSelectedPresetWrapper.dateRangeSelectedPreset = $scope.dateRangePresets[2];
    $scope.selectedRange = {};

    $scope.rangeMetadataFrom = {
      fieldName: 'from',
      icon: "icon icon-calendar",
      placeholder: "Start Date",
      validation: "{'required': true}",
      yearRange: "-7:+3",
      type: 'date'
    };
    $scope.rangeMetadataTo = {
      fieldName: 'to',
      icon: "icon icon-calendar",
      placeholder: "End Date",
      validation: "{'required': true}",
      yearRange: "-7:+3",
      type: 'date'
    };

    $scope.$watch('dateRangeSelectedPresetWrapper.dateRangeSelectedPreset', function (newVal) {
      if (newVal.value) {
        $scope.selectedRange.from = newVal.value.from;
        $scope.selectedRange.to = newVal.value.to;
      }
    });


    let searchText = '';
    $scope.$watchGroup([
      () => searchText, () => $scope.selectedRange.from, () => $scope.selectedRange.to,
      () => $scope.selectedEmployee.selected.value, () => $scope.selectedPriceQuote.selected.value, () => $scope.filterByInvoicesDates.value
    ],
      (newValues) => {
        const [searchText, from, to, employee, state] = newValues;
        $scope.lastValues = newValues;
        //console.log(searchText, from, to, employee, state)

        let mfrom = moment(from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
        let mto = moment(to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
        Repository.Custom("PriceQuote").getPriceQuotes(searchText, mfrom, mto, employee, state, $scope.filterByInvoicesDates.value).then(function (data) {
          $scope.priceQuotes = data;
          $scope.summingValues = { count: data.length, priceQuotesSum: 0, invoicesSum: 0, closedCount: 0 };
          data.forEach(function(item) {
            $scope.summingValues.closedCount += item.ResultedInvoiceId ? 1 : 0;
            $scope.summingValues.priceQuotesSum += item.TotalAmount;
            $scope.summingValues.invoicesSum += item.InvoiceTotalAmount;
          });

        })
          .catch(function (errorMessage) {
            toastr.error(localize.getLocalizedString("_ErrorGettingPriceQuotes_") + errorMessage.Message);
          });
      });

    const delayedCustomerSearch = new DelayedFunction((value) => $scope.$evalAsync(() => searchText = value), 750);
    $scope.searchWrapper = { text: '' };
    $scope.$watch('searchWrapper.text', (newVal => delayedCustomerSearch.set(newVal)));

    $scope.tableFields = [
      {
        name: localize.getLocalizedString("_PriceQuoteNumber_"),
        sortable: true,
        width: "5%",
        command: {
          get: function (entity) {
            return entity.PriceQuoteId;
          },
          sort: function () {
            return "PriceQuoteId";
          }
        }
      }, {
        name: localize.getLocalizedString("_CustomerName_"),
        sortable: true,
        width: "15%",
        command: {
          get: function (entity) {
            return entity.CustomerName;
          },
          sort: function () {
            return "CustomerName";
          }
        }
      }, {
        name: localize.getLocalizedString("_PriceQuoteTitle_"),
        sortable: true,
        width: "12%",
        command: {
          get: function (entity) {
            return entity.Title;
          },
          sort: function () {
            return "Title";
          }
        }
      }, {
        name: localize.getLocalizedString("_CreatedOn_"),
        orderedBy: true,
        width: "10%",
        command: {
          get: function (entity) {
            return moment(entity.CreatedOn, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT);
          },
          sort: function () {
            return "CreatedOn";
          }
        }
      }, {
        name: localize.getLocalizedString("_EmployeeName_"),
        sortable: true,
        width: "15%",
        command: {
          get: function (entity) {
            const relatedEmployee = ($scope.employees || []).find(x => x.id === entity.EmployeeId);
            return (relatedEmployee || {}).name;
          },
          sort: function () {
            return "EmployeeId";
          }
        }
      }, {
        name: localize.getLocalizedString("_Amount_"),
        sortable: true,
        width: "5%",
        command: {
          get: function (entity) {
            return entity.TotalAmount;
          },
          sort: function () {
            return "TotalAmount";
          }
        }
      }, {
        name: localize.getLocalizedString("_Status_"),
        sortable: true,
        width: "10%",
        command: {
          get: function (entity) {
            return localize.getLocalizedString("_" + entity.Status + "_");
          },
          sort: function () {
            return "Status";
          }
        }
      }, {
        name: localize.getLocalizedString("_Invoice_"),
        sortable: true,
        width: "10%",
        command: {
          get: function (entity) {
            return entity.InvoiceIndex;
          },
          sort: function () {
            return "InvoiceIndex";
          }
        }
      }, {
        name: localize.getLocalizedString("_Amount_"),
        sortable: true,
        width: "5%",
        command: {
          get: function (entity) {
            return entity.InvoiceTotalAmount + entity.DebtAmount;
          },
          sort: function () {
            return "InvoiceTotalAmount";
          }
        }
      }, {
        name: localize.getLocalizedString("_Paid_"),
        sortable: true,
        width: "5%",
        command: {
          get: function (entity) {
            return entity.InvoiceTotalAmount;
          },
          sort: function () {
            return "InvoiceTotalAmount";
          }
        }
      },
      {
        name: localize.getLocalizedString("_PriceQuoteClosedOn_"),
        sortable: true,
        width: "10%",
        command: {
          get: function (entity) {
            return entity.InvoiceCreatedOn ? moment(entity.InvoiceCreatedOn).format(DATE_DISPLAY_FORMAT) : null;
          },
          sort: function () {
            return "InvoiceCreatedOn";
          }
        }
      },
      {
        name: localize.getLocalizedString("_Actions_"),
        type: "Custom",
        template: `<div class="flex-container price-quote-actions"><div class="icon icon-send" ng-click="value(this, 'send')" tippy="_Send_"></div><div class="icon icon-disk" ng-click="value(this, 'load')" tippy="_Load_"></div></div>`,
        command: {
          get: function (entity) {
            return function (scope, action) {
              if (action === 'send') {
                const adaptedPriceQuote = {
                  PriceQuote: {
                    Customer: {
                      CustomerId: entity.CustomerId,
                      MobileFirst: entity.MobileFirst || ''
                    },
                    MailTo: entity.EmailAddress || '',
                    PriceQuoteId: entity.PriceQuoteId,
                    DocumentToken: entity.DocumentToken
                  },
                  Html: '<html></html>'
                };
                if (!entity.DocumentToken) {
                  Repository.Custom("PriceQuote").getDocumentToken(entity.PriceQuoteId).then((token) => {
                    entity.DocumentToken = adaptedPriceQuote.PriceQuote.DocumentToken = token;
                    digitalInvoiceCommon.sendPriceQuoteDialog(adaptedPriceQuote);
                  });
                } else {
                  digitalInvoiceCommon.sendPriceQuoteDialog(adaptedPriceQuote);
                }
              } else {
                // contextManager.set('priceQuote', { id: entity.PriceQuoteId, duplicate: false });
                // $state.go('CashRegister', {}, { reload: true });

                stateManager.setState('CashRegister', {
                  meetingId: undefined,
                  relativeDate: undefined,
                  sessionId: undefined,
                  customerId: undefined,
                  priceQuoteId: entity.PriceQuoteId,
                  defaultEmployeeId: undefined

                }, false, false);

                $modalInstance.close();
              }
            };
          }
        }
      }
    ];

    $scope.priceQuotes = [];
    $scope.trackByPriceQuote = (priceQuote) => priceQuote.PriceQuoteId;

    function generateImmediateRanges() {
      // TODAY.
      const now = moment();
      $scope.dateRangePresets.push({
        name: localize.getLocalizedString('_Today_'),
        value: {
          from: now.clone().format(DATE_DISPLAY_FORMAT),
          to: now.clone().format(DATE_DISPLAY_FORMAT)
        }
      });

      $scope.dateRangePresets.push({
        name: localize.getLocalizedString('_Yesterday_'),
        value: {
          from: now.clone().add(-1, 'days').format(DATE_DISPLAY_FORMAT),
          to: now.clone().add(-1, 'days').format(DATE_DISPLAY_FORMAT)
        }
      });
    }

    $scope.exportToExcel = function () {
      const [searchText, from, to, employee, state] = $scope.lastValues;
      let mfrom = moment(from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
      let mto = moment(to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
      Repository.Custom("PriceQuote").exportPriceQuotes(searchText, mfrom, mto, employee, state, $scope.filterByInvoicesDates.value);
    }

    function generateMonthsInRange() {
      var now = moment();
      for (var i = 0; i < 12; i++) {
        $scope.dateRangePresets.push({
          name: now.format("MMM YY"),
          value: {
            from: now.clone().date(1).format(DATE_DISPLAY_FORMAT),
            to: now.clone().add(1, 'months').date(0).format(DATE_DISPLAY_FORMAT)
          }
        });
        now = now.subtract(1, 'months');
      }
    }


  });
