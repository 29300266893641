import './promotion.send.summary.dialog.less';
import { DATE_DISPLAY_FORMAT, HOURS_AND_MINUTES_FORMAT } from "../../../constants";

angular.module('easybizy.promotions.widget').controller('PromotionSendSummaryDialogController',
  function ($scope, $modalInstance, localize, $timeout, $state, parameters, saveAction) {
    $scope.updateMode = parameters.updateMode;
    $scope.repeatingCampaign = parameters.repeating && localize.getLocalizedString('_RepeatingCampaign_');
    $scope.dialogTitle = localize.getLocalizedString($scope.updateMode ? '_UpdateCampaignSchedule_' : '_CreateNewCampaign_');
    $scope.send = function () {
      var validationFields = $scope.validateActionWrapper.validate();
      if (validationFields.length !== 0) { // is valid form?
        toastr.error(localize.getLocalizedString("_WrongCampaignName_"));
        return;
      }

      var timeToSend = getRelativeNow();

      if ($scope.selectedPromotionTiming.value.value === 'pickATime') {
        timeToSend = moment($scope.selectedTimeWrapper.sendingDate, DATE_DISPLAY_FORMAT).set({
          hour: $scope.selectedTimeWrapper.selected.value.get('hour'),
          minute: $scope.selectedTimeWrapper.selected.value.get('minute'),
          second: 0
        });
      }

      $scope.isSaving = true;
      saveAction($scope.promotionNameWrapper.PromotionName, timeToSend).then(function (data) {
        toastr.success(localize.getLocalizedString('_CampaignCreated_'));
        $scope.cancel();

        $timeout(function () {
          $state.go('ManagePromotions');
        }, 2000);
      })
        .catch(function (e) {
          $scope.isSaving = false;
          toastr.error(localize.getLocalizedString('_CampaignCreateError_'));
        });

    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.promotionNameFieldMetadata = {
      fieldName: 'PromotionName',
      icon: "icon icon-promote",
      placeholder: localize.getLocalizedString("_PromotionName_"),
      validation: "{'required': true, 'max-length': 40}"
    };

    $scope.promotionNameWrapper = { PromotionName: parameters.name };
    $scope.validateActionWrapper = {};
    $scope.promotionNameValidator = {};

    $scope.promotionTiming = [
      {
        name: parameters.repeating ? localize.getLocalizedString("_AtTime_", moment().format(HOURS_AND_MINUTES_FORMAT)) : localize.getLocalizedString("_Now_"),
        value: null
      },
      { name: localize.getLocalizedString("_PickATime_") + '...', value: 'pickATime' }
    ];

    if ($scope.updateMode) {
      $scope.promotionTiming.splice(0, 1);
    }

    $scope.promotionSendingDateMeta = {
      fieldName: 'sendingDate',
      icon: "icon icon-calendar",
      placeholder: localize.getLocalizedString('_ChooseDate_'),
      validation: "{'required': true}",
      yearRange: ":+1",
      minDate: "0",
      type: 'date'
    };


    $scope.selectedPromotionTiming = {};
    $scope.selectedPromotionTiming.value = $scope.promotionTiming[0];
    $scope.selectedTimeWrapper = { sendingDate: (parameters.startTime || moment()).format(DATE_DISPLAY_FORMAT) };
    $scope.selectedTimeWrapper.selected = null;
    $scope.timeOptions = [];

    $scope.$watch('selectedTimeWrapper.sendingDate',
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          updateSendingTime();
        }
      });

    updateSendingTime();

    function updateSendingTime() {
      $scope.timeOptions.length = 0;
      var timeDelta = 30;
      var relativeNow = getRelativeNow();

      var startTime = moment('08:00', HOURS_AND_MINUTES_FORMAT);
      var endTime = startTime.clone().add(-timeDelta, 'm').add(1, 'd');

      if (!parameters.repeating && !moment($scope.selectedTimeWrapper.sendingDate, DATE_DISPLAY_FORMAT).isAfter(moment().startOf('day'))) {
        startTime.set({
          'hour': relativeNow.minutes() > 30 ? relativeNow.hours() + 1 : relativeNow.hours(),
          'minute': relativeNow.minutes() > 30 ? 0 : 30,
          'second': 0
        });

        endTime = startTime.clone().set({ 'hours': 23, 'minute': 30, 'second': 0 });
        $timeout(() => {
          $scope.selectedTimeWrapper.selected = $scope.timeOptions[0];
        });

      }

      var midnight = ' (' + localize.getLocalizedString('_AtNight_') + ')';

      while (startTime.isSameOrBefore(endTime)) {
        var time = startTime.format(HOURS_AND_MINUTES_FORMAT);
        var hourAsInt = parseInt(startTime.format('HH'));

        var valueToInsert = {
          name: time + ((hourAsInt > 22 || hourAsInt < 8) ? midnight : ''),
          value: startTime.clone().date(relativeNow.date())
        };
        $scope.timeOptions.push(valueToInsert);
        let startToRelativeNowDiff = moment.duration(Math.abs(relativeNow.diff(startTime))).asMinutes();
        if (!$scope.selectedTimeWrapper.selected && startToRelativeNowDiff <= timeDelta && !startTime.isBefore(relativeNow)) {
          $scope.selectedTimeWrapper.selected = valueToInsert;
        }

        startTime = startTime.add(timeDelta, 'm');
        // Find the closest time to relativeNow.

      }
    }

    function getRelativeNow() {
      return parameters.startTime ? parameters.startTime.clone().set({
        'date': moment().date(),
        'month': moment().month(),
        'year': moment().year()
      }) : moment();
    }

  });
