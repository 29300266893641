'use strict';

/*
 * An AngularJS Localization Service
 *
 * Written by Jim Lavin
 * http://codingsmackdown.tv
 *
 */
(function () {
  var localPath = './';

  angular.module('localization', [])
    // localization service responsible for retrieving resource files from the server and
    // managing the translation dictionary
    .factory('localize', ['$http', '$rootScope', '$window', '$filter', function ($http, $rootScope, $window, $filter) {
      var k_DefaultLocalLanguageStorage = "local_dictionary";

      var localize = {
        // use the $window service to get the language of the user's browser
        language: $window.navigator.userLanguage || $window.navigator.language,
        // array to hold the localized resource string entries
        dictionary: [],
        // array to hold the localized resource string entries
        rarelyUseDictionary: [],
        // flag to indicate if the service hs loaded the resource file
        resourceFileLoaded: false,

        // success handler for all server communication
        successCallback: function (data) {
          // store the returned array in the dictionary
          localize.dictionary = Array.isArray(data) ? arrayToObject(data) : data;
          // set the flag that the resource are loaded
          localize.resourceFileLoaded = true;
          // broadcast that the file has been loaded
          $rootScope.$broadcast('localizeResourcesUpdates');


          function arrayToObject(arr) {
            var toReturn = {};
            for (var i = 0; i < arr.length; i++) {
              toReturn[arr[i].key] = arr[i];
            }

            return toReturn;
          }
        },

        // allows setting of language on the fly
        setLanguage: function (value) {
          var adaptedValue = value;
          // Globlize is using 'en' as the language and doesn't support en-US or other types of english.
          if (value === 'en-US') {
            adaptedValue = 'en';
          }

          if (value !== localize.language || !this.resourceFileLoaded) {
            localize.language = value;
            // TODO Mirgation.
            Globalize.culture(Globalize.cultures[adaptedValue]);
            // dateDisplayFormat = Globalize.culture().calendar.patterns.d.toUpperCase();
            moment.locale(Globalize.cultures[adaptedValue].language);
            localize.initLocalizedResources();
          }
        },

        // loads the language resource file from the server
        initLocalizedResources: function () {
          // build the url to retrieve the localized resource file
          //                var url = localPath + 'i18n/resources-locale_' + localize.language + '.js';

          var url = localPath + 'i18n/resources-locale_he_eng.json';

          if (!this.resourceFileLoaded) {
            if (this.isRightToLeft()) {
              $('body').addClass('right-to-left');
            } else {
              $('body').removeClass('right-to-left');
            }
          }

          var locallySavedDictionary = null;
          if (!$.localStorage.isSet(k_DefaultLocalLanguageStorage)) {
            loadLanguageFile(false);
          } else {
            locallySavedDictionary = $.localStorage.get(k_DefaultLocalLanguageStorage);
            localize.successCallback(locallySavedDictionary.data);
            loadLanguageFile(true);
          }

          function loadLanguageFile(async) {
            var language = localize.language;
            url = `${ window.intlURI }/locales/web?date=${ moment().format('YYMMDD') }`;

            $.ajax({
              url: url,
              dataType: 'json',
              method: "GET",
              async: async,
              success: function (data) {
                locallySavedDictionary = { language: language, data: data };
                $.localStorage.set(k_DefaultLocalLanguageStorage, locallySavedDictionary);
                localize.successCallback(locallySavedDictionary.data);
                setTimeagoValues();
              },
              error: function (e) {
                console.log("error loading dictionary file.", e);
              }
            });
          }

          function setTimeagoValues() {
            jQuery.timeago.settings.strings = {
              prefixAgo: localize.getLocalizedString("_timeAgoprefixAgo_"),
              prefixFromNow: localize.getLocalizedString("_timeAgoprefixFromNow_"),
              suffixAgo: localize.getLocalizedString("_timeAgosuffixAgo_"),//לפני",
              suffixFromNow: localize.getLocalizedString("_timeAgosuffixFromNow_"),//"מעכשיו",
              seconds: localize.getLocalizedString("_timeAgoseconds_"),//"פחות מדקה",
              minute: localize.getLocalizedString("_timeAgominute_"),//"דקה",
              minutes: localize.getLocalizedString("_timeAgominutes_"),//"%d דקות",
              hour: localize.getLocalizedString("_timeAgohour_"),//"שעה",
              hours: localize.getLocalizedString("_timeAgohours_"),//"%d שעות",
              day: localize.getLocalizedString("_timeAgoday_"),//"יום",
              days: localize.getLocalizedString("_timeAgodays_"),//"%d ימים",
              month: localize.getLocalizedString("_timeAgomonth_"),//"חודש",
              months: localize.getLocalizedString("_timeAgomonths_"),//"%d חודשים",
              year: localize.getLocalizedString("_timeAgoyear_"),//"שנה",
              years: localize.getLocalizedString("_timeAgoyears_")//"%d שנים"
            };
          }

        },

        // checks the dictionary for a localized resource string
        getLocalizedString: function (value/*otherParams*/) {
          // default the result to an empty string
          var result = '';

          // make sure the dictionary has valid data
          if ((localize.dictionary) && (Object.keys(localize.dictionary).length > 0)) {
            // use the filter service to only return those entries which match the value
            // and only take the first result
            var entry = localize.dictionary[value];

            // set the result
            if (!entry) {
              return value;
            }

            result = entry[this.language];

            // replace params with placeholders.
            if (arguments.length > 1) {
              var targuments = arguments;
              result = result.replace(/{\d*\}/gi, function (matched) {
                var valueToReplace = parseInt(matched.substring(1, matched.length - 1));
                return targuments[valueToReplace + 1];
              });
            }
          } else {
            throw ("dictionary was yet to be loaded! (localize.js - line 124)");
          }
          // return the value to the call
          return result;
        },
        initLocalizedRarelyResources: function () {
          var url = localPath + 'i18n/resources-rare-locale_he_eng.js?version=' + (window.build || '');
          loadRareUseLanguageFile(false);

          function loadRareUseLanguageFile(async) {
            $.ajax({
              url: url,
              dataType: 'json',
              async: async,
              method: "GET",
              success: function (data) {
                var asDictionary = {};
                for (var i = 0; i < data.length; i++) {
                  asDictionary[data[i].key] = data[i];
                }
                localize.rarelyUseDictionary = asDictionary;
              },
              error: function (e) {
                console.log("error loading rarely Use Dictionary file.");
              }
            });
          }
        },

        // checks the dictionary for a localized resource string
        getLocalizedRarelyUseString: function (value/*otherParams*/) {
          // default the result to an empty string
          var result = '';

          // make sure the dictionary has valid data
          if (!((localize.rarelyUseDictionary) && (Object.keys(localize.rarelyUseDictionary).length > 0))) {
            this.initLocalizedRarelyResources();
          }
          if ((localize.rarelyUseDictionary) && (Object.keys(localize.rarelyUseDictionary).length > 0)) {
            // use the filter service to only return those entries which match the value
            // and only take the first result
            var entry = localize.rarelyUseDictionary[value];

            // set the result
            result = (entry && entry[this.language]) || value;

            // replace params with placeholders.
            if (arguments.length > 1) {
              var targuments = arguments;
              result = result.replace(/{\d*\}/gi, function (matched) {
                var valueToReplace = parseInt(matched.substring(1, matched.length - 1));
                return targuments[valueToReplace + 1];
              });
            }
          } else {
            throw ("couldn't load rarely use dictionary!! ");
          }
          // return the value to the call
          return result;
        },
        isRightToLeft: function () {
          return this.language === 'he-IL';
        },
        isHebrew: function () {
          return localize.language === 'he-IL'
        }
      };

      // return the local instance when called
      return localize;
    }])
    // simple translation filter
    // usage {{ TOKEN | i18n }}
    .filter('i18n', ['localize', function (localize) {
      return function (input) {
        return localize.getLocalizedString(input);
      };
    }])
    // translation directive that can handle dynamic strings
    // updates the text value of the attached element
    // usage <span data-i18n="TOKEN" ></span>
    // or
    // <span data-i18n="TOKEN|VALUE1|VALUE2" ></span>
    .directive('i18n', ['localize', function (localize) {
      var i18nDirective = {
        restrict: "EAC",
        updateText: function (elm, token) {
          var values = token.split('|');
          if (values.length >= 1) {
            // construct the tag to insert into the element
            var tag = localize.getLocalizedString(values[0]);
            // update the element only if data was returned
            if ((tag !== null) && (tag !== undefined) && (tag !== '')) {
              if (values.length > 1) {
                for (var index = 1; index < values.length; index++) {
                  var target = '{' + (index - 1) + '}';
                  tag = tag.replace(target, values[index]);
                }
              }
              // insert the text into the element
              elm.text(tag);
            }
          }
        },

        link: function (scope, elm, attrs) {
          scope.$on('localizeResourcesUpdates', function () {
            i18nDirective.updateText(elm, attrs.i18n);
          });

          var observerd = attrs.$observe('i18n', function () {
            i18nDirective.updateText(elm, attrs.i18n);
            observerd();
          });
        }
      };

      return i18nDirective;
    }])
    // translation directive that can handle dynamic strings
    // updates the attribute value of the attached element
    // usage <span data-i18n-attr="TOKEN|ATTRIBUTE" ></span>
    // or
    // <span data-i18n-attr="TOKEN|ATTRIBUTE|VALUE1|VALUE2" ></span>
    .directive('i18nAttr', ['localize', function (localize) {
      var i18NAttrDirective = {
        restrict: "EAC",
        updateText: function (elm, token) {
          var values = token.split('|');
          // construct the tag to insert into the element
          var tag = localize.getLocalizedString(values[0]);
          // update the element only if data was returned
          if ((tag !== null) && (tag !== undefined) && (tag !== '')) {
            if (values.length > 2) {
              for (var index = 2; index < values.length; index++) {
                var target = '{' + (index - 2) + '}';
                tag = tag.replace(target, values[index]);
              }
            }
            // insert the text into the element
            elm.attr(values[1], tag);
          }
        },
        link: function (scope, elm, attrs) {
          scope.$on('localizeResourcesUpdated', function () {
            i18NAttrDirective.updateText(elm, attrs.i18nAttr);
          });

          attrs.$observe('i18nAttr', function (value) {
            i18NAttrDirective.updateText(elm, value);
          });
        }
      };

      return i18NAttrDirective;
    }]);
}());
