import './price.quote.approval.less'
import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT } from "../../constants";

angular.module('easybizy.easybizyModalViewsModel').controller('PriceQuoteApprovalController',
  function ($scope, $modalInstance, savedDelegate, params, localize, Repository) {
    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.approval = {};
    $scope.approval.dateApproved = moment().format(DATE_DISPLAY_FORMAT);


    $scope.dateApprovedField = {
      fieldName: 'dateApproved', icon: "icon icon-calendar", placeholder: localize.getLocalizedString("_ChooseDate_"),
      validation: "{'date': true, 'required': true}", type: 'date'
    };

    $scope.approverNameField = {
      fieldName: 'approvedBy', icon: "icon icon-clients-ol", placeholder: localize.getLocalizedString("_ApproverName_"),
      validation: "{'required': true}"
    };

    $scope.fields = [$scope.dateApprovedField, $scope.approverNameField];


    $scope.uploadImageWrapper = {};
    $scope.invalidContentWrapper = {};

    // validation
    $scope.approvalFieldValidator = {};
    $scope.approvalValidateAction = {};


    $scope.save = function () {
      var validationFields = $scope.approvalValidateAction.validate();
      if (validationFields.length != 0) { // is valid form?
        var wrongFieldName = $scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
        var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
        toastr.error(localizedError);
        return;
      }

      var approvalObj = {};
      approvalObj.PriceQuoteId = params.PriceQuoteId;
      approvalObj.ApprovedBy = $scope.approval.approvedBy;
      approvalObj.Remarks = $scope.approval.content;
      approvalObj.ApprovalDate = moment($scope.approval.dateApproved, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
      $scope.isSavingApproval = true;
      Repository.Custom("PriceQuote").approve(approvalObj)
        .then(function (result) {
          savedDelegate($modalInstance, result);
          $scope.isSavingApproval = false;
        })
        .catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorApprovingPriceQuote_"))
          $scope.isSavingApproval = false;
        })

    };

    $scope.insertImageWrapper = {};
    $scope.appendImage = function (base64Image) {
      var content = '<div class="centered-background-image" \
            style="background-image: url(\'' + base64Image + '\');"></div>';
      $scope.insertImageWrapper.insertContent(content);
    };
  });
