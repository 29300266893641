import './meeting.closing.modal.less'
angular.module('easybizy.easybizyModalViewsModel').controller('MeetingClosingModalController',
  function ($scope, $rootScope, $modalInstance, configuration,employees) {

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
    
    const configurations = configuration.get();

    $scope.mediator = {
      close: $scope.cancel,
      showPriceLists: configurations.ProductsAndServicesSettings.UsePriceLists,
      showServiceCost: configurations.ProductsAndServicesSettings.ShowServiceCost,
      showCreatedBy : configurations.EmployeesSettings.UseEmployeeCreatedMeeting,
      showRooms : configurations.CalendarSettings.EnableRooms,
      employees
    }
  });
