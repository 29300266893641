import './employee.shifts.modal.less';
import { DATE_DISPLAY_FORMAT } from "../../constants";

angular.module('easybizy.easybizyModalViewsModel').controller('EmployeeHoursModalController',
  function ($scope, $modalInstance, saveAction, localize, Repository, parameters, printerMediator, $rootScope) {
    $scope.shiftsHandler = {};

    const employeeFullName = parameters.employee.FirstName + " " + parameters.employee.LastName;
    $scope.employees = [{
      name: employeeFullName, value: parameters.employee.EmployeeId
    }];

    Repository.Entity("Employee").query().filter("IsDeactiveted eq false").get().then(function(employees) {
      employees.results.map(function(employee) {
        return {
          name: `${employee.FirstName} ${employee.LastName}`,
          value: employee.EmployeeId,
        };
      }).filter(({ value }) => value !== $scope.employeeId).forEach((employee) => $scope.employees.push(employee))
    });


    $scope.wrappedSelected = { selected: $scope.employees[0] };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.dateRangePresets = [];
    generateMonthsInRange();
    $scope.dateRangeSelectedPresetWrapper = {};
    $scope.dateRangeSelectedPresetWrapper.dateRangeSelectedPreset = $scope.dateRangePresets[0];

    $scope.selectedRange = {};

    $scope.rangeMetadataFrom = {
      fieldName: 'from',
      icon: "icon icon-calendar",
      placeholder: "Start Date",
      validation: "{'required': true}",
      yearRange: "-5:+3",
      type: 'date'
    };
    $scope.rangeMetadataTo = {
      fieldName: 'to',
      icon: "icon icon-calendar",
      placeholder: "End Date",
      validation: "{'required': true}",
      yearRange: "-5:+3",
      type: 'date'
    };

    // SEND EMAIL FIELDS:
    $scope.sendEmailValidateAction = {};
    $scope.sendEmailFieldValidator = {};
    $scope.emailAddressWrapper = { email: 'test@gmail.com' };
    $scope.isEmailVisible = false;
    $scope.sendByEmail = function () {
      var validationFields = $scope.sendEmailValidateAction.validate();
      if (validationFields.length != 0) { // is valid form?
        toastr.error(localize.getLocalizedString("_InvalidEmailAddress_"));
        return;
      }
      /// SEND BY EMAIL!
    };

    $scope.save = function (finalize) {
      if (!$scope.shiftsHandler.isValid) {
        toastr.warning(localize.getLocalizedString("_CantSaveInvalidShifts_"));
        return;
      }

      $scope.isSaving = true;
      var shiftsToServer = $scope.shiftsHandler.get(finalize);
      var deleted = shiftsToServer.deleted.map(function (shift) {
        return shift.EmployeeShiftId;
      });
      var deletedExceptions = shiftsToServer.deletedExceptions.map(function (shift) {
        return shift.EmployeeShiftId;
      });

      Repository.Custom("EmployeeHours").update($scope.wrappedSelected.selected.value,
        shiftsToServer.createdOrUpdated,
        deleted,
        deletedExceptions,
        shiftsToServer.shiftsToCommit)
        .then(function (response) {
          $scope.isSaving = false;
          if (!response) {
            toastr.success(localize.getLocalizedString("_ShiftsSaved_"));
            $rootScope.$broadcast('reload-shifts');
            //$scope.cancel();
          }
        })
        .catch(function (result) {
          $scope.isSaving = false;

         var message = result.data.map((error) => moment(error.DateTime).format(DATE_DISPLAY_FORMAT) + " - " + localize.getLocalizedString(error.Message)).join('\n');
          toastr.error(localize.getLocalizedString("_ErrorSavingShifts_", '\n' + message));
        });
    };

    $scope.$watch('dateRangeSelectedPresetWrapper.dateRangeSelectedPreset', function (newVal) {
      if (newVal.value) {
        $scope.selectedRange.from = newVal.value.from;
        $scope.selectedRange.to = newVal.value.to;
      }
    });

    $scope.exportToExcel = function () {
      if ($scope.shiftsHandler.anyShiftsInRange()) {
        var callback = function () {
        };
        var dictionary = $scope.shiftsHandler.getFieldNamesDictionary();
        Repository.Custom("EmployeeHours").exportDataToExcel($scope.wrappedSelected.selected.value, $scope.selectedRange.from, $scope.selectedRange.to, dictionary, callback);
      }
    };

    $scope.printShifts = function () {
      if ($scope.shiftsHandler.anyShiftsInRange()) {
        Repository.Custom("EmployeeHours").printShifts($scope.wrappedSelected.selected.value, $scope.selectedRange.from, $scope.selectedRange.to)
          .then(function (response) {
            printerMediator.print(response);
          })
          .catch(function (err) {
          });
      }
    };

    var handleEscapeEvent = function (e) {
      var charCode = e.charCode || e.keyCode || e.which;
      if (charCode === 27) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    };

    document.body.addEventListener("keydown", handleEscapeEvent);

    $scope.$on('$destroy', function () {
      document.body.removeEventListener("keydown", handleEscapeEvent);
    });


    function generateMonthsInRange() {
      var now = moment();
      for (var i = 0; i < 12; i++) {
        $scope.dateRangePresets.push({
          name: now.format("MMM YY"),
          value: {
            from: now.clone().date(1).format(DATE_DISPLAY_FORMAT),
            to: now.clone().add(1, 'months').date(0).format(DATE_DISPLAY_FORMAT)
          }
        });
        now = now.subtract(1, 'months');
      }
    }
  });
