import './report.widget.container.directive.less'

angular.module('easybizy.reports').directive('reportWidgetWrapper', [
  '$timeout', 'reportsSharedService', 'reportsDecorator', 'localize', 'Repository', '$window',
  function ($timeout, reportsSharedService, reportsDecorator, localize, Repository, $window) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        type: '=',
        editMode: '=',
        possibleGraphTypes: '=',
        graphChanged: '&',
        decorator: '=',
        visualization: '='
      },
      link: function (scope, element) {
        scope.isFullScreen = false;
        scope.isGraphMode = true;
        scope.tableData = [];
        scope.$watch('type', registerGraph);
        scope.$watch('editMode', function (newVal) {
          if (newVal) {
            scope.possibleGraphs = scope.possibleGraphTypes.slice(0);
            scope.possibleGraphs.unshift({ name: scope.title, value: scope.type });
            scope.selectGraph = scope.possibleGraphs[0];

          }
        });

        scope.dataApiWrapper = { api: null };
        scope.$watch('dataTableApi', function (newVal) {
          if (newVal) {
            scope.dataApiWrapper.api = newVal;
          }
        });

        scope.newGraphTypeSelected = function (newVal) {
          if (newVal.value !== scope.type) {
            scope.graphChanged()(scope.type, newVal.value);
          }
        };


        scope.toggleFullScreen = function () {
          scope.isFullScreen = !scope.isFullScreen;
          reportsSharedService.toggleFullScreen(scope.isFullScreen, scope.type);

          if (scope.isFullScreen) {
            element.closest('.tile').addClass("full-screen-report front-report");
            if ($window.navigator.isNotiPadSafari) {
              var parent = element.closest('.tile-content-wrapper');
              var em = parseFloat(getComputedStyle(parent[0]).fontSize);
              parent.css({ 'height': window.innerHeight - (em * 8.5), 'width': window.innerWidth - (2 * em) });
            } else {
              var currentPosition = element.closest('.tile-content-wrapper').position();
              element.closest('.tile-content-wrapper').css({ left: currentPosition.left, top: currentPosition.top });
            }


            $timeout(function () {
              element.closest('.tile').addClass("add-transition");
            }, 100);

            $timeout(function () {
              element.closest('.tile-content-wrapper').removeAttr('style');
              element.closest('.tile').removeClass('add-transition').addClass('added');
            }, 1000);


            $timeout(scope.resolveTableData);
          } else {
            if (scope.selectedView != scope.possibleViews[1]) {
              scope.selectedView = scope.possibleViews[1];
            }

            element.closest('.tile').addClass("add-transition");


            setTimeout(function () {
              element.closest('.tile').removeClass('added');
              element.closest('.tile').removeClass("full-screen-report")
            });

            $timeout(function () {
              element.closest('.tile').removeClass("add-transition");
            }, 1000);
          }
        };

        scope.getCurrentDate = function () {
          return scope.dateRange ? scope.dateRange : reportsSharedService.currentDate();
        };

        scope.possibleViews = [
          { icon: "glyphicon glyphicon-th-list", text: localize.getLocalizedString('_ListView_'), type: "list" },
          { icon: "glyphicon glyphicon-stats", text: localize.getLocalizedString('_ChartView_'), type: "chart" }
        ];

        scope.selectedView = scope.possibleViews[1];

        var graphRegistration;

        function registerGraph() {
          if (graphRegistration) {
            reportsSharedService.unregisterGraph(graphRegistration);
          }

          var graphRegistration = {
            type: scope.type,
            dateChanged: function () {
              scope.isDirty = true;
              scope.generateGraph();
              if (scope.isFullScreen && scope.dataTableApi) {
                $timeout(scope.dataTableApi.forceUpdate);
              }
            }
          };

          reportsSharedService.registerGraph(graphRegistration);
          scope.decorator.decorate(scope.type)(scope, element);
        }

        scope.isGeneratingExcel = false;
        scope.exportToExcel = function () {
          scope.isGeneratingExcel = true;
          var result = {};
          angular.forEach(scope.fields, function (field) {
            result[field.command.sort()] = field.name;
          });


          var dateResolver = function () {
            return scope.getCurrentDate();
          };

          var callback = function () {
            scope.isGeneratingExcel = false;
          };


          Repository.Custom("ReportsRepository").exportToExcel(dateResolver, scope.type, result, callback);
        };

      },
      template: require('./report.widget.container.directive.tpl.html')
    };
  }]);

