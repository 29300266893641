import './add.shifts.modal.less';
import './shift.row.directive';

(function () {
  angular.module('easybizy.easybizyModalViewsModel').controller('AddShiftsController',
    function ($scope, $modalInstance, saveAction, parameters, $timeout, localize, onlineSchedulingAvailabilityStore, confirmService) {
      $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
      };

      var dayDateFormat = 'DD MMM YYYY';
      var date = parameters.date;
      var promptUpdateFuture = false;


      $scope.header = localize.getLocalizedString('_AddShiftsModalHeader_', parameters.date.format(dayDateFormat), parameters.employee.name);
      onlineSchedulingAvailabilityStore.availabilityByDateAsync(date).then(setAvailability).catch(setAvailability);

      $scope.repeatEndDateField = {
        fieldName: 'until',
        placeholder: localize.getLocalizedString("_EndsOn_"),
        validation: "{'date': true}",
        type: 'date',
        defaultStartDate: "+1w",
        minDate: date.clone().add(7, 'days').toDate(),
        yearRange: "0:+1"
      };

      $scope.validateActionWrapper = {};
      $scope.endDateValidation = {};
      $scope.savingWrapper = { isSaving: false };
      $scope.deletingWrapper = { isDeleting: false };


      $scope.save = function () {
        if (promptUpdateFuture) {
          confirmService.confirm(localize.getLocalizedString('_UpdateFutureAvailability_'),
            null, function () {
              save(true);
            }, function () {
              save(false);
            }, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_'));
        } else {
          save(false);
        }
      };


      $scope.delete = function (updateFutureAvailabilities) {
        $scope.deletingWrapper.isDeleting = true;
        onlineSchedulingAvailabilityStore.deleteAvailability($scope.availability, updateFutureAvailabilities).then(function () {
          $scope.deletingWrapper.isDeleting = false;
          toastr.success(localize.getLocalizedString('_EntityDeleteSuccessfully_'));
          $scope.cancel();
        }).catch(function (err) {
          $scope.deletingWrapper.isDeleting = false;
          toastr.error(localize.getLocalizedString('_ErrorDeletingEntity_') + ' - ' + err.Message);
        });
      };

      function save(updateFutureAvailabilities) {
        $scope.savingWrapper.isSaving = true;
        onlineSchedulingAvailabilityStore.createOrUpdateAvailability($scope.availability, updateFutureAvailabilities).then(function () {
          $scope.savingWrapper.isSaving = false;
          toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'));
          $scope.cancel();
        }).catch(function (err) {
          $scope.savingWrapper.isSaving = false;
          toastr.error(localize.getLocalizedString('_ErrorUpdatingShifts_') + ' - ' + err.Message);
        });

      }

      function setAvailability() {
        $scope.availability = onlineSchedulingAvailabilityStore.availabilityByDate[onlineSchedulingAvailabilityStore.toDateKey(date)]
          || onlineSchedulingAvailabilityStore.generateAvailabilityForDate(date);

        if ($scope.availability.id) {
          promptUpdateFuture = $scope.availability.RepeatWeekly;
          $scope.availability = new onlineSchedulingAvailabilityStore.OnlineBookingAvailability($scope.availability);
        }

      }
    });


}());
