import './material.tab.directive.less'
import {setIfDifferent} from "@tomeravni/easybizy-js-common/common";

(function () {
  'use strict';

  angular.module('easybizy.common.common-controls').directive('materialTabs', [
    'localize', function (localize) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          tabsMetadata: '=',
          selectedTabWrapper: '=',
          lazy: '='
        },
        transclude: true,
        controller: function ($scope) {
          Object.defineProperty(this, 'currentTab', {
            get: function () {
              return $scope.selectedTabWrapper.selected;
            }
          })

          this.lazy = !!$scope.lazy;
          $scope.$watch('lazy', (newVal) => {
            this.lazy = !!newVal;
          })
        },
        link: function (scope, element, attrs) {
          scope.selectTab = function (tabMeta) {
            scope.selectedTabWrapper.selected = tabMeta;
          };

          var isRightToLeft = localize.isRightToLeft();

          Object.defineProperty(scope, 'slideStyle', {
            get: function () {
              var indentationValue = (scope.tabsMetadata.indexOf(scope.selectedTabWrapper.selected) * (100 / scope.tabsMetadata.length)) + '%';

              return {
                width: (100 / scope.tabsMetadata.length) + '%',
                right: !isRightToLeft ? 'initial' : indentationValue,
                left: isRightToLeft ? 'initial' : indentationValue
              }
            }
          })
        },
        template: require('./material.tab.directive.tpl.html')
      }
    }]);

  angular.module('easybizy.common.common-controls').directive('materialTab', [
    'localize', function (localize) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
          meta: '='
        },
        require: '^materialTabs',
        link: function (scope, element, attrs, controller) {
          scope.didLoad = !controller.lazy;
          Object.defineProperty(scope, 'isVisible', {
            get: function () {
              let isVisible = scope.meta === controller.currentTab;
              if (isVisible) {
                setIfDifferent(scope, 'didLoad', true)
              }

              return isVisible;

            }
          });
        },
        template: '<div class="internal-tab-content" ng-show="isVisible">\
                      <div ng-transclude ng-if="didLoad"></div>\
                   </div>'

      }
    }]);


}());
