var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard-tile-selection-wrapper" }, [
    _c(
      "div",
      { staticClass: "wizard-tile-selection" },
      _vm._l(_vm.items, function (item) {
        return _c(
          "div",
          {
            staticClass: "wizard-tile-selection__item-wrapper relative",
            class: _vm.itemClass(item),
            on: {
              click: function ($event) {
                return _vm.select(item)
              },
            },
          },
          [
            _c("div", { staticClass: "wizard-tile-selection__item" }, [
              item.icon
                ? _c("div", {
                    staticClass: "wizard-tile-selection__item-icon icon",
                    class: "icon-" + item.icon,
                  })
                : _vm._e(),
              _vm._v(" "),
              item.img
                ? _c("img", {
                    staticClass: "wizard-tile-selection__item-img",
                    attrs: { src: item.img },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("h3", { staticClass: "wizard-tile-selection__item-text" }, [
                _vm._v(_vm._s(item.he || item.name)),
              ]),
            ]),
            _vm._v(" "),
            _vm.multiSelect
              ? _c(
                  "div",
                  {
                    staticClass:
                      "wizard-tile-selection__multi-select-toggle flex-container flex-center",
                    class: _vm.isSelected(item),
                  },
                  [_c("div", { staticClass: "icon icon-check" })]
                )
              : _vm._e(),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }