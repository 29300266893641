import './simple.date.picker.directive.less'

(function () {
  'use strict';

  angular.module('easybizy.common.common-controls').directive('simpleDatePicker', [
    'localize', function (localize) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          currentDate: '=',
          dateChanged: '=',
          daysOfWork: '='

        },
        link: function (scope) {
          scope.$watch('currentDate', setMonth);

          scope.next = function (prev) {
            const addition = (localize.isRightToLeft() ? 1 : -1) * (angular.isDefined(prev) ? prev : 1);
            setMonth(scope.currentMonth.clone().add(addition, 'month'));
          };

          scope.prev = scope.next.bind(scope, -1);
          scope.daysName = calculateDaysName();

          scope.isToday = function (date) {
            return date && moment().isSameDate(date);
          };

          scope.isSelectedDate = function (date) {
            return date && scope.currentDate.isSameDate(date);
          };

          scope.isDayOfWorkAvailable = (dayOfWeek) => {
            return (scope.daysOfWork || []).length !== 7 || scope.daysOfWork[dayOfWeek].IsEnabled;
          };

          function setMonth(date) {
            scope.currentMonth = date.clone();
            scope.currentMonthText = date.format('MMMM YYYY');
            scope.weeks = calculateWeeks();
          }

          function calculateDaysName() {
            var firstDay = moment().add(-moment().weekday(), 'days'), toReturn = [];
            for (var i = 0; i < 7; i++) {
              toReturn.push(firstDay.format('dd'));
              firstDay = firstDay.add(1, 'd');
            }

            return toReturn;
          }


          function calculateWeeks() {
            var firstDayOfMonth = scope.currentMonth.clone().startOf('month');
            // lastDayOfMonth = scope.currentMonth.clone().endOf('month');

            var firstDayOfMonthWeekday = firstDayOfMonth.weekday();
            // var lastWeekAddition = (6 - lastDayOfMonth.weekday());
            var amountOfDaysInMonth = scope.currentMonth.endOf('month').date();

            // var perfectWeeksTotalAmount = firstDayOfMonthWeekday + lastWeekAddition + amountOfDaysInMonth;
            var weeks = [], weekIndex = 0, dateItr = firstDayOfMonth.clone();

            for (var i = firstDayOfMonthWeekday; i < firstDayOfMonthWeekday + amountOfDaysInMonth; i++) {
              if (!weeks[weekIndex]) {
                weeks[weekIndex] = new Array(7);
              }

              var actualWeekDay = i % 7;

              weeks[weekIndex][actualWeekDay] = dateItr.clone();
              dateItr = dateItr.add(1, 'day');

              if (actualWeekDay === 6) { // new week when it's the 7th day.
                weekIndex++;
              }
            }

            return weeks;
          }
        },
        template: require('./simple.date.picker.directive.html')
      }
    }]);

}());
