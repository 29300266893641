import './resource.carousel.directive.less'

angular.module('easybizy.resources.management').directive('resourceCarousel', [
    'localize', function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                resources: '=',
                selectedResource: '=',
                emptyPlaceholder: '=',
                addResourceDelegate: '=',
                deleteResourceDelegate: '=',
                editResourceDelegate: '=',
                theme: '@'
            },
            link: function (scope, element, attrs) {
                scope.$watchCollection('resources', function (newVal) {
                    if (!newVal || newVal.length < 1) {
                        return;
                    }

                    // resourcesWatcher();

                    // element.find('.resource-types-top').sortable({
                    //   placeholder: "new-resource-type-placeholder",
                    //   axis: "x",
                    //   cursor: "move",
                    //   scroll: true,
                    //   items: "> .resource-type"
                    // });
                });

                scope.edit = function ($event, resource) {
                    $event.stopPropagation();
                    $event.preventDefault();

                    scope.editResourceDelegate(resource);
                };

                scope.delete = function ($event, resource) {
                    $event.stopPropagation();
                    $event.preventDefault();
                    scope.deleteResourceDelegate(resource).then(function (deleted) {
                        if (deleted) {
                            scope.resources.remove(resource);
                        }
                    });
                };

                if (scope.theme) {
                    element.addClass('theme-' + scope.theme);
                }

                scope.selectResourceType = function (resource) {
                    scope.selectedResource = resource;
                };
            },
            template: require('./resource.carousel.directive.tpl.html')
        };
    }]);
