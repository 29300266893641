import { MAIN_MODULE } from "../constants";
import { isConcreteValue, isNonEmptyString } from "@tomeravni/easybizy-js-common/common";
import { countSpecificChar } from "./helpers";

MAIN_MODULE.factory('internalMessage', function () {
  var myListener = null;
  return {
    beforeMove: function () {
      myListener();
    },
    listener: function (listener) {
      myListener = listener;
    }
  };
});

MAIN_MODULE.run(function (runtimeStates, configuration, stateManager, Repository, $rootScope, localize) {
  var configurations = configuration.get();
  configuration.getPersonalAsync();
  if (configurations && configurations.GeneralSettings.EnableLeads) {

    $rootScope.$on('new-lead', (e, data) => {
      toastr.success(`${localize.getLocalizedString('_NewLeadReceived_')} - ${data.firstName}`)
    });

    runtimeStates.addState('Leads', {
      title: '_Leads_',
      url: '/leads?' + stateManager.getParamsForStateAsQueryParams('Leads'),
      params: stateManager.getParamsForState('Leads'),
      template: require('../partial-views/leads/new.leads.tpl.html'),
      controller: 'NewLeadsController',
      isNavigationRoute: true,
      icon: 'icon icon-leads',
      order: 8,
      resolve: {
        loggedInUser:
          ['configuration', (configuration) => {
            return configuration.loggedInUserAsPromise;
          }],
        customFilterData: [
          'Repository', function (Repository) {
            return Promise.all([Repository.Entity('ArrivalSource').query().get(), Repository.Custom('EntitiesLazyRepository').tags('Lead').get()]).then((results) => {
              const [arrivalSources, tags] = results;
              return { arrivalSources, tags };
            }).catch((err) => {
              toastr.error('Error loading arrival sources.')
              return err;
            })
          }
        ],
        isValidWhatsappAccount: [
          'configuration', (configuration) => {
            return configuration.isValidWhatsappAccount();
          }]
      }
    });

    runtimeStates.addState('LeadDetails', {
      title: '_LeadDetails_',
      url: '/leads/:leadId',
      template: require('../partial-views/lead-details/lead.details.tpl.html'),
      controller: 'LeadDetailsController',
      resolve: {
        isValidWhatsappAccount: [
          'configuration', (configuration) => {
            return configuration.isValidWhatsappAccount();
          }]
      }
    })

  }

  if (configurations && configurations.GeneralSettings.EnableOnlineScheduling) {
    runtimeStates.addState('OnlineScheduling', {
      title: '_OnlineScheduling_',
      url: '/online-scheduling?' + stateManager.getParamsForStateAsQueryParams('OnlineScheduling'),
      params: stateManager.getParamsForState('OnlineScheduling'),
      template: require('../partial-views/online-scheduling/online.scheduling.tpl.html'),
      controller: 'OnlineSchedulingController',
      isNavigationRoute: true,
      icon: 'icon icon-online-scheduling-meeting-approved',
      order: 13
    });
  }

  if (configurations && configurations.GeneralSettings.EnableResourcesManagement) {
    runtimeStates.addState('ResourcesManagement', {
      title: '_ResourcesManagement_',
      url: '/resources-management?' + stateManager.getParamsForStateAsQueryParams('ResourcesManagement'),
      params: stateManager.getParamsForState('ResourcesManagement'),
      template: require('../partial-views/resources-management/resources.management.tpl.html'),
      controller: 'ResourcesManagementController',
      isNavigationRoute: true,
      icon: 'icon icon-resources',
      order: 12
    });
  }

  runtimeStates.addState('Conversations', {
    title: '_Conversations_',
    url: '/conversations?' + stateManager.getParamsForStateAsQueryParams('Conversations'),
    params: stateManager.getParamsForState('Conversations'),
    template: require('../partial-views/conversations/conversations.tpl.html'),
    controller: 'ConversationsController',
    isNavigationRoute: true,
    icon: 'icon icon-conversations',
    order: 13
  });

  configuration.loggedInUser.then((res) => {
    if (!res.facebookLoginEnabled) {
      runtimeStates.removeState('Conversations', null)
      $rootScope.$emit('states-loaded', true);
    }
  })


  runtimeStates.addState('Forms', {
    title: '_Forms_',
    url: '/forms?' + stateManager.getParamsForStateAsQueryParams('Forms'),
    params: stateManager.getParamsForState('Forms'),
    template: require('../partial-views/forms/forms.tpl.html'),
    controller: 'FormsController',
    isNavigationRoute: true,
    icon: 'icon icon-forms',
    order: 14
  });

});


MAIN_MODULE.run([
  '$rootScope', '$timeout', '$injector', 'analytics', '$transitions', '$sce',
  function ($rootScope, $timeout, $injector, analytics, $transitions, $sce) {
    var localize = null;

    $transitions.onStart({}, function (trans) {
      if (!localize) {
        localize = $injector.get('localize');
      }

      var toState = trans.$to();
      var fromState = trans.$from();

      if (!toState.hasOwnProperty('localizedName')) {
        toState.localizedName = localize.getLocalizedString(toState.title);
      }

      $timeout(function () {
        $rootScope.viewName = toState.localizedName;
      });

      $('body').removeClass('view-' + fromState.name.toDash().toLowerCase());
      $('body').addClass('view-' + toState.name.toDash().toLowerCase());
    });


    $rootScope.constructor.prototype.$watchOnce = function (toWatch, callback) {
      var watcher = this.$watch(toWatch, function (newVal, oldVal) {
        if (angular.isDefined(newVal)) {
          watcher();
          callback(newVal, oldVal);
        }
      });
    };

    $rootScope.constructor.prototype.$applyIfNeeded = function (toApply) {
      this.$evalAsync(toApply);
    };

    $rootScope.constructor.prototype.isEmpty = function (obj) {
      return $.isEmptyObject(obj)
    };

    $rootScope.constructor.prototype.$digestIfNeeded = function () {
    };

    $rootScope.constructor.prototype.trustSrc = function (src) {
      return $sce.trustAsResourceUrl(src);

    };

    $rootScope.constructor.prototype.Math = Math;

    window.translate = (...args) => {
      return $injector.get('localize').getLocalizedString(...args);
    }

    let lastTarget;
    $(document).on('animationend transitionend', '.modal-dialog', function (e) {
      if (lastTarget !== e.currentTarget) {
        lastTarget = e.currentTarget;
        $rootScope.$broadcast('modal-presented');
      }
    });

    toastr.options = {
      "positionClass": "toast-bottom-full-width",
      "onclick": null,
      "closeButton": true,
      "showDuration": "300",
      "hideDuration": "700",
      "timeOut": "10000",
      "extendedTimeOut": "5000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut",
      modules: {
        Buttons: {
          sticker: false,
          closer: false
        }
      }
    };

    $.sessionStorage.remove('currentCustomer');
  }]);

MAIN_MODULE.directive('delayedHref', [
  '$timeout', '$window', 'internalMessage', function ($timeout, $window, internalMessage) {
    return {
      restrict: 'A',
      link: function (scope, element) {
        element.click(function () {
          internalMessage.beforeMove();
          $timeout(function () {
            $window.location.href = element.attr('href');
          }, 250);

          return false;
        });
      }
    };
  }]);

/*
 This part emulates the touch and mouse down so the user can experience the flat yet visible click
 */
MAIN_MODULE.directive('ngClick', function ($rootScope, $timeout) {
  return {
    restrict: 'A',
    priority: 100, // give it higher priority than built-in ng-click
    link: function (scope, element, attrs) {
      if (attrs.disableClickEffect === 'true' || element.parent().hasClass('disable-child-click-effect') ||
        element.parents().hasClass('disable-descendants-click-effect').length) {
        return;
      }

      var elementToStyle = angular.isDefined(attrs.clickEffectTarget) ? element.closest(attrs.clickEffectTarget) : element;
      var elementClass = element.attr('class');
      elementClass = elementClass ? elementClass : "";
      var foreground = (angular.isDefined(attrs.foregroundClick) && attrs.foregroundClick === 'true')
        || elementClass.indexOf('icon-') >= 0 || elementClass.indexOf('icon-holder') >= 0;

      element.on('mousedown.clickableElement touchstart.clickableElement', function (e) {
        if (attrs.disableClickEffect === 'true') {
          return;
        }


        elementToStyle.addClass(foreground ? 'generic-click-foreground-animation' : 'generic-click-background-animation');
        $timeout(function () {
          elementToStyle.addClass('generic-click-animation');
        });

        $(document).on('touchend.clickableElement mouseup.clickableElement touchcancel.clickableElement', function (e) {
          elementToStyle.removeClass('generic-click-background-animation generic-click-foreground-animation');
          $timeout(function () {
            elementToStyle.removeClass('generic-click-animation');
          }, 400);

          $(this).off('touchend.clickableElement mouseup.clickableElement touchcancel.clickableElement');
          if (attrs.extraTouchSensitive == "true" &&
            ((typeof TouchEvent != "undefined" && e.originalEvent instanceof TouchEvent) || (element[0] != e.target && element.has(e.target).length == 0))) {
            e.stopImmediatePropagation();
            e.preventDefault();
            e.stopPropagation();
            scope.$evalAsync(attrs.ngClick);
          }
          //                        element[0] != e.target && element.has(e.target).length == 0) {
          //                        scope.$apply(attrs.ngClick);
          //                    }
        });


      });

      scope.$on('$destroy', function () {
        $(this).off('mousedown.clickableElement touchstart.clickableElement touchend.clickableElement mouseup.clickableElement touchcancel.clickableElement touchmove.clickableElement mousemove.clickableElement');
      });
    }
  };
});

MAIN_MODULE.directive('ngSrc', function ($timeout) {
  return {
    restrict: 'A',
    priority: 100, // give it higher priority than built-in ng-click
    link: function (scope, element, attrs) {
      element.addClass('hide-image hide-image-animate');
      element.on('load.ngSrcPlugin', function () {
        element.off('load.ngSrcPlugin');
        element.removeClass('hide-image');
        $timeout(function () {
          element.removeClass('hide-image-animate');
        }, 1000);
      });
    }
  };
});


MAIN_MODULE.factory('browser', [
  '$window', function ($window) {
    var userAgent = $window.navigator.userAgent;
    var browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /trident/i };

    $window.navigator.isNotiPadSafari = /^((?!chrome|android).)*safari/i.test(userAgent) && navigator.platform !== 'iPad';

    var toReturn = "";
    for (var key in browsers) {
      if (browsers[key].test(userAgent)) {
        toReturn = key;
        break;
      }
    }

    return toReturn;
  }]);

MAIN_MODULE.factory('onlineStatus', [
  "$window", "$rootScope", function ($window, $rootScope) {
    var onlineStatus = {};
    var observers = [];
    onlineStatus.register = function (func) {
      observers.push(func);
    };

    onlineStatus.onLine = $window.navigator.onLine;

    onlineStatus.isOnline = function () {
      return onlineStatus.onLine;
    };

    $window.addEventListener("online", function () {
      console.log("online");
      onlineStatus.onLine = true;
      angular.forEach(observers, function (observer) {
        observer(true);
      });
    }, true);

    $window.addEventListener("offline", function () {
      console.log("offline");
      onlineStatus.onLine = false;
      angular.forEach(observers, function (observer) {
        observer(false);
      });
    }, true);

    return onlineStatus;
  }]);

MAIN_MODULE.run(function ($uiRouter, configuration, $rootScope, $state, pci) {
  configuration.getPersonalAsync().then(function (result) {
    if (result && result.hiddenViews && result.hiddenViews.length > 0) {
      angular.forEach(result.hiddenViews, function (view) {
        try {
          $uiRouter.stateRegistry.deregister(view);
        } catch (err) {
          /// View might not be registered at all.
        }
      });

      const isRouteExposedToUser = Object.keys($uiRouter.stateRegistry.states).some((stateName) => {
        return isConcreteValue($uiRouter.stateRegistry.states[stateName].url.exec(window.location.pathname));
      });

      if (!isRouteExposedToUser) {
        $state.go(($uiRouter.stateRegistry.states["Calendar"] || $uiRouter.stateRegistry.states["CashRegister"] || $uiRouter.stateRegistry.states["Customers"]).name);
      }
    }

  }).finally(function () {
    $rootScope.$emit('states-loaded', true);
  });
});

// UI-ROUTER TRANSITIONS DEBUGGER.
// easybizyMainModule.run(function ($trace) {
//   $trace.enable('TRANSITION');
// });

MAIN_MODULE.config(function ($provide) {
  $provide.decorator('$rootScope', [
    '$delegate', function ($delegate) {
      $delegate.__proto__.randomInt = function () {
        return Math.floor(Math.random() * Math.random() * 100000000000);
      };

      $delegate.__proto__.preventEventBubbling = function (e) {
        e.preventDefault();
        e.stopPropagation();
      };


      var localizeService;
      $delegate.__proto__.localize = function (string) {
        if (!localizeService) {
          localizeService = $injector.get('localize');
        }

        var parsedArguments;
        if (arguments.length > 1) {
          parsedArguments = [];
          angular.forEach(arguments, function (val) {
            parsedArguments.push(val);
          });
          parsedArguments = parsedArguments.slice(1);
        } else {
          parsedArguments = null;
        }

        return localizeService.getLocalizedString(string, arguments.length > 1 ? parsedArguments : null);
      };

      return $delegate;
    }]);

  $provide.decorator('$controller', [
    '$delegate',
    function ($delegate) {
      return function (constructor, locals) {
        if (typeof constructor === "string") {
          locals.$scope.controllerName = constructor;
        }

        return $delegate.apply(this, [].slice.call(arguments));
      }
    }]);


  $provide.decorator('$modal', [
    '$controller', '$delegate', '$injector', '$modalStack', '$q',
    function modalDecorator($controller, $delegate, $injector, $modalStack, $q) {
      var oldOpen = $delegate.open;
      var shouldAuthorize = new modalAuthorizer();
      var $body = $('body');
      $delegate.open = function (options) {
        var controllerName = angular.isFunction(options.controller) ? options.controller.name : options.controller;
        var authorizationName = options.authorizationName ? options.authorizationName :
          controllerName;
        shouldAuthorize.authorize(authorizationName, options.forceAuthorization).then(function () {
          //console.log($('.modal-dialog'));
          var animationEvent = 'DOMNodeInserted.' + Math.floor(Math.random() * 1000000000);
          $body.on(animationEvent, '.modal-dialog', function () {
            $body.off(animationEvent);
            var self = $(this);
            var randomInt = Math.floor(Math.random() * 1000000000);
            var modalAnimationEvent = 'transitionend.' + randomInt + ' oTransitionEnd.' + randomInt + ' transitionend.' + randomInt + ' webkitTransitionEnd.' + randomInt;
            self.on(modalAnimationEvent, function () {
              self.off(modalAnimationEvent);

              self.addClass('modal-loaded');

              if (controllerName) {
                self.parent().addClass(controllerName.toDash().toLowerCase().replace(/(controller|ctrl)/, 'wrap'));
              }

              var modalScope = angular.element(this).scope();
              modalScope.$evalAsync(function () {
                modalScope.modalLoaded = true;
              })
            });
          });

          try {
            // Handle Already same modal opened.
            var currentTopModal = $modalStack.getTop();
            if (currentTopModal) {
              var currentModalController = currentTopModal.value.modalScope.controllerName
              if (currentModalController === options.controller) {
                $modalStack.dismiss(currentTopModal.key)
              }
            }
          } catch (e) {
            console.log('Couldnt verify opened dialog.');
          }

          oldOpen.call(this, options);
        });
      };

      return $delegate;

      function modalAuthorizer() {
        var isAuthorizationRequired = undefined;
        var securitySettings;
        var authenticator;

        this.authorize = function (controllerName, forceAuthorization) {
          if (!angular.isDefined(isAuthorizationRequired)) {
            initialAuthorization.call(this);
          }

          var shouldAuthorize = false;
          angular.forEach(securitySettings, function (value, key) {
            if ((forceAuthorization) || (key.indexOf('Protect') == 0 && controllerName.replace('Controller', '') == (key.replace('Protect', '') + "Modal") && value)) {
              shouldAuthorize = true;
            }
          });

          var promiseToAuthorize;
          if (!(shouldAuthorize && (promiseToAuthorize = authorize()))) {
            promiseToAuthorize = $q.defer();
            promiseToAuthorize.resolve();
            promiseToAuthorize = promiseToAuthorize.promise;
          }

          return promiseToAuthorize;
        };

        function authorize() {
          if (!authenticator) {
            authenticator = $injector.get('authenticationService');
          }

          if (!authenticator.isAuthenticated()) {
            return authenticator.authenticate();
          }

          return undefined;
        }

        function initialAuthorization() {
          var securityConfigs = $injector.get('configuration');
          securitySettings = securityConfigs.get().SecuritySettings;
          if (!securitySettings.RequireAuthorization) {
            this.authorize = function () {
              var deferred = $q.defer();
              deferred.resolve();
              return deferred.promise;
            }
          } else {
            isAuthorizationRequired = true;
          }
        }
      } // END OF modal authorizer.


    }])
});

MAIN_MODULE.run([
  '$rootScope', '$timeout', '$injector', '$state', '$transitions', '$modalStack', '$uiRouter', 'configuration',
  function ($rootScope, $timeout, $injector, $state, $transitions, $modalStack, $uiRouter, configuration) {
    var authenticator = null;
    var configuration = null;
    var wasAuthenticated = false;
    var authenticationTimer = null;

    $transitions.onEnter({}, function (trans) {
      if (!initAuthorization()) {
        return true;
      }

      var targetState = trans.targetState().state();
      if (!targetState.authenticate) {
        return true;
      }


      if (!authenticator) {
        authenticator = $injector.get('authenticationService');
      }

      if (!wasAuthenticated && !authenticator.isAuthenticated()) {
        return authenticator.authenticate()
          .then(function () {
            wasAuthenticated = true;
            setAutoCancelAuthentication();
            return true;
          })
          .catch(function () {
            /**
             * We look for the first non password protected non hidden route.
             * @type {string}
             */
            const securedScreens = configuration.get().SecuritySettings;
            const routeNameToProgressTo = Object.keys($uiRouter.stateRegistry.states)
              .filter((stateName) => isNonEmptyString(stateName) && !['Configurations', 'Logout'].includes(stateName))
              .find((stateName) => {
                return !securedScreens['Protect' + stateName] && countSpecificChar($uiRouter.stateRegistry.states[stateName].url.pattern, '/') < 2;
              }) || 'Logout';

            $state.go(routeNameToProgressTo)
          });
      } else if (wasAuthenticated) {
        setAutoCancelAuthentication(true);
        setAutoCancelAuthentication();
      }

      return true;

      function setAutoCancelAuthentication(cancel) {
        if (authenticationTimer) {
          $timeout.cancel(authenticationTimer);
          authenticationTimer = null;
        }

        if (!cancel) {
          authenticationTimer = $timeout(function () {
            wasAuthenticated = false;
          }, 60000);
        }
      }

    });

    $transitions.onExit({}, function (trans) {
      if (trans.from().name !== trans.targetState().name && $modalStack.getTop()) {
        try {
          $modalStack.dismiss($modalStack.getTop().key)
        } catch (e) {
          console.log('failed to close modal.')
        }

      }
    });

    function initAuthorization() {
      configuration = $injector.get('configuration');
      var securityConfigs = configuration.get().SecuritySettings;
      if (!securityConfigs.RequireAuthorization) {
        return false;
      }

      var viewsToAuthenticate = "Configurations, ";
      angular.forEach(securityConfigs, function (value, key) {
        if (key.indexOf('Protect') > -1 && value) {
          viewsToAuthenticate += key.replace('Protect', '') + ', ';
        }
      });

      $state.get().forEach(function (state) {
        if (viewsToAuthenticate.indexOf(state.name + ",") > -1) {
          state.authenticate = true;
        } else {
          delete state.authenticate;
        }
      });


      return true;
    }


  }]);


MAIN_MODULE.run([
  '$rootScope', 'Repository', 'whatsNewService', '$timeout',
  function ($rootScope, Repository, whatsNewService, $timeout) {
    Repository.Custom('WhatsNew').whatsNew().then((whatsNew) => {
      if (whatsNew) {
        if (whatsNew.image_url) {
          $rootScope.$emit('loadImagesToCache', [whatsNew.image_url]);
        }

        $timeout(() => {
          whatsNewService.showWhatsNew(whatsNew)
        }, 1000)
      }
    })

  }]);


MAIN_MODULE.config(function () {
  angular.lowercase = angular.$$lowercase;
});
