import './caller.id.less'
import { DATE_DISPLAY_FORMAT, RTL_DATE_TIME_DISPLAY } from "../../constants";

angular.module('easybizy.easybizyModalViewsModel').controller('CallerIdController',
  function ($scope, $modalInstance, localize, customer, $state, contextManager, Repository, entityImageResolver, tileActionsResolver) {
    $scope.customer = customer;

    customer.MobileFirst = customer.MobileFirst.splice(customer.MobileFirst.length <= 10 ? 3 : 2, 0, '-');
    var availableActions = {
      bookAService: {
        name: localize.getLocalizedString('_BookAService_'), delegate: function () {
          if ($scope.knownCustomer) {
            contextManager.set('customer', customer.CustomerId);
          } else {
            contextManager.set('customerPhoneNumber', customer.MobileFirst);
          }

          $state.go('Calendar');
          $scope.close();
        }
      },
      sendIamBusy: {
        name: localize.getLocalizedString('_SendABusyMessage_'), delegate: function () {
          tileActionsResolver.getAction('sendSms').command({
            MobileFirst: customer.MobileFirst,
            FirstName: customer.MobileFirst,
            CustomerId: customer.CustomerId
          });

          // var metadata = { MobileFirst: customer.MobileFirst };
          // if ($scope.knownCustomer) {
          //     metadata.CustomerId = customer.CustomerId;
          // }
          //
          // Repository.Custom("PromotionsRepository").sendIAmBusySMS(metadata)
          //   .then(function () {
          //       toastr.success(localize.getLocalizedString('_MessageCreated_'));
          //   })
          //   .catch(function (e) {
          //       toastr.error(localize.getLocalizedString('_FailedToCreateMessage_'));
          //   })
          //   .finally(function () {
          //       $scope.isLoading = false;
          //       $scope.$digest();
          //   });

          $scope.close();
        }
      },
      goToCustomerDetails: {
        name: localize.getLocalizedString('_CustomerDetails_'), delegate: function () {
          $state.go('CustomerDetails', { customerId: customer.CustomerId });
          $scope.close();
        }
      }
    };

    if ($scope.customer.CustomerId) {
      $scope.knownCustomer = true;

      if ($scope.customer.DateOfBirth) {
        $scope.birthdayIn = moment($scope.customer.DateOfBirth).set('years', moment().year());
        $scope.birthdayIn = ($scope.birthdayIn.isAfter(moment()) ? $scope.birthdayIn : $scope.birthdayIn.add(1, 'years')).toDate();
        $scope.birthdayIn = $.timeago($scope.birthdayIn);
      }

      $scope.yearsOld = moment().diff(moment($scope.customer.DateOfBirth), 'y');
      $scope.genderIcon = !$scope.customer.Gender ? 'icon icon-gender' : 'icon icon-' + customer.Gender.toLowerCase();
      $scope.gender = !$scope.customer.Gender ? null : localize.getLocalizedString('_' + customer.Gender + '_');
      $scope.image = entityImageResolver.resolve(customer);

      $scope.remarks = customer.Remarks;

      $scope.dataSections = [
        {
          header: localize.getLocalizedString('_General_'),
          dataElements: [
            {
              icon: 'icon icon-meeting-status-reminder-sent',
              subTitle: localize.getLocalizedString('_Visits_'),
              value: $scope.customer.NumberOfVisits || 0
            },
            {
              icon: 'icon icon-banknote-aligned-shekel',
              subTitle: localize.getLocalizedString('_AverageForVisit_'),
              value: ('' + ($scope.customer.AvarageInvoice ? $scope.customer.AvarageInvoice.toFixed(1) : 0)).toMoney()
            },
            {
              icon: 'icon icon-punchcard',
              subTitle: localize.getLocalizedString('_ActiveSubscriptions_'),
              value: localize.getLocalizedString(customer.HasSubscriptions ? '_ThereAre_' : '_ThereArent_')
            }
          ]
        },
        {
          header: localize.getLocalizedString('_Visits_'),
          dataElements: [
            {
              icon: 'icon icon-meeting-status-approved',
              value: $scope.customer.NextMeeting ? moment($scope.customer.NextMeeting).format(RTL_DATE_TIME_DISPLAY) : localize.getLocalizedString('_NotScheduled_'),
              subTitle: localize.getLocalizedString('_NextMeeting_'),
              delegate: $scope.customer.NextMeeting ? function () {
                $state.go('Calendar', {
                  date: moment($scope.customer.NextMeeting).format('YY-MM-DD'),
                  meeting: $scope.customer.NextMeetingId
                });
                $modalInstance.close();
              } : null
            },
            {
              icon: 'icon icon-time',
              value: $scope.customer.LastVisit ? moment($scope.customer.LastVisit).format(DATE_DISPLAY_FORMAT) : localize.getLocalizedString('_NotOccurred_'),
              subTitle: localize.getLocalizedString('_LastVisit_')
            }
          ]
        }
      ];

      $scope.actions = [availableActions.bookAService, availableActions.sendIamBusy, availableActions.goToCustomerDetails];
    } else {
      $scope.actions = [availableActions.bookAService, availableActions.sendIamBusy];
    }

    $scope.close = function () {
      $modalInstance.close();
    };

  });
