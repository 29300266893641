import { MAIN_MODULE } from "../../constants";

(function () {
  MAIN_MODULE.factory('embedScrollLogic', function () {
    var element = null;

    return {
      embed: embed,
      scrollTop: scrollTop
    };

    function embed(scope, elm, attr) {
      element = elm;
      var raw = elm[0];

      var lastPositionTop = 0;
      elm.bind('scroll', function () {
        // TODO: Fix to better incorporate scroll event.
        // if (raw.scrollTop > lastPositionTop && ((raw.scrollTop + raw.offsetHeight) / raw.scrollHeight > .8)) {


        if (raw.scrollTop > lastPositionTop && (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight - 10)) {
          scope.$apply(attr.whenScrolled);
        }

        lastPositionTop = raw.scrollTop;
      });
    }

    function scrollTop() {
      element.animate({ scrollTop: 0 });
    }
  })
    .directive('whenScrolled', ['embedScrollLogic', function (embedScrollLogic) {
      return function (scope, elm, attr) {
        embedScrollLogic.embed(scope, elm, attr);
      };
    }]);
}());

