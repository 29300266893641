import { DATE_DISPLAY_FORMAT, HOURS_AND_MINUTES_FORMAT } from "../../../constants";
import './lost.payments.directive.less';

(function () {
  angular.module('easybizy.cash.register')
    .directive('lostPaymentsWrapper', ['$timeout', 'creditCardReaderFactory', 'Repository', 'localize', 'confirmService', 'pci', 'cancelInvoiceService',
      function ($timeout, creditCardReaderFactory, Repository, localize, confirmService, pci, cancelInvoiceService) {
        return {
          restrict: 'E',
          replace: true,
          scope: {
            usePaymentDelegate: '=',
            existingPayments: '=',
            isCancellingModeWrapper: '=',
            autoOpen: '='
          },
          link: function (scope) {
            scope.showLostPayments = false;
            scope.lostItemsWrapper = {};
            scope.toggleLostPayments = function (forceValue) {
              scope.showLostPayments = angular.isDefined(forceValue) ? forceValue : !scope.showLostPayments;
            };

            scope.reloadPaymentsDelegate = {};

            scope.usePayment = function (payment) {
              if (scope.usePaymentDelegate(payment)) {
                scope.toggleLostPayments();
              }
            };

            scope.cancelPayment = function (payment) {
              return confirmService.confirm(localize.getLocalizedString('_AreYouSureYouWantToCancel_'),
                null,
                () => {
                  scope.$evalAsync(() => {
                    quickCancelPayment(payment);
                  })
                }, null, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_'));
            };

            function quickCancelPayment(payment) {
              payment.isCancelling = true;
              pci.cancelPayment(payment.id).then((result) => {
                deleteCancelledPayments([payment.id, result.paymentId], () => {
                  scope.toggleLostPayments(false);
                  toastr.success(localize.getLocalizedString('_PaymentCancelled_'))
                  scope.$evalAsync(() => {
                    payment.isCancelling = false;
                  })

                })
              }).catch((e) => {
                const err = pci.extractError(e);

                $timeout(() => {
                  toastr.error(err);
                  payment.isCancelling = false;
                  confirmAndFullCancel(payment);
                }, 1000);
              })
            }

            function confirmAndFullCancel(payment) {
              confirmService.confirm(localize.getLocalizedString('_PerformFullCancelOfLostPaymentConfirm_'),
                null,
                () => {
                  scope.$evalAsync(() => {
                    cancelInvoiceService.show(null, payment);
                  })
                }, null, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_'));
            }

            scope.isCreditInputFocusedWrapper = { isFocused: false };

            scope.hideCancelPayment = function () {
              scope.hiddenCancelledPayments = true;
              $timeout(function () {
                scope.isCancellingModeWrapper.paymentToCancel = null;
                scope.hiddenCancelledPayments = false;
              }, 500);
            };

            scope.isManuallyChargingCard = false;
            scope.toggleManualCard = function (forceValue) {
              scope.isManuallyChargingCard = angular.isDefined(forceValue) ? forceValue : !scope.isManuallyChargingCard;
            };

            scope.manuallyChargeCardValues = {};
            scope.manuallyProgressWrapper = {};

            scope.foundPaymentsDelegate = function () {
              if (scope.autoOpen) {
                scope.toggleLostPayments(true);
              }
            };

            scope.$on('$destroy', function () {
              creditCardReaderFactory.unregister();
            });

            function deleteCancelledPayments(paymentIds, successCallback) {
              Repository.Custom("CashRegister").deleteLostCancelledPayments(paymentIds)
                .then(function () {
                  scope.reloadPaymentsDelegate.reload();
                  if (angular.isFunction(successCallback)) {
                    successCallback();
                  }
                })
                .catch(function (err) {
                  toastr.error('_ErrorDeletingLostPayments_' + err);
                });
            }
          },
          template: require('../../../partial-views/cash-register/lost-payments/lost.payments.wrapper.directive.tpl.html')
        }
      }]);

  angular.module('easybizy.cash.register').directive('lostPayments', ['$timeout',
    '$rootScope', 'localize', 'Repository',
    function ($timeout, $rootScope, localize, Repository) {

      return {
        restrict: 'E',
        replace: true,
        scope: {
          lostItemsWrapper: '=',
          usePaymentDelegate: '=',
          cancelPaymentDelegate: '=',
          reloadDelegate: '=',
          existingPayments: '=',
          foundPaymentsDelegate: '='
        },
        link: function (scope) {
          scope.missingPayments = [];
          scope.usePayment = function (payment) {
            if (scope.usePaymentDelegate(payment)) {
              scope.missingPayments.removeById('id', payment);
            }
          };

          scope.cancelPayment = function (payment) {
            scope.cancelPaymentDelegate(payment);
          };

          scope.$watchCollection('existingPayments', function (existingPayments) {
            if (Array.isArray(existingPayments)) {
              existingPayments.forEach(function (paymentToIgnore) {
                scope.missingPayments.removeByFunc(paymentToIgnore,
                  function (payment) {
                    return (paymentToIgnore.originalPayment) && payment.id === paymentToIgnore.originalPayment.id;
                  },
                  true);
              });
            }
          });

          loadPayments();

          scope.$watchOnce('reloadDelegate',
            function (newVal) {
              newVal.reload = loadPayments;
            });

          scope.$on('cancel-charging-card', () => {
            loadPayments();
          });

          scope.$on('invoice-cancelled', () => {
            loadPayments();
          });


          function loadPayments() {
            Repository.Custom('CashRegister').lostCreditPayments()
              .then(function (data) {
                $timeout(function () {
                  scope.missingPayments.length = 0;
                  if (data.length > 0) {

                    angular.forEach(data,
                      function (lostPayment) {
                        var payment = {
                          id: lostPayment.PaymentId,
                          amount: lostPayment.Amount,
                          cardType: (lostPayment.CardName || '').trim(),
                          cardLastFourDigits: lostPayment.CardLastFourDigits,
                          paymentDate: moment(lostPayment.CreatedOn)
                            .format(DATE_DISPLAY_FORMAT + " " + HOURS_AND_MINUTES_FORMAT),
                          isDoublePayment: lostPayment.IsDoublePayment,
                          numOfPayments: lostPayment.NumberOfCharges,
                          customerId: lostPayment.CustomerId
                        };

                        scope.missingPayments.push(payment);

                      });

                    if (scope.lostItemsWrapper) {
                      scope.lostItemsWrapper.items = scope.missingPayments.slice();
                    }
                  }

                  scope.foundPaymentsDelegate(scope.missingPayments);
                  scope.isLoadingMissingPayments = false;
                });

              })
              .catch(function (err) {
                toastr.error('_ErrorGettingLostPayments_' + err);
                scope.isLoadingMissingPayments = false;
                scope.$digestIfNeeded();
              });
          }
        },
        template: require('../../../partial-views/cash-register/lost-payments/lost.payments.directive.tpl.html')
      }
    }]);
}());
