import './add.pricelist.less';

angular.module('easybizy.easybizyModalViewsModel').controller('AddPriceListController',
  function ($scope, $modalInstance, saveAction) {
    // $scope.employees = employees;
    // $scope.subscription = subscription;
    // $scope.selectedEmployeeWrapper = { selected: employees[0] };

    $scope.pricelist = {
      name: '',
      discountPercentage: 0
    }

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.save = function () {
      $scope.isSaving = true;
      saveAction($scope.pricelist).then(() => {
        $scope.cancel();
      }).catch((err) => {
        $scope.isSaving = false;
        toastr.error(err.message);
      })
      // saveAction(usagesToCreate, $scope.cancel);
    };
  });
