import './forms.less'
import Url from 'url-parse'
import QueryString from 'query-string'

const DEFAULT_FORMS_VIEW = 'forms-dashboard';

(function () {
  angular.module('easybizy.forms')
    .controller('FormsController',
      function ($scope, $rootScope, $state, stateManager, $transitions) {
        // TODO, listen to route change and make sure its the same as the iframe url.
        const viewType = $state.params['view-type'];
        const id = $state.params['id'] ? `/${ $state.params['id'] }` : '';
        const mode = $state.params['mode'] ? `?mode=${ $state.params['mode'] }` : '';
        $scope.isLoading = true;
        $scope.formsURI = `${ window.formsURI }/${ viewType || DEFAULT_FORMS_VIEW }${ id }${ mode }`;
        $scope.onRouteChange = (newRoute) => {
          const { id, viewType, queryString } = urlToParams(newRoute);
          stateManager.setState('Forms', {
            viewType,
            id,
            mode: queryString.mode
          }, false, false);

          $scope.isLoading = false;
        };

        $scope.srcGetter = {};

        function urlToParams(url) {
          const parsedUrl = new Url(url);
          const splitUrlPath = parsedUrl.pathname.substr(1).split('/');
          const viewType = splitUrlPath[0];
          const id = splitUrlPath[1];
          const queryString = parsedUrl.query ? QueryString.parse(parsedUrl.query) : {};

          return { id, viewType, queryString };
        }

      });
}());
