import './add.customer.group.modal.less';

angular.module('easybizy.easybizyModalViewsModel').controller('AddCustomersGroupController',
  function ($scope, $modalInstance, saveAction, parameters, localize, Repository) {
    $scope.dialogTitle = parameters.group ? localize.getLocalizedString("_EditExistingGroup_") :
      localize.getLocalizedString("_CreateCustomersGroup_");


    $scope.selectedCustomerWrapper = {};
    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.allowSave = function () {
      return $scope.groupWrapper.customers.length > 0;
    };

    $scope.selectedCustomerWrapper.invoke = function () {
      if ($scope.groupWrapper.customers.indexOfById($scope.selectedCustomerWrapper.customer, "CustomerId") < 0) {
        $scope.groupWrapper.customers.push($scope.selectedCustomerWrapper.customer);
      }
    };

    $scope.removeCustomer = function (customer) {
      $scope.groupWrapper.customers.removeById(customer, "CustomerId");
    };

    $scope.validateActionWrapper = {};
    $scope.newGroupFieldToValidate = {};

    $scope.groupWrapper = {};
    $scope.groupWrapper.customers = [];

    $scope.field = {
      fieldName: 'GroupName', icon: "icon icon-group", placeholder: localize.getLocalizedString("_GroupName_"),
      validation: "{'required': true, 'max-length': 30}"
    };

    if (parameters.group) {
      Repository.Custom("CustomersGroup").get(parameters.group.CustomerId).then(function (res) {
        $scope.groupWrapper.customers.pushAll(res.value[0].Customers);
      })
        .catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorLoadingCustomerGroup_", err.message));
        });

      $scope.groupWrapper.GroupName = parameters.group.GroupName;
      $scope.groupWrapper.GroupId = parameters.group.CustomerId;
    }

    $scope.save = function () {
      var validationFields = $scope.validateActionWrapper.validate();
      if (validationFields.length != 0) { // is valid form?
        var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", $scope.field.placeholder, validationFields[0][$scope.field.fieldName]);
        toastr.error(localizedError);
        return;
      }

      var groupToSave = {};
      groupToSave.CustomerIds = $scope.groupWrapper.customers.map(function (x) {
        return x.CustomerId;
      });
      groupToSave.Name = $scope.groupWrapper.GroupName;
      groupToSave.GroupId = $scope.groupWrapper.GroupId;
      $scope.isSaving = true;
      saveAction(groupToSave)
        .then(function () {
          $scope.isSaving = false;
          $scope.cancel();
        })
        .finally(function () {
          $scope.isSaving = false;
        });
    };
  });
