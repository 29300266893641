import './promotion.test.flight.modal.less'

angular.module('easybizy.easybizyModalViewsModel').controller('PromotionTestFlightModalController',
    function ($scope, $modalInstance, sendAction, promotionData, localize, $timeout) {
        $scope.messageWrapper = {};
        $scope.messageWrapper.sendTo = "";

        $scope.isLoading = false;
        $scope.send = function () {
            $scope.isLoading = true;
            var to = $scope.messageWrapper.sendTo !== "" ? $scope.messageWrapper.sendTo : $scope.defaultSendTo;
            sendAction(to)
                .then(function() {
                    $timeout(function() {
                        $scope.isLoading = false;
                        $modalInstance.dismiss('cancel');
                    }, 2000);
                }).catch(function() {
                    $timeout(function() {
                        $scope.isLoading = false;
                        $modalInstance.dismiss('cancel');
                    }, 2000);
                });
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };


        $scope.defaultSendTo = promotionData;
        $scope.sendToPlaceholder = localize.getLocalizedString("_OrTypeAnotherAddress_");
    });
