var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard-summary-wrapper" }, [
    _c("div", { staticClass: "flex justify-start flex-col" }, [
      _c(
        "div",
        {
          staticClass: "flex justify-start max-h-96\twizard-summary__category",
        },
        _vm._l(_vm.itemsViewModel, function (category) {
          return _c("div", { staticClass: "flex justify-between flex-col" }, [
            _c(
              "div",
              [
                _c("p", { staticClass: "text-xl" }, [
                  _c("span", { staticClass: "px-2", class: category.icon }),
                  _vm._v(_vm._s(category.title) + ":"),
                ]),
                _vm._v(" "),
                _vm._l(category.items, function (item) {
                  return _c("p", [_vm._v(_vm._s(item))])
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("_Total_")) + ":"),
                _c("span", { staticClass: "text-4xl px-2" }, [
                  _vm._v(_vm._s(category.items.length)),
                ]),
              ]),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c(
            "vue-button",
            { attrs: { disabled: _vm.disabled }, on: { click: _vm.upload } },
            [_vm._v(_vm._s(_vm.$t("_Create_")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }