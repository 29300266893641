import {DelayedFunction} from "../../general/helpers";

angular.module('easybizy.calendar').directive('similarMeetings', function () {
  const CLASSNAME = 'calendar-meeting--similar-customer';

  return {
    restrict: 'A',
    link: function (scope, element) {
      const removeHover = () => {
        const meetings = element[0].querySelectorAll('.calendar-meeting--similar-customer');
        for (const meeting of meetings) {
          meeting.classList.remove(CLASSNAME)
        }
      }

      const delayedHoverRemoval = new DelayedFunction(removeHover, 500);
      $(element).on('mouseleave', '.calendar-meeting', (ev) => {
        delayedHoverRemoval.set(Math.random())
        removeHover()
      });


      $(element).on('mouseenter', '.calendar-meeting', (ev) => {
        delayedHoverRemoval.cancel()
        removeHover();
        const customerId = ev.currentTarget.getAttribute('data-customer-id');
        const meetingsForCustomer = element[0].querySelectorAll(`.calendar-meeting-content .calendar-meeting[data-customer-id="${customerId}"]`);
        if (meetingsForCustomer.length > 1) {
          for (const meeting of meetingsForCustomer) {
            meeting.classList.add(CLASSNAME);
          }
        }
      })
    }
  }
})
