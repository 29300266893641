import './calendar.popover.less'
import { ODATA_DATE_ONLY_FORMAT, ODATA_DATE_TIME_FORMAT, SERVER_DATA_DATE_FORMAT } from "../../../constants";
import { cleanObj } from "@tomeravni/easybizy-js-common/common";

(function () {
  angular.module('easybizy.calendar').directive('calendarPopover',
    function (
      localize, $timeout, calendarMetadata, calendarStore, $rootScope,
      calendarClipboard, $state, colorsService, contextManager, iCalService, Repository, configuration,
      intermediateManager,
      tileActionsResolver, globalActions, confirmService, stateManager
    ) {
      const includeCustomModelFormat = configuration.get().ProductsAndServicesSettings.IncludeIngredientsModelForCustomer;
      const useCustomFields = configuration.get().ProductsAndServicesSettings.UseCustomIngredientsFields;
      const customFieldsNames = configuration.get().ProductsAndServicesSettings.IngredientsFieldsCustomLabels;
      const useEmployeeCreatedMeeting = configuration.get().EmployeesSettings.UseEmployeeCreatedMeeting;
      const setEmployeeCreatedMeetingOnCashRegister = configuration.get().EmployeesSettings.SetEmployeeCreatedMeetingOnCashRegister;
      const defaultEmployeeId = configuration.defaultEmployee()?.id;

      let isValidWhatsappAccount = false;
      configuration.isValidWhatsappAccount().then((isValid) => {
        isValidWhatsappAccount = isValid;
      });

      const deleteReasons = [
        { label: localize.getLocalizedString('_Cancelled_') },
        { label: localize.getLocalizedString('_DidntShow_') },
        { label: localize.getLocalizedString('_Rescheduled_') },
        { label: localize.getLocalizedString('_Sick_') },
        { label: localize.getLocalizedString('_Other_') }
      ];

      var availableStatuses = [];
      for (var status in calendarMetadata.meetingStatuses) {
        availableStatuses.push(calendarMetadata.meetingStatuses[status]);
      }

      var actionShorthands = [
        ['cut', '_Cut_', 'icon icon-cut'],
        ['copy', '_Copy_', 'icon icon-page-copy'],
        [
          'toCashRegister', '_TakeToCashRegister_', 'icon icon-cashregister', function (meeting) {
            stateManager.setState('CashRegister', cleanObj({
              meetingId: meeting.MeetingId,
              relativeDate: moment(meeting.originalMeeting.StartTime, SERVER_DATA_DATE_FORMAT).format(ODATA_DATE_TIME_FORMAT),
              customerId: meeting.originalMeeting.CustomerId,
              defaultEmployeeId: (setEmployeeCreatedMeetingOnCashRegister) ? meeting.originalMeeting.CreatedByEmployeeId || defaultEmployeeId : meeting.originalMeeting.EmployeeId
            }));
          }],
        [
          'toCustomerDetails', '_ToCustomerDetails_', 'icon icon-clients-ol', function (meeting) {
            $state.go('CustomerDetails', { customerId: meeting.originalMeeting.CustomerId });
          }],
        [
          'sendWhatsapp', '_SendByWhatsapp_', 'icon icon-whatsapp', function (meeting) {
            tileActionsResolver.getAction('sendWhatsapp').command({
              MobileFirst: meeting.originalMeeting.MobileFirst,
              FirstName: meeting.originalMeeting.FirstName,
              LastName: meeting.originalMeeting.LastName,
              CustomerId: meeting.originalMeeting.CustomerId
            });
          }
        ],
        [
          'sendSms', '_SendSms_', 'icon icon-sms', function (meeting) {
            tileActionsResolver.getAction('sendSms').command({
              MobileFirst: meeting.originalMeeting.MobileFirst,
              FirstName: meeting.originalMeeting.FirstName,
              CustomerId: meeting.originalMeeting.CustomerId
            });
          }
        ],
        [
          'split', '_SplitServices_', 'icon icon-split', function (meeting) {
            $rootScope.$emit('page-in-progress', true);
            calendarStore.splitMeetingToServices(meeting).then(function () {
              toastr.success(localize.getLocalizedString("_MeetingWasSplittedIntoServices_"));
              $rootScope.$emit('page-in-progress', false);
            }).catch(function (err) {
              toastr.error(localize.getLocalizedString("_ErrorSplittingMeeting_", err.message));
              $rootScope.$emit('page-in-progress', false);
            });
          }],
        [
          'delete', '_Delete_', 'icon icon-delete-garbage-streamline', function (meeting, close) {
            this.intenralActionsVisible = !this.intenralActionsVisible;
            this.internalActions.forEach(function (internalAction) {
              internalAction.action = function () {
                if (internalAction.label === localize.getLocalizedString('_Other_')) {
                  confirmService.prompt(
                    localize.getLocalizedString("_CancellationReason_"),
                    "", localize.getLocalizedString("_CancellationReason_"),
                    function (updatedValue) {
                      toastr.success(localize.getLocalizedString("_MeetingDeletedSuccessfully_"));
                      $timeout(close, 300);
                      calendarStore.deleteMeeting(meeting, false, updatedValue).then(function (result) {
                      }).catch(function (err) {
                        toastr.error(localize.getLocalizedString("_ErrorDeletingMeeting_", err.message));
                        $rootScope.$emit('page-in-progress', false);
                      })
                    }, function () {
                    }
                    , function (value) {
                    }, '', localize.getLocalizedString('_Update_'));
                } else {
                  toastr.success(localize.getLocalizedString("_MeetingDeletedSuccessfully_"));
                  $timeout(close, 300);
                  calendarStore.deleteMeeting(meeting, false, internalAction.label).then(function (result) {
                  }).catch(function (err) {
                    toastr.error(localize.getLocalizedString("_ErrorDeletingMeeting_", err.message));
                    $rootScope.$emit('page-in-progress', false);
                  })
                }
              }
            })
          }, deleteReasons],
        [
          'more', '_More_', 'icon icon-android-more-vertical', function (meeting, close, moreActions) {
            this.intenralActionsVisible = !this.intenralActionsVisible;
          }, true],
        [
          'ingredients', '_Chemistry_', 'icon icon-ingredient', function (meeting) {
            var customerName = meeting.originalMeeting.CustomerName || meeting.originalMeeting.Title;
            globalActions.showCustomerIngredients(meeting.originalMeeting.CustomerId,
              useCustomFields,
              customFieldsNames, customerName);
          }],
        [
          'markAsNotPaid', '_MarkAsNotPaid_', 'icon icon-cancel', function (meeting, close) {
            $timeout(close, 300);
            calendarStore.markAsNotPaid(meeting).then(function (result) {
            }).catch(function (err) {
              toastr.error(localize.getLocalizedString("_ErrorUpdatingMeeting_", err.message));
              $rootScope.$emit('page-in-progress', false);
            });
          }],
        [
          'showHistory', '_MeetingHistory_', 'icon icon-table', function (meeting, close) {
            $timeout(close, 300);
            globalActions.showMeetingHistory(meeting.originalMeeting.MeetingId);
          }]
      ];

      var availableActions = {};
      actionShorthands.forEach(function (actionShorthand) {
        availableActions[actionShorthand[0]] = function (scope, close, moreActions) {
          var toReturn = {
            label: localize.getLocalizedString(actionShorthand[1]),
            icon: actionShorthand[2],
            action: function () {
              // This means there are internal reasons, no need to close.
              if (!actionShorthand[4]) {
                close();
              }

              if (actionShorthand[3]) {
                actionShorthand[3].call(toReturn, scope.meeting, close);
              } else {
                calendarClipboard[actionShorthand[0]](scope.meeting, close);
                if (actionShorthand[0] === 'cut') {
                  var relatedMeetings = calendarStore.getCustomerRelatedMeetings(scope.meeting);
                  if (relatedMeetings && relatedMeetings.length > 0) {
                    confirmService.confirm(localize.getLocalizedString('_CutOrCopyOtherCustomerMeetings_'),
                      null,
                      function () {
                        relatedMeetings.forEach((m) => {
                          m.extend().then(function (clientMeeting) {
                            calendarClipboard[actionShorthand[0]](clientMeeting, close)
                          })
                        })
                      },
                      function () {
                        // DOES NOTHING.
                      }, localize.getLocalizedString('_Yes_'));
                  }
                }
              }
            }
          };

          if (actionShorthand[4]) {
            toReturn.intenralActionsVisible = false;
            toReturn.internalActions = moreActions || actionShorthand[4];
          }

          return toReturn;
        };
      });

      var pasteAction = function (meeting, metadata, close, sameCustomerDifferentService) {
        var customerName = meeting.originalMeeting.CustomerName || meeting.originalMeeting.Remarks || meeting.originalMeeting.Title;
        var serviceNames = meeting.originalMeeting.ServiceMetadatas.map(function (service) {
          return service.Title;
        }).join(',');
        let title = sameCustomerDifferentService ? serviceNames : customerName + (serviceNames ? (' - ' + serviceNames) : '')


        return {
          label: title,
          icon: sameCustomerDifferentService ? '' : 'icon icon-paste',
          action: function () {
            close();
            var duplicatedMeeting = angular.extend({}, meeting.originalMeeting);
            duplicatedMeeting.from = metadata.startTime.clone();
            duplicatedMeeting.to = metadata.startTime.clone().add(meeting.EndTime.diff(meeting.StartTime, 'minutes'), 'm');
            duplicatedMeeting.repeatEvent = duplicatedMeeting.Recurrence;
            duplicatedMeeting.customer = { CustomerId: duplicatedMeeting.CustomerId };
            duplicatedMeeting.remarks = meeting.originalMeeting.Remarks;
            duplicatedMeeting.services = duplicatedMeeting.ServiceMetadatas ? duplicatedMeeting.ServiceMetadatas.map(function (service) {
              return { value: { ServiceMetadataId: service.ServiceMetadataId } };
            }) : duplicatedMeeting.ServiceIds.map(function (id) {
              return { value: { ServiceMetadataId: id } }
            });

            if (metadata.column.employee) {
              duplicatedMeeting.EmployeeId = metadata.column.employee.id;
            }

            if (useEmployeeCreatedMeeting) {
              duplicatedMeeting.CreatedByEmployeeId = defaultEmployeeId
            }

            if (metadata.column.room) {
              duplicatedMeeting.RoomId = metadata.column.room.id;
            }

            duplicatedMeeting.service = {};

            if (meeting.clipboardReason === 'copy') {
              delete duplicatedMeeting.repeatEvent;
              delete duplicatedMeeting.Recurrence;
              calendarStore.createOrUpdateMeeting(duplicatedMeeting).then(function () {
                calendarClipboard.remove(meeting);
              }).catch(function (e) {
                toastr.error(localize.getLocalizedString(e.Message || e.data.Message));
              });
            } else if (meeting.clipboardReason === 'cut') {
              duplicatedMeeting.OnlineBookingId = meeting.originalMeeting.OnlineBookingId;
              calendarStore.createOrUpdateMeeting(duplicatedMeeting, meeting, false)
                .then(function () {
                  calendarClipboard.remove(meeting);
                }).catch(function (e) {
                  toastr.error(localize.getLocalizedString(e.Message || e.data.Message));
                });
            }
          }
        };
      };

      var coreActions = {
        create: function (metadata, closeDelegate) {
          return {
            label: localize.getLocalizedString('_CreateNewMeeting_'), action: function () {
              closeDelegate();
              $rootScope.$emit('fire-toolbar-command', {
                name: 'add', params: {
                  date: metadata.startTime.clone(),
                  time: metadata.startTime.clone(),
                  employee: metadata.column.employee || calendarMetadata.filteredEmployee,
                  room: metadata.column.room
                }
              });
            }
          };
        },
        edit: function (scope, closeDelegate) {
          return {
            label: localize.getLocalizedString('_Edit_'), action: function () {
              closeDelegate();
              $rootScope.$emit('fire-toolbar-command', {
                name: 'add', params: {
                  meeting: scope.meeting, deleteCommand: function (meetingToDelete, deleteFutureEvents) {
                    return calendarStore.deleteMeeting(meetingToDelete, deleteFutureEvents);
                  }
                }
              });
            }
          };
        }
      };

      return {
        restrict: 'E',
        replace: true,
        link: function (scope, element) {
          var $popoverTrap = $('.popover-trap');
          $popoverTrap.on('click', function () {
            $timeout(scope.close);
          });

          scope.isVisible = false;
          scope.close = function () {
            scope.isVisible = false;
            $popoverTrap.removeClass('visible');
            element.css({
              display: 'none',
              visibility: 'hidden'
            });
          };

          scope.editNotes = () => {
            confirmService.prompt(
              /*title*/localize.getLocalizedString("_EditNotes_"),
              /*subtitle*/"",
              /*placeholder*/localize.getLocalizedString("_Notes_"),
              /*onOk*/function (updatedValue) {
                var relatedMeetings = calendarStore.getCustomerRelatedMeetings(scope.meeting);
                if (relatedMeetings && relatedMeetings.length > 1) {
                  confirmService.confirm(localize.getLocalizedString('_CutOrCopyOtherCustomerMeetings_'),
                    null,
                    function () {
                      relatedMeetings.forEach((m) => {
                        calendarStore.updateMeetingRemarks(m, updatedValue).then(function () {
                          m.originalMeeting.Remarks = updatedValue;
                        }).catch(function (err) {
                          toastr.error(localize.getLocalizedString("_ErrorSavingEntity_", err.message));
                        })
                      });
                    },
                    function () {
                      // DOES NOTHING.
                    }, localize.getLocalizedString('_Yes_'));
                }

                calendarStore.updateMeetingRemarks(scope.meeting, updatedValue).then(function () {
                  scope.meeting.originalMeeting.Remarks = updatedValue;
                  toastr.success(localize.getLocalizedString("_RemarksUpdated_"));
                }).catch(function (err) {
                  toastr.error(localize.getLocalizedString("_ErrorSavingEntity_", err.message));
                });
              },
              /*On cancel*/
              function () {
              },
              /*Validate*/
              function (value) {
              }, /*DEFAULT VALUE*/scope.meeting.originalMeeting.Remarks,
              /*Ok text*/localize.getLocalizedString('_Update_'), true);
          }

          scope.colorsMapper = colorsService.getDefaultColors()//.slice(0, 45);
          // SCOPE PROPERTIES....

          Object.defineProperty(scope, 'meetingColor', {
            get: function () {
              return (scope.meeting && scope.meeting.Color) ? scope.meeting.Color : 'rainbow';
            }
          });


          Object.defineProperty(scope, 'label', {
            get: function () {
              return scope.meeting ? scope.meeting.title : (scope.metadata ? scope.metadata.startTime.format('HH:mm') : null);
            }
          });

          Object.defineProperty(scope, 'initials', {
            get: function () {
              return scope.meeting ? scope.meeting.title.initials() : (scope.metadata ? scope.metadata.column.date.format('DD') : null);
            }
          });


          Object.defineProperty(scope, 'meetingMode', {
            get: function () {
              return !!scope.meeting;
            }
          });

          Object.defineProperty(scope, 'mobile', {
            get: function () {
              return scope.meeting && scope.meeting.originalMeeting.MobileFirst
                && scope.meeting.originalMeeting.MobileFirst.splitPhone();

            }
          });

          Object.defineProperty(scope, 'startTime', {
            get: function () {
              return scope.meeting ? scope.meeting.StartTime.format('HH:mm') + '-' + scope.meeting.EndTime.format('HH:mm') :
                (scope.metadata ? scope.metadata.startTime.format('HH:mm') : null);

            }
          });

          Object.defineProperty(scope, 'employeeName', {
            get: function () {
              return calendarMetadata.employees && calendarMetadata.employees.length > 0 && scope.meeting && scope.meeting.originalMeeting.EmployeeName;
            }
          });

          Object.defineProperty(scope, 'onlineScheduled', {
            get: function () {
              return scope.meeting && scope.meeting.OnlineMeeting;
            }
          });

          Object.defineProperty(scope, 'awaitingApproval', {
            get: function () {
              return scope.meeting && scope.meeting.originalMeeting.WaitingForApproval;
            }
          });

          Object.defineProperty(scope, 'meetingStatus', {
            get: function () {
              if (!scope.meeting) {
                return null;
              }

              var toReturn = null;
              if (angular.isDefined(scope.meeting.originalMeeting.MeetingState)) {
                toReturn = availableStatuses.filter(function (status) {
                  return status.value === scope.meeting.originalMeeting.MeetingState;
                });

                toReturn = toReturn.length > 0 ? toReturn[0] : availableStatuses[0];
              } else {
                toReturn = availableStatuses[0];
              }

              return toReturn;
            }
          });

          Object.defineProperty(scope, 'externalVoucherName', {
            get: function () {
              if (!scope.meeting) {
                return null;
              }

              var toReturn = null;
              if (scope.meeting.originalMeeting.ExternalVoucherMetadataId && externalVouchersMap.hasOwnProperty(scope.meeting.originalMeeting.ExternalVoucherMetadataId)) {
                var externalVoucher = externalVouchersMap[scope.meeting.originalMeeting.ExternalVoucherMetadataId];
                toReturn = externalVoucher.ExternalVoucherVendor.ExternalVoucherVendorName + ' / ' +
                  externalVoucher.ExternalVoucherName;

              }

              return toReturn;
            }
          });


          Object.defineProperty(scope, 'extendingMeeting', {
            get: function () {
              return scope.meeting && !scope.meeting.extended;
            }
          });

          // No remarks
          var kNoRemarksTitle = localize.getLocalizedString('_NoRemarks_');
          Object.defineProperty(scope, 'remarks', {
            get: function () {
              if (!scope.meeting) {
                return null;
              }

              return (scope.meeting && (scope.meeting.originalMeeting.Remarks !== scope.meeting.title) || !scope.meeting.originalMeeting.CustomerId) ? scope.meeting.originalMeeting.Remarks : kNoRemarksTitle
            }
          });

          Object.defineProperty(scope, 'recurrenceString', {
            get: function () {
              return scope.meeting && scope.meeting.originalMeeting.Recurrence ? iCalService.rRuleToString(scope.meeting.originalMeeting.Recurrence) : null;
            }
          });

          Object.defineProperty(scope, 'hasDebt', {
            get: function () {
              return scope.meeting && scope.meeting.originalMeeting.HasDebt;
            }
          });

          Object.defineProperty(scope, 'hasPrePayment', {
            get: function () {
              return scope.meeting && scope.meeting.originalMeeting.HasPrePayment;
            }
          });

          Object.defineProperty(scope, 'customerRemarks', {
            get: function () {
              return scope.meeting && scope.meeting.originalMeeting.CustomerId && scope.meeting.originalMeeting.CustomerRemarks;
            }
          });

          Object.defineProperty(scope, 'isNewCustomer', {
            get: function () {
              return scope.meeting && scope.meeting.originalMeeting.IsNewCustomer;
            }
          });

          Object.defineProperty(scope, 'isSubscription', {
            get: function () {
              return scope.meeting && scope.meeting.originalMeeting.IsSubscription;
            }
          });


          var kMissingRoomText = localize.getLocalizedString('_MissingRoom_');
          Object.defineProperty(scope, 'room', {
            get: function () {
              var toReturn = null;
              if (scope.meeting && scope.meeting.originalMeeting.RoomId) {
                var filteredRoom = calendarMetadata.rooms.filter(function (room) {
                  return room.id === scope.meeting.originalMeeting.RoomId;
                });

                toReturn = filteredRoom[0] ? filteredRoom[0].name : kMissingRoomText;
              }

              return toReturn;
            }
          });

          // END OF PROPERTIES.


          scope.meetingStatuses = availableStatuses;
          scope.statusVisibleWrapper = { isVisible: false };
          scope.meetingColorVisibleWrapper = { isVisible: false };
          var $document = $(document);
          $document.on('click.meeting', '.calendar-meeting:not(.meeting--pending-payment)', function (e) {
            if (!calendarMetadata.draggingMeeting) {
              showDialog.call(this, e, angular.element(this).scope().meeting);
            }
          });

          $document.on('click.meeting', '.all-day-meeting', function (e) {
            if (!calendarMetadata.draggingMeeting) {
              showDialog.call(this, e, angular.element(this).scope().meeting);
            }
          });

          $document.on('touchstart.meeting', '.calendar-meeting.history-meeting', function (e) {
            if (!calendarMetadata.draggingMeeting) {
              showDialog.call(this, e, angular.element(this).scope().meeting);
            }
          });

          scope.updateStatus = function (newStatus, meeting) {
            scope.meeting.originalMeeting.MeetingStatus = newStatus.value;
            scope.statusVisibleWrapper.isVisible = false;
            calendarStore.updateMeetingStatus(meeting, newStatus.value)
              .then(function (updatedMeeting) {
                scope.meeting = updatedMeeting;
              })
              .catch(function (e) {
                toastr.error(localize.getLocalizedString('_ErrorUpdatingMeeting_'));
              });
          };

          scope.updateColor = function (color, meeting) {
            scope.meeting.originalMeeting.Color = color.name;
            scope.meetingColorVisibleWrapper.isVisible = false;
            calendarStore.updateMeetingColor(meeting, color.name)
              .then(function (updatedMeeting) {
                scope.meeting = updatedMeeting;
              })
              .catch(function (e) {
                toastr.error(localize.getLocalizedString('_ErrorUpdatingMeeting_'));
              });
          };

          const showCalendarDelegate = $rootScope.$on('show-calendar-popover', function (_, e, metadata) {
            if (!scope.isVisible) {
              showDialog.call(this, e, null, metadata);
            } else {
              scope.close();
            }
          });

          function generatePopupForMeeting(meeting, metadata) {
            if (!meeting && !metadata) {
              console.log('cant show popover, no meeting or metadata.');
              return;
            }

            scope.meeting = meeting;
            scope.metadata = metadata;

            var startDate;
            if (meeting) {
              scope.avatarMode =
                meeting.originalMeeting.Image && !meeting.imageError;

              if (scope.avatarMode) {
                setTimeout(function () {
                  element.find('.customer-image')
                    .css('background-image', 'url(' + window.filesURI + meeting.originalMeeting.Image + ')');
                });
              }

              startDate = meeting.StartTime;
            } else {
              startDate = metadata.startTime;
              scope.avatarMode = false;
            }

            scope.dayOfWeek = startDate.format('dddd');
            scope.dayOfMonth = startDate.format('DD/MM');
          }

          function showDialog(e, meeting, metadata) {
            $popoverTrap.toggleClass('visible');
            createBasicActions(meeting, metadata);

            if (meeting) {
              if (!meeting.extended) {
                meeting.extend().then(function (clientMeeting) {
                  generateFullMeetingRelated(clientMeeting)
                })
              } else if (Array.isArray(meeting.originalMeeting.ServiceMetadatas)) {
                generateFullMeetingRelated(meeting)
              }
            }

            e.preventDefault();
            e.stopPropagation();

            element.css({
              display: 'initial',
              visibility: 'hidden',
              opacity: 0
            });

            scope.$apply(function () {
              scope.isVisible = true;
              generatePopupForMeeting(meeting, metadata);
            });

            function createBasicActions(meeting, metadata) {
              if (meeting) {
                scope.actions = [
                  availableActions.copy(scope, scope.close)];

                if (!meeting.originalMeeting.IsDeactiveted) {
                  scope.actions.push(availableActions.delete(scope, scope.close));
                }

                scope.coreAction = meeting.EndTime.isAfter(moment().add(-1, 'h')) || (meeting.originalMeeting && meeting.originalMeeting.IsAllDay && meeting.EndTime.isAfter(moment().add(-1, 'd'))) ?
                  coreActions.edit(scope, scope.close) : null;
              } else {
                scope.coreAction = coreActions.create(metadata, scope.close);
                scope.actions = [];
                scope.services = null;
                if (calendarClipboard.meetingsInClipboard.length > 0) {
                  let lastCustomerId = 0;
                  calendarClipboard.meetingsInClipboard.forEach(m => {
                    scope.actions.push(pasteAction(m, metadata, scope.close, lastCustomerId == m.originalMeeting.CustomerId));
                    lastCustomerId = m.originalMeeting.CustomerId;
                  });
                }
              }
            }

            function generateFullMeetingRelated(meeting) {
              var extraActions = [];

              if (meeting.originalMeeting.ServiceMetadatas) {
                scope.services = meeting.originalMeeting.ServiceMetadatas.map(function (service) {
                  var colorClass = 'default-service-color';
                  if (service.Color) {
                    colorClass = colorsService.getColorByName(service.Color);
                  }

                  return {
                    title: service.Title,
                    colorClass: colorClass
                  };
                });


                if (meeting.EndTime.isAfter(moment().add(-1, 'h'))) {
                  scope.actions.push(availableActions.cut(scope, scope.close));
                  if (meeting.originalMeeting.ServiceMetadatas.length > 1) {
                    extraActions.push(availableActions.split(scope, scope.close));
                  }
                }
              }

              if (meeting.originalMeeting.CustomerId) {
                scope.actions.push(availableActions.toCustomerDetails(scope, scope.close));
                if (isValidWhatsappAccount) {
                  extraActions.push(availableActions.sendWhatsapp(scope, scope.close));
                }
                extraActions.push(availableActions.sendSms(scope, scope.close));
                extraActions.push(availableActions.showHistory(scope, scope.close));
                if (includeCustomModelFormat) {
                  extraActions.push(availableActions.ingredients(scope, scope.close));
                }

                // scope.actions.push(availableActions.toCustomerDetails(scope, scope.close));
                if (meeting.originalMeeting.MeetingState !== "Paid" && !meeting.originalMeeting.Paid) {
                  scope.actions.push(availableActions.toCashRegister(scope, scope.close));
                }

                scope.actions.push(availableActions.more(scope, scope.close, extraActions));
              } else {
                scope.actions.push(availableActions.showHistory(scope, scope.close));
              }
              if (meeting.originalMeeting.Paid) {
                extraActions.push(availableActions.markAsNotPaid(scope, scope.close));
              }
            }


            var left = e.pageX, top = e.pageY;
            $timeout(calculatePosition);

            function calculatePosition() {
              var bounds = element[0].getBoundingClientRect();
              var elementLeft = left - (bounds.right - bounds.left) / 2;
              var elementTop = top - (bounds.bottom - bounds.top) - 60;
              var css = {
                'visibility': 'visible',
                'zIndex': 100000,
                opacity: 1,
                left: elementLeft,
                top: elementTop
              };

              var notInPlace = false;
              if (css.top < 5 && (top + bounds.bottom - bounds.top - window.innerHeight < 0)) {
                element.addClass('bottom-dialog');
                css.top = top - 30;
              } else {
                element.removeClass('bottom-dialog');
              }

              if (css.top < -50) {
                css.top = -50;
              }

              if (css.left < 5) {
                notInPlace = true;
                css.left = 5;
              } else if ((css.left + bounds.right - bounds.left) > window.innerWidth - 5) {
                notInPlace = true;
                css.left = window.innerWidth - (bounds.right - bounds.left) - 5;
              }

              if (notInPlace) {
                element.addClass('not-in-place');
              } else {
                element.removeClass('not-in-place');
              }

              element.css(css);
            }
          }


          function onScroll() {
            scope.close();
          }

          function onKeyup(evt) {
            evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
              isEscape = evt.key === "Escape";
            } else {
              isEscape = evt.keyCode === 27;
            }
            if (isEscape) {
              scope.close();
            }
          }


          function toggleScrollEvent(remove) {
            if (!remove) {
              window.addEventListener('scroll', onScroll, true);
              window.addEventListener('keyup', onKeyup, true);
              isWindowRegistered = true;
            } else if (isWindowRegistered) {
              window.removeEventListener('scroll', onScroll, true);
              window.removeEventListener('keyup', onKeyup, true);
              isWindowRegistered = false;
            }
          }

          var isWindowRegistered = false;

          scope.$watch('isVisible', function (newVal) {
            toggleScrollEvent(!newVal);
          });

          scope.$on('$destroy', function () {
            $($document).off('click.meeting');
            $($document).off('dblclick.meeting');
            $($document).off('touchstart.meeting');
            toggleScrollEvent(true);
            showCalendarDelegate();
          });

          scope.approveMeeting = function () {
            scope.isApprovingMeeting = true;
            calendarStore.approveMeeting(scope.meeting.MeetingId).then(() => {
              scope.isApprovingMeeting = false;
              toastr.success(localize.getLocalizedString("_MeetingApproved_"));
              $timeout(scope.close);
            }).catch(function () {
              toastr.error(e.Message);
              scope.isApprovingMeeting = false;
            });
          };

          var externalVouchersMap = {};
          if (configuration.get().ProductsAndServicesSettings.UseExternalVoucherModel) {
            setTimeout(function () {
              Repository.Entity('ExternalVoucherMetadata').query().expand('ExternalVoucherVendor').get().then(function (result) {
                result.value.forEach(function (voucher) {
                  externalVouchersMap[voucher.ExternalVoucherMetadataId] = voucher;
                });

                // console.log(externalVouchersMap);

              }).catch(function () {
                toastr.error(localize.getLocalizedString('_ErrorLoadingVouchers_'));
              });
            }, 500);
          }
        },
        template: require('./calendar.popover.tpl.html')
      };

    });
}());
