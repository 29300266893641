import './employee.work.hours.modal.less'
import { DATE_DISPLAY_FORMAT, HOURS_AND_MINUTES_FORMAT, ODATA_DATE_TIME_FORMAT } from "../../constants";
import { asArray } from "../../general/helpers";

angular.module('easybizy.easybizyModalViewsModel').controller('EmployeeWorkHoursModalController',
  function ($scope, $modalInstance, saveAction, localize, Repository, parameters, configuration, confirmService, $q) {
    $scope.isLoadingWrapper = { isLoading: true };
    Repository.Entity('Employee', true).query().id(parameters.employee.EmployeeId).expand('Image,DaysOfWork').get()
      .then(function (employee) {
        Object.assign($scope.employee, employee);
        initEmployeeSettings();
        $scope.isLoadingWrapper.isLoading = false;
      })
      .catch(function (err) {
        toastr.error(err.Message);
        $scope.isLoadingWrapper.isLoading = false;
      });

    $scope.employeeId = parameters.employee.EmployeeId;
    $scope.employeeName = parameters.employee.FirstName + " " + parameters.employee.LastName;
    $scope.employee = parameters.employee;

    $scope.daysOfWork = [];
    $scope.generalDaysOfWork = configuration.get().CalendarSettings.DaysOfWork;
    $scope.vacationAddMode = { mode: false };
    //currently we are not using the days of work hours in the configuration and we have general hours for all week
    //this needs to be removed when we will support dynamic hours display in the calendar
    $scope.generalStartTime = configuration.get().CalendarSettings.StartTime.Value;
    $scope.generalEndTime = configuration.get().CalendarSettings.EndTime.Value;
    for (var i = 0; i < 7; i++) {
      $scope.generalDaysOfWork[i].StartTime = $scope.generalStartTime;
      $scope.generalDaysOfWork[i].EndTime = $scope.generalEndTime;
    }

    var dayOffValues = [
      { name: localize.getLocalizedString('_Holiday_'), value: 'Holiday' },
      { name: localize.getLocalizedString('_Sick_'), value: 'Sick' },
      //{ name: localize.getLocalizedString('_Attendance_'), value: 'Attendance' }
    ];

    $scope.invalidDaysOfWork = [];
    $scope.isValidDayOfWork = function (dayOfWork) {
      var isValid = !dayOfWork.IsEnabled || (dayOfWork.StartTime < dayOfWork.EndTime && $scope.generalStartTime <= dayOfWork.StartTime && $scope.generalEndTime >= dayOfWork.EndTime);
      var isInArray = $.inArray(dayOfWork.Index, $scope.invalidDaysOfWork) !== -1;
      if (isValid) {
        if (isInArray) {
          $scope.invalidDaysOfWork.remove(dayOfWork.Index);
        }
      } else if (!isInArray) {
        $scope.invalidDaysOfWork.push(dayOfWork.Index);
      }
      return isValid;
    };

    $scope.newVacationValidateAction = {};
    $scope.newVacationFieldsValidator = {};
    $scope.newVacation = { VacationDate: moment().format(DATE_DISPLAY_FORMAT), Type: dayOffValues[0] };
    $scope.newVacationFields = [
      {
        fieldName: 'VacationDate',
        icon: "icon icon-calendar",
        yearRange: "-1:+2",
        placeholder: localize.getLocalizedString("_Date_"),
        type: 'date',
        validation: "{'date': true}"
      },
      {
        fieldName: 'Note',
        icon: "icon icon-message-outline",
        placeholder: localize.getLocalizedString("_Notes_"),
        validation: "{'max-length': 50}"
      },
      {
        fieldName: 'Type',
        placeholder: localize.getLocalizedString("_DayOffType_"),
        type: 'Combobox',
        values: dayOffValues
      }
    ];

    $scope.addVacation = function () {
      var validationFields = $scope.newVacationValidateAction.validate();
      if (validationFields.length != 0) { // is valid form?
        var wrongFieldName = $scope.newVacationFields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
        var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
        toastr.error(localizedError);
        return;
      }

      var dateToServer = moment($scope.newVacation.VacationDate, DATE_DISPLAY_FORMAT);
      var vacationToServer = {
        StartTime: dateToServer.hour(moment($scope.generalStartTime, HOURS_AND_MINUTES_FORMAT).hour()).format(ODATA_DATE_TIME_FORMAT),
        EndTime: dateToServer.hour(moment($scope.generalEndTime, HOURS_AND_MINUTES_FORMAT).hour()).format(ODATA_DATE_TIME_FORMAT),
        DaysOfWorkExceptionType: $scope.newVacation.Type.value,
        Remarks: $scope.newVacation.Note
      };

      Repository.Custom("EmployeeHours").daysOfWorkException($scope.employee.EmployeeId, vacationToServer)
        .then(function (result) {
          $scope.vacationAddMode.mode = false;
          $scope.employee.DaysOfWorkExceptions.push(result);
          $scope.vacations.push({
            DaysOfWorkExceptionId: result.DaysOfWorkExceptionId,
            date: moment(result.StartTime).format(DATE_DISPLAY_FORMAT),
            note: result.Remarks,
            type: getExceptionType(result.DaysOfWorkExceptionType)
          });
          toastr.success(localize.getLocalizedString("_EmployeeExceptionSuccessfulyAdded_"));
          $scope.$digestIfNeeded();
        })
        .catch(function (errorMessage) {
          try {
            var message = localize.getLocalizedString(errorMessage);
          } catch (e) {
            message = localize.getLocalizedString("_EmployeeExceptionError_");
          }
          toastr.error(message);
        });
    };

    $scope.removeVacationItem = function (vacationItem) {
      Repository.Custom("EmployeeHours").deleteDaysOfWorkException($scope.employee.EmployeeId, vacationItem.DaysOfWorkExceptionId)
        .then(function () {
          $scope.employee.DaysOfWorkExceptions.removeById(vacationItem, "DaysOfWorkExceptionId");
          $scope.vacations.remove(vacationItem);
          toastr.success(localize.getLocalizedString("_EmployeeExceptionSuccessfulyDeleted_"));
          $scope.$digestIfNeeded();
        })
        .catch(function (e) {
          toastr.error(localize.getLocalizedString("_EmployeeExceptionDeleteError_"));
        });
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
    $scope.save = function () {
      var deferred = $q.defer();

      confirmService.confirm(localize.getLocalizedString('_CancelFutureInlineChanges_'),
        null,
        function () {
          $scope.isSaving = true;
          saveAction(getCleanDaysOfWork(), true, deferred);
        },
        function () {
          saveAction(getCleanDaysOfWork(), false, deferred);
        }, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_')
      );


      deferred.promise.catch(function () {
        $scope.isSaving = false;
      });

      deferred.promise.then(function () {
        $modalInstance.close();
      });
    };

    function initEmployeeSettings() {
      var baseOfWorkHours;
      if ($scope.employee.DaysOfWork.length === 0) {
        baseOfWorkHours = $scope.generalDaysOfWork;
      } else {
        baseOfWorkHours = $scope.employee.DaysOfWork;
      }

      for (let i = 0; i < 7; i++) {
        if ($scope.generalDaysOfWork[i].IsEnabled) {
          $scope.daysOfWork.push({
            Index: i,
            name: moment().day(i).format('dd'),
            IsEnabled: baseOfWorkHours[i].IsEnabled,
            StartTime: baseOfWorkHours[i].StartTime ? baseOfWorkHours[i].StartTime : $scope.generalDaysOfWork[i].StartTime,
            EndTime: baseOfWorkHours[i].EndTime ? baseOfWorkHours[i].EndTime : $scope.generalDaysOfWork[i].EndTime,
            min: $scope.generalDaysOfWork[i].StartTime,
            max: $scope.generalDaysOfWork[i].EndTime
          });
        }
      }

      // $scope.vacations = getCleanDaysOfWorkExceptions();

    }

    function getCleanDaysOfWork() {
      return asArray($scope.daysOfWork).map(day => ({
        Index: day.Index,
        IsEnabled: day.IsEnabled,
        StartTime: day.StartTime,
        EndTime: day.EndTime
      }));
    }

    function getCleanDaysOfWorkExceptions() {
      return $scope.employee.DaysOfWorkExceptions.filter(function (exception) {
        return exception.DaysOfWorkExceptionType !== "Inline" && exception.DaysOfWorkExceptionType !== "Attendance";
      }).map(exception => ({
        DaysOfWorkExceptionId: exception.DaysOfWorkExceptionId,
        date: moment(exception.StartTime).format(DATE_DISPLAY_FORMAT),
        note: exception.Remarks,
        type: getExceptionType(exception.DaysOfWorkExceptionType)
      }));
    }

    function getExceptionType(type) {
      var result;
      if (isNaN(type)) {
        result = dayOffValues.filter(x => x.value === type);
      } else {
        result = dayOffValues[type];
      }
      return result.name;
    }
  });
