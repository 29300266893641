<template>
  <div class="whatsapp-on-boarding-wizard__reminders-explain">
    <h3 class="mt-0">{{ header }}</h3>
    <div class="whatsapp-on-boarding-wizard__reminders-explain-content">
      <div>
        <div class="flex items-center mb-2" v-for="item of items"
             :key="item">
          <div class="whatsapp-on-boarding-wizard__package-prop-text p-1">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="mt-4">
        <h4>{{ pricing.title }}</h4>
        <div>
          <div class="flex items-center mb-2" v-for="item of pricing.items"
               :key="item">
            <div class="whatsapp-on-boarding-wizard__package-prop-icon icon-check-circle text--success"></div>
            <div class="whatsapp-on-boarding-wizard__package-prop-text p-1">
              {{ item }}
            </div>
          </div>
          <div>* {{ $t('_PricesNotIncludeTax_') }}</div>
        </div>
      </div>
    </div>
    <div>
      <button class="whatsapp-on-boarding-wizard__reminders-explain-action background--primary mt-4" @click="nextStep">
        {{ actionText }}
      </button>
      <p v-if="expectedCostText">{{ expectedCostText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['next-step'],
  props: ['header', 'items', 'pricing', 'actionText', 'expectedCostText'],
  methods: {
    nextStep() {
      this.$emit('next-step')
    }
  }
}
</script>

<style lang="less">
@import "../../../../styles/variables";
@import "vars";

.whatsapp-on-boarding-wizard__reminders-explain-content {
  overflow: auto;
  max-height: 40vh;
}

.whatsapp-on-boarding-wizard__reminders-explain-action {
  animation: pulseIt 3s infinite;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
  border: 2px solid @primary-color;
}
</style>
