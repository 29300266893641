<template>
  <div class="wizard-grid-selection-wrapper">
    <Grid :dataSource="dataSource" v-bind="gridProps"></Grid>
  </div>
</template>

<script>
import { Grid, GridDataSource, GridLabels } from "@tomeravni/easybizy-vue-components";
import {resolveDefaultGridLabels} from "../../../vue.helpers";

export default {
  created() {
  },
  components: {
    Grid
  },
  props: {
    dataSource: {
      type: GridDataSource,
      default: null
    }

  },
  data() {
    const labels = resolveDefaultGridLabels(this.$t);

    return {
      gridProps: {
        freezeHeader: true,
        hideBorder: false,
        elevated: true,
        pickColumns: false,
        rowHeightMode: 'tight',
        backgroundColor: 'auto',
        height: 'calc(100vh - 350px)',
        allowSelection: true,
        preventScrollTopButton: false,
        selectOnDirty: true,
        labels
      }
    }
  },
  methods: {},
}

</script>

<style lang="less">
@import "../../../../../styles/variables";

.wizard-grid-selection-wrapper {
  display: flex;
  justify-content: center;
  max-width: 90vw;

  p {
    margin-bottom: 0;
  }

}

</style>
