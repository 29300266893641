import {getSafely, isNonEmptyArray, isNonEmptyString} from "@tomeravni/easybizy-js-common/common";
import {extractError} from "@tomeravni/easybizy-js-common/errors";

(function () {
  'use strict';

  angular.module('easybizy.common.common-controls').directive('fbLeadsLogin', ['localize', 'Repository', 'confirmService',
    (localize, Repository, confirmService) => {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          text: '='
        },
        template: `
<div>
    <div class="text-base" ng-if="!loadingFacebookPages && !connectedPages">
        <p i18n="_ConnectLeadsToFacebookPagesExplain_"></p>
        <div class="inline-block">
          <vue-button v-props-click="click"
            v-props-primary="true"
            v-props-disabled="disabled"
            v-props-initial-text-size="true"
            v-props-in-progress="inProgress">
            <div class="flex items-center gap-2">
                <img class="h-6 w-6" src="/img/facebook-144.png"/><div>{{text}}</div>
            </div>
          </vue-button>
        </div>
    </div>
    <div ng-if="connectedPages" class="flex items-center gap-4">
        <div class="flex items-center gap-2 text--primary">
            <span class="icon icon-check-circle"></span><span i18n="_FacebookLeadsAppConnected_"></span><span>:&nbsp;{{connectedPages}}</span>
        </div>
        <a ng-click="unregisterClicked()" class="cursor-pointer"><span class="icon icon-delete-garbage-streamline "></span><span>${localize.getLocalizedString('_Delete_')}</span></a>

    </div>
</div>
`,
        compile: function () {
          (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
              return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));


          window.fbAsyncInit = function () {
            // JavaScript SDK configuration and setup
            FB.init({
              appId: window.facebookLeadsAppId, // Facebook App ID
              cookie: true, // enable cookies
              xfbml: true, // parse social plugins on this page
              version: 'v20.0' //Graph API version
            });
          }


          // LINK Function
          return function (scope) {
            loadConnectedPages();

            scope.unregisterClicked = () => {
              confirmService.confirm(localize.getLocalizedString('_Delete_'),
                localize.getLocalizedString('_UnregisterFacebookPagesConfirm_'),
                function () {
                  unregisterPages();
                },
                function () {
                }
              );
            }

            scope.disabled = false;
            scope.inProgess = false;
            let shouldRemovePages = false;
            scope.toggleDisable = (isDisabled) => scope.$evalAsync(() => {
              scope.disabled = isDisabled
              if (!isDisabled) {
                scope.inProgess = false
              }
            })

            scope.click = () => scope.$evalAsync(() => {
              if (scope.disabled) {
                return;
              }

              scope.toggleDisable(true)
              FB.login(function (response) {
                const accessToken = getSafely(['authResponse', 'accessToken'], response);
                if (isNonEmptyString(accessToken)) {
                  if (shouldRemovePages) {
                    return unregisterPages(accessToken);
                  }

                  scope.$evalAsync(() => scope.inProgess = true)
                  Repository.Custom('Configurations').registerFacebookPages(accessToken).then((res) => {
                    scope.$evalAsync(loadConnectedPages)
                    if (res.success) {
                      toastr.success(localize.getLocalizedString('_FacebookLeadsAppSuccessfullyConnected_'));
                    } else {
                      toastr.error(localize.getLocalizedString('_FacebookLeadsAppFailedToConnect_'));
                    }

                    scope.toggleDisable(false)
                  }).catch((e) => {
                    scope.$evalAsync(loadConnectedPages)
                    scope.toggleDisable(false)
                    toastr.error(extractError(e));
                  })
                } else {
                  scope.toggleDisable(false)
                  toastr.error('User cancelled login or did not fully authorize.');
                }
              }, {
                scope: 'public_profile,leads_retrieval,pages_read_engagement,pages_manage_ads,ads_management,pages_manage_metadata,pages_show_list,business_management,ads_read'
              })
            });

            function unregisterPages(optionalAccessToken) {
              scope.inProgess = true;
              Repository.Custom('Configurations').deregisterFacebookPages(optionalAccessToken).then(() => {
                scope.inProgess = false;
                scope.connectedPages = null;
                toastr.success(localize.getLocalizedString('_EntityDeleteSuccessfully_'));
                loadConnectedPages();
              }).catch((e) => {
                scope.inProgess = false;
                toastr.error(extractError(e.data || e));
                if (shouldRemovePages) {
                  shouldRemovePages = false;
                  return;
                }

                shouldRemovePages = true;
                scope.click();
              })
            }

            function loadConnectedPages() {
              scope.loadingFacebookPages = true;
              scope.connectedPages = null;
              Repository.Custom('Configurations').getFacebookPages().then((res) => {
                scope.connectedPages = isNonEmptyArray(res) ? res.map((page) => page.name).join(', ') : null;
                scope.loadingFacebookPages = false;
              }).catch((e) => {
                scope.loadingFacebookPages = false;
                toastr.error(extractError(e));
              })
            }
          };
        }
      }
    }]);
})();
