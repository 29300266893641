import './payment.link.indicator.directive.less';
import { isConcreteValue } from "@tomeravni/easybizy-js-common/common";

(function () {

  angular.module('easybizy.cash.register').directive('paymentLinkIndicator', function (localize, $timeout) {
    return {
      scope: {
        paymentLinkUrl: '='
      },
      link: function (scope) {
        scope.tooltip = localize.getLocalizedString('_PaymentLinkAttached_');
        scope.blink = false;
        scope.$watch('paymentLinkUrl', (newVal, oldVal) => {
          if (isConcreteValue(oldVal) && isConcreteValue(newVal)) {
            scope.blink = true;
            $timeout(() => scope.blink = false, 2000);
          }
        })
      },
      template: `
          <div class="cash-register__payment-link absolute flex items-center z-10" ng-class="{'cash-register__payment-link--hidden': !paymentLinkUrl, 'cash-register__payment-link--updated': blink}">
            <a class="cash-register__payment-link-text icon icon-payment-link" target="_blank"
                ng-href="{{ paymentLinkUrl }}"
                 tippy="{{::tooltip}}"
            >
            </a>
          </div>
      `
    }
  });


}());
