import './notification.center.directive.less'
import { asArray } from "../../../general/helpers";
import { DATE_DISPLAY_FORMAT, SERVER_DATA_DATE_FORMAT } from "../../../constants";

angular.module('easybizy.common.toolbar.actions').directive('notificationCenter', function (localize, $transitions) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      show: '=?',
      hasNotifications: '=',
      independentSections: '=',
      items: '='
    },
    controller: function ($scope) {
      var listeners = [];
      this.register = function (listener) {
        listeners.push(listener);
      };

      this.hide = function () {
        $scope.show = false;
      };

      $scope.$watch('show', function (newVal, oldVal) {
        if (angular.isDefined(newVal) && newVal !== oldVal) {
          listeners.map(function (x) {
            x(newVal);
          });
        }
      });

      $transitions.onStart({}, function (trans) {
        $scope.show = false;
      })
    },
    link: function (scope, element, attrs) {
      scope.$on('$routeChangeStart', function (next, current) {
        scope.show = false;
      });

      scope.$watchCollection('items', function (newVal) {
        if (angular.isDefined(newVal)) {
          const reduced = newVal.reduce((acc, curr) => Object.assign(acc, { [curr.ConcreteType]: (acc[curr.ConcreteType] ? acc[curr.ConcreteType].concat([curr]) : [curr]) }), {})
          scope.sections = Object.keys(reduced)
            .map(key => ({
              Type: "SearchResult",
              ConcreteType: key,
              items: reduced[key],
              allowRemove: false
            }));

          if (scope.sections.length > 0) {
            scope.show = true;
          }
        }

      });

      scope.sections = [];
      scope.$watchOnce('independentSections', function (newVal) {
        newVal.forEach(function (section) {
          scope.sections.push({ name: section });
        });
      });

      scope.outsideClicked = function () {
        if (attrs.ignoreOutsideClick !== 'true') {
          scope.show = false;
        }
      };

      scope.hasNotifications = false;
    },
    template: '<div class="notification-center-element" ng-class="{\'visible-notifications\': show}"> \
                        <div outside-click="outsideClicked()" active="show">\
                            <div class="notification-center-section-wrapper" ng-repeat="section in sections">\
                                <notification-center-section section="section"></notification-center-section>\
                            </div>\
                        </div>\
                   </div>'
  };
});

angular.module('easybizy.common.toolbar.actions').directive('notificationCenterSection', ['notificationSectionVisitor', '$state',
  function (notificationSectionVisitor, $state) {
    var supportedDetailsEntities = "Customer ProductMetadata ServiceMetadata Meeting IncomingCall";
    return {
      restrict: 'E',
      replace: true,
      scope: {
        section: '='
      },
      require: '^notificationCenter',
      controller: function ($scope) {
        $scope.selectedItems = [];
        this.setSelected = function (item) {
          if (item.selected) {
            $scope.selectedItems.push(item);
          } else {
            $scope.selectedItems.remove(item);
          }
        };
        this.remove = function (item) {
          $scope.section.remove(item);
        };

        this.hide = function () {
          $scope.hideMainNotifications()

        };

        this.allowRemove = function () {
          return $scope.section.allowRemove === true;
        };

        this.isSelectable = function () {
          return $scope.section.selectable === true;
        };

        this.getDetailsLink = function (item) {
          if (supportedDetailsEntities.indexOf(item.ConcreteType) < 0 || !item.Id) {
            return undefined;
          }

          return function () {
            var routeName = "";
            var idName = "";
            if (item.ConcreteType === 'Customer') {
              routeName = 'CustomerDetails';
              idName = "customerId";
            } else if (item.ConcreteType === 'ProductMetadata') {
              routeName = 'ProductDetails';
              idName = "productId";
            } else if (item.ConcreteType === 'ServiceMetadata') {
              routeName = 'ServiceDetails';
              idName = "serviceId";
            } else if (item.ConcreteType === 'Meeting') {
              routeName = 'Calendar';
              idName = "meetingId";
            } else if (item.ConcreteType === 'IncomingCall') {
              routeName = 'CustomerDetails';
              idName = "customerId";
            }

            var param = {};
            param[idName] = item.Id;

            $state.go(routeName, param);
          };
        };
      },
      link: function (scope, element, attrs, notificationCenterCtrl) {
        scope.hideMainNotifications = notificationCenterCtrl.hide;
        notificationSectionVisitor.visit(scope.section, scope, element);

        if (angular.isDefined(scope.toggled)) {
          notificationCenterCtrl.register(function (show) {
            scope.toggled(show);
            scope.section.icon = "";
          });
        }

        if (scope.section.headerAppend) {
          element.find('.notification-center-section-header').append(scope.section.headerAppend)
        }

        scope.$watchCollection('section.items', function (newVal, oldVal) {
          if (newVal >= 0 && newVal != oldVal) {
            scope.selectedItems.length = 0;
            var selectedItems =
              asArray(scope.section.items).filter(x => x.selected);

            if (selectedItems.length > 0) {
              scope.selectedItems.pushAll(selectedItems);
            }
          }
        });

        scope.resolveAction = function () {
          scope.section.action(scope.section.items, scope.selectedItems);
        };

        scope.remove = function () {
          scope.section.remove(scope.selectedItems);
        };
      },
      template: `<div class="notification-center-section">
            <div class="notification-center-section-header" ng-class="{'notification-section-with-items': sectionDetails.items.length}">
                <div class="flex justify-between items-center">
                  <div class="flex justify-start items-center gap-x-2 notification-center-section-header__title">
                      <div class="{{::section.icon}}"></div>
                      <div>{{::section.name}}</div>
                  </div>
                  <div ng-if="section.selectable" class="notification-center-section-selected-items-wrapper">
                      <div class="notification-center-section-selected-header">
                          <submit-button class="warning-submit" text="_Remove_" click="remove()"
                                         disabled="selectedItems.length == 0"></submit-button>
                          <submit-button localized-text="section.actionName" click="resolveAction()"
                                         disabled="!section.allowCoreAction && selectedItems.length === 0"></submit-button>
                      </div>
                  </div>
                  <div ng-if="!section.selectable && section.allowCoreAction" class="notification-center-section-selected-items-wrapper">
                      <div class="notification-center-section-selected-header">
                        <submit-button localized-text="section.actionName" click="resolveAction()"></submit-button>
                      </div>
                  </div>
                </div>
            </div>
            <div class="notification-center-section-items-wrapper">
                <notification-center-item ng-repeat="item in section.items" item-details="item" bindonce></notification-center-item>
            </div>
        </div>`
    }
  }]);

angular.module('easybizy.common.toolbar.actions').directive('notificationCenterItem', function (localize, notificationItemVisitor, $compile) {
  var goToDetailsTemplate = '<div class="notification-go-to-details icon icon-go-right"></div>';

  return {
    restrict: 'E',
    replace: true,
    scope: {
      itemDetails: '='
    },
    require: '^notificationCenterSection',
    link: function (scope, element, attrs, notificationCenterSectionCtrl) {
      var lastState = scope.itemDetails.selected;
      scope.selectable = notificationCenterSectionCtrl.isSelectable();
      if (scope.selectable) {
        scope.itemSelected = function (e) {
          if (lastState == scope.itemDetails.selected) {
            scope.itemDetails.selected = !scope.itemDetails.selected;
          }

          notificationCenterSectionCtrl.setSelected(scope.itemDetails);
          lastState = scope.itemDetails.selected;
        };
      }

      if (notificationCenterSectionCtrl.allowRemove()) {
        scope.allowRemove = true;
        scope.remove = function (e) {
          e.stopPropagation();
          notificationCenterSectionCtrl.remove(scope.itemDetails);
        };
      }

      scope.detailsAction = notificationCenterSectionCtrl.getDetailsLink(scope.itemDetails);

      if (scope.detailsAction) {
        $compile(goToDetailsTemplate)(scope).on('click', function () {
          scope.$evalAsync(scope.detailsAction);
        }).appendTo(element);
      }

      scope.close = notificationCenterSectionCtrl.hide;

      scope.baseUrl = window.filesURI;
      scope.defaultTitle = localize.getLocalizedString("_DefaultImageTitle_");
      scope.hideMain = notificationCenterSectionCtrl.hide;
      notificationItemVisitor.visit(scope, element);

    },
    template: '<div class="notification-center-item" ng-class="{\'selected-notification-item\': itemDetails.selected}" \
                        ng-click="itemSelected($event)" disable-click-effect="{{!selectable}}">\
            <div class="notification-center-item-content"><div class="delete-notification-icon icon icon-flat-close" ng-click="remove($event)" ng-if="!selectable && allowRemove"></div>\
            <checkbox active="itemDetails.selected" ng-if="selectable" model="itemDetails" field="selected"></checkbox>\
            <div class="notification-center-item-image-wrapper" ng-if="::itemDetails.FilePath">\
                <img bo-src="baseUrl + itemDetails.FilePath" /> \
            </div>\
            <div class="notification-center-item-image-wrapper icon-mode" ng-if="::itemDetails.Icon">\
                <div ng-class="::itemDetails.Icon"></div>\
            </div>\
            <div class="notification-center-item-details">\
                <div class="notification-center-item-details-title ui-ellipsis" bo-bind="itemDetails.Title" bo-if="itemDetails.Title"></div>\
                <div class="notification-center-item-details-title ui-ellipsis default-image-title" bo-bind="defaultTitle" bo-if="!itemDetails.Title"></div>\
                <div class="notification-center-item-details-subtitle ui-ellipsis" bo-bind="itemDetails.Description" bo-if="itemDetails.Description"></div>\
            </div></div>\
        </div>'
  };
});

angular.module('easybizy.common.toolbar.actions').factory('notificationSectionVisitor', ['localize', '$modal', 'Repository', 'notificationCenterBus', '$compile',
  function (localize, $modal, Repository, notificationCenterBus, $compile) {
    var visitors = {
      appImages: visitAppImages,
      searchResultItems: searchResultItems,
      missedCalls: visitMissedCalls
    };

    var searchSectionMappers = [
      {
        type: 'Customer',
        name: localize.getLocalizedString("_Customers_"),
        icon: 'icon icon-clients-ol'
      },
      {
        type: 'ProductMetadata',
        name: localize.getLocalizedString("_Products_"),
        icon: 'icon icon-tag-price'
      },
      {
        type: 'ServiceMetadata',
        name: localize.getLocalizedString("_Services_"),
        icon: 'icon icon-quantity'
      },
      {
        type: 'Meeting',
        name: localize.getLocalizedString("_Meetings_"),
        icon: 'icon icon-calendar'
      },
      {
        type: 'Employee',
        name: localize.getLocalizedString("_Employees_"),
        icon: 'icon icon-users'
      }
    ];

    var searchSectionMap = {
      'Customer': searchSectionMappers[0],
      'ProductMetadata': searchSectionMappers[1],
      'ServiceMetadata': searchSectionMappers[2],
      'Meeting': searchSectionMappers[3],
      'Employee': searchSectionMappers[4]
    };

    return {
      visit: visit
    };

    function visit(section, scope, element) {
      if (visitors.hasOwnProperty(section.name)) {
        visitors[section.name](section, scope, element);
      } else if (section.Type === "SearchResult") {
        searchResultItems(section, scope, element);
      }
    }

    function searchResultItems(section, scope, element) {
      var sectionMap = searchSectionMap[section.ConcreteType];
      Object.assign(section, sectionMap);
    }

    function visitMissedCalls(section, scope, element) {
      //odata/IncomingCalls?$expand=Customer&$top=5&$orderby=CreatedOn%20desc
      var sectionDetails = new function () {
        var that = this;
        this.sectionName = 'callsLog'
        this.name = localize.getLocalizedString('_CallsLog_');
        this.icon = 'icon icon-sms';
        this.selectable = false;
        this.allowRemove = true;
        this.allowCoreAction = true;
        this.actionName = localize.getLocalizedString("_ShowAll_");
        this.headerAppend = $compile(`<void-options class="line-height-normal"></void-options>`)(scope);
        this.action = function () {
          var opts = {
            backdrop: 'static',
            keyboard: true,
            backdropClick: false,
            template: require('../../../modal-views/call-log/call.log.modal.html'),
            controller: 'CallLogModalController'
          };

          $modal.open(opts);
        };

        this.remove = function (item) {
          scope.section.items.remove(item);
          Repository.Entity("IncomingCall").patch(item.IncomingCallId, { Status: "Handled" }).post()
            .then(function () {
              notificationCenterBus.statusChanged(scope.section.items.length > 0, scope.section.items.length);
            })
            .catch(function (err) {
              scope.section.items.unshift(item);
              notificationCenterBus.statusChanged(scope.section.items.length > 0, scope.section.items.length);
              toastr.error(localize.getLocalizedString("_GeneralError_", err));
            });
        };

        this.items = [];
      };

      Object.assign(section, sectionDetails);

      notificationCenterBus.registerStatus((_, __, notifications) => {
        scope.section.items = notifications;
        scope.hasNotifications = notifications.length > 0;
      })

    }

    function visitAppImages(section, scope, element) {
      var sectionDetails = new function () {
        var that = this;
        this.sectionName = 'appImages';
        this.name = 'Photos';
        this.icon = 'icon icon-photo';
        this.selectable = true;
        this.actionName = localize.getLocalizedString("_ConnectToCustomer_");
        this.action = assignImagesToCustomer;
        this.remove = function (itemOrItems) {
          Repository.Entity("Image").remove(itemOrItems)
            .then(function () {
              that.items.remove(itemOrItems);
              toastr.success(localize.getLocalizedString("_ImageDeleted_"));
            })
            .catch(function (err) {
              toastr.error(localize.getLocalizedString("_ProblemDeletingImage_") + " " + err);
            });

        };

        this.items = [];
      };

      _.extend(section, sectionDetails);


      scope.toggled = function (isVisible) {
        if (isVisible) {
          loadImagesFromApp();
        }
      };

      function loadImagesFromApp() {
        Repository.Entity("Image").query().filter("IsFromMobileApp eq true and IsTemporary eq true and IsForImagePicker eq false").get()
          .then(function (data) {
            var images = data.results;
            images.forEach(function (x) {
              x.Title = x.ImageName;
            });
            scope.section.items.pushAllNewById(images, "ImageId", true);
            scope.hasNotifications = data.results.length > 0;
          })
          .catch(function (err) {
            toastr.error(localize.getLocalizedString("_CouldntGetImagesFromApp_"));
          });
      }

      function assignImagesToCustomer(items, selectedItems) {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../../modal-views/customer-images-assignment/customer.images.assignment.tpl.html'),
          controller: 'AssignImagesToCustomerController',
          resolve: {
            saveAction: function () {
              return function (customer, invoice, selectedImages) {
                angular.forEach(selectedImages, function (image) {
                  if (customer) {
                    Repository.Entity("Customer").createLink(customer, "Images", "Image", image.ImageId);
                  }
                  if (invoice && invoice.TreatmentId > 0) {
                    Repository.Entity("Treatment").createLink({ TreatmentId: invoice.TreatmentId }, "Images", "Image", image.ImageId);
                  }

                });
                items.remove(selectedImages);
              };
            },
            images: function () {
              return selectedItems;
            }
          }
        };

        $modal.open(opts);
      }
    }
  }]);

angular.module('easybizy.common.toolbar.actions').factory('notificationItemVisitor', ['localize', '$compile',
  '$state', 'employeesCrudFactory', '$modal', 'Repository', 'contextManager', '$q', 'configuration',
  function (localize, $compile, $state, employeesCrudFactory, $modal, Repository, contextManager, $q, configuration) {
    var k_dateFormat = "YYYYMMDD";


    var visitors = {
      Customer: visitCustomer,
      IncomingCall: visitIncomingCall,
      Employee: visitEmployee,
      ProductMetadata: visitProduct,
      ServiceMetadata: visitProduct,
      Meeting: visitMeeting
    };

    return {
      visit: visit
    };

    function visit(scope, element) {
      if (visitors.hasOwnProperty(scope.itemDetails.ConcreteType)) {
        visitors[scope.itemDetails.ConcreteType](scope, element);
      }
    }

    function visitCustomer(scope, element) {
      scope.itemDetails.firstActionText = localize.getLocalizedString("_ToCashRegister_");
      scope.itemDetails.firstAction = function () {
        setCurrentCustomerContext(scope.itemDetails);
      };

      scope.itemDetails.Description = splitFields(scope.itemDetails.Description, ["", localize.getLocalizedString("_ShortNotes_")]);
      if (scope.itemDetails.MeaningfulDate) {
        scope.itemDetails.Description = moment(scope.itemDetails.MeaningfulDate, k_dateFormat).format(DATE_DISPLAY_FORMAT) + (scope.itemDetails.Description ? ", " : "") + scope.itemDetails.Description;
      }

      appendActionTemplate(1, scope, element);
    }


    function visitIncomingCall(scope, element) {
      scope.itemDetails.firstActionText = localize.getLocalizedString("_ScheduleAMeeting_");
      scope.itemDetails.firstAction = function (e) {
        setCurrentCustomerContext(scope.itemDetails);
        scope.close();
      };

      if (scope.itemDetails.Image) {
        scope.itemDetails.FilePath = scope.itemDetails.Image;
      } else if (scope.itemDetails.Customer) {
        scope.itemDetails.Icon = scope.itemDetails.Customer.Gender === 'Male' ? 'icon icon-man' : 'icon icon-woman';
      } else {
        scope.itemDetails.Icon = 'icon icon-sms';
      }

      appendActionTemplate(1, scope, element);
      if (Object.getSafely(['loggedInUser', 'operator', 'vendor'], configuration)) {
        scope.$evalAsync(() => {
          const clickCallItem = $compile(`<div class="notification-center-item-details-title ui-ellipsis"><span click-call="itemDetails.FromNumber" click-call-title="itemDetails.Title">{{ itemDetails.Title }}</span></div>`)(scope);
          element.find('.notification-center-item-details-title').replaceWith(clickCallItem);
        })
      }
    }

    function visitEmployee(scope, element) {
      scope.itemDetails.firstActionText = localize.getLocalizedString("_Edit_");
      scope.itemDetails.firstAction = function () {
        editEmployee(scope.itemDetails);
      };

      if (scope.itemDetails.MeaningfulDate) {
        scope.itemDetails.Description = localize.getLocalizedString("_DateOfBirth_") + ": " +
          moment(scope.itemDetails.MeaningfulDate, k_dateFormat).format(DATE_DISPLAY_FORMAT);
      }

      appendActionTemplate(1, scope, element);
      element.removeClass("single-action").addClass("single-action-no-details");
    }

    function visitProduct(scope, element) {
      scope.itemDetails.firstActionText = localize.getLocalizedString("_AddToBill_");
      scope.itemDetails.firstAction = function () {
        contextManager.set('cashRegister', { items: [scope.itemDetails] });
      };

      var splitted = scope.itemDetails.CustomTextField.split(' ');
      scope.itemDetails.Description = splitted.length > 0 ? splitted[0].toMoney() : splitted[1].toMoney();
      appendActionTemplate(1, scope, element);
      element.removeClass("single-action").addClass("two-actions");
    }

    function visitMeeting(scope, element) {
      var meetingDateMoment = moment.isMoment(scope.itemDetails.StartTime) ? scope.itemDetails.StartTime : moment(scope.itemDetails.StartTime, SERVER_DATA_DATE_FORMAT);
      if (!meetingDateMoment.isValid()) {
        meetingDateMoment = moment(scope.itemDetails.StartTime);
      }
      var meetingDate = meetingDateMoment.format('YY-MM-DD');

      // if (!scope.itemDetails.Remarks) {
      //   scope.itemDetails.Title = scope.itemDetails.CalendarEventName;
      //   scope.itemDetails.Description = "";
      // }

      scope.itemDetails.Title = scope.itemDetails.CalendarEventName.replace(/undefined/ig, '');
      scope.itemDetails.Description = meetingDateMoment.format('LLL');
      if (scope.itemDetails.Image) {
        scope.itemDetails.FilePath = scope.itemDetails.Image;
      } else {
        scope.itemDetails.Icon = 'icon icon-clients-ol';
      }

      var navigateToMeeting = function () {
        $state.go('Calendar', {
          date: meetingDate,
          meeting: scope.itemDetails.MeetingId
        });

        scope.hideMain();
      };

      scope.detailsAction = navigateToMeeting;
    }

    function appendActionTemplate(numOfActions, scope, element) {
      var toAppend = '<div class="notification-center-item-actions-wrapper">\
                                    <submit-button class="shallow-submit" localized-text="itemDetails.firstActionText"\
                                                   click="itemDetails.firstAction($event)"></submit-button>'
        + (numOfActions === 1 ? "" :
          '<submit-button class="shallow-submit" localized-text="itemDetails.secondActionText" \
                          click="itemDetails.secondAction($event)"></submit-button>') +
        '</div>';
      element.addClass(numOfActions === 1 ? "single-action" : "two-actions");
      var goToDetailsEl = element.find('.notification-go-to-details');
      if (goToDetailsEl.length > 0) {
        ($compile(toAppend)(scope)).insertBefore(goToDetailsEl);
      } else {
        element.append($compile(toAppend)(scope));
      }
    }

    function setCurrentCustomerContext(customer) {
      if (customer.CustomerId) {
        contextManager.set('customer', customer.CustomerId);
      } else if (customer.FromNumber) {
        contextManager.set('customerPhoneNumber', customer.FromNumber);
      }

      $state.go('Calendar');
    }

    function editEmployee(searchedEmployee) {
      var opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../../modal-views//add-employee/add.employee.modal.tpl.html'),
        controller: "AddEmployeeModalController",
        resolve: {
          saveAction: function () {
            return function (employee, oldEmployee) {
              return employeesCrudFactory.createOrUpdate(employee, oldEmployee)
                .then(function (updatedEmployee) {
                  modalInstance.close();
                })
            }
          },
          parameters: function () {
            return {
              employeeResolver: function () {
                var deferred = $q.defer();
                Repository.Entity("Employee").query().id(searchedEmployee.Id).expand("Image").get()
                  .then(function (employee) {
                    deferred.resolve(employee);
                  })
                  .catch(function (err) {
                    deferred.reject(err);
                  });

                return deferred.promise;
              }
            };
          }
        },
        closeFn: function () {
        }
      };

      var modalInstance = $modal.open(opts);
    }

    function splitFields(string, fields) {
      var toReturn = "";
      string.split(',').forEach(function (value, index) {
        value = value.trim();
        if (value.length > 0) {
          if (toReturn.length > 0) {
            toReturn += ", ";
          }

          toReturn += fields[index] + (fields[index] ? ": " : "") + value;
        }
      });

      return toReturn;
    }
  }]);
