var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vue-button",
      class: [
        _vm.toggled && "toggled",
        _vm.primary && "background--primary",
        _vm.secondary && "vue-button--secondary",
        _vm.disabled && "vue-button--disabled",
        _vm.badge && "vue-button--badge",
        _vm.initialTextSize && "vue-button--initial-text-size",
        _vm.inProgress && "vue-button--in-progress",
      ],
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.toggled && !_vm.disabled
          ? _c("div", { staticClass: "mask", on: { click: _vm.toggle } })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.inProgress
        ? _c("div", { staticClass: "vue-button__loader" }, [_c("span")])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "vue-button__container grow" }, [
        _c(
          "button",
          {
            staticClass:
              "vue-button__core grow text-center overflow-hidden relative",
            attrs: { disabled: _vm.disabled },
            on: { click: _vm.myClickDelegate },
          },
          [
            _vm._t("default"),
            _vm._v(" "),
            _vm.badge
              ? _c(
                  "div",
                  {
                    staticClass: "vue-button__badge absolute flex items-center",
                    class: _vm.blinkingBadge && "vue-button__badge--blink",
                  },
                  [_c("div", { class: _vm.badge })]
                )
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _vm.actions && _vm.actions.length > 0
          ? _c("div", { staticClass: "vue-button__second-action" }, [
              _c("div", { staticClass: "vue-button__separator" }),
              _vm._v(" "),
              _c("div", { staticClass: "flex flex-center" }, [
                _c("button", {
                  staticClass: "icon caret-item",
                  class: _vm.toggled ? "icon-caret-left" : "icon-caret-right",
                  attrs: { disabled: _vm.disabled },
                  on: { click: _vm.toggle },
                }),
                _vm._v(" "),
                _vm.secondActionTooltip
                  ? _c("div", { staticClass: "custom-tooltip" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.secondActionTooltip) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "genie" } }, [
        _vm.actions && _vm.toggled
          ? _c("div", { staticClass: "vue-button__dropdown" }, [
              _c(
                "ul",
                _vm._l(_vm.actions, function (item) {
                  return _c(
                    "li",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.itemClicked(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }