(function () {
  angular.module('easybizy.calendar').directive('daysPlaceholderDrawer', [
    'calendarColumns',
    'calendarMetadata', 'localize', 'calendarStore', '$rootScope', '$timeout',
    function (calendarColumns, calendarMetadata, localize, calendarStore, $rootScope, $timeout) {
      var rowsRendered = 0;
      var dayString = localize.getLocalizedString('_Day_');

      return {
        restrict: 'E',
        replace: true,
        scope: {
          editMode: '='
        },
        link: function (scope, element) {
          calendarColumns.on('columnsChanged', render);

          var lastCreatedColumns;
          var firstRun = true;
          scope.$watch('editMode', toggleDynamicBehavior);

          function render(columns) {
            var preventColumnsReRender = lastCreatedColumns && lastCreatedColumns.length === columns.length;
            if (preventColumnsReRender) {
              updateColumnsMeta(lastCreatedColumns, columns);
              lastCreatedColumns = columns.slice(0);
              return;
            } else if (lastCreatedColumns) {
              modifyColumns(lastCreatedColumns, columns);
              updateColumnsMeta(lastCreatedColumns, columns);
              lastCreatedColumns = columns.slice(0);
              return;
            }

            var tempLastCreatedColumns = lastCreatedColumns;
            lastCreatedColumns = columns.slice(0);
            var htmlToAppend = '<table>';
            var endTime = calendarMetadata.endTime;
            var startTime = calendarMetadata.startTime;
            rowsRendered = 0;
            while (startTime.isBefore(endTime)) {
              rowsRendered++;
              htmlToAppend += '<tr>';
              columns.forEach(function (col) {
                htmlToAppend += createCellForColumn(col);
              });

              htmlToAppend += '</tr>';
              startTime.add(calendarMetadata.minutesBetweenMeetings, 'm');
            }

            htmlToAppend += '</tr></table>';
            element.html(htmlToAppend);

            if (firstRun) {
              toggleDynamicBehavior(false);
            }

            updateColumnHours(tempLastCreatedColumns, columns);
            firstRun = false;
          }

          function modifyColumns(oldColumns, newColumns) {
            var currentNewColumn, currentOldColumn;
            var tdsToAppend = '';
            var tdsToAppendLength = 0;
            for (var newColIdx in newColumns) {
              currentNewColumn = newColumns[newColIdx];
              currentOldColumn = oldColumns[newColIdx];
              if (!currentOldColumn) { // this means new columns have more columns.
                tdsToAppend += createCellForColumn(currentNewColumn);
                tdsToAppendLength++;
              }
            }

            // Remove unnecessary columns.
            var columnsToRemove = oldColumns.length - newColumns.length;
            if (columnsToRemove > 0) {
              element.find('table tr td:nth-child(n + ' + (newColumns.length + 1) + ')').remove();
            }

            if (tdsToAppend.length > 0) {
              element.find('tr').append(tdsToAppend);
            }
          }

          function createCellForColumn(column) {
            var customClass = getCustomClasses(column);
            if (customClass.length > 0) {
              customClass = 'class="' + customClass + '"';
            } else {
              customClass = '';
            }

            return '<td ' + customClass + '>' +
              '<div class="calendar-cell-content-wrapper calendar-cell-placeholder relative-container"></div></td>';
          }

          function getCustomClasses(column) {
            return (isToday(column.date) ? 'today-cell' : '') + (column.holiday ? ' holiday-cell' : '');
          }

          function updateColumnsMeta(oldColumns, newColumns) {
            oldColumns.forEach(function (column, index) {
              if (column.holiday || isToday(column.date)) {
                element.find('tr td:nth-child(' + (index + 1) + ')').removeClass('today-cell holiday-cell');
              }
            });

            newColumns.forEach(function (column, index) {
              var columnClass = (column.holiday ? 'holiday-cell ' : '') + (isToday(column.date) ? 'today-cell' : '');
              if (columnClass.length > 0) {
                element.find('tr td:nth-child(' + (index + 1) + ')').addClass(columnClass);
              }
            });

            updateColumnHours(oldColumns, newColumns);
          }

          function updateColumnHours(oldColumns, newColumns) {
            if (oldColumns) {
              oldColumns.forEach(function (column, index) {
                element.find('tr td:nth-child(' + (index + 1) + ').unavailable').removeClass('unavailable');
              });
            }

            newColumns.forEach(function (column, columnIndex) {
              if (column.exceptions) {
                column.exceptions.forEach(function (exception) {
                  if (exception.start !== exception.end && !(exception.end < 1)) {
                    // column.partialHours = true;
                    var selectorString = 'tr';
                    if (exception.start > 0) {
                      selectorString = ':nth-child(n+' + (exception.start + 1) + ')';
                    }

                    if (exception.end) {
                      selectorString += ':nth-child(-n+' + (exception.end) + ')';
                    }

                    selectorString += ' td:nth-child(' + (columnIndex + 1) + ')';
                    element.find(selectorString).addClass('unavailable');
                  }
                });
              }
            });
          }

          function toggleDynamicBehavior(editMode) {
            if (!angular.isDefined(editMode)) {
              return;
            }

            element[editMode ? 'off' : 'on']('click', 'td:not(.unavailable):not(.ui-draggable-dragging)', genericClickHandler);
            if (!editMode) {
              element['on']('mouseover', 'td:not(.unavailable):not(.ui-draggable-dragging)', genericHoverHanlder);
            }
          }

          function genericClickHandler(e) {
            var clickPosition = getClickedRowColumn(this);
            var column = positionToCell(clickPosition);
            var startTime = positionToCellStartTime(clickPosition);
            $rootScope.$emit('show-calendar-popover', e, { column: column, startTime: startTime });
          }


          function genericHoverHanlder() {
            var clickPosition = getClickedRowColumn(this);
            var startTime = positionToCellStartTime(clickPosition);
            this.firstChild.setAttribute('data-time', dayString + startTime.format(' dddd HH:mm'));
          }

          function getClickedRowColumn(el) {
            var col = $(el).closest('td').index();
            var row = $(el).closest('tr').index();
            return { row: row, col: col };
          }

          function positionToCellStartTime(position) {
            var date = positionToCell(position).date.clone();
            var time = calendarMetadata.startTime.add(calendarMetadata.minutesBetweenMeetings * position.row, 'm');
            date.hour(time.hour());
            date.minute(time.minute());
            return date;
          }

          function positionToCell(position) {
            return lastCreatedColumns[position.col];
          }

          function isToday(momentDate) {
            return moment().isSameDate(momentDate, 'day');
          }

        },
        template: '<div class="calendar-days-placeholder" calendar-shifts-drawer edit-mode="editMode"></div>'
      };
    }]);

}());
