import './operator.less'

angular.module('easybizy.common.common-controls')
  .directive('clickCall',
    function (configuration, Repository) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          const loggedInUser = configuration.loggedInUser;
          if (loggedInUser.then) {
            return loggedInUser.then((res) => {
              if (!!Object.getSafely(['operator', 'vendor'], res)) {
                configure();
              }
            })
          } else if (!Object.getSafely(['operator', 'vendor'], loggedInUser)) {
            return;
          }

          configure()

          function configure() {
            if (attrs.clickCall && attrs.clickCall.length > 0) {
              scope.$watch(attrs.clickCall, (newVal) => {
                updateClick2Call(newVal)
              })
            }

            function updateClick2Call(number) {
              if (!number) {
                return;
              }

              const destination = number.replace(/[^.\d]/g, '');
              let title = number;
              if (attrs.clickCallTitle) {
                title = scope.$eval(attrs.clickCallTitle);
              }

              const newEl = $(`<span class="click-2-call-element"><span class="icon icon-phone"></span><span class="left-margin right-margin">${ title }</span></span>`);

              newEl.on('click', () => {
                if (newEl.hasClass('disabled')) {
                  return;
                }

                newEl.removeClass('success');
                newEl.addClass('disabled');
                const { operator } = loggedInUser;
                Repository.Custom('VOIP').call({ vendor: operator.vendor, extension: operator.extension, destination })
                  .then(() => {
                    newEl.removeClass('disabled');
                    newEl.addClass('success');
                  }).catch((e) => {
                  newEl.removeClass('disabled');
                  const errorMessage = (e.data && e.data.message) || e.statusCode;
                  toastr.error(errorMessage);
                })
              });

              element.html('');
              element.append(newEl);
            }
          }
        }
      };
    });

