<template>
  <div class="wizard-summary-wrapper">
    <div class="flex justify-start flex-col">
      <div class="flex justify-start max-h-96	wizard-summary__category">
        <div class="flex justify-between flex-col" v-for="category in itemsViewModel">
          <div>
            <p class="text-xl"><span class="px-2" :class="category.icon"></span>{{ category.title }}:</p>
            <p v-for="item in category.items">{{ item }}</p>
          </div>
          <p><span>{{ $t('_Total_') }}:<span
            class="text-4xl px-2">{{ category.items.length }}</span></span>
          </p>
        </div>
      </div>
      <div class="mt-3">
        <vue-button @click="upload" :disabled="disabled">{{ $t('_Create_') }}</vue-button>
      </div>
    </div>
  </div>
</template>

<script>
import VueButton from '../../button.vue';

export default {
  components: {
    VueButton
  },
  data() {
    return {
      inProgress: false
    }
  },
  computed: {
    disabled() {
      return this.services.length + this.products.length === 0;
    },
    services() {
      return this.$store.getters['wizard/services'].map((x) => x.serviceName);
    },
    products() {
      return this.$store.getters['wizard/products'].map((x) => x.productName);
    },
    itemsViewModel() {
      return [
        { title: this.$t('_ServiceThatWillBeGenerated_'), items: this.services, icon: 'icon icon-woman' },
        { title: this.$t('_ProductThatWillBeGenerated_'), items: this.products, icon: 'icon icon-tag' },
      ]
    }
  },
  methods: {
    upload() {
      this.$emit('upload');
    }
  }
}
</script>

<style lang="less">
.wizard-summary-wrapper {
  .wizard-summary__category {
    min-height: 25vh;

    & > div {
      padding-left: 2rem;
      padding-right: 2rem;
      max-height: 50vh;
      overflow: auto;
    }
  }

  .vue-button__core {
    padding: 0.5rem;
  }

  .vue-button .vue-button__container {
    max-width: initial;
    justify-content: center;
    width: 100%;
  }
}
</style>
