(function () {
  angular.module('easybizy.common.common-services').service('filesService', ['$http',
    function ($http) {
      var validFileTypes = ['Image'];
      var validRelatedEntity = ['Customer', 'ProductMetadata', 'ServiceMetadata', 'ExternalVoucherMetadata', 'BusinessGeneral', 'Employee', 'PriceQuote'];

      /**
       *
       * @param file - The file to upload.
       * @param kind - Valid types = ['Image']
       * @param relatedEntity - Valid types = ['Customer', 'ServiceMetadata', 'ProductMetadata']
       * @param temp - Default == false
       * @param isPublic - Default == false
       */
      this.uploadFile = function (file, kind, relatedEntity, temp, isPublic) {
        assert(file, 'File must be an object');
        assert(validFileTypes.indexOf(kind) > -1, 'Invalid file type. Valid types:' + validFileTypes.join(', '));
        assert(validRelatedEntity.indexOf(relatedEntity) > -1, 'Invalid related entity type. Valid types:' + validRelatedEntity.join(', '));

        if (relatedEntity === 'BusinessGeneral') {
          return this.uploadLogo(file)
        }

        temp = temp ? 'true' : 'false';
        isPublic = isPublic ? 'true' : 'false';

        var path = adaptPathToEntityAndKind(file.name, kind, relatedEntity);
        var toSend = new FormData();
        toSend.append('isPublic', isPublic);
        toSend.append('file', file);
        toSend.append('path', path);
        toSend.append('size', file.size);
        toSend.append('type', file.type);
        toSend.append('force', 'false');
        toSend.append('temp', temp);

        var config = {
          method: 'POST',
          url: window.filesURI + 'api/files/upload',
          // transformRequest: angular.identity,
          withCredentials: true,
          data: toSend,
          // Handle upload progress.
          // uploadEventHandlers: {
          //   progress: function (e) {
          //     if (e.lengthComputable) {
          //       console.log((e.loaded / e.total) * 100);
          //       // $scope.progressBar = (e.loaded / e.total) * 100;
          //       // $scope.progressCounter = $scope.progressBar;
          //     }
          //   }
          // },
          headers: {
            'Content-Type': undefined
          }
        };


        return $http(config).then(function (response) {
          return response.data;
        });
      };

      this.uploadFileFromLink = function (url, kind, relatedEntity, temp, isPublic) {
        assert(url, 'Missing URL.');
        assert(validFileTypes.indexOf(kind) > -1, 'Invalid file type. Valid types:' + validFileTypes.join(', '));
        assert(validRelatedEntity.indexOf(relatedEntity) > -1, 'Invalid related entity type. Valid types:' + validRelatedEntity.join(', '));
        var path = adaptPathToEntityAndKind(url.split('/').pop().replace(/\?.*/, '') + '.jpg', kind, relatedEntity);

        var config = {
          method: 'POST',
          url: window.filesURI + 'api/files/upload-link',
          withCredentials: true,
          data: {
            fileUrl: url,
            temp: !!temp,
            isPublic: !!isPublic,
            path: path,
            fixFileExtension: true
          }
        };


        return $http(config).then(function (response) {
          return response.data;
        });
      };

      this.uploadLogo = function (file) {
        var toSend = new FormData();
        toSend.append('file', file);
        toSend.append('type', file.type);

        var config = {
          method: 'POST',
          url: window.filesURI + 'api/logo',
          withCredentials: true,
          data: toSend,
          headers: {
            'Content-Type': undefined
          }
        };

        return $http(config).then(function (response) {
          return response.data;
        });
      };


      function adaptPathToEntityAndKind(fileName, kind, relatedEntity) {
        var path = owl.pluralize(kind) + '/' + owl.pluralize(relatedEntity.replace('Metadata', '')) + '/';
        var splitFileName = fileName.split('.');
        var extension = splitFileName.pop();
        return path + splitFileName.join('.') + '___' + moment().format('YYYYMMDDss') + '.' + extension;
      }

    }]
  );
}());
