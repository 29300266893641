import './hide-switch.less';
import { MAIN_MODULE } from "../../../constants";


MAIN_MODULE.directive('ngHideSwitch', [
  '$timeout', function ($timeout) {
    return {
      restrict: 'A',
      scope: {
        on: '='
      },
      controller: [
        '$scope', function ngHideSwitchController() {
          this.cases = {};
        }],
      replace: true,
      transclude: true,
      require: '^ngHideSwitch',
      link: function (scope, element, attrs, ngHideSwitchController) {
        angular.forEach(ngHideSwitchController.cases, function (currentCase) {
          var el = currentCase[0].element;
          el.addClass("not-displayed");
          el.bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function () {
            if (el.hasClass('ng-leave')) {
              el.addClass("not-displayed");
              el.removeClass('ng-leave');
              el.removeClass('ng-leave-active');
            } else if (el.hasClass('ng-enter')) {
              el.removeClass('ng-enter');
              el.removeClass('ng-enter-active');
            }
          });
        });

        scope.$watch('on', function (newVal, oldVal) {
          var newEl = ngHideSwitchController.cases['!' + newVal][0].element;//.css('display', 'block');
          var oldEl = ngHideSwitchController.cases['!' + oldVal][0].element;
          if (oldEl.hasClass('ng-leave') || oldEl.hasClass('ng-enter')) {
            removeAll();
            newEl.removeClass('not-displayed');
          } else if (newVal !== oldVal) {
            animate(oldEl, true);
            animate(newEl);
          }

          newEl.removeClass('not-displayed');

        });

        function animate(el, leave) {
          var firstClass = leave ? 'ng-leave-active' : 'ng-enter-active';
          var secondClass = leave ? 'ng-leave' : 'ng-enter';

          el.addClass(secondClass);
          $timeout(function () {
            el.addClass(firstClass);
          }, 10);
        }

        function removeAll() {
          angular.forEach(ngHideSwitchController.cases, function (currentCase) {
            var el = currentCase[0].element;
            el.removeClass('ng-leave');
            el.removeClass('ng-leave-active');
            el.removeClass('ng-enter');
            el.removeClass('ng-enter-active');
            el.addClass('not-displayed');
          });
        }
      },
      template: '<div class="ng-switch-hide-container" ng-transclude="">\
                   </div>'
    };
  }]);

MAIN_MODULE.directive('ngHideSwitchWhen', function () {
  return {
    restrict: 'A',
    require: '^ngHideSwitch',
    replace: true,
    transclude: true,
    compile: function (element, attrs) {
      return function (scope, element, attrs, ctrl, $transclude) {
        ctrl.cases['!' + attrs.ngHideSwitchWhen] = (ctrl.cases['!' + attrs.ngHideSwitchWhen] || []);
        ctrl.cases['!' + attrs.ngHideSwitchWhen].push({ transclude: $transclude, element: element });
      };
    },
    template: '<div class="" ng-transclude="">\
                   </div>'
  };
});
