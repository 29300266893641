/**
 * Created by tomeravni on 1/1/14.
 */

import moment from "moment";
import './meetings.timeline.directive.less';
import { SERVER_DATA_DATE_FORMAT } from "../../../../constants";

angular.module('easybizy.dashboard').directive('meetingsTimeline', ['$timeout', '$state', 'Repository', 'localize',
  'contextManager', '$q',
  function ($timeout, $state, Repository, localize, contextManager, $q) {
    return {
      restrict: 'E',
      replace: true,
      link: function (scope, element, attrs) {
        scope.goToCalendarFunc = function () {
          $state.go('Calendar');
        };

        scope.navigateToCustomerDetails = goToCustomerDetails;
        scope.goToCashRegister = goToCashRegister;

        scope.meetings = [];

        var numOfMeetingsToShow = 4;
        scope.emptyMeetings = [];
        scope.showNoMeetingsMessage = false;

        getMeetings(numOfMeetingsToShow).then(function (meetings) {
          scope.emptyMeetings.length = 0;
          scope.meetings.pushAll(meetings);
          for (var i = 0; i < numOfMeetingsToShow - meetings.length; i++) {
            scope.emptyMeetings.push(i);
          }

          scope.showNoMeetingsMessage = scope.meetings.length == 0;
        });

        scope.promoteYourBusiness = function () {
          $state.go('Promotions');
        };

        var now = moment();
        scope.day = now.format('ddd');
        scope.dayOfMonth = now.format('DD');
        scope.month = now.format('MMM');


        var changeMinutes = function () {
          var _now = moment();
          scope.currentHour = _now.format("h");
          scope.currentMinute = _now.format("mm");
          scope.currentDayPart = _now.format("A");
        };

        var intervalMinutes = function () {
          scope.$apply(changeMinutes);
        };

        changeMinutes();
        const meetingsInterval = $timeout(function () {
          setInterval(intervalMinutes, 1000);
        }, 60 - (new moment()).seconds());

        scope.$on('$destroy', () => $timeout.cancel(meetingsInterval));

        var verifyMeetingsInRange = function () {
          // is first meeting over ?
          var now = new meeting();

          if (now.diff(scope.meetings[0].EndTime, 'minutes') > 0) {
            // load new meetings!
          }
        };
        scope.$watch('meetings.length', function (newVal) {
          if (newVal == 0) {
            element.addClass("meetings-timeline-meeting-no-meetings");
          } else {
            var _now = new moment();
            // is meeting already started?
            if (_now.diff(scope.meetings[0].startTime, 'minutes') >= -2) { // current meeting - meeting already started
              element.addClass("meetings-timeline-meeting-started-mode");

            } else {
              element.addClass("meetings-timeline-meeting-yet-to-be-started");
              if (scope.emptyMeetings.length > 0) {
                scope.emptyMeetings.splice(scope.emptyMeetings.length - 1);
              } else if (scope.meetings.length === numOfMeetingsToShow) {
                scope.meetings.splice(scope.meetings.length - 1, 1);
              }
            }
          }
        });

        function getMeetings(numOfMeetings) {
          scope.isLoading = true;
          var deferred = $q.defer();
          var meetings = [];
          Repository.Custom("Dashboard").meetings(numOfMeetings)
            .then(function (results) {
              angular.forEach(results, function (meeting) {
                var meetingAdapter = {
                  customerName: meeting.Title,
                  startTime: moment(meeting.StartTime, SERVER_DATA_DATE_FORMAT),
                  endTime: moment(meeting.EndTime, SERVER_DATA_DATE_FORMAT),
                  services: "",
                  customerId: meeting.CustomerId,
                  imageUrl: meeting.Image ? window.baseUrl + meeting.Image : null
                };

                angular.forEach(meeting.ServiceMetadatas, function (service, index) {
                  if (index > 0) {
                    meetingAdapter.services += " & ";
                  }
                  meetingAdapter.services += service.Title;
                });

                meetings.push(meetingAdapter);
              });

              deferred.resolve(meetings);
              scope.isLoading = false;
            });

          return deferred.promise;
        }

        function goToCustomerDetails(customerId) {
          $state.go("CustomerDetails", { customerId: customerId });
        }

        function goToCashRegister() {
          contextManager.set('customer', scope.meetings[0].customerId);
          $state.go("CashRegister");
        }
      },
      template: require('./meetings.timeline.directive.html')
    };
  }]);

