import './promotion.filter.editor.less';
import {DelayedFunction} from "../../../general/helpers";
import {get} from 'jquery';
import {isNonZeroNumber} from "@tomeravni/easybizy-js-common/numbers";

angular.module('easybizy.promotions.widget').directive('promotionFiltersEditor', ['dataFilterResolver', 'promotionsPresetResolver', '$modal', '$timeout',
  'Repository', '$http', 'localize', function (dataFilterResolver, promotionsPresetResolver, $modal, $timeout, Repository, $http, localize) {
    return {
      restrict: 'E',
      scope: {
        preset: '=',
        resolvedFilterWrapper: '=',
        concreteCustomers: '=',
        repeatWrapper: '=',
        mediumType: '=',
        selection: '=',
        customersForCurrentFilterWrapper: '='
      },
      replace: true,
      link: function (scope) {
        scope.sendingLimitMessage = '';
        scope.advancedFilters = [];
        scope.calculatingFilter = false;

        scope.$watch('preset', function (newVal) {
          if (newVal) {
            if (angular.isDefined(scope.concreteCustomers)) {
              scope.concreteCustomers.length = 0;
            }

            var preset = promotionsPresetResolver.getPresetByTemplateName(newVal);
            scope.filters = preset.Filters;
          }
        });

        var delayedGetCustomersForFilter = new DelayedFunction(loadCustomers, 1000);

        scope.filtersChangedDelegate = function (newVal) {
          delayedGetCustomersForFilter.set(newVal);
        };

        function getJsonFilters() {
          const jsonFilters = [];
          angular.forEach(scope.resolvedFilterWrapper, function (filter) {
            let jsonFilter = filter.toJSON();
            jsonFilter.ByCalendar = scope.selectedVisitMethod?.value?.value === 'meeting';
            jsonFilters.push(jsonFilter);
          });

          return jsonFilters;
        }

        scope.customersForCurrentFilter = 0;

        scope.$watch('filters', function (newVal) {
          if (newVal && newVal.length > 0) {
            scope.resolvedFilterWrapper.length = 0;
            var advancedFilters = dataFilterResolver.getFilterExclude(newVal);
            scope.hasAdvancedFilters = advancedFilters.length > 0;
            $timeout(function () {
              scope.advancedFilters = advancedFilters;
            }, 1000);

            loadCustomers();
          }

        });

        scope.calculateResolvedFilters = function () {
          var calculated = ($(".default-footer").offset().top - $(".promotion-first-step-advanced-filters").offset().top) + 'px';
          return "calc(" + calculated + " - 3em)";
        };


        scope.exportToExcel = function () {
          const jsonFilters = getJsonFilters();
          scope.isLoading = true;
          Repository.Custom("PromotionsRepository").getFilteredCustomersFile(jsonFilters, scope.mediumType)
            .then(function () {
              scope.isLoading = false;
            })
            .catch(function (e) {
              scope.isLoading = false;
              toastr.error(localize.getLocalizedString("_ProblemWithFilter_"));
            });
        };

        scope.invoicesOrMeetings = [
          {name: localize.getLocalizedString("_CashRegister_"), value: 'invoice'},
          {name: localize.getLocalizedString("_Calendar_"), value: 'meeting'}
        ];

        scope.selectedVisitMethod = {value: scope.invoicesOrMeetings[0]};
        Repository.Custom('Calendar').defaultPromotionVisitOptions().then((res) => {
          if (res.byCalendar) {
            scope.selectedVisitMethod.value = scope.invoicesOrMeetings[1];
          }

        }).catch((err) => {
          console.log('error in getting default promotion visit options', err);
        })

        scope.noteVisitFilter = '* ' + localize.getLocalizedString('_VisitFilterMethodExplain_')


        scope.$watch('selectedVisitMethod', function (newVal, oldVal) {
          if (newVal?.value?.value && newVal.value?.value !== oldVal?.value?.value) {
            loadCustomers();
          }
        }, true);


        var closeDialogDelegate = {};
        scope.manuallyChooseCustomers = function () {
          var opts = {
            backdrop: 'static',
            keyboard: true,
            backdropClick: false,
            template: "<customer-picker filters='filters' save='saveSelectedCustomers'></customer-picker>",
            controller: "CustomerPickerController",
            resolve: {
              promotionData: function () {
                return new function () {
                  this.filters = getJsonFilters();
                  this.promotionType = scope.mediumType;
                };
              },
              saveSelectedCustomers: function () {
                return function (customers) {
                  scope.concreteCustomers.pushAll(customers);
                  //scope.repeatCampaign = $scope.repeatCampaign && customers.length == 0;
                  closeDialogDelegate.close();
                };
              },
              cancel: function () {
                return function () {
                  closeDialogDelegate.close();
                };
              },
              closeDialogDelegate: function () {
                return closeDialogDelegate;
              }

            },
            closeFn: function () {
            }
          };

          $modal.open(opts);
        };

        scope.filteredCustomersCountMessage = "";
        let resolvedLimit = null;
        let limitPromise = null;

        Object.defineProperty(scope, 'sendingLimit', {
          get() {
            return (resolvedLimit || !limitPromise) ? Promise.resolve(resolvedLimit) : limitPromise;
          }
        })


        function loadCustomers() {
          scope.isLoading = true;
          var jsonfilters = getJsonFilters();
          const promises = [Repository.Custom("PromotionsRepository").getFilteredCustomerCount(jsonfilters, promotionsPresetResolver.adaptMediumType(scope.mediumType))]
          scope.sendingLimitMessage = '';
          /**
           * This is a special case for Whatsapp, we need to check the sending limit and that we do not exceed it
           * Should only be triggered when the medium type is Whatsapp
           */
          if (scope.mediumType === 'Whatsapp') {
            scope.selection.disabled = true;
            if (!limitPromise) {
              limitPromise = Repository.Custom('Conversations').sendingLimit().then((res) => {
                resolvedLimit = res.balance;
                return res.balance;
              })
            }

            promises.push(scope.sendingLimit)
          }

          Promise.all(promises).then(([result, sendingLimit]) => scope.$evalAsync(() => {
            scope.isLoading = false;
            scope.customersForCurrentFilter = result ? result.countAfterDisabled : 0;
            scope.filteredCustomersCountMessage = localize.getLocalizedString("_CustomersToCurrentFilter_",
              result.countBeforeDisabled);

            if (scope.customersForCurrentFilterWrapper) {
              scope.customersForCurrentFilterWrapper.value = result;
            }

            if (isNonZeroNumber(sendingLimit) && sendingLimit < result.countAfterDisabled) {
              scope.sendingLimitMessage = localize.getLocalizedString('_WASendingLimitReached_') + ` (${sendingLimit})`
            } else {
              scope.selection.disabled = false;

            }
          }))
            .catch(function (e) {
              scope.isLoading = false;

              toastr.error(localize.getLocalizedString("_ProblemWithFilter_"));
              scope.customersForCurrentFilter = 0;
            })


          // Repository.Custom("PromotionsRepository").getFilteredCustomerCount(jsonfilters, promotionsPresetResolver.adaptMediumType(scope.mediumType))
          //   .then(function (result) {
          //     scope.isLoading = false;
          //     scope.customersForCurrentFilter = result ? result.countAfterDisabled : 0;
          //     scope.filteredCustomersCountMessage = localize.getLocalizedString("_CustomersToCurrentFilter_",
          //       result.countBeforeDisabled);
          //
          //     if (scope.customersForCurrentFilterWrapper) {
          //       scope.customersForCurrentFilterWrapper.value = result;
          //     }
          //   })
          //   .catch(function (e) {
          //     scope.isLoading = false;
          //
          //     toastr.error(localize.getLocalizedString("_ProblemWithFilter_"));
          //     scope.customersForCurrentFilter = 0;
          //   })
        }
      },
      template: require('./promotion.filter.editor.tpl.html')
    };
  }]);
