angular.module('easybizy.cash.register').factory('productsPickerRepository', function () {
  var firstLoadAmount = 50;
  var k_productsKey = '_products_cash_register_';
  var k_uniquePersistenceKey = '_unique_business_key_';
  var Repository, configuration, $timeout, itemToId;

  var toReturn = {
    init: function (i_Repository, i_configuration, i_$timeout, i_itemToId) {
      Repository = i_Repository;
      configuration = i_configuration;
      $timeout = i_$timeout;
      itemToId = i_itemToId;

      return toReturn;
    },
    getProducts: getProducts,
    clearCache: clearCache
  };

  return toReturn;


  function getProducts(callback) {

    var products = loadProductsFromCache();
    if (products != null) {
      console.log('Cash register products loaded from cache', Array.isArray(products) && products.length);
    } else {
      console.log('Empty cash register cache.')
    }

    var concreteCallback = callback;

    if (products != null) {
      callback(null, products, true);
      concreteCallback = function (err, loadedResults) {
        if (err) {
          return callback(err);
        }

        updateInventory(products, loadedResults);
      };
    }

    loadProducts(!products, concreteCallback);
  }

  function loadProductsFromCache() {
    if (!shouldUseCache() || !$.localStorage.isSet(k_productsKey)) {
      return null;
    }

    return $.localStorage.get(k_productsKey);
  }

  function loadProducts(defer, callback) {
    if (!defer) {
      loadBatch(null, null, function (err, results) {
        if (err) {
          return callback(err);
        }

        $.localStorage.set(k_productsKey, results);
        callback(null, results);
      })
    } else {
      // This will holds the results of 2 batches.
      var bothResults = [];
      var internalBatchDelegate = function (err, results) {
        if (err) {
          return callback(err);
        }

        var resultSliced = results.slice();
        if (resultSliced.length > 0) {
          callback(null, resultSliced);
        }

        bothResults.push(results);

        if (bothResults.length > 1) {
          var toPut = [];
          bothResults.forEach(function (result) {
            toPut.push.apply(toPut, result);
          });

          $.localStorage.set(k_productsKey, toPut);
        }
      };

      loadBatch(firstLoadAmount, null, internalBatchDelegate);
      loadBatch(null, firstLoadAmount, internalBatchDelegate);

    }
  }

  function loadBatch(limit, skip, callback) {
    Repository.Custom('CashRegister').productOrServices(limit, skip)
      .then(function (results) {
        callback(null, results);
      })
      .catch(function (err) {
        callback(err);
      })
  }

  function shouldUseCache() {
    var toReturn = false;
    try {
      var currentPersistence = configuration.get().BusinessDetails.BusinessName.Value;
      var cachedIdentifier = $.localStorage.isSet(k_uniquePersistenceKey) && $.localStorage.get(k_uniquePersistenceKey);

      if (currentPersistence != null) {
        $.localStorage.set(k_uniquePersistenceKey, currentPersistence);
      }

      toReturn = cachedIdentifier === currentPersistence;
    } catch (e) {
    }

    return toReturn;
  }

  function clearCache() {
    $.localStorage.remove(k_productsKey);
  }

  function updateInventory(existingItems, newItems) {
    setTimeout(function () {
      var existingItemsMap = {};

      existingItems.forEach(function (item) {
        if (item.ConcreteType === 'ProductMetadata') {
          existingItemsMap[itemToId(item)] = item;
        }
      });

      newItems.forEach(function (newItem) {
        if (newItem.ConcreteType === 'ProductMetadata') {
          var existingItem = existingItemsMap[itemToId(newItem)];
          if (existingItem) {
            existingItem.CurrentInventory = newItem.CurrentInventory;
          }
        }
      });
    })
  }
});
