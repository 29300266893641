var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "flex items-center mb-4 justify-between" }, [
        _c("div", { staticClass: "flex items-center justify-start" }, [
          _c(
            "button",
            {
              staticClass: "flex items-center justify-start",
              on: {
                click: function ($event) {
                  return _vm.mediator.exportToExcel()
                },
              },
            },
            [
              _c("div", { staticClass: "icon icon-excel me-2" }),
              _c("div", [_vm._v(_vm._s(_vm.$t("_Export_")))]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "Grid",
        _vm._b(
          { attrs: { dataSource: _vm.dataSource, height: "70vh" } },
          "Grid",
          _vm.gridProps,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }