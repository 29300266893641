var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("div", { staticClass: "linear-progress-bar" }, [
        _c("div", { staticClass: "linear-progress-bar__line" }),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "linear-progress-bar__subline linear-progress-bar__subline--inc",
        }),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "linear-progress-bar__subline linear-progress-bar__subline--dec",
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }