import './price.quote.directive.less';
import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT, SERVER_DATA_DATE_FORMAT } from "../../constants";

angular.module('easybizy.common.price.quote').directive('priceQuoteView', [
  '$timeout', 'localize', 'Repository', '$modal', 'modalCreator', 'employees', 'configuration', 'invoiceFacade', '$q', 'digitalInvoiceCommon', '$rootScope',
  function ($timeout, localize, Repository, $modal, modalCreator, employees, configuration, invoiceFacade, $q, digitalInvoiceCommon, $rootScope) {

    var k_invalidPriceQuoteNumber = -1;

    return {
      restrict: 'E',
      scope: {
        itemsByCategory: '=',
        reloadItems: '&',
        finishPriceQuoting: '&',
        total: '=',
        customer: '=',
        existingPriceQuote: '=',
        openCustomerPicker: '='
      },
      replace: true,
      link: function (scope) {
        scope.$watchOnce('existingPriceQuote', initFromExisting);
        const settings = configuration.get();
        scope.priceQuoteNumber = k_invalidPriceQuoteNumber;
        scope.showHideTotal = settings.PriceQuoteSettings.HideTotal;

        getPriceQuoteNumber();
        scope.businessDetails = settings.BusinessDetails;
        scope.priceQuote = {};
        scope.priceQuote.sendingDate = moment().format(DATE_DISPLAY_FORMAT);
        scope.priceQuote.priceQuoteTitle = localize.getLocalizedString("_PriceQuoteFor_");
        scope.priceQuote.to = "";
        scope.priceQuote.payTo = settings.PriceQuoteSettings.DefaultPriceQuotePayTo.Value;
        scope.priceQuote.paymentMethod = settings.PriceQuoteSettings.DefaultPriceQuotePaymentMethod.Value;
        scope.priceQuote.generalPaymentTerms = settings.PriceQuoteSettings.DefaultPriceQuotePaymentTerms.Value;
        scope.priceQuote.aboutUs = settings.PriceQuoteSettings.DefaultPriceQuoteAboutUs.Value;
        scope.priceQuote.notes = settings.PriceQuoteSettings.DefaultPriceQuoteImportentNotes.Value;
        scope.priceQuote.SignatureRequired = settings.PriceQuoteSettings.SignatureRequired;
        scope.priceQuote.HideTotal = settings.PriceQuoteSettings.HideTotal;
        const forcePickingEmployee = settings.PriceQuoteSettings.ForcePickingEmployee;

        scope.expirationDateField = {
          fieldName: 'expiresOn',
          icon: "icon icon-calendar",
          placeholder: localize.getLocalizedString("_ExpiresOnPlaceholder_"),
          yearRange: '-1:+2',
          validation: "{'date': true, 'required': true}",
          type: 'date'
        };

        scope.sendToField = {
          fieldName: 'mailTo', icon: "icon icon-mail-outline", placeholder: localize.getLocalizedString("_Email_"),
          validation: "{'email': true}"
        };

        scope.priceQuote.mailTo = "";
        scope.$watch('customer', function (newVal) {
          if (newVal) {
            scope.priceQuote.mailTo = newVal.EmailAddress;
            scope.priceQuote.to = newVal.FirstName + " " + newVal.LastName;
          }
        });

        scope.priceQuote.expiresOn = getExpirationDefault();

        // scope.save = function () {
        //   if (!validatePriceQuote()) {
        //     return;
        //   }
        //
        //   scope.isSavingPriceQuote = true;
        //   savePriceQuote().catch((error) => {
        //     toastr.error(error);
        //     scope.isSavingPriceQuote = false;
        //   })
        // };

        scope.saveAndSend = function () {
          if (!validatePriceQuote(() => {
            $timeout(() => scope.saveAndSend())
          })) {
            return;
          }

          scope.isSavingPriceQuote = true;
          savePriceQuote()
            .then(function (data) {
              digitalInvoiceCommon.sendPriceQuoteDialog(data, () => scope.finishPriceQuoting()());
            }).catch((error) => {
            toastr.error(error);
            scope.isSavingPriceQuote = false;
          })
        };

        scope.employees = [];
        scope.selectedEmployee = {};
        employees.resolve(scope.employees, scope.selectedEmployee, true);

        function validatePriceQuote(callback) {
          if (!scope.customer) {
            toastr.error(localize.getLocalizedString("_PriceQuoteMustHaveACustomer_"));
            scope.openCustomerPicker().then(callback)
            return false;
          }


          return true;
        }


        function savePriceQuote() {
          var deferred = $q.defer();
          var priceQuateToSave = generatePriceQuote();
          if (priceQuateToSave.ClientPriceQuoteItems.length < 1 && priceQuateToSave.Subscriptions.length < 1) {
            const error = localize.getLocalizedString('_PriceQuoteMustHaveItems_');
            deferred.reject(error);
            return deferred.promise;
          }

          if (forcePickingEmployee && !priceQuateToSave.EmployeeId) {
            const error = localize.getLocalizedString('_PriceQuoteMustHaveEmployee_');
            deferred.reject(error);
            return deferred.promise;
          }

          Repository.Custom('PriceQuote').save(priceQuateToSave)
            .then(function (res) {
              toastr.success(localize.getLocalizedString("_PriceQuoteSaved_", "."));
              deferred.resolve(res);
              reloadExistingPriceQuote({
                id: res.PriceQuote.PriceQuoteId,
                items: res.PriceQuote.ClientPriceQuoteItems,
                total: res.PriceQuote.TotalAmount
              });
              scope.isSavingPriceQuote = false;
              $rootScope.$emit('clear-cash-register');
            }).catch(function (e) {
            toastr.error(localize.getLocalizedString("_ErrorSavingPriceQuote_"));
            deferred.reject(err);
            scope.isSavingPriceQuote = false;
          });

          return deferred.promise;
        }

        function reloadExistingPriceQuote(toReload) {
          scope.reloadItems()(toReload);
          scope.priceQuote.id = toReload.id;
        }

        function getPriceQuoteNumber(priceQuote) {
          if (priceQuote && priceQuote.id) {
            return priceQuote.id;
          } else if (scope.existingPriceQuote && scope.existingPriceQuote.PriceQuoteId) {
            return scope.existingPriceQuote.PriceQuoteId;
          } else {
            Repository.Custom("PriceQuote").getNextId()
              .then(function (res) {
                if (scope.priceQuoteNumber === k_invalidPriceQuoteNumber) {
                  scope.priceQuoteNumber = res;
                }
              }).catch(function (e) {
              toastr.error(e.Message)
            });
          }
        }

        function getExpirationDefault() {
          return moment().add(settings.PriceQuoteSettings.DefaultExpirationPeriodInDays.Value, 'd').format(DATE_DISPLAY_FORMAT);
        }


        function initFromExisting(existingPriceQuote) {
          if (existingPriceQuote) {
            scope.priceQuote = {};
            if (existingPriceQuote.PriceQuoteId) {
              scope.priceQuoteNumber = scope.priceQuote.id = existingPriceQuote.PriceQuoteId;
              scope.priceQuote.to = existingPriceQuote.to;
              scope.priceQuote.expiresOn = moment(existingPriceQuote.ExpirationDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT);

            } else {
              scope.priceQuote.expiresOn = getExpirationDefault();
            }

            scope.priceQuote.sendingDate = moment().format(DATE_DISPLAY_FORMAT);
            scope.priceQuote.priceQuoteTitle = existingPriceQuote.Title;
            scope.priceQuote.payTo = existingPriceQuote.PayTo;
            scope.priceQuote.paymentMethod = existingPriceQuote.PaymentMethod;
            scope.priceQuote.generalPaymentTerms = existingPriceQuote.PaymentTerms;
            scope.priceQuote.aboutUs = existingPriceQuote.AboutUs;
            scope.priceQuote.notes = existingPriceQuote.ImportentNotes;
            scope.priceQuote.productsNotes = existingPriceQuote.ItemsNotes;
          }
        }

        function generatePriceQuote() {
          var priceQuote = {};
          priceQuote.PriceQuoteId = scope.priceQuote.id;
          priceQuote.TotalAmount = scope.total.subTotal;
          priceQuote.TotalAmountBeforeDiscount = scope.total.calculatedSubTotal;
          priceQuote.Vat = configuration.get().CashRegisterSettings.VAT.Value;
          priceQuote.Title = scope.priceQuote.priceQuoteTitle;
          priceQuote.For = scope.priceQuote.to;
          priceQuote.PayTo = scope.priceQuote.payTo;
          priceQuote.PaymentMethod = scope.priceQuote.paymentMethod;
          priceQuote.PaymentTerms = scope.priceQuote.generalPaymentTerms;
          priceQuote.AboutUs = scope.priceQuote.aboutUs;
          priceQuote.ImportentNotes = scope.priceQuote.notes;
          priceQuote.SignatureRequired = scope.priceQuote.SignatureRequired;
          priceQuote.HideTotal = scope.priceQuote.HideTotal;
          priceQuote.ItemsNotes = scope.priceQuote.productsNotes;
          priceQuote.ExpirationDate = moment(scope.priceQuote.expiresOn, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
          priceQuote.MailTo = scope.priceQuote.mailTo;
          priceQuote.CustomerId = scope.customer ? scope.customer.CustomerId : null;
          priceQuote.EmployeeId = scope.selectedEmployee.selected.value;

          priceQuote.ClientPriceQuoteItems = [];
          var duplicatedItemsByCategory = $.extend(true, {}, scope.itemsByCategory);
          angular.forEach(duplicatedItemsByCategory, function (category) {
            angular.forEach(category, function (item) {
              if (item.itemCustomType === 'PunchCard') {
                priceQuote.Subscriptions = priceQuote.Subscriptions || [];
                priceQuote.Subscriptions.push(invoiceFacade.toPunchCard(item))
              } else {
                var cleanedItem = item;
                cleanedItem.CustomTitle = item.Label;
                delete cleanedItem.$$hashKey;
                delete cleanedItem.EntityType;
                delete cleanedItem.Images;
                delete cleanedItem.Duration;
                delete cleanedItem.WaitingAfter;
                if (item.Ingredients) {
                  angular.forEach(item.Ingredients, function (ingredient) {
                    delete ingredient.$$hashKey;
                    delete ingredient.EntityType;
                    delete ingredient.expandable;
                    delete ingredient.Image;
                    delete ingredient.mainCategoryId;
                  });
                }

                priceQuote.ClientPriceQuoteItems.push(cleanedItem);
              }
            });
          });
          return priceQuote;
        }

        scope.productNotesLabel = localize.getLocalizedString("_PriceQuoteProductNotes_");
      },
      template: require('./price.quote.directive.tpl.html')
    };
  }]);
