import './help.videos.less'

angular.module('easybizy.common.toolbar.actions').directive('helpVideos', [
  'Repository', 'localize', '$rootScope', '$timeout', '$sce', function (Repository, localize, $rootScope, $timeout, $sce) {
    return {
      restrict: 'E',
      replace: false,
      scope: {
        showWrapper: '=',
        videos: '='
      },
      link: function (scope, element, attrs) {
        scope.hide = function () {
          scope.showWrapper.show = !scope.showWrapper.show;
          if (scope.dontShowAgainWrapper.active == true) {
            Repository.Custom("Configurations").videoWatched(scope.videos[0].videoId);
          }
        };

        scope.$watch('videos', function (videos) {
          if (!videos || videos.length == 0) {
            return;
          }

          var notSeenTutorials = videos.filter(function (featuredVideo) {
            return !featuredVideo.watched
          });

          if (notSeenTutorials.length == 0) {
            scope.currentVideo = videos[0]
          } else {

            notSeenTutorials.sort(function (a, b) {
              return moment(a.dateAdded).isBefore(b.dateAdded);
            });

            scope.currentVideo = notSeenTutorials[0];
          }

          var script = '<script src="//fast.wistia.com/assets/external/E-v1.js" async></script>';
          script += '<script src="' + '//fast.wistia.com/embed/medias/' + scope.currentVideo.videoId + '.jsonp' + '" async></script>';
          script += '<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">\
            <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">\
            <div class="wistia_embed wistia_async_' + scope.currentVideo.videoId + ' seo=false videoFoam=true"\
          style="height:100%;width:100%">\
            &nbsp;</div>\
          </div>\
          </div>';

          scope.videoHtml = $sce.trustAsHtml(script);
          scope.currentVideo.title = localize.getLocalizedString(scope.currentVideo.header);
          scope.currentVideo.description = $sce.trustAsHtml(scope.currentVideo.descriptionHtml);
          scope.currentVideo.addedOn = moment(scope.currentVideo.dateAdded).format('LL');
        });

        scope.dontShowAgainWrapper = { active: false };
      },
      template: require('./help.videos.tpl.html')
    };
  }]);
