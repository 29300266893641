<template>
  <div class="wizard-container">
    <LinearProgressBar :is-loading="isLoading"/>
    <transition-group :name="controller.flowDirectionBackward ? 'slideback' : 'slide'" class="steps-wrapper">
      <WizardStep v-for="(step, index) in controller.steps" :key="index"
                  :hasNext="controller.hasNext"
                  :hasPrev="controller.hasPrev"
                  :title="step.title"
                  @prev="controller.prev()"
                  @next="controller.next()"
                  @skip="controller.skip()"
                  :skipable="step.skipable"
                  v-if="controller.currentStep === step">
        <div>
          <WizardTileSelect :items="step.options" v-on:itemSelected="itemSelected(step, $event)"
                            v-if="step.viewType === 'tiles'"
                            :selected="step.selected"
                            :multiSelect="step.selectionType === 'multi-select'"></WizardTileSelect>

          <WizardGridSelect
            v-if="step.viewType === 'grid'"
            :data-source="step.dataSource"
          ></WizardGridSelect>

          <WizardSummary
            v-if="step.viewType === 'summary'"
            :items="itemsToUpload"
            @upload="upload"
          >
          </WizardSummary>

        </div>
      </WizardStep>
    </transition-group>
    <div class="wizard-mask element-loading-mask" :class="isLoading && 'visible'"></div>
  </div>
</template>

<script>

import WizardStep from './wizard-step.vue';
import WizardTileSelect from './step-components/wizard-tile-select.vue';
import WizardGridSelect from './step-components/wizard-grid-select.vue';
import WizardSummary from './step-components/wizard-summary.vue';
import LinearProgressBar from '../common/linear.progress.vue';
import WizardController from "../../wizard.controller";
import { wrapInArray } from "@tomeravni/easybizy-js-common/common";

export default {
  components: { WizardTileSelect, WizardGridSelect, WizardStep, WizardSummary, LinearProgressBar },
  props: {
    controller: {
      type: WizardController,
      required: true
    },
    currentStep: {
      type: Object
    },
    isNextVisible: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      header: 'tomer',
      back: false,
      number: 0,
      currentIndex: 0
    }
  },
  mounted() {
    this.controller.store = this.$store;
  },
  computed: {
    isLoading() {
      return this.$store.getters['wizard/isLoading'];
    },
    itemsToUpload() {
      return this.$store.getters['wizard/itemsToUpload'];
    }
  },
  methods: {
    itemSelected(step, item) {
      this.controller.setValue(step, wrapInArray(item));
      if (this.controller.hasNext && this.controller.currentStep.autoProgressToNextStep) {
        this.controller.next();
      }
    },
    next() {
      this.back = false;
      if (this.currentIndex === this.steps.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    },
    prev() {
      this.back = true;
      if (this.currentIndex === 0) {
        this.currentIndex = this.steps.length - 1;
      } else {
        this.currentIndex--;
      }
    },
    background(idx) {
      if (idx === 0) {
        return 'rgba(30,30,30,0.2)'
      } else if (idx === 1) {
        return 'green'
      } else {
        return 'blue';
      }
    },
    upload() {
      this.$store.dispatch('wizard/setLoading', true);
      this.controller.uploadDelegate(this.itemsToUpload).then(() => {
        window.toastr.success(this.$t('_Saved_'));
        this.$store.dispatch('wizard/setLoading', false);
      }).catch((err) => {
        window.toastr.error(err.message);
        this.$store.dispatch('wizard/setLoading', false);
      });
    }
  }
}


</script>

<style lang="less">
.wizard-container {
  position: relative;
  height: 100%;
  width: 100%;

  .steps-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
  }


  .slide-leave-active,
  .slide-enter-active {
    transition: 0.75s;
  }

  .slide-enter {
    transform: translate(100%, 0);
  }

  .slide-leave-to {
    transform: translate(-100%, 0);
  }

  .slideback-leave-active,
  .slideback-enter-active {
    transition: 0.75s;
  }

  .slideback-enter {
    transform: translate(-100%, 0);
  }

  .slideback-leave-to {
    transform: translate(100%, 0);
  }

}


</style>
