import './add.preset.modal.less'
import { isNonEmptyArray } from "@tomeravni/easybizy-js-common/common";
import { extractError } from "@tomeravni/easybizy-js-common/errors";

angular.module('easybizy.easybizyModalViewsModel').controller('AddPresetModalViewController',
  function ($scope, $modalInstance, localize, Repository, preset, savedCallback, confirmService, configuration) {
    $scope.presetNameField = {
      fieldName: 'label', icon: "icon icon-tag", placeholder: localize.getLocalizedString('_Name_'),
      validation: "{'required': true, 'max-length': 20}"
    }

    $scope.presetFieldsToValidate = {};
    $scope.validateActionWrapper = {};
    $scope.presetToSet = { saveAsDefault: false };

    $scope.save = function () {
      // console.log(preset)
      const validationFields = $scope.validateActionWrapper.validate();
      if (isNonEmptyArray(validationFields)) { // is valid form?
        const localizedError = localize.getLocalizedString("_FieldIsIncorrect_", $scope.presetNameField.placeholder, validationFields[0][$scope.presetNameField.fieldName]);
        toastr.error(localizedError);
        return;
      }

      if (configuration.loggedInUser.rootUser) {
        confirmService.confirm(localize.getLocalizedString('_SaveForAllUsers_'),
          localize.getLocalizedString('_SaveForAllUsersDescription_'), () => {
            savePreset(true);
          }, () => {
            savePreset(false);
          }, localize.getLocalizedString('_SaveForAll_'), localize.getLocalizedString('_JustForMe_'));
      } else {
        savePreset(false);
      }
    };

    const savePreset = (saveForAll) => {
      $scope.isSaving = true;
      Repository.Custom('Configurations').setPreset('leads', {
        ...preset.toJSON(),
        label: $scope.presetToSet.label,
        isDefault: $scope.presetToSet.saveAsDefault,
      }, saveForAll).then((res) => {
        $scope.isSaving = false;
        $scope.cancel();
        savedCallback(res);
      }).catch((err) => {
        $scope.isSaving = false;
        toastr.error(extractError(err));
      })
    }


    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  });

