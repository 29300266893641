import './cashout.payments.helper.directive.less'
import { DATE_DISPLAY_FORMAT } from "../../../constants";

angular.module('easybizy.easybizyModalViewsModel').directive('cashoutPayments', ['$timeout',
  'localize', 'configuration',
  function ($timeout, localize, configuration) {
    var cashIcon = configuration.get().CashRegisterSettings.Currency.Value === "NIS" ? 'icon-banknote-aligned-shekel' : 'icon-banknote-aligned-dollar';


    return {
      restrict: 'E',
      replace: true,
      scope: {
        paymentTotal: '=payments',
        removePayment: '=',
        addChequeDelegateWrapper: '=',
        numberOfChequePayments: '='
      },
      link: function (scope, element, attrs) {
        scope.getPaymentIcon = function (payment) {
          switch (payment.type) {
            case 'cash':
              return cashIcon;
            case 'credit':
              return 'icon icon-credit';
            case 'other':
              return 'icon icon-credit';
            case 'cheque':
              return 'icon icon-edit-inventory';
            case 'external':
              return 'icon icon-list-circle';
          }
        };

        scope.paymentModified = function (payment) {
          payment.dirty = true;
          $timeout(updateDatesAndNumbers.bind(null, payment), 200);
        };

        scope.validateActionWrapper = {};
        scope.chequeNumberToValidate = {};
        scope.chequeField = {
          fieldName: 'AuthNumber',
          icon: "icon icon-edit-inventory",
          placeholder: localize.getLocalizedString("_ChequeNumber_"),
          validation: "{'max-length': 10, 'min-length': 3, 'digits': true, 'required': true}"
        };

        scope.chequeDate = {
          fieldName: 'PaymentDueDate',
          icon: "icon icon-calendar",
          placeholder: localize.getLocalizedString("_ChequeDate_"),
          validation: "{'required': true, 'date': true}",
          yearRange: "-1:+3",
          type: 'date'
        };

        scope.$watch('numberOfChequePayments.quantity', function () {
          updateChequePayments();
        });

        scope.removePaymentInternal = function (payment) {
          scope.removePayment(payment);
          if (payment.type === 'cheque' && scope.numberOfChequePayments.quantity > 1) {
            scope.numberOfChequePayments.quantity--;
          }
        };

        scope.addChequeDelegateWrapper.acceptPayment = function (payment) {
          updateChequePayments(payment);
        };

        function updateChequePayments(payment) {
          var totalAmountToDivide = 0;
          var existingChequePayments = scope.paymentTotal.payments.filter(function (payment) {
            if (payment.type === 'cheque') {
              totalAmountToDivide += payment.amount;
              return true;
            }

            return false;
          });

          var numberOfChequePayments = scope.numberOfChequePayments.quantity;

          if (payment) {
            totalAmountToDivide += payment.amount;
            // Handling adding new payment when there are already cheque payments.
            if (existingChequePayments.length > 0) {
              numberOfChequePayments++;
              var paymentDate = moment(existingChequePayments[existingChequePayments.length - 1].PaymentDueDate, DATE_DISPLAY_FORMAT).format(DATE_DISPLAY_FORMAT);
              existingChequePayments.push(pushPayment(payment.amount, paymentDate));
              $timeout(updateDatesAndNumbers.bind(null, existingChequePayments[existingChequePayments.length - 2]));
              return;
            }
          } else {
            if (existingChequePayments.length === 0) {
              return;
            }
          }

          var leftoverOfPayments = totalAmountToDivide % numberOfChequePayments;
          var regularPaymentSize = (totalAmountToDivide - leftoverOfPayments) / numberOfChequePayments;

          if (existingChequePayments.length < 1) {
            for (var paymentIndex = 0; paymentIndex < numberOfChequePayments; paymentIndex++) {
              pushPayment(paymentIndex === 0 ? regularPaymentSize + leftoverOfPayments : regularPaymentSize, moment().add(paymentIndex, 'months').format(DATE_DISPLAY_FORMAT));
            }
          } else {
            if (numberOfChequePayments < existingChequePayments.length) {
              for (var idx = existingChequePayments.length - 1; idx >= numberOfChequePayments; idx--) {
                var payment = existingChequePayments[idx];
                scope.paymentTotal.payments.remove(payment);
                existingChequePayments.remove(payment);
              }
            }

            var firstDate = moment(existingChequePayments[0].PaymentDueDate, DATE_DISPLAY_FORMAT);
            for (var paymentIndex = 0; paymentIndex < numberOfChequePayments; paymentIndex++) {
              if (existingChequePayments.length > paymentIndex) {
                existingChequePayments[paymentIndex].amount = paymentIndex === 0 ? regularPaymentSize + leftoverOfPayments : regularPaymentSize;
                existingChequePayments[paymentIndex].PaymentDueDate = firstDate.format(DATE_DISPLAY_FORMAT);

              } else {
                pushPayment(regularPaymentSize, firstDate.format(DATE_DISPLAY_FORMAT))
              }

              firstDate = firstDate.clone().add(1, 'months');
            }
          }

          function pushPayment(amount, date) {
            var newPayment = {
              type: 'cheque',
              amount: amount,
              change: 0,
              validateActionWrapper: {},
              chequeNumberToValidate: {},
              AuthNumber: "",
              PaymentDueDate: date
            };

            scope.paymentTotal.payments.push(newPayment);
            return newPayment;
          }
        }

        function updateDatesAndNumbers(payment) {
          var modifiedPaymentIndex = scope.paymentTotal.payments.indexOf(payment);
          // Last payment.
          if (modifiedPaymentIndex === scope.paymentTotal.payments.length - 1) {
            return;
          }

          var pivotDate = moment(payment.PaymentDueDate, DATE_DISPLAY_FORMAT).add(1, 'months');
          var pivotChequeNumber = parseInt(payment.AuthNumber.replace(/[^0-9]/g, ''));

          for (var paymentIndex = modifiedPaymentIndex + 1; paymentIndex < scope.paymentTotal.payments.length; paymentIndex++) {
            var currentItrPayment = scope.paymentTotal.payments[paymentIndex];
            currentItrPayment.PaymentDueDate = pivotDate.format(DATE_DISPLAY_FORMAT);

            if (pivotChequeNumber > 0) {
              pivotChequeNumber++;
              currentItrPayment.AuthNumber = pivotChequeNumber.toString();
            }

            pivotDate = pivotDate.clone().add(1, 'months');

          }

        }
      },
      template: require('./cashout.payments.helper.directive.tpl.html'),
    };
  }]);
