import './general.tile.directive.less';
import {HOURS_AND_MINUTES_FORMAT, PATH_TO_API} from "../../constants";

angular.module('easybizy.common.tiles').factory('tilesDecorator',
  function ($compile, $timeout, $http, localize, $state, Repository, googleCalendarService, $rootScope, $modal, configuration) {
    const configurations = configuration.get();
    let isValidWhatsappAccount = false;
    configuration.isValidWhatsappAccount().then((isValid) => {
      isValidWhatsappAccount = isValid;
    });

    const tilesType = {
      Customer: customerDecorator,
      ProductMetadata: productMetadataDecorator,
      ProductOrService: productOrServiceDecorator,
      ServiceMetadata: serviceMetadataDecorator,
      PromotionTemplate: promotionTemplateDecorator,
      GoogleCalendar: googleCalendarTemplateDecorator,
      Employee: employeeDecorator,
      Lead: leadDecorator
    };

    return {
      decorate: function (scope, element) {
        tilesType[scope.entity.EntityType](scope, element);
      }
    };

    function customerDecorator(scope, element) {
      if (scope.entity.CustomerType === "Group") {
        return customersGroupDecorator(scope, element);
      }

      if (!scope.entity.alwaysExpanded) {
        element.addClass("inactive-actions");
      }

      scope.leftTitle = scope.entity.FirstName + " " + scope.entity.LastName;
      scope.rightTitle = "";
      var debtAmount = parseFloat(scope.entity.Debt);

      if (debtAmount > 0) {
        scope.ribbon = localize.getLocalizedString('_Debt_') + ' ' + scope.entity.Debt.toMoney();
      }
      if (debtAmount < 0) {
        scope.secondRibbon = localize.getLocalizedString('_PositiveBalance_') + ' ' + (-scope.entity.Debt).toMoney();
      }

      if (scope.entity.DateOfFillingOutDetailsForm || scope.entity.FacebookUserId) {
        scope.badge = 'icon-remote-customer';
      }

      setTileImage(scope, (scope.entity.Gender && (scope.entity.Gender === 'Male' || scope.entity.Gender.value === 'Male')) ? 'icon icon-man' : 'icon icon-woman');
      scope.updateImage = function (newImage) {
        scope.imgUrl = newImage.source;
      };
      scope.detailsLink = "/customers/" + scope.entity.CustomerId;

      scope.goToFacebook = function () {
        var win = window.open("https://www.facebook.com/profile.php?id=" + scope.entity.FacebookUserId, '_blank');
        win.focus();
      };

      if (scope.entity.expandable === false) {
        return;
      }

      var linkToFacebook = function (model) {
        var win = window.open("https://www.facebook.com/profile.php?id=" + model.FacebookUserId, '_blank');
        win.focus();
      };

      var sendFbSms = function (model) {
        $http({
          url: baseUrl + PATH_TO_API + 'FbSmsSender?mobile=' + model.MobileFirst,
          method: "GET"
        }).then(function (result) {
          if (result.status === 200 && result.data.SendingResults[0].Succeeded) {
            toastr.success(localize.getLocalizedString("_ContactBySmsSent_"));
          } else {
            toastr.error(localize.getLocalizedString("_FailedToCreateMessage_", result.data.ExceptionMessage));
          }
        }).catch(function ({data}) {
          toastr.error(localize.getLocalizedString("_ErrorSendingSmsToCustomer_", data && data.Message ? data.Message : data));
        });
      };

      var deleteEntity = function (model) {
        scope.actions[2].isDisabled = true;
        scope.actionsResolver.deleteEntity(model)
          .then(function () {
            scope.actions[2].isDisabled = false;
          });
      };


      var sendOnlineBookingLink = function (model) {
        scope.actionsResolver.sendOnlineBookingLink(model);
      };

      scope.actions = [
        {type: 'sendSms', isDisabled: !scope.entity.MobileFirst, model: scope.entity},
        // { type: 'sendEmail', isDisabled: !scope.entity.EmailAddress, model: scope.entity },
        // {
        //   type: scope.entity.FacebookUserId ? 'linkToFacebook' : 'sendFbSms',
        //   delegate: scope.entity.FacebookUserId ? linkToFacebook : sendFbSms,
        //   isDisabled: !scope.entity.FacebookUserId && !scope.entity.MobileFirst,
        //   model: scope.entity
        // },
        {type: 'setCurrentCustomer', isDisabled: false, model: scope.entity},
        {type: 'bookAService', isDisabled: false, model: scope.entity},
        {type: 'deleteEntity', delegate: deleteEntity, isDisabled: false, model: scope.entity}
      ];

      if (isValidWhatsappAccount) {
        scope.actions.splice(1, 0, {type: 'sendWhatsapp', isDisabled: !scope.entity.MobileFirst, model: scope.entity});
      }

      // TODO: Daniel, is membershipId is the field for Idenitifcation?
      if (configurations.CustomersSettings.SupportsExternalSensor) {
        scope.actions.push({type: 'sendToSensor', isDisabled: !scope.entity.MembershipId, model: scope.entity});
      }

      if (scope.visibleCustomActions && scope.visibleCustomActions.indexOf('mergeCustomer') >= 0) {
        var mergeCustomerAction = {
          type: 'mergeCustomer', delegate: function () {
            scope.actionsResolver.reloadData();
          }, isDisabled: false, model: scope.entity
        };

        scope.actions.push(mergeCustomerAction);
      }

      if (configurations.CustomersSettings.EnableNextMeetingsPrinting && scope.actionsResolver && scope.actionsResolver.printCustomerMeetings) {
        var printCustomerMeetings = function (model) {
          scope.actionsResolver.printCustomerMeetings(model);
        };

        scope.actions.push({
          type: 'printCustomerMeetings',
          delegate: printCustomerMeetings,
          isDisabled: false,
          model: scope.entity
        });
      }

      if (configuration.get().GeneralSettings.EnableOnlineScheduling) {
        scope.actions.push({
          type: 'sendOnlineBookingLink',
          isDisabled: false,
          delegate: sendOnlineBookingLink,
          model: scope.entity
        });
      }


      if (debtAmount > 0) {
        var payDebt = function () {
          $state.go('CashRegister', {debt: true});
        };

        scope.actions.push({type: 'payDebt', isDisabled: false, model: scope.entity});

      }


    }

    function leadDecorator(scope, element) {
      scope.leftTitle = scope.entity.FirstName + " " + scope.entity.LastName;
      scope.rightTitle = "";

      scope.detailsLink = "/leads/" + scope.entity.LeadId;

      var deleteEntity = function (model) {
        scope.actions[3].isDisabled = true;
        scope.actionsResolver.deleteEntity(model)
          .then(function () {
            scope.actions[3].isDisabled = false;
          });
      };

      var transformIntoCustomer = function (model) {
        scope.actions[3].isDisabled = true;
        scope.actionsResolver.transformLeadToCustomer(model)
          .then(function () {
            scope.actions[3].isDisabled = false;
          });
      };

      var goToCustomer = function (model) {
        scope.actions[3].isDisabled = true;
        scope.actionsResolver.goToCustomer(model)
          .then(function () {
            scope.actions[3].isDisabled = false;
          });
      };

      setTileImage(scope, scope.entity.Gender == 'Male' || scope.entity.Gender.value == 'Male' ? 'icon icon-man' : 'icon icon-woman');
      scope.actions = [
        {type: 'sendSms', isDisabled: !scope.entity.MobileFirst, model: scope.entity},
        {type: 'sendEmail', isDisabled: !scope.entity.EmailAddress, model: scope.entity},
        //{type: 'bookAService', isDisabled: false, model: scope.entity},
        {type: 'deleteEntity', delegate: deleteEntity, isDisabled: false, model: scope.entity},
        {
          type: scope.entity.CustomerId ? 'goToCustomer' : 'transformLeadToCustomer',
          delegate: scope.entity.CustomerId ? goToCustomer : transformIntoCustomer,
          isDisabled: false,
          model: scope.entity
        }
      ];

      if (isValidWhatsappAccount) {
        scope.actions.splice(1, 0, {type: 'sendWhatsapp', isDisabled: !scope.entity.MobileFirst, model: scope.entity});
      }
    }

    function customersGroupDecorator(scope, element) {
      if (!scope.entity.alwaysExpanded) {
        element.addClass("inactive-actions");
      }

      scope.leftTitle = scope.entity.FirstName;
      scope.rightTitle = "";
      scope.detailsLink = "/customers/" + scope.entity.CustomerId;

      // scope.tileIcon = "icon icon-group";
      setTileImage(scope, 'icon icon-group');

      var editCustomersGroup = function (model) {
        scope.actionsResolver.editGroup(model);
      };

      var deleteEntity = function (model) {
        scope.actions[1].isDisabled = true;
        scope.actionsResolver.deleteEntity(model)
          .then(function () {
            scope.actions[1].isDisabled = false;
          });
      };

      if (scope.entity.expandable === false) {
        return;
      }

      scope.actions = [
        {type: 'editGroup', isDisabled: false, delegate: editCustomersGroup, model: scope.entity},
        {type: 'sendSms', isDisabled: false, model: scope.entity},
        {type: 'sendEmail', isDisabled: false, model: scope.entity},
        {type: 'setCurrentCustomer', isDisabled: false, model: scope.entity},
        {type: 'bookAService', isDisabled: false, model: scope.entity},
        {type: 'deleteEntity', delegate: deleteEntity, isDisabled: false, model: scope.entity}
      ];
    }

    function productMetadataDecorator(scope, element) {
      if (!scope.entity.alwaysExpanded) {
        element.addClass("inactive-actions");
      }
      scope.leftTitle = scope.entity.ProductLabel;
      scope.rightTitle = scope.entity.RetailPrice;
      scope.updateImage = function (newImage) {
        scope.imgUrl = newImage.source;
      };
      setTileImage(scope, "icon icon-tag");

      scope.detailsLink = "/products/" + scope.entity.Id;

      if (scope.entity.expandable === false) {
        return;
      }

      // Actions
      var addToBill = function (model) {
      };

      var promote = function (model) {
        promoteAProductOrService(model);
      };

      var deleteEntity = function (model) {
        scope.actions[2].isDisabled = true;
        scope.actionsResolver.deleteEntity(model)
          .finally(function () {
            scope.actions[2].isDisabled = false;
          });
      };

      scope.actions = [
        {type: 'addToBill'/*, delegate: addToBill*/, isDisabled: false, model: scope.entity},
        {type: 'promote', delegate: promote, isDisabled: false, model: scope.entity},
        {type: 'deleteEntity', delegate: deleteEntity, isDisabled: false, model: scope.entity}
      ];

    }

    function productOrServiceDecorator(scope, element) {
      if (!scope.entity.alwaysExpanded) {
        element.addClass("inactive-actions");
      }

      scope.leftTitle = scope.entity.Label;
      scope.rightTitle = scope.entity.RetailPrice;

      // Actions
      var promote = function (model) {
        promoteAProductOrService(model);
      };

      var deleteEntity = function (model, action) {
        action.isDisabled = true;
        scope.actionsResolver.deleteEntity(model)
          .finally(function () {
            action.isDisabled = false;
          });
      };

      scope.actions = [
        {type: 'addToBill', isDisabled: false, model: scope.entity},
        {type: 'promote', delegate: promote, isDisabled: false, model: scope.entity},
        {type: 'deleteEntity', delegate: deleteEntity, isDisabled: false, model: scope.entity}
      ];

      var detailsLinkByType = null;

      switch (scope.entity.ConcreteType) {
        case 'ProductMetadata':
          setTileImage(scope, scope.entity.IngredientOnly ? 'icon icon-ingredient' : 'icon icon-tag');
          detailsLinkByType = scope.entity.IngredientOnly ? '/ingredients/' : '/products/';

          break;
        case 'ServiceMetadata':
          setTileImage(scope, 'icon icon-hair-dryer');
          detailsLinkByType = '/services/';

          break;
        case 'ExternalVoucherMetadata':
          setTileImage(scope, 'icon icon-external-voucher');
          detailsLinkByType = '/external-vouchers/';
          scope.actions.splice(0, 2);
          break;
        default:
          scope.tileIcon = 'icon icon-tag-price';
          element.addClass('custom-defined-product');
      }

      scope.detailsLink = detailsLinkByType + scope.entity.Id;
      scope.updateImage = function (newImage) {
        scope.imgUrl = newImage.source;
      };

      if (scope.entity.expandable === false) {
        return;
      }


    }

    function employeeDecorator(scope, element) {
      if (!scope.entity.alwaysExpanded) {
        element.addClass("inactive-actions");
      }

      scope.leftTitle = scope.entity.FirstName + " " + scope.entity.LastName;
      scope.rightTitle = "";
      setTileImage(scope, "icon icon-clients-ol");

      scope.updateImage = function (newImage) {
        scope.imgUrl = newImage.source;
      };

      if (scope.entity.expandable === false) {
        return;
      }

      scope.markedTileTextFirstLine = localize.getLocalizedString('_CheckedInAt_');
      scope.detailsLink = "/employees/" + scope.entity.EmployeeId;

      scope.entity.isMarked = scope.entity.OpenCheckInTime != null;
      scope.markedTileTextSecondLine = scope.entity.OpenCheckInTime ?
        moment(scope.entity.OpenCheckInTime).format(HOURS_AND_MINUTES_FORMAT) : "";

      // Actions
      var checkInFunc = function (employee) {
        scope.actionsResolver.checkIn(employee)
          .then(function (openCheckInTime) {
            updateEmployeeOpenCheckInTime(openCheckInTime);
          })
          .catch(function (err) {
            console.log(err);
          });
      };

      var checkOutFunc = function (employee) {
        scope.actionsResolver.checkOut(employee)
          .then(function (openCheckInTime) {
            updateEmployeeOpenCheckInTime(openCheckInTime);
          })
          .catch(function (err) {
            console.log(err);
          });
      };

      var manuallySetShiftFunc = function (employee) {
        scope.actionsResolver.manuallySetShift(employee)
          .then(function (openCheckInTime) {
            updateEmployeeOpenCheckInTime(openCheckInTime);
          })
          .catch(function (err) {
            console.log(err);
          });
      };

      function updateEmployeeOpenCheckInTime(openCheckInTime) {
        scope.entity.OpenCheckInTime = openCheckInTime;
        scope.entity.isMarked = openCheckInTime != null;
        scope.markedTileTextSecondLine = scope.entity.isMarked ? moment(openCheckInTime).format(HOURS_AND_MINUTES_FORMAT) : null;
        scope.actions[0].isDisabled = scope.entity.isMarked;
        scope.actions[1].isDisabled = !scope.entity.isMarked;
      }

      var editWorkHours = function (model) {
        scope.actionsResolver.editWorkHours(model);
      };

      function editShifts(model) {
        scope.actionsResolver.editShifts(model);
      }

      function syncWithGoogle(model) {
        scope.actionsResolver.syncWithGoogle(model);
      }

      var deleteEntity = function (model) {
        scope.actions[2].isDisabled = true;
        scope.actionsResolver.deleteEntity(model)
          .finally(function () {
            scope.actions[2].isDisabled = false;
          });
      };

      scope.actions = [
        {
          type: 'checkIn',
          delegate: checkInFunc,
          isDisabled: scope.entity.OpenCheckInTime != null,
          model: scope.entity
        },
        {
          type: 'checkOut',
          delegate: checkOutFunc,
          isDisabled: scope.entity.OpenCheckInTime == null,
          model: scope.entity
        },
        {type: 'manuallySetShift', delegate: manuallySetShiftFunc, isDisabled: false, model: scope.entity},
        {type: 'employeeShifts', delegate: editShifts, isDisabled: false, model: scope.entity},
        {type: 'employeeWorkHours', delegate: editWorkHours, isDisabled: false, model: scope.entity},
        {type: 'employeeSells', delegate: openEmployeeSellsModal, isDisabled: false, model: scope.entity},
      ];

      if ($state.current.name === 'EmployeeDetails') {
        scope.actions.push({type: 'deleteEntity', delegate: deleteEntity, isDisabled: false, model: scope.entity});
      }

      var syncWithGoogleAction, pauseSyncAction;
      if (googleCalendarService.enabled && scope.entity.VisibleOnCalendar) {
        syncWithGoogleAction = {
          type: 'syncWithGoogle',
          delegate: syncGoogleCalendar,
          isDisabled: true,
          model: scope.entity
        };
        scope.actions.push(syncWithGoogleAction);
        if (scope.entity.alwaysExpanded) {
          generateGoogleSyncToken();
        } else {
          scope.$watchOnce('visibleActions', generateGoogleSyncToken);
        }

        defineCurrentGoogleCalendarSyncStatus();
      }

      function openEmployeeSellsModal(employee) {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../modal-views/employee-sells/employee.sells.modal.tpl.html'),
          controller: "EmployeeSellerModalController",
          resolve: {
            employee: function () {
              return employee;
            }
          },
          closeFn: function () {
          }
        };

        $modal.open(opts);
      }

      function generateGoogleSyncToken() {
        Repository.Custom('Calendar').generateToken(scope.entity.EmployeeId).then(function (response) {
          syncWithGoogleAction.token = response.token;
          syncWithGoogleAction.isDisabled = false;
        }).catch(function () {
          syncWithGoogleAction.isDisabled = true;
        })
      }


      function defineCurrentGoogleCalendarSyncStatus() {
        pauseSyncAction = {
          type: 'pauseSyncWithGoogle',
          delegate: pauseGoogleSync,
          isDisabled: true,
          model: scope.entity
        };

        scope.actions.push(pauseSyncAction);
        return Repository.Custom('Calendar').syncStatus(scope.entity.EmployeeId).then(function (response) {
          if (response.isActive) {
            pauseSyncAction.isDisabled = false;
          }
        });
      }

      function syncGoogleCalendar() {
        $rootScope.$emit('page-in-progress', true);
        googleCalendarService.syncGoogleCalendar(syncWithGoogleAction.token)
          .then(function () {
            $rootScope.$emit('page-in-progress', false);
          })
          .catch(function () {
            $rootScope.$emit('page-in-progress', false);

            return toastr.error(localize.getLocalizedString("_ErrorAuthorizingGoogleAccount_"));
          });
      }

      function pauseGoogleSync() {
        $rootScope.$emit('page-in-progress', true);
        Repository.Custom('Calendar').pauseSync(scope.entity.EmployeeId).then(function () {
          toastr.success(localize.getLocalizedString("_SyncWithGoogleCalendarWasPaused_"));
          scope.actions.remove(pauseSyncAction);
          $rootScope.$apply(function () {
            pauseSyncAction.isDisabled = true;
            $rootScope.$emit('page-in-progress', false);
          });
        })
          .catch(function () {
            $rootScope.$apply(function () {
              $rootScope.$emit('page-in-progress', false);
            });

            return toastr.error(localize.getLocalizedString("_ErrorPausingSync_"));
          });
      }
    }


    function serviceMetadataDecorator(scope, element) {
      if (!scope.entity.alwaysExpanded) {
        element.addClass("inactive-actions");
      }

      scope.leftTitle = scope.entity.ServiceName;
      scope.rightTitle = scope.entity.RetailPrice;

      scope.updateImage = function (newImage) {
        scope.imgUrl = newImage.source;
      };

      setTileImage(scope, "icon icon-tag");

      scope.detailsLink = "/products/" + scope.entity.Id;

      if (scope.entity.expandable === false) {
        return;
      }

      // Actions
      var promote = function (model) {
        promoteAProductOrService(model);
      };


      var deleteEntity = function (model) {
        scope.actions[2].isDisabled = true;
        scope.actionsResolver.deleteEntity(model)
          .finally(function () {
            scope.actions[2].isDisabled = false;
          });
      };

      scope.actions = [
        {type: 'addToBill', isDisabled: false, model: scope.entity},
        {type: 'promote', delegate: promote, isDisabled: false, model: scope.entity},
        {type: 'deleteEntity', delegate: deleteEntity, isDisabled: false, model: scope.entity}
      ];
    }

    function promotionTemplateDecorator(scope, element) {
      scope.leftTitle = scope.entity.TemplateLabel;
      scope.tileIcon = scope.entity.Icon;
      element.addClass("inactive-actions");
    }

    function googleCalendarTemplateDecorator(scope, element) {
      scope.leftTitle = scope.entity.Label;
      if (scope.entity.Icon) {
        scope.tileIcon = scope.entity.Icon;
      }

      if (scope.entity.BackgroundColor) {
        element.css('background-color', scope.entity.BackgroundColor);
      }

      element.addClass("inactive-actions");
    }

    function promoteAProductOrService(entity) {
      $state.go('Promotions');
    }

    function deleteEntity(entity) {
    }

    function setTileImage(scope, defaultIconClass) {
      if (scope.entity.DefaultImagePath) {
        scope.imgUrl = window.filesURI + scope.entity.DefaultImagePath + '?size=thumbnail';
      } else if (scope.entity.Image && scope.entity.Image.ImageId) {
        scope.imgUrl = window.filesURI + scope.entity.Image.FilePath + '?size=thumbnail';
      } else if (scope.entity.Images && scope.entity.Images.length > 0) {
        scope.imgUrl = window.filesURI;
        if (scope.entity.DefaultImage > 0) {
          var imageIndex = scope.entity.Images.indexOfById({ImageId: scope.entity.DefaultImage}, 'ImageId');
          if (imageIndex > -1) {
            scope.imgUrl += scope.entity.Images[imageIndex].FilePath;
          }
        } else {
          scope.imgUrl += scope.entity.Images[0].FilePath;
        }

        scope.imgUrl += '?size=thumbnail';
      } else {
        scope.tileIcon = defaultIconClass;
      }

    }
  });

angular.module('easybizy.common.tiles').directive('tilesWrapper', [
  '$timeout', /*'quickRepeatList',*/ function ($timeout/*, quickRepeatList*/) {
    return {
      restrict: 'E',
      transclude: true,
      replace: true,
      scope: {
        content: '=',
        modelsDecorator: '=',
        allowDrag: '=',
        dragStarted: '=',
        dragStopped: '=',
        click: '=',
        doubleClick: '=',
        selectable: '=',
        selectedTile: '=',
        markable: '=',
        hideDetailsLink: '=',
        api: '=',
        isLoadingWrapper: '=',
        actionsResolver: '=',
        disableActions: '=',
        visibleCustomActions: '=',
        imageUpdated: '&',
        dropTargetClass: '@',
        enableImageEditing: '@',
        lazyTooltip: '&',
        smallView: '=',
        publicFile: '='
      },
      controller: function ($scope) {
        this.allowDrag = $scope.allowDrag;
        this.dropTargetClass = $scope.dropTargetClass;
        this.markable = $scope.markable;
        this.dragStarted = $scope.dragStarted;
        this.dragStopped = $scope.dragStopped;
        this.selectable = $scope.selectable;
        // Setting the tooltip incase needed.
        this.lazyTooltip = $scope.lazyTooltip() ? $scope.lazyTooltip() : null;
      },
      template: '<div class="relative-container tiles-wrapper-component" when-scrolled="loadMore()">\
	                    <div class="relative-container tiles-immediate-wrapper" ng-class="{\'small-view\': smallView}">\
	                        <div class="user-tile" ng-repeat="entity in content" bindonce>\
		                    <general-tile entity="entity" enable-image-editing="{{::enableImageEditing}}"\
		                          visible-custom-actions="visibleCustomActions"\
					                    click="click" double-click="doubleClick" actions-resolver="actionsResolver"\
                                        disable-actions="disableActions" raise-both-events="raiseBothEvents()"\
					                    hide-details-link="hideDetailsLink" tile-selected="tileSelected" image-updated="imageUpdated" public-file="publicFile">\
		                    </general-tile>\
		                    </div>\
	                    </div>\
                    </div>',
      compile: function (telement, tattrs) {
        if (angular.isDefined(tattrs.trackBy)) {
          var repeatedElement = telement.find('div.user-tile');
          var updatedAttr = repeatedElement.attr('ng-repeat') + ' track by entity.' + tattrs.trackBy;
          repeatedElement.attr('ng-repeat', updatedAttr);
        }
        return function (scope, element, attrs, ctrl) {

          element.addClass(attrs.class);
          scope.isElementDragged = false;
          scope.tileSelected = function (entity) {
            selectEntity(entity);
          };

          scope.loadMore = function () {
            if (angular.isDefined(scope.api)) {
              updateData();
            }
          };

          /*
           Using Api Object
           */
          var updateData = null;
          var apiWatcher = scope.$watch('api', function (newApiObject) {
            if (angular.isDefined(newApiObject)) {
              apiWatcher(); // cancel watcher...
              // paging for data loading
              var eachPageLength = (scope.api && scope.api.pageSize) || 30;
              var isDirty = false;
              var lastUsedFilters = [];
              var lastUsedIds = [];
              var currentPage = 0;
              var lastRequestedPage = -1;

              scope.api.forceReload = function () {
                currentPage = -1;
                isDirty = true;
                updateData();
              };

              scope.api.externalAdd = function (entity, insertAtBeginning) {
                if (insertAtBeginning) {
                  scope.content.unshift(entity);
                } else {
                  scope.content.push(entity);
                }

                if (scope.api.hasOwnProperty('tileDecorator')) {
                  scope.api.tileDecorator(entity);
                }
              };

              updateData = function () {
                if (!isDirty && (currentPage === -1 || (currentPage === lastRequestedPage))) { // no more to load.
                  return;
                }

                if (angular.isDefined(scope.isLoadingWrapper)) {
                  scope.isLoadingWrapper.isLoading = true;
                }

                var clearEntities = false;
                if (isDirty) {
                  currentPage = 0;
                  lastRequestedPage = -1;
                  isDirty = false;
                  clearEntities = true;
                  element.animate({scrollTop: 0});
                }

                lastRequestedPage = currentPage;
                scope.internalLoading = true;
                scope.api.clear(); // clears the top, skip and filters.
                scope.api.take(eachPageLength);
                scope.api.skip(eachPageLength * currentPage);
                if (angular.isDefined(scope.api.filters)) {
                  lastUsedFilters.pushAll(scope.api.filters);
                  scope.api.filter(scope.api.filters);
                }

                if (angular.isDefined(scope.api.selectedIds)) {
                  lastUsedIds.pushAll(scope.api.selectedIds);
                  scope.api.ids(scope.api.selectedIds);
                }

                scope.list = [];
                scope.api.get().then(function (data) {

                  if (data.results.length < eachPageLength) {
                    currentPage = -1;
                  } else {
                    currentPage++;
                  }

                  scope.$evalAsync(function () {
                    if (currentPage == 1 || clearEntities) {
                      scope.content.length = 0;
                      clearEntities = false;
                      if (angular.isDefined(scope.api.emptyEntity)) {
                        scope.content.push(scope.api.emptyEntity);
                      }
                    } else if (currentPage == -1 && scope.content < eachPageLength) {
                      if (angular.isDefined(scope.api.emptyEntity)) {
                        scope.content.push(scope.api.emptyEntity);
                      }
                    }

                    if (scope.api.modelsDecorator) {
                      data = scope.api.modelsDecorator(scope.content, data);
                    } else {
                      scope.content.pushAll(data.results);
                    }

                    //                                _.defer(quickRepeatList.list, scope.content);

                    // Decorate result from outside...
                    if (scope.api.hasOwnProperty('tileDecorator')) {
                      angular.forEach(scope.content, scope.api.tileDecorator);
                    }

                    if (angular.isDefined(scope.isLoadingWrapper)) {
                      scope.isLoadingWrapper.isLoading = false;
                    }

                    if (scope.api.hasOwnProperty('doneLoadingCallback')) {
                      scope.api.doneLoadingCallback(data.results);
                    }
                    //                                scope.$apply(function () {
                    scope.isLoadingWrapper.isLoading = false;
                    //                                });
                  });
                }).catch(function () {
                  scope.isLoadingWrapper.isLoading = false;
                });
              };

              if (!scope.api.preventAutoLoad) {
                updateData();
              }


              scope.$watchCollection('api.filters', function (newVal) {
                if (!isDirty && angular.isDefined(newVal) && !lastUsedFilters.equals(newVal)) {
                  isDirty = true;
                  $timeout(updateData);
                }
              });

              scope.$watchCollection('api.selectedIds', function (newVal) {
                if (!isDirty && angular.isDefined(newVal) && !lastUsedIds.equals(newVal)) {
                  isDirty = true;
                  $timeout(updateData);
                }
              });
            }
          });
          /*
           ENDOF - Using Api Object
           */

          scope.raiseBothEvents = function () {
            return !angular.isDefined(scope.click) && scope.selectable == true && angular.isDefined(scope.doubleClick);
          };

          scope.$watch('selectedTile', function (newVal) {
            if (newVal) {
              newVal.manuallySelect = function (entity) {
                selectEntity(entity);
              };
            }
          });

          function selectEntity(entity) {
            angular.forEach(scope.content, function (containedEntity) {
              containedEntity.isSelected = false;
            });

            if (entity) {
              entity.isSelected = true;
              scope.selectedTile.entity = entity;
            } else {
              scope.selectedTile.entity = null;
            }
          }
        }
      }
    };
  }]);

angular.module('easybizy.common.tiles').directive('generalTile', [
  '$timeout', 'tilesDecorator', '$compile', 'localize', function ($timeout, tilesDecorator, $compile, localize) {
    var k_TooltipTemplate = '<div tooltips data-title="{{::tooltip}}" tooltip-lazy="true" class="relative-container"\
  tooltip-class="tooltip-custom-tile"></div>';

    return {
      restrict: 'E',
      replace: true,
      scope: {
        entity: '=',
        markable: '=',
        hideDetailsLink: '=',
        click: '=',
        doubleClick: '=',
        disableActions: '=',
        visibleCustomActions: '=',
        actionsResolver: '=',
        raiseBothEvents: '=',
        imageUpdated: '&',
        tileSelected: '&',
        enableImageEditing: '@'
      },
      require: '^tilesWrapper',
      template: require('./general.tile.directive.html'),
      link: function (scope, element, attrs, tilesWrapperCtrl) {
        tilesDecorator.decorate(scope, element);
        var wasDragged = false;
        if (scope.decorate) {
          scope.decorate(scope, element);
        }
        scope.updateImage = function (newImage) {
          scope.imgUrl = newImage.source;
        };

        scope.hasLegend = false;
        scope.disableClickEffect = scope.entity.alwaysExpanded || (!scope.click && !scope.doubleClick
          && !tilesWrapperCtrl.markable && !scope.actions && !tilesWrapperCtrl.selectable);
        scope.isElementDragged = true;

        // for tile that supports image uploading.
        scope.uploadImageWrapper = {};
        scope.uploadImageWrapper.onDoneUploading = function (image) {
          scope.updateImage(image);
          scope.imageUpdated()()(scope.entity, image);
        };

        scope.$watch('imgUrl', function (newVal) {
          if (angular.isDefined(newVal)) {
            element.find(".general-tile-cropped-image").waitForImages(function () {
              $(this).addClass('visible-images');
            });
          }
        });


        /********************************* Click & Dbl-Click handlers *********************************/
        scope.doubleClickHandler = undefined;

        var doubleClickedObserver = scope.$watch('doubleClick', function (newVal) {
          if (angular.isDefined(newVal)) {
            scope.doubleClickHandler = "wasDoubleClicked()";
            doubleClickedObserver();
          }
        });

        scope.wasDoubleClicked = function () {
          scope.doubleClick.call(this, scope.entity);
        };

        scope.wasClicked = function () {
          if (tilesWrapperCtrl.markable) {
            scope.entity.isMarked = !scope.entity.isMarked;
          } else if (scope.actions) {
            if (!scope.entity.alwaysExpanded && !scope.disableActions) {
              scope.visibleActions = !scope.visibleActions;
            }
          } else {
            if (scope.click) {
              scope.click.call(this, scope.entity);
            }

            selectTile();
          }
        };

        /********************************* Action handlers (in case defined) *********************************/

        if (scope.actions && !scope.disableActions) {
          scope.$watch('visibleActions', function (newVal, oldVal) {
            if (newVal === oldVal) {
              return;
            }

            if (!scope.$root.currentlyVisibleActions) {
              scope.$root.currentlyVisibleActions = [];
            }

            if (newVal) {
              element.removeClass('inactive-actions');
              $timeout(function () {
                scope.$root.lastOpened = scope;
                $('.tiles-wrapper-component').scrollTo(element, 500);
                scope.$root.currentlyVisibleActions.push(scope);
              }, 300);

              $timeout(function () {
                var toRemoveObject = [];
                $.each(scope.$root.currentlyVisibleActions, function (index, actionScope) {
                  if (actionScope !== scope.$root.lastOpened) {
                    actionScope.visibleActions = false;
                    toRemoveObject.push(actionScope);
                  }
                });

                scope.$root.currentlyVisibleActions.remove(toRemoveObject);

              }, 600);
            } else {
              element.addClass('inactive-actions');
            }
          });
        }
        /********************************* Tooltip *********************************/

        if (tilesWrapperCtrl.lazyTooltip) {
          element.on('mouseenter.tile', function () {
            element.off('mouseenter.tile');
            scope.tooltip = tilesWrapperCtrl.lazyTooltip(scope.entity);
            if (scope.tooltip) {
              scope.tooltip = scope.tooltip.replace(/\n/g, "<br />");
              element.find('.user-tile-draggar').append($compile(k_TooltipTemplate)(scope));
              scope.$on('$destroy', function () {
                setTimeout(function () {
                  $('.tooltip-custom-tile').remove();
                });
              });
            }
          });
        }

        /********************************* D&D handlers (in case defined) *********************************/
        // if (tilesWrapperCtrl.allowDrag) {
        //   var params = {
        //     removeMargins: false,
        //     deferPlacement: true,
        //     useCSSTranslation: false,
        //     clone: true,
        //     delay: window.chrome ? 270 : 170,
        //     //delay: 170,
        //     droppable: "." + tilesWrapperCtrl.dropTargetClass,
        //     callIfNotStarted: '',
        //     initiate: function (ev, obj) {
        //     },
        //     start: function (ev, obj) {
        //       wasDragged = true;
        //       scope.isElementDragged = true;
        //       if (angular.isDefined(scope.dragStarted)) {
        //         scope.dragStarted();
        //       }
        //
        //     },
        //     stop: function (ev, obj) {
        //
        //     },
        //     rest: function (ev, obj) {
        //       var droppedElement = $('.' + scope.dropTargetClass + '.pep-dpa');
        //
        //       var eventVal = {
        //         type: "tileDropped",
        //         dropData: scope.entity,
        //         time: new Date()
        //       };
        //
        //       $(droppedElement).trigger(eventVal);
        //
        //       $timeout(function () {
        //         wasDragged = false;
        //         if (angular.isDefined(scope.dragStopped)) {
        //           scope.dragStopped();
        //         }
        //       }, 0);
        //     }
        //   };
        //
        //   $(element).pep(params);
        // }


        /********************************* Image uploader *********************************/

        scope.imageUploaded = function () {
          toastr.success(localize.getLocalizedString('_ImageLoadedSuccessfully_') +
            ', ' + localize.getLocalizedString('_SaveToPersistImage_') + '.');

        };


        function selectTile() {
          if (tilesWrapperCtrl.selectable && scope.tileSelected()) {
            scope.tileSelected()(scope.entity);
          }
        }
      }
    };
  }]);


angular.module('easybizy.common.tiles').directive('tileDroppable', function () {
  return {
    restrict: 'A',
    scope: {
      tileDroppable: '='
    },
    link: function (scope, element, attrs) {
      $(element).on('tileDropped', function (e) {
        scope.tileDroppable(e);
      });
    }
  };
});
