import { WizardStep } from "../../vue/wizard.controller";
import { allConditionsAreMet, getSafely, isConcreteValue, isNonEmptyArray } from "@tomeravni/easybizy-js-common/common";
import { COLUMN_EDITOR_TYPE, GridApiResult, GridColumn, LazyGridDataSource } from "@tomeravni/easybizy-vue-components";

(function () {
  const MANUFACTURERS_FOLDER = `https://files.easybizy.net/files/__index/public/manufacturers/`;

  angular.module('easybizy.common.common-services')
    .service('onBoarding', function (localize, Repository, $q, $state) {
      let servicesMap;
      let similarityMap;
      let _manufacturers = [];
      let _pickServicesStep;
      let _pickProductsStep;
      let _businessTypeStep;
      let _businessSubTypeStep;
      let _pickManufacturersStep;
      let _summaryStep;
      let _controller;

      const similarInventoryItems = new GridColumn('similarLocalItems', localize.getLocalizedString('_SimilarDefinedItems_'), COLUMN_EDITOR_TYPE.LAZY, false);
      similarInventoryItems.width = 250;
      similarInventoryItems.customRenderFunc = (entity, cd, column, h) => {
        if (isNonEmptyArray(entity[column.fieldName])) {
          const links = entity[column.fieldName].map((similar) => {
            return h('a', {
              class: 'custom-link',
              attrs: {
                href: `/${ similar.type === 'Service' ? 'services' : 'products' }/${ similar.id }`,
                target: '_blank'
              }
            }, similar.label);
          });


          return h('div', {}, links);
        }


        return h('p', { class: 'evc-grid__table-column--text' }, localize.getLocalizedString('_NoRecord_'));
      }


      Object.defineProperty(this, 'controller', {
        set(newVal) {
          _controller = newVal;
          _controller.uploadDelegate = (items) => {
            return Repository.Custom('Search').createBatchItems(items).then((result) => {
              $state.go('Products', {}, { reload: true });
              return result;
            });
          };
        }
      });

      this.getFirstStep = () => {
        const deferred = $q.defer();

        const firstLoadDelegate = Repository.Custom('Search').getServiceCategories().then((res) => {
          servicesMap = res;
          return res;
        });

        //Repository.Custom('Search').getSimilarity().then((res) => {
        //  similarityMap = res;
        //  return res;
        //});

        if (_businessTypeStep) {
          return new Promise((resolve) => resolve(_businessTypeStep));
        }

        const resolveFirstStep = () => {
          _businessTypeStep = new WizardStep(localize.getLocalizedString('_BusinessType_'))
          _businessTypeStep.options = [...servicesMap.topLevelMap.types];
          _businessTypeStep.delegate = stepResolutionDelegate.bind(this);
          return deferred.resolve(_businessTypeStep)
        }

        if (servicesMap) {
          resolveFirstStep();
        } else {
          firstLoadDelegate.then(resolveFirstStep);
        }

        return deferred.promise;
      }

      function stepResolutionDelegate(step, selection) {
        /// resolve second step.
        if (step === _businessTypeStep) {
          loadManufacturers(selection[0].he);
          const { subTypes } = servicesMap.topLevelMap.map.find((item) => item.type === selection[0].value);
          if (isNonEmptyArray(subTypes)) { /// Pick sub type.
            if (allConditionsAreMet(isConcreteValue(_businessSubTypeStep), _controller.steps[1] === _businessSubTypeStep)) {
              if (_businessSubTypeStep.selected.some((subType) => !subTypes.includes(subType.value))) {
                _businessSubTypeStep.selected = [];
              }
            } else {
              _businessSubTypeStep = new WizardStep(localize.getLocalizedString('_SubTypes_'));
              _businessSubTypeStep.selectionType = 'multi-select';
              _businessSubTypeStep.delegate = stepResolutionDelegate.bind(this);
            }

            _businessSubTypeStep.parents = selection;
            _controller.steps = [_controller.steps[0], _businessSubTypeStep];
            _businessSubTypeStep.options = [...servicesMap.topLevelMap.subTypes.filter(({ value }) => subTypes.includes(value))];
          } else {
            addPickServicesStep(1, selection);
          }
        } else if (step === _businessSubTypeStep) {
          if (selection.length > 0) {
            addPickServicesStep(2, selection);
          } else {
            _controller.steps = [_controller.steps[0], _controller.steps[1]];
          }

        } else if (step === _pickManufacturersStep) {
          addPickProducts(_controller.steps.indexOf(step), selection);
        }
      }

      function addPickServicesStep(position, selection) {
        if (_pickServicesStep) {
          if (_pickServicesStep.parentItems.some((id) => !selection.includes(id))) {
            _pickServicesStep.dataSource.clearSelection();
          }
        } else {
          const durationOptions = [...new Array(60 / 5 - 1)].map((_, idx) => {
            const item = ((idx + 1) * 5).toString();
            const val = `00:${ item.padStart(2, '0') }`;
            return {
              text: val,
              value: val
            }
          }).concat([...new Array(29)].map((_, time) => {
            const inMinutes = 60 + time * 15;
            const hours = Math.floor(inMinutes / 60);
            const minutes = inMinutes - hours * 60;
            const val = `${ hours.toString().padStart(2, '0') }:${ minutes.toString().padEnd(2, '0') }`
            return {
              text: val,
              value: val
            }
          }));

          const columns = [
            new GridColumn('serviceName', localize.getLocalizedString('_Name_'), COLUMN_EDITOR_TYPE.EDITABLE_TEXT, false),
            new GridColumn('color', localize.getLocalizedString('_Color_'), COLUMN_EDITOR_TYPE.COLOR_PICKER, false),
            new GridColumn('defaultDuration', localize.getLocalizedString('_Duration_'), undefined, false),
            new GridColumn('defaultRetailPrice', localize.getLocalizedString('_Price_'), COLUMN_EDITOR_TYPE.NUMERIC, false),
            new GridColumn('businessSubType', localize.getLocalizedString('_Category_'), undefined, false),
            //similarInventoryItems,
            //new GridColumn('id', localize.getLocalizedString('_Identity_'), undefined, false),
          ];


          columns[0].width = 250;
          columns[0].rules = [{ type: 'required', message: localize.getLocalizedString('_ThisFieldIsRequired_') }];


          columns[1].placeholder = localize.getLocalizedString('_ServiceColor_');


          columns[2].editorType = COLUMN_EDITOR_TYPE.SINGLE_SELECT;
          columns[2].placeholder = localize.getLocalizedString('_Duration_');
          columns[2].options = durationOptions;

          const ds = new ServicesGridDataSource(Repository.Custom('Search').getServicesByBusinessTypes, similarityMap);
          ds.addListener('selectionChange', (selectedIds, selectionMode) => {
            handleSelectedServices(selectedIds, selectionMode, ds);
          });

          _pickServicesStep = new WizardStep(localize.getLocalizedString('_PickServices_'), true);
          _pickServicesStep.selectionType = 'multi-select';
          _pickServicesStep.viewType = 'grid';
          _pickServicesStep.parents = selection;
          _pickServicesStep.skipDelegate = () => {
            handleSelectedServices([], 'DEFAULT', ds);
          }

          _pickServicesStep.dataSource = ds;
          _pickServicesStep.dataSource.columns = columns;
        }

        _pickServicesStep.parentItems = selection;
        if (position === 1) {
          _pickServicesStep.dataSource.businessType = selection.map((x) => x.he);
        } else {
          _pickServicesStep.dataSource.businessType = _businessTypeStep._selected.map((x) => x.he);
          _pickServicesStep.dataSource.businessSubType = selection.map((x) => x.he);
        }


        _controller.steps = [..._controller.steps.slice(0, position), _pickServicesStep];
      }

      function handleSelectedServices(selectedIds, selectionMode, dataSource) {
        _controller.store.dispatch('wizard/setServices', dataSource.selectedItems);
        const pickServicesStepIdx = _controller.steps.indexOf(_pickServicesStep);
        if (!_pickManufacturersStep) {
          _pickManufacturersStep = new WizardStep(localize.getLocalizedString('_Manufacturers_'), true);
          _pickManufacturersStep.selectionType = 'multi-select';
          _pickManufacturersStep.delegate = stepResolutionDelegate.bind(this);
          _pickManufacturersStep.skipDelegate = () => {
            handleProductsSelection([]);
          }

          _pickManufacturersStep.options = _manufacturers.map((x) => ({
            name: x.manufacturer,
            value: x.manufacturerEng,
            img: `${ MANUFACTURERS_FOLDER }${ x.manufacturerEng.toDash().toLowerCase() }.png?size=thumbnail`
          }));

          _pickManufacturersStep.parents = _pickServicesStep;
          _controller.steps = [..._controller.steps.slice(0, pickServicesStepIdx + 1), _pickManufacturersStep];

        }

        _controller.steps = [..._controller.steps.slice(0, pickServicesStepIdx + 1), _pickManufacturersStep];
      }

      function addPickProducts(position, selection) {
        if (_pickProductsStep) {
          if (_pickProductsStep.parentItems.some((id) => !selection.includes(id))) {
            _pickProductsStep.dataSource.clearSelection();
          }

          const idx = _controller.steps.indexOf(_pickProductsStep);
          if (selection.length < 1) {
            const updatedSteps = [..._controller.steps];
            updatedSteps.splice(idx, 1);
            _controller.steps = updatedSteps;
          } else if (idx < 0) {
            _controller.steps = [..._controller.steps, _pickProductsStep];
          }
        } else {
          const columns = [
            new GridColumn('productName', localize.getLocalizedString('_Name_'), COLUMN_EDITOR_TYPE.EDITABLE_TEXT, false),
            new GridColumn('manufacturer', localize.getLocalizedString('_Manufacturer_'), null, false),
            new GridColumn('categories', localize.getLocalizedString('_Category_'), null, false),
            new GridColumn('defaultCostPrice', localize.getLocalizedString('_Cost_'), COLUMN_EDITOR_TYPE.NUMERIC, false),
            new GridColumn('defaultRetailPrice', localize.getLocalizedString('_Price_'), COLUMN_EDITOR_TYPE.NUMERIC, false),
            //similarInventoryItems,
            //new GridColumn('id', localize.getLocalizedString('_Identity_'), undefined, false),
          ];

          columns[0].width = 250;
          columns[0].rules = [{ type: 'required', message: localize.getLocalizedString('_ThisFieldIsRequired_') }];


          const ds = new ProductsGridDataSource(Repository.Custom('Search').getProductsByManufacturers, similarityMap);
          ds.addListener('selectionChange', (selectedIds, selectionMode) => {
            handleProductsSelection(selectedIds, selectionMode, ds);
          });


          _pickProductsStep = new WizardStep(localize.getLocalizedString('_Products_'), true);
          _pickProductsStep.selectionType = 'multi-select';
          _pickProductsStep.viewType = 'grid';
          _pickProductsStep.parents = selection;
          _pickProductsStep.skipDelegate = () => {
            handleProductsSelection([], 'DEFAULT', ds);
          }

          _pickProductsStep.dataSource = ds;
          _pickProductsStep.dataSource.columns = columns;
          _controller.steps = [..._controller.steps, _pickProductsStep];
        }

        _pickProductsStep.parentItems = selection;
        _pickProductsStep.dataSource.manufacturers = selection.map(x => x.name);
      }

      function handleProductsSelection(selectedIds, selectionMode, dataSource) {
        if (!_summaryStep) {
          _summaryStep = new WizardStep(localize.getLocalizedString('_Summary_'));
          _summaryStep.viewType = 'summary';
        }

        _controller.steps = [..._controller.steps, _summaryStep];
        _controller.store.dispatch('wizard/setProducts', getSafely(['selectedItems'], dataSource, []));
      }

      function loadManufacturers(businessType) {
        Repository.Custom('Search').getManufacturers(businessType).then((res) => {
          _manufacturers = res.results;
        }).catch((e) => toastr.error(e.message));
      }
    });


  class SimpleLazyGridDataSource extends LazyGridDataSource {
    constructor(apiResource, similarityMap) {
      super();
      this._latestResult = [];
      this._dirty = true;
      this._api = apiResource;
      this._similarityMap = similarityMap;
    }

    getParamsArr() {
      return [];
    }

    getItemId(item) {
      return item.id;
    }

    loadOnRemote(top, skip, sort, filter) {
      if (!this._dirty) {
        return new Promise(((resolve) => resolve(this._latestResult)));
      }

      return this._api.apply(this, this.getParamsArr()).then((result) => {
        const len = result.length;
        if (this._similarityMap) {
          result.forEach((item) => {
            item.similarLocalItems = () => new Promise((resolve) => {
              setTimeout(() => {
                if (this._similarityMap[item.id]) {
                  item.similarLocalItems = this._similarityMap[item.id].items.slice(0, 3);
                  resolve(item.similarLocalItems);
                } else {
                  item.similarLocalItems = '';
                  resolve('');
                }
              }, 1000);
            });


          });

        }

        this._dirty = false;
        this._latestResult = new GridApiResult(result, len, 0, len);
        return this._latestResult;
      });
    }

    errorLoadingItems(top, skip, error) {
      console.log('error!')
    }
  }

  class ServicesGridDataSource extends SimpleLazyGridDataSource {
    set businessType(newVal) {
      this._dirty = true;
      this._businessType = newVal;
      this._businessSubType = [];
    }

    set businessSubType(newVal) {
      this._dirty = true;
      this._businessSubType = newVal;
    }

    getParamsArr() {
      return [this._businessType, this._businessSubType];
    }
  }

  class ProductsGridDataSource extends SimpleLazyGridDataSource {
    set manufacturers(newVal) {
      this._dirty = true;
      this._manufacturers = newVal;
    }

    getParamsArr() {
      return [this._manufacturers];
    }
  }


}());
