import './operator.less'
import { RTL_DATE_TIME_DISPLAY } from "../../../constants";

angular.module('easybizy.common.common-controls')
  .directive('callLog',
    function (configuration, Repository, localize) {
      return {
        restrict: 'E',
        scope: {
          operator: '=',
          destination: '=',
        },
        replace: true,
        template: require('./call-log.directive.tpl.html'),
        link: function (scope, element, attrs) {
          scope.isLoadingWrapper = {};
          scope.calls = [];
          scope.callLogFields = [
            {
              name: localize.getLocalizedString('_StartTime_'),
              fieldName: 'startTime',
              width: '22%',
              command: {
                get: function (entity) {
                  return moment(entity.startTime).format(RTL_DATE_TIME_DISPLAY);
                }
              }
            },
            {
              name: localize.getLocalizedString('_Duration_'),
              fieldName: 'duration',
              width: '13%',

              command: {
                get: function (entity) {
                  const { duration } = entity;
                  let minutes = Math.floor(duration / 60);
                  if (minutes < 10) {
                    minutes = '0' + minutes;
                  }

                  let seconds = duration % 60;
                  if (seconds < 10) {
                    seconds = '0' + seconds;
                  }

                  return `${ minutes }:${ seconds }`;
                }
              }
            },
            {
              name: localize.getLocalizedString('_Identity_'),
              fieldName: 'id',
              width: '15%',
            },
            {
              name: localize.getLocalizedString('_Recording_'),
              width: '50%',
              type: 'Custom',
              template: `<div class="centered-div-container go-to-details-table-action">
                            <div class="centered-div-content">
                                <div ng-if="value.url">
                                    <audio
                                        preload="metadata"
                                        controlsList="nodownload"
                                        controls
                                        src="{{ value.url }}">
                                        Your browser does not support the
                                    <code>audio</code> element.
                                    </audio>
                                </div>
                                <div ng-if="!value.url" i18n="_NoRecord_"></div>
                            </div>
                         </div>`,
              command: {
                get: function (entity) {
                  return {
                    url: entity.recordingUrl
                  }
                }
              }
            }
          ];

          scope.api = Repository.Custom('VOIP').calls(scope.operator, scope.destination);
          scope.api.pageSize = 20;
        }
      };
    });

angular.module('easybizy.common.common-controls')
  .directive('callPlayer',
    function () {
      return {
        restrict: 'E',
        scope: {
          link: '=',
        },
        replace: true,
        link: function (scope, element, attrs) {

        },
        template: `<div>

                   </div>`,
      }
    });
