import { darkerColor } from "../../../common/common-js-functions/color-functions";
import {
  valueOrDefault
} from "@tomeravni/easybizy-js-common/common";

(function () {
  angular.module('easybizy.calendar').directive('calendarMeeting', [
    'localize', 'calendarMetadata', 'meetingDrawerHelper', 'entityImageResolver', 'colorsService',
    function (localize, calendarMetadata, meetingDrawerHelper, entityImageResolver, colorsService) {
      const MEETING_DEFAULT_BACKGROUND_COLOR = '#00a1cb';
      const MIN_HEIGHT_FOR_SMALL_CLASS = 45;

      var isRightToLeft = localize.isRightToLeft();


      var fieldsToManuallyManage = ['left', 'width', 'top', 'height'];
      return {
        restrict: 'E',
        replace: true,
        scope: {
          meeting: '='
        },
        link: function (scope, element) {
          // This holds all the drawable components.
          var manageableProperties = {};
          updateDrawableMetrics();
          generateTextContext(scope.meeting);

          scope.meeting.reRender = function () {
            updateDrawableMetrics();
          };

          function updateDrawableMetrics() {
            var newToSet = {};
            element.attr('data-customer-id', scope.meeting.originalMeeting.CustomerId)
            fieldsToManuallyManage.forEach(function (field) {
              if (!manageableProperties.hasOwnProperty(field) ||
                manageableProperties[field] !== scope.meeting[field]) {
                manageableProperties[field] = newToSet[field] = scope.meeting[field];
              }
            });

            if (Object.keys(newToSet).length > 0) {
              if (newToSet.hasOwnProperty('left') && isRightToLeft) {
                newToSet.right = newToSet['left'];
                delete newToSet['left'];
              }

              for (var key in newToSet) {
                if (!newToSet[key]) {
                  delete manageableProperties[key];
                  newToSet[key] = '';
                }
              }

              element.css(newToSet);
            }

            let classesToAdd = '';

            // History meeting.
            if (scope.meeting.originalMeeting.IsDeactiveted) {
              classesToAdd += ' cancelled-meeting';
            } else if (scope.meeting.historyMeeting) {
              classesToAdd += ' history-meeting';
            } else if (element.hasClass('history-meeting')) {
              classesToAdd += ' history-meeting';
            }

            if (scope.meeting.originalMeeting.Paid) {
              classesToAdd += ' paid-meeting';
            }

            if (scope.meeting.height < MIN_HEIGHT_FOR_SMALL_CLASS) {
              classesToAdd += ' meeting--short-height';

            }

            if (scope.meeting.colorClass) {
              element.find('.meeting-background-color').addClass(scope.meeting.colorClass);
            }

            if (scope.meeting.animateIn) {
              setTimeout(function () {
                element.addClass('animated flash');
              });

              setTimeout(function () {
                element.removeClass('animated flash');
              }, 1500);
            } else if (scope.meeting.defaultIn) {
              classesToAdd += ' animated zoomIn';
              setTimeout(function () {
                element.removeClass('animated zoomIn');
              }, 1000);
            }

            delete scope.meeting.animateIn;
            delete scope.meeting.defaultIn;

            if (scope.meeting.originalMeeting.disabled) {
              classesToAdd += ' disabled';
            }

            if (scope.meeting.originalMeeting.WaitingForPayment) {
              const pendingPaymentText = localize.getLocalizedString('_AwaitingPayment_');
              element.append(`<div class="flex items-center meeting__pending-payment"><div class="icon icon-locked"></div><div>${pendingPaymentText}</div></div>`)
              classesToAdd += ' meeting--pending-payment';
            }

            element.addClass(classesToAdd);


            loadImageIfNeeded();
          }

          function generateTextContext(meeting) {
            var customerName = meeting.title;
            var roomColorEl = '';
            if (calendarMetadata.viewType !== 'rooms' && calendarMetadata.enableRooms && !calendarMetadata.useRoomColors) {
              var roomColorClass = calendarMetadata.getRoomNameById(meeting.RoomId);
              if (roomColorClass) {
                roomColorEl = '<span class="meeting-room-color-indicator ' + roomColorClass + '"></span>';
              }
            }


            let html = '';

            const meetingCustomerName =
              '<div class="meeting-customer-name ui-ellipsis">' + roomColorEl + customerName + '</div>';

            /***************** FLAGS! *****************/

            let anyFlag = false;
            let flagsContent = '<div class="meeting--flags">';

            if (meeting.OnlineMeeting) {
              anyFlag = true;
              const awaitingApproval = meeting.originalMeeting.WaitingForApproval;
              flagsContent += `<span class="icon ${awaitingApproval ? 'icon-online-scheduling-meeting-awaiting-approval' : 'icon-online-scheduling-meeting'}"></span>`;


              if (meeting.originalMeeting.WaitingForApproval && !meeting.originalMeeting.WaitingForPayment) {
                element.prepend('<div class="pulse ' + scope.meeting.colorClass + '"></div>');
              }
            }

            if (meeting.originalMeeting.HasDebt) {
              anyFlag = true;
              flagsContent += `<span class="icon icon-debt"></span>`;
            }

            if (meeting.originalMeeting.HasPrePayment) {
              anyFlag = true;
              flagsContent += `<span class="icon icon-banknote"></span>`;
            }

            if (meeting.originalMeeting.IsNewCustomer) {
              anyFlag = true;
              flagsContent += `<span class="icon icon-customer-new"></span>`;
            }

            if (meeting.originalMeeting.IsSubscription) {
              anyFlag = true;
              flagsContent += `<span class="icon icon-punchcard-new"></span>`;
            }

            if (meeting.originalMeeting.HasRemarks) {
              anyFlag = true;
              flagsContent += `<span class="icon icon-notes"></span>`;
            }

            if (meeting.originalMeeting.RequiredFormStatus) {
              anyFlag = true;
              if (meeting.originalMeeting.RequiredFormStatus === 'filled') {
                flagsContent += `<span class="icon icon-filled-form"></span>`;
              } else {
                flagsContent += `<span class="icon icon-required-form"></span>`;

              }
            }

            if (anyFlag) {
              flagsContent += '</div>';
              html = `<div class="meeting-top-part flex justify-between">${meetingCustomerName}${flagsContent}</div>`
            } else {
              html = meetingCustomerName;
            }

            html += meetingDrawerHelper.getMeetingTimeEl(meeting);

            /***************** END of FLAGS! *****************/

            if (meeting.originalMeeting.MeetingState !== 'None' && calendarMetadata.meetingStatuses[meeting.originalMeeting.MeetingState]) {
              var colorClass = calendarMetadata.meetingStatuses[meeting.originalMeeting.MeetingState].colorClass;
              html = '<div class="meeting-status-indicator ' + colorClass + '"></div>' + html;
            }

            const meetingDuration = valueOrDefault(meeting.Duration?.asMinutes(), 1);
            const isLongMeeting = meetingDuration / (calendarMetadata.minutesBetweenMeetings ?? meetingDuration) > 3
            const meetingServices = `<div class="meeting-services service-type ${!isLongMeeting ? "ui-ellipsis" : ""}">${meeting.serviceNames}</div>`;
            if (meeting.originalMeeting.MobileFirst) {
              const mobileFirst = `<div class="mobile-first ui-ellipsis">${meeting.originalMeeting.MobileFirst}</div>`;
              html += `<div class="flex justify-between flex-wrap items-center">${meetingServices}${mobileFirst}</div>`
            } else {
              html += meetingServices;
            }

            if (calendarMetadata.viewType === 'rooms') {
              element.find('.meeting-content').append(getInitialsView(meeting));
            }

            if (meeting.originalMeeting.Paid) {
              let colorHex = meeting.colorClass ? colorsService.getHexColorsByName(meeting.colorClass) : MEETING_DEFAULT_BACKGROUND_COLOR;
              colorHex = darkerColor(colorHex, 0.1);

              element.find('.meeting-content').append(`<div class="flex-container flex-justify-end p-1 absolute bottom-0 w-full"><div class="meeting-content__paid icon icon-check z-10 flex justify-center items-center${meeting.historyMeeting ? ' meeting-content__paid--history' : ''}" style="background-color: ${colorHex}"></div></div>`);
            }

            element.find('.meeting-text-content').html(html);
          }

          var img;

          function getInitialsView(meeting) {
            const employeeIds = meeting.originalMeeting.CompositeAdapters
              ? Object.values(meeting.originalMeeting.CompositeAdapters).reduce((acc, curr) => acc.concat(curr.map(x => x.EmployeeId)), [])
              : [meeting.EmployeeId];


            const employees = calendarMetadata.employees.filter(employee => employeeIds.includes(employee.id)).map(x => x.name.initialsLong());

            // const colorHex = meeting.historyMeeting ? MEETING_HISTORY_BACKGROUND_COLOR :
            //   (meeting.colorClass ? colorsService.getHexColorsByName(meeting.colorClass) : MEETING_DEFAULT_BACKGROUND_COLOR);
            const colorHex = meeting.colorClass ? colorsService.getHexColorsByName(meeting.colorClass) : MEETING_DEFAULT_BACKGROUND_COLOR;
            const initialViews = employees.map(initials => {
              return `<div class="meeting-employee-initials" style="background-color: ${darkerColor(colorHex, .1)}">${initials}</div>`
            })

            return `<div class="meeting-employees">${initialViews.join('')}</div>`
          }

          function loadImageIfNeeded() {
            if (scope.meeting.preventImage || scope.meeting.imageError || scope.meeting.originalMeeting.WaitingForPayment) {
              return;
            }

            if (!img) {
              img = {
                imgEl: null,
                loaded: false,
                error: false,
                url: scope.meeting.originalMeeting.Image
              };
            }

            // No need to reload if there was once error or no image exists.
            if (!img.url || img.error) {
              return;
            }

            if (!img.loaded && !scope.meeting.width) {
              img.loaded = true;
              var imageUrl = entityImageResolver.toThumbnail(window.filesURI + img.url);
              img.imgEl = $('<div class="meeting-customer-img" style="background-image: url(\'' + imageUrl + '\')"></div>');
              element.addClass('has-image');
              element.append(img.imgEl);

              var imgBG = new Image();
              imgBG.onerror = function () {
                scope.meeting.imageError = true;
                img.error = scope.meeting.imageError = true;
                removeImage();
              };

              imgBG.src = imageUrl;
            } else if (img.loaded && scope.meeting.width) {
              removeImage();
            }
          }

          function removeImage() {
            if (img.imgEl) {
              element.removeClass('has-image');
              img.imgEl.remove();
              img.imgEl = null;
              img.loaded = false;
            }
          }
        },
        template: require('./calendar.meeting.tpl.html')
      }
    }
  ])

}());


