(function () {
  angular.module('easybizy.calendar').service('meetingDrawerHelper', [
    function () {
      this.getMeetingTimeString = (function (meeting) {
        return meeting.StartTime.format('HH:mm') + ' - ' + meeting.EndTime.format('HH:mm');
      }).bind(this);

      this.getMeetingTimeEl = function (meeting) {
        return '<div class="meeting-times">' + this.getMeetingTimeString(meeting) + '</div>';
      }
    }]);
}());
