import {DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT} from "../../constants";

(function () {
  angular.module('easybizy.common.common-services').service('globalEntitiesCreationService',
    function (Repository, $modal) {
      this.addCustomer = (fullName, mobileFirst) => {
        showModal(require('../../modal-views/add-customer/add-customer.tpl.html'),
          'AddCustomerModalController', {fullName, mobileFirst}, (newEntity) => {
            newEntity.DateOfBirth = newEntity.DateOfBirth ? moment(newEntity.DateOfBirth, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) : null;
            return Repository.Entity("Customer").create(newEntity).post()
          });
      }

      this.addLead = (fullName, mobileFirst) => {
        showModal(require('../../modal-views/add-lead/add.lead.tpl.html'),
          'AddLeadModalController', {fullName, mobileFirst}, (newEntity, deferred) => {
            return Repository.Entity('Lead').create(newEntity).post().then(deferred.resolve).catch(deferred.reject);
          });
      }

      const showModal = (template, controller, params, saveAction) => {
        const options = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template,
          controller,
          resolve: {
            saveAction: function () {
              return function (newEntity, callback) {
                return saveAction(newEntity, callback);
              };
            },
            parameters: function () {
              return params;
            }
          },
          closeFn: function () {
          }
        };

        $modal.open(options);
      }
    });
}())
