import {
  COLUMN_EDITOR_TYPE, GRID_FILTER_TYPE,
  GridApiResult,
  GridColumn,
  LazyGridDataSource,
  DatePickerLabels,
  SortByColumn
} from "@tomeravni/easybizy-vue-components";

import Moment from 'moment';
import { ClickToCall } from "../../vue/components/common/click-to-call";

import { isNonEmptyArray } from "@tomeravni/easybizy-js-common/common";
import {
  ODATA_DATE_TIME_FORMAT,
  SERVER_DATA_DATE_FORMAT,
  DATE_DISPLAY_FORMAT,
  RTL_DATE_TIME_DISPLAY
} from "../../constants";

export class CustomersTableDS extends LazyGridDataSource {
  constructor(api, localize, stateManager, mediator, Repository, printerMediator) {
    super();
    this._api = api;

    const customerName = new GridColumn('CustomerName', localize('_CustomerName_'), COLUMN_EDITOR_TYPE.LAZY, true);
    customerName.width = 150;
    customerName.filterType = GRID_FILTER_TYPE.STRING;
    customerName.filterArgs = { label: localize('Search'), debounce: true };
    customerName.freeze = true;

    customerName.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('a', {
        attrs: {
          href: `/customers/${entity.CustomerId}`,
          target: '_blank'
        }
      }, entity.CustomerName);
    };

    const mobileFirst = new GridColumn('MobileFirst', localize('_MobileFirst_'), COLUMN_EDITOR_TYPE.LAZY);
    mobileFirst.width = 125;
    mobileFirst.filterType = GRID_FILTER_TYPE.STRING;
    mobileFirst.filterArgs = { label: localize('Search'), debounce: true };
    mobileFirst.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h(ClickToCall, { props: { phone: entity.MobileFirst } })
    };


    const emailAddress = new GridColumn('EmailAddress', localize('_Email_'), false, true);
    emailAddress.width = 150;

    const totalSpent = new GridColumn('TotalSpent', localize('_TotalSpent_'), false, true);
    totalSpent.width = 100;

    const balance = new GridColumn('Balance', localize('_CustomerBalance_'), false, true);
    balance.width = 100;

    const birthday = new GridColumn('DateOfBirth', localize('_DateOfBirth_'), COLUMN_EDITOR_TYPE.LAZY);
    birthday.width = 150;
    //todo: make a birthday filter
    // birthday.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    // birthday.filterArgs = {datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()]};
    // birthday.customRenderFunc = (entity, changeDelegate, column, h) => {
    //   return h('p', entity.DateOfBirth ? Moment(entity.DateOfBirth, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT) : null);
    // };

    const createdOn = new GridColumn('CreatedOn', localize('_CreatedOn_'), COLUMN_EDITOR_TYPE.LAZY, true);
    createdOn.width = 150;
    createdOn.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    createdOn.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    createdOn.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.CreatedOn ? Moment(entity.CreatedOn, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT) : "  ");
    };

    const lastMeeting = new GridColumn('LastMeeting', localize('_LastMeeting_'), COLUMN_EDITOR_TYPE.LAZY, true);
    lastMeeting.width = 150;
    lastMeeting.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    lastMeeting.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    lastMeeting.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.LastMeeting ? Moment(entity.LastMeeting, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT) : "");
    };

    const nextMeeting = new GridColumn('NextMeeting', localize('_NextMeeting_'), COLUMN_EDITOR_TYPE.LAZY, true);
    nextMeeting.width = 150;
    nextMeeting.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    nextMeeting.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    nextMeeting.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.NextMeeting ? Moment(entity.NextMeeting, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT) : "");
    };

    const nextMeetingExist = new GridColumn('NextMeetingExist', localize('_NextMeetingExist_'), COLUMN_EDITOR_TYPE.LAZY);
    nextMeetingExist.width = 100;
    nextMeetingExist.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.NextMeetingExist ? localize('_Yes_') : localize('_No_'));
    };
    nextMeetingExist.filterType = GRID_FILTER_TYPE.SINGLE_SELECT;
    const options = [{ text: localize('_Yes_'), value: true }, { text: localize('_No_'), value: false }];
    nextMeetingExist.options = options;
    nextMeetingExist.filterArgs = {
      placeholder: localize('_All_'),
      options: options.map((opt) => ({
        text: opt.text,
        value: `NextMeetingExist eq ${opt.value}`
      }))
    };
    const historyMeetingsCount = new GridColumn('HistoryMeetingsCount', localize('_Meetings_'), false, true);
    historyMeetingsCount.width = 100;

    const arrivalSource = new GridColumn('ArrivalSource', localize('_ArrivalSource_'), COLUMN_EDITOR_TYPE.LAZY);
    arrivalSource.width = 150;
    arrivalSource.filterType = GRID_FILTER_TYPE.MULTI_SELECT;

    Repository.Entity("ArrivalSource").query().get().then(({ value }) => {
      const sources = value.map((arrivalSource) => ({
        text: arrivalSource.ArrivalSourceName,
        value: arrivalSource.ArrivalSourceId
      }));

      arrivalSource.options = [...sources];
      arrivalSource.filterArgs = {
        placeholder: localize('_ArrivalSource_'),
        options: sources.map((source) => ({
          text: source.text,
          value: `ArrivalSourceId eq ${source.value}`
        }))
      };
    });

    let priceList;
    if (mediator.showPriceLists) {
      priceList = new GridColumn('PriceList', localize('_PriceLists_'), COLUMN_EDITOR_TYPE.LAZY);
      priceList.width = 200;
      priceList.filterType = GRID_FILTER_TYPE.MULTI_SELECT;

      Repository.Entity("PriceList").query().get().then(({ value }) => {
        const priceLists = value.map((pricelist) => ({
          text: pricelist.PriceListName,
          value: pricelist.PriceListId
        }));

        priceList.options = [...priceLists];
        priceList.filterArgs = {
          placeholder: localize('_PriceLists_'),
          options: priceLists.map((pricelist) => ({
            text: pricelist.text,
            value: `PriceListId eq ${pricelist.value}`
          }))
        };
      });
    }




    this.columns = [
      customerName,
      mobileFirst,
      totalSpent,
      balance,
      createdOn,
      lastMeeting,
      nextMeeting,
      nextMeetingExist,
      historyMeetingsCount,
      arrivalSource,
      priceList,
      emailAddress,
      birthday,
    ].filter((x) => !!x);

    this._sortByColumn = new SortByColumn(customerName);

    mediator.exportToExcel = () => {
      this._api.export()
    };

    mediator.print = () => {
      let dictionary = Object.assign({}, ...this.columns.filter((c) => {
        return c.isVisible
      }).map((column) => ({
        [column.fieldName]: {
          Label: column.label,
          ToStringFormat: column.fieldName === 'Date' ? "dd/MM/yyyy" : null
        }
      })));
      this._api.print(dictionary).then(function (response) {
        printerMediator.print(response);
      })
        .catch(function (err) {
        });
    };
  }


  getItemId(item) {
    return item.CustomerId;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  adaptFiltersToQuery(filter) {
    let adaptedFiltersToSet = [];

    const adaptedFilters = filter.concat(this._constantFilters || []).map((x) => {
      if (x.column.fieldName === 'CustomerName') {
        return `(indexof(CustomerName,'${x.filterValue}') gt -1)`
      }
      if (x.column.fieldName === 'MobileFirst') {
        return `(indexof(MobileFirst,'${x.filterValue}') gt -1)`
      } else if (['CreatedOn', 'LastMeeting', 'NextMeeting'].includes(x.column.fieldName) && x.filterValue.length) {
        return adaptDateRangeQuery(x);
      } else {
        if (isNonEmptyArray(x.filterValue) && x.filterValue.length > 1) {
          return `(${x.filterValue.join(' or ')})`;
        }

        return x.filterValue;
      }
    });

    adaptedFiltersToSet = adaptedFilters;
    return adaptedFiltersToSet;
  }

  get api() {
    return this._api;
  }

  loadOnRemote(top, skip, sort, filter) {
    this._api.top(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }

    const adaptedFilters = this.adaptFiltersToQuery(filter);
    this._api.filter(adaptedFilters);

    return this._api.get().then((result) => {
      return new GridApiResult(result.map((item) => {
        return item;
      }),
        top,
        skip,
        null);
    });
  }
}

export class AbandonedCustomersDS extends LazyGridDataSource {
  constructor(api, localize, stateManager, mediator, Repository, printerMediator) {
    super();
    this._api = api;


    const customerName = new GridColumn('CustomerName', localize('_CustomerName_'), COLUMN_EDITOR_TYPE.LAZY, true);
    customerName.width = 150;
    customerName.filterType = GRID_FILTER_TYPE.STRING;
    customerName.filterArgs = { label: localize('Search'), debounce: true };
    customerName.freeze = true;
    customerName.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('a', {
        attrs: {
          href: `/customers/${entity.CustomerId}`,
          target: '_blank'
        }
      }, entity.CustomerName);
    };

    const createdOn = new GridColumn('CustomerCreatedOn', localize('_CreatedOn_'), COLUMN_EDITOR_TYPE.LAZY, true);
    createdOn.width = 150;
    createdOn.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    createdOn.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    createdOn.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.CustomerCreatedOn ? Moment(entity.CustomerCreatedOn, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT) : "  ");
    };

    const customerRemarks = new GridColumn('CustomerRemarks', localize('_Remarks_'), COLUMN_EDITOR_TYPE.LAZY);
    customerRemarks.width = 150;

    const mobileFirst = new GridColumn('MobileFirst', localize('_MobileFirst_'), COLUMN_EDITOR_TYPE.LAZY);
    mobileFirst.width = 125;
    mobileFirst.filterType = GRID_FILTER_TYPE.STRING;
    mobileFirst.filterArgs = { label: localize('Search'), debounce: true };
    mobileFirst.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h(ClickToCall, { props: { phone: entity.MobileFirst } })
    };


    const lastMeeting = new GridColumn('LastMeeting', localize('_LastMeeting_'), COLUMN_EDITOR_TYPE.LAZY, true);
    lastMeeting.width = 150;
    lastMeeting.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    lastMeeting.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    lastMeeting.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.LastMeeting ? Moment(entity.LastMeeting, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT) : "");
    };

    let employeeNames;
    if (mediator.showSpecificEmployees) {
      employeeNames = new GridColumn('Employees', localize('_Employees_'), COLUMN_EDITOR_TYPE.LAZY);
      employeeNames.width = 250;
      employeeNames.customRenderFunc = (entity, changeDelegate, column, h) => {
        return h('p', entity.Employees.map((employee) => employee.EmployeeName).join(','));
      };
      employeeNames.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
      Repository.Entity("Employee").query().filter('VisibleOnCalendar eq true').get().then(({ value }) => {
        const employees = value.map((employee) => ({
          text: employee.FirstName + " " + employee.LastName,
          value: employee.EmployeeId
        }));

        employeeNames.options = [...employees];
        employeeNames.filterArgs = {
          placeholder: localize('_Employee_'),
          options: employees.map((employee) => ({
            text: employee.text,
            value: `employee/EmployeeId eq ${employee.value}`
            // value: `Employees/any(employee: employee/EmployeeId eq ${employee.value})`
          }))
        };
      });

    }


    const historyMeetingsCount = new GridColumn('HistoryMeetingsCount', localize('_Meetings_'), false, true);
    historyMeetingsCount.width = 100;
    const cancellationsCount = new GridColumn('CancellationsCount', localize('_Cancellations_'), false, true);
    cancellationsCount.width = 100;


    this.columns = [
      customerName,
      mobileFirst,
      createdOn,
      lastMeeting,
      employeeNames,
      historyMeetingsCount,
      cancellationsCount,
      customerRemarks,
    ].filter((x) => !!x);

    this._sortByColumn = new SortByColumn(lastMeeting, true);

    mediator.exportToExcel = () => {
      this._api.export()
    };

    mediator.print = () => {
      let dictionary = Object.assign({}, ...this.columns.filter((c) => {
        return c.isVisible
      }).map((column) => ({
        [column.fieldName]: {
          Label: column.label,
          ToStringFormat: ['CustomerCreatedOn', 'LastMeeting'].includes(column.fieldName) ? "dd/MM/yyyy" : null
        }
      })));
      this._api.print(dictionary).then(function (response) {
        printerMediator.print(response);
      })
        .catch(function (err) {
        });
    };
  }

  getItemId(item) {
    return item.CustomerId;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  get api() {
    return this._api;
  }

  filterChange(filter) {
    super.filterChange(this.adaptFiltersToQuery(filter));
  }

  adaptFiltersToQuery(filter) {
    let adaptedFiltersToSet = [];

    const adaptedFilters = filter.concat(this._constantFilters || []).map((x) => {
      if (x.column.fieldName === 'CustomerName') {
        return `(indexof(CustomerName,'${x.filterValue}') gt -1)`
      } else if (['LastCancellation', 'CustomerCreatedOn'].includes(x.column.fieldName) && x.filterValue.length) {
        return adaptDateRangeQuery(x);
      } else if (x.column.fieldName === 'Employees') {
        return `Employees/any(employee:${x.filterValue.join(' or ')})`
      } else {
        if (isNonEmptyArray(x.filterValue) && x.filterValue.length > 1) {
          return `(${x.filterValue.join(' or ')})`;
        }

        return x.filterValue;
      }
    });

    adaptedFiltersToSet = adaptedFilters;
    return adaptedFiltersToSet;
  }

  loadOnRemote(top, skip, sort, filter) {
    if (isNonEmptyArray(filter)) {
      let rangeFilter = filter.find((x) => x.column.fieldName === 'LastMeeting')
      if (rangeFilter) {
        var range = filter.find((x) => x.column.fieldName === 'LastMeeting').filterValue
        this.from = moment(range[0]);
        this.to = range[1] != null ? moment(range[1]) : moment(range[0]).endOf('day');
        filter.remove(rangeFilter)
      }
    }

    this._api.from(this.from.format(ODATA_DATE_TIME_FORMAT));
    this._api.to(this.to.format(ODATA_DATE_TIME_FORMAT));
    this._api.top(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }
    const adaptedFilters = this.adaptFiltersToQuery(filter);
    this._api.filter(adaptedFilters);

    return this._api.get().then((result) => {
      return new GridApiResult(result.map((item) => {
        return item;
      }),
        top,
        skip,
        null);
    });
  }
}

export class CancelledCustomersDS extends LazyGridDataSource {
  constructor(api, localize, stateManager, mediator, Repository, printerMediator) {
    super();
    this._api = api;


    const customerName = new GridColumn('CustomerName', localize('_CustomerName_'), COLUMN_EDITOR_TYPE.LAZY, true);
    customerName.width = 150;
    customerName.filterType = GRID_FILTER_TYPE.STRING;
    customerName.filterArgs = { label: localize('Search'), debounce: true };
    customerName.freeze = true;

    customerName.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('a', {
        attrs: {
          href: `/customers/${entity.CustomerId}`,
          target: '_blank'
        }
      }, entity.CustomerName);
    };

    const customerRemarks = new GridColumn('CustomerRemarks', localize('_CustomerRemarks_'), COLUMN_EDITOR_TYPE.LAZY);
    customerRemarks.width = 150;

    const mobileFirst = new GridColumn('MobileFirst', localize('_MobileFirst_'), COLUMN_EDITOR_TYPE.LAZY);
    mobileFirst.width = 125;
    mobileFirst.filterType = GRID_FILTER_TYPE.STRING;
    mobileFirst.filterArgs = { label: localize('Search'), debounce: true };
    mobileFirst.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h(ClickToCall, { props: { phone: entity.MobileFirst } })
    };


    const cancellationTime = new GridColumn('CancellationTime', localize('_CancelledOn_'), COLUMN_EDITOR_TYPE.LAZY, true);
    cancellationTime.width = 150;
    cancellationTime.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    cancellationTime.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    cancellationTime.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.CancellationTime ? Moment(entity.CancellationTime, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT) : "");
    };

    const cancelledMeetingTime = new GridColumn('CancelledMeetingTime', localize('_MeetingTime_'), COLUMN_EDITOR_TYPE.LAZY, true);
    cancelledMeetingTime.width = 150;
    cancelledMeetingTime.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    cancelledMeetingTime.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    cancelledMeetingTime.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.CancelledMeetingTime ? Moment(entity.CancelledMeetingTime, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT) : "");
    };


    const lastCancellationReason = new GridColumn('LastCancellationReason', localize('_CancellationReason_'), COLUMN_EDITOR_TYPE.LAZY);
    lastCancellationReason.width = 100;
    lastCancellationReason.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
    Repository.Custom("Calendar").cancellationReasons().then((value) => {
      const reasons = value.reasons.map((reason) => ({
        text: reason || localize('_None_'),
        value: reason
      }));

      lastCancellationReason.options = [...reasons];
      lastCancellationReason.filterArgs = {
        placeholder: localize('_CancellationReason_'),
        options: reasons.map((reason) => ({
          text: reason.text,
          value: reason.value ? `LastCancellationReason eq '${reason.value}'` : `LastCancellationReason eq null`
        }))
      };
    });

    const employeeName = new GridColumn('EmployeeName', localize('_Employee_'), COLUMN_EDITOR_TYPE.LAZY, true);
    employeeName.width = 250;
    employeeName.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
    Repository.Entity("Employee").query().filter('VisibleOnCalendar eq true').get().then(({ value }) => {
      const employees = value.map((employee) => ({
        text: employee.FirstName + " " + employee.LastName,
        value: employee.EmployeeId
      }));

      employeeName.options = [...employees];
      employeeName.filterArgs = {
        placeholder: localize('_Employee_'),
        options: employees.map((employee) => ({
          text: employee.text,
          value: `employee/EmployeeId eq ${employee.value}`
        }))
      };
    });

    this.columns = [
      customerName,
      mobileFirst,
      cancellationTime,
      cancelledMeetingTime,
      lastCancellationReason,
      employeeName,
      customerRemarks,
    ].filter((x) => !!x);

    this._sortByColumn = new SortByColumn(cancelledMeetingTime, true);

    mediator.exportToExcel = () => {
      this._api.export()
    };

    mediator.print = () => {
      let dictionary = Object.assign({}, ...this.columns.filter((c) => {
        return c.isVisible
      }).map((column) => ({
        [column.fieldName]: {
          Label: column.label,
          ToStringFormat: ['CancellationTime', 'CancelledMeetingTime'].includes(column.fieldName) ? "dd/MM/yyyy" : null
        }
      })));
      this._api.print(dictionary).then(function (response) {
        printerMediator.print(response);
      })
        .catch(function (err) {
        });
    };
  }

  getItemId(item) {
    return item.CustomerId;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  get api() {
    return this._api;
  }

  filterChange(filter) {
    super.filterChange(this.adaptFiltersToQuery(filter));
  }

  adaptFiltersToQuery(filter) {
    let adaptedFiltersToSet = [];

    const adaptedFilters = filter.concat(this._constantFilters || []).map((x) => {
      if (x.column.fieldName === 'CustomerName') {
        return `(indexof(CustomerName,'${x.filterValue}') gt -1)`
      } else if (['CancellationTime', 'CancelledMeetingTime'].includes(x.column.fieldName) && x.filterValue.length) {
        return adaptDateRangeQuery(x);
      } else if (x.column.fieldName === 'Employees') {
        return `Employees/any(employee:${x.filterValue.join(' or ')})`
      } else {
        if (isNonEmptyArray(x.filterValue) && x.filterValue.length > 1) {
          return `(${x.filterValue.join(' or ')})`;
        }

        return x.filterValue;
      }
    });

    adaptedFiltersToSet = adaptedFilters;
    return adaptedFiltersToSet;
  }

  loadOnRemote(top, skip, sort, filter) {
    // if (isNonEmptyArray(filter)) {
    //   let rangeFilter = filter.find((x) => x.column.fieldName === 'CancelledMeetingTime')
    //   if (rangeFilter) {
    //     var range = filter.find((x) => x.column.fieldName === 'CancelledMeetingTime').filterValue
    //     this.from = moment(range[0]);
    //     this.to = moment(range[1]);
    //     filter.remove(rangeFilter)
    //   }
    // }

    // this._api.from(this.from.format(ODATA_DATE_TIME_FORMAT));
    // this._api.to(this.to.format(ODATA_DATE_TIME_FORMAT));
    this._api.top(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }
    const adaptedFilters = this.adaptFiltersToQuery(filter);
    this._api.filter(adaptedFilters);

    return this._api.get().then((result) => {
      return new GridApiResult(result.map((item) => {
        return item;
      }),
        top,
        skip,
        null);
    });
  }
}


export class CustomersWithDebtDS extends LazyGridDataSource {
  constructor(api, localize, stateManager, mediator, Repository, printerMediator) {
    super();
    this._api = api;


    const customerName = new GridColumn('CustomerName', localize('_CustomerName_'), COLUMN_EDITOR_TYPE.LAZY, true);
    customerName.width = 150;
    customerName.filterType = GRID_FILTER_TYPE.STRING;
    customerName.filterArgs = { label: localize('Search'), debounce: true };
    customerName.freeze = true;

    customerName.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('a', {
        attrs: {
          href: `/customers/${entity.CustomerId}`,
          target: '_blank'
        }
      }, entity.CustomerName);
    };

    const debt = new GridColumn('Debt', localize('_Debt_'), false, true);
    debt.width = 150;

    const lastDebtTime = new GridColumn('LastDebtTime', localize('_LastDebtTime_'), COLUMN_EDITOR_TYPE.LAZY, true);
    lastDebtTime.width = 150;
    lastDebtTime.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    lastDebtTime.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    lastDebtTime.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.LastDebtTime ? Moment(entity.LastDebtTime, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT) : "");
    };

    const customerRemarks = new GridColumn('CustomerRemarks', localize('_CustomerRemarks_'), COLUMN_EDITOR_TYPE.LAZY);
    customerRemarks.width = 200;

    const mobileFirst = new GridColumn('MobileFirst', localize('_MobileFirst_'), COLUMN_EDITOR_TYPE.LAZY);
    mobileFirst.width = 150;
    mobileFirst.filterType = GRID_FILTER_TYPE.STRING;
    mobileFirst.filterArgs = { label: localize('Search'), debounce: true };
    mobileFirst.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h(ClickToCall, { props: { phone: entity.MobileFirst } })
    };


    const createdOn = new GridColumn('CustomerCreatedOn', localize('_CreatedOn_'), COLUMN_EDITOR_TYPE.LAZY, true);
    createdOn.width = 150;
    createdOn.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    createdOn.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    createdOn.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.CustomerCreatedOn ? Moment(entity.CustomerCreatedOn, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT) : "  ");
    };


    this.columns = [
      customerName,
      debt,
      lastDebtTime,
      mobileFirst,
      customerRemarks,
    ].filter((x) => !!x);

    this._sortByColumn = new SortByColumn(lastDebtTime, true);

    mediator.exportToExcel = () => {
      this._api.export()
    };

    mediator.print = () => {
      let dictionary = Object.assign({}, ...this.columns.filter((c) => {
        return c.isVisible
      }).map((column) => ({
        [column.fieldName]: {
          Label: column.label,
          ToStringFormat: ['CustomerCreatedOn', 'LastDebtTime'].includes(column.fieldName) ? "dd/MM/yyyy" : null
        }
      })));
      this._api.print(dictionary).then(function (response) {
        printerMediator.print(response);
      })
        .catch(function (err) {
        });
    };
  }

  getItemId(item) {
    return item.CustomerId;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  get api() {
    return this._api;
  }

  filterChange(filter) {
    super.filterChange(this.adaptFiltersToQuery(filter));
  }

  adaptFiltersToQuery(filter) {
    let adaptedFiltersToSet = [];

    const adaptedFilters = filter.concat(this._constantFilters || []).map((x) => {
      if (x.column.fieldName === 'CustomerName') {
        return `(indexof(CustomerName,'${x.filterValue}') gt -1)`
      } else if (['LastDebtTime', 'CustomerCreatedOn'].includes(x.column.fieldName) && x.filterValue.length) {
        return adaptDateRangeQuery(x);
      } else {
        if (isNonEmptyArray(x.filterValue) && x.filterValue.length > 1) {
          return `(${x.filterValue.join(' or ')})`;
        }

        return x.filterValue;
      }
    });

    adaptedFiltersToSet = adaptedFilters;
    return adaptedFiltersToSet;
  }

  loadOnRemote(top, skip, sort, filter) {
    this._api.top(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }
    const adaptedFilters = this.adaptFiltersToQuery(filter);
    this._api.filter(adaptedFilters);

    return this._api.get().then((result) => {
      return new GridApiResult(result.map((item) => {
        return item;
      }),
        top,
        skip,
        null);
    });
  }
}

class DefaultDatePickerLabels extends DatePickerLabels {
  constructor(localize) {
    super();
    this._inputPlaceholder = localize('_ChooseDate_');
    this._selectADate = localize('_ChooseDate_');
    this._pickAStartTime = localize('_StartTime_');
  }

  get inputPlaceholder() {
    return this._inputPlaceholder;
  }

  get selectADate() {
    return this._selectADate;
  }

  get pickAStartTime() {
    return this._pickAStartTime;
  }
}


function adaptDateRangeQuery(dateFilter) {
  const fromDate = moment(dateFilter.filterValue[0]).format(ODATA_DATE_TIME_FORMAT);
  let toDate;
  if (dateFilter.filterValue[1] && dateFilter.filterValue[1].getDate() != dateFilter.filterValue[0].getDate()) {
    toDate = moment(dateFilter.filterValue[1]).format(ODATA_DATE_TIME_FORMAT);
  } else {
    toDate = moment(dateFilter.filterValue[0]).endOf('day').format(ODATA_DATE_TIME_FORMAT);
  };
  return `(${dateFilter.column.fieldName} ge DateTime'${fromDate}' and ${dateFilter.column.fieldName} le DateTime'${toDate}')`;
}


