import { getSafely } from "@tomeravni/easybizy-js-common/common";

(function () {
  angular.module('easybizy.common.common-services').service('contextManager',
    function ($q, Repository, entityImageResolver) {
      let persistedData = {};
      var contextEntities = {};
      var contextLoadingDelegates = {};
      var contextWatchers = {};
      var disabled = false;
      this.set = set.bind(this);
      this.get = get.bind(this);
      this.getRawPersistedData = getRawPersistedData.bind(this);
      this.clear = clear.bind(this);
      this.clearAll = clearAll.bind(this);
      this.watch = watch.bind(this);

      const contextsMetadata = {
        customer: {
          load: function (initMode) {
            // If customer was set, no need to keep last caller phone number.
            contextsMetadata.customerPhoneNumber.state = null;
            delete contextEntities.customerPhoneNumber;

            if (getSafely(['customer', 'expanded'], persistedData)) {
              return contextsMetadata.customer.handleLoadedData(!!initMode, persistedData.customer);
            }

            contextsMetadata.customer.state = 'loading';
            const persistedCustomer = persistedData['customer'];
            const customerId = getSafely(['CustomerId'], persistedCustomer, persistedCustomer);
            Repository.Entity("Customer").query().id(customerId).expand("Images").get()
              .then(contextsMetadata.customer.handleLoadedData.bind(false, !!initMode))
              .catch(function (e) {
                contextsMetadata.customer.state = 'failed';
                resolve('customer', e);
              });


          },
          state: null,
          handleLoadedData: function (initMode, data) {
            contextsMetadata.customer.state = 'loaded';
            contextEntities.customer = data;
            contextsMetadata.customer.visit(initMode);
            persistedData['customer'] = data.CustomerId;
            contextEntities['customer'] = data;

            resolve('customer');
          },
          visit: function (initMode) {
            contextEntities.customer.Image = entityImageResolver.resolve(contextEntities.customer);
            if (!initMode && contextEntities.customer.Remarks && !contextEntities.customer.preventToasts) {
              toastr.warning(contextEntities.customer.Remarks);
            }
          }
        },
        employee: {
          load: function () {
            contextsMetadata.employee.state = 'loading';
            Repository.Entity("Employee").query().id(persistedData['employee']).expand("DaysOfWork,DaysOfWorkExceptions").get()
              .then(function (data) {
                contextsMetadata.employee.state = 'loaded';
                contextEntities.employee = data;
                resolve('employee');
              })
              .catch(function (e) {
                contextsMetadata.employee.state = 'failed';
                resolve('employee', e);
              });
          },
          state: null
        },
        customerPhoneNumber: {
          customSetMethod: function (phoneNumber) {
            contextsMetadata.customerPhoneNumber.state = 'loaded';
            contextEntities.customerPhoneNumber = phoneNumber;
          },
          state: null
        },
      };

      if ($.sessionStorage.isSet('my-context')) {
        persistedData = $.sessionStorage.get('my-context');
        for (let contextType in persistedData) {
          this.set(contextType, persistedData[contextType], true);
        }
      }

      function resolve(type, error) {
        var contextDelegates = contextLoadingDelegates[type];
        if (contextDelegates) {
          contextDelegates.slice().forEach(function (delegate) {
            contextDelegates.remove(delegate);
            if (error) {
              delegate.reject(error);
            } else {
              delegate.resolve(contextEntities[type]);
            }
          });

          contextLoadingDelegates[type] = null;
        }

        notifyWatchers(type);
        commit();
      }

      function notifyWatchers(type) {
        if (contextWatchers[type] && contextWatchers[type].length > 0) {
          contextWatchers[type].forEach(function (delegate) {
            delegate.call(null, contextEntities[type]);
          })
        }
      }


      function set(type, idsOrValues, initMode) {
        if (disabled) {
          return;
        }

        if (contextsMetadata[type]?.customSetMethod) {
          contextsMetadata[type].customSetMethod(idsOrValues, initMode);
        } else {
          persistedData[type] = idsOrValues;
          contextsMetadata[type]?.load(initMode);
        }
      }

      function get(type) {
        const deferred = $q.defer();
        if (!contextsMetadata[type].state) {
          deferred.resolve();
        } else if (contextsMetadata[type].state === 'loading') {
          contextLoadingDelegates[type] = contextLoadingDelegates[type] || [];
          contextLoadingDelegates[type].push(deferred);
        } else {
          deferred.resolve(contextEntities[type]);
        }

        return deferred.promise;
      }

      function getRawPersistedData(type) {
        return persistedData[type];
      }

      /* Returns cancel function watcher*/
      function watch(type, delegate) {
        contextWatchers[type] = contextWatchers[type] || [];
        contextWatchers[type].push(delegate);
        return function () {
          contextWatchers[type].splice(contextWatchers[type].indexOf(delegate), 1);
        }
      }

      function clearType(type) {
        delete persistedData[type];
        delete contextEntities[type];
        notifyWatchers(type);
        commit();
      }

      function clear(typeOrTypes) {
        if (Array.isArray(typeOrTypes)) {
          typeOrTypes.forEach(clearType)
        } else {
          clearType(typeOrTypes);
        }

        if (Object.keys(persistedData).length > 0) {
          commit();
        } else {
          $.sessionStorage.remove('my-context');
        }
      }

      function clearAll() {
        disabled = true;
        persistedData = {};
        for (var entityType in contextEntities) {
          notifyWatchers(entityType);
        }

        contextEntities = {};
        $.sessionStorage.remove('my-context');
        disabled = false;
      }

      function commit() {
        $.sessionStorage.set('my-context', persistedData);
      }

    });

}());
