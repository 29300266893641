(function () {
  'use strict';

  angular.module('easybizy.common.common-controls').directive("rootNavigation", rootNavigation);

  function rootNavigation($transitions, $timeout, $modal) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        routes: '=',
        businessName: '=',
        viewName: '=',
        currentState: '=',
        loggedInUser: '='
      },
      link: (scope) => {
        $transitions.onSuccess({}, () => {
          $timeout(() => scope.open = false);
        })

        scope.openUserSettings = () => {
          const opts = {
            backdrop: 'static',
            keyboard: true,
            backdropClick: false,
            template: require('../../../modal-views/user-settings/user.settings.modal.tpl.html'),
            controller: 'UserSettingsModalViewController',
            resolve: {
              loggedInUser: function () {
                return scope.loggedInUser
              }
            },
            closeFn: function () {
              console.log("closing");
            }
          };

          $modal.open(opts);
        }
      },
      template: `
        <nav class="root-navigation custom-scrollbar-container" ng-class="open && 'open'">
            <section class="root-navigation__mask" ng-click="open = false" disable-click-effect="true"></section>
            <ul class="root-navigation-bar">
                <li class="toggle-menu">
                    <div ng-click="open = !open">
                        <div class="navigation-icon icon" ng-class="open ? 'icon-flat-close' : 'icon-menu'"></div>
                    </div>
                    <button class="root-navigation-user flex gap-2 items-center" ng-click="openUserSettings()">
                        <div class="icon-user-profile"></div>
                        <div class="text-sm">{{loggedInUser.username}}</div>
                        <div class="evci-chevron-down text-sm"></div>
                    </button>
                </li>
                <li ng-repeat="route in routes" ng-class="{'selected-navigation-view':viewName === route.name || currentState.topState === route.stateName}"
                tippy="{{ route.name }}" tippy-disabled="{{ open  }}"
                bindonce>
                  <a
                      bo-if="!route.directUrl"
                      foreground-click="true" ng-click="true"
                      ui-sref="{{::route.stateName}}({})">
                     <div class="navigation-link-wrapper">
                       <div class="navigation-icon" bo-class="route.icon"></div><div class="route-name">{{ route.name }}</div>
                      </div>
                  </a>
                  <a
                     foreground-click="true" ng-click="true"
                     bo-if="route.directUrl"
                     ng-href="{{::route.directUrl}}"
                     delayed-href>
                    <div class="navigation-link-wrapper">
                      <div class="navigation-icon" bo-class="route.icon"></div><div class="route-name">{{ route.name }}</div>
                    </div>
                  </a>
                </li>
            </ul>
        </nav>
      `
    }
  }

})();
