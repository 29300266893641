import './simple.customer.picker.less'
import { DelayedFunction } from "../../../general/helpers";

angular.module('easybizy.common.common-controls').directive('simpleCustomerPicker', [
  '$timeout', 'localize', 'Repository', 'filterLogicFactory', '$q',
  function ($timeout, localize, Repository, filterLogicFactory, $q) {
    return {
      restrict: 'E',
      replace: true,
      template: require('./simple.customer.picker.tpl.html'),
      scope: {
        includeGroups: '@',
        selectedCustomerWrapper: '=',
        selectedCustomerField: '@'
      },
      link: function (scope, element, attrs) {
        scope.isLoadingWrapper = { isLoading: false };
        scope.parsedSelectedCustomer = { text: getNameFromCustomer(scope.selectedCustomerWrapper[scope.selectedCustomerField]) || "" };

        scope.$watch('selectedCustomerWrapper', function (newVal) {
          if (newVal.customer) {
            var name = getNameFromCustomer(newVal) || "";
            if (name !== scope.parsedSelectedCustomer.text) {
              scope.parsedSelectedCustomer.text = name;
            }
          }
        });

        var lastLoadedFilterText = "";

        var api = scope.api = new reusableAPI(Repository.Entity("Customer").query());

        var delayedSearchObject = new DelayedFunction(findCustomer, 700);

        setTimeout(function () {
          element.find('input').on('focus', function () {
            $(this).select();
          })
        });

        scope.$watch('parsedSelectedCustomer.text', function (newCustomerNameSearch) {
          // customer requested new name;
          if (newCustomerNameSearch === getNameFromCustomer(scope.selectedCustomerWrapper[scope.selectedCustomerField])) {
            return;
          }

          if (newCustomerNameSearch === scope.parsedSelectedCustomer.text
            && newCustomerNameSearch !== lastLoadedFilterText) {
            delayedSearchObject.set(newCustomerNameSearch);
          }
        });

        scope.focused = function () {
          scope.listVisibility.visible = api.items && api.items.length > 0;
        };

        scope.listVisibility = { visible: false };

        scope.selectCustomer = function (customer) {
          scope.selectedCustomerWrapper[scope.selectedCustomerField] = customer;
          scope.parsedSelectedCustomer.text = getNameFromCustomer(customer);
          scope.listVisibility.visible = false;
        };

        function findCustomer(name) {
          lastLoadedFilterText = name;
          if (scope.parsedSelectedCustomer.text.length > 0) {
            var filter = generateAlphabetically(scope.parsedSelectedCustomer.text, 'FirstName,LastName,MembershipId,MobileFirst');
            if (scope.includeGroups != 'true') {
              filter += "and (CustomerType eq 'Single')";
            }

            api.filters = filter;
            api.forceReload().then(function (items) {
              $timeout(function () {
                scope.listVisibility.visible = items.length > 0;
              });

              scope.noCustomersFound = items.length === 0;
            })
          } else {
            api.filters = [];
            api.items.length = 0;
            scope.noCustomersFound = false;
          }
        }


        function getNameFromCustomer(customer) {
          return customer && customer.hasOwnProperty("FirstName") ? customer.FirstName + (customer.LastName ? " " + customer.LastName : "") :
            customer && customer.hasOwnProperty("CustomerName") ? customer.CustomerName : null;
        }

        function generateAlphabetically(string, fields) {
          return filterLogicFactory.get('search', {
            fields: "FirstName, LastName, MembershipId",
            type: "Customer",
            numbersOnlyFields: 'MobileFirst, MobileSecond'
          })
            .convertValue(string, fields, 'Customer');
        }
      }
    };

    function reusableAPI(repository) {
      var self = this;
      var selectedIds, isDirty = false, isLoading = false,
        eachPageLength = 100,
        lastUsedFilters = [],
        lastUsedIds = [],
        currentPage = 0,
        lastRequestedPage = -1;

      this.forceReload = function () {
        return reload()
      };

      var filters = [];

      Object.defineProperty(this, 'filters', {
        get: function () {
          return filters;
        },
        set: function (newValue) {
          isDirty = true;
          filters = newValue;
        }
      });
      var items = [];
      Object.defineProperty(this, 'items', {
        get: function () {
          return items;
        }
      });


      Object.defineProperty(this, 'selectedIds', {
        get: function () {
          return selectedIds;
        },
        set: function (newValue) {
          isDirty = true;
          selectedIds = newValue;
        }
      });

      Object.defineProperty(this, 'isLoading', {
        get: function () {
          return isLoading;
        }
      });

      function reload() {
        if (!isDirty && currentPage === -1 || (currentPage === lastRequestedPage)) { // no more to load.
          return;
        }

        isLoading = true;

        var clearEntities = false;
        if (isDirty) {
          currentPage = 0;
          lastRequestedPage = -1;
          isDirty = false;
          clearEntities = true;
          //element.animate({scrollTop: 0});
        }

        lastRequestedPage = currentPage;
        repository.clear(); // clears the top, skip and filters.
        repository.take(eachPageLength);
        repository.skip(eachPageLength * currentPage);
        if (self.filters) {
          lastUsedFilters.pushAll(self.filters);
          repository.filter(self.filters);
        }

        if (angular.isDefined(selectedIds)) {
          lastUsedIds.pushAll(selectedIds);
          repository.ids(selectedIds);
        }

        self.list = [];
        var deferred = $q.defer();
        repository.get().then(function (data) {
          if (currentPage == 0) {
            items.length = 0;
          }

          if (data.results.length < eachPageLength) {
            currentPage = -1;
          } else {
            currentPage++;
          }


          $timeout(function () {
            items.push.apply(items, data.results);
          });

          deferred.resolve(items);
        });

        return deferred.promise;


      }
    }
  }
]);
