import './add.product.modal.less'
import { DelayedFunction } from "../../general/helpers";

angular.module('easybizy.easybizyModalViewsModel').controller('AddProductModalController',
  function ($scope, $modalInstance, saveAction, $timeout, $http, localize, Repository, configuration, filesService, confirmService, $q, productsPickerRepository) {
    var k_productsDialog = "products";
    var k_serviceDialog = "services";
    var k_extrnalVoucherDialog = 'external-voucher';
    var k_maxNumberOfCategories = 5;
    var configurations = configuration.get();
    var minMeetingLength = configurations.CalendarSettings.MinimumMeetingLengthInMinutes.Value;
    var usingInventory = !configurations.ProductsAndServicesSettings.IgnoreInventory;
    var chargeForIngredients = configurations.ProductsAndServicesSettings.ChargeIngredients;
    var isUsingBarcode = configurations.ProductsAndServicesSettings.UsingBarcode;
    var showWaitingAfter = configurations.ProductsAndServicesSettings.EnableWaitingAfter;


    /******************************* Common part *******************************/
    $scope.enableAddingIngredients = !configuration.get().ProductsAndServicesSettings.IgnoreIngredients || configuration.get().ProductsAndServicesSettings.IncludeIngredientsModelForCustomer;
    $scope.useServiceIngredients = !configuration.get().ProductsAndServicesSettings.IgnoreIngredients;
    $scope.useCompositeServices = configuration.get().ProductsAndServicesSettings.EnableCompositeService;
    $scope.usingExternalVouchers = configurations.ProductsAndServicesSettings.UseExternalVoucherModel;

    $scope.productOrService = {};
    $scope.productOrService.Cost = 0;
    $scope.productOrService.Profit = 0;
    $scope.productOrService.Price = 0;
    $scope.productOrService.Duration = minMeetingLength;
    $scope.productOrService.Quantity = 0;
    $scope.productOrService.Title = "";
    $scope.productOrService.SelectedManufacturer = null;
    $scope.clearImageDelegate = {}; // this allow delegation of clearing the image after save and add another has been
    // invoked.

    $scope.dialogHeader = localize.getLocalizedString('_AddProductOrService_');
    if ($scope.enableAddingIngredients) {
      $scope.dialogHeader += ' / ' + localize.getLocalizedString('_ProductIngredient_');
    }

    if ($scope.usingExternalVouchers) {
      $scope.dialogHeader += ' / ' + localize.getLocalizedString('_ExternalVoucher_');
    }


    /////////// FIELDS DEFINITION
    var durationValue = {
      label: localize.getLocalizedString("_Duration_"), fieldName: 'Duration',
      model: $scope.productOrService, fieldValueType: "hrs", type: "time",
      min: "00:" + minMeetingLength, max: "3:00", delta: minMeetingLength, isManuallyDisabled: true
    };

    var waitingAfter = {
      label: localize.getLocalizedString("_BreakAfter_"), fieldName: 'WaitingAfter',
      model: $scope.productOrService, fieldValueType: "hrs", type: "time",
      min: "00:00", max: "3:00", delta: minMeetingLength, isManuallyDisabled: true
    };

    var quantity = {
      label: localize.getLocalizedString("_Quantity_"),
      fieldName: 'Quantity',
      model: $scope.productOrService,
      icon: 'icon icon-quantity',
      type: "integer",
      min: 0,
      delta: 1
    };
    var costField = {
      label: localize.getLocalizedString("_Cost_"),
      fieldName: 'Cost',
      model: $scope.productOrService,
      manuallyChanged: function (newVal) {
        if ($scope.productOrService.Price > 0) {
          fixProfit($scope.productOrService.Price);
        } else if ($scope.productOrService.Profit > 0) {
          fixPrice($scope.productOrService.Profit);
        }
      },
      fieldValueType: "".toCurrencySymbol(window.k_Currency),
      type: "float",
      min: 0,
      delta: 1
    };
    var profitField = {
      label: localize.getLocalizedString("_Profit_"), fieldName: 'Profit', manuallyChanged: function (newVal) {
        fixPrice(newVal);
      },
      model: $scope.productOrService, fieldValueType: "%", type: "float", min: 0, delta: 1
    };

    var retailPriceField = {
      label: localize.getLocalizedString("_RetailPrice_"),
      fieldName: 'Price',
      manuallyChanged: function (newVal) {
        fixProfit(newVal);
      },
      model: $scope.productOrService,
      fieldValueType: "".toCurrencySymbol(window.k_Currency),
      type: "float",
      min: 0,
      delta: 1
    };

    if (isUsingBarcode) {
      $scope.barcodeField = {
        fieldName: 'SerialNumber', icon: 'icon icon-barcode',
        placeholder: localize.getLocalizedString('_BarcodeNumber_'),
        validation: "{'required': false, 'max-length': 20}"
      };
    }
    /////////// ENDOF FIELDS DEFINITION

    $scope.numberValues = [
      costField, profitField, retailPriceField, durationValue, waitingAfter
    ];

    if (usingInventory) {
      $scope.numberValues.push(quantity);
    }

    function fixProfit(price) {
      if (price > 0) {
        $scope.productOrService.Profit = ((price - $scope.productOrService.Cost) / $scope.productOrService.Cost * 100).toFixed(2);
      } else {
        $scope.productOrService.Profit = 0;
      }
    }

    function fixPrice(profit) {
      $scope.productOrService.Price = ($scope.productOrService.Cost * (1 + (profit / 100))).toFixed(2);
    }

    /*
     Switch between Product and Service Mode.
     */
    var ingredientType = {
      name: localize.getLocalizedString("_ProductIngredient_"),
      value: k_productsDialog,
      ingredient: true,
      metadata: 'ProductMetadata'
    };

    $scope.dialogType = [
      {
        name: localize.getLocalizedString("_Product_"), value: k_productsDialog,
        metadata: 'ProductMetadata'
      },
      {
        name: localize.getLocalizedString("_Service_"), value: k_serviceDialog,
        metadata: 'ServiceMetadata'
      }
    ];

    if ($scope.enableAddingIngredients) {
      $scope.dialogType.splice(1, 0, ingredientType);
    }

    $scope.imageUploaded = function (image) {
      $scope.uploadedImage = image;
    };

    /******************************* External-Voucher part *******************************/

    if ($scope.usingExternalVouchers) {
      $scope.dialogType.push(
        {
          name: localize.getLocalizedString("_ExternalVoucher_"), value: k_extrnalVoucherDialog,
          metadata: 'ExternalVoucherMetadata'
        });

      $scope.vendors = [
        { name: '+ ' + localize.getLocalizedString("_SelectVendor_"), value: null }
      ];

      $scope.selectedVendorWrapper = { selected: $scope.vendors[0] };

      Repository.Entity('ExternalVoucherVendor').query().get().then(function (result) {
        result.value.forEach(function (vendor) {
          $scope.vendors.push({ name: vendor.ExternalVoucherVendorName, value: vendor });
        })

      }).catch(function (err) {
        toastr.error(localize.getLocalizedString('_ErrorLoadingVouchersVendors_'));
      });

      $scope.addNewVendor = function () {
        console.log('vendor added.');
      };


      /* voucher services. */
      $scope.voucherServicesProxy = Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory(true).entity("service");
      $scope.voucherServices = [];
      $scope.removeVoucherService = function (service) {
        $scope.voucherServices.remove(service);
      };

      $scope.externalVoucherServices = [
        { name: localize.getLocalizedString("_AddVoucherService_"), value: null }
      ];

      $scope.selectedVoucherServicesWrapper = { selectedVoucherService: $scope.externalVoucherServices[0] };
      $scope.voucherServiceSelected = function (voucherService) {
        var copyOfVoucher = Object.assign({}, voucherService);
        delete copyOfVoucher.$$hashKey;
        $scope.voucherServices.push(copyOfVoucher);
        $scope.selectedVoucherServicesWrapper.selectedVoucherService = $scope.externalVoucherServices[0];
      };

    }

    /******************************* ENDOF External-Voucher part *******************************/

    var isProductAsIngredient = undefined;
    $scope.selectedDialogType = {};
    $scope.selectedDialogType.value = $scope.dialogType.filter(x => x.value === k_serviceDialog)[0];
    $scope.voucherMode = false;
    $scope.entityType =
      $scope.$watch('selectedDialogType', function (newVal) {
        retailPriceField.hidden = false;
        durationValue.hidden = newVal.value.value !== k_serviceDialog;
        waitingAfter.hidden = newVal.value.value !== k_serviceDialog || !showWaitingAfter;
        quantity.hidden = newVal.value.value !== k_productsDialog;
        $scope.voucherMode = false;
        if (isUsingBarcode) {
          $scope.barcodeField.hidden = newVal.value.value === k_serviceDialog;
        }

        if (newVal.value.ingredient) {
          isProductAsIngredient = newVal.value.ingredient;
          $scope.productOrServiceNamePlaceholder = localize.getLocalizedString("_IngredientName_");
          if (!chargeForIngredients) {
            costField.hidden = true;
            profitField.hidden = true;
            retailPriceField.hidden = true;
            quantity.hidden = true;
          }
        } else if (newVal.value.value === k_extrnalVoucherDialog) {
          $scope.voucherMode = true;
          costField.hidden = false;
          profitField.hidden = false;
          $scope.productOrServiceNamePlaceholder = localize.getLocalizedString('_VoucherName_');

        } else {
          costField.hidden = newVal.value.value === k_serviceDialog;
          profitField.hidden = newVal.value.value === k_serviceDialog;
          $scope.productOrServiceNamePlaceholder = newVal.value.value === k_productsDialog ? localize.getLocalizedString("_ProductName_") : localize.getLocalizedString("_ServiceName_");
        }

        $scope.concreteServiceCategories.length = 0;
        $scope.concreteProductCategories.length = 0;
      }, true);

    $scope.productOrService.ImageId = 0;
    $scope.uploadImageWrapper = {};
    $scope.uploadImageWrapper.onDoneUploading = function (image) {
      $scope.productOrService.ImageId = image ? image.id : null;
    };

    $scope.categorySelected = function (category) {
      if ($scope.selectedDialogType.value.value === k_productsDialog && $scope.productCategories[0].value !== category.value) {
        if ($scope.concreteProductCategories.indexOf(category) < 0) { // doesn't already exists.
          if ($scope.concreteProductCategories.length === k_maxNumberOfCategories) {
            toastr.warning(localize.getLocalizedString("_MaxCategoriesNumberIs_") + k_maxNumberOfCategories);
          } else {
            $scope.concreteProductCategories.push(category);
          }
        }
        $scope.selectedProductCategoriesWrapper.selectedProductCategories = $scope.productCategories[0];
      } else if ($scope.selectedDialogType.value.value === k_serviceDialog && $scope.serviceCategories[0].value !== category.value) {
        if ($scope.concreteServiceCategories.indexOf(category) < 0) { // doesn't already exists.
          if ($scope.concreteServiceCategories.length === k_maxNumberOfCategories) {
            toastr.warning(localize.getLocalizedString("_MaxCategoriesNumberIs_") + k_maxNumberOfCategories);
          } else {
            $scope.concreteServiceCategories.push(category);
          }
        }

        $scope.selectedServiceCategoriesWrapper.selectedServiceCategories = $scope.serviceCategories[0];
      }

    };

    $scope.isSaving = false;
    $scope.save = function (addAnother) {
      if (validateEntity()) {
        var successCallback = function (results) {
          productsPickerRepository.clearCache();

          $scope.isSaving = false;
          updateCategoriesFromNewlyLoaded(addAnother, results);
          if (addAnother) {
            clearDialog();
            $scope.$digestIfNeeded();
          } else {
            $modalInstance.dismiss('cancel');
          }
        };


        $scope.isSaving = true;
        if (!$scope.suggestedSelectedImageUrl) {
          return saveEntity()
          .then(successCallback)
          .catch(function (err) {
              $scope.$evalAsync(function () {
                $scope.isSaving = false;
              });

              console.log(err);
              toastr.error(localize.getLocalizedString("_ErrorLoadingModule_", localize.getLocalizedString("_Products_")));
            });
        }

        var relatedEntityType;

        switch ($scope.selectedDialogType.value.value) {
          case k_productsDialog:
            relatedEntityType = 'ProductMetadata';
            break;
          case k_serviceDialog:
            relatedEntityType = 'ServiceMetadata';
            break;
          case k_extrnalVoucherDialog:
            relatedEntityType = 'ExternalVoucherMetadata';
            break;
        }

        filesService.uploadFileFromLink($scope.suggestedSelectedImageUrl, 'Image', relatedEntityType, true, false)
          .then(function (image) {
            saveEntity(image.ImageId)
              .then(successCallback)
              .catch(function (err) {
                $scope.$evalAsync(function () {
                  $scope.isSaving = false;
                });

                console.log(err);
                toastr.error(localize.getLocalizedString("_ErrorLoadingModule_", localize.getLocalizedString("_Products_")));
              })
          }).catch(function (err) {

          $scope.$evalAsync(function () {
            $scope.isSaving = false;
          });

          console.log(err);
          toastr.error(localize.getLocalizedString('_ErrorSavingEntityImage_') + ' - ' + err.Message);
        });
      }
    };

    function saveEntity(imageId) {
      var entityToCreate = null;

      switch ($scope.selectedDialogType.value.value) {
        case k_productsDialog:
          entityToCreate = generateProduct();
          break;
        case k_serviceDialog:
          entityToCreate = generateService();

          break;
        case k_extrnalVoucherDialog:
          entityToCreate = generateExternalVoucher();
          break;
      }

      if (imageId) {
        entityToCreate.ImageId = imageId;
      } else if ($scope.uploadedImage) {
        entityToCreate.ImageId = $scope.uploadedImage.id;
      }

      if (entityToCreate.FormId) {
        const deferred = $q.defer();

        confirmService.confirm(
          /*title*/localize.getLocalizedString('_UpdateServiceCategories_'),
          /*subtitle*/localize.getLocalizedString('_UpdateServiceCategoriesDescription_'),
          /*onOk*/function () {
            entityToCreate.AssignFormToCategory = false;
            deferred.resolve(saveAction(entityToCreate));
          },
          /*On cancel*/
          function () {
            entityToCreate.AssignFormToCategory = true;
            deferred.resolve(saveAction(entityToCreate));
          },
          localize.getLocalizedString('_JustForThisService_'),
          /*Ok text*/localize.getLocalizedString('_AllCategory_'));

        return deferred.promise;
      } else {
        return saveAction(entityToCreate);
      }

    }

    function updateCategoriesFromNewlyLoaded(addAnother, results) {
      if ($scope.newCategoriesToCreate.length > 0) {
        var currentProxy, selectedCategories;
        if ($scope.selectedDialogType.value.value === "products") {
          currentProxy = $scope.productsProxy;
          selectedCategories = $scope.concreteProductCategories;
        } else {
          currentProxy = $scope.servicesProxy;
          selectedCategories = $scope.concreteServiceCategories;
        }

        currentProxy.forceReload(true).then(function () {
          if (addAnother && $scope.newCategoriesToCreate.length > 0) {
            angular.forEach(selectedCategories, function (selectedCategory) {
              if (selectedCategory.categoryValue < 0) {
                selectedCategory.categoryValue = results.CategoryIdsMapping[selectedCategory.categoryValue];
              }
            });

            $scope.newCategoriesToCreate.length = 0;
          }
        });
      }

      if (addAnother && $scope.newManufacturers.length > 0) {
        angular.forEach(results.ManufacturerIdsMapping, function (generatedId, key) {
          var toReplace = $scope.manufactors.filter(x => x.value === parseInt(key))[0];
          toReplace.categoryValue = toReplace.value = generatedId;
        });

        $scope.newManufacturers.length = 0;
      }
    }

    $scope.saveAndAddAnother = function () {
      $scope.save(true);
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    function validateEntity() {
      var isValid = false;
      switch ($scope.selectedDialogType.value.value) {
        case k_productsDialog:
          isValid = validateProduct();
          break;
        case k_serviceDialog:
          isValid = validateService();
          break;
        case k_extrnalVoucherDialog:
          isValid = validateExternalVoucher();
          break;
      }

      if (!isValid && $scope.isIngredientsMode) {
        $scope.toggleIngredientsMode();
      }

      return isValid;
    }


    /******************************* Product part *******************************/
    $scope.manufactors = [
      { name: localize.getLocalizedString("_SelectManufactor_"), value: null }
    ];
    $scope.productOrService.SelectedManufacturer = $scope.manufactors[0];

    $scope.manufacturersProxy = Repository.Entity("Manufacturer").query(function (x) {
      // decorate entity method.
      return { name: x.ManufacturerName, value: x.ManufacturerId };
    }, function (manufacturerResult) {
      // decorate list method.
      return manufacturerResult.results;
    });

    $scope.newManufacturers = [];
    $scope.addNewManufactorAction = function (newManufacturer) {
      $scope.newManufacturers.push({ name: newManufacturer.name, id: newManufacturer.value });
    };

    var defaultProduct = { name: localize.getLocalizedString("_AddProductCategory_"), value: null };
    $scope.productCategories = [
      defaultProduct
    ];

    $scope.selectedProductCategoriesWrapper = {};
    $scope.selectedProductCategoriesWrapper.selectedProductCategories = $scope.productCategories[0];
    $scope.concreteProductCategories = [];

    // This part handles with the creation on new product categories
    $scope.newCategoriesToCreate = [];
    $scope.addNewCategoryAction = function (category) {
      $scope.newCategoriesToCreate.push({
        name: category.name,
        id: category.categoryValue,
        parentId: category.parentId
      });
    };

    // This part creates a proxy so the product categories can be loaded dynamically.
    $scope.productsProxy = Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory(false, defaultProduct).entity("product");

    $scope.removeProductCategory = function (category) {
      $scope.concreteProductCategories.remove(category);
    };


    $scope.availableIngredientCustomModelFormats = [
      { name: localize.getLocalizedString("_WithoutExtraFormattedValue_"), value: null },
      { name: localize.getLocalizedString("_DecimalNumberFormat_"), value: 'decimal' }
    ];

    $scope.customIngredientFormat = {};
    $scope.customIngredientFormat.value = $scope.availableIngredientCustomModelFormats[0];


    function generateProduct() {
      var product = $.extend({}, $scope.productOrService);
      product.EntityType = "ProductMetadata";
      delete product.Duration;
      product.SelectedManufacturerId = product.SelectedManufacturer.value;
      delete product.SelectedManufacturer;
      product.IngredientOnly = isProductAsIngredient;

      product.CategoriesToCreate = $scope.newCategoriesToCreate;
      product.ManufacturersToCreate = $scope.newManufacturers;
      product.SelectedCategories = $scope.concreteProductCategories.map(function (category) {
        return angular.isDefined(category.categoryValue) ? { id: category.categoryValue } : { id: category.id }
      });

      // clear invisible fields
      if (isProductAsIngredient) {
        if (!chargeForIngredients) {
          product.Price = 0;
        }
      }

      return product;
    }

    function generateExternalVoucher() {
      var externalVoucher = $.extend({}, $scope.productOrService);
      externalVoucher.EntityType = "ExternalVoucherMetadata";
      delete externalVoucher.Duration;
      delete externalVoucher.SelectedManufacturer;
      delete externalVoucher.Profit;
      delete externalVoucher.Quantity;
      delete externalVoucher.dirty;
      externalVoucher.Color = $scope.serviceColorWrapper.Color;

      if (!externalVoucher.DefaultImage) {
        delete externalVoucher.DefaultImage;
      }

      externalVoucher.ServiceMetadatas = $scope.voucherServices.map(function (voucher) {
        return voucher.value;
      });

      $scope.vendors.forEach(function (vendor) {
        if (vendor.value < 0) {
          externalVoucher.VendorsToCreate = externalVoucher.VendorsToCreate || [];
          externalVoucher.VendorsToCreate.push({ id: vendor.value, name: vendor.name });
        }
      });

      if ($scope.selectedVendorWrapper.selected.value.ExternalVoucherVendorId > 0) {
        externalVoucher.SelectedExternalVoucherVendorId = $scope.selectedVendorWrapper.selected.value.ExternalVoucherVendorId;
      } else {
        externalVoucher.SelectedExternalVoucherVendorId = $scope.selectedVendorWrapper.selected.value
      }

      return externalVoucher;
    }

    function clearDialog() {
      $scope.productOrService.Cost = 0;
      $scope.productOrService.Price = 0;
      $scope.productOrService.Duration = "00:30";
      $scope.productOrService.WaitingAfter = "00:00";
      $scope.productOrService.Title = "";
      delete $scope.suggestedSelectedImageUrl;
      delete $scope.suggestedImages;
      $scope.clearImageDelegate.invoke();
    }


    function validateProduct() {

      if ($scope.productOrService.Title.length === 0) {
        toastr.warning(localize.getLocalizedString("_ProductName_") + " " + localize.getLocalizedString("_Required_"));
        return false;
      }
      if ($scope.productOrService.Title.length > 30) {
        toastr.warning(localize.getLocalizedString("_ProductNameMaxLengthIs_") + " 30 " + localize.getLocalizedString("_Characters_"));
        return false;
      }
      // if ($scope.productOrService.Cost <= 0) {
      //   if (!(isProductAsIngredient && !chargeForIngredients)) {
      //     toastr.warning(localize.getLocalizedString("_Cost_") + " " + localize.getLocalizedString("_Required_"));
      //     return false;
      //   }
      // }
      if ($scope.productOrService.Price <= 0) {
        if (!isProductAsIngredient) {
          toastr.warning(localize.getLocalizedString("_RetailPrice_") + " " + localize.getLocalizedString("_Required_"));
          return false;
        }
      }
      if ($scope.productOrService.Quantity <= 0 && !isProductAsIngredient && !configurations.ProductsAndServicesSettings.IgnoreInventory) {
        toastr.warning(localize.getLocalizedString("_Quantity_") + " " + localize.getLocalizedString("_Required_"));
        return false;
      }

      if ($scope.concreteProductCategories.length === 0) {
        toastr.warning(localize.getLocalizedString("_AtLeastOneCategoryRequired_"));
        return false;
      }

      return true;
    }

    var defaultService = { name: localize.getLocalizedString("_AddServiceCategory_"), value: null };
    /******************************* Service part *******************************/

    $scope.serviceCategories = [
      defaultService
    ];
    $scope.selectedServiceCategoriesWrapper = {};
    $scope.selectedServiceCategoriesWrapper.selectedServiceCategories = $scope.serviceCategories[0];
    $scope.servicesProxy = Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory(false, defaultService).entity("service");

    $scope.concreteServiceCategories = [];
    $scope.removeServiceCategory = function (category) {
      $scope.concreteServiceCategories.remove(category);
    };

    // Showing and hiding the ingredients part.
    $scope.isIngredientsMode = false;
    $scope.toggleIngredientsMode = function () {
      $scope.isIngredientsMode = !$scope.isIngredientsMode;
      registerScrollEvent($scope.isIngredientsMode);
    };

    $scope.serviceColorWrapper = {};
    $scope.serviceColorField = {
      fieldName: 'Color', icon: "icon icon-color", placeholder: localize.getLocalizedString("_ServiceColor_"),
      validation: "{'color': true }", type: 'color'
    };

    /***** Form related to service *****/
    $scope.possibleForms = [{ name: localize.getLocalizedString('_PickForm_'), value: null }]
    $scope.selectedForm = { valueWrapper: $scope.possibleForms[0] };

    Repository.Custom('Forms').getForms().then((forms) => {
      forms.forEach(({ name, _id }) => $scope.possibleForms.push({ name, value: _id }));
    }).catch(e => toastr.error(e.message));

    /* Service Ingredients. */
    $scope.ingredientCategories = [];
    $scope.removeIngredientCategory = function (category) {
      $scope.ingredientCategories.remove(category);
    };

    $scope.ingredients = [
      { name: localize.getLocalizedString("_AddIngredientCategory_"), value: null }
    ];
    $scope.selectedIngredientWrapper = {};
    $scope.selectedIngredientWrapper.selectedIngredient = $scope.ingredients[0];
    $scope.ingredientSelected = function (category) {
      if ($scope.ingredientCategories.indexOf(category) < 0) { // doesn't already exists.
        if ($scope.ingredientCategories.length === k_maxNumberOfCategories) {
          toastr.warning(localize.getLocalizedString("_MaxCategoriesNumberIs_") + k_maxNumberOfCategories);
        } else {
          $scope.ingredientCategories.push(category);
        }
      }
      $scope.selectedIngredientWrapper.selectedIngredient = $scope.ingredients[0];
    };

    /* Composed Services. */
    $scope.composedServicesProxy = Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory(true).entity("service");
    $scope.composedServices = [];
    $scope.removeComposedService = function (service) {
      $scope.composedServices.remove(service);
    };

    $scope.services = [
      { name: localize.getLocalizedString("_AddComposedService_"), value: null }
    ];

    $scope.selectedComposedServicesWrapper = {};
    $scope.selectedComposedServicesWrapper.selectedComposedService = $scope.services[0];
    $scope.composedServiceSelected = function (composedService) {
      var copyOfComposedServices = Object.assign({}, composedService);
      delete copyOfComposedServices.$$hashKey;
      $scope.composedServices.push(copyOfComposedServices);
      $scope.selectedComposedServicesWrapper.selectedComposedService = $scope.services[0];
    };


    function generateService() {
      var service = $.extend({}, $scope.productOrService);
      service.EntityType = "ServiceMetadata";
      delete service.Quantity;
      delete service.SelectedManufacturer;
      delete service.Cost;
      service.CategoriesToCreate = $scope.newCategoriesToCreate;
      service.Color = $scope.serviceColorWrapper.Color;
      service.SelectedCategories = $scope.concreteServiceCategories.map(function (category) {
        return angular.isDefined(category.categoryValue) ? { id: category.categoryValue } : { id: category.id }
      });

      service.SelectedIngredientCategories = $scope.ingredientCategories.map(function (ingredientCategory) {
        return angular.isDefined(ingredientCategory.categoryValue) ? { id: ingredientCategory.categoryValue } : { id: ingredientCategory.id }
      });

      service.ComposedServiceMetadatas = $scope.composedServices.map(function (serviceMetadata) {
        return serviceMetadata.value;
      });

      if ($scope.selectedForm.valueWrapper.value) {
        service.FormId = $scope.selectedForm.valueWrapper.value;
      }

      return service;
    }

    function validateService() {

      if ($scope.productOrService.Title.length === 0) {
        toastr.warning(localize.getLocalizedString("_ServiceName_") + " " + localize.getLocalizedString("_Required_"));
        return false;
      }
      if ($scope.productOrService.Title.length > 30) {
        toastr.warning(localize.getLocalizedString("_ServiceNameMaxLengthIs_") + " 30 " + localize.getLocalizedString("_Characters_"));
        return false;
      }
      if ($scope.productOrService.Price <= 0) {
        toastr.warning(localize.getLocalizedString("_RetailPrice_") + " " + localize.getLocalizedString("_Required_"));
        return false;
      }
      if (!/^\d{0,2}(\:\d{0,2}){0,1}$/.test($scope.productOrService.Duration)) {
        toastr.warning(localize.getLocalizedString("_Duration_") + " " + localize.getLocalizedString("_BadFormat_"));
        return false;
      }
      if ($scope.concreteServiceCategories.length === 0) {
        toastr.warning(localize.getLocalizedString("_AtLeastOneCategoryRequired_"));
        return false;
      }

      return true;
    }

    function validateExternalVoucher() {

      if ($scope.productOrService.Title.length === 0) {
        toastr.warning(localize.getLocalizedString("_VoucherName_") + " " + localize.getLocalizedString("_Required_"));
        return false;
      }
      if ($scope.productOrService.Title.length > 30) {
        toastr.warning(localize.getLocalizedString("_VoucherNameMaxLengthIs_") + " 30 " + localize.getLocalizedString("_Characters_"));
        return false;
      }

      if ($scope.productOrService.Price <= 0) {
        if (!isProductAsIngredient) {
          toastr.warning(localize.getLocalizedString("_RetailPrice_") + " " + localize.getLocalizedString("_Required_"));
          return false;
        }
      }

      if (!$scope.selectedVendorWrapper.selected.value) {
        toastr.warning(localize.getLocalizedString("_VendorIsAMandatoryField_"));
        return false;
      }

      return true;
    }

    $scope.isValid = false;
    $timeout(function () {
      $scope.isValid = true;
    }, 500);

    $scope.ingredientsComboWrapper = {};

    function registerScrollEvent(isToRegister) {
      var scrollEvents = "wheel.addProductDialog mousewheel.addProductDialog scroll.addProductDialog";
      if (isToRegister) {
        $(document).on(scrollEvents, scrollOccurred);
      } else {
        $(document).off(scrollEvents, scrollOccurred);
      }
    }

    function scrollOccurred(e) {
      if (!$scope.ingredientsComboWrapper.el.is(e.target) &&
        $(e.target).closest($scope.ingredientsComboWrapper.el).length < 1) {// the scroll did not occur on the combobox..
        $scope.$evalAsync($scope.toggleIngredientsMode);
      }
    }


    $scope.suggestImages = { suggest: true };
    $scope.$watch('productOrService.Title', function (newVal) {
      if (!$scope.suggestImages.suggest || $scope.uploadedImage) {
        return;
      }

      // It won't search in case the image has already been selected.
      if (newVal && !$scope.suggestedSelectedImageUrl) {
        searchImages.set(newVal);
      }
    });

    $scope.selectThumbUrl = function (img) {
      // Unselect so you can search again.
      if (img.thumbnailUrl == $scope.suggestedSelectedImageUrl) {
        delete $scope.suggestedSelectedImageUrl;
      } else {
        $scope.suggestedSelectedImageUrl = img.thumbnailUrl;
      }
    };

    var searchImages = new DelayedFunction(cognitiveSearch);

    function cognitiveSearch(searchPhrase) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', window.indexURI + "api/services?select=image&service=" + searchPhrase, true);
      xhr.withCredentials = true;
      xhr.send();
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          var response = JSON.parse(xhr.responseText);
          if (xhr.status === 200) {
            applyImagesSearch(response)
          } else {
            console.log('failed');
          }
        }
      }
    }

    function applyImagesSearch(result) {
      try {
        $scope.$evalAsync(function () {
          $scope.suggestedImages = result.results.map(function (imgUrl) {
            return { thumbnailUrl: encodeURI(imgUrl) }
          });
        })
      } catch (e) {
      }
    }

    //

  });
