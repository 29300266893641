import {extractErrorText} from '../../../common/common-js-functions/common-js-new';
import {isFunction} from "@uirouter/angularjs";


angular.module('easybizy.easybizyModalViewsModel').service('digitalInvoiceCommon',
  function (localize, confirmService, validator, $rootScope, Repository, printerMediator, $http, $timeout, modalCreator, $modal) {
    const promptSendingInvoice = (title, subtitle, placeholder, validateDelegate, action, defaultValue) => {
      confirmService.prompt(
        /*title*/localize.getLocalizedString(title),
        /*subtitle*/localize.getLocalizedString(subtitle),
        /*placeholder*/localize.getLocalizedString(placeholder),
        /*onOk*/function (updatedValue) {
          action(updatedValue);
        },
        /*On cancel*/
        function () {
        },
        /*Validate*/
        function (value) {
          return validateDelegate(value);
        }, /*DEFAULT VALUE*/defaultValue,
        /*Ok text*/localize.getLocalizedString('_Send_'));
    }

    const validatePhone = this.validatePhone = (value) => {
      const validationResult = value.length > 0 && validator.number.validate(value.replace(/-/gi, ''));
      return !validationResult ? localize.getLocalizedString('_InvalidPhoneNumber_') : null;
    };

    const validateEmail = (value) => {
      let validationResult = value.length > 0;
      if (validationResult) {
        for (let email of value.split(',')) {
          validationResult = validator.email.validate(email);
          if (!validationResult) {
            break;
          }
        }
      }


      return !validationResult ? localize.getLocalizedString('_WrongEmail_') : null;
    };


    const decorateAsyncSendingAction = (promise, callback, isPriceQuote) => {
      $rootScope.$emit('page-in-progress', true);
      promise
        .then(function () {
          $timeout(() => {
            $rootScope.$emit('page-in-progress', false);
          });

          toastr.success(localize.getLocalizedString(isPriceQuote ? '_PriceQuoteSent_' : '_InvoiceSent_'));

          if (callback) {
            callback(true);
          }
        })
        .catch(function ({data}) {
          $timeout(() => {
            $rootScope.$emit('page-in-progress', false);
          });

          const error = (data && data.firstErrorMessage) ? extractErrorText(data.firstErrorMessage) : data;
          toastr.error(localize.getLocalizedString(isPriceQuote ? '_ErrorSendingPriceQuote_' : '_ErrorSendingInvoice_') + ' - ' + error);

          if (callback) {
            callback(false);
          }
        });
    };

    const resolveType = (invoice) => {
      return invoice.entityType;
    }

    /**
     *
     * @param includeSMS weather or not to include the SMS action.
     * @param invoices
     * @param iCloseDelegate
     * { invoiceId,
     *   entityType,
     *   mobile,
     *   emailAddress,
     *   invoiceLink,
     *   receipt
     * }
     * @param isPriceQuote
     * @param supportsWhatsappApi
     * @return list of actions.
     */
    this.getActions = (includeSMS, invoices, iCloseDelegate, isPriceQuote = false, supportsWhatsappApi = false) => {
      const closeDelegate = iCloseDelegate || (() => {
      });

      let toReturn = [
        {
          icon: 'icon-whatsapp',
          tooltip: '_SendByWhatsapp_',
          className: 'digital-invoice-short-action--whatsapp',
          delegate: () => {
            promptSendingInvoice('_SendByWhatsapp_', '_PhoneToSend_', '_Mobile_', validatePhone, (phone) => {
              sendInvoicesBySMS(invoices, phone, () => {
              }, supportsWhatsappApi);
            }, invoices[0].mobile || '');
          }
        },
        {
          icon: 'icon-sms', tooltip: '_SendBySms_', className: 'digital-invoice-short-action--sms', delegate: () => {
            promptSendingInvoice('_SendBySms_', '_PhoneToSend_', '_Mobile_', validatePhone, (phone) => {
              sendInvoicesBySMS(invoices, phone);
            }, invoices[0].mobile || '');
          }
        },
        {
          icon: 'icon-mail-outline',
          tooltip: '_SendByEmail_',
          className: 'digital-invoice-short-action--email',
          delegate: () => {
            promptSendingInvoice('_SendByEmail_', '_Email_', '_Email_', validateEmail, (email) => {
              const promises = invoices.map((invoice) => sendInvoice('mail', invoice.invoiceId, email, resolveType(invoice)));
              decorateAsyncSendingAction(Promise.all(promises), closeDelegate, isPriceQuote);
            }, invoices[0].emailAddress || '');
          }
        },
        {
          icon: 'icon-whatsapp-web',
          tooltip: '_SendByWhatsappWeb_',
          className: 'digital-invoice-short-action--whatsapp',
          delegate: () => {
            promptSendingInvoice('_SendBySms_', '_PhoneToSend_', '_Mobile_', validatePhone, (phone) => {
              let invoiceText = '';
              const localizedString = localize.getLocalizedString(!isPriceQuote ? '_SendWhatsappInvoiceText_' : '_SendPriceQuoteText_');
              if (invoices.length === 1) {
                invoiceText = `${localizedString} ${invoices[0].invoiceLink + '/open?method=whatsapp'}`;
              } else {
                invoiceText = `${localizedString}`;
                invoiceText += invoices.map((invoice) => invoice.invoiceLink + '/open?method=whatsapp').join(' ');
              }

              const link = `https://api.whatsapp.com/send?phone=972${phone.indexOf('0') === 0 ? phone.substr(1) : phone}&text=${invoiceText}`;
              openLinkAndFocus(link);
              $timeout(() => closeDelegate())
            }, invoices[0].mobile || '');
          }
        },
        {
          icon: 'icon-open-new-tab', tooltip: '_OpenPdf_', className: 'digital-invoice-short-action--pdf',
          delegate: () => {
            invoices.forEach((invoice) => {
              openLinkAndFocus(`${invoice.invoiceLink}/open?method=open-pdf`);
            })
            $timeout(() => closeDelegate())
          }
        },
        {
          icon: 'icon-printer',
          tooltip: '_PrintA4_',
          className: 'digital-invoice-short-action--print',
          delegate: () => {
            $rootScope.$emit('page-in-progress', true);
            const promises = invoices.map((invoice) => {
              return $http({
                url: `${invoice.invoiceLink}/html`,
                cache: false
              })
            })

            Promise.all(promises).then((results) => {
              results.forEach((res, idx) => {
                printerMediator.printDirectHtml(res.data);
              })

              $timeout(() => {
                $rootScope.$emit('page-in-progress', false);
                closeDelegate();
              });
            }).catch((err) => {
              $timeout(() => {
                $rootScope.$emit('page-in-progress', false);
              });

              toastr.error(err.body ? err.body.message : err.message);
            })

          }
        },
        {
          icon: 'icon-thermal-printer',
          tooltip: '_PrintThermalPrinting_',
          className: 'digital-invoice-short-action--print-thermal',
          delegate: () => {
            const promises = invoices.map((invoice) => {
              return Repository.Custom('CashRegister').invoiceDemand(invoice.invoiceLink.split('/').pop(), 'WebClient')
                .catch(err => toastr.error(err.Message));
            })

            Promise.all(promises).then(() => {
              invoices.forEach((invoice) => {
                printerMediator.print(invoice.receipt)
                if (Array.isArray(invoice.otherDocumentsToPrint) && invoice.otherDocumentsToPrint.length > 0) {
                  invoice.otherDocumentsToPrint.forEach((toPrint) => printerMediator.print(toPrint));
                }
              });

              if (isFunction(closeDelegate)) {
                closeDelegate();
              }
            });
          }
        },
      ]

      if (!supportsWhatsappApi) {
        toReturn.splice(0, 1);
      }

      if (!includeSMS) {
        toReturn.splice(0, 1);
      }

      if (isPriceQuote) {
        toReturn.pop();
        toReturn.pop();
      }

      return toReturn;
    };

    const sendInvoice = (Method, InvoiceId, To, Type) => {
      return Repository.Custom('CashRegister').sendInvoice({
        Method,
        InvoiceId,
        To,
        Type
      });
    };

    const openLinkAndFocus = (url) => {
      let openedWindow = window.open(url, '_blank');
      if (openedWindow) {
        openedWindow.focus();
      }
    };

    const sendInvoicesBySMS = this.sendInvoicesBySMS = (invoices, phone, callback, isWhatsapp = false) => {
      const promises = invoices.map((invoice) => sendInvoice(isWhatsapp ? 'whatsapp' : 'sms', invoice.invoiceId, phone, resolveType(invoice)));
      decorateAsyncSendingAction(Promise.all(promises), callback, invoices.some((x) => x.entityType === 'PriceQuote'));
    };

    this.sendPriceQuoteDialog = (priceQuoteEntity, finishPriceQuotingDelegate) => {
      const title = localize.getLocalizedString("_SendPriceQuote_");
      const modalContent = modalCreator.embedInModel(title,
        `<div class="relative-container">
                    <digital-invoice-sender invoice-result="invoiceResult" customer="result.Customer"
                                  close-delegate="doneSendingPriceQuote()"
                                  loading-wrapper="isSendingInvoice" />
            </div>`
        , "no-transition-modal");

      const opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: modalContent,
        controller: function ($scope, $modalInstance, $state, $timeout) {
          $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
            finishPriceQuotingDelegate();
          };

          const {PriceQuoteId, DocumentToken, MailTo, Customer} = priceQuoteEntity.PriceQuote;
          const link = `${invoiceURI}/price-quotes/${DocumentToken}`;

          $scope.invoiceResult = {
            isPriceQuote: true,
            Customer,
            items: [{
              InvoiceId: PriceQuoteId,
              EntityType: 'PriceQuote',
              EmailAddress: MailTo,
              DigitalInvoiceLink: link,
              Token: DocumentToken,
              Receipt: priceQuoteEntity.Html
            }]
          };

          $scope.doneSendingPriceQuote = function () {
            $modalInstance.dismiss('cancel');
            $timeout(function () {
              finishPriceQuotingDelegate();
            }, 300);
          };
        }
      };

      $modal.open(opts);
    }

  });
