'use strict';

(function () {
  angular.module('easybizy.common.common-services').service('pci', ['Repository', 'localize', 'configuration', 'confirmService', '$q',
    function (Repository, localize, configuration, confirmService, $q) {
      const terminals = [];
      let pinPads = [];
      let customTerminalsOrPinPads = false;
      this.printCreditVoucher = false;
      let supportsWebPayments = null;
      let supportsWebPaymentsDeferred = null;

      try {
        const configurations = configuration.get();
        this.printCreditVoucher = configurations.InvoiceSettings.TypeOfInvoice.Value === 'receipt'
          && configurations.InvoiceSettings.PrintingDefaultType.Value === 'pos' && !configurations.InvoiceSettings.PreventCreditVoucher;
      } catch (e) {
        /// Credit Voucher printing won't work.
      }


      const options = {
        useCreditCardToken: false,
        creditCardDetails: null,
        customerId: null,
        numOfPayments: 1,
        creditPayments: false,
        amount: 0, /// MANDATORY
      };

      this.PHONE_AUTH_REQUIRED_ERROR_CODE = 420;

      this.generateTransaction = (params) => {
        return {...options, ...params};
      };

      this.chargeCreditCard = (transaction) => {
        return Repository.Custom('PCI').charge(transaction);
      };

      this.getCustomerToken = (customerId, customCreditCardToken) => {
        return Repository.Custom('PCI').customerToken(customerId, customCreditCardToken);
      }

      this.deleteCreditToken = (customerId) => {
        return Repository.Custom('PCI').deleteCustomerToken(customerId);
      }

      this.convertToToken = (creditCardToken, customerId) => {
        return Repository.Custom('PCI').convertToToken(creditCardToken, customerId);
      };

      this.cancelPayment = (paymentId) => {
        return Repository.Custom('PCI').cancel(paymentId);
      };

      this.extractError = (err) => {
        let safeError = Object.getSafely(['data', 'localized_error', localize.isHebrew() ? 'he' : 'en'], err);
        const customText = Object.getSafely(['data', 'message'], err);
        if (safeError && customText && customText.hasHebrew()) {
          safeError += ` ${customText}`;
        }

        return safeError || customText || err.statusText;
      };

      /**
       * This method keeps manual card changes so it can be used if the dialog was closed before usage.
       * @param cardDetails
       */
      let cardDetails;
      this.saveManualCardDetailsInMemory = (iCardDetails) => {
        cardDetails = iCardDetails;
      }

      Object.defineProperty(this, 'terminals', {
        get() {
          return [...terminals];
        }
      })

      Object.defineProperty(this, 'pinPads', {
        get() {
          return [...pinPads];
        }
      })

      Object.defineProperty(this, 'customTerminalsOrPinPads', {
        get() {
          return customTerminalsOrPinPads;
        }
      });

      Object.defineProperty(this, 'savedCardDetails', {
        get() {
          return cardDetails;
        }
      });

      this.supportsWebPayments = () => {
        if (supportsWebPayments != null) {
          return {
            then: (func) => func(supportsWebPayments)
          }
        }

        supportsWebPaymentsDeferred = supportsWebPaymentsDeferred || $q.defer();
        return supportsWebPaymentsDeferred.promise;
      }

      this.confirmPromptingCreditCardAndResendIfAuthorized = (chargeOnPrompt) => {
        confirmService.confirm(localize.getLocalizedString('_ShouldInputAuthNumberFromCreditCompany_'),
          null,
          function () {
            confirmService.prompt(
              /*title*/localize.getLocalizedString('_CCAuthNumber_'),
              '', '',
              /*onOk*/function () {
                chargeOnPrompt();
              },
              function () {
              },
              function (value) {
                return value.replace(/\D/g, '') === value ? null : localize.getLocalizedString('_AuthNumberShouldContainOfNumbersOnly_');

              },
            );
          },
          function () {
          }, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_')
        );
      }


      Repository.Custom('PCI').terminalsAndPinpads()
        .then(function (data) {
          if (data.terminals) {
            data.terminals.forEach((terminal) => {
              const terminalPinPads = terminal.pinPads.map(({name, number}) => ({
                name: name,
                value: number
              }));

              pinPads = pinPads.concat(terminalPinPads);

              terminals.push({value: terminal.name, name: terminal.name, pinPads: terminalPinPads});

              if (terminal.supportsWebPayments) {
                supportsWebPayments = true;
              }
            })

            resolveSupportsWebPaymentsPromise();
          }
        })
        .catch(function (err) {
          if (err.status !== 404) {
            toastr.error(localize.getLocalizedString("_ErrorLoadingPinPadsOrTerminal_"));
          }

          supportsWebPayments = false;
          resolveSupportsWebPaymentsPromise();
        });

      function resolveSupportsWebPaymentsPromise() {
        supportsWebPaymentsDeferred?.resolve(supportsWebPayments);
      }
    }]
  );
}());
