import './feedback.directive.less'

angular.module('easybizy.common.toolbar.actions').directive('feedback', ['Repository', 'localize', '$rootScope', '$timeout', function (Repository, localize, $rootScope, $timeout) {
  var elementString = '<div class="feedback-mask absolute-container"></div>';
  var maskElement = $(elementString);
  maskElement.css('display', 'none');
  maskElement.appendTo('body');

  return {
    restrict: 'E',
    replace: true,
    scope: {
      show: '='
    },
    link: function (scope, element, attrs) {
      scope.feedback = {};
      scope.feedback.comment = "";

      scope.feedbackTypes = [
        { name: localize.getLocalizedString("_Positive_"), value: 'positive' },
        { name: localize.getLocalizedString("_Negative_"), value: 'negative' },
        { name: localize.getLocalizedString("_ER_"), value: 'ER' }
      ];

      scope.feedback.type = scope.feedbackTypes[0];
      scope.root = $rootScope;

      $rootScope.$on('$routeChangeSuccess', function (event, current, previous) {
        scope.show = false;
      });

      maskElement.on('click', function () {
        scope.show = false;
      });


      scope.$watch('show', function (newVal) {
        maskElement.css('display', (newVal ? 'block' : 'none'));
      });


      scope.isSubmitting = false;

      $timeout(function () {
        scope.didLoad = true;
      });

      scope.submitFeedback = function () {
        scope.feedback.location = $rootScope.viewName;
        if (scope.feedback.comment.length < 3) {
          toastr.warning(localize.getLocalizedString("_FillFeedbackText_"));
          return;
        }

        scope.isSubmitting = true;

        var feedback = {
          Content: scope.feedback.comment,
          Location: scope.feedback.location,
          Type: scope.feedback.type.value
        };

        Repository.Custom("Feedback").submit(feedback)
          .then(function (result) {
            scope.isSubmitSuccess = true;
            $timeout(function () {
              scope.show = false;
              resetDialog();
            }, 4000);

            scope.isSubmitting = false;
          }).catch(function (error) {
          toastr.error(localize.getLocalizedString("_CouldntSubmitFeedback_"));
          scope.isSubmitting = false;
        });
      };

      function resetDialog() {
        scope.isSubmitSuccess = false;
        scope.feedback.comment = "";
        scope.feedback.type = scope.feedbackTypes[0];
      }
    },
    template: require('./feedback.directive.tpl.html')
  };
}]);
