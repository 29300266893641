'use strict';

const {isNonEmptyString} = require("@tomeravni/easybizy-js-common/common");
exports.nonEmptyString = string => typeof (string) === 'string' && string.trim().length > 0;

exports.extractErrorText = (err) => {
  if (!err) {
    return null;
  }

  return Object.getSafely(['output', 'payload', 'message'], err);
}

exports.isEmptyString = (val) => typeof val === 'string' && val.trim().length === 0;

exports.isUndefined = (obj) => obj === void 0;

exports.isConcreteValue = (val) => typeof val !== 'undefined' && val !== null && !exports.isEmptyString(val);

exports.valueOrDefault = (val, ...defaultValue) =>
  exports.isConcreteValue(val) ? val : defaultValue.find((x) => !exports.isUndefined(x));

exports.isNumber = (value) => typeof value === 'number' && !isNaN(value);

exports.wrapJSONParse = (val, defaultValue = null) => {
  if (!isNonEmptyString(val)) {
    return defaultValue;
  }

  try {
    return JSON.parse(val);
  } catch (e) {
    return defaultValue;
  }
}
