<template>
  <div>
    <Grid :dataSource="dataSource" v-bind="gridProps" ref="grid"></Grid>
  </div>
</template>

<script>
import { ButtonsGroup, Grid, GridLabels } from "@tomeravni/easybizy-vue-components";
import OnlineBookingServicesDS from "./online.scheduling.services-ds";
import {resolveDefaultGridLabels} from "../../../vue/vue.helpers";

export default {
  components: {
    Grid,
  },
  props: {
    mediator: {
      type: Object
    }
  },
  data() {
    const labels = resolveDefaultGridLabels(this.$t);

    const api = this.Repository.Entity("OnlineBookingServiceMetadata").query().expand("ServiceMetadatas");
    api.pageSize = 30;

    const dataSource = new OnlineBookingServicesDS(api, this.$t, this.$stateManager, this.mediator, this.Repository, this.confirmService);
    dataSource.on('modelChange', ({ column, entity, prevValue, newValue, validationErrors }) => {
      const change = `${column.fieldName}: ${JSON.stringify(newValue)} (Previous: ${JSON.stringify(prevValue)})` +
        (validationErrors ? `, errors: ${validationErrors || ''}` : '');
      if (newValue === prevValue) {
        return;
      }

      let adaptedFieldName = column.fieldName;
      this.Repository.Entity("OnlineBookingServiceMetadata").patch(entity.OnlineBookingServiceMetadataId, { [adaptedFieldName]: newValue }).post().then((res) => {
      }).catch((err) => {
        this.toastr.error(this.$t("_ErrorUpdatingItems_"));
      });
    });

    return {
      dataSource,
      gridProps: {
        freezeHeader: true,
        hideBorder: false,
        elevated: true,
        pickColumns: true,
        rowHeightMode: 'tight',
        backgroundColor: 'auto',
        preventScrollTopButton: false,
        enableFilter: true,
        isFilterVisible: true,
        forceFullHeight: true,
        labels
      }
    }
  },
  computed: {
    saveEnabled() {
      return getSafely(['dataSource', 'selectionCount'], this, 0) > 0;
    }
  },
  methods: {
  }
}
</script>
