import './cash.register.daily.actions.modal.less'
import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT, RTL_DATE_TIME_DISPLAY } from "../../constants";
import './cash.register.daily.actions.service'
import { getPropertyValue } from "../../general/helpers";

angular.module('easybizy.easybizyModalViewsModel').controller('CashRegisterDailyActionsModalController',
  function ($scope, $modalInstance, dayClosed, localize, $timeout, Repository, printerMediator, configuration, employees, cashRegisterDailyActions) {
    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    const configurations = configuration.get();
    var change = configurations.CashRegisterSettings.ChangeInCashRegister;
    $scope.showEmployees = configurations.CashRegisterSettings.ShowDailyActionsByEmployees;
    $scope.disableChangingEmployees = false;
    $scope.preventShowOpenShift = configurations.EmployeesSettings.PreventClosingEmployeeShiftsOnZAction;
    $scope.changeInCashRegister = change ? (''.toCurrencySymbol(window.k_Currency) + change.Value) : 0;
    $scope.reports = [];
    $scope.isLoading = true;
    $scope.employees = [{ name: localize.getLocalizedString("_AllEmployees_"), value: null }];
    $scope.selectedEmployee = {};
    $scope.selectedEmployee.selected = $scope.employees[0];

    if ($scope.showEmployees) {
      configuration.loggedInUserAsPromise.then((res) => {
        if (res.rootUser) {
          employees.resolve($scope.employees);
        } else {
          $scope.disableChangingEmployees = true;
          employees.resolve($scope.employees, $scope.selectedEmployee);
        }
        loadData();
      })
    } else {
      loadData();
    }

    $scope.employeeSelected = function (value) {
      $scope.selectedEmployee.selected = value;
      loadData();
    };

    function loadData() {
      $scope.reports = [];
      $scope.total = {};
      $scope.monthlyIncome = {};
      $scope.isLoading = true;
      cashRegisterDailyActions.xReportData($scope.selectedEmployee?.selected?.value).then(function (data) {
        angular.forEach(data.results[0], function (value, key) {
          switch (key) {
            case "Total":
              $scope.total = { key: localize.getLocalizedString("_" + key + "_"), value: value.toMoney() };
              break;
            case "MonthlyIncome":
              $scope.monthlyIncome = { key: localize.getLocalizedString("_" + key + "_"), value: value && value.toMoney() };
              break;
            default:
              $scope.reports.push({ key: localize.getLocalizedString("_" + key + "_"), value: value });
          }
        });

        $scope.isLoading = false;
      });
    }

    $scope.closeShiftText = localize.getLocalizedString('_CloseShift_');

    $scope.wasApproved = false;
    $scope.closeShift = function () {
      if (!$scope.wasApproved) {
        $scope.wasApproved = true;
        $scope.closeShiftText = localize.getLocalizedString('_SureCannotBeUndone_');
        if ($scope.checkedInEmployees && !$scope.preventShowOpenShift) {
          $scope.showOpenEmployeeShift = true;
        } else {
          setTimerForToggleCloseShiftMode();
        }
      } else {
        zReport();
      }
    };


    /*Custom history Z*/
    $scope.printX = xReport;
    $scope.isVisibleHistoryXWrapper = {};
    $scope.isVisibleHistoryXWrapper.isVisible = false;
    $scope.customDatePicker = moment().format(DATE_DISPLAY_FORMAT);
    $scope.toggleCustomDate = function () {
      $scope.isVisibleHistoryXWrapper.isVisible = !$scope.isVisibleHistoryXWrapper.isVisible;
    };

    $scope.historyXSelected = function (date) {
      $scope.isDoingAction = true;
      $scope.isDoingHistoryX = true;
      $timeout($scope.toggleCustomDate, 100);
      const final = () => {
        $scope.isDoingAction = false;
        $scope.isDoingX = false;
        $scope.isDoingHistoryX = false;
      };

      Repository.Custom("CashRegister").xReport(date.format(ODATA_DATE_ONLY_FORMAT))
        .then(function (data) {
          if (data.results) {
            printerMediator.print(data.results[0].Receipt);
          } else {
            angular.forEach(data, function (value, key) {
              printerMediator.print(value.Receipt);
            });
          }

          final();
          $scope.cancel();

        })
        .catch(function (errorMessage) {
          final();
          toastr.error(localize.getLocalizedString(getPropertyValue(errorMessage, 'data.Message')));
        })
    };

    $timeout(hasOpenEmployeeShifts, 500);
    $scope.showOpenEmployeeShift = false;

    $scope.checkAllOut = function () {
      $scope.checkingAllOut = true;
      Repository.Custom("EmployeeHours").checkAllOut()
        .then(function () {
          $scope.checkedInEmployees = null;
          toastr.success(localize.getLocalizedString("_AllEmployeesWereCheckedOut_"));
          $scope.checkingAllOut = false;
          $scope.closeOpenShiftsDialog();
        })
        .catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorClosingShifts_", err.Message));
          $scope.checkingAllOut = false;
          $scope.closeOpenShiftsDialog();
        })
    };


    $scope.closeOpenShiftsDialog = function () {
      $scope.showOpenEmployeeShift = false;
      $scope.preventShowOpenShift = true;
      setTimerForToggleCloseShiftMode();
    };

    $scope.openDraw = function () {
      printerMediator.print(moment().format(RTL_DATE_TIME_DISPLAY), true);
    };

    function setTimerForToggleCloseShiftMode() {
      $timeout(function () {
        $scope.wasApproved = false;
        $scope.closeShiftText = localize.getLocalizedString('_CloseShift_');
      }, 15000);
    }

    function hasOpenEmployeeShifts() {
      Repository.Custom("EmployeeHours").openShifts().then(function (result) {
        $scope.checkedInEmployees = result;
      });
    }

    function xReport() {
      $scope.isDoingX = true;
      $scope.isDoingAction = true;
      const final = () => {
        $scope.isDoingX = false;
        $scope.isDoingAction = false;
      };


      Repository.Custom("CashRegister").xReport()
        .then(function (data) {
          printerMediator.print(data.results[0].Receipt);
          final();
          $scope.cancel();
        })
        .catch(function (errorMessage) {
          final();
          toastr.error(localize.getLocalizedString(errorMessage.Message));
        })
    }

    function zReport() {
      $scope.isDoingZ = true;
      $scope.isDoingAction = true;
      cashRegisterDailyActions.zReport().then(function (data) {
        if (dayClosed) {
          dayClosed();
        }

        $scope.cancel();
        apply();
      }).catch(function (errMessage) {
        $scope.closeShiftText = errMessage;
        apply();
      });

      function apply() {
        $scope.isDoingZ = false;
        $scope.isDoingAction = false;
        $scope.wasApproved = false;
      }
    }

    $scope.todaySubscriptionUsages = [];
    if (configurations.CustomersSettings.EnableSubscriptionUsagesFromDailyActions) {
      Repository.Custom("CashRegister").subscriptionUnclosedUsages().then(function (result) {
        $scope.todaySubscriptionUsages = result;
      });
    }

    $scope.closeTodaySubscriptionUsages = function () {
      $scope.isClosingUsages = true;
      $scope.isDoingAction = true;
      var requestInProcess = false;
      $scope.numberOfUsagesToCreate = $scope.todaySubscriptionUsages.length;
      var toPost = [];
      angular.forEach($scope.todaySubscriptionUsages, function (usage) {
        var usagesToCreateInvoice = {
          TotalAmount: 0,
          TotalAmountBeforeDiscount: 0,
          SubscriptionUsages: [
            {
              SubscriptionId: usage.SubscriptionId,
              EmployeeId: usage.EmployeeId,
              ItemId: usage.ServiceMetadataId,
              ItemType: "ServiceMetadata",
              Quantity: 1
            }],
          Subscriptions: [],
          Items: [],
          CustomItems: [],
          PaymentsToCreate: [],
          InvoiceRefersTo: usage.CustomerName,
          CustomerId: usage.CustomerId,
          EmployeeId: 1,
          Currency: configurations.CashRegisterSettings.Currency.Value
        };
        toPost.push(usagesToCreateInvoice);
      });
      createUsage(toPost);
    };

    function createUsage(usages) {
      if (usages.length === 0) {
        toastr.success(localize.getLocalizedString("_AllUsagesWereCreated_"));
        $scope.isClosingUsages = false;
        $scope.isDoingAction = false;
        $scope.todaySubscriptionUsages = [];
        return;
      }

      var usage = usages.pop();
      Repository.Custom("CashRegister").createSubscriptionUsages(usage)
        .then(function () {
          createUsage(usages);
        })
        .catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorCreatingUsage_", usage.CustomerName));
        })
    }
  });
