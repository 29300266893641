angular.module('easybizy.easybizyModalViewsModel').directive('digitalInvoiceShort', function (validator, $timeout, confirmService, localize, $rootScope,
                                                                                              Repository, digitalInvoiceCommon, configuration) {

  let isWhatsappSupported = false;
  configuration.isValidWhatsappAccount().then((isValid) => {
    isWhatsappSupported = isValid;
  })

  return {
    restrict: 'E',
    replace: true,
    template: require('./digital.invoice.short.directive.tpl.html'),
    scope: {
      invoiceId: '=',
      expandable: '=',
      entityType: '='
    },
    link: function (scope) {
      scope.supportsWhatsapp = isWhatsappSupported;
      scope.invoice = null;
      scope.expandedWrapper = {expanded: false};

      if (!scope.expandable) {
        scope.$watch('invoiceId', (newVal, oldVal) => {
          if (newVal && newVal !== oldVal) {
            fetchInvoice();
          }
        })
      }

      scope.toggle = () => {
        scope.expandedWrapper.expanded = !scope.expandedWrapper.expanded;
        fetchInvoice()
      };

      scope.callAction = (action) => {
        if (scope.fetching) {
          return toastr.warning(localize.getLocalizedString('_StillFetching_'));

        }

        action.delegate()
      }

      function fetchInvoice() {
        scope.invoice = null;
        scope.fetching = true;
        const invoiceResultCall = scope.entityType === 'Receipt'
          ? Repository.Custom('CashRegister').receiptResult(scope.invoiceId)
          : (scope.entityType === 'TaxInvoice'
            ? Repository.Custom('CashRegister').taxInvoiceResult(scope.invoiceId)
            : Repository.Custom('CashRegister').invoiceResult(scope.invoiceId));

        invoiceResultCall.then((invoice) => {
          if (invoice.results && invoice.results.length > 0) {
            scope.fetching = false;
            scope.invoice = invoice.results[0];
          } else {
            throw localize.getLocalizedString('_EntityNotFound_');
          }
        }).catch(({data}) => {
          scope.fetching = false;
          toastr.error(data.MessageDetail || data.Message || data);
        });
      }

      scope.actions = digitalInvoiceCommon.getActions(true, [{
        get invoiceId() {
          return scope.invoice.InvoiceId;
        },
        get entityType() {
          return scope.invoice.EntityType;
        },
        get mobile() {
          return scope.invoice.MobileFirst;
        },
        get emailAddress() {
          return scope.invoice.EmailAddress;
        },
        get invoiceLink() {
          return scope.invoice.DigitalInvoiceLink;
        },
        get receipt() {
          return scope.invoice.Receipt;
        },
      }], null, false, isWhatsappSupported);

      if (scope.expandable) {
        scope.actions.reverse();
      }


    }
  };
});
