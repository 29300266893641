import { MAIN_MODULE } from "../constants";

MAIN_MODULE.factory('sessionRecoverer', ['$q', '$injector', '$rootScope', function ($q, $injector, $rootScope) {

  // MAX REFRESH TIME So we won't get into a deadlock
  var MAX_REFRESH_TIME = 10000;
  var duringRenewSession = false;
  var afterRenewConfigQueue = [];
  var autoRefreshCallback = null;
  var releaseInRefreshModeCallback = null;
  var sessionService = null;
  var failedToRefresh = false;

  var sessionRecoverer = {
    responseError: function (response) {
      // Session has expired
      if (response.status === 401) {
        if (failedToRefresh || response.config.tryCount > 1) {
          return;
        }

        const $http = $injector.get('$http');
        // Renew already requested.
        if (duringRenewSession) {
          var queuedDeferred = $q.defer();
          afterRenewConfigQueue.push(queuedDeferred);
          return queuedDeferred.promise
            .then(function () {
              return $http(response.config);
            }).catch(function (err) {
              return err;
            })
        } else {
          return refreshToken().then(function () {
            while (afterRenewConfigQueue.length > 0) {
              afterRenewConfigQueue.shift().resolve();
            }

            return $http(Object.assign(response.config, { tryCount: (response.config.tryCount || 0) + 1 }))
          }).catch(function (err) {
            return $q.reject(response);
          })
        }
      } else {
        return $q.reject(response);
      }
    }
  };

  $rootScope.$on('refresh-session', function (_) {
    refreshToken();
  });

  /**
   * Browsers tend to ignore network requests and therefore when you come back, refresh will be executed.
   */
  document.addEventListener("visibilitychange", function () {
    if (document.visibilityState === 'visible') {
      refreshToken();
      $rootScope.$broadcast('tabFocusChanged', { focus: true });
    } else {
      if (autoRefreshCallback) {
        clearTimeout(autoRefreshCallback);
        autoRefreshCallback = null;
      }
    }
  });


  return sessionRecoverer;

  function refreshToken() {
    duringRenewSession = true;
    sessionService = sessionService || $injector.get('sessionService');

    if (autoRefreshCallback) {
      clearTimeout(autoRefreshCallback);
      autoRefreshCallback = null;
    }

    clearReleaseInRefreshMode();
    releaseInRefreshModeCallback = setTimeout(function () {
      duringRenewSession = false;
    }, MAX_REFRESH_TIME);

    return sessionService.refresh().then(function (response) {
      console.log('refreshed successfully!');

      clearReleaseInRefreshMode();
      duringRenewSession = false;
      setAutoRefresh(response.ttl);
      return response;
    }).catch(function (err) {
      console.log('failed to refresh!');
      failedToRefresh = true;
      clearReleaseInRefreshMode();
      duringRenewSession = false;
      setLoginEndedMode();
      return err;
    });
  }

  function clearReleaseInRefreshMode() {
    if (releaseInRefreshModeCallback) {
      clearTimeout(releaseInRefreshModeCallback);
      releaseInRefreshModeCallback = null;
    }
  }

  /**
   * This method used the TTL value returned from the renew request to auto refresh token.
   * @param ttl
   */
  function setAutoRefresh(ttl) {
    // We don't auto refresh if ttl is less than a minute.
    if (!ttl || ttl < 60000) {
      return;
    }

    autoRefreshCallback = setTimeout(function () {
      console.log('auto refreshing!');
      refreshToken();
    }, Math.round(ttl * .95));
  }

  function setLoginEndedMode() {
    var message = "Login session ended. Redirecting to login...";

    try {
      var localize = $injector.get('localize');
      message = localize.getLocalizedString("_LoginEndedMessage_");
      if (localize.isRightToLeft()) {
        $(".easybizy-login-session-ended-text").css('direction', 'rtl');
      }
    } catch (e) {
      console.log(e);
    }

    $(".easybizy-login-session-ended-text-content").text(message);
    $(".easybizy-login-session-ended").css("display", 'block');
    setTimeout(function () {
      $(".easybizy-login-session-ended").css("opacity", '1');
    });

    setTimeout(function () {
      $(".splash-screen-wrapper").css("opacity", '0.1');
    }, 500);

    for (var i = 3; i > 0; i--) {
      setTextTimer(i, (4 - i) * 1000);
    }

    setTimeout(function () {
      window.location = window.logoutUrl;
    }, 4000);
  }

  function setTextTimer(value, time) {
    setTimeout(function () {
      $(".easybizy-login-session-ended-timer").text(value);
    }, time);
  }

}]);

MAIN_MODULE.config(['$httpProvider', function ($httpProvider) {
  $httpProvider.interceptors.push('sessionRecoverer');
}]);

MAIN_MODULE.service('sessionService', ['$q', 'sockets', function ($q, sockets) {
  this.refresh = function () {
    const deferred = $q.defer();
    const req = new XMLHttpRequest();
    req.timeout = 5000; // time in milliseconds
    req.open('GET', window.renewAuthURI, true); // force XMLHttpRequest2
    req.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
    req.setRequestHeader('Accept', 'application/json');
    req.withCredentials = true; // pass along cookies
    req.onload = function () {
      try {
        const json = JSON.parse(req.responseText);
        if (req.status !== 200) {
          return deferred.reject(json);
        }

        deferred.resolve(json);
        sockets.validateUniqueId();

      } catch (error) {
        return deferred.reject(error);
      }
    };

    req.onerror = deferred.reject;
    req.send();

    return deferred.promise;
  }

}]);
