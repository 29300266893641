import './custom.entity.config.modal.less';

(function () {
  angular.module('easybizy.easybizyModalViewsModel').controller('CustomEntityConfigurationsController',
    function ($scope, $modalInstance, $timeout, $http, localize, Repository, $q, views) {
      $scope.save = function () {
      };

      $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
      };

      $scope.$on('$locationChangeStart', function (scope, next, current) {
        $modalInstance.dismiss('cancel');
      });


      $scope.views = views
      $scope.selectedViewType = { selected: $scope.views[0] };


    });
}());
