var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "whatsapp-on-boarding-wizard" },
    [
      _c("transition", { attrs: { name: "top" } }, [
        _vm.isVisible
          ? _c("div", {
              staticClass:
                "absolute top-0 left-0 h-full w-full whatsapp-on-boarding-wizard__mask",
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-it" } }, [
        _vm.isVisible && !_vm.editCampaign
          ? _c(
              "div",
              {
                staticClass:
                  "absolute top-0 h-full w-full whatsapp-on-boarding-wizard__content",
              },
              [
                _c(
                  "div",
                  { staticClass: "relative-container" },
                  [
                    _c("div", {
                      staticClass:
                        "absolute w-full whatsapp-on-boarding-wizard__content-mask",
                      class:
                        _vm.selectedPackage &&
                        "whatsapp-on-boarding-wizard__content-mask--expanded",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "z-10 relative whatsapp-on-boarding-wizard__header",
                      },
                      [
                        _c(
                          "h1",
                          { staticClass: "flex items-center justify-center" },
                          [
                            _c("div", { staticClass: "p-2" }, [
                              _vm._v(_vm._s(_vm.headerText)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("h3", { staticClass: "whitespace-pre-line" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.headerExplain) +
                              "\n          "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("pick-kind", {
                      attrs: {
                        "selected-package": _vm.selectedPackage,
                        "available-packages": _vm.availablePackages,
                      },
                      on: { selected: _vm.packageSelected },
                    }),
                    _vm._v(" "),
                    _c("transition", { attrs: { name: "slide-bottom" } }, [
                      _vm.selectedPackage
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "whatsapp-on-boarding-wizard__info z-10 absolute",
                            },
                            [
                              _c(
                                "reminders-only-content",
                                _vm._b(
                                  {
                                    attrs: {
                                      "expected-cost-text":
                                        _vm.expectedCostText,
                                    },
                                    on: { "next-step": _vm.toggleEditCampaign },
                                  },
                                  "reminders-only-content",
                                  _vm.selectedPackage.explain,
                                  false
                                )
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("transition", { attrs: { name: "slide-left" } }, [
                      _vm.selectedPackage
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "whatsapp-on-boarding-wizard__phone z-1 absolute",
                              class: _vm.packageImage.className,
                            },
                            [
                              _c("img", {
                                attrs: { src: _vm.packageImage.src },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-it" } }, [
        _vm.editCampaign && _vm.isVisible
          ? _c(
              "div",
              {
                staticClass:
                  "absolute top-0 h-full w-full whatsapp-on-boarding-wizard__promotion-editor",
              },
              [
                _c(
                  "div",
                  { staticClass: "relative-container" },
                  [
                    _c("div", {
                      staticClass:
                        "absolute w-full whatsapp-on-boarding-wizard__content-mask whatsapp-on-boarding-wizard__content-mask--expanded",
                    }),
                    _vm._v(" "),
                    _c("whatsapp-generate-campaign", {
                      attrs: {
                        templates: _vm.templates,
                        "selected-template": _vm.selectedTemplate,
                        "existing-promotion": _vm.existingReminderPromotion,
                        "form-options": _vm.formOptions,
                        rules: _vm.generationRules,
                        "reminder-content": _vm.selectedPackage.reminderContent,
                      },
                      on: {
                        "select-template": _vm.selectTemplate,
                        "create-promotion": _vm.createPromotion,
                        revert: _vm.toggleEditCampaign,
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }