import './tiles.actions.directive.less'
import {cleanObj, valueOrDefault} from "@tomeravni/easybizy-js-common/common";
import {ODATA_DATE_TIME_FORMAT, SERVER_DATA_DATE_FORMAT} from "../../constants";
import {extractError} from "@tomeravni/easybizy-js-common/errors";

var tileActionsModule = angular.module('easybizy.common.tiles');
tileActionsModule.factory('tileActionsResolver', [
  'localize', '$modal',
  '$state', 'contextManager', 'confirmService', 'Repository', '$timeout', '$rootScope', 'stateManager', 'sensorMediator',
  function (localize, $modal,
            $state, contextManager, confirmService, Repository, $timeout, $rootScope, stateManager, sensorMediator
  ) {
    //Customer
    var sendSms = createAction("_TextAMessage_", "icon icon-sms");
    sendSms.command = function (customer) {
      sendAMessage(customer, "Sms");
    };


    const sendWhatsapp = createAction("_SendByWhatsapp_", "icon icon-whatsapp");
    sendWhatsapp.command = function (customerOrLead) {
      let customerPhoneNumber = customerOrLead.MobileFirst;
      let personName = `${customerOrLead.FirstName || ''} ${customerOrLead.LastName || ''}`.trim();
      let conversationCreationPromise;

      const customerId = valueOrDefault(customerOrLead.CustomerId, customerOrLead.LeadId);
      if (customerId) {
        let customImage = null;
        if (customerOrLead.DefaultImagePath) {
          customImage = `${window.filesURI}/${customerOrLead.DefaultImagePath}`;
        }

        conversationCreationPromise = Repository.Custom('Conversations').createConversation(customerPhoneNumber,
          personName, customerId, customImage, customerOrLead.EntityType);
      } else {
        conversationCreationPromise = Repository.Custom('Conversations').createConversation(customerPhoneNumber,
          personName);
      }

      conversationCreationPromise.then((conversation) => {
        $rootScope.$emit('openConversation', conversation.entityId);
      }).catch((err) => {
        toastr.error(extractError(err));
      });

    };

    var sendEmail = createAction("_SendAnEmail_", "icon icon-email-mail-streamline");
    sendEmail.command = function (customer) {
      sendAMessage(customer, "Mail");
    };
    var editGroup = createAction("_EditCustomersGroup_", "icon icon-edit");
    var sendOnlineBookingLink = createAction("_SendBookAnOnlineService_", "icon icon-online-scheduling-meeting");
    sendOnlineBookingLink.command = function (customer) {
      $rootScope.$emit('page-in-progress', true);

      Repository.Custom('Calendar').getOnlineBookingLink(customer.CustomerId)
        .then(function (link) {
          $timeout(function () {
            $rootScope.$emit('page-in-progress', false);
          });

          var message = localize.getLocalizedString('_OnlineBookingOrderSMS_', link);

          sendAMessage(customer, 'Sms', message);
        })
        .catch(function (err) {
          toastr.error(localize.getLocalizedString('_CouldntGenerateLink', err.message));
          $timeout.$evalAsync(function () {
            $rootScope.$emit('page-in-progress', false);
          });
        });
      // sendAMessage(customer, "Mail");
    };

    var bookAService = createAction("_BookAService_", "icon icon-calendar");
    bookAService.command = scheduleAMeeting;
    var linkToFacebook = createAction("_LinkToFacebook_", "icon icon-facebook");
    var sendFbSms = createAction("_SendFbSmsToExistingCustomer_", "glyphicon glyphicon-user");
    var setCurrentCustomer = {
      name: localize.getLocalizedString('_TakeToCashRegister_'),
      icon: 'icon icon-clients-ol'
    };
    setCurrentCustomer.command = setCurrentCustomerContext;

    var payDebt = {
      name: localize.getLocalizedString('_PayDebt_'),
      icon: 'icon icon-debt-unlock'
    };
    payDebt.command = function (customer) {
      stateManager.setState('CashRegister', cleanObj({
        debt: true,
        relativeDate: moment().format(ODATA_DATE_TIME_FORMAT),
        customerId: customer.CustomerId,
      }));

    };

    var mergeCustomer = createAction("_MergeCustomer_", "icon icon-sync");
    mergeCustomer.command = function (customer, action) {
      mergeCustomerDialog(customer, action);
    };


    // products actions
    var bookThisService = {name: localize.getLocalizedString('_BookAService_'), icon: 'icon icon-calendar'};
    var addToBill = {name: localize.getLocalizedString('_AddToBill_'), icon: 'icon icon-cashregister'};
    addToBill.command = function (item) {
      addToBillAction(item);
    };

    var promote = {name: localize.getLocalizedString('_Promote_'), icon: 'icon icon-campaigns-ol'};
    var deleteEntity = {
      name: localize.getLocalizedString('_Delete_'), toggleMode: true,
      toggleText: localize.getLocalizedString("_ApproveSavingAsTemplate_"), icon: 'icon icon-remove'
    };

    var transformLeadToCustomer = {
      name: localize.getLocalizedString('_TransformLeadToCustomer_'), toggleMode: true,
      toggleText: localize.getLocalizedString("_ApproveSavingAsTemplate_"), icon: 'icon icon-users'
    };

    var goToCustomer = {
      name: localize.getLocalizedString('_Customer_'), icon: 'icon icon-users'
    };


    // employees
    var checkIn = {name: localize.getLocalizedString('_CheckIn_'), icon: 'icon icon-checkin'};
    var checkOut = {name: localize.getLocalizedString('_CheckOut_'), icon: 'icon icon-checkout'};
    var manuallySetShift = {name: localize.getLocalizedString('_ManuallyUpdateShift_'), icon: 'icon icon-time'};
    // var editDetails = {name: localize.getLocalizedString('_EditEmployeeDetails_'), icon: 'icon icon-edit-inventory'};
    var employeeShifts = {name: localize.getLocalizedString('_EmployeeShiftsAction_'), icon: 'icon icon-calendar'};
    var employeeSells = {name: localize.getLocalizedString('_EmployeeSells_'), icon: 'icon icon-banknote'};
    var employeeWorkHours = {
      name: localize.getLocalizedString('_EmployeeWorkHoursAction_'),
      icon: 'icon icon-list-circle'
    };
    var syncWithGoogle = {
      name: localize.getLocalizedString('_SyncEmployeeToGoogleCalendar_'),
      icon: 'icon icon-sync'
    };
    var pauseSyncWithGoogle = {name: localize.getLocalizedString('_PauseGoogleSync_'), icon: 'icon icon-pause'};


    var printCustomerMeetings = {name: localize.getLocalizedString('_PrintFutureMeetings_'), icon: 'icon icon-print'};

    const sendToSensor = createAction("_SendToSensor_", "icon icon-sensor");
    sendToSensor.command = function (customer) {
      let name = `${customer.FirstName || ''} ${customer.LastName || ''}`.trim();
      let identification = customer.MembershipId;
      let dob = customer.DateOfBirth

      let gender = customer.Gender.value == 'Male' ? 'M' : 'F';
      sensorMediator.openCustomer(identification, name, dob, gender).catch((err) => {
        toastr.error(extractError(err));
      })
     

    };


    var entityToActions = {
      Customer: [sendSms, bookAService, sendEmail, linkToFacebook, sendFbSms, printCustomerMeetings, sendToSensor],
      ProductOrService: [bookThisService, addToBill, promote],
      Employee: [checkIn, checkOut, manuallySetShift, employeeShifts, employeeWorkHours, employeeSells, syncWithGoogle, pauseSyncWithGoogle, deleteEntity]
    };

    var actions = {
      sendSms: sendSms,
      sendWhatsapp,
      bookAService: bookAService,
      sendEmail: sendEmail,
      linkToFacebook: linkToFacebook,
      sendFbSms: sendFbSms,
      bookThisService: bookThisService,
      addToBill: addToBill,
      promote: promote,
      deleteEntity: deleteEntity,
      checkIn: checkIn,
      checkOut: checkOut,
      manuallySetShift: manuallySetShift,
      employeeShifts: employeeShifts,
      employeeSells: employeeSells,
      mergeCustomer: mergeCustomer,
      // editDetails: editDetails,
      setCurrentCustomer: setCurrentCustomer,
      editGroup: editGroup,
      sendOnlineBookingLink: sendOnlineBookingLink,
      employeeWorkHours: employeeWorkHours,
      transformLeadToCustomer: transformLeadToCustomer,
      goToCustomer: goToCustomer,
      syncWithGoogle: syncWithGoogle,
      pauseSyncWithGoogle: pauseSyncWithGoogle,
      printCustomerMeetings: printCustomerMeetings,
      payDebt: payDebt,
      sendToSensor
    };

    return {
      getActionsByEntityType: function (entityType) {
        return entityToActions[entityType];
      },
      getAction: function (actionName) {
        return actions[actionName];
      }
    };

    function createAction(name, icon) {
      return {
        name: localize.getLocalizedString(name),
        icon: icon
      };
    }

    function mergeCustomerDialog(customer, action) {
      var opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/customers-picker/customers.picker.modal.tpl.html'),
        controller: "PickACustomerModalController",
        resolve: {
          saveAction: function () {
            return function (newCustomer, dismissDelegate, loadingWrapperToggle) {

              confirmService.confirm(localize.getLocalizedString('_ApproveMergeingCustomerMessage_', customer.FirstName, newCustomer.FirstName),
                null,
                function () {
                  loadingWrapperToggle(true);
                  Repository.Custom("CustomerDetails").mergeCustomers(customer.CustomerId, newCustomer.CustomerId).then(function (result) {
                    action.delegate();
                    loadingWrapperToggle(false);
                    dismissDelegate();
                  }).catch(function (err) {
                    loadingWrapperToggle(false);
                    toastr.error(localize.getLocalizedString("_GeneralError_"), err && err.message);
                  })

                },
                function () {
                  // DOES NOTHING.
                });

            };
          }
        },
        closeFn: function () {
        }
      };

      $modal.open(opts);
    }

    function scheduleAMeeting(customer) {
      contextManager.set('customer', customer.CustomerId);
      $state.go('Calendar');
    }

    function sendAMessage(customer, messageType, messageContent) {
      if (messageType == 'Sms') {
        return sendSMSMessage(customer, messageContent);
      }

      var opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/promotion-quick-sender/promotion.quick.sender.modal.tpl.html'),
        controller: "PromotionQuickSenderModalController",
        resolve: {
          parameters: function () {
            return {customer: customer, messageType: messageType};
          }
        },
        closeFn: function () {
        }
      };

      $modal.open(opts);
    }

    function sendSMSMessage(customer, message) {
      var opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/sms-quick-sender/sms.quick.sender.tpl.html'),
        controller: "SMSQuickSenderController",
        resolve: {
          customer: function () {
            return customer;
          },
          message: function () {
            return message;
          }
        },
        closeFn: function () {
        }
      };

      $modal.open(opts);
    }

    function setCurrentCustomerContext(customer) {
      // contextManager.set('customer', customer.CustomerId);
      // var message = customer.FirstName + " " + customer.LastName + " " + localize.getLocalizedString("_CustomerSetAsContext_");
      // toastr.success(message);
      // $state.go('CashRegister');
      stateManager.setState('CashRegister', cleanObj({
        relativeDate: moment().format(ODATA_DATE_TIME_FORMAT),
        customerId: customer.CustomerId,
      }));

    }

    function addToBillAction(item) {
      contextManager.set('cashRegister', {items: [item]});
    }
  }]);


tileActionsModule.directive('tileActions', [
  'tileActionsResolver', function (tileActionsResolver) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        actions: '=',
        isVisible: '='
      },
      controller: function ($scope) {
        var listeners = [];

        this.registerVisibilityDelegateOnce = function (delegate) {
          listeners.push(delegate);
        };

        this.unRegisterVisibilityDelegateOnce = function (delegate) {
          listeners.remove(delegate);
        };

        $scope.$watch('isVisible', function (isVisible) {
          if (!angular.isDefined(isVisible)) {
            return;
          }

          if (listeners.length > 0) {
            angular.forEach(listeners, function (listener) {
              listener(isVisible);
            });

            listeners.length = 0;
          }
        });
      },
      link: function (scope, element, attrs) {
        // watch once.
        var watchActions = scope.$watch('actions', function (receivedActions) {
          if (angular.isDefined(receivedActions)) {
            watchActions(); // remove watcher.
            scope.resolvedActions = [];
            angular.forEach(receivedActions, function (action) {
              var tileAction = tileActionsResolver.getAction(action.type);
              action.name = tileAction.name;
              action.toggleMode = tileAction.toggleMode;
              action.toggleText = tileAction.toggleText;
              action.icon = tileAction.icon;
              action.command = tileAction.command;
              scope.resolvedActions.push(action);
            });
          }
        });
      },
      template: '<div class="user-options-container prevent-selection-children">\
                        <div class="tile-action-wrapper" ng-repeat="action in resolvedActions" bindonce>\
                            <tile-action action="action" ></tile-action>\
                        </div>\
                  </div>'
    };
  }]);

tileActionsModule.directive('tileAction', function ($timeout, $interval) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      action: '='
    },
    require: '^tileActions',
    link: function (scope, element, attrs, tileActionsCtrl) {
      scope.isToggled = false;
      scope.actionText = scope.action.name;
      scope.clickHandler = function () {
        if (!scope.action.isDisabled) {
          if (scope.action.toggleMode && !scope.isToggled) {
            var tileVisibilityChangedDelegate = function (newVal) {
              if (!newVal) {
                toggleMode(false);
                $timeout.cancel(timeoutPromise);
              }
            };

            tileActionsCtrl.registerVisibilityDelegateOnce(tileVisibilityChangedDelegate);
            toggleMode(true);
            var timeoutPromise = $interval(function () {
              toggleMode(false);
              tileActionsCtrl.unRegisterVisibilityDelegateOnce(tileVisibilityChangedDelegate);
            }, 15000, 1);
          } else if (angular.isDefined(scope.action.command)) {
            scope.action.command(scope.action.model, scope.action);
          } else if (angular.isDefined(scope.action.delegate)) {
            scope.action.delegate(scope.action.model, scope.action);
          }
        }
      };

      function toggleMode(isToggled) {
        if (isToggled) {
          scope.isToggled = true;
          scope.actionText = scope.action.toggleText;
        } else {
          scope.isToggled = false;
          scope.actionText = scope.action.name;
        }
      }
    },
    template: '<div class="user-command" ng-click="clickHandler()" \
                        ng-class="{\'partialy-visible\': action.isDisabled, \'cursor-pointer\': !action.isDisabled, \
                        \'tile-command-toggle-mode\':isToggled}">\
                        <div class="centered-div-container">\
                            <div class="centered-div-content left-text-aligned user-command-content">\
                                <div class="tile-action-icon" bo-class="action.icon" />\
                                <div class="user-command-text" ng-bind="actionText"></div>\
                            </div>\
                        </div>\
                    </div>'
  };
});



