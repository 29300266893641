import {getSafely} from "@tomeravni/easybizy-js-common/common";
import {extractError} from "@tomeravni/easybizy-js-common/errors";

export const ClickToCall = {
  props: ['phone'],
  mounted() {
    const loggedInUser = this.configurationService.loggedInUser;
    if (loggedInUser.then) {
      return loggedInUser.then((res) => {
        this.operator = getSafely(['operator'], res)
        if (!!Object.getSafely(['operator', 'vendor'], res)) {
          this.supportsClickToCall = true
        }
      })
    } else {
      this.operator = getSafely(['operator'], loggedInUser)
      if (!!Object.getSafely(['operator', 'vendor'], loggedInUser)) {
        this.supportsClickToCall = true
      }
    }
  },
  data() {
    return {
      supportsClickToCall: false,
      operator: null,
      disabled: false,
      success: false
    };
  },
  render(createElement, context) {
    return createElement('div', [
      this.supportsClickToCall ? createElement('button', {
        class: [this.success ? 'text--success' : `text--primary`, this.disabled ? 'evc--disabled' : ''].join(' '),
        on: {
          click: () => {
            const destination = this.phone.replace(/[^.\d]/g, '');
            this.disabled = true
            this.success = false

            this.Repository.Custom('VOIP').call({
              vendor: this.operator.vendor,
              extension: this.operator.extension,
              destination
            }).then(() => {
              this.disabled = false
              this.success = true
            }).catch((e) => {
              this.disabled = false
              this.toastr.error(extractError(e));
            })
          }
        }
      }, this.phone) : createElement('p', this.phone)
    ]);
  }
}
