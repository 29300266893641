var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "whatsapp-on-boarding-wizard__packages z-10 relative",
      class:
        _vm.selectedPackage &&
        "whatsapp-on-boarding-wizard__packages--selected",
    },
    _vm._l(_vm.availablePackages, function (p) {
      return _c(
        "div",
        {
          key: p.name,
          staticClass: "whatsapp-on-boarding-wizard__package absolute",
          class:
            _vm.selectedPackage &&
            p.name === _vm.selectedPackage.name &&
            "whatsapp-on-boarding-wizard__package--selected",
          on: {
            click: function ($event) {
              return _vm.translate(p)
            },
          },
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.selectedPackage
              ? _c("button", {
                  staticClass:
                    "absolute z-10 icon icon-close-round whatsapp-on-boarding-wizard__package-close",
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "whatsapp-on-boarding-wizard__package-top" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "whatsapp-on-boarding-wizard__package-name text--primary",
                },
                [_vm._v("\n        " + _vm._s(p.name) + "\n      ")]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "whatsapp-on-boarding-wizard__package-icon",
                class: p.icon,
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "whatsapp-on-boarding-wizard__package-price flex items-center justify-center",
                },
                [
                  _c("div", [_vm._v(_vm._s(_vm.startingAt))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "bold whatsapp-on-boarding-wizard__package-price-num",
                    },
                    [_vm._v(_vm._s(p.price))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "px-2" }, [_vm._v("/")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.perMonth))]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "whatsapp-on-boarding-wizard__package-content" },
            [
              _c("div", {
                staticClass: "whatsapp-on-boarding-wizard__package-separator",
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "whatsapp-on-boarding-wizard__package-props" },
                _vm._l(p.props, function (prop) {
                  return _c(
                    "div",
                    {
                      key: prop.title,
                      staticClass:
                        "whatsapp-on-boarding-wizard__package-prop flex",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "whatsapp-on-boarding-wizard__package-prop-text p-1",
                        },
                        [
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(_vm._s(prop.title)),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(prop.text))]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "whatsapp-on-boarding-wizard__package-select" },
                [
                  _c(
                    "button",
                    {
                      class: p.primary
                        ? "background--primary"
                        : "button-secondary",
                    },
                    [_vm._v(_vm._s(p.actionText))]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }