import './mail.promotion.editor.directive.less'
import { PATH_TO_API } from "../../../constants";

angular.module('easybizy.common.promotion.editors').directive('mailEditor', ['$compile', 'localize', '$timeout', 'promotionTemplateResolver', '$sce', '$fileUploader',
  function ($compile, localize, $timeout, promotionTemplateResolver, $sce, $fileUploader) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        contentResolver: "=",
        defaultCustomer: "=",
        active: "=",
        preset: "=",
        content: "=",
        alwaysEditMode: '@',
        mode: "@"
      },
      template: require('./mail.promotion.editor.directive.html'),
      link: function (scope, element, attrs) {
        scope.colordecorator = function (itemScope, itemElement) {
          itemScope.$watch('item', function (val) {
            if (val) {
              template.css('background-color', val.value);
              if (val.value === "transparent") {
                template.addClass("transparent-item");
              } else {
                template.removeClass("transparent-item");
              }
            }
          });


          var template = '<div class="combobox-color-icon" ng-click="selectItem(item, true, $event)" click-effect-target=".combobox-value-part"></div>';
          template = $compile(template)(itemScope);
          itemElement.replaceWith(template);
        };

        scope.isEditMode = scope.alwaysEditMode == "true" ? true : false;
        scope.loadingImage = false;
        scope.subjectPlaceHolder = localize.getLocalizedString("_SubjectPlaceHolder_");
        scope.mailPlaceholder = localize.getLocalizedString("_MailPlaceholder_");
        scope.editorTitle = scope.isEditMode ? localize.getLocalizedString("_Edit_") : localize.getLocalizedString("_Preview_");
        scope.modeButtonText = localize.getLocalizedString("_Edit_");


        scope.insertDynamicField = function (field, $event) {
          if (editor.focusManager.hasFocus) {
            editor.insertText(" " + field.value + " ");
          } else {
            if (scope.isSubjectFocused) {
              scope.isSubjectFocused = false;
              $timeout(function () {
                var newValue = $("input.mail-editor-subject-editor")[0].insertAtCaret(field.value);
                scope.$apply(function () {
                  scope.subject = newValue;
                });
              });
            }
          }
        };

        scope.toggleEditMode = function () {
          scope.isEditMode = !scope.isEditMode;
          var data = editor.getData();
          if (scope.isEditMode) {
            scope.editorTitle = localize.getLocalizedString("_Edit_");
            scope.modeButtonText = localize.getLocalizedString("_Preview_");
            editor.setReadOnly(false);
            if (data.length > 0) {
              editor.setData(promotionTemplateResolver.replaceDynamicFields(data, true));
            }
          } else {
            scope.editorTitle = localize.getLocalizedString("_Preview_");
            scope.modeButtonText = localize.getLocalizedString("_Edit_");
            editor.setReadOnly(true);
            if (data.length > 0) {
              editor.setData(promotionTemplateResolver.replaceDynamicFields(data, false));
            }
          }

          scope.$root.$broadcast('editMode', scope.isEditMode);
        };

        scope.applyBooleanAction = function (action) {
          executeBooleanCommand(action);
        };

        if (scope.contentResolver) {
          scope.contentResolver.resolve = function (promotionToAppendContentTo) {
            var mailContent = getContent();
            var subject = scope.subject;
            if (angular.isDefined(scope.content)) {
              if (subject === scope.content.subject &&
                mailContent === scope.content.html) {
                // content wan't changed.
                promotionToAppendContentTo.ClientPromotionContent.State = "Unchanged";
                return;
              } else {
                promotionToAppendContentTo.ClientPromotionContent = {};
                promotionToAppendContentTo.ClientPromotionContent.State = "Modified";
              }
            } else {
              promotionToAppendContentTo.ClientPromotionContent = {};
            }

            promotionToAppendContentTo.ClientPromotionContent.Message = mailContent;
            promotionToAppendContentTo.ClientPromotionContent.Subject = subject;
            promotionToAppendContentTo.ClientPromotionContent.PromotionSenderType = "mail";
            promotionToAppendContentTo.ClientPromotionContent.OriginalTemplateName = scope.currentPreset;
          };

          scope.contentResolver.validate = function () {
            var returnValue = null;
            if (scope.subject.length < 1) {
              // Post has no text nor images.
              returnValue = localize.getLocalizedString("_MailMustHaveASubject_");
            }

            return returnValue;
          };
        }

        function getContent() {
          return (scope.isEditMode) ? editor.getData() : promotionTemplateResolver.replaceDynamicFields(editor.getData(), true);
        }

        scope.subject = "";
        scope.subjectPreview = "";
        scope.currentPreset = null;
        var editor = null;

        scope.$watch('subject', function (newSubject) {
          scope.subjectPreview = $sce.trustAsHtml(promotionTemplateResolver.replaceDynamicFields(newSubject, false));
        });

        var resolveContent = function (newContent) {
          if (newContent) {
            scope.subject = newContent.subject;
            editor.setData(newContent.html);
          }
        };


        /********************************* Template part *********************************/

        var applyTemplate = function () {
          if (scope.active && scope.preset) {
            if (scope.preset.TemplateName !== scope.currentPreset) {
              scope.currentPreset = scope.preset.TemplateName;
              scope.fields = promotionTemplateResolver.getDynamicFields(scope.preset);
              promotionTemplateResolver.get("mail", scope.currentPreset).then(function (preset) {
                editor.setData(promotionTemplateResolver.replaceDynamicFields(preset.message, false));
                scope.$apply(function () {
                  scope.subject = preset.subject;
                });
              });
            }
          }
        };

        scope.$watch('active', applyTemplate);
        scope.$watch('preset', applyTemplate);


        $(".mail-editor-upload-template-wrapper").click(function () {
          element.find('.upload-template-field').click();
        });

        var uploader = scope.uploader = $fileUploader.create({
          scope: scope,
          url: baseUrl + PATH_TO_API + 'FilesUpload/MailHtmlTemplate'
        });

        uploader.filters.push(function (item) {
          var type = uploader.isHTML5 ? item.type : '/' + item.name.slice(item.value.lastIndexOf('.') + 1);
          type = '|' + type.toLowerCase().slice(type.lastIndexOf('/') + 1) + '|';
          return '|zip|html|htm|x-zip-compressed|'.indexOf(type) !== -1;
        });

        uploader.bind('afteraddingfile', function (event, item) {
          if (uploader.queue.length > 1) {
            uploader.queue.shift();
          }

          uploader.uploadAll();
          scope.loadingImage = true;
        });

        scope.uploadResult = [];

        uploader.bind('success', function (event, xhr, item, response) {
          //scope.uploadResult.push(response.data);
          scope.uploadResult.push(response);
          //editor.setData(response.Content);
          editor.setData(promotionTemplateResolver.replaceDynamicFields(response.Content, false));
          scope.loadingImage = false;
        });

        /********************************* Mail editor part (CKEDITOR) *********************************/


        $timeout(function () {
          scope.$watch('content', resolveContent, true);

          // Hack to fix a focus issue when inserting custom fields...
          $("div.promotion-custom-field-wrapper").on("mousedown touchdown", function (e) {
            var focused = document.activeElement;
            if ($(focused)[0] == $("input.mail-editor-subject-editor")[0]) {
              scope.isSubjectFocused = true;
            }

            $timeout(function () {
              $(focused).focus();
            });
          });

          if (localize.isRightToLeft()) {
            CKEDITOR.config.bodyClass = 'rtl-ckeditor';
          }
          CKEDITOR.config.allowedContent = true;


          editor = CKEDITOR.replace('editor1', {});
          editor.on('instanceReady', function () {
            editor.setReadOnly(!scope.isEditMode);
//                        editor.config.contentsLangDirection = "rtl";


            // font-type
            scope.fontNames = setMultipleValuesType('font_names', function (parts) {
              return { name: parts.length > 1 ? parts[0].split(',')[0] : parts[1], value: parts[0] };
            }, 'fontFamilySelected', function (newValue) {
              return new CKEDITOR.style(editor.config.font_style, { family: newValue.value });
            });

            scope.fontName = scope.fontNames[0];

            // font-size
            scope.fontSizes = setMultipleValuesType('fontSize_sizes', function (parts) {
              return { name: parts[1], value: parts[0] };
            }, 'fontSizeSelected', function (newValue) {
              return new CKEDITOR.style(editor.config.fontSize_style, { size: newValue.name });
            });

            scope.fontSize = scope.fontSizes[0];

            // foreground-color
            scope.foregroundColors = setMultipleValuesType('colorButton_colors', function (parts) {
              return { name: parts[0], value: "#" + parts[0] };
            }, 'foregroundColorSelected', function (newValue) {
              return new CKEDITOR.style(editor.config.colorButton_foreStyle, { color: newValue.value });
            }, ',');

            scope.foregroundColor = scope.foregroundColors[0];

            // background-color
            scope.backgroundColors = setMultipleValuesType('colorButton_colors', function (parts) {
              return { name: parts[0], value: "#" + parts[0] };
            }, 'backgroundColorSelected', function (newValue) {
              return new CKEDITOR.style(editor.config.colorButton_backStyle, { color: newValue.value });
            }, ',');

            scope.backgroundColor = scope.backgroundColors[0];

            scope.$digestIfNeeded();
            var styleBold = new CKEDITOR.style(CKEDITOR.config.coreStyles_bold); // doesn't have to be bold.. Can be any other.

            editor.attachStyleStateChange(styleBold, function (state) {
              $timeout(function () {
                var currentCursorStyles = $(editor.getSelection().getStartElement().$).getStyleObject();
                // For instance, the font-family is:
                var fontFamily = currentCursorStyles.fontFamily;
                var fontSize = currentCursorStyles.fontSize;
                var foregroundColor = currentCursorStyles.color.rgbToHex();
                var backgroundColor = currentCursorStyles.backgroundColor.rgbToHex();

                var verifyEditorState = function () {
                  cursorStyleChanged('fontSizes', 'fontSize', {
                    name: fontSize,
                    value: parseInt(fontSize)
                  });

                  cursorStyleChanged('fontNames', 'fontName', {
                    name: fontFamily,
                    value: fontFamily
                  });

                  cursorStyleChanged('foregroundColors', 'foregroundColor', {
                    name: foregroundColor,
                    value: foregroundColor
                  });

                  cursorStyleChanged('backgroundColors', 'backgroundColor', {
                    name: backgroundColor,
                    value: backgroundColor
                  });

                  cursorBooleanChanged(currentCursorStyles);
                };

                if (!scope.$root.$$phase) {
                  scope.$apply(function () {
                    scope.isBold = state == CKEDITOR.TRISTATE_ON;
                    verifyEditorState();
                  });
                } else {
                  verifyEditorState();
                }
              });

            });
          });
        });

        function setMultipleValuesType(configType, valueToConcreteFunc, scopeActionName, ckeditorStyleCreator, customSplitChar) {
          var values = editor.config[configType].split(customSplitChar ? customSplitChar : ';');
          var seperatedValues = [];
          for (var i = 0; i < Math.min(10, values.length); i++) {
            var parts = values[i];
            parts = parts.split('/');
            seperatedValues.push(valueToConcreteFunc(parts));
          }

          var toReturn = seperatedValues;
          scope[scopeActionName] = function (newValue) {
            editor.focus();
            editor.fire('saveSnapshot');
            var newValueStyle = ckeditorStyleCreator(newValue);
            editor['applyStyle'](newValueStyle);
            editor.fire('saveSnapshot');
          };

          return toReturn;
        }

        function cursorStyleChanged(valuesName, valueName, newValue) {
          var valueInList = scope[valuesName].filter(x => x.name.equalsIgnoreCase(newValue.name))[0];
          if (!valueInList) {
            scope[valuesName].push(newValue);
            valueInList = newValue;
          }

          scope[valueName] = valueInList;
        }

        function cursorBooleanChanged(styles) {
          scope.isItalic = styles.fontStyle === "italic";
          scope.isUnderlined = styles.textDecoration.indexOf("underline") != -1;
        }

        function executeBooleanCommand(commandName) {
          editor.execCommand(commandName);
        }
      }
    };
  }]);
