var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "whatsapp-on-boarding-wizard__reminders-explain" },
    [
      _c("h3", { staticClass: "mt-0" }, [_vm._v(_vm._s(_vm.header))]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "whatsapp-on-boarding-wizard__reminders-explain-content",
        },
        [
          _c(
            "div",
            _vm._l(_vm.items, function (item) {
              return _c(
                "div",
                { key: item, staticClass: "flex items-center mb-2" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "whatsapp-on-boarding-wizard__package-prop-text p-1",
                    },
                    [_vm._v("\n          " + _vm._s(item) + "\n        ")]
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt-4" }, [
            _c("h4", [_vm._v(_vm._s(_vm.pricing.title))]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._l(_vm.pricing.items, function (item) {
                  return _c(
                    "div",
                    { key: item, staticClass: "flex items-center mb-2" },
                    [
                      _c("div", {
                        staticClass:
                          "whatsapp-on-boarding-wizard__package-prop-icon icon-check-circle text--success",
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "whatsapp-on-boarding-wizard__package-prop-text p-1",
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(item) + "\n          "
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _c("div", [
                  _vm._v("* " + _vm._s(_vm.$t("_PricesNotIncludeTax_"))),
                ]),
              ],
              2
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", [
        _c(
          "button",
          {
            staticClass:
              "whatsapp-on-boarding-wizard__reminders-explain-action background--primary mt-4",
            on: { click: _vm.nextStep },
          },
          [_vm._v("\n      " + _vm._s(_vm.actionText) + "\n    ")]
        ),
        _vm._v(" "),
        _vm.expectedCostText
          ? _c("p", [_vm._v(_vm._s(_vm.expectedCostText))])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }