var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Grid",
        _vm._b(
          { ref: "grid", attrs: { dataSource: _vm.dataSource } },
          "Grid",
          _vm.gridProps,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }