import { DATE_DISPLAY_FORMAT } from "../../constants";
import { asArray } from "../../general/helpers";
import './reports.less';
import './reports.plain.decorators'
import './reports.widget.decorators'
import './report-widget-container/report.widget.container.directive'
import './widgets/graph-widget/graph.widget.directive'
import './widgets/plain-numbers-widget/plain.numbers.widget.directive'
import * as _ from 'underscore';

/**
 * Created by tomeravni on 2/9/14.
 */
angular.module('easybizy.reports').controller('ReportsController', function ($scope, $timeout, Repository, toolbar, reportsSharedService,
                                                                             localize, configuration, globalActions, reportsDecorator, reportsPlainDecorators) {

    let preEditReports;
    toolbar.set([
      {
        name: 'edit',
        action: (editMode) => {
          $scope.sortModeWrapper.sortMode = editMode;
          const currentReports = $scope.reports.map((report) => report.type);
          if (!editMode) {
            saveGraphLayout();
          } else {
            preEditReports = currentReports;
          }
        },
        tooltip: localize.getLocalizedString('_EditReports_')
      },
      {
        name: 'reportActions',
        icon: 'icon icon-stats',
        actions: [
          {
            name: localize.getLocalizedString("_GenerateIrsReports_"),
            icon: 'icon icon-file-zip',
            action: globalActions.openIrsReport
          },
          {
            name: localize.getLocalizedString("_GenerateReports_"),
            icon: 'icon icon-chart-line',
            action: globalActions.openZByRange
          },
          {
            name: localize.getLocalizedString("_ExportExternalAccountingReports_"),
            icon: 'icon icon-chart-line',
            action: globalActions.openExternalAccountingWizard
          },
          {
            name: localize.getLocalizedString("_SalesTree_"),
            icon: 'icon icon-chart-area',
            action: globalActions.openSalesTree
          },
          {
            name: localize.getLocalizedString("_MeetingClosing_"),
            icon: 'icon icon-socket-ok',
            action: globalActions.showMeetingClosing
          },
          {
            name: localize.getLocalizedString("_MeetingsHistory_"),
            icon: 'icon icon-table',
            action: globalActions.showMeetingHistory
          }]
      }
    ]);

    $scope.sortModeWrapper = { sortMode: false };
    $scope.graphTypeChanged = function (oldType, newType) {
      var oldTypeObject = $scope.reports.filter(function (graph) {
        return graph.type === oldType;
      })[0];

      var newTypeProps = $scope.reportsLayout.filter(function (option) {
        return option.type === newType;
      })[0];

      Object.assign(oldTypeObject, newTypeProps);
      saveGraphLayout();
    };

    $scope.graphSortChanged = (updatedGraphs) => {
      const updateRange = updatedGraphs.map((graphScope) => graphScope.report.type);
      const tmpReports = $scope.reports.slice();
      $scope.reports = updateRange.map((reportType) => {
        return tmpReports.find((r) => r.type === reportType)
      });

      saveGraphLayout();
    };

    configuration.getPersonalAsync().then(function (result) {
      initGraphTypes(result.reportsLayout);
    }).catch(function () {
      toastr.error(localize.getLocalizedString('_ErrorLoadingFavoriteGraphs_'));
      initGraphTypes();
    });

    function initGraphTypes(layout) {
      var reportOptions = [
        { type: 'SalesByProduct', visualization: 'graph', decorator: reportsDecorator },
        { type: 'SalesByService', visualization: 'graph', decorator: reportsDecorator },
        { type: 'TotalSales', visualization: 'graph', decorator: reportsDecorator },
        { type: 'Subscriptions', visualization: 'graph', decorator: reportsDecorator },
        { type: 'Customers', visualization: 'graph', decorator: reportsDecorator },
        { type: 'SalesByClerk', visualization: 'graph', decorator: reportsDecorator },
        { type: 'PriceVsCost', visualization: 'graph', decorator: reportsDecorator },
        { type: 'GiftCards', visualization: 'graph', decorator: reportsDecorator },
        { type: 'EmployeeHours', visualization: 'graph', decorator: reportsDecorator },
        { type: 'ProductInventory', visualization: 'graph', decorator: reportsDecorator },
        { type: 'ServicesByClerk', visualization: 'graph', decorator: reportsDecorator },
        { type: 'ProductsByClerk', visualization: 'graph', decorator: reportsDecorator },
        { type: 'SubscriptionUsages', visualization: 'graph', decorator: reportsDecorator },
        { type: 'Refunds', visualization: 'graph', decorator: reportsDecorator },
        { type: 'MeetingCancellations', visualization: 'graph', decorator: reportsDecorator },
        { type: 'ExternalVouchers', visualization: 'graph', decorator: reportsDecorator },
        { type: 'Payments', visualization: 'graph', decorator: reportsDecorator },
        { type: 'Debts', visualization: 'graph', decorator: reportsDecorator },
        { type: 'Meetings', visualization: 'graph', decorator: reportsDecorator },
        { type: 'OnlineBookings', visualization: 'graph', decorator: reportsDecorator },
        { type: 'Feedbacks', visualization: 'graph', decorator: reportsDecorator },
        { type: 'Leads', visualization: 'graph', decorator: reportsDecorator },
        { type: 'MeetingsByEmployees', visualization: 'graph', decorator: reportsDecorator },
        { type: 'MeetingsScheduling', visualization: 'graph', decorator: reportsDecorator },
        { type: 'CreditForecast', visualization: 'plain', decorator: reportsPlainDecorators }
      ];

      if (layout && layout.length > 0) {
        // Clone first.
        var clonedOptions = reportOptions.clone(),
          // Create map for name to report.
          nameToReportMap = reportOptions.reduce(function (map, report) {
            map[report.type] = report;
            return map;
          }, {});

        // Grab configuration saved desired reports and remove them from clonedOptions
        $scope.reportsLayout = [];
        layout.forEach(function (reportName) {
          var report = nameToReportMap[reportName];
          if (report) {
            $scope.reportsLayout.push(report);
            clonedOptions.remove(report);
          }
        });
        // Add other reports that not on config.
        $scope.reportsLayout.pushAll(clonedOptions);
      } else {
        $scope.reportsLayout = reportOptions;
      }

      var rows = 2, columns = 3, graph = 0;
      $scope.reports = [];
      $scope.availableGraphs = reportOptions.clone();

      for (var yIndex = 0; yIndex < rows; yIndex++) {
        for (var xIndex = 0; xIndex < columns; xIndex++) {
          var currentLayout = $scope.reportsLayout[graph];
          $scope.reports.push({
            type: currentLayout.type,
            decorator: currentLayout.decorator,
            row: yIndex + 1,
            column: xIndex + 1,
            visualization: currentLayout.visualization
          });

          graph++;

          $scope.availableGraphs.remove(currentLayout);
        }
      }

      $scope.availableGraphs = $scope.availableGraphs.map(function (layout) {
        return {
          name: localize.getLocalizedString('_' + layout.type + '_'),
          value: layout.type,
        }
      });
    }

    $scope.dateRangePresets = [
      { name: localize.getLocalizedString('_ShowDaily_'), value: new dateRangePreset("daily") },
      { name: localize.getLocalizedString('_ShowWeekly_'), value: new dateRangePreset("weekly") },
      { name: localize.getLocalizedString('_ShowMonthly_'), value: new dateRangePreset("monthly") },
      { name: localize.getLocalizedString('_ShowYearly_'), value: new dateRangePreset("yearly") },
      { name: localize.getLocalizedString('_Custom_'), value: null }
    ];

    $scope.dateRangeSelectedPreset = $scope.dateRangePresets[2];
    $scope.rangeMetadataFrom = {
      fieldName: 'from',
      icon: "icon icon-calendar",
      placeholder: "Start Date",
      validation: "{'required': true}",
      yearRange: "-5:+1",
      type: 'date'
    };
    $scope.rangeMetadataTo = {
      fieldName: 'to',
      icon: "icon icon-calendar",
      placeholder: "End Date",
      validation: "{'required': true}",
      yearRange: "-5:+1",
      type: 'date'
    };
    $scope.selectedRange = {};

    $scope.$watch('dateRangeSelectedPreset', function (newVal) {
      if (newVal.value) {
        $scope.selectedRange.from = newVal.value.from;
        $scope.selectedRange.to = newVal.value.to;
      }

    });

    $scope.$watch('selectedRange', function (newVal) {
      var presetIndex = -1;
      angular.forEach($scope.dateRangePresets, function (preset, index) {
        if (preset.value && preset.value.isSame(newVal)) {
          presetIndex = index;
          return false;
        }
      });

      if (presetIndex > 0) {
        $scope.dateRangeSelectedPreset = $scope.dateRangePresets[presetIndex];
      } else {
        $scope.dateRangeSelectedPreset = $scope.dateRangePresets[$scope.dateRangePresets.length - 1];
      }

      reportsSharedService.dateChanged(newVal);
    }, true);

    function dateRangePreset(presetOrFrom, to) {
      if (angular.isDefined(to)) {
        this.from = presetOrFrom;
        this.to = to;
      } else {
        if (presetOrFrom === "daily") {
          this.from = moment().format(DATE_DISPLAY_FORMAT);
          this.to = moment().add(1, 'd').format(DATE_DISPLAY_FORMAT);
        } else if (presetOrFrom === "weekly") {
          this.from = moment().startOf('week').format(DATE_DISPLAY_FORMAT);
          this.to = moment().format(DATE_DISPLAY_FORMAT);
        } else if (presetOrFrom === "monthly") {
          this.from = moment().startOf('month').format(DATE_DISPLAY_FORMAT);
          this.to = moment().format(DATE_DISPLAY_FORMAT);
        } else if (presetOrFrom === "yearly") {
          this.from = moment().startOf('year').format(DATE_DISPLAY_FORMAT);
          this.to = moment().format(DATE_DISPLAY_FORMAT);
        }
      }
    }

    dateRangePreset.prototype.isSame = function (otherDateRange) {
      return otherDateRange != null && this.from === otherDateRange.from && this.to === otherDateRange.to;
    };

    function saveGraphLayout() {
      const currentReports = $scope.reports.map((report) => report.type);
      if (_.isEqual(preEditReports, currentReports)) {
        return;
      }

      const reportsNewLayout = currentReports;
      angular.forEach($scope.reportsLayout, function (report) {
        if (report.name && reportsNewLayout.indexOf(report.name) < 0) {
          reportsNewLayout.push(report.name);
        }
      });

      // We only save names on configurations.
      Repository.Custom("Configurations").reportsLayout(reportsNewLayout).then(() => {
        toastr.success(localize.getLocalizedString('_LayoutSaved_'));
        preEditReports = reportsNewLayout;
      }).catch((err) => {
        toastr.error(err.message);
      })
    }
  }
);

angular.module('easybizy.reports').factory('reportsSharedService', [
  '$rootScope', '$timeout', '$transitions', function ($rootScope, $timeout, $transitions) {
    var registeredFullScreenListeners = undefined;
    var graphs = {};
    var currentDate = null;
    var isCurrentlyFullScreen = false;
    var isDirty = false;

    $transitions.onExit({ exiting: "Reports" }, function () {
      registeredFullScreenListeners = undefined;
      graphs = {};
      isDirty = false;
      isCurrentlyFullScreen = false;
      currentDate = null;
    });

    return {
      toggleFullScreen: toggleFullScreen,
      registerGraph: registerGraph,
      unregisterGraph: unregisterGraph,
      dateChanged: dateChanged,
      registerFullScreenHandler: registerFullScreenHandler,
      currentDate: function () {
        return currentDate;
      }
    };

    function registerFullScreenHandler(listener) {
      registeredFullScreenListeners = listener;
    }

    function registerGraph(graphDelegate) {
      graphs[graphDelegate.type] = graphDelegate;
    }

    function unregisterGraph(graphDelegate) {
      delete graphs[graphDelegate.type];
    }

    function toggleFullScreen(isFullScreen, type) {
      isCurrentlyFullScreen = isFullScreen;
      graphs[type].isFullScreen = isFullScreen;

      if (registeredFullScreenListeners) {
        registeredFullScreenListeners(isFullScreen);
      }

      if (!isFullScreen) {
        if (isDirty) {
          isDirty = false;
          $timeout(function () {
            angular.forEach(graphs, function (value, graphType) {
              if (graphType !== type) {
                value.dateChanged();
              }
            });
          }, 800);

        }
      }
    }

    function dateChanged(newDate) {
      currentDate = newDate;
      if (!isCurrentlyFullScreen) {
        angular.forEach(graphs, function (graph) {
          graph.dateChanged();
        });

        isDirty = false;
      } else {
        isDirty = true;
        let graphsAsArray = asArray(graphs);
        var currentFullScreenGraph = graphsAsArray.filter(x => x.isFullScreen)[0];
        currentFullScreenGraph.dateChanged();
      }
    }
  }]);

angular.module('easybizy.reports').directive('reportsViewWrapper', [
  'reportsSharedService', function (reportsSharedService) {
    return {
      restrict: 'A',
      link: function (scope, element) {

        // Handle resize of graph element so we resize the graph itself.
        element.on('transitionend', '.tile-content-wrapper', (e) => {
          if (e.target.className.indexOf('tile-content-wrapper') > -1 && e.originalEvent.propertyName === 'height') {
            $(e.target).find('.graph-viewer-view').trigger('resize');
          }
        });

        reportsSharedService.registerFullScreenHandler(function (isFullScreen) {
          if (isFullScreen) {
            element.addClass("selected-report-mode");
          } else {
            element.removeClass("selected-report-mode");
          }
        });
      }
    };
  }]);
