import './filter.toolbar.directive.less';
import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT, URL_FRIENDLY_SHORT_DATE_FORMAT } from "../../constants";

const commonControlsModule = angular.module('easybizy.common.common-controls');

commonControlsModule.factory('filterLogicFactory', [
  'Repository', 'localize', '$window', '$timeout', 'configuration',
  function (Repository, localize, $window, $timeout, configuration) {
    var filters = {
      alphabetically: generateAlphabeticallyFilter,
      productOrService: generateProductOrService,
      gender: generateGenderFilter,
      search: generateSearchFilter,
      backButton: generateBackAction,
      nextButton: generateNextAction,
      takeEbPaymentButton: generateEbPaymentAction,
      customerDetailsFilter: generateCustomerDetailsFilter,
      leadDetailsFilter: generateLeadDetailsFilter,
      employeeDetailsFilter: generateEmployeeDetailsFilter,
      productDetailsFilter: generateProductDetailsFilter,
      shiftState: generateEmployeesFilter,
      state: generateLeadState,
      employees: generateLeadByEmployeesFilter,
      serviceIngredientsProductCategory: generateServiceIngredientsProductCategoryFilter,
      productsFilter: generateProductsFilter,
      servicesFilter: generateServicesFilter,
      simpleHeader: generateSimpleHeader,
      tag: generateTagFilter,
      manufacturersFilter: generateManufacturersFilter,
      arrivalsource: generateArrivalSourcesFilter,
      subStatus: generateSubStatusFilter,
      nextHandlingTime: nextHandlingTime,
      expenseCategory: expenseCategoryFilter,
      buckets: bucketsFilter,
      dateRange: dateRangeFilter,
      form: formFilter,
      promotionStatus: promotionStatusFilter,
      promotionMedium: promotionMediumFilter,
    };

    return {
      get: get
    };

    function get(name, params) {
      var toReturn = filters[name](params);
      if (params && params.hasOwnProperty("disabledWrapper")) {
        toReturn.disabledWrapper = params.disabledWrapper;
      }
      return toReturn;
    }

    function generateAlphabeticallyFilter(params) {
      var alphabetically = new filterLogicItem(
        [
          {
            name: localize.getLocalizedString("_Alphabetically_"),
            value: null
          },
          {
            name: localize.getLocalizedString("_A-D_"),
            value: generateAlphabetically(localize.getLocalizedString("_A-D_Dictionary_").split(""), params.fields),
            slug: 'a-d'
          },
          {
            name: localize.getLocalizedString("_E-J_"),
            value: generateAlphabetically(localize.getLocalizedString("_E-J_Dictionary_").split(""), params.fields),
            slug: 'e-j'
          },
          {
            name: localize.getLocalizedString("_K-N_"),
            value: generateAlphabetically(localize.getLocalizedString("_K-N_Dictionary_").split(""), params.fields),
            slug: 'k-n'
          },
          {
            name: localize.getLocalizedString("_O-S_"),
            value: generateAlphabetically(localize.getLocalizedString("_O-S_Dictionary_").split(""), params.fields),
            slug: 'o-s'
          },
          {
            name: localize.getLocalizedString("_T-W_"),
            value: generateAlphabetically(localize.getLocalizedString("_T-W_Dictionary_").split(""), params.fields),
            slug: 't-w'
          },
          {
            name: localize.getLocalizedString("_X-Z_"),
            value: generateAlphabetically(localize.getLocalizedString("_X-Z_Dictionary_").split(""), params.fields),
            slug: 'x-z'
          }
        ]);

      alphabetically.order = 0;
      alphabetically.type = "combo";
      return alphabetically;
    }

    function bucketsFilter(params) {
      var values = [
        { name: localize.getLocalizedString('_AllTypes_'), value: null }
      ];

      values.pushAll(params.options);

      var bucketsFilter = new filterLogicItem(values);
      bucketsFilter.order = 0;
      bucketsFilter.type = "combo";
      return bucketsFilter;

    }

    function generateLeadState() {
      var leadStateFilter = new filterLogicItem(
        [
          {
            name: localize.getLocalizedString("_LeadsState_"),
            value: null
          },
          {
            name: localize.getLocalizedString("_New_"),
            slug: 'new',
            value: 'LeadStatus eq \'New\''
          },
          {
            name: localize.getLocalizedString("_Active_"),
            slug: 'active',
            value: 'LeadStatus eq \'Active\''
          },
          {
            name: localize.getLocalizedString("_Suspended_"),
            slug: 'suspended',
            value: 'LeadStatus eq \'Suspended\''
          },
          {
            name: localize.getLocalizedString("_Inactive_"),
            slug: 'inactive',
            value: 'LeadStatus eq \'Inactive\''
          },
          {
            name: localize.getLocalizedString("_BecomeACustomer_"),
            slug: 'becomeacustomer',
            value: 'LeadStatus eq \'BecomeACustomer\''
          }
        ]);

      leadStateFilter.order = 0;
      leadStateFilter.type = "combo";
      leadStateFilter.allowMultiple = true;
      leadStateFilter.convertValue = function (selectedValues) {
        if (!angular.isArray(selectedValues)) {
          return selectedValues > 0 ? [selectedValues] : null;
        }
        var resultToReturn = '';
        if (selectedValues && selectedValues.length > 0) {
          angular.forEach(selectedValues,
            function (filter) {
              if (filter.value != null) {
                resultToReturn = resultToReturn.length > 0 ? (resultToReturn + " or " + filter.value) : '(' + filter.value;
              }
            });

          if (resultToReturn.length == 0) {
            resultToReturn = null;
          } else {
            resultToReturn += ')';
          }
        }

        return resultToReturn;
      };

      return leadStateFilter;
    }


    function generateAlphabetically(letters, fields) {
      var toReturn = "(";
      var splittedFields = fields.split(", ");
      $.each(letters,
        function (index, value) {
          if (index !== 0) {
            toReturn += " or ";
          }

          toReturn += "startswith(" + splittedFields[0] + ", '" + value + "')";


          for (let i = 1; i < splittedFields.length; i++) {
            toReturn += " or startswith(" + splittedFields[i] + ", '" + value + "')";
          }

        });

      toReturn += ")";
      return toReturn;
    }

    function generateContains(string, fields, type, numbersOnlyFields = '') {
      var adaptedString = string.replace(/\'/gi, "''");

      var toReturn = "(";
      var splittedFields = fields.split(", ");
      const mappedNumbersOnlyFields = numbersOnlyFields.split(',').map((x) => x.trim());

      toReturn += type === 'Customer'
        ? generateContainsInternalCustomer(adaptedString.split(" "), splittedFields, mappedNumbersOnlyFields)
        : generateContainsInternal(adaptedString.split(" "), splittedFields);
      // toReturn += " or " +
      //   (type === 'Customer'
      //     ? generateContainsInternalCustomer(adaptedString.toTitleCase().split(" "), splittedFields, mappedNumbersOnlyFields)
      //     : generateContainsInternal(adaptedString.toTitleCase().split(" "), splittedFields));
      toReturn += ")";
      return toReturn;
    }

    function generateContainsInternal(splittedStrings, splittedFields) {
      var toReturn = "";
      angular.forEach(splittedStrings,
        function (currentWord, index) {
          toReturn += "(indexof(" + splittedFields[0] + ", '" + currentWord + "') gt -1)";
          for (let i = 1; i < splittedFields.length; i++) {
            toReturn += " or (indexof(" + splittedFields[i] + ", '" + currentWord + "') gt -1)";
          }

          if (index < splittedStrings.length - 1) {
            toReturn += " or ";
          }
        });

      return toReturn;

    }

    function generateContainsInternalCustomer(splittedStrings, splittedFields, digitsOnlyFields) {
      var toReturn = "";
      var joinedFullName = splittedStrings[0];

      joinedFullName = splittedStrings.join(' ');
      
     //old search version
      // toReturn += "((indexof(" + splittedFields[0] + ", '" + joinedFullName + "') eq 0)";
      // toReturn += " or (indexof(" + splittedFields[1] + ", '" + joinedFullName + "') eq 0))";
      // // First Then Last
      // toReturn += " or ((indexof(" + splittedFields[0] + ", '" + splittedStrings[0] + "') eq 0)";
      // toReturn += " and (indexof(" + splittedFields[1] + ", '" + splittedStrings[1] + "') eq 0))";
      
      toReturn += `(indexof(concat(concat(FirstName,' '),LastName),'${joinedFullName}') gt -1)`;
      if(splittedStrings.length > 1) {
        toReturn += `or (indexof(concat(concat(LastName,' '),FirstName),'${joinedFullName}') gt -1)`;
      }

      if (splittedFields[2]) {
        toReturn += " or ((indexof(" + splittedFields[2] + ", '" + joinedFullName + "') gt -1))";
      }

      if (splittedFields[3]) {
        toReturn += " or ((indexof(" + splittedFields[3] + ", '" + joinedFullName + "') gt -1))";
      }

      if (Array.isArray(digitsOnlyFields) && digitsOnlyFields.length > 0
        && splittedStrings.length === 1
        && /^\d+$/.test(splittedStrings[0])) {
        digitsOnlyFields.forEach((field) => {
          toReturn += " or ((indexof(" + field + ", '" + joinedFullName + "') gt -1))";
        })
      }

      return toReturn;
    }

    function generateProductOrService(params) {
      var usingExternalVouchers = true; // configurations.ProductsAndServicesSettings.UsingExternalVouchers;

      var productOrServiceFilter = [
        { name: localize.getLocalizedString("_BothProductsAndServices_"), value: null },
        {
          name: localize.getLocalizedString("_OnlyProducts_"),
          slug: 'products-only',
          value: '(ConcreteType eq \'ProductMetadata\' and IngredientOnly eq false)'
        },
        {
          name: localize.getLocalizedString("_OnlyServices_"),
          slug: 'services-only',
          value: '(ConcreteType eq \'ServiceMetadata\')'
        }
      ];

      if (params.includeIngredients) {
        var onlyIngredients = {
          name: localize.getLocalizedString("_OnlyIngredients_"),
          slug: 'ingredients-only',
          value: '(IngredientOnly eq true)'
        };
        productOrServiceFilter.push(onlyIngredients);
        productOrServiceFilter[0].name = localize.getLocalizedString("_AllElementTypes_");
      }

      if (usingExternalVouchers) {
        var onlyExternalVoucher = {
          name: localize.getLocalizedString("_OnlyExternalVoucher_"),
          slug: 'vouchers-only',
          value: '(ConcreteType eq \'ExternalVoucherMetadata\')'
        };
        productOrServiceFilter.push(onlyExternalVoucher);
        productOrServiceFilter[0].name = localize.getLocalizedString("_AllElementTypesWithVouchers_");
      }

      var toReturn = new filterLogicItem(productOrServiceFilter);
      if (params.useConfigurations) {
        var defaultFilter = configuration.get().CashRegisterSettings.DefaultFilterProductOrService.Value;
        if (defaultFilter !== 'default') {
          toReturn.defaultValue = productOrServiceFilter.filter(x => x.value === defaultFilter)[0];
        }
      }

      toReturn.order = 0;
      toReturn.type = "combo";

      return toReturn;
    }

    function generateServiceIngredientsProductCategoryFilter(categories) {
      var productCategories = [];
      productCategories.push({ name: localize.getLocalizedString("_All_"), value: null });
      angular.forEach(categories,
        function (category) {
          productCategories.push({ name: category.ProductCategoryName, value: category.ProductCategoryId });
        });

      var toReturn = new filterLogicItem(productCategories);
      toReturn.order = 0;
      toReturn.type = "combo";

      return toReturn;
    }

    function generateGenderFilter() {
      var genderFilter = [
        { name: localize.getLocalizedString("_Gender_"), value: null },
        { name: localize.getLocalizedString("_Males_"), value: '(Gender eq \'Male\')', slug: 'male' },
        { name: localize.getLocalizedString("_Females_"), value: '(Gender eq \'Female\')', slug: 'female' }
      ];

      var toReturn = new filterLogicItem(genderFilter);

      toReturn.order = 0;
      toReturn.type = "combo";

      return toReturn;
    }

    function nextHandlingTime() {

      var customStartFormat = 'YYYY-MM-DDT00:00:00';
      var customEndFormat = 'YYYY-MM-DDT23:59:59';

      var nextHandlingTimeFilter = [
        { name: localize.getLocalizedString("_AllTimes_"), value: null },
        {
          name: localize.getLocalizedString("_InThePast_"),
          value: "(NextHandlingTime le DateTime'" + moment().format(ODATA_DATE_ONLY_FORMAT) + "')",
          slug: 'in-the-past'
        },
        {
          name: localize.getLocalizedString("_This Week_"),
          value: "(NextHandlingTime le DateTime'" +
            moment().weekday(6).format(ODATA_DATE_ONLY_FORMAT) +
            "' and NextHandlingTime ge DateTime'" +
            moment().weekday(0).format(ODATA_DATE_ONLY_FORMAT) +
            "')",
          slug: 'this-week'
        },
        {
          name: localize.getLocalizedString("_NextWeek_"),
          value: "(NextHandlingTime le DateTime'" +
            moment().weekday(6).add(1, 'w').format(ODATA_DATE_ONLY_FORMAT) +
            "' and NextHandlingTime ge DateTime'" +
            moment().weekday(0).add(1, 'w').format(ODATA_DATE_ONLY_FORMAT) +
            "')",
          slug: 'next-week'
        },
        {
          name: localize.getLocalizedString("_Yesterday_"),
          value: "(NextHandlingTime ge DateTime'" +
            moment().add(-1, 'd').format(customStartFormat) +
            "' and NextHandlingTime lt DateTime'" +
            moment().add(-1, 'd').format(customEndFormat) +
            "')",
          slug: 'yesterday'
        },
        {
          name: localize.getLocalizedString("_Today_"),
          value: "(NextHandlingTime ge DateTime'" +
            moment().format(customStartFormat) +
            "' and NextHandlingTime lt DateTime'" +
            moment().format(customEndFormat) +
            "')",
          slug: 'today'
        },
        {
          name: localize.getLocalizedString("_Tomorrow_"),
          value: "(NextHandlingTime ge DateTime'" +
            moment().add(1, 'd').format(customStartFormat) +
            "' and NextHandlingTime le DateTime'" +
            moment().add(1, 'd').format(customEndFormat) +
            "')",
          slug: 'tomorrow'
        },
        {
          name: localize.getLocalizedString("_This Month_"),
          value: "(NextHandlingTime le DateTime'" +
            moment().endOf('month').format(ODATA_DATE_ONLY_FORMAT) +
            "' and NextHandlingTime ge DateTime'" +
            moment().startOf('month').format(ODATA_DATE_ONLY_FORMAT) +
            "')",
          slug: 'this-month'
        },
        {
          name: localize.getLocalizedString("_NextMonth_"),
          value: "(NextHandlingTime le DateTime'" +
            moment().add(1, 'month').endOf('month').format(ODATA_DATE_ONLY_FORMAT) +
            "' and NextHandlingTime ge DateTime'" +
            moment().add(1, 'month').startOf('month').format(ODATA_DATE_ONLY_FORMAT) +
            "')",
          slug: 'next-month'
        },
        {
          name: localize.getLocalizedString("_InTheFuture_"),
          value: "(NextHandlingTime gt DateTime'" +
            moment().format(ODATA_DATE_ONLY_FORMAT) +
            "' or NextHandlingTime eq null)",
          slug: 'in-the-future'
        }
      ];

      var toReturn = new filterLogicItem(nextHandlingTimeFilter);

      toReturn.order = 0;
      toReturn.type = "combo";

      return toReturn;
    }

    function generateEmployeesFilter() {
      var employeesFilter = [
        { name: localize.getLocalizedString("_All_"), value: null },
        { name: localize.getLocalizedString("_CheckedIn_"), value: '(OpenCheckInTime ne null)', slug: 'in-shift' },
        { name: localize.getLocalizedString("_CheckedOut"), value: '(OpenCheckInTime eq null)', slug: 'not-in-shift' }
      ];

      var toReturn = new filterLogicItem(employeesFilter);

      toReturn.order = 0;
      toReturn.type = "combo";

      return toReturn;
    }


    function generateSearchFilter(params) {
      var toReturn = {};
      toReturn.order = 10;
      toReturn.type = "button";
      toReturn.convertValue = function (string) {
        return string ? generateContains(string, params.fields, params.type, params.numbersOnlyFields) : null;
      };

      return toReturn;
    }

    function generateBackAction() {
      var toReturn = {};
      toReturn.order = 10;
      toReturn.type = "icon_button";
      toReturn.icon = localize.getLocalizedString("_BackIcon_");
      toReturn.text = localize.getLocalizedString("_Back_");
      toReturn.action = function () {
        $window.history.back();
      };

      return toReturn;
    }

    function generateNextAction(params) {
      var toReturn = {};
      toReturn.order = 11;
      toReturn.type = "icon_button";
      //toReturn.icon = localize.getLocalizedString("_BackIcon_");
      toReturn.text = localize.getLocalizedString("_NextCustomer_");
      toReturn.action = function () {
        params.goToNextCustomer();
      };

      return toReturn;
    }

    function generateEbPaymentAction(params) {
      var toReturn = {};
      toReturn.order = 12;
      toReturn.type = "icon_button";
      //toReturn.icon = localize.getLocalizedString("_BackIcon_");
      toReturn.text = localize.getLocalizedString("_Payment_");
      toReturn.action = function () {
        params.takeEbCustomerToCashRegister();
      };

      return toReturn;
    }

    function generateSimpleHeader(params) {
      var toReturn = {};
      toReturn.text = params.header;
      toReturn.type = "label";

      return toReturn;
    }

    function generateCustomerDetailsFilter() {
      var allFilters = [
        { name: localize.getLocalizedString("_All_"), value: null, defaultValue: true },
        { name: localize.getLocalizedString("_Visits_"), value: "Visit" },
        { name: localize.getLocalizedString("_Meetings_"), value: 'Meeting' },
        { name: localize.getLocalizedString("_Promotions_"), value: 'Promotion' },
        { name: localize.getLocalizedString("_PriceQuotes_"), value: 'PriceQuote' },
        { name: localize.getLocalizedString("_CustomCustomerNote_"), value: 'EntityNoteReminder' }
      ];

      Repository.Custom("CustomTimelineItem").getCategories()
        .then(function (categories) {
          categories.forEach(function (category) {
            allFilters.push({ name: category, value: { value: 'EntityNoteReminder', category: category } })
          });
        }).catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorLoadingCutomItemCategories_"), err);
        });

      var toReturn = new filterLogicItem(allFilters);
      toReturn.type = "combo";
      toReturn.label = localize.getLocalizedString("_Show_");
      toReturn.allowMultiple = true;
      toReturn.forceMultiple = true;
      toReturn.convertValue = function (selectedValues) {
        var resultToReturn = [];
        if (selectedValues.length > 0) {
          selectedValues.forEach(function (filter) {
            if (filter.value != null) {
              resultToReturn.push(filter.value);
            }
          });

          if (resultToReturn.length === 0) {
            resultToReturn = null;
          }
        }

        return resultToReturn;
      };

      return toReturn;
    }

    function generateLeadDetailsFilter() {
      var allFilters = [
        { name: localize.getLocalizedString("_All_"), value: null },
        { name: localize.getLocalizedString("_Meetings_"), value: 'Meeting' },
        { name: localize.getLocalizedString("_Promotions_"), value: 'Promotion' },
        { name: localize.getLocalizedString("_CustomTimelineItems_"), value: 'EntityNoteReminder' }
      ];

      var toReturn = new filterLogicItem(allFilters);
      toReturn.type = "combo";
      toReturn.label = localize.getLocalizedString("_Show_");
      toReturn.allowMultiple = true;
      toReturn.forceMultiple = true;
      toReturn.convertValue = function (selectedValues) {
        var resultToReturn = [];
        if (selectedValues.length > 0) {
          angular.forEach(selectedValues,
            function (filter) {
              if (filter.value != null) {
                resultToReturn.push(filter.value);
              }
            });

          if (resultToReturn.length == 0) {
            resultToReturn = null;
          }
        }

        return resultToReturn;
      };

      return toReturn;
    }

    function generateEmployeeDetailsFilter() {
      var allFilters = [
        { name: localize.getLocalizedString("_All_"), value: null },
        { name: localize.getLocalizedString("_CustomTimelineItems_"), value: 'EntityNoteReminder' }
      ];

      var toReturn = new filterLogicItem(allFilters);
      toReturn.type = "combo";
      toReturn.label = localize.getLocalizedString("_Show_");
      toReturn.allowMultiple = true;
      toReturn.forceMultiple = true;
      toReturn.convertValue = function (selectedValues) {
        var resultToReturn = [];
        if (selectedValues.length > 0) {
          angular.forEach(selectedValues,
            function (filter) {
              if (filter.value != null) {
                resultToReturn.push(filter.value);
              }
            });

          if (resultToReturn.length == 0) {
            resultToReturn = null;
          }
        }

        return resultToReturn;
      };

      return toReturn;
    }

    function generateProductDetailsFilter() {
      var allFilters = [
        { name: localize.getLocalizedString("_All_"), value: null },
        { name: localize.getLocalizedString("_Sold_"), value: "ProductSold" },
        { name: localize.getLocalizedString("_Bought_"), value: 'ProductPurchased' },
        { name: localize.getLocalizedString("_UsedInService_"), value: 'ServicePerformed' }
      ];

      var toReturn = new filterLogicItem(allFilters);
      toReturn.type = "combo";
      toReturn.label = localize.getLocalizedString("_Show_");
      toReturn.allowMultiple = true;
      toReturn.forceMultiple = true;
      toReturn.convertValue = function (selectedValues) {
        var resultToReturn = [];
        if (selectedValues.length > 0) {
          angular.forEach(selectedValues,
            function (filter) {
              if (filter.value != null) {
                resultToReturn.push(filter.value);
              }
            });

          if (resultToReturn.length == 0) {
            resultToReturn = null;
          }
        }

        return resultToReturn;
      };

      return toReturn;
    }

    function generateProductsFilter() {
      var productsFilterValue = [
        { name: localize.getLocalizedString("_AllProducts_"), value: null }
      ];

      var toReturn = new filterLogicItem(productsFilterValue);
      toReturn.type = "combo";
      toReturn.allowMultiple = false;
      toReturn.allowCategorySelection = true;
      toReturn.api = Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory().entity("product");

      toReturn.convertValue = function (selectedValue, categoryValue) {
        var queryString = null;
        if (categoryValue > 0) {
          queryString = "ProductCategories:[" + categoryValue + "]";
        }

        return queryString;
      };

      return toReturn;
    }

    function generateServicesFilter() {
      var servicesFilterValue = [
        { name: localize.getLocalizedString("_AllServices_"), value: null }
      ];

      var toReturn = new filterLogicItem(servicesFilterValue);
      toReturn.type = "combo";
      toReturn.allowMultiple = false;
      toReturn.allowCategorySelection = true;
      toReturn.api = Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory().entity("service");
      toReturn.convertValue = function (selectedValue, categoryValue) {
        var queryString = null;
        if (categoryValue > 0) {
          queryString = "ServiceCategories:[" + categoryValue + "]";
        }

        return queryString;
      };

      return toReturn;
    }

    function generateTagFilter(params) {
      var servicesFilterValue = [
        { name: localize.getLocalizedString("_AllTags_"), value: null }
      ];

      var toReturn = new filterLogicItem(servicesFilterValue);
      toReturn.type = "combo";
      toReturn.allowMultiple = false;
      toReturn.allowCategorySelection = true;
      toReturn.api = Repository.Custom("EntitiesLazyRepository").tags(params.entity);

      toReturn.convertValue = function (selectedValues, categoryValue) {
        if (!angular.isArray(selectedValues)) {
          return selectedValues > 0 ? [selectedValues] : null;
        }
        var resultToReturn = [];
        if (selectedValues && selectedValues.length > 0) {
          angular.forEach(selectedValues,
            function (filter) {
              if (filter.value != null) {
                resultToReturn.push(filter.value);
              }
            });

          if (resultToReturn.length == 0) {
            resultToReturn = null;
          }
        }

        return resultToReturn;
      };

      return toReturn;
    }

    function generateLeadByEmployeesFilter(params) {
      var employeesFilterValue = [
        { name: localize.getLocalizedString("_AllEmployees_"), value: null }
      ];

      var toReturn = new filterLogicItem(employeesFilterValue);
      toReturn.type = "combo";
      toReturn.allowMultiple = true;
      toReturn.allowCategorySelection = true;
      toReturn.api = Repository.Custom("EntitiesLazyRepository").employees();

      toReturn.convertValue = function (selectedValues) {
        if (!angular.isArray(selectedValues)) {
          return selectedValue > 0 ? '(EmployeeId eq ' + selectedValue + ')' : null;
        }
        var resultToReturn = '';
        if (selectedValues && selectedValues.length > 0) {
          angular.forEach(selectedValues,
            function (filter) {
              if (filter.value != null) {
                var value = 'EmployeeId eq ' + filter.value;
                resultToReturn = resultToReturn.length > 0 ? (resultToReturn + " or " + value) : '(' + value;
              }
            });

          if (resultToReturn.length === 0) {
            resultToReturn = null;
          } else {
            resultToReturn += ')';
          }
        }

        return resultToReturn;
      };

      return toReturn;
    }

    function formFilter() {
      const formsFilterValue = [
        { name: localize.getLocalizedString("_FilledFormIgnoredValue_"), value: null }
      ];

      const toReturn = new filterLogicItem(formsFilterValue);
      toReturn.api = Repository.Custom('Forms').formsApi();
      toReturn.type = "combo";
      toReturn.allowMultiple = false;
      toReturn.allowCategorySelection = false;
      toReturn.convertValue = function (selectedValue) {
        return (selectedValue && selectedValue.length > 0) ? '(FilledForm eq ' + selectedValue + ')' : null;
      };

      return toReturn;
    }

    function promotionStatusFilter() {
      const promotionStatusFilterValue = [
        { name: localize.getLocalizedString("_ActiveAndHistory_"), value: 'all' },
        { name: localize.getLocalizedString("_OnlyActive_"), value: 'active' },
        { name: localize.getLocalizedString("_OnlyHistory_"), value: 'history' }
      ];

      const toReturn = new filterLogicItem(promotionStatusFilterValue);
      toReturn.type = "combo";
      return toReturn;
    }

    function promotionMediumFilter() {
      const promotionMediumValues = [
        { name: localize.getLocalizedString("_AllCampaigns_"), value: 'all' },
        { name: localize.getLocalizedString("_Email_"), value: 'mail' },
        { name: localize.getLocalizedString("_Sms_"), value: 'sms' },
        { name: localize.getLocalizedString("_Whatsapp_"), value: 'whatsapp' },
        { name: localize.getLocalizedString("_Facebook_"), value: 'facebook' }
      ];

      const toReturn = new filterLogicItem(promotionMediumValues);
      toReturn.type = 'combo';
      return toReturn;
    }

    function generateManufacturersFilter() {
      var servicesFilterValue = [
        { name: localize.getLocalizedString("_AllManufacturers_"), value: null }
      ];

      var toReturn = new filterLogicItem(servicesFilterValue);
      toReturn.type = "combo";
      toReturn.allowMultiple = false;
      toReturn.allowCategorySelection = true;
      toReturn.api = Repository.Custom("EntitiesLazyRepository").manufacturers();
      toReturn.convertValue = function (selectedValue, categoryValue) {
        return selectedValue > 0 ? '(ManufacturerId eq ' + selectedValue + ')' : null;
      };

      return toReturn;
    }

    function generateArrivalSourcesFilter() {
      var servicesFilterValue = [
        { name: localize.getLocalizedString("_AllArrivalSources_"), value: null }
      ];

      var toReturn = new filterLogicItem(servicesFilterValue);
      toReturn.type = "combo";
      toReturn.allowMultiple = true;
      toReturn.allowCategorySelection = true;
      toReturn.api = Repository.Custom("EntitiesLazyRepository").arrivalsources();
      //toReturn.convertValue = function (selectedValue, categoryValue) {
      //  return selectedValue > 0 ? '(ArrivalSourceId eq ' + selectedValue + ')' : null;
      //};
      toReturn.convertValue = function (selectedValues) {
        if (!angular.isArray(selectedValues)) {
          return selectedValue > 0 ? '(ArrivalSourceId eq ' + selectedValue + ')' : null;
        }
        var resultToReturn = '';
        if (selectedValues && selectedValues.length > 0) {
          angular.forEach(selectedValues, function (filter) {
            if (filter.value != null) {
              var value = 'ArrivalSourceId eq ' + filter.value;
              resultToReturn = resultToReturn.length > 0 ? (resultToReturn + " or " + value) : '(' + value;
            }
          });

          if (resultToReturn.length === 0) {
            resultToReturn = null;
          } else {
            resultToReturn += ')';
          }
        }

        return resultToReturn;
      };

      return toReturn;
    }


    function generateSubStatusFilter() {
      var servicesFilterValue = [
        { name: localize.getLocalizedString("_AllSubStatuses_"), value: null }
      ];

      var toReturn = new filterLogicItem(servicesFilterValue);
      toReturn.type = "combo";
      toReturn.allowMultiple = true;
      toReturn.allowCategorySelection = true;
      toReturn.allowAddNewCategory = true;
      toReturn.addNewCategoryAction = function (newCategory) {
        Repository.Custom("LeadSubStatus").create({ LeadSubStatusName: newCategory.name }).then(function (category) {
          //todo: need to update the right values
          //values.push({ name: category.LeadSubStatusName, value: category.LeadSubStatusId });
        }).catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorLoadingCutomItemCategories_"), err);
        });
      };
      //toReturn.decorateCategoryCombo = function ($scope, element) {
      //  if ($scope.item && $scope.item.value) {
      //    $scope.deleteText = localize.getLocalizedString('_Delete_');
      //    $scope.approvalText = localize.getLocalizedString('_Sure_');
      //    var submitButton = '<div ng-click="removeTemplate($event, item)">{{approvalMode ? approvalText : deleteText}}</div>';

      //    $scope.removeTemplate = function (e, item) {
      //      e.preventDefault();
      //      e.stopPropagation();

      //      if (!$scope.approvalMode) {
      //        $scope.approvalMode = true;
      //        return $timeout(function () {
      //          delete $scope.approvalMode;
      //        }, 10000);
      //      }

      //      //scope.giftCardsTemplates.splice(scope.giftCardsTemplates.indexOf(item), 1);
      //      Repository.Custom("LeadSubStatus").remove(item.id)
      //        .catch(function () {
      //          toastr.error(localize.getLocalizedString('_ErrorDeletingTemplate_'));
      //        });
      //    };

      //    var decorationItem = '<div class="combobox-template-remove-decorator">' + submitButton + '</div>';
      //    //todo:find out why i cant inject compile
      //    //element.find('.combobox-list-item-concrete-value').append($compile(decorationItem)($scope));
      //  }
      //};

      toReturn.api = Repository.Custom("EntitiesLazyRepository").leadSubStatus();
      toReturn.convertValue = function (selectedValues) {
        if (!angular.isArray(selectedValues)) {
          return selectedValue > 0 ? '(LeadSubStatusId eq ' + selectedValue + ')' : null;
        }
        var resultToReturn = '';
        if (selectedValues && selectedValues.length > 0) {
          angular.forEach(selectedValues, function (filter) {
            if (filter.value != null) {
              var value = 'LeadSubStatusId eq ' + filter.value;
              resultToReturn = resultToReturn.length > 0 ? (resultToReturn + " or " + value) : '(' + value;
            }
          });

          if (resultToReturn.length === 0) {
            resultToReturn = null;
          } else {
            resultToReturn += ')';
          }
        }

        return resultToReturn;
      };

      return toReturn;
    }

    function filterLogicItem(values) {
      this.values = values;
      this.order = 0;
    }

    function expenseCategoryFilter() {
      var servicesFilterValue = [
        { name: localize.getLocalizedString('_General_'), value: null }
      ];

      var toReturn = new filterLogicItem(servicesFilterValue);
      toReturn.type = "combo";
      toReturn.allowMultiple = false;
      toReturn.allowCategorySelection = true;
      toReturn.api = Repository.Custom("EntitiesLazyRepository").expenseCategories();
      toReturn.convertValue = function (selectedValue, categoryValue) {
        return selectedValue > 0 ? '(ExpensesCategoryId eq ' + selectedValue + ')' : null;
      };

      return toReturn;
    }

    function dateRangeFilter(params) {
      var toReturn = { type: 'dateRange' };
      if (!params.defaultRange) {
        toReturn.defaultRange = {
          from: moment().startOf('month').add(-1, 'month'),
          to: moment().add(-1, 'month').endOf('month')
        }
      } else {
        toReturn.defaultRange = params.defaultRange;
      }

      if (params.convertValue) {
        toReturn.convertValue = params.convertValue;
      } else {
        toReturn.convertValue = function (selectedValues) {
          return 'start=' + moment(selectedValues.from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) + '&end=' + moment(selectedValues.to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
        };
      }


      return toReturn;

    }


  }]);


commonControlsModule.directive('filterToolBar', [
  'filterLogicFactory', 'stateManager', '$timeout', 'localize', function (filterLogicFactory, stateManager, $timeout, localize) {
    return {
      restrict: 'E',
      scope: {
        model: '=',
        listModeCombos: '=',
        scrollbarSizeAware: '@',
        stateManagerKey: '=',
        title: '@', // this for using the filtertoolbar as header.
        iconActions: '=actions',
        hideClearButton: '='
      },
      replace: true,
      link: function (scope) {
        scope.filters = [];
        let loadedFromStateValues = {};

        scope.$watchCollection('model.filters', function (newVal) {
          if (angular.isDefined(newVal)) {
            if (scope.stateManagerKey) {
              loadedFromStateValues = stateManager.currentState(scope.stateManagerKey);
            }

            scope.filters.length = 0;
            var lazy = false;
            angular.forEach(newVal, function (filter) {
              if (filter.rightSide) {
                return;
              }

              const filterLogicObject = filterLogicFactory.get(filter.type, filter.params);
              filterLogicObject.filterType = filter.type;
              var preLoadedValue = loadedFromStateValues[filter.type];
              if (filterLogicObject.type === "combo") {
                filterLogicObject.allowMultiple = filter.allowMultiple === true;
                filterLogicObject.selectedValues = filterLogicObject.allowMultiple ? [] : null;
                filterLogicObject.clear = function () {
                  filterLogicObject.selectedValue = filterLogicObject.values[0];

                  if (filterLogicObject.allowMultiple) {
                    filterLogicObject.selectedValues.length = 0;
                    angular.forEach(filterLogicObject.values, function (filterValue) {
                      filterValue.isSelected = false;
                    });
                  }

                };

                filterLogicObject.selectedValue = filterLogicObject.defaultValue ? filterLogicObject.defaultValue : filterLogicObject.values[0];
                if (preLoadedValue) {
                  selectValuesFromUrl(preLoadedValue, filterLogicObject);

                  if (filterLogicObject.api && !filterLogicObject.api.firstLoaded) {
                    lazy = true;
                    var methodToSetFilter = selectValuesFromUrl.bind(scope, preLoadedValue, filterLogicObject);
                    filterLogicObject.api.doneLoading = function () {
                      delete filterLogicObject.api.doneLoading;
                      methodToSetFilter();
                      filterChanged();
                    }

                  } else {
                    selectValuesFromUrl(preLoadedValue, filterLogicObject);
                  }
                }

              } else if (filterLogicObject.type === "button") {
                filterLogicObject.selectedValue = filter.valueWrapper;
                if (preLoadedValue) {
                  filterLogicObject.selectedValue.value = preLoadedValue;
                }

                filterLogicObject.clear = filter.clearWrapper.clear;
                scope.$watch(function () {
                  return filter.valueWrapper.value;
                }, function (newVal, oldVal) {
                  if (newVal !== oldVal) {
                    filterChanged();
                  }
                });
              } else if (filterLogicObject.type === 'dateRange') {
                let selectedRange = {
                  from: filter.params.defaultRange.from.format(DATE_DISPLAY_FORMAT),
                  to: filter.params.defaultRange.to.format(DATE_DISPLAY_FORMAT)
                };

                const preLoadedValue = loadedFromStateValues[filter.type];
                if (preLoadedValue) {
                  try {
                    const [from, to] = preLoadedValue.split('_').map((date) => {
                      const adapted = moment(date, URL_FRIENDLY_SHORT_DATE_FORMAT)
                      if (adapted.isValid()) {
                        return adapted.format(DATE_DISPLAY_FORMAT);
                      }

                      throw new Error('invalid date');
                    }, []);

                    selectedRange = { from, to };
                    filterLogicObject.selectedValue = { value: selectedRange };
                  } catch (e) {
                    // return false;
                  }
                }


                scope.$watch('selectedRange', function (newRange, previousRange) {
                  if (JSON.stringify(newRange) !== JSON.stringify(previousRange)) {
                    filterLogicObject.selectedValue = { value: newRange };
                    filterChanged();
                  }
                }, true);

                scope.selectedRange = selectedRange;
                filterLogicObject.selectedValue = { value: selectedRange };
              }

              filterLogicObject.valueChangedFunction = function () {
                var filterType = filter.type;
                var that = this;
                return function (newValue) {
                  // TODO: this is a hack to solve angular's not working binding in this position.
                  // TODO: Any better solution or explanation why it doesn't work?
                  that.selectedValue = newValue;
                  filterChanged(filterType, newValue);
                };
              };
              filterLogicObject.valueChangedFunction = filterLogicObject.valueChangedFunction.call(filterLogicObject);

              scope.filters.push(filterLogicObject);
            });

            if (!lazy) {
              $timeout(filterChanged);
            }
          }
        });

        function selectValuesFromUrl(valueFromURL, filterLogicObject) {
          const filteredValues = filterLogicObject.values.filter(function (comboValue) {

            // This handles the case of a url that includes multiple values separated with comma, whereas the values of the combo are actually values inside an object.
            const adaptedSeparatedValues = filterLogicObject.allowMultiple && angular.isString(valueFromURL) && valueFromURL.split(',');
            // valueFromURL must be string (it came from the URL - hence - String.
            const comboValueAsString =
              comboValue.slug || (comboValue.value ? comboValue.value.toString() : comboValue.value);
            const comboCategoryValueAsString = comboValue.categoryValue ? comboValue.categoryValue.toString() : comboValue.categoryValue;


            return (comboValue.slug && valueFromURL === comboValue.slug) ||
              (valueFromURL === comboValueAsString) ||
              (valueFromURL === comboCategoryValueAsString) ||
              (adaptedSeparatedValues && (adaptedSeparatedValues.includes(comboValueAsString) || adaptedSeparatedValues.includes(comboCategoryValueAsString)));
          });

          if (!filterLogicObject.allowMultiple) {
            filterLogicObject.selectedValue = filteredValues[0] || null;

          } else {
            filterLogicObject.selectedValues = filteredValues;
            if (filteredValues.length > 0) {
              filterLogicObject.selectedValue = null;
            }

          }
        }

        scope.clear = function (filterIndex) {
          scope.filters[filterIndex].clear();
        };

        scope.clearAll = function () {
          angular.forEach(scope.filters, function (filter) {
            if (angular.isFunction(filter.clear)) {
              filter.clear();
            }
          });

          filterChanged();
        };

        scope.selectMode = function (mode) {
          scope.model.modes.forEach(function (modeItr) {
            if (modeItr != mode) {
              modeItr.isSelected = false;
            } else {
              modeItr.isSelected = true;
            }
          })
        };

        scope.hasNonTrivialFilter = undefined;

        function filterChanged() {
          var filtersByName = {};
          var filtersSlug = {};
          scope.hasNonTrivialFilter = false;
          angular.forEach(scope.filters, function (filter) {
            if (filter.hasOwnProperty('disabledWrapper') && filter.disabledWrapper.disabled) {
              filtersByName[filter.filterType] = null;
            } else if (filter.allowMultiple) {

              let selectedValues = filter.convertValue(filter.selectedValues);
              let selectedSlugs = filter.selectedValues.map((x) => x.slug);

              if (selectedValues.length === 0 && filter.selectedValue) {
                selectedValues = filter.convertValue([filter.selectedValue]);
                selectedSlugs = [filter.selectedValue.slug];
              }

              // Only valid values should be here.
              selectedSlugs = selectedSlugs.filter(x => !!x);
              if (selectedSlugs.length === 0 && Array.isArray(selectedValues)) {
                selectedSlugs = selectedValues;
              }

              filtersByName[filter.filterType] = selectedValues;
              filtersSlug[filter.filterType] = selectedSlugs.length > 0 ? selectedSlugs.join(',') : null;
            } else if (filter.selectedValue) {
              filtersByName[filter.filterType] = angular.isDefined(filter.convertValue)
                ? filter.convertValue(filter.selectedValue.value, filter.selectedValue.categoryValue)
                : filter.selectedValue.value;
              filtersSlug[filter.filterType] = filter.selectedValue.slug || filter.selectedValue.categoryValue || (angular.isString(filter.selectedValue.value) ? filter.selectedValue.value : null);
              if (!filtersSlug[filter.filterType] && Object.getSafely(['selectedValue', 'value', 'from'], filter)) {
                const adaptedFrom = moment(filter.selectedValue.value.from, DATE_DISPLAY_FORMAT).format(URL_FRIENDLY_SHORT_DATE_FORMAT);
                const adaptedTo = moment(filter.selectedValue.value.to, DATE_DISPLAY_FORMAT).format(URL_FRIENDLY_SHORT_DATE_FORMAT);
                filtersSlug[filter.filterType] = `${adaptedFrom}_${adaptedTo}`;
              }
            }

            scope.hasNonTrivialFilter = !!(scope.hasNonTrivialFilter || filtersByName[filter.filterType]);
          });

          if (scope.model.filterStateChanged) {
            scope.model.filterStateChanged(filtersByName, filtersSlug);
          }

          if (scope.stateManagerKey) {
            stateManager.setState(scope.stateManagerKey, filtersSlug, false, false)
          }
        }

        scope.rangeMetadataFrom = {
          fieldName: 'from',
          icon: "icon icon-calendar",
          placeholder: localize.getLocalizedString('_FromDate_'),
          validation: "{'required': true}",
          yearRange: "-5:+1",
          type: 'date'
        };

        scope.rangeMetadataTo = {
          fieldName: 'to',
          icon: "icon icon-calendar",
          placeholder: localize.getLocalizedString('_ToDate_'),
          validation: "{'required': true}",
          yearRange: "-5:+1",
          type: 'date'
        };


      },
      template: require('./filter.toolbar.directive.html')
    };
  }]);
