<template>
  <div class="vue-credit-card-input">
    <div class="card-form">
      <div class="card-form__inner">
        <div class="card-input">
          <label for="cardNumber" class="card-input__label">{{ inputLabels.card }}</label>
          <div class="relative-wrapper">
            <input id="cardNumber" class="card-input__input"
                   :disabled="inProgress"
                   v-mask="generateCardNumberMask"
                   v-model="cardNumber" data-ref="cardNumber"
                   type="search"
                   autocomplete="off">
            <div class="card-input__icon" :class="cardClass">

            </div>

          </div>
        </div>
        <div class="card-input" v-if="requireId">
          <label for="idNumber" class="card-input__label">{{ inputLabels.id }}</label>
          <input type="text" id="idNumber" class="card-input__input"
                 :disabled="inProgress"
                 v-model="id"
                 data-ref="idNumber" autocomplete="off">
        </div>
        <div class="card-input" v-if="requireAuthNumber">
          <label for="authNumber" class="card-input__label">{{ inputLabels.authNumber }}</label>
          <input type="text" id="authNumber" class="card-input__input"
                 :disabled="inProgress"
                 v-model="authNumber"
                 data-ref="idNumber" autocomplete="off">
        </div>
        <div class="card-form__row">
          <div class="card-form__col">
            <div class="card-form__group">
              <label for="cardMonth" class="card-input__label">{{ inputLabels.expiration }}</label>
              <select class="card-input__input -select"
                      :disabled="inProgress"
                      id="cardMonth" v-model="cardMonth"
                      data-ref="cardDate">
                <option value="" disabled selected>{{ inputLabels.month }}</option>
                <option v-bind:value="n < 10 ? '0' + n : n" v-for="n in 12" v-bind:disabled="n < minCardMonth"
                        v-bind:key="n">
                  {{ n < 10 ? '0' + n : n }}
                </option>
              </select>
              <select class="card-input__input -select" id="cardYear"
                      :disabled="inProgress"
                      v-model="cardYear"
                      data-ref="cardDate">
                <option value="" disabled selected>{{ inputLabels.year }}</option>
                <option v-bind:value="$index + minCardYear" v-for="(n, $index) in 12" v-bind:key="n">
                  {{ $index + minCardYear }}
                </option>
              </select>

            </div>
          </div>
          <div class="card-form__col -cvv" v-if="requireCvv">
            <div class="card-input">
              <label for="cardCvv" class="card-input__label">CVV</label>
              <input type="text" class="card-input__input" id="cardCvv"
                     :disabled="inProgress"
                     v-mask="'####'" maxlength="4" v-model="cardCvv"
                     autocomplete="off">
            </div>
          </div>
        </div>

        <Button :click="_charge" :in-progress="inProgress">
          {{ submitText }}
        </Button>
      </div>
    </div>
  </div>

</template>

<script>
import Button from './button.vue';
import { nonEmptyString } from "../../common/common-js-functions/common-js-new";

export default {
  name: 'vue-credit-card-input',
  components: { Button },
  props: {
    requireCvv: {
      type: Boolean,
      default: false
    },
    refund: {
      type: Boolean,
      default: false
    },
    requireId: {
      type: Boolean,
      default: false,
    },
    requireAuthNumber: {
      type: Boolean,
      default: false,
    },
    charge: {
      type: Function
    },
    inProgress: {
      type: Boolean,
      default: false
    },
    submitCustomText: {
      type: String,
      default: null
    },
    savedCard: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      id: "",
      cardNumber: "",
      charging: false,
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      authNumber: null,
      minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      inputLabels: {
        card: translate('_CardNumber_'),
        id: translate('_IdNumber_'),
        expiration: translate('_ExpirationDate_'),
        month: translate('_Month_'),
        year: translate('_Year_'),
        authNumber: translate('_PhoneApproval_')
      }
    }
  },
  mounted() {
    this.cardNumberTemp = this.otherCardMask;
    setTimeout(() => {
      document.getElementById('cardNumber').focus();
    }, 250);

    this.$watch(vm => [vm.id, vm.cardNumber, vm.cardMonth, vm.cardYear, vm.cardCvv, vm.authNumber], (val) => {
      this.$emit('card-details-changed', {
        id: val[0],
        cardNumber: val[1],
        cardMonth: val[2],
        cardYear: val[3],
        cardCvv: val[4],
        authNumber: val[5]
      });

    }, {
      immediate: true, // run immediately
      deep: false // detects changes inside objects. not needed here, but maybe in other cases
    })

    if (this.savedCard) {
      this.id = this.savedCard.id || '';
      this.cardNumber = this.savedCard.cardNumber || '';
      this.cardMonth = this.savedCard.cardMonth || '';
      this.cardYear = this.savedCard.cardYear || '';
      this.cardCvv = this.savedCard.cardCvv || '';
      this.authNumber = this.savedCard.authNumber || '';
    }

  },
  computed: {
    getCardType() {
      let number = this.cardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      re = new RegExp('^9792')
      if (number.match(re) != null) return 'troy'

      return "visa"; // default type
    },
    cardClass() {
      if (this.cardNumber.length >= 4) {
        switch (this.getCardType) {
          case 'visa':
            return 'icon icon-visa'
            break;
          case 'amex':
            return 'icon icon-american-express'
            break;
          case 'mastercard':
            return 'icon icon-mastercard'
            break;
          case 'discover':
            return 'icon icon-discover'
            break;
          default:
            return '';
        }
      }
    },
    generateCardNumberMask() {
      return this.getCardType === "amex" ? this.amexCardMask : this.otherCardMask;
    },
    minCardMonth() {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    },
    submitText() {
      return this.submitCustomText ||
        (this.refund ? window.translate('_DoRefund_') : window.translate('_Charge_'));
    }
  },
  watch: {
    cardYear() {
      if (this.cardMonth < this.minCardMonth) {
        this.cardMonth = "";
      }
    }
  },
  methods: {
    _getCardDetails() {
      const expirationMmYy = `${ this.cardMonth || '' }${ (this.cardYear % 100) || '' }`;

      const cardDetails = {
        cardNumber: (this.cardNumber || '').replace(/ /g, ''),
        expirationMmYy,
        cvv: (this.requireCvv && this.cardCvv) || null,
        id: (this.requireId && this.id) || null
      };

      if (nonEmptyString(this.authNumber)) {
        cardDetails.authNum = this.authNumber;
      }

      return cardDetails;
    },
    _charge() {
      if (this.charging) {
        return console.log('Already charging.');
      }

      if (!nonEmptyString(this.cardNumber) || this.cardNumber.length < 6) {
        return window.toastr.error(window.translate('_InvalidCreditCardNumber_'));
      }

      if (this.requireId && (!nonEmptyString(this.id) || this.id.length < 8)) {
        return window.toastr.error(window.translate('_InvalidIdNumber_'));
      }

      if (this.requireAuthNumber && (!nonEmptyString(this.authNumber) || this.authNumber.length < 4)) {
        return window.toastr.error(window.translate('_InvalidAuthNumber_'));
      }

      if (!nonEmptyString(this.cardYear + '') || !nonEmptyString(this.cardMonth + '')) {
        return window.toastr.error(window.translate('_InvalidExpirationDate_'));
      }

      if (this.requireCvv && (!nonEmptyString(this.cardCvv) || this.cardCvv.length < 3)) {
        return window.toastr.error(window.translate('_InvalidCvv_'));
      }

      this.charging = true;
      setTimeout(() => {
        this.charging = false;
      }, 1000);

      this.charge(this._getCardDetails());
    }
  }
}
</script>

<style lang="less">
@import "../../../styles/variables";

.vue-credit-card-input {
  display: flex;
  @media screen and (max-width: 700px), (max-height: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .card-form {
    max-width: 570px;
    margin: auto;
    width: 100%;

    @media screen and (max-width: 576px) {
      margin: 0 auto;
    }

    &__inner {
      padding: 15px 35px;

      @media screen and (max-width: 480px) {
        padding: 25px;
      }
      @media screen and (max-width: 360px) {
        padding: 15px;
      }
    }

    &__row {
      //direction: ltr;
      display: flex;
      align-items: flex-start;
      @media screen and (max-width: 480px) {
        flex-wrap: wrap;
      }
    }

    &__col {
      flex: auto;
      //margin-right: 35px;

      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 480px) {
        margin-right: 0;
        flex: unset;
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.-cvv {
        max-width: 150px;
        @media screen and (max-width: 480px) {
          max-width: initial;
        }
      }
    }

    &__group {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .card-input__input {
        flex: 1;
        margin-left: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__button {
      width: 100%;
      height: 55px;
      background: #2364d2;
      border: none;
      border-radius: 5px;
      font-size: 22px;
      font-weight: 500;
      font-family: "Source Sans Pro", sans-serif;
      box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
      color: #fff;
      margin-top: 20px;
      cursor: pointer;

      @media screen and (max-width: 480px) {
        margin-top: 10px;
      }
    }
  }

  .vue-button {
    width: 100%;
    height: 55px;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    box-shadow: 3px 5px 10px 0 rgba(35, 100, 210, 0.3);
    margin-top: 20px;

    cursor: pointer;

    .vue-button__container {
      max-width: none;
      width: 100%;

    }

    button {
      font-size: 22px;
      text-align: center;
      width: 100%;

    }

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }

  .card-list {
    margin-bottom: -130px;

    @media screen and (max-width: 480px) {
      margin-bottom: -120px;
    }
  }

  .card-input {
    margin-bottom: 20px;

    &__label {
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: 500;
      color: #1a3b5d;
      width: 100%;
      display: block;
      user-select: none;
      direction: rtl;
    }

    &__input {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      box-shadow: none;
      border: 1px solid #ced6e0;
      transition: all 0.3s ease-in-out;
      font-size: 18px;
      padding: 5px 15px;
      background: none;
      color: #1a3b5d;
      font-family: "Source Sans Pro", sans-serif;
      direction: ltr;

      &:hover,
      &:focus {
        border-color: #3d9cff;
      }

      &:focus {
        box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
      }

      &.-select {
        -webkit-appearance: none;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
        background-size: 12px;
        background-position: 10% center;
        background-repeat: no-repeat;
        padding-left: 30px;
        direction: rtl;
      }
    }

    &__icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding-left: 15px;
      padding-right: 15px;
      font-size: 50px;
    }
  }
}
</style>
