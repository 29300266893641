import './online.scheduling.availability.directive.less'

import {
  DATE_DISPLAY_FORMAT,
  HOURS_AND_MINUTES_FORMAT,
  ODATA_DATE_ONLY_FORMAT,
  ODATA_DATE_TIME_FORMAT,
  SERVER_DATA_DATE_FORMAT
} from "../../../constants";

(function () {
  angular.module('easybizy.online.scheduling').service('onlineSchedulingAvailabilityStore', ['Repository', 'configuration', '$q', '$rootScope',
    function (Repository, configuration, $q, $rootScope) {
      var LOADING_EVENT = 'online-scheduling-loading';

      var kDayKey = 'YYMMDD';
      var settings = configuration.get();

      var minutesBetween = settings.CalendarSettings.MinimumMeetingLengthInMinutes.Value;
      var calendarStartTime = moment(settings.CalendarSettings.StartTime.Value, HOURS_AND_MINUTES_FORMAT);
      var calendarEndTime = moment(settings.CalendarSettings.EndTime.Value, HOURS_AND_MINUTES_FORMAT);
      // Make sure the length is a whole number within range.
      var defaultShiftLength = Math.floor(calendarEndTime.diff(calendarStartTime, 'minutes') / 2 / minutesBetween) * minutesBetween;
      // BIND TO THIS>
      // var loadRangeForDate = _loadRangeForDate.bind(this);
      var iterateDateInRange = _iterateDateInRange.bind(this);
      var loadForRange = _loadForRange.bind(this);
      var availabilitiesLoaded = _availabilitiesLoaded.bind(this);
      var generateAndInsertAvailability = _generateAndInsertAvailability.bind(this);
      var availabilityByDateAsync = this.availabilityByDateAsync = _availabilityByDateAsync.bind(this);
      var resolveDelegateForDate = _resolveDelegateForDate.bind(this);
      var getMetadata = _getMetadata.bind(this);
      var handleResponse = _handleResponse.bind(this);

      var daysToKeepInMemory = {
        forward: 7,
        backward: -7
      };

      // Exposed methods.
      this.availabilityByDate = {};
      this.reload = _reload.bind(this);
      this.generateAvailabilityForDate = generateAvailabilityForDate;
      var firstDayOfWork = -1, lastDayOfWork;
      var loadingStatusByDate = {}, loadingDelegatesByDate = {};
      this.toDateKey = function (date) {
        return date.format(kDayKey);
      };

      settings.CalendarSettings.DaysOfWork.forEach(function (dayOfWork) {
        if (dayOfWork.IsEnabled) {
          if (firstDayOfWork === -1) {
            firstDayOfWork = dayOfWork.Index;
          }

          lastDayOfWork = dayOfWork.Index;
        }
      });


      this.createOrUpdateAvailability = _createOrUpdateAvailability.bind(this);
      this.deleteAvailability = _deleteAvailability.bind(this);

      function _createOrUpdateAvailability(availability, updateFutureAvailabilities) {
        var toSend = {
          Availability: availability.json,
          Metadata: getMetadata(availability, updateFutureAvailabilities)
        };

        return Repository.Custom("OnlineScheduling").createOrUpdateAvailability(toSend).then(handleResponse);
      }

      function _deleteAvailability(availability, updateFutureAvailabilities) {
        var toSend = {
          Availability: availability.json,
          Metadata: getMetadata(availability, updateFutureAvailabilities)
        };

        return Repository.Custom("OnlineScheduling").deleteAvailability(toSend).then(handleResponse);
      }

      /*
	   * AffectedFromDate: "2018-09-30T00:00:00"
		 AffectedToDate: "2018-11-17T00:00:00"
		 AvailabilityDateBeforeModification: "2018-10-16T16:00:00"
		 UpdateFutureAvailabilities: ?
	   * */
      function _getMetadata(availability, updateFutureAvailabilities) {
        var visibleRange = this.visibleRange;
        var toReturn = { AffectedFromDate: visibleRange.startDate, AffectedToDate: visibleRange.endDate };
        toReturn.AvailabilityDateBeforeModification = availability.shifts[0].start.format(ODATA_DATE_TIME_FORMAT);
        if (angular.isDefined(updateFutureAvailabilities)) {
          toReturn.UpdateFutureAvailabilities = updateFutureAvailabilities;
        }
        return toReturn;
      }

      function _availabilityByDateAsync(date) {
        var deferred = $q.defer();

        var dayKey = date.format(kDayKey);
        var loadingStatus = loadingStatusByDate[dayKey];
        if (['loaded', 'error'].indexOf(loadingStatus) > -1) {
          if (loadingStatus === 'loaded') {
            deferred.resolve(this.availabilityByDate[dayKey]);
          } else {
            deferred.reject();
          }
        } else {
          loadingDelegatesByDate[dayKey] = loadingDelegatesByDate[dayKey] || [];
          loadingDelegatesByDate[dayKey].push(deferred);

          if (!loadingStatus) {
            loadForRange(date);
          }
        }

        return deferred.promise;
      }

      function _reload() {
        this.availabilityByDate = {};
        loadingStatusByDate = {};
        this.availabilityByDateAsync(this.visibleDate);
      }

      function _iterateDateInRange(date, callback) {
        var range = getRangeForDate(date);
        var startDateItr = range.startDate.clone();
        while (startDateItr.isBefore(range.endDate)) {
          callback.call(this, startDateItr, range.endDate);
          startDateItr.add(1, 'd');
        }

        return range;
      }

      function getRangeForDate(date) {
        var startDate = date.clone().weekday(0).add(daysToKeepInMemory.backward, 'days');
        var endDate = date.clone().weekday(0).add(daysToKeepInMemory.forward + 7 /*Always from firstday.*/, 'days');
        return { startDate: startDate, endDate: endDate };
      }

      function _loadForRange(date) {
        var range = iterateDateInRange(date, function (currentDate) {
          loadingStatusByDate[currentDate.format(kDayKey)] = 'loading';
        });

        $rootScope.$emit(LOADING_EVENT, true);

        Repository.Custom("OnlineScheduling").loadAvailabilities(range.startDate.format(ODATA_DATE_TIME_FORMAT), range.endDate.format(ODATA_DATE_TIME_FORMAT))
          .then(function (results) {
            availabilitiesLoaded(date, results);
            $rootScope.$emit(LOADING_EVENT, false);
          })
          .catch(function (err) {
            iterateDateInRange(date, function (currentDate) {
              loadingStatusByDate[currentDate.format(kDayKey)] = 'error';
              resolveDelegateForDate(currentDate, null, err);
            });

            $rootScope.$emit(LOADING_EVENT, false);
          });
      }

      function _availabilitiesLoaded(date, results) {
        results.forEach(generateAndInsertAvailability);
        iterateDateInRange(date, function (currentDate) {
          loadingStatusByDate[currentDate.format(kDayKey)] = 'loaded';
          resolveDelegateForDate(currentDate, results);
        });

      }

      function _generateAndInsertAvailability(iAvailability) {
        var availability = new OnlineBookingAvailability(iAvailability);
        // FIGURE OUT THE CONCRETE RELEVANT DATE (because of repeating things).
        var concreteDateRelated = availability.shifts[0].start.format(kDayKey);
        // noinspection JSPotentiallyInvalidUsageOfThis
        this.availabilityByDate[concreteDateRelated] = availability
        resolveDelegateForDate(availability.shifts[0].start, availability);
      }

      function _resolveDelegateForDate(date, result, error) {
        var formatDate = date.format(kDayKey);
        if (loadingDelegatesByDate[formatDate]) {
          var clonedDelegatesArray = loadingDelegatesByDate[formatDate];
          delete loadingDelegatesByDate[formatDate]; // Remove delegates.
          clonedDelegatesArray.forEach(function (delegate) {
            if (error) {
              delegate.reject(result);
            } else {
              delegate.resolve(result);
            }
          });
        }
      }

      function _handleResponse(response) {
        if (response.Removed) {
          (Array.isArray(response.Removed) ? response.Removed : [response.Removed]).forEach(toRemove => {
            var date = moment(toRemove.From, SERVER_DATA_DATE_FORMAT);
            delete this.availabilityByDate[date.format(kDayKey)];
            resolveDelegateForDate(date, {});

            if (toRemove.RepeatWeekly) {
              do {
                date = date.add(7, 'days');
                delete this.availabilityByDate[date.format(kDayKey)];
                resolveDelegateForDate(date, {});
              } while (this.availabilityByDate[date.format(kDayKey)]);


            }
          });
        }

        if (response.Inserted) {
          response.Inserted.forEach(generateAndInsertAvailability);
        }
      }

      Object.defineProperty(this, 'visibleRange', {
        get: function () {
          var toReturn = Object.keys(loadingStatusByDate).sort();
          return {
            startDate: moment(toReturn[0], kDayKey).format(ODATA_DATE_TIME_FORMAT),
            endDate: moment(toReturn.pop(), kDayKey).format(ODATA_DATE_TIME_FORMAT)
          };
        }
      });

      Object.defineProperty(this, 'visibleDate', (function () {
        var _visibleDate = moment();
        return {
          get: function () {
            return _visibleDate;
          },
          set: function (d) {
            _visibleDate = moment(d);
          }
        }
      }()));


      this.OnlineBookingAvailability = OnlineBookingAvailability;
      this.OnlineBookingAvailabilityPart = OnlineBookingAvailabilityPart;

      // Represents a day or shift portion.
      function OnlineBookingAvailability(availabilityOrDate) {
        // Generate availability from moment.
        if (moment.isMoment(availabilityOrDate)) {
          this.From = availabilityOrDate.clone().format(ODATA_DATE_ONLY_FORMAT);
          this.RepeatWeekly = true;

          this.Until = null;
          this.OnlineBookingAvailabilityParts = [generateShiftForDate(availabilityOrDate)];

        } else {
          Object.assign(this, availabilityOrDate);
          this.OnlineBookingAvailabilityParts = availabilityOrDate.OnlineBookingAvailabilityParts.map(function (shift) {
            return new OnlineBookingAvailabilityPart(shift);
          });
        }

        // From - UNTIL for repeats., RepeatWeekly has to be true.
      }


      Object.defineProperty(OnlineBookingAvailability.prototype, 'id', {
        get: function () {
          return this.OnlineBookingAvailabilityId;
        }
      });

      Object.defineProperty(OnlineBookingAvailability.prototype, 'shifts', {
        get: function () {
          return this.OnlineBookingAvailabilityParts;
        }
      });

      Object.defineProperty(OnlineBookingAvailability.prototype, 'repeats', {
        get: function () {
          return this.RepeatWeekly;
        }
      });

      Object.defineProperty(OnlineBookingAvailability.prototype, 'canAddShift', {
        get: function () {
          return moment().grabTime(this.shifts[this.shifts.length - 1].end).isBefore(calendarEndTime);
        }
      });

      Object.defineProperty(OnlineBookingAvailability.prototype, 'ends', {
        get: function () {
          return !!this.Until;
        },
        set: function (value) {
          if (!value) {
            this.Until = null;
          } else {
            this.Until = this.shifts[0].start.clone().add(7, 'days').format(SERVER_DATA_DATE_FORMAT);
          }
        }
      });

      Object.defineProperty(OnlineBookingAvailability.prototype, 'until', {
        get: function () {
          return !this.Until ? null : moment(this.Until, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT);
        },
        set: function (value) {
          this.Until = moment(value, DATE_DISPLAY_FORMAT).format(SERVER_DATA_DATE_FORMAT);
        }
      });

      Object.defineProperty(OnlineBookingAvailability.prototype, 'json', {
        get: function () {
          var toReturn = {
            From: this.From,
            Until: this.Until,
            RepeatWeekly: this.RepeatWeekly,
            OnlineBookingAvailabilityParts: this.shifts.map(function (shift) {
              return shift.json;
            })
          };

          if (this.id) {
            toReturn.OnlineBookingAvailabilityId = this.id;
          }

          return toReturn;
        }
      });


      OnlineBookingAvailability.prototype.getMinStartTimeForShift = function (shift) {
        var indexOfShift = this.shifts.indexOf(shift);
        var toReturn = calendarStartTime;
        if (indexOfShift !== 0) {
          toReturn = this.shifts[indexOfShift - 1].end;
        }

        var adaptedTime = moment().grabTime(toReturn);
        if (!shift.__minStartTimeShift || !adaptedTime.isSameTime(shift.__minStartTimeShift)) {
          shift.__minStartTimeShift = adaptedTime;
        }

        return shift.__minStartTimeShift;
      };

      OnlineBookingAvailability.prototype.getMaxEndTimeForShift = function (shift) {
        var toReturn = calendarEndTime;
        var indexOfShift = this.shifts.indexOf(shift);
        if (this.shifts.length !== 1 && indexOfShift + 1 !== this.shifts.length) {
          toReturn = this.shifts[indexOfShift + 1].start;
        }

        var adaptedTime = moment().grabTime(toReturn);
        if (!shift.__maxEndTimeShift || !adaptedTime.isSameTime(shift.__maxEndTimeShift)) {
          shift.__maxEndTimeShift = adaptedTime;
        }

        return shift.__maxEndTimeShift;
      };

      OnlineBookingAvailability.prototype.removeShift = function (shift) {
        this.shifts.remove(shift);
      };

      OnlineBookingAvailability.prototype.addShift = function () {
        if (!this.canAddShift) {
          return;
        }

        var prevShift = this.shifts[this.shifts.length - 1];
        var startTime = prevShift.end;
        var endTime = startTime.clone().add(defaultShiftLength, 'minutes');
        var shiftToAdd = generateShiftForDate(this.shifts[0].start, startTime, endTime.isBefore(calendarEndTime) ? endTime : calendarEndTime);
        this.shifts.push(shiftToAdd);
      };

      // Represents a portion inside a day shifts. (from - to).
      function OnlineBookingAvailabilityPart(part) {
        Object.assign(this, part);
        this._startTime = moment(this.StartTime, SERVER_DATA_DATE_FORMAT);
        this._endTime = moment(this.EndTime, SERVER_DATA_DATE_FORMAT);
      }

      Object.defineProperty(OnlineBookingAvailabilityPart.prototype, 'startTime', {
        get: function () {
          return this._startTime.format(HOURS_AND_MINUTES_FORMAT);
        }
      });


      Object.defineProperty(OnlineBookingAvailabilityPart.prototype, 'start', {
        get: function () {
          return this._startTime;
        },
        set: function (val) {
          this._startTime = this._startTime.grabTime(val);
          this.StartTime = val.format(HOURS_AND_MINUTES_FORMAT);
        }
      });

      Object.defineProperty(OnlineBookingAvailabilityPart.prototype, 'endTime', {
        get: function () {
          return this._endTime.format(HOURS_AND_MINUTES_FORMAT);
        }
      });

      Object.defineProperty(OnlineBookingAvailabilityPart.prototype, 'end', {
        get: function () {
          return this._endTime;
        },
        set: function (val) {
          this._endTime = this._endTime.grabTime(val);
          this.EndTime = val.format(HOURS_AND_MINUTES_FORMAT);
        }
      });

      Object.defineProperty(OnlineBookingAvailabilityPart.prototype, 'priority', {
        get: function () {
          return this.Rate;
        },
        set: function (val) {
          this.Rate = val;
        }
      });

      Object.defineProperty(OnlineBookingAvailabilityPart.prototype, 'approval', {
        get: function () {
          var toReturn = this.NeedsApproval ? 1 : 0;
          if (this.NeedsCreditToken) {
            toReturn += 2;
          }

          return toReturn;
        },
        set: function (val) {
          this.NeedsApproval = val > 0 && val !== 2;
          this.NeedsCreditToken = val > 1;
        }
      });

      Object.defineProperty(OnlineBookingAvailabilityPart.prototype, 'priorityClass', {
        get: function () {
          return toPriority(this.Rate) + '-priority';

          function toPriority(value) {
            return value === 0 ? 'low' : (value === 1 ? 'default' : 'high');
          }
        }
      });

      Object.defineProperty(OnlineBookingAvailabilityPart.prototype, 'json', {
        get: function () {
          var toReturn = {
            StartTime: moment.isMoment(this.StartTime) ? this.StartTime.format(ODATA_DATE_TIME_FORMAT) : this.StartTime,
            EndTime: moment.isMoment(this.EndTime) ? this.EndTime.format(ODATA_DATE_TIME_FORMAT) : this.EndTime,
            Rate: this.Rate,
            NeedsApproval: this.NeedsApproval,
            NeedsCreditToken: this.NeedsCreditToken
          };

          if (this.OnlineBookingAvailabilityPartId) {
            toReturn.OnlineBookingAvailabilityPartId = this.OnlineBookingAvailabilityPartId;
          }

          return toReturn;
        }
      });

      function generateShiftForDate(date, startTime, endTime) {
        startTime = startTime || calendarStartTime;
        endTime = endTime || startTime.clone().add(defaultShiftLength, 'minutes');

        if (endTime.isAfter(calendarEndTime)) {
          endTime = calendarEndTime.clone();
        }

        return new OnlineBookingAvailabilityPart({
          NeedsApproval: false,
          NeedsCreditToken: false,
          StartTime: date.grabTime(startTime),
          EndTime: date.grabTime(endTime), Rate: 1
        });
      }

      function generateAvailabilityForDate(date) {
        return new OnlineBookingAvailability(date);
      }

    }])
}());
