import './leads.less'
import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT } from "../../constants";

angular.module('easybizy.leads')
  .controller('LeadsController', function LeadsController($scope, Repository, toolbar, localize, configuration,
    entityImageResolver, tileActionsResolver, $timeout, $state, $modal, confirmService, $q, subStatuses, employees, stateManager) {

    $scope.subStatus = [{ value: null, name: localize.getLocalizedString("_None_") }, ...subStatuses];
    $scope.employees = [{ value: null, name: localize.getLocalizedString("_NoEmployee_") }, ...employees];
    $scope.clearSearchFuncWrapper = {};
    const confirmMessage = localize.getLocalizedString('_ApproveGeneralConfirm_');

    const toolbarActions = [

      {
        name: 'add',
        action: function (lead, callback) {
          createNewLead(lead, callback);
        },
        data: {
          template: require('../../modal-views/add-lead/add.lead.tpl.html'),
          controller: 'AddLeadModalController'
        }
      },
      {
        name: 'search', action: function (newValue) {
          $scope.valueWrapper.value = newValue;
        },
        data: {
          delay: 500,
          clearWrapper: $scope.clearSearchFuncWrapper,
          setSearchWrapper: $scope.clearSearchFuncWrapper
        }
      }, {
        name: 'custom',
        icon: 'icon icon-entity-settings',
        action: function() {
          const opts = {
            backdrop: 'static',
            keyboard: false,
            template: require('../../modal-views/custom-entity-settings/custom.entity.config.modal.tpl.html'),
            controller: 'CustomEntityConfigurationsController',
            resolve: {
              views: () => {
                return [
                  {
                    loadItemsDelegate: () => Repository.Custom('CategoriesEditorRepository').get("LeadTags"),
                    name: localize.getLocalizedString('_Tags_'),
                    renameDelegate: (item, newName) => {
                      return Repository.Custom('CategoriesEditorRepository').rename("LeadTags", item.value, newName);
                    },
                    actions: [
                      {
                        name: localize.getLocalizedString('_Delete_'),
                        type: 'warning-submit',
                        confirmMessage: confirmMessage,
                        delegate: (items) => {
                          return Repository.Custom('CategoriesEditorRepository').remove("LeadTags", items);
                        }
                      },
                      {
                        name: localize.getLocalizedString('_Unite_'),
                        confirmMessage: confirmMessage,
                        minSelectionLength: 2,
                        delegate: (items) => {
                          return Repository.Custom('CategoriesEditorRepository').merge("LeadTags", items);
                        }
                      }
                    ]
                  },
                  {
                    loadItemsDelegate: () => Repository.Custom('CategoriesEditorRepository').get("LeadSubStatus"),
                    name: localize.getLocalizedString('_LeadSubStatus_'),
                    renameDelegate: (item, newName) => {
                      return Repository.Custom('CategoriesEditorRepository')
                        .rename("LeadSubStatus", item.value, newName);
                    },
                    actions: [
                      {
                        name: localize.getLocalizedString('_Delete_'),
                        type: 'warning-submit',
                        confirmMessage: confirmMessage,
                        delegate: (items) => {
                          return Repository.Custom('CategoriesEditorRepository').remove("LeadSubStatus", items);
                        }
                      },
                      {
                        name: localize.getLocalizedString('_Unite_'),
                        confirmMessage: confirmMessage,
                        minSelectionLength: 2,
                        delegate: (items) => {
                          return Repository.Custom('CategoriesEditorRepository').merge("LeadSubStatus", items);
                        }
                      }
                    ]
                  },
                  {
                    loadItemsDelegate: (search) => Repository.Custom('CategoriesEditorRepository').get("Leads",search),
                    name: localize.getLocalizedString('_Restore_'),
                    showSearch: true,
                    actions: [
                      {
                        name: localize.getLocalizedString('_Restore_'),
                        type: 'warning-submit',
                        confirmMessage: confirmMessage,
                        delegate: (items) => {
                          return Repository.Custom('CategoriesEditorRepository').restore("Leads", items);
                        }
                      }
                    ]
                  }
                ];
              }
            }
          }
          $modal.open(opts);
        }
      }
    ];

    toolbar.set(toolbarActions);

    $scope.leads = [];
    $scope.valueWrapper = {};
    $scope.filtersModel = {};
    $scope.filtersModel.filters = [
      { type: "state", allowMultiple: true },
      { type: "subStatus", allowMultiple: true },
      { type: "arrivalsource", allowMultiple: true },
      { type: "tag", allowMultiple: true, params: { entity: "Lead" } },
      { type: "nextHandlingTime", allowMultiple: false },
      { type: "employees", allowMultiple: true },
      {
        type: "dateRange", params: {
          defaultRange: {
            from: moment().subtract(3, 'month').startOf('month'),
            to: moment().add(1, 'day').endOf('day')
          },
          convertValue: function (selectedValues) {
            return 'CreatedOn ge DateTime%27' + moment(selectedValues.from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) + '%27 and CreatedOn le DateTime%27' +
              moment(selectedValues.to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) + '%27';
          }
        }
      },
      {
        type: "search",
        params: { fields: "FirstName, LastName, MobileFirst", type: "Lead" },
        valueWrapper: $scope.valueWrapper,
        clearWrapper: $scope.clearSearchFuncWrapper
      }
    ];

    $scope.trackByFunc = function (lead) {
      return lead.LeadId + lead.LastUpdated;
    };

    $scope.isSavingWrapper = { isSaving: false };

    var lastSelectedTags = null;

    $scope.filtersModel.filterStateChanged = (function () {
      let lastFilterState;
      return function (newFiltersState, newFiltersSlug) {
        const filterAsJson = JSON.stringify(newFiltersState);
        if (lastFilterState && lastFilterState === filterAsJson) {
          return;
        }

        if (!$scope.api) {
          loadApi();
        }

        lastFilterState = filterAsJson;
        $scope.api.filters.length = 0;
        angular.forEach(newFiltersState, function (filterValue, filterName) {
          if (filterName === 'tag') {
            if (lastSelectedTags || filterValue) {
              lastSelectedTags = filterValue;
              $scope.api.tags(filterValue);
              //$scope.api.forceReload();
            }
          } else if (filterValue) {
            $scope.api.filters.push(filterValue);
            if (filterName === 'search') {
              $scope.clearSearchFuncWrapper.setSearch(newFiltersSlug['search']);
            }
          }
        });

        // Update if already defined.
        if (angular.isFunction($scope.api.forceUpdate)) {
          $scope.api.forceUpdate();
        }
      };
    }());

    function loadApi() {
      $scope.api = Repository.Entity('Lead', true).query().expand('CustomTimelineItems,Tags');
      $scope.api.filters = [];
      $scope.api.pageSize = 20;
      const rawParams = stateManager.getRawParams();
      const visibleIndex = rawParams.visibleIndex;
      if (visibleIndex) {
        $scope.api.visibleIndex = parseInt(visibleIndex);
      }

      $scope.api.preventAutoLoad = true;
      $scope.api.doneLoadingCallback = function (leads) {
        $scope.firstLoaded = true;
        Repository.Custom('Leads').info($scope.api.getQuery().replace($scope.api.getBaseQuery(), '')).then(function (data) {
          $scope.leadsCurrentFilterInfo = data;
        }).catch(function (e) {
          $scope.leadsCurrentFilterInfo = null;
        });
      };

      $scope.api.modelsDecorator = function (results) {
        return results.value;
      };

      const current = stateManager.currentState('Leads');
      if (current.visibleIndex) {
        $timeout(() => {
          stateManager.setState('Leads', { ...current, visibleIndex: '' }, false, true);
        })
      }
    }

    $scope.isLoadingWrapper = {};


    $scope.selectedItemsCount = 0;
    $scope.deleteMessage = localize.getLocalizedString("_DeleteLeads_", $scope.selectedItemsCount + '');
    $scope.selectionChanged = function () {
      $scope.selectedItemsCount = $scope.leads.filter(function (lead) {
        return lead.isSelected;
      }).length;

      $scope.deleteMessage = localize.getLocalizedString("_DeleteLeads_", $scope.selectedItemsCount);
    };

    $scope.validateActionWrapper = {};
    $scope.isSavingWrapper = { isSaving: false };

    $scope.deleteLeads = function () {
      confirmService.confirm(localize.getLocalizedString("_DeleteLeads_", $scope.selectedItemsCount), localize.getLocalizedString('_ThisCannotBeUndone_'), removeSelectedLeads);
    };


    $scope.leadFields = [
      {
        name: localize.getLocalizedString("_None_"),
        type: 'Checkbox',
        allowSelectAll: true,
        selectOnDirty: true,
        sortable: false,
        fieldName: 'isSelected'
      },
      {
        name: localize.getLocalizedString("_FullName_"),
        sortable: true,
        command: {
          get: function (entity) {
            return entity.FirstName + ' ' + entity.LastName;
          },
          sort: function () {
            return "LastName";
          }
        }
      },
      // {
      //   name: localize.getLocalizedString("_PhoneNumber_"),
      //   sortable: false,
      //   fieldName: 'MobileFirst'
      // },
      {
        name: localize.getLocalizedString('_PhoneNumber_'),
        type: 'Custom',
        template: `<div class="flex-container full-height align-flex-center">
                        <div click-call="value.url">{{ value.url }}</div>
                   </div>`,
        command: {
          get: function (entity) {
            return {
              url: entity.MobileFirst
            }
          }
        }
      },
      {
        name: localize.getLocalizedString("_LastHandlingNote_"),
        sortable: false,
        command: {
          get: function (entity) {
            var lastActiveTimelineItem = '';
            if (entity.CustomTimelineItems) {
              for (var i = entity.CustomTimelineItems.length - 1; i >= 0; i--) {
                var currentTimelineItem = entity.CustomTimelineItems[i];
                if (currentTimelineItem.Status == 'Active') {
                  lastActiveTimelineItem = $('<div>' + currentTimelineItem.Content.replace('<div>', '<div>&nbsp;') + '</div>').text();
                  break;
                }
              }
            }

            return lastActiveTimelineItem;
          }
        }
      },
      {
        name: localize.getLocalizedString("_Tags_"),
        sortable: true,
        fieldName: 'Tags',
        command: {
          get: function (entity) {
            return entity.Tags.map(o => o.Name).join(', ');
          },
          sort: function () {
            return "Rank";
          }
        }
      },
      function () {
        var values = [
          { name: localize.getLocalizedString('_New_'), value: 'New' },
          { name: localize.getLocalizedString('_Active_'), value: 'Active' },
          { name: localize.getLocalizedString('_Suspended_'), value: 'Suspended' },
          { name: localize.getLocalizedString('_Inactive_'), value: 'Inactive' },
          { name: localize.getLocalizedString('_BecomeACustomer_'), value: 'BecomeACustomer' }
        ];

        return {
          name: localize.getLocalizedString("_Status_"),
          sortable: true,
          type: 'Combobox',
          values: values,
          command: {
            get: function (entity) {
              var currentStatusAsComboboxValue;
              for (var idx in values) {
                if (values[idx].value === entity.LeadStatus) {
                  currentStatusAsComboboxValue = values[idx];
                  break;
                }
              }

              return currentStatusAsComboboxValue;
            },
            set: function (newValue, entity) {
              entity['LeadStatus'] = newValue.value;
            },
            sort: function () {
              return "LeadStatus";
            }
          }
        };
      }(),
      function () {

        return {
          name: localize.getLocalizedString("_Employee_"),
          sortable: true,
          type: 'Combobox',
          values: $scope.employees,
          command: {
            get: function (entity) {
              var filteredEmployees = null;
              //if (entity.EmployeeId) {
              filteredEmployees =
                $scope.employees.filter(function (employeeOption) {
                  return employeeOption.value == entity.EmployeeId;
                });
              //}

              return filteredEmployees && filteredEmployees.length > 0 ? filteredEmployees[0] : null;
            },
            set: function (newValue, entity) {
              entity.EmployeeId = newValue.value;
              //changeSaleOfEmployee(entity, newValue.value);
            },
            sort: function () {
              return "EmployeeId";
            }
          }
        };
      }()
      , function () {
        return {
          name: localize.getLocalizedString("_LeadSubStatus_"),
          sortable: true,
          type: 'Combobox',
          values: $scope.subStatus,
          command: {
            get: function (entity) {
              var filteredSubStatus = null;
              filteredSubStatus =
                $scope.subStatus.filter(function (subStatusOption) {
                  return subStatusOption.value == entity.LeadSubStatusId;
                });

              return filteredSubStatus && filteredSubStatus.length > 0 ? filteredSubStatus[0] : null;
            },
            set: function (newValue, entity) {
              entity.LeadSubStatusId = newValue.value;
            },
            sort: function () {
              return "LeadSubStatusId";
            }
          }
        };
      }(),
      {
        name: localize.getLocalizedString("_LeadCreatedOn_"),
        sortable: true,
        orderedBy: true,
        isDecending: true,
        command: {
          get: function (entity) {
            return moment(entity.CreatedOn).format(DATE_DISPLAY_FORMAT);
          },
          sort: function () {
            return "CreatedOn";
          }
        }
      },
      {
        name: localize.getLocalizedString("_Updated_"),
        sortable: true,
        command: {
          get: function (entity) {
            return moment(entity.LastUpdated).format(DATE_DISPLAY_FORMAT);
          },
          sort: function () {
            return "LastUpdated";
          }
        }
      },
      new function () {
        var originalNextHandlingTime;
        this.name = localize.getLocalizedString("_NextHandlingTime_");
        this.sortable = true;
        this.fieldName = 'NextHandlingTime';
        this.type = "WritableTextbox";
        this.command = {
          get: function (entity) {
            return entity.NextHandlingTime ? moment(entity.NextHandlingTime).format(DATE_DISPLAY_FORMAT) : null;
          },
          set: function (entity, value) {
            var newDate = moment(value, DATE_DISPLAY_FORMAT);
            var relativeTime = originalNextHandlingTime ? moment(originalNextHandlingTime, DATE_DISPLAY_FORMAT) : moment();
            if (!originalNextHandlingTime || relativeTime.diff(newDate, 'days') != 0) {
              entity.NextHandlingTime = newDate.format(ODATA_DATE_ONLY_FORMAT);
              entity.dirty = true;
            }
          },
          isEditable: function (/*entity*/) {
            return true;
          },
          sort: function () {
            return "NextHandlingTime,LastUpdated";
          }
        };
        this.metadata = {
          placeholder: localize.getLocalizedString("_EndDate_"),
          type: 'date',
          validation: "{'required': true, 'date': true}",
          defaultStartDate: moment().add(1, 'd').format(DATE_DISPLAY_FORMAT),
          yearRange: "-1:+2"
        };
      },
      {
        name: localize.getLocalizedString("_Actions_"),
        type: "Custom",
        template: '<div class="centered-div-container go-to-details-table-action">\
                  <div class="centered-div-content"> \
                      <div ng-click="value.sendSms()" class="icon icon-sms"></div>\
                      <div ng-click="value.add()" class="icon icon-add"></div> \
                      <div ng-click="value.go()" class="icon icon-go-right" right-to-left="icon-go-left"></div> \
                   </div></div>',
        command: {
          get: function (entity) {
            return {
              go: function () {
                const current = stateManager.currentState('Leads');
                stateManager.setState('Leads', { ...current, visibleIndex: $scope.leads.indexOf(entity) }, false, true);
                $timeout(() => {
                  $state.go('LeadDetails', { 'leadId': entity.LeadId });
                });
              },
              add: function () {
                addCustomTimelineItem(entity);
              },
              sendSms: function () {
                tileActionsResolver.getAction('sendSms').command({
                  MobileFirst: entity.MobileFirst,
                  FirstName: entity.FirstName,
                  LeadId: entity.LeadId,
                  EntityType: "Lead"
                });
              }
            };
          }
        }
      }

    ];

    var widths = ['3%', '10%', '9%', '18%', '10%', '8%', '7%', '7%', '6%', '6%', '10%', '6%'];


    $scope.leadFields.forEach(function (field, idx) {
      field.width = widths[idx];
    });

    $scope.isAnyDirty = function () {
      var toReturn = false;
      for (var idx = 0; idx < $scope.leads.length; idx++) {
        if ($scope.leads[idx].dirty) {
          toReturn = true;
          break;
        }
      }

      return toReturn;
    };

    $scope.save = function () {
      var leadsToUpdate = $scope.leads.filter(function (lead) {
        return lead.dirty && lead.isSelected;
      });

      updateDirtyEntities();

      $scope.isSavingWrapper.isSaving = true;
      Repository.Custom('Leads').updateBatch(leadsToUpdate)
        .then(function () {
          $scope.isSavingWrapper.isSaving = false;
          $scope.$evalAsync(updateDirtyEntities);
          toastr.success(localize.getLocalizedString("_ItemsSuccessfullyUpdated_"));
        })
        .catch(function (e) {
          toastr.error(localize.getLocalizedString("_ErrorUpdatingItems_"));
        });
    };

    $scope.actions = [
      {
        icon: 'icon icon-excel', action: function () {
          var currentQuery = $scope.api.getQuery().replace($scope.api.getBaseQuery(), '');
          Repository.Custom('Leads').exportToExcel(currentQuery);
        },
        disabled: false,
        tooltip: localize.getLocalizedString('_Export_')
      }
    ];


    function addCustomTimelineItem(lead) {
      var options = {
        backdrop: 'static',
        windowClass: 'transition-off',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/entity-custom-note/entity.custom.note.tpl.html'),
        controller: 'EntityCustomNoteController',
        resolve: {
          saveAction: function () {
            return function (newEntity) {
              return generateNewCustomTimelineItem(newEntity, lead);
            };
          },
          parameters: function () {
            return {
              getOwnerId: function () {
                return lead.LeadId;
              },
              reminderType: 'customer'
            };
          }
        },
        closeFn: function () {
          console.log("closing");
        }
      };

      $modal.open(options);
    }

    function updateDirtyEntities() {
      $scope.leads.forEach(function (lead) {
        if (lead.dirty) {
          lead.dirty = false;
          lead.isSelected = false;
        }
      });
    }

    function generateNewCustomTimelineItem(timelineEntityCustomNoteToSend, lead) {
      var deferred = $q.defer();
      timelineEntityCustomNoteToSend.EntityType = 'Lead';
      Repository.Custom("CustomTimelineItem").createOrUpdate(timelineEntityCustomNoteToSend)
        .then(function (timelineEntityCustomNote) {
          toastr.success(localize.getLocalizedString("_CustomItemSuccesfulyCreated_"));
          if (!lead.CustomTimelineItems) {
            lead.CustomTimelineItems = [];
          }

          lead.CustomTimelineItems.push(timelineEntityCustomNote);

          deferred.resolve(timelineEntityCustomNoteToSend);
          $scope.$digestIfNeeded();
        }).catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorCreatingCutomItem_"), err);
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function createNewLead(lead, callback) {
      Repository.Entity("Lead").create(lead).post()
        .then(function (newLead) {
          $scope.leads.unshift(newLead);
          callback.resolve(newLead);
        })
        .catch(function (err) {
          callback.reject(err);
        });
    }

    function removeSelectedLeads() {
      var leadsToDelete = [];
      $scope.leads.forEach(function (lead) {
        if (lead.isSelected) {
          leadsToDelete.push(lead);
        }
      });

      Repository.Entity("Lead").remove(leadsToDelete)
        .then(function () {
          toastr.success(localize.getLocalizedString("_LeadsRemovedSuccessfully_"));
          leadsToDelete.forEach(function (lead) {
            $scope.leads.remove(lead);
          });

          $scope.selectionChanged();
        })
        .catch(function (err) {
          toastr.error(err);
        });
    }

  });
