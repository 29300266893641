export function DelayedFunction(toPerform, lengthToDelay) {
  let _value = null;
  var func = toPerform;
  var delay = lengthToDelay ? lengthToDelay : 700;
  var timeoutFunctionPointer = null;
  let _disabled = false;

  this.set = function (valueToSet) {
    if (_disabled) {
      return;
    }

    if (timeoutFunctionPointer) {
      clearTimeout(timeoutFunctionPointer);
    }

    _value = valueToSet;
    if (valueToSet == null) {
      return;
    }

    timeout();
  };

  this.isDisabled = () => _disabled;

  this.setDisabled = function (isDisabled) {
    _disabled = isDisabled;
  };

  this.setForce = function (valueToSet) {
    if (timeoutFunctionPointer) {
      clearTimeout(timeoutFunctionPointer);
    }

    _value = null;
    return func(valueToSet);
  };

  this.cancel = function () {
    _value = null;
    if (timeoutFunctionPointer) {
      clearTimeout(timeoutFunctionPointer);
    }
  };

  this.get = function () {
    return _value;
  };

  this.trigger = function () {
    if (!_value) {
      return;
    }

    this.setForce(_value);
  };

  function timeout() {
    timeoutFunctionPointer = setTimeout(function () {
      timeoutFunctionPointer = null;
      func(_value);
      _value = null;
    }, delay);
  }
}


export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

export const asArray = (obj) => Array.isArray(obj) ? obj : Object.keys(obj).map(key => Object.assign({}, obj[key], {key}));

export function ObservableProperties(object) {
  object.observer = {};
  object.on = function (key, delegate) {
    if (!object.observer[key]) {
      object.observer[key] = [];
    }

    object.observer[key].push(delegate);
  };

  object.off = function (key, delegate) {
    var toReturn = null;
    if (object.observer.hasOwnProperty(key)) {
      const delegateIndex = object.observer[key].indexOf(delegate);
      if (delegateIndex >= 0) {
        object.observer[key].splice(object.observer[key].indexOf(delegate), 1);
      }
    }

    return toReturn;
  };

  object.notify = function (key, value) {
    if (!object.observer.hasOwnProperty(key)) {
      return;
    }

    object.observer[key].forEach(function (observer) {
      observer.apply(observer, [value]);
    })
  };

  object.clearObservers = function () {
    object.observer = {};
  }
}

export const getPropertyValue = (obj, deepProperty) => deepProperty.split('.').reduce((acc, key) => acc ? acc[key] : null, obj);

export const remToPX = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const pxToREM = (px) => {
  return px / parseFloat(getComputedStyle(document.documentElement).fontSize);
};


export const nullUndefinedOrEmpty = (value) => {
  return !angular.isDefined(value) || value === null || (angular.isString(value) && value.length === 0);
};

export function Publisher() {
  return {
    subscribers: {
      any: [] // event type: subscribers
    },
    on: function (type, fn, context) {
      type = type || 'any';
      fn = typeof fn === 'function' ? fn : context[fn];
      if (typeof this.subscribers[type] === "undefined") {
        this.subscribers[type] = [];
      }
      this.subscribers[type].push({fn: fn, context: context || this});
    },
    remove: function (type, fn, context) {
      this.visitSubscribers('unsubscribe', type, fn, context);
    },
    fire: function (type, publication) {
      this.visitSubscribers('publish', type, publication);
    },
    visitSubscribers: function (action, type, arg, context) {
      var pubtype = type || 'any',
        subscribers = this.subscribers[pubtype],
        i,
        max = subscribers ? subscribers.length : 0;

      for (i = 0; i < max; i += 1) {
        if (action === 'publish') {
          // Call our observers, passing along arguments
          subscribers[i].fn.call(subscribers[i].context, arg);
        } else {
          if (subscribers[i].fn === arg && subscribers[i].context === context) {
            subscribers.splice(i, 1);
          }
        }
      }
    }
  };
}

export function countSpecificChar(str, charToCount) {
  let count = 0;

  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) === charToCount) {
      count++;
    }
  }

  return count;
}
