<template>
  <div class="vue-button" :class="[toggled && 'toggled',
  primary && 'background--primary',
  secondary && 'vue-button--secondary',
  disabled && 'vue-button--disabled',
  badge && 'vue-button--badge',
  initialTextSize && 'vue-button--initial-text-size',
  inProgress && 'vue-button--in-progress']">
    <transition name="fade">
      <div class="mask" v-if="toggled && !disabled" @click="toggle"></div>
    </transition>
    <div class="vue-button__loader" v-if="inProgress"><span></span></div>
    <div class="vue-button__container grow">
      <button class="vue-button__core grow text-center overflow-hidden relative" @click="myClickDelegate"
              :disabled="disabled">
        <slot></slot>
        <div class="vue-button__badge absolute flex items-center" v-if="badge"
             :class="blinkingBadge && 'vue-button__badge--blink'">
          <div :class="badge"></div>
        </div>
      </button>
      <div class="vue-button__second-action" v-if="actions && actions.length > 0">
        <div class="vue-button__separator">

        </div>
        <div class="flex flex-center">
          <button class="icon caret-item"
                  :disabled="disabled"
                  :class="toggled ? 'icon-caret-left' : 'icon-caret-right'" @click="toggle">
          </button>
          <div class="custom-tooltip" v-if="secondActionTooltip">
            {{ secondActionTooltip }}
          </div>
        </div>

      </div>
    </div>
    <transition name="genie">
      <div class="vue-button__dropdown" v-if="actions && toggled">
        <ul>
          <li v-for="item in actions" v-on:click="itemClicked(item)">{{ item.name }}</li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
// TODO: Tomer support disabled for both sides.
import { isFunction, setIfHasValue } from "@tomeravni/easybizy-js-common/common";

export default {
  name: 'vue-button',
  created() {
    this.blockTimer = null
  },
  data() {
    return {
      toggled: false,
      blocked: false,
      blinkingBadge: false
    }
  },
  mounted() {
    setIfHasValue(this.refWrapper, 'ref', this);
  },
  props: {
    // This blocks clicks within 500 millisecodnds range.
    safeClickMode: {
      type: Boolean,
      default: false
    },
    click: {
      type: Function,
    },
    actions: {
      type: Array,
      default: null
    },
    secondActionTooltip: {
      type: String,
      default: null
    },
    inProgress: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    initialTextSize: {
      type: Boolean,
      default: false
    },
    badge: {
      type: String,
      default: null
    },
    refWrapper: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    myClickDelegate() {
      if (this.isBlocked()) {
        return;
      }

      this.blockEvents();
      if (isFunction(this.click)) {
        this.click()
      } else {
        this.$emit('click');
      }
    },
    toggle() {
      this.toggled = !this.toggled;
    },
    itemClicked(item) {
      if (this.isBlocked()) {
        return;
      }

      this.blockEvents();
      this.toggle();
      item.action();
    },
    isBlocked() {
      return this.inProgress || this.blocked;
    },
    blockEvents(cancelOnly = false) {
      if (!this.safeClickMode) {
        return;
      }

      this.blocked = !cancelOnly;
      if (this.blockTimer) {
        clearTimeout(this.blockTimer)
        this.blockTimer = null;
      }

      if (!cancelOnly) {
        this.blockTimer = setTimeout(() => {
          this.blockEvents(true)
        }, 500)
      }
    },
    blinkBadge() {
      if (this.blinkingBadge) {
        return;
      }

      this.blinkingBadge = true
      setTimeout(() => {
        this.blinkingBadge = false;
      }, 500);
    }
  },
  watch: {
    inProgress(newVal) {
      if (newVal) {
        this.toggled = false;
      }
    }
  }
}
</script>

<style lang="less">
@import "../../../styles/variables";


.vue-button {
  background-color: @submit-color;
  display: flex;
  color: @combo-text-color;
  position: relative;
  border-radius: 5px;
  transition: all .3s;
  justify-content: center;

  &--disabled {
    opacity: 0.5;
  }

  &--secondary {
    background-color: transparent;
    color: @default-text-color;
  }

  &--badge {
    .vue-button__container button.vue-button__core {
      padding-inline-end: @padding-container;
    }
  }

  &.vue-button--initial-text-size * {
    font-size: initial;
  }

  &.vue-button--in-progress {
    background-color: fade(@submit-color, 70%);


    .vue-button__container {
      max-width: 50px;
      opacity: 0;
      /*overflow: hidden;*/
    }

    .vue-button__loader {
      visibility: visible;
      opacity: 1;
    }

    /*transform: scaleX(0.5);*/

    /*.vue-button__second-action {*/
    /*  opacity: 0;*/
    /*}*/
  }

  &.vue-button--in-progress.background--primary {
    background-color: fade(@primary-color, 70%);
  }

  .vue-button__container {
    transition: all .3s;
    white-space: nowrap;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-radius: 5px;

    .vue-button__core {
      padding: @padding-container / 3;
      border-radius: 5px;
    }
  }

  button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-size: 1.4em;
    color: @combo-text-color;

    * {
      color: @combo-text-color;
    }
  }

  .vue-button__second-action {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    transition: opacity .3s;

    button {
      padding-left: 0.25em;
      line-height: 0;
    }
  }

  .vue-button__separator {
    height: 1.4em;
    width: 2px;
    background-color: @combo-text-color;
    margin-left: 0.75em;
  }

  .mask {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 99;
  }

  .vue-button__dropdown {
    background-color: @content-background-color;
    border: 1px solid @submit-color;
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    left: 100%;
    margin-left: 10px;
    z-index: 100;
    box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.25);

    @arrow-size: 10px;
    @arrow-color: @submit-color;

    &:before {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      bottom: 8px;
      margin-right: 1px;

      border-top: @arrow-size solid transparent;
      border-bottom: @arrow-size solid transparent;
      border-right: @arrow-size solid @arrow-color;
      right: 100%;
    }


    ul li {
      float: none;
      font-size: 1.4em;
      padding: @padding-container / 3 @padding-container / 2;
      white-space: nowrap;
      transition: all .1s;
      cursor: pointer;
      color: @default-text-color;

      &:not(:last-of-type) {
        border-bottom: 1px solid @submit-color;
      }

      &:hover {
        background-color: @submit-color;
        color: @combo-text-color;
      }
    }
  }

  @badge-size: 45px;

  .vue-button__badge {
    height: @badge-size;
    width: @badge-size;
    left: -@badge-size / 2;
    top: -@badge-size / 2;
    background: @submit-color-underline;
    transform: rotate(45deg);
    text-align: right;
    padding-right: 1px;

    > div {
      transform: rotate(-45deg);
      color: @combo-text-color;
      font-size: 0.7em;
    }

    &--blink {
      .blink_me(0.5s);
    }
  }

  .genie-enter-active, .genie-leave-active {
    transition: all .2s;
    transform: scale(1);
    transform-origin: left bottom;
  }

  .genie-enter, .genie-leave-to {
    opacity: 0;
    transform-origin: left bottom;
    transform: scale(0) translateX(-15px);
  }

  .custom-tooltip {
    z-index: 100;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.9);

    &:after {
      border-top-color: rgba(0, 0, 0, 0.9);
    }
  }

  .caret-item:hover + .custom-tooltip {
    opacity: 1;
  }

  &.toggled {
    .custom-tooltip {
      display: none;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  @loader-size: 25px;

  .vue-button__loader {
    transition: opacity .3s;
    position: absolute;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 1;
    left: 50%;
    top: 50%;
    display: block;
    height: @loader-size;
    width: @loader-size;

    span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: @loader-size;
      width: @loader-size;
    }

    span::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: @loader-size;
      width: @loader-size;
      border: 3px solid #FFF;
      border-bottom: 3px solid transparent;
      border-radius: 50%;
      -webkit-animation: loader-3-1 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
      animation: loader-3-1 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    }

    @keyframes loader-3-1 {
      0% {
        transform: rotate(0deg);
      }
      40% {
        transform: rotate(180deg);
      }
      60% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    span::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: @loader-size / 5;
      height: @loader-size / 5;
      background: #FFF;
      border-radius: 50%;
      -webkit-animation: loader-3-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
      animation: loader-3-2 1.5s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
    }


    @keyframes loader-3-2 {
      0% {
        transform: translate3d(0, -32px, 0) scale(0, 2);
        opacity: 0;
      }
      50% {
        transform: translate3d(0, 0, 0) scale(1.25, 1.25);
        opacity: 1;
      }
      100% {
        transform: translate3d(0, 8px, 0) scale(0, 0);
        opacity: 0;
      }
    }

  }
}
</style>
