import Moment from 'moment';
import './cash-register.less';
import './cash.register.plugins';
import './cash.register.resizable';
import './invoice.facade';
import './custom-item-dialog/custom.item.dialog';
import './lost-payments/lost.payments.directive';
import './products-picker/lazy.items';
import './products-picker/products.picker.directive';
import './purchasing-history/purchasing.history.directive';
import { DelayedFunction } from "../../general/helpers";
import {
  ODATA_DATE_ONLY_FORMAT,
  SESSION_RELATIVE_DATE_FORMAT
} from "../../constants";
import {
  allConditionsAreMet, cleanObj,
  getSafely, isConcreteValue,
  isEmptyString,
  isFunction,
  isNonEmptyArray, isNonEmptyObject, isNumber,
  setIfDifferent,
  valueOrDefault
} from "@tomeravni/easybizy-js-common/common";
import { extractError } from "@tomeravni/easybizy-js-common/errors";
import { presentablePrice } from "@tomeravni/easybizy-js-common/money";

const TIP_ITEM_ID = -6000;

angular.module('easybizy.cash.register').controller('CashRegisterController',
  function ($scope, $timeout, $compile, Repository, toolbar, localize, $modal, configuration,
    modalCreator, entityImageResolver, contextManager, invoiceFacade, $q, $modalStack,
    cashRegisterPlugins, personalConfigurations, $rootScope, globalActions, confirmService,
    cashRegisterDailyActions, $transitions, stateManager, $state, intermediateManager, pci) {
    $scope.clearSearchFuncWrapper = {};
    $scope.isOverlappingZ = false;
    var configurations = configuration.get();
    var ignoreInventoryConfiguration = configurations.ProductsAndServicesSettings.IgnoreInventory;
    $scope.hasPriceQuote = configurations.PriceQuoteSettings.IsUsingPriceQuote;
    $scope.enableEmployeePerProduct = configurations.CashRegisterSettings.EnableEmployeePerProduct;
    $scope.enableDiscountPerItem = configurations.CashRegisterSettings.EnableDiscountPerItem;
    $scope.isUsingCredit = !configurations.CashRegisterSettings.PreventCreditCard;
    $scope.isUsingPunchCard = configurations.CashRegisterSettings.UseSubscriptionsModel;
    $scope.isUsingPoints = configurations.PointsSettings && configurations.PointsSettings.EnablePoints;
    $scope.minimumForUsingPoints = configurations.PointsSettings && configurations.PointsSettings.MinimumCreditForUsingPoints;

    const ignoreIngredientsModel = configurations.ProductsAndServicesSettings.IgnoreIngredients;
    const separateSameItemsToDifferentLines = configurations.CashRegisterSettings.SeparateSameItemsToDifferentLines;
    const helpMeChoosingEmployee = configurations.CashRegisterSettings.HelpChoosingEmployee;
    const disableInvoiceItemsNameChanges = configurations.InvoiceSettings && configurations.InvoiceSettings.DisableInvoiceItemsNameChanges;
    // const preventFirstUsageOnCreatingSubscription = configurations.CashRegisterSettings.PreventFirstUsageOnCreatingSubscription

    $scope.isNewModeCashRegister = configurations.CashRegisterSettings.UseNewPosModel;
    const sortByProducts = configurations.ProductsAndServicesSettings.DefaultProductsSortBy.Value != "default" ?
      configurations.ProductsAndServicesSettings.DefaultProductsSortBy.Value : null;
    const warnForNonCustomer = configurations.CashRegisterSettings.WarnMeForInvoiceWithoutCustomer;
    const invoiceMustHaveCustomer = configurations.CashRegisterSettings.InvoiceMustHaveCustomer;
    const preventInventoryUpdateOnCashRegister = configurations.ProductsAndServicesSettings.PreventInventoryUpdateOnCashRegister;
    const considerSubscriptionsExpirationDate = configurations.ProductsAndServicesSettings.ConsiderSubscriptionsExpirationDate;
    const defaultSubscriptionExpirationDateInMonths = configurations.ProductsAndServicesSettings.DefaultSubscriptionExpirationDateInMonths?.Value ?? 12;
    var VAT = configurations.CashRegisterSettings.VAT.Value;
    VAT = VAT * 1.0 / (VAT + 100.0);
    var roundTo = 100 / (configurations.CashRegisterSettings.PaymentRounding.Value * 100);

    $scope.paymentLinkEnabled = false;

    /***************************************** HANDLING DEBT *****************************************/
    $scope.shouldHandleDebt = stateManager.getParamValue('debt');

    const addItemDelegate = $rootScope.$on('add-item-to-cash-register', (e, { Id, ConcreteType, Price }) => {
      const entity = $scope.inventoryItems.find((item) => item.Id === Id && item.ConcreteType === ConcreteType);
      if (angular.isObject(entity)) {
        addItemToCart(Object.assign(entity, { RetailPrice: Price }));
      } else {
        toastr.warning(localize.getLocalizedString('_EntityNotFound_'));
      }
    });

    $scope.customCustomerCreditToken = null;
    /// Session modification
    const sessionModifiedSocketDelegate = $rootScope.$on('session-modified', (e, data) => {
      const validRawParams = stateManager.getRawParams();
      const socketCustomerId = getSafely(['customerId'], data, '-1').toString();
      const queryParamsCustomerId = getSafely(['customerId'], validRawParams);

      const socketRelativeDate = getSafely(['relativeDate'], data);
      const queryParamsRelativeDate = Moment(getSafely(['relativeDate'], validRawParams))
        .format(SESSION_RELATIVE_DATE_FORMAT);


      if (($scope.sessionId === data.sessionId && $scope.sessionVersion !== data.sessionVersion) || (
        socketCustomerId === queryParamsCustomerId &&
        socketRelativeDate === queryParamsRelativeDate
      )) {
        return reloadSessionItems(true);
      }
    })

    const clearRegisterDelegate = $rootScope.$on('clear-cash-register', (e, data) => {
      $scope.reset();
    })

    const sessionRemovedDelegate = $rootScope.$on('session-removed', (e, data) => {
      if ($scope.sessionId === data.sessionId) {
        $scope.$evalAsync(() => $scope.reset(true));
      }
    })

    const sessionDoneDelegate = $rootScope.$on('session-done', (e, data) => {
      if ($scope.sessionId === data.sessionId) {
        toastr.warning(localize.getLocalizedString('_PaidOnDifferentAccount_'));
        $scope.$evalAsync(() => $scope.reset(true));
        let openModalKey = $modalStack.getTop()?.key
        if (openModalKey) {
          $modalStack.dismiss(openModalKey);
        }
      }
    })


    $scope.$on('$destroy', () => {
      addItemDelegate();
      sessionModifiedSocketDelegate();
      sessionRemovedDelegate();
      sessionDoneDelegate();
      clearRegisterDelegate();
    });


    $scope.closeDebt = function (e) {
      e.stopPropagation();
      e.preventDefault();
      $timeout(handleDebt);
    };
    $scope.usePrePayment = function (e) {
      e.stopPropagation();
      e.preventDefault();
      $timeout($scope.openPrePaymentUsage);
    };


    /***************************************** END OF HANDLING DEBT *****************************************/

    // This connects between the width of the width.
    $scope.leftScreenWidthWrapper = { value: personalConfigurations.mainViewWidth && personalConfigurations.mainViewWidth < 100 && personalConfigurations.mainViewWidth > 0 ? personalConfigurations.mainViewWidth : 50 };

    let membershipDiscountPlugin;
    let specialItemsPlugin = new cashRegisterPlugins.specialItemsPlugin($scope, removeItemFromCart);
    let subscriptionTemplatePlugin = new cashRegisterPlugins.subscriptionTemplatePlugin($scope, 'itemsInCart', removeItemFromCart);

    var editModeArgs = {
      toggleEdit: function () {
        $scope.editFavoritesMode = !$scope.editFavoritesMode;
        // $scope.$digestIfNeeded();
      }
    };

    Object.defineProperty(editModeArgs, 'editMode', {
      get: function () {
        return $scope.editFavoritesMode;
      }
    });

    var reportsAction = {
      name: 'reportActions',
      tooltip: localize.getLocalizedString('_Actions_'),
      icon: 'icon-circle-menu',
      actions: [
        {
          name: localize.getLocalizedString("_InvoicesHistory_"),
          icon: 'icon icon-wallet',
          action: globalActions.cashRegisterActivities.bind(this, {})
        },
        {
          name: localize.getLocalizedString("_DailyRegisterActions_"),
          icon: 'icon icon-enter',
          action: globalActions.cashRegisterActions.bind(this, {
            dayClosed: function () {
              $scope.isOverlappingZ = false;
            }
          })
        },
        {
          name: localize.getLocalizedString("_EditFavorites_"),
          icon: 'icon icon-details',
          action: globalActions.editConfig.bind(this, editModeArgs)
        },
        {
          name: localize.getLocalizedString("_DebtsAndTaxInvoices"),
          icon: 'icon icon-invoice',
          action: () => {
            if (!$scope.customerInvoice) {
              openCustomerPickerDialog().then((selectedCustomer) => {
                openDebtsAndTaxInvoicesDialog(selectedCustomer);
              });

              return toastr.error(localize.getLocalizedString('_PickCustomerFirst_'));
            }

            // TODO: Send invoices and debts that already here.
            openDebtsAndTaxInvoicesDialog($scope.customerInvoice);
          }
        }
      ]
    };

    var actions = [
      {
        name: 'openDrawer',
        tooltip: localize.getLocalizedString('_OpenDrawer_')
      }, reportsAction
    ];


    var priceQuoteAction;
    if ($scope.hasPriceQuote) {
      reportsAction.actions.push(
        {
          name: localize.getLocalizedString("_PriceQuotes_"),
          icon: 'icon icon-price-quote',
          action: globalActions.showPriceQuotes
        });

      priceQuoteAction = {
        name: 'toggleButton',
        tooltip: localize.getLocalizedString('_PriceQuote_'),
        func: function () {
          $scope.togglePriceQuoteMode();
        },
        icon: 'icon icon-price-quote-action',
        icons: ['icon icon-price-quote-action', 'icon icon-banknote']
      };

      actions.unshift(priceQuoteAction);
    }

    if (configurations.CashRegisterSettings.UseGiftCardModel) {
      reportsAction.actions.push(
        {
          name: localize.getLocalizedString("_GiftCards_"),
          icon: 'icon icon-gift-card',
          action: globalActions.showGiftCards
        });
    }

    reportsAction.actions.push(
      {
        name: localize.getLocalizedString("_GenerateReceiptOnly_"),
        icon: 'icon icon-price-quote',
        action: generateReceiptOnly.bind(null)


      });

    function generateReceiptOnly() {
      $scope.reset(true);
      confirmService.prompt(
        /*title*/localize.getLocalizedString('_ReceiptAmount_'),
        localize.getLocalizedString('_ReceiptOnlyExplanation_'), '',
        /*onOk*/function (amount) {
          if (amount > 0) {
            const receiptOnly = {
              Id: -1,
              TaxInvoiceId: -1,
              Label: localize.getLocalizedString("_ReceiptAmount_"),
              itemCustomType: 'TaxInvoice',
              RetailPrice: amount
            };
            receiptOnly.preventRegisteringSubscription = true;
            addItemToCart(receiptOnly, true);
          }
        },
        function () {
        },
        function (value) {
        }
      );
    }

    toolbar.set(actions);

    $scope.valueWrapper = {};
    $scope.filtersModel = {};
    $scope.filtersModel.filters = [
      { type: "alphabetically", params: { fields: "Label" }, allowMultiple: false },
      {
        type: "productOrService",
        allowMultiple: false,
        params: { disabledWrapper: { disabled: false }, useConfigurations: true }
      },
      { type: "productsFilter", allowMultiple: false },
      { type: "servicesFilter", allowMultiple: false },
      {
        type: "search",
        params: { fields: "Label" },
        valueWrapper: $scope.valueWrapper,
        clearWrapper: $scope.clearSearchFuncWrapper
      }
    ];

    $scope.filtersModel.filterStateChanged = function (newFiltersState) {
      $scope.api.filters.length = 0;
      $scope.api.selectedIds.length = 0;
      angular.forEach(newFiltersState, function (filterValue, filterName) {
        if (filterValue && filterName !== 'productOrService') { // deal with product details later.
          if (filterName === 'productsFilter' || filterName === 'servicesFilter') {
            $scope.api.selectedIds.push(filterValue);
          } else {
            $scope.api.filters.push(filterValue);
          }
        }
      });

      if ($scope.api.selectedIds.length > 0) {
        $scope.filtersModel.filters[1].params.disabledWrapper.disabled = true;
      } else {
        if (newFiltersState['productOrService']) {
          $scope.api.filters.push(newFiltersState['productOrService']);
        }

        $scope.filtersModel.filters[1].params.disabledWrapper.disabled = false;
      }

      $scope.api.filters.push('(IngredientOnly eq false)');
      $scope.api.queryParams('getCategory', 'true');
    };

    $scope.api = Repository.Entity('ProductOrService').query();
    if (sortByProducts) {
      $scope.api.orderBy(sortByProducts);
    }

    $scope.api.emptyEntity = {
      Id: -2, Label: localize.getLocalizedString("_CustomProduct_"),
      Images: [],
      EntityType: "ProductOrService", ConcreteType: "Product",
      HasIngredients: false
    };
    $scope.api.filters = [];
    $scope.api.selectedIds = [];
    $scope.api.doneLoadingCallback = function (productsOrServices) {
      // This forces each element to keep a copy of the RetailPrice
      productsOrServices.forEach(function (productOrService) {
        productOrService.OriginalRetailPrice = productOrService.RetailPrice;
      });

      $scope.noItemsFound = productsOrServices.length === 1;
      $scope.inventoryItems = productsOrServices;
    };

    $scope.api.preventAutoLoad = true;
    $scope.isLoadingWrapper = {};
    $scope.products = [];
    $scope.models = [];
    $scope.itemsInCart = [];
    var trackedItemIndex = 0;
    $scope.itemsInCartTrackBy = function (x) {
      var toReturn = "C";
      if (x.ConcreteType === 'ServiceMetadata') {
        toReturn = "S";
      } else if (x.ConcreteType === 'ProductMetadata') {
        toReturn = "P";
      }

      toReturn += x.itemCustomType === 'PunchCardUsage' ? "D" : "";
      return toReturn + x.Id + (separateSameItemsToDifferentLines ? ++trackedItemIndex : '');
    };

    /******************** CUSTOM PRODUCTS IN CASH REGISTER ***/
    $scope.specialItems = [
      {
        name: localize.getLocalizedString('_CustomProduct_'),
        icon: 'icon icon-custom-product generic-product-color',
        type: 'custom-product',
        delegate: openCustomItemDialogItem.bind(this, 'generic-product', function () {
          return Math.min($scope.totalAggregator.subTotal, 0);
        }, ' + ' + localize.getLocalizedString('_CustomProductCreator_'), function (metadata) {
          const customProduct = {
            Id: -1,
            Label: metadata.name || localize.getLocalizedString("_SpecialProduct_"),
            itemCustomType: 'GenericProduct',
            RetailPrice: metadata.price
          };

          customProduct.Id = -cashRegisterPlugins.randomUniqueId();
          customProduct.OriginalRetailPrice = customProduct.RetailPrice;
          customProduct.preventRegisteringSubscription = true;
          addItemToCart(customProduct, true);
          return true;
        })
      },
      {
        name: localize.getLocalizedString('_PrePayment_'),
        icon: 'icon icon-banknote',
        type: 'pre-payment',
        disabled: isPrePaymentAllowed,
        disabledTooltip: localize.getLocalizedString('_PrePaymentNotAllowedWhenCustomerInDebt_'),
        delegate: openCustomItemDialogItem.bind(this, 'pre-payment', function () {
          return Math.min($scope.totalAggregator.subTotal, 0);
        }, ' + ' + localize.getLocalizedString('_PrePayment_'),
          function (metadata) {
            var customProduct = {
              Id: -1,
              Label: metadata.name || localize.getLocalizedString("_PrePayment_"),
              itemCustomType: 'PrePayment',
              RetailPrice: metadata.price
            };

            var minId = -3;
            // Find min id value.
            $scope.itemsInCart.forEach(function (x) {
              if (x.Id < minId) {
                minId = x.Id;
              }
            });

            customProduct.Id = minId - 1;
            customProduct.OriginalRetailPrice = customProduct.RetailPrice;
            customProduct.preventRegisteringSubscription = true;
            addItemToCart(customProduct, true);
            return true;
          })
      }];

    if (configurations.CashRegisterSettings.UseGiftCardModel) {
      $scope.specialItems.push({
        name: localize.getLocalizedString('_GiftCard_'),
        icon: 'icon icon-gift-card gift-card-color',
        type: 'gift-card',
        delegate: openCustomItemDialogItem.bind(this, 'gift-card', function () {
          return 100;
        }, localize.getLocalizedString('_GiftCard_'), function (metadata) {
          if (metadata.digitalPass) {
            Repository.Entity("GiftCard", true).query().filter("GiftCardReference eq '" + metadata.digitalPass + "' and TotalAmount gt AmountUsed").get().then(
              function (res) {
                if (res && res.value.length) {
                  toastr.error(localize.getLocalizedString('_GiftCardStillInUse_'));
                  return false;
                } else {
                  addGiftCardToCart(metadata);
                }
              })
              .catch(function (err) {
                toastr.error(err.Message)
              });
          } else {
            addGiftCardToCart(metadata);
          }

          return true;
        })
      });


      function addGiftCardToCart(metadata) {
        var customProduct = {
          Id: -cashRegisterPlugins.randomUniqueId(),
          Label: metadata.name,
          RetailPrice: metadata.price,
          ReferenceNumber: metadata.digitalPass,
          Template: metadata.template,
          Giver: metadata.giver,
          Greating: metadata.greating,
          SmsSendTime: metadata.smsSendTime,
          AllowOnlineScheduling: metadata.allowOnlineScheduling,
          PhoneNumber: metadata.phoneNumber,
          Remarks: metadata.remarks,
          ShowPrice: !!metadata.showPrice,
          SmsMessageContent: metadata.smsMessageContent,
          itemCustomType: 'GiftCard'
        };

        customProduct.Id = -cashRegisterPlugins.randomUniqueId();
        customProduct.OriginalRetailPrice = customProduct.RetailPrice;
        customProduct.preventRegisteringSubscription = true;
        addItemToCart(customProduct, true);
        return true;
      }

      $scope.specialItems.push({
        name: localize.getLocalizedString('_GiftCardUsage_'),
        icon: 'icon icon-gift-card-usage gift-card-color',
        type: 'gift-card-usage',
        disabled: function () {
          return !$scope.totalAggregator.subTotal || parseFloat($scope.totalAggregator.subTotal) < 1;
        },
        disabledTooltip: localize.getLocalizedString('_CanOnlyApplyGiftCardWhenProducts_'),

        delegate: openCustomItemDialogItem.bind(this, 'gift-card-usage', function () {
          return $scope.totalAggregator.subTotal;
        }, localize.getLocalizedString('_GiftCardUsage_'), function (amountWrapper) {
          var customProduct = {
            Id: -cashRegisterPlugins.randomUniqueId(),
            Label: localize.getLocalizedString('_GiftCardUsage_'),
            RetailPrice: -amountWrapper.amount,
            GiftCardId: amountWrapper.GiftCardId,
            itemCustomType: 'GiftCardUsage'
          };

          customProduct.OriginalRetailPrice = customProduct.RetailPrice;
          customProduct.preventRegisteringSubscription = true;
          addItemToCart(customProduct);
          return true;
        })
      });

    }

    if (configurations.CashRegisterSettings.UseTips) {
      $scope.specialItems.push({
        name: localize.getLocalizedString('_Tip_'),
        icon: 'icon icon-tip_money tip-color',
        type: 'tip',
        delegate: openCustomItemDialogItem.bind(this, 'tip', function () {
          return 199;
        }, localize.getLocalizedString('_Tip_'), function (metadata) {
          addTipToCart(metadata)
          return true;
        })
      });

      //
      function addTipToCart(metadata) {
        var tipItem = {
          Id: -6000,
          Label: localize.getLocalizedString("_Tip_"),
          itemCustomType: 'Tip',
          RetailPrice: metadata.price
        };

        // Find min id value.
        var minId = TIP_ITEM_ID
        // Find min id value.
        $scope.itemsInCart.forEach(function (x) {
          if (x.Id < minId) {
            minId = x.Id;
          }
        });

        tipItem.Id = minId - 1;
        tipItem.OriginalRetailPrice = tipItem.RetailPrice;
        tipItem.preventRegisteringSubscription = true;
        tipItem.preventMembershipDiscount = true;
        tipItem.EmployeeId = $scope.forcedEmployeeId || configuration.defaultEmployee().id
        addItemToCart(tipItem);
        return true;
      }
    }

    if (configurations.ProductsAndServicesSettings.UseExternalVoucherModel) {
      var externalVoucherPlugin = {
        name: localize.getLocalizedString('_ExternalVoucherUsage_'),
        icon: 'icon icon-external-voucher gift-card-color',
        type: 'external-gift-card-usage',
        disabled: function () {
          return !$scope.totalAggregator.subTotal || parseFloat($scope.totalAggregator.subTotal) < 1;
        },
        disabledTooltip: localize.getLocalizedString('_CanOnlyApplyGiftCardWhenProducts_'),
        delegate: openCustomItemDialogItem.bind(this, 'external-gift-card-usage', function () {
          var valueToReturn = {
            total: $scope.totalAggregator.subTotal,
            voucherId: externalVoucherPlugin.voucherId
          };

          delete externalVoucherPlugin.voucherId;
          return valueToReturn;
        }, localize.getLocalizedString('_ExternalVoucherUsage_'), function (amountWrapper) {
          var voucherAmount = amountWrapper.Price;
          if (amountWrapper.ServiceMetadatas && amountWrapper.ServiceMetadatas.length) {

            var serviceMetadatasRelativePrices = [];
            //calculate sum of original services
            var allServicesAmount = 0;
            for (var i = 0, len = amountWrapper.ServiceMetadatas.length; i < len; i++) {
              allServicesAmount += parseFloat(amountWrapper.ServiceMetadatas[i].RetailPrice);
            }
            //getting services relative prices
            var relativeMultiplier = allServicesAmount != 0 ? (voucherAmount / allServicesAmount) : 1;
            amountWrapper.ServiceMetadatas.forEach(function (service) {
              serviceMetadatasRelativePrices[service.ServiceMetadataId] = service.RetailPrice * relativeMultiplier;
            });

            $scope.itemsInCart.forEach(function (itemInCart) {
              if (itemInCart.ConcreteType === 'ServiceMetadata' && serviceMetadatasRelativePrices[itemInCart.Id]) {
                itemInCart.RetailPrice = serviceMetadatasRelativePrices[itemInCart.Id];
              }
            });
          }

          var customProduct = {
            Id: -cashRegisterPlugins.randomUniqueId(),
            Label: amountWrapper.ExternalVoucherName + ' - ' + localize.getLocalizedString('_ExternalVoucherUsage_'),
            RetailPrice: -voucherAmount,
            ExternalVoucherMetadataId: amountWrapper.ExternalVoucherMetadataId,
            ReferenceNumber: amountWrapper.refNumber,
            Remarks: amountWrapper.remarks,
            itemCustomType: 'ExternalGiftCardUsage'
          };

          customProduct.OriginalRetailPrice = customProduct.RetailPrice;
          customProduct.preventRegisteringSubscription = true;
          addItemToCart(customProduct, true);
          if ($scope.totalAggregator.calculatedAfterDiscount + customProduct.RetailPrice < 0) {
            const giftCardReplacer = {
              Id: -cashRegisterPlugins.randomUniqueId(),
              Label: localize.getLocalizedString('_ExternalVoucherCompensation_', amountWrapper.ExternalVoucherName, amountWrapper.refNumber),
              RetailPrice: Math.abs(Math.floor($scope.totalAggregator.calculatedAfterDiscount + customProduct.RetailPrice)),
              itemCustomType: 'GiftCard',
              preventMembershipDiscount: true
            };

            giftCardReplacer.Id = -cashRegisterPlugins.randomUniqueId();
            giftCardReplacer.OriginalRetailPrice = giftCardReplacer.RetailPrice;
            giftCardReplacer.preventRegisteringSubscription = true;
            addItemToCart(giftCardReplacer, true);
          }

          return true;
        })
      };

      $scope.specialItems.push(externalVoucherPlugin);
    }

    $scope.specialItems.push({
      name: localize.getLocalizedString('_Refund_'),
      icon: 'icon icon-refund points-color',
      type: 'refund',
      delegate: openCustomItemDialogItem.bind(this, 'refund-product', function () {
        return Math.min($scope.totalAggregator.subTotal, 0);
      }, localize.getLocalizedString('_Refund_'), function (metadata) {
        var customProduct = {
          Id: -1,
          itemCustomType: 'Refund',
          Label: metadata.name || localize.getLocalizedString("_RefundProduct_"),
          RetailPrice: metadata.price
        };

        var minId = -3;
        // Find min id value.
        $scope.itemsInCart.forEach(function (x) {
          if (x.Id < minId) {
            minId = x.Id;
          }
        });

        customProduct.Id = minId - 1;
        customProduct.OriginalRetailPrice = customProduct.RetailPrice;
        customProduct.preventRegisteringSubscription = true;
        customProduct.preventMembershipDiscount = true;
        addItemToCart(customProduct, true);
        return true;
      }, 'RefundModal')
    });

    /******************** TABLE COLUMNS  ***/
    const differentialDelta = {};

    var priceField = {
      name: localize.getLocalizedString("_Price_"),
      width: '21%',
      type: ($scope.hasPriceQuote || $scope.enableDiscountPerItem) ? 'NumberWithHandlers' : 'Money',
      valueType: 'float',
      fieldName: 'RetailPrice',
      command: {
        customDelta: function (model) {
          return (model.OriginalRetailPrice * 0.05).toFixed(2)
        },
        differentialDelta: (model) => {
          let originalPrice = parseFloat(model.OriginalRetailPrice);
          let currentPrice = parseFloat(model.RetailPrice);
          let currentDiscountPercentages = (originalPrice - currentPrice) / originalPrice
          let floorDelta = (Math.floor((currentDiscountPercentages * 100) / 5) * 5) / 100.0;
          let ceilDelta = (Math.ceil((currentDiscountPercentages * 100) / 5) * 5) / 100.0;

          if (parseFloat(((currentDiscountPercentages + 1) * 100.0).toFixed(2)) % 5 === 0) {
            let wholeDiscount = (model.OriginalRetailPrice * 0.05).toFixed(2);
            differentialDelta.addition = wholeDiscount;
            differentialDelta.subtraction = wholeDiscount;
          } else {
            differentialDelta.addition = ((ceilDelta - currentDiscountPercentages) * originalPrice).toFixed(2);
            differentialDelta.subtraction = ((currentDiscountPercentages - floorDelta) * originalPrice).toFixed(2)
          }

          return differentialDelta;
        },
        isReadOnly: function (model) {
          return ['PointsUsage', 'GiftCardUsage', 'PunchCardUsage', 'Debt', 'PrePayment', 'PrePaymentUsage', 'TaxInvoice', 'Refund', 'Tip'].indexOf(model.itemCustomType) > -1;
        }
      },
      mode: 'readonly',
      allowMinus: function (model) {
        return (model.Id < 0 && ['GiftCard', 'GenericProduct'].indexOf(model.itemCustomType) < 0) ? Number.MIN_SAFE_INTEGER : 0;
      }
    };

    $scope.productsFields = [
      {
        name: localize.getLocalizedString("_ProductOrServiceName_"),
        width: '45%',
        type: 'ImageWithValue',
        command: {
          get: function (entity) {
            return {
              text: entity.Label,
              image: entityImageResolver.resolve(entity, true)
            };
          },
          dynamicIcon: function (entity) {
            if (!entity.itemCustomType) {
              return 'icon icon-tag-price';
            }

            switch (entity.itemCustomType) {
              case 'GiftCard':
                return 'icon icon-gift-card gift-card-color';
              case 'GiftCardUsage':
                return 'icon icon-gift-card-usage gift-card-color';
              case 'ExternalGiftCardUsage':
                return 'icon icon-external-voucher gift-card-color';
              case 'GenericProduct':
                return 'icon icon-custom-product generic-product-color';
              case 'Refund':
                return 'icon icon-refund points-color';
              case 'PointsUsage':
                return 'icon icon-cash-out points-color';
              case 'Debt':
                return 'icon icon-debt-unlock points-color';
              case 'TaxInvoice':
                return 'icon icon-invoice points-color';
              case 'Tip':
                return 'icon icon-tip_money tip-color';
              case 'PrePayment':
                return 'icon icon-banknote';
            }
          },
          isEditableText: (entity) => {
            return !disableInvoiceItemsNameChanges && (['ServiceMetadata', 'ProductMetadata'].includes(entity.ConcreteType)
              || ['PunchCard', 'Debt', 'PrePayment', 'GenericProduct'].includes(entity.itemCustomType)) && entity.itemCustomType !== 'PunchCardUsage';
          },
          editText: (entity) => {
            confirmService.prompt(
              /*title*/localize.getLocalizedString('_NewName_'),
              `${localize.getLocalizedString('_Current_')}: ${entity.Label}`, '',
              /*onOk*/function (newName) {
                $scope.$evalAsync(() => {
                  entity.Label = newName;

                });
              },
              function () {
              },
              function (value) {
                return value.trim().length > 2 ? null : localize.getLocalizedString('_LengthMustBeAtLeastChars_', 2);

              }
            );
          }
        }
      },
      priceField,
      {
        name: localize.getLocalizedString("_Quantity_"),
        width: '18%',
        type: "NumberWithHandlers",
        valueType: 'float',
        fieldName: 'Quantity',
        command: {
          min: 1,
          isReadOnly: function (entity) {
            return ['PunchCardUsage', 'PunchCard', 'PointsUsage', 'Debt', 'GiftCardUsage', 'PrePayment', 'TaxInvoice', 'ExternalGiftCardUsage'].includes(entity.itemCustomType);
          }
        }
      },
      {
        name: localize.getLocalizedString("_Total_"),
        width: '16%',
        type: 'MoneyWithDiscount',
        command: {
          get: function (entity) {
            return entity.itemCustomType === 'PunchCardUsage' ? 0 : entity.Quantity * entity.RetailPrice;
          },
          beforeDiscount: function (entity) {
            return entity.RetailPrice !== entity.OriginalRetailPrice && entity.Quantity * entity.OriginalRetailPrice;
          }
        }
      }
    ];

    var composedEmployeeField = {
      name: localize.getLocalizedString("_Employee_"),
      width: '25%',
      type: 'Combobox',
      fieldName: 'EmployeeId',
      values: [],
      command: {
        get: function (entity) {
          var byEmployee = entity.EmployeeId;
          if (!byEmployee) {
            entity.EmployeeId = byEmployee = entity.getParent().EmployeeId;
          }


          var filteredEmployees =
            selectedEmployeeField.values.filter(function (employeeOption) {
              return employeeOption.value == byEmployee;
            });

          return filteredEmployees[0];
        },
        set: function (newValue, entity) {
          entity.EmployeeId = newValue.value;
        }
      }
    };

    $scope.composedFields = [
      {
        name: localize.getLocalizedString("_ProductOrServiceName_"),
        width: '30%',
        fieldName: 'Label'
      },
      composedEmployeeField,
      {
        name: localize.getLocalizedString("_Price_"),
        width: '40%',
        type: 'Money',
        command: {
          get: function (entity) {
            return calculateComposedRelativePrice(entity.getParent(), entity);
          }
        }
      }
    ];


    /*** EMPLOYEES HANDLER ***/
    if ($scope.enableEmployeePerProduct) {
      var selectedEmployeeField = {
        name: localize.getLocalizedString("_Employee_"),
        width: '15%',
        type: 'Combobox',
        fieldName: 'EmployeeId',
        values: [],
        command: {
          get: function (entity) {
            var filteredEmployees = null;
            if (entity.EmployeeId) {
              filteredEmployees =
                selectedEmployeeField.values.filter(function (employeeOption) {
                  return employeeOption.value === entity.EmployeeId;
                });
            }

            return filteredEmployees && filteredEmployees.length > 0 ? filteredEmployees[0] : selectedEmployeeField.values[0];
          },
          set: function (newValue, entity) {
            entity.EmployeeId = newValue.value;
          }
        }
      };

      $scope.productsFields.splice($scope.productsFields.length - 1, 0, selectedEmployeeField);

      selectedEmployeeField.values = [];

      Repository.Entity("Employee").query().filter("IsDeactiveted eq false").get().then(function (iEmployees) {
        const employees = iEmployees.results.map(function (employee) {
          return {
            name: employee.FirstName + " " + employee.LastName,
            value: employee.EmployeeId
          };
        });

        selectedEmployeeField.values.pushAll(employees);
        composedEmployeeField.values.pushAll(employees);
      }).catch(function (err) {
        toastr.error(err.Message);
      });
    }

    /********************* CUSTOM WIDTH FOR CUSTOM FIELDS ON TABLE *********************/
    if ($scope.hasPriceQuote || $scope.enableDiscountPerItem || $scope.enableEmployeePerProduct) {
      var updatedColumnWidths;
      // Both editable price AND editable employee:
      if (($scope.hasPriceQuote || $scope.enableDiscountPerItem) && $scope.enableEmployeePerProduct) {
        updatedColumnWidths = ['30%', '20%', '17%', '16%', '18%'];
      } else if (!$scope.enableEmployeePerProduct) {
        updatedColumnWidths = ['40%', '22%', '22%', '16%'];
      } else { // only enableEmployeePerProduct
        updatedColumnWidths = ['40%', '12%', '20%', '16%', '12%'];
      }

      $scope.productsFields.forEach(function (column, idx) {
        column.width = updatedColumnWidths[idx];
      })
    }

    $scope.tileDoubleClicked = function (productOrService) {
      var addedItem = addItemToCart(productOrService);
      if (!addedItem) { // IF not enough items it will be null.
        return;
      }

      if (!ignoreIngredientsModel && addedItem && addedItem.HasIngredients) {
        openIngredientsDialog(addedItem);
      }

      if (helpMeChoosingEmployee && addedItem.Quantity === 1) {
        setTimeout(function () {
          addedItem.chooseEmployee();
        }, 100)
      }
    };

    $scope.pickACustomer = openCustomerPickerDialog;


    $scope.toggleActions = function () {
      console.log("tile action");
    };

    $scope.tileDropped = function (e) {
      var addedItem = addItemToCart(e.dropData);
      if (addedItem.HasIngredients) {
        openIngredientsDialog(addedItem);
      }
    };

    Object.defineProperty($scope, 'sessionBadge', {
      get() {
        let sessionBadge = isConcreteValue($scope.sessionId) ? 'icon icon-check-circle' : null;
        if ($scope.paymentLinkUrl) {
          sessionBadge = 'icon icon-payment-link';
        }

        return sessionBadge;
      }
    });

    $scope.rowDecorator = function (scope, element) {
      // Any reason to decorate?
      if ((scope.item.itemCustomType !== 'PunchCard' && scope.item.Id > 0) || (scope.item.ConcreteType === 'ServiceMetadata' && scope.item.HasIngredients)) {
        let el = '<div class="cash-register-item-icons">';

        // HAS Subscription?
        if ($scope.isUsingPunchCard && scope.item.itemCustomType !== 'PunchCardUsage' && !scope.item.preventRegisteringSubscription) {
          scope.relatedPunchcardTemplates = [];
          scope.addPunchcard = function () {

            if (!$scope.customerInvoice) {
              toastr.warning(localize.getLocalizedString("_PunchcardMustHaveCustomerWarning_"));
              return;
            }

            if (scope.relatedPunchcardTemplates.length > 0) {
              scope.showPunchcardPopover = true;
            } else {
              scope.editPunchCard()
            }
          };

          scope.allowGeneratingCustomPunchcard = true;

          scope.editPunchCard = () => {
            scope.showPunchcardPopover = false;
            openCreatePunchCardDialog(scope.item);
          }

          scope.hideTemplatePicker = () => {
            scope.showPunchcardPopover = false;
          }

          let adaptedTotalPriceInCaseOfPriceList = (templateTotalPrice, templatePurchasedQuantity) => {
            //if the item has price quote effecting the price
            if (scope.item.PriceLists && scope.item.PriceLists.length > 0) {
              //if price of the template equals to quantity * original retail price (price wasn't edited)
              if (parseFloat(templateTotalPrice) === parseFloat(scope.item.OriginalRetailPrice) * parseFloat(templatePurchasedQuantity)) {
                //then we will adapt price to the price list original price
                return templatePurchasedQuantity * scope.item.RetailPrice;
              }
            }

            return templateTotalPrice;
          }

          scope.useTemplate = (template) => {
            const punchCard = {
              CustomerId: $scope.customerInvoice.CustomerId,
              buy: parseFloat(template.PurchasedQuantity),
              get: parseFloat(template.GivenQuantity),
              RetailPrice: adaptedTotalPriceInCaseOfPriceList(template.TotalPrice, template.PurchasedQuantity),
              OriginalRetailPrice: template.TotalPrice,
              Label: template.Title,
              ExpirationDate: Moment().add(defaultSubscriptionExpirationDateInMonths, 'M').format(ODATA_DATE_ONLY_FORMAT),
            }

            addPunchcardForItem(scope.item, punchCard);
            if (!configurations.CashRegisterSettings.PreventFirstUsageOnCreatingSubscription) {
              $timeout(function () {
                addItemToCart(scope.item);
              });
            }
          }

          let loadTemplates = () => {
            subscriptionTemplatePlugin.getTemplates().then((templates) => {
              scope.relatedPunchcardTemplates = templates[`${scope.item.ConcreteType.charAt(0)}${scope.item.Id}`] || [];
            });
          };

          if (subscriptionTemplatePlugin) {
            loadTemplates();
          } else {
            let customerWatch = $scope.$watch('customerInvoice', (newVal) => {
              if (newVal) {
                customerWatch();
                loadTemplates();
              }
            })
          }

          const subscriptionTemplate = `
            <div>
                <div class="cash-register-item-icon" ng-click="addPunchcard()" foreground-click="true">
                    <span class="cash-register-ingredients-icon icon icon-punchcard"></span>
                </div>
                <div ng-if="showPunchcardPopover" class="cash-register-item-icon__mask" disable-click-effect="true" ng-click="hideTemplatePicker()"></div>
                <div class="cash-register-item-icon__popover-arrow animate-opacity-appearance" ng-if="showPunchcardPopover"></div>
                <div class="cash-register-item-icon__popover animate-opacity-appearance" ng-if="showPunchcardPopover">
                    <div i18n="_Templates_" class="cash-register-item-icon__popover-title"></div>
                    <button ng-repeat="template in relatedPunchcardTemplates" ng-click="useTemplate(template)">
                         {{ template.Name }}
                    </button>
                    <button class="flex cash-register-item-icon__popover-show-dialog" ng-click="editPunchCard()" ng-if="allowGeneratingCustomPunchcard">
                        <span i18n="_Edit_"></span>
                        <span class="icon icon-edit"></span>
                    </button>

                </div>
            </div>`;


          el += subscriptionTemplate;
        } else if (scope.item.itemCustomType === 'PunchCardUsage') {
          scope.cancelPunchcardUsage = function () {
            removeItemFromCart(scope.item);
            $timeout(function () {
              delete scope.item.itemCustomType;
              delete scope.item.SubscriptionId;
              scope.item.ignorePunchCard = true;
              Object.assign(scope.item, { RetailPrice: scope.item.OriginalRetailPrice });
              addItemToCart(scope.item, true);
            });
          };

          Object.defineProperty(scope, 'subscriptionUsages', function () {
            var subscription = getItemSubscription(scope.item);
            var totalSubscriptionQuantity = parseInt(subscription.TotalQuantity);
            var toReturn = {};
            toReturn.get = function () {
              return "(" + (subscription.QuantityBalance) + "/" + totalSubscriptionQuantity + ")";
            };

            return toReturn;
          }());

          var ignoreSubscription =
            `<div class="cash-register-item-icon" ng-click="cancelPunchcardUsage()" foreground-click="true" tippy="${localize.getLocalizedString('_CancelPunchcardUsage_')}">
    <span class="cash-register-ingredients-icon icon icon-punchcard-cancel"></span>
    <span class="cash-register-subscription-balance">{{subscriptionUsages}}</span>
    </div>`;
          el += ignoreSubscription;
          element.addClass('cash-register-punchcard-usage-item');
        }

        // HAS Ingredients?
        if (scope.item.ConcreteType === 'ServiceMetadata' && scope.item.HasIngredients) {
          scope.editIngredients = function () {
            openIngredientsDialog(scope.item);
          };

          el += '<div class="cash-register-item-icon" ng-click="editIngredients()" foreground-click="true">\
                                <span class="cash-register-ingredients-icon icon icon-lab"></span>\
                                <div class="cash-register-ingredients-quantity">\
                                    <span ng-bind="item.Ingredients.length"></span>\
                                </div>\
                           </div>';

        }

        el += '</div>';

        var compiledElement = $compile(el)(scope);

        if (helpMeChoosingEmployee) {
          scope.item.chooseEmployee = function () {
            try {
              if (element.closest('.composite-row').length == 0) {
                angular.element(element.find('.column-for-field-employee-id .combobox .combobox-default-combo-value-wrapper')[0]).scope().toggleCombo();
              } else {
                var internalFirstCombo = element.parent().find('.child-row .column-for-field-employee-id .combobox .combobox-default-combo-value-wrapper')[0];
                angular.element(internalFirstCombo).scope().toggleCombo();
              }
            } catch (e) {
              console.log(e);
            }
          };
        }

        if (scope.item.Composite) {
          var comboEl = element.find('.column-for-field-employee-id .combobox .combobox-default-combo-value-wrapper');
          comboEl.css('opacity', 0.2);
          angular.element(comboEl[0]).scope().disabled = true;
        }

        var toAppendTo = element.find('td:first-of-type .droppable-table-icon-with-value-decorator');
        toAppendTo.append(compiledElement);

      }
    };

    $scope.priceQuoteMode = false;
    $scope.togglePriceQuoteMode = function (forceMode) {
      if (forceMode === $scope.priceQuoteMode) {
        return;
      }

      $scope.priceQuoteMode = angular.isDefined(forceMode) ? forceMode : !$scope.priceQuoteMode;
      if ($scope.priceQuoteMode) {
        delayedSaveToSession.setDisabled(true);
        intermediateManager.clearSession($scope.sessionId);
        delete $scope.sessionId;
        $scope.itemsInCart.forEach(function (item) {
          if (['PunchCardUsage', 'GiftCard', 'PointsUsage', 'GiftCardUsage', 'ExternalGiftCardUsage'].indexOf(item.itemCustomType) > -1) {
            removeItemFromCart(item);
          }
        });
      } else {
        delayedSaveToSession.setDisabled(false);
      }
    };

    if ($scope.hasPriceQuote) {
      $scope.itemsInCartByCategory = {};
      $scope.$watchCollection('itemsInCart', function (newVal) {
        if (!angular.isDefined(newVal)) {
          return;
        }

        $scope.itemsInCartByCategory = {};
        angular.forEach(newVal, function (item) {
          var itemCategoryId = item.ConcreteType + item.CategoryId;
          if (!$scope.itemsInCartByCategory.hasOwnProperty(itemCategoryId)) {
            $scope.itemsInCartByCategory[itemCategoryId] = [];
          }

          $scope.itemsInCartByCategory[itemCategoryId].push(item);
        });
      });

      $scope.reloadItems = function (toReload) {
        $scope.reset(true);
        angular.forEach(toReload.items, function (item) {
          addItemToCart(item, true);
        });

        $timeout(function () {
          $scope.totalAggregator.subTotal = toReload.total;
        });

      };

      $scope.clearAndToggle = function () {
        $scope.reset();
        // $scope.$digestIfNeeded();
        $timeout(function () {
          $scope.togglePriceQuoteMode(false);
        }, 400);
      };
    }

    $scope.itemRemoved = function (item) {
      item.CurrentInventory += item.Quantity;
    };

    $scope.totalAggregator = {};
    $scope.totalAggregator.discountPercentage = 0;


    var pricesAreIncludeTax = true;
    /******************** GENERIC PRODUCT ***/


    /******************** CUSTOMER'S HISTORY ***/

    $scope.popCustomerHistory = function () {
      $scope.showCustomerHistory = !$scope.showCustomerHistory;
    };


    $scope.$watch('itemsInCart', itemsInCartChanged, true);

    const delayedSaveToSession = new DelayedFunction(_saveToSession, 50);
    // delayedSaveToSession.setDisabled(true);


    $scope.discountChanged = function (discount) {
      // This means the discount component was manually changed.
      if ($scope.totalAggregator.discountPercentage != discount) {
        $scope.totalAggregator.discountPercentage = discount;
      }

      $scope.totalAggregator.pinDiscount = "Percentage";
      $scope.totalAggregator.calculatedAfterDiscount = $scope.totalAggregator.calculatedSubTotal * (1 - parseFloat(discount) / 100);
      $scope.totalAggregator.discountAsPrice = $scope.totalAggregator.calculatedSubTotal - $scope.totalAggregator.calculatedAfterDiscount;
      calculateTotals();
    };

    $scope.calculatedSubTotalChanged = function (priceAfterDiscount) {
      // This means the total component was manually changed.
      $scope.totalAggregator.pinDiscount = "Price";
      $scope.totalAggregator.calculatedAfterDiscount = parseFloat(priceAfterDiscount);
      $scope.totalAggregator.discountPercentage = calculateDiscountLevel();
      $scope.totalAggregator.discountAsPrice = $scope.totalAggregator.calculatedSubTotal
        - priceAfterDiscount;
      calculateTotals();
    };

    $scope.reset = function (leaveCustomer) {
      contextManager.clearAll();
      $scope.itemsInCart.length = 0;
      $scope.totalAggregator.calculatedAfterDiscount = 0;
      $scope.totalAggregator.discountPercentage = 0;
      $scope.customCustomerCreditToken = null;

      /// TODO: WHAT?
      if ($scope.priceQuoteMode) {
        $scope.togglePriceQuoteMode();
        delete $scope.priceQuoteMetadataFromSession;
        intermediateManager.clearSession($scope.sessionId);
      }

      delete $scope.paymentLinkUrl;
      delete $scope.sessionId;
      delete $scope.sessionVersion;
      delete $scope.forcedEmployeeId;

      if (!leaveCustomer) {
        clearCurrentCustomer();
      }

      intermediateManager.clearGlobalSession();

    };

    $scope.invokeDailyActions = function () {
      globalActions.cashRegisterActions.bind(this, {
        dayClosed: function () {
          $scope.isOverlappingZ = false;
        }
      })();
    };

    $scope.checkout = openCheckoutDialog;

    $scope.wasIfOverlappingZLoaded = false;
    ensureZNoOverlapping();

    $scope.closeDay = function () {
      $scope.isClosingDay = true;
      cashRegisterDailyActions.zReport().then(function () {
        $scope.$evalAsync(function () {
          $scope.isOverlappingZ = false;
          $scope.isClosingDay = false;
        });
      }).catch(function (message) {
        $scope.$evalAsync(function () {
          $scope.isClosingDay = false;
        });

        toastr.error(message);
      })
    };

    if ($scope.hasPriceQuote) {
      var priceQuoteModeText = localize.getLocalizedString("_PriceQuote_");
      var cashRegisterModeText = localize.getLocalizedString("_CashRegister_");
      $scope.priceQuoteModeText = priceQuoteModeText;
      var isCurrentlyPriceQuoteMode = false;

      $scope.$watch('priceQuoteMode', function (newVal) {
        if (newVal === isCurrentlyPriceQuoteMode) {
          return;
        }

        isCurrentlyPriceQuoteMode = newVal;
        $timeout(function () {
          if (!newVal) {
            $scope.priceQuoteModeText = priceQuoteModeText;
          } else {
            $scope.priceQuoteModeText = cashRegisterModeText;
          }
        }, 700);
      });
    }

    // PointsDefinition.
    var pointsUsageTextBase = localize.getLocalizedString('_UsePointsAmount_');
    $scope.availablePoints = { value: 0 };
    Object.defineProperty($scope, 'pointsUsageText', {
      get: function () {
        return isNumber(parseFloat($scope.availablePoints.value)) ? (presentablePrice(parseFloat($scope.availablePoints.value)) + ' ' + pointsUsageTextBase) : null;
      }
    });


    // PrePaymentUsage.
    Object.defineProperty($scope, 'prePaymentAvailable', {
      get: function () {
        if ($scope.customerInvoice?.Debt < 0) {
          return $scope.customerInvoice.Debt - $scope.itemsInCart.reduce((acc, item) => acc + (item.itemCustomType === 'PrePaymentUsage' ? (parseFloat(item.RetailPrice) * parseFloat(item.Quantity)) : 0), 0);
        }

        return 0;
      }
    });

    var didInitCustomer = false;
    stateManager.registerStateChanged('CashRegister', (state) => {
      [membershipDiscountPlugin, specialItemsPlugin].forEach((plugin) => isFunction(getSafely(['dispose'], plugin)) && plugin.dispose());
      if (!$scope.priceQuoteMode) {
        reloadSessionItems();
      }
    });

    $scope.openPointsUsage = openPointsUsage;

    $scope.continueToCheckoutWrapper = {};
    pci.supportsWebPayments().then((isSupported) => {
      if (isSupported) {
        $scope.coreActions = [
          {
            name: localize.getLocalizedString('_GeneratePaymentLinkTooltip_'),
            action: () => {
              showCreatePaymentLinkDialog();
            }
          }
        ];

        $scope.secondActionTooltip = localize.getLocalizedString('_GeneratePaymentLinkTooltip_');

        reportsAction.actions.push(
          {
            name: localize.getLocalizedString('_PaymentLinks_'),
            icon: 'icon icon-payment-link',
            action: globalActions.showPaymentLinks
          });

      }
    });

    function showCreatePaymentLinkDialog(force) {
      if ($scope.totalAggregator.calculatedAfterDiscount < 1) {
        return toastr.error(localize.getLocalizedString('_PaymentLinkMustHaveAPositiveAmount_'));
      }

      if (!$scope.sessionId) {
        if (!getSafely(['customerInvoice', 'CustomerId'], $scope)) {
          return openCustomerPickerDialog();
        }

        return _saveToSession(true).then(showCreatePaymentLinkDialog);
      }

      if (!force && $scope.paymentLinkUrl) {
        return confirmService.confirm(localize.getLocalizedString('_PaymentLinkAlreadyExistsForSessionTitle_'),
          localize.getLocalizedString('_PaymentLinkAlreadyExistsForSessionDescription_'),
          function () {
            showCreatePaymentLinkDialog(true);
          },
          function () {

          }
        );
      }


      const deferred = $q.defer();
      const opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/payment-link/payment.link.modal.tpl.html'),
        controller: 'PaymentLinkController',
        resolve: {
          sessionId: () => {
            return $scope.sessionId;
          },
          customer: () => {
            return {
              customerName: (`${$scope.customerInvoice.FirstName || ''} ${$scope.customerInvoice.LastName || ''}`).trim(),
              mobile: $scope.customerInvoice.MobileFirst,
              address: $scope.customerInvoice.Address,
              identifyNumber: $scope.customerInvoice.MembershipId
            }
          },
          onCreateDelegate: () => {
            return (session) => {
              $scope.paymentLinkUrl = session.paymentLink.shortUrl;
              $scope.reset(true);
            }
          }
        },
        closeFn: function () {
        }
      };

      let invoiceOnlyForValidation = invoiceFacade.generateNewInvoiceData($scope.itemsInCart, $scope.totalAggregator, $scope.customerInvoice, $scope.priceQuoteMetadataFromSession, $scope.sessionId);
      Repository.Custom("CashRegister").validateInvoice(invoiceOnlyForValidation).then(function (result) {
        openModalIfValid(result, opts);
      }).catch(function (error) {
        toastr.error(error.data);
        return;
      });

      return deferred.promise;
    }

    function openModalIfValid(resultFromServer, opts) {
      let result = resultFromServer?.results && resultFromServer?.results.length ? resultFromServer?.results[0] : null;
      if (result && result.validate && result.validate.length > 0) {
        if (result.validate === "VerifyDiscountWithPassword") {
          //use password
          opts.forceAuthorization = true;
          $modal.open(opts);
        } else if (result.validate === "VerifyDiscountWithCode") {
          //use code
          verifyCode(opts, result.code);
        }
      } else {
        $modal.open(opts);
      }
    }

    function verifyCode(opts, encrypted) {
      confirmService.prompt(
        /*title*/localize.getLocalizedString("_DiscountVerification_"),
        /*subtitle*/localize.getLocalizedString("_PleaseEnterVerificationCode_"),
        /*placeholder*/'',
        /*onOk*/function (code) {
          Repository.Custom("CashRegister").validateDiscountCode({ Code: code, Encrypted: encrypted }).then(function (resultFromCodeValidation) {
            if (resultFromCodeValidation.results && resultFromCodeValidation.results.length > 0 && resultFromCodeValidation.results[0]) {
              $modal.open(opts);
            } else {
              toastr.error(localize.getLocalizedString("_InvalidVerificationCode_"));
              verifyCode(opts, encrypted);
            }
          });
        },
        function () {
        },
        function (value) {
        }, /*Default value*/''
      );
    }

    function addItemToCart(item, asIs) {
      // Find index or indexes of certain item in the cash register.
      const index = $scope.itemsInCart.indexOfByIds(item, ['Id', 'ConcreteType'], true);

      if (!asIs && !verifyInventory(index >= 0 ? $scope.itemsInCart[index] : item)) {
        return undefined;
      }

      var isSubscriptionEnded = undefined;
      var subscriptionId = undefined;

      // Handling punchcard usage.
      if (item.itemCustomType !== 'PunchCard' && ['ServiceMetadata'].includes(item.ConcreteType) && !asIs && !item.ignorePunchCard && $scope.isUsingPunchCard && $scope.customerInvoice && $scope.customerInvoice.subscriptionsMap && Object.keys($scope.customerInvoice.subscriptionsMap).length > 0) {
        var result = getMatchingSubscriptionForItem(item, index);
        isSubscriptionEnded = result.isSubscriptionEnded;
        subscriptionId = result.subscriptionId;
      }

      var itemAdded = null;


      if (!separateSameItemsToDifferentLines && (angular.isArray(index) || index > -1) && !isSubscriptionEnded) {
        // Incase of more than one item, one must not be a subscription.
        itemAdded = !angular.isArray(index) ? $scope.itemsInCart[index] : function () {
          // find the item that is not punchcard usage.
          var firstItem = $scope.itemsInCart[index[0]];
          var secondItem = $scope.itemsInCart[index[1]];
          return firstItem.itemCustomType === 'PunchCardUsage' ? secondItem : firstItem;
        }();

        itemAdded.Quantity = parseFloat(itemAdded.Quantity) + (item.Quantity ? item.Quantity : 1);
      } else {
        var copiedItem = itemAdded = Object.assign({}, item);
        // Dealing with subscriptions.
        if (subscriptionId) {
          copiedItem.itemCustomType = 'PunchCardUsage';
          copiedItem.SubscriptionId = subscriptionId;
          copiedItem.RetailPrice = 0;
          copiedItem.Quantity = 1;
        } else if (isSubscriptionEnded) {
          copiedItem.preventRegisteringSubscription = true;
        }

        delete copiedItem.$$hashKey;
        copiedItem.remove = $scope.removeItem;
        copiedItem.Quantity = copiedItem.Quantity ? copiedItem.Quantity : 1;
        copiedItem.edit = function () {
          editItem(copiedItem);
        };

        if (!copiedItem.OriginalRetailPrice) {
          copiedItem.OriginalRetailPrice = parseFloat(item.RetailPrice);
        }

        const employeeId = $scope.forcedEmployeeId || configuration.defaultEmployee().id
        if (employeeId && !copiedItem.EmployeeId) {
          copiedItem.EmployeeId = employeeId;
        }

        $scope.itemsInCart.push(copiedItem);

        itemAdded = copiedItem;
      }

      if (!ignoreInventoryConfiguration && item.ConcreteType === 'ProductMetadata') {
        itemAdded.CurrentInventory--;
      }

      if (item.itemCustomType === 'PunchCard' && item.Id < 0) {
        const newSubscription =
        {
          SubscriptionId: item.Id,
          QuantityBalance: 0,
          ActualPrice: item.OriginalRetailPrice,
          ItemMetadataType: item.Item.ConcreteType,
          ItemMetadataId: item.Item.Id,
          Title: item.Label,
          TotalQuantity: item.buy + item.get
        };

        $scope.customerInvoice.subscriptionsMap = $scope.customerInvoice.subscriptionsMap || {};
        $scope.customerInvoice.subscriptionsMap[newSubscription.ItemMetadataId + newSubscription.ItemMetadataType] = newSubscription;
      }


      /// TODO: Make sure it is handled.
      // if (item.ConcreteType === "ServiceMetadata" && !item.MeetingId && ($scope.removedMeetingId || $scope.SourceMeetingId)) {
      //   itemAdded.MeetingId = $scope.removedMeetingId || $scope.SourceMeetingId;
      //   itemAdded.MeetingStartTime = $scope.removedMeetingStartTime || $scope.SourceMeetingDate;
      //   $scope.removedMeetingId = null;
      //   $scope.removedMeetingStartTime = null;
      // }

      return itemAdded;
    }

    /* This method looks for a subscription in the current customer's subscription and verifies the subscription balance is > 0.
     */
    function getMatchingSubscriptionForItem(item, index) {
      var subscriptionId = undefined;
      var isSubscriptionEnded = false;
      var subscription = getItemSubscription(item);
      if (subscription) {
        if (parseInt(subscription.QuantityBalance) < parseInt(subscription.TotalQuantity)) {
          subscription.QuantityBalance++;
          subscriptionId = subscription.SubscriptionId;
        } else if (!angular.isArray(index)) {
          isSubscriptionEnded = true;
          toastr.warning(localize.getLocalizedString("_NoUsagesLeftForPunchCard_"));
        }
      }

      return { isSubscriptionEnded: isSubscriptionEnded, subscriptionId: subscriptionId };
    }

    function verifyInventory(item) {
      var toReturn = true;

      if (item.Id > -1 && item.ConcreteType === 'ProductMetadata' && item.CurrentInventory <= 0 && !ignoreInventoryConfiguration) {
        toReturn = false;
        if (!preventInventoryUpdateOnCashRegister) {


          confirmService.prompt(
            /*title*/localize.getLocalizedString("_ProductIsNotInInventory_"),
            /*subtitle*/localize.getLocalizedString("_PleaseUpdateInventory_"),
            /*placeholder*/localize.getLocalizedString("_CurrentInventory_"),
            /*onOk*/function (updatedValue) {
              Repository.Custom("CashRegister").updateInventory(item.Id, updatedValue, item.CurrentInventory).then(function () {
                // and then
                item.CurrentInventory = updatedValue;
                addItemToCart(item);
                confirmService.alert(localize.getLocalizedString("_InventoryUpdated_"));
                // $scope.$digest();
              }).catch(function (error) {
                toastr.error(localize.getLocalizedString("_FailedToUpdateInventory_"));
                confirmService.alert(localize.getLocalizedString("_FailedToUpdateInventory_"));
              });
            },
            function () {
            },
            function (value) {
              return /^\+?(0|[1-9]\d*)$/.test(value) ? null : localize.getLocalizedString("_InventoryMustBePositveNumber_");
            }, /*Default value*/1
          );
        } else {
          toastr.error(localize.getLocalizedString("_ProductIsNotInInventory_"));
        }
      }

      return toReturn;
    }

    function removeItemFromCart(item) {
      $scope.itemRemoved(item);
      var itemIndex = $scope.itemsInCart.indexOfById(item);

      if (itemIndex > -1) {
        $scope.itemsInCart.splice(itemIndex, 1);
      }
    }

    function editItem(item) {
      openIngredientsDialog(item);
    }

    function handleCustomerChange(newVal, oldVal) {
      if (newVal) {
        contextManager.set('customer', newVal);
      } else {
        contextManager.clear('customer');
      }

      if (membershipDiscountPlugin) {
        membershipDiscountPlugin.dispose();
      }

      if ($scope.isUsingPunchCard && newVal && (newVal !== oldVal || !didInitCustomer)) {
        didInitCustomer = true;
        if (oldVal) {
          removeAllSubscriptionUsages();
        }

        $scope.customerInvoice.subscriptionsMap = $scope.customerInvoice.subscriptionsMap || {};

        ($scope.customerInvoice.Subscriptions || []).reverse().forEach(function (subscription) {
          if (subscription.SubscriptionStatus === 'Active' &&
            (!considerSubscriptionsExpirationDate || (subscription.ExpirationDate && Moment(subscription.ExpirationDate).isAfter(Moment())))) {
            $scope.customerInvoice.subscriptionsMap[subscription.ItemMetadataId + subscription.ItemMetadataType] = subscription;
          }
        });

        membershipDiscountPlugin = new cashRegisterPlugins.discountPlugin(newVal, $scope, 'itemsInCart');
        if (Array.isArray(membershipDiscountPlugin.memberships) && membershipDiscountPlugin.memberships.length > 0) {
          $scope.discountLabel = membershipDiscountPlugin.memberships.join(',');
        } else {
          $scope.discountLabel = '';
        }


        if (newVal.PriceLists && newVal.PriceLists.length > 0) {
          var priceLists = [];
          newVal.PriceLists.forEach(function (priceList) {
            priceLists.push(priceList.PriceListName);
          });
          $scope.discountLabel = $scope.discountLabel ? $scope.discountLabel + ',' + priceLists.join(',') : priceLists.join(',');
        }

        updateItemsToUseSubscriptions();
      }

      $scope.openPrePaymentUsage = () => {
        openCustomItemDialogItem('points', function () {
          return Math.min(-$scope.customerInvoice.Debt, $scope.totalAggregator.subTotal);
        }, localize.getLocalizedString('_UsePrePayment_'), function (amountWrapper) {
          addPrePaymentUsage(amountWrapper.amount, true);
          return true;
        }, null, {
          header: localize.getLocalizedString("_PickPrePaymentAmount_"),
          useTitle: localize.getLocalizedString("_UsePrePayment_")
        });
      };


      //Points
      /// TODO: Doesn't persist.
      if (newVal && $scope.isUsingPoints && newVal.EnablePoints && newVal.Points && parseFloat(newVal.Points) > 0) {
        $scope.availablePoints.value = newVal.Points;
        $scope.customerCantUsePointsYet = $scope.minimumForUsingPoints.Value > parseFloat($scope.availablePoints.value);
      }
    }

    function openPointsUsage() {
      openCustomItemDialogItem('points', function () {
        return Math.min($scope.availablePoints.value, $scope.totalAggregator.subTotal);
      }, localize.getLocalizedString('_UsePointsHeader_'), function (amountWrapper) {
        const discountRow = {
          Id: -cashRegisterPlugins.randomUniqueId(),
          itemCustomType: 'PointsUsage',
          Label: localize.getLocalizedString('_PointsUsageCashRegisterItem_'),
          RetailPrice: -amountWrapper.amount,
          preventMembershipDiscount: true
        };

        discountRow.OriginalRetailPrice = discountRow.RetailPrice;
        discountRow.preventRegisteringSubscription = true;
        addItemToCart(discountRow, true);
        return true;
      });
    }

    function isPunchCardItemUsageInCart(item) {
      return $scope.isUsingPunchCard && item.itemCustomType === 'PunchCardUsage';
    }

    function isTemporarySubscriptionItemInCart(item) {
      return $scope.isUsingPunchCard && item.itemCustomType === 'PunchCard' && item.Id < 0;
    }

    function updateItemsToUseSubscriptions() {
      var toReplace = [];
      $scope.itemsInCart.forEach(function (itemInCart) {
        var subscription = getItemSubscription(itemInCart);
        if (subscription) {
          toReplace.push({ item: itemInCart, subscriptionId: subscription.SubscriptionId });
        }
      });

      toReplace.forEach(function (item) {
        replaceItemWithSubscriptionUsageItem(item.item);
      });
    }

    function getItemSubscription(item) {
      return $scope.customerInvoice.subscriptionsMap[item.Id + item.ConcreteType];
    }

    function deleteItemTemporarySubscription(temporarySubscriptionItem) {
      delete $scope.customerInvoice.subscriptionsMap[temporarySubscriptionItem.Id + temporarySubscriptionItem.ConcreteType];
    }

    function removeUsagesOfCancelledSubscription(newItems, subscriptionId) {
      newItems.forEach(function (newItem) {
        if (isPunchCardItemUsageInCart(newItem)) {
          var subscription = getItemSubscription(newItem);
          if (subscription && subscription.SubscriptionId === subscriptionId) {
            delete newItem.itemCustomType;
          }
        }
      });
    }

    function removeAllSubscriptionUsages() {
      var itemsToRemoveFromCart = [];

      $scope.itemsInCart.forEach(function (itemInCart) {
        if (['PunchCardUsage', 'PunchCard'].includes(itemInCart.itemCustomType)) {
          itemsToRemoveFromCart.push(itemInCart);
        } else if (itemInCart.preventRegisteringSubscription) {
          delete itemInCart.preventRegisteringSubscription;
        }
      });

      itemsToRemoveFromCart.forEach(function (itemToRemove) {
        removeItemFromCart(itemToRemove);
      });

    }

    function replaceItemWithSubscriptionUsageItem(item) {
      removeItemFromCart(item);
      var usageItem = $.extend({}, item);
      $timeout(function () {
        var itemQuantity = item.Quantity;
        usageItem.Quantity = 1;
        for (var idx = 0; idx < itemQuantity; idx++) {
          addItemToCart(usageItem);
        }
      });
    }

    function reloadSessionItems(fromSocket) {
      delete $scope.sessionId;
      delete $scope.sessionVersion;
      delayedSaveToSession.setDisabled(true);
      const validRawParams = stateManager.getRawParams();
      const isPriceQuoteSession = validRawParams.priceQuoteId || $scope.priceQuoteMode;
      if (isPriceQuoteSession) {
        $scope.reset(true);
      }

      delete $scope.paymentLinkUrl;
      $scope.forcedEmployeeId = parseInt(validRawParams.defaultEmployeeId);
      if (isNaN($scope.forcedEmployeeId)) {
        delete $scope.forcedEmployeeId;
      }

      $scope.isLoadingSession = true;
      intermediateManager.getSession(validRawParams).then((loadedSession) => {
        $scope.paymentLinkUrl = loadedSession.paymentLinkUrl;
        $scope.customCustomerCreditToken = getSafely(['relatedCreditTokens'], loadedSession);
        if (loadedSession.isFallbackSession) {
          confirmService.confirm(localize.getLocalizedString('_FallbackSessionTitle_'),
            localize.getLocalizedString('_FallbackSessionDescription_'),
            () => {
              handleLoadedSession(loadedSession, isPriceQuoteSession);
              $scope.isLoadingSession = false;
            },
            () => {
              const prevCustomer = $scope.customerInvoice || null;
              if (fromSocket) {
                $scope.customerInvoice = true;
              }

              $scope.customerInvoice = loadedSession.customer;
              handleCustomerChange(loadedSession.customer, prevCustomer);
              specialItemsPlugin = new cashRegisterPlugins.specialItemsPlugin($scope, removeItemFromCart);
              $scope.isLoadingSession = false;
            }
          );
        } else {
          handleLoadedSession(loadedSession, isPriceQuoteSession, fromSocket);
        }

        if ($scope.shouldHandleDebt) {
          $scope.shouldHandleDebt = false
          handleDebt();
        }
      }).catch((err) => {
        $scope.isLoadingSession = false;
        toastr.error(extractError(err, localize.isHebrew() ? 'he' : 'en'));
      })
    }

    function handleLoadedSession(loadedSession, isPriceQuoteSession, fromSocket) {
      $scope.isLoadingSession = false;
      const {
        sessionId,
        customer,
        items,
        priceQuote,
        externalVoucherIds,
        totals,
        sessionVersion
      } = valueOrDefault(loadedSession, {});
      $scope.sessionId = sessionId;
      $scope.sessionVersion = sessionVersion;
      if (!isPriceQuoteSession) {
        $timeout(() => {
          delayedSaveToSession.setDisabled(false);
        });
      } else if (priceQuote) {
        $scope.priceQuoteMetadataFromSession = priceQuote;
      }

      $timeout(findAndHandleMissingPayments);
      const prevCustomer = $scope.customerInvoice;
      $scope.customerInvoice = isNonEmptyObject(customer) ? cleanObj({ ...customer, preventToasts: fromSocket }) : null;
      handleCustomerChange($scope.customerInvoice, prevCustomer);

      if (fromSocket) {
        $scope.itemsInCart.length = 0;
      }

      /**
       * If an item already exists in cart at this point (this means there is no customer selected, otherwise it was empty)
       * Remove items that exists in session (take the session ones).
       */
      [...($scope.itemsInCart || [])].forEach((itemInCart) => {
        if (valueOrDefault(items, []).some((sessionItem) => allConditionsAreMet(sessionItem.ConcreteType === itemInCart.ConcreteType, sessionItem.Id === itemInCart.Id))) {
          $scope.itemsInCart.remove(itemInCart);
        }
      });

      /**
       * If register currently contains of items,
       * Update current session to include the existing items.
       */
      const persistSession = !fromSocket && isNonEmptyArray($scope.itemsInCart);

      if (isNonEmptyArray(items)) {
        items.map((item) => addItemToCart(item));
      }

      if (totals) {
        $scope.totalAggregator.calculatedSubTotal = totals.subTotal;
        $scope.calculatedSubTotalChanged(totals.calculatedAfterDiscount);
      }

      specialItemsPlugin = new cashRegisterPlugins.specialItemsPlugin($scope, removeItemFromCart);

      if (externalVoucherIds) {
        let externalVouchersInCart = [];
        if (isNonEmptyArray(items)) {
          externalVouchersInCart = items.filter((item) => {
            return item.itemCustomType == "ExternalGiftCardUsage"
          }).map((item) => item.ExternalVoucherMetadataId);
        }
        const notIncartExternalVoucherId = externalVoucherIds.filter(value => !externalVouchersInCart.includes(value))[0];
        if (notIncartExternalVoucherId) {
          $timeout(() => {
            externalVoucherPlugin.voucherId = notIncartExternalVoucherId;
            externalVoucherPlugin.delegate();
          }, 500);
        }

      }

      if (persistSession) {
        $timeout(saveToSession);
      }
    }

    function clearCurrentCustomer() {
      $scope.customerInvoice = null;
      stateManager.setState('CashRegister', {
        meetingId: undefined,
        relativeDate: undefined,
        sessionId: undefined,
        customerId: undefined,
        priceQuoteId: undefined,
        defaultEmployeeId: undefined,
        debt: undefined
      }, false, false);

    }

    // END OF Session handling.

    function ensureZNoOverlapping() {
      $timeout(function () {
        Repository.Custom("CashRegister").isZOverlapping().then(function (res) {
          $timeout(function () {
            $scope.wasIfOverlappingZLoaded = true;
          });

          if (res.results[0] === true) {
            $timeout(function () {
              $scope.isOverlappingZ = true;
            }, 500);
          }
        });
      }, 1000);
    }

    function openCheckoutDialog(debt, forceLostPayments) {
      if ((invoiceMustHaveCustomer && !$scope.customerInvoice)) {
        toastr.warning(localize.getLocalizedString('_InvoiceMustHaveCustomer_'));
        return openCustomerPickerDialog();
      }

      ensureZNoOverlapping();
      $rootScope.$emit('refresh-session');

      $timeout(function () {
        if (warnForNonCustomer && !$scope.customerInvoice) {
          toastr.warning(localize.getLocalizedString("_CustomerWasNotChosen_"));
        }
      }, 1000);

      let invoice = debt || invoiceFacade.generateNewInvoiceData($scope.itemsInCart, $scope.totalAggregator,
        $scope.customerInvoice, $scope.priceQuoteMetadataFromSession, $scope.sessionId);

      var opts = {
        backdrop: 'static',
        keyboard: false,
        backdropClick: false,
        template: require('../../modal-views/cash-register-checkout/cash.register.checkout.tpl.html'),
        controller: "CashRegisterCheckoutModalController",
        resolve: {
          parameters: function () {
            return {
              invoice: invoice,
              forceLostPayments: forceLostPayments,
              customCustomerCreditToken: $scope.customCustomerCreditToken
            };
          }, saveAction: function () {
            return function () {
            };
          }
        },
        closeFn: function () {
          console.log("closing");
        }
      };

      Repository.Custom("CashRegister").validateInvoice(invoice).then(function (result) {
        openModalIfValid(result, opts);
      }).catch(function (error) {
        toastr.error(error.data);
      });
    }

    function openCustomerPickerDialog() {
      const deferred = $q.defer();
      const opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/customers-picker/customers.picker.modal.tpl.html'),
        controller: "PickACustomerModalController",
        resolve: {
          saveAction: function () {
            return function (newCustomer, dismissDelegate) {

              if ($scope.priceQuoteMode) {
                $scope.customerInvoice = newCustomer;
                deferred.resolve(newCustomer);
              } else {
                if (angular.isDefined(newCustomer.CustomerId)) {
                  $timeout(() => {
                    stateManager.setState('CashRegister', {
                      meetingId: undefined,
                      relativeDate: Moment().format(ODATA_DATE_ONLY_FORMAT),
                      sessionId: undefined,
                      customerId: newCustomer.CustomerId,
                      priceQuoteId: undefined,
                      defaultEmployeeId: stateManager.getValidRawParams().defaultEmployeeId
                    }, false, false);
                  });

                  /// If currently there is a customer related to this session, clear all items.
                  if (getSafely(['customerInvoice', 'CustomerId'], $scope)) {
                    $scope.reset();
                  }

                  deferred.resolve(newCustomer);
                } else {
                  clearCurrentCustomer();
                  deferred.resolve(null);
                }
              }

              dismissDelegate();


            };
          }
        },
        closeFn: function () {
        }
      };

      $modal.open(opts);
      return deferred.promise;
    }

    function openCreatePunchCardDialog(item) {
      var opts = {
        backdrop: 'static',
        keyboard: false,
        backdropClick: false,
        template: require('../../modal-views/create-punchcard/create.punchcard.tpl.html'),
        controller: "CreatePunchcardController",
        resolve: {
          parameters: function () {
            return { item: item, customer: $scope.customerInvoice };
          }, saveAction: function () {
            return function (punchCard) {
              punchCard.RetailPrice = punchCard.OriginalRetailPrice = punchCard.finalPrice;
              delete punchCard.finalPrice;
              addPunchcardForItem(item, punchCard);

              if (punchCard.usePunchCard) {
                $timeout(function () {
                  addItemToCart(item);
                });
              }
            };
          }
        }
      };

      $modal.open(opts);
    }

    function addPunchcardForItem(item, punchCard) {
      delete item.ignorePunchCard;
      punchCard.Item = item;
      punchCard.Quantity = 1;
      punchCard.itemCustomType = 'PunchCard';
      punchCard.Id = -cashRegisterPlugins.randomUniqueId();
      addItemToCart(punchCard);
      removeItemFromCart(item);
    }

    function openCustomItemDialogItem(type, valueGetter, header, saveAction, authorizationName, titles) {
      var opts = {
        backdrop: 'static',
        backdropClick: false,
        template: require('./custom-item-dialog/custom.item.dialog.html'),
        controller: "CashRegisterCustomItemDialogController",
        authorizationName: authorizationName,
        resolve: {
          type: function () {
            return type;
          },
          value: function () {
            return valueGetter();
          },
          header: function () {
            return header;
          },
          saveAction: function () {
            return saveAction;
          },
          titles: function () {
            return titles;
          }
        },
        closeFn: function () {
          console.log("closing");
        }
      };

      $modal.open(opts);
    }

    function findAndHandleMissingPayments() {
      if ($scope.isUsingCredit) {
        Repository.Custom("CashRegister").lostCreditPayments()
          .then(function (results) {
            if (results && results.length > 0) {
              confirmService.confirm(localize.getLocalizedString('_LostPaymentsDialogTitle_'),
                null,
                function () {
                  openCheckoutDialog(null, true);
                },
                function () {

                }
              );
            }
          });
      }
    }

    function itemsInCartChanged(newVal, oldVal) {
      if (!isNonEmptyArray(newVal) && !($scope.customerInvoice && $scope.customerInvoice.Discount)) {
        for (let totalType in $scope.totalAggregator) {
          $scope.totalAggregator[totalType] = 0;
        }

        handlePunchcardIfRemoved(newVal, oldVal);
        if (!delayedSaveToSession.isDisabled()) {
          intermediateManager.clearSession($scope.sessionId);
          delete $scope.sessionId;
          delete $scope.sessionVersion;
          delete $scope.paymentLinkUrl;
        }

        return;
      }

      var actualTotal = 0;
      $scope.itemsInCart.forEach(function (x) {
        actualTotal += x.itemCustomType === 'PunchCardUsage' ? 0 : x.Quantity * parseFloat(x.RetailPrice);
      });

      $scope.totalAggregator.calculatedSubTotal = $scope.totalAggregator.calculatedAfterDiscount =
        actualTotal;
      // Include discounts.
      if ($scope.totalAggregator.pinDiscount) {
        if ($scope.totalAggregator.pinDiscount == "Percentage") {
          $scope.totalAggregator.calculatedAfterDiscount = (actualTotal * (1 - $scope.totalAggregator.discountPercentage / 100.0));
        } else if ($scope.totalAggregator.pinDiscount == "Price") {
          $scope.totalAggregator.calculatedAfterDiscount = actualTotal - $scope.totalAggregator.discountAsPrice;
        }
      }

      calculateTotals();
      handlePunchcardIfRemoved(newVal, oldVal);
    }

    function handlePunchcardIfRemoved(newItems, oldItems) {
      if ($scope.isUsingPunchCard && newItems.length < oldItems.length /*Item(s) removed*/ &&
        ($scope.customerInvoice && $scope.customerInvoice.subscriptionsMap)) {
        var removedItems = oldItems.filter(function (oldItem) {
          // Does it exits on the new items? note: temporary subscriptions will not be filtered because its structure
          // is different
          return newItems.indexOfByIds(oldItem, ['Id', 'Quantity', 'ConcreteType']) < 0;
        });


        removedItems.forEach(function (item) {
          if (isPunchCardItemUsageInCart(item)) {
            var subscription = getItemSubscription(item);
            if (subscription) {
              subscription.QuantityBalance -= item.Quantity;
            }
          } else if (isTemporarySubscriptionItemInCart(item)) {
            if (newItems.indexOfById(item) < 0) { //subscription creation was cancelled
              removeUsagesOfCancelledSubscription(newItems, item.Id);
              deleteItemTemporarySubscription(item.Item);
            }
          }
        });
      }
    }

    function calculateTotals() {
      if (!angular.isDefined($scope.totalAggregator.calculatedAfterDiscount)) {
        $scope.totalAggregator.calculatedAfterDiscount = $scope.totalAggregator.calculatedSubTotal;
        if (!($scope.customerInvoice && $scope.customerInvoice.Discount)) {
          $scope.totalAggregator.discountPercentage = 0;
        }
      }

      if (pricesAreIncludeTax) {
        $scope.totalAggregator.subTotal = $scope.totalAggregator.calculatedAfterDiscount;
        $scope.totalAggregator.totalWithoutTax = ($scope.totalAggregator.subTotal * (1 - VAT)).toFixed(2);
        $scope.totalAggregator.tax = ($scope.totalAggregator.subTotal * VAT).toFixed(2);

      } else {
        $scope.totalAggregator.totalWithoutTax = $scope.totalAggregator.calculatedAfterDiscount;
        $scope.totalAggregator.tax = ($scope.totalAggregator.calculatedSubTotal * VAT).toFixed(2);
        $scope.totalAggregator.subTotal = ($scope.totalAggregator.calculatedSubTotal * (1 + VAT)).toFixed(2);
      }

      // deal with rounding.
      if (roundTo) {
        var roundedSubTotal = Math.round($scope.totalAggregator.subTotal * roundTo) / roundTo;
        var roundedValue = roundedSubTotal - $scope.totalAggregator.subTotal;
        $scope.totalAggregator.rounding = roundedValue != 0 ? roundedValue.toFixed(2) : null;
        $scope.totalAggregator.subTotal = roundedSubTotal;
      }

      $scope.totalAggregator.maxTotal = Math.max($scope.totalAggregator.subTotal, $scope.totalAggregator.calculatedSubTotal);
      saveToSession();
    }

    function calculateDiscountLevel() {
      return ((1 - ($scope.totalAggregator.calculatedAfterDiscount / $scope.totalAggregator.calculatedSubTotal)) * 100).toFixed(2);
    }

    function setWatchItems() {
      $scope.$watch('itemsInCart', saveToSession, true);
    }

    function saveToSession() {
      delayedSaveToSession.set(Math.random());
    }

    function _saveToSession(force = false) {
      const itemsToSaveInSession = [];
      $scope.itemsInCart.forEach(function (item) {
        if (item.Quantity > 0) {
          const cleanedItem = { ...item, $$hashKey: undefined };
          itemsToSaveInSession.push(cleanedItem);
        }
      });

      const fullName = (getSafely(['FirstName'], $scope.customerInvoice, '') + ' ' + getSafely(['LastName'], $scope.customerInvoice, '')).trim();

      let defaultParams;
      if (isNonEmptyObject($scope.customerInvoice)) {
        defaultParams = {
          customerId: $scope.customerInvoice.CustomerId,
          relativeDate: Moment().format(ODATA_DATE_ONLY_FORMAT),
        }
      }

      return intermediateManager.updateSession({
        items: itemsToSaveInSession,
        totals: $scope.totalAggregator,
        customerName: isEmptyString(fullName) ? undefined : fullName
      }, $scope.sessionId, (sessionId, paymentLinkUrl) => {
        setIfDifferent($scope, 'sessionId', sessionId);
        if (paymentLinkUrl) {
          $scope.paymentLinkUrl = paymentLinkUrl + `${paymentLinkUrl.includes('?') ? '&' : '?'}
          date =${new Date().getTime()}`;
        } else {
          delete $scope.paymentLinkUrl;
        }

        if (sessionId) {
          $scope.continueToCheckoutWrapper.ref?.blinkBadge();
        }

      }, force === true, defaultParams);
    }

    function openIngredientsDialog(productOrService) {
      var opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/service-ingredients/service-ingredients.tpl.html'),
        controller: 'ServiceIngredientsModalController',
        resolve: {
          saveAction: function () {
            return function (newEntity) {
              console.log("saving entities!");
            };
          },
          productOrService: function () {
            return productOrService;
          },
          customer: function () {
            return $scope.customerInvoice;
          }
        },
        closeFn: function () {
          console.log("closing");
        }
      };

      $modal.open(opts);
    }

    function handleDebt() {
      openDebtsAndTaxInvoicesDialog($scope.customerInvoice);
    }

    function addDebtOrTaxInvoiceItems(amount, type, removeOthers = false, metadata = {}) {
      const debtItem = {
        Id: -cashRegisterPlugins.randomUniqueId(),
        itemCustomType: type,
        Label: type === 'Debt' ? localize.getLocalizedString('_DebtPayment_') : (localize.getLocalizedString('_TaxInvoicePayment_') + ` - ${metadata.TaxInvoiceId}`),
        RetailPrice: amount,
        ...metadata
      };

      debtItem.OriginalRetailPrice = debtItem.RetailPrice;
      debtItem.preventRegisteringSubscription = true;

      if (removeOthers) {
        $scope.itemsInCart.filter(function (item) {
          return ['Debt', 'TaxInvoice'].includes(item.itemCustomType);
        }).forEach(removeItemFromCart)
      }

      addItemToCart(debtItem, true);
    }

    function isPrePaymentAllowed() {
      if (!$scope.customerInvoice || $scope.totalAggregator.calculatedAfterDiscount < 1) {
        return false;
      }

      return $scope.itemsInCart.some(function (item) {
        return ['Debt', 'TaxInvoice'].includes(item.itemCustomType);
      });
    }

    function openDebtsAndTaxInvoicesDialog(customer) {
      globalActions.debtAndTaxInvoices(customer, (entities) => {
        entities.forEach((entity, idx) => {
          addDebtOrTaxInvoiceItems(entity.amountToPay,
            entity.type,
            idx === 0,
            entity.TaxInvoiceId ? { TaxInvoiceId: entity.TaxInvoiceId } : {});
        });
      });

    }

    function calculateComposedRelativePrice(parent, me) {
      var sum = 0;
      for (var i = 0, len = parent.ComposedItems.length; i < len; i++) {
        sum += parent.ComposedItems[i].RetailPrice;
      }

      me.calculatedRelativePart = (me.RetailPrice / sum);

      me.calPrice = Math.round(me.calculatedRelativePart * parseFloat(parent.RetailPrice));
      var calculatedLeftOver = 0;
      // Is last item?
      if (parent.ComposedItems.indexOf(me) == parent.ComposedItems.length - 1) {
        calculatedLeftOver = parseFloat(parent.RetailPrice);
        for (var i = 0, len = parent.ComposedItems.length; i < len; i++) {
          calculatedLeftOver -= parent.ComposedItems[i].calPrice;
        }
      }

      return me.calPrice + calculatedLeftOver;
    }

    function addPrePaymentUsage(amount, removeOthers = false, metadata = {}) {
      const prePaymentItem = {
        Id: -cashRegisterPlugins.randomUniqueId(),
        itemCustomType: 'PrePaymentUsage',
        Label: localize.getLocalizedString('_UsePrePayment_'),
        RetailPrice: -amount,
        ...metadata
      };

      prePaymentItem.OriginalRetailPrice = prePaymentItem.RetailPrice;
      prePaymentItem.preventRegisteringSubscription = true;

      if (removeOthers) {
        const existingDebtItems = $scope.itemsInCart.filter(x => x.itemCustomType === 'PrePaymentUsage');
        existingDebtItems.forEach(removeItemFromCart);
      }

      addItemToCart(prePaymentItem);
    }
  });
