(function () {
  angular.module('easybizy.common.common-services').service('cashRegisterDailyActions',
    function (Repository, printerMediator, configuration) {
      this.xReportData = function (employeeId) {
        return Repository.Custom("CashRegister").xReportData(employeeId)
          .then(function (data) {
            return data;
          })
          .catch(function (err) {
            toastr.error(localize.getLocalizedString('_ErrorGettingDailyTotal_'));
            return err;
          });
      };

      this.zReport = function () {
        return Repository.Custom("CashRegister").zReport()
          .then(function (data) {
            printerMediator.print(data.results[0].Receipt);
            return data;
          })
          .catch(function () {
            return localize.getLocalizedString('_ErrorPrinterDailyReports_');
          })

      }

    })

}());
