import {DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT} from "../../constants";

var customersModule = angular.module('easybizy.customers', [
  'easybizy.common.common.resources',
  'easybizy.common.toolbar.actions']);

customersModule.controller('CustomersController', function ($scope, Repository, configuration, toolbar,
                                                            localize, globalActions, webSocketsBus, $state, $q, $modal) {
  $scope.clearSearchFuncWrapper = {};
  const confirmMessage = localize.getLocalizedString('_ApproveGeneralConfirm_');

  var toolbarActions = [

    {
      name: 'add',
      action: function (customer) {
        return createNewCustomer(customer);
      },
      data: {
        template: require('../../modal-views/add-customer/add-customer.tpl.html'),
        controller: 'AddCustomerModalController'
      }
    },
    {
      name: 'search', action: function (newValue) {
        $scope.valueWrapper.value = newValue;/**/
      },
      data: {
        delay: 500,
        clearWrapper: $scope.clearSearchFuncWrapper,
        setSearchWrapper: $scope.clearSearchFuncWrapper
        // This way if $scope.clearSearch() is called the toolbar clears the text field.
      }
    },
    {
      name: 'custom',
      icon: 'icon icon-table',
      tooltip: localize.getLocalizedString('_CustomersTable_'),
      action: function () {
        globalActions.showCustomersTable()
      },
    },
    {
      name: 'custom',
      icon: 'icon icon-entity-settings',
      action: function () {
        const opts = {
          backdrop: 'static',
          keyboard: false,
          template: require('../../modal-views/custom-entity-settings/custom.entity.config.modal.tpl.html'),
          controller: 'CustomEntityConfigurationsController',
          resolve: {
            views: () => {
              return [
                {
                  loadItemsDelegate: () => Repository.Custom('CategoriesEditorRepository').get("CustomerTags"),
                  name: localize.getLocalizedString('_Tags_'),
                  renameDelegate: (item, newName) => {
                    return Repository.Custom('CategoriesEditorRepository').rename("CustomerTags", item.value, newName);
                  },
                  actions: [
                    {
                      name: localize.getLocalizedString('_Delete_'),
                      type: 'warning-submit',
                      confirmMessage: confirmMessage,
                      delegate: (items) => {
                        return Repository.Custom('CategoriesEditorRepository').remove("CustomerTags", items);
                      }
                    },
                    {
                      name: localize.getLocalizedString('_Unite_'),
                      confirmMessage: confirmMessage,
                      minSelectionLength: 2,
                      delegate: (items) => {
                        return Repository.Custom('CategoriesEditorRepository').merge("CustomerTags", items);
                      }
                    },
                  ]
                },
                {
                  loadItemsDelegate: () => Repository.Custom('CategoriesEditorRepository').get("ArrivalSources"),
                  name: localize.getLocalizedString('_ArrivalSource_'),
                  renameDelegate: (item, newName) => {
                    return Repository.Custom('CategoriesEditorRepository').rename("ArrivalSources", item.value, newName);
                  },
                  actions: [
                    {
                      name: localize.getLocalizedString('_Delete_'),
                      type: 'warning-submit',
                      confirmMessage: confirmMessage,
                      delegate: (items) => {
                        return Repository.Custom('CategoriesEditorRepository').remove("ArrivalSources", items);
                      }
                    },
                    {
                      name: localize.getLocalizedString('_Unite_'),
                      confirmMessage: confirmMessage,
                      minSelectionLength: 2,
                      delegate: (items) => {
                        return Repository.Custom('CategoriesEditorRepository').merge("ArrivalSources", items);
                      }
                    },
                  ]
                },
                {
                  loadItemsDelegate: (search) => Repository.Custom('CategoriesEditorRepository').get("Customers", search),
                  name: localize.getLocalizedString('_Restore_'),
                  showSearch: true,
                  actions: [
                    {
                      name: localize.getLocalizedString('_Restore_'),
                      type: 'warning-submit',
                      confirmMessage: confirmMessage,
                      delegate: (items) => {
                        return Repository.Custom('CategoriesEditorRepository').restore("Customers", items);
                      }
                    }
                  ]
                }
              ];
            }
          },
        };

        $modal.open(opts);
      },
      tooltip: localize.getLocalizedString('_EntityConfig_')
    }
  ];

  $scope.tileDoubleClicked = function (customer) {
    $state.go('CustomerDetails', {customerId: customer.CustomerId});
  };

  var configurations = configuration.get();
  var useCustomerGroups = configurations.CustomersSettings && configurations.CustomersSettings.UseCustomerGroups;
  if (useCustomerGroups) {
    var addGroupToolbarItem = {
      name: 'addGroup',
      action: function (customer, callback) {
        return createCustomerGroup(customer);
      },
      data: {
        template: require('../../modal-views/add-customers-group/add.customer.group.modal.tpl.html'),
        controller: 'AddCustomersGroupController'
      }
    };

    toolbarActions.splice(0, 0, addGroupToolbarItem);
  }

  toolbar.set(toolbarActions);

  webSocketsBus.registerView($scope);
  $scope.addNewEntity = function (newCustomer) {
    $scope.customers.unshift(newCustomer);
  };

  $scope.customers = [];
  $scope.valueWrapper = {};
  $scope.filtersModel = {};
  $scope.filtersModel.filters = [
    {type: "alphabetically", params: {fields: "FirstName, LastName"}, allowMultiple: false},
    {type: "gender", allowMultiple: false},
    {
      type: "buckets", allowMultiple: false, params: {
        options: [
          {name: localize.getLocalizedString('_HasDebt_'), value: 'Debt gt 0', slug: 'has-debt'},
          {name: localize.getLocalizedString('_HasPositiveBalance_'), value: 'Debt lt 0', slug: 'has-prepayment'},
          {
            name: localize.getLocalizedString('_WebJoined_'),
            value: '((DateOfFillingOutDetailsForm ne null) or (FacebookUserId ne null))',
            slug: 'web-joined'
          },
          {
            name: localize.getLocalizedString('_Unsubscribed_'),
            value: '((EnablePromotionSending eq false) or (EnableMailSending eq false) or (EnableSmsSending eq false))',
            slug: 'unsubscribed'
          },
        ]
      }
    },
    {type: 'tag', allowMultiple: true, params: {entity: 'Customer'}},
    {
      type: 'search',
      params: {
        fields: 'FirstName, LastName, WebSite, MembershipId',
        numbersOnlyFields: 'MobileFirst, MobileSecond',
        type: 'Customer'
      },
      valueWrapper: $scope.valueWrapper,
      clearWrapper: $scope.clearSearchFuncWrapper
    }
  ];

  var lastSelectedTags = null;
  $scope.filtersModel.filterStateChanged = function (newFiltersState, newFiltersSlug) {
    $scope.api.filters.length = 0;
    angular.forEach(newFiltersState, function (filterValue, filterName) {
      if (filterName === 'tag') {
        if (lastSelectedTags || filterValue) {
          lastSelectedTags = filterValue;
          $scope.api.tags(filterValue);
        }
      } else if (filterValue) {
        $scope.api.filters.push(filterValue);
        if (filterName === 'search') {
          $scope.clearSearchFuncWrapper.setSearch(newFiltersSlug['search']);
        }

      }
    });

    $scope.api.forceReload();
  };

  $scope.tileActionsResolver = {
    deleteEntity: function (entity) {
      var deferred = $q.defer();
      Repository.Entity(entity.EntityType).remove(entity)
        .then(function () {
          $scope.customers.remove(entity);
          toastr.success(localize.getLocalizedString("_EntityRemovedSuccessfully_"));
          deferred.resolve(entity);
        })
        .catch(function () {
          toastr.error(localize.getLocalizedString("_ErrorRemovingEntity_"));
          deferred.reject(data);
        });

      return deferred.promise;
    },
    editGroup: function (entity) {
      $scope.$emit('fire-toolbar-command', {
        name: "addGroup", params: {
          group: entity
        }
      });
    }
  };

  $scope.noItemsFound = false;
  $scope.api = Repository.Entity("Customer").query().select('CustomerId,FirstName,LastName,MobileFirst,FacebookUserId,EmailAddress,DefaultImagePath,Gender,Debt,CustomerType');
  $scope.api.preventAutoLoad = true;
  $scope.api.filters = [];
  $scope.api.doneLoadingCallback = function (customers) {
    $scope.noItemsFound = customers.length == 0;
  };

  $scope.isLoadingWrapper = {};

  function createNewCustomer(customer) {
    customer.DateOfBirth = customer.DateOfBirth ? moment(customer.DateOfBirth, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) : null;
    return Repository.Entity("Customer").create(customer).post()
      .then(function (newCustomer) {
        newCustomer.EntityType = "Customer";
        $scope.customers.unshift(newCustomer);
        return newCustomer;
      });
  }

  function createCustomerGroup(group) {
    var deferred = $q.defer();
    Repository.Custom("CustomersGroup").create(group)
      .then(function (res) {
        if (group.GroupId) {
          group.CustomerId = group.GroupId;
          $scope.customers.removeById(group, "CustomerId");
        }
        $scope.customers.unshift(res);

        deferred.resolve(res);
      })
      .catch(function (err) {
        toastr.error(localize.getLocalizedString("_ErrorSavingGroup_", err['odata.error'].message.value));
        deferred.reject(err);
      });

    return deferred.promise;
  }
});

