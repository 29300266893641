import './online.scheduling.availability.directive.less'

(function () {
  angular.module('easybizy.online.scheduling').directive('onlineSchedulingAvailability', [
    '$timeout',
    'localize', 'timeConverter', 'configuration', '$modal', 'onlineSchedulingAvailabilityStore', function ($timeout, localize,
                                                                                                           timeConverter, configuration, $modal, onlineSchedulingAvailabilityStore) {
      var priorities = [
        { name: localize.getLocalizedString('_Low_'), class: 'low-priority' },
        { name: localize.getLocalizedString('_Medium_'), class: 'default-priority' },
        { name: localize.getLocalizedString('_High_'), class: 'high-priority' }
      ];

      return {
        restrict: 'E',
        replace: true,
        scope: {},
        link: function (scope) {
          scope.priorities = priorities;
          scope.dayDateFormat = 'dddd, DD MMM';
          var settings = configuration.get();
          var firstDayOfWork = -1, lastDayOfWork;

          settings.CalendarSettings.DaysOfWork.forEach(function (dayOfWork) {
            if (dayOfWork.IsEnabled) {
              if (firstDayOfWork === -1) {
                firstDayOfWork = dayOfWork.Index;
              }

              lastDayOfWork = dayOfWork.Index;
            }
          });


          var currentWeek = updateCurrentWeek(moment());
          Object.defineProperty(scope, 'currentWeek', {
            get: function () {
              return currentWeek;
            }
          });

          Object.defineProperty(scope, 'currentDate', {
            get: function () {
              return onlineSchedulingAvailabilityStore.visibleDate;
            }
          });

          scope.employees = [{ name: localize.getLocalizedString('_Scheduling_'), value: -1 }];

          scope.dateChanged = function (newDate) {
            onlineSchedulingAvailabilityStore.visibleDate = newDate.clone();
            currentWeek = updateCurrentWeek(newDate);
          };

          scope.addShift = addShift;

          scope.toDateKey = onlineSchedulingAvailabilityStore.toDateKey;

          // This actually making sure the dates are ready.
          scope.initDate = function (date) {
            onlineSchedulingAvailabilityStore.availabilityByDateAsync(date).then(function () {
              // nothing... It's all good!
            });
          };

          Object.defineProperty(scope, 'availabilityByDate', {
            get: function () {
              return onlineSchedulingAvailabilityStore.availabilityByDate;
            }
          });


          function updateCurrentWeek(relativeDate) {
            return generateWeekDates(relativeDate.clone().weekday(firstDayOfWork), relativeDate.clone().weekday(lastDayOfWork))
          }

          function generateWeekDates(iStartDate, endDate) {
            var toReturn = [], startDate = iStartDate.clone();
            while (!startDate.isAfter(endDate)) {
              toReturn.push(startDate.clone());
              startDate = startDate.add(1, 'd');
            }

            return toReturn;
          }


          function addShift(date, employee) {
            var opts = {
              backdrop: 'static',
              keyboard: false,
              backdropClick: false,
              template: require('../../../modal-views/add-shifts/add.shifts.tpl.modal.html'),
              controller: "AddShiftsController",
              resolve: {
                parameters: function () {
                  return {
                    date: date,
                    employee: employee
                  };
                }, saveAction: function () {
                  return function (newEntity) {
                    //Save
                  };
                }
              },
              closeFn: function () {
                console.log("closing");
              }
            };

            $modal.open(opts);
          }
        },
        template: require('./online.scheduling.availability.directive.html')

      }
    }
  ]);
}());
