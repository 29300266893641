import './users.settings.actions.directive.less'

angular.module('easybizy.common.common-controls')
  .directive('userSettingsActions',
    function () {
      return {
        restrict: 'E',
        scope: {
          show: '=',
          actions: '='
        },
        replace: true,
        template: require('./users.settings.actions.directive.tpl.html'),
      };
    });

