import './smart.date.picker.directive.less'

(function () {
  'use strict';

  angular.module('easybizy.common.common-controls').directive('smartDatePicker', [
    'localize', function (localize) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          currentDate: '=',
          mode: '=',
          daysOfWork: '=',
          disabled: '=',
          dateChangedDelegate: '=dateChanged'
        },
        link: function (scope) {
          scope.visiblePicker = false;
          const isDayOfWorkAvailable = (dayOfWeek) => {
            const weeklyMode = scope.mode === 'weekly';
            return weeklyMode || (scope.daysOfWork || []).length !== 7 || scope.daysOfWork[dayOfWeek].IsEnabled;
          };

          scope.$watch('[currentDate, mode]', function (newVal, prevVal) {
            const [newDate, newMode] = newVal;
            const [prevDate, prevMode] = prevVal;
            if (scope.visibleDate && newDate.isSameDate(prevDate) && newMode === prevMode) {
              return;
            }

            scope.visibleDate = generateCalendarVisibleRange();
          });


          Object.defineProperty(scope, 'isCurrentRangeIncludeToday', {
            get() {
              return moment().isSameDate(scope.currentDate) || (scope.mode === 'weekly' && moment().isSame(scope.currentDate, 'week'));
            }
          })

          var dayString = localize.getLocalizedString('_Day_');

          scope.next = function (iPrev) {
            let addition = (localize.isRightToLeft() ? 1 : -1) * (angular.isDefined(iPrev) ? iPrev : 1);
            let newDate = scope.currentDate.clone();
            const weeklyMode = scope.mode === 'weekly';

            do {
              newDate = newDate.add(addition, (weeklyMode ? 'week' : 'day'));
            } while (!isDayOfWorkAvailable(newDate.day()));

            scope.dateChanged(newDate);
          };


          scope.nextLarge = function (iPrev) {
            let addition = (localize.isRightToLeft() ? 1 : -1) * (angular.isDefined(iPrev) ? iPrev : 1);
            scope.dateChanged(scope.currentDate.clone().add(addition, (scope.mode === 'weekly' ? 'month' : 'week')));
          };

          scope.today = function () {
            if (scope.currentDate.isSameDate(moment())) {
              return;
            }

            let today = moment();
            scope.dateChanged(today);
            if (!isDayOfWorkAvailable(today.day())) {
              scope.next();
            }

          };

          scope.dateChanged = function (newDate) {
            scope.dateChangedDelegate(newDate);
            scope.togglePicker(false);
          };

          scope.prev = scope.next.bind(scope, -1);
          scope.prevLarge = scope.nextLarge.bind(scope, -1);

          scope.togglePicker = function (value) {
            scope.visiblePicker = angular.isDefined(value) ? value : !scope.visiblePicker;
          };

          function generateCalendarVisibleRange() {
            var toReturn;
            if (scope.mode === 'weekly') {
              const { from, to } = getRange();

              // same month
              if (from.month() === to.month()) {
                toReturn = from.format("DD");
              } else if (from.year() === to.year()) {
                toReturn = from.format("DD MMM");
              } else {
                toReturn = from.format("DD MMM");
              }


              toReturn += " - " + to.format("DD MMM");
            } else {
              toReturn = dayString + scope.currentDate.format(' dddd, DD MMM');
            }

            return toReturn;
          }

          function getRange() {
            if (scope.mode === 'weekly') {
              // WEEKLY MODE
              const from = scope.currentDate.clone().weekday(0);
              const to = from.clone().add(6, 'd');
              return { from, to }
            } else {
              return scope.currentDate.clone();
            }
          }

        },
        template: require('./smart.date.picker.directive.html')
      }
    }]);
}());
