<template>
  <div>
    <div class="flex items-center mb-4 justify-between">
      <div class="flex items-center justify-start">
        <button class="flex items-center justify-start" @click="mediator.exportToExcel()">
          <div class="icon icon-excel me-2"></div><div>{{ $t('_Export_')}}</div>
        </button>
      </div>
    </div>
    <Grid :dataSource="dataSource" v-bind="gridProps" :height="'70vh'"></Grid>
  </div>
</template>

<script>
import { Grid, GridLabels } from "@tomeravni/easybizy-vue-components";
import MeetingHistoryDS from "./meeting.history.ds";
import Moment from 'moment';
import {resolveDefaultGridLabels} from "../../vue/vue.helpers";

export default {
  components: {
    Grid,
  },
  props: {
    mediator: {
      type: Object
    }
  },
  data() {
    const labels = resolveDefaultGridLabels(this.$t);

    const api = this.Repository.Custom("QueryableTable").meetingHistory(this.mediator.meetingId)
    api.pageSize = 30;

    const dataSource = new MeetingHistoryDS(api, this.$t, this.$stateManager, this.mediator,this.Repository,this.printerMediator);
    dataSource.filterValues = {
      ActionTime: this.mediator.meetingId ? [] : [Moment().startOf('week').toDate(), new Date()]
    }

    return {
      dataSource,
      gridProps: {
        freezeHeader: true,
        hideBorder: false,
        elevated: true,
        pickColumns: true,
        rowHeightMode: 'tight',
        backgroundColor: 'auto',
        preventScrollTopButton: false,
        enableFilter: !this.mediator.meetingId,
        isFilterVisible: true,
        forceFullHeight: true,
        labels
      }
    }
  },
  mounted() {

  },
  methods: {
  },

}
</script>
