import './google.sync.wizard.less'
import { Publisher } from "../../general/helpers";

angular.module('easybizy.easybizyModalViewsModel').controller('GoogleCalendarSyncWizard',
  function ($scope, $modalInstance, saveAction, deleteAction, localize, existingCalendars,
            alreadySyncedCalendar, $timeout, employee) {

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.designatedCalendarName = employee ? (employee.FirstName + ' ' + employee.LastName) : 'easybizy';
    $scope.calendarsOptions = existingCalendars.map(googleCalendarObjToTileObj);

    var existingCalendarNamedEasybizy = existingCalendars.filter(function (calendarItem) {
      return calendarItem.name === 'easybizy' || calendarItem.name === $scope.designatedCalendarName;
    });
    existingCalendarNamedEasybizy = existingCalendarNamedEasybizy.length > 0 ? existingCalendarNamedEasybizy[0] : null;

    var newCalendarItem = {
      Icon: 'icon icon-add',
      EntityType: 'GoogleCalendar',
      BackgroundColor: '#badce8',
      id: null,
      Label: localize.getLocalizedString('_CreateNew_')
    };

    $scope.selectedCalendarWrapper = {};
    $scope.modeWrapper = {};

    if (!alreadySyncedCalendar) {
      $scope.modeWrapper.mode = 'pickCalendar';
      if (!existingCalendarNamedEasybizy) {
        $scope.calendarsOptions.unshift(newCalendarItem);
      }

    } else {
      $scope.modeWrapper.mode = 'alreadyExists';
      $scope.selectedWhenAlreadySynced = {};
      $scope.alreadySyncedCalendar = alreadySyncedCalendar;

      $scope.alreadyExistsMessage = localize.getLocalizedString('_CalendarAlreadySyncedMessage_', $scope.alreadySyncedCalendar.name);
      // Don't allow already synced when new is requested.
      $scope.calendarsOptions.removeById($scope.alreadySyncedCalendar, 'id');

      $scope.existingCalendarOptions = [
        {
          Icon: 'icon icon-delete-garbage-streamline',
          EntityType: 'GoogleCalendar',
          id: alreadySyncedCalendar.id,
          Label: localize.getLocalizedString('_DeleteExistingSync_')
        },
        {
          Icon: 'icon icon-calendar',
          EntityType: 'GoogleCalendar',
          id: alreadySyncedCalendar.id,
          Label: localize.getLocalizedString('_PerformFullSync_')
        },
        {
          Icon: 'icon icon-flat-close',
          EntityType: 'GoogleCalendar',
          id: alreadySyncedCalendar.id,
          Label: localize.getLocalizedString('_CancelSync_')
        }
      ];

      if (existingCalendarNamedEasybizy) {
        newCalendarItem.id = existingCalendarNamedEasybizy.id;
      }
      $scope.calendarsOptions.unshift(newCalendarItem);

    }

    function googleCalendarObjToTileObj(calendar) {
      return {
        EntityType: 'GoogleCalendar',
        BackgroundColor: calendar.backgroundColor,
        id: calendar.id,
        Label: calendar.name
      }
    }

    $scope.goRight = false;
    $scope.back = function () {
      $scope.goRight = true;
      $timeout(function () {
        $scope.modeWrapper.mode = 'alreadyExists';
      })
    };

    $scope.alreadySyncedSelected = function (newVal) {
      if (newVal) {
        $scope.goRight = false;
        $timeout(function () {
          if ($scope.existingCalendarOptions[0].isSelected) {
            $scope.modeWrapper.mode = 'pickCalendar';
          }
        });
      }
    };

    //$scope.modeWrapper.mode = 'syncing';
    $scope.errors = [];

    $scope.syncStats = [
      {
        label: localize.getLocalizedString('_Total_'),
        synced: 0,
        total: 0
      },
      {
        label: localize.getLocalizedString('_GoogleEvents_'),
        synced: 0,
        errors: 0,
        total: 0
      },
      {
        label: localize.getLocalizedString('_EasybizyMeetings_'),
        synced: 0,
        errors: 0,
        total: 0
      }
    ];


    $scope.sync = function () {
      $scope.modeWrapper.mode = 'syncing';
      var publisher = new Publisher();
      $scope.syncMessages = [];
      publisher.on('sync.started', function () {
        addSyncMessage(localize.getLocalizedString('_SyncStarted_'))
      });

      publisher.on('sync.finished', function () {
        addSyncMessage(localize.getLocalizedString('_SyncFinished_'));
        $timeout(function () {
          $scope.finishedSyncing = true;
          $scope.progress = 100;
        });
      });

      publisher.on('sync.stats', function (metadata) {
        $timeout(function () {
          $scope.statLoaded = true;
          $scope.syncStats[0].total = metadata.stats.total;
          $scope.syncStats[1].total = metadata.stats.googleEvents;
          $scope.syncStats[2].total = metadata.stats.easybizyMeetings;

          if (metadata.stats.total == 0) {
            $scope.progress = 100;
          }

        });
      });

      $scope.progress = 0;
      publisher.on('sync.progress', function (progress) {
        $timeout(function () {
          if (progress.firstRun && !!progress.error) {
            return;
          }

          if (!progress.invoker) {
            return addSyncMessage(progress.message);
          }

          $scope.progress = Math.round($scope.syncStats[0].synced * 1.0 / $scope.syncStats[0].total * 100);

          $scope.syncStats[0].synced++;
          var toUpdate = $scope.syncStats[progress.invoker == 'google-event' ? 1 : 2];
          if (!!progress.error) {
            toUpdate.errors++;
            $scope.errors.push(publisher);
          } else {
            toUpdate.synced++;
          }
        });
      });

      publisher.on('sync.error', function (err) {
        addSyncMessage(localize.getLocalizedString('_SyncError_', err.message));
        $timeout(function () {
          $scope.progress = 100;
          $scope.finishedSyncing = true;
        });
      });

      if ($scope.existingCalendarOptions && $scope.existingCalendarOptions[2].isSelected) {
        return deleteAction({}, publisher);
      }

      saveAction({
        syncExisting: !!($scope.existingCalendarOptions && $scope.existingCalendarOptions[1].isSelected),
        deleteExisting: !!($scope.existingCalendarOptions && $scope.existingCalendarOptions[0].isSelected),
        calendarToSync: getSelectedCalendarToSync() || '',
        newCalendarName: encodeURI($scope.designatedCalendarName)
      }, publisher);

      $timeout(function () {
        addSyncMessage(localize.getLocalizedString('_Initializing_') + '...');
      }, 1000);


      function addSyncMessage(message) {
        $timeout(function () {
          //$scope.syncMessages.unshift(message);
          $scope.lastSyncMessage = message;
        })

      }
    };

    $scope.isReadyWrapper = {};
    Object.defineProperty($scope.isReadyWrapper, 'isReady', {
      get: function () {
        var toReturn = $scope.existingCalendarOptions && ($scope.existingCalendarOptions[1].isSelected || $scope.existingCalendarOptions[2].isSelected);
        // This is perform full sync OR full delete.

        if (!toReturn && $scope.modeWrapper.mode == 'pickCalendar') {
          toReturn = getSelectedCalendarToSync() != false;
        }

        return toReturn;
      }
    });

    function getSelectedCalendarToSync() {
      return $scope.selectedCalendarWrapper.entity ? $scope.selectedCalendarWrapper.entity.id :
        ((alreadySyncedCalendar && $scope.existingCalendarOptions[1].isSelected) ? alreadySyncedCalendar.id : false);
    }

  });
