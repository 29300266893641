import { ODATA_DATE_TIME_FORMAT, ALL_DAY_MEETING_FORMAT } from "../../../constants";

(function () {
  angular.module('easybizy.calendar').factory('calendarIOService', function (Repository, calendarMetadata) {
    return {
      createOrUpdate: saveOrUpdate,
      deleteMeeting: deleteMeeting,
      splitMeeting: splitMeeting,
      updateStatus: updateStatus,
      markAsNotPaid: markAsNotPaid,
      updateColor: updateColor,
      approveMeeting: approveMeeting,
      updateMeetingRemarks: updateMeetingRemarks
    };

    function saveOrUpdate(meeting, oldMeeting, metadata) {
      if (oldMeeting) {
        return updateMeeting(meeting, oldMeeting, metadata);
      }

      var meetingToPost = generateMeetingToServer({}, meeting);
      return Repository.Custom("Calendar").createMeeting(meetingToPost, metadata);
    }

    function updateMeeting(meeting, oldMeeting, metadata) {
      var updatedMeeting = generateMeetingToServer($.extend({}, oldMeeting.originalMeeting), meeting);
      return Repository.Custom("Calendar").updateMeeting(updatedMeeting, metadata);
    }

    function updateStatus(meeting, newStatus) {
      return Repository.Custom("Calendar").updateStatus(meeting.MeetingId, meeting.StartTime.format(ODATA_DATE_TIME_FORMAT), newStatus);
    }

    function markAsNotPaid(meeting) {
      return Repository.Custom("Calendar").markAsNotPaid(meeting.MeetingId, meeting.StartTime.format(ODATA_DATE_TIME_FORMAT));
    }

    function updateColor(meeting, color) {
      return Repository.Custom("Calendar").updateColor(meeting.MeetingId, meeting.StartTime.format(ODATA_DATE_TIME_FORMAT), color);
    }

    function updateMeetingRemarks(meeting, remarks) {
      return Repository.Custom("Calendar").updateRemarks(meeting.MeetingId, meeting.StartTime.format(ODATA_DATE_TIME_FORMAT), remarks);
    }


    function splitMeeting(meetingAndMetadata) {
      return Repository.Custom("Calendar").splitMeeting(meetingAndMetadata);
    }

    function approveMeeting(meetingId) {
      return Repository.Custom('Calendar').approveMeeting(meetingId);
    }

    function generateMeetingToServer(serverMeeting, meeting) {
      if (!meeting.IsAllDay) {
        serverMeeting.StartTime = meeting.from.format(ODATA_DATE_TIME_FORMAT);
        serverMeeting.EndTime = meeting.to.format(ODATA_DATE_TIME_FORMAT);
      } else {
        serverMeeting.StartTime = meeting.from.format(ALL_DAY_MEETING_FORMAT);
        serverMeeting.EndTime = meeting.to.format(ALL_DAY_MEETING_FORMAT);
      }



      // if (isMeetingTimeAllDay(meeting)) {
      //   serverMeeting.StartTime = moment(serverMeeting.StartTime).set({
      //     hour: 0,
      //     minute: 0,
      //     second: 0
      //   }).format(ODATA_DATE_TIME_FORMAT);
      //
      //   serverMeeting.EndTime = moment(serverMeeting.EndTime).set({
      //     hour: 0,
      //     minute: 0,
      //     second: 0
      //   }).format(ODATA_DATE_TIME_FORMAT);
      //
      //   serverMeeting.IsAllDay = true;
      // } else {
      //   serverMeeting.IsAllDay = false;
      // }

      serverMeeting.IsAllDay = meeting.IsAllDay;
      serverMeeting.CalendarEventName = meeting.CalendarEventName;
      serverMeeting.CalendarEventType = "Meeting";
      serverMeeting.Recurrence = meeting.repeatEvent;
      serverMeeting.Reminder = meeting.Reminder;
      serverMeeting.Remarks = meeting.remarks;
      serverMeeting.CustomerId = meeting.customer ? meeting.customer.CustomerId : null;
      serverMeeting.EmployeeId = meeting.EmployeeId;
      serverMeeting.CreatedByEmployeeId = meeting.CreatedByEmployeeId;
      serverMeeting.RoomId = meeting.RoomId;
      serverMeeting.Location = meeting.Location;
      serverMeeting.GenderSelection = meeting.GenderSelection;
      serverMeeting.CompositeAdapters = meeting.CompositeAdapters;
      serverMeeting.ExternalVoucherMetadataId = meeting.ExternalVoucherMetadataId;

      if (meeting.NewCustomer) {
        serverMeeting.NewCustomer = $.extend({}, meeting.NewCustomer, true);
        serverMeeting.NewCustomer.Gender = serverMeeting.NewCustomer.Gender.value;
        delete serverMeeting.CustomerId;
      }

      if (meeting.services.length > 0) {
        serverMeeting.ServiceMetadatas = meeting.services.map(function (service) {
          return { ServiceMetadataId: service.value.ServiceMetadataId };
        }
        );
      } else if (meeting.service.value) {
        serverMeeting.ServiceMetadatas = [{ ServiceMetadataId: meeting.service.value.ServiceMetadataId }];
      } else {
        serverMeeting.ServiceMetadatas = [];
      }

      delete serverMeeting.EntityType;
      delete serverMeeting.Customer;
      return serverMeeting;
    }

    function deleteMeeting(meeting, metadata) {
      var meetingToPost = $.extend({}, meeting.originalMeeting);
      delete meetingToPost.EntityType;
      meetingToPost.StartTime = meeting.StartTime.format(ODATA_DATE_TIME_FORMAT);
      meetingToPost.EndTime = meeting.EndTime.format(ODATA_DATE_TIME_FORMAT);
      return Repository.Custom("Calendar").deleteMeeting(meetingToPost, metadata);
    }

    function isMeetingTimeAllDay(meeting) {
      return meeting.from.hour() === calendarMetadata.startTime.hour() &&
        meeting.from.minute() === calendarMetadata.startTime.minute() &&
        meeting.to.hour() === calendarMetadata.endTime.hour() &&
        meeting.to.minute() === calendarMetadata.endTime.minute()

    }
  });

}());
