(function () {
  angular.module('easybizy.calendar').directive('currentTimeMarker', [
    'calendarMetadata', '$rootScope', 'calendarColumns', 'localize', '$timeout',
    function (calendarMetadata, $rootScope, calendarColumns, localize, $timeout) {

      return {
        restrict: 'E',
        replace: true,
        link: function (scope, element) {
          var intervalId; // Pointer to interval to kill when scope is destroyed.
          var isVisible = false;
          var k_columnRelativeWidth = 100 / calendarMetadata.daysInWeek;
          var weekday = moment().weekday();
          var relativePosition = weekday < 1 ? null : weekday * k_columnRelativeWidth + '%';
          var k_rightOrLeft = localize.isRightToLeft() ? 'right' : 'left';

          var setPosition = function (remove) {
            if (!relativePosition) {
              return;
            }

            element.css(k_rightOrLeft, remove ? '' : relativePosition);
          };

          element.css('display', 'none');
          $timeout(init, 100);
          scope.$on('$destroy', destroy);
          const scrollToNowIndicatorDelegate = $rootScope.$on('scroll-to-now', function () {
            scrollToNowIndicatorDelegate();
            setTimeout(function () {
              const top = calculateTopPosition() - 150;
              $('.calendar-days-content-scroller').animate({ scrollTop: top });
              $('.calendar-content-sticky').animate({ scrollTop: top });
            }, 500);
          });

          calendarColumns.on('columnsChanged', showOrHide);

          function init() {
            setTopPosition();
            setTimeout(function () {
              setTopPosition();
              intervalId = setInterval(setTopPosition, 60000);
            }, (60 - moment().seconds()) * 1000);

          }

          function showOrHide() {
            var toReturn = calendarMetadata.isTodayVisible;
            var currentTimeInRange = toReturn && isCurrentTimeInRange();
            if (!currentTimeInRange && isVisible) {
              element.css('display', 'none');
              toReturn = isVisible = false;
            } else if (currentTimeInRange && !isVisible) {
              isVisible = true;
              element.css('display', '');
            }

            if (isVisible) {
              var suggestedWidth = calendarMetadata.viewType === 'w' ? (k_columnRelativeWidth + '%') : '';
              if (element.css('width') !== suggestedWidth) {
                element.css('width', suggestedWidth);
                setPosition(suggestedWidth === '');
              }
            }

            return toReturn;
          }


          function setTopPosition() {
            if (!showOrHide()) {
              return;
            }

            var top = calculateTopPosition();
            element.css('top', top - 1 + 'px');
          }

          function calculateTopPosition() {
            const eachMinuteHeight = calendarMetadata.actualCellHeight / (calendarMetadata.minutesBetweenMeetings * 1.0);
            return moment().date(calendarMetadata.startTime.date())
              .diff(calendarMetadata.startTime, 'minutes') * eachMinuteHeight;
          }

          function isCurrentTimeInRange() {
            var currentTime = moment();
            return currentTime.isAfter(calendarMetadata.startTime.date(currentTime.date())) && currentTime.isBefore(calendarMetadata.endTime);
          }

          function destroy() {
            if (intervalId) {
              clearInterval(intervalId);
            }
          }
        },
        template: '<div class="current-time-marker"><div></div></div>'
      }
    }])
}());
