import './employee.sells.modal.less'
import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT } from "../../constants";

angular.module('easybizy.easybizyModalViewsModel').controller('EmployeeSellerModalController',
  function ($scope, $modalInstance, localize, Repository, employee, configuration) {
    $scope.employeeId = employee.EmployeeId;
    $scope.employeeName = employee.FirstName + " " + employee.LastName;
    $scope.employee = employee;
    $scope.title = $scope.employeeId > 0 ? localize.getLocalizedString("_EmployeeSells_") + " - " + $scope.employeeName : localize.getLocalizedString("_SalesTree_");

    // //todo: create a user control for this
    // $scope.groupByManufacturer = false;

    $scope.groupByOptions = [
      {
        name: localize.getLocalizedString('_Categories_'), value: false
      },
      {
        name: localize.getLocalizedString('_Manufacturers_'), value: true
      }
    ];

    $scope.groupByWrapper = { groupedBy: $scope.groupByOptions[0] };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.dateRangePresets = [];
    generateImmediateRanges();
    generateMonthsInRange();
    $scope.dateRangeSelectedPresetWrapper = {};
    $scope.dateRangeSelectedPresetWrapper.dateRangeSelectedPreset = $scope.dateRangePresets[2];
    $scope.selectedRange = {};

    $scope.rangeMetadataFrom = {
      fieldName: 'from',
      icon: "icon icon-calendar",
      placeholder: "Start Date",
      validation: "{'required': true}",
      yearRange: "-7:+3",
      type: 'date'
    };
    $scope.rangeMetadataTo = {
      fieldName: 'to',
      icon: "icon icon-calendar",
      placeholder: "End Date",
      validation: "{'required': true}",
      yearRange: "-7:+3",
      type: 'date'
    };

    $scope.$watch('dateRangeSelectedPresetWrapper.dateRangeSelectedPreset', function (newVal) {
      if (newVal.value) {
        $scope.selectedRange.from = newVal.value.from;
        $scope.selectedRange.to = newVal.value.to;
      }
    });

    $scope.exportToExcel = function () {
      var from = moment($scope.selectedRange.from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
      var to = moment($scope.selectedRange.to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
      Repository.Custom("EmployeeSales").exportToExcel($scope.employeeId, from, to, $scope.groupByWrapper.groupedBy.value);
    };

    $scope.sendEmailValidateAction = {};
    $scope.sendEmailFieldValidator = {};
    $scope.emailAddressWrapper = { email: 'test@gmail.com' };
    $scope.isEmailVisible = false;
    $scope.sendByEmail = function () {
      var validationFields = $scope.sendEmailValidateAction.validate();
      if (validationFields.length != 0) { // is valid form?
        toastr.error(localize.getLocalizedString("_InvalidEmailAddress_"));
        return;
      }

      /// SEND BY EMAIL!
    };

    function generateImmediateRanges() {
      // TODAY.
      var now = moment();
      $scope.dateRangePresets.push({
        name: localize.getLocalizedString('_Today_'),
        value: {
          from: now.clone().format(DATE_DISPLAY_FORMAT),
          to: now.clone().format(DATE_DISPLAY_FORMAT)
        }
      });

      $scope.dateRangePresets.push({
        name: localize.getLocalizedString('_Yesterday_'),
        value: {
          from: now.clone().add(-1, 'days').format(DATE_DISPLAY_FORMAT),
          to: now.clone().add(-1, 'days').format(DATE_DISPLAY_FORMAT)
        }
      });
    }

    function generateMonthsInRange() {
      var now = moment();
      for (var i = 0; i < 12; i++) {
        $scope.dateRangePresets.push({
          name: now.format("MMM YY"),
          value: {
            from: now.clone().date(1).format(DATE_DISPLAY_FORMAT),
            to: now.clone().add(1, 'months').date(0).format(DATE_DISPLAY_FORMAT)
          }
        });
        now = now.subtract(1, 'months');
      }
    }


  });
