import { ID_PREFIX, PATH_TO_API } from "../../../constants";
import './image.uploader.directive.less'

angular.module('easybizy.common.common-controls').directive('imageUploader', [
  '$timeout', '$compile', '$fileUploader', 'localize', 'Repository', '$http', 'filesService', '$q',
  function ($timeout, $compile, $fileUploader, localize, Repository, $http, filesService, $q) {
    var validImageFilesExtensions = '|jpg|png|jpeg|bmp|gif|';
    var validImageFilesExtensionsSeperated = '.jpg,.png,.jpeg,.bmp,.gif';

    return {
      restrict: 'E',
      replace: true,
      template: require('./image.uploader.directive.tpl.html'),
      scope: {
        isTemporary: '=',
        uploadImmediately: '=',
        startUploadingWrapper: '=',
        entityToUpdate: '=',
        relatedEntityType: '=',
        existingImage: '=',
        clearImageDelegate: '=',
        imageSelected: '&',
        imageSelectedAsBase64: '&',
        actionText: '@',
        imageUploaded: '='
      },
      link: function (scope, element, attrs) {
        scope.isDefaultText = !angular.isDefined(scope.actionText);
        scope.uploadAsTemporary = !!scope.isTemporary;
        // This encapsulates the related entity type. Either from the entityToUpdate or straight from the relatedEntityType
        var relatedEntityType = null;

        var uploader = scope.uploader = $fileUploader.create({
          scope: scope,
          url: window.filesURI + 'api/files/upload?temp=' + scope.uploadAsTemporary
        });

        scope.$watch('entityToUpdate', function (newVal) {
          if (angular.isDefined(newVal)) {
            relatedEntityType = newVal.EntityType;
            scope.publicFile = newVal.EntityType === 'Employee';
            uploader.url = baseUrl + PATH_TO_API + 'FilesUpload/UploadImage?temp=false&EntityType='
              + newVal.EntityType + "&Id=" + newVal[newVal.EntityType + ID_PREFIX];
          }
        });

        scope.$watch('relatedEntityType', function (newVal) {
          if (angular.isDefined(newVal)) {
            relatedEntityType = newVal;
          }
        });

        var startUploadingWrapper = scope.$watch('startUploadingWrapper', function (newVal) {
          if (angular.isDefined(newVal)) {
            startUploadingWrapper();
            scope.startUploadingWrapper.startUploading = function () {
              uploader.uploadAll();
            };
          }
        });

        if (scope.clearImageDelegate) {
          scope.clearImageDelegate.invoke = function () {
            uploader.queue.length = 0;
            scope.toggleImageSelection(null, true);

          };
        }

        function onFinishedUploading(response) {
          scope.loadingImage = false;

          var toUpdate = {
            id: response.ImageId,
            name: "newImage",
            source: window.filesURI + (response.FilePath || response.fullPath)
          };

          scope.image = Object.assign({}, toUpdate, {
            source: toUpdate.source + '?time=' + Math.floor(Math.random() * 10000000)
          });

          if (angular.isDefined(scope.startUploadingWrapper) && scope.startUploadingWrapper.hasOwnProperty('onDoneUploading')) {
            scope.startUploadingWrapper.onDoneUploading(toUpdate);
          }

          scope.showPreview = true;

          if (angular.isDefined(scope.entityToUpdate)) {
            scope.entityToUpdate.DefaultImage = response.ImageId;
            scope.entityToUpdate.DefaultImagePath = response.FilePath;
          }

          if (scope.imageUploaded) {
            scope.imageUploaded(scope.image);
          }
        }


        scope.rejectFiles = [];
        scope.$watch('rejectFiles', function (newVal) {
          if (newVal && newVal.length > 0) {
            toastr.warning(localize.getLocalizedString("_WrongImageFileExtension_", validImageFilesExtensions));
          }
        });

        scope.allowedFiles = validImageFilesExtensionsSeperated;
        scope.upload = function (files) {
          if (files && files.length) {
            if (scope.uploadImmediately) {
              scope.loadingImage = true;

              var promises = files.map(function (file) {
                try {
                  return filesService.uploadFile(file, 'Image', relatedEntityType, scope.uploadAsTemporary, scope.publicFile);
                } catch (e) {
                  // Invalid file, entity or other.
                  toastr.error(e.message);
                  scope.loadingImage = false;
                  throw(e);
                }
              });

              $q.all(promises)
                .then(function (results) {
                  onFinishedUploading(results[0]);
                })
                .catch(function (err) {
                  toastr.error((err && err.data && err.data.message) || 'Unknown error occurred.');
                  scope.loadingImage = false;
                });
            } else {
              var fileReader = new FileReader();
              fileReader.readAsDataURL(files[0]);
              fileReader.onload = function (e) {
                scope.$apply(function () {
                  scope.imagePreview = e.target.result;
                  scope.showPreview = true;
                });

                imageSelected(this.result);
              };
            }
          }
        };

        var existingImage = scope.$watch('existingImage', function (newVal) {
          if (angular.isDefined(newVal)) {
            existingImage();
            //scope.imagePreview = newVal.src;
            scope.image = { source: newVal.src };
            scope.showPreview = true;
          }
        });

        scope.showFilesDialog = function () {
          element.find('.image-uploader-choose-file-button').trigger('click');
        };

        scope.appImages = [];

        function loadImagesFromApp() {
          Repository.Entity("Image", true).query().filter("IsFromMobileApp eq true and IsTemporary eq true and IsForImagePicker eq true").get()
            .then(function (data) {
              data.results.forEach(function (img) {
                var existingImage = scope.appImages.indexOfById({ id: img.ImageId }, 'id');
                if (existingImage < 0) {
                  scope.appImages.push({
                    id: img.ImageId,
                    ImageId: img.ImageId,
                    name: img.ImageName,
                    Path: baseUrl + img.FilePath
                  });
                }

                scope.$digestIfNeeded();
              });
            })
            .catch(function (err) {
              toastr.error(localize.getLocalizedString("_CouldntGetImagesFromApp_"));
            });
        }

        loadImagesFromApp();

        var lastSelectedImage = null;
        scope.toggleImageSelection = function (image, selectNone) {
          if (selectNone) {
            scope.image = null;
            scope.imagePreview = null;
            scope.showPreview = false;
            if (lastSelectedImage) {
              lastSelectedImage.selected = false;
            }
          } else if (image.isApproveDelete) {
            Repository.Entity("Image").remove(image)
              .then(function (res) {
                scope.appImages.remove(image);
                scope.$digestIfNeeded();
              })
              .catch(function (e) {
                toastr.error(localize.getLocalizedString("_ErrorRemovingImage"));
              });


          } else if (image == lastSelectedImage) {
            image.isApproveDelete = true;
          } else {
            if (lastSelectedImage) {
              lastSelectedImage.selected = false;
              delete lastSelectedImage.isApproveDelete;
            }

            lastSelectedImage = image;
            image.selected = true;
            scope.image = { id: image.id, name: image.name, source: image.Path };
            imageSelected();
          }

          if (angular.isDefined(scope.startUploadingWrapper) && scope.startUploadingWrapper.hasOwnProperty('onDoneUploading')) {
            scope.startUploadingWrapper.onDoneUploading(scope.image);
          }
        };

        function imageSelected(base64Image) {
          if (scope.imageSelectedAsBase64()) {
            if (base64Image) {
              if (attrs.compressLargerThen) {
                base64Image = compressImageToFitDesiredSize(base64Image);
              }

              scope.imageSelectedAsBase64()(base64Image);
            } else {
              convertImgToBase64(scope.image.source, scope.imageSelectedAsBase64(), 'image/jpeg');
            }
          }

          if (scope.imageSelected()) {
            if (base64Image) {
              scope.imageSelected()(base64Image);
            } else {
              convertImgToBase64(scope.image.source, scope.imageSelected(), 'image/jpeg');
              scope.togglePickerVisiblity();
            }
          }
        }

        $timeout(function () {
          var elWidth = element.outerWidth();
          scope.elementMarginLeft = 0;
          scope.elementMarginRight = 0;

          if (localize.isRightToLeft()) {
            scope.elementMarginRight = elWidth;
          } else {
            scope.elementMarginLeft = elWidth;
          }
        });

        scope.togglePickerVisiblity = function () {
          if (!scope.visiblePicker && scope.appImages.length == 0) {
            scope.showFilesDialog();
          } else {
            scope.visiblePicker = !scope.visiblePicker;
            angular.forEach(scope.appImages, function (img) {
              img.selected = false;
            });

            lastSelectedImage = null;
            if (scope.visiblePicker) {
              loadImagesFromApp();
            }
          }
        };

        function getCompressionRate(base64) {
          var toReturn = -1;
          if (attrs.compressLargerThen && base64.length > parseInt(attrs.compressLargerThen)) {
            toReturn = (parseInt(attrs.compressLargerThen) / base64.length);
          }

          return toReturn;
        }

        function compressImageToFitDesiredSize(base64) {
          if (!attrs.compressLargerThen) {
            return base64;
          }

          var compressionRate = getCompressionRate(base64);
          var mime_type = "image/jpeg";
          var tempImage = new Image();
          tempImage.src = base64;
          var cvs = document.createElement('canvas');
          cvs.width = tempImage.naturalWidth;
          cvs.height = tempImage.naturalHeight;
          cvs.getContext("2d").drawImage(tempImage, 0, 0);
          var compressedImage = base64;
          var iteration = 0;
          while (compressedImage.length > parseInt(attrs.compressLargerThen) && iteration < 5) {
            compressedImage = cvs.toDataURL(mime_type, compressionRate);
            compressionRate /= 1.5;
            iteration++;
            console.log("iteration: " + iteration);
          }

          // Making sure the elements is disposed.
          cvs = null;
          tempImage = null;

          return compressedImage;
        }

        /**
         * Convert an image
         * to a base64 string
         * @param  {String}   url
         * @param  {Function} callback
         * @param  {String}   [outputFormat=image/png]
         */
        function convertImgToBase64(url, callback, outputFormat) {
          var canvas = document.createElement('CANVAS'),
            ctx = canvas.getContext('2d'),
            img = new Image;
          img.crossOrigin = 'Anonymous';
          img.onload = function () {
            var dataURL;
            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;
            ctx.drawImage(img, 0, 0);
            dataURL = compressImageToFitDesiredSize(canvas.toDataURL(outputFormat));
            //var compressionRate = getCompressionRate(dataURL);
            //if (compressionRate > 0) {
            //  dataURL = canvas.toDataURL(compressionRate);
            //}

            callback.call(this, dataURL);
            canvas = null;
          };
          img.src = url;
        }
      }
    };
  }]);
