import './new.leads.less'
import { ObservableProperties } from "../../general/helpers";
import { haveSameElements } from "@tomeravni/easybizy-js-common/array";
import { toKebabCase, capitalizeEveryFirstLetter } from "@tomeravni/easybizy-js-common/string-manipulation";
import { adaptUrlValue, adaptFilterValue } from "./query.and.filter.adapters";
import { parseNumber } from "@tomeravni/easybizy-js-common/numbers";
import { cleanObj, getSafely, isConcreteValue, isNonEmptyObject } from "@tomeravni/easybizy-js-common/common";
import { last } from 'underscore';
import {
  ODATA_DATE_TIME_FORMAT,
  SERVER_DATA_DATE_FORMAT
} from "../../constants";

angular.module('easybizy.leads')
  .controller('NewLeadsController', function NewLeadsController($scope, Repository, toolbar, localize, configuration,
    entityImageResolver, tileActionsResolver, $timeout, $state, $modal, confirmService,
    $q, employees, stateManager, loggedInUser, isValidWhatsappAccount, $rootScope) {

    $scope.clearSearchFuncWrapper = {};
    const confirmMessage = localize.getLocalizedString('_ApproveGeneralConfirm_');

    const loadPresetsAsync = () => {
      return Repository.Custom('Configurations').loggedInUser().then((res) => {
        return getSafely(['personalConfigurations', 'presets', 'leads'], res, []);
      });
    }

    const toolbarActions = [
      {
        name: 'custom',
        icon: 'icon icon-entity-settings',
        action: function () {
          const opts = {
            backdrop: 'static',
            keyboard: false,
            template: require('../../modal-views/custom-entity-settings/custom.entity.config.modal.tpl.html'),
            controller: 'CustomEntityConfigurationsController',
            resolve: {
              views: () => {
                return [
                  {
                    loadItemsDelegate: () => Repository.Custom('CategoriesEditorRepository').get("LeadTags"),
                    name: localize.getLocalizedString('_Tags_'),
                    renameDelegate: (item, newName) => {
                      return Repository.Custom('CategoriesEditorRepository').rename("LeadTags", item.value, newName);
                    },
                    actions: [
                      {
                        name: localize.getLocalizedString('_Delete_'),
                        type: 'warning-submit',
                        confirmMessage: confirmMessage,
                        delegate: (items) => {
                          return Repository.Custom('CategoriesEditorRepository').remove("LeadTags", items);
                        }
                      },
                      {
                        name: localize.getLocalizedString('_Unite_'),
                        confirmMessage: confirmMessage,
                        minSelectionLength: 2,
                        delegate: (items) => {
                          return Repository.Custom('CategoriesEditorRepository').merge("LeadTags", items);
                        }
                      }
                    ],
                    addNewItemDelegate: (tagName) => {
                      const newTag = { Name: tagName, EntityType: 'Lead', Color: 'back-color-abbey' }
                      return Repository.Entity('Tag').create(newTag).post();
                    }
                  },
                  {
                    loadItemsDelegate: () => Repository.Custom('CategoriesEditorRepository').get("LeadSubStatus"),
                    name: localize.getLocalizedString('_LeadSubStatus_'),
                    renameDelegate: (item, newName) => {
                      return Repository.Custom('CategoriesEditorRepository')
                        .rename("LeadSubStatus", item.value, newName);
                    },
                    actions: [
                      {
                        name: localize.getLocalizedString('_Delete_'),
                        type: 'warning-submit',
                        confirmMessage: confirmMessage,
                        delegate: (items) => {
                          return Repository.Custom('CategoriesEditorRepository').remove("LeadSubStatus", items);
                        }
                      },
                      {
                        name: localize.getLocalizedString('_Unite_'),
                        confirmMessage: confirmMessage,
                        minSelectionLength: 2,
                        delegate: (items) => {
                          return Repository.Custom('CategoriesEditorRepository').merge("LeadSubStatus", items);
                        }
                      }
                    ],
                    addNewItemDelegate: (subStatusName) => {
                      return Repository.Custom('LeadSubStatus').create({ LeadSubStatusName: subStatusName });
                    }
                  },
                  {
                    loadItemsDelegate: () => Repository.Custom('CategoriesEditorRepository').get("LeadCategories"),
                    name: localize.getLocalizedString('_LeadCategories_'),
                    renameDelegate: (item, newName) => {
                      return Repository.Custom('CategoriesEditorRepository')
                        .rename("LeadCategory", item.value, newName);
                    },
                    actions: [
                      {
                        name: localize.getLocalizedString('_Delete_'),
                        type: 'warning-submit',
                        confirmMessage: confirmMessage,
                        delegate: (items) => {
                          return Repository.Custom('CategoriesEditorRepository').remove("LeadCategory", items);
                        }
                      },
                      {
                        name: localize.getLocalizedString('_Unite_'),
                        confirmMessage: confirmMessage,
                        minSelectionLength: 2,
                        delegate: (items) => {
                          return Repository.Custom('CategoriesEditorRepository').merge("LeadCategory", items);
                        }
                      }
                    ],
                    addNewItemDelegate: (categoryName) => {
                      return Repository.Custom('LeadCategory').create({ LeadCategoryName: categoryName });
                    }
                  },
                  {
                    loadItemsDelegate: (search) => Repository.Custom('CategoriesEditorRepository').get("Leads", search),
                    name: localize.getLocalizedString('_Restore_'),
                    showSearch: true,
                    actions: [
                      {
                        name: localize.getLocalizedString('_Restore_'),
                        type: 'warning-submit',
                        confirmMessage: confirmMessage,
                        delegate: (items) => {
                          return Repository.Custom('CategoriesEditorRepository').restore("Leads", items);
                        }
                      }
                    ]
                  },
                  {
                    loadItemsDelegate: () => loadPresetsAsync().then((presets) => {
                      return presets.map((preset) => ({
                        name: preset.label,
                        value: preset.uid
                      }))
                    }),
                    name: localize.getLocalizedString('_Presets_'),
                    showSearch: false,
                    actions: [
                      {
                        name: localize.getLocalizedString('_Delete_'),
                        type: 'warning-submit',
                        confirmMessage: confirmMessage,
                        delegate: (items) => {
                          return Promise.all(items.map((value) => Repository.Custom('Configurations').deletePreset(value))).then((res) => {
                            return loadPresetsAsync().then((presets) => {
                              return $scope.mediator.reloadPresetsDelegate(presets);
                            })
                          })
                        }
                      }
                    ]
                  }
                ];
              }
            }
          }
          $modal.open(opts);
        }
      },
      {
        name: 'custom',
        icon: 'icon icon-excel',
        action: function () {
          $scope.mediator.exportToExcel()
        },
        disabled: false,
        tooltip: localize.getLocalizedString('_Export_')
      }, {
        name: 'add',
        action: function (lead, callback) {
          createNewLead(lead, callback);
        },
        data: {
          template: require('../../modal-views/add-lead/add.lead.tpl.html'),
          controller: 'AddLeadModalController'
        }
      }
    ];

    toolbar.set(toolbarActions);

    let queryParams = stateManager.getValidRawParams()
    queryParams = Object.keys(queryParams).reduce((acc, key) => {
      let fieldName = capitalizeEveryFirstLetter(key, true);
      return {
        ...acc,
        [fieldName]: adaptUrlValue(fieldName, queryParams[key])
      }
    }, {})

    let scrollToItemIndex;
    if (queryParams.VisibleIndex) {

      const adaptedIndex = parseNumber(queryParams.VisibleIndex);
      if (isConcreteValue(adaptedIndex)) {
        scrollToItemIndex = adaptedIndex;
      }

      delete queryParams.VisibleIndex;
    }

    $scope.mediator = cleanObj({ initilizedQuery: queryParams, scrollToItemIndex });

    const configurations = configuration.get();
    $scope.mediator.showIncomes = loggedInUser.rootUser || configurations.LeadsSettings?.ShowIncomesForEmployeeUsers;


    ObservableProperties($scope.mediator);
    $scope.mediator.on('viewTypeChanged', ({ viewType, prev }) => {
      const params = !prev ? stateManager.getValidRawParams() : {};
      stateManager.setState('Leads', {
        viewType,
        query: params.query,
        sort: params.sort
      }, false, true);
    });


    ///
    let lastFilterSet = [];
    $scope.mediator.on('filterChange', (adaptedFilters) => {
      if (haveSameElements(lastFilterSet, adaptedFilters)) {
        return;
      }

      lastFilterSet = adaptedFilters;

      const params = stateManager.getValidRawParams();

      const queryAdaptedFilters = adaptedFilters.reduce((acc, filter) => {
        let fieldName = filter.column.fieldName;
        let adaptedFieldName = toKebabCase(fieldName);
        return {
          ...acc,
          [adaptedFieldName]: adaptFilterValue(fieldName, filter.filterValue)
        }
      }, Object.keys(params).reduce((acc, key) => ({ ...acc, [toKebabCase(key)]: undefined }), {}));

      stateManager.setState('Leads', {
        ...queryAdaptedFilters
      }, false, true);
    });

    $scope.mediator.on('sortChange', (sort) => {
      const params = stateManager.getValidRawParams();
      let validSort = undefined;
      if (sort) {
        validSort = `${sort.desc ? '-' : ''}${sort.column.fieldName}`
      }

      stateManager.setState('Leads', {
        viewType: params.viewType,
        sort: validSort,
        query: params.query
      }, false, true);
    });

    function createNewLead(lead, callback) {
      Repository.Entity("Lead").create(lead).post()
        .then(function (newLead) {
          $scope.$digestIfNeeded();
          $scope.mediator.addItem(newLead);
          callback.resolve(newLead);
        })
        .catch(function (err) {
          callback.reject(err);
        });
    }

    $rootScope.$on('new-lead', (e, data) => {
      Repository.Custom('QueryableTable').leads().filter(`LeadId eq ${data.leadId}`).get().then((res) => {
        const leadResult = getSafely([0], res);
        if (isNonEmptyObject(leadResult)) {
          $scope.mediator.addItem(leadResult);
        }
      });
    });

    $scope.mediator.remove = function (lead) {
      const deferred = $q.defer();
      confirmService.confirm(localize.getLocalizedString('_ApproveDeleteMessage_'), null, function () {
        Repository.Entity("Lead").remove([lead])
          .then(function () {
            toastr.success(localize.getLocalizedString("_LeadsRemovedSuccessfully_"));
            deferred.resolve();
          })
          .catch(function (err) {
            toastr.error(err);
            deferred.reject(err);
          });
      }, null, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_'));
      return deferred.promise;
    }

    $scope.mediator.bounce = function (lead) {
      const deferred = $q.defer();
      let now = moment();
      lead.CreatedOn = now.format(SERVER_DATA_DATE_FORMAT);
      Repository.Entity("Lead").patch(lead.LeadId, { LastAppeal: now.format(ODATA_DATE_TIME_FORMAT) }).post()
        .then(function () {
          deferred.resolve();
          $timeout(function () {
            $scope.mediator.addItem(lead);
          }, 500);
        })
        .catch(function (err) {
          toastr.error(err);
          deferred.reject(err);
        });

      return deferred.promise;
    }

    $scope.mediator.turnToCustomer = function (lead) {
      var deferred = $q.defer();
      confirmService.confirm(localize.getLocalizedString('_TransformLeadToCustomer_'), null, function () {
        Repository.Custom('Leads').transformIntoCustomer(lead.LeadId)
          .then(function (result) {
            toastr.success(localize.getLocalizedString("_TransformedToCustomSuccessfully_"));
            $state.go('CustomerDetails', { customerId: result.CustomerId });
            deferred.resolve(lead);
          })
          .catch(function (err) {
            var message = '';
            try {
              var customerExistMessage = message = err.data['odata.error'].innererror.internalexception.message;
              if (customerExistMessage) {
                confirmService.confirm(localize.getLocalizedString("_MergeLeadWithCustomer_", message), customerExistMessage,
                  function () {
                    var deferred = $q.defer();
                    Repository.Custom('Leads').mergeIntoCustomer(lead.LeadId)
                      .then(function (result) {
                        toastr.success(localize.getLocalizedString("_TransformedToCustomSuccessfully_"));
                        $state.go('CustomerDetails', { customerId: result.CustomerId });
                        deferred.resolve(lead);
                      })
                      .catch(function (err) {
                        toastr.error(localize.getLocalizedString("_ErrorTransformLead_", ''));
                        deferred.reject(data);
                      });
                  });
              } else {
                toastr.error(localize.getLocalizedString("_ErrorTransformLead_", message));
              }
            } catch (e) {
            }
            deferred.reject(data);
          })
      }, null, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_'));

      return deferred.promise;
    }

    $scope.mediator.addCustomTimelineItem = function (lead, cb = () => {
    }, noteToEdit) {
      const options = {
        backdrop: 'static',
        windowClass: 'transition-off',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/entity-custom-note/entity.custom.note.tpl.html'),
        controller: 'EntityCustomNoteController',
        resolve: {
          saveAction: function () {
            return function (newEntity) {
              return generateNewCustomTimelineItem(newEntity, lead).then(cb)
            };
          },
          parameters: function () {
            return {
              forceNew: !isNonEmptyObject(noteToEdit),
              getOwnerId: function () {
                return lead.LeadId;
              },
              reminderType: 'customer',
              noteToEdit
            };
          }
        },
        closeFn: function () {
          console.log("closing");
        }
      };

      $modal.open(options);
    }

    $scope.mediator.savePreset = (preset, cb) => {
      const options = {
        backdrop: 'static',
        windowClass: 'transition-off',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/add-preset/add.preset.modal.tpl.html'),
        controller: 'AddPresetModalViewController',
        resolve: {
          savedCallback: function () {
            return function (presets) {
              cb(presets);
            };
          },
          preset: () => preset
        },
        closeFn: function () {
          console.log("closing");
        }
      };

      $modal.open(options);
    }

    function generateNewCustomTimelineItem(timelineEntityCustomNoteToSend, lead) {
      const deferred = $q.defer();
      timelineEntityCustomNoteToSend.EntityType = 'Lead';
      Repository.Custom("CustomTimelineItem").createOrUpdate(timelineEntityCustomNoteToSend)
        .then(function (timelineEntityCustomNote) {
          toastr.success(localize.getLocalizedString("_CustomItemSuccesfulyCreated_"));
          if (!lead.CustomTimelineItems) {
            lead.CustomTimelineItems = [];
          }

          lead.CustomTimelineItems.push(timelineEntityCustomNote);

          deferred.resolve(timelineEntityCustomNoteToSend);
          $scope.$digestIfNeeded();
        }).catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorCreatingCutomItem_"), err);
          deferred.reject(err);
        });

      return deferred.promise;
    }

    $scope.mediator.sendSms = function (entity) {
      tileActionsResolver.getAction('sendSms').command({
        MobileFirst: entity.MobileFirst,
        FirstName: entity.FirstName,
        LeadId: entity.LeadId,
        EntityType: "Lead"
      });
    }

    if (isValidWhatsappAccount) {
      $scope.mediator.sendWhatsapp = function (entity) {
        const entityType = entity.CustomerId ? 'customer' : 'lead';
        tileActionsResolver.getAction('sendWhatsapp').command({
          MobileFirst: entity.MobileFirst,
          FirstName: entity.FirstName,
          LastName: entity.LastName,
          CustomerId: entity.CustomerId,
          LeadId: entity.LeadId,
          EntityType: entityType
        });
      }
    }


  });
