(function () {
  angular.module('easybizy.calendar').service('calendarClipboard', [
    'calendarMetadata', 'calendarStore',
    function (calendarMetadata, calendarStore) {
      this.meetingsInClipboard = [];
      this.cut = function (meeting) {
        if (meeting.clipboardReason !== 'copy') {
          this.meetingsInClipboard.push(meeting);
        }

        meeting.clipboardReason = 'cut';
        calendarStore.removeFromStore(meeting);
      };

      this.copy = function (meeting) {
        if (meeting.clipboardReason !== 'copy') {
          this.meetingsInClipboard.push(meeting);
        }

        meeting.clipboardReason = 'copy';
      };

      this.remove = function (meeting) {
        this.meetingsInClipboard.remove(meeting);
        delete meeting.clipboardReason;
      };

      this.clear = function () {
        var self = this;
        var cutMeetings = [];
        self.meetingsInClipboard.forEach(function (meeting) {
          if (meeting.clipboardReason === 'cut') {
            cutMeetings.push(meeting.originalMeeting);
          }
        });

        self.meetingsInClipboard.forEach(function (meeting) {
          delete meeting.clipboardReason;
        });

        self.meetingsInClipboard.length = 0;
        cutMeetings.forEach(function (meeting) {
          calendarStore.insertToStore(meeting);
        });
      }
    }]);

}());
