<template>
  <div class="whatsapp-on-boarding-wizard">
    <transition name="top">
      <div class="absolute top-0 left-0 h-full w-full whatsapp-on-boarding-wizard__mask" v-if="isVisible">
      </div>
    </transition>
    <transition name="slide-it">
      <div v-if="isVisible && !editCampaign"
           class="absolute top-0 h-full w-full whatsapp-on-boarding-wizard__content">
        <div class="relative-container">
          <div class="absolute w-full whatsapp-on-boarding-wizard__content-mask"
               :class="selectedPackage && 'whatsapp-on-boarding-wizard__content-mask--expanded'">
          </div>
          <div class="z-10 relative whatsapp-on-boarding-wizard__header">
            <h1 class="flex items-center justify-center">
              <div class="p-2">{{ headerText }}</div>
            </h1>
            <h3 class="whitespace-pre-line">
              {{ headerExplain }}
            </h3>
          </div>
          <pick-kind @selected="packageSelected" :selected-package="selectedPackage"
                     :available-packages="availablePackages"/>
          <transition name="slide-bottom">
            <div class="whatsapp-on-boarding-wizard__info z-10 absolute"
                 v-if="selectedPackage">
              <reminders-only-content v-bind="selectedPackage.explain" :expected-cost-text="expectedCostText"
                                      @next-step="toggleEditCampaign"/>
            </div>
          </transition>
          <transition name="slide-left">
            <div class="whatsapp-on-boarding-wizard__phone z-1 absolute" v-if="selectedPackage"
                 :class="packageImage.className">
              <img :src="packageImage.src"/>
            </div>
          </transition>
        </div>
      </div>
    </transition>
    <transition name="slide-it">
      <div v-if="editCampaign && isVisible"
           class="absolute top-0 h-full w-full whatsapp-on-boarding-wizard__promotion-editor">
        <div class="relative-container">
          <div
            class="absolute w-full whatsapp-on-boarding-wizard__content-mask whatsapp-on-boarding-wizard__content-mask--expanded">
          </div>
          <whatsapp-generate-campaign
            :templates="templates"
            :selected-template="selectedTemplate"
            :existing-promotion="existingReminderPromotion"
            :form-options="formOptions"
            :rules="generationRules"
            @select-template="selectTemplate"
            @create-promotion="createPromotion"
            @revert="toggleEditCampaign" :reminder-content="selectedPackage.reminderContent"/>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>
import PickKind from "./pick-kind.vue";
import RemindersOnlyContent from "./reminders-only-content.vue";
import {availablePackages} from "./metadata.json";
import WhatsappGenerateCampaign from "./whatsapp-generate-campaign.vue";
import {extractError} from "@tomeravni/easybizy-js-common/errors";
import {getSafely, valueOrDefault} from "@tomeravni/easybizy-js-common/common";

const VALID_TEMPLATE_NAMES = ["__global_simple_meeting_reminder_v1", "__global_meeting_reminder_with_approval_v1"]
export default {
  components: {WhatsappGenerateCampaign, RemindersOnlyContent, PickKind},
  created() {
    this.headerText = this.$t('_WhatsappOnBoarding_HeaderText_')
    this.headerExplain = this.$t('_WhatsappOnBoarding_ExplainText_')
  },
  mounted() {
    const conversationsRepository = this.Repository.Custom('Conversations');
    const promotionsApi = this.Repository.Custom('PromotionsRepository');
    const calendarRepository = this.Repository.Custom('Calendar')

    Promise.all([
      conversationsRepository.globalAccount(),
      conversationsRepository.globalTemplates(),
      promotionsApi.getRecurringMeetingReminderCampaign(),
      calendarRepository.averageMonthlyMeetings(),
    ]).then(([account, templates, existingReminderPromotion, averageMeetings]) => {
      const {median, avarage} = valueOrDefault(averageMeetings, {});
      this.meetingsPerMonth = Math.max(median, avarage, 0);
      this.templates = templates.filter((x) =>
        VALID_TEMPLATE_NAMES.includes(x.waName) && x.language === 'he').map((template) => {
        const {name, waName} = template;
        return {
          name,
          template,
          value: waName,
        }
      })

      this.selectedTemplate = this.templates.find((x) => x.value === '__global_meeting_reminder_with_approval_v1')
      this.existingReminderPromotion = existingReminderPromotion;
      // const formIfRequiredFieldName = this.promotionTemplateResolver.getFieldByName('FormIfNeeded').value
      // const formIfRequiredExists = getSafely(['PromotionContent', 'Message'], existingReminderPromotion, '').includes(formIfRequiredFieldName)

      this.generationRules = {
        ...this.generationRules,
        allowOnlineBooking: true,
        formIfRequired: true,
        waAccount: account
      }

    }).catch((err) => {
      toastr.error(extractError(err))
    })


    const formOptions = [];
    this.Repository.Custom('Forms').getForms().then((forms) => {
      forms.forEach(function (form) {
        formOptions.push({name: form.name, value: form._id});
      });
    }).catch(e => console.log("couldn't get forms.."));

    this.formOptions = formOptions;
  },
  data() {
    return {
      selectedPackage: null,
      availablePackages,
      editCampaign: false,
      templates: [],
      formOptions: [],
      selectedTemplate: null,
      existingReminderPromotion: null,
      meetingsPerMonth: 0,
      generationRules: {
        formIfRequired: true,
        allowOnlineBooking: true,
        existingSMSCampaign: true
      }
    }
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    packageImage() {
      if (!this.selectedPackage) {
        return ''
      }

      const isRemindersOnly = this.selectedPackage.type === 'reminders-only';
      return {
        src: isRemindersOnly ? '/img/reminder.png' : '/img/whatsapp-full.png',
        className: isRemindersOnly ? '' : 'whatsapp-on-boarding-wizard__phone--mask'
      };
    },
    expectedCostText() {
      if (this.selectedPackage.type === 'full' || !(this.meetingsPerMonth > 0)) {
        return null;
      }

      return '* ' + this.$t('_WhatsappOnBoarding_ExpectedCost_', this.meetingsPerMonth, 20 + this.meetingsPerMonth * 0.25);
    }
  },

  methods: {
    packageSelected(waPackage) {
      this.selectedPackage = waPackage;
    },
    toggleEditCampaign() {
      if (this.selectedPackage.type === 'full') {
        const message = this.$t('_WhatsappGlobalEmbedExplain_');
        const link = `https://api.whatsapp.com/send?phone=972${505927326}&text=${message}`;
        window.open(link, '_blank');
        return;
      }

      this.editCampaign = !this.editCampaign;
    },
    selectTemplate(template) {
      this.selectedTemplate = template
    },
    createPromotion(promotion, replaceExisting) {
      this.toggleLoading(true)
      const promotionRepository = this.Repository.Custom('PromotionsRepository')
      promotionRepository.create(promotion).then((res) => {
        if (replaceExisting) {
          promotionRepository.pause(this.existingReminderPromotion.PromotionId).then(() => this.doneCreatingPromotion()).catch((err) => {
            toastr.error(extractError(err))
          });

        } else {
          this.doneCreatingPromotion()
        }
      }).catch((err) => {
        toastr.error(extractError(err))
      });
    },
    doneCreatingPromotion() {
      this.$rootScope.$evalAsync(() => {
        this.$rootScope.$emit('toggle-whatsapp-onboarding');
        this.$rootScope.$emit('update-conversation-state');
        this.toggleLoading(false)
        this.toastr.success(this.$t('_WhatsappOnBoarding_SuccessMessage_'))
        setTimeout(() => {
          if (this.$state.current.name === 'ManagePromotions') {
            return this.$state.reload()
          }

          this.$stateManager.setState('ManagePromotions', {
            promotionStatus: 'active'
          });
        }, 1000);
      })
    }
  }
}

</script>

<style lang="less">
@import "../../../../styles/variables";
@import "vars";

.whatsapp-on-boarding-wizard {
  font-family: "Heebo", Arimo, Helvetica, Arial, sans-serif;
  direction: rtl;

  h1 {
    color: @primary-color;
  }

  &__mask {
    background-color: white;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, rgba(66, 163, 252, 0.5) 100%);
    opacity: 1;
    z-index: 1000;
  }

  &__header {
    padding: 0 30px;
  }

  &__content {
    z-index: 1001;
    display: flex;
    justify-content: center;

    h3 {
      margin-bottom: 30px;
    }

    > div {
      max-width: 1200px;
      overflow-y: auto;
    }
  }

  &__promotion-editor {
    z-index: 1001;
    display: flex;
    justify-content: center;

    > div {
      max-width: 1200px;
    }

  }

  &__logo {
    height: 40px;
  }

  &__content-mask {
    height: 40%;
    border-radius: 30px;
    margin-top: 85px;
    background-color: white;
    box-shadow: 0 2px 5px 0 rgba(164, 173, 186, 0.25);
    transition: height 0.5s;

    &--expanded {
      height: 80%;
    }

  }

  &__info {
    width: calc(100% - @tile-width - 60px - @phone-size - @space-between-tile * 2);
    padding: 25px;
    left: @phone-size + @space-between-tile * 2 - 5px;
    border-radius: 15px;
  }

  &__phone {
    left: @space-between-tile - 5px;
    margin-top: -10px;

    img {
      width: @phone-size;
    }

    &--mask {
      position: absolute;
      top: 85px;
      left: 0;
      height: 80%;
      margin-top: 0;

      > img {
        height: 100%;
        width: auto;
      }
    }
  }
}

.top-enter-active, .top-leave-active {
  transition: opacity 0.3s, max-height 0.5s;
  max-height: 100%;
}

.top-enter, .top-leave-to {
  opacity: 0;
  max-height: 0;
}


.slide-it-enter-active, .slide-it-leave-active {
  transition: all 0.5s;
  transform: translate(0, 0);
  opacity: 1;
}

.slide-it-enter, .slide-it-leave-to {
  transform: translate(0, 60%);
  opacity: 0;
}


.slide-bottom-enter-active, .slide-bottom-leave-active {
  transition: all 0.5s;
  transition-delay: 0.15s;
  transform: translateY(0);
  opacity: 1;
}

.slide-bottom-enter, .slide-bottom-leave-to {
  transform: translateY(500px);
  opacity: 0;
  transition-delay: 0s;
}


.slide-left-enter-active, .slide-left-leave-active {
  transition: all 0.5s;
  transition-delay: 0.25s;
  transform: translateY(0);
  opacity: 1;
}

.slide-left-enter, .slide-left-leave-to {
  transform: translateX(-500px);
  opacity: 0;
}

</style>
