import { PRINT_CALENDAR_CSS } from "./print.css";

export const printTableMode = (calendarColumns, calendarStore, calendarMetadata, configurations, localize) => {
  var cssLink = document.createElement("link");
  cssLink.innerHTML = PRINT_CALENDAR_CSS;
  cssLink.rel = "stylesheet";
  cssLink.type = "text/css";
  var visibleColumns = calendarColumns.visibleColumns;
  var columnToMeetings = new Array(visibleColumns.length);
  var columnsResolved = 0;
  const tableMode = configurations.CalendarSettings.UseTableCalendarPrint;
  const sortPrintByColumnsInView = configurations.CalendarSettings.SortPrintByColumnsInView;
  visibleColumns.forEach(function (column) {
    calendarStore.getMeetingsByDate(column).then(function (meetings) {
      meetings = meetings.sort(function (a, b) {
        return new Date(a.StartTime).getTime() - new Date(b.StartTime).getTime();
      });
      columnToMeetings[visibleColumns.indexOf(column)] = meetings;
      columnsResolved++;
      if (columnsResolved === visibleColumns.length) {
        let sortedMeetings = columnToMeetings.reduce((acc, curr) => acc.concat(curr), []);          
        if(!sortPrintByColumnsInView){
          sortedMeetings = sortedMeetings.sort((a, b) => a.StartTime.unix() - b.StartTime.unix());
        }
        calendarStore.extendMeetingNotes(sortedMeetings).then((sortedMeetings) => {
          if (tableMode) {
            renderTable(sortedMeetings, calendarMetadata, configurations, localize);
          } else {
            renderPrintView(visibleColumns, columnToMeetings, calendarMetadata, configurations, localize);
          }
        }).catch((err) => {
          toastr.error(err.Message);
        });



      }

    });
  });

  window.frames['print_frame'].document.head.appendChild(cssLink);
}


function renderTable(meetingsArr, calendarMetadata, configurations, localize) {
  const roomsMap = getRoomsMap(calendarMetadata);
  const employeesMap = getEmployeesMap(calendarMetadata);
  const hasRooms = Object.keys(roomsMap).length > 0;

  const style = `
          <style type="text/css">
            * {text-align:  right; direction: rtl}
            .tg  {border-collapse:collapse;border-spacing:0;}
            .tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
              overflow:hidden;padding:10px 5px;word-break:normal;}
            .tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
              font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
            .tg .tg-0lax{text-align:right;vertical-align:top}
          </style>
          `;
  var finalHTML = `<div style="direction: rtl">${style}<h1>${calendarMetadata.currentRangeView.label}</h1>`;
  const showMobile = configurations.CalendarSettings.IncludeMobileOnCalendarPrint;
  const showCustomerName = !configurations.CalendarSettings.HideCustomerNamesOnCalendarPrint;

  finalHTML += `
          <table class="tg" style="width: 100%">
            <thead>
              <tr>
                <th class="tg-0lax" style="width: 50px">${localize.getLocalizedString('_Start_')}</th>
                <th class="tg-0lax" style="width: 50px">${localize.getLocalizedString('_End_')}</th>
                ${showCustomerName
      ? `<th class="tg-0lax" style="width: 90px">${localize.getLocalizedString('_Customer_')}</th>`
      : ''}
                ${showMobile
      ? `<th class="tg-0lax" style="width: 70px">${localize.getLocalizedString('_MobileFirst_')}</th>`
      : ''}
                <th class="tg-0lax" style="width: 70px">${localize.getLocalizedString('_Employee_')}</th>
                ${hasRooms
      ? `<th class="tg-0lax" style="width: 60px">${localize.getLocalizedString('_Room_')}</th>`
      : ''}
                <th class="tg-0lax" style="width: 120px">${localize.getLocalizedString('_Services_')}</th>
                <th class="tg-0lax">${localize.getLocalizedString('_Notes_')}</th>
                <th class="tg-0lax" style="width: 250px">${localize.getLocalizedString('_Other_')}</th>
              </tr>
            </thead>
            <tbody>
          `;

  meetingsArr.forEach(meeting => {
    const customerName = meeting.originalMeeting.Title || meeting.originalMeeting.CustomerName;
    const customerMobile = meeting.originalMeeting.MobileFirst;
    const start = meeting.EndTime.format('HH:mm');
    const end = meeting.StartTime.format('HH:mm');
    let compositeAdapters = meeting.originalMeeting.CompositeAdapters;
    const employee = compositeAdapters ? compositeAdapters[Object.keys(compositeAdapters)[0]].map(function (service) { return employeesMap[service.EmployeeId]; }).join(",") : employeesMap[meeting.EmployeeId];
    const room = roomsMap[meeting.RoomId] || localize.getLocalizedString('_NoRoom_');
    let serviceType = meeting.serviceNames || localize.getLocalizedString("_GeneralTreatment_");
  
    const notes = meeting.remarks || '';
    finalHTML += `
              <tr>
                  <td class="tg-0lax">${ end}</td>
                  <td class="tg-0lax">${ start}</td>
                  ${ showCustomerName ? `<td class="tg-0lax">${customerName}</td>` : ''}
                  ${ showMobile ? `<td class="tg-0lax">${customerMobile}</td>` : ''}
                  <th class="tg-0lax">${ employee}</th>
                  ${ hasRooms ? `<th class="tg-0lax">${room}</th>` : ''}
                  <th class="tg-0lax">${ serviceType}</th>
                  <th class="tg-0lax">${ notes}</th>
                  <th class="tg-0lax"></th>
                </tr>
            `

  })


  finalHTML += '</tbody></table></div>';

  window.frames["print_frame"].document.body.innerHTML = finalHTML;
  setTimeout(function () {
    window.frames["print_frame"].window.focus();
    window.frames["print_frame"].window.print();
    window.frames["print_frame"].document.body.innerHTML = "";
  });

}

function renderPrintView(columnsArr, meetingsArr, calendarMetadata, configurations, localize) {
  const roomsMap = getRoomsMap(calendarMetadata);
  const hasRooms = Object.keys(roomsMap).length > 1;

  let filteredEmployee = (calendarMetadata.viewType === 'd' || calendarMetadata.viewType === 'w') &&
    calendarMetadata.filteredEmployeeId > 0 &&
    calendarMetadata.employees.find(x => x.id === calendarMetadata.filteredEmployeeId);

  var finalHTML = '<div style="direction: rtl"><h1>' + calendarMetadata.currentRangeView.label + '</h1>' + (filteredEmployee ? '<h1>' + filteredEmployee.name + '</h1>' : '');
  var showMobile = configurations.CalendarSettings.IncludeMobileOnCalendarPrint;
  var showCustomerName = !configurations.CalendarSettings.HideCustomerNamesOnCalendarPrint;


  columnsArr.forEach(function (column, idx) {
    finalHTML += '<h4>' + column.label + '</h4>';
    finalHTML += '<ul>';
    meetingsArr[idx].forEach(function (meeting) {

      var customerName = meeting.originalMeeting.Title;
      var customerMobile = meeting.originalMeeting.MobileFirst;
      var timeString = meeting.EndTime.format('HH:mm') + ' - ' + meeting.StartTime.format('HH:mm');
      var innerHtml = '<span><b>' + (showCustomerName ? customerName : '') + '</b>&nbsp;-&nbsp;</span>' + (showMobile ? '<span><b>' + customerMobile + '</b>&nbsp;-&nbsp;</span>' : '');
      innerHtml += '<span>' + timeString;

      if (hasRooms) {
        const room = roomsMap[meeting.RoomId] || localize.getLocalizedString('_NoRoom_');

        innerHtml += ',&nbsp;<span>' +
          // Is there a room.
          (room) +
          '</span>,';
      }

      innerHtml += '&nbsp;(';

      var serviceType;
      if (meeting.originalMeeting.ServiceNames.length > 0) {
        serviceType = meeting.originalMeeting.ServiceNames.map(function (service) {
          return service;
        }).join(', ');
      } else {
        serviceType = localize.getLocalizedString("_GeneralTreatment_");
      }

      innerHtml += serviceType + '), </span>';
      finalHTML += '<li>' + innerHtml + '</li>';
    });

    finalHTML += '</ul>';
  });

  finalHTML += '</div>';

  window.frames["print_frame"].document.body.innerHTML = finalHTML;
  setTimeout(function () {
    window.frames["print_frame"].window.focus();
    window.frames["print_frame"].window.print();
    window.frames["print_frame"].document.body.innerHTML = "";
  });
}


function getRoomsMap(calendarMetadata) {
  var roomsMap = {};
  var NO_ROOM_NAME = 'none';

  if (Array.isArray(calendarMetadata.rooms)) {
    calendarMetadata.rooms.forEach(function (room) {
      roomsMap[room.id || NO_ROOM_NAME] = room.name;
    });
  }

  return roomsMap;
}

function getEmployeesMap(calendarMetadata) {
  return calendarMetadata.employees.reduce((acc, curr) => Object.assign(acc, { [curr.id]: curr.name }), {})

}

