angular.module('easybizy.google-calendar', []).factory('googleCalendarService', [
  'localize', '$modal', '$timeout', '$rootScope', 'Repository', 'configuration', '$q',
  function (localize, $modal, $timeout, $rootScope, Repository, configuration, $q) {
    var toReturn = {
      syncGoogleCalendar: syncGoogleCalendar,
      selectCalendarToSyncWith: selectCalendarToSyncWith,
      performFullSync: performFullSync
    };

    Object.defineProperty(toReturn, 'enabled', {
      get: function () {
        return configuration.get().CalendarSettings.SyncGoogleCalendar;
      }
    });

    return toReturn;

    function syncGoogleCalendar(token) {
      var deferred = $q.defer();

      var syncURL = window.googleCalendarPath + '/google-register' + '?token=' + token + '&validate-url=' +
        getEasybizyEnviromentURL() + '&base-url=' + encodeURIComponent(getEasybizyEnvBaseUrl());
      var myPopup = window.open(syncURL
        , 'sync-google-calendar');

      var pollingForMessageInterval = setInterval(function () {
        myPopup.postMessage('polling', window.googleCalendarPath); //send the message and target URI
      }, 1000);

      window.addEventListener('message', messageReceived, false);
      return deferred.promise;

      function messageReceived(event) {
        if (event.origin !== window.googleCalendarPath) {
          return;
        }

        window.removeEventListener('message', messageReceived);
        myPopup.close();

        clearInterval(pollingForMessageInterval);

        if (!event.data) {
          deferred.reject({ message: 'no sync occured' });

          return toastr.error(localize.getLocalizedString("_ErrorAuthorizingGoogleAccount_"));
        }

        try {
          var result = JSON.parse(event.data);
          if (result.error) {
            throw ('error in authorizing');
          }

          toastr.success(localize.getLocalizedString('_SuccessfullyEstablishedSync_',
            localize.getLocalizedString(event.data.existingAccount ? '_ExistingCalendar_' : '_NewCalendar_')));

          selectCalendarToSyncWith(result.availableCalendars, result.existingAccount, token, result.employeeId);

          $timeout(function () {
            $rootScope.$emit('page-in-progress', false);
          }, 500);

          //deferred.resolve(event.data);

        } catch (err) {
          deferred.reject({ message: 'error in authorizing' });
        }
      }
    }

    function selectCalendarToSyncWith(calendars, existingCalendarId, token, employeeId) {
      var opts = {
        backdrop: 'static',
        keyboard: false,
        backdropClick: false,
        windowClass: 'google-sync-wizard-modal',
        template: require('../../modal-views/google-sync-wizard/google.sync.wizard.tpl.html'),
        controller: "GoogleCalendarSyncWizard",
        resolve: {
          existingCalendars: function () {
            return calendars;
          },
          saveAction: function () {
            return function (options, publisher) {
              options.token = token;
              performFullSync(options, publisher);
            };
          },
          deleteAction: function () {
            return function (options, publisher) {
              options.token = token;
              performFullDelete(options, publisher);
            }
          },
          alreadySyncedCalendar: function () {
            var existingCalendar = calendars.filter(function (calendar) {
              return calendar.id == existingCalendarId;
            });

            return existingCalendar.length > 0 ? existingCalendar[0] : null;
          },
          employee: function () {
            return employeeId ? Repository.Entity("Employee").query().id(employeeId).get() : function () {
              return -1;
            }
          }
        }
      };

      $modal.open(opts);
    }

    function performFullSync(metadata, publisher) {
      Repository.Custom("Calendar").performFullSync(metadata, publisher);
    }

    function performFullDelete(metadata, publisher) {
      Repository.Custom("Calendar").performFullDelete(metadata, publisher);
    }

    function getEasybizyEnviromentURL() {
      return encodeURIComponent(getEasybizyEnvBaseUrl() + 'api/GoogleCalendar/Validate');
    }

    function getEasybizyEnvBaseUrl() {
      return baseUrl === '../' ? (window.googleCalendarProxy || (document.location.origin + '/')) : baseUrl;
    }

  }]);
