import { getSafely, isNonEmptyObject } from "@tomeravni/easybizy-js-common/common";

(function () {
  angular.module('easybizy.cash.register').service('cashRegisterPlugins',
    [
      'Repository', '$rootScope', 'localize', 'confirmService', '$q',
      function (Repository, $rootScope, localize, confirmService, $q) {
        // Has to be initialized with new().
        var kDiscountByType = {
          GiftCard: { Level: 0 },
          CustomItem: { Level: 0 },
          Product: { Level: 0 },
          Service: { Level: 0 },
          Subscription: { Level: 0 }
        };

        this.discountPlugin = function (customer, scopeToWatch, itemsPropertyName) {
          var discountsMap = null;
          this.memberships = [];

          if (customer && customer.MembershipDiscounts && customer.MembershipDiscounts.length > 0) {
            // var maxDiscount,
            discountsMap = $.extend(true, {}, kDiscountByType);
            var expired = false;

            for (var discountIdx = 0; discountIdx < customer.MembershipDiscounts.length; discountIdx++) {
              var discount = customer.MembershipDiscounts[discountIdx];
              var currentExpired = moment(discount.ExpiresOn).isBefore(moment());

              if (currentExpired) {
                expired = true;
                if (!customer.preventToasts) {
                  toastr.warning(localize.getLocalizedString('_DiscountExpired_',
                    discount.MembershipDiscountMetadata.Name,
                    moment(discount.ExpiresOn).format('LL')));
                }
              } else if (discount.MembershipStatus === 'Active') {
                var currentMembershipPercentages = discount.MembershipDiscountMetadata.DiscountPrecents,
                  Id = discount.MembershipDiscountId,
                  Label = discount.MembershipDiscountMetadata.Name;
                this.memberships.push(Label);

                // Get all prevented.
                var prevented = [];
                Object.keys(discount.MembershipDiscountMetadata).filter(function (discountPropertyName) {
                  if (discountPropertyName.indexOf('Prevent') === 0 &&
                    discount.MembershipDiscountMetadata[discountPropertyName]) {
                    prevented.push(discountPropertyName.replace('Prevent', '').replace('Discount', ''));
                  }
                });

                Object.keys(discountsMap).forEach(function (discountItemType) {
                  if (prevented.indexOf(discountItemType) < 0 &&
                    discountsMap[discountItemType].Level < currentMembershipPercentages) {
                    discountsMap[discountItemType] = {
                      Level: currentMembershipPercentages,
                      Id: Id,
                      Label: Label
                    }
                  }
                })
              }
            }
          }

          this.discountsMap = discountsMap;

          if (customer && customer.PriceLists && customer.PriceLists.length > 0) {
            var priceListWatcher = scopeToWatch.$watchCollection(itemsPropertyName, updateItemsByPriceList.bind(this));

            // In case there are watched items.
            this.dispose = function () {
              priceListWatcher();
              cancelDiscount();
            };
          }


          this.dispose = function () {
            this.memberships = [];
          };

          // var _isValidForDiscountBinded = isValidForDiscount.bind(this);
          var _getDiscountForItem = getDiscountForItem.bind(this);

          if (this.discountsMap) {
            var watcher = scopeToWatch.$watchCollection(itemsPropertyName, updateItemsDiscount.bind(this));
            // In case there are watched items.
            this.dispose = function () {
              watcher();
              cancelDiscount();
              this.memberships = [];
            };

          }

          function updateItemsDiscount(items) {
            items.forEach(function (item) {
              var membershipDiscount = _getDiscountForItem(item);
              if (isNonEmptyObject(membershipDiscount)) {
                item.membershipDiscount = membershipDiscount;
                var updatedPrice = (item.OriginalRetailPrice * ((100 - membershipDiscount.Level) / 100)).toFixed(2);
                if (parseFloat(item.RetailPrice) === parseFloat(item.OriginalRetailPrice)) {
                  item.RetailPrice = updatedPrice;
                }
              }
            });
          }

          function cancelDiscount() {
            scopeToWatch[itemsPropertyName].forEach(function (item) {
              delete item.membershipDiscountedLevel;
              item.RetailPrice = item.OriginalRetailPrice;
            });
          }

          function getDiscountForItem(item) {
            if (item.preventMembershipDiscount ||
              ['PunchCardUsage', 'Debt', 'TaxInvoice', 'ExternalGiftCardUsage', 'GiftCardUsage'].includes(item.itemCustomType)) {
              return 0;
            }

            return (item.itemCustomType === 'GiftCard' && this.discountsMap['GiftCard']) ||
              (item.Id < 0 && this.discountsMap['CustomItem']) ||
              (item.ConcreteType === 'ProductMetadata' && this.discountsMap['Product']) ||
              (item.ConcreteType === 'ServiceMetadata' && this.discountsMap['Service']) ||
              (item.itemCustomType === 'PunchCard' && this.discountsMap['Subscription']) ||
              0;
          }


          function updateItemsByPriceList(items) {
            items.forEach(function (item) {
              if (item.PriceLists && item.PriceLists.length > 0) {
                item.PriceLists.forEach(function (priceListItem) {
                  const customerHasThisPriceList = customer.PriceLists.find(p => p.PriceListId === priceListItem.PriceListId);
                  if (customerHasThisPriceList && (!item.PriceListItemOriginalPrice || item.PriceListId !== customerHasThisPriceList.PriceListId)) {
                    item.RetailPrice = priceListItem.RetailPrice;
                    item.PriceListItemOriginalPrice = priceListItem.RetailPrice;
                    item.PriceListId = customerHasThisPriceList.PriceListId;
                    return true;
                  }
                });
              }
            });
          }
        }

        this.specialItemsPlugin = function (scopeToWatch, removeItemDelegate) {
          let totalWatch;
          const itemsWatcher = scopeToWatch.$watchCollection('itemsInCart', _itemsChanged.bind(this));
          const customerWatcher = scopeToWatch.$watch('customerInvoice', _cancelCustomItems.bind(this));

          this.dispose = function () {
            itemsWatcher();
            customerWatcher();
            if (totalWatch) {
              totalWatch();
            }
          };

          function _itemsChanged() {
            const pointsItem = scopeToWatch.pointsItem = getSpecialItems();
            if (pointsItem.length > 0) {
              if (!totalWatch) {
                totalWatch = scopeToWatch.$watch('totalAggregator.subTotal', _updateSpecialItems.bind(this));
              }
            } else if (totalWatch) {
              totalWatch();
              totalWatch = null;
              updateSpecialItemsAvailability([]);
            }

          }

          function _updateSpecialItems() {
            const specialItems = scopeToWatch.pointsItem = getSpecialItems();
            let currentSubTotal = scopeToWatch.totalAggregator.subTotal;
            if (specialItems.length > 0) {
              if (currentSubTotal < 0) {
                for (let itemIdx = 0; itemIdx < specialItems.length; itemIdx++) {
                  const currentItem = specialItems[itemIdx];
                  const updatedPrice = currentSubTotal + Math.abs(currentItem.RetailPrice);
                  if (updatedPrice > 0) {
                    updateItemPrice(currentItem, -updatedPrice);
                  } else {
                    currentItem.RetailPrice = 0;
                    removeItemDelegate(currentItem);
                  }
                }
              }

            }

            updateSpecialItemsAvailability(specialItems);
          }

          function _cancelCustomItems(newCustomer, oldCustomer) {
            const specialItems = getSpecialItems();
            if (scopeToWatch.availablePoints && newCustomer && (getSafely(['CustomerId'], newCustomer) === getSafely(['CustomerId'], oldCustomer))) {
              const usedPoints = specialItems.filter((item) => item.itemCustomType === 'PointsUsage').reduce((acc, item) => acc + Math.abs(item.RetailPrice), 0)
              scopeToWatch.availablePoints.value = Math.max(0, (parseFloat(newCustomer.Points) || 0) - usedPoints);
              return;
            }

            /// No need to cancel custom items if no previous customer was there.
            if (isNonEmptyObject(oldCustomer)) {
              specialItems.forEach(removeItemDelegate);
            }

            updateSpecialItemsAvailability(specialItems);

          }

          function getSpecialItems() {
            const specialItems = [];
            let pointsUsageItem;
            for (let itemIdx = 0; itemIdx < scopeToWatch.itemsInCart.length; itemIdx++) {
              var item = scopeToWatch.itemsInCart[itemIdx];
              if (item.itemCustomType === 'GiftCardUsage') {
                specialItems.push(item);
              } else if (item.itemCustomType === 'PointsUsage') {
                if (!pointsUsageItem) {
                  pointsUsageItem = item;
                  specialItems.push(item);
                } else {
                  updateItemPrice(pointsUsageItem, parseFloat(pointsUsageItem.RetailPrice) + item.RetailPrice);
                  removeItemDelegate(item);
                }
              }
            }

            return specialItems;
          }

          function updateItemPrice(pointsItem, newPrice) {
            pointsItem.RetailPrice = pointsItem.OriginalRetailPrice = newPrice;
          }

          function updateSpecialItemsAvailability(specialItems) {
            if (specialItems.length > 0) {
              specialItems.forEach(function (specialItem) {
                if (specialItem.itemCustomType === 'PointsUsage') {
                  scopeToWatch.availablePoints.value = parseFloat(scopeToWatch.customerInvoice.Points) -
                    (Math.abs(specialItem.RetailPrice));
                }
              })
            } else if (scopeToWatch.customerInvoice && scopeToWatch.customerInvoice.Points) {
              scopeToWatch.availablePoints.value = parseFloat(scopeToWatch.customerInvoice.Points);
            } else {
              scopeToWatch.availablePoints.value = 0;
            }

          }

        }

        this.subscriptionTemplatePlugin = function (scopeToWatch, itemsPropertyName) {
          let subscriptionTemplates;
          let subscriptionTemplateDidLoad = false;
          const promises = [];

          let watcher = scopeToWatch.$watchCollection(itemsPropertyName,
            (newValue) => {
              if (!subscriptionTemplateDidLoad && newValue.length > 0 && newValue.some((item) => item.Id > 0)) {
                Repository.Entity('SubscriptionTemplate')
                  .query()
                  .select('ItemMetadataId,ItemMetadataType,Name,GivenQuantity,PurchasedQuantity,Title,TotalPrice')
                  .get()
                  .then(({ results }) => {
                    subscriptionTemplates = results.reduce((acc, curr) => {
                        const id = `${ curr.ItemMetadataType.charAt(0) }${ curr.ItemMetadataId }`;
                        return ({
                          ...acc,
                          [id]: (acc[id] || []).concat(curr)
                        })
                      },
                      {});

                    subscriptionTemplateDidLoad = true;
                    promises.forEach((promise) => promise.resolve(subscriptionTemplates));
                    promises.length = 0;
                  })

              }
            });

          this.dispose = () => {
            if (watcher) {
              watcher();
              watcher = null;
              promises.length = 0;
            }
          };

          this.getTemplates = () => {
            const deferred = $q.defer();
            if (!subscriptionTemplateDidLoad) {
              promises.push(deferred);
            } else {
              deferred.resolve(subscriptionTemplates);
            }

            return deferred.promise;
          }

        }

        /**
         * Generate a unique id based on timestamp.
         * @returns {string}
         */
        this.randomUniqueId = () => (Date.now().toString()).substring(4);
      }
    ]);

}());
