import {DATE_DISPLAY_FORMAT, PATH_TO_API, SERVER_DATA_DATE_FORMAT} from '../../constants';

angular.module('easybizy.common.common.resources', [])

  .factory('holidaysCalendar', ['Repository', 'localize', '$q', function (Repository, localize, $q) {
    var k_holidaysStorageKey = "Jewish_Holiday";
    var isHebrew = localize.isRightToLeft();

    return {
      get: getHolidays
    };

    function getHolidays() {
      var resolved = false;
      var deferred = $q.defer();
      Repository.Custom("Calendar").getHolidays()
        .then(function (holidays) {
          var toSave = {};
          angular.forEach(holidays[0], function (holiday) {
            var dateAsKey = moment(holiday.Key, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT);
            toSave[dateAsKey] = {
              label: ((toSave[dateAsKey] && (toSave[dateAsKey].label + ', ')) || '') +
              (isHebrew || !holiday.Value.English) ? holiday.Value.Hebrew : holiday.Value.English
            };
          });

          $.localStorage.set(k_holidaysStorageKey, toSave);
          if (!resolved) {
            deferred.resolve(toSave);
          }

        }).catch(function (err) {
        deferred.reject(err);
      });

      if ($.localStorage.isSet(k_holidaysStorageKey)) {
        resolved = true;
        deferred.resolve($.localStorage.get(k_holidaysStorageKey));
      }

      return deferred.promise;
    }
  }])
  .factory('configurationTemplate', ['$timeout', 'localize', '$http', '$q', function ($timeout, localize, $http, $q) {
    return {
      getAsync: getAsync
    };

    function getAsync() {
      const deferred = $q.defer();

      $http({
        url: baseUrl + PATH_TO_API + "Configuration/Body",
        method: "GET"
      }).then(function ({data}) {
        deferred.resolve(data);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }
  }])
  .factory('webSocketsBus', ['$timeout', 'localize', '$transitions', '$modal', 'notificationCenterBus', '$rootScope', function ($timeout, localize, $transitions, $modal, notificationCenterBus, $rootScope) {
    var currentView = null;
    var currentViewScope = null;


    $transitions.onStart({}, function () {
      currentView = null;
      currentViewScope = null;
    });

    $transitions.onSuccess({}, function (trans) {
      currentView = trans.router.stateService.current.title;
    });

    return {
      messageReceived: messageReceived,
      registerView: registerView
    };

    function messageReceived(message) {
      if (message.MessageType === "SocketTextMessage") {
        toastr.success(message.Content);
      } else if (message.MessageType.indexOf("EntityCreatedSocketMessage") > -1) {
        var concreteEntity = message.Content.Concrete;
        concreteEntity.EntityType = message.Content.EntityType;
        var viewAsEntityType = "_" + window.owl.pluralize(concreteEntity.EntityType) + "_";
        if (currentView === viewAsEntityType) {
          currentViewScope.$evalAsync(() => {
            currentViewScope.addNewEntity(concreteEntity);
          });
        }
      } else if (message.MessageType.indexOf("CallerIdSocketMessage") > -1) {
        var options = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../modal-views/caller-id/caller.id.tpl.html'),
          controller: 'CallerIdController',
          resolve: {
            customer: function () {
              return message.Content[0];
            }
          },
          closeFn: function () {
            console.log("closing");
          }
        };

        $modal.open(options);
        notificationCenterBus.loadNotifications();

      } else if (message.MessageType === 'SocketMeetingMessage') {
        $rootScope.$broadcast('meetingCRUDEvent', message);
      } else if (message.MessageType === 'CallHandledSocketMessage') {
        notificationCenterBus.loadNotifications();
      }
    }

    function registerView(viewScope) {
      currentViewScope = viewScope;
    }
  }])
  .factory('notificationCenterBus', ['$timeout', 'localize', 'Repository', function ($timeout, localize, Repository) {
    const statusDelegates = [];
    let lastLoadedNotifications = null;

    return {
      registerStatus: registerStatus,
      unregisterStatus: unregisterStatus,
      statusChanged: statusChanged,
      loadNotifications: loadNotifications
    };

    function registerStatus(delegate) {
      statusDelegates.push(delegate);
    }

    function unregisterStatus(delegate) {
      statusDelegates.remove(delegate, true);
    }

    function statusChanged(hasNewMessages, count) {
      if (statusDelegates.length > 0) {
        statusDelegates.forEach((delegate) => delegate(hasNewMessages, count, lastLoadedNotifications));
      }
    }

    function loadNotifications() {
      return Repository.Entity('IncomingCall', true).query().top(10).filter("Status eq 'Unhandled'").expand('Customer').orderBy('CreatedOn', true).get()
        .then(function (data) {
          $timeout(function () {
            statusChanged(data.results.length > 0, data.results.length);
          });

          data.results.forEach(function (notification) {
            notification.Title = (notification.Customer && (notification.Customer.FirstName + ' ' + notification.Customer.LastName)) || (notification.FromNumber ? notification.FromNumber.splitPhone() : '');
            notification.Description = moment(notification.CreatedOn).format('LLL')
            notification.ConcreteType = notification.EntityType;
            notification.Id = notification.CustomerId;

            if (notification.Customer && (notification.Customer.DefaultImagePath || (notification.Customer.Images && notification.Customer.Images.length > 0))) {
              var images = notification.Customer.Images;
              if (notification.Customer.DefaultImagePath) {
                notification.Image = notification.Customer.DefaultImagePath;
              } else if (notification.Customer.DefaultImage) {
                var imageIndex = images.indexOfById({ImageId: notification.Customer.DefaultImage}, 'ImageId');
                notification.Image = images[imageIndex].FilePath;
              } else {
                notification.Image = images[0].FilePath;
              }
            }
          });

          lastLoadedNotifications = data.results;
          return data.results;
        }).catch(function (err) {
          return err;
          // localize.getLocalizedString('_GeneralError_', err.Message);

        });
    }
  }])
  .factory('versionService', ['Repository', function (Repository) {

  }]);


