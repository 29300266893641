import './add.subscription.less'

angular.module('easybizy.easybizyModalViewsModel').controller('AddSubscriptionController',
  function ($scope, $modalInstance, saveAction, parameters, $timeout, localize, Repository, configuration) {
    var memberShipDiscountValidDefaults = {
      PreventServiceDiscount: false,
      PreventProductDiscount: false,
      PreventSubscriptionDiscount: false,
      PreventCustomItemDiscount: false,
      PreventGiftCardDiscount: false,
    };

    /********************* Subscription Types *********************/

    $scope.subscriptionTypes = [];
    $scope.allowChangingAllMembers = {};
    $scope.allowChangingAllMembers.allowed = false;

    Repository.Entity("MembershipDiscountMetadata").query().get()
      .then(function (data) {
        data.results.forEach(function (membership) {
          var membershipDiscountValidFor = mapDiscountValidFor(Object.keys(membership).filter(function (key) {
            return key.indexOf('Prevent') === 0;
          }), membership);


          $scope.subscriptionTypes.push(
            {
              name: membership.Name,
              value: {
                id: membership.MembershipDiscountMetadataId, discount: membership.DiscountPrecents,
                months: membership.DurationInMonths, validFor: membershipDiscountValidFor
              }
            });
        });
        $scope.refreshAfterLoadingMemberships();
        // $scope.$digest();
      })
      .catch(function () {
        toastr.error(localize.getLocalizedString("_ProblemLoadingMembershipMetadatas_"));
      });

    $scope.refreshAfterLoadingMemberships = function () {
      $scope.selectedSubscriptionTypeWrapper.selected = $scope.subscriptionTypes[0];
      $scope.subscriptionLengthWrapper.selected = $scope.selectedSubscriptionTypeWrapper.selected ?
        $scope.subscriptionLength.filter(x => $scope.selectedSubscriptionTypeWrapper.selected.value.months === x.value)[0] : $scope.subscriptionLength[0];
      $scope.discountLevelWrapper.value = $scope.selectedSubscriptionTypeWrapper.selected ? $scope.selectedSubscriptionTypeWrapper.selected.value.discount : 5;
    };


    $scope.selectedSubscriptionTypeWrapper = {};

    $scope.subscriptionSelected = function (subscription) {
      if (subscription.value < 0) {
        subscription.value = {
          id: -(Math.floor(Math.random() * 10000)) - 3,
          months: $scope.subscriptionLength[2].value,
          discount: 1,
          validFor: mapDiscountValidFor(Object.keys(memberShipDiscountValidDefaults), memberShipDiscountValidDefaults)
        }
      }
      // console.log(subscription);
      // if (!subscription.value.validFor) {
      //   console.log($scope.subscriptionTypes);
      // }

      if (angular.isObject(subscription.value)) {
        $scope.discountLevelWrapper.value = subscription.value.discount;
        $scope.subscriptionLengthWrapper.selected = $scope.subscriptionLength.filter(x => $scope.selectedSubscriptionTypeWrapper.selected.value.months === x.value);
      } else {
        $scope.discountLevelWrapper.value = 1;
        $scope.subscriptionLengthWrapper.selected = $scope.subscriptionLength[2];
      }
    };

    $scope.newlyCreatedSubscription = [];
    $scope.createNewSubscription = function (category) {
      // console.log(category);
      $scope.newlyCreatedSubscription.push({
        name: category.name,
        value: -(Math.floor(Math.random() * 10000)) - 3
      });
    };

    /********************* Membership length *********************/
    $scope.subscriptionLength = [
      { name: localize.getLocalizedString("_NoTimeLimit_"), value: -1 },
      { name: localize.getLocalizedString("_3Months_"), value: 3 },
      { name: localize.getLocalizedString("_6Months_"), value: 6 },
      { name: localize.getLocalizedString("_YearlyFuture_"), value: 12 },
      { name: localize.getLocalizedString("_DualYearlyFuture_"), value: 24 },
    ];

    $scope.subscriptionLengthWrapper = {};

    /********************* Discount Levels *********************/
    $scope.discountLevelWrapper = {};

    $scope.discountLevelField = {
      fieldName: 'value',
      model: $scope.discountLevelWrapper,
      type: "integer",
      min: 0,
      max: 100,
      delta: 1
    };

    $scope.$watchGroup(['discountLevelWrapper.value', 'subscriptionLengthWrapper.selected.value'],
      verifyWarningMessage);


    Object.defineProperty($scope, 'permissionFlags', {
      get: function () {
        return $scope.selectedSubscriptionTypeWrapper.selected ? getPermissionsBinaryValue($scope.selectedSubscriptionTypeWrapper.selected.value.validFor) : 0;
      }
    });

    function getPermissionsBinaryValue(permissions) {
      return '_' + permissions.map(function (permission) {
        return !!permission.value ? 1 : 0;
      }).join('');
    }

    $scope.$watch('permissionFlags', function (newVal, oldVal) {
      if (!$scope.selectedSubscriptionTypeWrapper.selected) {
        return;
      }

      var membershipId = getMembershipId();
      if (newVal && oldVal && newVal != oldVal && membershipId == $scope.lastId) {
        verifyWarningMessage(true);
      } else {
        $scope.lastId = membershipId;
      }
    });

    $scope.cancel = function () {
      $modalInstance.dismiss();
    };

    function verifyWarningMessage(permissionFlagsChanged) {
      var dontShowWarning = true;
      if ($scope.selectedSubscriptionTypeWrapper.selected && angular.isObject($scope.selectedSubscriptionTypeWrapper.selected.value)) {
        var dontShowWarning = !($scope.selectedSubscriptionTypeWrapper.selected.value.discount > 0 &&
          $scope.discountLevelWrapper.value != $scope.selectedSubscriptionTypeWrapper.selected.value.discount);

        dontShowWarning = dontShowWarning
          && $scope.subscriptionLengthWrapper.selected.value ==
          $scope.selectedSubscriptionTypeWrapper.selected.value.months;

        dontShowWarning = dontShowWarning && !(permissionFlagsChanged === true);
      }

      $timeout(function () {
        $scope.showWarning = !dontShowWarning;
      });
    }

    function getMembershipId() {
      return $scope.selectedSubscriptionTypeWrapper.selected.value && angular.isObject($scope.selectedSubscriptionTypeWrapper.selected.value)
        ? $scope.selectedSubscriptionTypeWrapper.selected.value.id
        : $scope.selectedSubscriptionTypeWrapper.selected.value;
    }

    $scope.save = function () {
      var membership = {
        MembershipDiscountMetadataId: getMembershipId(),
        CustomerId: parameters.CustomerId
      };

      $scope.isSaving = true;
      console.log($scope.allowChangingAllMembers);
      var action = "assignToCustomer";
      if (membership.MembershipDiscountMetadataId < 0 || (membership.MembershipDiscountMetadataId > 0 && $scope.allowChangingAllMembers.allowed && $scope.showWarning)) {
        membership.MembershipLengthInMonths = $scope.subscriptionLengthWrapper.selected.value;
        membership.Name = $scope.selectedSubscriptionTypeWrapper.selected.name;
        membership.Discount = $scope.discountLevelWrapper.value;
        action = "createOrUpdate";
      }

      $scope.selectedSubscriptionTypeWrapper.selected.value.validFor.forEach(function (membershipPermission) {
        membership[membershipPermission.key] = !membershipPermission.value;
      });

      Repository.Custom("Membership")[action](membership)
        .then(function (result) {
          result.MembershipStatus = 'Active';
          $scope.isSaving = false;
          saveAction(result, $scope.cancel);
          // $scope.$digestIfNeeded();
        })
        .catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorSavingSubscription_", err.message));
          $scope.isSaving = false;
          // $scope.$digestIfNeeded();
        });
    };

    function mapDiscountValidFor(props, obj) {
      return props.map(function (key) {
        return {
          key: key,
          name: localize.getLocalizedString('_' + key + '_'),
          value: !obj[key]
        };
      });
    }
  });
