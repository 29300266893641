import './conversations.less'
import {isNonEmptyString, resolveCondition, valueOrDefault} from "@tomeravni/easybizy-js-common/common";

(function () {
  angular.module('easybizy.conversations')
    .controller('ConversationsController',
      function ($scope, $rootScope, $state, stateManager, $timeout, toolbar) {

        window.addEventListener('message', (event) => {
          if (event.data.source === 'conversations') {
            $scope.$apply(() => {
              switch (event.data.event) {
                case 'conversations-loaded':
                  $scope.isLoading = false
                  break;
                case 'route-change':
                  let data = valueOrDefault(event.data?.data, {});
                  const id = data.conversationId;
                  const filter = data.filter
                  stateManager.setState('Conversations', {
                    id,
                    filter
                  }, false, false);
              }
            })
          }
        })

        const {id, filter} = stateManager.getValidRawParams();
        $timeout(() => {
          if ($scope.isLoading !== false) {
            $scope.isLoading = true;
          }
        }, 500)

        const baseConversationURI = `${window.chatURI}/lobby`;
        const conversationIdQueryParam = resolveCondition(isNonEmptyString(id), `?conversation=${id}`, '');
        const filterPrefix = resolveCondition(isNonEmptyString(conversationIdQueryParam), '&', '?');
        const filterQueryParam = resolveCondition(isNonEmptyString(filter), `${filterPrefix}filter=${filter}`, '');
        $scope.conversationsURI = `${baseConversationURI}${conversationIdQueryParam}${filterQueryParam}`;
        toolbar.set([]);
      });
}());
