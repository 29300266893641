<template>
  <div class="whatsapp-on-boarding-wizard__promotion-editor-content relative">
    <div class="text-center whatsapp-on-boarding-wizard__promotion-editor-header">
      <h1 @click="revert"><span class="cursor-pointer icon icon-arrow-rightpaint-bucket ms-2 text-xl"></span>{{ reminderContent.title }}</h1>
    </div>
    <div class="whatsapp-on-boarding-wizard__promotion-editor-wrapper flex h-full">
      <div class="grow flex">
        <div class="flex flex-col grow">
          <div class="flex grow whatsapp-on-boarding-wizard__promotion-editor-wrapper-parts">
            <div class="phase--active">
              <h3>{{ reminderContent.reminderTimeTitle }}</h3>
              <Select :options="reminderTimeOptions"
                      :force-selection="true"
                      class="whatsapp-on-boarding-wizard__promotion-editor-template-dynamic-combobox"
                      v-model="reminderTime"></Select>
              <h3>{{ reminderContent.whatTimeTitle }}</h3>
              <Select :options="reminderHourOptions"
                      :force-selection="true"
                      class="whatsapp-on-boarding-wizard__promotion-editor-template-dynamic-combobox"
                      v-model="reminderHour"></Select>
              <p class="mt-2 text-sm">* {{ firstCampaignStartTimeString }}</p>
              <div v-if="phase === 1">
                <button class="whatsapp-on-boarding-wizard__promotion-editor-next" @click="nextPhase">{{ nextText }}
                </button>
              </div>
            </div>
            <div class="whatsapp-on-boarding-wizard__promotion-editor-templates" :class="phase > 1 && 'phase--active'">
              <h3>{{ reminderContent.templateTitle }}</h3>
              <div class="flex flex-col whatsapp-on-boarding-wizard__promotion-editor-templates-picker">
                <button v-for="template in templates"
                        :class="template.value === selectedTemplate.value ? 'whatsapp-on-boarding-wizard__promotion-editor-template--selected' : ''"
                        @click="selectTemplate(template)"
                        class="whatsapp-on-boarding-wizard__promotion-editor-template">
                  {{ template.name }}
                </button>
              </div>
              <div v-if="phase === 2">
                <button class="whatsapp-on-boarding-wizard__promotion-editor-next" @click="nextPhase">{{
                    nextText
                  }}
                </button>
              </div>
            </div>
            <div :class="phase > 2 && 'phase--active'">
              <h3>{{ reminderContent.templateDynamicTitle }}</h3>
              <p class="mt-2 text-sm">* {{ reminderContent.templateDynamicSubTitle }}</p>
              <textarea v-model="lastTemplateValue"
                        @blur="removeNewLines"
                        class="whatsapp-on-boarding-wizard__promotion-editor-template-dynamic"
                        :placeholder="reminderContent.templateDynamicTitle"></textarea>
              <div class="mt-4" v-if="rules.formIfRequired">
                <Checkbox v-model="formIfRequired" :text="reminderContent.formIfRequired"></Checkbox>
              </div>
              <div class="mt-4" v-if="rules.allowOnlineBooking">
                <Checkbox v-model="allowOnlineBooking" :text="reminderContent.allowOnlineBooking"></Checkbox>
              </div>
            </div>
          </div>
          <div class="grow justify-center flex">
            <div class="flex justify-center align-start">
              <transition name="slide-bottom">
                <div v-if="phase > 2" class="flex flex-col">
                  <button class="whatsapp-on-boarding-wizard__send-promotion-button background--primary"
                          @click="confirmCampaignAndSend">{{ saveCampaignText }}
                  </button>
                  <a class="text-center mt-1 underline text-sm" target="_blank"
                     href="https://auth.easybizy.net/terms-of-use#whatsapp">{{ $t("_AccordingToTermsOfUse_") }}</a>
                </div>
              </transition>
            </div>

          </div>
        </div>
      </div>
      <div class="h-full">
        <whatsapp-message :message="adaptedTemplate"/>
      </div>
    </div>
  </div>
</template>
<script>
import WhatsappMessage from "./whatsapp-message.vue";
import {getSafely, isNonEmptyObject, isNonEmptyString, valueOrDefault} from "@tomeravni/easybizy-js-common/common";
import {Select, Checkbox} from "@tomeravni/easybizy-vue-components";
import Moment from "moment";
import {dateWithTime} from "@tomeravni/easybizy-js-common/date";
import {ODATA_DATE_TIME_FORMAT} from "../../../constants";

export default {
  components: {WhatsappMessage, Select, Checkbox},
  props: ['reminderContent', 'templates', 'selectedTemplate', 'forms', 'rules', 'existingPromotion'],
  emits: ['revert', 'select-template', 'create-promotion'],
  data() {
    const reminderHourOptions = [];
    const MINUTES_INTERVAL = 15;
    const padTime = (hour, minute) => `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
    for (let hour = 8; hour < 23; hour++) {
      for (let minute = 0; minute < 60; minute += MINUTES_INTERVAL) {
        const asTime = padTime(hour, minute)
        reminderHourOptions.push({
          value: asTime,
          text: asTime
        })
      }
    }

    let reminderHour = reminderHourOptions[8].value;
    const existingCampaignStartTime = getSafely(['PromotionSchedule', 'PromotionStartTime'], this.existingPromotion);
    if (isNonEmptyString(existingCampaignStartTime)) {
      let asDate = Moment(existingCampaignStartTime);
      if (asDate.isValid()) {
        const adaptedMinutes = Math.floor(asDate.minutes() / MINUTES_INTERVAL) * MINUTES_INTERVAL;
        const adaptedTime = padTime(asDate.hours(), adaptedMinutes);
        if (reminderHourOptions.some((x) => x.value === adaptedTime)) {
          reminderHour = adaptedTime;
        }
      }
    }

    const meetingsFilter = this.dataFilterService.get('MeetingTimingOption');
    const reminderTimeOptions = meetingsFilter.options.filter((x) => x.availableAsAutoGeneratedPromotion).map((x) => ({
      value: x.value,
      text: x.nameRelativeToMeeting,
      other: x
    }));

    const existingCampaignValue = getSafely(['Filter', 'ClientPromotionFilters', 0, 'Value'], this.existingPromotion);
    let reminderTime = reminderTimeOptions[1].value;

    if (isNonEmptyString(existingCampaignValue)) {
      if (reminderTimeOptions.some((x) => x.value === existingCampaignValue)) {
        reminderTime = existingCampaignValue;
      }
    }

    return {
      adaptedTemplate: null,
      phase: 1,
      lastTemplateValue: '',
      reminderTime,
      reminderTimeOptions,
      reminderHour,
      reminderHourOptions,
      formIfRequired: this.rules.formIfRequired,
      formIfRequiredExtraText: '',
      allowOnlineBooking: false,
      allowOnlineBookingExtraText: '',
      saveCampaignText: this.$t('_SaveCampaign_'),
      nextText: this.$t('_Next_'),
    }
  },
  mounted() {
    this.adaptFormIfRequired()
    this.adaptAllowOnlineBooking()
    this.adaptTemplateMessage(true)
  },
  watch: {
    selectedTemplate() {
      this.adaptTemplateMessage(true)
    },
    lastTemplateValue(newVal) {
      this.adaptTemplateMessage()
    },
    formIfRequired() {
      this.adaptFormIfRequired()
    },
    allowOnlineBooking() {
      this.adaptAllowOnlineBooking()
    }
  },
  computed: {
    firstCampaignStartTime() {
      let startTime = this.reminderTimeOptions.find((x) => x.value === this.reminderTime).other.relativeStartTime;
      startTime = dateWithTime(startTime, Moment(this.reminderHour, 'HH:mm').toDate())
      if (Moment().isAfter(startTime)) {
        startTime = Moment(startTime).add(1, 'day').toDate();
      }

      return startTime;

    },
    firstCampaignStartTimeString() {
      let repeat = this.reminderTimeOptions.find((x) => x.value === this.reminderTime).other.possibleRepeat;
      return `${this.$t('_FirstCampaignStartTime_')}: ${Moment(this.firstCampaignStartTime).format('DD/MM/YYYY HH:mm')}, ${this.$t('_AndWillRepeat_')} ${this.$t(`_${repeat}_`)}`
    }
  },
  methods: {
    removeNewLines() {
      this.lastTemplateValue = this.lastTemplateValue.replace(/\n/g, ' ');
    },
    adaptFormIfRequired() {
      if (this.formIfRequired) {
        this.formIfRequiredExtraText = `${this.reminderContent.formIfRequiredText}: https://forms.easybizy.net/aabbcc112233\n\n`;
      } else {
        this.formIfRequiredExtraText = ''
      }

      this.adaptTemplateMessage()
    },
    adaptAllowOnlineBooking() {
      if (this.allowOnlineBooking) {
        this.allowOnlineBookingExtraText = `${this.reminderContent.allowOnlineBookingText}: https://schedule.easybizy.net/999888bbbaaa\n\n`;
      } else {
        this.allowOnlineBookingExtraText = ''
      }

      this.adaptTemplateMessage()
    },
    revert() {
      this.$emit('revert')
    },
    selectTemplate(template) {
      this.$emit('select-template', template)
    },
    getSelectedTemplateComponents() {
      if (!this.selectedTemplate) {
        return;
      }

      return JSON.parse(this.selectedTemplate.template.components);
    },
    getSelectedPromotionBody() {
      if (!this.selectedTemplate) {
        return;
      }

      return this.getSelectedTemplateComponents().find(c => c.type === 'BODY');
    },
    adaptTemplateMessage(forceLastMessage) {
      if (!this.selectedTemplate) {
        return;
      }
      const components = this.getSelectedTemplateComponents();
      const header = getSafely(['text'], components.find(c => c.type === 'HEADER'));
      const body = components.find(c => c.type === 'BODY');
      const rawText = getSafely(['text'], body);
      /// Remove last params.
      const businessTitle = this.configurations.BusinessDetails.BusinessName.Value + ' - ' + this.configurations.BusinessDetails.BusinessPhoneNumber.Value;
      this.lastTemplateValue = this.lastTemplateValue ? this.lastTemplateValue : (forceLastMessage ? businessTitle : '');
      let dynamicLastParam = valueOrDefault(this.formIfRequiredExtraText, '') +
        valueOrDefault(this.allowOnlineBookingExtraText, '') +
        this.lastTemplateValue;
      const dynamicValues = body.examples.slice(0, -1).concat(dynamicLastParam);
      const buttonsCMP = getSafely(['buttons'], components.find(c => c.type === 'BUTTONS'), []);
      const buttons = buttonsCMP.map((btn) => btn.text);

      this.adaptedTemplate = {
        header,
        rawText,
        dynamicValues,
        buttons
      };
    },
    confirmCampaignAndSend() {
      this.confirmService.confirm(
        this.reminderContent.confirm.title,
        this.reminderContent.confirm.description,
        /*onOk*/() => {
          this.saveCampaign()

        },
        /*onCancel*/() => {
        },
      );
    },
    saveCampaign() {
      let reminderOption = this.reminderTimeOptions.find((x) => x.value === this.reminderTime);
      const reminderTimeText = reminderOption.text;

      const name = `${this.reminderContent.promotionBaseName}, ${reminderTimeText} - ${this.reminderHour}`;

      const {text: messageText, paramDefaults} = this.getSelectedPromotionBody();

      const dynamicParams = paramDefaults.slice(0, -1)
      const formIfRequiredFieldName = this.promotionTemplateResolver.getFieldByName('FormIfNeeded').value
      const onlineBookingFieldName = this.promotionTemplateResolver.getFieldByName('OnlineBookingLink').value

      let adaptedLastParam =
        (this.formIfRequired ? `${formIfRequiredFieldName}` : '') +
        (this.allowOnlineBooking ? `${this.formIfRequired ? ', ' : ''}${this.$t('_ForChanges_')}: ${onlineBookingFieldName}` : '');

      adaptedLastParam += (isNonEmptyString(this.lastTemplateValue) ? ((isNonEmptyString(adaptedLastParam) ? ', ' : '') + this.lastTemplateValue.replace(/\n/g, ', ')) : '');
      const campaign = {
        Repeat: reminderOption.other.possibleRepeat,
        PromotionName: name,
        PresetName: "MeetingRemainder",
        IsServiceEngagementType: true,
        IsForAllCustomers: false,
        Filters: [
          {
            FilterType: "Meeting",
            FilterComperator: "Equals",
            Value: reminderOption.value
          }
        ],
        ClientPromotionContent: {
          WhatsappTemplateName: this.selectedTemplate.value,
          PromotionSenderType: "whatsapp",
          Message: messageText,
          DynamicValues: dynamicParams.concat([adaptedLastParam.trim()]),
          IsGenericWhatsApp: true
        },
        StartTime: Moment(this.firstCampaignStartTime).format(ODATA_DATE_TIME_FORMAT),
      }

      if (isNonEmptyObject(this.existingPromotion)) {
        this.confirmService.confirm(
          this.reminderContent.existingSMSCampaign.title,
          this.reminderContent.existingSMSCampaign.description,
          /*onOk*/() => {
            this.$emit('create-promotion', campaign, true)
          },
          () => {
            this.$emit('create-promotion', campaign, false)
          },
        );
      } else {
        this.$emit('create-promotion', campaign)
      }
    },
    nextPhase() {
      this.phase += 1;
    }
  }
}
</script>

<style lang="less">
@import "../../../../styles/variables";
@import "vars";

.whatsapp-on-boarding-wizard__promotion-editor-content {
  height: 80%;
  margin-top: 85px;

  .whatsapp-message-container {
    border-radius: 15px 0 0 15px;
  }

}

.whatsapp-on-boarding-wizard__send-promotion-button {
  animation: pulseIt 2s infinite;
  width: 300px;
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
  border: 2px solid @primary-color;
}

.whatsapp-on-boarding-wizard__promotion-editor {
  &-header {
    margin-top: -50px;
  }

  &-templates {
    &-picker {
      row-gap: @space-between-tile / 2;
    }
  }

  &-next {
    animation: pulseIt 2s infinite;
    margin-top: 25px;
    border-radius: 5px;
    padding: 5px 25px;
    width: 80%;
    font-size: 20px;
    border: 2px solid @primary-color;
    color: @primary-color;
  }

  &-template {
    background: white;
    padding: @space-between-tile;
    border-radius: 15px;
    width: @tile-width / 2;
    box-shadow: 0 2px 5px 0 rgba(164, 173, 186, 0.5);
    border: 2px solid transparent;


    &--selected {
      border: 2px solid @primary-color;
    }
  }
}


.whatsapp-on-boarding-wizard__promotion-editor-wrapper {
  display: flex;
  justify-content: center;

  &-parts {
    column-gap: @space-between-tile / 2;
    padding: @space-between-tile;

    > div {
      flex-basis: 0;
      flex-grow: 1;
      transition: opacity 0.5s ease-in-out;

      &:not(.phase--active) {
        opacity: 0.1;
      }
    }
  }

  textarea {
    background-color: white;
    width: 100%;
    min-height: 200px;
    resize: none;
    line-height: 25px;
    font-size: 1.2em;
    padding: 5px 5px;
    border: 1px solid @tile-icon-color;
  }
}
</style>
