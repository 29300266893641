import './add-meeting.less';
import './manual-repeating/manual.repeating.directive';
import {
  DATE_DISPLAY_FORMAT,
  HOURS_AND_MINUTES_FORMAT,
  ODATA_DATE_TIME_FORMAT,
} from "../../constants";
import {DelayedFunction} from "../../general/helpers";
import {getSafely} from "@tomeravni/easybizy-js-common/common";

angular.module('easybizy.easybizyModalViewsModel').controller('AddMeetingModalController',
  function (
    $scope, $modalInstance, $timeout, $http, Repository, localize,
    calendarMetadata, calendarColumns, saveAction, parameters, configuration, iCalService, contextManager, colorsService, confirmService
  ) {
    $scope.meeting = {};
    $scope.meeting.services = [];
    $scope.selectedCustomerWrapper = {};
    $scope.isRightToLeft = localize.isRightToLeft();
    var configurations = configuration.get();
    $scope.listMode = configurations.CalendarSettings.AvoidImagesOnAddMeetingDialog;
    $scope.editMode = false;
    $scope.isLoading = false;
    $scope.dialogTitle = localize.getLocalizedString("_AddNewMeeting_");
    $scope.saveButtonTitle = localize.getLocalizedString("_Save_");
    $scope.cancelButtonTitle = localize.getLocalizedString("_Cancel_");
    $scope.servicesByCategories = configurations.CalendarSettings.GetServicesByCategories;
    $scope.includeMeetingLocation = configurations.CalendarSettings.IncludeMeetingLocation;
    $scope.allowGenderSelection = configurations.CalendarSettings.AllowGenderSelection;
    $scope.usingExternalVouchers = configurations.ProductsAndServicesSettings.UseExternalVoucherModel && !configurations.ProductsAndServicesSettings.HideExternalVoucherOnCalendar;
    $scope.useEmployeeCreatedMeeting = configurations.EmployeesSettings.UseEmployeeCreatedMeeting;

    $scope.services = [
      {name: localize.getLocalizedString("_SelectServices_"), value: null, defaultValue: true}
    ];

    $scope.genderOptions = [
      {name: localize.getLocalizedString("_None_"), value: null, defaultValue: true},
      {name: localize.getLocalizedString("_MaleSelection_"), value: "male"},
      {name: localize.getLocalizedString("_FemaleSelection_"), value: "female"},
      {name: localize.getLocalizedString("_Nevermind_"), value: "nevermind"},
    ];

    $scope.includeRoom = configurations.CalendarSettings.EnableRooms;
    const allocateRoom = configurations.CalendarSettings.ForceRoomAllocation;
    $scope.forceRoom = configurations.CalendarSettings.ForceRoomAllocation && configurations.CalendarSettings.ForceRoomSelection;
    const isServiceSelected = function () {
      return (($scope.meeting.services && $scope.meeting.services.length > 0) || ($scope.meeting.service && $scope.meeting.service.value));
    }

    $scope.currentMeetingLengthInMinutes = calendarMetadata.stepInMinutesBetweenMeetings;

    $scope.meetingDateField = {
      fieldName: 'meetingDate',
      placeholder: localize.getLocalizedString("_MeetingDate_"),
      defaultStartDate: "+1d",
      validation: "{'date': true, 'required': true}",
      yearRange: "-1:+3",
      type: 'date'
    };

    $scope.meetingColorField = {
      fieldName: 'Color', icon: "icon icon-color", placeholder: localize.getLocalizedString("_ServiceColor_"),
      validation: "{'color': true }", type: 'color'
    };

    if ($scope.includeMeetingLocation) {
      $scope.locationField = {
        fieldName: 'Location',
        type: 'address',
        icon: "icon icon-home-ol",
        placeholder: localize.getLocalizedString("_MeetingAddress_")
      };
    }

    $scope.fromTime = [];
    $scope.toTime = [];
    var toTimeOptions = [];
    var startTime = calendarMetadata.startTime.clone();

    // How long to add between steps in meetings should be the difference between each meeting possible in calendar
    // window.

    // We are creating the start time's combo by iterating fr
    while (startTime.isBefore(calendarMetadata.endTime) || startTime.isSame(calendarMetadata.endTime)) {
      $scope.fromTime.push({name: startTime.format(HOURS_AND_MINUTES_FORMAT), value: startTime.clone()});
      startTime.add(Math.min(calendarMetadata.minutesBetweenMeetings, calendarMetadata.stepInMinutesBetweenMeetings), 'm');
      toTimeOptions.push({name: startTime.format(HOURS_AND_MINUTES_FORMAT), value: startTime.clone()});
    }

    $scope.meeting.isToRemind = configurations.CalendarSettings.SendARemainderDefault;
    $scope.timesToRemind = [
      {name: localize.getLocalizedString("_30MinBefore_"), value: 30},
      {name: localize.getLocalizedString("_HourBefore_"), value: 60},
      {name: localize.getLocalizedString("_2HoursBefore_"), value: 120},
      {name: localize.getLocalizedString("_DayBefore_"), value: 24 * 60},
      {name: localize.getLocalizedString("_2DaysBefore_"), value: 24 * 60 * 2},
      {name: localize.getLocalizedString("_3DaysBefore_"), value: 24 * 60 * 3}
    ];

    $scope.remarksPlaceHolder = localize.getLocalizedString("_Remarks_");

    var manualRepeatComboItem = {name: localize.getLocalizedString("_Custom_"), value: null};
    $scope.repeats = [
      {name: localize.getLocalizedString("_Daily_"), value: 'RRULE:FREQ=DAILY'},
      {name: localize.getLocalizedString("_Weekly_"), value: 'RRULE:FREQ=WEEKLY'},
      {name: localize.getLocalizedString("_Every2Weeks_"), value: 'RRULE:FREQ=WEEKLY;INTERVAL=2'},
      {name: localize.getLocalizedString("_Every3Weeks_"), value: 'RRULE:FREQ=WEEKLY;INTERVAL=3'},
      {name: localize.getLocalizedString("_Monthly_"), value: 'RRULE:FREQ=MONTHLY;'},
      manualRepeatComboItem
    ];

    $scope.repeatSelected = function (repeatValue, force) {
      if (!repeatValue.value && !force) {
        $scope.toggleCustomRepeat();
      } else {
        $scope.manualRepeatWrapper.value = null;
      }
    };

    $scope.manualRepeatWrapper = {};
    $scope.isCustomRepeatVisible = false;
    $scope.toggleCustomRepeat = function () {
      $scope.isCustomRepeatVisible = !$scope.isCustomRepeatVisible;
      $scope.$digestIfNeeded();
    };

    $scope.$watch('manualRepeatWrapper.value', function (newVal, oldVal) {
      if (newVal && (newVal !== oldVal || !$scope.translatedRepeat)) {
        $scope.translatedRepeat = iCalService.rRuleToString(newVal);
      } else if (!newVal) {
        $scope.translatedRepeat = "";
      }
    });

    $scope.meeting.timeToRemind = $scope.timesToRemind.filter(x => x.value === configuration.get().CalendarSettings.RemainderTimingDefault.Value)[0] || $scope.timesToRemind[2];

    // $scope.meeting.timeToRemind = Enumerable.From($scope.timesToRemind).FirstOrDefault($scope.timesToRemind[2], function (x) {
    //   return x.value === configuration.get().CalendarSettings.RemainderTimingDefault.Value;
    // });

    $scope.customerTooltip = function (customer) {
      return customer.Remarks;
    };

    if (parameters && Object.keys(parameters).length) {
      if (parameters.meeting) {
        $scope.editMode = true;
        $scope.saveButtonTitle = localize.getLocalizedString("_UpdateMeeting_");
        $scope.dialogTitle = localize.getLocalizedString("_EditMeeting_");
        var meeting = parameters.meeting;

        setStartEndTimeByMeeting();

        $scope.meeting.remarks = parameters.meeting.originalMeeting.Remarks;
        if (parameters.meeting.originalMeeting.CustomerId) {
          $scope.meeting.customer = {
            CustomerId: parameters.meeting.originalMeeting.CustomerId,
            FirstName: parameters.meeting.originalMeeting.CustomerName
          };
        }

        if (parameters.meeting.originalMeeting.RoomId) {
          $scope.meeting.room = {value: parameters.meeting.originalMeeting.RoomId};
        }

        $scope.meeting.meetingDate = meeting.StartTime.format(DATE_DISPLAY_FORMAT);
        $scope.meeting.Location = meeting.originalMeeting.Location;
        $scope.meeting.isAllDay = meeting.originalMeeting.IsAllDay;

        if (parameters.meeting.originalMeeting.GenderSelection) {
          $scope.meeting.genderSelection = $scope.genderOptions.filter(x => x.value === parameters.meeting.originalMeeting.GenderSelection)[0];
        }

        // handle repeating meeting
        if (meeting.originalMeeting.Recurrence) {
          $scope.verifyFutureEvents = !meeting.originalMeeting.RecurreningEvaluatedEndTime /*Endless meeting*/ ||
            /*Or there are future meetings*/
            moment(meeting.originalMeeting.RecurreningEvaluatedEndTime).startOf('day').isAfter(moment().startOf('day'));

          $scope.meeting.isMeetingRepeats = true;
          var repeatingCostantValue = $scope.repeats.filter(x => x.value === meeting.originalMeeting.Recurrence)[0];
          if (!repeatingCostantValue) { // manual repeat!
            $scope.manualRepeatWrapper.value = meeting.originalMeeting.Recurrence;
            $scope.meeting.repeats = manualRepeatComboItem;
          } else {
            $scope.meeting.repeats = repeatingCostantValue;
          }
        }

        $scope.meeting.isToRemind = parameters.meeting.originalMeeting.Reminder > 0;
        if ($scope.meeting.isToRemind) {
          $scope.meeting.timeToRemind = $scope.timesToRemind.filter(x => x.value === parameters.meeting.originalMeeting.Reminder)[0];
        }

      } else if (angular.isDefined(parameters.customer)) {
        $scope.meeting.customer = parameters.customer;
      } else {
        var parsedFrom = parameters.time.format(HOURS_AND_MINUTES_FORMAT);
        var selectedFrom = $scope.fromTime[0];

        for (var time in $scope.fromTime) {
          if ($scope.fromTime[time].name == parsedFrom) {
            selectedFrom = $scope.fromTime[time];
            break;
          }
        }

        $scope.meeting.from = selectedFrom;
        $scope.meeting.meetingDate = parameters.date.format(DATE_DISPLAY_FORMAT);//.format(DATE_DISPLAY_FORMAT);

        contextManager.get('customerPhoneNumber').then(function (phoneNumber) {
          if (phoneNumber) {
            $scope.customerSearchTextWrapper.text = phoneNumber;
            $scope.toggleNewCustomerMode();
          } else {
            setCustomerFromContext();
          }
        });
      }
    } else {
      $scope.meeting.from = $scope.fromTime[0];
    }

    function setStartEndTimeByMeeting() {
      //set fromTime value from original meeting
      $scope.meeting.from = $scope.fromTime.filter(x => x.value.format(HOURS_AND_MINUTES_FORMAT) === meeting.StartTime.format(HOURS_AND_MINUTES_FORMAT))[0];

      //in case value doesn't exist in times array (configuration changed)
      if (!$scope.meeting.from) {
        $scope.meeting.from = setNewValueInToTime(meeting.StartTime, $scope.fromTime);
      }

      var valueToCompare = $scope.meeting.from.value.format('HHmm');
      $scope.toTime = toTimeOptions.slice(0).filter(function (timeValue) {
        return timeValue.value.format('HHmm') > valueToCompare;
      });


      //set toTime value from original meeting
      $scope.meeting.to = $scope.toTime.filter(x => x.value.format(HOURS_AND_MINUTES_FORMAT) === meeting.EndTime.format(HOURS_AND_MINUTES_FORMAT))[0];
      //in case value doesn't exist in times array (configuration changed)
      if (!$scope.meeting.to) {
        $scope.meeting.to = setNewValueInToTime(meeting.EndTime, $scope.toTime);
      }

      $scope.currentMeetingLengthInMinutes = $scope.meeting.to.value.diff($scope.meeting.from.value, 'minutes');

    }

    $scope.$watch('meeting.service', function (newVal) {
      var previousComposedTo = $scope.meeting.composedServices;
      delete $scope.meeting.composedServices;

      if (newVal && (Array.isArray(newVal) || !newVal.defaultValue)) {
        if (newVal.value) {
          handleCompositeIfNeeded(newVal.value);
        } else if (newVal.length > 0) {
          var idx = 0;
          // Handle the first composite service found.
          while (idx < newVal.length && !handleCompositeIfNeeded(newVal[idx].id)) {
            idx++
          }
        }
      }

      function handleCompositeIfNeeded(service) {
        if (service.ComposedServiceMetadatas && service.ComposedServiceMetadatas.length > 0) {
          var composedService = {
            name: service.Name,
            value: service.ServiceMetadataId,
            composedServices: []
          };

          $scope.meeting.composedServices = {};
          $scope.meeting.composedServices[service.ServiceMetadataId] = composedService;
          service.ComposedServiceMetadatas.forEach(function (composedService, index) {
            var assignedTo = (previousComposedTo && previousComposedTo[service.ServiceMetadataId] &&
                previousComposedTo[service.ServiceMetadataId].composedServices[index] &&
                previousComposedTo[service.ServiceMetadataId].composedServices[index].assignedTo)
              || $scope.meeting.assignedTo;

            $scope.meeting.composedServices[service.ServiceMetadataId].composedServices.push({
              name: composedService.Name,
              value: composedService.ServiceMetadataId,
              assignedTo: assignedTo,
              employees: $scope.employees
            })
          });

          return true;
        }

        return false;
      }
    });


    $scope.manuallySelectedService = function (newVal, isOpen) {
      if (newVal) {
        if (Array.isArray(newVal) || !newVal.defaultValue) {
          var serviceDurationInMinutes = 0;
          var serviceDuration;
          if (newVal.value) {
            serviceDuration = moment.duration(newVal.value.Duration);
            serviceDurationInMinutes = serviceDuration.asMinutes();
          } else if (newVal.length > 0) {
            for (var i = 0; i < newVal.length; i++) {
              var duration = (newVal[i].id && newVal[i].id.Duration) || (newVal[i].value && newVal[i].value.Duration);
              if (duration) {
                serviceDuration = moment.duration(duration);
                serviceDurationInMinutes += serviceDuration.asMinutes();
              }
            }
          }

          var timeAfterAddingDuration = moment($scope.meeting.from.value).add(serviceDurationInMinutes, 'minutes');
          $scope.meeting.to = $scope.toTime.filter(x => x.value.format(HOURS_AND_MINUTES_FORMAT) === timeAfterAddingDuration.format(HOURS_AND_MINUTES_FORMAT))[0];
          if (!$scope.meeting.to) {
            $scope.meeting.to = setNewValueInToTime(timeAfterAddingDuration, $scope.toTime);
          }

          $scope.currentMeetingLengthInMinutes = $scope.meeting.to.value.diff($scope.meeting.from.value, 'minutes');
        }

        if (!isOpen) {
          recalculateResources();
        }
      }
    };

    function setNewValueInToTime(value, timeArray) {
      //new item to insert
      let newTimeItem = {name: moment(value).format(HOURS_AND_MINUTES_FORMAT), value: value};
      //if new item is earlier then array values
      if (isTimeBefore(value, getSafely([0, 'value'], timeArray))) {
        timeArray.splice(0, 0, newTimeItem);
      } else {
        //find the right place for the new value
        for (let i = 0; i < timeArray.length - 1; i++) {
          const prevValue = getSafely([i, 'value'], timeArray);
          const nextValue = getSafely([i + 1, 'value'], timeArray);
          if (isTimeAfter(nextValue, value) && isTimeBefore(prevValue, value)) {
            timeArray.splice(i + 1, 0, newTimeItem);
            i = timeArray.length;
          }
        }

      }

      if (isTimeBefore(value, getSafely([0, 'value'], timeArray))) {
        timeArray.splice(0, 0, newTimeItem);
      } else {
        for (var i = 0; i < toTimeOptions.length - 1; i++) {
          if (isTimeAfter(toTimeOptions[i + 1].value, value) && isTimeBefore(toTimeOptions[i].value, value)) {
            toTimeOptions.splice(i + 1, 0, newTimeItem);
            i = toTimeOptions.length;
          }
        }
      }
      return newTimeItem;
    }

    //is value to check greater then value

    function isTimeAfter(valueToCheck, value) {
      return (value && valueToCheck) && (valueToCheck.hour() > value.hour() || (valueToCheck.hour() === value.hour() && valueToCheck.minutes() > value.minutes()));
    }

    //is value to check smaller then value

    function isTimeBefore(valueToCheck, value) {
      return (valueToCheck && value) && (valueToCheck.hour() < value.hour() || (valueToCheck.hour() === value.hour() && valueToCheck.minutes() < value.minutes()));
    }


    $scope.$watch('meeting.from', function (newVal) {
      if (!angular.isDefined(newVal)) {
        return;
      }

      var valueToCompare = newVal.value.format('HHmm');
      $scope.toTime = toTimeOptions.slice().filter(function (timeValue) {
        return timeValue.value.format('HHmm') > valueToCompare;
      });

      var newMeetingTo = moment($scope.meeting.from.value).add($scope.currentMeetingLengthInMinutes, "minutes");
      var foundedToTime = $scope.toTime.filter(x => x.value.format(HOURS_AND_MINUTES_FORMAT) === newMeetingTo.format(HOURS_AND_MINUTES_FORMAT))[0]

      // _.find($scope.toTime, function (x) {
      // return x.value.format(HOURS_AND_MINUTES_FORMAT) === newMeetingTo.format(HOURS_AND_MINUTES_FORMAT);
      // });

      $scope.meeting.to = foundedToTime ? foundedToTime : $scope.toTime[$scope.toTime.length - 1];
    });

    $scope.cancel = function () {
      $modalInstance.close();
      contextManager.clear('customerPhoneNumber');
    };

    $scope.delete = function (ignoreFutureEvents) {
      parameters.deleteCommand(parameters.meeting, !ignoreFutureEvents)
        .finally(function () {
          $scope.cancel();
        });
    };


    $scope.employees = [];
    $scope.allEmployees = [];

    Repository.Entity("Employee").query().filter("IsDeactiveted eq false").get().then(function (iEmployees) {
      $scope.allEmployees = iEmployees.results.map(function (employee) {
        return {
          name: employee.FirstName + " " + employee.LastName,
          id: employee.EmployeeId
        };
      });
    }).catch(function (err) {
      toastr.error(err.Message);
    });

    $scope.createdByEmployee = {selected: configuration.defaultEmployee()};

    $scope.defaultEmployee = {};

    $scope.defaultEmployee = $scope.meeting.assignedTo = configuration.defaultEmployee();
    $scope.employees.push($scope.defaultEmployee);

    /*********** ROOMS ***********/
    if ($scope.includeRoom) {
      $scope.rooms = [
        {
          id: null,
          name: localize.getLocalizedString('_NoRoom_'),
          value: null,
          serviceMetadatas: null
        }];
    }


    $scope.decorateServiceCombo = function (scope, element) {
      var subscriptionUsagesForItem = scope.item.value && scope.item.value.SubscriptionUsage;
      if (subscriptionUsagesForItem) {
        element.find('.combobox-list-item-concrete-value').append('<div class="combobox-service-punchcard-decorator"><span class="icon icon-punchcard-new"></span><span>' + subscriptionUsagesForItem + '</span></div>')
      }
    };


    var recalculateResources = (function () {
      var wasFirstLoaded = false;
      var delayedGetResources = new DelayedFunction(function () {
        _recalculateResources();
      }, 300);

      return function () {
        if (!wasFirstLoaded) {
          delayedGetResources.setForce({})
        } else {
          delayedGetResources.set({})
        }
      };

      function _recalculateResources() {
        Repository.Custom('Calendar').resolveResources.apply($scope, calculateParams()).then(function (results) {
          $scope.$evalAsync(function () {
            setEmployees(results.employees);
            setServices(results.byCategories || results.serviceMetadatas);
            if ($scope.includeRoom) {
              setRooms(results.rooms);
            }

            // if (parameters.meeting) {
            //   setStartEndTimeByMeeting();
            // }

            wasFirstLoaded = true;
          });
        });
      }

      function calculateParams() {
        var services = null;
        if (wasFirstLoaded) {
          if ($scope.meeting.services && $scope.meeting.services.length > 0) {
            services = "'" + $scope.meeting.services.reduce(function (acc, val) {
              return acc + (acc.length > 0 ? ',' : '') + val.value.ServiceMetadataId;
            }, "") + "'";
          } else if ($scope.meeting.service && $scope.meeting.service.value) {
            services = "'" + $scope.meeting.service.value.ServiceMetadataId + "'";
          }
        } else {
          if (parameters.meeting && parameters.meeting.originalMeeting.ServiceMetadatas
            && parameters.meeting.originalMeeting.ServiceMetadatas.length > 0) {
            services = "'" + parameters.meeting.originalMeeting.ServiceMetadatas.reduce(function (acc, val) {
              return acc + (acc.length > 0 ? ',' : '') + val.ServiceMetadataId;
            }, "") + "'";
          }
        }

        var mfrom = moment($scope.meeting.meetingDate, DATE_DISPLAY_FORMAT);
        mfrom.set('hour', $scope.meeting.from.value.hour());
        mfrom.set('minute', $scope.meeting.from.value.minute());
        var mto = moment($scope.meeting.meetingDate, DATE_DISPLAY_FORMAT);
        mto.set('hour', $scope.meeting.to.value.hour());
        mto.set('minute', $scope.meeting.to.value.minute());

        return [
          $scope.meeting.assignedTo.value,
          $scope.meeting.customer ? $scope.meeting.customer.CustomerId : null,
          $scope.meeting.room ? $scope.meeting.room.value : null,
          services,
          mfrom.format(ODATA_DATE_TIME_FORMAT),
          mto.format(ODATA_DATE_TIME_FORMAT)
        ];
      }

      function setServices(serviceMetadatas) {
        $scope.services.splice(1);
        serviceMetadatas.forEach(function (customServicePresentation) {
          var serviceComboboxItem;
          if (!$scope.servicesByCategories) {
            serviceComboboxItem = serviceMetadataToComboboxItem(customServicePresentation)
          } else {
            serviceComboboxItem = {
              valueType: 'Category',
              name: customServicePresentation.CategoryName,
              value: customServicePresentation.ServiceMetadatas.map(serviceMetadataToComboboxItem)
            };
          }

          $scope.services.push(serviceComboboxItem);
        });

        setServicesForMeeting(!wasFirstLoaded);

        function serviceMetadataToComboboxItem(serviceMetadata) {
          return {
            name: serviceMetadata.Name,
            forceUniqueSelection: false,//serviceMetadata.ComposedServiceMetadatas &&
            // serviceMetadata.ComposedServiceMetadatas.length > 1,
            value: serviceMetadata
          }
        }
      }

      function setRooms(rooms) {
        if ($scope.forceRoom && isServiceSelected()) {
          $scope.rooms.length = 0;
          if (rooms.length === 0) {
            toastr.error(localize.getLocalizedString("_NoRoomsAvailable_"));
          }
        } else {
          $scope.rooms.splice(1);
        }
        rooms.forEach(function (room) {
          $scope.rooms.push({
            id: room.RoomId,
            name: room.Name,
            value: room.RoomId
          });
        });

        selectMeetingRoom(!wasFirstLoaded);
      }

      function setEmployees(employees) {
        $scope.employees.length = 0;
        employees.forEach(function (employee) {
          $scope.employees.push({
            id: employee.EmployeeId,
            name: employee.Name,
            value: employee.EmployeeId
          });
        });

        selectMeetingEmployee(!wasFirstLoaded);
      }


      function setServicesForMeeting(useParams) {
        var selectedServiceMetadatas = !useParams ?
          ($scope.meeting.services && $scope.meeting.services.length > 0 ? $scope.meeting.services : $scope.meeting.service)
          : (parameters.meeting && parameters.meeting.originalMeeting.ServiceMetadatas); // Only for first load.

        if (selectedServiceMetadatas && !Array.isArray(selectedServiceMetadatas)) {
          selectedServiceMetadatas = [selectedServiceMetadatas];
        }

        if (!selectedServiceMetadatas || selectedServiceMetadatas.length == 0 || selectedServiceMetadatas[0].defaultValue) {
          return;
        }

        // Composite Services
        if (useParams && parameters.meeting && parameters.meeting.originalMeeting.CompositeAdapters) {
          var compositeServices = parameters.meeting.originalMeeting.CompositeAdapters;
          for (var compositeServiceId in compositeServices) {
            // Find composite service in selected services;
            var service = null;
            selectedServiceMetadatas.some(function (serviceMetadata) {
              if (serviceMetadata.ServiceMetadataId == compositeServiceId) {
                return service = serviceMetadata;
              }

            });

            $scope.meeting.composedServices = {};
            var composedService = {
              name: service.Title,
              value: compositeServiceId,
              composedServices: []
            };

            $scope.meeting.composedServices[compositeServiceId] = composedService;
            compositeServices[compositeServiceId].forEach(function (internalComposedService) {
              var composedServiceFromServices = findServiceRecursively($scope.services, internalComposedService.ServiceMetadataId);
              var composedAssignedTo = null;
              $scope.employees.some(function (employee) {
                if (employee.value && employee.value == internalComposedService.EmployeeId) {
                  return composedAssignedTo = employee;
                }
              });

              composedService.composedServices.push({
                name: (composedServiceFromServices && (composedServiceFromServices.name || composedServiceFromServices.Name)) || localize.getLocalizedString('_MissingService_'),
                value: composedService.value,
                assignedTo: composedAssignedTo,
                employees: $scope.employees
              })

            })
          }
        }

        var servicesIdsForMeeting = selectedServiceMetadatas.map(function (serviceMetadata) {
          return serviceMetadata.ServiceMetadataId || serviceMetadata.value.ServiceMetadataId;
        });

        var meetingServices = [];
        if (!$scope.servicesByCategories) {
          matchServiceMetadata($scope.services)
        } else {
          for (var categoryIdx = 1; categoryIdx < $scope.services.length; categoryIdx++) {
            var serviceCategory = $scope.services[categoryIdx];
            matchServiceMetadata(serviceCategory.value);
            if (servicesIdsForMeeting.length === 0) {
              break;
            }
          }
        }

        if (meetingServices.length > 1) {
          $scope.meeting.services = meetingServices.map(function (serviceMetadata) {
            serviceMetadata.isSelected = true;
            return serviceMetadata;
          });

          // delete $scope.meeting.service;

        } else if (meetingServices.length == 1) {
          $scope.meeting.service = meetingServices[0];
          $scope.meeting.services = $scope.servicesByCategories ? meetingServices : [];
        }

        function matchServiceMetadata(services) {
          for (var serviceIdx = (services[0].defaultValue ? 1 : 0); serviceIdx < services.length; serviceIdx++) {
            var serviceMetadata = services[serviceIdx];
            var indexOfServiceAtMeeting = servicesIdsForMeeting.indexOf(serviceMetadata.value.ServiceMetadataId);
            if (indexOfServiceAtMeeting >= 0) {
              meetingServices.push(serviceMetadata);
              servicesIdsForMeeting.splice(indexOfServiceAtMeeting, 1);

              if (servicesIdsForMeeting.length === 0) {
                break;
              }
            }
          }
        }

        function findServiceRecursively(services, serviceId) {
          var toReturn = null;
          for (var serviceIdx in services) {
            var serviceComboItem = services[serviceIdx].value;
            if (serviceComboItem) {
              if (!Array.isArray(serviceComboItem)) {
                if (serviceComboItem.ServiceMetadataId == serviceId) {
                  toReturn = serviceComboItem;
                  break;
                }
              } else {
                toReturn = findServiceRecursively(serviceComboItem, serviceId);
                if (toReturn) {
                  break;
                }
              }
            }
          }

          return toReturn;
        }
      }
    }());

    var lastLoadedResourcesFor = null;
    $scope.$watchGroup(['meeting.assignedTo.value', 'meeting.customer.CustomerId', 'meeting.room.value'/*, 'meeting.services.length'*/], function (newVal) {
      var tmpLastLoadedResources = lastLoadedResourcesFor;
      lastLoadedResourcesFor = JSON.stringify(newVal);
      if (tmpLastLoadedResources == lastLoadedResourcesFor) {
        return;
      }

      recalculateResources();
    });

    function selectMeetingRoom(useParams) {
      if ($scope.meeting.room && !$scope.meeting.room.value) {
        // Default Room selected.
        if (allocateRoom && $scope.rooms[1] && isServiceSelected()) {
          return $scope.meeting.room = $scope.rooms[1];
        }
        return $scope.meeting.room = $scope.rooms[0];
      }

      var roomId = ($scope.meeting.room && $scope.meeting.room.value) || (parameters.meeting && parameters.meeting.originalMeeting && parameters.meeting.originalMeeting.RoomId) || (useParams && parameters.room && parameters.room.id);
      $scope.meeting.room = $scope.rooms[0];
      if (roomId) {
        var roomItem = $scope.rooms.filter(function (room) {
          return roomId === room.id;
        });

        if (roomItem.length > 0) {
          $scope.meeting.room = roomItem[0];
        }
      }
    }

    function selectMeetingEmployee(useParams) {
      var employeeId = $scope.meeting.assignedTo && $scope.meeting.assignedTo.value;
      if (useParams) {
        employeeId = (parameters.meeting && parameters.meeting.originalMeeting && parameters.meeting.originalMeeting.EmployeeId) ||
          (parameters.employee && parameters.employee.id);
      }


      var employeeItem = $scope.employees.filter(function (employee) {
        return employeeId === employee.id;
      });

      $scope.meeting.assignedTo = $scope.employees[0];
      if (employeeItem.length > 0) {
        $scope.meeting.assignedTo = employeeItem[0];
      }
    }

    $(document).on('keydown.escapeAddMeeting', function (e) {
      if (e.which == 27) {
        if (!$scope.isCustomRepeatVisible) {
          $scope.cancel();
        }
      }
    });

    $scope.$on('$destroy', function () {
      $(document).off('keydown.escapeAddMeeting');
    });


    // saveAction is resolved on the toolbar so the modal can have a result when the dialog changed.
    $scope.meetingDateValidation = {};
    $scope.meetingDateFieldValidation = {};

    $scope.save = _save;

    // $scope.save = $timeout.bind(null, _save, 0);
    function _save(updateFutureEvents, addAnother) {
      if ($scope.meetingDateValidation.validate().length > 0) {
        showValidationErrorMessage("_Date_");
        return;
      }

      if (!!$scope.newCustomerMode && !isValidNewCustomer()) {
        return;
      }

      if (!$scope.newCustomerMode && !$scope.meeting.customer && !$scope.meeting.remarks) {
        toastr.error(localize.getLocalizedString("_MeetingMustHaveCustomerOrNote_"));
        return;
      }

      $scope.isSubmittingMeeting = true;
      $scope.meeting.CalendarEventName = $scope.meeting.customer ?
        $scope.meeting.customer.FirstName + " " + $scope.meeting.customer.LastName :
        (!!$scope.newCustomerMode) ? $scope.newCustomerWrapper.NewCustomer.FirstName + " " + $scope.newCustomerWrapper.NewCustomer.LastName
          : $scope.meeting.remarks;
      $scope.meeting.Reminder = $scope.meeting.isToRemind ? $scope.meeting.timeToRemind.value : 0;
      var meeting = $.extend({}, $scope.meeting);

      if (!!$scope.newCustomerMode) {
        meeting.NewCustomer = $scope.newCustomerWrapper.NewCustomer;
        if (meeting.NewCustomer.DateOfBirth) {
          meeting.NewCustomer.Birthday = moment(meeting.NewCustomer.DateOfBirth, DATE_DISPLAY_FORMAT).format(ODATA_DATE_TIME_FORMAT);
        }
      }

      var from = moment(meeting.meetingDate, DATE_DISPLAY_FORMAT);
      from.set('hour', meeting.from.value.hour());
      from.set('minute', meeting.from.value.minute());
      var to = moment(meeting.meetingDate, DATE_DISPLAY_FORMAT);
      to.set('hour', meeting.to.value.hour());
      to.set('minute', meeting.to.value.minute());
      if (to.isBefore(from)) {
        to.add(1, 'day')
      }

      // Recurring event...
      if (meeting.isMeetingRepeats) {
        meeting.repeatEvent = meeting.repeats.value ? meeting.repeats.value : $scope.manualRepeatWrapper.value;
      }

      meeting.from = from;
      meeting.to = to;
      meeting.EmployeeId = $scope.meeting.assignedTo.id;
      meeting.RoomId = $scope.meeting.room ? $scope.meeting.room.value : null;
      meeting.GenderSelection = $scope.meeting.genderSelection ? $scope.meeting.genderSelection.value : null;
      meeting.Location = $scope.meeting.Location;
      if (parameters && parameters.meeting && parameters.meeting.originalMeeting.OnlineBookingId) {
        meeting.OnlineBookingId = parameters.meeting.originalMeeting.OnlineBookingId;
      }
      meeting.CreatedByEmployeeId = $scope.editMode ? $scope.meeting.CreatedByEmployeeId : $scope.createdByEmployee.selected.id;

      meeting.IsAllDay = $scope.meeting.isAllDay;
      if (meeting.NewCustomer) {
        meeting.NewCustomer.ArrivalSource = meeting.NewCustomer.ArrivalSource || {ArrivalSourceId: 0};
      }

      if (meeting.externalVoucher) {
        meeting.ExternalVoucherMetadataId = meeting.externalVoucher.value ? meeting.externalVoucher.value.ExternalVoucherMetadataId : null;
      }

      // Composed services
      if ($scope.meeting.composedServices) {
        meeting.CompositeAdapters = {};
        for (var composedServiceMetadataId in $scope.meeting.composedServices) {
          meeting.CompositeAdapters[composedServiceMetadataId] = [];
          $scope.meeting.composedServices[composedServiceMetadataId].composedServices
            .forEach(function (composedService) {
              meeting.CompositeAdapters[composedServiceMetadataId].push({
                ServiceMetadataId: composedService.value,
                EmployeeId: composedService.assignedTo.value
              });
            });
        }
      }

      sendToServer(meeting, (parameters && parameters.meeting) ? parameters.meeting : null, updateFutureEvents, addAnother)

    };

    function sendToServer(meeting, oldMeeting, updateFutureEvents, addAnother, alreadySent = false) {
      return saveAction(meeting, oldMeeting, updateFutureEvents, addAnother)
        .then(function (meetingResult) {
          if ($scope.editMode) {
            toastr.success(localize.getLocalizedString("_MeetingUpdated_"));
          }

          if (!addAnother) {
            $scope.cancel();
            contextManager.clear(['customer']);
          } else {
            var customerId = meetingResult && Array.isArray(meetingResult.Inserted) && meetingResult.Inserted[0] && meetingResult.Inserted[0].CustomerId;
            if (customerId) {
              contextManager.set('customer', customerId);
            }

            $scope.cancel();
          }
          $scope.$applyIfNeeded(function () {
            $scope.isSubmittingMeeting = false;
          });
        })
        .catch(function ({data: err}) {
          var errorMessage = err.InnerException ? err.InnerException.ExceptionMessage :
            err.ExceptionMessage ? err.ExceptionMessage : err.Message ? err.Message : err;
          try {
            errorMessage = localize.getLocalizedString(errorMessage);
          } catch (e) {
          }

          /**
           * To prevent double handling the same error, doesn't look problematic, just in case.
           */
          if (!alreadySent && err.CustomerId) {
            confirmService.confirm(errorMessage,
              localize.getLocalizedString("_WhatWouldYouLikeToDo_"),
              function () {
                meeting.NewCustomer.ForceNewCustomerWithTheSameMobile = true;
                sendToServer(meeting, oldMeeting, updateFutureEvents, addAnother, true);
              },
              function () {
                meeting.NewCustomer = null;
                meeting.CalendarEventName = err.CustomerName;
                meeting.CustomerId = err.CustomerId;
                sendToServer(meeting, oldMeeting, updateFutureEvents, addAnother, true);
              },
              localize.getLocalizedString("_AddANewCustomerWithSameMobile_"),
              localize.getLocalizedString("_ChooseExistingCustomer_")
            );
          } else {
            toastr.error(localize.getLocalizedString("_ErrorSavingMeeting_", errorMessage));
          }

          $scope.$applyIfNeeded(function () {
            $scope.isSubmittingMeeting = false;
          });
        });
    }


    function showValidationErrorMessage(param) {
      toastr.error(localize.getLocalizedString("_MeetingMustHaveA_", localize.getLocalizedString(param)));
    }

    // Any change that requires employee should be validated.
    $scope.$watch('meeting.to', validateEmployeeAndTime);
    $scope.$watch('meeting.meetingDate', validateEmployeeAndTime);
    $scope.$watch('meeting.assignedTo', validateEmployeeAndTime);

    function validateEmployeeAndTime(newVal, oldVal) {
      if (newVal == oldVal) {
        return;
      }

      // var selectedEmployee = _.find(calendarMetadata.employees, function (employee) {
      //   return employee.id == $scope.meeting.assignedTo.id
      // });

      var selectedEmployee = calendarMetadata.employees.filter(employee => employee.id === $scope.meeting.assignedTo.id)[0];

      calendarColumns.isValidMeetingPositionAsync(selectedEmployee.id,
        moment($scope.meeting.meetingDate, DATE_DISPLAY_FORMAT), $scope.meeting.from.value, $scope.meeting.to.value, $scope.meeting.isAllDay)
        .then(function (result) {
          if (!result) {
            toastr.error(localize.getLocalizedString("_EmployeeIsNotAvailableOnThisTime_", $scope.meeting.assignedTo.name));
            if (!calendarMetadata.allowMeetingsOnTopOfExceptions) {
              $scope.invalidEmployee = true;
            }
          } else {
            $scope.invalidEmployee = false;
          }
        });
    }

    /************** CREATE NEW CUSTOMER ************/

    $scope.customerSearchTextWrapper = {};
    $scope.toggleNewCustomerMode = function () {
      $scope.newCustomerMode = !$scope.newCustomerMode;
      $scope.newCustomerTextMode = $scope.newCustomerMode ? existingCustomerModeText : newCustomerModeText;
      var customerSearchText = $scope.customerSearchTextWrapper.text ? $scope.customerSearchTextWrapper.text.replace('-', '') : "";

      $scope.newCustomerWrapper.NewCustomer.FirstName =
        $scope.newCustomerWrapper.NewCustomer.LastName =
          $scope.newCustomerWrapper.NewCustomer.MobileFirst = '';

      if (customerSearchText.length > 0) {
        // Is only digits - phone number...
        if (/^\d+$/.test(customerSearchText)) {
          $scope.newCustomerWrapper.NewCustomer.MobileFirst = customerSearchText;
        } else { //name.
          var figuredNames = customerSearchText.split(' ');
          $scope.newCustomerWrapper.NewCustomer.FirstName = figuredNames[0];
          $scope.newCustomerWrapper.NewCustomer.LastName = figuredNames.length > 1 ? customerSearchText.replace(figuredNames[0] + ' ', '') : '';
        }
      }
    };

    var newCustomerModeText = localize.getLocalizedString('_NewCustomer_');
    var existingCustomerModeText = localize.getLocalizedString('_ExistingCustomer_');

    $scope.newCustomerTextMode = newCustomerModeText;

    var genderField = {
      fieldName: 'Gender', icon: "glyphicon glyphicon-glass", placeholder: localize.getLocalizedString("_Gender_"),
      validation: "{'required': true}", type: 'radio', values: [
        {name: localize.getLocalizedString("_Female_"), value: 'Female'},
        {name: localize.getLocalizedString("_Male_"), value: 'Male'}
      ]
    };

    $scope.fields = [
      {
        fieldName: 'FirstName', icon: "icon icon-clients-ol", placeholder: localize.getLocalizedString("_FirstName_"),
        validation: "{'required': true, 'max-length': 20}"
      },
      {
        fieldName: 'LastName', icon: "icon icon-client", placeholder: localize.getLocalizedString("_LastName_"),
        validation: "{'required': true, 'max-length': 20}"
      },
      {
        fieldName: 'MobileFirst', icon: "icon icon-sms", placeholder: localize.getLocalizedString("_Phone_"),
        validation: "{'required': true, 'max-length': 10, 'min-length': 9, 'digits': true}"
      },
      {
        fieldName: 'MembershipId',
        icon: "icon icon-mail-outline",
        placeholder: localize.getLocalizedString("_MembershipNum_"),
        validation: "{'max-length': 20}"
      },
      {
        fieldName: 'EmailAddress',
        icon: "icon icon-mail-outline",
        placeholder: localize.getLocalizedString("_Email_"),
        validation: "{'email': true}"
      },
      {
        fieldName: 'DateOfBirth', icon: "icon icon-gift", placeholder: localize.getLocalizedString("_DateOfBirth_"),
        validation: "{'date': true, 'birthday': true}", type: 'date'
      },
      genderField
    ];

    $scope.newCustomerWrapper = {
      NewCustomer: {
        FirstName: '', LastName: '',
        MobileFirst: '', Gender: genderField.values[0],
        Tags: [],
      }
    };

    $scope.newCustomerFieldsToValidate = {}; // just initialize fields to validate.
    $scope.validateActionWrapper = {}; // this is an object to wrap an action, so the form validator can


    /************** EXTERNAL VOUCHER ************/
    if ($scope.usingExternalVouchers) {// USING EXTERNAL VOUCHERS.
      setExternalVouchers();
    }

    function setExternalVouchers() {
      $scope.externalVouchersByVendors = [
        {name: localize.getLocalizedString("_SelectExternalVoucher_"), value: null, defaultValue: true}
      ];


      Repository.Custom('EntitiesLazyRepository').externalVouchersByVendor().then(function (result) {
        for (var vendorId in result) {
          var vouchersForVendor = result[vendorId];
          var vouchers = [];

          vouchersForVendor.forEach(function (voucher) {
            var adaptedVoucher = {
              name: voucher.ExternalVoucherName,
              value: voucher,
              serviceMetadatas: voucher.ServiceMetadatas.map(function (serviceMetadata) {
                return {
                  id: serviceMetadata.ServiceMetadataId,
                  name: serviceMetadata.ServiceName
                }
              })
            };

            vouchers.push(adaptedVoucher);
            if (parameters && parameters.meeting && parameters.meeting.originalMeeting.ExternalVoucherMetadataId == adaptedVoucher.value.ExternalVoucherMetadataId) {
              $scope.meeting.externalVoucher = adaptedVoucher
            }

          });

          var vouchersByVendor = {
            valueType: 'Category',
            name: vouchersForVendor[0].ExternalVoucherVendor.ExternalVoucherVendorName,//customServicePresentation.CategoryName,
            value: vouchers
          };

          $scope.externalVouchersByVendors.push(vouchersByVendor);
        }
      });

      $scope.manuallySelectedExternalVoucher = function (voucher) {
        if (voucher.serviceMetadatas && voucher.serviceMetadatas.length > 0) {
          var mappedServicesById = {};
          $scope.services.forEach(function (serviceOrCategory) {
            if (serviceOrCategory.value) {
              if (serviceOrCategory.valueType === 'Category') {
                serviceOrCategory.value.forEach(function (serviceMetadataItem) {
                  mappedServicesById[serviceMetadataItem.value.ServiceMetadataId] = serviceMetadataItem;
                });
              } else {
                mappedServicesById[serviceOrCategory.value.ServiceMetadataId] = serviceOrCategory;
              }
            }
          });

          var services = [];
          voucher.serviceMetadatas.forEach(function (service) {
            if (mappedServicesById.hasOwnProperty(service.id)) {

              if (!meetingHasService(service)) {
                services.push(mappedServicesById[service.id]);
              }

            } else {
              toastr.warning(localize.getLocalizedString('_ServiceNotAvailableForVoucher_'), service.name);
            }
          });

          $scope.meeting.services.length = 0;
          $scope.meeting.services.push.apply($scope.meeting.services, services);
          recalculateResources();
          $scope.manuallySelectedService($scope.meeting.services, true);
        }
      }
    }

    /********************************** CUSTOMER TAGS **********************************/
    $scope.tagsPlaceholder = localize.getLocalizedString('_AddCustomerTag_');
    var tagsAutoCompleteOptions = [];
    Repository.Entity('Tag').query().filter('EntityType eq \'Customer\'').get()
      .then(function (data) {
        if (angular.isDefined(data)) {
          data.value.forEach(function (tag) {
            tag.EntityType = "Customer";
            tagsAutoCompleteOptions.push(tag);
          });
        }
      });

    $scope.onTagAdded = function (tag) {
      var newTagsAdded = tagsAutoCompleteOptions.filter(function (tagItem) {
        return tagItem.TagId < 0;
      }).length;

      if (!tag.TagId) {
        tag.TagId = -1;
        tag.EntityType = "Customer";
        tag.Color = colorsService.getColorByIndex(tagsAutoCompleteOptions.length + newTagsAdded);
      }
    };

    $scope.loadItems = function (query) {
      var regex = new RegExp('.*' + query + '.*', 'i');
      return tagsAutoCompleteOptions.filter(function (item) {
        return item.Name.match(regex);
      });
    };

    function meetingHasService(service) {
      if (!$scope.meeting.services || $scope.meeting.services.length === 0) {
        return false;
      }

      for (var serviceIdx in $scope.meeting.services) {
        if ($scope.meeting.services[serviceIdx].value.ServiceMetadataId === service.id) {
          return true;
        }
      }

      return false;
    }


    function isValidNewCustomer() {
      var validationFields = $scope.validateActionWrapper.validate();
      if (validationFields.length !== 0) { // is valid form?
        var wrongFieldName = $scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0]
        var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
        toastr.error(localizedError);
        return false;
      }

      return true;
    }

    function setCustomerFromContext() {
      contextManager.get('customer').then(function (customer) {
        if (customer) {
          var tempCustomer = {
            CustomerId: customer.CustomerId,
            FirstName: customer.FirstName + ' ' + customer.LastName
          };

          $scope.meeting.customer = tempCustomer;
        }
      });
    }
  });
