var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "px-4 pb-4 flex items-center" },
        [
          _c("div", { staticClass: "pe-4" }, [
            _vm._v(_vm._s(_vm.$t("_View_"))),
          ]),
          _vm._v(" "),
          _c("ButtonsGroup", {
            staticClass: "evc-background--default me-4",
            attrs: {
              options: _vm.viewTypeOptions,
              bordered: false,
              spaceEvenly: true,
            },
            model: {
              value: _vm.viewType,
              callback: function ($$v) {
                _vm.viewType = $$v
              },
              expression: "viewType",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "flex items-center ms-2" }, [
            ["inventory", "pricelists"].includes(_vm.viewType)
              ? _c("div", { staticClass: "me-2" }, [
                  _vm._v(_vm._s(_vm.$t("_Actions_"))),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.viewType === "inventory"
              ? _c(
                  "button",
                  {
                    staticClass: "background--primary p-2 rounded-md",
                    class: !_vm.saveEnabled && "evc--disabled",
                    attrs: { disabled: !_vm.saveEnabled },
                    on: { click: _vm.saveInventory },
                  },
                  [_vm._v("\n        " + _vm._s(_vm.$t("_Save_")) + "\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.viewType === "pricelists"
              ? _c(
                  "button",
                  {
                    staticClass: "background--primary p-2 rounded-md",
                    on: {
                      click: function ($event) {
                        return _vm.addNewPriceList()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("_AddPriceList_")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Grid",
        _vm._b(
          { attrs: { dataSource: _vm.dataSource } },
          "Grid",
          _vm.gridProps,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }