import moment from "moment";
import './reviews.over.time.widget.directive.less'
import { SERVER_DATA_DATE_FORMAT } from "../../../../constants";

angular.module('easybizy.dashboard').directive('reviewsOverTime', ['$timeout', 'localize', 'Repository',
  function ($timeout, localize, Repository) {
    return {
      restrict: 'E',
      replace: true,
      scope: true,
      link: function (scope, element, attrs) {
        var xAxis = [];
        var yAxis = [];

        scope.chartWrapper = {};
        var graphWrapper = element.find('.usage-widget-graph-content');

        var lastHeight = graphWrapper.height();
        var lastWidth = graphWrapper.width();
        var externalTimeout = null;

        graphWrapper.on('resize', function (e) {
          changeSize();
        });

        function changeSize() {
          if (externalTimeout != null) {
            clearTimeout(externalTimeout);
          }

          externalTimeout = setTimeout(function () {
            lastHeight = graphWrapper.height();
            lastWidth = graphWrapper.width();
            if (scope.chartWrapper.chart) {
              scope.chartWrapper.chart.setSize(lastWidth - 4, lastHeight);
            }
          }, 500);
        }

        setChart([]);
        setChartConfig([]);

        scope.isLoading = true;


        Repository.Custom('CustomersFeedback').feedbacksAverageGrade()
          .then(values => {
            if (values.length === 0) {
              noDataYet()
              return;
            }

            const sortedValues = values.sort((a, b) => {
              return a.firstDateOfWeek < b.firstDateOfWeek ? -1 : 1
            })

            scope.isLoading = false;
            setChart(sortedValues.map(x => parseFloat((x.averageGrade * 10).toFixed(1))));
            setChartConfig(sortedValues);
            changeSize();
          })
          .catch(e => {
            toastr.error(e.message);
            scope.isLoading = false;

          })

        function noDataYet() {
          scope.isLoading = false;
          scope.noData = true;
        }

        function setChartConfig(values) {
          const momentWeek = moment().week();

          xAxis.length = 0;
          values.forEach(value => {
            const weeksAgo = momentWeek - 1 - value.week;
            if (weeksAgo === 0) {
              xAxis.push(localize.getLocalizedString('_ThisWeek_'))
            } else if (weeksAgo === 1) {
              xAxis.push(localize.getLocalizedString('_The past week_'));
            } else if (weeksAgo === 2) {
              xAxis.push(localize.getLocalizedString('_2WeeksAgo_'))
            } else {
              xAxis.push(localize.getLocalizedString('_XWeeksAgo_', weeksAgo))
            }
          })


          scope.chartConfig = {
            options: {
              chart: {
                type: 'line'
              },
              plotOptions: {
                line: {
                  dataLabels: {
                    enabled: true
                  }
                  //enableMouseTracking: false
                }
              },
              exporting: {
                enabled: false
              },
              legend: {
                enabled: false
              },
              xAxis: {
                text: null,
                title: null,
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                categories: xAxis
              },
              yAxis: {
                text: null,
                title: null,
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                labels: {
                  enabled: false
                }
              },
              reflow: false
            },
            series: scope.chartSeries,
            title: {
              text: null
            },
            legend: {
              enabled: false
            },
            credits: {
              enabled: false
            },
            loading: false,
            "useHighStocks": false
          };

        }

        scope.$on('$destroy', function () {
          graphWrapper.off('DOMSubtreeModified');
          graphWrapper.off('resize');

        });

        function setChart(data) {
          yAxis = data ? data : yAxis;
          scope.chartSeries = [
            { "name": localize.getLocalizedString("_Rank_"), "data": yAxis, color: "#D5D5D5" }
          ];
        }
      },
      template: require('./reviews.over.time.widget.directive.html')
    };
  }]);
