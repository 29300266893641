const STATE = () => ({
  isLoading: false,
  itemsToUpload: [],
  services: [],
  products: []
});

const mutations = {
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_SERVICES(state, services) {
    state.services = [...services];
    state.itemsToUpload = [...services, ...state.products];

  },
  SET_PRODUCTS(state, products) {
    state.products = [...products];
    state.itemsToUpload = [...state.services, ...products];
  },
};

const actions = {
  setLoading(context, isLoading) {
    context.commit('SET_LOADING', isLoading);
  },
  setServices(context, services) {
    context.commit('SET_SERVICES', services);
  },
  setProducts(context, products) {
    context.commit('SET_PRODUCTS', products);
  },

};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  itemsToUpload(state) {
    return state.itemsToUpload.map((item) => {
      const isService = !!item.serviceName;
      return {
        EntityType: isService ? 'ServiceMetadata' : 'ProductMetadata',
        Category: item.categories,
        Cost: isService ? null : item.defaultCostPrice,
        IndexIdentifier: item.id,
        Color: Array.isArray(item.color) ? item.color[0] : item.color,
        ImageUrl: item.images[0].fullPath,
        Title: item.serviceName || item.productName,
        Manufacturer: item.manufacturer,
        Price: item.defaultRetailPrice,
        Duration: item.defaultDuration
      }
    });
  },
  services(state) {
    return state.services;
  },
  products(state) {
    return state.products;
  }
};

export default {
  state: STATE,
  getters,
  actions,
  mutations
}
