import { MAIN_MODULE } from "./constants";
import dataModule from "./data/dataModule";

MAIN_MODULE.factory('Repository', dataModule);
window.Globalize = require('../legacy/globalize/globalize');
require('../legacy/globalize/cultures/globalize.culture.he-IL');

import './common/localization/localize';


angular.module('easybizy.common.common-controls',
  [
    'easybizy.common.common.resources', 'easybizy.common.common-services',
    'localization',
    /*'ngAnimate',*/ 'angularFileUpload', 'videosharing-embed']);

angular.module('easybizy.easybizyModalViewsModel',
  ['ui.bootstrap', 'easybizy.common.common.resources', 'easybizy.common.common-controls', 'localization',
    'easybizy.common.common-services', 'easybizy.common.droppable.table']);

angular.module('easybizy.calendar', [
  'easybizy.common.common.resources', 'easybizy.common.toolbar.actions', 'easybizy.filters',
  'easybizy.common.common-controls', 'easybizy.common.common-services', 'localization']);

angular.module('easybizy.common.common-services',
  ['easybizy.common.common.resources']);

angular.module('easybizy.configurations', ['easybizy.common.common-controls',
  'easybizy.common.toolbar.actions', 'easybizy.common.common.resources']);

angular.module('easybizy.promotions.widget', [
  'easybizy.common.toolbar.actions', 'easybizy.common.common-controls', 'easybizy.common.promotion.editors',
  'easybizy.common.data.filter', 'easybizy.common.common-services', 'easybizy.configurations']);

angular.module('easybizy.common.promotion.editors',
  ['easybizy.common.common-controls', 'easybizy.common.common-services']);

angular.module('easybizy.common.data.filter', []);

angular.module('easybizy.promotions.manager', ['easybizy.common.graph.viewer',
  'easybizy.common.common-services']);

angular.module('easybizy.common.graph.viewer',
  ['easybizy.common.common-controls']);

angular.module('easybizy.common.droppable.table', []);

angular.module('easybizy.reports', [
  'easybizy.common.common.resources', 'easybizy.common.toolbar.actions', 'easybizy.filters', 'easybizy.common.tiles', 'easybizy.configurations']);

angular.module('easybizy.common.custom.reports.actions',
  ['easybizy.common.common-controls']);

angular.module('easybizy.leads', ['easybizy.common.tiles', 'easybizy.common.common.resources',
  'easybizy.common.toolbar.actions', 'easybizy.common.common-controls']);

angular.module('easybizy.products', [
  'easybizy.common.tiles', 'easybizy.common.common.resources',
  'easybizy.common.toolbar.actions', 'easybizy.easybizyModalViewsModel', 'easybizy.common.common-controls']);

angular.module('easybizy.common.price.quote',
  ['easybizy.common.common-controls']);

angular.module('easybizy.employees', ['easybizy.common.tiles', 'easybizy.common.toolbar.actions']);

angular.module('easybizy.resources.management', []);

angular.module('easybizy.expenses', []);

angular.module('easybizy.cash.register', [
  'easybizy.configurations',
  'easybizy.common.toolbar.actions', 'easybizy.filters', 'easybizy.common.common-services', 'easybizy.common.common.resources',
  'easybizy.common.price.quote']);

angular.module('easybizy.online.scheduling', []);
angular.module('easybizy.forms', []);
angular.module('easybizy.conversations', []);

angular.module('easybizy.vue', ['ngVue', 'ngVue.plugins']);
