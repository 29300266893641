import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT } from "../../constants";
import { getSafely, setIfHasValue } from "@tomeravni/easybizy-js-common/common";
import { isValidDate } from "@tomeravni/easybizy-js-common/date";

(function () {
  angular.module('easybizy.cash.register').service('invoiceFacade', [
    'configuration', 'intermediateManager',
    function (configuration, intermediateManager) {
      var configurations = configuration.get();
      this.generateNewInvoiceData = getNewInvoiceData;
      this.toPunchCard = toPunchCard;

      //todo: this is where i need to implement label logic
      function getNewInvoiceData(items, totalAggregator, customerInvoice, priceQuote, sessionId) {
        var newInvoice = {};
        if (customerInvoice) {
          newInvoice.Customer = customerInvoice;
          newInvoice.CustomerId = customerInvoice.CustomerId;
          newInvoice.InvoiceRefersTo = customerInvoice.FirstName + " " + customerInvoice.LastName;
          newInvoice.Address = customerInvoice.Address;
          newInvoice.IdentifyNumber = customerInvoice.MembershipId;
          if (customerInvoice.Discount && totalAggregator.discountPercentage === customerInvoice.Discount.Level) {
            newInvoice.MembershipDiscountId = customerInvoice.Discount.Id;
          }
        }

        newInvoice.NewMeetingsPayedStatusHandling = !configurations.CashRegisterSettings.PreventNewMeetingPayedStatusBehaviour;
        newInvoice.Currency = configurations.CashRegisterSettings.Currency.Value;
        newInvoice.Items = [];
        newInvoice.CustomItems = [];
        newInvoice.GiftCards = [];
        newInvoice.GiftCardUsages = [];
        newInvoice.Subscriptions = [];
        newInvoice.SubscriptionUsages = [];
        newInvoice.ExternalVouchers = [];
        newInvoice.Tips = [];

        newInvoice.unrelatedMeetings = intermediateManager.decorateRelatedMeetingsIfNeed(items, sessionId);
        setIfHasValue(newInvoice, 'SessionId', sessionId);

        items.forEach(function (itemInCart) {
          let itemInList;
          if (itemInCart.itemCustomType === 'PunchCard') {
            itemInList = toPunchCard(itemInCart);
            newInvoice.Subscriptions.push(itemInList);
          } else if (itemInCart.itemCustomType === 'PunchCardUsage') {
            itemInList = toPunchCardUsage(itemInCart);
            newInvoice.SubscriptionUsages.push(itemInList);
          } else if (itemInCart.Id > 0) {
            itemInList = toDefaultItem(itemInCart);
            newInvoice.Items.push(itemInList);
          } else if (itemInCart.itemCustomType === 'GiftCard') {
            itemInList = toGiftCard(itemInCart);
            newInvoice.GiftCards.push(itemInList);
          } else if (itemInCart.itemCustomType === 'GiftCardUsage') {
            itemInList = toGiftCardUsage(itemInCart);
            newInvoice.GiftCardUsages.push(itemInList);
          } else if (itemInCart.itemCustomType === 'ExternalGiftCardUsage') {
            itemInList = toExternalVoucher(itemInCart);
            newInvoice.ExternalVouchers.push(itemInList);
          } else if (itemInCart.itemCustomType === 'PointsUsage') {
            newInvoice.PointsUsage = itemInCart.RetailPrice;
          } else if (itemInCart.itemCustomType === 'Debt') {
            newInvoice.Debt = -itemInCart.RetailPrice;
            newInvoice.DebtTitle = itemInCart.Label;
          } else if (itemInCart.itemCustomType === 'PrePayment') {
            newInvoice.PrePayment = -itemInCart.RetailPrice;
            newInvoice.PrePaymentTitle = itemInCart.Label;
          } else if (itemInCart.itemCustomType === 'PrePaymentUsage') {
            newInvoice.PrePayment = -itemInCart.RetailPrice;
            newInvoice.PrePaymentTitle = itemInCart.Label;
          } else if (itemInCart.itemCustomType === 'TaxInvoice') {
            newInvoice.TaxInvoiceIdAndAmounts = newInvoice.TaxInvoiceIdAndAmounts || {};
            newInvoice.TaxInvoiceIdAndAmounts[itemInCart.TaxInvoiceId] = itemInCart.RetailPrice;
          } else if (itemInCart.itemCustomType === 'Tip') {
            itemInList = toTip(itemInCart);
            newInvoice.Tips.push(itemInList);
          } else {
            itemInList = toCustomItem(itemInCart);
            newInvoice.CustomItems.push(itemInList);
          }

          setIfHasValue(itemInList, 'PriceListItemOriginalPrice', itemInCart?.PriceListItemOriginalPrice);
          setIfHasValue(itemInList, 'PriceListId', itemInCart?.PriceListId);
          setIfHasValue(itemInList, 'MeetingStartTime', itemInCart?.MeetingStartTime);
          setIfHasValue(itemInList, 'MeetingId', itemInCart?.MeetingId);
          setIfHasValue(itemInList, 'MembershipDiscountId', getSafely(['membershipDiscount', 'Id'], itemInCart));
        });


        newInvoice.Discount = totalAggregator.discountPercentage;
        newInvoice.TotalAmountBeforeDiscount = totalAggregator.calculatedSubTotal;
        newInvoice.TotalAmount = totalAggregator.subTotal;
        newInvoice.Rounding = totalAggregator.rounding;

        if (priceQuote) {
          newInvoice.IntermediateCartId = priceQuote.IntermediateCartId;
        }

        return newInvoice;
      }

      function toTip(itemInCart) {
        return {
          Id: itemInCart.Id,
          EmployeeId: itemInCart.EmployeeId,
          Amount: itemInCart.RetailPrice
        };
      }

      function toPunchCard(itemInCart) {
        var subscription = {
          Item: {
            Id: itemInCart.Item.Id,
            Quantity: itemInCart.buy + itemInCart.get,
            Price: itemInCart.RetailPrice,
            Type: itemInCart.Item.ConcreteType ? itemInCart.Item.ConcreteType : itemInCart.Item.Type
          },
          PurchasedQuantity: itemInCart.buy,
          GivenQuantity: itemInCart.get,
          ActualPrice: itemInCart.OriginalRetailPrice,
          Title: itemInCart.Label,
          CustomTitle: itemInCart.Label,
          ExpirationDate: itemInCart.ExpirationDate
        };

        decorateEmployeeId(subscription, itemInCart.EmployeeId);
        return subscription;
      }

      function toPunchCardUsage(itemInCart) {
        var subscriptionUsage = {
          SubscriptionId: itemInCart.SubscriptionId,
          ItemId: itemInCart.Id,
          Quantity: itemInCart.Quantity,
          Price: 0,
          ItemType: itemInCart.ConcreteType
        };

        decorateEmployeeId(subscriptionUsage, itemInCart.EmployeeId);
        setComposeItemsToItemIfNeeded(subscriptionUsage, itemInCart);
        return subscriptionUsage;
      }

      function toDefaultItem(itemInCart) {
        var item = {
          Id: itemInCart.Id,
          Type: itemInCart.ConcreteType,
          Quantity: itemInCart.Quantity,
          Price: itemInCart.RetailPrice,
          OriginalRetailPrice: itemInCart.OriginalRetailPrice,
          CustomTitle: itemInCart.Label
        };

        decorateEmployeeId(item, itemInCart.EmployeeId);
        if (itemInCart.Ingredients) {
          item.Ingredients = [];
          angular.forEach(itemInCart.Ingredients, function (ingredient) {
            var ignored = {
              Id: ingredient.ProductMetadataId,
              Quantity: ingredient.Quantity,
              CustomModelValue: ingredient.CustomModelValue
            };

            item.Ingredients.push(ignored);
          });
        }

        setComposeItemsToItemIfNeeded(item, itemInCart);
        return item;
      }

      function toGiftCard(itemInCart) {
        var giftCard = {
          Label: itemInCart.Label,
          Amount: itemInCart.OriginalRetailPrice,
          RetailPrice: itemInCart.RetailPrice,
          ReferenceNumber: itemInCart.ReferenceNumber,
          Template: itemInCart.Template,
          Giver: itemInCart.Giver,
          Greating: itemInCart.Greating,
          SmsSendTime: itemInCart.SmsSendTime,
          AllowOnlineScheduling: itemInCart.AllowOnlineScheduling,
          ShowPrice: itemInCart.ShowPrice,
          PhoneNumber: itemInCart.PhoneNumber,
          Remarks: itemInCart.Remarks,
          SmsMessageContent: itemInCart.SmsMessageContent,
          Quantity: itemInCart.Quantity
        };
        decorateEmployeeId(giftCard, itemInCart.EmployeeId);
        return giftCard;
      }

      function toGiftCardUsage(itemInCart) {
        var giftCardUsage = {
          Label: itemInCart.Label,
          RetailPrice: -itemInCart.RetailPrice,
          Quantity: itemInCart.Quantity,
          GiftCardId: itemInCart.GiftCardId
        };

        decorateEmployeeId(giftCardUsage, itemInCart.EmployeeId);
        return giftCardUsage
      }

      function toExternalVoucher(itemInCart) {
        var externalVoucher = {
          ExternalVoucherMetadataId: itemInCart.ExternalVoucherMetadataId,
          RetailPrice: Math.abs(parseFloat(itemInCart.RetailPrice)),
          ReferenceNumber: itemInCart.ReferenceNumber,
          Remarks: itemInCart.Remarks
        };

        decorateEmployeeId(externalVoucher, itemInCart.EmployeeId);
        return externalVoucher;
      }

      function toCustomItem(itemInCart) {
        var customItem = {
          Label: itemInCart.Label,
          RetailPrice: itemInCart.RetailPrice,
          Quantity: itemInCart.Quantity
        };

        decorateEmployeeId(customItem, itemInCart.EmployeeId);
        return customItem;
      }


      function setComposeItemsToItemIfNeeded(item, itemInCart) {
        if (itemInCart.Composite) {
          item.CompositeServices = itemInCart.ComposedItems.map(function (composedServiceMetadata) {
            return {
              EmployeeId: composedServiceMetadata.EmployeeId,
              ServiceMetadataId: composedServiceMetadata.Id
            }
          })
        }
      }

      function decorateEmployeeId(item, employeeId) {
        if (employeeId) {
          item.EmployeeId = employeeId;
        }
      }
    }]);

}());
