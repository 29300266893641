var filtersModule = angular.module('easybizy.filters', []);

filtersModule.filter('noFractionCurrency', ['$filter', 'localize',
  function (filter, localize) {
    var currencyFilter = filter('currency');
    return function (amount) {
      if (typeof amount === 'number') {
        var currencySymbol = Globalize.culture('he-IL').numberFormat.currency.symbol;
        var value = currencyFilter(amount, currencySymbol);
        var sep = value.indexOf('.');
        if (amount >= 0) {
          return value.substring(0, sep);
        }
      } else {
        return amount;
      }
    };
  }]);


filtersModule.filter('money', ['$filter', 'localize',
  function (filter, localize) {
    return function (amount) {
      var sign = "".toCurrencySymbol(window.k_Currency);
//            var toReturn = Math.max(0, amount);

      var toReturn = amount;
      //if (parseFloat(amount) < 0) {
      //    toReturn = 0;
      //}

      if (!Globalize.culture().isRTL) {
        toReturn = toReturn + sign;
      } else {
        toReturn = sign + toReturn;
      }

      return toReturn;
    };
  }]);

filtersModule.filter('timeString', ['$filter', 'localize', function () {
  return function (input) {
    return input.format('HH:mm');
  };
}]);

filtersModule.filter('date', ['$filter', 'localize', function () {
  return function (input, pattern) {
    if (!input) {
      return "";
    }

    return moment(input).format(pattern);
  };
}]);


filtersModule.filter('positive', ['$filter', 'localize', function () {
  return function (input) {
    return parseFloat(input) >= 0 ? input : 0;
  };
}]);

filtersModule.filter('calendarDayShort', ['$filter', 'localize',
  function (filter, localize) {
    return function (input) {
      if (localize.language === "he-IL") {
        return Globalize.format(input._d, Globalize.culture(localize.language).calendar.patterns.D);
      } else {
        return input.format("ddd D/M");
      }
    };
  }]);

filtersModule.filter('calendarLocalizedDate', ['$filter', 'localize', function () {
  return function (input) {
    return new moment(input).format("LLLL");
  };
}]);

filtersModule.filter('isToday', ['$filter', 'localize', function () {
  return function (input) {
    var current = new moment();
    return current.isSame(input, 'year') && current.isSame(input, 'month') && current.isSame(input, 'day');
  };
}]);

filtersModule.filter('orderObjectBy', function () {
  return function (items, field, reverse) {
    var filtered = [];
    angular.forEach(items, function (item) {
      filtered.push(item);
    });
    filtered.sort(function (a, b) {
      return (a[field] > b[field]);
    });

    if (reverse) filtered.reverse();
    return filtered;
  };
});

filtersModule.filter("trust", ['$sce', function ($sce) {
  return function (htmlCode) {
    return $sce.trustAsHtml(htmlCode);
  }
}]);
