var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "whatsapp-on-boarding-wizard__promotion-editor-content relative",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "text-center whatsapp-on-boarding-wizard__promotion-editor-header",
        },
        [
          _c("h1", { on: { click: _vm.revert } }, [
            _c("span", {
              staticClass:
                "cursor-pointer icon icon-arrow-rightpaint-bucket ms-2 text-xl",
            }),
            _vm._v(_vm._s(_vm.reminderContent.title)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "whatsapp-on-boarding-wizard__promotion-editor-wrapper flex h-full",
        },
        [
          _c("div", { staticClass: "grow flex" }, [
            _c("div", { staticClass: "flex flex-col grow" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex grow whatsapp-on-boarding-wizard__promotion-editor-wrapper-parts",
                },
                [
                  _c(
                    "div",
                    { staticClass: "phase--active" },
                    [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.reminderContent.reminderTimeTitle)),
                      ]),
                      _vm._v(" "),
                      _c("Select", {
                        staticClass:
                          "whatsapp-on-boarding-wizard__promotion-editor-template-dynamic-combobox",
                        attrs: {
                          options: _vm.reminderTimeOptions,
                          "force-selection": true,
                        },
                        model: {
                          value: _vm.reminderTime,
                          callback: function ($$v) {
                            _vm.reminderTime = $$v
                          },
                          expression: "reminderTime",
                        },
                      }),
                      _vm._v(" "),
                      _c("h3", [
                        _vm._v(_vm._s(_vm.reminderContent.whatTimeTitle)),
                      ]),
                      _vm._v(" "),
                      _c("Select", {
                        staticClass:
                          "whatsapp-on-boarding-wizard__promotion-editor-template-dynamic-combobox",
                        attrs: {
                          options: _vm.reminderHourOptions,
                          "force-selection": true,
                        },
                        model: {
                          value: _vm.reminderHour,
                          callback: function ($$v) {
                            _vm.reminderHour = $$v
                          },
                          expression: "reminderHour",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "mt-2 text-sm" }, [
                        _vm._v("* " + _vm._s(_vm.firstCampaignStartTimeString)),
                      ]),
                      _vm._v(" "),
                      _vm.phase === 1
                        ? _c("div", [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "whatsapp-on-boarding-wizard__promotion-editor-next",
                                on: { click: _vm.nextPhase },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.nextText) + "\n              "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "whatsapp-on-boarding-wizard__promotion-editor-templates",
                      class: _vm.phase > 1 && "phase--active",
                    },
                    [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.reminderContent.templateTitle)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-col whatsapp-on-boarding-wizard__promotion-editor-templates-picker",
                        },
                        _vm._l(_vm.templates, function (template) {
                          return _c(
                            "button",
                            {
                              staticClass:
                                "whatsapp-on-boarding-wizard__promotion-editor-template",
                              class:
                                template.value === _vm.selectedTemplate.value
                                  ? "whatsapp-on-boarding-wizard__promotion-editor-template--selected"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.selectTemplate(template)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(template.name) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.phase === 2
                        ? _c("div", [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "whatsapp-on-boarding-wizard__promotion-editor-next",
                                on: { click: _vm.nextPhase },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.nextText) + "\n              "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { class: _vm.phase > 2 && "phase--active" }, [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.reminderContent.templateDynamicTitle)),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mt-2 text-sm" }, [
                      _vm._v(
                        "* " +
                          _vm._s(_vm.reminderContent.templateDynamicSubTitle)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.lastTemplateValue,
                          expression: "lastTemplateValue",
                        },
                      ],
                      staticClass:
                        "whatsapp-on-boarding-wizard__promotion-editor-template-dynamic",
                      attrs: {
                        placeholder: _vm.reminderContent.templateDynamicTitle,
                      },
                      domProps: { value: _vm.lastTemplateValue },
                      on: {
                        blur: _vm.removeNewLines,
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.lastTemplateValue = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.rules.formIfRequired
                      ? _c(
                          "div",
                          { staticClass: "mt-4" },
                          [
                            _c("Checkbox", {
                              attrs: {
                                text: _vm.reminderContent.formIfRequired,
                              },
                              model: {
                                value: _vm.formIfRequired,
                                callback: function ($$v) {
                                  _vm.formIfRequired = $$v
                                },
                                expression: "formIfRequired",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.rules.allowOnlineBooking
                      ? _c(
                          "div",
                          { staticClass: "mt-4" },
                          [
                            _c("Checkbox", {
                              attrs: {
                                text: _vm.reminderContent.allowOnlineBooking,
                              },
                              model: {
                                value: _vm.allowOnlineBooking,
                                callback: function ($$v) {
                                  _vm.allowOnlineBooking = $$v
                                },
                                expression: "allowOnlineBooking",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "grow justify-center flex" }, [
                _c(
                  "div",
                  { staticClass: "flex justify-center align-start" },
                  [
                    _c("transition", { attrs: { name: "slide-bottom" } }, [
                      _vm.phase > 2
                        ? _c("div", { staticClass: "flex flex-col" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "whatsapp-on-boarding-wizard__send-promotion-button background--primary",
                                on: { click: _vm.confirmCampaignAndSend },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.saveCampaignText) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "text-center mt-1 underline text-sm",
                                attrs: {
                                  target: "_blank",
                                  href: "https://auth.easybizy.net/terms-of-use#whatsapp",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("_AccordingToTermsOfUse_"))
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "h-full" },
            [
              _c("whatsapp-message", {
                attrs: { message: _vm.adaptedTemplate },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }