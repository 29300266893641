import './review.sender.modal.less';

const AUTO_SEND_REVIEW_COMBO_VALUES =
  [{ 'name': '_Never_', 'value': 'Never' },
    { 'name': '_FirstTreatment_', 'value': 'FirstTreatment' },
    { 'name': '_FirstVisit_', 'value': 'FirstVisit' },
    { 'name': '_OnceAMonth_', 'value': 'OnceAMonth' },
    { 'name': '_OnceEvery3Months_', 'value': 'OnceEvery3Months' },
    { 'name': '_OnceEvery6Month_', 'value': 'OnceEvery6Month' },
    { 'name': '_EveryTreatment_', 'value': 'EveryTreatment' },
    { 'name': '_EveryTime_', 'value': 'EveryTime' }];

const NEVER_SEND_AUTOMATICALLY = 'Never';

const DELAY_SENDING_COMBO_VALUES = [
  { 'name': '_None_', 'value': '0' },
  { 'name': '_NumOfMinutes_', 'params': [5], 'value': '5' },
  { 'name': '_NumOfMinutes_', 'params': [10], 'value': '10' },
  { 'name': '_QHour_', 'value': '15' },
  { 'name': '_HalfAnHour_', 'value': '30' },
  { 'name': '_Hour_', 'value': '60' },
  { 'name': '_Day_', 'value': '1440' }
];

const MALE_IMAGE = 'https://files.easybizy.net/files/__feedback/public/review_man.png';
const FEMALE_IMAGE = 'https://files.easybizy.net/files/__feedback/public/review.png'


angular.module('easybizy.easybizyModalViewsModel').service('reviewsService', function ($modal) {
  this.showFeedback = (customer, callback) => {
    var opts = {
      backdrop: 'static',
      keyboard: true,
      template: require('./review.sender.modal.tpl.html'),
      controller: 'FeedbackSenderController',
      resolve: {
        saveAction: function () {
          return function (newOrUpdatedMembership, closeDialogDelegate) {

            closeDialogDelegate();
          };
        },
        parameters: function () {
          return {
            customerName: `${ customer.FirstName || '' } ${ customer.LastName }`,
            customerId: customer.CustomerId,
            invoiceId: customer.invoiceId,
            gender: customer.Gender,
            callback
          };
        }
      }
    };

    $modal.open(opts);
  }

});

angular.module('easybizy.easybizyModalViewsModel').controller('FeedbackSenderController',
  function ($scope, $modalInstance, saveAction, $timeout, $http, localize, Repository, parameters, configuration) {

    const feedbackSettings = configuration.get().FeedbackSettings;
    $scope.message = feedbackSettings.DefaultFeedbackSMSMessage.Value;

    $scope.genderValues = [
      { name: localize.getLocalizedString("_Female_"), value: 'Female' },
      { name: localize.getLocalizedString("_Male_"), value: 'Male' }
    ];

    const configGender = $scope.genderValues.filter(x => x.value === parameters.gender)[0];
    $scope.gender = { value: configGender };

    $scope.imageSource = configGender === 'Male' ? MALE_IMAGE : FEMALE_IMAGE;

    $scope.$watch('gender.value', (gender) => {
      $scope.imageSource = gender.value === 'Male' ? MALE_IMAGE : FEMALE_IMAGE;
    })

    $scope.minutesDelay = 0;

    $scope.cancel = function () {
      $modalInstance.close();
      if (parameters.callback) {
        parameters.callback();
      }
    };

    $scope.isLoadingWrapper = { isLoading: false }
    // Automatically.
    $scope.senderReviewAutomaticallyWrapper = { sendReview: feedbackSettings.AutoSendFeedback.Value !== NEVER_SEND_AUTOMATICALLY }

    $scope.autoSendReviewValues = AUTO_SEND_REVIEW_COMBO_VALUES.map(({ name, value }) => ({
      name: localize.getLocalizedString(name),
      value
    }));

    $scope.selectedAutoSend = { selected: $scope.autoSendReviewValues.filter(x => x.value === feedbackSettings.AutoSendFeedback.Value)[0] }

    /// Delay
    $scope.delaySendingValues = DELAY_SENDING_COMBO_VALUES.map(({ name, value, params }) => ({
      name: localize.getLocalizedString(name, params ? params[0] : ''),
      value
    }));

    $scope.selectedDelay = { selected: $scope.delaySendingValues.filter(x => x.value === feedbackSettings.SendFeedbackDelay.Value)[0] }


    $scope.dontShowAgainWrapper = { selected: false };
    $scope.$watch('dontShowAgainWrapper.selected', function (dontShowAgain, prev) {
      if (dontShowAgain !== prev) {
        Repository.Custom("CustomersFeedback").hideFeedbackDialog(dontShowAgain)
          .then(function (data) {

          })
          .catch(function (err) {
          });
      }
    });

    $scope.$watch('selectedAutoSend.selected',
      function(period, prev) {
        if (period && prev && period.value !== prev.value && period.value !== NEVER_SEND_AUTOMATICALLY) {
          $scope.dontShowAgainWrapper.selected = true;
        }
      });


    $scope.send = () => {
      const feedbackConfigurations = {
        AutoSendFeedback: $scope.selectedAutoSend.selected.value,
        SendFeedbackDelay: $scope.selectedDelay && $scope.selectedDelay.selected ? $scope.selectedDelay.selected.value : $scope.delaySendingValues[0].value,
        HideFeedbackDialog: $scope.dontShowAgainWrapper.selected
      }

      if (!$scope.senderReviewAutomaticallyWrapper.sendReview) {
        feedbackConfigurations.AutoSendFeedback = NEVER_SEND_AUTOMATICALLY;
      }

      /// If auto send turned on, we turn the show dialog off.
      //if (feedbackConfigurations.AutoSendFeedback !== NEVER_SEND_AUTOMATICALLY) {
      //  feedbackConfigurations.HideFeedbackDialog = true;
      //}


      $scope.isLoadingWrapper.isLoading = true;
      Repository.Custom("CustomersFeedback").sendFeedback({
        InvoiceId: parameters.invoiceId,
        CustomerId: parameters.customerId,
        Gender: $scope.gender.value.value,
        MinutesDelay: feedbackConfigurations.SendFeedbackDelay,
        Message: $scope.message,
        Settings: feedbackConfigurations.AutoSendFeedback !== NEVER_SEND_AUTOMATICALLY ? feedbackConfigurations : null
      })
        .then(function (data) {
          toastr.success(localize.getLocalizedString('_FeedbackSent_'));
          $scope.cancel();
          $scope.isLoadingWrapper.isLoading = false;

        })
        .catch(function (err) {
          $scope.isLoadingWrapper.isLoading = true;

          if (parameters.callback) {
            parameters.callback();
          }

          toastr.error(err.data.ExceptionMessage);

        });
    };
  });
