import './cash.register.debt.tax.invoices.less';
import { DATE_DISPLAY_FORMAT, SERVER_DATA_DATE_FORMAT } from "../../constants";
import { DelayedFunction } from "../../general/helpers";

angular.module('easybizy.easybizyModalViewsModel').controller('CashRegisterDebtTaxInvoicesModalController',
  function ($scope, customerId, customerName, customerDebt, saveAction, $modalInstance, $timeout, $http, localize, Repository) {

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.tabs = [{
      name: customerName,
      showLoading: false
    }, { name: localize.getLocalizedString('_OtherOpenInvoices_'), showLoading: true }];
    $scope.selectedTabWrapper = { selected: $scope.tabs[0] };

    // Customer related tax invoices.
    $scope.isLoading = true;
    Repository.Custom('CashRegister').taxInvoiceDebts(customerId, false).then(function (data) {
      $scope.isLoading = false;
      $scope.taxInvoices = [...data];
      let baseDebtValue = 0;
      $scope.taxInvoices.forEach((taxInvoice, idx) => {
        visitTaxInvoice(taxInvoice);
        taxInvoice.name = customerName;

        if (idx === 0) {
          baseDebtValue = taxInvoice.Debt;
        }

        baseDebtValue -= taxInvoice.amountToPay;
      });

        if ($scope.taxInvoices.length === 0) {
        baseDebtValue = (customerDebt && parseFloat(customerDebt)) || 0;
      }

      if (baseDebtValue > 0) {
        $scope.debtWithoutInvoiceToPay = [{
          CustomerId: customerId,
          Amount: baseDebtValue,
          amountToPay: baseDebtValue,
          _amountToPay: baseDebtValue,
          type: 'Debt',
          idx: -999,
          name: localize.getLocalizedString('_DebtWithoutTaxInvoice_')
        }];
      }
    }).catch(function (error) {
      $scope.isLoading = false;
      return toastr.error(localize.getLocalizedString("_ErrorLoadingModule_", error.Message));
    });

    $scope.totalWrapper = {};
    Object.defineProperty($scope.totalWrapper, 'total', {
      get: function () {
        return $scope.entitiesToAdd.reduce((acc, entity) => acc + parseFloat(entity.amountToPay), 0);
      }
    });

    Object.defineProperty($scope, 'noCustomerInvoicesOrDebts', {
      get: function () {
        return !$scope.isLoading && (Array.isArray($scope.taxInvoices) && $scope.taxInvoices.length === 0) &&
          (!$scope.debtWithoutInvoiceToPay)
      }
    });

    $scope.entitiesToAdd = [];
    $scope.taxInvoiceFields = [
      {
        name: localize.getLocalizedString("_InvoiceCreatedOn_"),
        fieldName: 'CreatedOn',
        width: '15%',
        orderedBy: true,
        isDecending: true,
        command: {
          get: function (entity) {
            return moment(entity.CreatedOn, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT)
          },
          sort: () => 'CreatedOn'
        }
      },
      {
        name: localize.getLocalizedString("_TaxInvoiceNumber_"),
        fieldName: 'TaxInvoiceNumber',
        width: '18%',
        command: {
          get: function (entity) {
            return entity.TaxInvoiceNumber || kInvoiceYetToBeGenerated;
          }
        }
      },
      {
        name: localize.getLocalizedString("_Amount_"),
        type: 'Money',
        width: '18%',
        fieldName: 'Amount'
      },
      {
        name: localize.getLocalizedString("_Paid_"),
        type: 'Money',
        width: '18%',
        fieldName: 'PaidAmount'
      },
      {
        name: localize.getLocalizedString("_AmountToPay_"),
        width: '20%',
        type: 'NumberWithHandlers',
        valueType: 'float',
        fieldName: 'amountToPay',
        command: {
          customDelta: function (model) {
            return (model.amountToPay * 0.05).toFixed(2);
          },
        },
        allowMinus: function () {
          return false;
        },
        max: (model) => {
          return model._amountToPay;
        }
      },
      {
        name: '',//localize.getLocalizedString("_Actions_"),
        type: "Custom",
        width: '11%',
        template: `<div class="table-actions-wrapper">
                        <div>
                            <div ng-click="value(this, 'add')" class="icon icon-add"></div>
                        </div>
                     </div>`,
        command: {
          get: function (entity) {
            return function (scope, action) {
              if (action === 'add') {
                // Regular debt;
                const duplicated = { ...entity, amountToPay: entity._amountToPay };
                let index = 0;
                if (!entity.TaxInvoiceId) {
                  $scope.debtWithoutInvoiceToPay.length = 0;
                  entity.remove = () => {
                    $scope.debtWithoutInvoiceToPay.push(duplicated);
                    $scope.entitiesToAdd.remove(entity)
                  }
                } else if (entity.CustomerId === customerId) {
                  index = $scope.taxInvoices.indexOf(entity);
                  $scope.taxInvoices.remove(entity);
                  entity.remove = () => {
                    $scope.taxInvoices.splice(index, 0, duplicated);
                    $scope.entitiesToAdd.remove(entity)
                  }
                } else {
                  index = $scope.otherTaxInvoices.indexOf(entity);
                  $scope.otherTaxInvoices.remove(entity);
                  entity.remove = () => {
                    $scope.otherTaxInvoices.splice(index, 0, duplicated);
                    $scope.entitiesToAdd.remove(entity)
                  }
                }

                $scope.entitiesToAdd.push(entity);
              }
            };
          }
        }
      }
    ];

    $scope.debtNoTaxInvoiceFields = [{ ...$scope.taxInvoiceFields[2], width: '25%' },
      { ...$scope.taxInvoiceFields[4], width: '24%' }, { ...$scope.taxInvoiceFields[5], width: '51%' }];

    $scope.totalFields = [
      {
        name: localize.getLocalizedString("_RelatedTo_"),
        fieldName: 'name',
        width: '40%'
      },
      {
        name: localize.getLocalizedString("_AmountToPay_"),
        width: '43%',
        type: 'NumberWithHandlers',
        valueType: 'float',
        fieldName: 'amountToPay',
        command: {
          customDelta: function (model) {
            return (model.amountToPay * 0.05).toFixed(2);
          },
        },
        allowMinus: function () {
          return false;
        },
        max: (model) => {
          return model._amountToPay;
        }
      },
      {
        name: localize.getLocalizedString("_Actions_"),
        type: "Custom",
        width: '17%',
        template: `<div class="table-actions-wrapper">
                        <div>
                            <div ng-click="value(this, 'remove')" class="icon icon-flat-close"></div>
                        </div>
                     </div>`,
        command: {
          get: function (entity) {
            return function (scope, action) {
              if (action === 'remove') {
                entity.remove();
              }
            };
          }
        }
      }

    ];

    // Other Tax invoices
    $scope.searchTaxInvoiceWrapper = { search: '' };
    $scope.searchFieldMetadata = {
      fieldName: 'search', icon: "icon icon-invoice", placeholder: localize.getLocalizedString("_SearchInvoice_"),
    };

    $scope.otherTaxInvoices = [];

    const searchOtherInvoices = new DelayedFunction(function (searchString) {
      $scope.$evalAsync(() => searchTaxInvoices(searchString));
    }, 500);

    $scope.$watch('searchTaxInvoiceWrapper.search', (newVal) => {
      searchOtherInvoices.set(newVal);
    });

    $scope.searchInvoiceWrapper = { searching: false };
    // Duplicating so we can change them.
    $scope.otherTaxInvoiceFields = $scope.taxInvoiceFields.map(entity => ({ ...entity }));

    $scope.otherTaxInvoiceFields.forEach(field => {
      field.width = `${ (parseInt(field.width.replace('%', '')) - 2) }%`;
    });

    $scope.otherTaxInvoiceFields.splice(1, 0, {
      name: localize.getLocalizedString("_Customer_"),
      fieldName: 'name',
      width: '12%'
    });

    $scope.generalTaxInvoiceApi = Repository.Custom('CashRegister').generalTaxInvoiceDebts(customerId);
    $scope.generalTaxInvoiceApi.preventAutoLoad = true;
    delete $scope.generalTaxInvoiceApi.clear;

    $scope.generalTaxInvoiceApi.doneLoadingCallback = function (invoices) {
      invoices.forEach(visitTaxInvoice);
    };

    function searchTaxInvoices(search) {
      $scope.generalTaxInvoiceApi.queryParams('search', search);
      $scope.generalTaxInvoiceApi.forceUpdate();

      // $scope.searchInvoiceWrapper.searching = true;
      // Repository.Custom('CashRegister').generalTaxInvoiceDebts(query, customerId).then(function (data) {
      //   $scope.searchInvoiceWrapper.searching = false;
      //   $scope.otherTaxInvoices = [...data];
      //   $scope.otherTaxInvoices.forEach(visitTaxInvoice);
      // }).catch(function (error) {
      //   $scope.searchInvoiceWrapper.searching = false;
      //   return toastr.error(localize.getLocalizedString("_ErrorLoadingModule_", error.Message));
      // });
    }

    function visitTaxInvoice(taxInvoice) {
      taxInvoice.Debt = parseFloat(taxInvoice.Debt);
      taxInvoice.Amount = parseFloat(taxInvoice.Amount);
      taxInvoice.PaidAmount = parseFloat(taxInvoice.PaidAmount);
      taxInvoice.idx = taxInvoice.TaxInvoiceId;
      taxInvoice.type = 'TaxInvoice';
      taxInvoice.amountToPay = taxInvoice._amountToPay = taxInvoice.Amount - taxInvoice.PaidAmount;
      taxInvoice.name = taxInvoice.CustomerId === customerId ? customerName : (taxInvoice.InvoiceRefersTo || localize.getLocalizedString('_UnknownCustomer_'))
    }

    $scope.save = () => {
      $scope.cancel();
      saveAction($scope.entitiesToAdd);
    }
  });
