import './payment.links.search.modal.less'
import { extractError } from "@tomeravni/easybizy-js-common/errors";

angular.module('easybizy.easybizyModalViewsModel').controller('PaymentLinksModalController',
	function ($scope, $rootScope, $modalInstance, localize, Repository) {

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};

		$scope.mediator = {
			close: $scope.cancel,
			clearCashRegister: () => {
				$rootScope.$emit('clear-cash-register');
			},
			deleteSession: (sessionId) => {
				$rootScope.$emit('page-in-progress', true);
				return Repository.Custom('Sessions').clearSession(sessionId).then((res) => {
					toastr.success(localize.getLocalizedString('_EntityDeleteSuccessfully_'));
					$rootScope.$emit('session-removed', {
						sessionId: sessionId
					});

					$rootScope.$emit('page-in-progress', false);
					return res;
				}).catch((err) => {
					toastr.error(extractError(err));
					$rootScope.$emit('page-in-progress', false);
					return err;

				})
			}
		}
	});
