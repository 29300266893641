import './droppable-table.directive.less';

angular.module('easybizy.common.droppable.table').directive('droppableTable', function ($timeout, measurements) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      fields: '=',
      composedFields: '=',
      models: '=',
      isLoadingWrapper: '=?',
      placeholders: '=',
      externalRowDecorator: '&decorator',
      preventLoadingAnimation: '=',
      selectable: '=',
      selectedWrapper: '=',
      api: '=',
      zebraStripes: '=',
      grayscaleImages: '=',
      selectAll: '=',
      allowRemovingRows: '@',
      autoManageRows: '=', /*This will always keep selected rows and select dirty rows by default.*/
      onRemove: '&',
      markedEntitiesChangedDelegate: '&',
      trackBy: '=',
      trackDirtyEntities: '@'
    },
    template: require('./droppable-table.directive.tpl.html'),
    controller: function ($scope) {
      Object.defineProperty(this, 'selectAll', {
        get: function () {
          return $scope.selectAll;
        },
        set: function (newValue) {
          $scope.selectAll = newValue;
          if ($scope.markedEntitiesChangedDelegate()) {
            $scope.markedEntitiesChangedDelegate()();
          }
        }
      });

      this.selectionChanged = function (newVal) {
        if (!newVal && this.selectAll) {
          this.selectAll = false;
        }

        if ($scope.markedEntitiesChangedDelegate()) {
          $scope.markedEntitiesChangedDelegate()();
        }
      };

      this.getModels = function () {
        return $scope.models;
      };

      this.removable = $scope.allowRemovingRows == "true";
      this.getFields = function () {
        return $scope.fields;
      };

      Object.defineProperty(this, 'composedFields', {
        get: function () {
          return $scope.composedFields;
        }
      });

      this.getExternalRowDecorator = function () {
        return $scope.externalRowDecorator();
      };

      this.removeRow = function (item, $event) {
        return $scope.removeRow(item, $event);
      };
    },
    require: '^droppableTable',
    link: function (scope, element, attrs, droppableTableCtrl) {
      scope.isLoadingWrapper = scope.isLoadingWrapper || {};
      if (scope.trackBy) {
        scope.trackByFunction = angular.isFunction(scope.trackBy) ? scope.trackBy : function (item) {
          return item[scope.trackBy];
        };
      } else if (attrs.hasOwnProperty('trackByRandom')) {
        scope.trackByFunction = function (item) {
          return Math.random() * Math.random() * Math.random();
        }
      } else {
        scope.trackByFunction = function (item) {
          return item;
        }
      }

      var injectedCtrl = {};
      injectedCtrl['droppableTable'] = { instance: droppableTableCtrl };

      // paging for data loading
      var eachPageLength = 10; //element[0].offsetHeight
      // Calculate minumum number of lines so lazy will work.
      try {
        var tmpPageLength = element[0].offsetHeight / measurements.emToPx(4 /*Line height*/, element[0]);
        eachPageLength = Math.max(Math.round(tmpPageLength), eachPageLength);
        console.log('Table render default ' + eachPageLength + ' lines.')
      } catch (e) {

      }

      var currentPage = 0;
      var isDirty = false;
      var updateData = null; // this variables gets the data using the api.

      scope.selectRow = function (model) {
        if (scope.selectable) {
          scope.selectedWrapper.selected = model;
        }
      };

      scope.nextPage = function () {
        if (updateData) {
          updateData();
        }
      };

      scope.$watch("selectAll", function (newVal) {
        if (newVal === true) {
          element.addClass('select-all-mode');
        } else {
          element.removeClass('select-all-mode');
        }
      });

      var apiWatcher = scope.$watch('api', function (newVal) {
        if (newVal) {
          apiWatcher();
          var isWaitingForApi = false;
          var toApplyWhenApiDone = null;
          eachPageLength = newVal.pageSize ? newVal.pageSize : eachPageLength;
          let customTop, customVisibleIndex, customPage;

          if (newVal.visibleIndex && newVal.visibleIndex > eachPageLength) {
            customVisibleIndex = newVal.visibleIndex;
            currentPage = (Math.floor(customVisibleIndex / eachPageLength) + 2);
            customPage = true;
            customTop = currentPage * eachPageLength;
            delete newVal.visibleIndex;
          }

          updateData = function (callback) {
            if (!isDirty && currentPage === -1) { // no more to load.
              return;
            }

            if (isWaitingForApi) {
              toApplyWhenApiDone = updateData;
              return;
            }

            isWaitingForApi = true;

            var clearTable = false;
            if (isDirty) {
              currentPage = 0;
              isDirty = false;
              clearTable = true;
              $(element.find('.droppable-table-content-part')).animate({ scrollTop: 0 });
            }

            if (scope.api.hasOwnProperty('clear')) {
              scope.api.clear(); // clears the top, skip and filters.
            }

            if (scope.isLoadingWrapper) {
              scope.isLoadingWrapper.isLoading = true;
            }

            const sortBy = scope.fields.filter(x => x.orderedBy)[0];
            if (sortBy) {
              scope.api.orderBy(sortBy.command.sort(), sortBy.isDecending);
            }

            scope.api.top(customTop || eachPageLength);
            scope.api.skip(eachPageLength * (customPage ? 0 : currentPage));
            if (angular.isDefined(scope.api.filters)) {
              scope.api.filter(scope.api.filters);
            }

            if (angular.isDefined(scope.api.selectedIds)) {
              scope.api.ids(scope.api.selectedIds);
            }

            customTop = null;
            customPage = null;
            scope.api.get().then(function (data) {
              if (!scope.trackBy && (currentPage == 0 || clearTable)) {
                if (scope.autoManageRows) {
                  var selectedRows = scope.models.filter(function (item) {
                    return item.isSelected;
                  });

                  scope.models.length = 0;
                  if (selectedRows.length > 0) {
                    scope.models.push.apply(scope.models, selectedRows);
                  }
                } else {
                  scope.models.length = 0;
                }

                clearTable = false;
              }

              if (scope.api.modelsDecorator) {
                data = scope.api.modelsDecorator(data);
              }


              // This means it has to be unique.
              if (scope.trackBy) {
                var currentItemsTrackedBy = scope.models.map(function (item, idx) {
                  return scope.trackByFunction(item, idx);
                });

                var oldModels = scope.models.slice();

                var clearedTable = false;
                if (currentPage == 0 || clearTable) {
                  scope.models.length = 0;
                  clearTable = false;
                  clearedTable = true;

                  if (scope.autoManageRows) {
                    oldModels.forEach(function (item) {
                      if (item.isSelected) {
                        scope.models.push(item);
                      }
                    })
                  }
                }

                data.forEach(function (item, idx) {
                  var indexOfItemInExistingItems = currentItemsTrackedBy.indexOf(scope.trackByFunction(item, scope.models.length + idx));
                  if (indexOfItemInExistingItems < 0 && scope.models.indexOf(item) < 0) {
                    scope.models.push(item);
                  } else if (clearedTable && scope.models.indexOf(oldModels[indexOfItemInExistingItems]) < 0) { // item was in table before clear.
                    scope.models.push(oldModels[indexOfItemInExistingItems]);
                  }
                });
              } else {
                scope.models.pushAll.apply(scope.models, data);
              }


              if (data.length < eachPageLength) {
                currentPage = -1;
              } else {
                currentPage++;
              }


              if (scope.selectAll === true) {
                angular.forEach(scope.models, function (entity) {
                  droppableTableCtrl.setCheckboxValue(entity, true);
                });
              }

              if (scope.markedEntitiesChangedDelegate()) {
                scope.markedEntitiesChangedDelegate()();
              }


              if (scope.api.doneLoadingCallback) {
                scope.$evalAsync(function () {
                  scope.api.doneLoadingCallback(scope.models);
                });
              }

              isWaitingForApi = false;
              if (toApplyWhenApiDone) {
                var toApply = toApplyWhenApiDone;
                toApplyWhenApiDone = null;
                toApply();
              }

              if (scope.isLoadingWrapper) {
                $timeout(function () {
                  scope.isLoadingWrapper.isLoading = false;
                });
              }

              if (angular.isFunction(callback)) {
                $timeout(callback);
              }

              if (customVisibleIndex) {
                $timeout(() => {
                  try {
                    let scrollTop = element.find(`table tr:nth-child(${ customVisibleIndex })`)[0].offsetTop - 100;
                    element.find('.droppable-table-content-part')[0].scrollTop = scrollTop;
                    customVisibleIndex = null;
                  } catch (e) {
                    /// Didn't work, wtd
                  }
                })
              }
            }).catch((e) => {
              isWaitingForApi = false;
            });
          };
          if (!newVal.preventInitializtionLoad) {
            updateData();
          }

          newVal.forceUpdate = function (callback) {
            isDirty = true;
            updateData(callback);
          }

        }
      })


      scope.$watch('placeholders.length', function (changed) {
        if (angular.isDefined(changed)) { // first time it is raised is undefined
          scope.placeholders.sort(function (a, b) {
            return b.isVisible - a.isVisible;
          });
        }
      });

      scope.orderChanged = function (changed) {
        angular.forEach(scope.fields, function (val) {
          if (val != changed) {
            val.orderedBy = false;
            val.isDecending = false;
          }
        });

        isDirty = true;
        updateData();
      };


      scope.removeRow = function (item, $event) {
        if (typeof item !== 'undefined' && scope.allowRemovingRows == "true") {
          //var toRemove = $($event.currentTarget);
          scope.models.remove(item);
          if (scope.onRemove()) {
            scope.onRemove()(item);
          }
        }
      };
    }
  };
});

angular.module('easybizy.common.droppable.table').directive('droppableTableHeaderItem', [
  '$compile', '$timeout', function ($compile, $timeout) {
    var checkboxTemplate = '<div class="relative-container droppable-table-column-content-wrapper">\
                            <checkbox model="checkboxValueWrapper" field="selectAll" clicked="onCheckboxClicked"></checkbox>\
                          </div>';

    return {
      restrict: 'E',
      replace: true,
      scope: true,
      require: '^droppableTable',
      link: function (scope, element, attrs, droppableTableCtrl) {

        // Is Checkbox
        if (scope.field.type === "Checkbox" && scope.field.allowSelectAll) {
          scope.checkboxValueWrapper = droppableTableCtrl;
          scope.onCheckboxClicked = updateCheckbox;

          var checkbox = $compile(checkboxTemplate)(scope);
          element.replaceWith(checkbox);
          if (droppableTableCtrl.isAllSelected) {
            scope.checkboxValueWrapper.value = true;
          }

          droppableTableCtrl.setCheckboxValue = function (entity, value) {
            entity[scope.field.fieldName] = value;
          };


          function updateCheckbox(newVal) {
            angular.forEach(scope.models, function (rowEntity) {
              rowEntity[scope.field.fieldName] = newVal;
            });
          }

        }

        scope.$watch('field.orderedBy', function (value) {
          if (typeof something !== "undefined") {
            scope.orderChanged(scope.field);
          }
        });

        scope.orderColumnBy = function () {
          if (scope.field.orderedBy) {
            scope.field.isDecending = !scope.field.isDecending;
          } else {
            scope.field.orderedBy = true;
          }

          scope.orderChanged(scope.field);
        };


      },
      template: `<div class="relative-container droppable-table-header-column-wrapper">
                        <div class="relative-container">
                            <div class="centered-div-container">
                                <div class="centered-div-content left-text-aligned">
                                    <div class="droppable-table-item-content">
                                        <div class="header-name flex-header">{{::field.name}}<div ng-if="field.tooltip" class="icon-padding"><span class="glyphicon glyphicon-question-sign" tippy="{{ field.tooltip }}"></span></div></div>
                                        <div class="header-sort-direction" ng-if="field.sortable" ng-click="orderColumnBy()"
                                            ng-class="{\'sorted-by-column\': field.orderedBy}">
                                            <span ng-class="{\'glyphicon-chevron-up\': field.isDecending, \'glyphicon-chevron-down\': !field.isDecending}" class="glyphicon"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>`
    };
  }]);

angular.module('easybizy.common.droppable.table').directive('droppableTableRowItem', function ($timeout, $compile) {
  var template = '<td>\
                        <div class="relative-container droppable-table-column-content-wrapper">\
                            <droppable-table-column-content content="columnContent" external-content="externalContent">\
                            </droppable-table-default-column-content>\
                        </div>\
                    </td>';

  var removeRowTemplate = '<div class="table-row-remove-icon icon icon-flat-close" ng-click="remove($event)"></div>';

  return {
    restrict: 'EA',
    scope: {
      item: '=',
      childRow: '='
    },
    require: '^droppableTable',
    link: function (scope, element, attrs, droppableTableCtrl) {
      scope.columnsContent = [];
      var fields = scope.childRow ? droppableTableCtrl.composedFields : droppableTableCtrl.getFields();
      angular.forEach(fields, function (fieldValue) {
        scope.columnsContent.push({
          field: fieldValue,
          model: new ColumnContent(scope.item, fieldValue.command)
        });
      });


      scope.remove = function ($event) {
        $event.currentTarget = element;
        droppableTableCtrl.removeRow(scope.item, $event);
      };

      var injectedCtrl = {};
      injectedCtrl['droppableTable'] = { instance: droppableTableCtrl };

      var toAppend = [];

      scope.columnsContent.forEach(function (columnContent, index) {
        var newScope = scope.$new();
        newScope.columnContent = columnContent;

        var td = $compile(template)(newScope, undefined, { transcludeControllers: injectedCtrl }).css('width', fields[index].width);
        if (droppableTableCtrl.removable && (index == scope.columnsContent.length - 1)) {
          td.first().prepend($compile(removeRowTemplate)(newScope));
        }

        toAppend.push(td);
      });

      element.append(toAppend);

      if (scope.item.Composite && scope.item.ComposedItems) {
        scope.item.children = [];
        scope.item.ComposedItems.forEach(function (compositeService) {
          var internalRow = $('<tr class="child-row"><td colspan="45"><table><tr><td class="indentation"><div class="icon icon-arrow-down-left"></div></td><td class="internal-content"><table></table></td></tr></table></td></tr>');
          var newScope = scope.$new();
          compositeService.getParent = function () {
            return scope.item;
          };

          newScope.item = compositeService;
          var compiledNewRow = $compile('<tr droppable-table-row-item child-row="true" item="item"></tr>')(newScope, undefined, { transcludeControllers: injectedCtrl });
          internalRow.find('.internal-content > table').append(compiledNewRow);
          element.parent().addClass('composite-row').append(internalRow)
        });
      }

      if (!scope.childRow) {
        var decorator = droppableTableCtrl.getExternalRowDecorator();
        if (decorator) {
          decorator(scope, element);
        }
      }
    }
  }
});

angular.module('easybizy.common.droppable.table').directive('droppableTableColumnContent', [
  'columnContentVisitor', function (columnContentVisitor) {
    return {
      restrict: 'E',
      scope: {
        content: '=',
        externalContent: '='
      },
      require: '^droppableTable',
      replace: true,
      link: function (scope, element, attrs, droppableTableCtrl) {
        if (scope.content.field.hasOwnProperty("conditionalCreation")
          && !scope.content.field.conditionalCreation(scope.content.model)) {
          return;
        }

        columnContentVisitor.visit(scope, element, scope.content.field.type, scope.content.field.template,
          droppableTableCtrl, scope.content.field.extendScopeWith);
      },
      template: '<div class="relative-container">\
                    </div>'
    };
  }]);


angular.module('easybizy.common.droppable.table').factory('columnContentVisitor', function ($compile, $timeout) {

  var contentType = {
    ImageWithValue: function (scope, element, template) {
      imageWithValue(scope, element, true, template);
    },
    IconWithValue: function (scope, element, template) {
      imageWithValue(scope, element, false, template);
    },
    NumberWithHandlers: numberWithHandlers,
    Checkbox: checkboxDecorator,
    Money: moneyDecorator,
    MoneyWithDiscount: moneyWithDiscountDecorator,
    Custom: customDecorator,
    ReadWritePrice: toggleablePriceEditor,
    ReadWriteText: readWriteInput,
    WritableTextbox: writableTextbox,
    Combobox: combobox,
    Time: timepicker,
    Rank: rank,
    EditableNotes: editableNotes,
    Default: defaultDecorator,
    Textarea: textareaDecorator
  };

  return {
    visit: function (scope, element, type, template, ctrl, extendScopeWith) {
      if (contentType.hasOwnProperty(type)) {
        contentType[type](scope, element, template, ctrl, extendScopeWith);
      } else {
        contentType['Default'](scope, element);
      }

      try {
        var fieldName = scope.content.field.fieldName;
        if (fieldName) {
          element.addClass('column-for-field-' + fieldName.toDash().toLowerCase())
        }

      } catch (e) {
      }
    }
  };

  function imageWithValue(scope, element, isImage) {
    var model = scope.content.model.get();
    scope.valuesWrapper = {};
    Object.defineProperty(scope.valuesWrapper, 'name', {
      get() {
        return scope.content.model.get().text;
      }
    })

    scope.valuesWrapper.icon = model.image;

    if (scope.content.field.command && scope.content.field.command.dynamicIcon) {
      scope.valuesWrapper.iconReplacer = scope.content.field.command.dynamicIcon(scope.content.model.entity);
    } else if (angular.isDefined(scope.content.field.iconReplacer)) {
      scope.valuesWrapper.iconReplacer = scope.content.field.iconReplacer;
    }

    var template = '<div class="relative-container droppable-table-icon-with-value-decorator" bindonce="valuesWrapper">';

    if (isImage) {
      template += '<div class="droppable-table-icon-with-value-decorator-image">\
                            <img bo-src="valuesWrapper.icon" ng-if="valuesWrapper.icon && !valuesWrapper.isErrorLoadingImage" image-load-error="valuesWrapper.isErrorLoadingImage = true"/>\
                            <div bo-class="valuesWrapper.iconReplacer" class="dropable-table-icon-replacer" ng-if="valuesWrapper.iconReplacer && (!valuesWrapper.icon || valuesWrapper.isErrorLoadingImage)"></div>';
    } else {
      template += '<div class="droppable-table-icon-with-value-decorator-icon">\
                            <div bo-class="valuesWrapper.icon"></div>';
    }

    scope.textClass = () => {
      let toReturn = '';
      if (angular.isFunction(scope.content.field.command.isEditableText) && scope.content.field.command.isEditableText(scope.content.model.entity)) {
        toReturn = 'droppable-table-icon-with-value--editable'
      }

      return toReturn;
    }



    template += '</div>\
                            <div class="droppable-table-icon-with-value-decorator-value" ng-class="textClass()">\
                                <div class="droppable-table-icon-with-value-content-wrapper">\
                                    <div class="ui-ellipsis droppable-table-icon-with-value__text" ng-bind="valuesWrapper.name"></div>\
                                    <div bindonce="externalContent" bo-title="externalContent.subIconTooltip" \
                                         bo-class="externalContent.subIcon" droppable-table-icon-with-value-decorator-value-icon cursor-pointer"\
                                         ng-click="::externalContent.subIconClick()" \
                                         bo-class="{\'invisible\': !externalContent.subIconIsVisible}"></div>\
                                </div>\
                            </div>\
                        </div>';

    var innerContent = $compile(template)(scope);
    element.append(innerContent);
    element.find('.droppable-table-icon-with-value__text').on('click', () => {
      if (scope.textClass().length > 0) {
        scope.$evalAsync(() => {
          scope.content.field.command.editText(scope.content.model.entity);
        });
      }
    })

  }

  function numberWithHandlers(scope, element) {
    var innerContent = $compile(generateNumberWithHandlersTemplate(scope))(scope);
    element.append(innerContent);
    element.addClass('droppable-table-number-editor-column');
  }

  function generateNumberWithHandlersTemplate(scope) {
    var valueType = scope.content.field.valueType ? scope.content.field.valueType : "integer";
    var template = ' <number-editor model="content.model.entity" momentum-click="false" field="{{content.field.fieldName}}"\
                                        allow-undefined-model="true" \
                                        disabled="content.field.command.isReadOnly(content.model.entity)"\
                                         ';

    if (angular.isDefined(scope.content.field.allowMinus)) {
      scope.content.model[scope.content.field.fieldName] = {};
      Object.defineProperty(scope.content.model[scope.content.field.fieldName], 'internalAllowMinus', {
        get: function () {
          var toReturn = angular.isFunction(scope.content.field.allowMinus) ? scope.content.field.allowMinus(scope.content.model.entity) : Number.MIN_SAFE_INTEGER;
          return toReturn;
        }
      });

      template += 'min="content.model[\'' + scope.content.field.fieldName + '\'].internalAllowMinus" ';
      if (scope.content.field.max) {
        Object.defineProperty(scope.content.model[scope.content.field.fieldName], 'max', {
          get: function () {
            return angular.isFunction(scope.content.field.max) ? scope.content.field.max(scope.content.model.entity) : Number.MAX_SAFE_INTEGER;
          }
        });

        template += 'max="content.model[\'' + scope.content.field.fieldName + '\'].max" ';
      }
    } else {
      let min = 0;
      try {
        min = scope.content.field.command.min || min;
      } catch (e) {
      }

      template += `min="${ min }" `;
    }

    Object.defineProperty(scope, 'delta', {
      get: function () {
        return scope.content.field.command.customDelta ? (scope.content.field.command.customDelta(scope.content.model.entity)) :
          (scope.content.field.delta ? scope.content.field.delta : 1);
      }
    });

    scope.differentialDelta = (model) => {
      return scope.content.field.command.differentialDelta ? (scope.content.field.command.differentialDelta(model)) : null;
    }


    template += 'delta="delta" ';
    template += 'differential-delta="differentialDelta" ';
    template += 'type="' + valueType + '"';
    template += '></number-editor>';

    return template;
  }


  function combobox(scope, element) {
    var originalValue;
    Object.defineProperty(scope, 'comboValueAdapter', {
      get: function () {
        if (!originalValue) {
          originalValue = scope.content.field.command.get(scope.content.model.entity);
        }

        return scope.content.field.command.get(scope.content.model.entity);
      },
      set: function (newValue) {
        scope.content.field.command.set(newValue, scope.content.model.entity);
        scope.content.model.entity.dirty = originalValue != newValue;
      }
    });

    scope.comboManuallySelected = (newVal) => {
      if (scope.content.field.command.manuallySet) {
        scope.content.field.command.manuallySet(newVal, scope.content.model.entity);
      }
    };

    var template = '<div class="droppable-table-combobox-column"><combobox values="content.field.values" selected="comboValueAdapter" value-selected="comboManuallySelected" lazy="true"></combobox></div>';
    var innerContent = $compile(template)(scope);
    element.append(innerContent);
  }

  function rank(scope, element) {
    var originalValue;
    Object.defineProperty(scope, 'rankingValueAdapter', {
      get: function () {
        if (!angular.isDefined(originalValue)) {
          originalValue = (scope.content.model.entity[scope.content.field.fieldName] || 0).toString();
        }

        return scope.content.model.entity[scope.content.field.fieldName] || 0;
      },
      set: function (newValue) {
        scope.content.model.entity[scope.content.field.fieldName] = newValue;
        scope.content.model.entity.dirty = newValue !== originalValue;
      }
    });

    var template = '<div class="centered-div-container ranking-column"><div class="centered-div-content"> \
                      <simple-star-rating size="md" value="rankingValueAdapter" step="1" read-only="content.field.command.readonly"></simple-star-rating> \
                    </div></div>';
    var innerContent = $compile(template)(scope);
    element.append(innerContent);
  }

  function moneyDecorator(scope, element) {
    scope.getValue = (angular.isDefined(scope.content.field.command) && angular.isDefined(scope.content.field.command.get)) ?
      function () {
        return scope.content.field.command.get(scope.content.model.entity);
      } :
      function () {
        return scope.content.model.entity[scope.content.field.fieldName];
      };

    var template = '<money-field entity="content.model.entity" get-value-func="getValue"></money-field>';

    var innerContent = $compile(template)(scope);
    element.append(innerContent);
  }

  function moneyWithDiscountDecorator(scope, element) {
    scope.getValue = (angular.isDefined(scope.content.field.command) && angular.isDefined(scope.content.field.command.get)) ?
      function () {
        return scope.content.field.command.get(scope.content.model.entity);
      } :
      function () {
        return scope.content.model.entity[scope.content.field.fieldName];
      };


    scope.getBeforeDiscount = function () {
      return scope.content.field.command.beforeDiscount(scope.content.model.entity);
    };

    scope.getDiscountPercentage = function () {
      if (scope.content.model.entity.itemCustomType === 'PunchCardUsage') {
        return false;
      }

      var currentValue = scope.getValue();
      var originalPrice = scope.getBeforeDiscount();
      return originalPrice && currentValue !== originalPrice &&
        (currentValue > originalPrice ? '+' : '') + (Math.round((currentValue / originalPrice) * 100 - 100)) + '%';
    };

    var template = '<div class="centered-div-container">\
                        <div class="discount-percentage-badge" ng-if="getDiscountPercentage()">{{getDiscountPercentage()}}</div>\
                        <div class="centered-div-content left-text-aligned">\
                            <div class="discounted-money" ng-if="getDiscountPercentage()">\
                              <money-field entity="content.model.entity" get-value-func="getBeforeDiscount"></money-field>\
                            </div>\
                            <money-field entity="content.model.entity" get-value-func="getValue"></money-field>\
                        </div>\
                    </div>';

    var innerContent = $compile(template)(scope);
    element.append(innerContent);
  }

  function editableNotes(scope, element) {
    scope.contentWrapper = {};
    scope.contentWrapper.content = scope.content.field.command.get(scope.content.model.entity);
    scope.isEditable = scope.content.field.command.isEditable(scope.content.model.entity);
    scope.wasEditModeRendered = false;
    scope.editMode = false;
    scope.toggleEditMode = function () {
      if (!scope.wasEditModeRendered) {
        scope.wasEditModeRendered = true;
      }

      scope.editMode = !scope.editMode;
    };

    var template = '<div class="droppable-table-editable-notes relative-container">\
                      <div class="droppable-table-editable-notes-edit-icon-wrapper absolute-container" ng-if="::isEditable">\
                         <div class="centered-div-container">\
                            <div class="centered-div-content">\
                              <div class="icon icon-edit" ng-click="::toggleEditMode()"></div>\
                            </div>\
                          </div>\
                      </div>\
                      <div class="relative-container droppable-table-editable-notes-content">\
                        <div class="relative-container">\
                          <div class="absolute-container" ng-if="isEditable && wasEditModeRendered" ng-show="editMode">\
                            <custom-text-area content-wrapper="contentWrapper"></custom-text-area>\
                          </div>\
                          <div class="absolute-container droppable-table-editable-notes-view" ng-show="!editMode">\
                            <div>{{contentWrapper.content}}</div>\
                          </div>\
                        </div>\
                      </div>\
                    </div>';

    scope.$watch('contentWrapper.content', function (newVal) {
      if (angular.isDefined(newVal)) {
        scope.content.field.command.set(scope.content.model.entity, newVal);
      }
    });

    var innerContent = $compile(template)(scope);
    element.append(innerContent);
  }

  function readWriteInput(scope, element) {
    var template = '<div class="centered-div-container read-write-droppable-field"><div class="centered-div-content"> \
                      <input ng-model="content.model.entity[content.field.fieldName]" ng-disabled="content.field.readonly(content.model.entity)"/> \
                    </div></div>';
    var dirtyWatcher = scope.$watch('content.model.entity[content.field.fieldName]', function (newVal, oldVal) {
      if (newVal !== oldVal) {
        dirtyWatcher()
        scope.content.model.entity.dirty = true;
      }
    });

    element.append($compile(template)(scope));

  }

  function textareaDecorator(scope, element) {
    var template = '<div class="textarea-droppable-field"> \
                      <textarea ng-model="content.model.entity[content.field.fieldName]" ng-disabled="content.field.readonly(content.model.entity)"/> \
                    </div>';
    var dirtyWatcher = scope.$watch('content.model.entity[content.field.fieldName]', function (newVal, oldVal) {
      if (newVal !== oldVal) {
        dirtyWatcher();
        scope.content.model.entity.dirty = true;
      }
    });

    element.append($compile(template)(scope));

  }

  function timepicker(scope, element) {
    scope.manipulatedEntity = {};
    var beforeChangedValue = scope.content.field.command.get(scope.content.model.entity);

    scope.manipulatedEntity.value = beforeChangedValue;
    scope.isEditable = scope.content.field.command.isEditable(scope.content.model.entity)
    scope.wasEditModeRendered = false;
    scope.editMode = false;
    scope.eventsDelegate = {};
    var watchedFunction = null;
    scope.toggleEditMode = function () {
      scope.editMode = !scope.editMode;
      if (scope.editMode) {
        if (!scope.wasEditModeRendered) {
          scope.wasEditModeRendered = true;
        }

        $timeout(function () {
          scope.eventsDelegate.show();
        });

        watchedFunction = scope.$watch('manipulatedEntity.value', function (newVal) {
          if (angular.isDefined(newVal) && newVal !== beforeChangedValue) {
            beforeChangedValue = newVal;
            scope.content.field.command.set(scope.content.model.entity, beforeChangedValue);
          }
        });
      } else {
        watchedFunction();
        watchedFunction = null;
      }

    };

    scope.eventsDelegate.onClose = scope.toggleEditMode;

    var template = '<div class="droppable-table-writable-textbox-wrapper jtimepicker-container relative-container">\
                      <div class="droppable-table-writable-textbox-edit-icon-wrapper relative-container" ng-if="::isEditable">\
                         <div class="centered-div-container">\
                            <div class="centered-div-content">\
                              <div class="icon icon-edit" ng-click="::toggleEditMode()"></div>\
                            </div>\
                          </div>\
                      </div>\
                      <div class="droppable-table-writable-textbox-content relative-container">\
                        <div class="centered-div-container">\
                            <div class="centered-div-content">\
                              <div class="droppable-table-writable-textbox-content-text" ng-show="!editMode">{{manipulatedEntity.value}}</div>\
                              <jtimepicker ng-show="editMode" ng-if="isEditable && wasEditModeRendered" time-selected="toggleEditMode" events-delegate="eventsDelegate" \
                              time="manipulatedEntity.value" options="{hideButtons: true, hideCurrentTime: true}"></jtimepicker>\
                            </div>\
                        </div>\
                      </div>\
                    </div>';

    var innerContent = $compile(template)(scope);
    element.append(innerContent);
  }


  function writableTextbox(scope, element) {
    scope.manipulatedEntity = {};
    scope.metadata = $.extend({}, scope.content.field.metadata);
    scope.metadata.fieldName = "TempValueField";
    scope.metadata.placeholder = scope.content.field.placeholder ? scope.content.field.placeholder : '';
    var beforeChangedValue = scope.content.field.command.get(scope.content.model.entity);

    scope.manipulatedEntity[scope.metadata.fieldName] = beforeChangedValue;
    scope.isEditable = scope.content.field.command.isEditable(scope.content.model.entity);
    scope.wasEditModeRendered = false;
    scope.editMode = false;
    scope.focus = {};

    var watchedFunction = null;
    var divertedEditModeWatcher = null;
    scope.toggleEditMode = toggleEditModeInternal;
    // scope.$evalAsync(toggleEditModeInternal);
    // };

    /*
    If the field has fieldName, it means we care about the field from outside as well.
    * */
    if (scope.content.field.fieldName) {
      scope.$watch('content.model.entity[content.field.fieldName]', function (newVal) {
        var valueFromGetter = scope.content.field.command.get(scope.content.model.entity);
        if (valueFromGetter !== newVal) {
          scope.manipulatedEntity[scope.metadata.fieldName] = valueFromGetter;
        }
      });
    }

    function toggleEditModeInternal() {
      scope.editMode = !scope.editMode;
      if (scope.editMode) {
        if (!scope.wasEditModeRendered) {
          scope.wasEditModeRendered = true;
        }

        watchedFunction = scope.$watch('manipulatedEntity', function (newVal) {
          if (angular.isDefined(newVal) && scope.manipulatedEntity[scope.metadata.fieldName] !== beforeChangedValue) {
            beforeChangedValue = scope.manipulatedEntity[scope.metadata.fieldName];
            scope.content.field.command.set(scope.content.model.entity, beforeChangedValue);
            // toggleEditModeInternal();
          }
        }, true);

        setTimeout(function () {
          element.find('input').trigger('touchstart')
        }, 100);

        // }, 100);

        if (!divertedEditModeWatcher) {
          // divertedEditModeWatcher = scope.$watch('content.model.entity.dirty', function (newVal) {
          //   if (newVal === false) {
          //     scope.toggleEditMode();
          //   }
          // })
        }


      } else {
        watchedFunction();
        watchedFunction = null;
      }
    }


    if (!!scope.content.field.defaultEditMode) {
      scope.alwaysEditMode = true;
      scope.toggleEditMode();
    }

    var template = '<div class="droppable-table-writable-textbox-wrapper relative-container {{::(alwaysEditMode ? \'always-edit\': \'\')}}">\
                      <div class="droppable-table-writable-textbox-edit-icon-wrapper relative-container" ng-if="::(isEditable && !alwaysEditMode)">\
                         <div class="centered-div-container">\
                            <div class="centered-div-content">\
                              <div class="icon icon-edit" ng-click="::toggleEditMode($event)"></div>\
                            </div>\
                          </div>\
                      </div>\
                      <div class="droppable-table-writable-textbox-content relative-container">\
                        <div class="centered-div-container">\
                            <div class="centered-div-content">\
                              <div class="droppable-table-writable-textbox-content-text" ng-show="!editMode">{{manipulatedEntity[metadata.fieldName]}}</div>\
                              <div ng-if="isEditable && wasEditModeRendered">\
                                <textbox ng-show="editMode" \
                                field-metadata="metadata" ng-model="manipulatedEntity"></textbox>\
                              </div>\
                            </div>\
                        </div>\
                      </div>\
                    </div>';

    var innerContent = $compile(template)(scope);
    element.append(innerContent);
  }

  function toggleablePriceEditor(scope, element) {
    scope.getValue = function () {
      return scope.content.model.entity[scope.content.field.fieldName];
    };

    var template = '<div class="relative-container toggleable-price-editor-wrapper" ng-class="{\'edit-mode\':content.field.mode == \'editMode\'}">' +
      '<div class="toggleable-price-editor-readonly absolute-container"><money-field entity="::content.model.entity" get-value-func="getValue"></money-field></div>' +
      '<div class="toggleable-price-editor-editor absolute-container">' + generateNumberWithHandlersTemplate(scope) + '</div>'
      + '</div>';

    var innerContent = $compile(template)(scope);
    element.append(innerContent);
  }

  function checkboxDecorator(scope, element, template, ctrl) {
    var template = '<new-checkbox model="::content.model.entity" field="{{::content.field.fieldName}}"></new-checkbox>';
    var innerContent = $compile(template)(scope);
    var watchExpression = 'content.model.entity[\'' + scope.content.field.fieldName + '\']';
    scope.$watch(watchExpression, function (newVal, oldVal) {
      if (angular.isDefined(newVal) && newVal !== oldVal) {
        ctrl.selectionChanged(newVal);
        // } else if (newVal != oldVal) {
        scope.content.model.entity[scope.content.field.fieldName] = !!newVal;
        scope.content.model.entity.dirty = true;
      }
    });

    if (scope.content.field.selectOnDirty) {
      var watchDirtyExpression = 'content.model.entity[\'dirty\']';
      scope.$watch(watchDirtyExpression, function (newVal) {
        if (newVal == true) {
          scope.content.model.entity[scope.content.field.fieldName] = true;
        } else if (newVal == false) {
          scope.content.model.entity[scope.content.field.fieldName] = false;
        }
      });
    }

    element.append(innerContent);
  }

  function customDecorator(scope, element, template, ctrl, extendScopeWith) {
    if (angular.isDefined(extendScopeWith)) {
      $.extend(scope, extendScopeWith);
    }

    scope.value = scope.content.model.get();
    scope.concreteEntity = scope.content.model.entity;
    var innerContent = $compile(template)(scope);
    element.append(innerContent);

    scope.$watch('content.model.entity', function () {
      scope.value = scope.content.model.get();
    });
  }

  function defaultDecorator(scope, element) {
    scope.hasGetter = !!(scope.content.model.command && scope.content.model.command.get)
    var template = '<div class="centered-div-container" bindonce="hasGetter">\
                                <div class="centered-div-content left-text-aligned" bo-switch="hasGetter">\
                                    <div bo-switch-when="false">{{content.model.entity[content.field.fieldName]}}</div>\
                                    <div bo-switch-when="true" ng-bind="value"></div>\
                                </div>\
                        <div>';

    if (scope.hasGetter) {
      scope.value = scope.content.model.get();
      if (!scope.content.model.command.ignoreWatchingModel) {
        scope.$watch('content.model.entity', function () {
          scope.value = scope.content.model.get();
        }, true);
      }
    }

    var innerContent = $compile(template)(scope);
    element.append(innerContent);
  }
});

function ColumnContent(entity, command) {
  this.entity = entity;
  this.command = command;
}

ColumnContent.prototype.get = function () {
  return this.command.get(this.entity);
};

ColumnContent.prototype.set = function (value) {
  this.command.set(this.entity, value);
};
