import './facebook.promotion.editor.directive.less'
import { PATH_TO_API } from "../../../constants";

angular.module('easybizy.common.promotion.editors').directive('facebookEditor',
  ['localize', '$sce', '$http', '$timeout', 'promotionTemplateResolver', '$fileUploader', 'configuration',
    function (localize, $sce, $http, $timeout, promotionTemplateResolver, $fileUploader, configuration) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          contentResolver: "=",
          defaultCustomer: "=",
          active: "=",
          preset: "=",
          mode: "@",
          facebookId: '@',
          content: "="
        },
        template: require('./facebook.promotion.editor.directive.html'),
        link: function (scope, element, attrs) {
          var tagCustomersInConfigurations = configuration.get().PromotionsSettings.TagCustomersInPosts;
          scope.isEditMode = false;
          scope.createPostPerCustomer = true;
          scope.tagCustomers = true;
          scope.editorTitle = localize.getLocalizedString("_Preview_");
          scope.modeButtonText = localize.getLocalizedString("_Edit_");
          scope.currentPreset = null;
          scope.postImages = [];

          scope.insertDynamicField = function (field, $event) {
            var textArea = element.find('.facebook-text-editor')[0];
            textArea.insertAtCaret(field.value);
            scope.messageText = textArea.value;
          };

          scope.messageText = "";

          scope.$watch('messageText', function (newMessageText) {
            if (newMessageText) {
              if (newMessageText.length > 0) {
                newMessageText = promotionTemplateResolver.replaceDynamicFields(newMessageText, false);
              }

              scope.messagePreview = $sce.trustAsHtml(newMessageText.replace(/\r?\n/g, '<br/>'));
            }
          });

          scope.$watch('content', function (newContent) {
            if (newContent) {
              scope.messageText = newContent.message;
              scope.postImages = newContent.images ? newContent.images : [];
              scope.content.convertedImages = [];
              angular.forEach(scope.postImages, function (img) {
                var imgObject = { source: img, included: true };
                scope.content.convertedImages.push(imgObject);
                scope.postImages.push(imgObject);
              });
            }
          });

          if (scope.contentResolver) {
            scope.contentResolver.resolve = function (promotionToAppendContentTo) {
              var message = scope.messageText;
              var images = [];
              angular.forEach(scope.postImages, function (image) {
                if (image.included) {
                  images.push(image.id);
                  //images.push(image.source);
                }
              });

              if (angular.isDefined(scope.content)) {
                if (message === scope.content.message &&
                  images.equals(scope.content.convertedImages, function (firstImg, secondImg) {
                    return firstImg === secondImg.source;
                  })) {
                  // content wan't changed.
                  promotionToAppendContentTo.ClientPromotionContent.State = "Unchanged";
                  return;
                } else {
                  promotionToAppendContentTo.ClientPromotionContent = {};
                  promotionToAppendContentTo.ClientPromotionContent.State = "Modified";
                }
              } else {
                promotionToAppendContentTo.ClientPromotionContent = {};
              }

              promotionToAppendContentTo.ClientPromotionContent.Message = message;
              promotionToAppendContentTo.ClientPromotionContent.FbImages = images;
              promotionToAppendContentTo.ClientPromotionContent.PromotionSenderType = "facebook";
              promotionToAppendContentTo.ClientPromotionContent.IsSinglePostMode = !scope.createPostPerCustomer;
              promotionToAppendContentTo.ClientPromotionContent.IsToAllowTagging = scope.tagCustomers;
              promotionToAppendContentTo.ClientPromotionContent.IsToUseCustomerBestRatedImage = scope.useCustomerBestRatedImage;
            };
            scope.contentResolver.validate = function () {
              var returnValue = null;
              if (scope.messageText.length < 1 || !scope.postImages.filter(x => x.included)[0]) {
                // Post has no text nor images.
                returnValue = localize.getLocalizedString("_FacebookPostMustHaveTextOrImages_");
              }

              return returnValue;
            };
          }

          var applyTemplate = function () {
            if (scope.active && scope.preset) {
              if (scope.preset.TemplateName !== scope.currentPreset) {
                scope.currentPreset = scope.preset.TemplateName;
                scope.fields = promotionTemplateResolver.getDynamicFields(scope.preset);
                promotionTemplateResolver.get("facebook", scope.currentPreset).then(function (preset) {
                  scope.$apply(function () {
                    scope.messageText = preset.message;
                    scope.postImages = preset.images ? preset.images : [];
                    angular.forEach(scope.postImages, function (img) {
                      img.included = true;
                    });
                    scope.createPostPerCustomer = preset.PostPerCustomer;
                    scope.tagCustomers = preset.TagCustomers && tagCustomersInConfigurations;
                    scope.useCustomerBestRatedImage = preset.UseCustomerBestRatedImage;
                  });
                });
              }
            }
          };

          scope.$watch('active', applyTemplate);
          scope.$watch('preset', applyTemplate);

          scope.businessName = "Daniel Easybizy";


          scope.toggleEditMode = function () {
            scope.isEditMode = !scope.isEditMode;
            if (scope.isEditMode) {
              scope.editorTitle = localize.getLocalizedString("_Edit_");
              scope.modeButtonText = localize.getLocalizedString("_Preview_");
            } else {
              scope.editorTitle = localize.getLocalizedString("_Preview_");
              scope.modeButtonText = localize.getLocalizedString("_Edit_");

            }

            scope.$root.$broadcast('editMode', scope.isEditMode);
          };

          $(".facebook-editor-area-image-placeholder").click(function () {
            element.find('.input-file-field').click();
          });

          scope.loadingImage = false;

          scope.toggleIncludeImage = function (image) {
            image.included = !image.included;
          };
          var uploader = scope.uploader = $fileUploader.create({
            scope: scope,
            url: baseUrl + PATH_TO_API + 'FilesUpload/UploadImage?temp=true'
          });

          uploader.filters.push(function (item /*{File|HTMLInputElement}*/) {
            var type = uploader.isHTML5 ? item.type : '/' + item.value.slice(item.value.lastIndexOf('.') + 1);
            type = '|' + type.toLowerCase().slice(type.lastIndexOf('/') + 1) + '|';
            return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
          });

          uploader.bind('afteraddingfile', function (event, item) {
            if (uploader.queue.length > 1) {
              uploader.queue.shift();
            }

            uploader.uploadAll();
            scope.loadingImage = true;
          });

          uploader.bind('success', function (event, xhr, item, response) {
            scope.postImages.push({
              id: response.id,
              name: "newImage",
              source: baseUrl + response.path,
              included: true
            });
//                    scope.loadingImage = false;
          });
        }
      };
    }]);
