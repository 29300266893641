import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT, ODATA_DATE_TIME_FORMAT, RTL_DATE_TIME_DISPLAY } from "../../constants";

angular.module('easybizy.employees')
  .controller('EmployeesController', function
    DashboardController($scope, Repository, toolbar, localize, $modal, googleCalendarService, employeesCrudFactory, $state, $q) {
    $scope.clearSearchFuncWrapper = {};
    const confirmMessage = localize.getLocalizedString('_ApproveGeneralConfirm_');

    toolbar.set([
      {
        name: 'notifyBySMS',
        action: function () {
          openShiftsSMSNotificationsDialog();
        },
        tooltip: localize.getLocalizedString('_NotifyEmployeesOnTheirShifts_')
      },
      {
        name: 'add',
        action: function (employee, oldEmployee, callback) {
          return createOrSaveEmployee(employee, oldEmployee, callback);
        },
        data: {
          template: require('../../modal-views/add-employee/add.employee.modal.tpl.html'),
          controller: 'AddEmployeeModalController'
        }
      },
      {
        name: 'search', action: function (newValue) {
          $scope.searchEmployeeWrapper.value = newValue;
        },
        data: {
          delay: 500,
          clearWrapper: $scope.clearSearchFuncWrapper,
          setSearchWrapper: $scope.clearSearchFuncWrapper
          // This way if $scope.clearSearch() is called the toolbar clears the text field.
        }
      }, {
        name: 'custom',
        icon: 'icon icon-entity-settings',
        action: function () {
          const opts = {
            backdrop: 'static',
            keyboard: false,
            template: require('../../modal-views/custom-entity-settings/custom.entity.config.modal.tpl.html'),
            controller: 'CustomEntityConfigurationsController',
            resolve: {
              views: () => {
                return [
                  {
                    loadItemsDelegate: (search) => Repository.Custom('CategoriesEditorRepository').get("Employees",search),
                    name: localize.getLocalizedString('_Restore_'),
                    showSearch: true,
                    actions: [
                      {
                        name: localize.getLocalizedString('_Restore_'),
                        type: 'warning-submit',
                        confirmMessage: confirmMessage,
                        delegate: (items) => {
                          return Repository.Custom('CategoriesEditorRepository').restore("Employees", items);
                        }
                      }
                    ]
                  }
                ];
              }
            }
          };

          $modal.open(opts);
        },
        tooltip: localize.getLocalizedString('_EntityConfig_')
      }
    ]);

    const toExpand = 'Image';
    $scope.employees = [];
    $scope.searchEmployeeWrapper = { value: null };
    $scope.filtersModel = {};
    $scope.filtersModel.filters = [
      { type: 'shiftState' },
      {
        type: "search",
        params: { fields: 'FirstName, LastName, PhoneNumber', type: 'Employee' },
        valueWrapper: $scope.searchEmployeeWrapper,
        clearWrapper: $scope.clearSearchFuncWrapper
      }
    ];

    $scope.filtersModel.filterStateChanged = function (newFiltersState, newFiltersSlug) {
      $scope.api.filters.length = 0;
      angular.forEach(newFiltersState, function (filterValue, filterName) {
        if (filterValue) {
          $scope.api.filters.push(filterValue);
        }

        if (filterName === 'search') {
          $scope.clearSearchFuncWrapper.setSearch(newFiltersSlug['search']);
        }
      });
    };

    $scope.api = Repository.Entity('Employee', true).query().expand(toExpand);
    $scope.api.doneLoadingCallback = function (employees) {
      $scope.noItemsFound = employees.length === 0;
    };

    $scope.tileDoubleClicked = function (employee) {
      $state.go('EmployeeDetails', { employeeId: employee.EmployeeId });
    };


    $scope.api.filters = [];
    $scope.api.pageSize = 100;
    $scope.isLoadingWrapper = {};

    $scope.actions = {};
    $scope.actions.checkIn = function (employee) {
      var deferred = $q.defer();
      Repository.Custom("EmployeeHours").checkIn(employee.EmployeeId)
        .then(function (data) {
          toastr.success(localize.getLocalizedString("_EmployeeCheckedInSuccessfuly_"));
          deferred.resolve(data);
        })
        .catch(function (errorMessage) {
          toastr.warning(errorMessage);
          deferred.reject(errorMessage);
        });
      return deferred.promise;
    };

    $scope.actions.checkOut = function (employee) {
      var deferred = $q.defer();
      Repository.Custom("EmployeeHours").checkOut(employee.EmployeeId)
        .then(function (data) {
          toastr.success(localize.getLocalizedString("_EmployeeCheckedOutSuccessfuly_"));
          deferred.resolve(data);
        })
        .catch(function (errorMessage) {
          toastr.warning(errorMessage);
          deferred.reject(errorMessage);
        });
      return deferred.promise;
    };

    $scope.actions.manuallySetShift = function (employee) {
      var deferred = $q.defer();
      openManuallyShiftModal(employee, deferred);
      return deferred.promise;
    };

    $scope.actions.editDetails = function (model) {
      $scope.$emit('fire-toolbar-command', {
        name: "add", params: {
          employee: model
        }
      });
    };

    $scope.actions.editShifts = openEmployeeShiftModal;

    $scope.actions.editWorkHours = openEmployeeWorkHoursModal;
    $scope.actions.syncWithGoogle = function () {
    };

    function openShiftsSMSNotificationsDialog() {
      var opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/employee-shifts-sms-notifications/employee.shifts.sms.notifications.modal.html'),
        controller: "EmployeeShiftsSMSNotifications",
      };

      $modal.open(opts);


    }

    function createOrSaveEmployee(employee, oldEmployee) {
      return employeesCrudFactory.createOrUpdate(employee, oldEmployee)
        .then(function (employee) {
          if (oldEmployee == null) {
            $scope.employees.unshift(employee);
          } else {
            var index = $scope.employees.indexOfById(oldEmployee, "EmployeeId");
            $scope.employees[index] = employee;
          }

          return employee;
        })
    }

    function openManuallyShiftModal(employee, deferred) {
      var opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/manually-shift/manually.shift.modal.tpl.html'),
        controller: "ManuallyShiftModalController",
        resolve: {
          saveAction: function () {
            return function (checkInTime, checkOutTime, callback) {
              Repository.Custom("EmployeeHours").manualyUpdateOrCreateShift(employee.EmployeeId, checkInTime, checkOutTime)
                .then(function (data) {
                  toastr.success(localize.getLocalizedString("_ShiftUpdatedSuccessfuly_"));
                  deferred.resolve(data.OpenCheckInTime);
                  callback.resolve(data.OpenCheckInTime);
                })
                .catch(function (errorMessage) {
                  toastr.warning(errorMessage);
                  deferred.reject(errorMessage);
                  callback.reject(errorMessage);
                });
            };
          },
          parameters: function () {
            var shiftParmeters = {
              checkedInTime: employee.OpenCheckInTime
            };
            return shiftParmeters;
          }
        },
        closeFn: function () {
        }
      };

      $modal.open(opts);
    }

    function openEmployeeShiftModal(employee) {
      var opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/employee-shifts/employee.shifts.modal.tpl.html'),
        controller: "EmployeeHoursModalController",
        resolve: {
          saveAction: function () {
            return function () {
            };
          },
          parameters: function () {
            return {
              employee: employee
            };
          }
        },
        closeFn: function () {
        }
      };

      $modal.open(opts);
    }

    function openEmployeeWorkHoursModal(employee) {
      var opts = {
        backdrop: 'static',
        keyboard: true,
        backdropClick: false,
        template: require('../../modal-views/employee-work-hours/employee.work.hours.modal.tpl.html'),
        controller: "EmployeeWorkHoursModalController",
        resolve: {
          saveAction: function () {
            return function (daysOfWork, cancelFutureInlineChanges, callback) {
              Repository.Custom("EmployeeHours").daysOfWork(employee.EmployeeId, daysOfWork, cancelFutureInlineChanges)
                .then(function (result) {
                  toastr.success(localize.getLocalizedString("_WorkingHoursUpdatedSuccessfuly_"));
                  employee.DaysOfWork = result;
                  callback.resolve();
                })
                .catch(function (error) {
                  try {
                    var errorMessage = localize.getLocalizedString(error?.data?.validationError);
                    if (error?.data?.meetingsInRange && error.data.meetingsInRange?.length > 0) {
                      angular.forEach(error.data.meetingsInRange, function (meeting) {
                        errorMessage += '\n' + moment(meeting.StartTime).format(RTL_DATE_TIME_DISPLAY) + ' ' + meeting.Title;
                      });
                    }
                    toastr.warning(errorMessage);
                  } catch (e) {
                    toastr.warning(error.data);
                  }
                  callback.reject(error);
                });
            };
          },
          parameters: function () {
            return {
              employee: employee
            };
          }
        },
        closeFn: function () {
        }
      };

      $modal.open(opts);
    }
  })
  .factory('employeesCrudFactory', [
    'Repository', function (Repository) {
      return {
        createOrUpdate: createOrUpdateEmployee
      };

      function createOrUpdateEmployee(employee, oldEmployee) {
        var httpAction;

        if (oldEmployee == null) {
          //create new employee
          employee.DateOfBirth = angular.isDefined(employee.DateOfBirth)
            ? moment(employee.DateOfBirth, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) : null;
          httpAction = Repository.Entity("Employee").create(employee);
        } else {
          //update employee data
          var employeeData = getEmployeeDataFields(oldEmployee, employee);
          httpAction = Repository.Entity("Employee").update(employeeData);
        }

        return httpAction.expand('Image').post();
      }

      function getEmployeeDataFields(oldEmployee, updatedEmployee) {
        oldEmployee.FirstName = updatedEmployee.FirstName;
        oldEmployee.LastName = updatedEmployee.LastName;
        oldEmployee.EmailAddress = updatedEmployee.EmailAddress;
        oldEmployee.PhoneNumber = updatedEmployee.PhoneNumber;
        oldEmployee.VisibleOnCalendar = updatedEmployee.VisibleOnCalendar;
        oldEmployee.DateOfBirth = updatedEmployee.DateOfBirth ?
          moment(updatedEmployee.DateOfBirth, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) : oldEmployee.DateOfBirth;
        //this field can be update from different places but not from here
        oldEmployee.OpenCheckInTime = oldEmployee.OpenCheckInTime ? moment(oldEmployee.OpenCheckInTime).format(ODATA_DATE_TIME_FORMAT) : null;
        if (angular.isDefined(updatedEmployee.ImageId)) {
          oldEmployee.ImageId = updatedEmployee.ImageId;
        }
        delete oldEmployee.Image;
        delete oldEmployee.EntityType;
        delete oldEmployee.isMarked;
        delete oldEmployee.$$hashKey;
        return oldEmployee;
      }
    }]);
