(function () {
  angular.module('easybizy.calendar').directive('calendarShiftsDrawer', [
    'calendarMetadata', 'calendarColumns',
    function (calendarMetadata, calendarColumns) {
      return {
        restrict: 'A',
        link: function (scope) {
          let table;
          let isMouseDown = false, selectCells = false, unavailableSlots;
          let startPosition = null;

          scope.$watch('editMode', function (newVal) {
            if (!angular.isDefined(newVal)) {
              return;
            }

            unavailableSlots = {};
            toggleSelectable(!!newVal);
          });

          function toggleSelectable(editMode) {
            table = $('.calendar-days-placeholder > table');
            const method = editMode ? 'on' : 'off';
            table[method]('mousedown', 'td', tdMouseDown);
            table[method]('mouseover', 'td', tdMouseOver);
            if (!editMode) {
              startPosition = null;
            }

            $('.days-header-wrapper')[method]('click', 'td', headerTdClicked);
          }

          function mouseUpToFinish(event) {
            $(document)['off']('mouseup', mouseUpToFinish);
            isMouseDown = false;
            mapChangesToTime();
          }

          function tdMouseDown(event) {
            isMouseDown = true;
            const cell = $(this);
            selectCells = !cell.hasClass('unavailable');

            if (event.shiftKey) {
              const mouseDownPosition = getCellPosition(cell);
              if (startPosition) {
                if (startPosition.col === mouseDownPosition.col) {
                  /// This means we want to capture
                  if (startPosition.row !== mouseDownPosition.row) {
                    const startIdx = Math.min(startPosition.row, mouseDownPosition.row);
                    const finishIdx = Math.max(startPosition.row, mouseDownPosition.row);
                    let columnsRows = cell.parent().parent().children();
                    for (let i = startIdx; i <= finishIdx; i++) {
                      let cell = $(columnsRows[i].children[startPosition.col])
                      cell[selectCells ? 'addClass' : 'removeClass']('unavailable');
                    }

                    if (!selectCells) {
                      startPosition = null
                    }
                  }
                } else {
                  startPosition = mouseDownPosition;
                }
              } else {
                startPosition = mouseDownPosition;
              }


            } else {
              startPosition = null;
            }

            cell[selectCells ? 'addClass' : 'removeClass']('unavailable');
            $(document)['on']('mouseup', mouseUpToFinish);
          }

          function getCellPosition($cell) {
            try {
              const tr = $cell.parent();
              const row = tr.parent().children().index(tr)
              const col = tr.children().index($cell);
              return { row, col };
            } catch (e) {
              return null;
            }
          }


          function tdMouseOver() {
            if (!isMouseDown) {
              return;
            }

            var cell = $(this);
            cell[selectCells ? 'addClass' : 'removeClass']('unavailable');
          }

          function headerTdClicked() {
            var colHeaderTd = $(this);
            var colIndex = colHeaderTd.parent().children().index(colHeaderTd);
            var colStatus = !colHeaderTd.hasClass('all-unavailable');
            colHeaderTd.toggleClass('all-unavailable');
            table.find('tr td:nth-child(' + (colIndex + 1) + ')')[colStatus ? 'addClass' : 'removeClass']('unavailable');
            mapChangesToTime();
          }

          function mapChangesToTime() {
            calendarColumns.visibleColumns.forEach(function (column, colIdx) {
              unavailableSlots[column.formattedDate] = unavailableSlots[column.formattedDate] || {};
              const employeeId = calendarMetadata.filteredEmployeeId || (column.employee && column.employee.id) || (calendarMetadata.isSingleWeeklyEmployeeMode && calendarMetadata.employees[0].id);
              if (!employeeId) {
                toastr.error(localize.getLocalizedString("_CantAssignExceptions_"));
                throw new Error('_CantAssignExceptions_');
              }

              const columnBlocking = unavailableSlots[column.formattedDate][employeeId] = [];
              let batchRangeStartIndex = null;
              const tds = table.find('tr td:nth-child(' + (colIdx + 1) + ')');
              const numOfTdsInEachColumn = tds.length;
              tds.each(function (idx) {
                if (this.classList.contains('unavailable')) {
                  if (batchRangeStartIndex == null) {
                    // Edge case, only last cell.
                    if (idx === numOfTdsInEachColumn - 1) {
                      pushBatch(idx, idx + 1);
                    } else {
                      batchRangeStartIndex = idx;
                    }
                  } else if (idx === numOfTdsInEachColumn - 1) {
                    pushBatch(batchRangeStartIndex, idx + 1);
                  }
                } else if (batchRangeStartIndex !== null) {
                  pushBatch(batchRangeStartIndex, idx);
                }
              });

              function pushBatch(startIndex, endIndex) {
                columnBlocking.push({
                  start: startIndex,
                  end: endIndex,
                  startTime: calendarMetadata.startTime.add(calendarMetadata.minutesBetweenMeetings * startIndex, 'm').format('HH:mm'),
                  endTime: calendarMetadata.startTime.add(calendarMetadata.minutesBetweenMeetings * endIndex, 'm').format('HH:mm')
                });

                batchRangeStartIndex = null;
              }
            });

            calendarColumns.lastEditedExceptions = unavailableSlots;
          }

        }
      };
    }]);

}());
