import './new.calendar.less'
import './calendar.less'
import './helpers/calendar.metadata'
import './helpers/calendar.clipboard'
import './helpers/calendar.column'
import './helpers/calendar.columns'
import './helpers/current.time.indicator'
import './helpers/meeting.drawer.helper'
import './calendar-awaiting-approval/calendar.meetings.awaiting.approval'
import './calendar-drawers/calendar.days.placeholder'
import './calendar-drawers/calendar.drawers'
import './calendar-drawers/calendar.shifts.drawer'
import './calendar-meetings/calendar.meeting'
import './calendar-meetings/calendar.meeting.holders'
import './calendar-popover/calendar.popover'
import './calendar-store/calendar.io.service'
import './calendar-store/calendar.store'
import './calendar.sticky.less'
import './waiting-list/waiting.list.directive'

import {DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT, SERVER_DATA_DATE_FORMAT} from '../../constants'
import {printTableMode} from "./print-helper";


(function () {
  angular.module('easybizy.calendar').controller('CalendarController',
    function (
      $scope, $timeout, Repository, localize,
      $rootScope, calendarMetadata, calendarColumns, calendarStore, toolbar,
      calendarClipboard, stateManager, configuration, $templateCache
    ) {
      if (configuration.get().CalendarSettings.AllowWidthScrolling) {
        /// SCROLLABLE Calendar:
        $templateCache.put('calendarTemplate', require('./calendar.sticky.tpl.html'));
      } else {
        /// REGULAR Calendar:
        $templateCache.put('calendarTemplate', require('./calendar.default.tpl.html'));
      }


      /******* Show calendar content only after it was first loaded *******/
      $scope.firstLoaded = false;
      calendarStore.on('done-loading-meetings-from-backend', () => {
        $timeout(() => {
          $scope.firstLoaded = true;
        });
      });
      // HANDLING MEETING MODIFICATIONS.
      $scope.$on('meetingCRUDEvent', function (_, socketData) {
        $timeout(function () {
          calendarStore.forceReloadMeeting(socketData.Content.MeetingId, true, socketData);
        }, 100);
      });

      Object.defineProperty($scope, 'isLoading', (function () {
        let isLoading = false;
        return {
          get: () => /*$scope.firstLoaded && */isLoading,
          set: (val) => isLoading = val
        }
      }()));

      /*********** TOOLBAR COMMANDS ***********/
      var commands = [
        {
          name: 'print',
          action: function () {
            printView();
          }
        },
        {
          hidden: true,
          name: 'add',
          action: function (meeting, oldMeeting, updateFutureEvents) {
            return calendarStore.createOrUpdateMeeting(meeting, oldMeeting, updateFutureEvents);
          },
          params: {
            preventEscape: true,
            time: calendarMetadata.getCurrentDay().hour(moment().hour() + 1),
            date: calendarMetadata.getCurrentDay()
          },
          data: {
            template: require('../../modal-views/add-meeting/add-meeting.tpl.html'),
            controller: 'AddMeetingModalController'
          }
        },
        {
          name: 'headerSearch',
          searchDelegateWrapper: {
            search: function (string) {
              return Repository.Custom('Calendar').searchMeetings(string, calendarMetadata.getCurrentDay().format(ODATA_DATE_ONLY_FORMAT))
                .then(function (meetings) {
                  const toReturn = meetings.map(function (meeting) {
                    meeting.ConcreteType = 'Meeting';
                    meeting.StartTime = moment(meeting.StartTime, SERVER_DATA_DATE_FORMAT);
                    return meeting
                  });

                  return toReturn;
                })
                .catch(function (err) {
                  toastr.error(localize.getLocalizedString('_ErrorFindingMeetings_'));
                  return err;
                })

            }
          }
        }
      ];

      if (calendarMetadata.inlineEditShifts) {
        // TODO: remove waiting list in case of entering this mode.
        commands.unshift({
          name: 'edit',
          editActionName: 'InlineEditShifts',
          action: (function () {
            var currentViewMode;
            return function (editMode) {
              calendarMetadata.editMode = true;
              $scope.editMode = editMode;
              if ($scope.editMode) {
                currentViewMode = stateManager.currentState('Calendar');
                // Move to employees mode if not weekly and filtered.
                // const weeklyFilteredByEmployeeMode = calendarMetadata.viewType === 'w' && (calendarMetadata.filteredEmployeeId || calendarMetadata.employees.length === 1);
                if (calendarMetadata.viewType !== 'employees' &&
                  !calendarMetadata.isSingleWeeklyEmployeeMode) {
                  calendarMetadata.viewType = 'employees';
                }

                // Move to all statuses.
                $scope.selectedStatus = $scope.availableStatuses[0];
                $scope.statusSelected($scope.availableStatuses[0]);

                // Move to all employees
                if (!calendarMetadata.isSingleWeeklyEmployeeMode) {
                  $scope.selectedEmployees = $scope.employees[0];
                  $scope.employeeSelected($scope.selectedEmployees);
                }
              } else {
                setTimeout(function () {
                  if (Object.keys(calendarColumns.lastEditedExceptions).length < 1) {
                    return;
                  }

                  $rootScope.$emit('page-in-progress', true);

                  Repository.Custom('Calendar').uploadShifts(calendarColumns.lastEditedExceptions).then(function (result) {
                    //what to do with result? and what type of result?
                    calendarColumns.exceptionsUpdated(result);
                    // Go back to position before editing.
                    stateManager.setState('Calendar', currentViewMode, true);
                    $scope.$evalAsync(function () {
                      $rootScope.$emit('page-in-progress', false);
                    });
                  }).catch(function (err) {
                    toastr.error(localize.getLocalizedString('_ErrorUpdatingShifts_', err.Message));
                    $scope.$evalAsync(function () {
                      $rootScope.$emit('page-in-progress', false);
                    });
                  });
                }, 1);

                setTimeout(function () {
                  if (calendarColumns.employeesSort) {
                    Repository.Custom('Calendar').updateEmployeesOrder(calendarColumns.employeesSort)
                      .catch(function () {
                        toastr.error(localize.getLocalizedString('_ErrorUpdatingSort_'));
                      });
                  }
                }, 1);
              }
            };
          })(),
          tooltip: localize.getLocalizedString('_EmployeeWorkHoursAction_')
        });
      }

      /**
       * TODO: Implement this prop.
       */
      if (calendarMetadata.supportWaitingList) {
        $scope.isWaitingListDrawerVisible = false;
        commands.push({
          name: 'custom',
          icon: 'icon icon-waiting-list',
          tooltip: localize.getLocalizedString('_WaitingList_'),
          action: function () {
            $scope.isWaitingListDrawerVisible = !$scope.isWaitingListDrawerVisible;
          },
          decorate: (outerScope, element) => {
            $scope.$watch('isWaitingListDrawerVisible', (newValue) => {
              if (newValue) {
                element.addClass('edit-mode');
              } else {
                element.removeClass('edit-mode');
              }
            });
          }
        });
      }

      toolbar.set(commands);


      /*********** LOADER ***********/
      var loadingIndicatorDelegate = $rootScope.$on('calendar-column-drawing', function (_, isLoading) {
        $scope.isLoading = !angular.isDefined(isLoading) || isLoading === true;
      });

      var scrollToNowDelegate = $rootScope.$on('calendar-column-drawing', function () {
        if ($scope.firstLoaded && !stateManager.currentState('Calendar').meeting) {
          scrollToNowDelegate();
          $rootScope.$emit('scroll-to-now');
        }
      });

      $scope.$on('$destroy', function () {
        loadingIndicatorDelegate();
      });

      /*********** CLIPBOARD ***********/
      $scope.clipboard = calendarClipboard;

      /*********** EMPLOYEES***********/
      calendarMetadata.getEmployeesAsync().then(setEmployees)
        .catch(function (err) {
          toastr.error(localize.getLocalizedString('_ErrorGettingEmployees_'));
        });


      function setEmployees(employees) {
        if (employees && employees.length > 0) {
          $scope.employees = employees.slice(0);
          $scope.employees.unshift({name: localize.getLocalizedString("_AllEmployees_"), value: null});
          $scope.selectedEmployees = $scope.employees.filter(function (employee) {
            return employee.id === (calendarMetadata.filteredEmployee && calendarMetadata.filteredEmployee.id);
          });

          $scope.selectedEmployees = ($scope.selectedEmployees && $scope.selectedEmployees[0]) || $scope.employees[0];
          $scope.employeeSelected = function (employee) {
            calendarMetadata.filteredEmployee = employee;
          };

          $scope.allowMeetingsOnTopOfExceptions = calendarMetadata.allowMeetingsOnTopOfExceptions;
        }
      }

      /********** DRAG SELECT *********/


      /*********** MEETINGS ***********/

      calendarColumns.on('columnsChanged', render);

      function render(columns) {
        $scope.visibleColumns = columns.slice();
        $scope.customDatePicker = ((columns && columns[columns.length - 1].date) || moment()).format(DATE_DISPLAY_FORMAT);
        $timeout(() => {
          $scope.columnsDidDraw = true;
        })
      }

      Object.defineProperty($scope, 'currentDate', function () {
        let currentDate = moment();

        return {
          get() {
            // console.log($scope.viewModeWrapper.selected.value)
            const calendarMetadataDate = calendarMetadata.getCurrentDay();
            if (currentDate.isSameDate(calendarMetadataDate)) {
              return currentDate;
            }

            currentDate = calendarMetadataDate;
            return currentDate;
          }
        }
      }())

      /*********** TOOLBAR COMMANDS ***********/

      $scope.viewOptions = [
        {name: localize.getLocalizedString("_ByDaily_"), value: 'd'},
        {name: localize.getLocalizedString("_ByWeekly_"), value: 'w'}
      ];


      if (!configuration.get().CalendarSettings.HideEmployees) {
        $scope.viewOptions.push({name: localize.getLocalizedString("_ByEmployees_"), value: 'employees'})
      }

      if (configuration.get().CalendarSettings.EnableRooms) {
        $scope.viewOptions.push({name: localize.getLocalizedString("_ByRooms_"), value: 'rooms'});
      }

      $scope.viewModeWrapper = {};
      Object.defineProperty($scope.viewModeWrapper, 'selected', {
        get: function () {
          // console.log($scope.viewMode.value);
          return $scope.viewOptions.filter(function (viewType) {
            return calendarMetadata.viewType === viewType.value;
          })[0] || $scope.viewOptions[0];
        },
        set(value) {
          calendarMetadata.viewType = value.value;

        }
      });

      $scope.calendarMetadata = calendarMetadata;

      $scope.customDatePicker = calendarMetadata.firstDayOfTheWeek.format(DATE_DISPLAY_FORMAT);
      $scope.rangeDateSelected = function (date) {
        calendarMetadata.setCustomDate(date);
      };

      /******* Filter Status *******/
      $scope.availableStatuses = [
        {
          name: localize.getLocalizedString("_AllStatuses_"),
          value: null
        },
        {
          name: localize.getLocalizedString("_CancelledOnly_"),
          value: calendarMetadata.CANCELLED_STATUS
        },
        {
          name: localize.getLocalizedString("_CancelledAndOther_"),
          value: calendarMetadata.INCLUDE_CANCELLED_STATUS
        },
        {
          name: localize.getLocalizedString("_AwaitingApproval_"),
          value: calendarMetadata.AWAITING_APPROVAL_STATUS
        }
      ];

      for (const status in calendarMetadata.meetingStatuses) {
        $scope.availableStatuses.push(calendarMetadata.meetingStatuses[status]);
      }

      $scope.statusSelected = function (selected) {
        calendarMetadata.filteredStatus = selected.value;
      };


      Object.defineProperty($scope, 'selectedStatus', {
        get: function () {
          return $scope.availableStatuses.filter(function (status) {
            return status.value === calendarMetadata.filteredStatus;
          })[0]
        },
        set: function (selectedStatus) {
          calendarMetadata.filteredStatus = selectedStatus.value;
        }
      });

      $scope.cellHeightMode = calendarMetadata.cellHeightMode;

      function printView() {
        printTableMode(calendarColumns, calendarStore, calendarMetadata,
          configuration.get(), localize);
      }

    });

}());
