import './sms.promotion.editor.directive.less';

angular.module('easybizy.common.promotion.editors').directive('smsEditor', [
  'localize', '$sce', 'promotionTemplateResolver',
  function (localize, $sce, promotionTemplateResolver) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        contentResolver: "=",
        defaultCustomer: "=",
        preventTemplate: '=',
        active: "=",
        preset: "=",
        mode: "@",
        content: "=",
        alwaysEditMode: '='
      },
      template: require('./sms.promotion.editor.directive.html'),
      link: function (scope, element, attrs) {
        scope.isEditMode = !!scope.alwaysEditMode;
        scope.currentPreset = null;
        scope.editorTitle = scope.isEditMode ? localize.getLocalizedString("_Edit_") : localize.getLocalizedString("_Preview_");
        scope.modeButtonText = localize.getLocalizedString("_Edit_");

        scope.insertDynamicField = function (field) {
          var textArea = element.find('textarea.sms-text-editor')[0];
          if (document.queryCommandSupported('insertText')) {
            document.execCommand('insertText', false, field.value);
          } else {
            textArea.insertAtCaret(field.value);
          }

          scope.messageText = textArea.value;
        };

        scope.toggleEditMode = function () {
          scope.isEditMode = !scope.isEditMode;
          if (scope.isEditMode) {
            scope.editorTitle = localize.getLocalizedString("_Edit_");
            scope.modeButtonText = localize.getLocalizedString("_Preview_");
          } else {
            scope.editorTitle = localize.getLocalizedString("_Preview_");
            scope.modeButtonText = localize.getLocalizedString("_Edit_");
          }

          scope.$root.$broadcast('editMode', scope.isEditMode);
        };

        scope.$watch('content', function (newVal) {
          if (newVal) {
            scope.messageText = newVal.message;
          }
        });

        scope.messagePreview = "";

        scope.$watch('contentResolver', function (newVal) {
          if (newVal) {
            scope.contentResolver.resolve = function (promotionToAppendContentTo) {
              if (angular.isDefined(scope.content)) {
                if (promotionToAppendContentTo.ClientPromotionContent.message === scope.messageText) {
                  // content wan't changed.
                  promotionToAppendContentTo.ClientPromotionContent.State = "Unchanged";
                  return;
                } else {
                  promotionToAppendContentTo.ClientPromotionContent = {};
                  promotionToAppendContentTo.ClientPromotionContent.State = "Modified";
                }
              } else {
                promotionToAppendContentTo.ClientPromotionContent = {};
              }

              promotionToAppendContentTo.ClientPromotionContent.Message = scope.messageText;
              promotionToAppendContentTo.ClientPromotionContent.PromotionSenderType = "sms";
            };

            scope.contentResolver.validate = function () {
              var returnValue = null;
              if (!(scope.messageText && scope.messageText.length > 0)) {
                // Post has no text nor images.
                returnValue = localize.getLocalizedString("_SMSMustHaveContent_");
              }

              return returnValue;
            };
          }
        });

        var applyTemplate = function () {
          if (scope.active && scope.preset) {
            if (scope.preset.TemplateName !== scope.currentPreset) {
              scope.currentPreset = scope.preset.TemplateName;
              scope.fields = promotionTemplateResolver.getDynamicFields(scope.preset);
              if (!scope.preventTemplate) {
                promotionTemplateResolver.get("sms", scope.currentPreset).then(function (preset) {
                  scope.$evalAsync(function () {
                    scope.messageText = preset.message;
                  });
                });
              }
            }
          }
        };

        scope.$watch('active', applyTemplate);
        scope.$watch('preset', applyTemplate);

        scope.$watch('messageText', function (newVal) {
          if (!angular.isDefined(newVal)) {
            return;
          }

          if (newVal.length > 0) {
            newVal = promotionTemplateResolver.replaceDynamicFields(newVal, false);
          }

          scope.messagePreview =
            $sce.trustAsHtml(newVal.replace(/\r?\n/g, '<br/>'));

          scope.messagesCount = Math.max(1, Math.ceil(scope.messageText.length / 200));

        });

      }
    };
  }]);
