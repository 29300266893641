var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "whatsapp-message-container h-full" }, [
    _c("div", { staticClass: "message-container" }, [
      _c("div", { staticClass: "message-container__wrapper" }, [
        _vm.message && _vm.message.header
          ? _c("div", { staticClass: "message-container__header" }, [
              _vm._v("\n        " + _vm._s(_vm.message.header) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", {
          staticClass: "message-container__body",
          domProps: { innerHTML: _vm._s(_vm.messageContent) },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "message-container__time" }, [
          _vm._v("\n        " + _vm._s(_vm.time) + "\n      "),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.message && _vm.message.buttons
      ? _c(
          "div",
          { staticClass: "flex" },
          _vm._l(_vm.message.buttons, function (button) {
            return _c(
              "div",
              { staticClass: "whatsapp-message__button text-center" },
              [_vm._v("\n      " + _vm._s(button) + "\n    ")]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }