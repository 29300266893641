import './toolbar-action.directive.less';
import {DelayedFunction} from "../../general/helpers";
import {RTL_DATE_TIME_DISPLAY} from "../../constants";
import {isNonEmptyObject} from "@tomeravni/easybizy-js-common/common";

var toolbarActions = angular.module('easybizy.common.toolbar.actions', [
  'ui.bootstrap',
  'easybizy.easybizyModalViewsModel',
  'easybizy.common.common.resources',
  'easybizy.common.custom.reports.actions', 'easybizy.employees']);

toolbarActions.factory('toolbar', [
  '$rootScope', '$timeout', '$modal', '$http', 'notificationCenterBus', 'configuration', 'authenticationService', 'printerMediator', 'Repository', 'localize',
  function ($rootScope, $timeout, $modal, $http, notificationCenterBus, configuration, authenticationService, printerMediator, Repository, localize) {
    this.actionsForCurrentView = [];
    this.constantActions = [];

    var that = this;
    var actions = {};

    $rootScope.$on('fire-toolbar-command', function (args, value) {
      var action = that.actionsForCurrentView.filter(x => x.name === value.name)[0];

      action.func(null, value.params);
    });

    let isWaSilentMode = false;
    configuration.loggedInUserAsPromise.then((res) => {
      if (isNonEmptyObject(res.whatsappProps)) {
        isWaSilentMode = !!res.whatsappProps?.clientSilentMode;
      }
    })


    actions['search'] = function () {
      var myThis = this;
      this.scope = null;
      this.order = 10;
      this.hasExternalResource = true;
      this.icon = 'icon icon-search';

      this.func = function () {
        myThis.scope.showSearchWrapper.isVisible = !myThis.scope.showSearchWrapper.isVisible;
      };

      this.decorate = function (outerScope, element) {
        myThis.scope = outerScope;
        myThis.scope.showSearchWrapper = {};
        myThis.scope.showSearchWrapper.isVisible = false;
        myThis.scope.searchString = "";
        myThis.scope.toggle = myThis.func;

        var delayedSearchObject = new DelayedFunction(function (searchString) {
          myThis.scope.$evalAsync(function () {
            myThis.action(searchString);
          })
        }, 500);

        myThis.scope.$watch('searchString', function (newVal, oldVal) {
          if (!angular.isDefined(newVal) || newVal === oldVal) {
            return;
          }

          delayedSearchObject.set(newVal);
        });

        myThis.scope.clear = function () {
          myThis.scope.searchString = "";
          $timeout(function () {
            myThis.scope.showSearchWrapper.isVisible = false;
          });
        };

        if (myThis.data && myThis.data.setSearchWrapper) {
          myThis.data.setSearchWrapper.setSearch = function (searchTerm) {
            myThis.data.setSearchWrapper.setSearch = function () {
            };
            if (myThis.scope.searchString !== searchTerm) {
              myThis.scope.searchString = searchTerm;
            }
          }
        }

        myThis.scope.$watch('showSearchWrapper.isVisible', function (newVal) {
          if (newVal) {
            $timeout(function () {
              $(element.find("input")[0]).focus();
            }, 100);
          }
        });

        myThis.data.clearWrapper.clear = myThis.scope.clear;
      };
    };

    actions['headerSearch'] = function () {
      var myThis = this;
      this.hasExternalResource = false;
      this.type = 'custom';
    };

    actions['toggleButton'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = '';
      this.hasExternalResource = true;
      this.type = 'togglebutton';
    };

    actions['notification'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = 'icon icon-notification-empty';
      this.hasExternalResource = true;
      this.func = function () {
        myThis.scope.showNotifications = !myThis.scope.showNotifications;
      };

      this.decorate = function (outerScope, element) {
        myThis.scope = outerScope;
        myThis.scope.sections = [/*'appImages',*/'missedCalls'];
      };

      var hasAnimationClass = false;
      notificationCenterBus.registerStatus(function (hasUnreadNotifications, count) {
        var classIcon = hasUnreadNotifications ? 'icon-notification-unread' : 'icon-notification-empty';
        if (!hasAnimationClass) {
          myThis.element.addClass('animating-header-icon');
          hasAnimationClass = true;
        }

        if (hasUnreadNotifications) {
          myThis.element.addClass("rotating-icon");
          setTimeout(function () {
            myThis.element.removeClass("rotating-icon animating-header-icon");
            hasAnimationClass = false;
          }, 1000);

          myThis.element.attr('data-badge', count === 10 ? '+9' : count);
        } else {
          myThis.element.attr('data-badge', null);
        }

        myThis.element.find('.toolbar-action-icon > span').removeClass("icon-notification-unread icon-notification-empty");
        myThis.element.find('.toolbar-action-icon > span').addClass(classIcon);
      });

      notificationCenterBus.loadNotifications();
    };

    actions['print'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = 'icon icon-print';
      this.hasExternalResource = true;

      this.func = function () {
        myThis.action();
      }

    };

    actions['openDrawer'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = 'icon icon-cash-drawer';
      this.hasExternalResource = true;

      this.func = function () {
        printerMediator.print(moment().format(RTL_DATE_TIME_DISPLAY), true);
      }

    };

    actions['feedback'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = 'icon icon-lamp';
      this.hasExternalResource = true;
      this.func = function () {
        myThis.scope.showFeedbackForm = !myThis.scope.showFeedbackForm;
      };
      this.decorate = function (outerScope, element) {
        myThis.scope = outerScope;
      };
    };

    actions['helpVideos'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = 'icon icon-help';
      this.hasExternalResource = true;
      this.func = function () {
        if (!angular.isDefined(myThis.scope.showHelpVideos)) {
          myThis.scope.showHelpVideos = {show: false};
        }

        myThis.scope.showHelpVideos.show = !myThis.scope.showHelpVideos.show;
      };

      this.decorate = function (outerScope, element) {
        myThis.scope = outerScope;
        outerScope.videos = myThis.videos;
        if (!angular.isDefined(myThis.isVisible)) {
          Object.defineProperty(myThis, 'isVisible', {
            get: function () {
              return myThis.videos.length > 0;
            }
          })
        }

        outerScope.$watch('videos', function (newVal) {
          if (newVal && newVal.length > 0) {
            var hasUnseenTutorials = newVal.filter(function (featuredVideo) {
              return !featuredVideo.watched
            }).length > 0;

            if (hasUnseenTutorials) {
              $timeout(myThis.func, 5000);
            }
          }
        })
      };

    };

    actions['edit'] = function () {
      var myThis = this;
      var editMode = false;
      this.order = 0;
      this.icon = 'icon icon-details';
      this.hasExternalResource = true;
      this.func = function () {
        if (!editMode && myThis.editActionName && !authenticationService.isAuthenticated()) {
          var securityConfigs = configuration.get().SecuritySettings;
          if (securityConfigs.RequireAuthorization && securityConfigs['Protect' + myThis.editActionName]) {
            authenticationService.authenticate()
              .then(function () {
                myThis.scope.$evalAsync(toggleEdit);
              })
              .catch(function () {
                console.log('fail!')
              });
          } else {
            toggleEdit();
          }
        } else {
          toggleEdit();
        }
      };

      function toggleEdit() {
        myThis.scope.editMode = editMode = !editMode;
        myThis.action(editMode);
      }

      this.decorate = function (outerScope, element) {
        myThis.scope = outerScope;
        outerScope.$watch('editMode', function (newVal) {
          if (newVal) {
            element.addClass('edit-mode');
          } else {
            element.removeClass('edit-mode');
          }
        })
      };
    };

    actions['addGroup'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = 'icon icon-group';
      this.hasExternalResource = true;
      this.func = function (event, dataParams) {
        myThis.opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: myThis.data.template,
          controller: myThis.data.controller,
          resolve: {
            saveAction: function () {
              return function (newEntity, oldEntity, callback) {
                return myThis.action(newEntity, oldEntity, callback);
              };
            },
            parameters: function () {
              return dataParams ? dataParams : {};
            }
          },
          closeFn: function () {
            console.log("closing");
          }
        };

        var modalInstance = $modal.open(myThis.opts);
      };
    };

    actions['add'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = 'icon icon-add';
      this.hasExternalResource = true;
      this.func = function (event, dataParams) {
        myThis.opts = {
          backdrop: 'static',
          windowClass: 'transition-off',
          keyboard: !!(myThis.params && myThis.params.preventEscape),
          backdropClick: false,
          template: myThis.data.template,
          controller: myThis.data.controller,
          resolve: {
            saveAction: function () {
              return function (newEntity, oldEntity, callback) {
                return myThis.action(newEntity, oldEntity, callback);
              };
            },
            parameters: function () {
              return dataParams ? dataParams : {};
            }
          },
          closeFn: function () {
          }
        };

        $modal.open(myThis.opts);
      };
    };

    actions['magicAdd'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = 'icon icon-magic-add';
      this.hasExternalResource = true;
      this.func = function (event, dataParams) {
        myThis.opts = {
          backdrop: 'static',
          windowClass: 'transition-off',
          keyboard: !!(myThis.params && myThis.params.preventEscape),
          backdropClick: false,
          template: myThis.data.template,
          controller: myThis.data.controller,
          resolve: {
            saveAction: function () {
              return function (newEntity, oldEntity, callback) {
                return myThis.action(newEntity, oldEntity, callback);
              };
            },
            parameters: function () {
              return dataParams ? dataParams : {};
            }
          },
          closeFn: function () {
          }
        };

        $modal.open(myThis.opts);
      };
    };

    actions['tutorials'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = 'icon icon-help';
      this.hasExternalResource = true;
      this.func = function () {
        myThis.opts = {
          backdrop: 'static',
          windowClass: 'transition-off',
          template: require('../../modal-views/tutorials/tutorials.modal.html'),
          controller: 'TutorialsController',
        };

        $modal.open(myThis.opts);
      };
    };

    actions['cashRegisterActivities'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = 'icon icon-wallet';
      this.hasExternalResource = true;
      this.func = function (event, dataParams) {
        myThis.opts = {
          backdrop: 'static',
          keyboard: false,
          backdropClick: false,
          template: require("../../modal-views/cash-register-activities/cash.register.activities.modal.tpl.html"),
          controller: "CashRegisterActivitiesModalController",
          resolve: {
            saveAction: function () {
              return function (newEntity, oldEntity, callback) {
                myThis.action(newEntity, oldEntity, callback);
              };
            },
            parameters: function () {
              return myThis.data;
            }
          },
          closeFn: function () {
            console.log("closing");
          }
        };

        $modal.open(myThis.opts);
      };
    };

    actions['cashRegisterActions'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = 'icon icon-enter';
      this.hasExternalResource = true;
      this.func = function (event, dataParams) {
        myThis.opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require("../../modal-views/cash-register-daily-actions/cash.register.daily.actions.modal.tpl.html"),
          controller: "CashRegisterDailyActionsModalController",
          resolve: {
            dayClosed: function () {
              return myThis.data ? myThis.data.dayClosed : undefined;
            }
          },
          closeFn: function () {
            console.log("closing");
          }
        };

        var modalInstance = $modal.open(myThis.opts);
      };
    };

    actions['reportActions'] = function () {
      var myThis = this;
      this.order = 0;
      this.hasExternalResource = true;

      this.func = function () {
        myThis.scope.showCustomReports = !myThis.scope.showCustomReports;
      };

      this.decorate = function (outerScope, element) {
        myThis.scope = outerScope;
        myThis.scope.toggle = myThis.func;
      };
    };

    actions['conversations'] = function () {
      const myThis = this;
      this.order = 0;
      this.hasExternalResource = true;
      this.func = function () {
        $rootScope.$emit('toggle-conversations');
      };

      this.decorate = function (outerScope, element) {
        element.addClass('relative');
        element.find('.toolbar-action-icon > span').addClass('icon icon-conversations');
        const updateUnreadCount = (count) => {
          element.find('.header-control__unread-count').remove()
          if (count === 0) {
            element.find('.toolbar-action-icon > span').removeClass('text--primary');
          } else {
            element.find('.toolbar-action-icon > span').addClass('text--primary');
            element.append(`<div class="header-control__unread-count background--primary">${count}</div>`);
          }
        }

        const audioElement = document.getElementById('whatsappSound');
        Repository.Custom('Conversations').unreadCount().then(updateUnreadCount).catch((err) => {
          toastr.error(err.message)
        })

        $rootScope.$on('conversation-change', (e, data) => {
          updateUnreadCount(data.totalUnread)
          if (data.unreadCount > 0 && !isWaSilentMode) {
            audioElement.play();
          }
        })

      };
    };

    actions['whatsappOnboarding'] = function (item) {
      const myThis = this;
      this.order = 0;
      this.hasExternalResource = true;
      this.func = function () {
        $rootScope.$emit('toggle-whatsapp-onboarding');
      };

      this.decorate = function (outerScope, element) {
        element.find('.toolbar-action-icon > span').addClass('icon icon-whatsapp' + (item.blinking ? ' blinking' : ''));
      };
    };

    actions['userSettings'] = function (item) {
      const myThis = this;
      this.order = 0;
      this.icon = 'icon icon-user-profile'
      this.actions = [
        {
          name: localize.getLocalizedString("_GenerateIrsReports_"),
          icon: 'icon icon-file-zip',
          action: () => {
          }
        },
        {
          name: localize.getLocalizedString("_GenerateReports_"),
          icon: 'icon icon-chart-line',
          action: () => {
          }
        }]

      this.hasExternalResource = true;
      this.tooltip = localize.getLocalizedString('_UserSettings_')

      this.func = function () {
        myThis.scope.showUserSettings = !myThis.scope.showUserSettings;
      };


      this.decorate = function (outerScope, element) {
        myThis.scope = outerScope;
        myThis.scope.toggle = myThis.func;
        //   element.find('.toolbar-action-icon > span').addClass('icon icon-user-profile' + (item.blinking ? ' blinking' : ''));
      };
    };

    actions['download'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = 'icon icon-print';
      this.hasExternalResource = true;
      this.func = function (event, dataParams) {
        return myThis.action(dataParams.getId());
      };
    };

    actions['notifyBySMS'] = function () {
      var myThis = this;
      this.order = 0;
      this.icon = 'icon icon-sms';
      this.hasExternalResource = true;
      this.func = function (event, dataParams) {
        return myThis.action(event, dataParams);
      };
    };

    actions['custom'] = function () {
      var myThis = this;
      this.order = 0;
      this.hasExternalResource = true;

      this.func = function () {
        myThis.action();
      };

      this.decorate = function (outerScope, element) {
        if (this !== myThis) {
          myThis?.decorate?.(outerScope, element);
        }
      };
    };

    this.actions = actions;
    return {
      actionsForCurrentView: that.actionsForCurrentView,
      constantActions: that.constantActions,
      set: function (actions) {
        applyActions(addItemsToArray(actions, []));
      },
      setConstantActions: function (actions) {
        applyActions(addItemsToArray(actions, []), true);
      },
      register: function (func) {
        this.listeners.push(func);
      }
    };

    function applyActions(actionsForView, addToConstantActions) {
      var arrayOfActionsToAddTo = addToConstantActions ? that.constantActions : that.actionsForCurrentView;

      arrayOfActionsToAddTo.length = 0;
      angular.forEach(actionsForView, function (value, key) {
        arrayOfActionsToAddTo.push(value);
      });
    }

    function pushItemToArray(item, array) {
      that.scope = null;
      var toolbarAction = new that.actions[item.name](item);
      angular.extend(toolbarAction, item);
      array.push(toolbarAction);
    }

    function addItemsToArray(item, newArray) {
      // item is either a single or array of {name: , action} pairs.
      if (Object.prototype.toString.call(item) === '[object Array]') {
        angular.forEach(item, function (value, key) {
          pushItemToArray(value, newArray);
        });
      } else {
        pushItemToArray(item, newArray);
      }

      return newArray;
    }
  }]);


toolbarActions.directive('toolbarActions', [
  'toolbar', 'localize', '$rootScope', function (toolbar, localize, $rootScope, configuration) {
    return {
      restrict: 'E',
      replace: true,
      scope: true,
      link: function (scope, element, attrs) {
        scope.toolbar = toolbar;
        scope.isVisible = function (item) {
          return item.isVisible || !angular.isDefined(item.isVisible);
        };

        scope.$watchCollection('toolbar.actionsForCurrentView', function (newVal) {
          delete scope.headerSearch;
          for (var actionIdx in toolbar.actionsForCurrentView) {
            var action = toolbar.actionsForCurrentView[actionIdx];
            if (action.name === 'headerSearch') {
              scope.headerSearch = action;
              break;
            }
          }
        });

        scope.socketStatusIcon = 'icon icon-socket-connecting';

        $rootScope.$on('socket-status-changed', function (event, args) {
          scope.$evalAsync(function () {
            scope.socketConnected = false;
            switch (args.status) {
              case 'connected':
                scope.socketStatusIcon = 'icon icon-socket-ok';
                scope.socketConnected = true;
                break;
              case 'disconnected':
                scope.socketStatusIcon = 'icon icon-socket-error';
                break;
            }
          });

        });


        scope.enablePickingEmployee = true; // TODO: DANIEL configuration.get()..... enforceCurrentEmployee no means don't show it.
      },
      template: `<div class="headers-controls-container">
                       <div class="headers-controls-wrapper">
                           <toolbar-action ng-if="isVisible(item)" ng-repeat="item in toolbar.constantActions | orderBy:'order'" bindonce></toolbar-action>
                           <toolbar-action ng-if="item.type != 'custom' && !item.hidden" ng-repeat="item in toolbar.actionsForCurrentView | orderBy:'order'" bindonce></toolbar-action>
<!--                           <current-employee ng-if="enablePickingEmployee" />-->
                       </div>
                       <header-search ng-if="headerSearch" meta="headerSearch"></header-search>
                   </div>`
    };
  }]);

toolbarActions.directive('headerSearch', [
  'toolbar', 'localize', 'Repository', '$compile', '$timeout',
  function (toolbar, localize, Repository, $compile, $timeout) {
    var notificationCenterTemplate = '<notification-center items="items" sections="sections" \
                                                               has-notifications="hasNotifications" \
                                                               ignore-outside-click="true" \
                                                               show="showNotifications"></notification-center>';
    return {
      scope: {
        meta: '='
      },
      restrict: 'E',
      replace: true,
      link: function (scope, element, attrs) {
        scope.searchPlaceHolder = localize.getLocalizedString("_SearchEverything_");
        scope.searchText = "";
        scope.showNotifications = false;
        scope.hasNotifications = false;
        scope.sections = [];

        var wasFocused = false;
        var input = element.find('.header-search-input');
        var headerSearchIcon = element.find('.header-search-icon');

        headerSearchIcon.on('mousedown', function (e) {
          if (input.is(":focus")) {
            wasFocused = true;
          }
        }).on('click', function () {
          if (wasFocused) {
            scope.searchText = "";
            scope.$digestIfNeeded();
          } else {
            element.addClass("visible-search");
            scope.showNotifications = true;
            scope.$digestIfNeeded();
          }

          wasFocused = false;
        }).on('mouseenter', function () {
          element.addClass("visible-search");
          setTimeout(function () {
            input.focus();
          }, 200)
        }).on('mouseout', function () {
          scheduleHideSearch();
        });

        input.on('focus', function () {
          setTimeout(function () {
            input.select();
            input[0].setSelectionRange(0, 9999);
          }, 0);
        });

        var delayedSearchObject = new DelayedFunction(findByString, 300);
        scope.$watch('searchText', function (newVal, oldVal) {
          if (!newVal || newVal.length === 0) {
            scheduleHideSearch();
            if (headerSearchIcon.hasClass("icon-flat-close")) {
              headerSearchIcon.addClass('icon-search').removeClass('icon-flat-close');
            }
          } else if (!oldVal || oldVal.length === 0) {
            headerSearchIcon.removeClass('icon-search').addClass('icon-flat-close');
          } else {
            delayedSearchObject.set(newVal);
          }
        });

        // searchDelegateWrapper ==> {search: func()}
        var repository = scope.meta.searchDelegateWrapper || Repository.Custom("TextSearch");

        function findByString(string) {
          if (string.length < 2) {
            return;
          }

          scope.isLoading = true;

          repository.search(string)
            .then(function (res) {
              scope.isLoading = false;
              searchResultsReceived(res);
            })
            .catch(function (err) {
              scope.isLoading = false;
              toastr.error(localize.getLocalizedString('_ErrorInSearch_'));
            })
        }

        scope.searchFocused = function () {
          if (scope.items && scope.items.length > 0) {
            scope.showNotifications = true;
          }
        };

        function searchResultsReceived(items) {
          if (items) {
            items.forEach(function (item) {
              item.Id = item.MeetingId;
            })
          }

          scope.$evalAsync(() => scope.items = items);
        }

        var scheduled = undefined;
        var wasSearchInitilized = false;

        function scheduleHideSearch() {
          if (angular.isDefined(scheduled)) {
            clearTimeout(scheduled);
          }

          scheduled = setTimeout(hideSearch, 15000);

          if (!wasSearchInitilized) {
            wasSearchInitilized = true;
            element.children(":first").append($compile(notificationCenterTemplate)(scope));
          }
        }


        function hideSearch() {
          if (scope.searchText.length > 0) {
            return;
          } else if (element.is(":focus") || input.is(":focus") || scope.showNotifications) {
            scheduleHideSearch();
          } else {
            element.removeClass("visible-search");
          }
        }

        scope.isTextFocused = true;

        scope.outside = function () {
          scope.showNotifications = false;
          scope.searchText = "";
        }
      },
      template: '<div class="header-search-wrapper">\
                            <div class="header-search-content" outside-click="outside()" active="showNotifications">\
                                <div class="header-search relative-container">\
                                    <div class="icon icon-search header-search-icon"></div>\
                                    <div class="absolute-container header-search-mask"></div>\
                                    <input class="header-search-input" type="text" ng-focus="searchFocused()" \
                                           ng-model="searchText" placeholder="{{::searchPlaceHolder}}..." />\
                                </div>\
                                <material-loader class="header-search-loader animate-opacity-appearance" is-loading="isLoading"></material-loader>\
                            </div>\
                       </div>'
    }
  }]);

toolbarActions.directive('toolbarAction', [
  '$compile', 'toolbarTemplateResolver', '$timeout',
  function ($compile, toolbarTemplateResolver, $timeout) {

    function toggleIcons(icons, current) {
      var nextIconIndex = icons.indexOf(current);
      if (nextIconIndex < icons.length - 1) {
        nextIconIndex++;
      } else {
        nextIconIndex = 0;
      }

      return icons[nextIconIndex];
    }

    return {
      restrict: 'E',
      transclude: true,
      replace: true,
      scope: true,
      link: function (scope, element, attrs) {
        scope.icon = scope.item.icon;

        var toggleIcon;

        if (scope.item.type === 'togglebutton') {
          toggleIcon = toggleIcons.bind(this, scope.item.icons);
        }

        scope.actionFunction = function () {
          if (toggleIcon) {
            scope.item.icon = toggleIcon(scope.item.icon);
          }

          scope.item.func(null, scope.item.params);
        };

        if (!scope.item.hasExternalResource) {
          return;
        }

        var attributesToAttach = {
          'ng-click': '::actionFunction($event)'
        };

        if (scope.item.tooltip) {
          var tooltipAttributes = {tippy: scope.item.tooltip}
          angular.extend(attributesToAttach, tooltipAttributes);
        }

        $compile(element.find('.toolbar-action-icon').attr(attributesToAttach))(scope);

        var template = toolbarTemplateResolver.get(scope.item.name);
        if (!template && angular.isDefined(scope.item.decorate)) {
          scope.item.decorate(scope, element);
          return;
        }

        // Load dynamic template.
        var innerContent = $compile(template)(scope);
        innerContent.addClass('not-displayed');
        element.append(innerContent);
        $timeout(function () {
          innerContent.addClass('default-display display-toggle');
        }, 500);

        scope.item.element = element;

        if (!angular.isDefined(scope.item.decorate)) {
          return;
        }

        scope.item.decorate(scope, innerContent);

      },
      template: `<div class="header-control">
                    <div class="toolbar-action-icon" foreground-click="true">
                        <span ng-class="item.icon" />
                    </div>
                 </div>`
    };
  }]);

toolbarActions.directive('currentEmployee', (Repository, $q, $rootScope, localize, confirmService, authenticationService, configuration) => {
  return {
    restrict: 'E',
    replace: true,
    link: function (scope) {
      getEmployees().then(updateCurrentEmployeeId);
      const currentEmployeeText = localize.getLocalizedString('_CurrentContextEmployee_');
      scope.currentEmployeeTooltip = currentEmployeeText;

      scope.selectEmployee = (employee) => {
        const {EmployeeId} = employee;
        // TODO: DANIEL please confirm in the configurations weather or not current employee should only be enabled using ID, password or none.
        //
        // configuration.get()..... enforceCurrentEmployee

        const enforceCurrentEmployee = 'no'; // 'id', '----ANYTHING ELSE-----';

        if (enforceCurrentEmployee === 'id') {
          confirmService.prompt(
            /*title*/localize.getLocalizedString("_IdentificationToValidate_"),
            '', '',
            /*onOk*/function () {
              updateCurrentEmployee(EmployeeId);
            },
            function () {
            },
            function (value) {
              // TODO: DANIEL Compare with employee Identification (Israeli Id Number).
              return value !== employee.Identification ? localize.getLocalizedString('_WrongIdentification_') : '';

            },
          );
        } else if (enforceCurrentEmployee === 'password') {
          authenticationService.authenticate()
            .then(function () {
              updateCurrentEmployee(EmployeeId);
            })
            .catch(function () {
              console.log('fail!')
            });
        } else {
          updateCurrentEmployee(EmployeeId);
        }
      };

      scope.toggle = (value) => {
        scope.visible = angular.isDefined(value) ? value : !scope.visible;
      };

      function updateCurrentEmployee(EmployeeId) {
        return Repository.Custom("Configurations").setEmployeeForSession(EmployeeId).then(function () {
          updateCurrentEmployeeId().then(() => {
            scope.toggle(false)
          }).catch((e) => {
            toastr.error(e.message);
          })
        }).catch((e) => {
          toastr.error(e.message);
        })
      }

      function updateCurrentEmployeeId() {
        const deferred = $q.defer();

        Repository.Custom("Configurations").loggedInUser().then(({employeeId}) => {
          if (employeeId) {
            getEmployees().then((employees) => {
              employees.forEach((employee) => {
                if (employee.EmployeeId === employeeId) {
                  scope.currentEmployee = employee;
                  scope.currentEmployeeTooltip = `${currentEmployeeText} ${employee.Name}`;
                }
              });

              deferred.resolve(scope.currentEmployee);
            });
          } else {
            scope.currentEmployee = null;
            deferred.resolve(null);

          }
        });

        return deferred.promise;
      }

      function getEmployees() {
        const deferred = $q.defer();

        if (!scope.employees) {
          Repository.Entity("Employee").query().expand('Image').get()
            .then(({value}) => {
              scope.employees = value.map(({FirstName, LastName, Image, EmployeeId, Identification}) => ({
                Name: `${FirstName || ''} ${LastName || ''}`,
                EmployeeId,
                Image: Image ? window.filesURI + Image.FilePath + '?size=thumbnail' : '',
                Identification
              }));

              deferred.resolve(value);
            });
        } else {
          deferred.resolve(scope.employees);
        }

        return deferred.promise;
      }
    },
    template: `<div class="header-control">
                    <div class="toolbar-current-employee" ng-click="toggle()" tippy="{{ currentEmployeeTooltip }}"
                         foreground-click="true"><div class="flex-center cover-background-image" validate-image back-img="currentEmployee.Image">
                            <span ng-if="currentEmployee">{{currentEmployee.Name.initials()}}</span>
                            <span ng-if="!currentEmployee" class="icon icon-employee double-font"></span>
                        </div>
                     </div>
                    <div class="employees-option">
                        <div class="employees-option__mask" ng-class="visible && 'visible'" ng-click="toggle(false)" foreground-click="true"></div>
                        <div class="employees-option__items" ng-class="visible && 'visible'">
                            <div ng-repeat="employee in employees" class="employees-option__employee" ng-class="currentEmployee.EmployeeId === employee.EmployeeId && 'selected'" ng-click="selectEmployee(employee)">{{ employee.Name }}</div>
                            <div class="employees-option__employee" ng-click="selectEmployee({EmployeeId: -1})" i18n="_CancelCurrentEmployee_"></div>
                        </div>
                    </div>
               </div>`
  };

});

toolbarActions.factory('toolbarTemplateResolver', function () {
  var templates = {
    search: '<div class="searchbox-container" ng-class="{\'zero-opacity\': !showSearchWrapper.isVisible}" outside-click="toggle()" active="showSearchWrapper.isVisible">\
                <input type="text" ng-model="searchString"/>\
                <div class="icon icon-flat-close clear-action" ng-class="{\'zero-opacity\': searchString.length == 0}" ng-click="clear()"></div>\
            </div>',
    notification: '<notification-center show="showNotifications" class="notification-sections-wrapper" independent-sections="sections" \
            has-notifications="hasNotifications"></notification-center>',
    feedback: '<feedback show="showFeedbackForm"></feedback>',
    helpVideos: '<help-videos show-wrapper="showHelpVideos" videos="videos"></help-videos>',
    reportActions: '<custom-reports-actions show="showCustomReports" actions="item.actions"></custom-reports-actions>',
    userSettings: '<user-settings-actions show="showUserSettings" actions="item.actions"></user-settings-actions>',
  };

  return {
    get: get
  };

  function get(actionName) {
    return templates[actionName];
  }

});
