<template>
  <div class="wizard-tile-selection-wrapper">
    <div class="wizard-tile-selection">
      <div class="wizard-tile-selection__item-wrapper relative" v-for="item in items" @click="select(item)"
           :class="itemClass(item)">
        <div class="wizard-tile-selection__item">
          <div class="wizard-tile-selection__item-icon icon" :class="`icon-${item.icon}`" v-if="item.icon"></div>
          <img class="wizard-tile-selection__item-img" :src="item.img" v-if="item.img"/>
          <h3 class="wizard-tile-selection__item-text">{{ item.he || item.name }}</h3>
        </div>
        <div v-if="multiSelect"
             class="wizard-tile-selection__multi-select-toggle flex-container flex-center"
             :class="isSelected(item)">
          <div class="icon icon-check"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { resolveCondition } from "@tomeravni/easybizy-js-common/common";

export default {
  components: {},
  props: {
    items: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      required: false
    },
    multiSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    select(item) {
      let updatedSelection = [item];
      if (this.multiSelect) {
        updatedSelection = [...this.selected];
        const selectedIndex = updatedSelection.indexOf(item);
        if (selectedIndex < 0) {
          updatedSelection.push(item);
        } else {
          updatedSelection.splice(selectedIndex, 1);
        }
      }

      this.$emit('itemSelected', updatedSelection);
    },
    isSelected(item) {
      return this.selected.includes(item);
    },
    itemClass(item) {
      return resolveCondition(this.isSelected(item), 'wizard-tile-selection__item-wrapper--selected', '');
    }
  },
}

</script>

<style lang="less">
@import "../../../../../styles/variables";

.wizard-tile-selection-wrapper {
  display: flex;
  justify-content: center;
  max-width: 70vw;
  max-height: 65vh;
  overflow-y: auto;
  padding: 1vh;

}

.wizard-tile-selection {
  justify-content: flex-start;
  display: flex;
  column-gap: @padding-container;
  flex-wrap: wrap;
}

.wizard-tile-selection__item-wrapper {
  flex-grow: 0;
  flex-basis: 0;
  min-width: 150px;
  text-align: center;
  padding: 25px;
  background: @content-background-color;
  border: @border-default-width solid @tile-border-color;
  border-radius: @default-border-radius;
  cursor: pointer;
  margin-bottom: 15px;

  .CubicAnimation(0.15s, transform);

  &:hover {
    transform: scale(1.05, 1.05);
  }

  &--selected {
    transform: scale(1.05, 1.05);
    border-color: @tile-selected-color;

    .wizard-tile-selection__multi-select-toggle {
      background: @submit-color;
      border-color: @submit-color;
    }
  }
}

.wizard-tile-selection__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .wizard-tile-selection__item-icon {
    font-size: 40px;
  }

  .wizard-tile-selection__item-img {
    height: 100px;
    width: 100px;
    object-fit: contain;
  }

  .wizard-tile-selection__item-text {
    font-size: 20px;
  }
}


.wizard-tile-selection__multi-select-toggle {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 1.5em;
  height: 1.5em;
  border: @tile-border-width solid @tile-border-color;
  border-radius: 50%;

  div {
    color: @combo-text-color;
    font-size: 80%;
  }
}

</style>
