import './plain.numbers.widget.directive.less'

angular.module('easybizy.reports').directive('plainNumbersWidget',
  function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        data: '=',
        fields: '='
      },
      template: require("./plain.numbers.widget.directive.html")
    };
  });

