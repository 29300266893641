import './inventory.less'
import GlobalWizardController from "../../vue/global.wizard.controller";
import {ObservableProperties} from "../../general/helpers";
import {cleanObj, isNonEmptyObject} from "@tomeravni/easybizy-js-common/common";

angular.module('easybizy.products').controller('InventoryController', function ProductsController($scope, Repository, toolbar, localize,
                                                                                                  configuration, entityImageResolver, confirmService,
                                                                                                  loggedInUser,
                                                                                                  $timeout, $state, $q, $modal, onBoarding, stateManager) {

  const confirmMessage = localize.getLocalizedString('_ApproveGeneralConfirm_');

  $scope.clearSearchFuncWrapper = {};
  toolbar.set([
    {
      name: 'custom',
      icon: 'icon icon-entity-settings',
      action: function () {
        const opts = {
          backdrop: 'static',
          keyboard: false,
          template: require('../../modal-views/custom-entity-settings/custom.entity.config.modal.tpl.html'),
          controller: 'CustomEntityConfigurationsController',
          resolve: {
            views: () => {
              return [
                {
                  loadItemsDelegate: () => Repository.Custom('CategoriesEditorRepository').get("ProductCategories"),
                  name: localize.getLocalizedString('_ProductCategories_'),
                  renameDelegate: (item, newName) => {
                    return Repository.Custom('CategoriesEditorRepository').rename("ProductCategories", item.value, newName);
                  },
                  actions: [
                    {
                      name: localize.getLocalizedString('_Unite_'),
                      confirmMessage: confirmMessage,
                      minSelectionLength: 2,
                      delegate: (items) => {
                        return Repository.Custom('CategoriesEditorRepository').merge("ProductCategories", items);
                      }
                    },
                  ]
                },
                {
                  loadItemsDelegate: () => Repository.Custom('CategoriesEditorRepository').get("ServiceCategories"),
                  name: localize.getLocalizedString('_ServiceCategories_'),
                  renameDelegate: (item, newName) => {
                    return Repository.Custom('CategoriesEditorRepository').rename("ServiceCategories", item.value, newName);
                  },
                  actions: [
                    {
                      name: localize.getLocalizedString('_Unite_'),
                      confirmMessage: confirmMessage,
                      minSelectionLength: 2,
                      delegate: (items) => {
                        return Repository.Custom('CategoriesEditorRepository').merge("ServiceCategories", items);
                      }
                    },
                  ]
                },
                {
                  loadItemsDelegate: () => Repository.Custom('CategoriesEditorRepository').get("Manufacturers"),
                  name: localize.getLocalizedString('_Manufacturer_'),
                  renameDelegate: (item, newName) => {
                    return Repository.Custom('CategoriesEditorRepository').rename("Manufacturers", item.value, newName);
                  },
                  actions: [
                    {
                      name: localize.getLocalizedString('_Delete_'),
                      type: 'warning-submit',
                      confirmMessage: confirmMessage,
                      delegate: (items) => {
                        return Repository.Custom('CategoriesEditorRepository').remove("Manufacturers", items);
                      }
                    },
                    {
                      name: localize.getLocalizedString('_Unite_'),
                      confirmMessage: confirmMessage,
                      minSelectionLength: 2,
                      delegate: (items) => {
                        return Repository.Custom('CategoriesEditorRepository').merge("Manufacturers", items);
                      }
                    },
                  ]
                },
                {
                  loadItemsDelegate: () => Repository.Custom('CategoriesEditorRepository').get("ProductMetadatas"),
                  name: localize.getLocalizedString('_RestoreProduct_'),
                  actions: [
                    {
                      name: localize.getLocalizedString('_Restore_'),
                      type: 'warning-submit',
                      confirmMessage: confirmMessage,
                      delegate: (items) => {
                        return Repository.Custom('CategoriesEditorRepository').restore("ProductMetadatas", items);
                      }
                    }
                  ]
                },
                {
                  loadItemsDelegate: () => Repository.Custom('CategoriesEditorRepository').get("ServiceMetadatas"),
                  name: localize.getLocalizedString('_RestoreService_'),
                  actions: [
                    {
                      name: localize.getLocalizedString('_Restore_'),
                      type: 'warning-submit',
                      confirmMessage: confirmMessage,
                      delegate: (items) => {
                        return Repository.Custom('CategoriesEditorRepository').restore("ServiceMetadatas", items);
                      }
                    }
                  ]
                },
                {
                  loadItemsDelegate: () => Repository.Custom('CategoriesEditorRepository').get("PriceLists"),
                  name: localize.getLocalizedString('_PriceLists_'),
                  renameDelegate: (item, newName) => {
                    return Repository.Custom('CategoriesEditorRepository').rename("PriceLists", item.value, newName);
                  },
                  actions: [
                    {
                      name: localize.getLocalizedString('_Delete_'),
                      type: 'warning-submit',
                      confirmMessage: confirmMessage,
                      delegate: (items) => {
                        return Repository.Custom('CategoriesEditorRepository').remove("PriceLists", items);
                      }
                    }
                  ]
                }
              ];
            }
          },
        };

        $modal.open(opts);
      },
      tooltip: localize.getLocalizedString('_EntityConfig_')
    },
    {
      name: 'custom',
      icon: 'icon icon-excel',
      action: function () {
        const api = $scope.mediator.dataSource.api;
        const currentQuery = api.getQuery().replace(api.getBaseQuery(), '');
        Repository.Custom('ReportsRepository').exportProducts(currentQuery);
      },
      disabled: false,
      tooltip: localize.getLocalizedString('_Export_')
    },
    {
      name: 'custom',
      icon: 'icon icon-magic-add',
      action: function () {

        const wizard = new GlobalWizardController();
        onBoarding.controller = wizard;
        $scope.wizardController = wizard;
        onBoarding.getFirstStep().then((step) => {
          wizard.steps = [step];
          $scope.wizardController = wizard;
        });

        $timeout(() => {
          $scope.isWizrdVisible = !$scope.isWizrdVisible;
        });

      },
      disabled: false,
      tooltip: localize.getLocalizedString('_Import_')
    },
    {
      name: 'add',
      action: function (productOrService) {
        return createNewProductOrService(productOrService);
      },
      data: {
        template: require('../../modal-views/add-product/add.product.modal.tpl.html'),
        controller: 'AddProductModalController'
      }
    }
  ]);

  let existingQuery = stateManager.getValidRawParams().query;
  try {
    existingQuery = cleanObj(JSON.parse(existingQuery));
    if (!isNonEmptyObject(existingQuery)) {
      existingQuery = undefined;
    }

  } catch (e) {
    existingQuery = undefined;
  }

  let existingSort = stateManager.getValidRawParams().sort;
  try {
    existingSort = {desc: existingSort.indexOf('-') === 0, field: existingSort.replace('-', '')};
  } catch (e) {
    existingSort = undefined;
  }

  const configurations = configuration.get();
  stateManager.registerStateChanged('Products', (state) => {
    $scope.mediator.viewType = state.viewType;
  });
  
  $scope.mediator = {
    onLoadQuery: existingQuery,
    onLoadSort: existingSort,
    viewType: stateManager.getValidRawParams().viewType || 'services',
    showPriceLists: configurations.ProductsAndServicesSettings.UsePriceLists,
    showServiceCost: configurations.ProductsAndServicesSettings.ShowServiceCost,
    showIngredients: !configurations.ProductsAndServicesSettings.IgnoreIngredients || configurations.ProductsAndServicesSettings.IncludeIngredientsModelForCustomer,
    showExternalVouchers: configurations.ProductsAndServicesSettings.UseExternalVoucherModel,
    enableBarcode: configurations.ProductsAndServicesSettings.UsingBarcode,
    enablePoints: configurations.PointsSettings.EnablePoints && configurations.PointsSettings.PurchaseTypeToInclude && configurations.PointsSettings.PurchaseTypeToInclude.Value === "Custom",
    serviceTimeInterval: configurations.CalendarSettings.MinimumMeetingLengthInMinutes.Value,
    addPriceList: () => {
      const deferred = $q.defer();

      const opts = {
        backdrop: 'static',
        keyboard: false,
        template: require('../../modal-views/add-pricelist/add.pricelist.tpl.html'),
        controller: 'AddPriceListController',
        resolve: {
          saveAction: () => {
            return (pricelist) => {
              return Repository.Custom('PriceLists').post(pricelist)
                .then((res) => {
                  deferred.resolve('...');
                  return res;
                }).catch((err) => {
                  deferred.reject(err);
                  return err;
                });
            }
          }
        }
      };

      $modal.open(opts);
      return deferred.promise;
    },
    remove: (entity) => {
      const deferred = $q.defer();
      confirmService.confirm(localize.getLocalizedString('_ApproveDeleteMessage_'), null, function () {
        Repository.Entity(entity.ConcreteType).remove(entity.Id)
          .then(function () {
            toastr.success(localize.getLocalizedString("_EntityRemovedSuccessfully_"));
            deferred.resolve();
          })
          .catch(function (err) {
            toastr.error(err);
            deferred.reject(err);
          });
      }, null, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_'));
      return deferred.promise;
    },
    isAdmin: !!loggedInUser.isAdmin
  };

  ObservableProperties($scope.mediator);
  $scope.mediator.on('viewTypeChanged', ({viewType, prev}) => {
    const params = !prev ? stateManager.getValidRawParams() : {};
    stateManager.setState('Products', {
      viewType,
      query: params.query,
      sort: params.sort
    }, false, true);
  });

  $scope.mediator.on('filterChange', (adaptedFilters) => {
    const query = cleanObj({...adaptedFilters});
    const params = stateManager.getValidRawParams();
    stateManager.setState('Products', {
      viewType: params.viewType,
      sort: params.sort,
      query: isNonEmptyObject(query) ? JSON.stringify(query) : undefined
    }, false, true);
  });

  $scope.mediator.on('sortChange', (sort) => {
    const params = stateManager.getValidRawParams();
    let validSort = undefined;
    if (sort) {
      validSort = `${sort.desc ? '-' : ''}${sort.column.fieldName}`
    }

    stateManager.setState('Products', {
      viewType: params.viewType,
      sort: validSort,
      query: params.query
    }, false, true);
  });


  function createNewProductOrService(productOrServiceOrExternalVoucher) {
    return Repository.Custom("EntitiesLazyRepository").create(productOrServiceOrExternalVoucher)
      .then(function (result) {
        $scope.mediator.dataSource.initialize();
        return result;
      });
  }

});
