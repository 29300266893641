import {
  COLUMN_EDITOR_TYPE, GRID_FILTER_TYPE,
  GridApiResult,
  GridColumn,
  LazyGridDataSource,
  DatePickerLabels,
  SortByColumn
} from "@tomeravni/easybizy-vue-components";

import Moment from "moment"
import { isNonEmptyArray, isNonEmptyObject } from "@tomeravni/easybizy-js-common/common";
import {
  SERVER_DATA_DATE_FORMAT,
  RTL_DATE_TIME_DISPLAY,
  ODATA_DATE_TIME_FORMAT
} from "../../constants";

export default class MeetingClosingDS extends LazyGridDataSource {
  constructor(api, localize, stateManager, mediator, Repository, printerMediator) {
    super();
    this._api = api;

    const nameCol = new GridColumn('CustomerName', localize('_Customer_'), COLUMN_EDITOR_TYPE.LAZY);
    nameCol.width = 170;
    nameCol.filterType = GRID_FILTER_TYPE.STRING;
    nameCol.filterArgs = { label: localize('Search'), debounce: true };
    nameCol.freeze = true;

    const meetingStartTime = new GridColumn('MeetingStartTime', localize('_StartTime_'), COLUMN_EDITOR_TYPE.LAZY, true);
    meetingStartTime.width = 150;
    meetingStartTime.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    meetingStartTime.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    meetingStartTime.freez = true;
    meetingStartTime.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', Moment(entity.MeetingStartTime, SERVER_DATA_DATE_FORMAT).format(RTL_DATE_TIME_DISPLAY));
    };

    const meetingEndTime = new GridColumn('MeetingEndTime', localize('_EndTime_'), COLUMN_EDITOR_TYPE.LAZY);
    meetingEndTime.width = 150;
    meetingEndTime.isVisible = false;
    meetingEndTime.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', Moment(entity.MeetingStartTime, SERVER_DATA_DATE_FORMAT).format(RTL_DATE_TIME_DISPLAY));
    };

    const meetingEmployeeName = new GridColumn('MeetingEmployeeName', localize('_EmployeeName_'), COLUMN_EDITOR_TYPE.LAZY, true);
    meetingEmployeeName.width = 150;
    meetingEmployeeName.filterType = GRID_FILTER_TYPE.MULTI_SELECT;

    const employees = mediator.employees.filter((x) => x.VisibleOnCalendar).map((employee) => ({
      text: employee.FirstName + " " + employee.LastName,
      value: employee.EmployeeId
    }));

    meetingEmployeeName.options = [...employees];
    meetingEmployeeName.filterArgs = {
      placeholder: localize('_Employee_'),
      options: employees.map((employee) => ({
        text: employee.text,
        value: `MeetingEmployeeId eq ${employee.value}`
      }))
    };


    const allEmployees = mediator.employees.map((employee) => ({
      text: employee.FirstName + " " + employee.LastName,
      value: employee.EmployeeId
    }));

    const employeeDictionary = Object.assign({}, ...allEmployees.map((employee) => ({
      [employee.value]: employee.text
    })));

    let createdByEmployee;
    if (mediator.showCreatedBy) {
      createdByEmployee = new GridColumn('CreatedByEmployeeId', localize('_MeetingSetBy_'), COLUMN_EDITOR_TYPE.LAZY);
      createdByEmployee.width = 150;
      createdByEmployee.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
      createdByEmployee.customRenderFunc = (entity, changeDelegate, column, h) => {
        return h('p', entity.CreatedByEmployeeId ? employeeDictionary[entity.CreatedByEmployeeId] : '');
      };
      createdByEmployee.filterArgs = {
        placeholder: localize('_Employee_'),
        options: mediator.employees.map((employee) => ({
          text: employee.FirstName + " " + employee.LastName,
          value: `CreatedByEmployeeId eq ${employee.EmployeeId}`
        }))
      };
    }

    const meetingServiceName = new GridColumn('MeetingServiceName', localize('_ServiceName_'), COLUMN_EDITOR_TYPE.LAZY, true);
    meetingServiceName.width = 150;
    meetingServiceName.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
    Repository.Entity('ServiceMetadata').query().filter(`(IsDeactiveted eq false and Hidden eq false)`).select('ServiceMetadataId,ServiceName').get().then(({ value }) => {
      const services = value.map((service) => ({
        text: service.ServiceName,
        value: service.ServiceMetadataId
      }));

      meetingServiceName.options = [...services];
      meetingServiceName.filterArgs = {
        placeholder: localize('_Service_'),
        options: services.map((service) => ({
          text: service.text,
          value: `MeetingServiceMetadataId eq ${service.value}`
        }))
      };
    });


    const originalPrice = new GridColumn('ServiceOriginalPrice', localize('_ServiceOriginalPrice_'), false, true);
    originalPrice.width = 80;

    const paid = new GridColumn('Paid', localize('_Status_'), COLUMN_EDITOR_TYPE.LAZY);
    paid.width = 100;
    paid.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.Paid ? localize('_Paid_') : localize('_NotPaid_'));
    };
    paid.filterType = GRID_FILTER_TYPE.SINGLE_SELECT;
    const paidStatuses = [{ text: localize('_Paid_'), value: true }, { text: localize('_NotPaid_'), value: false }];
    paid.options = paidStatuses;
    paid.filterArgs = {
      placeholder: localize('_All_'),
      options: paidStatuses.map((status) => ({
        text: status.text,
        value: `Paid eq ${status.value}`
      }))
    };


    const actualPrice = new GridColumn('ActualPrice', localize('_ServiceActualPrice_'), false, true);
    actualPrice.width = 80;

    const relativePrice = new GridColumn('RelativePrice', localize('_ServiceRelativePrice_'), false, true);
    relativePrice.width = 80;

    const mobile = new GridColumn('CustomerMobile', localize('_MobileFirst_'), false, true);
    mobile.isVisible = false;
    mobile.width = 120;

    const identifyNumber = new GridColumn('CustomerIdentifyNumber', localize('_IdentityNumber_'), false, true);
    identifyNumber.isVisible = false;
    identifyNumber.width = 120;

    let roomColumn;
    if (mediator.showRooms) {
      roomColumn = new GridColumn('Room', localize('_Room_'), COLUMN_EDITOR_TYPE.LAZY, true);
      roomColumn.width = 175;
      roomColumn.isVisible = false;
      roomColumn.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
      Repository.Entity('Room').query().get().then(({ value }) => {
        const rooms = value.map((room) => ({
          text: room.Name,
          value: room.RoomId
        }));
        roomColumn.options = rooms;
        roomColumn.filterArgs = {
          placeholder: localize('_Room_'),
          options: rooms.map((room) => ({
            text: room.text,
            value: `RoomId eq ${room.value}`
          }))
        };
      });
    }

    let cost;
    if (mediator.showServiceCost) {
      cost = new GridColumn('Cost', localize('_ServiceCost_'), false, true);
      cost.width = 120;
      cost.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
      Repository.Custom('QueryableTable').groupedServicesCost().get().then((result) => {
        const costs = result.map((costValue) => ({
          text: costValue,
          value: costValue
        }));

        cost.options = [...costs];
        cost.filterArgs = {
          placeholder: localize('_ServiceCost_'),
          options: costs.map((costValue) => ({
            text: costValue.text,
            value: costValue.value % 1 != 0 ? `(Cost gt ${parseInt(costValue.value)} and Cost lt ${parseInt(costValue.value) + 1})` : `(Cost eq ${costValue.value})`
          }))
        };
      });
    }

    const createdOn = new GridColumn('MeetingCreatedOn', localize('_CreatedOn_'), COLUMN_EDITOR_TYPE.LAZY);
    createdOn.width = 150;
    createdOn.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    createdOn.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true }
    createdOn.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', Moment(entity.MeetingCreatedOn, SERVER_DATA_DATE_FORMAT).format(RTL_DATE_TIME_DISPLAY));
    };

    const serviceCategoryColumn = new GridColumn('CategoryName', localize('_Category_'), COLUMN_EDITOR_TYPE.LAZY, true);
    serviceCategoryColumn.width = 175;
    serviceCategoryColumn.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
    Repository.Entity('ServiceCategory').query().get().then(({ value }) => {
      const categories = value.map((category) => ({
        text: category.ServiceCategoryName,
        value: category.ServiceCategoryId
      }));
      serviceCategoryColumn.options = categories;
      serviceCategoryColumn.filterArgs = {
        placeholder: localize('_Category_'),
        options: categories.map((category) => ({
          text: category.text,
          value: `CategoryId eq ${category.value}`
        }))
      };
    });

    const arrivalSource = new GridColumn('ArrivalSource', localize('_ArrivalSource_'), COLUMN_EDITOR_TYPE.LAZY, true);
    arrivalSource.width = 200;
    arrivalSource.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
    // arrivalSource.isVisible = false;

    Repository.Entity("ArrivalSource").query().get().then(({ value }) => {
      const sources = value.map((arrivalSource) => ({
        text: arrivalSource.ArrivalSourceName,
        value: arrivalSource.ArrivalSourceId
      }));

      arrivalSource.options = [...sources];
      arrivalSource.filterArgs = {
        placeholder: localize('_ArrivalSource_'),
        options: sources.map((source) => ({
          text: source.text,
          value: `ArrivalSourceId eq ${source.value}`
        }))
      };
    });

    let priceList;
    if (mediator.showPriceLists) {
      priceList = new GridColumn('PriceList', localize('_PriceLists_'), COLUMN_EDITOR_TYPE.LAZY);
      priceList.width = 200;
      priceList.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
      //todo:show tomer - this hides only the row content not the header
      // priceList.isVisible = false;

      Repository.Entity("PriceList").query().get().then(({ value }) => {
        const priceLists = value.map((pricelist) => ({
          text: pricelist.PriceListName,
          value: pricelist.PriceListId
        }));

        priceList.options = [...priceLists];
        priceList.filterArgs = {
          placeholder: localize('_PriceLists_'),
          options: priceLists.map((pricelist) => ({
            text: pricelist.text,
            value: `PriceListId eq ${pricelist.value}`
          }))
        };
      });
    }

    this.columns = [
      meetingStartTime,
      nameCol,
      meetingEmployeeName,
      meetingServiceName,
      paid,
      originalPrice,
      actualPrice,
      relativePrice,
      serviceCategoryColumn,
      createdOn,
      arrivalSource,
      priceList,
      createdByEmployee,
      cost,
      mobile,
      identifyNumber,
      roomColumn,
      meetingEndTime
    ].filter((x) => !!x)

    this._sortByColumn = new SortByColumn(meetingStartTime, true);

    mediator.exportToExcel = () => {
      this._api.export()
    };

    mediator.print = () => {
      let dictionary = Object.assign({}, ...this.columns.filter((c) => { return c.isVisible }).map((column) => ({
        [column.fieldName]: {
          Label: column.label,
          ToStringFormat: ['MeetingStartTime', 'MeetingCreatedOn'].includes(column.fieldName) ? "dd/MM/yyyy HH:MM" :
            (["RelativePrice", "ActualPrice", "ServiceOriginalPrice"].includes(column.fieldName) ? "G29" : null)
        }
      })));
      this._api.print(dictionary).then(function (response) {
        printerMediator.print(response);
      })
        .catch(function (err) {
        });
    };
  }


  getItemId(item) {
    return item.MeetingId + "_" + (item.MeetingServiceMetadataId || 0);
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  get api() {
    return this._api;
  }

  filterChange(filter) {
    super.filterChange(this.adaptFiltersToQuery(filter));
  }

  adaptFiltersToQuery(filter) {
    let adaptedFiltersToSet = [];

    if (isNonEmptyArray(filter) || isNonEmptyArray(this._constantFilters)) {
      const adaptedFilters = filter.concat(this._constantFilters || []).map((x) => {
        if (x.column.fieldName === 'CustomerName') {
          return `(indexof(CustomerName,'${x.filterValue}') gt -1)`
        } else if (x.column.fieldName === 'MeetingCreatedOn' && x.filterValue.length) {
          return adaptDateRangeQuery(x);
        } else {
          if (isNonEmptyArray(x.filterValue) && x.filterValue.length > 1) {
            return `(${x.filterValue.join(' or ')})`;
          }

          return x.filterValue;
        }
      });

      adaptedFiltersToSet = adaptedFilters;
    }

    return adaptedFiltersToSet;
  }

  loadOnRemote(top, skip, sort, filter) {
    let isFilterChanged = false;
    const newFilter = isNonEmptyObject(filter) ? JSON.stringify(filter) : null
    if (newFilter !== this._stringifiedCurrentFilter) {
      isFilterChanged = true;
      this._stringifiedCurrentFilter = newFilter;
    }
    if (isNonEmptyArray(filter)) {
      let rangeFilter = filter.find((x) => x.column.fieldName === 'MeetingStartTime')
      if (rangeFilter) {
        var range = filter.find((x) => x.column.fieldName === 'MeetingStartTime').filterValue
        this.from = moment(range[0]);
        this.to = range[1] != null ? moment(range[1]) : moment(range[0]).endOf('day');
        filter.remove(rangeFilter)
      }
    }

    this._api.from(this.from.format(ODATA_DATE_TIME_FORMAT));
    this._api.to(this.to.format(ODATA_DATE_TIME_FORMAT));
    this._api.top(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }
    const adaptedFilters = this.adaptFiltersToQuery(filter);
    this._api.filter(adaptedFilters);

    if (isFilterChanged) {
      this._api.getInfo(true).then((data) => {
        this.setFooter(data[0]);
      }).catch((e) => {
        this._toaster.error(extractError(e))
      });
    }

    // // let inlineQuery = Repository.get...
    // let inlineQuery = new Promise((resolve) => resolve());

    // // return Promise.all([this._api.get(), inlineQuery]).then(([]) => {

    // // })

    return this._api.get().then((result) => {
      return new GridApiResult(result.map((item) => {
        return item;
      }),
        top,
        skip,
        null);
    });
  }
}

class DefaultDatePickerLabels extends DatePickerLabels {
  constructor(localize) {
    super();
    this._inputPlaceholder = localize('_ChooseDate_');
    this._selectADate = localize('_ChooseDate_');
    this._pickAStartTime = localize('_StartTime_');
  }

  get inputPlaceholder() {
    return this._inputPlaceholder;
  }

  get selectADate() {
    return this._selectADate;
  }

  get pickAStartTime() {
    return this._pickAStartTime;
  }
}

function adaptDateRangeQuery(dateFilter) {
  const fromDate = moment(dateFilter.filterValue[0]).format(ODATA_DATE_TIME_FORMAT);
  let toDate;
  if (dateFilter.filterValue[1] && dateFilter.filterValue[1].getDate() != dateFilter.filterValue[0].getDate()) {
    toDate = moment(dateFilter.filterValue[1]).format(ODATA_DATE_TIME_FORMAT);
  } else {
    toDate = moment(dateFilter.filterValue[0]).endOf('day').format(ODATA_DATE_TIME_FORMAT);
  };
  return `(${dateFilter.column.fieldName} ge DateTime'${fromDate}' and ${dateFilter.column.fieldName} le DateTime'${toDate}')`;
}


