import './employee.details.less';
import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT, RTL_DATE_TIME_DISPLAY } from "../../constants";

angular.module('easybizy.employees')
  .controller('EmployeeDetailsController',
    function ($scope, $stateParams, Repository,
      $window, $timeout, toolbar,
      localize, $state,
      $modal, printerMediator, $filter, $q) {
      toolbar.set([
        {
          name: 'add',
          action: function (timelineEntityCustomNote) {
            return updateEmployeeNote(timelineEntityCustomNote);
          },
          params: {
            getOwnerId: getEmployeeId,
            reminderType: 'me'
          },
          data: {
            template: require('../../modal-views/entity-custom-note/entity.custom.note.tpl.html'),
            controller: 'EntityCustomNoteController'
          }
        }
      ]);

      $scope.editMode = false;
      $scope.detailsMode = 0;
      $scope.toggleDetailsMode = function (mode) {
        $scope.detailsMode = mode;
      };

      function goToEmployees() {
        $state.go('Employees');
      }

      $scope.isRightToLeft = localize.isRightToLeft();
      $scope.employeeData = [];
      $scope.isLoadingDataWrapper = {};
      $scope.isLoadingCustomerInfo = true;
      $scope.employeeTileWrapper = [];
      $scope.isSaving = false;
      $scope.imageIdFromAppToLink = 0;
      $scope.placeHolderLabel = localize.getLocalizedString("_Notes_");
      $scope.customerFieldsToValidate = {}; // just initialize fields to validate.
      $scope.validateActionWrapper = {}; // this is an object to wrap an action, so the form validator can

      var originalEmployee;
      $scope.edit = function () {
        $scope.editMode = !$scope.editMode;
        if ($scope.editMode && !originalEmployee) {
          originalEmployee = $.extend(true, {}, $scope.employee);
        }
      };


      $scope.imageUpdated = function (entity, image) {
        $scope.imageIdFromAppToLink = image.id;
      };

      $scope.fields = [
        {
          fieldName: 'FirstName',
          icon: "icon icon-clients-ol",
          placeholder: localize.getLocalizedString("_FirstName_"),
          validation: "{'required': true, 'max-length': 20}"
        },
        {
          fieldName: 'LastName',
          icon: "icon icon-client",
          placeholder: localize.getLocalizedString("_LastName_"),
          validation: "{'required': true, 'max-length': 20}"
        },
        {
          fieldName: 'PhoneNumber',
          icon: "icon icon-sms",
          placeholder: localize.getLocalizedString("_Phone_"),
          validation: "{'max-length': 10, 'digits': true}"
        },
        {
          fieldName: 'EmailAddress',
          icon: "icon icon-mail-outline",
          placeholder: localize.getLocalizedString("_Email_"),
          validation: "{'email': true}"
        },
        {
          fieldName: 'DateOfBirth',
          icon: "icon icon-gift",
          placeholder: localize.getLocalizedString("_DateOfBirth_"),
          type: 'date',
          validation: "{'date': true, 'birthday': true}"
        },
        {
          fieldName: 'DefaultAddress',
          icon: "icon icon-address",
          placeholder: localize.getLocalizedString("_Address_"),
          validation: "{'required': false, 'max-length': 120}"
        },
        {
          fieldName: 'Color', icon: "icon icon-color", placeholder: localize.getLocalizedString("_ServiceColor_"),
          validation: "{'color': true }", type: 'color'
        },
        {
          fieldName: 'Gender', icon: "glyphicon glyphicon-glass", placeholder: localize.getLocalizedString("_Gender_"),
          validation: "{'required': true}", type: 'radio',
          values: [
            { name: localize.getLocalizedString("_Female_"), value: 'Female' },
            { name: localize.getLocalizedString("_Male_"), value: 'Male' }
          ]
        }
      ];

      $scope.save = function () {
        var validationFields = $scope.validateActionWrapper.validate();
        if (validationFields.length !== 0) { // is valid form?
          var wrongFieldName = $scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
          var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
          toastr.error(localizedError);
          return;
        }

        $scope.isSaving = true;
        var employeeToSave = $.extend({}, $scope.employee);
        delete employeeToSave.EntityType;
        delete employeeToSave.alwaysExpanded;
        delete employeeToSave.isSelected;
        delete employeeToSave.$$hashKey;
        delete employeeToSave.isMarked;
        delete employeeToSave.DefaultImage;
        delete employeeToSave.DefaultImagePath;

        employeeToSave.DateOfBirth = employeeToSave.DateOfBirth != null ? moment(employeeToSave.DateOfBirth, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT) : null;
        employeeToSave.Gender = angular.isObject(employeeToSave.Gender) ? employeeToSave.Gender.value : employeeToSave.Gender;
        if (employeeToSave.Color === '') { employeeToSave.Color = null };

        if ($scope.imageIdFromAppToLink > 0) {
          employeeToSave.ImageId = $scope.imageIdFromAppToLink;
        }


        Repository.Entity("Employee").update(employeeToSave)
          .post().then(function () {
            loadEmployee();
            $scope.edit();
            $scope.isSaving = false;
            toastr.success(localize.getLocalizedString("_EntitySavedSuccessfully_"));

          })
          .catch(function (e) {
            if (Array.isArray(e) && e[0].MeetingId) {
              let joined = e.map((x) => { return x.StartTime + ' - ' + x.Title }).join('\n')
              toastr.error(localize.getLocalizedString("_ErrorSavingEntity_") + " : \n " + joined);
            } else {
              toastr.error(localize.getLocalizedString("_ErrorSavingEntity_") + " - " + e);
            }
            $scope.isSaving = false;
          });
      };

      $scope.cancel = function () {
        Object.assign($scope.employee, originalEmployee);
        $scope.edit();
      };

      // backFilter
      $scope.leftSideBackFilterModel = {};
      $scope.leftSideBackFilterModel.filters = [
        { type: "backButton" }
      ];

      // filter
      $scope.filtersModel = {};
      $scope.filtersModel.filters = [
        { type: "employeeDetailsFilter", allowMultiple: true }
      ];


      $scope.employeeDataProxy = Repository.Custom("EmployeeDetails").data($stateParams.employeeId);

      $scope.filtersModel.filterStateChanged = function (newFiltersState) {
        $scope.employeeDataProxy.filter(newFiltersState.customerDetailsFilter);
      };

      $scope.actions = {};
      $scope.actions.checkIn = function (employee) {
        var deferred = $q.defer();
        Repository.Custom("EmployeeHours").checkIn(employee.EmployeeId)
          .then(function (data) {
            toastr.success(localize.getLocalizedString("_EmployeeCheckedInSuccessfuly_"));
            deferred.resolve(data);
          })
          .catch(function (errorMessage) {
            toastr.warning(errorMessage);
            deferred.reject(errorMessage);
          });
        return deferred.promise;
      };

      $scope.actions.checkOut = function (employee) {
        const deferred = $q.defer();
        Repository.Custom("EmployeeHours").checkOut(employee.EmployeeId)
          .then(function (data) {
            toastr.success(localize.getLocalizedString("_EmployeeCheckedOutSuccessfuly_"));
            deferred.resolve(data);
          })
          .catch(function (errorMessage) {
            toastr.warning(errorMessage);
            deferred.reject(errorMessage);
          });
        return deferred.promise;
      };

      $scope.actions.manuallySetShift = function (employee) {
        var deferred = $q.defer();
        openManuallyShiftModal(employee, deferred);
        return deferred.promise;
      };

      // notes:
      $scope.updateNotes = function () {
        Repository.Entity("Employee").patch($scope.employee.EmployeeId, { Remarks: $scope.employeeNotes.notes })
          .post()
          .then(function () {
            $scope.employee.Remarks = $scope.employeeNotes.notes;
          })
          .catch(function (e) {
            toastr.error(localize.getLocalizedString("_ErrorUpdatingNotes_"));
          });
      };

      $scope.actions.editShifts = openEmployeeShiftModal;

      $scope.actions.editWorkHours = openEmployeeWorkHoursModal;
      $scope.actions.syncWithGoogle = function () {
      };

      $scope.actions.deleteEntity = function (entity) {
        var deferred = $q.defer();
        Repository.Entity(entity.EntityType).remove(entity)
          .then(function () {
            toastr.success(localize.getLocalizedString("_EntityRemovedSuccessfully_"));
            $timeout(goToEmployees);
            deferred.resolve(entity);
          })
          .catch(function (e) {
            toastr.error(localize.getLocalizedString("_ErrorRemovingEntity_") + " - " + e);
            deferred.reject(data);
          });

        return deferred.promise;
      };

      loadEmployee();
      setLabelsForLocale();

      $scope.customerCustomDataPlaceholder = localize.getLocalizedString("_CustomCustomerData_");

      $scope.memberships = [];
      $scope.printCustomData = function () {
        printerMediator.print($scope.customerCustomData.data.replace(/\n/g, '<br/>'), true);
      };

      var dayFilter = $filter('calendarDayShort');
      $scope.getPunchcardFormattedDateOfUsage = function (punchCardUsageDate) {
        return dayFilter(moment(punchCardUsageDate));
      };

      function openEmployeeShiftModal(employee) {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../modal-views/employee-shifts/employee.shifts.modal.tpl.html'),
          controller: "EmployeeHoursModalController",
          resolve: {
            saveAction: function () {
              return function () {
              };
            },
            parameters: function () {
              return {
                employee: employee
              };
            }
          },
          closeFn: function () {
          }
        };

        $modal.open(opts);
      }

      function openEmployeeWorkHoursModal(employee) {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../modal-views/employee-work-hours/employee.work.hours.modal.tpl.html'),
          controller: "EmployeeWorkHoursModalController",
          resolve: {
            saveAction: function () {
              return function (daysOfWork, cancelFutureInlineChanges, callback) {
                Repository.Custom("EmployeeHours").daysOfWork(employee.EmployeeId, daysOfWork, cancelFutureInlineChanges)
                  .then(function (result) {
                    toastr.success(localize.getLocalizedString("_WorkingHoursUpdatedSuccessfuly_"));
                    employee.DaysOfWork = result;
                    callback.resolve();
                  })
                  .catch(function (error) {
                    try {
                      var errorMessage = localize.getLocalizedString(error?.data?.validationError);
                      if (error?.data?.meetingsInRange && error.data.meetingsInRange?.length > 0) {
                        angular.forEach(error.data.meetingsInRange, function (meeting) {
                          errorMessage += '\n' + moment(meeting.StartTime).format(RTL_DATE_TIME_DISPLAY) + ' ' + meeting.Title;
                        });
                      }
                      toastr.warning(errorMessage);
                    } catch (e) {
                      toastr.warning(error.data);
                    }
                    callback.reject(error);
                  });
              };
            },
            parameters: function () {
              return {
                employee: employee
              };
            }
          },
          closeFn: function () {
          }
        };

        $modal.open(opts);
      }

      function openManuallyShiftModal(employee, deferred) {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../modal-views/manually-shift/manually.shift.modal.tpl.html'),
          controller: "ManuallyShiftModalController",
          resolve: {
            saveAction: function () {
              return function (checkInTime, checkOutTime, callback) {
                Repository.Custom("EmployeeHours").manualyUpdateOrCreateShift(employee.EmployeeId, checkInTime, checkOutTime)
                  .then(function (data) {
                    toastr.success(localize.getLocalizedString("_ShiftUpdatedSuccessfuly_"));
                    deferred.resolve(data.OpenCheckInTime);
                    callback.resolve(data.OpenCheckInTime);
                  })
                  .catch(function (errorMessage) {
                    toastr.warning(errorMessage);
                    deferred.reject(errorMessage);
                    callback.reject(errorMessage);
                  });
              };
            },
            parameters: function () {
              var shiftParmeters = {
                checkedInTime: employee.OpenCheckInTime
              };
              return shiftParmeters;
            }
          },
          closeFn: function () {
          }
        };

        $modal.open(opts);
      }


      function updateEmployeeNote(timelineEntityCustomNoteToSend) {
        var deferred = $q.defer();
        timelineEntityCustomNoteToSend.EntityType = 'Employee';
        Repository.Custom("CustomTimelineItem").createOrUpdate(timelineEntityCustomNoteToSend)
          .then(function (timelineEntityCustomNote) {
            if (timelineEntityCustomNoteToSend.EntityId) {
              toastr.success(localize.getLocalizedString("_CustomItemUpdatedSuccessfully_"));
            } else {
              toastr.success(localize.getLocalizedString("_CustomItemSuccesfulyCreated_"));
            }

            $scope.$applyIfNeeded(function () {
              $scope.employeeData.removeByFunc(timelineEntityCustomNoteToSend,
                function (x) {
                  return x.Id == timelineEntityCustomNoteToSend.EntityId;
                }
                , true /*Allow not exists.*/);
              $scope.employeeData.unshift(timelineEntityCustomNote);
            });

            deferred.resolve(timelineEntityCustomNoteToSend);
          }).catch(function (err) {
            toastr.error(localize.getLocalizedString("_ErrorCreatingCutomItem_"), err);
            deferred.reject(err);
          });

        return deferred.promise;
      }

      function getEmployeeId() {
        return $stateParams.employeeId;
      }

      function loadEmployee() {
        Repository.Entity("Employee").query().id($stateParams.employeeId).expand("Image,DaysOfWork,DaysOfWorkExceptions").get()
          .then(function (data) {
            if (angular.isDefined(data)) {
              querySucceeded(data);
            } else {
              queryFailed();
            }
          })
          .catch(queryFailed);
      }

      function querySucceeded(data) {
        var updateCurrentEmployee = function () {
          $scope.employeeTileWrapper.length = 0;
          $scope.employee = data;
          $scope.createdOn = localize.getLocalizedString('_Add_') + ': ' + moment($scope.employee.CreatedOn).format(DATE_DISPLAY_FORMAT);
          if ($scope.employee.DateOfBirth) {
            $scope.employeeDOB = localize.getLocalizedString('_Age_') + ': ' + moment().diff($scope.employee.DateOfBirth, 'years')
          }
          $scope.employee.alwaysExpanded = true;
          $scope.employeeTileWrapper.push($scope.employee);
          $scope.employee.DateOfBirth = $scope.employee.DateOfBirth ? moment($scope.employee.DateOfBirth).format(DATE_DISPLAY_FORMAT) : null;
          $scope.employeeNotes = {};
          $scope.employeeNotes.notes = data.Remarks;
          $scope.employee.Gender = $scope.employee.Gender === 'Female' ? $scope.fields[$scope.fields.length - 1].values[0] : $scope.fields[$scope.fields.length - 1].values[1];
        };


        $scope.$applyIfNeeded(updateCurrentEmployee);
        loadEmployeeInfo();
      }

      function loadEmployeeInfo() {
        Repository.Custom("EmployeeDetails").info($stateParams.employeeId)
          .then(function (data) {
            // $scope.$applyIfNeeded(function () {
            $scope.employeeInfo = data;
            // });
          })
          .catch(function (err) {
            throw (err);
          });
      }

      function queryFailed(/*error*/) {
        toastr.error(localize.getLocalizedString("_EntityWasNotFound_"));
        // $timeout(goToLeads, 3000);

      }

      function setLabelsForLocale() {
        $scope.BackScrollName = localize.getLocalizedString("_Back_");
        $scope.DetailsLabelName = localize.getLocalizedString("_CustomerDetails_");
        $scope.InsightsLabelName = localize.getLocalizedString("_Insights_");
        $scope.SortByLabelName = localize.getLocalizedString("_SortBy_");
        $scope.TreatmentsLabelName = localize.getLocalizedString("_Visits_");
        $scope.PromotionsLabelName = localize.getLocalizedString("_AverageInvoice_");
        $scope.EventsLabelName = localize.getLocalizedString("_Events_");
      }

    });
