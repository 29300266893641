import {MAIN_MODULE} from "../constants";
import Detector from "../common/platform-detection/detector";
import {getSafely, isBoolean} from "@tomeravni/easybizy-js-common/common";

const BASE_ACTIONS = [
  {
    name: 'notification', action: function () {
      console.log("customers' notification function!");
    }
  },
  {
    name: 'feedback', action: function () {
      console.log("feedback!");
    }
  },
  {
    name: 'tutorials'
  },
  // {
  //   name: 'userSettings'
  // }
];

MAIN_MODULE.service('toolbarConversationModifier', function (toolbar,
                                                             configuration,
                                                             Repository,
                                                             $timeout,
                                                             $rootScope) {

  this.updateConversationState = () => {
    const isAlreadyInstalledWhatsappCampaign = Repository.Custom('PromotionsRepository').getRecurringMeetingReminderCampaign().then((res) => {
      return getSafely(['PromotionContent', 'PromotionSenderType'], res) === 'whatsapp';
    });

    Promise.all([
      isAlreadyInstalledWhatsappCampaign,
      configuration.isValidWhatsappAccount(),
      configuration.get().PromotionsSettings.HideWhatsapp
    ]).then(([isAlreadyInstalledWhatsappCampaignRes, isValidActiveWhatsappAccount, hideGenericWhatsapp]) => {
      const actions = [...BASE_ACTIONS];
      if (isValidActiveWhatsappAccount) {
        actions.splice(2, 0, {name: 'conversations'})
      } else if (!isAlreadyInstalledWhatsappCampaignRes && !hideGenericWhatsapp) {
        actions.push({
          name: 'whatsappOnboarding',
          blinking: true,
        })
      }

      // actions.splice(actions.findIndex(({name}) => name === 'userSettings'), 1)

      toolbar.setConstantActions(actions);
    });
  }

  $rootScope.$on('update-conversation-state', this.updateConversationState);

});


MAIN_MODULE.controller('MainController', function ($scope, toolbar,
                                                   $timeout, internalMessage,
                                                   configuration, localize,
                                                   entityImageResolver,
                                                   Repository,
                                                   onlineStatus, browser, $state, contextManager,
                                                   toolbarConversationModifier,
                                                   $rootScope, $transitions, sockets, webSocketsBus) {

  const detector = new Detector().detect();
  $('body').addClass(browser + '-browser ' + detector.os + '-os');

  sockets.init();
  $rootScope.$on(sockets.defaultEventName, function (event, data) {
    webSocketsBus.messageReceived(data);
  });

  $rootScope.$on(sockets.doubleSessionEvent, function (event, data) {
    $scope.$evalAsync(() => {
      $scope.doubleSessionAlertVisible = true;
    });
  });

  $scope.hideDoubleSession = function () {
    $scope.doubleSessionAlertVisible = false;
  };

  // Draw these only after configurations has been loaded.
  $rootScope.$on('states-loaded', function () {
    var states = $state.get();
    states.sort(function (a, b) {
      return (a.order || 0) - (b.order || 0);
    });

    $scope.routes = [];
    $scope.quickNavigationRoutes = [];
    for (var stateIdx in states) {
      var state = states[stateIdx];
      if (state.isNavigationRoute) {
        var modifiedState = {
          name: localize.getLocalizedString(state.title),
          stateName: state.name,
          icon: state.icon,
          url: state.url,
          directUrl: state.directUrl,
        };

        $scope.routes.push(modifiedState);
        if (state.isQuickNavigationRoute) {
          $scope.quickNavigationRoutes.push(modifiedState);
        }

        state.localizedName = modifiedState.name;
      }
    }
  });

  Object.defineProperty($scope, 'currentState', {
    get: function () {
      return $state.current;
    }
  });

  var helpVideosItem = {
    name: 'helpVideos', action: function () {
      console.log("feedback!");
    }, videos: []
  };


  /// TODO: is using conversations?

  // Setting toolbar constant actions!
  toolbar.setConstantActions([...BASE_ACTIONS]);

  /* LOADING VIDEOS IF EXISTS */
  $timeout(function () {
    configuration.getPersonalAsync().then(function (result) {
      if (result) {
        helpVideosItem.videos.push.apply(helpVideosItem.videos, result.videos);
      }
    });
  });

  $scope.localize = localize;
  $transitions.onSuccess({}, function () {
    toggleWhatsappOnboarding(false);
    $('body > ._720kb-tooltip').remove();
  });

  const configurations = configuration.get() || {};

  $scope.businessName = (configurations.BusinessDetails && configurations.BusinessDetails.BusinessName.Value) || 'ERROR';
  $scope.configurationName = localize.getLocalizedString("_Configurations_");


  let whatsappTimer;

  const toggleWhatsappOnboarding = (forceValue) => {
    if (whatsappTimer) {
      $timeout.cancel(whatsappTimer);
    }

    const targetValue = isBoolean(forceValue) ? forceValue : !$scope.showWhatsappOnboarding;

    if (targetValue) {
      $scope.showWhatsappOnboarding = true;
      whatsappTimer = $timeout(() => {
        $scope.didShowWhatsappOnboarding = true;
        whatsappTimer = null
      })
    } else {
      $scope.didShowWhatsappOnboarding = false;
      whatsappTimer = $timeout(() => {
        $scope.showWhatsappOnboarding = false;
        whatsappTimer = null
      }, 500);
    }
  }

  $rootScope.$on('toggle-whatsapp-onboarding', toggleWhatsappOnboarding)

  var currentCustomer = null;
  contextManager.watch('customer', function (customer) {
    currentCustomer = customer;
    if (customer) {
      $scope.customerInContext = customer;
      $scope.customerName = customer.FirstName + ' ' + customer.LastName;
      $scope.customerShorthand = customer.FirstName.charAt(0);
      $scope.customerImage = entityImageResolver.resolve(customer);
    } else {
      clearCustomerData();
    }
  });

  contextManager.get('customer'); // since we already have watch, no need to handle the get call.

  $scope.clearCustomer = function () {
    contextManager.clear(['customer', 'meeting', 'priceQuote']);
    clearCustomerData();
  };

  function clearCustomerData() {
    $scope.customerInContext = null;
    $scope.customerName = null;
    $scope.customerShorthand = null;
    $scope.customerImage = null;
  }

  $scope.actions = [
    {
      icon: 'icon icon-clients-ol', action: function () {
        $state.go('CustomerDetails', {customerId: currentCustomer.CustomerId});
      }
    },
    {
      icon: 'icon icon-cashregister', action: function () {
        $state.go('CashRegister');
      }
    },
    {icon: 'icon icon-flat-close', action: $scope.clearCustomer}];

  $scope.supportConvesations = true;

  toolbarConversationModifier.updateConversationState();

  configuration.loggedInUserAsPromise.then((user) => {
    $scope.loggedInUser = user;
  })

});
