import './graph.viewer.directive.less';

angular.module('easybizy.common.graph.viewer').directive('graphView', [
  '$timeout', function ($timeout) {
    return {
      restrict: 'E',
      scope: {
        value: "=",
        colorsPallete: "=",
        sizeAware: "=",
        isLoadingWrapper: "=",
        showNoVisibleDataMessage: "@"
      },
      replace: true,
      link: function (scope, element, attrs) {
        scope.noVisibleData = false;
        scope.chartWrapper = {};


        var parent = function () {
          return element.parent();
        };


        if (attrs.sizeAware) {
          element.on('resize', function (e) {
            changeSize();
          });
        }

        var externalTimeout = null;

        function changeSize() {
          if (externalTimeout != null) {
            clearTimeout(externalTimeout);
          }

          externalTimeout = setTimeout(function () {
            var lastHeight = parent().height();
            var lastWidth = parent().width();

            try {
              scope.chartWrapper.chart.setSize(lastWidth - 4, lastHeight);
            } catch (e) {
              console.log('chart was destruct');
            }

          }, 300);
        }

        scope.$watch('value', function (newValue) {
          scope.chartSeries.length = 0;
          var categories = [];
          scope.noVisibleData = false;

          if (!newValue || !newValue.values || newValue.values.length == 0) {
            scope.noVisibleData = true;
            return;
          }

          scope.chartConfig.options.chart.type = newValue.type === "stackedColumn" ? "column" : newValue.type;
          scope.chartConfig.options.plotOptions.column.stacking = newValue.type === "stackedColumn" ? "normal" : "";

          var dataSeries = [];
          if (!newValue.isArranged) {
            angular.forEach(newValue.config, function (axisValue) {
              var valuesForAxis = [];
              angular.forEach(newValue.values, function (dataValue) {
                valuesForAxis.push([dataValue[axisValue.xValue], dataValue[axisValue.yValue]]);
                categories.push(dataValue[axisValue.xValue]);
              });

              dataSeries.push(valuesForAxis);
            }); // each series has its own axisMetadata.

            for (var i = 0; i < dataSeries.length; i++) {
              scope.chartSeries.push({
                "name": newValue.config[i].label,
                "data": dataSeries[i]
                //color: scope.colorsPallete ? scope.colorsPallete[i] : "#a186be"
              });
            }

            scope.chartConfig.options.xAxis.categories = categories;
          } else {
            scope.chartSeries.length = 0;
            angular.forEach(newValue.values, function (val) {
              scope.chartSeries.push(val);

              //todo: find out how to show extra data in the graph mouse hover.
              // if(val.name2 && val.data && val.data.length > 0 && val.data[0].length > 1){
              //   let moreValues = {name: val.name2, data: val.data.map(function (item) { return [item[0], item[2]]})};
              //   scope.chartSeries.push(moreValues);
              // }
            });

            scope.chartConfig.options.xAxis.categories = newValue.config;

          }

        }, true);

        scope.chartSeries = [];


        scope.chartConfig = {
          options: {
            chart: {
              type: 'column'
            },
            plotOptions: {
              line: {
                dataLabels: {
                  enabled: true
                }
                //enableMouseTracking: false
              },
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  useHTML: true,
                  enabled: true,
                  color: '#000000',
                  connectorColor: '#000000',
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              },
              column: {
                //stacking: 'normal',
                dataLabels: {
                  enabled: true,
                  color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white',
                  style: {
                    textShadow: '0 0 3px black, 0 0 3px black'
                  }
                }
              }
            },
            exporting: {
              enabled: false
            },
            legend: {
              enabled: true,
              rtl: true
            },
            tooltip: {
              useHTML: true
            },
            xAxis: {
              //reversed: true,
              rtl: true

//                        gridLineWidth: 0,
//                        minorGridLineWidth: 0,
              //labels: {
              //    enabled: false
              //},
//                        categories: xAxis
            },
            yAxis: {
              text: null,
              title: null,
              //opposite: true,
              rtl: true,
              gridLineColor: "#e1e1e1",
              gridLineWidth: 1,
//                        minorGridLineWidth: 0,
              labels: {
                enabled: true,
                rtl: true
              }
            },
            reflow: false
          },
          series: scope.chartSeries,
          title: {
            text: null
          },
//                legend: {
//                    enabled: false
//                },
          credits: {
            enabled: false
          },
          loading: false,
          useHighStocks: false,
          labels: {
            useHTML: Highcharts.hasBidiBug
          }
        };

        scope.$on('$destroy', function () {
          parent().off('resize');
        });
      },
      template: require('./graph.viewer.directive.tpl.html')
    };
  }]);
