(function () {
  // loadCssIfNotLoaded("components/jquery-ui/ui-lightness/jquery-ui-1.10.3.custom.css");


  angular.module('easybizy.common.common-controls').directive('quillEditor', [
    'localize', '$parse', function (localize, $timeout) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          ngModel: '=',
          addImageWrapper: '=',
          text: '=',
          actionsDelegate: '='
        },
        require: '^ngModel',
        link: function (scope, element, attrs, ngModelCtrl) {


          var quill = new Quill(element.find('.quill-editor')[0], {
            modules: {
              toolbar: element.find('.quill-toolbar')[0],
              imageResize: {
                // See optional "config" below
                displaySize: true
              },
              history: {
                delay: 2000,
                maxStack: 500,
                userOnly: true
              }
            },
            placeholder: 'Compose an epic...',
            theme: 'snow'  // or 'bubble'
          });

          scope.$watchOnce('addImageWrapper', function (newVal) {
            newVal.addImage = function (base64Image) {
              quill.focus();
              var positionToInsert = quill.getSelection();
              positionToInsert = positionToInsert ? positionToInsert.index : 1;
              quill.insertEmbed(positionToInsert, 'image', base64Image)
            }
          });


          quill.pasteHTML(scope.ngModel || "<span></span>");
          if (localize.isRightToLeft()) {
            quill.format('align', 'right');
            quill.format('direction', 'rtl');
          }

          if (scope.actionsDelegate) {
            scope.actionsDelegate.paste = function (htmlContent) {
              quill.pasteHTML(htmlContent);
            }
          }

          quill.on('text-change', function (delta, oldDelta, source) {
            var html = quill.container.firstChild.innerHTML;
            if (html === '<p><br></p>') {
              html = null
            }

            scope.$applyAsync(function () {
              ngModelCtrl.$setViewValue(html);
              scope.text = quill.getText().trim();
            }.bind(this));
          }.bind(this));

          var toolbar = quill.getModule('toolbar');
          // toolbar.addHandler('image', function() {
          //   console.log(quill);
          // });

          var Parchment = Quill.import('parchment');
          var ImageBlot = Quill.import('formats/image');

          var lastSelected = null;
          quill.root.addEventListener('click', function (ev) {
            var image = Parchment.find(ev.target);

            if (lastSelected) {
              lastSelected.domNode.classList.remove('selected');
              if (lastSelected == image) {
                return lastSelected = null;
              }

            }

            if (image instanceof ImageBlot) {
              quill.setSelection(image.offset(quill.scroll), 1, 'user');
              image.domNode.classList.add('selected');
              lastSelected = image;
            }
          });


          // END;;
        },
        template: require('./quill.editor.tpl.html')
      }
    }]);

}());


(function () {
  'use strict';

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) {
          descriptor.writable = true;
        }
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) {
        defineProperties(Constructor.prototype, protoProps);
      }
      if (staticProps) {
        defineProperties(Constructor, staticProps);
      }
      return Constructor;
    };
  }();

  var _get = function get(object, property, receiver) {
    if (object === null) {
      object = Function.prototype;
    }
    var desc = Object.getOwnPropertyDescriptor(object, property);
    if (desc === undefined) {
      var parent = Object.getPrototypeOf(object);
      if (parent === null) {
        return undefined;
      } else {
        return get(parent, property, receiver);
      }
    } else if ("value" in desc) {
      return desc.value;
    } else {
      var getter = desc.get;
      if (getter === undefined) {
        return undefined;
      }
      return getter.call(receiver);
    }
  };

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }
    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }
    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) {
      Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }
  }


  angular.module('easybizy.common.common-controls').run(function () {


    var BlockEmbed = Quill.import('blots/block/embed');

    var ImageBlot = function (_BlockEmbed) {
      _inherits(ImageBlot, _BlockEmbed);

      function ImageBlot() {
        _classCallCheck(this, ImageBlot);

        return _possibleConstructorReturn(this, (ImageBlot.__proto__ || Object.getPrototypeOf(ImageBlot)).apply(this, arguments));
      }

      _createClass(ImageBlot, null, [
        {
          key: 'create',
          value: function create(value) {
            var node = _get(ImageBlot.__proto__ || Object.getPrototypeOf(ImageBlot), 'create', this).call(this);
            node.style.backgroundImage = angular.isObject(value) ? value.backgroundImage : 'url(' + value + ')';
            node.style.height = angular.isObject(value) ? value.height : '180px';
            node.style.width = angular.isObject(value) ? value.width : '320px';
            node.style.backgroundSize = angular.isObject(value) ? value.backgroundSize : 'cover';
            // node.classList.add('resizable-image');

            // $(node).resizable({
            //   handles: 'w, sw, s',
            //   minHeight: 45,
            //   maxHeight: 480,
            //   minWidth: 80,
            //   maxWidth: 640,
            //   resize: function () {
            //     this.style.left = 0;
            //   }
            // });
            return node;
          }
        }, {
          key: 'value',
          value: function value(node) {
            return {
              backgroundImage: node.style.backgroundImage,
              height: node.style.height,
              width: node.style.width,
              backgroundSize: node.style.backgroundSize
            };
          }
        },
        // {
        //   key: 'formats',
        //   value: function formats(node) {
        //     // We still need to report unregistered embed formats
        //     var format = {};
        //     if (node.hasAttribute('height')) {
        //       format.height = node.getAttribute('height');
        //     }
        //     if (node.hasAttribute('width')) {
        //       format.width = node.getAttribute('width');
        //     }
        //     return format;
        //   }
        // }
      ]);

      return ImageBlot;
    }(BlockEmbed);

    ImageBlot.blotName = 'image';
    ImageBlot.tagName = 'div';
    ImageBlot.className = 'resizable-image';
    // Quill.register(ImageBlot);

  });
}());

