import { DATE_DISPLAY_FORMAT, RTL_DATE_TIME_DISPLAY } from "../../constants";

angular.module('easybizy.reports').factory('reportsDecorator', [
  '$rootScope', 'Repository', 'localize', 'configuration', function ($rootScope, Repository, localize, configuration) {
    var decorators = {
      SalesByProduct: decorateReport,
      SalesByService: decorateReport,
      TotalSales: decorateReport,
      SalesByClerk: decorateReport,
      ServicesByClerk: decorateReport,
      PriceVsCost: decorateReport,
      EmployeeHours: decorateReport,
      ProductInventory: decorateReport,
      ProductsByClerk: decorateReport,
      Customers: decorateReport,
      Subscriptions: decorateReport,
      GiftCards: decorateReport,
      SubscriptionUsages: decorateReport,
      Refunds: decorateReport,
      MeetingCancellations: decorateReport,
      MeetingsScheduling: decorateReport,
      Meetings: decorateReport,
      OnlineBookings: decorateReport,
      MeetingsByEmployees: decorateReport,
      Feedbacks: decorateReport,
      Leads: decorateReport,
      ExternalVouchers: decorateReport,
      Payments: decorateReport,
      Debts: decorateReport,
    };

    return {
      decorate: function (type) {
        return decorators[type];
      }
    };

    function decorateReport(scope) {

      // is loading wrapper is wrapping the boolean of isLoading. The reason it is needed is because the boolean
      // fields are not well observed. (so, isLoadingWrapper has field => isLoadingWrapper.isLoading = (true/false).)
      scope.isLoadingWrapper = {};
      // the graph title.
      scope.title = localize.getLocalizedString("_" + scope.type + "_");

      setFilterInitialValue();
      scope.selectedGraphWrapper = {};
      /* Graph type selection (area or pie). we should also generate a column in case it is needed. */
      setGraphTypeByReport(scope.type);
      scope.$watch('selectedGraphWrapper.selected', function () {
        scope.generateGraph();
      });
      /* ENDOF Graph type selection (area or pie). we should also generate a column in case it is needed. */

      scope.graphData = {};
      scope.isDirty = false;
      scope.areaData = null;
      scope.pieData = null;
      scope.tableData = [];
      scope.selectedIds = "";

      scope.showTotal = false;
      scope.groupedByCategory = false;


      scope.generateGraph = function () {
        generateSpecificGraph(scope.selectedGraphWrapper.selected.value);
      };

      scope.resolveTableData = function () {
        if (!scope.dataTableApi) {
          scope.dataTableApi = Repository.Custom("ReportsRepository")
            .generateGraphData(function () {
              return scope.getCurrentDate();
            }, null, true, scope.type);
        }
      };

      /* Defined the fields the data table has.*/
      scope.fields = scope.productTableFields = getTableDataByReportType(scope.type);


      scope.trackByWidget = getTrackByFunc(scope.type);

      ///* ENDOF Defining the radio value switcher */
      var decorationRepository = null;

      function generateSpecificGraph(graphType) {
        scope.isLoadingWrapper.isLoading = true;
        if (scope[graphType + "Data"] && !scope.isDirty) {
          toGraph(scope[graphType + "Data"]);
        } else {
          if (!decorationRepository) {
            decorationRepository = Repository.Custom("ReportsRepository").generateGraphData(
              //get current dates selection
              function () {
                return scope.getCurrentDate();
              },
              //get current graph type selection
              function () {
                return scope.selectedGraphWrapper.selected.value;
              }, false, scope.type);
          }


          decorationRepository.get().then(function (data) {
            scope[graphType + "Data"] = data;
            scope.noData = data.total == null;
            scope.isDirty = false;
            // scope.$apply(function () {
            toGraph(data);
            // });
          });
        }

        function toGraph(data) {
          scope.graphData.type = scope.selectedGraphWrapper.selected.value; // area / pie (maybe column?).
          scope.graphData.config = data.metadata; // the labels.. (metadata from server).
          scope.graphData.values = localizeValueNames(data.values, data.valueNames);
          scope.groupedByCategory = data.byCategory;
          scope.totalValue = data.total != null ? getTotalValueByReport(data.total) : 0;
          scope.showTotal = isToShowTotal();
          scope.graphData.isArranged = true; // is true for all this parts.
          scope.isLoadingWrapper.isLoading = false;
        };

        function localizeValueNames(values, otherValueNames) {
          angular.forEach(values, function (value) {
            if (value.name) {
              value.name = getLocalizedName(value.name);
            }
            if(value.data && value.data.length > 0 && value.data[0].length > 2 && otherValueNames && otherValueNames.Value2) {
              value.name2 = getLocalizedName(otherValueNames.Value2);
            }
            if(value.data && value.data.length > 0 && value.data[0].length > 3 && otherValueNames && otherValueNames.Value3) {
              value.name3 = getLocalizedName(otherValueNames.Value3);
            }
          });

          return values;
        }

        function getLocalizedName(name) {
          try {
            var localized = localize.getLocalizedString("_" + name + "_");
          } catch (e) {
            localized = name;
          }
          return localized.replace(/_/g, "");
        }

        function isToShowTotal() {
          return scope.type === "TotalSales"
            || scope.type === "SalesByProduct"
            || scope.type === "SalesByService"
            || scope.type === "EmployeeHours"
            || scope.type === "SalesByClerk"
            || scope.type === "Subscriptions"
            || scope.type === "GiftCards"
            || scope.type === "Refunds"
            || scope.type === "MeetingCancellations"
            || scope.type === "MeetingsScheduling"
            || scope.type === "Meetings"
            || scope.type === "OnlineBookings"
            || scope.type === "MeetingsByEmployees"
            || scope.type === "Feedbacks"
            || scope.type === "Leads"
            || scope.type === "ExternalVouchers"
            || scope.type === "Payments"
            || scope.type === "Debts";
        }

        function getTotalValueByReport(total) {
          switch (scope.type) {
            case "TotalSales":
            case "SalesByClerk":
            case "SalesByProduct":
            case "SalesByService":
            case "Refunds":
              return addCurrencyValue(total);
            case "Subscriptions":
            case "GiftCards":
              return total + " " + localize.getLocalizedString("_UnitsSold_");
            case "EmployeeHours":
              return total ? total.toFixed(1) : 0 + " " + localize.getLocalizedString("_TotalHours_");
          }
          return total;
        }
      }

      function getTrackByFunc(type) {
        switch (type) {
          //case "TotalSales":
          //    return function (item, index) {
          //        console.log(item);
          //        return index + item.InvoiceId;
          //    };
          //case "SalesByClerk":
          //case "SalesByProduct":
          //case "SalesByService":
          //    return function (item, index) {
          //        //console.log(item);
          //        return index + item.EmployeeId;
          //    };
          default:
            return defaultTrackBy;
        }
      }

      function defaultTrackBy(item, index) {
        return (index + 1) * Math.random();
      }


      function setGraphTypeByReport() {
        switch (scope.type) {
          case "TotalSales":
            scope.graphTypes = [
              { name: 'Area', value: 'area' },
              { name: 'Column', value: 'column' }
            ];
            break;

          case "EmployeeHours":
            scope.graphTypes = [
              { name: 'Pie', value: 'pie' },
              { name: 'Area', value: 'area' }
            ];
            break;

          case "PriceVsCost":
            scope.graphTypes = [
              { name: 'Column', value: 'column' },
              { name: 'Stacked Column', value: 'stackedColumn' },
              { name: 'Pie', value: 'pie' }
            ];
            break;

          case "ProductInventory":
            scope.graphTypes = [
              { name: 'Column', value: 'column' },
              { name: 'Area', value: 'area' }
            ];
            break;

          case "ServicesByClerk":
            scope.graphTypes = [
              { name: 'Pie', value: 'pie' }
            ];
            break;

          case "ProductsByClerk":
            scope.graphTypes = [
              { name: 'Pie', value: 'pie' }
            ];
            break;

          case "Customers":
            scope.graphTypes = [
              { name: 'Area', value: 'area' },
              { name: 'Column', value: 'column' },
              { name: 'Pie', value: 'pie' }
            ];
            break;

          case "Subscriptions":
            scope.graphTypes = [
              { name: 'Column', value: 'column' },
              { name: 'Area', value: 'area' },
              { name: 'Pie', value: 'pie' }
            ];
            break;

          case "SubscriptionUsages":
            scope.graphTypes = [
              { name: 'Column', value: 'column' },
              { name: 'Area', value: 'area' }
            ];
            break;

          case "GiftCards":
            scope.graphTypes = [
              { name: 'Column', value: 'column' },
              { name: 'Area', value: 'area' }
            ];
            break;

          case "Refunds":
            scope.graphTypes = [
              { name: 'Column', value: 'column' }
            ];
            break;

          case "MeetingCancellations":
            scope.graphTypes = [
              { name: 'Column', value: 'column' },
              { name: 'Pie', value: 'pie' }
            ];
            break;

          case "MeetingsScheduling":
            scope.graphTypes = [
              { name: 'Column', value: 'column' },
              { name: 'Area', value: 'area' },
              { name: 'Pie', value: 'pie' }
            ];
            break;

          case "Meetings":
            scope.graphTypes = [
              { name: 'Column', value: 'column' },
              { name: 'Area', value: 'area' }
            ];
            break;

          case "OnlineBookings":
            scope.graphTypes = [
              { name: 'Column', value: 'column' },
              { name: 'Area', value: 'area' }
            ];
            break;

          case "MeetingsByEmployees":
            scope.graphTypes = [
              { name: 'Pie', value: 'pie' }
            ];
            break;

          case "Feedbacks":
            scope.graphTypes = [
              { name: 'Column', value: 'column' }
            ];
            break;

          case "Leads":
            scope.graphTypes = [
              { name: 'Area', value: 'area' },
              { name: 'Stacked Column', value: 'stackedColumn' },
              { name: 'Column', value: 'column' },
              { name: 'Pie', value: 'pie' }
            ];
            break;

          case "ExternalVouchers":
            scope.graphTypes = [
              { name: 'Pie', value: 'pie' },
              { name: 'Column', value: 'column' }
            ];
            break;

          case "Payments":
            scope.graphTypes = [
              { name: 'Pie', value: 'pie' }
            ];
            break;

          case "Debts":
            scope.graphTypes = [
              { name: 'Column', value: 'column' }
            ];
            break;

          default:
            scope.graphTypes = [
              { name: 'Pie', value: 'pie' },
              { name: 'Area', value: 'area' }
            ];
            break;

        }

        //scope.selectedGraphType = scope.graphTypes[0];
        scope.selectedGraphWrapper.selected = scope.graphTypes[0];


      }

      function setFilterInitialValue() {
        /* the first filter (in this case the products filter).*/
        switch (scope.type) {

          case "SalesByProduct":
          case "PriceVsCost":
            setProductFilter(scope);
            break;
          case "SalesByService":
            setServiceFilter(scope);
            break;

          default:
            scope.firstFilterValues = [];
        }


        scope.$watch('firstFilterSelectedValue', function () {
          scope.isDirty = true;
        });

        //scope.$watch('firstFilterMetadata.multiSelected.length', function (newVal) {
        //    console.log(newVal);
        //});

        /* ENDOF the first filter (in this case the products filter).*/
      }

      function setProductFilter() {
        scope.firstFilterValues = [
          { name: localize.getLocalizedString("_AllProducts_"), value: -1, defaultValue: true }
        ];

        scope.firstFilterSelectedValue = scope.firstFilterValues[0];
        scope.firstFilterApi = Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory(true).entity("product");
        scope.firstFilterMetadata = {};
        scope.firstFilterMetadata.multiSelect = true;
        scope.firstFilterMetadata.multiSelected = [];
        // When the products filter is changed, this changes.
        var lastFilteredData = [];
        scope.firstFilterMetadata.valueSelected = function (newSelectionOfCategoriesAndItems) {
          if (allArraysAlike([lastFilteredData, newSelectionOfCategoriesAndItems])) {
            return;
          } else {
            lastFilteredData = newSelectionOfCategoriesAndItems;
          }

          scope.isDirty = true;
          var resultedQuery = {};
          resultedQuery.ProductCategories = [];
          resultedQuery.ProductMetadatas = [];

          angular.forEach(newSelectionOfCategoriesAndItems, function (param) {
            if (param.id > 0) {
              if (param.isCategory) {
                resultedQuery.ProductCategories.push(param.id);
              } else {
                resultedQuery.ProductMetadatas.push(param.id);
              }
            }
          });
          decorationRepository.filter(resultedQuery); // put here the parsed filter.
          scope.generateGraph();
        };
      }


      function setServiceFilter() {
        scope.firstFilterValues = [
          { name: localize.getLocalizedString('_AllServices_'), value: -1, defaultValue: true }
        ];

        scope.firstFilterSelectedValue = scope.firstFilterValues[0];
        scope.firstFilterApi = Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory(true).entity("service");
        scope.firstFilterMetadata = {};
        scope.firstFilterMetadata.multiSelect = true;
        scope.firstFilterMetadata.multiSelected = [];
        // When the products filter is changed, this changes.
        var lastFilteredData = [];
        scope.firstFilterMetadata.valueSelected = function (newSelectionOfCategoriesAndItems) {
          if (allArraysAlike([lastFilteredData, newSelectionOfCategoriesAndItems])) {
            return;
          } else {
            lastFilteredData = newSelectionOfCategoriesAndItems;
          }

          scope.isDirty = true;
          var resultedQuery = {};
          if (Array.isArray(newSelectionOfCategoriesAndItems)) {
            resultedQuery.ServiceCategories = [];
            resultedQuery.ServiceMetadatas = [];

            angular.forEach(newSelectionOfCategoriesAndItems, function (param) {
              if (param.isCategory) {
                resultedQuery.ServiceCategories.push(param.id);
              } else {
                resultedQuery.ServiceMetadatas.push(param.id);
              }
            });
          }

          decorationRepository.filter(resultedQuery); // put here the parsed filter.
          scope.generateGraph();
        };
      }

      function allArraysAlike(arrays) {
        // return _.all(arrays, function (array) {
        //   return array.length == arrays[0].length && _.difference(array, arrays[0]).length == 0;
        // });
        return arrays.reduce((acc, curr) => acc.concat(curr));
      }

    }

    function getDuration(hours) {
      //var ms = moment(end, "DD/MM/YYYY HH:mm:ss").diff(moment(start, "DD/MM/YYYY HH:mm:ss"));
      var duration = moment.duration(hours * 60, "minutes");
      return Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(":mm");
    }

    function addCurrencyValue(value) {
      return value ? value.toMoney() : value;
    }

    function getTableDataByReportType(type) {
      var genderTypes = [localize.getLocalizedString("_Male_"), localize.getLocalizedString("_Female_")];
      switch (type) {
        case "SalesByProduct":
          return [
            {
              name: localize.getLocalizedString("_Name_"),
              width: "28%",
              type: 'ImageWithValue',
              sortable: true,
              command: {
                get: function (entity) {
                  return {
                    text: entity.Name,
                    image: baseUrl + entity.Image
                  };
                },
                sort: function () {
                  return "Name";
                }

              }
            },
            {
              name: localize.getLocalizedString("_Manufacturer_"), width: "12%", sortable: true, orderedBy: true, command: {
                get: function (entity) {
                  return entity.ManufacturerName;
                },
                sort: function () {
                  return "ManufacturerName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Category_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CategoryName;
                },
                sort: function () {
                  return "CategoryName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Sold_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Sold;
                },
                sort: function () {
                  return "Sold";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Cost_"), width: "10%", sortable: true, command: {
                get: function (entity) {
                  return addCurrencyValue(entity.BuyingPrice);
                },
                sort: function () {
                  return "BuyingPrice";
                }
              }
            },
            {
              name: localize.getLocalizedString("_RetailPrice_"), width: "10%", sortable: true, command: {
                get: function (entity) {
                  return addCurrencyValue(entity.RetailPrice);
                },
                sort: function () {
                  return "RetailPrice";
                }
              }
            },
            {
              name: localize.getLocalizedString("_AverageSelling_"), width: "10%", sortable: true, command: {
                get: function (entity) {
                  return addCurrencyValue(Math.round(entity.AverageUnitSoldPrice));
                },
                sort: function () {
                  return "AverageUnitSoldPrice";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Total_"), width: "10%", sortable: true, command: {
                get: function (entity) {
                  return addCurrencyValue(Math.round(entity.TotalSales));
                },
                sort: function () {
                  return "TotalSales";
                }
              }
            }

          ];


        // -------------------------  SalesByService ------------------------//

        case "SalesByService":
          return [
            {
              name: localize.getLocalizedString("_Name_"),
              width: "28%",
              type: 'ImageWithValue',
              sortable: true,
              command: {
                get: function (entity) {
                  return {
                    text: entity.Name,
                    image: baseUrl + entity.Image
                  };
                },
                sort: function () {
                  return "Name";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Category_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CategoryName;
                },
                sort: function () {
                  return "CategoryName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Date_"),
              width: "16%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return moment(entity.Date).format(DATE_DISPLAY_FORMAT);
                },
                sort: function () {
                  return "Date";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "16%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.EmployeeName;
                },
                sort: function () {
                  return "EmployeeName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Sold_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Sold;
                },
                sort: function () {
                  return "Sold";
                }
              }
            },
            {
              name: localize.getLocalizedString("_RetailPrice_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return addCurrencyValue(entity.RetailPrice);
                },
                sort: function () {
                  return "RetailPrice";
                }
              }
            },
            {
              name: localize.getLocalizedString("_TotalAfterDiscounts_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.RelativeSum;
                },
                sort: function () {
                  return "RelativeSum";
                }
              }
            }
          ];

        // -------------------------  TotalSales ------------------------//

        case "TotalSales":

          return [
            {
              name: localize.getLocalizedString("_InvoiceIndex_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.InvoiceIndex;
                },
                sort: function () {
                  return "InvoiceIndex";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Date_"),
              width: "12%",
              sortable: true,
              orderedBy: true,
              isDecending: true,
              command: {
                get: function (entity) {
                  return moment(entity.Date).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "Date";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Amount_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return addCurrencyValue(entity.TotalAmount);
                },
                sort: function () {
                  return "TotalAmount";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.EmployeeName;
                },
                sort: function () {
                  return "EmployeeName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Customer_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CustomerName;
                },
                sort: function () {
                  return "CustomerName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_ProductsAndServices_"),
              width: "20%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.ProductsAndServices;
                },
                sort: function () {
                  return "ProductsAndServices";
                }
              }
            },
            {
              name: localize.getLocalizedString("_PaymentType_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.PaymentType;
                },
                sort: function () {
                  return "PaymentType";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Debt_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Debt;
                },
                sort: function () {
                  return "Debt";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Remarks_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Remarks;
                },
                sort: function () {
                  return "Remarks";
                }
              }
            }
          ];

        // -------------------------  SalesByClerk ------------------------//


        case "SalesByClerk":
          const configurations = configuration.get();
          const showExternalVouchersOnEmployeeSales = configurations.EmployeesSettings.ShowExternalVouchersOnEmployeeSales;

          let resultedTableData = [
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "20%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.EmployeeName;
                },
                sort: function () {
                  return "EmployeeName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_TotalSales_"),
              width: "12%",
              orderedBy: true,
              isDecending: true,
              sortable: true,
              command: {
                get: function (entity) {
                  return addCurrencyValue(entity.TotalAmount);
                },
                sort: function () {
                  return "TotalAmount";
                }
              }
            },
            {
              name: localize.getLocalizedString("_SumOfServices_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return addCurrencyValue(entity.SumOfServices);
                },
                sort: function () {
                  return "SumOfServices";
                }
              }
            },
            {
              name: localize.getLocalizedString("_SumOfProducts_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return addCurrencyValue(entity.SumOfProducts);
                },
                sort: function () {
                  return "SumOfProducts";
                }
              }
            },
            {
              name: localize.getLocalizedString("_SumOfCustomItems_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return addCurrencyValue(entity.SumOfCustomItems);
                },
                sort: function () {
                  return "SumOfCustomItems";
                }
              }
            },
            {
              name: localize.getLocalizedString("_SumOfSubscriptions_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return addCurrencyValue(entity.SumOfSubscriptions);
                },
                sort: function () {
                  return "SumOfSubscriptions";
                }
              }
            }
          ];

          if (showExternalVouchersOnEmployeeSales) {
            resultedTableData.push({
              name: localize.getLocalizedString("_ExternalVouchers_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return addCurrencyValue(entity.SumOfExternalVouchers);
                },
                sort: function () {
                  return "SumOfExternalVouchers";
                }
              }
            });
          }

          return resultedTableData;

        // -------------------------  ServicesByClerk ------------------------//

        case "ServicesByClerk":

          return [
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "20%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.EmployeeName;
                },
                sort: function () {
                  return "EmployeeName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Category_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CategoryName;
                },
                sort: function () {
                  return "CategoryName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_ServiceName_"),
              width: "30%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.ServiceName;
                },
                sort: function () {
                  return "ServiceName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Quantity_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Quantity;
                },
                sort: function () {
                  return "Quantity";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Total_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.TotalSum;
                },
                sort: function () {
                  return "TotalSum";
                }
              }
            },
            {
              name: localize.getLocalizedString("_TotalAfterDiscounts_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.RelativeSum;
                },
                sort: function () {
                  return "RelativeSum";
                }
              }
            },
            {
              name: localize.getLocalizedString("_ServiceCost _"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Cost;
                },
                sort: function () {
                  return "Cost";
                }
              }
            }
          ];


        // -------------------------  ProductsByClerk ------------------------//

        case "ProductsByClerk":

          return [
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "30%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.EmployeeName;
                },
                sort: function () {
                  return "EmployeeName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Category_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CategoryName;
                },
                sort: function () {
                  return "CategoryName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_ProductName_"),
              width: "30%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.ProductLabel;
                },
                sort: function () {
                  return "ProductLabel";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Quantity_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Quantity;
                },
                sort: function () {
                  return "Quantity";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Total_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.TotalSum;
                },
                sort: function () {
                  return "TotalSum";
                }
              }
            },
            {
              name: localize.getLocalizedString("_TotalAfterDiscounts_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.RelativeSum;
                },
                sort: function () {
                  return "RelativeSum";
                }
              }
            }
          ];

        // -------------------------  PriceVsCost ------------------------//

        case "PriceVsCost":
          return [
            {
              name: localize.getLocalizedString("_Name_"),
              width: "28%",
              type: 'ImageWithValue',
              sortable: true,
              command: {
                get: function (entity) {
                  return {
                    text: entity.Name,
                    image: baseUrl + entity.Image
                  };
                },
                sort: function () {
                  return "Name";
                }

              }
            },
            {
              name: localize.getLocalizedString("_PurchasePrice_"),
              width: "12%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return addCurrencyValue(entity.PurchasingPrice);
                },
                sort: function () {
                  return "PurchasingPrice";
                }
              }
            },
            {
              name: localize.getLocalizedString("_RetailPrice_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return addCurrencyValue(entity.RetailPrice);
                },
                sort: function () {
                  return "RetailPrice";
                }
              }
            },
            {
              name: localize.getLocalizedString("_NumOfUnitsSold_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.NumOfUnitsSold;
                },
                sort: function () {
                  return "NumOfUnitsSold";
                }
              }
            },
            {
              name: localize.getLocalizedString("_ActualPriceAverage_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return addCurrencyValue(entity.ActualPriceAverage);
                },
                sort: function () {
                  return "ActualPriceAverage";
                }
              }
            },
            {
              name: localize.getLocalizedString("_TotalSalesAmount_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return addCurrencyValue(entity.TotalSalesAmount);
                },
                sort: function () {
                  return "TotalSalesAmount";
                }
              }
            }
          ];

        // -------------------------  EmployeeHours ------------------------//

        case "EmployeeHours":

          return [
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "8%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.EmployeeName;
                },
                sort: function () {
                  return "EmployeeName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_TotalHours_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return getDuration(entity.Hours);
                  //return entity.Hours;
                },
                sort: function () {
                  return "Hours";
                }
              }
            },
            {
              name: localize.getLocalizedString("_FromHour_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.From;
                },
                sort: function () {
                  return "From";
                }
              }
            },
            {
              name: localize.getLocalizedString("_ToHour_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.To;
                },
                sort: function () {
                  return "To";
                }
              }
            }
          ];

        // -------------------------  ProductInventory ------------------------//

        case "ProductInventory":

          return [
            {
              name: localize.getLocalizedString("_Name_"),
              width: "24%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Name;
                },
                sort: function () {
                  return "Name";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Category_"),
              width: "12%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.Category;
                },
                sort: function () {
                  return "Category";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Quantity_"),
              width: "12%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.Quanitity;
                },
                sort: function () {
                  return "Quanitity";
                }
              }
            },
            {
              name: localize.getLocalizedString("_LastSupply_"),
              width: "16%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.LastSupply).format(DATE_DISPLAY_FORMAT);
                },
                sort: function () {
                  return "LastSupply";
                }
              }
            },
            {
              name: localize.getLocalizedString("_LastSupplyQuantity_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.LastSupplyQuantity;
                },
                sort: function () {
                  return "LastSupplyQuantity";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Remarks_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.LastSupplyRemarks;
                },
                sort: function () {
                  return "LastSupplyRemarks";
                }
              }
            },
            {
              name: localize.getLocalizedString("_PurchasePrice_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.PurchasingPrice;
                },
                sort: function () {
                  return "PurchasingPrice";
                }
              }
            },
            {
              name: localize.getLocalizedString("_RetailPrice_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.RetailPrice;
                },
                sort: function () {
                  return "RetailPrice";
                }
              }
            }
          ];



        // -------------------------  Customers ------------------------//

        case "Customers":

          return [
            {
              name: localize.getLocalizedString("_Name_"),
              width: "12%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.Name;
                },
                sort: function () {
                  return "Name";
                }
              }
            },
            {
              name: localize.getLocalizedString("_GenderType_"),
              width: "4%",
              sortable: true,
              command: {
                get: function (entity) {
                  return genderTypes[entity.Gender];
                  ;
                },
                sort: function () {
                  return "Gender";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Mobile_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.MobileFirst;
                },
                sort: function () {
                  return "MobileFirst";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Email_"),
              width: "16%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.EmailAddress;
                },
                sort: function () {
                  return "EmailAddress";
                }
              }
            },
            {
              name: localize.getLocalizedString("_DateOfBirth_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.DateOfBirth ? moment(entity.DateOfBirth).format(DATE_DISPLAY_FORMAT) : null;
                },
                sort: function () {
                  return "DateOfBirth";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CreatedOn_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.CreatedOn).format(DATE_DISPLAY_FORMAT);
                },
                sort: function () {
                  return "CreatedOn";
                }
              }
            },
            {
              name: localize.getLocalizedString("_LastVisit_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.LastVisit ? moment(entity.LastVisit).format(DATE_DISPLAY_FORMAT) : null;
                },
                sort: function () {
                  return "LastVisit";
                }
              }
            },
            {
              name: localize.getLocalizedString("_NextMeeting_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.NextMeeting ? moment(entity.NextMeeting).format(DATE_DISPLAY_FORMAT) : null;
                },
                sort: function () {
                  return "NextMeeting";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Visits_"),
              width: "6%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.NumberOfVisits;
                },
                sort: function () {
                  return "NumberOfVisits";
                }
              }
            },
            {
              name: localize.getLocalizedString("_AverageInvoice_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.AvarageInvoice;
                },
                sort: function () {
                  return "AvarageInvoice";
                }
              }
            }
          ];


        // -------------------------  Subscriptions ------------------------//

        case "Subscriptions":

          return [
            {
              name: localize.getLocalizedString("_Customer_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CustomerName;
                },
                sort: function () {
                  return "CustomerName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Name_"),
              width: "16%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Title;
                },
                sort: function () {
                  return "Title";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Kind_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.ItemType;
                },
                sort: function () {
                  return "ItemType";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Price_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.TotalPrice;
                },
                sort: function () {
                  return "TotalPrice";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Total_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.TotalQuantity;
                },
                sort: function () {
                  return "TotalQuantity";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Usages_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.QuantityBalance;
                },
                sort: function () {
                  return "QuantityBalance";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Balance_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.QuantityLeft;
                },
                sort: function () {
                  return "QuantityLeft";
                }
              }
            },
            {
              name: localize.getLocalizedString("_LastUsage_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.CreatedOn).format(DATE_DISPLAY_FORMAT);
                },
                sort: function () {
                  return "LastUsage";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CreatedOn_"),
              width: "10%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return moment(entity.CreatedOn).format(DATE_DISPLAY_FORMAT);
                },
                sort: function () {
                  return "CreatedOn";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.EmployeeName;
                },
                sort: function () {
                  return "EmployeeName";
                }
              }
            }
          ];
        // -------------------------  GiftCards ------------------------//

        case "GiftCards":

          return [
            {
              name: localize.getLocalizedString("_Buyer_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Buyer;
                },
                sort: function () {
                  return "Buyer";
                }
              }
            }, {
              name: localize.getLocalizedString("_Name_"),
              width: "18%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Title;
                },
                sort: function () {
                  return "Title";
                }
              }
            },
            {
              name: localize.getLocalizedString("_RefNumber_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.RefNumber;
                },
                sort: function () {
                  return "RefNumber";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Total_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.TotalAmount;
                },
                sort: function () {
                  return "TotalAmount";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Usages_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Usages;
                },
                sort: function () {
                  return "Usages";
                }
              }
            },
            {
              name: localize.getLocalizedString("_AmountUsed_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.AmountUsed;
                },
                sort: function () {
                  return "AmountUsed";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CreatedOn_"),
              width: "16%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return moment(entity.CreatedOn).format(DATE_DISPLAY_FORMAT);
                },
                sort: function () {
                  return "CreatedOn";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "14%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.EmployeeName;
                },
                sort: function () {
                  return "EmployeeName";
                }
              }
            }
          ];

        // -------------------------  SubscriptionUsages ------------------------//

        case "SubscriptionUsages":

          return [
            {
              name: localize.getLocalizedString("_Name_"),
              width: "24%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Title;
                },
                sort: function () {
                  return "Title";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CustomerName_"),
              width: "18%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CustomerName;
                },
                sort: function () {
                  return "CustomerName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_OriginalPrice_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.OriginalPrice;
                },
                sort: function () {
                  return "OriginalPrice";
                }
              }
            },
            {
              name: localize.getLocalizedString("_RelativePrice_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.RelativePrice;
                },
                sort: function () {
                  return "RelativePrice";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CreatedOn_"),
              width: "18%",
              sortable: true,
              orderedBy: true,

              command: {
                get: function (entity) {
                  return moment(entity.CreatedOn).format(DATE_DISPLAY_FORMAT);
                },
                sort: function () {
                  return "CreatedOn";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "20%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.EmployeeName;
                },
                sort: function () {
                  return "EmployeeName";
                }
              }
            }
          ];

        case "Refunds":
          return [
            {
              name: localize.getLocalizedString("_InvoiceIndex_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.InvoiceIndex;
                },
                sort: function () {
                  return "InvoiceIndex";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Date_"),
              width: "12%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return moment(entity.Date).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "Date";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Amount_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return addCurrencyValue(entity.TotalAmount);
                },
                sort: function () {
                  return "TotalAmount";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.EmployeeName;
                },
                sort: function () {
                  return "EmployeeName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Customer_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CustomerName;
                },
                sort: function () {
                  return "CustomerName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_PaymentType_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.PaymentType;
                },
                sort: function () {
                  return "PaymentType";
                }
              }
            },
            {
              name: localize.getLocalizedString("_RefundFor_"),
              width: "16%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Description;
                },
                sort: function () {
                  return "Description";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Remarks_"),
              width: "16%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Remarks;
                },
                sort: function () {
                  return "Remarks";
                }
              }
            }
          ];

        case "MeetingCancellations":
          return [
            {
              name: localize.getLocalizedString("_StartTime_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.StartTime).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "StartTime";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CancelledOn_"),
              width: "12%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return moment(entity.LastUpdated).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "LastUpdated";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CancellationReason_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CancellationReason;
                },
                sort: function () {
                  return "CancellationReason";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Customer_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CustomerName;
                },
                sort: function () {
                  return "CustomerName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Mobile_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.MobileFirst;
                },
                sort: function () {
                  return "MobileFirst";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Employee;
                },
                sort: function () {
                  return "Employee";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CreatedOn_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.CreatedOn).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "CreatedOn";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Remarks_"),
              width: "16%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Remarks;
                },
                sort: function () {
                  return "Remarks";
                }
              }
            }
          ];

        case "Meetings":
          return [
            {
              name: localize.getLocalizedString("_StartTime_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.StartTime).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "StartTime";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EndTime_"),
              width: "10%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return moment(entity.EndTime).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "EndTime";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Employee;
                },
                sort: function () {
                  return "Employee";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Customer_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CustomerName;
                },
                sort: function () {
                  return "CustomerName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Mobile_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.MobileFirst;
                },
                sort: function () {
                  return "MobileFirst";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CreatedOn_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.CreatedOn).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "CreatedOn";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Services_"),
              width: "14%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Services;
                },
                sort: function () {
                  return "Services";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Remarks_"),
              width: "14%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Recurrence;
                },
                sort: function () {
                  return "Remarks";
                }
              }
            }
          ];
        case "MeetingsScheduling":
          return [
            {
              name: localize.getLocalizedString("_CreatedOn_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.CreatedOn).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "CreatedOn";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Customer_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Title;
                },
                sort: function () {
                  return "Title";
                }
              }
            },
            {
              name: localize.getLocalizedString("_StartTime_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.StartTime).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "StartTime";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.EmployeeName;
                },
                sort: function () {
                  return "EmployeeName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_UserName_"),
              width: "16%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.UserName;
                },
                sort: function () {
                  return "UserName";
                }
              }
            }
          ];

        case "Meetings":
          return [
            {
              name: localize.getLocalizedString("_StartTime_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.StartTime).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "StartTime";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EndTime_"),
              width: "10%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return moment(entity.EndTime).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "EndTime";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Employee;
                },
                sort: function () {
                  return "Employee";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Customer_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CustomerName;
                },
                sort: function () {
                  return "CustomerName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Mobile_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.MobileFirst;
                },
                sort: function () {
                  return "MobileFirst";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CreatedOn_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.CreatedOn).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "CreatedOn";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Services_"),
              width: "14%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Services;
                },
                sort: function () {
                  return "Services";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Remarks_"),
              width: "14%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Recurrence;
                },
                sort: function () {
                  return "Remarks";
                }
              }
            }
          ];

        case "OnlineBookings":
          return [
            {
              name: localize.getLocalizedString("_StartTime_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.StartTime).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "StartTime";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EndTime_"),
              width: "10%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return moment(entity.EndTime).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "EndTime";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Employee;
                },
                sort: function () {
                  return "Employee";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Customer_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CustomerName;
                },
                sort: function () {
                  return "CustomerName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Mobile_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.MobileFirst;
                },
                sort: function () {
                  return "MobileFirst";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CreatedOn_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.CreatedOn).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "CreatedOn";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Services_"),
              width: "14%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Services;
                },
                sort: function () {
                  return "Services";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Remarks_"),
              width: "14%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Recurrence;
                },
                sort: function () {
                  return "Remarks";
                }
              }
            }
          ];

        case "MeetingsByEmployees":
          return [
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "10%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.Employee;
                },
                sort: function () {
                  return "Employee";
                }
              }
            },
            {
              name: localize.getLocalizedString("_NumberOfMeetings_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.NumberOfMeetings;
                },
                sort: function () {
                  return "NumberOfMeetings";
                }
              }
            },
            {
              name: localize.getLocalizedString("_TotalMinutes_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.TotalMinutes;
                },
                sort: function () {
                  return "TotalMinutes";
                }
              }
            },
            {
              name: localize.getLocalizedString("_NumberOfServices_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.NumberOfServices;
                },
                sort: function () {
                  return "NumberOfServices";
                }
              }
            },
            {
              name: localize.getLocalizedString("_ServicesAmount_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.SumOfServices;
                },
                sort: function () {
                  return "SumOfServices";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Cost_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CostOfServices;
                },
                sort: function () {
                  return "CostOfServices";
                }
              }
            }
          ];

        case "Feedbacks":
          return [
            {
              name: localize.getLocalizedString("_CreatedOn_"),
              width: "10%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return moment(entity.timeSubmitted).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "timeSubmitted";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Customer_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.customerName;
                },
                sort: function () {
                  return "customerName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Rank_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return Math.round(entity.grade * 100) / 10;
                },
                sort: function () {
                  return "grade";
                }
              }
            }, {
              name: localize.getLocalizedString("_FinalResult_"),
              width: "10%",
              command: {
                get: function (entity) {
                  return entity.questionsMap[0].answerValue;
                },
                sort: function () {
                  return "reviewText";
                }
              }
            }, {
              name: localize.getLocalizedString("_Treatment_"),
              width: "10%",
              command: {
                get: function (entity) {
                  return entity.questionsMap[1].answerValue;
                },
                sort: function () {
                  return "reviewText";
                }
              }
            }, {
              name: localize.getLocalizedString("_Satisfaction_"),
              width: "10%",
              command: {
                get: function (entity) {
                  return entity.questionsMap[2].answerValue;
                },
                sort: function () {
                  return "reviewText";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Remarks_"),
              width: "40%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.reviewText;
                },
                sort: function () {
                  return "reviewText";
                }
              }
            }
          ];


        case "Leads":
          return [
            {
              name: localize.getLocalizedString("_CreatedOn_"),
              width: "10%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return moment(entity.CreatedOn).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "CreatedOn";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Name_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Name;
                },
                sort: function () {
                  return "Name";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Mobile_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.MobileFirst;
                },
                sort: function () {
                  return "MobileFirst";
                }
              }
            }, {
              name: localize.getLocalizedString("_Status_"),
              width: "10%",
              command: {
                get: function (entity) {
                  return entity.LeadStatus;
                },
                sort: function () {
                  return "LeadStatus";
                }
              }
            }, {
              name: localize.getLocalizedString("_LeadSubStatus_"),
              width: "10%",
              command: {
                get: function (entity) {
                  return entity.LeadSubStatus;
                },
                sort: function () {
                  return "LeadSubStatus";
                }
              }
            }, {
              name: localize.getLocalizedString("_Tags_"),
              width: "15%",
              command: {
                get: function (entity) {
                  return entity.Tags.join(",");
                },
                sort: function () {
                  return "Tags";
                }
              }
            },
            {
              name: localize.getLocalizedString("_ArrivalSource_"),
              width: "15%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.ArrivalSource;
                },
                sort: function () {
                  return "ArrivalSource";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Meetings_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.MeetingCount;
                },
                sort: function () {
                  return "MeetingCount";
                }
              }
            },
            {
              name: localize.getLocalizedString("_TotalIncomes_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.TotalIncomes;
                },
                sort: function () {
                  return "TotalIncomes";
                }
              }
            }
          ];

        case "ExternalVouchers":
          return [
            {
              name: localize.getLocalizedString("_Date_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.Date).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "Date";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Vendor_"),
              width: "8%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.VendorName;
                },
                sort: function () {
                  return "VendorName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_VoucherName_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.VoucherName;
                },
                sort: function () {
                  return "VoucherName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_RefNumber_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.RefNumber;
                },
                sort: function () {
                  return "RefNumber";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Amount_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.TotalSum;
                },
                sort: function () {
                  return "TotalSum";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Customer_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CustomerName;
                },
                sort: function () {
                  return "CustomerName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_EmployeeName_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.EmployeeName;
                },
                sort: function () {
                  return "EmployeeName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Remarks_"),
              width: "16%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Remarks;
                },
                sort: function () {
                  return "Remarks";
                }
              }
            }
          ];

        case "Payments":
          return [
            {
              name: localize.getLocalizedString("_Date_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return moment(entity.CreatedOn).format(RTL_DATE_TIME_DISPLAY);
                },
                sort: function () {
                  return "CreatedOn";
                }
              }
            },
            {
              name: localize.getLocalizedString("_InvoiceIndex_"),
              width: "10%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.InvoiceIndex;
                },
                sort: function () {
                  return "InvoiceIndex";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CustomerName_"),
              width: "10%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CustomerName;
                },
                sort: function () {
                  return "CustomerName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Amount_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Amount;
                },
                sort: function () {
                  return "Amount";
                }
              }
            },
            {
              name: localize.getLocalizedString("_PaymentType_"),
              width: "10%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.PaymentType;
                },
                sort: function () {
                  return "PaymentType";
                }
              }
            },
            {
              name: localize.getLocalizedString("_NumberOfCharges_"),
              width: "8%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.NumberOfCharges;
                },
                sort: function () {
                  return "NumberOfCharges";
                }
              }
            },
            {
              name: localize.getLocalizedString("_AuthNumber_"),
              width: "14%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.AuthNumber;
                },
                sort: function () {
                  return "AuthNumber";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Result_"),
              width: "8%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.Result;
                },
                sort: function () {
                  return "Result";
                }
              }
            },
            {
              name: localize.getLocalizedString("_ChequeDate_"),
              width: "12%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.PaymentDueDate ? moment(entity.PaymentDueDate).format(DATE_DISPLAY_FORMAT) : "";
                },
                sort: function () {
                  return "PaymentDueDate";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CardLastFourDigits_"),
              width: "8%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CardLastFourDigits;
                },
                sort: function () {
                  return "CardLastFourDigits";
                }
              }
            }
          ];

        case "Debts":
          return [
            {
              name: localize.getLocalizedString("_CustomerName_"),
              width: "20%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.CustomerName;
                },
                sort: function () {
                  return "CustomerName";
                }
              }
            },
            {
              name: localize.getLocalizedString("_CustomerBalance_"),
              width: "10%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.BalanceType === "Debt" ? localize.getLocalizedString("_Debt_") : localize.getLocalizedString("_PositiveBalance_");
                },
                sort: function () {
                  return "BalanceType";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Amount_"),
              width: "10%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.CustomerBalance;
                },
                sort: function () {
                  return "CustomerBalance";
                }
              }
            },
            {
              name: localize.getLocalizedString("_Mobile_"),
              width: "15%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.Mobile;
                },
                sort: function () {
                  return "Mobile";
                }
              }
            },
            {
              name: localize.getLocalizedString("_LastVisit_"),
              width: "15%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.LastVisit;
                },
                sort: function () {
                  return "LastVisit";
                }
              }
            },
            {
              name: localize.getLocalizedString("_NextMeeting_"),
              width: "15%",
              sortable: true,
              command: {
                get: function (entity) {
                  return entity.NextMeeting;
                },
                sort: function () {
                  return "NextMeeting";
                }
              }
            },
            {
              name: localize.getLocalizedString("_TotalSpent_"),
              width: "15%",
              sortable: true,
              orderedBy: true,
              command: {
                get: function (entity) {
                  return entity.TotalSpent;
                },
                sort: function () {
                  return "TotalSpent";
                }
              }
            }
          ];
      }
    }
  }]);
