import { render, staticRenderFns } from "./customers.table.vue?vue&type=template&id=10b98b78&"
import script from "./customers.table.vue?vue&type=script&lang=js&"
export * from "./customers.table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Easybizy\\Code\\easybizy-client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10b98b78')) {
      api.createRecord('10b98b78', component.options)
    } else {
      api.reload('10b98b78', component.options)
    }
    module.hot.accept("./customers.table.vue?vue&type=template&id=10b98b78&", function () {
      api.rerender('10b98b78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/modal-views/customers-table/customers.table.vue"
export default component.exports