<template>
  <div class="whatsapp-on-boarding-wizard__packages z-10 relative"
       :class="selectedPackage && 'whatsapp-on-boarding-wizard__packages--selected'">
    <div class="whatsapp-on-boarding-wizard__package absolute" v-for="p of availablePackages" :key="p.name"
         :class="selectedPackage && p.name === selectedPackage.name && 'whatsapp-on-boarding-wizard__package--selected'"
         @click="translate(p)">
      <transition name="fade">
        <button class="absolute z-10 icon icon-close-round whatsapp-on-boarding-wizard__package-close"
                v-if="selectedPackage"></button>
      </transition>
      <div class="whatsapp-on-boarding-wizard__package-top">
        <div class="whatsapp-on-boarding-wizard__package-name text--primary">
          {{ p.name }}
        </div>
        <div class="whatsapp-on-boarding-wizard__package-icon" :class="p.icon">
        </div>
        <div class="whatsapp-on-boarding-wizard__package-price flex items-center justify-center">
          <div>{{ startingAt }}</div>
          <div class="bold whatsapp-on-boarding-wizard__package-price-num">{{ p.price }}</div>
          <div class="px-2">/</div>
          <div>{{ perMonth }}</div>
        </div>
      </div>
      <div class="whatsapp-on-boarding-wizard__package-content">
        <div class="whatsapp-on-boarding-wizard__package-separator">
        </div>
        <div class="whatsapp-on-boarding-wizard__package-props">
          <div class="whatsapp-on-boarding-wizard__package-prop flex" v-for="(prop) of p.props"
               :key="prop.title">
            <div class="whatsapp-on-boarding-wizard__package-prop-text p-1">
              <span class="bold">{{ prop.title }}</span>&nbsp;<span>{{ prop.text }}</span>
            </div>
          </div>
        </div>
        <div class="whatsapp-on-boarding-wizard__package-select">
          <button :class="p.primary ? 'background--primary' : 'button-secondary'">{{ p.actionText }}</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  emits: ['selected'],
  props: ['availablePackages', 'selectedPackage'],
  created() {
    this.startingAt = this.$t('_WhatsappOnBoarding_PriceStartingAt_')
    this.perMonth = this.$t('_PerMonth_')
  },
  methods: {
    translate(pack) {
      this.$emit('selected', pack === this.selectedPackage ? null : pack)
    }
  }
}
</script>

<style lang="less">
@import "../../../../styles/variables";
@import "vars";

.whatsapp-on-boarding-wizard__packages {
  &--selected .whatsapp-on-boarding-wizard__package:not(.whatsapp-on-boarding-wizard__package--selected) {
    opacity: 0;
    transform: scale(0);
    right: 30px;
  }
}

.whatsapp-on-boarding-wizard__package {
  background: white;
  padding: @space-between-tile;
  border-radius: 15px;
  width: @tile-width;
  box-shadow: 0 2px 5px 0 rgba(164, 173, 186, 0.5);

  transition: all 0.5s ease-in-out;
  right: calc(50% - @tile-width / 2);

  &:first-of-type {
    right: calc(50% - @tile-width - @space-between-tile);
  }

  &:last-of-type {
    right: calc(50% + @space-between-tile);
  }

  &-content {
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    max-height: @tile-max-height;
  }

  &--selected.absolute {
    right: 30px;

    .whatsapp-on-boarding-wizard__package-content {
      max-height: 0;
    }
  }

  .whatsapp-on-boarding-wizard__package-content > * {
    margin-top: 20px;
  }

  &-close {
    top: 20px;
    left: 20px;
    font-size: 1.25em;
  }

  &-name {
    font-size: 25px;
    text-align: center;
  }

  &-icon {
    font-size: 50px;
    text-align: center;
    margin-top: 20px;
  }

  &-separator {
    height: 1px;
    background-color: @header-underline-color;
    margin: 10px 0;
  }

  &-price-num {
    font-size: 25px;
  }

  &-select button {
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    border: 2px solid @primary-color;
  }

  &-select .button-secondary {
    border: 2px solid @primary-color;
    color: @primary-color;
  }

  .whatsapp-on-boarding-wizard__package-prop {
    align-items: baseline;
  }


  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }



}


</style>
