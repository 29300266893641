import './whatsapp.promotion.editor.directive.less';
import {extractError} from "@tomeravni/easybizy-js-common/errors";
import {
  isNonEmptyArray,
  isNonEmptyObject,
  isNonEmptyString,
  valueOrDefault
} from "@tomeravni/easybizy-js-common/common";

const NUMBER_OF_PARAMS_REGEX = new RegExp('{{[0-9]*}}', 'g');

angular.module('easybizy.common.promotion.editors').directive('whatsappEditor', [
  'localize', 'promotionTemplateResolver', 'Repository',
  function (localize, promotionTemplateResolver, Repository) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        contentResolver: "=",
        defaultCustomer: "=",
        preventTemplate: '=',
        active: "=",
        preset: "=",
        mode: "@",
        content: "=",
        alwaysEditMode: '='
      },
      template: require('./whatsapp.promotion.editor.directive.tpl.html'),
      link: function (scope, element, attrs) {
        let currentLang = localize.language.split('-')[0];
        Repository.Custom('Conversations').templates(currentLang).then((res) => {
          scope.templates = res.map((template) => {
            const components = JSON.parse(template.components);
            let headerComponent = components.find((x) => x.type === 'HEADER');
            const header = headerComponent?.text
            const headerDefaultParamValues = headerComponent?.paramDefaults;
            const headerDynamicParamsCount = getNumberOfDynamicParams(header)
            let bodyComponent = components.find((x) => x.type === 'BODY');
            const body = bodyComponent?.text
            const bodyDynamicParamsCount = getNumberOfDynamicParams(body)
            const bodyDefaultParamValues = bodyComponent?.paramDefaults;
            const footer = components.find((x) => x.type === 'FOOTER')?.text
            const buttons = components.find((x) => x.type === 'BUTTONS')?.buttons
            const buttonsWithParams = buttons?.filter((x) => isNonEmptyArray(x.paramDefaults));


            return {
              ltr: template.language === 'en',
              name: template.name,
              value: template.waName,
              editable: template.numberOfParams > 0,
              header,
              headerDefaultParamValues,
              headerDynamicParamsCount,
              body,
              bodyDefaultParamValues,
              bodyDynamicParamsCount,
              buttonsWithParams,
              // numOfButtonsDynamicParams,
              footer,
              buttons
            }
          })

          scope.selectedTemplate = scope.templates[0]
        }).catch((err) => {
          toastr.error(extractError(err))
        })

        scope.isEditMode = !!scope.alwaysEditMode;
        scope.currentPreset = null;
        scope.editorTitle = scope.isEditMode ? localize.getLocalizedString("_Edit_") : localize.getLocalizedString("_Preview_");
        scope.modeButtonText = localize.getLocalizedString("_Edit_");

        scope.$root.$broadcast('editMode', false);

        scope.toggleEditMode = function () {
          scope.isEditMode = !scope.isEditMode;
          if (scope.isEditMode) {
            scope.editorTitle = localize.getLocalizedString("_Edit_");
            scope.modeButtonText = localize.getLocalizedString("_Preview_");
          } else {
            scope.editorTitle = localize.getLocalizedString("_Preview_");
            scope.modeButtonText = localize.getLocalizedString("_Edit_");
          }
        };

        scope.actionTriggerButtonTooltip = localize.getLocalizedString('_WhatsappActionButtonsTooltipExplain_')

        scope.headerParamValues = {};
        scope.bodyParamValues = {};
        scope.headerInputFields = [];
        scope.bodyInputFields = [];

        scope.focused = (ev, field, model) => {
          scope.addDynamicFieldValue = (stringToAppend) => {
            model[field.fieldName] = ev.target.insertAtCaret(stringToAppend);
          }
        }

        scope.blured = (ev) => {
          setTimeout(() => {
            if (document.activeElement.tagName !== 'INPUT') {
              scope.addDynamicFieldValue = null
            }
          }, 100)
        }


        scope.$watch('selectedTemplate', (template) => {
          scope.headerInputFields = [];
          scope.bodyInputFields = [];
          scope.headerParamValues = {};
          scope.bodyParamValues = {};
          if (!template?.editable) {
            return;
          }

          if (template.headerDynamicParamsCount > 0) {
            [...new Array(template.headerDynamicParamsCount)].forEach((_, idx) => mapSingleInputField(true, idx));
          }

          if (template.bodyDynamicParamsCount > 0) {
            [...new Array(template.bodyDynamicParamsCount)].forEach((_, idx) => mapSingleInputField(false, idx));
          }
        })

        const dynamicFieldLocalized = localize.getLocalizedString('_DynamicField_')

        const mapSingleInputField = (isHeader, index) => {
          const scopeInputFieldKey = isHeader ? 'headerInputFields' : 'bodyInputFields';
          const scopeParamValue = isHeader ? 'headerParamValues' : 'bodyParamValues';
          const templateDefaultValues = (isHeader ? scope.selectedTemplate.headerDefaultParamValues : scope.selectedTemplate.bodyDefaultParamValues) || [];

          let fieldKey = `param_${index + 1}`;
          const field = {
            fieldName: fieldKey,
            placeholder: `${dynamicFieldLocalized}-{{${index + 1}}}`,
            validation: "{'required': true}"
          }

          scope[scopeParamValue][fieldKey] = templateDefaultValues[index] || '';
          scope[scopeInputFieldKey].push(field);
        }

        scope.insertDynamicField = function (field) {
          if (scope.addDynamicFieldValue) {
            scope.addDynamicFieldValue(field.value)
          } else {
            let firstInputElement = element.find('.promotion-preview .text-box-with-icon-container:first-of-type input');
            firstInputElement.focus()
            firstInputElement[0].blink()
            toastr.warning(localize.getLocalizedString('_PleaseFocusOnInputField_'))
          }
        };

        scope.$watch('contentResolver', function (newVal) {
          if (newVal) {
            scope.contentResolver.resolve = function (promotionToAppendContentTo) {

              /// TODO: Daniel, this for handling existing Template.
              // if (angular.isDefined(scope.content)) {
              //   if (promotionToAppendContentTo.ClientPromotionContent.message === scope.messageText) {
              //     // content wan't changed.
              //     promotionToAppendContentTo.ClientPromotionContent.State = "Unchanged";
              //     return;
              //   } else {
              //     promotionToAppendContentTo.ClientPromotionContent = {};
              //     promotionToAppendContentTo.ClientPromotionContent.State = "Modified";
              //   }
              // } else {
              //   promotionToAppendContentTo.ClientPromotionContent = {};
              // }

              promotionToAppendContentTo.ClientPromotionContent = {};
              promotionToAppendContentTo.ClientPromotionContent.WhatsappTemplateName = scope.selectedTemplate.value;
              promotionToAppendContentTo.ClientPromotionContent.PromotionSenderType = 'whatsapp';
              promotionToAppendContentTo.ClientPromotionContent.Message = scope.selectedTemplate.body;

              const paramDynamicValues = [];
              if (isNonEmptyArray(scope.headerInputFields)) {
                scope.headerInputFields.forEach((field) => paramDynamicValues.push(scope.headerParamValues[field.fieldName]))
              }

              if (isNonEmptyArray(scope.bodyInputFields)) {
                scope.bodyInputFields.forEach((field) => paramDynamicValues.push(scope.bodyParamValues[field.fieldName]))
              }

              if (isNonEmptyArray(scope.selectedTemplate.buttonsWithParams)) {
                let preAddingButtonParamsLength = getNumberOfDynamicParams(promotionToAppendContentTo.ClientPromotionContent.Message)
                valueOrDefault(scope.selectedTemplate.buttons, []).forEach((button) => {
                  if (button.type === 'URL' && isNonEmptyArray(button.paramDefaults)) {
                    paramDynamicValues.push(...button.paramDefaults);
                    promotionToAppendContentTo.ClientPromotionContent.Message += `\n\n${button.text}:\n {{${++preAddingButtonParamsLength}}}`
                  } else {
                    promotionToAppendContentTo.ClientPromotionContent.Message += `\n\n${button.text}: ${button.phone_number || button.url}`;
                  }
                })
              }

              /// TODO:
              // Handle call phone number button action.


              promotionToAppendContentTo.ClientPromotionContent.DynamicValues = paramDynamicValues;
            };
            scope.contentResolver.validate = function () {
              let foundError = false;
              if (isNonEmptyArray(scope.headerInputFields)) {
                foundError = scope.headerInputFields.some((field) => !isNonEmptyString(scope.headerParamValues[field.fieldName]))
              }

              if (!foundError && isNonEmptyArray(scope.bodyInputFields)) {
                foundError = scope.bodyInputFields.some((field) => !isNonEmptyString(scope.bodyParamValues[field.fieldName]))
              }

              if (foundError && !scope.isEditMode) {
                scope.isEditMode = true;
              }

              return foundError ? localize.getLocalizedString('_WhatsappParamsAreMandatory_') : '';
            };
          }
        });

        const applyTemplate = function () {
          if (scope.active && scope.preset) {
            if (scope.preset.TemplateName !== scope.currentPreset) {
              scope.currentPreset = scope.preset.TemplateName;
              scope.fields = promotionTemplateResolver.getDynamicFields(scope.preset);
            }
          }
        };

        scope.$watch('active', applyTemplate);
        scope.$watch('preset', applyTemplate);
      }
    };

    function getNumberOfDynamicParams(text) {
      if (!isNonEmptyString(text)) {
        return 0;
      }

      const dynamicParams = text.match(NUMBER_OF_PARAMS_REGEX)?.length;
      // noinspection JSValidateTypes
      return valueOrDefault(dynamicParams, 0);
    }

  }]);
