var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-time-picker-input", class: _vm.disabled && "disabled" },
    [
      _c("div", {
        staticClass: "vue-time-picker-input__arrow icon icon-dropup",
        on: { click: _vm.increase },
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          { name: "mask", rawName: "v-mask", value: "##", expression: "'##'" },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.internalValue,
            expression: "internalValue",
          },
        ],
        ref: "input",
        attrs: { maxlength: "2", disabled: _vm.disabled, autocomplete: "off" },
        domProps: { value: _vm.internalValue },
        on: {
          blur: _vm.adaptModifiedValue,
          focus: _vm.selectAll,
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.internalValue = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "vue-time-picker-input__arrow icon icon-dropdown",
        on: { click: _vm.decrease },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }