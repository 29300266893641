import {isFunction, isNonEmptyObject} from "@tomeravni/easybizy-js-common/common";

angular.module('easybizy.common.common-controls').directive('entityConfigPicker', function (localize, confirmService) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      loadItemsDelegate: '=',
      showSearch: '=',
      actions: '=',
      renameDelegate: '=',
      addNewItemDelegate: '='
    },
    controller: function ($scope) {
      Object.defineProperty(this, 'allowInlineEdit', {
        get() {
          return isFunction($scope.renameDelegate);
        }
      })

      Object.defineProperty($scope, 'supportAddition', {
        get() {
          return isFunction($scope.addNewItemDelegate)
        }
      })

      if ($scope.showSearch) {
        $scope.searchWrapper = {searchText: ""};
        $scope.$watch('searchWrapper.searchText', function (newVal, oldVal) {
          if (newVal &&
            newVal.length > 2 || (oldVal && oldVal.length > 0)) {
            $scope.reloadItems($scope.searchWrapper.searchText);
          }
        });
        $scope.searchWrapper.showSearch = true;
      } else {
        $scope.searchWrapper = {showSearch: false}
      }

      $scope.addNewItem = this.inlineUpdateItem = (item) => {
        confirmService.prompt(
          /*title*/localize.getLocalizedString('_NewName_'),
          isNonEmptyObject(item) ? `${localize.getLocalizedString('_Current_')}: ${item.name}` : '', '',
          /*onOk*/function (newName) {
            $scope.$evalAsync(() => {
              $scope.isLoading = true;
              let delegate = isNonEmptyObject(item) ? $scope.renameDelegate(item, newName) : $scope.addNewItemDelegate(newName);
              delegate.then(() => {
                $scope.isLoading = false;
                $scope.reloadItems();
                toastr.success(localize.getLocalizedString('_ConfigurationSaved_'));
              }).catch((e) => {
                $scope.isLoading = false;
                toastr.error(e.message);
              })
            })
          },
          function () {
          },
          function (value) {
            return value.trim().length > 2 ? null : localize.getLocalizedString('_LengthMustBeAtLeastChars_', 2)

          },
        );
      }
    },
    link: function (scope) {
      scope.isLoading = true;
      scope.items = [];
      scope.$watchOnce('loadItemsDelegate', reloadItems);
      scope.reloadItems = reloadItems;

      scope.performAction = (action) => {
        const perform = () => {
          toggleProgress(action);
          action.delegate(scope.selectedValues).then(() => {
            toggleProgress(null);
            toastr.success(localize.getLocalizedString('_ConfigurationSaved_'));
            reloadItems();
          }).catch((e) => {
            toggleProgress(null);
            toastr.error(e.message);
          })
        }

        if (action.confirmMessage) {
          confirmService.confirm(action.confirmMessage,
            null, function () {
              perform();
            });
        } else {
          perform();
        }
      }


      Object.defineProperty(scope, 'selectedValues', {
        get() {
          return getSelectedValues(scope.items);
        }
      });

      function toggleProgress(action) {
        scope.$evalAsync(() => {
          scope.progressAction = action
        })
      }

      function getSelectedValues(items) {
        const filteredSelected = items.filter((x) => x.selected).map((x) => x.value);
        const selectedChildren = items.filter((x) => Array.isArray(x.children)).reduce((acc, x) => ([...acc, ...getSelectedValues(x.children)]), []);
        return [...filteredSelected, ...selectedChildren];
      }

      function reloadItems(search) {
        scope.loadItemsDelegate(search).then((res) => {
          scope.items.length = 0;
          scope.isLoading = false;
          scope.items.pushAll(res);
        }).catch((e) => {
          scope.isLoading = false;
          toastr.error(e.message);
        })
      }

    },
    template: require('./custom.entity.config.picker.tpl.html')
  }
});


angular.module('easybizy.common.common-controls').directive('selectableTree', function () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      items: '='
    },
    link: function (scope, element, attrs) {
    },
    template: '<div class="selectable-tree"><selectable-tree-root items="items" level="0"></selectable-tree-root></div>'
  };
});

angular.module('easybizy.common.common-controls').directive('selectableTreeRoot', function () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      items: '=',
      level: '='
    },
    link: function (scope, element, attrs) {
    },
    template: '<ul><selectable-node ng-repeat="item in items" item="item" level="level + 1"></selectable-node></ul>'
  };
});

angular.module('easybizy.common.common-controls').directive('selectableNode', function ($compile) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      item: '=',
      level: '='
    },
    require: '^entityConfigPicker',
    link: function (scope, element, attrs, ctrl) {
      Object.defineProperty(scope, 'allowInlineEdit', {
        get() {
          return ctrl.allowInlineEdit;
        }
      });

      scope.inlineEditItem = (item) => {
        ctrl.inlineUpdateItem(item);
      }

      //// Check if there are any children, otherwise we'll have infinite execution

      const hasChildren = scope.hasChildren = Array.isArray(scope.item.children);

      //// Manipulate HTML in DOM
      if (hasChildren) {
        element.append('<selectable-tree-root items="item.children" level="level"></selectable-tree-root>');

        // recompile Angular because of manual appending
        $compile(element.contents())(scope);
      } else {
        element.addClass('no-parent')
      }

      if (scope.level >= 1) {
        element.addClass('collapsed');
      }

      scope.toggle = () => {
        if (hasChildren) {
          element.toggleClass('collapsed');
        }
      }

      scope.itemClicked = (isSelected) => {
        if (hasChildren) {
          selectChildren(scope.item, isSelected);
        }

        adaptParent(scope.item, isSelected);
      };

      function selectChildren(item, isSelected) {
        item.selected = isSelected;
        if (Array.isArray(item.children)) {
          item.children.forEach((x) => selectChildren(x, isSelected));
        }
      }

      function adaptParent(item, isSelected) {
        if (!item.parent) {
          return;
        }

        if (!isSelected) {
          item.parent.selected = isSelected;
          return adaptParent(item.parent, isSelected);
        } else {
          if (item.parent.children.filter(x => x !== item).every(isAllChildrenSelected)) {
            item.parent.selected = true;
            adaptParent(item.parent, isSelected);
          }
        }
      }

      function isAllChildrenSelected(item) {
        return item.selected && (!Array.isArray(item.children) || item.children.every(isAllChildrenSelected));
      }

    },
    template: '<li><div ng-click="toggle()" ng-if="hasChildren" class="icon collapse-icon"></div><div class="flex-container align-flex-center"><checkbox string-text="item.name" active="item.selected" clicked="itemClicked"></checkbox><div class="selected-tree-node-notes" ng-if="item.notes">{{ item.notes }}</div><div ng-if="allowInlineEdit" ng-click="inlineEditItem(item)" class="icon icon-edit inline-edit"></div></div></li>'
  };
});
