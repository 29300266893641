import './graph.widget.directive.less'

angular.module('easybizy.reports').directive('graphWidget',
  function () {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        noData: '=',
        selectedView: '=',
        tableData: '=',
        trackByWidget: '=',
        dataTableApi: '=',
        graphData: '=',
        isLoadingWrapper: '=',
        fields: '=',
        apiWrapper: '='
      },
      link: function (scope, element) {
        scope.$watch('graphData', () => {
          setTimeout(() => {
            element.find('.graph-viewer-view').trigger('resize');
          }, 500 + Math.floor(Math.random() * 1000));
        })
      },
      template: require("./graph.widget.tpl.directive.html")
    };
  });

