<template>
  <div class="waiting-list-item p-4">
    <div class="flex justify-between">
      <label>{{ $t('_AddToWaitingList_') }}</label>
      <div class="flex-container items-center flex-justify-end">
        <button class="evci-close" @click="close"></button>
      </div>
    </div>

    <div class="mt-4 flex gap-2 items-center">
      <label>{{ $t('_Customer_') }}</label>
      <button class="text--primary underline" @click="pickCustomer">{{ customerName }}</button>
    </div>

    <div class="mt-4 flex flex-col gap-2">
      <label>{{ $t('_Services_') }}</label>
      <combobox :value="item.services" :fullwidth="true"
                :placeholder="$t('_SelectServices_')"
                :options="availableServices"
                :searchable="true" :multi-select="true" @change="updateFieldValue('services', $event)"
      ></combobox>
    </div>

    <div class="mt-4">
      <div class="flex items-center">
        <label class="me-4">{{ $t('_WeekDays_') }}</label>
        <toggle :message="$t('_Date_')"
                :value="item.onlySpecifiedDate"
                @change="updateFieldValue('onlySpecifiedDate', $event)"></toggle>
      </div>
      <div class="mt-2"></div>
      <expandable-component :value="!item.onlySpecifiedDate" :animate-height-change="true">
        <div class="waiting-list-item--days-of-work flex justify-between">
          <checkbox v-for="day in daysOfWork"
                    @change="setWeekDay(day, $event)"
                    :value="item.weekDays[day.rrday]" :text="day.label"></checkbox>
        </div>
      </expandable-component>
      <expandable-component :value="item.onlySpecifiedDate" :animate-height-change="true">
        <div class="flex items-center gap-2">
          <label>{{ $t('_Date_') }}</label>
          <div>
            <DatePicker
              v-model="specificDate"
              :weekDays="workingDays"
              :title="$t('_Date_')"
            />
          </div>
        </div>
      </expandable-component>
    </div>
    <div class="h-2 inline-block"></div>
    <div class="mt-4">
      <label>{{ $t('_DayPart_') }}</label>
      <div class="waiting-list-item--day-parts flex gap-4 mt-4">
        <checkbox v-for="dayPart in dayParts"
                  @change="updateDayParts(dayPart, $event)"
                  :value="item.dayParts[dayPart.value]" :text="dayPart.label"></checkbox>
      </div>
      <div class="mt-4">
        <toggle :message="$t('_SpecificTime_')"
                :value="item.specificTimeOfDay"
                @change="updateFieldValue('specificTimeOfDay', $event)"></toggle>

        <div class="mt-2">
          <expandable-component :value="item.specificTimeOfDay" :animate-height-change="true">
            <div>
              <label>{{ $t('_SearchingRange_') }}</label>
              <div class="waiting-list-item--time flex gap-4">
                <time-picker :value="this.item.exactStartTime"
                             @change="updateFieldValue('exactStartTime', $event)"></time-picker>
                <time-picker :value="this.item.exactEndTime"
                             @change="updateFieldValue('exactEndTime', $event)"></time-picker>
              </div>
            </div>
          </expandable-component>
        </div>
      </div>
      <div class="h-2 inline-block"></div>
      <div class="mt-4">
        <label>{{ $t('_Employees_') }}</label>
        <div class="flex flex-col gap-2 mt-2">
          <checkbox :value="allEmployees" @change="toggleAllEmployees" :text="$t('_AllEmployees_')"></checkbox>
          <expandable-component :value="!allEmployees" :animate-height-change="true">
            <div class="flex flex-col gap-2">
              <checkbox v-for="employee in availableEmployees"
                        :value="item.employees.includes(employee.id)"
                        @change="toggleEmployee(employee, $event)"
                        :text="employee.name"></checkbox>
            </div>
          </expandable-component>
        </div>
      </div>
      <div class="h-2 inline-block"></div>
      <Button :primary="true" class="text-sm" :in-progress="isSavingItem" @click="save">{{ $t('_Save_') }}</Button>
      <transition name="fade">
        <div class="top-0 left-0 absolute-container evc-background--default opacity-30" v-if="isSavingItem"></div>
      </transition>
    </div>
  </div>
</template>

<script>
import {
  Checkbox,
  TimePicker,
  Toggle,
  ExpandableComponent,
  Combobox,
  DatePicker
} from "@tomeravni/easybizy-vue-components";
import {isValidDate, toRRuleDay} from "@tomeravni/easybizy-js-common/date";
import {getSafely, isNonEmptyArray} from "@tomeravni/easybizy-js-common/common";
import Button from "../../../vue/components/button.vue";

export default {
  components: {Checkbox, TimePicker, Toggle, ExpandableComponent, Combobox, Button, DatePicker},
  emits: ['close', 'change', 'save'],
  created() {
    const dayNames = [this.$t("_Sun_"),
      this.$t("_Mon_"),
      this.$t("_Tue_"),
      this.$t("_Wed_"),
      this.$t("_Thu_"),
      this.$t("_Fri_"),
      this.$t("_Sat_")]

    this.workingDays = this.calendarMetadata.daysOfWork.map(({IsEnabled}) => IsEnabled);
    this.daysOfWork = this.calendarMetadata.daysOfWork.filter(({IsEnabled}) => IsEnabled).map((day) => {
      const index = day.Index;
      return {
        index: index,
        rrday: toRRuleDay(null, index),
        label: dayNames[index]
      }
    })

    const partOfDays = [
      {label: this.$t('_Morning_'), value: 'morning'},
      {label: this.$t('_Noon_'), value: 'noon'},
      {label: this.$t('_Evening_'), value: 'evening'}
    ]

    this.dayParts = partOfDays.map((part) => {
      return {
        label: part.label,
        value: part.value
      }
    })

    // this.availableEmployees = this.calendarMetadata.employees;

    /// Recurring or single specific day/days
    /// Expires.

  },
  data() {
    return {
      daysOfWork: [],
      workingDays: [],
      specificDate: this.item.specificDate
      // availableEmployees: []
      // dayParts: [],
      // startRange: new Date(),
      // endRange: new Date()
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    setWeekDay(day, {newValue}) {
      const updated = {...this.item.weekDays};
      updated[day.rrday] = newValue;
      this.$emit('change', {weekDays: updated});
    },
    updateDayParts(dayPart, {newValue}) {
      const updated = {...this.item.dayParts};
      updated[dayPart.value] = newValue;
      this.$emit('change', {dayParts: updated});
    },
    updateFieldValue(field, val) {
      const updatedValue = getSafely(['value'], val, val)
      this.$emit('change', {[field]: updatedValue});
    },
    toggleAllEmployees({newValue}) {
      if (newValue) {
        this.updateFieldValue('employees', [])
      } else {
        this.toggleEmployee(this.availableEmployees[0], {newValue: true})
      }
    },
    // updateSpecificDate(range) {
    //   this.specificDate = [range[0]];
    //   console.log('new Date', range)
    //   this.$emit('change', {specificDate: this.specificDate});
    // },
    toggleEmployee(employee, {newValue}) {
      const updated = [...this.item.employees];
      if (newValue) {
        updated.push(employee.id);
      } else {
        updated.remove(employee.id)
      }

      this.$emit('change', {employees: updated});
    },
    pickCustomer() {
      this.pickACustomer().then((customer) => {
        this.$emit('change', {customer: customer})
      })
    },
    save() {
      this.$emit('save', this.item);
    }
  },
  watch: {
    specificDate(newVal) {
      this.$emit('change', {specificDate: newVal[0]});
    },
    'item.specificDate'(newVal) {
      if (newVal === this.specificDate[0]) {
        return;
      }

      this.specificDate = [newVal];
    },
  },
  computed: {
    allEmployees() {
      return !isNonEmptyArray(this.item.employees);
    },
    customerName() {
      return this.item.customer?.name || this.$t('_ChooseAClient_')
    },
    selectedSpecificDate() {
      return isValidDate(this.item.specificDate) ? [this.item.specificDate] : [];
    }
  },
  props: {
    isSavingItem: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({})
    },
    availableServices: {
      type: Array,
      default: () => []
    },
    availableEmployees: {
      type: Array,
      default: () => []
    },

  }
}
</script>
