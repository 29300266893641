import './promotion.content.editors.less'
import './sms-editor/sms.promotion.editor.directive'
import './whatsapp/whatsapp.promotion.editor.directive'
import './mail-editor/mail.promotion.editor.directive.js'
import './facebook-editor/facebook.promotion.editor.directive'
import './promotion-content-preview/promotion.content.viewer.directive'
import './promotions-template-resolver/promotion.template.resolver'
import './message-fields/message.dynamic.fields'

angular.module('easybizy.common.promotion.editors').directive('promotionEditors', function ($timeout, Repository, localize, $compile, configuration) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      contentResolverFunc: '=',
      presetType: '=',
      mediumType: '=',
      isEditModeWrapper: '=',
      alwaysEditMode: '=',
      lazyMode: '@'
    },
    template: require('./promotion.content.editors.tpl.html'),
    link: function (scope, element) {
      scope.mailContentResolver = {};
      scope.facebookContentResolver = {};
      scope.smsContentResolver = {};
      scope.whatsappContentResolver = {};
      scope.editors = [
        {
          type: 'Mail',
          heading: 'Email',
          icon: 'glyphicon glyphicon-envelope',
          isActive: true,
          contentResolver: scope.mailContentResolver
        },
        {
          type: 'Sms',
          heading: 'Sms',
          icon: 'glyphicon glyphicon-phone',
          isActive: false,
          contentResolver: scope.smsContentResolver
        },
        {
          type: 'Whatsapp',
          heading: 'Whatsapp',
          icon: 'icon icon-whatsapp',
          isActive: false,
          contentResolver: scope.whatsappContentResolver
        },
        {
          type: 'Facebook',
          heading: 'Facebook',
          icon: 'icon icon-facebook',
          isActive: false,
          contentResolver: scope.facebookContentResolver
        }
      ];

      scope.hideFacebookUser = !configuration.isValidFacebook();
      if (!scope.hideFacebookUser) {
        scope.facebookId = configuration.get().PromotionsSettings.FacebookUserDetails.FacebookId.Value;
      }

      configuration.isValidWhatsappAccount().then((whatsappSupported) => {
        scope.hideWhatsapp = !whatsappSupported;
      })


      var wasTemplateAppended = false;

      scope.$watch('mediumType', function (newVal) {
        if (newVal) {
          if (scope.lazyMode != "true") {
            setCurrentEditor();
            if (!wasTemplateAppended) {
              appendContentEditors();
            }
          } else {
            $timeout(function () {
              setCurrentEditor();
              if (!wasTemplateAppended) {
                appendContentEditors();
              }
            }, 1000);
          }
        }

        function setCurrentEditor() {
          for (var editor in scope.editors) {
            if (newVal == scope.editors[editor].type) {
              scope.currentStep = editor;
              scope.currentEditor = scope.editors[editor];
              scope.contentResolverFunc.resolver = scope.currentEditor.contentResolver;
              scope.contentResolverFunc.currentType = scope.currentEditor.type;
            }
          }
        }
      });

      scope.steps = [0, 1, 2];
      fixPositions();

      scope.goRight = function () {
        scope.steps.push(scope.steps.shift());
        scope.rightToLeftStep = true;
        fixPositions();
      };

      scope.goLeft = function () {
        scope.steps.unshift(scope.steps.pop());
        scope.rightToLeftStep = false;
        fixPositions();
      };

      function fixPositions() {
        scope.leftEditor = scope.editors[scope.steps[2]];
        scope.currentEditor = scope.editors[scope.steps[0]];
        scope.rightEditor = scope.editors[scope.steps[1]];
        scope.currentStep = scope.lazyMode === "true" ? -1 : scope.steps[0];
        scope.contentResolverFunc.resolver = scope.currentEditor.contentResolver;
        scope.contentResolverFunc.currentType = scope.currentEditor.type;
        toggleSaveAsDefaultMode(false);
      }

      scope.rightToLeftStep = false;
      scope.$on('stepChanged', function (e, arg) {
        toggleSaveAsDefaultMode(false);
      });

      scope.$on('editMode', function (e, arg) {
        scope.isEditModeWrapper.isEditMode = arg;
        toggleSaveAsDefaultMode(false);
      });

      scope.isSavingDefaultTemplate = false;
      scope.isSaveTemplateWarningMode = false;
      scope.saveTemplateText = localize.getLocalizedString("_SaveAsDefaultTemplate_");
      var timeoutKey = null;
      scope.saveAsDefaultTemplate = function () {
        if (!scope.isSaveTemplateWarningMode) {
          toggleSaveAsDefaultMode(true);
          timeoutKey = $timeout(function () {
            toggleSaveAsDefaultMode(false);
          }, 15000);
        } else {
          var dummyPromotion = {};
          scope.contentResolverFunc.resolver.resolve(dummyPromotion);
          var messageToUpdate = dummyPromotion.ClientPromotionContent.PromotionSenderType === "mail" ? dummyPromotion.ClientPromotionContent.Subject : dummyPromotion.ClientPromotionContent.Message;
          scope.isSavingDefaultTemplate = true;
          Repository.Custom("PromotionsRepository")
            .updateTemplateMessage(scope.presetType.TemplateName, dummyPromotion.ClientPromotionContent.PromotionSenderType, {Message: messageToUpdate})
            .then(function () {
              toastr.success(localize.getLocalizedString('_SavingAsDefaultTemplateSuccesseded_'));
              scope.$evalAsync(() => {
                scope.isSavingDefaultTemplate = false;
                toggleSaveAsDefaultMode(false);
              });
            })
            .catch(function () {
              toastr.error(localize.getLocalizedString('_ErrorSavingTemplateAsDefault_'));
              scope.$evalAsync(() => {
                scope.isSavingDefaultTemplate = false;
                toggleSaveAsDefaultMode(false);
                scope.$digestIfNeeded();
              });
            });
        }
      };

      function toggleSaveAsDefaultMode(warningMode) {
        if (warningMode) {
          scope.isSaveTemplateWarningMode = true;
          scope.saveTemplateText = localize.getLocalizedString("_ApproveSavingAsTemplate_");
        } else {
          $timeout.cancel(timeoutKey);
          scope.isSaveTemplateWarningMode = false;
          scope.saveTemplateText = localize.getLocalizedString("_SaveAsDefaultTemplate_");
        }
      }

      if (scope.hideFacebookUser) {
        hideFacebookPromotion();
      }

      function hideFacebookPromotion() {
        scope.hideFacebookUser = true;
        scope.steps = [0, 1];
        scope.editors.pop();
        fixPositions();
      }

      function appendContentEditors() {
        wasTemplateAppended = true;
        const switchOrSwitchHide = scope.lazyMode === 'true' ? 'ng-switch' : 'ng-hide-switch';

        const toReturn = '<div class="relative-container" ' + switchOrSwitchHide + ' on="currentStep"\
                                    data-ng-class="{\'promotion-content-editors-right-to-left-step\': rightToLeftStep}">\
                                        <div class="absolute-container animate-switch" ' + switchOrSwitchHide + '-when="0">\
                                            <mail-editor content-resolver="mailContentResolver" default-customer="defaultCustomer"\
                                            preset="presetType" active="currentStep == 0" always-edit-mode="{{alwaysEditMode || true}}"></mail-editor>\
                                        </div>\
                                        <div class="absolute-container animate-switch" ' + switchOrSwitchHide + '-when="1">\
                                            <sms-editor content-resolver="smsContentResolver" always-edit-mode="alwaysEditMode" default-customer="defaultCustomer" preset="presetType"\
                                            active="currentStep == 1"></sms-editor>\
                                        </div>'
          + (!scope.hideWhatsapp ? `
                <div class="absolute-container animate-switch" ${switchOrSwitchHide}-when="2">
                    <whatsapp-editor content-resolver="whatsappContentResolver" always-edit-mode="alwaysEditMode" default-customer="defaultCustomer" preset="presetType"
                                            active="currentStep == 2"></whatsapp-editor>
                </div>
          ` : '')
          + (!scope.hideFacebookUser ? '<div class="absolute-container animate-switch" ' + switchOrSwitchHide + '-when="3">\
                        <facebook-editor content-resolver="facebookContentResolver" default-customer="defaultCustomer"\
                        preset="presetType" active="currentStep == 2" facebook-id="{{facebookId}}"></facebook-editor>\
                    </div>' : "") +
          '</div>';
        const compiled = $compile(toReturn)(scope);
        element.find('.content-editors-container').append(compiled);
      }
    }
  };
});
