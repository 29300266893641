class WizardController {
  constructor() {
    this._steps = [];
    this._currentStep = null;
    this._flowDirectionBackward = false;
  }

  get flowDirectionBackward() {
    return this._flowDirectionBackward;
  }

  get steps() {
    return this._steps
  }

  set steps(steps) {
    if (!this._currentStep) {
      this._currentStep = steps[0];
    }

    this._steps = steps;
  }

  get currentStepIdx() {
    return this._steps.indexOf(this.currentStep);
  }

  get hasNext() {
    return this.currentStepIdx < this._steps.length - 1;
  }

  get hasPrev() {
    return this.currentStepIdx > 0;
  }

  get currentStep() {
    return this._currentStep;
  }

  get store() {
    return this._store;
  }

  set store(newVal) {
    this._store = newVal;
  }

  set uploadDelegate(func) {
    this._uploadDelegate = func;
  }

  get uploadDelegate() {
    return this._uploadDelegate;
  }

  setValue(step, values) {
    step.selected = values;
  }

  next() {
    const currentStepIdx = this.currentStepIdx;
    if (currentStepIdx >= 0) {
      this._flowDirectionBackward = false;
      this._currentStep = this._steps[currentStepIdx + 1];
    }
  }

  prev() {
    const currentStepIdx = this.currentStepIdx;
    if (currentStepIdx > 0) {
      this._flowDirectionBackward = true;
      this._currentStep = this._steps[currentStepIdx - 1];
    }
  }

  skip() {
    this.currentStep.skipDelegate();
    this.next();
  }
}

class WizardStep {
  constructor(title, skipable = false) {
    this._parents = null;
    this._dataSource = null;
    this._title = title;
    this._options = [];
    this._viewType = 'tiles'; // tiles/table
    this._selectionType = 'single-select'; // single-select/multiple
    this._selected = [];
    this._skipable = skipable;
    this._parentItems = [];
    this._skipDelegate = () => {};
  }

  get title() {
    return this._title;
  }

  get parents() {
    return this._parents;
  }

  set parents(newVal) {
    this._parents = newVal;
  }

  get options() {
    return this._options;
  }

  set options(options) {
    this._options = options;
  }

  set viewType(newVal) {
    this._viewType = newVal;
  }

  get viewType() {
    return this._viewType;
  }

  get selectionType() {
    return this._selectionType;
  }

  set selectionType(iSelectionType) {
    this._selectionType = iSelectionType;
  }

  get selected() {
    return this._selected;
  }

  set selected(values) {
    this._selected = values;
    if (this._delegate) {
      this._delegate(this, this._selected);
    }
  }

  set delegate(delegate) {
    this._delegate = delegate;
  }

  get autoProgressToNextStep() {
    return this.viewType === 'tiles' && this.selectionType === 'single-select';
  }

  set dataSource(ds) {
    this._dataSource = ds;
  }

  get dataSource() {
    return this._dataSource;
  }

  get skipable() {
    return this._skipable;
  }

  set skipDelegate(delegate) {
    this._skipDelegate = delegate;
  }

  get skipDelegate() {
    return this._skipDelegate;
  }

  set parentItems(newVal) {
    this._parentItems = newVal;
  }

  get parentItems() {
    return this._parentItems;
  }
}

export { WizardStep };

export default WizardController;
