(function () {
  const PRELOAD_IMAGES = ['https://files.easybizy.net/files/__feedback/public/review.png', 'https://files.easybizy.net/files/__feedback/public/review_man.png']


  angular.module('easybizy.dashboard').directive('preFetchImages', ($rootScope) => {
    return {
      link: function (scope, element, attrs) {

        $rootScope.$on('loadImagesToCache', (_, images) => {
          scope.images.pushAll(images);
        })


        scope.images = PRELOAD_IMAGES;
      },
      template: `<div style="position: absolute; top: 0; left: 0; visibility: hidden; height: 0; width: 0; overflow: hidden; z-index: -1">
                     <img src="{{ imageUrl }}" ng-repeat="imageUrl in images"/>
                 </div>`
    }
  })

}());
