var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard-wrapper" }, [
    _c("div", { staticClass: "top-part" }, [
      _c("h2", [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "bottom-part" }, [_vm._t("default")], 2),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "actions-part", class: _vm.enabledActionsClass },
      [
        _c(
          "transition-group",
          {
            staticClass: "actions-wrapper-of-buttons",
            attrs: { name: "action", tag: "div", mode: "out-in" },
          },
          _vm._l(_vm.actions, function (action) {
            return _c(
              "button",
              {
                key: action.name,
                class: action.class,
                on: { click: action.action },
              },
              [_vm._v("\n        " + _vm._s(action.name) + "\n      ")]
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }