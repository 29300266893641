'use strict';

(function () {
  if (!Object.values) {
    Object.values = o => Object.keys(o).map(k => o[k]);
  }

  Object.getSafely = (p, obj) => {
    if (!p || !obj || !Array.isArray(p)) {
      return null;
    }

    return p.reduce((xs, x) => {
      return ((xs && xs[x]) ? xs[x] : null);
    }, obj);
  };

}());
