import './custom.item.dialog.less'
import { DelayedFunction, nullUndefinedOrEmpty } from "../../../general/helpers";
import {
  DATE_DISPLAY_FORMAT,
  HOURS_AND_MINUTES_FORMAT,
  ODATA_DATE_TIME_FORMAT
} from "../../../constants";

angular.module('easybizy.cash.register').controller('CashRegisterCustomItemDialogController',
  function ($scope, $modalInstance, type, value, saveAction, header, titles, localize, Repository, $compile) {
    $scope.type = type;
    $scope.value = value;
    $scope.header = header;
    $scope.isSavableWrapper = { savable: true };
    $scope.valueToSaveWrapper = { value: value, baseValue: value };
    $scope.validatorWrapper = { validate: null };
    $scope.topClassWrapper = { classes: '' };
    $scope.titles = titles;

    $scope.save = function () {
      if ($scope.validatorWrapper.validate && !$scope.validatorWrapper.validate()) {
        return;
      }

      var result = saveAction($scope.valueToSaveWrapper.value);
      if (result) {
        $scope.cancel();
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    if ($scope.type === 'pre-payment') {
      $scope.prePaymentTemplates = [{ name: localize.getLocalizedString("_SubscriptionTemplate_") }];
      $scope.selectedCategoriesWrapper = { selected: null };
      $scope.$watch('selectedCategoriesWrapper.selected', updatePrePaymentByTemplate);

      Repository.Entity("PrePaymentTemplate").query().get()
        .then(function (res) {
          if (res.results) {
            res.results.forEach(function (template) {
              $scope.prePaymentTemplates.push({
                name: template.Name,
                title: template.Title,
                value: template.Price,
                id: template.PrePaymentTemplateId
              });

            });
          }
        });

      $scope.addNewCategoryAction = function (category) {
        let val = $scope.valueToSaveWrapper.value;
        var template = {
          Name: category.name,
          Title: val.name,
          Price: val.price
        }

        Repository.Entity("PrePaymentTemplate").create(template).post()
          .then(function (res) {
            let newTemplate = $scope.prePaymentTemplates.find(x => x.name === res.Name);
            newTemplate.title = res.Title,
              newTemplate.value = res.Price,
              newTemplate.id = res.PrePaymentTemplateId
          });
      };

      var deleteText = localize.getLocalizedString('_Delete_');
      var approvalText = localize.getLocalizedString('_Sure_');
      $scope.decorateTemplateCombo = function (scope, element) {
        var templateComboItem = scope.item;
        if (templateComboItem && templateComboItem.id) {
          scope.deleteText = deleteText;
          scope.approvalText = approvalText;

          var submitButton = '<div ng-click="removeTemplate($event, item)">{{approvalMode ? approvalText : deleteText}}</div>';

          scope.removeTemplate = function (e, item) {
            e.preventDefault();
            e.stopPropagation();

            if (!scope.approvalMode) {
              scope.approvalMode = true;
              return $timeout(function () {
                delete scope.approvalMode;
              }, 10000);
            }

            $scope.prePaymentTemplates.splice($scope.prePaymentTemplates.indexOf(item), 1);
            Repository.Entity("PrePaymentTemplate").remove(item.id)
              .catch(function () {
                toastr.error(localize.getLocalizedString('_ErrorDeletingTemplate_'));
              });
          };

          var decorationItem = '<div class="combobox-template-remove-decorator">' + submitButton + '</div>';
          element.find('.combobox-list-item-concrete-value').append($compile(decorationItem)(scope));
        }
      };

      function updatePrePaymentByTemplate(template) {
        if (template && template.title) {
          $scope.valueToSaveWrapper.value.name = template.title;
          $scope.valueToSaveWrapper.value.price = template.value;
        }
      }
    }

  });


angular.module('easybizy.cash.register').directive('pointsUsage', function (localize) {
  return {
    scope: {
      points: '=',
      savableWrapper: '=',
      valueToSaveWrapper: '=',
      titles: '='
    },
    link: function (scope) {
      scope.viewTitles = {
        header: (scope.titles && scope.titles.header) || localize.getLocalizedString('_PointsToUse_'),
        useTitle: (scope.titles && scope.titles.useTitle) || localize.getLocalizedString('_UsePoints_'),
        useIcon: (scope.titles && scope.titles.useIcon) || 'icon icon-cash-out'
      };

      scope.pointsAvailable = scope.points;
      scope.pointsToUseWrapper = { value: scope.points.toFixed(2).replace(/\.?0+$/, '') };
      scope.$watch('pointsToUseWrapper.value', function (newVal) {
        if (scope.savableWrapper) {
          scope.savableWrapper.savable = newVal > 0;
        }

        scope.valueToSaveWrapper.value = scope.valueToSaveWrapper.value ? Object.assign(scope.valueToSaveWrapper.value, { amount: newVal }) : { amount: newVal };
      })
    },
    template: require('./points.usage.tpl.html')
  }
});

angular.module('easybizy.cash.register').directive('tipUsage', function (localize) {
  return {
    scope: {
      points: '=',
      savableWrapper: '=',
      valueToSaveWrapper: '=',
      titles: '='
    },
    link: function (scope) {
      scope.max = Number.MAX_SAFE_INTEGER;
      scope.min = 1;
      scope.placeholder = '_Tip_';
      scope.valueToSaveWrapper.value = scope.valueWrapper = { name: 'tip', price: 1 };
    },
    template: require('./tip.tpl.html')
  }
});

angular.module('easybizy.cash.register').directive('genericProduct', function (localize, Repository) {
  return {
    scope: {
      valueToSaveWrapper: '=',
      savableWrapper: '=',
      type: '='
    },
    link: function (scope) {
      switch (scope.type) {
        case 'generic-product':
          scope.max = Number.MAX_SAFE_INTEGER;
          scope.min = 0;
          scope.placeholder = '_ProductNameOnInvoice_';
          break;
        case 'pre-payment':
          scope.max = Number.MAX_SAFE_INTEGER;
          scope.min = 0;
          scope.placeholder = '_PrePayment_';
          break;
        case 'refund-product':
          scope.min = -Number.MAX_SAFE_INTEGER;
          scope.max = 0;
          scope.placeholder = '_RefundFor_';
          scope.$watch('valueWrapper.name', function (newVal) {
            scope.savableWrapper.savable = newVal.length > 0;
          });

          break;
        case 'gift-card':
          scope.min = 1;
          scope.max = Number.MAX_SAFE_INTEGER;
          scope.placeholder = '_GiftCardNameOrDescription_';
          scope.$watch('valueWrapper.name',
            function (newVal) {
              scope.savableWrapper.savable = newVal.length > 0;
            });
      }

      scope.valueToSaveWrapper.value = scope.valueWrapper = { name: '', price: 0 };
    },
    template: require('./generic.product.tpl.html')
  }
});

angular.module('easybizy.cash.register').directive('giftCard', function (Repository, localize, configuration, contextManager, confirmService, $compile, $timeout) {
  return {
    scope: {
      valueToSaveWrapper: '=',
      savableWrapper: '=',
      type: '=',
      topClassWrapper: '=',
      validatorWrapper: '='
    },
    link: function (scope) {
      scope.templates = [];
      scope.simpleModeWrapper = { designed: true };
      const configurations = configuration.get();
      const businessInfo = {
        name: configurations.BusinessDetails.BusinessName.Value,
        phone: configurations.BusinessDetails.BusinessPhoneNumber.Value,
        address: configurations.BusinessDetails.BusinessAddress.Value,

        /// TODO: Remarks from Configurations.
      };

      scope.toggleMode = (mode) => {
        scope.topClassWrapper.classes = !mode ? 'simple-mode' : '';
        scope.valueWrapper.name = '';
      };

      Repository.Custom('General').giftCardTemplates().then(({ data }) => {
        scope.templates = data.map(x => ({
          name: x['name-he-IL'],
          value: x.key,
          url: `${window.giftCardsIframeURI}/templates/${x.key}`
        }));

        scope.valueWrapper.template = scope.templates[0];
        updateUrl(true);
      }).catch((err) => {
        toastr.error(localize.getLocalizedString('_CantLoadGiftCardTemplates_'));
      });

      scope.min = 1;
      scope.max = Number.MAX_SAFE_INTEGER;
      scope.placeholders = {
        name: localize.getLocalizedString('_GiftCardFor_'),
        giver: localize.getLocalizedString('_GiftCardFrom_'),
        greating: localize.getLocalizedString('_GiftCardGreating_'),
        phoneNumberToSend: localize.getLocalizedString('_Mobile_'),
        remarks: localize.getLocalizedString('_Remarks_'),
        smsMessage: localize.getLocalizedString('_GiftCardSmsMessage_', businessInfo.name)
      };


      scope.validatorWrapper.validate = () => {
        const mapped = { ...scope.valueToSaveWrapper.value };
        delete mapped.dirty;
        var mandatory = ['name', 'price'];
        const invalid = Object.keys(mapped).filter(key => mandatory.indexOf(key) >= 0 && nullUndefinedOrEmpty(mapped[key]));

        if (invalid.length > 0) {
          toastr.error(localize.getLocalizedString('_AllFieldsAreMandatoryUseSimpleMode_'));
          return false;
        } else {
          return true;
        }

      };

      $(document).on('swipe.pos.card', onSwipeCard);

      scope.$on('$destroy', function () {
        $(document).off('swipe.pos.card')
      });

      var validatedDigitalCard = true;
      var delayedUpdateTemplate = new DelayedFunction((value) => scope.$evalAsync(() => scope.templateUrl = encodeURI(value)), 1000);


      scope.$watch('selectedTimeWrapper', updateValueToSave, true);

      scope.$watch('valueWrapper', (newVal, oldValue) => {
        if (!newVal || !scope.valueWrapper.template) {
          return '';
        }

        updateUrl(!oldValue || !oldValue.template || newVal.template.url !== oldValue.template.url);
      }, true);


      scope.promotionTiming = [
        {
          name: localize.getLocalizedString("_Now_"),
          value: null
        },
        { name: localize.getLocalizedString("_PickATime_") + '...', value: 'pickATime' }
      ];

      scope.selectedPromotionTiming = {};
      scope.selectedPromotionTiming.value = scope.promotionTiming[0];
      scope.selectedTimeWrapper = { sendingDate: moment().format(DATE_DISPLAY_FORMAT) };
      scope.sendingDateMeta = {
        fieldName: 'sendingDate',
        icon: "icon icon-calendar",
        placeholder: localize.getLocalizedString('_ChooseDate_'),
        validation: "{'required': true}",
        yearRange: ":+1",
        minDate: "0",
        type: 'date'
      };

      scope.timeOptions = [];
      const TIME_DELTA = 30;
      calculateTimeOptions();


      contextManager.get('customer').then(function (customer) {
        initModel(customer);
      }).catch(() => {
        initModel();
      });

      function initModel(customer) {
        scope.valueWrapper = {
          name: localize.getLocalizedString('_GiftCardDefaultName_'),
          giver: localize.getLocalizedString('_GiftCardDefaultFrom_', (customer ? `${customer.FirstName} ${customer.LastName}` : '')),
          greating: localize.getLocalizedString('_GiftCardDefaultGreating_'),
          allowOnlineScheduling: configurations.GeneralSettings.EnableOnlineScheduling,
          smsMessageContent: scope.placeholders.smsMessage,
          showPrice: false,
          isDigital: false,
          phoneNumber: '',
          remarks: '',
          price: 100, digitalPass: null
        };

        updateValueToSave();
      }

      function calculateTimeOptions() {
        let startTime = moment('08:00', HOURS_AND_MINUTES_FORMAT);
        let endTime = startTime.clone().set({ 'hours': 23, 'minute': 30, 'second': 0 });
        const midnight = ' (' + localize.getLocalizedString('_AtNight_') + ')';
        const relativeNow = moment();

        while (startTime.isSameOrBefore(endTime)) {
          var time = startTime.format(HOURS_AND_MINUTES_FORMAT);
          var hourAsInt = parseInt(startTime.format('HH'));

          var valueToInsert = {
            name: time + ((hourAsInt > 22 || hourAsInt < 8) ? midnight : ''),
            value: startTime.clone().date(relativeNow.date())
          };

          scope.timeOptions.push(valueToInsert);
          let startToRelativeNowDiff = moment.duration(Math.abs(relativeNow.diff(startTime))).asMinutes();
          if (!scope.selectedTimeWrapper.selected && startToRelativeNowDiff <= TIME_DELTA && !startTime.isBefore(relativeNow)) {
            scope.selectedTimeWrapper.selected = valueToInsert;
          }

          startTime = startTime.add(TIME_DELTA, 'm');
          // Find the closest time to relativeNow.

        }
      }

      function updateUrl(force) {
        const url =
          scope.valueWrapper.template.url + `?amount=${scope.valueWrapper.price}&top_title=${scope.valueWrapper.name}&giver=${scope.valueWrapper.giver}&greating=${scope.valueWrapper.greating}&show_price=${scope.valueWrapper.showPrice}` +
          Object.keys(businessInfo).reduce((acc, key) => acc + `&${key}=${businessInfo[key]}`, '') + `&online_scheduling=${scope.valueWrapper.allowOnlineScheduling ? 'true' : 'false'}&remarks=${scope.valueWrapper.remarks || ''}`;

        if (force || !delayedUpdateTemplate.get()) {
          delayedUpdateTemplate.setForce(url);
        } else {
          delayedUpdateTemplate.set(url);
        }

        updateValueToSave();
      }

      function onSwipeCard(event) {
        validatedDigitalCard = false;
        scope.savableWrapper.savable = false;
        scope.valueWrapper.digitalPass = event.code;
        scope.isDigitalWrapper.isDigital = true;
        delete scope.valueWrapper.force;
        validateExistingCode(event.code);
      }

      function updateValueToSave() {
        if (!scope.valueWrapper) {
          return;
        }

        scope.valueToSaveWrapper.value = {
          name: scope.valueWrapper.name,
          price: scope.valueWrapper.price
        };

        if (scope.simpleModeWrapper.designed) {
          scope.valueToSaveWrapper.value = {
            ...scope.valueToSaveWrapper.value,
            template: scope.valueWrapper.template ? scope.valueWrapper.template.value : null,
            giver: scope.valueWrapper.giver,
            greating: scope.valueWrapper.greating,
            remarks: scope.valueWrapper.remarks,
            showPrice: scope.valueWrapper.showPrice,
            smsSendTime: moment().format(ODATA_DATE_TIME_FORMAT),
            allowOnlineScheduling: scope.simpleModeWrapper.designed ? !!scope.valueWrapper.allowOnlineScheduling : false
          }
        }

        if (scope.valueWrapper.isDigital) {
          scope.valueToSaveWrapper.value.digitalPass = scope.valueWrapper.digitalPass;
        }

        if (scope.simpleModeWrapper.designed && scope.valueWrapper.sendBySms) {
          scope.valueToSaveWrapper.value.phoneNumber = scope.valueWrapper.phoneNumber.replace(/[^0-9]/g, '');
          scope.valueToSaveWrapper.value.smsMessageContent = scope.valueWrapper.smsMessageContent;
          if (scope.selectedPromotionTiming.value.value === 'pickATime') {
            let adaptedStartTime = moment(scope.selectedTimeWrapper.sendingDate, DATE_DISPLAY_FORMAT);
            adaptedStartTime = adaptedStartTime.set({
              'hours': scope.selectedTimeWrapper.selected.value.hours(),
              'minutes': scope.selectedTimeWrapper.selected.value.minutes(),
              'seconds': 0
            });

            scope.valueToSaveWrapper.value.smsSendTime = adaptedStartTime.format(ODATA_DATE_TIME_FORMAT);
          }
        } else {
          delete scope.valueToSaveWrapper.value.sendBySms;
          delete scope.valueToSaveWrapper.value.phoneNumber;
          delete scope.valueToSaveWrapper.value.smsSendTime;
        }

      }

      function validateExistingCode(code) {
        /*Repository.validateCode*/

        // If invalid/exists:
        confirmService.confirm(localize.getLocalizedString('_ExistingBalance_', 250/*exisiting balance*/),
          null, function () {
            scope.$apply(function () {
              validatedDigitalCard = true;
              scope.valueWrapper.force = true;

            });
          });

      }


      //--------------------------------------------- templates --------------------------------------------//
      scope.$watch('selectedInfoCategoriesWrapper.selected', updateGiftCardByTemplate);
      scope.giftCardsTemplates = [{ name: localize.getLocalizedString("_SubscriptionTemplate_") }];

      scope.selectedInfoCategoriesWrapper = { selected: scope.giftCardsTemplates[0] };

      scope.addNewCategoryAction = function (category) {
        var template = {
          title: scope.valueWrapper.name,
          name: category.name,
          greating: scope.valueWrapper.greating,
          allowOnlineScheduling: scope.valueWrapper.allowOnlineScheduling && configurations.GeneralSettings.EnableOnlineScheduling,
          smsMessageContent: scope.valueWrapper.smsMessageContent,
          template: scope.valueWrapper.template.value,
          showPrice: scope.valueWrapper.showPrice,
          price: scope.valueWrapper.price,
          remarks: scope.valueWrapper.remarks
        }

        Repository.Custom("CommonGiftCards").post(template)
          .then(function (res) {
            let newTemplate = scope.giftCardsTemplates.find(x => x.name === res.Name);
            newTemplate.value = res;
            newTemplate.id = res.GiftCardTemplateId;
          });
      };


      Repository.Custom("CommonGiftCards").get()
        .then(function (res) {
          if (res.length > 0) {
            scope.giftCardsTemplates = [];
            res.forEach(function (template) {
              scope.giftCardsTemplates.push({
                name: template.Name,
                value: template,
                id: template.GiftCardTemplateId
              });
            });
          }
        });

      function updateGiftCardByTemplate(template) {
        if (template && template.value && template.value.TotalAmount) {
          scope.onTemplateUpdate = true;
          scope.valueWrapper.name = template.value.Title;
          scope.valueWrapper.greating = template.value.Greating;
          scope.valueWrapper.allowOnlineScheduling = template.value.AllowOnlineScheduling;
          scope.valueWrapper.smsMessageContent = template.value.SmsMessageContent || (localize.getLocalizedString('_GiftCardSmsMessage_', businessInfo.name));
          scope.valueWrapper.template = scope.templates.find(x => x.value === template.value.Template);
          scope.valueWrapper.showPrice = template.value.ShowPrice;
          scope.valueWrapper.price = template.value.TotalAmount;
          scope.valueWrapper.remarks = template.value.Remarks;
          $timeout(() => {
            scope.onTemplateUpdate = false;
          });
        }
      }

      var deleteText = localize.getLocalizedString('_Delete_');
      var approvalText = localize.getLocalizedString('_Sure_');
      scope.decorateTemplateCombo = function ($scope, element) {
        var templateComboItem = $scope.item;
        if (templateComboItem && templateComboItem.id) {
          $scope.deleteText = deleteText;
          $scope.approvalText = approvalText;

          var submitButton = '<div ng-click="removeTemplate($event, item)">{{approvalMode ? approvalText : deleteText}}</div>';

          $scope.removeTemplate = function (e, item) {
            e.preventDefault();
            e.stopPropagation();

            if (!$scope.approvalMode) {
              $scope.approvalMode = true;
              return $timeout(function () {
                delete $scope.approvalMode;
              }, 10000);
            }

            scope.giftCardsTemplates.splice(scope.giftCardsTemplates.indexOf(item), 1);
            Repository.Custom("CommonGiftCards").remove(item.id)
              .catch(function () {
                toastr.error(localize.getLocalizedString('_ErrorDeletingTemplate_'));
              });
          };

          var decorationItem = '<div class="combobox-template-remove-decorator">' + submitButton + '</div>';
          element.find('.combobox-list-item-concrete-value').append($compile(decorationItem)($scope));
        }
      };

    },
    template: require('./gift.card.tpl.html')
  };
});

angular.module('easybizy.cash.register').directive('giftCardUsage', function (Repository, localize) {
  return {
    scope: {
      savableWrapper: '=',
      valueToSaveWrapper: '=',
      maxValue: '='
    },
    link: function (scope) {
      scope.giftCard = { number: '' };
      scope.savableWrapper.savable = false;
      scope.giftCardWrapper = { value: null };
      scope.titlesForGiftCardUsage = {
        header: localize.getLocalizedString('_MoneyToUse_'),
        useTitle: localize.getLocalizedString('_UseInThisPurchase_'),
        useIcon: 'icon icon-gift-card-usage'
      };

      scope.search = function () {
        if (scope.giftCard.number) {
          scope.loading = true;
          Repository.Entity("GiftCard", true).query().filter("GiftCardReference eq '" + scope.giftCard.number + "' and TotalAmount gt AmountUsed").get().then(function (res) {
            if (res && res.value.length) {
              scope.loading = false;
              var result = res.value[0];
              scope.foundGiftCard = result;
              scope.valueToSaveWrapper.value = { GiftCardId: scope.foundGiftCard.GiftCardId };
              scope.giftCardWrapper.value = Math.min(parseFloat(scope.maxValue), result.TotalAmount - result.AmountUsed);
              if (scope.giftCardWrapper.value == 0) {
                toastr.warning(localize.getLocalizedString('_GiftCardFullyUsed_'));
              }
            } else {
              toastr.error(localize.getLocalizedString('_GiftCardNotFound_'));
            }
            scope.$digestIfNeeded();
          }).catch(function (err) {
            toastr.error(localize.getLocalizedString("_ErrorLookingUpGiftCard_"));
            scope.loading = false;
            console.log(err);
          });
        }
      };

    },
    template: require('./gift.card.usage.tpl.html')
  };
});

angular.module('easybizy.cash.register').directive('externalGiftCardUsage', function (Repository, localize) {
  return {
    scope: {
      savableWrapper: '=',
      valueToSaveWrapper: '=',
      maxValue: '=',
      selectedGiftCard: '='
    },
    link: function (scope, element) {
      scope.searchWrapper = { name: '' };
      scope.giftCardWrapper = { value: null };
      scope.savableWrapper.savable = false;
      scope.giftCardWrapper = { value: null };
      scope.titlesForGiftCardUsage = {
        header: localize.getLocalizedString('_MoneyToUse_'),
        useTitle: localize.getLocalizedString('_UseInThisPurchase_'),
        useIcon: 'icon icon-gift-card-usage'
      };

      scope.vouchersDidLoad = false;
      var externalVoucherByVendorType = {};
      Repository.Entity('ExternalVoucherMetadata').query().filter('Hidden eq false').expand('Images,ExternalVoucherVendor,ServiceMetadatas').get()
        .then(function (result) {
          result.value.forEach(function (externalVoucher) {
            externalVoucherByVendorType[externalVoucher.ExternalVoucherVendor.ExternalVoucherVendorName] = externalVoucherByVendorType[externalVoucher.ExternalVoucherVendor.ExternalVoucherVendorName] || [];
            externalVoucherByVendorType[externalVoucher.ExternalVoucherVendor.ExternalVoucherVendorName].push(externalVoucher);
            externalVoucher.Price = (parseFloat(externalVoucher.Price) - parseInt(externalVoucher.Price)) > 0 ? externalVoucher.Price : parseInt(externalVoucher.Price);

            if (externalVoucher.ExternalVoucherMetadataId === scope.selectedGiftCard) {
              scope.selectGiftCard(externalVoucher);
              scope.forcedMode = true;
            }
          });

          scope.vouchersDidLoad = true;
          renderItems('');
        })
        .catch(function () {
          toastr.error(localize.getLocalizedString('_ErrorLoadingVouchersVendors_'));
        });

      function renderItems(searchTerm) {
        if (!scope.vouchersDidLoad) {
          return;
        }

        scope.vendors = [];
        var regex = new RegExp(searchTerm, "i");
        for (var vendorName in externalVoucherByVendorType) {
          var vouchers = externalVoucherByVendorType[vendorName].filter(function (voucher) {
            return voucher.ExternalVoucherName.search(regex) >= 0;
          });

          // ALSO SHOW VENDORS NAME!
          if (vouchers.length > 0 || vendorName.search(regex) >= 0) {
            scope.vendors.push({
              name: vendorName,
              vouchers: vouchers
            });
          }
        }
      }

      scope.voucherMeta = { remarks: '', refNumber: '' };


      Object.defineProperty(scope.valueToSaveWrapper, 'value', {
        get: function () {
          return Object.assign({}, scope.voucherMeta, scope.giftCardWrapper.value);
        }
      });

      scope.$watch('searchWrapper.name', function (newVal) {
        if (!angular.isDefined(newVal)) {
          return;
        }

        renderItems(newVal);
        scope.giftCardWrapper.value = null;
        scope.savableWrapper.savable = false;

      });

      scope.selectGiftCard = function (giftCard) {
        if (!scope.forcedMode) {
          scope.giftCardWrapper.value = giftCard;
          scope.savableWrapper.savable = true;
          // setTimeout(function() {
          //   element.find('.ref-number input').focusin();
          // }, 300)
        }
      }

    },
    template: require('./external.gift.card.usage.tpl.html')
  }
});
