import {
  COLUMN_EDITOR_TYPE, GRID_FILTER_TYPE,
  GridApiResult,
  GridColumn,
  LazyGridDataSource,
  DatePickerLabels,
  SortByColumn
} from "@tomeravni/easybizy-vue-components";

import Moment from 'moment';

import { isNonEmptyArray } from "@tomeravni/easybizy-js-common/common";
import {
  ODATA_DATE_TIME_FORMAT,
  SERVER_DATA_DATE_FORMAT,
  DATE_DISPLAY_FORMAT,
  RTL_DATE_TIME_DISPLAY
} from "../../constants";

export class CustomerVisitHistoryDS extends LazyGridDataSource {
  constructor(api, localize, stateManager, mediator, Repository, printerMediator) {
    super();
    this._api = api;
    const visitDate = new GridColumn('Date', localize('_Date_'), COLUMN_EDITOR_TYPE.LAZY);
    visitDate.width = 240;
    visitDate.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    visitDate.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    visitDate.freeze = true;
    visitDate.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', Moment(entity.Date, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT));
    };

    const invoiceIndex = new GridColumn('InvoiceIndex', localize('_InvoiceIndex_'), COLUMN_EDITOR_TYPE.LAZY);
    invoiceIndex.width = 120;

    const meetingServiceName = new GridColumn('Services', localize('_Services_'), COLUMN_EDITOR_TYPE.LAZY);
    meetingServiceName.width = 200;

    const totalAmount = new GridColumn('TotalAmount', localize('_Paid_'), false, true);
    totalAmount.width = 100;

    const debt = new GridColumn('DebtAmount', localize('_DebtOrPrePayment_'), false, true);
    debt.width = 200;

    const balance = new GridColumn('Balance', localize('_CustomerBalance_'), false, true);
    balance.width = 200;

    this.columns = [
      visitDate,
      invoiceIndex,
      meetingServiceName,
      totalAmount,
      debt,
      balance
    ]

    this._sortByColumn = new SortByColumn(visitDate, true);

    mediator.exportToExcel = () => {
      this._api.export()
    };

    mediator.print = () => {
      let dictionary = Object.assign({}, ...this.columns.filter((c) => { return c.isVisible }).map((column) => ({
        [column.fieldName]: {
          Label: column.label,
          ToStringFormat: column.fieldName === 'Date' ? "dd/MM/yyyy" : null
        }
      })));
      this._api.print(dictionary).then(function (response) {
        printerMediator.print(response);
      })
        .catch(function (err) {
        });
    };
  }


  getItemId(item) {
    return item.Date;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  get api() {
    return this._api;
  }

  loadOnRemote(top, skip, sort, filter) {
    if (isNonEmptyArray(filter)) {
      let rangeFilter = filter.find((x) => x.column.fieldName === 'Date')
      if (rangeFilter) {
        var range = filter.find((x) => x.column.fieldName === 'Date').filterValue
        this.from = moment(range[0]);
        this.to = range[1] != null ? moment(range[1]) : moment(range[0]).endOf('day');
      }
    }

    this._api.from(this.from.format(ODATA_DATE_TIME_FORMAT));
    this._api.to(this.to.format(ODATA_DATE_TIME_FORMAT));
    this._api.top(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }

    return this._api.get().then((result) => {
      return new GridApiResult(result.map((item) => {
        return item;
      }),
        top,
        skip,
        null);
    });
  }
}

export class CustomerMeetingsDS extends LazyGridDataSource {
  constructor(api, localize, stateManager, mediator, Repository, printerMediator) {
    super();
    this._api = api;

    const meetingStartTime = new GridColumn('MeetingStartTime', localize('_StartTime_'), COLUMN_EDITOR_TYPE.LAZY);
    meetingStartTime.width = 150;
    meetingStartTime.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    meetingStartTime.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    meetingStartTime.freeze = true;
    meetingStartTime.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', Moment(entity.MeetingStartTime, SERVER_DATA_DATE_FORMAT).format(RTL_DATE_TIME_DISPLAY));
    };

    const meetingEmployeeName = new GridColumn('MeetingEmployeeName', localize('_EmployeeName_'), COLUMN_EDITOR_TYPE.LAZY);
    meetingEmployeeName.width = 150;
    meetingEmployeeName.filterType = GRID_FILTER_TYPE.MULTI_SELECT;

    Repository.Entity("Employee").query().filter('VisibleOnCalendar eq true').get().then(({ value }) => {
      const employees = value.map((employee) => ({
        text: employee.FirstName + " " + employee.LastName,
        value: employee.EmployeeId
      }));

      meetingEmployeeName.options = [...employees];
      meetingEmployeeName.filterArgs = {
        placeholder: localize('_Employee_'),
        options: employees.map((employee) => ({
          text: employee.text,
          value: `MeetingEmployeeId eq ${employee.value}`
        }))
      };
    });

    const meetingServiceName = new GridColumn('Services', localize('_Services_'), COLUMN_EDITOR_TYPE.LAZY);
    meetingServiceName.width = 200;

    const meetingRemarks = new GridColumn('Remarks', localize('_Remarks_'), COLUMN_EDITOR_TYPE.LAZY);
    meetingRemarks.width = 200;

    const paid = new GridColumn('Paid', localize('_Status_'), COLUMN_EDITOR_TYPE.LAZY);
    paid.width = 100;
    paid.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.Paid ? localize('_Paid_') : localize('_NotPaid_'));
    };
    paid.filterType = GRID_FILTER_TYPE.SINGLE_SELECT;
    const paidStatuses = [{ text: localize('_Paid_'), value: true }, { text: localize('_NotPaid_'), value: false }];
    paid.options = paidStatuses;
    paid.filterArgs = {
      placeholder: localize('_All_'),
      options: paidStatuses.map((status) => ({
        text: status.text,
        value: `Paid eq ${status.value}`
      }))
    };

    const createdOn = new GridColumn('MeetingCreatedOn', localize('_CreatedOn_'), COLUMN_EDITOR_TYPE.LAZY);
    createdOn.width = 150;
    createdOn.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    createdOn.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true }
    createdOn.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', Moment(entity.MeetingCreatedOn, SERVER_DATA_DATE_FORMAT).format(RTL_DATE_TIME_DISPLAY));
    };

    this.columns = [
      meetingStartTime,
      meetingEmployeeName,
      paid,
      meetingServiceName,
      meetingRemarks,
      createdOn
    ]

    this._sortByColumn = new SortByColumn(meetingStartTime, true);

    mediator.exportToExcel = () => {
      this._api.export()
    };

    mediator.print = () => {
      let dictionary = Object.assign({}, ...this.columns.filter((c) => { return c.isVisible }).map((column) => ({
        [column.fieldName]: {
          Label: column.fieldName === 'Paid' ? localize('_Paid_') : column.label,
          ToStringFormat: ['MeetingStartTime', 'MeetingCreatedOn'].includes(column.fieldName) ? "dd/MM/yyyy HH:MM" : null
        }
      })));
      this._api.print(dictionary).then(function (response) {
        printerMediator.print(response);
      })
        .catch(function (err) {
        });
    };
  }

  /**
   * !Important to return the correct item.id
   * @param item
   * @returns {string}
   */
  getItemId(item) {
    return item.MeetingId + "_" + item.MeetingStartTime;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  get api() {
    return this._api;
  }

  filterChange(filter) {
    super.filterChange(this.adaptFiltersToQuery(filter));
  }

  adaptFiltersToQuery(filter) {
    const adaptedFilters = filter.concat(this._constantFilters || []).map((x) => {
      if (x.column.fieldName !== 'MeetingStartTime') {
        if (['MeetingCreatedOn'].includes(x.column.fieldName) && x.filterValue.length) {
          return adaptDateRangeQuery(x);
        } else if (isNonEmptyArray(x.filterValue) && x.filterValue.length > 1) {
          return `(${x.filterValue.join(' or ')})`;
        } else {
          return x.filterValue;
        }
      }
    }).filter((x) => x);
    return adaptedFilters;
  }
  
  loadOnRemote(top, skip, sort, filter) {
    if (isNonEmptyArray(filter)) {
      let rangeFilter = filter.find((x) => x.column.fieldName === 'MeetingStartTime')
      if (rangeFilter) {
        var range = filter.find((x) => x.column.fieldName === 'MeetingStartTime').filterValue
        this.from = moment(range[0]);
        this.to = range[1] != null ? moment(range[1]) : moment(range[0]).endOf('day');
        filter.remove(rangeFilter)
      }
    }

    this._api.from(this.from.format(ODATA_DATE_TIME_FORMAT));
    this._api.to(this.to.format(ODATA_DATE_TIME_FORMAT));
    this._api.top(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }
    const adaptedFilters = this.adaptFiltersToQuery(filter);
    this._api.filter(adaptedFilters);

    return this._api.get().then((result) => {
      return new GridApiResult(result.map((item) => {
        return item;
      }),
        top,
        skip,
        null);
    });
  }
}


export class CustomerInvoicesDS extends LazyGridDataSource {
  constructor(api, localize, stateManager, mediator, Repository, printerMediator) {
    super();
    this._api = api;

    const documentDate = new GridColumn('Date', localize('_Date_'), COLUMN_EDITOR_TYPE.LAZY);
    documentDate.width = 240;
    documentDate.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    documentDate.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    documentDate.freeze = true;
    documentDate.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', Moment(entity.Date, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT));
    };

    const documentType = new GridColumn('DocumentType', localize('_DocumentType_'), COLUMN_EDITOR_TYPE.LAZY);
    documentType.width = 100;

    const documentNumber = new GridColumn('DocumentNumber', localize('_InvoiceIndex_'), COLUMN_EDITOR_TYPE.LAZY);
    documentNumber.width = 100;

    const details = new GridColumn('Details', localize('_Details_'), COLUMN_EDITOR_TYPE.LAZY);
    details.width = 200;

    const totalAmount = new GridColumn('TotalAmount', localize('_Paid_'), false, true);
    totalAmount.width = 100;

    const remarks = new GridColumn('Remarks', localize('_Remarks_'), false, true);
    remarks.width = 200;

    const payments = new GridColumn('Payments', localize('_Payments_'), false, true);
    payments.width = 200;

    const debt = new GridColumn('DebtAmount', localize('_Debt_'), false, true);
    debt.width = 100;

    const prepayment = new GridColumn('PrePaymentAmount', localize('_PrePayment_'), false, true);
    prepayment.width = 100;


    this.columns = [
      documentDate,
      documentType,
      documentNumber,
      totalAmount,
      details,
      payments,
      remarks,
      debt,
      prepayment
    ]

    this._sortByColumn = new SortByColumn(documentDate, true);

    mediator.exportToExcel = () => {
      this._api.export()
    };

    mediator.print = () => {
      let dictionary = Object.assign({}, ...this.columns.filter((c) => { return c.isVisible }).map((column) => ({
        [column.fieldName]: {
          Label: column.label,
          ToStringFormat: ['Date'].includes(column.fieldName) ? "dd/MM/yyyy HH:MM" : null
        }
      })));
      this._api.print(dictionary).then(function (response) {
        printerMediator.print(response);
      })
        .catch(function (err) {
        });
    };
  }


  getItemId(item) {
    return item.DocumentNumber;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  get api() {
    return this._api;
  }

  filterChange(filter) {
    super.filterChange(this.adaptFiltersToQuery(filter));
  }

  adaptFiltersToQuery(filter) {
    let adaptedFiltersToSet = [];

    return adaptedFiltersToSet;
  }

  loadOnRemote(top, skip, sort, filter) {
    if (isNonEmptyArray(filter)) {
      let rangeFilter = filter.find((x) => x.column.fieldName === 'Date')
      if (rangeFilter) {
        var range = filter.find((x) => x.column.fieldName === 'Date').filterValue
        this.from = moment(range[0]);
        this.to = range[1] != null ? moment(range[1]) : moment(range[0]).endOf('day');
        filter.remove(rangeFilter)
      }
    }

    this._api.from(this.from.format(ODATA_DATE_TIME_FORMAT));
    this._api.to(this.to.format(ODATA_DATE_TIME_FORMAT));
    this._api.top(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }
    const adaptedFilters = this.adaptFiltersToQuery(filter);
    this._api.filter(adaptedFilters);

    return this._api.get().then((result) => {
      return new GridApiResult(result.map((item) => {
        return item;
      }),
        top,
        skip,
        null);
    });
  }
}



export class CustomerPurchasesDS extends LazyGridDataSource {
  constructor(api, localize, stateManager, mediator, Repository, printerMediator) {
    super();
    this._api = api;
    const createdOn = new GridColumn('CreatedOn', localize('_Date_'), COLUMN_EDITOR_TYPE.LAZY);
    createdOn.width = 240;
    createdOn.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    createdOn.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    createdOn.freeze = true;
    createdOn.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', Moment(entity.CreatedOn, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT));
    };

    const label = new GridColumn('Name', localize('_Name_'), COLUMN_EDITOR_TYPE.LAZY);
    label.width = 200;



    const itemType = new GridColumn('ItemType', localize('_ItemType_'), COLUMN_EDITOR_TYPE.LAZY);
    itemType.width = 150;

    const itemTypes = ['Product', 'Service', 'Subscription', 'Custom', 'GiftCard', 'PrePayment', 'SubscriptionUsage'].map((itemType) => ({
      text: getLocalizeItemType(itemType),
      value: itemType
    }));

    const itemTypeDictionary = itemTypes.reduce((acc, itemType) => {
      acc[itemType.value] = itemType.text;
      return acc;
    }, {});

    itemType.options = [...itemTypes];
    itemType.filterArgs = {
      placeholder: localize('_ItemType_'),
      options: itemTypes.map((itemType) => ({
        text: itemType.text,
        value: `ItemType eq '${itemType.value}'`
      }))
    };

    itemType.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', itemTypeDictionary[entity.ItemType]);
    };
    itemType.filterType = GRID_FILTER_TYPE.MULTI_SELECT;


    function getLocalizeItemType(itemType) {
      switch (itemType) {
        case 'Product':
          return localize('_Product_');
        case 'Service':
          return localize('_Service_');
        case 'Subscription':
          return localize('_Subscriptions_');
        case 'Custom':
          return localize('_CustomProduct_');
        case 'GiftCard':
          return localize('_GiftCard_');
        case 'PrePayment':
          return localize('_PrePayment_');
        case 'SubscriptionUsage':
          return localize('_SubscriptionUsage_');
        default:
          return itemType;
      }
    }


    const originalRetailPrice = new GridColumn('OriginalRetailPrice', localize('_OriginalPrice_'), false, true);
    originalRetailPrice.width = 150;

    const price = new GridColumn('Price', localize('_Price_'), false, true);
    price.width = 150;

    const quantity = new GridColumn('Quantity', localize('_Quantity_'), false, true);
    quantity.width = 150;


    this.columns = [
      createdOn,
      label,
      itemType,
      originalRetailPrice,
      price,
      quantity
    ]

    this._sortByColumn = new SortByColumn(createdOn, true);


    mediator.exportToExcel = () => {
      this._api.export()
    };

    mediator.print = () => {
      let dictionary = Object.assign({}, ...this.columns.filter((c) => { return c.isVisible }).map((column) => ({
        [column.fieldName]: {
          Label: column.label,
          ToStringFormat: column.fieldName === 'CreatedOn' ? "dd/MM/yyyy" : (['OriginalRetailPrice','Price'].includes(column.fieldName) ? '#.##' : null) 
        }
      })));
      this._api.print(dictionary).then(function (response) {
        printerMediator.print(response);
      })
        .catch(function (err) {
        });
    };
  }


  getItemId(item) {
    return item.ItemType + "_" + item.Id;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  filterChange(filter) {
    super.filterChange(this.adaptFiltersToQuery(filter));
  }

  adaptFiltersToQuery(filter) {
    const adaptedFilters = filter.concat(this._constantFilters || []).map((x) => {
      if (x.column.fieldName !== 'CreatedOn') {
        if (isNonEmptyArray(x.filterValue) && x.filterValue.length > 1) {
          return `(${x.filterValue.join(' or ')})`;
        } else {
          return x.filterValue;
        }
      }
    }).filter((x) => x);
    return adaptedFilters;
  }

  get api() {
    return this._api;
  }

  loadOnRemote(top, skip, sort, filter) {
    if (isNonEmptyArray(filter)) {
      let rangeFilter = filter.find((x) => x.column.fieldName === 'CreatedOn')
      if (rangeFilter) {
        var range = filter.find((x) => x.column.fieldName === 'CreatedOn').filterValue
        this.from = moment(range[0]);
        this.to = range[1] != null ? moment(range[1]) : moment(range[0]).endOf('day');
      }
    }

    this._api.from(this.from.format(ODATA_DATE_TIME_FORMAT));
    this._api.to(this.to.format(ODATA_DATE_TIME_FORMAT));
    this._api.top(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }

    const adaptedFilters = this.adaptFiltersToQuery(filter);
    this._api.filter(adaptedFilters);

    return this._api.get().then((result) => {
      return new GridApiResult(result.map((item) => {
        return item;
      }),
        top,
        skip,
        null);
    });
  }
}


function adaptDateRangeQuery(dateFilter) {
  const fromDate = moment(dateFilter.filterValue[0]).format(ODATA_DATE_TIME_FORMAT);
  let toDate;
  if (dateFilter.filterValue[1] && dateFilter.filterValue[1].getDate() != dateFilter.filterValue[0].getDate()) {
    toDate = moment(dateFilter.filterValue[1]).format(ODATA_DATE_TIME_FORMAT);
  } else {
    toDate = moment(dateFilter.filterValue[0]).endOf('day').format(ODATA_DATE_TIME_FORMAT);
  };
  return `(${dateFilter.column.fieldName} ge DateTime'${fromDate}' and ${dateFilter.column.fieldName} le DateTime'${toDate}')`;
}


class DefaultDatePickerLabels extends DatePickerLabels {
  constructor(localize) {
    super();
    this._inputPlaceholder = localize('_ChooseDate_');
    this._selectADate = localize('_ChooseDate_');
    this._pickAStartTime = localize('_StartTime_');
  }

  get inputPlaceholder() {
    return this._inputPlaceholder;
  }

  get selectADate() {
    return this._selectADate;
  }

  get pickAStartTime() {
    return this._pickAStartTime;
  }
}

