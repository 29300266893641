import './promotion.content.viewer.directive.less';

angular.module('easybizy.common.promotion.editors').controller('PromotionPreviewController', function ($scope, $modalInstance, promotion, Repository, localize) {
  $scope.cancel = function () {
    $modalInstance.close();
  };

  $scope.promotion = promotion;
  $scope.contentResolver = {};

  $scope.save = () => {
    const resolved = $scope.contentResolver.resolve();
    if (resolved.ClientPromotionContent.State === 'Unchanged') {
      $modalInstance.close();
    } else {
      return Repository.Entity("PromotionSmsContent").patch($scope.promotion.PromotionContentId, { Message: resolved.ClientPromotionContent.Message })
        .post()
        .then(function () {
          $modalInstance.close();
          toastr.success(localize.getLocalizedString("_CampaignUpdatedSuccessfully_"));
          return true;
        })
        .catch(function (e) {
          toastr.error(localize.getLocalizedString("_ErrorUpdatingNotes_"));
          return false;
        });
    }
  };

});

angular.module('easybizy.common.promotion.editors').directive('promotionPreviewer', ['$timeout', 'Repository', function ($timeout, Repository) {
  const PHONE_PROMOTION_TYPE = ['sms', 'whatsapp'];
  return {
    restrict: 'E',
    replace: true,
    scope: {
      promotionMetadata: '=',
      contentResolver: '=contentResolverWrapper',
      save: '=',
      cancel: '=',
      editMode: '=?'
    },
    template: require('./promotion.content.viewer.tpl.directive.html'),
    link: function (scope) {
      scope.isLoading = true;
      // scope.contentResolverWrapper = scope.contentResolverWrapper || {};
      scope.$watchOnce('promotionMetadata', function (newVal) {
        if (newVal) {
          loadPromotion();
        }
      });

      scope.contentResolverWrapper = {};

      scope.$watchOnce('contentResolver', (newVal) => {
        newVal.resolve = () => {
          const resolved = { ClientPromotionContent: scope.smsContent };
          scope.contentResolverWrapper.resolve(resolved);
          return resolved;
        }
      });

      scope.isSavingWrapper = { saving: false };

      scope.internalSave = () => {
        scope.isSavingWrapper.saving = true;
        const savePromise = scope.save();
        if (savePromise) {
          savePromise
            .then(_ => scope.isSavingWrapper.saving = false)
            .catch(_ => scope.isSavingWrapper.saving = false);
        }
      }

      function loadPromotion() {
        scope.isLoading = true;
        var resolve = function (result) {
          scope.promotion = result.value[0];
          scope.editorType = PHONE_PROMOTION_TYPE.includes(scope.promotionMetadata.type) ? 'sms' : scope.promotionMetadata.type;
          scope.presetType = '_Blank_';
          setPromotionContent();
          scope.isLoading = false;
        };

        const catchError = (err) => {
          toastr.error(err.Message);
        };

        const promises = [Repository.Entity('Promotion', true).query().filter(`PromotionId eq ${ scope.promotionMetadata.promotionId }`).get()];


        if (scope.promotionMetadata.type === "mail") {
          promises.push(Repository.Custom("PromotionsRepository").getHtmlContent(scope.promotionMetadata.promotionId))
        } else if (PHONE_PROMOTION_TYPE.includes(scope.promotionMetadata.type)) {
          promises.push(Repository.Custom("PromotionsRepository").getSmsContent(scope.promotionMetadata.promotionId))
        } else if (scope.promotionMetadata.type === "facebook") {
          promises.push(Repository.Custom("PromotionsRepository").getFbContent(scope.promotionMetadata.promotionId))
        }

        Promise.all(promises).then((res) => {
          scope.promotion = res[1].value[0];
          scope.editorType = PHONE_PROMOTION_TYPE.includes(scope.promotionMetadata.type) ? 'sms' : scope.promotionMetadata.type;
          scope.presetType = res[0].value[0]['PresetName'] || '_Blank_';
          setPromotionContent();
          scope.isLoading = false;
        }).catch(catchError)
      }

      function setPromotionContent() {
        if (scope.editorType === "mail") {
          scope.mailContent = {};
          scope.mailContent.subject = scope.promotion.Subject;
          scope.mailContent.html = scope.promotion.Content;
        } else if (scope.editorType === "facebook") {
          scope.facebookContent = {};
          scope.facebookContent.message = scope.promotion.Message;
          scope.facebookContent.images = scope.promotion.Images.map(x => baseUrl + x.FilePath);
        } else if (PHONE_PROMOTION_TYPE.includes(scope.editorType)) {
          scope.smsContent = {};
          scope.smsContent.message = scope.promotion.Message;
        }
      }
    }
  };
}
]);
