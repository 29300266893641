var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-4" },
    [
      _c(
        "Grid",
        _vm._b(
          {
            ref: "gridEl",
            attrs: { dataSource: _vm.dataSource },
            on: {
              "save-preset": _vm.savePreset,
              "preset-selected": _vm.presetSelected,
            },
            scopedSlots: _vm._u([
              {
                key: "footer",
                fn: function () {
                  return [_c("div", [_vm._v(_vm._s(_vm.footerText))])]
                },
                proxy: true,
              },
            ]),
          },
          "Grid",
          _vm.gridProps,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }