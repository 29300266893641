import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT } from "../../../constants";
import './z.by.date.report.directive.less'

angular.module('easybizy.common.custom.reports.actions')
  .directive('zByDateReport', [
    'localize', 'Repository', '$timeout', 'printerMediator', function (localize, Repository, $timeout, printerMediator) {
      return {
        restrict: 'E',
        scope: {
          cancel: '&'
        },
        replace: true,
        template: require('./z.by.date.report.directive.tpl.html'),
        link: function (scope, element, attrs) {
          scope.rangeMetadataFrom = {
            fieldName: 'from',
            icon: "icon icon-calendar",
            placeholder: "Start Date",
            validation: "{'required': true}",
            type: 'date'
          };
          scope.rangeMetadataTo = {
            fieldName: 'to',
            icon: "icon icon-calendar",
            placeholder: "End Date",
            validation: "{'required': true}",
            type: 'date'
          };

          scope.sendByMailEmail = {
            fieldName: 'EmailAddress',
            icon: "icon icon-mail-outline",
            placeholder: localize.getLocalizedString("_Email_"),
            validation: "{'email': true, 'required': true}"
          };

          scope.reportToUse = { z: true };

          scope.reportTypes = [
            { name: localize.getLocalizedString('_ZForPeriod_'), field: 'z' },
            { name: localize.getLocalizedString('_IncomesForPeriod_'), field: 'incomes' },
            { name: localize.getLocalizedString('_IntakesForPeriod_'), field: 'intakes' },
            { name: localize.getLocalizedString('_EmployeeHours_'), field: 'employee-hours' }
          ];

          scope.generationMethod = [
            {
              name: localize.getLocalizedString("_PrintIt_"), value: 'print', isDisabled: function () {
                return false;
              }
            },
            {
              name: localize.getLocalizedString("_Download_"), value: 'download', isDisabled: function () {
                return false;
              }
            },
            {
              name: localize.getLocalizedString("_SendByEmail_"), value: 'send', isDisabled: function () {
                return scope.emailValidatorAction.validate().length !== 0;
              }
            }
          ];

          scope.detailsLevel = [
            {
              name: localize.getLocalizedString("_SummingOnly_"), value: 'summing', isDisabled: function () {
                return false;
              }
            },
            {
              name: localize.getLocalizedString("_ByAction_"), value: 'action', isDisabled: function () {
                return false;
              }
            }
          ];

          scope.selectedGenerationMethod = { value: scope.generationMethod[0] };
          scope.selectedDetailLevel = { value: scope.detailsLevel[0] };

          scope.selectedRange = {};
          var now = moment();
          scope.selectedRange.from = moment().date(1).month(now.month() - 1);
          scope.selectedRange.to = moment().date(1).month(now.month()).subtract(1, 'days');
          scope.selectedRange.from = scope.selectedRange.from.format(DATE_DISPLAY_FORMAT);
          scope.selectedRange.to = scope.selectedRange.to.format(DATE_DISPLAY_FORMAT);

          scope.sendByMailWrapper = {};
          scope.sendByMailWrapper.sendByMail = false;

          scope.$watch("sendByMailWrapper.sendByMail", function (newVal) {
            if (newVal) {
              scope.sendText = localize.getLocalizedString("_SendByMail_");
            } else {
              scope.sendText = localize.getLocalizedString("_Print_");
            }
          });

          Repository.Custom("Configurations").mailForZByDate().then(function (result) {
            scope.sendByMailWrapper.EmailAddress = result.Message;
          });

          scope.emailValidator = {};
          scope.emailValidatorAction = {};
          scope.isGeneratingReportWrapper = {};
          scope.isGeneratingReportWrapper.inProgress = false;

          scope.sendOrPrint = function () {
            if (!moment(scope.selectedRange.from, DATE_DISPLAY_FORMAT).isBefore(moment(scope.selectedRange.to, DATE_DISPLAY_FORMAT))) {
              toastr.error(localize.getLocalizedString("_DateRangeIsIncorrect_"));
              return;
            }

            scope.isGeneratingReportWrapper.inProgress = true;

            var keys = Object.keys(scope.reportToUse);
            var reportTypes = keys.filter(function (key) {
              return scope.reportToUse[key];
            });
            var method = scope.selectedGenerationMethod.value.value;
            var showActionDetails = scope.selectedDetailLevel.value.value == 'action';

            var from = moment(scope.selectedRange.from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
            var to = moment(scope.selectedRange.to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
            switch (method) {
              case "download":
                Repository.Custom("ReportsRepository").download(from, to, reportTypes, showActionDetails);
                scope.isGeneratingReportWrapper.inProgress = false;
                scope.$digestIfNeeded();
                //.then(function (result) {
                //    //scope.cancel();
                //})
                //.catch(function (err) {
                //    toastr.error(localize.getLocalizedString("_ErrorProducing_", localize.getLocalizedString("_Reports_")) + " - " + err.ExceptionMessage);
                //})
                //.fin(function (res) {
                //    scope.isGeneratingReportWrapper.inProgress = false;
                //    scope.$digestIfNeeded();
                //});
                break;
              case "print":
                Repository.Custom("ReportsRepository").print(from, to, reportTypes, showActionDetails)
                  .then(function (result) {
                    angular.forEach(result, function (r) {
                      printerMediator.print(r.Receipt);
                    });

                    scope.isGeneratingReportWrapper.inProgress = false;
                  })
                  .catch(function (err) {
                    toastr.error(localize.getLocalizedString("_ErrorProducing_", localize.getLocalizedString("_Reports_")) + " - " + err.ExceptionMessage);
                    scope.isGeneratingReportWrapper.inProgress = false;
                  })
                break;
              case "send":
                Repository.Custom("ReportsRepository").send(from, to, reportTypes, showActionDetails, scope.sendByMailWrapper.EmailAddress)
                  .then(function (result) {
                    if (result) {
                      toastr.success(localize.getLocalizedString("_ReportSent_") + ".");
                      scope.cancel();
                    } else {
                      toastr.error(localize.getLocalizedString("_ErrorProducing_", localize.getLocalizedString("_Reports_")) + " - " + err.ExceptionMessage);
                    }

                    scope.isGeneratingReportWrapper.inProgress = false;
                  })
                  .catch(function (err) {
                    toastr.error(localize.getLocalizedString("_ErrorProducing_", localize.getLocalizedString("_Reports_")) + " - " + err.ExceptionMessage);
                    scope.isGeneratingReportWrapper.inProgress = false;
                  })

                break;
              default:
            }
          };
        }
      };
    }]);
