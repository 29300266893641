<template>
  <div class="wizard-wrapper">
    <div class="top-part">
      <h2>
        {{ title }}
      </h2>
    </div>
    <div class="bottom-part">
      <slot/>
    </div>
    <div class="actions-part" :class="enabledActionsClass">
      <transition-group :name="'action'" class="actions-wrapper-of-buttons" tag="div" mode="out-in">
        <button v-for="action of actions" @click="action.action" :key="action.name" :class="action.class">
          {{ action.name }}
        </button>
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    hasNext: {
      type: Boolean,
      required: true
    },
    hasPrev: {
      type: Boolean,
      required: true
    },
    skipable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    enabledActionsClass() {
      return (this.hasPrev || this.hasNext) ? '' : 'actions-part--hidden'
    },
    actions() {
      const toReturn = [];
      if (this.hasPrev) {
        toReturn.push({
          name: this.$t('_Previous_'),
          action: () => this.$emit('prev'),
          class: ''
        });
      }

      if (this.hasNext) {
        toReturn.push({
          name: this.$t('_NextCustomer_'),
          action: () => this.$emit('next'),
          class: 'primary-button'
        });
      } else if (this.skipable) {
        toReturn.push({
          name: this.$t('_None_'),
          action: () => this.$emit('skip'),
          class: ''
        });
      }

      return toReturn;
    }
  }
}
</script>

<style lang="less">
@import "../../../../styles/variables";

.wizard-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 1em;

  .top-part {
    margin-bottom: 25px;
  }

  .actions-part {
    margin-top: 50px;

    &--hidden {
      visibility: hidden;
      opacity: 0;
      min-height: initial;
    }

    button {
      outline: @border-default-width solid transparent;
      background: transparent;
      box-shadow: none;
      cursor: pointer;
      display: inline-block;
      font-weight: 600;
      font-size: 18px;
      //padding: 0 18px;
      line-height: 50px;
      text-align: center;
      text-decoration: none;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      width: 150px;

      &.primary-button {
        outline: @border-default-width solid @tile-border-color;
        background: @content-background-color;
        border-radius: @default-border-radius;
      }

      .CubicAnimation(0.3s, all);

      &:hover {
        transform: scale(1.15, 1.15);
      }

      &.hidden-action {
        transform: scale(0, 0) !important;
      }
    }


    .action-leave-active,
    .action-enter-active {
      // Unnecessary, button already defines animation.
    }

    .action-leave-active {
      position: absolute;
    }

    .action-enter {
      transform: scale(0, 0, 0);
      opacity: 0;
    }


    .action-leave-to {
      transform: scale(0, 0, 0);
      opacity: 0;
    }
  }
}

</style>
