import './manual.credit.card.modal.less';

angular.module('easybizy.easybizyModalViewsModel').service('manualCreditChargeService', function ($modal, $rootScope) {
  this.show = (transaction, successCallback) => {
    showDialog(transaction, successCallback);
  }

  this.saveCreditCardDetails = (customerId, successCallback) => {
    assert(customerId, 'CustomerId must be provided.');
    showDialog({ customerId }, successCallback || (() => {
    }), true);
  }

  function showDialog(transaction, successCallback, tokenOnly) {
    if (!tokenOnly) {
      assert(transaction, 'Transaction details must be provided.');
      assert(transaction.amount, 'amount must be provided.');
      assert(transaction.currency, 'currency must be provided.');
      assert(successCallback, 'Success Callback must be provided.');
    }

    const opts = {
      backdrop: 'static',
      keyboard: false,
      template: require('../../modal-views/manual-credit-card/manual.credit.card.modal.tpl.html'),
      controller: 'ManualCreditCardInputController',
      resolve: {
        transactionDetails: () => {
          return transaction;
        },
        successCallback: () => successCallback,
        saveTokenMode: () => tokenOnly
      }
    };

    $modal.open(opts);
  }
});


angular.module('easybizy.easybizyModalViewsModel').controller('ManualCreditCardInputController',
  function ($scope, $modalInstance, configuration, focusManager, transactionDetails, successCallback, saveTokenMode, pci, localize) {
    focusManager.toggleFocus(true, $scope);
    focusManager.preventEscape($scope);
    if (saveTokenMode) {
      $scope.submitCustomText = localize.getLocalizedString('_SaveCardEncrypted_');
    }

    $scope.cancel = function () {
      if ($scope.inProgress) {
        return;
      }

      $modalInstance.dismiss('cancel');
    };

    $scope.isRefund = transactionDetails.isRefund;

    const configurations = configuration.get();
    $scope.allowCreditToken = !saveTokenMode && !$scope.isRefund && !!transactionDetails.customerId && configurations.CashRegisterSettings.AllowCreditToken;
    $scope.confirmCustomerIsPresent = configurations.CashRegisterSettings.ConfirmCustomerIsPresent;
    $scope.requireId = configurations.CashRegisterSettings.RequireCardHolderIdInManualCharge;
    $scope.requireCvv = configurations.CashRegisterSettings.RequireCvvInManualCharge;
    $scope.requireAuthNumber = false;
    $scope.saveTokenWrapper = { save: false };
    $scope.isCustomerPresentWrapper = { isPresent: false };

    $scope.submit = saveTokenMode ? convertToToken : charge;
    $scope.savedCard = pci.savedCardDetails;
    $scope.cardDetailsChanged = (details) => {
      pci.saveManualCardDetailsInMemory(details);
    };

    function toggleProgress(inProgress, closeDialog) {
      $scope.$evalAsync(() => {
        $scope.inProgress = inProgress;
        if (closeDialog) {
          $scope.cancel();
        }
      });
    }

    function charge(creditCardDetails) {
      toggleProgress(true);
      const transactionToSend = { ...transactionDetails, creditCardDetails };
      if ($scope.isCustomerPresentWrapper.isPresent) {
        transactionToSend.isCustomerPresent = true;
      }

      const containAuthNum = creditCardDetails.authNum && creditCardDetails.authNum.length > 0;
      if (containAuthNum) {
        transactionToSend.authNum = creditCardDetails.authNum;
        delete creditCardDetails.authNum;
      }

      const transaction = pci.generateTransaction(transactionToSend);
      pci.chargeCreditCard(transaction)
        .then((result) => {
          /// Clear saved card, no need.
          pci.saveManualCardDetailsInMemory();
          toggleProgress(false, true);
          successCallback(result);
          if ($scope.saveTokenWrapper.save) {
            convertToToken(creditCardDetails, true)
          }

        }).catch((err) => {
        toggleProgress(false);
        $scope.$evalAsync(() => {
          $scope.requirePhoneAuthNum = err.status === pci.PHONE_AUTH_REQUIRED_ERROR_CODE || containAuthNum;
          toastr.error(pci.extractError(err));
        });
      });
    }

    function convertToToken(creditCardDetails, preventCallback) {
      toggleProgress(true);
      pci.convertToToken(creditCardDetails, transactionDetails.customerId)
        .then((result) => {
          toggleProgress(false, !preventCallback);
          if (!preventCallback) {
            successCallback(result);
          }
        }).catch((err) => {
        toggleProgress(false);
        toastr.error(pci.extractError(err));

      });
    }

  });
