/**
 * Created by tomeravni on 2/17/14.
 */

import './combobox.directive.less'

(function () {
  angular.module('easybizy.common.common-controls')
    .directive('combobox', [
      'localize', '$timeout', '$compile', '$q', function (localize, $timeout, $compile, $q) {
        return {
          restrict: 'E',
          replace: true,
          scope: {
            values: '=?',
            stringValues: '=?',
            selected: '=',
            manuallySelect: '=',
            valueSelected: '&',
            itemdecorator: '&',
            listMode: '=',
            disabled: '=?',
            multiSelect: '=',
            multiSelected: '=',
            forceMultiSelection: '=',
            allowCategorySelection: '=',
            forceConcreteSelection: '=',
            allowAddNewCategory: '=',
            autoGeneratedValues: '=',
            addNewCategoryText: "@",
            addNewCategoryAction: '&',
            searchable: '=',
            proxy: '=',
            toggleOpenWrapper: '=',
            raiseValueSelectedOn: '@',
            args: '='
          },
          controller: function ($scope) {
            $scope.searchTextWrapper = {};
            this.itemStateChanged = function (item) {
              if (item.isSelected) {
                if ($scope.multiSelected.indexOf(item) < 0) {
                  $scope.multiSelected.push(item);
                }
              } else {
                $scope.multiSelected.remove(item);
                try {
                  if ($scope.multiSelected.length === 0) {
                    $timeout(function () {
                      $scope.listValueSelected($scope.values[0]);
                    });
                  }
                } catch (e) {
                  console.log('cannot default to default item in combobox');
                }

              }
            };

            this.raiseChangeEvent = function () {
              $scope.listValueSelected(($scope.multiSelected && buildSelectionHirerachy($scope.multiSelected)) || $scope.selected, true);
            };

            this.hasItems = function () {
              return $scope.multiSelected && $scope.multiSelected.length > 0;
            };

            this.clear = function () {
              $scope.multiSelected.length = 0;
            };

            this.setSearchText = function (text) {
              $scope.searchTextWrapper.text = text;
            };

            Object.defineProperty(this, 'selected', {
              get: function () {
                return $scope.selected;
              }
            });

            Object.defineProperty(this, 'selectable', {
              get: function () {
                return $scope.selectable;
              }
            });

            const proxyWatcher = $scope.$watch('proxy', function (proxy) {
              if (proxy) {
                proxyWatcher(); // cancel watcher.
                $scope.isLoading = true;
                var loadDataFromProxy = function (reloadCache) {
                  var deferred = $q.defer();
                  proxy.get(reloadCache).then(function (values) {
                    if (reloadCache) {
                      $scope.values.length = 0;
                      if (proxy.defaultItem) {
                        $scope.values.push(proxy.defaultItem);
                      }
                    }

                    $scope.values.pushAll(values);
                    $timeout(function () {
                      $scope.isLoading = false;
                    });

                    proxy.firstLoaded = true;

                    if (proxy.doneLoading) {
                      proxy.doneLoading(values);
                    }

                    deferred.resolve(values);
                  })
                    .catch(function (err) {
                      toastr.error(localize.getLocalizedString("_ErrorLoadingInternalValues_"));
                      deferred.reject(err);
                    });

                  return deferred.promise;
                };

                proxy.forceReload = function () {
                  return loadDataFromProxy(true);
                };

                loadDataFromProxy();
              }
            });
          },
          compile: function (tElement, tAttrs) {
            if (tAttrs['rtlValues'] === 'true') {
              tElement.addClass('rtl-list-values');
            }

            return function (scope, element, attrs) {
              if (attrs.className) {
                element.addClass(attrs.className);
              }

              if (scope.stringValues) {
                scope.values = [];
                angular.forEach(scope.stringValues, function (val) {
                  scope.values.push({
                    name: localize.getLocalizedString(val.name, val.params ? val.params : []),
                    value: val.value
                  });
                });

                adjustSelectedValue();
              } else if (scope.autoGeneratedValues) {
                autoGenerateValues(scope.autoGeneratedValues);
                adjustSelectedValue();
              }

              scope.element = element;
              if (!scope.selected && scope.values) {
                scope.selected = scope.values[0];
              }

              scope.isOpenWrapper = {};
              var isCompiled = false;
              scope.toggleCombo = function () {
                if (!scope.disabled) {
                  if (!isCompiled) {
                    isCompiled = true;
                    var compiled = $compile(k_ComboboxListItemtemplate)(scope);
                    element.first().append(compiled);
                  }

                  scope.isOpenWrapper.isOpen = !scope.isOpenWrapper.isOpen;
                }
              };

              if (scope.raiseValueSelectedOn && scope.raiseValueSelectedOn.indexOf('closed') > -1) {
                var diffValueToRaiseWhenClosed = null;
                scope.$watch('isOpenWrapper.isOpen', function (newVal) {
                  if (newVal) {
                    diffValueToRaiseWhenClosed = null;
                  } else {
                    if (diffValueToRaiseWhenClosed) {
                      raiseValueChangedEvent(diffValueToRaiseWhenClosed)
                    }
                  }
                });
              }

              if (scope.toggleOpenWrapper) {
                scope.toggleOpenWrapper.toggle = function () {
                  scope.toggleCombo();
                  setTimeout(function () {
                    element.find('.combobox-add-new-item-input').focus();
                  }, 500)
                }
              }

              scope.setDisabled = function (isDisabled) {
                scope.disabled = isDisabled;
              };

              scope.$watch('disabled', function (newVal) {
                if (newVal) {
                  scope.isOpenWrapper.isOpen = false;
                }
              });

              var selectedItemWidth = null;
              $timeout(function () {
                selectedItemWidth = element.find(".combobox-list-item").first().css("width");
              });


              if (angular.isDefined(scope.manuallySelect)) {
                scope.manuallySelect.select = function (val) {
                  scope.selected = val;
                  scope.$digestIfNeeded();
                };
              }

              scope.listValueSelected = function (val, userEvent) {
                scope.selected = diffValueToRaiseWhenClosed = val;
                // This for making sure the "selected" combobox value was already digested.
                if (!scope.raiseValueSelectedOn || scope.raiseValueSelectedOn.indexOf('closed') < 0 || (userEvent && scope.raiseValueSelectedOn.indexOf('userEvent') > -1)) {
                  scope.$evalAsync(raiseValueChangedEvent.bind(scope, val));
                }

                $timeout(function () {
                  selectedItemWidth = element.find(".combobox-list-item").first().css("width");
                });
              };

              scope.$watch('multiSelected.length', function (newVal) {
                if (angular.isDefined(newVal) && newVal > 0) {
                  scope.multiSelected.forEach(function (itemWrapper) {
                    itemWrapper.isSelected = true;
                  });

                  scope.listValueSelected(buildSelectionHirerachy(scope.values));
                }

              });

              scope.getMultiSelectedValues = getMultiSelectedValues;

              scope.$watch('values.length', (newVal) => {
                if (newVal > 1 && scope.values[1] && scope.values[1].valueType === 'Category') {
                  scope.flatValues = scope.values.slice(1).reduce((acc, x) => {
                    return acc.concat(x.value);
                  }, []);
                } else if (scope.flatValues && scope.flatValues.length > 0) {
                  scope.flatValues = [];
                }
              });

              function getMultiSelectedValues() {
                return scope.multiSelected.map(function (value) {
                  return value.name
                }).join(", ");
              }

              function autoGenerateValues(autoGeneratedValues) {
                var parsedValues = [];
                var currentValue = autoGeneratedValues.min;
                var maxValue = autoGeneratedValues.max;
                var delta = autoGeneratedValues.delta;
                var comparisonMethod = function (a, b) {
                  return a <= b;
                };

                var addMethod = function (a, toAdd) {
                  return a + toAdd;
                };

                if (autoGeneratedValues.type == "time") {
                  currentValue = moment(currentValue, "HH:mm");
                  maxValue = moment(maxValue, "HH:mm");
                  delta = moment(delta, "HH:mm").diff(moment('00:00', 'HH:mm'), 'm');
                  if (currentValue.isAfter(maxValue)) {
                    maxValue.add(1, 'd');
                  }

                  comparisonMethod = function (a, b) {
                    return b.isSameOrAfter(a);
                  };

                  addMethod = function (a, toAdd) {
                    return a.add(toAdd, 'm');
                  }
                }

                while (comparisonMethod(currentValue, maxValue)) {
                  var concreteValue = autoGeneratedValues.type == "time" ? (currentValue.isSame(maxValue) ? autoGeneratedValues.max : currentValue.format("HH:mm")) : currentValue;
                  var newValue = { name: concreteValue, value: concreteValue };
                  if (autoGeneratedValues.localize) {
                    newValue.name = localize.getLocalizedString(autoGeneratedValues.localize, concreteValue);
                  }

                  parsedValues.push(newValue);
                  currentValue = addMethod(currentValue, delta);
                }

                scope.values = [];
                scope.values.pushAll(parsedValues);
              }

              function adjustSelectedValue() {
                scope.$watchOnce('selected', function (newVal) {
                  scope.selected = scope.values.filter(x => x.value == newVal)[0];
                });
              }

              function raiseValueChangedEvent(val) {
                if (typeof scope.valueSelected() === "function") {
                  scope.valueSelected()(val, scope.isOpenWrapper.isOpen, scope.args);
                } else {
                  scope.valueSelected(val, scope.isOpenWrapper.isOpen, scope.args);
                }
              }
            };
          },
          template: k_ComboboxTemplate
        };

        /*
         This builds the selection tree, where if the parent is SELECTED, only the parent is present.
         Otherwise the parent is brought with it's children and their state is changed accordingly.
         */
        function buildSelectionHirerachy(values) {
          var currentlySelected = [];
          angular.forEach(values, function (value) {
            if (value.isSelected) {
              currentlySelected.push(
                {
                  id: value.valueType === "Category" ? value.categoryValue : value.value,
                  isCategory: value.valueType === "Category"
                });
            } else if (value.valueType === "Category") {
              var siblingsSelection = buildSelectionHirerachy(value.value);
              if (siblingsSelection.length > 0) {
                currentlySelected.pushAll(siblingsSelection);
              }
            }
          });

          return currentlySelected;
        }
      }]);

  angular.module('easybizy.common.common-controls').directive('comboboxList', [
    'localize', '$timeout', 'escapeKeyService', function (localize, $timeout, escapeKeyService) {
      var tempNewItemId = (-1) * Math.floor(Math.random() * 1000);

      return {
        restrict: 'E',
        replace: true,
        scope: {
          values: '=',
          isOpenWrapper: '=',
          decoratorref: '&',
          selected: '=',
          valueSelected: '&',
          relativeTo: '=',
          listMode: '=',
          multiSelect: '=',
          forceMultiSelection: '=',
          externalLoading: '=',
          parentItem: '=',
          searchable: '=',
          searchTerm: '=',
          allowCategorySelection: '=',
          forceConcreteSelection: '=',
          allowAddNewCategory: '=',
          addNewCategoryText: '@',
          addNewCategoryAction: '=',
          isAllSelectedWrapper: '=',
          toggleParent: '=',
          flatValues: '='
        },
        link: function (scope, element) {
          const listItem = element.find('> .combobox-list-part');
          const listMask = element.find('> .combobox-list-item-mask');
          let escListenerResolver;
          var isRightToLeft = localize.isRightToLeft();
          scope.isLoading = false;
          scope.$watch('isOpenWrapper.isOpen', function (newVal, oldVal) {
            if (!newVal) {
              hide();
            }

            if (scope.disabled) {
              return;
            }

            if (newVal || newVal !== oldVal) {
              toggle(newVal);
            }

            if (angular.isDefined(scope.isAllSelectedWrapper) && scope.isAllSelectedWrapper.isAllSelected) {
              scope.values.forEach(value => value.isSelected = true);
            }

            if (newVal) {
              angular.forEach(scope.values, function (model) {
                if (model.expand && !model.wasExpanded) {
                  model.expand(function (toApply) {
                    toApply();
                  });
                }
              });
            }

          }, true);

          scope.$watch('isAllSelectedWrapper.isAllSelected', function (val) {
            if (angular.isDefined(val)) {
              angular.forEach(scope.values, function (value) {
                value.isSelected = val;
              });
            }
          });

          scope.select = function (value, preventRegisteringEvent) {
            if (!scope.forceMultiSelection) {
              if (scope.multiSelect) {
                scope.selectAll(false, true);

                if (!value.defaultValue) {
                  scope.selected = value;
                }
              }

              toggle();
              if (scope.valueSelected()) {
                scope.valueSelected()(value, true);
              }
            } else if (value.value == null) {
              scope.selectAll(false, true);
              scope.selected = value;
              toggle();
            }
          };

          scope.selectAll = function () {
            var args = [scope.values];
            for (var argument in arguments) {
              args.push(arguments[argument]);
            }

            selectAll.apply(scope, args);
          };

          function selectAll(values, isSelected, recursively) {
            values.forEach(function (value) {
              if (!!value.isSelected != isSelected) {
                value.isSelected = isSelected;
              }

              if (!isSelected && recursively && value.valueType === 'Category') {
                selectAll(value.value, isSelected, recursively);
              }
            })
          }

          scope.onListFocused = function (preventEventAddition) {
            angular.forEach(scope.values, function (itemInList) {
              itemInList.isBlurred = false;
            });
          };

          scope.addNewCategoryItem = function (item) {
            var newItem = { name: item, value: null };
            newItem.value = newItem.categoryValue = tempNewItemId;
            tempNewItemId--;

            if (scope.allowCategorySelection) {
              newItem.value = [];
              newItem.valueType = "Category";
              if (angular.isDefined(scope.parentItem)) {
                if (!angular.isDefined(scope.parentItem.newCategories)) {
                  scope.parentItem.newCategories = [];
                }

                scope.parentItem.newCategories.push(newItem);
                newItem.parentId = angular.isDefined(scope.parentItem.categoryValue)
                  ? scope.parentItem.categoryValue : scope.parentItem.value;
              } else {
                newItem.parentId = 0;
              }
            }

            if (angular.isDefined(scope.values[0]) && scope.values[0].value == null) {
              scope.values.insertAtIndex(newItem, 1);
            } else {
              scope.values.unshift(newItem);
            }

            scope.addNewCategoryAction()(newItem);
            scope.select(newItem, true);
          };

          /********************** List Toggle, and positioning *******************/

          /*
           This method toggles the combobox-list on/off. If showing the combobox-list, it calculates the concrete position
           the list should be placed on, since the list is FIXED positioned.
           */


          function toggle(isToOpen) {
            if (isToOpen) {
              // This means it was already set from outside.
              registerToClosePopupWhenScroll(true);
              fixListPosition();
              if (scope.searchable) {
                setTimeout(function () {
                  listItem.find('input').focus()
                }, 500);
              }

              escListenerResolver = escapeKeyService.addEscListener(() => toggle(false));

            } else {
              registerToClosePopupWhenScroll(false);
              const currentValue = scope.isOpenWrapper.isOpen;
              scope.isOpenWrapper.isOpen = false;
              // This is a BUG, can't reproduce. This should solve it overcome it.
              if (!currentValue) {
                hide();
              }
            }
          }

          scope.close = toggle;
          scope.fixListPosition = fixListPosition;
          /*
           In case the combo is dynamic, fix the size according to the items change.
           */
          scope.$watch('values.length', function (newVal) {
            if (newVal > 0 && scope.isOpenWrapper.isOpen) {
              fixListPosition();
            }
          });

          function fixListPosition() {
            if (listItem.height() < ($("body").height() / 2)) {
              listItem.css('height', "auto");
            }

            var selectedIndex = scope.values.indexOf(scope.selected);

            if (selectedIndex) {
              // Scroll to SELECTED ITEM.
              setTimeout(function () {
                var listWrapper = listItem.find('.combobox-list-part-items-wrapper');
                var listItemHeight = listWrapper.find('li:first-of-type')[0].getBoundingClientRect().height;
                listWrapper.scrollTop(Math.max(0, (selectedIndex - 1)) * listItemHeight);
              }, 10);
            }

            listItem.css('margin-top', 0);

            fixListPositionInternal();
            $timeout(fixListPositionInternal, 5);
          }

          const $body = $('body');
          const bodyHeight = $body.height();
          const bodyWidth = $body.width();

          function fixListPositionInternal() {
            listMask.removeClass('zero-opacity');
            if (scope.listMode) {
              listItem.removeClass('zero-opacity');
              const bodyHeight = $body.height();
              if (listItem.height() > bodyHeight / 2.5) {
                listItem.css('height', bodyHeight / 2.5);
              }


              if (listItem.css('margin-top') === '0px') {
                var marginTop = listItem.parent().offset().top - listItem.offset().top + listItem.parent().height();
                listItem.css('margin-top', marginTop + 'px');
              }

              listItem.css('width', listItem.closest('.combobox').width() + 'px');

              return;
            }

            /********************** Fix height and top position *******************/
            var listElementHeight = Math.min(bodyHeight / 2, listItem.height()) + 4;
            scope.hasScroll = listItem.height() > (bodyHeight / 2);

            var comboTop = scope.relativeTo.offset().top;

            /********************** HACK TO FIX BUG WITH CALCULATING WIDTH INSIDE MODAL*******************/
            var modalWrapper = scope.relativeTo.closest('.modal-dialog');
            if (modalWrapper && modalWrapper.length === 1) {
              comboTop -= modalWrapper.offset().top;
            }

            var calculatedTop = comboTop - (listItem.height() / 2 - scope.relativeTo.height() / 2);
            if (calculatedTop - 10 < 0) {
              calculatedTop = 10;
            } else if (listElementHeight + calculatedTop > (bodyHeight - 10)) {
              var sholders = listElementHeight + calculatedTop - bodyHeight + 10;
              listElementHeight -= sholders;
            }

            listItem.css('height', listElementHeight);
            listItem.css('top', calculatedTop);

            /********************** Fix left position *******************/
            var arrowElement = listItem.find(".combobox-list-arrow-pointer");
            var marginLeft = scope.relativeTo.width() + 10;
            var listElementWidth = listItem.width();
            var listLeft = scope.relativeTo.offset().left + (isRightToLeft ? 0 : marginLeft);
            var actualLeftPosition = listLeft + (isRightToLeft ? -1 : 1) * listElementWidth;
            if (actualLeftPosition > bodyWidth || actualLeftPosition < 5) {
              marginLeft = -1 * (listElementWidth + 20);
              arrowElement.addClass("right-side-combo-arrow");
            } else {
              arrowElement.removeClass("right-side-combo-arrow");
            }

            if (!isRightToLeft) {
              listItem.css('margin-left', marginLeft + "px");
            } else {
              listItem.css('margin-right', marginLeft + "px");
            }

            listItem.removeClass('zero-opacity');

            /********************** Fix arrow position *******************/
            var top = scope.relativeTo.offset().top - listItem.offset().top + (scope.relativeTo.height() / 2 - 12);
            arrowElement.css('top', top + "px");
          }


          /*
           This method closes the list in case scroll is in progress.
           */

          function registerToClosePopupWhenScroll(isToRegister) {
            var scrollEvents = "wheel.modal mousewheel.modal";
            if (isToRegister) {
              $(document).on(scrollEvents, scrollOccurred);
            } else {
              $(document).off(scrollEvents, scrollOccurred);
            }
          }

          function scrollOccurred(e) {
            if (!listItem.is(e.target) // if the target of the click isn't the container...
              && listItem.has(e.target).length === 0) { // ... nor a descendant of the container
              toggle();
            }
          }

          scope.childrenMatchString = function (array, term) {
            return Array.isArray(array) && array.some(function (item) {
              return item.name.indexOf(term) > -1;
            });
          };

          function removeEscListener() {
            if (escListenerResolver) {
              escListenerResolver();
              escListenerResolver = null;
            }
          }

          function hide() {
            removeEscListener();
            [listItem, listMask].forEach(l => l.addClass('zero-opacity'));
          }

          scope.$on('$destroy', removeEscListener);
        },
        template: '<div>\
        <div class="combobox-list-item-mask zero-opacity" disable-click-effect="true" ng-click="close(false, $event)"></div>\
        <div class="combobox-list-part" \
                    ng-class="{\'combobox-with-scrollbar\': hasScroll, \'combobox-list-mode\': listMode}">\
                            <div class="relative-container combobox-list-part-items-container" bindonce="allowAddNewCategory">\
                                <combobox-add-new placeholder="{{::addNewCategoryText}}" ng-class="{\'blurred-list\': notInFocus}" bo-if="allowAddNewCategory" add-item-action="addNewCategoryItem"></combobox-add-new>\
                                <combobox-search ng-class="{\'blurred-list\': notInFocus}" ng-if="::(!allowAddNewCategory && searchable)"></combobox-search>\
                                <div class="relative-container combobox-list-part-items-wrapper {{::(allowAddNewCategory || searchable ? \'combobox-list-add-new-mode\' : \'\')}}"> \
                                    <div class="combobox-items-wrapper" ng-class="{\'partialy-visible\': (isLoading || externalLoading)}">\
                                        <ul class="combobox-items-wrapper-ul" ng-show="!searchTerm || searchTerm.length < 1 || !flatValues">\
                                            <li class="combobox-list-item-li" ng-repeat="value in values" \
                                                ng-hide="searchable && !value.isSelected && searchTerm.length > 0 && (!value.name.containsIgnoreCase(searchTerm) && !childrenMatchString(value.value, searchTerm))" \
                                                ng-class="{\'partialy-visible\': value.isBlurred}"> \
                                                <combobox-item item="value" decorateitem="decoratorref()" class="combobox-main-item" value-select="select" \
                                                    list-item="true" child-focus="childFocusStateChanged" focus-item-parent="onListFocused"\
                                                    allow-category-selection="allowCategorySelection" \
                                                    force-concrete-selection="forceConcreteSelection" \
                                                    allow-add-new-category="allowAddNewCategory"\
                                                    add-new-category-action="addNewCategoryAction"\
                                                    partially-select="multiSelect" select-all-list-items="selectAll"\
                                                    toggle-parent="close"\
                                                    force-multi-selection="forceMultiSelection">\
                                                </combobox-item>\
                                            </li>\
                                        </ul>\
                                        <ul class="combobox-items-wrapper-ul single-values" ng-if="flatValues && searchTerm && searchTerm.length > 0">\
                                            <li class="combobox-list-item-li" ng-repeat="value in flatValues" \
                                                ng-hide="searchable && !value.isSelected && searchTerm.length > 0 && (!value.name.containsIgnoreCase(searchTerm) && !childrenMatchString(value.value, searchTerm))" \
                                                ng-class="{\'partialy-visible\': value.isBlurred}"> \
                                                <combobox-item item="value" decorateitem="decoratorref()" class="combobox-main-item" value-select="select" \
                                                    list-item="true" child-focus="childFocusStateChanged" focus-item-parent="onListFocused"\
                                                    allow-category-selection="allowCategorySelection" \
                                                    force-concrete-selection="forceConcreteSelection" \
                                                    allow-add-new-category="allowAddNewCategory"\
                                                    add-new-category-action="addNewCategoryAction"\
                                                    partially-select="multiSelect" select-all-list-items="selectAll"\
                                                    toggle-parent="close"\
                                                    force-multi-selection="forceMultiSelection">\
                                                </combobox-item>\
                                            </li>\
                                        </ul>\
                                    </div>\
                             </div>\
                             <div class="combobox-list-arrow-pointer">\
                                <div class="combo-arrow"></div>\
                                <div class="combo-internal-arrow"></div>\
                             </div>\
                             <div class="absolute-container loading-image-spinner" ng-class="{\'zero-opacity\': !(isLoading || externalLoading)}">\
                                    <div class="combobox-loading-mask absolute-container"></div>\
                                    <div class="centered-div-container">\
                                        <div class="centered-div-content">\
                                            <div round-circle-loader="(isLoading || externalLoading)"></div>\
                                        </div>\
                                    </div>\
                                </div>\
                            </div>\
                        </div>\
                      </div>\
            '
      };
    }]);

  angular.module('easybizy.common.common-controls').directive('comboboxItem', [
    'localize', '$timeout', '$compile', function (localize, $timeout, $compile) {
      var checkbox = '<checkbox class="combobox-multi-select-checkbox" active="item.isSelected" clicked="comboClicked"></checkbox>';

      return {
        restrict: 'E',
        replace: true,
        scope: {
          hideCategoryIcon: '=', // in-case it is the first item, don't show the category icon.
          decorateitem: '&',
          item: '=',
          valueSelect: '&',
          listItem: '=',
          childFocus: '=',
          selectAllListItems: '&',
          allowCategorySelection: '=',
          forceConcreteSelection: '=',
          forceMultiSelection: '=',
          allowAddNewCategory: '=',
          addNewCategoryAction: '=',
          focusItemParent: '&',
          disabled: '=',
          toggleParent: '=',
          partiallySelect: "=" // this means it is a part of a multi selectable combobox, which means is has a checkbox.
        },
        require: '^combobox',
        link: function (scope, element, attrs, comboboxCtrl) {
          scope.$watch('decorateitem', function (val) {
            if (val()) {
              scope.decorateitem()(scope, element);
            }
          });

          if (isCategory()) {
            Object.defineProperty(scope, 'selectedChildrenCount', {
              get: function () {
                return scope.item.value.reduce(function (acc, val) {
                  return acc + ((val.isSelected || comboboxCtrl.selected == val) ? 1 : 0);
                }, 0);
              }
            });
          }


          Object.defineProperty(scope, 'isSelected', {
            get: function () {
              if (!scope.item || scope.item.value == null || !comboboxCtrl.selected) {
                return false;
              }

              if (scope.item.isSelected) {
                return true;
              }

              if (!Array.isArray(comboboxCtrl.selected)) {
                return comboboxCtrl.selected == scope.item;
              }

              for (var selectedIdx in comboboxCtrl.selected) {
                if (comboboxCtrl.selected[selectedIdx].id == scope.item) {
                  return true;
                }
              }
            }
          });

          var newScope = null;
          if (isCategory()) {
            newScope = scope.$new(true, scope);
            newScope.isAllSelectedWrapper = {};
          }

          var wasExpandCategorySelected = false;
          scope.expandCategory = function () {
            wasExpandCategorySelected = true;
          };

          var wasChildListCreated = false;
          scope.childListHasItems = true;
          scope.isRightToleft = localize.isRightToLeft();

          scope.selectItem = function (value, isUserInvoked, $event) {
            // was it a checkbox click?
            if (isCategory() && isUserInvoked &&
              // in case we ALLOW selection of category, we have to make sure the expand icon was clicked.
              (scope.forceConcreteSelection || !angular.isDefined(scope.allowCategorySelection) || (scope.allowCategorySelection && wasExpandCategorySelected))) {
              wasExpandCategorySelected = false;

              if (!wasChildListCreated) {
                wasChildListCreated = true;
                scope.childListHasItems = generateChildList();
              }

              if (scope.childListHasItems) {
                newScope.isOpenWrapper.isOpen = !newScope.isOpenWrapper.isOpen;
                if (angular.isDefined(scope.childFocus)) {
                  scope.childFocus(value, newScope.isOpenWrapper.isOpen);
                }
              }
            } else if (scope.forceMultiSelection && value.value == null) {
              // scope.valueSelect()(value, isUserInvoked);
              comboboxCtrl.clear();
              scope.$evalAsync(() => scope.valueSelect()(value, isUserInvoked))
            } else if (scope.forceMultiSelection) {
              scope.item.isSelected = !scope.item.isSelected;
            } else if (scope.valueSelect()) {
              wasExpandCategorySelected = false;
              scope.valueSelect()(value, isUserInvoked);
              if (value.value == null && comboboxCtrl.hasItems()) {
                comboboxCtrl.clear();
              }

            }
          };


          // If allowing multiple selection - add checkbox,
          // unless it is the default item which shouldn't be able to be part of multiselected items.
          var compiledCheckbox = undefined;
          if (scope.partiallySelect && !(scope.item.defaultValue || scope.item.forceUniqueSelection)
            && !(!scope.allowCategorySelection && isCategory())
          ) {
            compiledCheckbox = $compile(checkbox)(scope);
            element.prepend(compiledCheckbox);

            scope.comboClicked = function (newVal, event) {
              event.stopPropagation();
              changeCheckboxValueRecursively(scope.item, newVal);

              if (scope.item.valueType === 'Category' && Array.isArray(scope.item.value) && !newVal.wasExpanded) {
                scope.item.value.forEach(function (internal) {
                  comboboxCtrl.itemStateChanged(internal)
                });

              }

              $timeout(function () {
                if (!newVal) {
                  convertParentSelectAllState(scope.item);
                }

                if (scope.valueSelect()) {
                  comboboxCtrl.raiseChangeEvent();
                }
              });
            };

            scope.$watch('item.isSelected', function (newVal, oldVal) {
              // This means that nothing really changed. (undefined --> false).
              if (!angular.isDefined(oldVal) && !newVal) {
                return;
              }

              if (angular.isDefined(newVal) && newVal != oldVal) {
                if (scope.item.value == null && scope.forceMultiSelection && angular.isDefined(scope.selectAllListItems())) {
                  scope.selectAllListItems()(newVal);
                }

                comboboxCtrl.itemStateChanged(scope.item);
              }
            });
          }

          function isCategory() {
            return scope.listItem &&
              scope.item && scope.item.hasOwnProperty("valueType") && scope.item.valueType === "Category";
          }

          function changeCheckboxValueRecursively(item, value) {
            if (item.hasOwnProperty("valueType") && item.valueType === "Category") {
              item.isSelected = value;
              angular.forEach(item.value, function (subItem) {
                subItem.parent = item;
                changeCheckboxValueRecursively(subItem, value);
              });
            } else {
              item.isSelected = value;
            }
          }

          function convertParentSelectAllState(item) {
            if (angular.isDefined(item.parent)) {
              item.parent.isSelected = false;
              if (newScope) {
                newScope.isAllSelectedWrapper.isAllSelected = false;
              }

              convertParentSelectAllState(item.parent);
            }
          }

          function generateChildList() {
            if (isCategory() && (scope.allowAddNewCategory || scope.item.value.length > 0)) {
              let compiledList;
              newScope.values = scope.item.value;
              angular.forEach(scope.item.value, function (childItem) {
                childItem.parent = scope.item;
              });

              newScope.isOpenWrapper = { isOpen: false };
              newScope.element = element.parent();
              newScope.multiSelect = scope.partiallySelect;
              newScope.forceMultiSelection = scope.forceMultiSelection;
              newScope.allowCategorySelection = scope.allowCategorySelection;
              newScope.forceConcreteSelection = scope.forceConcreteSelection;
              newScope.allowAddNewCategory = scope.allowAddNewCategory;
              newScope.addNewCategoryAction = scope.addNewCategoryAction;
              newScope.allowAddNewCategory = scope.allowAddNewCategory;
              newScope.itemdecorator = scope.decorateitem;
              newScope.parentItem = scope.item;//.hasOwnProperty('categoryValue') ? scope.item.categoryValue : -1;
              newScope.externalLoading = false;
              newScope.listMode = false;
              newScope.toggleParent = scope.toggleParent;
              newScope.isAllSelectedWrapper.isAllSelected = scope.item.isSelected;
              newScope.listValueSelected = function (value) {
                scope.selectItem(value);
              };

              compiledList = $compile(k_ComboboxListItemtemplate)(newScope);
              element.parent().append(compiledList);
              return true;
            } else {
              return false;
            }
          }

        },
        template: '<div class="combobox-list-item" ng-class="{\'selected-combo\': isSelected || selectedChildrenCount > 0}" bindonce="isRightToleft" \
                        ng-click="selectItem(item, true, $event)" click-effect-target=".combobox-value-part">\
                        <div class="combobox-list-item-concrete-value"><span ng-if="selectedChildrenCount > 0">({{selectedChildrenCount}})</span>{{item.name}}</div>\
                        <div class="combobox-list-item-extend-icon icon icon-dropdown" right-to-left="icon-right-open-big" ng-if="childListHasItems" \
                             ng-click="expandCategory(item, $event)"\
                             ng-show="item.valueType === \'Category\' && !hideCategoryIcon"></div>\
                    </div>'
      };
    }]);


  angular.module('easybizy.common.common-controls').directive('comboboxAddNew', [
    'localize', function (localize) {
      return {
        restrict: 'E',
        scope: {
          itemAdded: '&',
          externalPlaceholder: '@placeholder',
          addItemAction: '&'
        },
        replace: true,
        link: function (scope, element) {
          scope.input = "";
          scope.placeholder = scope.externalPlaceholder.length > 0 ? localize.getLocalizedString(scope.externalPlaceholder)
            : localize.getLocalizedString("_CreateNew_");
          scope.addItem = function () {
            if (scope.input.length >= 2) {
              scope.addItemAction()(scope.input);
              scope.input = "";
            }
          };

          scope.keyPressed = function (e) {
            if (e.which == 13) {
              scope.addItem();
            }
          };
        },
        template: '<div class="combobox-add-new-item-wrapper">\
                        <div class="combobox-add-new-item-input-wrapper">\
                            <input class="combobox-add-new-item-input" ng-model="input" ng-keypress="keyPressed($event)" placeholder="{{placeholder}}"/>\
                        </div>\
                        <div class="combobox-add-new-item-button" data-i18n="_Create_" ng-click="addItem()"\
                             ng-class="{\'partialy-visible\': input.length < 2}"></div>\
                   </div>'
      };
    }]);

  angular.module('easybizy.common.common-controls').directive('comboboxSearch', [
    'localize', '$timeout', function (localize, $timeout) {
      return {
        restrict: 'E',
        replace: true,
        require: '^combobox',
        link: function (scope, element, attrs, comboboxCtrl) {
          scope.placeholder = localize.getLocalizedString('_SearchItem_');
          var searchText = '';
          scope.clear = function () {
            scope.inputWrapper.text = '';
          };

          scope.inputWrapper = {};
          Object.defineProperty(scope.inputWrapper, 'text', {
            get: function () {
              return searchText;
            },
            set: function (newValue) {
              searchText = newValue;
              comboboxCtrl.setSearchText(newValue);
            }
          });
        },
        template: '<div class="combobox-add-new-item-wrapper">\
                        <div class="combobox-add-new-item-input-wrapper">\
                            <input class="combobox-add-new-item-input" ng-model="inputWrapper.text" placeholder="{{::placeholder}}"/>\
                        </div>\
                        <div class="combobox-add-new-item-button" data-i18n="_ClearSearch_" ng-click="clear()"\
                             ng-class="{\'partialy-visible\': input.length < 1}"></div>\
                   </div>'
      };
    }]);


  var k_ComboboxListItemtemplate = '<combobox-list values="values" is-open-wrapper="isOpenWrapper" selected="selected" value-selected="listValueSelected"\
			   relative-to="element" decoratorref="itemdecorator()" multi-select="multiSelect" force-multi-selection="forceMultiSelection"\
               allow-category-selection="allowCategorySelection" force-concrete-selection="forceConcreteSelection" allow-add-new-category="allowAddNewCategory" add-new-category-action="addNewCategoryAction"\
               add-new-category-text="{{addNewCategoryText}}" parent-item="parentItem" flat-values="flatValues"\
               external-loading="isLoading" list-mode="listMode" searchable="searchable" search-term="searchTextWrapper.text"\
               is-all-selected-wrapper="isAllSelectedWrapper"\
			   ></combobox-list>';

  var k_ComboboxTemplate = '<div class="combobox cursor-pointer prevent-selection" \
                               bindonce="allowAddNewCategory" bo-class="{\'combobox-with-add-new-cateogry\': allowAddNewCategory}">\
                        <div class="combobox-default-combo-value-wrapper"\
                        ng-class="{\'default-combo-value\': selected.defaultValue || (!selected.value && selected.length < 1), \'partialy-visible\':disabled, \'dynamic-combobox\': proxy}">\
                            <div class="combobox-value-part" ng-click="toggleCombo()">\
                                <div class="multiple-selection-item clearfix" ng-show="multiSelected && multiSelected.length > 0">\
                                    <div class="icon icon-open-check"></div>\
                                    <div class="ui-ellipsis multi-selection-item-text" ng-if="multiSelected.length > 1" ng-bind="getMultiSelectedValues()"></div>\
                                    <div class="" ng-if="multiSelected.length == 1" ng-bind="multiSelected[0].name"></div>\
                                </div>\
                                <combobox-item ng-show="!multiSelected || multiSelected.length === 0" item="selected" disabled="disabled"\
                                               decorateitem="itemdecorator()" hide-category-icon="true"></combobox-item>\
                                <div \
                                     class="combobox-toggle-list-icon icon" ng-class="isOpenWrapper.isOpen ? \'icon-dropdown\' : \'icon-dropdown\'"></div>\
                            </div>\
                        </div>\
                      </div>';
})();
