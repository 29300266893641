'use strict';

import './online.scheduling.less';
import './online-scheduling-availability/online.scheduling.availability.directive';
import './online-scheduling-availability/online.scheduling.availability.store';
import './online-scheduling-configurations/online.scheduling.configurations.directive';
import './online-scheduling-employees/online.scheduling.employees.directive';
import './online-scheduling-services/online.scheduling.services.directive';
import './online-scheduling-theme-picker/online.scheduling.theme.picker.directive';

(function () {
  angular.module('easybizy.online.scheduling').controller('OnlineSchedulingController',
    function ($scope, Repository, $modal, localize, stateManager, employeesCrudFactory, confirmService,
      $timeout,
      toolbar, $rootScope, onlineSchedulingAvailabilityStore, configuration) {

      var SERVICES_VIEW = 'services';
      var AVAILABILITIES_VIEW = 'availability';

      var addAction = {
        name: 'add',
        action: function (service) {
          return createOrUpdateOnlineService(service);
        },
        data: {
          template: require('../../modal-views/online-scheduling-add-service/online.scheduling.add.service.html'),
          controller: 'OnlineSchedulingAddServiceController'
        }
      };

      var magicAdd = {
        name: 'magicAdd',
        action: function (onlineServices) {
          return createOrUpdateOnlineServicesBatch(onlineServices);
        },
        data: {
          template: require('../../modal-views/online-scheduling-add-services/online.scheduling.add.multiple.services.html'),
          controller: 'OnlineSchedulingAddMultipleServicesController'
        }
      };

      var magicCreateShifts =
      {
        name: 'custom',
        icon: 'icon icon-magic-add',
        action: function () {
          confirmService.confirm(localize.getLocalizedString('_AlignBusinessHoursWithShifts_'),
            null,
            function () {
              $scope.globalViewLoadingWrapper.loadingCounter += 1;

              return Repository.Custom('OnlineScheduling').alignBusinessHoursWithOnlineBooking()
                .then(function () {
                  $scope.$evalAsync(function () {
                    $scope.globalViewLoadingWrapper.loadingCounter -= 1;
                    onlineSchedulingAvailabilityStore.reload();
                  });
                }).catch(function (e) {
                  $scope.globalViewLoadingWrapper.loadingCounter -= 1;
                  toastr.error(e.Message);
                });

            },
            function () {
              // DOES NOTHING.
            }
          );
        }
      };

      toolbar.set([]);

      /***************************** VIEW HANDLER *****************************/

      stateManager.registerStateChanged('OnlineScheduling', stateChanged);
      $scope.views = [
        {
          viewType: AVAILABILITIES_VIEW,
          resourceType: AVAILABILITIES_VIEW,
          name: localize.getLocalizedString('_AvailabilityHeader_')
        },
        {
          viewType: SERVICES_VIEW,
          resourceType: SERVICES_VIEW,
          name: localize.getLocalizedString('_Services_')
        },
        {
          viewType: 'general',
          resourceType: 'general',
          name: localize.getLocalizedString('_General_')
        }
      ];

      $scope.$watch('selectedViewType.selected', function (newVal) {
        if (newVal) {
          stateManager.setState('OnlineScheduling', {
            viewType: newVal.viewType
          }, false, false);

          if (newVal.viewType === SERVICES_VIEW) {
            toolbar.set([addAction, magicAdd]);
          } else if (newVal.viewType === AVAILABILITIES_VIEW) {
            toolbar.set([magicCreateShifts]);
          } else {
            toolbar.set([]);
          }
        }
      })

      $scope.selectedViewType = {};

      // Handle URL changes and persisting state according to tabs.
      function stateChanged(state) {
        var currentResourceType = $scope.views.filter(function (resourceType) {
          return resourceType.viewType === state.viewType
        })[0];

        if (currentResourceType && $scope.selectedViewType.selected !== currentResourceType) {
          $scope.$evalAsync(function () {
            $scope.selectedViewType.selected = currentResourceType
          });
        }
      }

      /***************************** END OF VIEW HANDLER *****************************/

      /***************************** CONFIGURATIONS AND EMPLOYEES *****************************/
      loadConfigurations();
      loadEmployees();

      function loadConfigurations() {
        return Repository.Custom('OnlineScheduling').loadConfigurations().then(function (onlineConfigurations) {
          $scope.onlineConfigurations = onlineConfigurations;
          $scope.allEmployeesEnabled = { enabled: onlineConfigurations.EnableAllEmployees };
          $scope.servicesMediator.showServiceDescription = $scope.onlineConfigurations.ShowServiceDescription

        }).catch(function (err) {
          toastr.error(localize.getLocalizedString('_ErrorLoadingEntities_') + ' - ' + err.Message);
        });
      }


      function loadEmployees() {
        Repository.Entity('Employee', true).query().expand('Image').filter('VisibleOnCalendar eq true').get().then(function (employees) {
          $scope.onlineEmployees = employees.value;
          $scope.onlineEmployees.forEach(function (employee) {
            employee.enabled = employee.ValidForOnlineBooking
          });
        });
      }

      $scope.saveConfigurationsWrapper = { isSaving: false };
      $scope.saveConfigurationsAndEmployees = function () {
        $scope.saveConfigurationsWrapper.isSaving = true;
        var validEmployees = $scope.onlineEmployees.map(function (x) {
          return x.ValidForOnlineBooking ? x.EmployeeId : 0
        });

        $scope.onlineConfigurations.EnableAllEmployees = $scope.allEmployeesEnabled.enabled;
        var toUpdate = { OnlineBookingSettings: $scope.onlineConfigurations, ValidEmployeesMap: validEmployees };

        $scope.globalViewLoadingWrapper.loadingCounter += 1;
        return Repository.Custom('OnlineScheduling').saveConfigurationsAndEmployees(toUpdate).then(function () {
          $scope.globalViewLoadingWrapper.loadingCounter -= 1;
          $scope.saveConfigurationsWrapper.isSaving = false;
          $scope.$digestIfNeeded();
          toastr.success(localize.getLocalizedString('_ConfigurationSaved_'));
        }).catch(function (err) {
          $scope.globalViewLoadingWrapper.loadingCounter -= 1;
          $scope.saveConfigurationsWrapper.isSaving = false;
          toastr.error(localize.getLocalizedString('_ErrorSavingEntity_') + ' - ' + err.Message);
        });
      };

      Repository.Custom('Configurations').loggedInUser().then((contextMeta) => {
        $scope.$evalAsync(function () {
          $scope.onlineScheduleLink = `${window.onlineBookingURI}/${contextMeta.context.name.toLowerCase()}`;
          $scope.configMediator.isWhatsappSupported = contextMeta.isWhatsappSupported ?? false;
        })
      });

      $scope.copyLink = function () {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($scope.onlineScheduleLink).select();
        document.execCommand("copy");
        $temp.remove();
        toastr.success(localize.getLocalizedString('_LinkedCopied_'));
      };


      /***************************** SERVICES *****************************/
      $scope.dirtyServices = [];
      $scope.isSavingServicesWrapper = { isSaving: false };
      $scope.updateServices = function () {
        var servicesDelta = $scope.dirtyServices.map(function (service) {
          var toReturn = Object.assign({}, service);
          delete toReturn.CreatedOn;
          delete toReturn.ServiceMetadatas;
          delete toReturn.State;
          delete toReturn.dirty;
          delete toReturn.id;
          delete toReturn.LastUpdated;

          return toReturn;
        });

        $scope.isSavingServicesWrapper.isSaving = true;

        return Repository.Custom('OnlineScheduling').updateMultipleServices(servicesDelta)
          .then(function () {
            $scope.$evalAsync(function () {
              $scope.isSavingServicesWrapper.isSaving = false;
            });

            toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'));
          }).catch(function (err) {
            $scope.$evalAsync(function () {
              $scope.isSavingServicesWrapper.isSaving = false;
            });

            toastr.error(localize.getLocalizedString('_ErrorUpdatingShifts_') + ' - ' + err.Message)
          });
      };


      // We expose it to the directive so we can edit the list from outside.
      $scope.services = [];

      $scope.editOnlineService = function (service, callback) {
        $scope.$emit('fire-toolbar-command', {
          name: 'add', params: {
            service: service,
            onUpdateCallback: callback
          }
        });
      };

      $scope.removeOnlineService = function (iService) {
        return Repository.Custom('OnlineScheduling').removeOnlineService(iService).then(function () {
        }).catch(function (err) {
          toastr.error(localize.getLocalizedString('_ErrorDeletingEntity_') + ' - ' + err.Message);
        });
      };

      const serviceTimeInterval = configuration.get().CalendarSettings.MinimumMeetingLengthInMinutes.Value
      $scope.servicesMediator = {
        serviceTimeInterval: serviceTimeInterval,
        editOnlineService: $scope.editOnlineService,
        removeOnlineService: $scope.removeOnlineService
      }

      $scope.configMediator = {};
      // Handling Loading.
      $scope.globalViewLoadingWrapper = { loadingCounter: 0 };
      const schedulingLoadingEventDelegate = $rootScope.$on('online-scheduling-loading', function (_, isLoading) {
        $timeout(function () {
          $scope.globalViewLoadingWrapper.loadingCounter += isLoading ? 1 : -1;
        });
      });

      $scope.$watch('globalViewLoadingWrapper.loadingCounter', (newVal) => {
        $rootScope.$emit('page-in-progress', newVal > 0);
      });

      $scope.$on('$destroy', () => {
        schedulingLoadingEventDelegate();
      });


      function createOrUpdateOnlineService(onlineService) {
        // Send
        return Repository.Custom('OnlineScheduling').createOrUpdateOnlineService(onlineService)
          .then(function (service) {
            toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'));
            $scope.servicesMediator.addItemToTable(service);
            return service;
          });
      }

      function createOrUpdateOnlineServicesBatch(onlineServices) {
        return Repository.Custom('OnlineScheduling').createOrUpdateOnlineServicesBatch(onlineServices)
          .then(function (createdServices) {
            toastr.success(localize.getLocalizedString('_EntitySavedSuccessfully_'));
            createdServices.forEach(t => $scope.servicesMediator.addItemToTable(t))
            return createdServices;
          });
      }

      /***************************** END OF SERVICES *****************************/

    });

}());
