import './data-filter.directive.less'
import './data-filter.model';

angular.module('easybizy.common.data.filter').factory('repeatingResolver', function (dataFilterResolver) {
  var repeatingPossiblites = {};
  var listeners = [];
  var currentLongestTime = null;

  return {
    repeatArrayChanged: repeatArrayChanged,
    register: register,
    clear: clear
  };

  function register(func) {
    listeners.push(func);
    return new function () {
      var pointerFunc = func;
      this.clear = function () {
        var index = listeners.indexOf(pointerFunc);
        listeners.splice(index, 1);
      };
    };
  }

  function repeatArrayChanged(possibilities) {
    var longestTime = null;
    
    let disabledPossibilities = possibilities.filter(function (possibleTime) { return possibleTime === "Disabled";});
    if (disabledPossibilities && disabledPossibilities.length > 0) {
      possibilities.length = 0;
    }


    let forcedHardPossibilities = possibilities.filter(function (possibleTime) { return possibleTime?.startsWith("ForceHard_");});
    if (forcedHardPossibilities && forcedHardPossibilities.length > 0) {
      possibilities = forcedHardPossibilities.map(function (possibleTime) {
        return possibleTime.replace("ForceHard_", "");
      });
    }

    let forcedPossibilities = possibilities.filter(function (possibleTime) { return possibleTime?.startsWith("Force_");});
    if (forcedPossibilities && forcedPossibilities.length > 0) {
      possibilities = forcedPossibilities.map(function (possibleTime) {
        return possibleTime.replace("Force_", "");
      });
    }
    angular.forEach(possibilities, function (possibleTime) {
      if (!longestTime || dataFilterResolver.compareTimes(possibleTime, longestTime)) {
        longestTime = possibleTime;
      }
    });

    if (longestTime != currentLongestTime) {
      currentLongestTime = longestTime;
      notify();
    }
  }


  function clear() {
    for (var member in repeatingPossiblites)
      delete repeatingPossiblites[member];
  }

  function notify() {
    angular.forEach(listeners, function (listener) {
      listener(currentLongestTime);
    });
  }
});

angular.module('easybizy.common.data.filter').directive('filterData', [
  'dataFilterResolver', 'repeatingResolver', function (dataFilterResolver, repeatingResolver) {
    return {
      restrict: 'E',
      transclude: true,
      replace: true,
      scope: {
        filters: '=',
        resolvedFilters: '=',
        minRepeatLength: "=",
        resolvedFilterChangedDelegate: '='
      },
      controller: function ($scope) {
        if ($scope.minRepeatLength) {
          repeatingResolver.register(function (longestLength) {
            $scope.minRepeatLength.length = longestLength;
          });
        }

        this.fieldStateChanged = function (data, fieldName, isTrivial) {
          if (isTrivial) {
            $scope.resolvedFilters.remove(data, true);
          } else {
            $scope.resolvedFilters.remove(data, true);
            $scope.resolvedFilters.push(data);
          }

          if ($scope.resolvedFilterChangedDelegate) {
            $scope.resolvedFilterChangedDelegate($scope.resolvedFilters);
          }

          var affectingScheduleRepeat = [];
          $scope.resolvedFilters.forEach(function (filter) {
            for (var filterKey in filter.fields) {
              var internalField = filter.fields[filterKey];
              if (internalField.affectsSchedule) {
                affectingScheduleRepeat.push(internalField.value.possibleRepeat);
              }
            }
          });

          repeatingResolver.repeatArrayChanged(affectingScheduleRepeat);

        };

      },
      link: function (scope, element, attrs, filterDataCtrl) {
        scope.filtersData = [];
        scope.$watch('filters', function (newVal, oldVal) {
          repeatingResolver.clear();
          if (scope.filters && scope.filters.length > 0) { // if filters exists
            // Then set the filters to the new ones.
            scope.filtersData.length = 0;
            if (typeof scope.filters === 'string') { // if string filters split and create.
              var splitted = scope.filters.split(',');
              angular.forEach(splitted, function (filterName) {
                filterName = filterName.replace(/\s/g, '');
                var filterData = dataFilterResolver.get(filterName, true/*scope.resolvedFilters.length === 0*/);
                scope.filtersData.pushAll(filterData);
              });
            } else if (typeof scope.filters === 'object') {
              angular.forEach(scope.filters, function (filter) {
                scope.filtersData.push(filter);
              });
            }
          } else {
            if (scope.filtersData) {
              scope.filtersData.length = 0;
            }
            if (scope.resolvedFilters) {
              scope.resolvedFilters.length = 0;
            }
          }
        });


      },
      template: require('./data-filter.directive.html')
    };
  }]);


angular.module('easybizy.common.data.filter').directive('filterDataRow', [
  '$compile', function ($compile) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        filter: "=filterModel"
      },
      require: '^filterData',
      link: function (scope, element, attrs, filterDataCtrl) {
        element.prepend('<span>');
        var finalString = scope.filter.text.replace(/(\][\w\u0590-\u05FFa-zA-Z0-9 ]{2,}\[|^[\w\u0590-\u05FFa-zA-Z0-9 ]{2,}|[\w\u0590-\u05FFa-zA-Z0-9]{2,}$)/gi, function (match) {
          var toMatch = "";
          if (match.charAt(0) === ']') {
            toMatch = "] <span>" + match.substring(1, match.length - 1) + "</span> [";
          } else {
            toMatch = "<span>" + match + "</span>";
          }

          return toMatch;
        });


        finalString = finalString.replace(/\[[a-z]*\]/gi, function (match) {
          var matchToEval = match.substring(1, match.length - 1);
          var insertedCombo =
            '<combobox lazy="true"  values="filter.fields.' + matchToEval + '.options" \
              force-concrete-selection="filter.forceConcreteSelection" \
              allow-category-selection="true" \
              selected="filter.fields.' + matchToEval + '.value" \
              multi-selected="filter.fields.' + matchToEval + '.values" \
              multi-select="!!filter.fields.' + matchToEval + '.multiSelect"></combobox>';
          return insertedCombo;
        });

        var compileCombobox = $compile(finalString)(scope);
        element.prepend(compileCombobox);
        element.append('</span>');

        var mainFilter = null;
        angular.forEach(scope.filter.fields, function (field, key) {
          if (Object.keys(scope.filter.fields).length == 1 || field.main) {
            mainFilter = field;
          }

          scope.$watch(('filter.fields.' + key + '.value'), function (newVal, oldVal) {
            scope.isTrivialFilter = mainFilter.value.defaultValue || newVal.defaultValue;
            if (newVal !== oldVal || !scope.isTrivialFilter) {
              filterDataCtrl.fieldStateChanged(scope.filter, key, scope.isTrivialFilter);
            }
          });
        });

        scope.isTrivialFilter = false;
      },
      template: '<div class="filter-data-row" ng-class="{\'trivial-filter\': isTrivialFilter}">\
                       </div>'

    };
  }]);
