import './online.scheduling.add.multiple.services.less'

(function () {
  angular.module('easybizy.easybizyModalViewsModel').controller('OnlineSchedulingAddMultipleServicesController',
    function ($scope, $modalInstance, saveAction, parameters, $timeout, localize, onlineSchedulingAvailabilityStore, Repository, configuration, timeConverter) {
      $scope.loadingWrapper = { loading: false };
      loadOnlineServices();
      loadServices();
      $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
      };

      $scope.serviceMetadatasFields = [
        {
          name: '',
          type: 'Checkbox',
          allowSelectAll: true,
          sortable: true,
          fieldName: 'select'
        },
        {
          name: localize.getLocalizedString('_ServiceName_'),
          fieldName: 'OriginalName',
          sortable: false,
        },
        {
          name: localize.getLocalizedString('_ServiceRepresentativeName_'),
          fieldName: 'Name',
          sortable: false,
          type: 'ReadWriteText'
        },
        {
          name: localize.getLocalizedString("_Price_"),
          type: 'ReadWritePrice',
          valueType: 'float',
          fieldName: 'Price',
          sortable: true,
          isDecending: true,
          orderedBy: true,
          command: {
            sort: function () {
              return 'PriceFrom';
            }
          },
          mode: 'editMode'

        },
        {
          name: localize.getLocalizedString("_ForceApproval_"),
          type: 'Checkbox',
          allowSelectAll: false,
          sortable: true,
          fieldName: 'NeedsApproval'
        },
        // {
        //     name: localize.getLocalizedString("_ForceCredit_"),
        //     type: 'Checkbox',
        //     allowSelectAll: false,
        //     sortable: true,
        //     fieldName: 'NeedsCreditToken'
        // }
      ];

      [10, 20, 35, 15, 10/*, 10*/].forEach(function (val, idx) {
        $scope.serviceMetadatasFields[idx].width = val + '%';

      });

      $scope.selectAllServices = function () {

      };

      var defaultSaveText = $scope.saveText = localize.getLocalizedString('_CreateServicesForOnlineBooking_');
      var selectedServices;
      $scope.selectedCount = 0;
      $scope.dirtyChanged = function () {
        selectedServices = $scope.serviceMetadatas.filter(function (service) {
          return service.select;
        });

        $scope.selectedCount = selectedServices.length;

        if ($scope.selectedCount > 0) {
          $scope.saveText = localize.getLocalizedString('_CreateServicesCountForOnlineBooking_', $scope.selectedCount);
        } else {
          $scope.saveText = defaultSaveText;
        }
      };

      var serviceMetadatasMatch;
      var serviceMetadatas;

      function loadOnlineServices() {
        $scope.loadingWrapper.loading = true;
        Repository.Entity("OnlineBookingServiceMetadata").query().expand("ServiceMetadatas")
          .get().then(function (data) {
          serviceMetadatasMatch = [];
          data.value.forEach(function (onlineService) {
            onlineService.ServiceMetadatas.forEach(function (sm) {
              if (serviceMetadatasMatch.indexOf(sm.ServiceMetadataId) < 0) {
                serviceMetadatasMatch.push(sm.ServiceMetadataId);
              }
            });
          });

          syncServices();
        }).catch(function (e) {
          $scope.loadingWrapper.loading = false;
          toastr.error(e.Message);
        });
      }

      function loadServices() {
        $timeout(function () {
          Repository.Custom('Calendar').resolveResources().then(function (results) {
            const serviceMetadatas = results.byCategories ? results.byCategories.reduce((acc, category) => acc.concat(category.ServiceMetadatas), []) : results.serviceMetadatas;

            setServices(serviceMetadatas.map(x => ({ ...x, OriginalName: x.Name })));
          }).catch(function (e) {
            $scope.loadingWrapper.loading = false;
            toastr.error(e.Message);
          });
        }, 300);


        function setServices(loadedServiceMetadatas) {
          serviceMetadatas = [];
          loadedServiceMetadatas.forEach(function (serviceMetadataOrCategory) {
            if (!serviceMetadataOrCategory.servicesByCategories) {
              serviceMetadatas.push(serviceMetadataOrCategory);
            } else {
              serviceMetadatas.pushAll(serviceMetadataOrCategory.ServiceMetadatas);
            }
          });

          syncServices();
        }
      }

      function syncServices() {
        if (serviceMetadatas && serviceMetadatasMatch) {
          $scope.$evalAsync(function () {
            $scope.loadingWrapper.loading = false;
            $scope.serviceMetadatas = [];
            serviceMetadatas.forEach(function (serviceMetadata) {
              if (serviceMetadatasMatch.indexOf(serviceMetadata.ServiceMetadataId) < 0) {
                $scope.serviceMetadatas.push(serviceMetadata);
              }
            });
          });
        }
      }


      $scope.save = function () {
        $scope.loadingWrapper.saving = true;
        var selectedServices = $scope.serviceMetadatas.filter(function (service) {
          return service.select;
        });
        var adaptedServices = selectedServices.map(function (serviceMetadata) {
          var mins = moment.duration(serviceMetadata.Duration).asMinutes();
          return {
            PriceFrom: serviceMetadata.Price,
            PriceTo: serviceMetadata.Price,
            ServiceRepresentativeName: serviceMetadata.Name,
            Description: serviceMetadata.Name,
            Duration: mins,
            NeedsApproval: serviceMetadata.NeedsApproval || false,
            NeedsCreditToken: serviceMetadata.NeedsCreditToken || false,
            ServiceMetadatas: [serviceMetadata]
          }
        });

        saveAction(adaptedServices).then(function () {
          $scope.loadingWrapper.saving = false;
          $scope.cancel();
        }).catch(function (err) {
          $scope.loadingWrapper.saving = false;
          toastr.error(localize.getLocalizedString('_ErrorSavingEntities_') + ' - ' + err.Message);
          $scope.$digestIfNeeded();
        });
      }

    });

}());
