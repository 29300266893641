import './customer-picker.directive.less';

angular.module('easybizy.common.common-controls').controller('CustomerPickerController', function ($scope, $modalInstance, promotionData, saveSelectedCustomers, cancel, closeDialogDelegate) {
  $scope.filters = promotionData.filters;
  $scope.promotionType = promotionData.promotionType;
  $scope.saveSelectedCustomers = saveSelectedCustomers;
  $scope.cancelAction = cancel;
  closeDialogDelegate.close = $modalInstance.close;

});

angular.module('easybizy.common.common-controls').directive('customerPicker', ['Repository', '$http', 'localize', '$q', function (Repository, $http, localize, $q) {
  return {
    restrict: 'E',
    transclude: true,
    replace: true,
    scope: true,
    link: function (scope, element, attrs) {
      scope.useFilter = true;
      scope.$watch('selectAll', function (newVal) {
        selectAllCustomers(newVal);
      });

      scope.isLoadingWrapper = {};
      scope.isLoadingWrapper.isLoading = false;

      scope.saveSelection = function () {
        scope.saveSelectedCustomers(scope.customers.filter(x => x.isMarked))
      };

      function selectAllCustomers(isToSelect, customers) {
        angular.forEach(customers ? customers : scope.customers, function (x) {
          x.isMarked = isToSelect;
        });
      }

      scope.noItemsFound = false;
      var deferred = $q.defer();
      var decorator = function (data) {
        angular.forEach(data, function (customer) {
          customer.EntityType = "Customer";
        });

        return { results: data };
      };

      scope.customers = [];
      scope.api = Repository.Custom("PromotionsRepository").filteredCustomer(scope.promotionType, decorator);
      scope.api.data(scope.filters);
      scope.api.filters = [];
      scope.api.doneLoadingCallback = function (customers) {
        scope.noItemsFound = customers.length == 0;
        if (scope.useFilter) {

          selectAllCustomers(true, customers);
        }
      };

      scope.$watch('useFilter', function (newVal, oldVal) {
        if (newVal !== oldVal) {
          scope.api.data(!newVal ? [] : scope.filters);
          scope.api.forceReload();
        }
      });

      scope.cancel = function () {
        scope.cancelAction();
      };
    },
    template: require('./customer-picker.directive.html')
  };
}]);
