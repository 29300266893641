<template>
  <div>
    <div class="px-4 pb-4 flex items-center">
      <div class="pe-4">{{ $t('_View_') }}</div>
      <ButtonsGroup :options="viewTypeOptions" v-model="viewType" :bordered="false" :spaceEvenly="true"
        class="evc-background--default me-4" />
      <div class="flex items-center ms-2">
        <div v-if="['inventory', 'pricelists'].includes(viewType)" class="me-2">{{ $t('_Actions_') }}</div>
        <button class="background--primary p-2 rounded-md" v-if="viewType === 'inventory'"
          :class="!saveEnabled && 'evc--disabled'" :disabled="!saveEnabled" @click="saveInventory">
          {{ $t('_Save_') }}
        </button>
        <button class="background--primary p-2 rounded-md" @click="addNewPriceList()" v-if="viewType === 'pricelists'">
          {{
            $t('_AddPriceList_')
          }}
        </button>
      </div>
    </div>
    <Grid :dataSource="dataSource" v-bind="gridProps"></Grid>
  </div>
</template>

<script>
import { ButtonsGroup, Grid } from "@tomeravni/easybizy-vue-components";

import { ServiceMetadatasDS, ProductMetadatasDS, IngredientsDS, ExternalVouchersDS, PriceListInventoryDS, UpdateInventoryDS ,InventoryHistoryDS} from "./inventory-ds";
import { getSafely } from "@tomeravni/easybizy-js-common/common";
import {resolveDefaultGridLabels} from "../../vue/vue.helpers";


export default {
  components: {
    Grid,
    ButtonsGroup
  },
  props: {
    mediator: {
      type: Object
    }
  },
  data() {
    const labels = resolveDefaultGridLabels(this.$t);

    return {
      viewTypeOptions: [
        {
          text: this.$t('_Services_'), value: 'services'
        },
        {
          text: this.$t('_Products_'), value: 'products'
        },
        this.mediator.showIngredients ?
          {
            text: this.$t('_Chemistry_'), value: 'ingredients'
          } : null,
        , this.mediator.showExternalVouchers ?
          {
            text: this.$t('_ExternalVouchers_'), value: 'externalvouchers'
          } : null,
        , {
          text: this.$t('_Inventory_'), value: 'inventory'
        },
        {
          text: this.$t('_InventoryHistory_'), value: 'inventoryHistory'
        },
        this.mediator.showPriceLists ?
          {
            text: this.$t('_PriceLists_'), value: 'pricelists'
          } : null,
      ].filter((x) => !!x),
      viewType: this.mediator.viewType,
      dataSource: null,
      gridProps: {
        freezeHeader: true,
        hideBorder: false,
        elevated: true,
        pickColumns: true,
        rowHeightMode: 'tight',
        backgroundColor: 'auto',
        allowSelection: true,
        preventScrollTopButton: false,
        enableFilter: true,
        isFilterVisible: true,
        forceFullHeight: true,
        labels
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.mediator.onLoadSort) {
        this.dataSource.reverseSort(this.mediator.onLoadSort);
        if (!this.mediator.onLoadQuery) {
          this.dataSource.initialize();
        }
      }

      if (this.mediator.onLoadQuery) {
        this.dataSource.reverseFilter(this.mediator.onLoadQuery);
      }
    })
  },
  methods: {
    addNewPriceList() {
      this.mediator.addPriceList()
        .then((res) => {
          this.updateDS(this.viewType);
        })
    },
    saveInventory() {
      this.confirmService.prompt(
        /*title*/this.$t('_Summary_'),
        `${this.$t('_SumOfProducts_')}: ${this.dataSource.selectionCount} \n`
        + this.dataSource.selectedItems.map((x) => x.QuantityAdded + '    ' + x.Label).join('\n'),
        this.$t('_InvoiceRelated_'),
        /*onOk*/(invoiceOrTitle) => {
          const batchToSend = {
            SupplyRelatedInvoice: invoiceOrTitle,
            InventoryUpdateItems: this.dataSource.selectedItems.map((p) => ({
              Id: p.Id,
              QuantityAdded: p.QuantityAdded
            }))
          }
          this.Repository.Custom("EntitiesLazyRepository").updateInventory(batchToSend)
            .then((result) => {
              this.clearCashRegisterCache();
              this.dataSource.clearSelection();
              this.dataSource.initialize();
              this.toastr.success(this.$t('_InventoryUpdated_'));
            })
            .catch((e) => {
              this.toastr.error(this.$t("_ErrorUpdatingItems_"));
            });
        },
        function () {
        },
        (value) => {
          return value.trim().length > 2 ? null : this.$t('_LengthMustBeAtLeastChars_', 2)
        },
      );
    },
    updateDS(viewType) {
      let dataSource;
      const api = this.Repository.Entity('ProductOrService').query();
      const inventoryHistoryApi = this.Repository.Custom("QueryableTable").inventoryHistory();
      api.setOnlineCount(true);
      api.preventAutoLoad = true;
      /// Todo: Add to P/S Color field.
      api.queryParams('getCategory', 'true');

      if (['pricelists', 'services', 'products', 'ingredients', 'externalvouchers','inventoryHistory'].includes(viewType)) {
        if (viewType === 'pricelists') {
          dataSource = new PriceListInventoryDS(api, this.$t, this.mediator, this.Repository, this.$state);
        } else if (viewType === 'services') {
          dataSource = new ServiceMetadatasDS(api, this.$t, this.mediator, this.Repository, this.$state);
        } else if (viewType === 'products') {
          dataSource = new ProductMetadatasDS(api, this.$t, this.mediator, this.Repository, this.$state);
        } else if (viewType === 'ingredients') {
          dataSource = new IngredientsDS(api, this.$t, this.mediator, this.Repository, this.$state);
        } else if (viewType === 'externalvouchers') {
          dataSource = new ExternalVouchersDS(api, this.$t, this.mediator, this.Repository, this.$state);
        } else if (viewType === 'inventoryHistory') {
          dataSource = new InventoryHistoryDS(inventoryHistoryApi, this.$t, this.mediator, this.Repository, this.$state);
        }

        dataSource.on('modelChange', ({ column, entity, prevValue, newValue, validationErrors }) => {
          const change = `${column.fieldName}: ${JSON.stringify(newValue)} (Previous: ${JSON.stringify(prevValue)})` +
            (validationErrors ? `, errors: ${validationErrors || ''}` : '');
          if (newValue === prevValue) {
            return;
          }

          this.clearCashRegisterCache();
          if (column.fieldName.indexOf('PriceList_') > -1) {
            var priceListItemId = entity['ItemId' + column.fieldName];
            if (priceListItemId) {
              this.Repository.Entity('PriceListItem').patch(priceListItemId, { 'RetailPrice': newValue }).post()
                .then((res) => {
                  console.log('PATCH ', res);
                }).catch((err) => {
                });
            } else {
              const newPriceListItem = {
                ItemMetadataType: entity.ConcreteType,
                ItemMetadataId: entity.Id,
                'RetailPrice': newValue
              }

              this.Repository.Entity('PriceListItem').create(newPriceListItem).post().then((res) => {
                entity['ItemId' + column.fieldName] = res.PriceListItemId;
                this.Repository.Entity('PriceList').createLink({ PriceListId: column.priceListId }, 'PriceListItems', 'PriceListItem', res.PriceListItemId)
                  .then((res) => {
                    console.log('success linking PriceListItem to PriceList', res);
                  }).catch((err) => {
                    console.log('error linking PriceListItem to PriceList', err);
                  });
              }).catch((err) => {
                console.log('error creating PriceListItem ', err);

              });
            }
          } else if (column.fieldName === 'CategoryId') {
            let toChange = { ItemMetadataType: entity.ConcreteType, ItemMetadataId: entity.Id, ItemCategoryId: newValue }
            this.Repository.Custom("EntitiesLazyRepository").updateCategory(toChange)
              .then(function (result) { }).catch(function (e) {
                this.toastr.error(this.$t("_ErrorUpdatingItems_"));
              })
          } else {
            let adaptedFieldName = column.fieldName;
            if (column.fieldName === 'Label') {
              adaptedFieldName = entity.ConcreteType === "ServiceMetadata" ? "ServiceName" : (entity.ConcreteType === "ProductMetadata" ? "ProductLabel" : "ExternalVoucherName");
            } else if (column.fieldName === 'Color') {
              newValue = newValue[0];
            } else if (column.fieldName === 'RetailPrice' && entity.ConcreteType === "ExternalVoucherMetadata") {
              adaptedFieldName = "Price";
            }

            this.Repository.Entity(entity.ConcreteType).patch(entity.Id, { [adaptedFieldName]: newValue }).post().then((res) => {
            }).catch((err) => {
              this.toastr.error(this.$t("_ErrorUpdatingItems_"));
            });
          }
        });

        this.gridProps.selectOnDirty = false;
        this.gridProps.allowSelection = false;
      } else if (viewType === 'inventory') {
        dataSource = new UpdateInventoryDS(api, this.$t, this.mediator, this.Repository, this.$state);
        this.gridProps.selectOnDirty = true;
        this.gridProps.allowSelection = true;
      }

      this.dataSource = dataSource;
    }
  },
  computed: {
    saveEnabled() {
      return getSafely(['dataSource', 'selectionCount'], this, 0) > 0;
    }
  },
  watch: {
    viewType: {
      immediate: true,
      handler(viewType, prev) {
        this.updateDS(viewType);
        this.mediator.notify('viewTypeChanged', { viewType, prev });

      }
    },
    dataSource: {
      immediate: true,
      handler(ds) {
        this.mediator.dataSource = ds;
      }
    },
  }


}
</script>

<style lang="less">
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity .3s ease;
}

.component-fade-enter,
.component-fade-leave-to

/* .component-fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
</style>
