import './call.log.modal.less'

(function () {
  angular.module('easybizy.easybizyModalViewsModel').controller('CallLogModalController',
    function ($scope, $modalInstance, $timeout, entityImageResolver, localize, Repository, $state, contextManager, confirmService,
              notificationCenterBus, tileActionsResolver) {
      var k_NoCustomer = localize.getLocalizedString('_NoCustomer_');
      var k_Unknown = localize.getLocalizedString('_Unknown_');

      $scope.cancel = function () {
        $modalInstance.close();
      };


      function handleCopyText(event) {
        var copytext = window.getSelection();
        if (copytext && event.clipboardData) {
          try {
            copytext = copytext.toString();
            var textWithoutDash = copytext.split(/\r?\n/)[0].replace(/\-/gi, '');
            if (copytext !== textWithoutDash) {
              event.clipboardData.setData('Text', textWithoutDash);
              event.preventDefault();
            }
          } catch (e) {
            console.log('Error intercepting copied text');
          }
        }
      }

      document.addEventListener('copy', handleCopyText);

      $scope.$on('$destroy', function () {
        document.removeEventListener('copy', handleCopyText);
      });


      $scope.api = Repository.Entity('IncomingCall', true).query().expand('Customer');
      delete $scope.api.clear;
      $scope.api.pageSize = 20;
      $scope.api.modelsDecorator = function (result) {
        return result.results;
      };

      $scope.filterCallLogTypes = [
        { name: localize.getLocalizedString("_All_"), value: null },
        { name: localize.getLocalizedString("_OnlyUnhandled_"), value: "Status ne 'Handled'" },
        { name: localize.getLocalizedString("_OnlyHandled_"), value: "Status eq 'Handled'" },
      ];

      $scope.selectedFilterCallLog = $scope.filterCallLogTypes[0];
      $scope.filterChanged = function (newVal) {
        $scope.api.setFilter(newVal.value);
        $timeout($scope.api.forceUpdate);
        // $scope.api.forceUpdate();
      };

      $scope.incomingCalls = [];
      $scope.isLoadingWrapper = { loading: false };

      $scope.fields = [
        {
          name: localize.getLocalizedString("_None_"),
          type: 'Checkbox',
          allowSelectAll: true,
          selectOnDirty: true,
          selectElement: true,
          sortable: false,
          fieldName: 'isSelected'
        },
        {
          name: localize.getLocalizedString("_Customer_"),
          type: 'ImageWithValue',
          iconReplacer: 'icon icon-customer',
          command: {
            get: function (entity) {
              return {
                text: entity.Customer ? entity.Customer.FirstName + ' ' + entity.Customer.LastName : k_NoCustomer,
                image: (entity.Customer && entityImageResolver.resolve(entity.Customer))
              };
            },
            dynamicIcon: function (entity) {
              return !entity.Customer ? 'icon icon-sms' : (entity.Customer.Gender === 'Male' ? 'icon icon-man' : 'icon icon-woman');
            }
          }
        },
        // {
        //   name: localize.getLocalizedString("_PhoneNumber_"),
        //   fieldName: 'FromNumber',
        //   command: {
        //     get: function (entity) {
        //       return (entity.FromNumber && entity.FromNumber.splitPhone()) || k_Unknown;
        //     }
        //   },
        //   sortable: false
        // },
        {
          name: localize.getLocalizedString('_PhoneNumber_'),
          type: 'Custom',
          sortable: false,
          template: `<div class="flex-container full-height align-flex-center">
                        <div click-call="value.url">{{ value.url }}</div>
                   </div>`,
          command: {
            get: function (entity) {
              return {
                url: (entity.FromNumber && entity.FromNumber.splitPhone()) || null
              }
            }
          }
        },
        {
          name: localize.getLocalizedString("_CallTime_"),
          fieldName: 'CreatedOn',
          orderedBy: true,
          sortable: true, isDecending: true,
          command: {
            get: function (entity) {
              return moment(entity.CreatedOn).format('LLL');
            },
            sort: function () {
              return 'CreatedOn';
            }
          }
        },
        {
          name: localize.getLocalizedString("_Actions_"),
          type: "Custom",
          template: '<div class="table-actions-wrapper">\
                        <div>\
                            <div ng-click="value(this, \'sendSms\')" class="icon icon-sms"></div>\
                            <div ng-click="value(this, \'schedule\')" class="icon icon-calendar"></div>\
                            <div ng-if="content.model.entity.CustomerId" ng-click="value(this, \'goToDetails\')" class="icon icon-go-right" right-to-left="icon-go-left"></div>\
                        </div>\
                     </div>',
          command: {
            get: function (entity) {
              return function (scope, action) {
                if (action === 'schedule') {
                  if (entity.CustomerId) {
                    contextManager.set('customer', entity.CustomerId);
                  } else if (entity.FromNumber) {
                    contextManager.set('customerPhoneNumber', entity.FromNumber);
                  }

                  $state.go('Calendar');
                } else if (action === 'goToDetails') {
                  $state.go('CustomerDetails', { customerId: entity.CustomerId });
                } else if (action === 'sendSms') {
                  tileActionsResolver.getAction('sendSms').command({
                    MobileFirst: entity.FromNumber,
                    FirstName: entity.FirstName,
                    CustomerId: entity.CustomerId
                  });
                }
              };
            }
          }
        }
      ];


      var widths = ['5%', '35%', '20%', '20%', '10%'];
      $scope.fields.forEach(function (field, idx) {
        field.width = widths[idx];
      });


      $scope.selectionChanged = function () {
        $scope.selectedCount = 0;
        $scope.incomingCalls.forEach(function (item) {
          if (item.isSelected) {
            $scope.selectedCount++;
          }
        });

        $scope.deleteMessage = localize.getLocalizedString('_DeleteCallsLog_', $scope.selectedCount);

      };

      $scope.deleteSelected = function () {
        confirmService.confirm($scope.deleteMessage + '?',
          null,
          function () {

            $scope.selectedIncomingIds = [];
            $scope.incomingCalls.forEach(function (item) {
              if (item.isSelected) {
                $scope.selectedIncomingIds.push(item.IncomingCallId);
              }
            });

            Repository.Custom("IncomingCalls").handleMultiple($scope.selectedIncomingIds)
              .then(function () {
                $scope.selectedIncomingIds = [];
                $scope.incomingCalls = [];
                $timeout($scope.api.forceUpdate);
                notificationCenterBus.loadNotifications();
              })
              .catch(function (err) {
                toastr.error(localize.getLocalizedString("_GeneralError_", err));
              });
          },
          function () {
            // DOES NOTHING.
          });
      };

      $scope.markAllAsHandled = function () {
        confirmService.confirm(localize.getLocalizedString('_MarkAllAsHandledConfirm_'),
          null,
          function () {
            Repository.Custom("IncomingCalls").handleAll()
              .then(function () {
                $scope.selectedIncomingIds = [];
                $scope.incomingCalls = [];
                $timeout($scope.api.forceUpdate);
                notificationCenterBus.loadNotifications();
              })
              .catch(function (err) {
                toastr.error(localize.getLocalizedString("_GeneralError_", err));
              });

          },
          function () {
            // DOES NOTHING.
          });
      };
    });

}());
