import {
  COLUMN_EDITOR_TYPE, GRID_FILTER_TYPE,
  GridApiResult,
  GridColumn,
  LazyGridDataSource,
  SortByColumn
} from "@tomeravni/easybizy-vue-components";

import { isNonEmptyArray } from "@tomeravni/easybizy-js-common/common";
import { parseNumber } from "@tomeravni/easybizy-js-common/numbers";
import { extractError } from "@tomeravni/easybizy-js-common/errors";

export default class extends LazyGridDataSource {
  constructor(api, localize, stateManager, mediator, Repository, confirmService) {
    super();
    this._api = api;

    const nameCol = new GridColumn('ServiceRepresentativeName', localize('_ServiceRepresentativeName_'), COLUMN_EDITOR_TYPE.EDITABLE_TEXT);
    nameCol.width = 300;
    nameCol.filterType = GRID_FILTER_TYPE.STRING;
    nameCol.filterArgs = { label: localize('Search'), debounce: true };
    nameCol.freeze = true;

    const durationOptions = [];
    let currentStart = mediator.serviceTimeInterval;
    while (currentStart < 5 * 60) {
      let minutes = currentStart % 60;
      let hours = Math.floor(currentStart / 60);
      durationOptions.push({
        text: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`,
        value: hours * 60 + minutes
      });

      currentStart += mediator.serviceTimeInterval;
    }

    const durationCol = new GridColumn('Duration', localize('_Duration_'), COLUMN_EDITOR_TYPE.SINGLE_SELECT);
    durationCol.width = 120;
    durationCol.options = durationOptions;

    const descriptionCol = new GridColumn('Description', localize('_Description_'), COLUMN_EDITOR_TYPE.EDITABLE_TEXT);
    descriptionCol.width = 300;
    descriptionCol.isVisible = !!mediator.showServiceDescription;


    const forceRemarksCol = new GridColumn('ForceRemarks', localize('_ForceRemarks_'), COLUMN_EDITOR_TYPE.TOGGLE);
    forceRemarksCol.width = 100;

    const remarksPlacholderCol = new GridColumn('RemarksPlaceHolder', localize('_RemarksPlaceholder_'), COLUMN_EDITOR_TYPE.EDITABLE_TEXT);
    remarksPlacholderCol.width = 300;

    const originalServiceNameCol = new GridColumn('OriginalServiceName', localize('_InternalServices_'), COLUMN_EDITOR_TYPE.LAZY);
    originalServiceNameCol.width = 300;
    originalServiceNameCol.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.ServiceMetadatas.length < 1 ? '' : entity.ServiceMetadatas.map(function (service) {
        return service.ServiceName;
      }).join(', '));
    };

    const fromPriceCol = new GridColumn('PriceFrom', localize('_FromPrice_'), COLUMN_EDITOR_TYPE.NUMERIC, true);
    fromPriceCol.width = 200;
    fromPriceCol.customAttributes = { debounce: true, decimal: true, valueAsString: true };

    const toPriceCol = new GridColumn('PriceTo', localize('_ToPrice_'), COLUMN_EDITOR_TYPE.NUMERIC, true);
    toPriceCol.width = 200;
    toPriceCol.customAttributes = { debounce: true, decimal: true, valueAsString: true };

    const needsCreditToken = new GridColumn('NeedsCreditToken', localize('_ForceCredit_'), COLUMN_EDITOR_TYPE.TOGGLE);
    needsCreditToken.width = 100;

    const needsApproval = new GridColumn('NeedsApproval', localize('_ForceApproval_'), COLUMN_EDITOR_TYPE.TOGGLE);
    needsApproval.width = 100;

    const hidden = new GridColumn('Hidden', localize('_Hidden_'), COLUMN_EDITOR_TYPE.TOGGLE);
    hidden.width = 100;

    const prePaymentPrice = new GridColumn('PrePaymentPrice', localize('_PrePayment_'), COLUMN_EDITOR_TYPE.NUMERIC, true);
    prePaymentPrice.width = 200;
    prePaymentPrice.customAttributes = { debounce: true, decimal: true, valueAsString: true };

    const maximumPerDay = new GridColumn('MaximumPerDay', localize('_MaximumServiceBookingsPerDay_'), COLUMN_EDITOR_TYPE.NUMERIC, true);
    maximumPerDay.width = 150;
    maximumPerDay.customAttributes = { debounce: true, integer: true, valueAsString: true };

    const maximumPerCustomerPerDay = new GridColumn('MaximumPerCustomerPerDay', localize('_MaximumServiceBookingsPerCustomerPerDay_'), COLUMN_EDITOR_TYPE.NUMERIC, true);
    maximumPerCustomerPerDay.width = 150;
    maximumPerCustomerPerDay.customAttributes = { debounce: true, integer: true, valueAsString: true };

    const order = new GridColumn('Order', localize('_Location_'), COLUMN_EDITOR_TYPE.NUMERIC, true);
    order.width = 150;
    order.customAttributes = { debounce: true, integer: true, valueAsString: true };

    const pickGender = new GridColumn('PickGender', localize('_AllowPickingGender_'), COLUMN_EDITOR_TYPE.TOGGLE);
    pickGender.width = 100;

    const quantity = new GridColumn('Quantity', localize('_Quantity_'), COLUMN_EDITOR_TYPE.NUMERIC, true);
    quantity.width = 150;
    quantity.customAttributes = { debounce: true, integer: true, valueAsString: true, min: 1, max: 5 };

    const quantityOrderOptions = [
      { text: localize("_BothParallelOrQueue_"), value: 'both' },
      { text: localize("_Parallel_"), value: 'parallel' },
      { text: localize("_OneAfterTheOther_"), value: 'waterfall' }
    ];

    const quantityOrder = new GridColumn('QuantityOrder', localize('_QuantityOrder_'), COLUMN_EDITOR_TYPE.SINGLE_SELECT, true);
    quantityOrder.width = 150;
    quantityOrder.options = quantityOrderOptions.slice(1);
    
    const allowQuantitySelection = new GridColumn('AllowQuantitySelection', localize('_AllowQuantitySelection_'), COLUMN_EDITOR_TYPE.TOGGLE);
    allowQuantitySelection.width = 100;

    const allowedQuantityMax = new GridColumn('AllowedQuantityMax', localize('_AllowedQuantityMax_'), COLUMN_EDITOR_TYPE.NUMERIC, true);
    allowedQuantityMax.width = 150;
    allowedQuantityMax.customAttributes = { debounce: true, integer: true, valueAsString: true, min: 1, max: 5 };

    const allowedQuantityOrder = new GridColumn('AllowedQuantityOrder', localize('_AllowedQuantityOrder_'), COLUMN_EDITOR_TYPE.SINGLE_SELECT, true);
    allowedQuantityOrder.width = 150;
    allowedQuantityOrder.options = quantityOrderOptions;

    const onlyAllowWithSubscription = new GridColumn('OnlyAllowWithSubscription', localize('_OnlyAllowWithSubscription_'), COLUMN_EDITOR_TYPE.TOGGLE);
    onlyAllowWithSubscription.width = 100;


//OnlyAllowWithSubscription
    let removeFromTable = (function (item) {
      this.pullItem(item);
    }).bind(this);

    let addToTable = (function (item) {
      this.pushItem(adaptModelToTableItem(item), 0);
    }).bind(this);

    mediator.addItemToTable = function (item) {
      addToTable(item);
    }

    const deleteCol = new GridColumn('link', localize('_Actions_'), COLUMN_EDITOR_TYPE.LAZY, false);
    deleteCol.width = 100;

    const validateAndDeleteEntity = (entity) => {
      confirmService.confirm(localize('_ApproveDeleteMessage_'), null,
        () => {
          Repository.Entity("OnlineBookingServiceMetadata").remove(entity).then((result) => {
            removeFromTable(entity);
          }).catch(function (err) {
            window.toastr.error(extractError(err))
          });
        },
        () => {
        })
    }


    deleteCol.customRenderFunc = (entity, cd, column, h) => {
      return [h('button', {
        class: `go-to-item-link text--primary icon icon-delete-garbage-streamline`,
        on: {
          click() {
            validateAndDeleteEntity(entity);
          }
        }
      }),
      h('button', {
        class: `go-to-item-link text--primary icon icon-edit me-4`,
        on: {
          click() {
            mediator.editOnlineService(entity, (item) => {
              Object.assign(entity, adaptModelToTableItem(item))
            })
          }
        }
      })

      ];
    }


    this.columns = [
      nameCol,
      durationCol,
      descriptionCol,
      fromPriceCol,
      toPriceCol,
      originalServiceNameCol,
      forceRemarksCol,
      remarksPlacholderCol,
      needsApproval,
      needsCreditToken,
      maximumPerDay,
      maximumPerCustomerPerDay,
      prePaymentPrice,
      hidden,
      order,
      pickGender,
      quantity,
      quantityOrder,
      allowQuantitySelection,
      allowedQuantityMax,
      allowedQuantityOrder,
      onlyAllowWithSubscription,
      deleteCol
    ].filter((x) => !!x)

    this._sortByColumn = new SortByColumn(order, false);

  }


  getItemId(item) {
    return item.OnlineBookingServiceMetadataId;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  get api() {
    return this._api;
  }

  filterChange(filter) {
    super.filterChange(this.adaptFiltersToQuery(filter));
  }

  adaptFiltersToQuery(filter) {
    let adaptedFiltersToSet = [];

    if (isNonEmptyArray(filter) || isNonEmptyArray(this._constantFilters)) {
      const adaptedFilters = filter.concat(this._constantFilters || []).map((x) => {
        if (x.column.fieldName === 'ServiceRepresentativeName') {
          return `(indexof(ServiceRepresentativeName,'${x.filterValue}') gt -1)`
        }
      });

      adaptedFiltersToSet = adaptedFilters;
    }

    return adaptedFiltersToSet;
  }

  loadOnRemote(top, skip, sort, filter) {
    this._api.top(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }
    const adaptedFilters = this.adaptFiltersToQuery(filter);
    this._api.setFilter(adaptedFilters);


    return this._api.get().then((result) => {
      return new GridApiResult(result.results.map(adaptModelToTableItem),
        top,
        skip,
        null);
    });
  }
}

const KEYS_TO_VALIDATE_NUMBER = ['PriceFrom', 'PriceTo', 'PrePaymentPrice', 'Order', 'MaximumPerCustomerPerDay', 'MaximumPerDay']

const adaptModelToTableItem = (item) => {
  return KEYS_TO_VALIDATE_NUMBER.reduce((acc, currKey) => {
    return {
      ...acc,
      [currKey]: parseNumber(acc[currKey], 0)
    }
  }, item)
}
