import './tutorials.modal.less'
import { DelayedFunction } from "../../general/helpers";

(function () {

  angular.module('easybizy.easybizyModalViewsModel').controller('TutorialsController',
    function ($scope, $modalInstance, $http, localize, $sce, tutorialsService, $state) {
      $scope.cancel = $modalInstance.dismiss.bind(this, 'cancel');
      $scope.currnetTutorial = null;

      $scope.searchWrapper = { searchText: '' };
      $scope.description = "";

      // tutorialsService.defaultTutorial().then(setTutorial);


      $scope.setTutorial = setTutorial;
      $scope.$watch('searchWrapper.searchText', function (newVal) {
        if (angular.isDefined(newVal)) {
          delayedSearchObject.set(newVal);
        }
      });

      var delayedSearchObject = new DelayedFunction(getTutorials, 50);

      function setTutorial(tutorial) {
        $scope.currentTutorial = tutorial;
        $scope.description = $sce.trustAsHtml(tutorial.description);
      }

      function getTutorials(search) {
        tutorialsService.getTutorials(search).then(function (tutorials) {
          $scope.tutorials = tutorials;
          setTutorial(tutorials[0]);
          if ($scope.currentTutorial && tutorials.indexOf($scope.currentTutorial) < 0) {
            tutorials.unshift($scope.currentTutorial);
          }
        });
      }
    });

}());

angular.module('easybizy.common.common-controls').factory('tutorialsService', function (Repository, $q, $state) {
  var tutorials = [];
  var defaultTutorial = null;
  var delegates = [];

  Repository.Custom('Tutorials').getTutorials($state.current.name).then(init)
    .catch(function (err) {
      toastr.error(err.message);
    });


  var api = {
    // defaultTutorial: getDefaultTutorial,
    getTutorials: getTutorials
  };

  return api;

  function init(result) {
    tutorials = result;
    defaultTutorial = result[0];
    while (delegates.length > 0) {
      delegates.pop()();
    }
  }

  // function getDefaultTutorial() {
  //   var deferred = $q.defer();
  //   var returnTutorial = function () {
  //     deferred.resolve(defaultTutorial);
  //   };
  //
  //   if (!defaultTutorial) {
  //     delegates.push(returnTutorial);
  //   } else {
  //     returnTutorial()
  //   }
  //
  //   return deferred.promise;
  // }

  function getTutorials(search) {
    var deferred = $q.defer();

    var returnTutorials = function () {
      var currentState = $state.current;
      var validTutorials = tutorials.filter(function (tutorial) {
        if (tutorial.inactiveOnScreens.indexOf(currentState.name) >= 0) {
          return false;
        }

        if (search && search.length > 0 && tutorial.title.indexOf(search) < 0 && !tutorial.tags.some(function (tag) {
          return tag.startsWith(search)
        })) {
          return false;
        }

        return true;
      });

      validTutorials.sort(function (a, b) {
        var aIndex = a.relatedScreens.indexOf(currentState.localizedName);
        var bIndex = b.relatedScreens.indexOf(currentState.localizedName);

        if (aIndex > -1 && bIndex > -1) {
          return b - a;
        } else if (aIndex > -1) {
          return -1;
        } else if (bIndex > -1) {
          return 1;
        } else {
          return 0;
        }
      });

      deferred.resolve(validTutorials);
    };

    if (!defaultTutorial) {
      delegates.push(returnTutorials);
    } else {
      returnTutorials()
    }


    return deferred.promise;
  }

});
