<template>
  <div class="vue-time-picker">
    <TimePickerInput :min="0" :max="23" :value="hours" @change="hoursChanged" :disabled="disabled"/>
    <div class="vue-time-picker__split">:</div>
    <TimePickerInput :min="0" :max="59" :value="minutes" @change="minutesChanged" :disabled="disabled"/>
  </div>
</template>

<script>
import TimePickerInput from './time-picker-input.vue';
import Moment from 'moment';

export default {
  components: {
    TimePickerInput
  },
  props: {
    time: {
      type: String,
      required: false
    },
    eventProps: {
      type: Object,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  mounted() {
    if (!this.time) {
      this.time = `${ Moment().hours() }:00`;
    }

    const parsedTime = Moment(this.time, 'HH:mm');
    this.hours = parsedTime.hours();
    this.minutes = parsedTime.minutes();

    this.$watch((vm) => [vm.hours, vm.minutes], ([hours, minutes]) => {
      const adapted = Moment(`${ hours }:${ minutes }`, 'HH:mm').format('HH:mm');
      this.adapted = adapted;
      this.$emit('change', { value: adapted, props: this.eventProps });
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
  },
  data() {
    return {
      hours: '00',
      minutes: '00',
      adapted: ''
    }
  },
  watch: {
    time(newVal) {
      if (newVal !== this.adapted) {
        const split = newVal.split(':');
        this.hours = split[0];
        this.minutes = split[1];
      }
    }
  },
  methods: {
    minutesChanged(newVal) {
      this.minutes = newVal;
    },
    hoursChanged(newVal) {
      this.hours = newVal;
    }
  },
}
</script>

<style lang="less">
.vue-time-picker {
  display: flex;
  align-items: center;
  direction: ltr;

  &__split {
    padding: 3px;
    font-size: 18px;
  }
}
</style>
