export function InfiniteList(ulWrapper, baseItems, config) {
  var render = _render.bind(this);
  var onScroll = _onScroll.bind(this);
  var loadMore = _loadMore.bind(this);
  var getVisualItems = _getVisualItems.bind(this);
  var setBottomWrapperHeight = _setBottomWrapperHeight.bind(this);
  var calculateMaxVisibleItems = _calculateMaxVisibleItems.bind(this);
  var wrap = _wrap.bind(this);
  this.appendItems = _appendItems.bind(this);
  var items = [];
  var itemsCache = {};
  this.itrIndex = 0;
  this.currentMaxVisibleItems = 0;
  this.currentVisibleItemsCount = 0;

  var defaultConfig = {
    pageSize: 30,
    reloadOnPosition: 2 / 3,
    itemsInRow: 5,
    lowPerformanceMode: false,
    sort: function (items) {
      return items;
    },
    loadedMoreDelegate: function () {

    }
  };

  this.config = Object.assign({}, defaultConfig, config);
  (this.config.scrollTrapper || ulWrapper).on('scroll', onScroll);


  this.appendItems(baseItems);

  render();


  if (this.config.onClick) {
    var delegate = (function (e) {
      this.config.onClick(itemsCache[e.currentTarget.dataset.id].entity);
    }).bind(this);

    ulWrapper.on('tap', '.custom-column', function (e) {
      delegate(e);
      $(this).addClass('animated bounceInCustom');
    });

    ulWrapper.on('webkitAnimationEnd oanimationend msAnimationEnd animationend', '.custom-column', function () {
      $(this).removeClass('animated bounceInCustom');
    })

  }

  this.setItemsInRow = function (count) {
    this.config.itemsInRow = count;
  };

  this.filter = function (phraseRegex, equivalentPhraseRegex) {
    this.itrIndex = 0;
    this.currentVisibleItemsCount = 0;
    this.phraseRegex = phraseRegex;
    this.equivalentPhraseRegex = equivalentPhraseRegex;
    lastScrollBottom = 0;

    ulWrapper.find('li').remove();
    calculateMaxVisibleItems();
    render(phraseRegex, equivalentPhraseRegex);
    (this.config.scrollTrapper || ulWrapper).scrollTo(0);

  };

  function _appendItems(itemsToAppend) {
    var toPerform = (function (item) {
      var uniqueId = this.config.uniqueResolver(item);
      var wrappedItem = wrap(item, uniqueId);
      itemsCache[uniqueId] = wrappedItem;
      items.push(wrappedItem);
    }).bind(this);

    itemsToAppend.forEach(toPerform);
    calculateMaxVisibleItems();
    setBottomWrapperHeight();
  }

  var lastScrollBottom = 0;

  var isLoadingMore = false;

  function _onScroll(e) {
    var scrollBottom = e.target.scrollTop + e.target.clientHeight;
    if (scrollBottom - lastScrollBottom > (this.config.itemHeight * 2)) { // don't calculate every scroll move.
      lastScrollBottom = scrollBottom;

      var visibleItemsCount =
        Math.ceil(scrollBottom / this.config.itemHeight * this.config.itemsInRow + this.config.itemsInRow);

      if (!isLoadingMore && visibleItemsCount > (this.currentVisibleItemsCount * this.config.reloadOnPosition) && this.currentVisibleItemsCount <= this.currentMaxVisibleItems) {
        loadMore();
        setTimeout(function () {
          isLoadingMore = false;
        })
      }
    }

  }

  var isLoadingMore = false;

  function _loadMore() {
    if (isLoadingMore || this.itrIndex >= items.length) {
      return;
    }

    render();
  }

  function _render() {
    var itemsToAppend = getVisualItems();
    var itemsAsString = '';
    itemsToAppend.forEach(function (item) {
      if (!item.isVisible) {
        // TODO: Handle already drawn items.
        itemsAsString += item.html;
      }
    });


    ulWrapper.append(itemsAsString);
    setBottomWrapperHeight();
    this.config.loadedMoreDelegate(itemsToAppend);
  }

  function _setBottomWrapperHeight() {
    if (!this.config.itemHeight) {
      this.config.itemHeight = ulWrapper.find('li:first-of-type').outerHeight(true);
    }

    if (!this.config.itemHeight) {
      return;
    }

    var bottomWrapperSize = 0;
    if (this.currentVisibleItemsCount < this.currentMaxVisibleItems) {
      bottomWrapperSize = this.config.itemHeight * (this.currentMaxVisibleItems - this.currentVisibleItemsCount) / this.config.itemsInRow;
    }

    ulWrapper.next().css('height', bottomWrapperSize + 'px');
  }

  //noinspection JSPotentiallyInvalidUsageOfThis
  function _getVisualItems() {
    var toReturn = [];
    while (this.itrIndex < items.length && toReturn.length < this.config.pageSize) {
      var item = items[this.itrIndex];
      if (!this.phraseRegex || this.config.filterItem(item.entity, this.phraseRegex, this.equivalentPhraseRegex)) {
        toReturn.push(item);
        this.currentVisibleItemsCount++;
      }

      this.itrIndex++;
    }

    this.config.sort(toReturn);
    return toReturn;
  }

  function _calculateMaxVisibleItems() {
    var idx = 0;
    this.currentMaxVisibleItems = 0;
    while (idx < items.length) {
      var item = items[idx];
      if (!this.phraseRegex || this.config.filterItem(item.entity, this.phraseRegex, this.equivalentPhraseRegex)) {
        this.currentMaxVisibleItems++;
      }

      idx++;
    }
  }


  function _wrap(item, uniqueId) {
    var that = this;
    return {
      entity: item,
      id: uniqueId,
      isVisible: false,
      get html() {
        return that.config.itemToHtml(item, uniqueId, that.config.lowPerformanceMode)
      }
    }
  }


}

