var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard-container" },
    [
      _c("LinearProgressBar", { attrs: { "is-loading": _vm.isLoading } }),
      _vm._v(" "),
      _c(
        "transition-group",
        {
          staticClass: "steps-wrapper",
          attrs: {
            name: _vm.controller.flowDirectionBackward ? "slideback" : "slide",
          },
        },
        _vm._l(_vm.controller.steps, function (step, index) {
          return _vm.controller.currentStep === step
            ? _c(
                "WizardStep",
                {
                  key: index,
                  attrs: {
                    hasNext: _vm.controller.hasNext,
                    hasPrev: _vm.controller.hasPrev,
                    title: step.title,
                    skipable: step.skipable,
                  },
                  on: {
                    prev: function ($event) {
                      return _vm.controller.prev()
                    },
                    next: function ($event) {
                      return _vm.controller.next()
                    },
                    skip: function ($event) {
                      return _vm.controller.skip()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    [
                      step.viewType === "tiles"
                        ? _c("WizardTileSelect", {
                            attrs: {
                              items: step.options,
                              selected: step.selected,
                              multiSelect:
                                step.selectionType === "multi-select",
                            },
                            on: {
                              itemSelected: function ($event) {
                                return _vm.itemSelected(step, $event)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      step.viewType === "grid"
                        ? _c("WizardGridSelect", {
                            attrs: { "data-source": step.dataSource },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      step.viewType === "summary"
                        ? _c("WizardSummary", {
                            attrs: { items: _vm.itemsToUpload },
                            on: { upload: _vm.upload },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e()
        }),
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "wizard-mask element-loading-mask",
        class: _vm.isLoading && "visible",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }