import './online.scheduling.employees.directive.less'

(function () {
  angular.module('easybizy.online.scheduling').directive('onlineSchedulingEmployees', [
    '$timeout',
    'localize', 'timeConverter', function ($timeout, localize, timeConverter) {
      return {
          restrict: 'E',
          replace: true,
          scope: {
              employees: '=',
              allEmployeesEnabled: '='
          },
          link: function(scope, element, attrs) {
              scope.getImage = function(employee) {
                  return employee.Image && employee.Image.FilePath && (baseUrl + employee.Image.FilePath);
              };
          },
          template: require('./online.scheduling.employees.directive.html')

      };
  }]);

}());
