angular.module('easybizy.promotions.manager').controller('PromotionEditViewController', function ($scope, $modalInstance, promotion) {
  $scope.close = function () {
    $modalInstance.close();
  };

  $scope.promotionMetadata = promotion;
});

angular.module('easybizy.promotions.manager').directive('promotionEditView', ['$timeout', '$http', 'Repository', function ($timeout, $http, Repository) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      promotionMetadata: '='
    },
    template: require('./promotion.edit.view.tpl.html'),
    link: function (scope) {
      scope.contentResolverWrapper = {};

      $timeout(function () {
        scope.editorMetadata = scope.promotionMetadata;

        Repository.Custom("PromotionsRepository").getClientPromotion(scope.promotionMetadata.promotionId)
          .then(function (result) {
            scope.clientPromotion = result;
          }).catch(function (err) {
          throw err;
        });
      }, 1000);

      scope.savePromotion = function () {
        scope.contentResolverWrapper.resolve(scope.clientPromotion);
        Repository.Custom("PromotionsRepository").update(scope.clientPromotion)
          .then(function (result) {
            console.log(result);
          })
          .catch(function (err) {
            toastr.error('Error saving promotion')
          });
      };
    }
  };
}
]);
