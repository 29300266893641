import moment from "moment";
import './usage.widget.directive.less'
import { SERVER_DATA_DATE_FORMAT } from "../../../../constants";

angular.module('easybizy.dashboard').directive('usageWidget', ['$timeout', 'localize', 'Repository',
  function ($timeout, localize, Repository) {
    return {
      restrict: 'E',
      replace: true,
      scope: true,
      link: function (scope, element, attrs) {
        var xAxis = [];
        var yAxis = [];

        scope.chartWrapper = {};
        var graphWrapper = element.find('.usage-widget-graph-content');

        var lastHeight = graphWrapper.height();
        var lastWidth = graphWrapper.width();
        var externalTimeout = null;

        graphWrapper.on('resize', function (e) {
          changeSize();
        });

        function changeSize() {
          if (externalTimeout != null) {
            clearTimeout(externalTimeout);
          }

          externalTimeout = setTimeout(function () {
            lastHeight = graphWrapper.height();
            lastWidth = graphWrapper.width();
            if (scope.chartWrapper.chart) {
              try {
                scope.chartWrapper.chart.setSize(lastWidth - 4, lastHeight);
              } catch (e) {
                console.log('chart was already destructed.');
              }

            }
          }, 500);
        }

        setChart([0, 0, 0, 0, 0, 0, 0]);
        setChartConfig();

        scope.isLoading = true;

        Repository.Custom("Dashboard").meetingsForecast()
          .then(function (data) {
            scope.isLoading = false;
            scope.upcomingMeetings = 0;
            if (data.results.meetings && data.results.meetings.length > 0) {
              angular.forEach(data.results.meetings, function (meeting) {
                var indexOf = xAxis.indexOf(moment(meeting.Date, SERVER_DATA_DATE_FORMAT).format("ddd"));
                yAxis[indexOf] = meeting.TotalActions;
                scope.upcomingMeetings += meeting.TotalActions;
              });
            }

            setChart(yAxis);
            setChartConfig();
            changeSize();
          })
          .catch(function (res) {
            scope.isLoading = false;
          });

        function setChartConfig() {
          var currentDay = moment();
          xAxis.length = 0;
          for (var i = 0; i < 7; i++) {
            xAxis.unshift(currentDay.format("ddd"));
            currentDay.add(-1, 'd');
          }

          scope.chartConfig = {
            options: {
              chart: {
                type: 'line'
              },
              plotOptions: {
                line: {
                  dataLabels: {
                    enabled: true
                  }
                  //enableMouseTracking: false
                }
              },
              exporting: {
                enabled: false
              },
              legend: {
                enabled: false
              },
              xAxis: {
                text: null,
                title: null,
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                categories: xAxis
              },
              yAxis: {
                text: null,
                title: null,
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                labels: {
                  enabled: false
                }
              },
              reflow: false
            },
            series: scope.chartSeries,
            title: {
              text: null
            },
            legend: {
              enabled: false
            },
            credits: {
              enabled: false
            },
            loading: false,
            "useHighStocks": false
          };

        }

        scope.$on('$destroy', function () {
          graphWrapper.off('DOMSubtreeModified');
          graphWrapper.off('resize');

        });

        function setChart(data) {
          yAxis = data ? data : yAxis;
          scope.chartSeries = [
            { "name": localize.getLocalizedString("_Meetings_"), "data": yAxis, color: "#D5D5D5" }
          ];
        }
      },
      template: require('./usage.widget.directive.html')
    };
  }]);
