import './employee.sells.tree.directive.less';
import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT } from "../../constants";

angular.module('easybizy.common.common-controls')
  .directive('employeeSellsTree', [
    'localize', 'Repository', function (localize, Repository) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          employeeId: '=',
          dateRange: '=',
          groupByManufacturer: '='
        },
        link: function (scope) {
          scope.firstLoaded = true;
          scope.sells = { Details: [] };
          scope.$watch('dateRange', paramChanged, true);
          scope.$watch('groupByManufacturer', paramChanged);

          function paramChanged(newVal, oldVal) {
            if (angular.isDefined(newVal) && (newVal !== oldVal || scope.firstLoaded)) {
              getEmployeeSales();
              scope.firstLoaded = false;
            }
          }

          function getEmployeeSales() {
            var from = moment(scope.dateRange.from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
            var to = moment(scope.dateRange.to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
            scope.isLoading = true;
            Repository.Custom("EmployeeSales").get(scope.employeeId, from, to, scope.groupByManufacturer)
              .then(function (result) {
                scope.isLoading = false;
                scope.sells.Details[0] = localizeResult(result);
                scope.$digestIfNeeded();
              })
              .catch(function (errorMessage) {
                scope.isLoading = false;
                toastr.error(errorMessage);
              });
          }

          function localizeResult(result) {
            result.Type = localize.getLocalizedString("_" + result.Type + "_");
            for (var i = 0; i < result.Details.length; i++) {
              result.Details[i].Type = localize.getLocalizedString("_" + result.Details[i].Type + "_");
            }
            return result;
          }
        },
        template: require('./employee.sells.tree.directive.tpl.html')
      };
    }]);

angular.module('easybizy.common.common-controls').directive('tree', function () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      sells: '=',
      level: '='
    },
    template: '<ul><branch ng-repeat="child in sells.Details" item="child" level="level + 1"></branch></ul>'
  };
});

angular.module('easybizy.common.common-controls').directive('branch', function ($compile) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      item: '=',
      level: '='
    },
    link: function (scope, element, attrs) {
      //// Check if there are any children, otherwise we'll have infinite execution

      var has_children = angular.isArray(scope.item.Details) && scope.item.Details.length > 0;

      //// Manipulate HTML in DOM
      if (has_children) {
        element.append('<tree sells="item" level="level"></tree>');

        // recompile Angular because of manual appending
        $compile(element.contents())(scope);
      } else {
        element.addClass('no-parent')
      }

      if (scope.level > 1) {
        element.addClass('collapsed');
      }

      //// Bind events
      element.on('click', function (event) {
        event.stopPropagation();

        if (has_children) {
          element.toggleClass('collapsed');
        }
      });

      if (scope.item.TotalMinutes) {
        scope.workingTime = forceTwoDigits(~~(scope.item.TotalMinutes / 60)) + ':' + forceTwoDigits(scope.item.TotalMinutes % 60);
      }

      function forceTwoDigits(number) {
        return (number < 10 ? '0' : '') + number;
      }
    },
    template: '<li><a>({{::item.Count}}) {{::item.Type}} {{::item.Price | money}}</a><div class="total-minutes" ng-if="::item.TotalMinutes"><span class="icon icon-time"></span><span>{{::workingTime}}</span></div></li>'
  };
});
