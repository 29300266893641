import {isConcreteValue, isNonEmptyArray} from "@tomeravni/easybizy-js-common/common";
import Moment from "moment";
import {DATE_RANGE_FIELDS, MULTI_SELECTION_FIELDS} from "./leads.constants";
import {parseNumber} from "@tomeravni/easybizy-js-common/numbers";
import {GridColumnFilterAdapter} from "@tomeravni/easybizy-vue-components";
import {isSameDayOfMonth, isToday, isTomorrow, isYesterday} from "@tomeravni/easybizy-js-common/date";

const URL_DATE_FORMAT = 'YYYYMMDD';

export const adaptDateFilterValue = (filterValue) => {
  if (!isNonEmptyArray(filterValue)) {
    return;
  }

  if (filterValue.length === 1) {
    return Moment(filterValue[0]).format(URL_DATE_FORMAT);
  }

  const [from, to] = filterValue;
  return `${Moment(from).format(URL_DATE_FORMAT)}-${Moment(to).format(URL_DATE_FORMAT)}`;
}

export const adaptDateOnUrlToFilterValue = (urlValue) => {
  try {
    const [from, to] = urlValue.split('-');
    if (!to) {
      return [Moment(from, URL_DATE_FORMAT).toDate()];
    }

    return [Moment(from, URL_DATE_FORMAT).toDate(), Moment(to, URL_DATE_FORMAT).toDate()];
  } catch (e) {
    toastr.error('Error parsing date filter value', e);
    return undefined;
  }
}

export const adaptUrlValue = (fieldName, value) => {
  if (DATE_RANGE_FIELDS.includes(fieldName)) {
    return adaptDateOnUrlToFilterValue(value);
  }

  if (MULTI_SELECTION_FIELDS.includes(fieldName)) {
    if ([true.toString(), false.toString()].includes(value)) {
      return value === true.toString();
    }

    return value.split(',').map((v) => parseNumber(v.trim(), v.trim()));
  }

  return value;
}

export const adaptFilterValue = (fieldName, value) => {
  if (DATE_RANGE_FIELDS.includes(fieldName)) {
    return adaptDateFilterValue(value);
  }

  if (MULTI_SELECTION_FIELDS.includes(fieldName) && isNonEmptyArray(value)) {
    return value.map((v) => v.toString().trim()).join(',');
  }

  return isConcreteValue(value) ? value.toString() : value;
}

export class GridDateColumnFilterAdapter extends GridColumnFilterAdapter {
  exportValue(value, column) {
    if (value.length === 1) {
      if (isToday(value[0])) {
        return 'today';
      }

      if (isTomorrow(value[0])) {
        return 'tomorrow';
      }

      if (isYesterday(value[0])) {
        return 'yesterday';
      }
    } else if (value.length === 2) {
      if (Moment(value[0]).isSame(Moment().startOf('month'), 'date') && Moment(value[1]).isSame(Moment().endOf('month'), 'date')) {
        return 'this-month';
      } else if (Moment(value[0]).isSame(Moment().startOf('week'), 'date') && Moment(value[1]).isSame(Moment().endOf('week'), 'date')) {
        return 'this-week';
      }
    }

    return value;
  }

  importValue(value, column) {
    if (value === 'today') {
      return [Moment().startOf('day').toDate()];
    } else if (value === 'tomorrow') {
      return [Moment().startOf('day').add(1, 'day').toDate()];
    } else if (value === 'yesterday') {
      return [Moment().startOf('day').add(-1, 'day').toDate()];
    } else if (value === 'this-month') {
      return [Moment().startOf('month').toDate(), Moment().endOf('month').endOf('day').toDate()];
    } else if (value === 'this-week') {
      return [Moment().startOf('week').toDate(), Moment().endOf('week').endOf('day').toDate()];
    }

    return value;
  }

}
