export const PRINT_CALENDAR_CSS = `

body {
  direction: rtl;
}

/*@page { size: landscape; }*/


table {
  width: 100%;
}

.zero-opacity {
  display: none;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  vertical-align: top;
}

.calendar-loading-indication {
  display: none;
}

.add-meeting-placeholder {
  display: none;
}

.meeting-customer-image, .meeting-employee {
  display: none;
}

.calendar-content-table-wrapper .mobile-first {
  font-size: 0.8em;
  float: left;
}

.meeting-customer {
  float: right;
  font-weight: bold;
}

.meeting-time {
  float: left;
  direction: ltr;
}

.meeting-type.service-type {
  clear: both;
  float: right;
  font-size: 0.8em;
}

.calendar-content-table-wrapper {
  float: right;
  width: 100%;
}

.calendar-content-table-wrapper * {
  font-size: 14px;
}

.calendar-content-table-wrapper td:first-of-type {
  width: 4%;
  text-align: center;
}

.calendar-hours-column {
  float: right;
}

.calendar-content-headers .calendar-content-table-wrapper {
  width: 100%;
}

.tool-tip-wrapper {
  display: none;
}

.meeting-container {
  clear: both;
  max-height: 100%;
  width: 100% !important;
}

.meeting-content-container {
  padding: 1px 3px;
}

h1 {
  text-align: center;
}

ul {
    list-style: none; /* Remove HTML bullets */
    padding: 0;
    margin: 0;
}

li {
    padding-right: 1px;
}

li::before {
    padding-left: 1px;
}

`;
