import Vue from 'vue';

import Vuex from 'vuex'

import VueButton from './vue/components/button.vue';
import CreditCardInput from './vue/components/credit-card-input.vue';
import TimePicker from './vue/components/time-picker/time-picker.vue';
import Wizard from './vue/components/wizard/wizard-container.vue';
import WhatsappOnBoarding from './vue/components/whatsapp-on-boarding/whatsapp-on-boarding.vue';

import Inventory from './partial-views/inventory/inventory.vue';
import Leads from './partial-views/leads/new.leads.vue';

import {VueMaskDirective} from 'v-mask'
import {MAIN_MODULE} from "./constants";
import WizardStore from './vue/store/wizard';

import PaymentLinksTable from "./modal-views/payment-links-search/payment.links.table.vue";
import MeetingClosingsTable from "./modal-views/meeting-closing-table/meeting.closing.table.vue";
import MeetingHistoryTable from "./modal-views/meeting-history-table/meeting.history.table.vue";
import CustomerVisitHistory from "./modal-views/customer-visit-history/customer.visit.history.vue";
import CustomersTable from "./modal-views/customers-table/customers.table.vue";
import OnlineSchedulingServices
  from "./partial-views/online-scheduling/online-scheduling-services/online.scheduling.services.vue";
import WaitingList from "./partial-views/calendar/waiting-list/waiting.list.vue";

import {
  isConcreteValue,
} from "@tomeravni/easybizy-js-common/common";

Vue.use(Vuex);
Vue.directive('mask', VueMaskDirective);

MAIN_MODULE.run(function (localize, Repository, confirmService, $state, productsPickerRepository, stateManager, printerMediator, $rootScope, $q,
                          dataFilterStructures, configuration, promotionTemplateResolver, $compile, calendarMetadata, contextManager, $modal) {
  const localizeMethod = (...args) => localize.getLocalizedString(...args);

  localizeMethod.isRTL = localize.isRightToLeft();

  Vue.mixin({
    beforeCreate() {
      this.$t = localizeMethod;
      this.Repository = Repository;
      this.clearCashRegisterCache = productsPickerRepository.clearCache;
      this.confirmService = confirmService;
      this.toastr = window.toastr;
      this.$state = $state;
      this.$stateManager = stateManager;
      this.$angularCompile = $compile;
      this.$rootScope = $rootScope;
      this.printerMediator = printerMediator;
      this.configurations = configuration.get();
      this.configurationService = configuration;
      this.promotionTemplateResolver = promotionTemplateResolver;
      this.contextManager = contextManager;
      this.toggleLoading = (isLoading) => {
        $rootScope.$evalAsync(() => {
          $rootScope.$emit('page-in-progress', isLoading);
        })
      }
      this.dataFilterService = dataFilterStructures;
      this.calendarMetadata = calendarMetadata;
      this.pickACustomer = () => pickACustomer($q, $modal);
    }
  });
})


const module = angular.module('easybizy.vue');

module.directive("vueButton", createVueComponent => {
  return createVueComponent(Vue.component("VueButton", VueButton));
});

module.directive("vueCreditCardInput", createVueComponent => {
  return createVueComponent(Vue.component("VueCreditCardInput", CreditCardInput))
});

module.directive("vueWizard", createVueComponent => {
  return createVueComponent(Vue.component("VueWizard", Wizard))
});

module.directive("vueTimePicker", createVueComponent => {
  return createVueComponent(Vue.component("VueTimePicker", TimePicker))
});

module.directive("vueInventory", createVueComponent => {
  return createVueComponent(Vue.component("VueInventory", Inventory));
});

module.directive("vueLeads", createVueComponent => {
  return createVueComponent(Vue.component("VueLeads", Leads));
});

module.directive("vuePaymentLinks", createVueComponent => {
  return createVueComponent(Vue.component("vuePaymentLinks", PaymentLinksTable));
});

module.directive("vueMeetingClosing", createVueComponent => {
  return createVueComponent(Vue.component("vueMeetingClosing", MeetingClosingsTable));
});

module.directive("vueMeetingHistory", createVueComponent => {
  return createVueComponent(Vue.component("vueMeetingHistory", MeetingHistoryTable));
});

module.directive("vueCustomerVisitHistory", createVueComponent => {
  return createVueComponent(Vue.component("vueCustomerVisitHistory", CustomerVisitHistory));
});


module.directive("vueCustomersTable", createVueComponent => {
  return createVueComponent(Vue.component("vueCustomersTable", CustomersTable));
});


module.directive("vueOnlineSchedulingServices", createVueComponent => {
  return createVueComponent(Vue.component("vueOnlineSchedulingServices", OnlineSchedulingServices));
});

module.directive("vueWhatsappOnBoarding", createVueComponent => {
  return createVueComponent(Vue.component("vueWhatsappOnBoarding", WhatsappOnBoarding));
});

module.directive("vueWaitingList", createVueComponent => {
  return createVueComponent(Vue.component("vueWaitingList", WaitingList));
});


module.config(($ngVueProvider) => {
  const store = new Vuex.Store({
    modules: {
      wizard: {
        ...WizardStore,
        namespaced: true,
      }
    }
  });

  $ngVueProvider.setRootVueInstanceProps({
    store: store
  })
});

function pickACustomer($q, $modal) {
  const deferred = $q.defer();
  const opts = {
    backdrop: 'static',
    keyboard: true,
    backdropClick: false,
    template: require('./modal-views/customers-picker/customers.picker.modal.tpl.html'),
    controller: "PickACustomerModalController",
    resolve: {
      saveAction: function () {
        return function (newCustomer, dismissDelegate) {
          if (isConcreteValue(newCustomer?.CustomerId)) {
            deferred.resolve(newCustomer);
          } else {
            deferred.resolve(null);
          }

          dismissDelegate();
        };
      }
    },
    closeFn: function () {
    }
  };

  $modal.open(opts);
  return deferred.promise;
}
