<template>
  <div>
    <div class="flex items-center mb-4 justify-between">
      <div class="flex items-center justify-start">
        <button class="flex items-center justify-start me-4" @click="mediator.print()">
          <div class="icon icon-print me-2"></div>
          <div>{{ $t('_Print_') }}</div>
        </button>
        <button class="flex items-center justify-start" @click="mediator.exportToExcel()">
          <div class="icon icon-excel me-2"></div>
          <div>{{ $t('_Export_') }}</div>
        </button>
      </div>
    </div>
    <Grid :dataSource="dataSource" v-bind="gridProps" :height="'70vh'">
      <template v-slot:footer>
        <div>{{ footerText }}</div>
      </template>
    </Grid>
  </div>
</template>

<script>
import { Grid, GridLabels } from "@tomeravni/easybizy-vue-components";
import MeetingClosingDS from "./meeting.closing.ds";
import { isNonEmptyObject } from "@tomeravni/easybizy-js-common/common";
import Moment from 'moment';
import { resolveDefaultGridLabels } from "../../vue/vue.helpers";
import { toHumanReadablePrice } from "@tomeravni/easybizy-js-common/money";

export default {
  components: {
    Grid,
  },
  props: {
    mediator: {
      type: Object
    }
  },
  data() {
    const labels = resolveDefaultGridLabels(this.$t);
    const api = this.Repository.Custom("QueryableTable").meetingClosing()
    api.pageSize = 30;

    const dataSource = new MeetingClosingDS(api, this.$t, this.$stateManager, this.mediator, this.Repository, this.printerMediator);
    dataSource.filterValues = {
      MeetingStartTime: [Moment().startOf('week').toDate(), new Date()],
      MeetingCreatedOn: []
    }

    const summaryDictionary = {
      services: this.$t('_Services_'),
      customers: this.$t('_Customers_'),
      originalPrice: this.$t('_OriginalPrice_'),
      actualPrice: this.$t('_ServiceActualPrice_'),
      relativePrice: this.$t('_RelativePrice_'),
      serviceCost: this.$t('_ServiceCost _')
    }

    dataSource.setFooter = (data) => {
      this.footerText = this.adaptFooterText(data)
    }

    return {
      dataSource,
      summaryDictionary,
      gridProps: {
        freezeHeader: true,
        hideBorder: false,
        elevated: true,
        pickColumns: true,
        rowHeightMode: 'tight',
        backgroundColor: 'auto',
        preventScrollTopButton: false,
        enableFilter: true,
        isFilterVisible: true,
        forceFullHeight: true,
        labels
      },
      footerText: ''
    }
  },
  mounted() {

  },
  methods: {
    adaptFooterText(footerData) {
      if (!isNonEmptyObject(footerData) || footerData.HideFooter) {
        return ''
      }
      const { services, customers, originalPrice, actualPrice, relativePrice, serviceCost } = this.summaryDictionary;

      let result = `${services}: ${footerData.Count}, ${customers}:` +
        `${footerData.Customers}, ${originalPrice}: ${toHumanReadablePrice(footerData.OriginalPrice)},` +
        `${actualPrice}: ${toHumanReadablePrice(footerData.ActualPrice)}, ${relativePrice}: ${toHumanReadablePrice(footerData.RelativePrice)}`;

      if (this.mediator.showServiceCost) {
        result += `, ${serviceCost}: ${footerData.ServiceCost}`
      }

      return result;
    }
  }
}
</script>
