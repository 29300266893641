var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-wizard" },
    [
      _c("div", { staticClass: "wizard-mask" }),
      _vm._v(" "),
      _c("Wizard", {
        attrs: {
          controller: _vm.controller,
          currentStep: _vm.currentStep,
          isNextVisible: _vm.isNextVisible,
        },
        on: { change: _vm.change, prev: _vm.prev, next: _vm.next },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }