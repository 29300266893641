import './external.voucher.details.less'

angular.module('easybizy.products').controller('ExternalVoucherDetailsController', function (
  $scope, $stateParams, Repository, $window, toolbar, localize, $timeout,
  $filter, configuration, $state, $q
) {
  toolbar.set([]);
  init();
  $scope.editMode = false;
  $scope.productFieldsToValidate = {}; // just initialize fields to validate.
  $scope.validateActionWrapper = {}; // this is an object to wrap an action, so the form validator can
  $scope.productOrServiceDynamic = {};

  $scope.edit = function () {
    $scope.editMode = !$scope.editMode;
    if ($scope.editMode) {
      $scope.originalProuctOrService = $.extend({}, $scope.productOrService);
    }
  };

  $scope.cancel = function () {
    $.extend($scope.productOrService, $scope.originalProuctOrService);
    $scope.voucherServices.length = 0;

    if ($scope.productOrServiceDynamic.voucherServices) {
      $scope.voucherServices.pushAll($scope.productOrServiceDynamic.voucherServices);
    }

    $scope.edit();
  };

  // backFilter
  $scope.leftSideBackFilterModel = {};
  $scope.leftSideBackFilterModel.filters = [
    { type: "backButton" }
  ];

  $scope.filtersModel = {
    filters: [
      { type: "simpleHeader", params: { header: localize.getLocalizedString("_ExternalVoucherUsages_") } }
    ]
  };


  var configuredCurrency = configuration.get().CashRegisterSettings.Currency.Value;
  var currencySymbol = "".toCurrencySymbol(null, configuredCurrency);
  $scope.fields = [
    {
      showLabel: false,
      fieldName: 'ExternalVoucherName',
      icon: "icon icon-tag",
      placeholder: localize.getLocalizedString("_ProductName_"),
      validation: "{'required': true, 'max-length': 30}"
    },
    {
      showLabel: false, fieldName: 'Cost',
      icon: "icon icon-banknote-out", unitOfMeasure: currencySymbol,
      placeholder: localize.getLocalizedString("_PurchasePrice_"),
      validation: "{'required': true, 'decimal': true}"
    },
    {
      showLabel: false, fieldName: 'Price',
      icon: "icon icon-banknote-in", unitOfMeasure: currencySymbol,
      placeholder: localize.getLocalizedString("_RetailPrice_"),
      validation: "{'required': true, 'decimal': true}"
    },
    {
      fieldName: 'SerialNumber', icon: 'icon icon-barcode',
      mutualFieldName: 'SerialNumber',
      placeholder: localize.getLocalizedString('_BarcodeNumber_'),
      validation: "{'required': false, 'max-length': 20}"
    },
    {
      fieldName: 'Color', icon: "icon icon-color", placeholder: localize.getLocalizedString("_ServiceColor_"),
      validation: "{'color': true }", type: 'color'
    }
  ];


  $scope.isSavingWrapper = { isSaving: false };

  $scope.imageIdFromAppToLink = 0;
  $scope.imageUpdated = function (entity, image) {
    $scope.imageIdFromAppToLink = image.id;
  };

  $scope.save = function () {
    if (validateEntity()) {
      $scope.isSavingWrapper.isSaving = true;

      var entityToSave = Object.assign({
        Title: $scope.productOrService.ExternalVoucherName,
      }, $scope.productOrService);

      entityToSave.SelectedExternalVoucherVendorId = $scope.selectedVendorWrapper.selected.value;
      entityToSave.Color = $scope.productOrService.Color;

      delete entityToSave.CreatedOn;
      delete entityToSave.alwaysExpanded;
      delete entityToSave.State;
      delete entityToSave.$$hashKey;
      delete entityToSave.LastUpdated;
      delete entityToSave.Label;
      delete entityToSave.RetailPrice;
      delete entityToSave.ExternalVoucherName;
      delete entityToSave.SerialNumber;

      if (!entityToSave.DefaultImage) {
        delete entityToSave.DefaultImage;
      }

      entityToSave.ServiceMetadatas = $scope.voucherServices.map(function (serviceMetadata) {
        return serviceMetadata.value;
      });

      $scope.vendors.forEach(function (vendor) {
        if (vendor.value < 0) {
          entityToSave.VendorsToCreate = entityToSave.VendorsToCreate || [];
          entityToSave.VendorsToCreate.push({ id: vendor.value, name: vendor.name });
        }
      });


      if ($scope.imageIdFromAppToLink > 0) {
        entityToSave.ImageId = $scope.imageIdFromAppToLink;
      }

      Repository.Custom("EntitiesLazyRepository").update(entityToSave)
        .then(function () {
          $scope.isSavingWrapper.isSaving = false;
          init(true);
          $scope.edit();
        })
        .catch(function (e) {
          toastr.error(localize.getLocalizedString("_ErrorSavingEntity_"));
          $scope.isSavingWrapper.isSaving = false;
        })
    }
  };


  $scope.vouchersDataProxy = Repository.Custom("ProductOrServiceDetails").data($stateParams.voucherId, 'ExternalVoucherMetadata');
  $scope.isLoadingDataWrapper = {};

  $scope.tileActionsResolver = {
    deleteEntity: function () {
      var deferred = $q.defer();
      Repository.Entity('ExternalVoucherMetadata').remove($scope.productOrService)
        .then(function () {
          toastr.success(localize.getLocalizedString("_EntityRemovedSuccessfully_"));
          $state.go('Products');
          deferred.resolve($scope.productOrService);
        })
        .catch(function (e) {
          toastr.error(localize.getLocalizedString("_ErrorRemovingEntity_"));
          deferred.reject(e);
        });

      return deferred.promise;
    }
  };

  /* voucher services. */
  $scope.voucherServicesProxy = Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory(true).entity("service").delegate(servicesResolved);
  $scope.voucherServices = [];
  $scope.removeVoucherService = function (service) {
    $scope.voucherServices.remove(service);
  };

  $scope.externalVoucherServices = [
    { name: localize.getLocalizedString("_AddVoucherService_"), value: null }
  ];

  $scope.selectVoucherValueWrapper = {};


  $scope.selectedVoucherServicesWrapper = { selectedVoucherService: $scope.externalVoucherServices[0] };
  $scope.voucherServiceSelected = function (voucherService) {
    for (var idx in $scope.voucherServices) {
      if ($scope.voucherServices[idx].value === voucherService.value) {
        return;
      }
    }


    var copyOfVoucher = Object.assign({}, voucherService);
    delete copyOfVoucher.$$hashKey;
    $scope.voucherServices.push(copyOfVoucher);
    // setTimeout(function() {
    $scope.selectVoucherValueWrapper.select($scope.externalVoucherServices[0]);
    // })
  };

  /* end of voucher services. */


  function init(preventReloadingOfStatistics) {
    Repository.Entity('ExternalVoucherMetadata').query().expand("ServiceMetadatas,Images").id($stateParams.voucherId).get()
      .then(function (data) {
        getVendors();
        querySucceeded(data);
      })
      .catch(queryFailed);

    if (!preventReloadingOfStatistics) {
      loadVoucherStatistics();
    }

  }

  function queryFailed() {
    toastr.error(localize.getLocalizedString("_EntityWasNotFound_"));
    $timeout(function () {
      $state.go('Products');
    }, 3000);
  }

  function querySucceeded(data) {
    if (!data) {
      return queryFailed();
    }

    var updateProductOrService = function () {
      $scope.externalVoucherTileWrapper = [];
      $scope.productOrService = data;
      $scope.productOrService.EntityType = 'ProductOrService';
      $scope.productOrService.ConcreteType = 'ExternalVoucherMetadata';

      $scope.productOrService.Label = data.ExternalVoucherName;
      $scope.productOrService.RetailPrice = data.Price;

      $scope.productOrService.alwaysExpanded = true;
      $scope.externalVoucherTileWrapper.push($scope.productOrService);
      $scope.productOrServiceDynamic.voucherServices = [];

    };

    updateProductOrService();
  }

  function servicesResolved(results) {
    if (angular.isDefined($scope.productOrService?.ServiceMetadatas)) {
      var servicesMap = {};
      results.forEach(function (serviceCategory) {
        if (serviceCategory.value) {
          serviceCategory.value.forEach(function (serviceMetadata) {
            servicesMap[serviceMetadata.value] = serviceMetadata;
          })
        }
      });

      $scope.productOrService.ServiceMetadatas.forEach(function (voucherService) {
        var serviceAsValue = servicesMap[voucherService.ServiceMetadataId];
        if (serviceAsValue) {
          $scope.productOrServiceDynamic.voucherServices.push(serviceAsValue);
          $scope.voucherServices.push(serviceAsValue);
        }
      });
    } else {
      $timeout(() => servicesResolved(results), 500);
    }
  }


  function loadVoucherStatistics() {
    $scope.isLoadingProductInfo = true;
    $scope.viewLabels = {};
    $scope.viewLabels.firstInfoKey = localize.getLocalizedString("_QuantityUsed_");
    $scope.viewLabels.secondInfoKey = localize.getLocalizedString("_TotalIncomes_");
    $scope.viewLabels.thirdInfoKey = localize.getLocalizedString("_VoucherRelativePart_");
    Repository.Custom("ProductOrServiceDetails").info($stateParams.voucherId, 'ExternalVoucherMetadata')
      .then(function (data) {
        $scope.viewLabels.firstInfoValue = Math.floor(data.VoucherUsed);
        $scope.viewLabels.secondInfoValue = Math.floor(data.TotalIncomes).toMoney();
        $scope.viewLabels.thirdInfoValue = parseFloat(data.RelativePart).toFixed(2) + "%";

        $scope.isLoadingProductInfo = false;
      })
      .catch(function (err) {
        throw (err);
      });
  }

  function getVendors() {
    $scope.vendors = [
      // {name: '+ ' + localize.getLocalizedString("_SelectVendor_"), value: null}
    ];

    $scope.selectedVendorWrapper = { selected: null };

    Repository.Entity('ExternalVoucherVendor').query().get().then(function (result) {
      result.value.forEach(function (vendor, idx) {
        $scope.vendors.push({ name: vendor.ExternalVoucherVendorName, value: vendor.ExternalVoucherVendorId });

        if (vendor.ExternalVoucherVendorId == $scope.productOrService.ExternalVoucherVendorId) {
          $scope.selectedVendorWrapper.selected = $scope.vendors[idx];
        }
      });

    }).catch(function (err) {
      toastr.error(localize.getLocalizedString('_ErrorLoadingVouchersVendors_'));
    });

    $scope.addNewVendor = function () {
      console.log('vendor added.');
    };
  }

  function validateEntity() {
    var validationFields = $scope.validateActionWrapper.validate();
    if (validationFields.length != 0) { // is valid form?
      var wrongFieldName = $scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
      var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
      toastr.error(localizedError);
      return false;
    }

    if (!$scope.selectedVendorWrapper.selected.value) {
      toastr.warning(localize.getLocalizedString("_VendorIsAMandatoryField_"));
      return false;
    }

    return true;

  }


});
