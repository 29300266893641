import './large.invoice.generator.directive.less'

angular.module('easybizy.easybizyModalViewsModel').directive("invoiceByMail", ['$timeout', 'localize', 'printerMediator', 'Repository', 'configuration',
  function ($timeout, localize, printerMediator, Repository, configuration) {
    return {
      restrict: 'E',
      replace: true,
      template: require('../../../modal-views/cash-register-checkout/large-invoice-generator/large.invoice.generator.directive.tpl.html'),
      scope: {
        invoiceResult: '=',
        doneSendingInvoice: '&',
        mode: '@'
      },
      link: function (scope, element, attrs) {
        scope.$watch('invoiceResult', (newVal) => console.log(newVal));
        var configurations = configuration.get();
        var isPriceQuoteMode = false;

        if (scope.mode == 'priceQuote') {
          isPriceQuoteMode = true;
        }

        scope.invoiceByMail = {};
        scope.invoiceByMail.To = "";
        scope.invoiceByMail.CC = "";
        scope.invoiceByMail.mailContent = isPriceQuoteMode ? localize.getLocalizedString("_PriceQuoteMailContent_") :
          localize.getLocalizedString("_MailInvoiceContent_");

        scope.fields = [
          {
            fieldName: 'To',
            icon: "icon icon-mail-outline",
            placeholder: localize.getLocalizedString("_MainReceptionist_"),
            validation: "{'required': true, 'email': true}"
          },
          {
            fieldName: 'CC',
            allowMultiple: true,
            icon: "icon icon-email-mail-streamline",
            placeholder: localize.getLocalizedString("_OtherReceptionists_"),
            validation: "{'email': true}"
          },
          {
            fieldName: 'Subject',
            icon: "icon icon-details",
            placeholder: localize.getLocalizedString("_SubjectPlaceHolder_"),
            validation: "{'required': true, 'max-length': 70}"
          }
        ];

        scope.contentPlaceholder = localize.getLocalizedString("_MailPlaceholder_");
        scope.invoiceFieldsToValidate = {}; // just initialize fields to validate.
        scope.validateActionWrapper = {}; // this is an object to wrap an action, so the form validator can

        scope.isPrintingReceipt = false;
        scope.print = function () {
          scope.isPrintingReceipt = true;
          if (isPriceQuoteMode) {
            printPriceQuote();
            //scope.doneSendingInvoice()();
          } else {
            printInvoiceReceipt();
          }
        };

        scope.isSendingByMail = false;
        scope.sendByMail = function () {
          var validationFields = scope.validateActionWrapper.validate();
          if (validationFields.length != 0) { // is valid form?
            var wrongFieldName = scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
            var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
            toastr.error(localizedError);
            return;
          }

          scope.isSendingByMail = true;
          if (isPriceQuoteMode) {
            Repository.Custom("PriceQuote").send(getMailObject())
              .then(function (data) {
                if (data.Succeeded) {
                  toastr.success(localize.getLocalizedString("_PriceQuoteSentByMail_"));
                  scope.doneSendingInvoice()();
                } else {
                  toastr.error(localize.getLocalizedString("_ErrorSendingPriceQuote_" + " " + data.Message));
                }
                scope.isSendingByMail = true;

              }).catch(function (e) {
              toastr.error(localize.getLocalizedString("_ErrorSendingPriceQuote_"));
              scope.isSendingByMail = true;
            });
          } else {
            Repository.Custom("CashRegister").sendByMail(getMailObject()).then(function (data) {
              if (data.results[0].Result.Succeeded) {
                toastr.success(localize.getLocalizedString("_InvoiceSentByMail_"));
                scope.doneSendingInvoice()();
              } else {
                toastr.error(localize.getLocalizedString("_ErrorSendingInvoice_") + " " + data.results[0].Message);
              }
              scope.isSendingByMail = true;

            }).catch(function (e) {
              toastr.error(localize.getLocalizedString("_ErrorSendingInvoice_"));
              scope.isSendingByMail = true;
            });
          }
        };

        function getMailObject() {
          return {
            Id: scope.invoiceByMail.id,
            To: [scope.invoiceByMail.To],
            CC: scope.invoiceByMail.CC.length > 0 ? scope.invoiceByMail.CC.split(',') : null,
            Subject: scope.invoiceByMail.Subject,
            Message: scope.invoiceByMail.mailContent
          };
        }


        var iframeEl = element.find('.invoice-preview-html');
        //iframeEl.on('ready', function (e) {
        //console.log(e);
        //});

        scope.$watchOnce('invoiceResult', function (newVal) {
          if (angular.isDefined(newVal)) {

            if (isPriceQuoteMode) {
              setMailFieldsForPriceQuote();
            } else {
              setMailFieldsForMailInvoice();
            }

            putContentInIFrame();


            iframeEl.load(function () {
              putContentInIFrame();
            });


          }
        });

        function putContentInIFrame() {
          iframeEl.contents().find('body').html(isPriceQuoteMode ? scope.invoiceResult.Html :
            scope.invoiceResult.Receipt)
            .addClass("client-view-price-quote")
            .find('.page-wrap').css({
            'margin-top': '-20px',
            'transform': 'scale(0.75)',
            'transform-origin': '0 0 0'
          });
        }

        function setMailFieldsForMailInvoice() {
          scope.invoiceByMail.To = scope.invoiceResult.EmailAddress ? scope.invoiceResult.EmailAddress : "";
          scope.invoiceByMail.CC = configurations.InvoiceSettings.DefaultInvoiceRecipients.Value ?
            configurations.InvoiceSettings.DefaultInvoiceRecipients.Value :
            "";
          scope.invoiceByMail.id = scope.invoiceResult.InvoiceId;
          scope.invoiceByMail.Subject = localize.getLocalizedString("_MailInvoiceSubject_", configurations.BusinessDetails.BusinessName.Value);//, scope.invoiceResult.InvoiceIndex);
        }

        function setMailFieldsForPriceQuote() {
          var priceQuote = scope.invoiceResult.PriceQuote;
          scope.invoiceByMail.To = priceQuote.MailTo ? priceQuote.MailTo : "";
          scope.invoiceByMail.CC = "";
          scope.invoiceByMail.id = priceQuote.PriceQuoteId;
          scope.invoiceByMail.Subject = localize.getLocalizedString("_MailPriceQuoteSubject_", configurations.BusinessDetails.BusinessName.Value, priceQuote.Title);
        }

        function printInvoiceReceipt() {
          Repository.Custom("CashRegister").invoiceReceipt(scope.invoiceResult.InvoiceId)
            .then(function (data) {
              try {
                printerMediator.print(data.results[0].Receipt);
                scope.doneSendingInvoice()();
              } catch (e) {
                toastr.error(localize.getLocalizedString("_CannotPrintRecipt_"));
                scope.isPrintingReceipt = false;
              }
              scope.isPrintingReceipt = false;
            })
            .catch(function (e) {
              toastr.error(localize.getLocalizedString("_CannotPrintRecipt_"));
              scope.isPrintingReceipt = false;
            });
        }

        function printPriceQuote() {
          scope.doneSendingInvoice()();
          Repository.Custom("PriceQuote").pdf(scope.invoiceResult.PriceQuote.PriceQuoteId);
        }
      }
    };
  }]);
