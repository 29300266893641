import {
  COLUMN_EDITOR_TYPE, GRID_FILTER_TYPE,
  GridApiResult,
  GridColumn,
  LazyGridDataSource,
} from "@tomeravni/easybizy-vue-components";
import { cleanObj, isNonEmptyArray, valueOrDefault } from "@tomeravni/easybizy-js-common/common";
import {
  DATE_DISPLAY_FORMAT,
  ODATA_DATE_TIME_FORMAT, RTL_DATE_TIME_DISPLAY,
  SESSION_RELATIVE_DATE_FORMAT
} from "../../constants";
import Moment from 'moment';

export default class PaymentLinksDS extends LazyGridDataSource {
  constructor(api, localize, stateManager, mediator, confirmService) {
    super();
    this._api = api;

    const nameCol = new GridColumn('customerName', localize('_Customer_'), COLUMN_EDITOR_TYPE.LAZY);
    nameCol.width = 170;
    nameCol.filterType = GRID_FILTER_TYPE.STRING;
    nameCol.filterArgs = { label: localize('Search'), debounce: true };
    nameCol.customRenderFunc = (entity, changeDelegate, column, h) => {
      let isActive = entity.status === 'active';
      return h('p', {
        class: `prevent-selection-children ${ isActive ? 'cursor-pointer text--primary' : '' }`.trim(),
        on: {
          click: () => {
            mediator.close();
            mediator.clearCashRegister();
            setTimeout(() => {
              stateManager.setState('CashRegister', cleanObj({
                customerId: entity.customerId,
                relativeDate: Moment(entity.relativeDate, SESSION_RELATIVE_DATE_FORMAT).add(12, 'hours').format(ODATA_DATE_TIME_FORMAT),
              }));
            })

          }
        }
      }, [
        h('span', entity.customerName),
        isActive ? h('span', { class: 'icon icon-open pe-2' }) : undefined
      ].filter((x) => !!x));
    };


    const link = new GridColumn('paymentLink', localize('_Link_'), COLUMN_EDITOR_TYPE.LAZY);
    link.width = 180;

    link.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('a', {
        attrs: {
          href: entity.paymentLink,
          target: '_blank'
        }
      }, entity.paymentLink);
    };

    const other = localize('_Other_');
    const status = new GridColumn('status', localize('_Link_'), COLUMN_EDITOR_TYPE.LAZY);
    status.width = 120;
    const statusMap = {
      paid: localize('_Paid_'),
      done: localize('Done'),
      active: localize('_On_'),
      validated: localize('_CreditValidated_'),
      conflicted_payment: localize('_ConflictedPayment_')
    }

    status.filterType = GRID_FILTER_TYPE.SINGLE_SELECT;
    status.filterArgs = {
      placeholder: localize('_Status_'),
      options: [{
        text: statusMap.paid,
        value: 'paid'
      },
        {
          text: statusMap.active,
          value: 'active'
        },
        {
          text: statusMap.conflicted_payment,
          value: 'conflicted_payment'
        },
        {
          text: statusMap.done,
          value: 'done'
        },
        {
          text: statusMap.validated,
          value: 'validated'
        }
      ]
    };


    status.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', statusMap[entity.status] || other);
    };

    const totalAmountCol = new GridColumn('totalAmount', localize('_Total_'), COLUMN_EDITOR_TYPE.DEFAULT);
    totalAmountCol.width = 100;

    const totalAmountBeforeDiscount = new GridColumn('totalAmountBeforeDiscount', localize('_BeforeDiscount_'), COLUMN_EDITOR_TYPE.DEFAULT);
    totalAmountBeforeDiscount.width = 100;

    const totalPaidCol = new GridColumn('totalAmountCharged', localize('_ActuallyPaid_'), COLUMN_EDITOR_TYPE.DEFAULT);
    totalPaidCol.width = 100;

    const totalGiftCardAmount = new GridColumn('giftCardUsageAmount', localize('_GiftCard_'), COLUMN_EDITOR_TYPE.DEFAULT);
    totalGiftCardAmount.width = 100;

    const createdOn = new GridColumn('timeCreated', localize('_CreatedOn_'), COLUMN_EDITOR_TYPE.LAZY);
    createdOn.width = 120;

    createdOn.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', Moment(entity.timeCreated).format(DATE_DISPLAY_FORMAT));
    };

    const expiresOn = new GridColumn('timeCreated', localize('_ExpiresOn_'), COLUMN_EDITOR_TYPE.LAZY);
    expiresOn.width = 180;

    expiresOn.customRenderFunc = (entity, changeDelegate, column, h) => {
      let timeExpires = Moment(valueOrDefault(entity.expiresOn, entity.tmpExpiresOn));
      if (timeExpires.isAfter(Moment())) {
        return h('p', timeExpires.format(DATE_DISPLAY_FORMAT));
      }

      return h('p', { class: 'warning-text line-through ltr' }, [
        h('span', timeExpires.format(RTL_DATE_TIME_DISPLAY)),
        h('span', { class: 'icon icon-meeting--late px-1' })
      ]);
    };

    const self = this;

    const actionsColumn = new GridColumn('actionsColumn', localize('_Actions_'), COLUMN_EDITOR_TYPE.LAZY, false);
    actionsColumn.width = 100;
    actionsColumn.customRenderFunc = (entity, cd, column, h) => {
      if (entity.status !== 'active') {
        return h('div');
      }

      return h('button', {
        class: `icon icon-delete-garbage-streamline text--error`,
        on: {
          click() {
            confirmService.confirm(
              /*title*/localize('_Delete_'),
              /*message*/localize('_ApproveGeneralConfirm_'),
              /*onOk*/() => {
                mediator.deleteSession(entity._id).then(() => {
                  self.pullItem(entity);
                })
              },
              function () {
              },
            );
          }
        }
      });
    }


    this.columns = [
      nameCol,
      expiresOn,
      link,
      status,
      totalPaidCol,
      totalAmountCol,
      totalAmountBeforeDiscount,
      totalGiftCardAmount,
      createdOn,
      actionsColumn
    ]

    // this._sortByColumn = new SortByColumn(kindColumn, false);

  }


  getItemId(item) {
    return item._id;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  get api() {
    return this._api;
  }

  filterChange(adaptedFilter) {
    super.filterChange(adaptedFilter);
  }

  loadOnRemote(top, skip, sort, filter) {
    let customerName = undefined;
    let status = undefined;
    if (isNonEmptyArray(filter)) {
      filter.forEach((concrete) => {
        if (concrete.column.fieldName === 'customerName') {
          customerName = concrete.filterValue;
        } else if (concrete.column.fieldName === 'status') {
          status = concrete.filterValue;
        }
      })

    }

    return this._api(status, customerName, top, skip).then((result) => {
      return new GridApiResult(result.data.map((item) => {
          return item;
        }),
        top,
        skip,
        result.items.total);
    });

  }
}
