/**
 * Created by tomeravni on 1/4/14.
 */

import './value.widget.directive.less'

angular.module('easybizy.dashboard').directive('valueWidget', ['$timeout', 'dashboardWidgetModelResolver', function ($timeout, dashboardWidgetModelResolver) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      preventFullAreaClick: '@',
      type: '@'
    },
    link: function (scope, element) {
      var untilLoadedModel = dashboardWidgetModelResolver.get(scope.type);
      if (untilLoadedModel.resolve) {
        untilLoadedModel.resolve().then(function (data) {
          scope.$evalAsync(() => {
            scope.model = untilLoadedModel;
          })
        });
      } else {
        scope.model = untilLoadedModel;
      }

      scope.shouldBlurValue = scope.blurred = untilLoadedModel.blurred;

      var autoBlurTimeout = null;
      scope.toggleBlurred = function () {
        if (!angular.isDefined(scope.shouldBlurValue)) {
          return;
        }

        scope.blurred = !scope.blurred;
        if (autoBlurTimeout) {
          $timeout.cancel(autoBlurTimeout);
          autoBlurTimeout = null;
        }

        if (!scope.blurred) {
          autoBlurTimeout = $timeout(function () {
            autoBlurTimeout = null;
            scope.blurred = true;
          }, 10000);
        }
      };

      scope.isLink = untilLoadedModel.mainActionType === 'link';
      scope.isAddition = untilLoadedModel.mainActionType === 'addition';

      $timeout(function () {
        if (element.width() < 500) {
          element.addClass("minimized-mode");
        }
      });
    },
    template: require('./value.widget.directive.html')
  };
}]);

