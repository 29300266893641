<template>
  <div class="whatsapp-message-container h-full">
    <div class="message-container">
      <div class="message-container__wrapper">
        <div class="message-container__header" v-if="message && message.header">
          {{ message.header }}
        </div>
        <div class="message-container__body" v-html="messageContent">
        </div>
        <div class="message-container__time">
          {{ time }}
        </div>
      </div>
    </div>
    <div v-if="message && message.buttons" class="flex">
      <div v-for="button in message.buttons" class="whatsapp-message__button text-center">
        {{ button }}
      </div>
    </div>

  </div>
</template>

<script>
import Moment from 'moment'
import {getNumberOfDynamicParams, NUMBER_OF_PARAMS_REGEX} from "../common/helpers";
import linkifyHtml from "linkify-html";

export default {
  props: ['message'],
  data() {
    return {}
  },
  computed: {
    messageContent() {
      if (!this.message) {
        return '';
      }

      const dynamicValues = this.message.dynamicValues;
      const rows = this.message.rawText.split('\n')
      const adapted = rows.map((row) => {
        if (getNumberOfDynamicParams(row) < 1) {
          return `<div>${row}</div>`
        } else {
          return '<div>' + row.replace(NUMBER_OF_PARAMS_REGEX, (match) => {
            const adaptedIndex = parseInt(match.replace(/[^0-9]/g, '')) - 1;
            return `<span class="italic">${dynamicValues[adaptedIndex]}</span>`
          }) + '</div>'
        }
      }).join('\n')

      return linkifyHtml(adapted);
    },
    time() {
      return Moment().format('HH:mm')
    }
  }

}
</script>

<style lang="less">
@import "../../../../styles/variables";
@import "vars";

.whatsapp-message-container {
  width: 300px;
  background-color: #e5ddd5;
  font-family: -apple-system, BlinkMacSystemFont, Arial, sans-serif;
  padding: 25px;
  position: relative;

  &:before {
    background-image: url('/img/background-whatsapp.png');
    background-size: 366.5px 666px;
    content: '';
    height: 100%;
    left: 0;
    opacity: .06;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .message-container {
    background-color: #fff;
    width: 100%;
    border-radius: 7.5px;
    border-top-right-radius: 0;
    box-shadow: 0 1px 0.5px rgba(0, 0, 0, .15);
    min-height: 20px;
    position: relative;
    word-wrap: break-word;

    .message-container__wrapper {
      position: relative;

      &:after {
        background: url('/img/whatsapp-bubble-arrow-right.png') 50% 50% no-repeat;
        background-size: contain;
        content: '';
        height: 19px;
        right: -12px;
        position: absolute;
        top: 0;
        width: 12px;
      }
    }

    .message-container__header {
      color: rgba(0, 0, 0, .76);
      font-size: 15px;
      font-weight: bold;
      line-height: 19px;
      padding: 6px 7px 0 9px;
    }

    .message-container__body {
      color: #262626;
      font-size: 13.6px;
      line-height: 15px;
      padding: 7px 7px 6px 9px;
      white-space: pre-wrap;
    }

    .message-container__time {
      color: rgba(0, 0, 0, 0.4);
      font-size: 11px;
      line-height: 15px;
      padding: 7px;
      margin-bottom: 2px;
    }
  }
}

.whatsapp-message__buttons {
  column-gap: 5px;
}

.whatsapp-message__button {
  background-color: #fff;
  width: 100%;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, .15);
  min-height: 20px;
  position: relative;
  word-wrap: break-word;
  color: @primary-color;
  padding: 7px;
  font-size: 13.6px;
}

</style>
