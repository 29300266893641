import './whats.new.modal.less';

angular.module('easybizy.easybizyModalViewsModel').service('whatsNewService', function ($modal) {
  this.showWhatsNew = (whatsNew) => {
    var opts = {
      backdrop: 'static',
      keyboard: true,
      template: require('../../modal-views/whats-new/whats.new.modal.tpl.html'),
      controller: 'WhatsNewController',
      resolve: {
        saveAction: function () {
          return function (newOrUpdatedMembership, closeDialogDelegate) {

            closeDialogDelegate();
          };
        },
        parameters: function () {
          return {
            whatsNew
          };
        }
      }
    };

    $modal.open(opts);
  }

});

angular.module('easybizy.easybizyModalViewsModel').controller('WhatsNewController',
  function ($scope, $modalInstance, saveAction, $timeout, $http, localize, Repository, parameters) {
    const { whatsNew } = parameters;
    $scope.imageUrl = whatsNew.image_url;
    $scope.header = whatsNew.header;
    $scope.description = whatsNew.description;
    $scope.descriptionHTML = whatsNew.description_html;
    $scope.reverseImage = whatsNew.text_first;
    $scope.maxImageSize = whatsNew.limit_image_size;


    $scope.send = () => {
      Repository.Custom('WhatsNew').gotIt(whatsNew.version).catch((e) => {
        toastr.warning(e.message);
      })

      $scope.close();
    };

    $scope.close = () => {
      $modalInstance.close();
    };

  });
