import { ObservableProperties } from "../../../general/helpers";
import { ODATA_DATE_TIME_FORMAT, SERVER_DATA_DATE_FORMAT } from "../../../constants";
import { isNonEmptyArray } from "@tomeravni/easybizy-js-common/common";

(function () {
  angular.module('easybizy.calendar').service('calendarStore', function (calendarMetadata, Repository, $stateParams, $q, $rootScope, calendarIOService, colorsService,
                                                                         calendarColumns, $transitions, localize, $timeout, configuration) {

    ObservableProperties(this);
    const loadMeetings = _loadMeetings.bind(this);
    const serverGetMeetings = _serverGetMeetings.bind(this);
    const loadMeetingsFromCache = _loadMeetingsFromCache.bind(this);
    const getMeetingsFromCache = _getMeetingsFromCache.bind(this);

    let meetingsByDay = {};
    let meetingsByDayDelegates = {};
    let allMeetingsByUniqueKey = {};
    let meetingsEventUniquenessKey = 0;
    let clearDataEvent;
    const dayIndexKeyFormat = calendarMetadata.formatKeys.columnKey;
    const k_weeklyMeetingsCacheKey = '_weekly_meetings_cache_';
    const k_weeklyMeetingsCacheRangeKey = '_weekly_meetings_cache_range_';
    const k_containsOfRemovedMeetingsKey = '_contains_of_removed_meetings_';
    const k_uniqueLastUpdatedFormat = 'MM/DD/YYYY HH:mm:ss.SS';

    const clearLoadedData = () => {
      meetingsByDayDelegates = {};
      meetingsByDay = {};
      allMeetingsByUniqueKey = {};
    }

    const clearCache = this.clearCache = () => {
      $.localStorage.remove(k_weeklyMeetingsCacheKey);
      $.localStorage.remove(k_weeklyMeetingsCacheRangeKey);
      $.localStorage.remove(k_containsOfRemovedMeetingsKey);
    };

    clearDataEvent = $rootScope.$on('clearCalendarData', () => {
      clearLoadedData();
      clearCache();
    });

    getInShiftEmployees.call(this);
    $transitions.onCreate({ entering: 'Calendar' }, () => {
      getInShiftEmployees.call(this);

    });

    $transitions.onExit({ exiting: 'Calendar' }, () => {
      clearLoadedData();
      this.clearObservers();
      calendarMetadata.actualCellHeight = null;
      calendarMetadata.employeesInShift = null;
      clearDataEvent();
    });

    this.getMeetingsByDate = function (column, allDaysMeetings = false) {
      var date = column.date;
      var deferred = $q.defer();
      var formattedRequestedDate = moment(date).format(dayIndexKeyFormat);
      if (meetingsByDayDelegates.hasOwnProperty(formattedRequestedDate)) { // currently loading.
        deferred.promise.cancel = meetingsByDayDelegates[formattedRequestedDate].pushGetRemovePointer(function () {
          resolveMeetingsByFilter(column, deferred, allDaysMeetings);
        });
      } else if (meetingsByDay.hasOwnProperty(formattedRequestedDate)) { // already loaded.
        resolveMeetingsByFilter(column, deferred, allDaysMeetings);
        extendRangeIfNeeded(date);
      } else { // not yet loaded.
        var firstDayOfWeekForDate = date.clone().weekday(calendarMetadata.startingDay);
        loadMeetings(firstDayOfWeekForDate, firstDayOfWeekForDate.clone().add(1, 'w'), function () {
          extendRangeIfNeeded(firstDayOfWeekForDate);
        });

        return this.getMeetingsByDate(column, allDaysMeetings);
      }

      return deferred.promise;
    };

    this.extendMeetingNotes = function (meetings) {
      return Repository.Custom('Calendar').extendMeetingNotes(meetings.map(x => x.MeetingId)).then((resultMap) => {
        meetings.forEach(meeting => {
          meeting.remarks = resultMap[meeting.MeetingId];
        });

        return meetings;
      });
    };


    this.forceReloadMeeting = function (id, safeMode, socketData) {
      const that = this;
      const metadata = generateMetadataForIO(null, false);


      try {
        if (socketData && ['PUT', 'POST'].includes(socketData.Content.ActionType)) {
          const startTime = moment(socketData.Content.StartTime, SERVER_DATA_DATE_FORMAT);
          const existingMeeting =
            (meetingsByDay[startTime.format(dayIndexKeyFormat)] || []).filter(
              (x) => x.MeetingId === socketData.Content.MeetingId)[0] ||
            {};

          if (existingMeeting.originalMeeting && existingMeeting.originalMeeting.LastUpdated === socketData.Content.TimeUpdated) {
            // No need to updated. The TimeUpdated of the socket meeting is the same is the meeting lastUpdate which means it is already up-to-date.

            console.log('Ignores meeting update.', existingMeeting.originalMeeting.LastUpdated, socketData.Content.TimeUpdated);
            return;
          }
        }
      } catch (e) {
        // this shouldn't happen, socketData should always contain content and action type.
      }

      Repository.Custom('Calendar').getRelatedMeetings(metadata.AffectedFromDate, metadata.AffectedToDate, id)
        .then(function (result) {
          // var result = Object.assign({}, iResult);
          if (!Array.isArray(result.Removed)) {
            result.Removed = [];
          }

          if (result.Removed.indexOf(id) < 0) {
            result.Removed.push(id);
          }

          removeAllMeetingsById(result);
          handleMeetingChanged(result, null, true);

          if (safeMode && Array.isArray(result.Inserted) && result.Inserted.length > 0 && result.Inserted[0].RecurreningCalendarEventId) {
            that.forceReloadMeeting(result.Inserted[0].RecurreningCalendarEventId);
          }

        }).catch(function (err) {
        toastr.error('Error updating socket meeting', err.Message || err.message || '');
      })

    };

    this.toggleContainsOfRemovedMeetings = function (shouldContain) {
      const currentState = $.localStorage.isSet(k_containsOfRemovedMeetingsKey) && !!$.localStorage.get(k_containsOfRemovedMeetingsKey);
      if (shouldContain !== currentState) {
        clearCache();
      }

      if (shouldContain) {
        $.localStorage.set(k_containsOfRemovedMeetingsKey, true);
      }
    }

    function resolveMeetingsByFilter(column, promise, allDaysMeetings) {
      var formattedRequestedDate = moment(column.date).format(dayIndexKeyFormat);
      var meetings = meetingsByDay[formattedRequestedDate].slice(0);
      if (column.employee || column.room || column.filteredEmployeeId || column.status != null) {
        meetings = meetings.map(function (meeting) {
          return new WrappedMeeting(meeting.originalMeeting);
        });

        meetings = meetings.filter((meeting) => filterOnlyByEmployeeAndStatusColumn(column, allDaysMeetings, meeting));
      } else {
        meetings = meetings.filter(meeting => !!allDaysMeetings === meeting.originalMeeting.IsAllDay);
      }

      const uniques = meetings.map(x => x.Unique);
      const uniqueMeetings = meetings.filter((meeting, idx) => uniques.indexOf(meeting.Unique) === idx);
      promise.resolve(uniqueMeetings)
    }

    function filterOnlyByEmployeeAndStatusColumn(column, allDaysMeetings, meeting) {
      const meetingEmployeeId = meeting.EmployeeId;
      const meetingEmployeeMatchColumn = column.employee && column.employee.id === meetingEmployeeId;
      const compositeAdapters = Object.values(meeting.originalMeeting.CompositeAdapters || {}).reduce((acc, x) => [...acc, ...x.map(({ EmployeeId }) => EmployeeId)], [])
      const hasComposedServices = isNonEmptyArray(compositeAdapters);
      return (!!allDaysMeetings === meeting.originalMeeting.IsAllDay) &&
        shouldMeetingBeIncludedInStatus.call(column, meeting) &&
        (!column.employee ||
          /*Not composite*/(!hasComposedServices && meetingEmployeeMatchColumn) ||
          /*Composite*/(hasComposedServices && compositeAdapters.includes(column.employee.id)))
        &&
        (!column.room || column.room.id === meeting.originalMeeting.RoomId) &&
        (!column.filteredEmployeeId ||
          /*Global employee filter - NOT Composite*/
          (!hasComposedServices && column.filteredEmployeeId === meetingEmployeeId) ||
          /*Global employee filter - Composite*/
          (hasComposedServices && (!column.employee || column.filteredEmployeeId === column.employee?.id) && compositeAdapters.includes(column.filteredEmployeeId)));
    }

    function shouldMeetingBeIncludedInStatus(meeting) {
      const deletedMeeting = meeting.originalMeeting.IsDeactiveted;
      const positive = !deletedMeeting &&
        (!this.status ||
          meeting.originalMeeting.MeetingState === this.status ||
          (this.status === calendarMetadata.AWAITING_APPROVAL_STATUS && meeting.originalMeeting.WaitingForApproval));


      const negative = deletedMeeting && (this.status === calendarMetadata.CANCELLED_STATUS);

      return this.status === calendarMetadata.INCLUDE_CANCELLED_STATUS || (
        positive || negative
      );
    }

    // function doesMeetingHasMatchedComposedEmployee(meeting, employeeId, meetingHasCorrectEmployee) {
    //   const compositeAdapters = meeting.originalMeeting.CompositeAdapters;
    //   for (const composedServiceMetadataId in compositeAdapters) {
    //     // This is a hack to solve empty composed items.
    //     if (meetingHasCorrectEmployee && !isNonEmptyArray(compositeAdapters[composedServiceMetadataId])) {
    //       return true;
    //     }
    //
    //     for (const composedServiceIdx in compositeAdapters[composedServiceMetadataId]) {
    //       if (compositeAdapters[composedServiceMetadataId][composedServiceIdx].EmployeeId === employeeId) {
    //         // We want to add this composite employee id to prevent duplication.
    //
    //         // meeting.Unique += '--' + employeeId;
    //
    //         return true;
    //       }
    //     }
    //   }
    //
    //   return false;
    // }

    this.meetingPositionChanged = function (meeting) {
      var deferred = $q.defer();
      var meetingAsNew = Object.assign({}, meeting.originalMeeting);
      if (meetingAsNew.Recurrence) {
        meetingAsNew.RecurreningOriginalStartTime =
          moment(meeting.originalMeeting.StartTime, SERVER_DATA_DATE_FORMAT).format(ODATA_DATE_TIME_FORMAT);
      }

      meetingAsNew.StartTime = meeting.StartTime.format(SERVER_DATA_DATE_FORMAT);
      meetingAsNew.EndTime = meeting.EndTime.format(SERVER_DATA_DATE_FORMAT);
      meetingAsNew.EmployeeId = meeting.EmployeeId;
      meetingAsNew.RoomId = meeting.RoomId;
      meetingAsNew.CompositeAdapters = meeting.CompositeAdapters;
      // We want to make sure last updated is modified correctly.
      meetingAsNew.LastUpdated = moment().format(k_uniqueLastUpdatedFormat);

      var shouldPrevent = !calendarColumns.isValidMeetingPosition(meetingAsNew.EmployeeId || calendarMetadata.filteredEmployeeId, meeting.StartTime, meeting.StartTime, meeting.EndTime);
      if (shouldPrevent) {
        deferred.reject('employee-not-available');
      } else {


        handleMeetingRemoved(meeting);
        let tmpInsertedMeeting;
        // We want to be specific with the date format, hence the expected one is different from the one we send to the server.

        Repository.Custom("Calendar").updateCalendarEvent(Object.assign({}, meetingAsNew, {
          StartTime: meeting.StartTime.format(ODATA_DATE_TIME_FORMAT),
          EndTime: meeting.EndTime.format(ODATA_DATE_TIME_FORMAT)
        }))
          .then(function (result) {
            if (tmpInsertedMeeting) {
              handleMeetingRemoved(tmpInsertedMeeting);
            }

            deferred.resolve(result);
            const insertedMeeting = handleMeetingInserted(result);
            handleModificationOnCache(insertedMeeting, [result]);
          })
          .catch(function (e) {
            deferred.reject(e);
            if (tmpInsertedMeeting) {
              handleMeetingRemoved(tmpInsertedMeeting);
            }
          });

        delete meetingAsNew.Recurrence;
        meetingAsNew.disabled = true;
        tmpInsertedMeeting = handleMeetingInserted(meetingAsNew);
      }

      return deferred.promise;
    };

    this.updateMeetingStatus = function (meeting, status) {
      var deferred = $q.defer();
      calendarIOService.updateStatus(meeting, status)
        .then(function (result) {
          handleMeetingRemoved(meeting);
          var insertedMeeting = handleMeetingInserted(result);
          deferred.resolve(insertedMeeting);
          handleModificationOnCache(insertedMeeting, [result]);
        })
        .catch(function (e) {
          deferred.reject(e);
        });

      return deferred.promise;
    };

    this.markAsNotPaid = function (meeting) {
      var deferred = $q.defer();
      calendarIOService.markAsNotPaid(meeting)
        .then(function (result) {
          handleMeetingRemoved(meeting);
          var insertedMeeting = handleMeetingInserted(result);
          deferred.resolve(insertedMeeting);
          handleModificationOnCache(insertedMeeting, [result]);
        })
        .catch(function (e) {
          deferred.reject(e);
        });

      return deferred.promise;
    };

    this.getCustomerRelatedMeetings = function (meeting) {
      let customerId = meeting.originalMeeting.CustomerId;
      if (customerId) {
        let date = meeting.originalMeeting.StartTime;
        var formattedRequestedDate = moment(date).format(dayIndexKeyFormat);
        var meetings = meetingsByDay[formattedRequestedDate].slice(0);
        var meetings = meetings.filter((m) => m.originalMeeting.CustomerId === customerId && m.Unique !== meeting.Unique)
        return meetings;
      } else {
        return [];
      }
    };

    this.updateMeetingColor = function (meeting, color) {
      var deferred = $q.defer();
      calendarIOService.updateColor(meeting, color)
        .then(function (result) {
          handleMeetingRemoved(meeting);
          var insertedMeeting = handleMeetingInserted(result);
          deferred.resolve(insertedMeeting);
          handleModificationOnCache(insertedMeeting, [result]);
        })
        .catch(function (e) {
          deferred.reject(e);
        });

      return deferred.promise;
    };

    this.updateMeetingRemarks = (meeting, remarks) => {
      return calendarIOService.updateMeetingRemarks(meeting, remarks)
        .then(function (result) {
          handleMeetingRemoved(meeting);
          const insertedMeeting = handleMeetingInserted(result);
          handleModificationOnCache(insertedMeeting, [result]);
          return insertedMeeting;
        })
    }

    this.approveMeeting = function (meetingId) {
      return calendarIOService.approveMeeting(meetingId)
        .then(function (result) {
          $rootScope.$broadcast('meetingCRUDEvent', { Content: { MeetingId: meetingId } });
          return result;
        })
    };

    /***
     * This forces the meetings to be unique as part of a batch.
     * This is used after calendar was fully loaded and event has driven redraw of a column - in which case we force the meetings to be redrawn.
     * @param meetings
     * @return meetings
     */
    this.forceMeetingsUniqueness = (meetings) => {
      if (meetings.length === 0) {
        return meetings;
      }

      meetingsEventUniquenessKey++;
      meetings.forEach((m) => m.eventUnique = meetingsEventUniquenessKey);
      return meetings;
    };

    this.revertMeetingToItsOriginal = _revertMeetingToItsOriginal.bind(this);

    function _revertMeetingToItsOriginal(meeting) {
      var forcedUpdatedMeeting = Object.assign({}, meeting.originalMeeting, { LastUpdated: new Date() });
      handleMeetingRemoved(meeting);
      handleMeetingInserted(forcedUpdatedMeeting);
    }

    var handleMeetingRemoved = this.removeFromStore = _handleMeetingRemoved.bind(this);

    function _handleMeetingRemoved(i_oldMeeting) {
      var formattedOldDate = new WrappedMeeting(i_oldMeeting.originalMeeting).StartTime.format(dayIndexKeyFormat);
      // If the meeting was cut before, it is not there anymore. No need to notify again.
      if (meetingsByDay[formattedOldDate]) {
        var removedItem = meetingsByDay[formattedOldDate].removeById(i_oldMeeting, 'MeetingId', true);
      }
      // Important to use the OLD UNIQUE
      delete allMeetingsByUniqueKey[i_oldMeeting.Unique];

      if (removedItem) {
        this.notify('meeting-changed', {
          type: 'meeting-removed', dateTime: formattedOldDate, meeting: i_oldMeeting
        })
      }

      return removedItem;
    }

    var handleMeetingInserted = this.insertToStore = _handleMeetingInserted.bind(this);

    function _handleMeetingInserted(i_newMeeting, verifyDoNotExist) {
      var newMeeting = insertMeetingToDay(i_newMeeting, verifyDoNotExist);
      if (newMeeting) {
        this.notify('meeting-changed', {
          type: 'meeting-added', dateTime: newMeeting.StartTime.format(dayIndexKeyFormat), meeting: newMeeting
        })
      }

      return newMeeting;
    }

    var handleMeetingChanged = _handleMeetingChanged.bind(this);

    function _handleMeetingChanged(serverResult, oldMeeting, animateIn) {
      if (oldMeeting) {
        if (oldMeeting.originalMeeting.Recurrence) {
          removeAllMeetingsById(serverResult);
        } else {
          handleMeetingRemoved(oldMeeting);
        }
      }

      serverResult.Inserted.forEach(function (meeting) {
        const newMeeting = handleMeetingInserted(meeting, true);
        // Safe Side
        if (newMeeting) {
          if (animateIn) {
            newMeeting.animateIn = true;
          } else {
            newMeeting.defaultIn = true;
          }
        }
      });

      handleModificationOnCache(oldMeeting, serverResult.Inserted);
    }

    function removeAllMeetingsById(serverResult) {
      if (serverResult.Removed) {
        var itemsToRemove = [];
        for (var dayIdx in meetingsByDay) {
          var dayArr = meetingsByDay[dayIdx];
          dayArr.forEach(function (meeting) {
            if (serverResult.Removed.indexOf(meeting.MeetingId) >= 0) {
              itemsToRemove.push(meeting);
            }
          })
        }

        itemsToRemove.forEach(handleMeetingRemoved);
        return itemsToRemove.length;
      }

      return 0;
    }

    this.createOrUpdateMeeting = _createOrUpdateMeeting.bind(this);

    function _createOrUpdateMeeting(meeting, oldMeeting, updateFutureEvents) {
      var deferred = $q.defer();
      var metadata = generateMetadataForIO(oldMeeting, updateFutureEvents);
      calendarIOService.createOrUpdate(meeting, oldMeeting, metadata)
        .then(function (result) {
          handleMeetingChanged(result, oldMeeting);
          deferred.resolve(result);
        })
        .catch(function (err) {
          deferred.reject(err);
        });


      return deferred.promise;
    }

    this.splitMeetingToServices = _splitMeetingToServices.bind(this);

    function _splitMeetingToServices(meeting) {
      var deferred = $q.defer();

      var metadata = generateMetadataForIO(meeting);
      metadata.MeetingId = meeting.MeetingId;
      calendarIOService.splitMeeting(metadata).then(function (result) {
        handleMeetingChanged(result, meeting);
        deferred.resolve(result);
      }).catch(function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }

    this.deleteMeeting = _deleteMeeting.bind(this);

    function _deleteMeeting(meeting, updateFutureEvents, reason) {
      var deferred = $q.defer();

      var metadata = generateMetadataForIO(meeting, updateFutureEvents);
      if (reason) {
        metadata.CancellationReason = reason;
      }

      if (!updateFutureEvents) {
        handleMeetingRemoved(meeting);
      }

      calendarIOService.deleteMeeting(meeting, metadata).then(function (result) {
        handleMeetingChanged(result, meeting);
        deferred.resolve(result);
        $rootScope.$broadcast('meetingCRUDEvent', { Content: { MeetingId: meeting.originalMeeting.MeetingId } });
      }).catch(function (err) {
        handleMeetingInserted(meeting);
        deferred.reject(err);
      });

      return deferred.promise;

    }

    function generateMetadataForIO(oldMeeting, updateFutureEvents) {
      var sortedDates = Object.keys(meetingsByDay).sort();
      var affectedStartDate = moment(sortedDates[0], calendarMetadata.formatKeys.columnKey).format(ODATA_DATE_TIME_FORMAT);
      var affectedEndDate = moment(sortedDates[sortedDates.length - 1], calendarMetadata.formatKeys.columnKey).format(ODATA_DATE_TIME_FORMAT);
      var metadata = {
        AffectedFromDate: affectedStartDate, AffectedToDate: affectedEndDate,
        MeetingDateBeforeModification: oldMeeting ? moment(oldMeeting.originalMeeting.StartTime, SERVER_DATA_DATE_FORMAT).format(ODATA_DATE_TIME_FORMAT) : null
      };

      if (angular.isDefined(updateFutureEvents)) {
        metadata.UpdateFutureEvents = updateFutureEvents;
      }

      return metadata;
    }

    function _loadMeetings(startDate, endDate, successCallback) {
      this.notify('loading-meetings-from-backend');


      var rangeWasCached = loadMeetingsFromCache(startDate, endDate, successCallback);
      if (!rangeWasCached) {
        serverGetMeetings(startDate, endDate, doneGetMeetingsFromServerHandler(startDate, endDate, successCallback));
        iterateDatePeriod(startDate, endDate, function (startDate) {
          var startDateFormatted = startDate.format(dayIndexKeyFormat);
          if (!meetingsByDayDelegates.hasOwnProperty(startDateFormatted)) {
            meetingsByDayDelegates[startDateFormatted] = [];
          }
        });
      }

    }

    var doneGetMeetingsFromServerHandler = _doneGetMeetingsFromServerHandler.bind(this);

    function _doneGetMeetingsFromServerHandler(startDate, endDate, successCallback) {
      var that = this;
      return function (err, results) {
        if (err) {
          that.notify('error-loading-meetings-from-backend');
          return errorLoadingMeetings(err, startDate, endDate);
        }

        that.notify('done-loading-meetings-from-backend');
        doneLoadingMeetings(results, startDate.clone(), endDate.clone());
        if (successCallback) {
          successCallback(startDate.clone(), endDate.clone());
        }
      }
    }

    function _serverGetMeetings(startDate, endDate, callback) {
      Repository.Custom("Calendar").getMeetings(
        startDate.clone().add(-1, 'd').format(ODATA_DATE_TIME_FORMAT),
        endDate.format(ODATA_DATE_TIME_FORMAT), calendarMetadata.showCancelled)
        .then(function (results) {
          callback(null, results);
          loadMeetingToCache(startDate, endDate, results);
        })
        .catch(function (err) {
          callback(err);
        });
    }

    function shouldRangeBeCached(startDate, endDate) {
      var cachedRange = getCachedDates(true);

      // First and last days are the beginning and end of loading.
      return startDate.format(dayIndexKeyFormat) === cachedRange.from && endDate.format(dayIndexKeyFormat) === cachedRange.to;
    }

    function _getMeetingsFromCache(startDate, endDate) {
      if (!shouldRangeBeCached(startDate, endDate)) {
        return false;
      }

      var currentPersistence = configuration.get().BusinessDetails.BusinessName.Value;
      var cachedRange = $.localStorage.isSet(k_weeklyMeetingsCacheRangeKey) && $.localStorage.get(k_weeklyMeetingsCacheRangeKey);
      var currentMappedCache = startDate.format(dayIndexKeyFormat) + endDate.format(dayIndexKeyFormat) + currentPersistence;

      // Either we don't have cached meetings or it's cached not for the right range.
      if (!cachedRange || cachedRange.toString() !== currentMappedCache) {
        return null;
      }

      return $.localStorage.isSet(k_weeklyMeetingsCacheKey) && $.localStorage.get(k_weeklyMeetingsCacheKey);
    }

    function _loadMeetingsFromCache(startDate, endDate, successCallback) {
      try {
        var meetingsMap = getMeetingsFromCache(startDate, endDate);
        if (!meetingsMap) {
          return false;
        }

        var that = this;
        console.log('loaded from cache');
        that.notify('done-loading-meetings-from-backend');
        var meetings = Object.keys(meetingsMap).map(function (meetingKey) {
          return meetingsMap[meetingKey];
        });

        doneLoadingMeetings(meetings, startDate.clone(), endDate.clone());
        if (successCallback) {
          successCallback(startDate.clone(), endDate.clone());
        }

        // Even if it is cached, we won't to reload the meetings to make sure no changes were made.
        serverGetMeetings(startDate, endDate, doneGetMeetingsFromServerHandler(startDate, endDate));
      } catch (e) {
        console.log('error loading from cache', e);
        // Something goes wrong? clear cache.
        clearCache();

        return false;
      }

      return true;
    }

    /**
     * This loads a full meetings to cache.
     * @param startDate
     * @param endDate
     * @param meetings
     * @returns {boolean} false if not updated.
     */
    function loadMeetingToCache(startDate, endDate, meetings) {
      try {

        if (!shouldRangeBeCached(startDate, endDate)) {
          return false;
        }

        var currentPersistence = configuration.get().BusinessDetails.BusinessName.Value;
        var currentMappedCache = startDate.format(dayIndexKeyFormat) + endDate.format(dayIndexKeyFormat) + currentPersistence;
        var currentMeetingMap = {};
        meetings.forEach(function (meeting) {
          currentMeetingMap[uniqueMeetingId(meeting)] = meeting;
        });

        var cachedMeetingsMap = getMeetingsFromCache(startDate, endDate);
        if (cachedMeetingsMap) {
          // Remove any cached meeting that is not present in loaded meeting
          for (var meetingKey in cachedMeetingsMap) {
            var cachedMeeting = cachedMeetingsMap[meetingKey];
            if (cachedMeeting && !currentMeetingMap[meetingKey]) {
              // removeMeeting.
              handleMeetingRemoved(new WrappedMeeting(cachedMeeting));
            }
          }

          // insert any loaded meeting that was not in cache.
          for (var meetingKey in currentMeetingMap) {
            if (!cachedMeetingsMap[meetingKey]) {
              // insert meeting.
              handleMeetingInserted(currentMeetingMap[meetingKey]);
            }
          }
        }

        $.localStorage.set(k_weeklyMeetingsCacheRangeKey, currentMappedCache);
        $.localStorage.set(k_weeklyMeetingsCacheKey, currentMeetingMap);

      } catch (e) {
        console.log('error updating cache', e);
        // Something goes wrong? clear cache.
        clearCache();
      }
    }

    /**
     * This handles modification of a specific meeting(s)
     * @param removedMeeting
     * @param insertedMeetings
     */
    function handleModificationOnCache(/*removedMeeting, insertedMeetings*/) {
      var updatedMap = {};
      var cachedRange = getCachedDates();

      iterateDatePeriod(cachedRange.from, cachedRange.to, function (startDate) {
        if (meetingsByDay[startDate.format(dayIndexKeyFormat)]) {
          meetingsByDay[startDate.format(dayIndexKeyFormat)].forEach(function (meeting) {
            updatedMap[uniqueMeetingId(meeting)] = meeting.originalMeeting || meeting;
          })
        }
      });

      $.localStorage.set(k_weeklyMeetingsCacheKey, updatedMap);
    }

    function errorLoadingMeetings(err, startDate, endDate) {
      iterateDatePeriod(startDate, endDate, function (startDate) {
        var formattedDate = startDate.format(dayIndexKeyFormat);
        console.log('error loading meeting, removed!', formattedDate);
        meetingsByDay[formattedDate] = [];
        delete meetingsByDayDelegates[formattedDate];
      });

    }

    function doneLoadingMeetings(results, startDate, endDate) {
      iterateDatePeriod(startDate, endDate, function (currentDate) {
        var formattedDate = currentDate.format(dayIndexKeyFormat);
        if (meetingsByDay[formattedDate]) {
          meetingsByDay[formattedDate].forEach(function (meeting) {
            delete allMeetingsByUniqueKey[meeting.Unique];
          });
        }

        meetingsByDay[formattedDate] = [];
      });

      results.forEach(function (newMeeting) {
        // For some reason the server returns meetings that are closed to the range.
        var meetingStartTime = moment(newMeeting.StartTime, SERVER_DATA_DATE_FORMAT);
        if (meetingStartTime.isSameOrAfter(startDate) && meetingStartTime.isBefore(endDate)) {
          insertMeetingToDay(newMeeting, true);
        }
      });

      iterateDatePeriod(startDate, endDate, function (startDate) {
        var formattedRequestedDate = startDate.format(dayIndexKeyFormat);
        if (meetingsByDayDelegates[formattedRequestedDate]) {
          meetingsByDayDelegates[formattedRequestedDate].slice(0).forEach(function (promise) {
            meetingsByDayDelegates[formattedRequestedDate].remove(promise);
            promise(meetingsByDay[formattedRequestedDate]);
          });

          delete meetingsByDayDelegates[formattedRequestedDate];
        }
      });
    }

    function insertMeetingToDay(meeting, validateNotExist) {
      const wrappedMeeting = new WrappedMeeting(meeting);
      const formattedStartTime = wrappedMeeting.StartTime.format(dayIndexKeyFormat);

      // We don't want to insert meeting to a day that is not withing the range, since we are not sure weather the
      // day got them all.
      if (!meetingsByDay.hasOwnProperty(formattedStartTime)) {
        return;
      }

      allMeetingsByUniqueKey[wrappedMeeting.Unique] = wrappedMeeting;
      // With recurring replaced meeting this might happen.
      if (validateNotExist) {
        for (const idx in meetingsByDay[formattedStartTime]) {
          const itrMeeting = meetingsByDay[formattedStartTime][idx];
          if (itrMeeting.Unique === wrappedMeeting.Unique) {
            break;
          }

        }
      }

      meetingsByDay[formattedStartTime].push(wrappedMeeting);
      return wrappedMeeting;
    }

    function iterateDatePeriod(startDateInput, endDate, callback) {
      var startDate = startDateInput.clone();
      while (startDate.isBefore(endDate)) {
        callback(startDate, endDate);
        startDate.add(1, 'd');
      }
    }

    function extendRangeIfNeeded(dateRequested) {
      // forward
      var desiredForwardDateThatShouldBeAvailable = dateRequested.clone().add(calendarMetadata.daysToKeepInMemory.forward, 'd');
      var formattedRequestedDate = desiredForwardDateThatShouldBeAvailable.format(dayIndexKeyFormat);
      if (!meetingsByDay.hasOwnProperty(formattedRequestedDate)
        && !meetingsByDayDelegates.hasOwnProperty(formattedRequestedDate)) {
        var fromDatePivot = desiredForwardDateThatShouldBeAvailable.clone();
        while (!meetingsByDay.hasOwnProperty(fromDatePivot.add(-1, 'd').format(dayIndexKeyFormat))) {
        }

        loadMeetings(fromDatePivot.add(1, 'd'), fromDatePivot.clone().add(calendarMetadata.daysToKeepInMemory.forward, 'd'));
      }

      // backward
      var desiredBackwardDateThatShouldBeAvailable = dateRequested.clone().add(calendarMetadata.daysToKeepInMemory.backward, 'd');
      formattedRequestedDate = desiredBackwardDateThatShouldBeAvailable.format(dayIndexKeyFormat);
      if (!meetingsByDay.hasOwnProperty(formattedRequestedDate)
        && !meetingsByDayDelegates.hasOwnProperty(formattedRequestedDate)) {
        var toDatePivot = desiredBackwardDateThatShouldBeAvailable.clone();
        while (!meetingsByDay.hasOwnProperty(toDatePivot.add(1, 'd').format(dayIndexKeyFormat))) {
        }

        loadMeetings(toDatePivot.clone().add(calendarMetadata.daysToKeepInMemory.backward, 'd'), toDatePivot);

      }
    }

    function getCachedDates(formatted) {
      var firstCachedDay = moment().weekday(calendarMetadata.startingDay);
      var lastCachedDay = firstCachedDay.clone().add(1, 'w');

      return {
        from: formatted ? firstCachedDay.format(dayIndexKeyFormat) : firstCachedDay,
        to: formatted ? lastCachedDay.format(dayIndexKeyFormat) : lastCachedDay
      };
    }

    function getInShiftEmployees() {
      Repository.Custom("EmployeeHours").openShifts().then((result) => {
        if (result) {
          calendarMetadata.employeesInShift = result.reduce((acc, employee) => Object.assign(acc, {
            [employee.EmployeeId]: Object.assign({}, employee, { OpenCheckInTime: moment(employee.OpenCheckInTime, SERVER_DATA_DATE_FORMAT) })
          }), {});
          $rootScope.$emit('employees-in-shift-calculated', calendarMetadata.employeesInShift);
        }
      });
    }

    this.WrappedMeeting = WrappedMeeting;

    function WrappedMeeting(serverMeeting) {
      this.originalMeeting = serverMeeting;
      this.wrap();
    }

    Object.defineProperty(WrappedMeeting.prototype, 'title', {
      get: function () {
        return this.originalMeeting.Title || (this.originalMeeting.CustomerName ?
          this.originalMeeting.CustomerName :
          (this.originalMeeting.Remarks || this.originalMeeting.CalendarEventName))
      }
    });

    Object.defineProperty(WrappedMeeting.prototype, 'DrawingUnique', {
      get: function () {
        return this.Unique + (this.eventUnique || '');
      }
    });

    var kGeneralTreatment = localize.getLocalizedString("_GeneralTreatment_");
    WrappedMeeting.prototype.wrap = function () {
      var originalMeeting = this.originalMeeting;

      this.StartTime = moment(originalMeeting.StartTime, SERVER_DATA_DATE_FORMAT);
      this.EndTime = moment(originalMeeting.EndTime, SERVER_DATA_DATE_FORMAT);

      this.Duration = moment.duration(this.EndTime.diff(this.StartTime));
      this.MeetingId = originalMeeting.MeetingId;
      this.EntityType = originalMeeting.EntityType;
      this.EmployeeId = originalMeeting.EmployeeId;
      this.RoomId = originalMeeting.RoomId;
      this.OnlineMeeting = !!originalMeeting.OnlineBookingId;
      this.Unique = uniqueMeetingId(originalMeeting);
      // this.MeetingId + originalMeeting.StartTime + originalMeeting.LastUpdated + originalMeeting.MeetingState;

      if (Array.isArray(originalMeeting.ServiceMetadatas)) {
        this.extended = true;

        if (originalMeeting.ServiceMetadatas.length > 0) {
          this.colorClass = colorsService.getColorByName(originalMeeting.ServiceMetadatas[0].Color);
        }
      }

      if (originalMeeting.Color) {
        this.colorClass = colorsService.getColorByName(originalMeeting.Color);
      }

      if (originalMeeting.ServiceMetadatas && originalMeeting.ServiceMetadatas.length > 0) {
        this.serviceNames = originalMeeting.ServiceMetadatas.map(function (service) {
          return service.Title;
        }).join(', ');
      } else if (originalMeeting.ServiceNames && originalMeeting.ServiceNames.length > 0) {
        this.serviceNames = originalMeeting.ServiceNames.join(', ');
      } else {
        this.serviceNames = kGeneralTreatment;
      }

      if (originalMeeting.Title) {
        originalMeeting.Title = originalMeeting.Title.replace(/undefined/gi, '');
      }


      this.historyMeeting = this.EndTime.isBefore(moment())
    };


    var kErrorExtendingMeetingLocalized = localize.getLocalizedString('_ErrorLoadingFullMeeting_');
    WrappedMeeting.prototype.extend = function () {
      var deferred = $q.defer();
      if (this.extended) {
        deferred.resolve(this);
      }

      var doneLoadingOk = (function (clientMeeting) {
        delete clientMeeting.StartTime;
        delete clientMeeting.EndTime;
        Object.assign(this.originalMeeting, clientMeeting);
        delete this.originalMeeting.Title;
        delete this.originalMeeting.ServiceNames;
        this.extended = true;
        deferred.resolve(this);
      }).bind(this);

      Repository.Custom("Calendar").clientMeetingById(this.MeetingId, moment(this.StartTime).format(ODATA_DATE_TIME_FORMAT))
        .then(doneLoadingOk)
        .catch(function (err) {
          toastr.error(kErrorExtendingMeetingLocalized, err.message);
        });

      return deferred.promise;
    };

    function uniqueMeetingId(iMeeting) {
      var meeting = iMeeting.originalMeeting || iMeeting;
      return meeting.MeetingId + '__' + meeting.StartTime + meeting.LastUpdated + meeting.MeetingState;
    }


  });
}());
