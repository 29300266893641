import './message.dynamic.fields.less'

angular.module('easybizy.common.promotion.editors')
  .directive('messageDynamicFields', function () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      fields: '=',
      insertField: '='
    },
    template: require('./message.dynamic.fields.tpl.html'),
    link: function (scope, element, attrs) {

    }
  }
});
