import './gift.cards.modal.less'
import {
  DATE_DISPLAY_FORMAT,
  ODATA_DATE_ONLY_FORMAT
} from "../../constants";
import { DelayedFunction } from "../../general/helpers";

angular.module('easybizy.easybizyModalViewsModel').controller('GiftCardsModal',
  function ($scope, $modalInstance, localize, Repository) {

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
    $scope.dateRangePresets = [];
    generateImmediateRanges();
    generateMonthsInRange();
    $scope.dateRangeSelectedPresetWrapper = {};
    $scope.dateRangeSelectedPresetWrapper.dateRangeSelectedPreset = $scope.dateRangePresets[2];
    $scope.selectedRange = {};

    $scope.rangeMetadataFrom = {
      fieldName: 'from',
      icon: "icon icon-calendar",
      placeholder: "Start Date",
      validation: "{'required': true}",
      yearRange: "-7:+3",
      type: 'date'
    };
    $scope.rangeMetadataTo = {
      fieldName: 'to',
      icon: "icon icon-calendar",
      placeholder: "End Date",
      validation: "{'required': true}",
      yearRange: "-7:+3",
      type: 'date'
    };

    $scope.$watch('dateRangeSelectedPresetWrapper.dateRangeSelectedPreset', function (newVal) {
      if (newVal.value) {
        $scope.selectedRange.from = newVal.value.from;
        $scope.selectedRange.to = newVal.value.to;
      }
    });


    let searchText = '';
    $scope.$watchGroup([() => searchText, () => $scope.selectedRange.from, () => $scope.selectedRange.to],
      (newValues) => {
        const [searchText, from, to] = newValues;

        let mfrom = moment(from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
        let mto = moment(to, DATE_DISPLAY_FORMAT).add('days',1).format(ODATA_DATE_ONLY_FORMAT);
        Repository.Custom("GiftCard").getGiftCards(searchText, mfrom, mto).then(function (data) {
          $scope.giftCards = data;
        })
          .catch(function (errorMessage) {
            toastr.error(localize.getLocalizedString("_ErrorGettingGiftCards_") + errorMessage.Message);
          });
      });

    const delayedCustomerSearch = new DelayedFunction((value) => $scope.$evalAsync(() => searchText = value), 750);
    $scope.searchWrapper = { text: '' };
    $scope.$watch('searchWrapper.text', (newVal => delayedCustomerSearch.set(newVal)));

    var rowActionsResolver = {
      openGiftCard: function (giftCardId) {
        return Repository.Custom("GiftCard").openGiftCard(giftCardId)
          .catch(function (e) {
            toastr.error(e.Message);
            return false;
          });

      }
    };


    $scope.tableFields = [
      {
        name: localize.getLocalizedString("_RefNumber_"),
        sortable: true,
        width: "10%",
        command: {
          get: function (entity) {
            return entity.RefNumber;
          },
          sort: function () {
            return "RefNumber";
          }
        }
      }, {
        name: localize.getLocalizedString("_Buyer_"),
        sortable: true,
        width: "10%",
        command: {
          get: function (entity) {
            return entity.Buyer;
          },
          sort: function () {
            return "Buyer";
          }
        }
      }, {
        name: localize.getLocalizedString("_For_"),
        width: "15%",
        command: {
          get: function (entity) {
            return entity.Title;
          }
        }
      }, {
        name: localize.getLocalizedString("_GiftCardGreating_"),
        width: "15%",
        command: {
          get: function (entity) {
            return entity.Greating;
          }
        }
      }, {
        name: localize.getLocalizedString("_Date_"),
        width: "10%",
        command: {
          get: function (entity) {
            return moment(entity.CreatedOn).format(DATE_DISPLAY_FORMAT);
          }
        }
      }, {
        name: localize.getLocalizedString("_Invoice_"),
        width: "10%",
        command: {
          get: function (entity) {
            return entity.InvoiceIndex;
          }
        }
      }, {
        name: localize.getLocalizedString("_EmployeeName_"),
        width: "10%",
        command: {
          get: function (entity) {
            return entity.EmployeeName;
          }
        }
      }, {
        name: localize.getLocalizedString("_Paid_"),
        width: "5%",
        command: {
          get: function (entity) {
            return entity.RelativePrice;
          }
        }
      }, {
        name: localize.getLocalizedString("_Amount_"),
        width: "5%",
        command: {
          get: function (entity) {
            return entity.TotalAmount;
          }
        }
      }, {
        name: localize.getLocalizedString("_Used_"),
        width: "5%",
        command: {
          get: function (entity) {
            return entity.AmountUsed;
          }
        }
      }, {
        name: localize.getLocalizedString("_Usages_"),
        width: "15%",
        command: {
          get: function (entity) {
            return entity.Usages.length > 0 ? entity.Usages.map(a => moment(a.CreatedOn).format(DATE_DISPLAY_FORMAT)).join(", ") : null;
          }
        }
      }, {
        name: localize.getLocalizedString("_Actions_"),
        width: "5%",
        type: "Custom",
        template: "<gift-card-row-actions entity='concreteEntity' actions-resolver='actionsResolver'></gift-card-row-actions>",
        extendScopeWith: { actionsResolver: rowActionsResolver },
        command: {
          get: function (entity) {
            return entity.GiftCardId;
          }
        }
      }
    ];

    $scope.giftCards = [];
    $scope.trackByGiftCards = (gc) => gc.GiftCardId + '_' + gc.InvoiceId;

    function generateImmediateRanges() {
      // TODAY.
      const now = moment();
      $scope.dateRangePresets.push({
        name: localize.getLocalizedString('_Today_'),
        value: {
          from: now.clone().format(DATE_DISPLAY_FORMAT),
          to: now.clone().format(DATE_DISPLAY_FORMAT)
        }
      });

      $scope.dateRangePresets.push({
        name: localize.getLocalizedString('_Yesterday_'),
        value: {
          from: now.clone().add(-1, 'days').format(DATE_DISPLAY_FORMAT),
          to: now.clone().add(-1, 'days').format(DATE_DISPLAY_FORMAT)
        }
      });
    }

    function generateMonthsInRange() {
      var now = moment();
      for (var i = 0; i < 12; i++) {
        $scope.dateRangePresets.push({
          name: now.format("MMM YY"),
          value: {
            from: now.clone().date(1).format(DATE_DISPLAY_FORMAT),
            to: now.clone().add(1, 'months').date(0).format(DATE_DISPLAY_FORMAT)
          }
        });
        now = now.subtract(1, 'months');
      }
    }


  });

angular.module('easybizy.cash.register').directive("giftCardRowActions", ['$modal', 'modalCreator', 'localize', 'confirmService', 'Repository',
  function ($modal, modalCreator, localize, confirmService, Repository) {
    return {
      scope: {
        entity: '=',
        actionsResolver: '='
      },
      restrict: 'E',
      replace: true,
      link: function (scope, element, attrs) {
        scope.openGiftCard = (giftCardId) => {
          Repository.Custom("GiftCard").openGiftCard(giftCardId).then(function (data) {
            window.open(data, '_blank');
          })
            .catch(function (e) {
              toastr.error(e.Message);
              return false;
            });
        };
      },
      template: `<div class="relative-container">
                        <div class="centered-div-container">
                          <div class="centered-div-content left-text-aligned">
                            <div class="promotions-manager-row-action-item" ng-click="openGiftCard(entity.GiftCardId)"><span class="icon" ng-class="'icon-preview'"></span> </div>
                          </div>
                        </div>
                   </div>`

    };
  }]);
