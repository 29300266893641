import './emoji.picker.less';
import EmojiButton from '@joeattardi/emoji-button';


(function () {
  'use strict';

  angular.module('easybizy.common.common-controls').directive("emojiPicker", () => {
    return {
      restrict: 'A',
      scope: {
        enabled: '=',
        margin: '='
      },
      link: function (scope, element) {
        var emojiPickerItem = $('<div class="emoji-picker-item-button icon icon-emoji"></div>');
        const picker = new EmojiButton();

        emojiPickerItem.on('click', () => {
          picker.pickerVisible ? picker.hidePicker() : picker.showPicker(emojiPickerItem[0]);

        });

        picker.on('emoji', emoji => {
          element[0].insertAtCaret(emoji);
        });

        var parent = element.parent();
        parent.append(emojiPickerItem);
      }
    }
  });
})();
