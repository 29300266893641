(function () {
  var resizeHandler = '<div class="resize-handler ui-resizable-handle ui-resizable-e glyphicon glyphicon-resize-horizontal"></div>';

  angular.module('easybizy.cash.register').directive('cashRegisterResizable', [
    'localize', 'configuration', function (localize, configuration) {
      return {
        scope: {
          editMode: '=',
          coreSelector: '@',
          reverseSelector: '@',
          percentageWrapper: '='
        },
        link: function (scope, element) {
          var myResizeHandler;


          scope.$watch('percentageWrapper.value', function (newVal) {
            if (!scope.editMode && newVal !== 50 && newVal > 0 && newVal < 100) {
              element.css('width', newVal + '%');
              $(scope.reverseSelector).css('width', (100 - newVal) + '%');
            }
          });

          scope.$watch('editMode', function (newVal) {
            if (!angular.isDefined(newVal)) {
              return;
            }

            if (newVal && !myResizeHandler) {
              myResizeHandler = $(resizeHandler);
              element.prepend(myResizeHandler);
              element.resizable({
                handles: {
                  'e': myResizeHandler
                },
                minWidth: window.innerWidth * .3,
                maxWidth: window.innerWidth * .7,
                alsoResizeReverse: scope.reverseSelector,
                stop: function () {
                  scope.percentageWrapper.value = viewWidthToPercentage();
                }
              });
            } else if (newVal) {
              element.resizable('enable');
            } else {
              var percentageWidth = viewWidthToPercentage();
              var percentageReverseSize = 100 - percentageWidth;
              element.css('width', percentageWidth + '%');
              $(scope.reverseSelector).css('width', percentageReverseSize + '%');
              element.resizable('disable');

            }
          });

          function viewWidthToPercentage() {
            var width = element.css('width');
            width = parseInt(width.replace(/\D/g, ''));
            var windowWidth = window.innerWidth;
            return ((width * 1.0 / windowWidth) * 100).toFixed(2);
          }
        }
      }
    }]);


}());
