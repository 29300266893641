import {MAIN_MODULE} from './constants'
import './modules';
import './imports';
import '../styles/main.less';

MAIN_MODULE.config(['$stateProvider', '$locationProvider', 'stateManagerProvider', function ($stateProvider, $locationProvider, stateManagerProvider) {
  $locationProvider.html5Mode(true);

  $stateProvider
    .state('Dashboard', {
      title: '_Dashboard_',
      url: '/',
      template: require('./partial-views/dashboard/dashboard.tpl.html'),
      controller: 'DashboardController',
      isNavigationRoute: true,
      icon: 'icon icon-home-ol',
      order: 1,
      resolve: {
        employees: [
          'configuration', '$q', '$injector', function (configuration, $q, $injector) {
            var deferred = $q.defer();
            configuration.getUpdatedConfigurationAsync().then(function () {
              $injector.get('calendarMetadata').getEmployeesAsync().then(function () {
                deferred.resolve(true);
              })
            });

            return deferred.promise;
          }]
      }
    }).state('Customers',
    {
      title: '_Customers_',
      url: '/customers?' + stateManagerProvider.getParamsForStateAsQueryParams('Customers'),
      params: stateManagerProvider.getParamsForState('Customers'),
      template: require('./partial-views/customers/customers.tpl.html'),
      controller: 'CustomersController',
      isNavigationRoute: true,
      isQuickNavigationRoute: true,
      icon: 'icon icon-clients-ol',
      order: 4,
      resolve: {
        isValidWhatsappAccount: [
          'configuration', (configuration) => {
            return configuration.isValidWhatsappAccount();
          }]
      }
    })
    .state('CustomerDetails', {
      title: '_CustomerDetails_',
      url: '/customers/:customerId',
      template: require('./partial-views/customer-details/customer-details.tpl.html'),
      controller: 'CustomerDetailsController',
      topState: 'Customers',
      resolve: {
        activeEmployees: [
          'Repository', function (Repository) {
            return Repository.Entity('Employee').query().filter('IsDeactiveted eq false').get().then(({value}) => value);
          }],
        isValidWhatsappAccount: [
          'configuration', (configuration) => {
            return configuration.isValidWhatsappAccount();
          }]
      }
    })
    .state('Calendar', {
      title: '_Calendar_',
      url: '/calendar?' + stateManagerProvider.getParamsForStateAsQueryParams('Calendar'),
      params: stateManagerProvider.getParamsForState('Calendar'),
      template: require('./partial-views/calendar/calendar.tpl.html'),
      controller: 'CalendarController',
      isNavigationRoute: true,
      isQuickNavigationRoute: true,
      icon: 'icon icon-calendar',
      order: 2,
      resolve: {
        employees: [
          'calendarMetadata', function (calendarMetadata) {
            return calendarMetadata.getEmployeesAsync();
          }]
      }
    })
    .state('CashRegister', {
      title: '_Cash Register_',
      url: '/cash-register?' + stateManagerProvider.getParamsForStateAsQueryParams('CashRegister'),
      params: stateManagerProvider.getParamsForState('CashRegister'),
      template: require('./partial-views/cash-register/cash-register.tpl.html'),
      controller: 'CashRegisterController',
      isNavigationRoute: true,
      isQuickNavigationRoute: true,
      icon: 'icon icon-banknote',
      dynamic: true,
      order: 3,
      resolve: {
        personalConfigurations: [
          'configuration', function (configuration) {
            return configuration.getPersonalAsync();
          }]
      }
    })
    .state('Promotions', {
      title: '_PromotionsWidget_',
      url: '/promotions',
      template: require('./partial-views/promotions-widget/promotions.widget.tpl.html'),
      controller: 'PromotionWidgetsController',
      isNavigationRoute: true,
      icon: 'icon icon-campaigns-ol',
      order: 9
    })
    .state('ManagePromotions', {
      title: '_ManagePromotions_',
      url: '/manage-promotions?' + stateManagerProvider.getParamsForStateAsQueryParams('ManagePromotions'),
      params: stateManagerProvider.getParamsForState('ManagePromotions'),
      template: require('./partial-views/promotions-manager/promotions.manager.tpl.html'),
      controller: 'PromotionsManagerController',
      isNavigationRoute: true,
      icon: 'icon icon-promote',
      order: 10
    })
    .state('Products', {
      title: '_ProductsAndServicesSetting_',
      url: '/products?' + stateManagerProvider.getParamsForStateAsQueryParams('Products'),
      params: stateManagerProvider.getParamsForState('Products'),
      template: require('./partial-views/inventory/inventory.tpl.html'),
      controller: 'InventoryController',
      isNavigationRoute: true,
      dynamic: true,
      icon: 'icon icon-tag',
      order: 6,
      resolve: {
        loggedInUser: [
          'configuration', function (configuration) {
            return configuration.loggedInUser;
          }]
      }
    })
    .state('ProductDetails', {
      title: '_ProductDetails_',
      url: '/products/:productId',
      template: require('./partial-views/product-details/product.details.tpl.html'),
      controller: 'ProductDetailsController',
      topState: 'Products'
    })
    .state('IngredientDetails', {
      title: '_IngredientDetails_',
      url: '/ingredients/:ingredientId',
      template: require('./partial-views/product-details/product.details.tpl.html'),
      controller: 'ProductDetailsController',
      topState: 'Products'
    })
    .state('ServiceDetails', {
      title: '_ServiceDetails_',
      url: '/services/:serviceId',
      template: require('./partial-views/product-details/product.details.tpl.html'),
      controller: 'ProductDetailsController',
      topState: 'Products'
    })
    .state('ExternalVoucherDetails', {
      title: '_ExternalVoucherDetails_',
      url: '/external-vouchers/:voucherId',
      template: require('./partial-views/external-voucher-details/external.voucher.details.html'),
      controller: 'ExternalVoucherDetailsController',
      topState: 'Products'
    })
    .state('Employees', {
      title: '_Employees_',
      url: '/employees?' + stateManagerProvider.getParamsForStateAsQueryParams('Employees'),
      params: stateManagerProvider.getParamsForState('Employees'),
      template: require('./partial-views/employees/employees.tpl.html'),
      controller: 'EmployeesController',
      isNavigationRoute: true,
      isQuickNavigationRoute: true,
      icon: 'icon icon-users',
      order: 5
    })
    .state('EmployeeDetails', {
      title: '_EmployeeDetails_',
      url: '/employees/:employeeId',
      template: require('./partial-views/employee-details/employee.details.tpl.html'),
      controller: 'EmployeeDetailsController',
      topState: 'Employees'
    })
    .state('Expenses', {
      title: '_Expenses_',
      url: '/expenses',
      template: require('./partial-views/expenses/expenses.tpl.html'),
      controller: 'ExpensesController',
      isNavigationRoute: true,
      icon: 'icon icon-invoice',
      authenticate: true,
      order: 11
    })
    .state('Reports', {
      title: '_Reports_',
      url: '/reports',
      template: require('./partial-views/reports/reports.tpl.html'),
      controller: 'ReportsController',
      isNavigationRoute: true,
      icon: 'icon icon-graph',
      authenticate: true,
      order: 7
    })

    .state('Configurations', {
      title: '_Configurations_',
      url: '/configurations',
      icon: 'icon icon-system-settings',
      authenticate: true,
      templateProvider: function (configurationTemplate) {
        return configurationTemplate.getAsync().then((res) => {
          let trimmed = res.trim();
          const firstClassOccurrence = trimmed.indexOf('class');
          trimmed = trimmed.substr(0, firstClassOccurrence) + ' accord=\".configuration-section-wrapper\" accord-title=\".configuration-section-title\" ' + trimmed.substr(firstClassOccurrence);
          return trimmed;
        });
      },
      controller: 'ConfigurationsController',
      isNavigationRoute: true,
      order: 20

    })
    .state('Logout', {
      title: '_Logout_',
      url: '/logout',
      icon: 'icon icon-logout',
      directUrl: window.logoutUrl,
      isNavigationRoute: true,
      order: 30
    })


}]);

MAIN_MODULE.provider('runtimeStates', function runtimeStates($stateProvider) {
  // runtime dependencies for the service can be injected here, at the provider.$get() function.
  this.$get = function ($stateRegistry, $state) { // for example
    return {
      addState: function (name, state) {
        $stateProvider.state(name, state);
      },
      removeState: (name) => {
        if ($state.current.name === name) {
          $state.go('Dashboard')
        }

        $stateRegistry.deregister(name)
      }
    };
  };
});
