import io from 'socket.io-client'
import { isNonEmptyString } from "@tomeravni/easybizy-js-common/common";

(function () {
  const SOCKETS_MESSAGE_PREFIX = 'socket-message-';
  const DEFAULT_MESSAGE_NAME = 'SERVER';

  const DEFAULT_EVENT_NAME = SOCKETS_MESSAGE_PREFIX + DEFAULT_MESSAGE_NAME;
  const NEW_SESSION_EVENT = SOCKETS_MESSAGE_PREFIX + '-new-session-created';
  const UNIQUE_ID_COOKIE = 'easybizy-unique-id';

  angular.module('easybizy.common.common-services').service('sockets',
    function ($q, Repository, $rootScope) {
      let socket;
      const toReturn = {
        validateUniqueId: () => {
          if (socket) {
            const uniqueId = $.cookie(UNIQUE_ID_COOKIE);
            const socketUniqueId = isNonEmptyString(socket.query) &&
              socket.query.split('&').map((portion) => {
                const split = portion.split('=');
                return { key: split[0], value: split[1] }
              }).find((x) => x.key === 'unique')?.value;

            if (isNonEmptyString(uniqueId) && uniqueId !== socketUniqueId) {
              console.log('unique id changed, reinitializing socket.');
              socket.disconnect();
              toReturn.init();
            }
          }
        },
        init: function () {
          $rootScope.$emit('socket-status-changed', { status: 'connecting' });
          return Repository.Custom("Configurations").loggedInUser().then(function (contextMeta) {

            const uniqueId = $.cookie(UNIQUE_ID_COOKIE);
            let contextName = contextMeta.context.name.toLowerCase();

            let query = 'context=' + contextName
              + '&userId=' + contextMeta.ebUserId + '&sessionId=' + contextMeta.sessionId + `&unique=${ uniqueId }`;


            socket = io(window.socketServerPath, { query: query }).connect();

            socket.on('connect', function () {
              $rootScope.$emit('socket-status-changed', { status: 'connected', id: socket.io.engine.id });
            });

            socket.on('disconnect', function () {
              $rootScope.$emit('socket-status-changed', { status: 'disconnected' });
            });

            registerDefaultMessages();
            angular.module('easybizy.common.common-services')
            return toReturn;
          })

        },
        defaultEventName: DEFAULT_EVENT_NAME,
        doubleSessionEvent: NEW_SESSION_EVENT,
        emit: function (eventName, data, callback) {
          socket.emit(eventName, data, function () {
            var args = arguments;
            $rootScope.$apply(function () {
              if (callback) {
                callback.apply(socket, args);
              }
            });
          })
        }
      };

      return toReturn;

      function registerDefaultMessages() {
        socket.on(DEFAULT_MESSAGE_NAME, function (data) {
          console.log('SOCKETS!!!!', data);
          $rootScope.$emit(DEFAULT_EVENT_NAME, data);
        });

        socket.on(NEW_SESSION_EVENT, function (data) {
          $rootScope.$emit(NEW_SESSION_EVENT, data);
        });

        socket.on('session-modified', (data) => {
          $rootScope.$emit('session-modified', data);
        });

        socket.on('session-removed', (data) => {
          $rootScope.$emit('session-removed', data);
        });

        socket.on('session-done', (data) => {
          $rootScope.$emit('session-done', data);
        });

        socket.on('new-lead', (data) => {
          $rootScope.$emit('new-lead', data);
        });

        socket.on('conversation-change', (data) => {
          $rootScope.$emit('conversation-change', data);
        })
      }
    });

}());
