import './calendar.meetings.awaiting.approval.less'

(function () {
  angular.module('easybizy.calendar').directive('meetingsAwaitingApproval', [
    'calendarMetadata', 'Repository', 'meetingDrawerHelper', '$state', 'calendarStore', 'localize',
    function (calendarMetadata, Repository, meetingDrawerHelper, $state, calendarStore, localize) {
      return {
        restrict: 'E',
        replace: true,
        scope: {},
        link: function (scope, element) {
          scope.toggled = false;

          scope.toggle = function () {
            scope.toggled = !scope.toggled;
          };

          scope.goToMeeting = function (meeting) {
            $state.go('Calendar', {
              date: moment(meeting.StartTime).format('YY-MM-DD'),
              meeting: meeting.MeetingId
            }, {location: true});
          };

          var dayString = localize.getLocalizedString('_Day_');

          scope.getMeetingTimeEl = function (meeting) {
            var startTime = moment(meeting.StartTime);
            return dayString + ' ' + startTime.format('dddd') + ', ' + (startTime.format(startTime.isSame(moment(), 'year') ? 'DD.MM' : 'DD.MM.YYYY')) + ', ' + meetingDrawerHelper.getMeetingTimeString(meeting);
          };

          scope.$on('meetingCRUDEvent', function () {
            loadAwaitingApproval();
          });

          loadAwaitingApproval();

          function loadAwaitingApproval() {
            Repository.Custom('Calendar').getAwaitingApproval().then(function (meetings) {
              scope.awaitingMeetings = meetings.map(function (meeting) {
                return new calendarStore.WrappedMeeting(meeting);
              });
            }).catch(function (e) {
              toastr.error(e.Message);
            });
          }

        },
        template: require('./calendar.meetings.awaiting.approval.tpl.html')
      }
    }])
}());
