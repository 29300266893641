import './dashboard.less'
import { HOURS_AND_MINUTES_FORMAT, SERVER_DATA_DATE_FORMAT } from "../../constants";

var dashboardModule = angular.module('easybizy.dashboard', ['easybizy.common.tiles',
  'easybizy.common.toolbar.actions', 'easybizy.dashboardWidgetsModule']);

var dashboardWidgetsModule = angular.module('easybizy.dashboardWidgetsModule', ['easybizy.common.tiles',
  'easybizy.common.common.resources', 'highcharts-ng', 'easybizy.common.common-services']);

dashboardModule.controller('DashboardController', function DashboardController($scope, Repository, toolbar) {
  toolbar.set([
    // {
    //   name: 'headerSearch'
    // }
  ]);

});

dashboardWidgetsModule.factory('dashboardWidgetModelResolver', ['$http', 'Repository',
  'iconsResolver', '$state', 'localize', 'configuration', '$q',
  function ($http, Repository, iconsResolver, $state, localize, configuration, $q) {
    var dashboardRepository = Repository.Custom("Dashboard");

    return {
      get: function (widgetType) {
        if (widgetType === 'Customers') {
          return getCustomers();
        } else if (widgetType === 'Products') {
          return getProducts();
        } else if (widgetType === 'Promotions') {
          return getPromotions();
        } else if (widgetType === 'DailyIncomes') {
          return getDailyIncomes();
        }

        throw "must have a widget type";
      }
    };

    function getCustomers() {
      return new function () {
        this.headerIcon = "icon icon-clients-ol";
        this.headerValue = "";
        this.headerTitle = localize.getLocalizedString("_TotalCustomers_");
        this.hasValidValue = false;
        this.actions = [
//                    {
//                        icon: 'icon icon-sms',
//                        value: '',
//                        action: function (action) {
//                            console.log(action);
//                        }
//                    },
//                    {
//                        icon: 'icon icon-email-mail-streamline',
//                        value: '',
//                        action: function (action) {
//                            console.log(action);
//                        }
//                    }
        ];


        this.mainActionType = "link";
        this.mainActionFunc = function () {
          $state.go('Customers');
        };

        var that = this;
        this.resolve = function () {
          that.isLoading = true;
          var deferred = $q.defer();
          dashboardRepository.customers()
            .then(function (data) {
              that.isLoading = false;
              that.headerValue = data.results.TotalCustomers;

              if (data.results.LatelyVisit) {
                that.hasValidValue = true;
                var yearlyVisits = data.results.LatelyVisit.yearlyVisits;
                var monthlyVisits = data.results.LatelyVisit.monthlyVisits;
                var thisMonthVisits = data.results.LatelyVisit.thisMonthVisits;
                var lastMonthAdded = data.results.LatelyAdded.lastMonth;
                var thisMonthAdded = data.results.LatelyAdded.thisMonth;

                that.itemHeader = thisMonthAdded ? thisMonthAdded + " " + localize.getLocalizedString("_Added_") + " " + localize.getLocalizedString("_This Month_") + ', ' : "";
                that.itemHeader += lastMonthAdded ? lastMonthAdded + " " + localize.getLocalizedString("_Added_") + " " + localize.getLocalizedString("_LastMonth_") : "";

                that.itemValue = thisMonthVisits ? thisMonthVisits + " " + localize.getLocalizedString("_CustomerVisits_") + " " + localize.getLocalizedString("_This Month_") + ', ' : "";
                that.itemValue += yearlyVisits ? yearlyVisits + " " + localize.getLocalizedString("_The past year_") + ', ' : "";
                that.itemValue += monthlyVisits ? monthlyVisits + " " + localize.getLocalizedString("_MonthlyAvarage_") : "";
              }

              deferred.resolve(data);
            })
            .catch(function () {
              that.isLoading = false;
              deferred.reject(arguments);
            });

          return deferred.promise;
        };
      };
    }

    function getProducts() {
      return new function () {
        this.headerIcon = "icon icon-tag";
        this.headerValue = "";
        this.headerTitle = localize.getLocalizedString("_Products_");
        this.mainActionFunc = function () {
          $state.go('Products');
        };

        this.hasValidValue = false;
        this.actions = [];
        //this.actions = [
        //    {
        //        icon: 'glyphicon glyphicon-earphone',
        //        value: '',
        //        action: function (action) {
        //            console.log(action);
        //        }
        //    },
        //    {
        //        icon: 'glyphicon glyphicon-envelope',
        //        value: '',
        //        action: function (action) {
        //            console.log(action);
        //        }
        //    }
        //];
        this.mainActionType = "link";
        var that = this;
        this.resolve = function () {
          var deferred = $q.defer();
          that.isLoading = true;
          dashboardRepository.products()
            .then(function (data) {
              that.isLoading = false;


              that.headerValue = data.results.TotalProducts;
              if (data.results.LastProductsSale) {
                that.hasValidValue = true;
                var value = data.results.LastProductsSale;
                that.itemHeader = value.thisMonthProductsSale ? value.thisMonthProductsSale + " " + localize.getLocalizedString("_Sold_") + " " + localize.getLocalizedString("_This Month_") + ', ' : "";
                that.itemHeader += value.lastMonthProductsSale ? value.lastMonthProductsSale + " " + localize.getLocalizedString("_Sold_") + " " + localize.getLocalizedString("_LastMonth_") + ', ' : "";
                that.itemHeader += value.yearlyProductsSale ? value.yearlyProductsSale + " " + localize.getLocalizedString("_Sold_") + " " + localize.getLocalizedString("_The past year_") : "";
              }
              that.isLoading = false;
              deferred.resolve(data);
            })
            .catch(function () {
              that.isLoading = false;
              deferred.reject(arguments);
            });

          return deferred.promise;
        };
      };
    }

    function getPromotions() {
      return new function () {
        this.headerIcon = "icon icon-campaigns-ol";
        this.headerValue = "";
        this.headerTitle = localize.getLocalizedString("_ActivePromotions_");
        this.hasValidValue = false;
        this.mainActionFunc = function () {
          $state.go('ManagePromotions');
        };

        this.actions = [];
//                    {
//                        icon: 'glyphicon glyphicon-thumbs-up', value: '', action: function (action) {
//                            console.log(action);
//                        }
//                    },
//                    {
//                        icon: 'glyphicon glyphicon-cloud', value: '', action: function (action) {
//                            console.log(action);
//                        }
//                    }
//                ];
        this.mainActionType = "addition";
        var that = this;
        this.resolve = function () {
          var deferred = $q.defer();
          that.isLoading = true;
          dashboardRepository.promotions()
            .then(function (data) {
              that.isLoading = false;
              that.headerValue = data.results.TotalPromotions;
              var lastPromotionSent = data.results.LastPromotionSendingReport;
              if (lastPromotionSent) {
                that.hasValidValue = true;
                that.itemHeader = !lastPromotionSent.PromotionName ? localize.getLocalizedString("_PromotionsWithoutName_") : lastPromotionSent.PromotionName;
                that.itemHeader += " " + localize.getLocalizedString("_WasSentTo_") + " " + lastPromotionSent.Count + " " + localize.getLocalizedString("_Customers_");
                that.icon = iconsResolver.getIcon(that.itemHeader);
                var lastSent = new moment(lastPromotionSent.SendingTime, SERVER_DATA_DATE_FORMAT);
                that.itemValue = lastSent.format("DD MMM YYYY HH:mm");
              }
              if (data.results.PromotionTypesSendingCount) {
                that.extraInfo = {};
                that.extraInfo.concretes = [];
                that.extraInfo.label = localize.getLocalizedString('_UsedMedia_');
                data.results.PromotionTypesSendingCount.forEach(function (counterType) {
                  that.extraInfo.concretes.push({
                    label: counterType.Type,
                    value: counterType.Count,
                    icon: counterType.Type == 'sms' ? 'icon icon-sms' : 'icon icon-email-mail-streamline'
                  });
                });
              }

              deferred.resolve(data);
            })
            .catch(function () {
              that.isLoading = false;
              deferred.reject(arguments);
            });

          return deferred.promise;
        };
      };
    }

    function getDailyIncomes() {
      return new function () {
        this.headerIcon = "icon icon-banknote";
        this.headerValue = "";
        this.blurred = true;
        this.headerTitle = localize.getLocalizedString("_Today_");
        this.mainActionFunc = function () {
          $state.go('CashRegister');
        };

        this.itemHeader = "";
        this.itemValue = "";
        this.mainActionType = "addition";
        var that = this;

        this.resolve = function () {
          var configurationCurrency = "".toCurrencySymbol(configuration.get().Currency);
          var deferred = $q.defer();
          that.isLoading = true;
          dashboardRepository.dailyIncomes()
            .then(function (data) {
              that.isLoading = false;
              that.headerValue = data.results.TotalIncomes + " " + configurationCurrency;
              var randomInvoice = data.results.RandomInvoice;
              if (randomInvoice) {
                that.hasValidValue = true;
                //that.itemHeader = randomInvoice.Amount + configurationCurrency;
                var createdOn = moment(randomInvoice.CreatedOn, SERVER_DATA_DATE_FORMAT);
                that.itemValue = localize.getLocalizedString("_LastSale_") + " : " + randomInvoice.Amount + configurationCurrency
                  + " " + localize.getLocalizedString("_At_") + " " + createdOn.format(HOURS_AND_MINUTES_FORMAT);
              }

              deferred.resolve(data);
            })
            .catch(function () {
              deferred.reject(arguments);
              that.isLoading = false;
            });

          return deferred.promise;
        };
      };
    }
  }]);
