import './lazy.background.image.directive.less'

(function () {
  'use strict';

  angular.module('easybizy.common.common-controls').directive("lazyBackgroundImage", lazyBackgroundImageDirective);

  function lazyBackgroundImageDirective() {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var imageWatcher = scope.$watch(attrs.lazyBackgroundImage, setImageTemp);
        var wasRendered = false;
        var tempElement;

        function setImageTemp(newVal, oldVal) {
          if (wasRendered && newVal === oldVal) {
            return;
          }

          wasRendered = true;
          destroyTempElement();
          if (attrs.keepWatching != 'true') {
            imageWatcher();
          }


          if (newVal == null && attrs.iconReplacement) {
            element.addClass(attrs.iconReplacement);
          }

          var backgroundImage = scope.$eval(attrs.lazyBackgroundImage);
          if (!backgroundImage && attrs.default === 'client') {
            backgroundImage = 'https://files.easybizy.net/files/__index/public/other/profile.png';
          }


          element.addClass('lazy-background-image not-loaded');
          element.css('background-image', "url('" + backgroundImage + "')");
          tempElement = $('<img src="' + backgroundImage + '" style="position: absolute; visibility:hidden; opacity: 0; height: 1px; width: 1px; top: 0px; left: 1px;"/>');
          tempElement.appendTo('body');
          tempElement.on('load', function () {
            element.addClass('loaded');
            element.removeClass('not-loaded');
            tempElement.off('load');
            tempElement.remove();
          });

          if (attrs.hasOwnProperty('default') || attrs.iconReplacement) {
            tempElement.on('error', function () {
              if (attrs.iconReplacement) {
                element.addClass(attrs.iconReplacement + ' icon-mode');
              } else {
                element.css('background-image', "url('images/avatar_placeholder.png')");
              }

              element.addClass('loaded');
              element.removeClass('not-loaded');
              tempElement.off('load');
              tempElement.remove();
              tempElement.off('error');
            })
          }

        }


        scope.$on('$destroy', destroyTempElement);

        function destroyTempElement() {
          if (tempElement) {
            tempElement.remove();
          }

        }
      }
    };
  }

})();
