import './send.by.email.content.less'

angular.module('easybizy.common.common-controls').directive('sendByEmailContent', function (localize) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      emailAddress: '=',
      send: '=',
      mask: '=',
      close: '='
    },
    link: function (scope) {
      scope.mailInvoiceField = {
        fieldName: 'EmailAddress', icon: "icon icon-mail-outline", placeholder: localize.getLocalizedString("_Email_"),
        validation: "{'email': true, 'required': true}"
      };

      scope.emailModel = { EmailAddress: scope.emailAddress || '' };
      scope.emailAddressToValidate = {};
      scope.validateActionWrapper = {};

      scope.sendInvoiceByMail = function () {
        var validationFields = scope.validateActionWrapper.validate();
        if (validationFields.length !== 0) { // is valid form?
          return toastr.error(localize.getLocalizedString("_WrongEmail_"));
        }

        scope.sendingInvoice = true;
        scope.send(scope.emailModel.EmailAddress);
      };
    },
    template: require('./send.by.email.content.tpl.html')
  };
});
