import {GridLabels} from "@tomeravni/easybizy-vue-components";

export const resolveDefaultGridLabels = ($t) => {
  const dictionary = {
    settings: $t('_Columns_'),
    totalRows: $t('_Rows_'),
    selectedRows: $t('_Selected_'),
    presets: $t('_Presets_'),
    defaultPreset: $t('_DefaultPreset_'),
    savePreset: $t('_SavePreset_')
  };

  return new GridLabels((key) => dictionary[key]);
}

export const wrapValue = function (obj, fields)  {
  const toReturn = {};
  fields.forEach((field) => {
    Object.defineProperty(toReturn, field, {
      get: () => obj[field],
      set: (value) => {
        this.$emit('update-value', field, value)
      }
    })
  });

  return toReturn;
}
