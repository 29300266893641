var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "waiting-list-container flex flex-col relative-container" },
    [
      _c(
        "div",
        { staticClass: "flex justify-between items-center p-4 shrink-0" },
        [
          _c("label", [_vm._v(_vm._s(_vm.$t("_WaitingList_")))]),
          _vm._v(" "),
          _c(
            "vue-button",
            {
              attrs: { primary: true, "initial-text-size": true },
              on: { click: _vm.addNewWaitingListItem },
            },
            [_vm._v(_vm._s(_vm.$t("_Add_")) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-4 p-4 grow overflow-y-auto" },
        [
          _c(
            "expandable-component",
            {
              attrs: { value: _vm.showNewItem, "animate-height-change": true },
            },
            [
              _c("editable-waiting-list-item", {
                attrs: {
                  "available-employees": _vm.availableEmployees,
                  "available-services": _vm.availableServices,
                  item: _vm.editableItem,
                  "is-saving-item": _vm.isSavingItem,
                },
                on: {
                  change: _vm.updateNewItemValue,
                  save: _vm.saveNewItem,
                  close: _vm.removeNewItem,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }