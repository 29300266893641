import './online.scheduling.theme.picker.directive.less'

(function () {
  const EDITABLE_THEME_NAMES = ['default', 'dark', 'light'];

  angular.module('easybizy.online.scheduling').directive('onlineSchedulingThemePicker', [
    '$timeout',
    'localize', 'Repository', function ($timeout, localize, Repository) {

      return {
        restrict: 'E',
        replace: true,
        link: function (scope) {
          let previouslySelected;
          scope.themes = [];

          scope.selected = 'default';

          scope.selectTheme = (theme) => {
            if (scope.disabled) {
              return;
            }

            scope.selected = theme.name;
            const selectedLocale = Object.getSafely(['locale'], previouslySelected) || {};
            if (theme.name === 'default' && Object.keys(selectedLocale).length === 0) {
              Repository.Custom('Themes').deleteTheme().then(() => {
                toastr.success(localize.getLocalizedString('_ItemsSuccessfullyUpdated_'))
              }).catch((e) => {
                toastr.error(e.message);
              });
            } else {
              const toSend = { theme: { ...previouslySelected, ...theme.value } };
              delete toSend.theme.selected;

              if (Object.keys(toSend.theme.locale || {}).length === 0) {
                delete toSend.theme.locale;
              }

              Repository.Custom('Themes').setTheme(toSend).then(() => {
                toastr.success(localize.getLocalizedString('_ItemsSuccessfullyUpdated_'))
              }).catch((e) => {
                toastr.error(e.message);
              });
            }

          }

          Repository.Custom('Themes').getThemes().then((loadedThemes) => {
            loadedThemes.forEach((theme) => {
              if (theme.selected) {
                previouslySelected = theme;
              }

              scope.themes.push({
                name: theme.name,
                value: theme,
                img: `https://files.easybizy.net/files/__general/public/themes/${ theme.name }.png`,
              });

              if (previouslySelected && !EDITABLE_THEME_NAMES.includes(previouslySelected.name)) {
                scope.themes = [previouslySelected];
                scope.disabled = true;
              }


            });
          }).catch((e) => {
            toastr.error(e.message);
          })


        },
        template: require('./online.scheduling.theme.picker.directive.html')

      };
    }]);

}());
