(function () {
  angular.module('easybizy.common.common-services').service('timeConverter', ['localize', function (localize) {
    this.secondsToString = function secondsToString(seconds) {
      var value = seconds;

      var units = {
        "month": 30 * 24 * 60 * 60,
        "day": 24 * 60 * 60,
        "hour": 60 * 60,
        "minute": 60,
        "second": 1
      };

      var result = '';

      for (var name in units) {
        var p = Math.floor(value / units[name]);
        if (p >= 1) {
          var localizedTime = localize.getLocalizedString('_' + (p === 1 ? name : name + 's').toTitleCase() + '_');
          result += ' ' + p + ' ' + localizedTime;
          // result += (" " + p + ' ' + localizedTime + " ");
        }

        // if (p === 1) result.push(" " + p + " " + name);
        // if (p >= 2) result.push(" " + p + " " + name + "s");
        value %= units[name]
      }

      return result;

    }
  }]);

}());
