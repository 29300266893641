(function () {
  'use strict';
  $('.core-loader__spinner-wrapper').addClass('start');
  var $svg = $('svg').drawsvg({
    callback: function () {
      $('.core-loader__spinner-wrapper').addClass('done')
    }
  });

  $svg.drawsvg('animate');
  angular.module('easybizy.common.common-controls').directive("coreLoader", coreLoader);

  function coreLoader() {
    return {
      restrict: 'A',
      scope: {},
      link: function (scope, element) {
        var listener = scope.$on('configurationsLoaded', function () {
          listener();
          setTimeout(function () {
            element.addClass('finished');
            setTimeout(function () {
              element.remove();
              // Remove injected styles to head.
              $('#code-loader-styles').remove();
            }, 500);
          }, 100);
        });

        var errorListener = scope.$on('configurationsLoadedError', function () {
          console.log(arguments)
          errorListener();
          element.append('<div class="core-loader__error-loading">Oppssss, Couln\'t load the easybizy. Try refreshing... (F5).</div>')
          setTimeout(function () {
            element.addClass('error');

          }, 100);
        });
      }
    };
  }

})();
