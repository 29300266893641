import './customers.table.modal.less'
angular.module('easybizy.easybizyModalViewsModel').controller('CustomersTableController',
  function ($scope, $rootScope, $modalInstance,configuration) {

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
    const configurations = configuration.get();

    $scope.mediator = {
      close: $scope.cancel,
      showPriceLists: configurations.ProductsAndServicesSettings.UsePriceLists,
      showSpecificEmployees: configurations.CustomersSettings.EnableCustomerToEmployeesRelation
    }
  });
