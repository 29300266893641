(function () {
  angular.module('easybizy.common.common-services').service('globalActions',
    function (localize, $modal, modalCreator, $state, $rootScope, Repository, googleCalendarService, $timeout, configuration, authenticationService) {
      var googleCalendarSyncToken = null;
      //
      // if (googleCalendarService.enabled) {
      //   scope.actions.push({
      //     name: localize.getLocalizedString("_SyncGlobalGoogleCalendar_"),
      //     icon: 'icon icon-google',
      //     action: syncGoogleCalendar
      //   });
      //
      //   generateGoogleSyncToken();
      //   defineCurrentGoogleCalendarSyncStatus();
      // }

      Object.assign(this, {
        openIrsReport: openIrsReport,
        openZByRange: openZByRange,
        openExternalAccountingWizard: openExternalAccountingWizard,
        openSalesTree: openSalesTree,
        showCancelledMeetings: showCancelledMeetings,
        syncGoogleCalendar: syncGoogleCalendar,
        pauseSyncing: pauseSyncing,
        cashRegisterActions: cashRegisterActions,
        cashRegisterActivities: cashRegisterActivities,
        editConfig: editConfig,
        showCustomerIngredients: showCustomerIngredients,
        debtAndTaxInvoices: debtAndTaxInvoices,
        showPriceQuotes: showPriceQuotes,
        showGiftCards: showGiftCards,
        showPaymentLinks: showPaymentLinks,
        showMeetingClosing: showMeetingClosing,
        showMeetingHistory: showMeetingHistory,
        showCustomerVisitHistory: showCustomerVisitHistory,
        showCustomersTable: showCustomersTable
      });


      function openIrsReport() {
        var title = localize.getLocalizedString("_GenerateIrsReports_");
        var modalContent = modalCreator.embedInModel(title,
          '<div class="relative-container">\
        <unified-structured-report></unified-structured-report>\
</div>'
          , "no-transition-modal unified-structured-report-view");
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: modalContent,
          controller: function openIrsReportController($scope, $modalInstance) {
            $scope.cancel = function () {
              $modalInstance.dismiss('cancel');
            };
          }
        };

        $modal.open(opts);
      }

      function showCustomerIngredients(customerId, useCustomFields, customFieldsNames, customerName) {
        var title = localize.getLocalizedString("_WorkingIngredients_") + (customerName ? ' - ' + customerName : '');
        const fields = customFieldsNames;// ? customFieldsNames.Value.split(',') : [];
        //var fields = customFieldsNames ? customFieldsNames.Value.split(',') : '';
        var modalContent = modalCreator.embedInModel(title,
          '<div class="relative-container ingredient-cards-modal">\
        <ingredient-cards \
        customer-id="' + customerId + '" use-custom-fields="' + useCustomFields + '" custom-fields-names="fields" />\
      </div>'
          , "no-transition-modal unified-structured-report-view ingredients-view-modal");
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: modalContent,
          controller: function openIrsReportController($scope, $modalInstance) {
            $scope.cancel = function () {
              $modalInstance.dismiss('cancel');
            };

            $scope.fields = fields;
          }
        };

        $modal.open(opts);
      }


      function openZByRange() {
        var title = localize.getLocalizedString("_GenerateReports_");
        var modalContent = modalCreator.embedInModel(title,
          '<div class="relative-container">\
               <z-by-date-report cancel="cancel()"></z-by-date-report>\
           </div>'
          , "no-transition-modal unified-structured-report-view");
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: modalContent,
          controller: function openZByRangeController($scope, $modalInstance) {
            $scope.cancel = function () {
              $modalInstance.dismiss('cancel');
            };
          }
        };

        $modal.open(opts);
      }

      function openExternalAccountingWizard() {
        var title = localize.getLocalizedString("_ExportExternalAccountingReports_");
        var modalContent = modalCreator.embedInModel(title,
          '<div class="relative-container">\
        <external-accounting-wizard></external-accounting-wizard>\
        </div>'
          , "no-transition-modal unified-structured-report-view");
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: modalContent,
          controller: function openExternalAccountingWizardController($scope, $modalInstance) {
            $scope.cancel = function () {
              $modalInstance.dismiss('cancel');
            };
          }
        };

        $modal.open(opts);
      }

      function openSalesTree() {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../modal-views/employee-sells/employee.sells.modal.tpl.html'),
          controller: "EmployeeSellerModalController",
          resolve: {
            employee: function () {
              return { EmployeeId: 0, FirstName: "All" };
            }
          },
          closeFn: function () {
          }
        };

        $modal.open(opts);
      }

      function showPriceQuotes() {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../modal-views/price-quotes/price.quotes.modal.tpl.html'),
          controller: "PriceQuotesModal",
          resolve: {

          },
          closeFn: function () {
          }
        };

        $modal.open(opts);
      }

      function showGiftCards() {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../modal-views/gift-cards/gift.cards.modal.tpl.html'),
          controller: "GiftCardsModal",
          resolve: {

          },
          closeFn: function () {
          }
        };

        $modal.open(opts);
      }

      function showPaymentLinks() {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../modal-views/payment-links-search/payment.links.search.modal.tpl.html'),
          controller: 'PaymentLinksModalController',
          resolve: {

          },
          closeFn: function () {
          }
        };

        $modal.open(opts);
      }

      function showMeetingClosing() {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../modal-views/meeting-closing-table/meeting.closing.modal.tpl.html'),
          controller: 'MeetingClosingModalController',
          resolve: {
            employees: () => Repository.Entity("Employee").query().get().then((res) => res.results)
          },
          closeFn: function () {
          }
        };

        $modal.open(opts);
      }

      function showMeetingHistory(id) {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../modal-views/meeting-history-table/meeting.history.modal.tpl.html'),
          controller: 'MeetingHistoryModalController',
          resolve: {
            meetingId: function () {
              return id;
            }
          },
          closeFn: function () {
          }
        };

        $modal.open(opts);
      }

      function showCustomerVisitHistory(id) {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../modal-views/customer-visit-history/customer.visit.history.tpl.html'),
          controller: 'CustomerVisitHistoryController',
          resolve: {
            customerId: function () {
              return id;
            }
          },
          closeFn: function () {
          }
        };

        $modal.open(opts);
      }

      function showCustomersTable() {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../modal-views/customers-table/customers.table.tpl.html'),
          controller: 'CustomersTableController',
          resolve: {
          },
          closeFn: function () {
          }
        };

        $modal.open(opts);
      }

      function showCancelledMeetings() {
        $state.go('Calendar', { 'show-cancelled': true });
      }

      function syncGoogleCalendar() {
        if (!googleCalendarSyncToken) {
          return toastr.error(localize.getLocalizedString("_NotReadyTryAgainSoon_"));
        } else if (googleCalendarSyncToken == 'error') {
          return toastr.error(localize.getLocalizedString("_ErrorGeneratingGoogleToken_"));
        }

        $rootScope.$emit('page-in-progress', true);
        googleCalendarService.syncGoogleCalendar(googleCalendarSyncToken)
          .then(function () {
            $rootScope.$apply(function () {
              $rootScope.$emit('page-in-progress', false);
            });
          })
          .catch(function (res) {
            $rootScope.$emit('page-in-progress', false);
            return toastr.error(localize.getLocalizedString("_ErrorAuthorizingGoogleAccount_"));
          });
      }

      function pauseSyncing() {
        $rootScope.$emit('page-in-progress', true);
        return Repository.Custom('Calendar').pauseSync().then(function () {
          $rootScope.$emit('page-in-progress', false);
          toastr.success(localize.getLocalizedString("_SyncWithGoogleCalendarWasPaused_"));
          var pauseSync = scope.actions.filter(function (action) {
            return action.icon == 'icon icon-pause';
          })[0];

          scope.actions.remove(pauseSync);

          var syncAction = scope.actions.filter(function (action) {
            return action.icon == 'icon icon-google'
          })[0];

          syncAction.name = localize.getLocalizedString('_SyncGlobalGoogleCalendar_');
        })
          .catch(function () {
            $rootScope.$emit('page-in-progress', false);
            return toastr.error(localize.getLocalizedString("_ErrorPausingSync_"));
          });
      }

      function generateGoogleSyncToken() {
        Repository.Custom('Calendar').generateToken().then(function (response) {
          googleCalendarSyncToken = response.token;
        }).catch(function () {
          googleCalendarSyncToken = 'error';
        })
      }

      function defineCurrentGoogleCalendarSyncStatus() {
        return Repository.Custom('Calendar').syncStatus().then(function (response) {
          if (response.isActive) {
            var syncAction = scope.actions.filter(function (action) {
              return action.icon == 'icon icon-google'
            })[0];

            syncAction.name = localize.getLocalizedString('_ConfigureGoogleSync_');

            scope.actions.push({
              name: localize.getLocalizedString('_PauseGoogleSync_'),
              icon: 'icon icon-pause',
              action: pauseSyncing
            })
          }
        });
      }

      function cashRegisterActions(args) {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require("../../modal-views/cash-register-daily-actions/cash.register.daily.actions.modal.tpl.html"),
          controller: "CashRegisterDailyActionsModalController",
          resolve: {
            dayClosed: function () {
              return args.dayClosed();
            }
          },
          closeFn: function () {
            console.log("closing");
          }
        };

        $modal.open(opts);
      }

      function cashRegisterActivities(args) {
        var opts = {
          backdrop: 'static',
          keyboard: false,
          backdropClick: false,
          template: require("../../modal-views/cash-register-activities/cash.register.activities.modal.tpl.html"),
          controller: "CashRegisterActivitiesModalController",
          resolve: {
            saveAction: function () {
              return function (newEntity, oldEntity, callback) {
                args.action(newEntity, oldEntity, callback);
              };
            },
            parameters: function () {
              return args.data;
            }
          },
          closeFn: function () {
            console.log("closing");
          }
        };

        $modal.open(opts);
      }

      function editConfig(args) {
        if (!args.editMode && args.editActionName && !authenticationService.isAuthenticated()) {
          var securityConfigs = configuration.get().SecuritySettings;
          if (securityConfigs.RequireAuthorization && securityConfigs['Protect' + args.editActionName]) {
            authenticationService.authenticate()
              .then(function () {
                args.toggleEdit();
              })
              .catch(function () {
                console.log('fail!')
              });
          } else {
            args.toggleEdit();
          }
        } else {
          args.toggleEdit();
        }
      }

      function debtAndTaxInvoices(customer, save) {
        var opts = {
          backdrop: 'static',
          keyboard: false,
          backdropClick: false,
          template: require("../../modal-views/cash-register-debt-tax-invoices/cash.register.debt.tax.invoices.html"),
          controller: "CashRegisterDebtTaxInvoicesModalController",
          resolve: {
            saveAction: function () {
              return function (entities) {
                save(entities)
              };
            },
            customerId: function () {
              return customer.CustomerId;
            },
            customerName: function () {
              return `${customer.FirstName || ''} ${customer.LastName || ''}`;
            },
            customerDebt: () => {
              return customer.Debt;
            }
          },
          closeFn: function () {
            console.log("closing");
          }
        };

        $modal.open(opts);
      }


    });
}());
