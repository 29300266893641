import './ingredient.cards.directive.less';
import { DATE_DISPLAY_FORMAT } from "../../../constants";

angular.module('easybizy.common.common-controls').directive('ingredientCards', function (printerMediator, Repository, $timeout, localize, $modal) {
  return {
    restrict: 'E',
    scope: {
      customerId: '=',
      addNewChemistryWrapper: '=',
      allowRemoving: '=',
      useCustomFields: '=',
      customFieldsNames: '='
    },
    replace: true,
    template: require('./ingredient.cards.directive.tpl.html'),
    link: function (scope) {
      scope.duplicateTooltip = localize.getLocalizedString('_Duplicate_');
      scope.print = printIngredients;
      scope.remove = removeIngredientItem;
      scope.duplicate = duplicateIngredientItem;
      getCustomerIngredients();
      loadCustomer();

      if (scope.addNewChemistryWrapper) {
        scope.addNewChemistryWrapper.add = addNewChemistry;
      }

      function addNewChemistry(existingItem) {
        var opts = {
          backdrop: 'static',
          keyboard: true,
          backdropClick: false,
          template: require('../../../modal-views/service-ingredients/service-ingredients.tpl.html'),
          controller: 'ServiceIngredientsModalController',
          resolve: {
            saveAction: function () {
              return function (entities, extraDetails) {
                return Repository.Custom("ServiceMetadataIngredients").post(entities, scope.customerId, extraDetails)
                  .then(function (result) {
                    determineIfToShowCustomModelForIngredient(result);
                    scope.ingredients.unshift(result);
                  })
                  .catch(function () {
                    toastr.error(localize.getLocalizedString("_ErrorSavingEntity_"));
                  });
              };
            },
            productOrService: function () {
              return {};
            },
            customer: function () {
              return scope.customer;
            },
            existingItem: function () {
              return existingItem
            }
          },
          closeFn: function () {
            console.log("closing");
          }
        };

        $modal.open(opts);
      }

      scope.fieldNames = [
        localize.getLocalizedString("_ProductName_"),
        localize.getLocalizedString("_SubModel_"),
        localize.getLocalizedString("_Quantity_"),
        localize.getLocalizedString("_CustomField1_"),
        localize.getLocalizedString("_CustomField2_"),
        localize.getLocalizedString("_Notes_"),
        localize.getLocalizedString("_Notes_"),
        localize.getLocalizedString("_IngredientsTime_")
      ];

      if (scope.customFieldsNames != null && scope.customFieldsNames.Value && scope.customFieldsNames.Value.length > 0) {
        let split = scope.customFieldsNames.Value.split(',');
        for (var i = 0; i < split.length; i++) {
          scope.fieldNames[i] = split[i];
        }
      }

      function loadCustomer() {
        Repository.Entity("Customer").query().id(scope.customerId).get().then(function (customer) {
          scope.customer = customer;
          scope.customerName = customer.FirstName + ' ' + customer.LastName;
        });
      }

      function getCustomerIngredients() {
        scope.loading = true;
        Repository.Custom("ServiceMetadataIngredients").getCustomerIngredients(scope.customerId)
          .then(function (results) {
            scope.ingredients = results;
            if (scope.ingredients) {
              scope.ingredients.forEach(determineIfToShowCustomModelForIngredient);
            }
            $timeout(function () {
              scope.loading = false;
            });
          })
          .catch(function () {
            toastr.error(localize.getLocalizedString("_ErrorLoadingModule_", localize.getLocalizedString("_Products_")));
            scope.loading = false;

          });
      }

      function determineIfToShowCustomModelForIngredient(ingredientItem) {
        var ingredientWithCustomModel = ingredientItem.Ingredients.filter(function (ingredient) {
          return !!ingredient.IngredientModel;
        })[0];

        ingredientItem.showCustomModel = !!ingredientWithCustomModel;
      }

      function removeIngredientItem(ingredientItem) {
        Repository.Custom("ServiceMetadataIngredients").remove(ingredientItem.id)
          .then(function () {
            scope.ingredients.remove(ingredientItem);
          })
          .catch(function () {
            toastr.error(localize.getLocalizedString('_ErrorRemovingEntity_'));
          });
      }

      function duplicateIngredientItem(ingredientItem) {
        addNewChemistry(ingredientItem)
      }

      function printIngredients(ingredientItem) {
        var ingredientItemForPrinting = '<div style="font-size:16px; font-style:arial; direction:rtl; text-align:center;">';
        ingredientItemForPrinting += '<div style="font-size:25px; font-weight:bold;">' + scope.customerName + '</div><br/>';
        ingredientItemForPrinting += (ingredientItem.CreatedOn ? '<div>' + moment(ingredientItem.CreatedOn).format(DATE_DISPLAY_FORMAT) + '</div><br/>' : "");
        ingredientItemForPrinting += (ingredientItem.Remarks ? '<div>' + ingredientItem.Remarks + '</div><br/>' : "");
        ingredientItemForPrinting += (ingredientItem.Duration ? '<div>' + ingredientItem.Duration + '</div><br/>' : "");

        angular.forEach(ingredientItem.Ingredients, function (ingredient) {
          ingredientItemForPrinting += '<div>' +
            '<span>' + ingredient.ProductLabel + '</span>' +
            (ingredient.IngredientModel ? ' - <span>' + ingredient.IngredientModel + '    </span>' : "") +
            (ingredient.Quantity ? ' - <span>' + ingredient.Quantity + '</span>' : "") +
            (ingredient.CustomField1 ? ' - <span>' + ingredient.CustomField1 + '    </span>' : "") +
            (ingredient.CustomField2 ? ' - <span>' + ingredient.CustomField2 + '    </span>' : "") +
            (ingredient.Notes ? '<div>' + ingredient.Notes + '</div>' : "</br>") +
            '</br>' +
            '</div>';
        });

        ingredientItemForPrinting += '</br></div>';
        printerMediator.print(ingredientItemForPrinting, true);
      }
    }
  }
});
