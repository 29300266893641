import './purchasing.history.directive.less';

angular.module('easybizy.cash.register').directive('purchasingHistory', [
  'Repository', 'localize', 'entityImageResolver', '$timeout', '$rootScope', function (Repository, localize, entityImageResolver, $timeout, $rootScope) {
    return {
      replace: true,
      scope: {
        customer: '=',
        close: '='
      },
      link: function (scope) {
        scope.topHeader = localize.getLocalizedString('_CustomerHistoryHeader_', scope.customer.FirstName + ' ' + scope.customer.LastName);

        scope.itemsInCartTrackBy = function (item) {
          return item.key;
        };

        scope.rowDecorator = function (scope, el) {
          el.addClass(scope.item.color);
        };

        $rootScope.$emit('page-in-progress', true);

        scope.viewOptions = [
          { name: localize.getLocalizedString('_All_'), value: 'all' },
          { name: localize.getLocalizedString('_OnlyProducts_'), value: 'ProductMetadata' },
          { name: localize.getLocalizedString('_OnlyServices_'), value: 'ServiceMetadata' },
          { name: localize.getLocalizedString('_Other_'), value: 'other' }
        ];

        scope.viewModeWrapper = { selected: scope.viewOptions[0] };
        scope.$watch('viewModeWrapper', (newVal) => {
          filterItems(newVal.selected.value);
        }, true)

        let allResults;

        scope.$watch('customer', (newVal) => {
          loadCustomerHistory(newVal.CustomerId);
        });

        const tooltip = localize.getLocalizedString('_Add_');

        scope.tableMetadata = [
          {
            name: localize.getLocalizedString("_Date_"),
            fieldName: 'CreatedOn',
            width: '12%',
            sortable: false,
            command: {
              get: function (entity) {
                return moment(entity.CreatedOn).format('DD/MM/YY');
              }
            }
          },
          {
            name: localize.getLocalizedString("_ProductOrServiceName_"),
            width: '38%',
            type: 'ImageWithValue',
            iconReplacer: 'icon icon-tag-price',
            command: {
              get: function (entity) {
                return {
                  text: entity.Name,
                  image: entityImageResolver.resolve(entity)
                };
              }
            }
          },
          {
            name: localize.getLocalizedString("_Price_"),
            width: '15%',
            type: 'Money',
            valueType: 'float',
            fieldName: 'Price',
            command: {}
          },
          {
            name: localize.getLocalizedString("_Quantity_"),
            width: '8%',
            fieldName: 'Quantity'
          },
          {
            name: localize.getLocalizedString("_Employee_"),
            width: '19%',
            fieldName: 'EmployeeName'
          },
          {
            name: '',
            width: '8%',
            type: "Custom",
            template: `<div class="flex-container flex-center relative-container" ng-if="::isVisible(concreteEntity)"><button ng-click="value()"
tippy="_Add_"
class="icon icon-add larger-font link-button"></button></div>`,
            extendScopeWith: {
              isVisible(entity) {
                return ['ServiceMetadata', 'ProductMetadata'].includes(entity.ItemMetadataType);
              }
            },
            command: {
              get: (entity) => () => $rootScope.$emit('add-item-to-cash-register', {
                Id: entity.ItemMetadataId,
                ConcreteType: entity.ItemMetadataType,
                Price: entity.Price
              }),
            }
          }
        ];

        function loadCustomerHistory(customerId) {
          $rootScope.$emit('page-in-progress', true);
          Repository.Custom('CashRegister').customerInvoiceHistory(customerId)
            .then(function (result) {
              $rootScope.$emit('page-in-progress', false);
              $timeout(function () {
                scope.loaded = true;
              }, 500);

              scope.historyItems = result;
              if (result.length === 0) {
                return;
              }

              scope.historyItems.forEach(function (item) {
                item.key = moment(item.CreatedOn).format('YYMMDDHHMM') + '-' + item.InvoiceId + item.ItemMetadataId + item.ItemMetadataType + item.ConcreteId;
              });

              scope.historyItems.sort(function (a, b) {
                return a.key < b.key ? 1 : -1;
              });

              var currentColor = 'first-row-color';
              scope.historyItems[0].color = currentColor;


              for (var i = 1; i < result.length; i++) {
                if (scope.historyItems[i].CreatedOn !== scope.historyItems[i - 1].CreatedOn) {
                  currentColor = currentColor === 'first-row-color' ? 'second-row-color' : 'first-row-color';
                }

                scope.historyItems[i].color = currentColor;
              }

              allResults = scope.historyItems.slice();
              filterItems(scope.viewModeWrapper.selected.value);
            })
            .catch(function (err) {
              $rootScope.$emit('page-in-progress', false);

              toastr.error(localize.getLocalizedString("_ErrorLookingUpCustomerHistory_"));
            });
        }


        function filterItems(newVal) {
          if (!allResults) {
            return;
          }

          if (newVal === 'all') {
            return scope.historyItems = allResults.slice();
          }

          scope.historyItems = allResults.filter((item) => {
            const itemMetadataType = item.ItemMetadataType;
            return (newVal === 'ServiceMetadata' && itemMetadataType === 'ServiceMetadata') ||
              (newVal === 'ProductMetadata' && itemMetadataType === 'ProductMetadata') ||
              (newVal === 'other' && !['ServiceMetadata', 'ProductMetadata'].includes(itemMetadataType));
          });
        }
      },
      template: require('./purchasing.history.directive.tpl.html')

    };
  }]);
