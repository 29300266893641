import './employee.shifts.sms.notifications.modal.less'
import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT } from "../../constants";

angular.module('easybizy.easybizyModalViewsModel').controller('EmployeeShiftsSMSNotifications',
  function ($scope, $modalInstance, localize, Repository, $q) {
    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.dateRangePresets = [
      {
        name: localize.getLocalizedString('_ThisWeek_'),
        value: { from: moment().format(DATE_DISPLAY_FORMAT), to: moment().endOf('week').format(DATE_DISPLAY_FORMAT) }
      },
      {
        name: localize.getLocalizedString('_NextWeek_'),
        value: {
          from: moment().add(1, 'week').startOf('week').format(DATE_DISPLAY_FORMAT),
          to: moment().add(1, 'week').endOf('week').format(DATE_DISPLAY_FORMAT)
        }
      },
      {
        name: localize.getLocalizedString('_Manually_'),
        value: null
      }
    ];

    $scope.dateRangeSelectedPresetWrapper = {};
    $scope.dateRangeSelectedPresetWrapper.dateRangeSelectedPreset = $scope.dateRangePresets[0];
    $scope.selectedRange = {};

    $scope.rangeMetadataFrom = {
      fieldName: 'from',
      icon: "icon icon-calendar",
      placeholder: "Start Date",
      validation: "{'required': true}",
      yearRange: "-1:+3",
      type: 'date'
    };
    $scope.rangeMetadataTo = {
      fieldName: 'to',
      icon: "icon icon-calendar",
      placeholder: "End Date",
      validation: "{'required': true}",
      yearRange: "-1:+3",
      type: 'date'
    };


    $scope.fields = [
      {
        name: localize.getLocalizedString("_None_"),
        type: 'Checkbox',
        allowSelectAll: true,
        selectOnDirty: true,
        sortable: false,
        fieldName: 'isSelected',
        width: '5%'
      },
      {
        name: localize.getLocalizedString("_EmployeeToSendNotificationsTo_"),
        fieldName: 'EmployeeName',
        width: '95%',
        command: {
          get: function (employee) {
            return employee.FirstName + ' ' + employee.LastName;
          }
        }
      }
    ];

    $scope.employees = [];

    $scope.trackByFunc = function (employee) {
      return employee.EmployeeId;
    };

    loadEmployees();

    $scope.$watch('dateRangeSelectedPresetWrapper.dateRangeSelectedPreset', function (newVal) {
      if (newVal.value) {
        $scope.selectedRange.from = newVal.value.from;
        $scope.selectedRange.to = newVal.value.to;
      }
    });

    $scope.$watch('selectedRange', function (newVal) {
      if (newVal) {
        var selectedPreset = $scope.dateRangePresets[$scope.dateRangePresets.length - 1];
        for (var presetIdx in $scope.dateRangePresets) {
          var preset = $scope.dateRangePresets[presetIdx];
          if (preset.value && preset.value.from === newVal.from && preset.value.to === newVal.to) {
            selectedPreset = preset;
            break;
          }
        }

        if (selectedPreset != $scope.dateRangeSelectedPresetWrapper.dateRangeSelectedPreset) {
          $scope.dateRangeSelectedPresetWrapper.dateRangeSelectedPreset = selectedPreset;
        }
      }
    }, true);


    var sendNotification = localize.getLocalizedString('_Send_');
    $scope.$watch('employees', function (newVal) {
      $scope.enabled = false;
      var selectedEmployees = newVal.filter(function (employee) {
        return employee.isSelected;
      });

      if (selectedEmployees.length === 0) {
        $scope.sendText = sendNotification;
      } else if (selectedEmployees.length === 1) {
        $scope.enabled = true;
        $scope.sendText = sendNotification + ' ' + localize.getLocalizedString('_ToEmployee_', ((selectedEmployees[0].FirstName || '') + ' ' + (selectedEmployees[0].LastName || '')))
      } else {
        $scope.sendText = sendNotification + ' ' + localize.getLocalizedString('_ToXEmployees_', selectedEmployees.length);
        $scope.enabled = true;

      }
    }, true);

    $scope.send = function () {
      $scope.inProgress = true;

      var dateRange = {
        from: moment($scope.selectedRange.from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT),
        to: moment($scope.selectedRange.to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT)
      };

      var employeeIds = $scope.employees.filter(function (employee) {
        return employee.isSelected;
      }).map(function (employee) {
        return employee.EmployeeId;
      });


      Repository.Custom("EmployeeHours").notifyShiftsViaSMS(dateRange, employeeIds)
        .then(function (res) {
          $scope.inProgress = false;
          $modalInstance.close();
          toastr.success(res);
        })
        .catch(function (err) {
          $scope.inProgress = false;
          toastr.error(localize.getLocalizedString('_ErrorSendingNotifications_', err.message));
        });
    };

    function loadEmployees() {
      Repository.Entity("Employee").query().filter('VisibleOnCalendar eq true and not(PhoneNumber eq null)').get().then(function (result) {
        $scope.employees = result.results;
      }).catch(function (err) {
        toastr.error(localize.getLocalizedString('_ErrorGettingEmployees_'));
      });
    }

  });
