import { DATE_DISPLAY_FORMAT, ODATA_DATE_ONLY_FORMAT } from "../../constants";

angular.module('easybizy.reports').factory('reportsPlainDecorators', [
  '$rootScope', 'Repository', 'localize', 'configuration', function ($rootScope, Repository, localize, configuration) {
      var decorators = {
          CreditForecast: decorateReport
      };

      return {
          decorate: function (type) {
              return decorators[type];
          }
      };

      function decorateReport(scope) {
          scope.isLoadingWrapper = {};
          // the graph title.
          scope.title = localize.getLocalizedString("_" + scope.type + "_");

          //     TotalIncomes = total,
          //     TotalVat = totalVat,
          //     CashPayments = cashPayments,
          //     TotalCreditPayments = totalCreditPayments,
          //     ChequePayments = chequePayments,
          //     ExternalPayments = externalPayments,
          //     ImmidiateCredit = immidiateCredit,
          //     PostponedCredit = postponedCredit,
          //     DebitPayments = debitPayments,
          //     CreditFromOldPayments = Math.Round(creditFromOldPayments)


          scope.fields = [
            [
                //Incomes
              { field: 'TotalIncomes', name: localize.getLocalizedString('_TotalIncomes_')/*To localize*/, cssClass: 'bold' },
              { field: 'TotalIntakes', name: localize.getLocalizedString('_TotalIntakes_')/*To localize*/, cssClass: 'bold' },
              { field: 'TotalBeforeVat', name: localize.getLocalizedString('_TotalBeforeVat_'), cssClass: 'bold' },
              { field: 'TotalVat', name: localize.getLocalizedString('_Vat_'), cssClass: 'bold' }
            ],
            [
                //Payments type
              { field: 'TotalCreditPayments', name: localize.getLocalizedString('_Credit_'), cssClass: 'bold' },
              { field: 'CashPayments', name:  localize.getLocalizedString('_Cash_'), cssClass: 'bold' },
              { field: 'ChequePayments', name: localize.getLocalizedString('_Cheque_'), cssClass: 'bold' },
              { field: 'ExternalPayments', name: localize.getLocalizedString('_External_'), cssClass: 'bold' }
            ],
            [
                //Credit
              { field: 'ImmidiateCredit', name: localize.getLocalizedString('_ImmidiateCredit_'), cssClass: 'bold' },
              { field: 'DebitPayments', name: localize.getLocalizedString('_DebitPayments_'), cssClass: 'bold' },
              { field: 'PostponedCredit', name: localize.getLocalizedString('_PostponedCredit_'), cssClass: 'bold' },
              { field: 'CreditFromOldPayments', name: localize.getLocalizedString('_CreditFromOldPayments_'), cssClass: 'bold' }
            ]
          ];

          scope.generateGraph = generateGraph;
          generateGraph();

          function generateGraph() {
              scope.isLoadingWrapper.isLoading = true;
              var range = scope.getCurrentDate(); // from-to dates.
              var from = moment(range.from, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
              var to = moment(range.to, DATE_DISPLAY_FORMAT).format(ODATA_DATE_ONLY_FORMAT);
              Repository.Custom("ReportsRepository").plain(from, to, "Incomes")
             .then(function (result) {
                 scope.data = result;
             })
             .catch(function (e) {
               toastr.error(e.Message);
             });

          }
      }
  }]);
