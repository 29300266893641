import './cancel.invoice.modal.less';

angular.module('easybizy.easybizyModalViewsModel').service('cancelInvoiceService', function ($modal, localize) {
  this.show = (invoiceId, payment) => {
    if (payment && payment.amount <= 0) {
      return toastr.error(localize.getLocalizedString('_CannotAttachCustomPaymentToANegativeOne_'));
    }

    const opts = {
      backdrop: 'static',
      keyboard: true,
      template: require('../../modal-views/cancel-invoice/cancel.invoice.model.tpl.html'),
      controller: 'CancelInvoiceModalViewController',
      resolve: {
        invoiceId: () => invoiceId,
        solePayment: () => ({ ...payment })
      }
    };

    $modal.open(opts);
  }
});

angular.module('easybizy.easybizyModalViewsModel').controller('CancelInvoiceModalViewController',
  function ($scope, $modalInstance, invoiceId, Repository, localize, $timeout, pci,
            configuration,
            confirmService, manualCreditChargeService, focusManager, $rootScope, solePayment) {

    focusManager.toggleFocus(true, $scope);
    focusManager.preventEscape($scope);
    const SELECTED_CUSTOM_REFUND_FIELD_NAME = 'selectedCustomRefundType';
    const configurations = configuration.get();
    let lostPayments = [];

    $scope.invoice = null;
    $scope.invoiceLoadingWrapper = { loading: true };
    $scope.isCancellingInvoice = { loading: false };
    $scope.cancelInvoiceText = localize.getLocalizedString('_IssueRefund_');

    $scope.$on('modal-presented', () => {
      $scope.didLoad = true;
      updateInvoiceData();
    })

    Repository.Custom('CashRegister').lostCreditPayments()
      .then(function (results) {
        if (results && results.length > 0) {
          lostPayments = results;
          if (solePayment) {
            lostPayments =
              lostPayments.filter((lostPayment) => lostPayment.PaymentId !== solePayment.id);
          }
        }
      });

    $scope.cancel = function () {
      if ($scope.isCancellingInvoice.loading) {
        toastr.warning(localize.getLocalizedString("_CannotExitDuringCancellation_"));
        return;
      }

      $modalInstance.dismiss('cancel');
    };


    $scope.primarySubmit = () => {
      if ($scope.performedCancellation) {
        return createCancellationInvoice();
      }

      return confirmService.confirm(localize.getLocalizedString('_AreYouSureYouWantToCancel_'),
        null,
        () => {
          $scope.$evalAsync(performCancellation);
        }, null, localize.getLocalizedString('_Yes_'), localize.getLocalizedString('_No_'));
    }

    const customTypes = [];

    if (configurations.CashRegisterSettings.EnableExternalPayments) {
      customTypes.push({ name: localize.getLocalizedString('_Bit_'), value: 'external_bit' });
      customTypes.push({
        name: localize.getLocalizedString('_Paybox_'), value: 'external_paybox',
        inputText: true
      });

      customTypes.push({
        name: localize.getLocalizedString('_WireTransfer_'),
        value: 'external_wire',
        inputText: true
      });

      customTypes.push({ name: localize.getLocalizedString('_Other_'), value: 'external_other', inputText: true });
    }


    if (!configurations.CashRegisterSettings.PreventCreditCard) { // using card
      if (pci.pinPads && pci.pinPads.length === 1) {
        customTypes.push({ name: localize.getLocalizedString('_ChargeEmv_'), value: 'pinPad' });
      } else {
        pci.pinPads.forEach((pinPad) => customTypes.push({
          name: localize.getLocalizedString('_ChargeEmv_') + ` - ${ pinPad.name }`,
          value: 'pinPad',
          pinpad: pinPad.name
        }));
      }

      if (pci.terminals.length > 1) {
        pci.terminals.forEach((terminal) => {
          customTypes.push({
            name: localize.getLocalizedString('_ManualCreditCard_') + ` - ${ terminal.name }`,
            value: 'manual-card',
            terminal: terminal.value
          });
        })
      } else {
        customTypes.push({ name: localize.getLocalizedString('_ManualCreditCard_'), value: 'manual-card' });
      }
    }

    $scope.customRefundTypes = [
      { name: localize.getLocalizedString('_OtherRefundMethod_'), value: null },
      { name: localize.getLocalizedString('_Cash_'), value: 'cash' },
      ...customTypes
    ];

    $scope.customRefundTypeInputField = {
      fieldName: 'customRefundTypeInput',
      icon: "icon icon-cash-out",
      placeholder: localize.getLocalizedString('_ExternalPaymentNumber_')
    };

    $scope.isCreditCard = (type) => {
      return type === 'pinPad' || type === 'manual-card';
    }

    $scope.paymentToIconResolver = (payment) => {
      if (payment.PaymentType === 'Credit') {
        return 'icon icon-credit';
      } else if (payment.PaymentType === 'Cash') {
        return 'icon icon-banknote-aligned-shekel';
      } else if (payment.PaymentType === 'External') {
        return 'icon icon-sms';
      } else if (payment.PaymentType === 'Cheque') {
        return 'icon icon-wallet';
      }
    };

    $scope.paymentToTextResolver = (payment) => {
      let toReturn;
      if (payment.PaymentType === 'Credit') {
        toReturn = localize.getLocalizedString('_Credit_');
        toReturn += `, ${ payment.ExternalName } (${ payment.CardLastFourDigits })`;
        if (payment.NumberOfCharges > 1) {
          toReturn += ` (${ localize.getLocalizedString('_NumOfPayments_', payment.NumberOfCharges) })`;
        }
      } else if (payment.PaymentType === 'Cash') {
        toReturn = localize.getLocalizedString('_Cash_');
      } else if (payment.PaymentType === 'External') {
        toReturn = payment.ExternalName;
      } else if (payment.PaymentType === 'Cheque') {
        toReturn = localize.getLocalizedString('_Cheque_');
      }

      return toReturn;
    };

    $scope.customCancelCreditPayment = (payment) => {
      const transaction = {
        amount: -parseFloat(payment.Amount),
        currency: 1,
        isRefund: true,
      }

      if ($scope.invoice.Customer) {
        transaction.customerId = $scope.invoice.Customer.CustomerId;
      }

      if (payment.selectedCustomRefundType.terminal && payment.selectedCustomRefundType.terminal !== 'default') {
        transaction.terminal = payment.selectedCustomRefundType.terminal;
      }

      if (payment.selectedCustomRefundType.value === 'manual-card') {
        manualCreditChargeService.show(transaction, (result) => {
          payment.cancellationStatus = 'success';
          payment.cancellationId = result.paymentId;
          handleResponse(true);
        });
      } else { // pinpad.
        transaction.pinpad = payment.selectedCustomRefundType.pinpad;
        payment.pinPadCancel = true;
        pci.chargeCreditCard(transaction).then((result) => {
          payment.pinPadCancel = false;
          payment.cancellationStatus = 'success';
          payment.cancellationId = result.paymentId;
          handleResponse(true);
        }).catch((e) => {
          payment.pinPadCancel = false;
          toastr.error(pci.extractError(e));
        })
      }
    }


    if (invoiceId) {
      loadInvoice(invoiceId);
    } else {
      generateWrappingInvoice(solePayment);
    }


    /**************************** FUNCTIONS ****************************/
    /**
     * This method is used for handling lost payments and it generates a fake
     * invoice wrapper and adopt the payment to the default methods.
     * @param payment lostPayment.
     */
    function generateWrappingInvoice(payment) {
      const emulatedPayment = {
        Amount: payment.amount,
        CardLastFourDigits: payment.cardLastFourDigits,
        ExternalName: payment.cardType,
        PaymentType: 'Credit',
        NumberOfCharges: payment.numOfPayments,
        CustomerId: payment.customerId
      };

      $scope.invoiceLoadingWrapper.loading = false;
      $scope.invoice = { TotalAmount: payment.amount, Payments: [emulatedPayment] };
      $scope.cancelInvoiceText = localize.getLocalizedString('_CloseInvoice_');
      $scope.performedCancellation = true;
      decoratePaymentWithCustomRefundMethod(emulatedPayment);
      paymentCancellationFailed(emulatedPayment);
      updateInvoiceData();
    }


    function updateInvoiceData() {
      if (!$scope.didLoad || !$scope.invoice) {
        return;
      }

      $timeout(() => {
        $scope.didLoadSecond = true;
      });
    }


    function loadInvoice(invoiceId) {
      Repository.Custom('CashRegisterActivities').expandInvoice(invoiceId).then(function (result) {
        $scope.invoice = result;
        $scope.invoice.Payments = $scope.invoice.Payments.sort((a, b) => (a.PaymentType === 'Credit' && b.PaymentType !== 'Credit') ? 1 : -1);

        if (!result.AllowCancellation) {
          toastr.error(localize.getLocalizedString("_InvoiceCannotBeCancelled_", err.Message));
          return;
        }

        $scope.invoiceLoadingWrapper.loading = false;
        updateInvoiceData()
      }).catch(function (err) {
        $scope.invoiceLoadingWrapper.loading = false;
        toastr.error(localize.getLocalizedString("_FailedGettingInvoice_", err.Message));
      });
    }

    function decorateLostPayments(payment) {
      [...lostPayments].filter((lostPayment) => {
        if (lostPayment.Amount === -payment.Amount) {
          payment.otherCancellationOptions.push({
            name: localize.getLocalizedString('_UseLostPayment_', `, ${ lostPayment.CardName } (${ lostPayment.CardLastFourDigits })`),
            value: `lost_${ lostPayment.PaymentId }`,
            cancellationId: lostPayment.PaymentId
          });

          lostPayments.remove(lostPayment);
        }
      })
    }

    function performCancellation() {
      $scope.performedCancellation = true;
      $scope.isCancellingInvoice.loading = true;
      $scope.cancelInvoiceText = localize.getLocalizedString('_CloseInvoice_');
      $scope.invoice.Payments.filter((p) => p.PaymentType !== 'Credit').forEach((p, i) => {
        $timeout(() => {
          p.cancellationStatus = 'success';
        }, i * 300);
      })

      $scope.invoice.Payments
        .filter((p) => p.PaymentType === 'Credit').sort((a, b) => a.Amount > b.Amount).forEach((p) => {
        decoratePaymentWithCustomRefundMethod(p);
        cancelCreditPayment(p)
      });
    }

    function decoratePaymentWithCustomRefundMethod(payment) {
      if (!payment.hasOwnProperty(SELECTED_CUSTOM_REFUND_FIELD_NAME)) {
        Object.defineProperty(payment, SELECTED_CUSTOM_REFUND_FIELD_NAME, (function () {
          let val;
          return {
            get: () => val,
            set: (newVal) => {
              val = newVal;
              if (newVal.value === 'manual-card') {
                $scope.customCancelCreditPayment(payment)
              }

              handleResponse();
            }
          }
        }()));
      }
    }

    function cancelCreditPayment(payment) {
      payment.cancellationStatus = 'loading';
      pci.cancelPayment(payment.PaymentId).then((result) => {
        payment.cancellationStatus = 'success';
        payment.cancellationId = result.paymentId;
        handleResponse(true);
      }).catch((e) => {
        const err = pci.extractError(e);
        toastr.error(err);
        paymentCancellationFailed(payment);
      })

    }

    function paymentCancellationFailed(payment) {
      payment.cancellationStatus = 'failed';
      payment.otherCancellationOptions = [...$scope.customRefundTypes];
      decorateLostPayments(payment);
      payment.otherCancellationOptions.forEach(p => p.payment = payment);

      payment[SELECTED_CUSTOM_REFUND_FIELD_NAME] = $scope.customRefundTypes[0];
      paymentStatusChanged();
    }

    function paymentStatusChanged() {
      $scope.isCancellingInvoice.loading = $scope.invoice.Payments.some((p) => p.cancellationStatus === 'loading');
      handleResponse();
    }


    function createCancellationInvoice() {
      const paymentsToCreate = $scope.invoice.Payments
        .filter((p) => p.PaymentType !== 'Credit')
        .map(({
                PaymentType,
                Amount,
                AuthNumber,
                PaymentDueDate,
                ExternalName
              }) => ({
          Type: PaymentType,
          Amount: -Amount,
          AuthNumber,
          PaymentDueDate,
          ExternalName
        }));

      const creditPayments = [];

      $scope.invoice.Payments
        .filter((p) => p.PaymentType === 'Credit')
        .forEach((p) => {
          const cancellationId = p.cancellationId || (p.selectedCustomRefundType && p.selectedCustomRefundType.cancellationId);
          if (cancellationId) {
            creditPayments.push(cancellationId);
          } else {
            let paymentType = 'cash';
            let externalName = '';
            let authNumber = '';
            if (p.selectedCustomRefundType.value.indexOf('external') === 0) {
              paymentType = 'external';
              externalName = p.selectedCustomRefundType.name;
              authNumber = p.customRefundTypeInput;
            }

            paymentsToCreate.push({
              Amount: -p.Amount,
              Type: paymentType,
              ExternalName: externalName,
              AuthNumber: authNumber
            });
          }
        });

      paymentsToCreate.forEach((newPayment) => {
        Object.keys(newPayment).forEach((key) => {
          if (!newPayment[key]) {
            delete newPayment[key];
          }
        })
      })

      $scope.isCancellingInvoice.loading = true;
      let request;

      if (invoiceId) {
        request = Repository.Custom('CashRegister').customCancelInvoice(invoiceId, {
          PaymentsToCreate: paymentsToCreate,
          CreditPaymentsIds: creditPayments
        });
      } else {
        request = Repository.Custom('CashRegister').cancelLostPayment(solePayment.id, {
          PaymentsToCreate: paymentsToCreate,
          CreditPaymentsIds: creditPayments,
          CustomerId: solePayment.customerId
        });
      }

      request.then((result) => {
        toastr.success(localize.getLocalizedString('_InvoiceCancelled_'));
        let results = result;
        if (result.hasOwnProperty('results') && result.results) {
          results = result.results;
        }

        const items = Array.isArray(results) ? results : [results];
        $scope.isCancellingInvoice.loading = false;
        $scope.cancelInvoiceResult = $scope.invoiceResult = { items };
        $scope.cancelInvoiceResult.Customer = $scope.invoice.Customer;

        $rootScope.$broadcast('invoice-cancelled', {
          invoiceId
        });
      }).catch((e) => {
        $scope.isCancellingInvoice.loading = false;
        toastr.error(e.message || (e.data && e.data.Message) || e.statusText);
      })
    }

    function handleResponse(cancelInvoiceIfPossible) {
      // invoice is ready to be cancelled if all payments cancelled.
      $scope.isInvoiceReadyToBeCancelled = !$scope.invoice.Payments.filter((p) => p.PaymentType === 'Credit')
        .some((p) => {
          /// Look for invalid results.
          if (p.cancellationStatus === 'success') {
            return false;
          }

          // is payment valid?
          if (p.cancellationId) {
            return false;
          }

          if (p.selectedCustomRefundType && p.selectedCustomRefundType.value && !$scope.isCreditCard(p.selectedCustomRefundType.value)) {
            return false
          }

          return true;
        });

      if (cancelInvoiceIfPossible && $scope.isInvoiceReadyToBeCancelled) {
        createCancellationInvoice();
      }
    }

  });
