import './service-ingredients.less'

angular.module('easybizy.easybizyModalViewsModel').controller('ServiceIngredientsModalController',
  function ($scope, $modalInstance, saveAction, productOrService, customer, existingItem, $timeout, $http, localize,
    Repository, configuration) {
    $scope.entities = productOrService.Ingredients
      ? productOrService.Ingredients : [];
    $scope.includeCustomModelFormat = configuration.get().ProductsAndServicesSettings.IncludeCustomModelCategory;
    $scope.useCustomIngredientsFields = configuration.get().ProductsAndServicesSettings.UseCustomIngredientsFields;
    $scope.tabs = [];
    $scope.countOfUnresolvedPlaceholders = 1;
    $scope.productOrService = productOrService;
    $scope.isLoadingWrapper = {};
    $scope.isLoadingWrapper.isLoading = true;
    $scope.isLoadingWrapper.isLoading = true;

    $scope.productCategories = {};
    $scope.serviceName = productOrService.Label;
    $scope.showDropMessage = true;
    $scope.customer = customer;

    var allPlaceholders = null;

    $scope.trackIngredientBy = function (item) {
      return Math.floor(Math.random() * 100000) + item.ProductMetadataId;
    };

    Repository.Custom("ServiceMetadataIngredients").get(productOrService.Id)
      .then(function (serviceMetadata) {
        $scope.tabs = createTabs(serviceMetadata);
        allPlaceholders = createPlaceholders($scope.tabs);
        $scope.placeholders = $.extend([], allPlaceholders);
        if ($scope.entities.length > 0) {
          updateTabsAccordingToEntities();
        }

        $timeout(function () {
          $scope.showDropMessage = false;
        }, 500);

        $scope.isLoadingWrapper.isLoading = false;
      })
      .catch(function (e) {
        $scope.isLoadingWrapper.isLoading = false;
        toastr.error("failed loading ingredients");
      });

    $scope.submit = function () {
      if (productOrService.id) {
        productOrService.Ingredients = $scope.entities;
      }

      saveAction($scope.entities, $scope.extraIngredientDetails).then(function (result) {
        $modalInstance.close($scope.entities);
      }).catch(function () {
        toastr.error(localize.getLocalizedString('_ErrorSavingEntity_'));
      })
    };

    $scope.addElement = function (e) {
      addEntityToCart(e);
    };

    $scope.addAllElements = function (e) {
      for (var i = 0; i < e.length; i++) {
        addEntityToCart(e[i]);
      }
    };

    $scope.tileDropped = function (e) {
      addEntityToCart(e.dropData);
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    var quantityDelta = configuration.get().CashRegisterSettings.IngridientQuantity.Value ? configuration.get().CashRegisterSettings.IngridientQuantity.Value : 0.25;
    if (existingItem) {
      $scope.extraIngredientDetails = {};
      $scope.extraIngredientDetails.Remarks = existingItem.Remarks;
      $scope.extraIngredientDetails.Duration = existingItem.Duration;
      existingItem.Ingredients.forEach(function (i) {
        addEntityToCart(
          {
            ProductMetadataId: i.ProductMetadataId,
            ProductLabel: i.ProductLabel,
            IngredientModel: i.IngredientModel,
            Quantity: i.Quantity,
            CustomField1: i.CustomField1,
            CustomField2: i.CustomField2,
            Notes: i.Notes
          });
      });
    }

    function addEntityToCart(entity) {
      if ($scope.includeCustomModelFormat && !entity.CustomModelValue) {
        entity.CustomModelValue = 0;
      }

      var toPush = $.extend({}, entity);
      delete toPush.$$hashKey;
      $scope.entities.push(toPush);
    }

    var fieldNames = [
      localize.getLocalizedString("_ProductName_"),
      localize.getLocalizedString("_SubModel_"),
      localize.getLocalizedString("_Quantity_"),
      localize.getLocalizedString("_CustomField1_"),
      localize.getLocalizedString("_CustomField2_"),
      localize.getLocalizedString("_Notes_"),
      localize.getLocalizedString("_Notes_"),
      localize.getLocalizedString("_IngredientsTime_")
    ];
    var labels = configuration.get().ProductsAndServicesSettings.IngredientsFieldsCustomLabels;
    if (labels != null && labels.Value && labels.Value.length > 0) {
      let split = labels.Value.split(',');
      for (var i = 0; i < split.length; i++) {
        fieldNames[i] = split[i];
      }
    }

    $scope.ingredientsFields = [
      {
        name: fieldNames[0],
        fieldName: 'ProductLabel',
        width: "30%"
      },
      {
        name: fieldNames[2],
        width: "30%",
        type: "WritableTextbox",
        fieldName: 'Quantity',
        defaultEditMode: true,
        placeholder: localize.getLocalizedString("_IngredientQuantityExample_"),
        command: {
          get: function (entity) {
            return entity.Quantity;
          },
          set: function (entity, value) {
            entity.Quantity = value;
          },
          isEditable: function () {
            return true;
          }
        }
      },
      {
        name: fieldNames[5],
        width: "40%",
        type: "WritableTextbox",
        fieldName: 'Notes',
        defaultEditMode: true,
        command: {
          get: function (entity) {
            return entity.Notes;
          },
          set: function (entity, value) {
            entity.Notes = value;
          },
          isEditable: function () {
            return true;
          }
        }
      }
    ];

    if (!$scope.includeCustomModelFormat) {
      var subModelFeld = {
        name: fieldNames[1],
        width: "20%",
        type: "WritableTextbox",
        fieldName: 'IngredientModel',
        defaultEditMode: true,
        placeholder: localize.getLocalizedString("_IngredientExample_"),
        command: {
          get: function (entity) {
            return entity.IngredientModel;
          },
          set: function (entity, value) {
            entity.IngredientModel = value;
          },
          isEditable: function () {
            return true;
          }
        }
      };


      $scope.ingredientsFields[0].width = "20%";
      $scope.ingredientsFields[1].width = "20%";
      $scope.ingredientsFields[2].width = "40%";
      $scope.ingredientsFields.splice(1, 0, subModelFeld);
    }

    if ($scope.useCustomIngredientsFields) {
      var custom1 = {
        name: fieldNames[3],
        width: "15%",
        type: "WritableTextbox",
        fieldName: 'CustomField1',
        defaultEditMode: true,
        //placeholder: localize.getLocalizedString("_IngredientQuantityExample_"),
        command: {
          get: function (entity) {
            return entity.CustomField1;
          },
          set: function (entity, value) {
            entity.CustomField1 = value;
          },
          isEditable: function () {
            return true;
          }
        }
      }
      var custom2 = {
        name: fieldNames[4],
        width: "15%",
        type: "WritableTextbox",
        fieldName: 'CustomField2',
        defaultEditMode: true,
        //placeholder: localize.getLocalizedString("_IngredientQuantityExample_"),
        command: {
          get: function (entity) {
            return entity.CustomField2;
          },
          set: function (entity, value) {
            entity.CustomField2 = value;
          },
          isEditable: function () {
            return true;
          }
        }
      }

      if ($scope.ingredientsFields.length === 3) {
        $scope.ingredientsFields[0].width = "20%";
        $scope.ingredientsFields[1].width = "20%";
        $scope.ingredientsFields[2].width = "30%";
      } else {
        $scope.ingredientsFields[0].width = "15%";
        $scope.ingredientsFields[1].width = "15%";
        $scope.ingredientsFields[2].width = "15%";
        $scope.ingredientsFields[3].width = "25%";
      }

      $scope.ingredientsFields.splice($scope.ingredientsFields.length - 1, 0, custom1);
      $scope.ingredientsFields.splice($scope.ingredientsFields.length - 1, 0, custom2);
    }

    var lastUpdatedLength = 0;
    $scope.$watch('entities.length', function () {
      var currentLength = $scope.entities.length;
      if (currentLength >= 0 && $scope.tabs.length > 0 && lastUpdatedLength != currentLength) {
        lastUpdatedLength = currentLength;
        // initial array visibility.
        updateTabsAccordingToEntities();
      }
    });

    // GENERATE EXTRA DATA DETAILS.
    if (!$scope.extraIngredientDetails) {
      $scope.extraIngredientDetails = {};
    }
    $scope.extraFields = [
      {
        fieldName: 'Remarks',
        icon: 'icon icon-lab',
        placeholder: fieldNames[6],
        validation: "{'required': false, 'max-length': 40}"
      },
      {
        fieldName: 'Duration',
        icon: 'icon icon-time',
        placeholder: fieldNames[7],
        validation: "{'required': false, 'max-length': 20}"
      }
    ];

    $scope.validateExtraFieldsAction = {};
    $scope.extraFieldsToValidate = {};

    function updateTabsAccordingToEntities() {
      var finalVisiblePlaceHolders = allPlaceholders.slice();
      var entities = $scope.entities.slice();

      for (var i = 0; i < allPlaceholders.length; i++) {
        var placeholder = allPlaceholders[i];
        var removeFromEntities = [];
        for (var j = 0; j < entities.length; j++) {
          if (placeholder.categoryId == entities[j].mainCategoryId) {
            finalVisiblePlaceHolders.removeById(placeholder, "categoryId");
            removeFromEntities.push(entities[j]);
            break;
          }
        }

        removeFromEntities.forEach(x => entities.remove(x));
        if (entities.length === 0) {
          break;
        }
      }

      $scope.placeholders.length = 0;
      $scope.placeholders.pushAll(finalVisiblePlaceHolders);


    }

    function createTabs(expandedServiceMetadata) {
      var tabs = [];
      angular.forEach(expandedServiceMetadata.ProductCategories, function (category) { //tabs
        var allProducts = [];
        var tabItem = {};
        tabItem.heading = category.ProductCategoryName;
        tabItem.categoryId = category.ProductCategoryId;
        // has sub categories?
        if (category.ProductCategories.length > 0) {
          tabItem.filtersModel = {};
          tabItem.filteredItems = {};
          var categoriesWithItems = [];
          angular.forEach(category.ProductCategories, function (subCategory) {
            if (subCategory.ProductMetadatas.length > 0) {
              categoriesWithItems.push(subCategory);
              allProducts.pushAll(subCategory.ProductMetadatas);
              tabItem.filteredItems[subCategory.ProductCategoryId] = [];
              tabItem.filteredItems[subCategory.ProductCategoryId].pushAll(subCategory.ProductMetadatas);
            }
          });

          tabItem.filtersModel.filters = [
            { type: "serviceIngredientsProductCategory", params: categoriesWithItems }
          ];
          tabItem.filtersModel.filterStateChanged = function (newFiltersState) {
            if (newFiltersState.serviceIngredientsProductCategory != null) {
              tabItem.currentItems = tabItem.filteredItems[newFiltersState.serviceIngredientsProductCategory];
            } else {
              tabItem.currentItems = allProducts;
            }
          };

        } else {
          allProducts.pushAll(category.ProductMetadatas);
        }

        allProducts.forEach(x => x.mainCategoryId = category.ProductCategoryId);
        tabItem.allItems = allProducts;
        tabItem.currentItems = tabItem.allItems;
        tabs.push(tabItem);
      });

      return tabs;
    }

    function createPlaceholders(tabs) {
      var toReturn = [];
      angular.forEach(tabs, function (tab) {
        var placeholder = {};
        placeholder.name = tab.heading;
        placeholder.categoryId = tab.categoryId;
        placeholder.select = function () {
          tab.isActive = true;
        };

        placeholder.isVisible = true;

        tab.placeholder = placeholder;
        toReturn.push(placeholder);
      });

      return toReturn;
    }
  });
