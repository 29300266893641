import './sms.quick.sender.less'

angular.module('easybizy.easybizyModalViewsModel').controller('SMSQuickSenderController',
  function ($scope, $modalInstance, customer, message, localize, promotionTemplateResolver, $timeout, $compile, Repository) {
    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.customer = customer;
    $scope.validateActionWrapper = {};
    $scope.sendToValidatorAggregator = {};
    $scope.messageWrapper = {};
    if (message) {
      $scope.messageWrapper.message = message;
    }

    $scope.isGroup = customer && customer.CustomerType === "Group";
    if (customer) {
      $scope.messageWrapper.PhoneNumber = customer.MobileFirst ? customer.MobileFirst.replace(/\-/gi, '') : '';
    }

    $scope.sendToField = {
      fieldName: 'PhoneNumber', icon: "icon icon-sms", placeholder: localize.getLocalizedString("_Phone_"),
      validation: "{'max-length': 10, 'min-length': 9, 'digits': true, 'required': true}"
    };

    $scope.templateTypes = [
      { name: localize.getLocalizedString("_ChooseTemplate_"), value: null }
    ];

    $scope.selectedTemplatesWrapper = { selected: $scope.templateTypes[0] };

    $scope.editMode = false;
    $scope.toggleEditMode = function () {
      $scope.editMode = !$scope.editMode;
      if ($scope.editMode) {
        if (!$scope.selectedTemplatesWrapper.selected.value && !$scope.selectedTemplatesWrapper.selected.message) {
          $timeout(function () {
            $scope.toggleOpenWrapper.toggle();
          }, 100);
        } else {
          $scope.messageWrapper.message = $scope.selectedTemplatesWrapper.selected.message;
        }
      } else if ($scope.messageWrapper.message) {
        $scope.selectedTemplatesWrapper.selected.message = $scope.messageWrapper.message;
        replaceDynamicFields();
      }
    };

    Object.defineProperty($scope, 'status', {
      get: function () {
        var toReturn = 'not-ready';
        var hasTemplateSelected = $scope.selectedTemplatesWrapper.selected.value > 0;
        var isNewTemplateSelected = $scope.selectedTemplatesWrapper.selected.value < 0;
        var hasMessage = !!$scope.messageWrapper.message;

        if (hasMessage && !$scope.editMode) {
          toReturn = 'ready-to-send';
        } else if ($scope.editMode && ((isNewTemplateSelected || hasTemplateSelected) && hasMessage)) {
          toReturn = 'savable';
        } else if (!isNewTemplateSelected && $scope.editMode) {
          toReturn = 'disabled';
        }

        return toReturn;
      }
    });

    Object.defineProperty($scope, 'buttonText', {
      get: (function () {
        var statusToButtonText = {
          send: localize.getLocalizedString('_Send_'),
          save: localize.getLocalizedString('_SaveTemplate_')
        };

        return function () {
          return $scope.status == 'savable' ? statusToButtonText.save : statusToButtonText.send;
        };
      }())
    });

    $scope.insertAtCaretWrapper = {};
    $scope.addDynamicFields = function (field) {
      if ($scope.status != 'disabled') {
        $scope.messageWrapper.message = $scope.insertAtCaretWrapper.insert(field.value);
      }
    };

    var regex = new RegExp(promotionTemplateResolver.openTag + "[a-zA-Z0-9]*" + promotionTemplateResolver.closeTag, "g");
    var fieldsMapper = {};

    initFields();

    $scope.toggleOpenWrapper = {};
    $scope.addNewCategoryAction = function (newField) {
      $scope.insertAtCaretWrapper.insert(null);
    };

    $scope.getMessageContent = function () {
      if ($scope.messageWrapper.message) {
        var templateMessage = {
          Content: $scope.messageWrapper.message,
          Id: $scope.customer.CustomerId ? $scope.customer.CustomerId : $scope.customer.LeadId,
          EntityType: $scope.customer.EntityType || "Customer"
        };
        Repository.Custom('TemplateMessages').getMessageContent(templateMessage).then(function (message) {
          openWhatsapp($scope.messageWrapper.PhoneNumber, message);
        }).catch(function (e) { 
          toastr.error(localize.getLocalizedString('_FailedToCreateMessage_'));
        });
      } else {
        openWhatsapp($scope.messageWrapper.PhoneNumber, '');
      }

    };


    function openWhatsapp(phoneNumber, message) {
      let sendToNumber = phoneNumber;
      if (sendToNumber.startsWith('0')) {
        sendToNumber = sendToNumber.substr(1);
      }

      const link = `https://api.whatsapp.com/send?phone=972${ sendToNumber }&text=${ message }`;
      window.open(link, '_blank');
    }


    $scope.saveOrSend = function () {
      // Should save!
      if ($scope.editMode) {
        var templateMessageToSend = {
          Message: $scope.messageWrapper.message,
          MessageTemplateType: 'Custom',
          Name: $scope.selectedTemplatesWrapper.selected.name
        };

        var delegate;
        if ($scope.selectedTemplatesWrapper.selected.value > 0) {
          // UPDATE
          templateMessageToSend.MessageTemplateId = $scope.selectedTemplatesWrapper.selected.value;
          delegate = Repository.Custom('TemplateMessages').put(templateMessageToSend);
        } else {
          // Create
          delegate = Repository.Custom('TemplateMessages').post(templateMessageToSend);
        }

        $scope.isLoading = true;
        delegate.then(function (result) {
          if ($scope.selectedTemplatesWrapper.selected.value < 0) {
            var inserted = insertToCombobox(result, $scope.selectedTemplatesWrapper.selected.value);
            $scope.templateSelected(inserted);
          }

          $scope.$applyIfNeeded(function () {
            $scope.isLoading = false;
            $scope.toggleEditMode();
          });
        }).catch(function (err) {
          toastr.error(localize.getLocalizedString('_ErrorSavingTemplates_'));
          $scope.isLoading = false;
        });
        // SEND!
      } else {
        var validationFields = $scope.validateActionWrapper.validate();
        if (validationFields.length !== 0 && !$scope.isGroup) {
          var wrongFieldMessage = localize.getLocalizedString("_WrongPhone_");

          toastr.error(wrongFieldMessage);
          return;
        }

        var fakePromotion = {
          ClientPromotionContent: { PromotionSenderType: 'sms', Message: $scope.messageWrapper.message },
        };

        if ((customer.MobileFirst == $scope.messageWrapper.PhoneNumber || $scope.isGroup) && $scope.customer.CustomerId) {
          fakePromotion.CustomersId = [$scope.customer.CustomerId];
        } else {
          fakePromotion.DirectRecipients = $scope.messageWrapper.PhoneNumber;
        }

        fakePromotion.IsServiceEngagementType = true;
        Repository.Custom("PromotionsRepository").create(fakePromotion)
          .then(function () {
            $scope.isLoading = false;
            $scope.cancel();
            toastr.success(localize.getLocalizedString('_MessageCreated_'));
          })
          .catch(function (e) {
            $scope.isLoading = false;
            toastr.error(localize.getLocalizedString('_FailedToCreateMessage_'));
          });
      }
    };



    $scope.deleteTemplate = function (templateMessage) {
      Repository.Custom('TemplateMessages').delete(templateMessage);
    };

    $scope.templateSelected = function (item) {
      if ($scope.selectedTemplatesWrapper.selected !== item) {
        $scope.selectedTemplatesWrapper.selected = item;
      }

      if (!item.value) {
        $scope.messageWrapper.message = '';
        return;
      }

      $scope.messageWrapper.message = item.message;
      if (!$scope.editMode) {
        replaceDynamicFields();
      }
    };

    loadTemplates();

    function loadTemplates() {
      Repository.Custom('TemplateMessages').get()
        .then(function(results) {
          results.value.forEach(insertToCombobox);
        });
    }

    var deleteText = localize.getLocalizedString('_Delete_');
    var approvalText = localize.getLocalizedString('_Sure_');
    $scope.decorateTemplateCombo = function (scope, element) {
      var templateComboItem = scope.item;
      if (templateComboItem && templateComboItem.value) {
        scope.deleteText = deleteText;
        scope.approvalText = approvalText;

        var submitButton = '<div ng-click="removeTemplate($event, item)">{{approvalMode ? approvalText : deleteText}}</div>';

        scope.removeTemplate = function (e, item) {
          e.preventDefault();
          e.stopPropagation();

          if (!scope.approvalMode) {
            scope.approvalMode = true;
            return $timeout(function () {
              delete scope.approvalMode;
            }, 10000);
          }

          $scope.templateTypes.splice($scope.templateTypes.indexOf(item), 1);
          Repository.Custom('TemplateMessages').delete(item.value)
            .catch(function () {
              toastr.error(localize.getLocalizedString('_ErrorDeletingTemplate_'));
            });
        };

        var decorationItem = '<div class="combobox-template-remove-decorator">' + submitButton + '</div>';
        element.find('.combobox-list-item-concrete-value').append($compile(decorationItem)(scope));
      }
    };



    function insertToCombobox(messageTemplate, customID) {
      var item = {
        value: messageTemplate.MessageTemplateId,
        name: messageTemplate.Name,
        message: messageTemplate.Message
      };

      var existingItemIndex = $scope.templateTypes.indexOfById(customID < 0 ? { value: customID } : item, 'value');
      if (existingItemIndex > -1) {
        $scope.templateTypes.splice(existingItemIndex, 1, item);
      } else {
        $scope.templateTypes.push(item);
      }

      return item;
    }

    function replaceDynamicFields() {
      $scope.messageWrapper.message = $scope.messageWrapper.message.replace(regex, function (value) {
        var valueToReturn = value;
        if (fieldsMapper.hasOwnProperty(value)) {
          var field = fieldsMapper[value];
          if (field.calculated) {
            valueToReturn = field.calculated(customer);
          } else {
            valueToReturn = customer[field.fieldName];
            if (!valueToReturn) {
              valueToReturn = field.value;
            }
          }
        }

        return valueToReturn;
      });
    }

    function initFields() {
      var fields = promotionTemplateResolver.getDynamicFields({ CustomFields: ['NextMeetings', 'NextMeetingsAndServices'] });
      var removeFields = ['Address', 'EmailAddress'].map(function (item) {
        return promotionTemplateResolver.openTag + item + promotionTemplateResolver.closeTag;
      });

      $scope.fields = fields.filter(function (field) {
        var toReturn = removeFields.indexOf(field.value) < 0;
        if (toReturn) {
          fieldsMapper[field.value] = field;
        }

        return toReturn;
      });
    }
  });
