import {isNonEmptyString, valueOrDefault} from "@tomeravni/easybizy-js-common/common";
export const NUMBER_OF_PARAMS_REGEX = new RegExp('{{[0-9]*}}', 'g');

export const replaceDynamicParams = (str, params) => {
  if (!isNonEmptyString(str)) {
    return str;
  }


  const duplicated = [...valueOrDefault(params, [])];
  return str.replace(NUMBER_OF_PARAMS_REGEX, () => duplicated.shift() || '').trim();
};

export const getNumberOfDynamicParams = (text) => {
  if (!isNonEmptyString(text)) {
    return 0;
  }

  const dynamicParams = text.match(NUMBER_OF_PARAMS_REGEX)?.length;
  // noinspection JSValidateTypes
  return valueOrDefault(dynamicParams, 0);
};
