import './employee.shifts.calendar.directive.less';
import {
  DATE_DISPLAY_FORMAT,
  HOURS_AND_MINUTES_FORMAT,
  ODATA_DATE_TIME_FORMAT,
  RTL_DATE_TIME_DISPLAY,
  SERVER_DATA_DATE_FORMAT
} from "../../../constants";

const SHIFT_MODIFICATIONS_DATE_FORMAT = 'YYYY-MM-DDTHH-mm-ss'

angular.module('easybizy.easybizyModalViewsModel').directive('employeeShiftsCalendar', function (localize, Repository, configuration, $timeout, $rootScope) {
  const MOMENT_TIME_FORMAT = 'HH:mm';

  var k_DaysMapIndex = 'YYMMDD';
  var k_Day = localize.getLocalizedString('_Day_');
  var s_RandomShift = -Math.floor(Math.random() * 10000);
  var calendarSettings = configuration.get().CalendarSettings,
    k_DefaultStartTime = moment(calendarSettings.StartTime.Value, MOMENT_TIME_FORMAT);

  var k_FieldsToExport = {
    CheckedIn: localize.getLocalizedString("_StartTime_"),
    CheckedOut: localize.getLocalizedString("_EndTime_"),
    Notes: localize.getLocalizedString("_Notes_")
  };

  return {
    restrict: 'E',
    replace: true,
    scope: {
      employeeId: '=',
      shiftsHandler: '=',
      dateRange: '='
    },
    link: function (scope) {

      scope.setShiftTime = function ({ value, props }) {
        if (props.type === 'start') {
          props.shift._startTime = value;
        } else {
          props.shift._endTime = value;
        }
      };

      scope.$on('reload-shifts',
        () => {
          $timeout(() => {
            buildWeeks(scope.dateRange);
          });
        });

      scope.possibleShiftTypes = [
        { name: localize.getLocalizedString('_Shift_'), value: 'Shift' },
        { name: localize.getLocalizedString('_Vacation_'), value: 'Holiday', icon: 'icon icon-vacation' },
        { name: localize.getLocalizedString('_Sickness_'), value: 'Sick', icon: 'icon icon-sick' },
        { name: localize.getLocalizedString('_Other_'), value: 'Other', icon: 'icon icon-non-appearance' },
      ];

      let api;

      scope.$watch('employeeId', (newVal) => {
        if (api) {
          $timeout(() => {
            buildWeeks(scope.dateRange);
          });
        }
      });

      scope.isLoadingWrapper = { isLoading: false };
      scope.currentDay = null;
      scope.toggleShift = function (day) {
        scope.currentDay = scope.currentDay != day ? day : null;
      };

      var dirty = false;

      scope.$watchOnce('shiftsHandler', function (shiftsHandler) {
        Object.defineProperty(scope.shiftsHandler, 'dirty', {
          get: function () {
            return dirty || allShifts.some(function (shift) {
              return shift.dirty;
            });
          }
        });

        Object.defineProperty(scope.shiftsHandler, 'isValid', {
          get: function () {
            return !allShifts.some(function (shift) {
              return shift._totalShiftTime === 0;
            });
          }
        });

        Object.defineProperty(scope.shiftsHandler, 'totalRangeHours', {
          get: function () {
            return allShifts.reduce(reduceFunc, 0);
          }
        });

        Object.defineProperty(scope.shiftsHandler, 'totalRangeWorkingDays', {
          get: function () {
            return Object.keys(shiftsByDaysMap).reduce(function (accWorkingDays, dayKey) {
              return accWorkingDays + (shiftsByDaysMap[dayKey].shifts.length > 0 && shiftsByDaysMap[dayKey].shifts.some(function (shift) {
                return shift._shiftType.value === 'Shift';
              }) ? 1 : 0);
            }, 0);
          }
        });

        Object.defineProperty(scope.shiftsHandler, 'totalRangeHolidayDays', {
          get: function () {
            return Object.keys(shiftsByDaysMap).reduce(function (accWorkingDays, dayKey) {
              return accWorkingDays + (shiftsByDaysMap[dayKey].shifts.length > 0 && shiftsByDaysMap[dayKey].shifts.some(function (shift) {
                return shift._shiftType.value === 'Holiday';
              }) ? 1 : 0);
            }, 0);
          }
        });

        Object.defineProperty(scope.shiftsHandler, 'totalRangeSickDays', {
          get: function () {
            return Object.keys(shiftsByDaysMap).reduce(function (accWorkingDays, dayKey) {
              return accWorkingDays + (shiftsByDaysMap[dayKey].shifts.length > 0 && shiftsByDaysMap[dayKey].shifts.some(function (shift) {
                return shift._shiftType.value === 'Sick';
              }) ? 1 : 0);
            }, 0);
          }
        });

        Object.defineProperty(scope.shiftsHandler, 'totalRangeOtherDays', {
          get: function () {
            return Object.keys(shiftsByDaysMap).reduce(function (accWorkingDays, dayKey) {
              return accWorkingDays + (shiftsByDaysMap[dayKey].shifts.length > 0 && shiftsByDaysMap[dayKey].shifts.some(function (shift) {
                return shift._shiftType.value === 'Other';
              }) ? 1 : 0);
            }, 0);
          }
        });


        shiftsHandler.anyShiftsInRange = function () {
          return allShifts.length > 0 && allShifts.some(function (shift) {
            return shift.EmployeeShiftId > 0;
          });
        };

        shiftsHandler.get = function (finilize) {
          var createdOrUpdated = [];
          allShifts.forEach(function (shift) {
            if (shift.dirty || shift.EmployeeShiftId < 0) {
              createdOrUpdated.push(cleanShift(shift));
            }
          });

          var committed = [];
          if (finilize) {
            allShifts.forEach(function (shift) {
              if (!shift.IsFinal) {
                committed.push(shift.EmployeeShiftId);
              }
            });
          }

          return {
            createdOrUpdated: createdOrUpdated,
            deleted: deletedShifts,
            deletedExceptions: deletedExceptions,
            shiftsToCommit: committed
          };
        };

        shiftsHandler.getFieldNamesDictionary = function () {
          return k_FieldsToExport;
        };

      });

      scope.$watch('dateRange', buildWeeks, true);


      scope.daysOfWork = [];
      var currentDay = moment().startOf('week');

      var dayIndex = -1;
      while (++dayIndex < 7) {
        scope.daysOfWork.push(currentDay.clone().add(dayIndex, 'days').format('dddd'));
      }


      scope.weeks = [];
      var shiftsByDaysMap = {};
      var allShifts = [], deletedShifts = [], deletedExceptions = [];

      scope.fromMetadata = {
        name: localize.getLocalizedString("_StartTime_"),
        placeholder: localize.getLocalizedString("_StartTime_"),
        type: 'Time',
        command: {
          get: function (entity) {
            return moment(entity.CheckedIn).format(HOURS_AND_MINUTES_FORMAT);
          },
          set: function (entity, value) {
            var prevCheckIn = moment(entity.CheckedIn);
            if (!prevCheckIn.isValid()) {
              prevCheckIn = moment(entity._CheckedIn);
            }

            var newTime = moment(value, HOURS_AND_MINUTES_FORMAT);
            entity.CheckedIn = prevCheckIn.hour(newTime.hour()).minute(newTime.minute()).format(SERVER_DATA_DATE_FORMAT);
          }
        }
      };

      scope.removeShift = function (shift, day) {
        day.shifts.remove(shift);
        allShifts.remove(shift);
        dirty = true;

        if (shift.EmployeeShiftId > 0) {
          shift._shiftType.value === "Shift" ? deletedShifts.push(shift) : deletedExceptions.push(shift);
        }
      };

      scope.addShift = function (day) {
        if (!day.allowAddingShifts) {
          return;
        }

        var createdShift;
        if (day.shifts.length === 0) {
          createdShift = createShift(day.date);
          day.shifts.push(createdShift)
        } else {
          createdShift = createShift(day.date, moment(day.shifts[day.shifts.length - 1]._endTime, MOMENT_TIME_FORMAT));
          day.shifts.push(createdShift);
        }

        allShifts.push(createdShift);
      };


      scope.fixCheckoutDate = function (shift) {
        shift._checkedOut = shift._checkedIn.clone().add(1, 'h');
        shift._endTime = shift._checkedOut.format(MOMENT_TIME_FORMAT);
      };

      scope.getShiftCssClass = function (shifts) {
        //console.log(shifts);
        return shifts.length > 0 ? (shifts[0].ShiftType !== 'Shift' ? 'day-off' : 'has-shifts') : '';
      }


      function reduceFunc(acc, shift) {
        return acc + (shift._shiftType.value === 'Shift' ? shift._totalShiftTime : 0);
      }

      function buildWeeks(dateRange) {
        if (!dateRange) {
          return;
        }

        scope.currentDay = null;

        loadShifts();
        scope.weeks = [];
        shiftsByDaysMap = {};
        allShifts.length = 0;

        var from = moment(scope.dateRange.from, DATE_DISPLAY_FORMAT),
          to = moment(scope.dateRange.to, DATE_DISPLAY_FORMAT);
        var firstDay = from.clone().startOf('week'),
          lastDay = to.clone().endOf('week');

        var inRangeDaysIndexes = [from.diff(firstDay, 'days'), to.diff(firstDay, 'days')];

        var daysCount = lastDay.diff(firstDay, 'days');
        for (var i = 0; i <= daysCount; i++) {
          var weekIdx = Math.floor(i / 7);
          var date = firstDay.clone().add(i, 'days');
          var day = {
            date: date,
            dayIndex: date.format('DD'),
            dayOfWeek: k_Day + ' ' + date.format('dddd'),
            dayFullHeader: date.format('DD MMM YYYY'),
            shifts: [],
            isActive: i >= inRangeDaysIndexes[0] && i <= inRangeDaysIndexes[1]
          };

          Object.defineProperty(day, 'totalShiftsTime', {
            get: function () {
              return this.shifts.reduce(reduceFunc, 0);
            }
          });

          Object.defineProperty(day, 'invalid', {
            get: function () {
              const shiftTypes = new Set();
              return this.shifts.some(function (shift) {
                shiftTypes.add(shift.ShiftType);
                return shift._totalShiftTime === 0 || shift._notSameDay ||
                  [...shiftTypes].filter((type) => ['Holiday', 'Sick', 'Other'].includes(type)).length > 1;
              });
            }
          });

          Object.defineProperty(day, 'isManuallyDay', {
            get: function () {
              return this.shifts.some((shift) => shift.ManuallyCreated === 'Manually');
            }
          });


          Object.defineProperty(day, 'allowChangingShiftType', {
            get: function () {
              return this.shifts.length === 1 && this.shifts[0].EmployeeShiftId < 1;
            }
          });


          Object.defineProperty(day, 'allowAddingShifts', {
            get: function () {
              return !this.shifts.some((shift) => ['Holiday', 'Sick', 'Other'].includes(shift.ShiftType));
            }
          });

          const addShiftTip = localize.getLocalizedString('_AddNewShift_');
          const disallowShiftTip = localize.getLocalizedString('_AddingShiftsIsNotAllowed_');
          Object.defineProperty(day, 'addingTooltip', {
            get: function () {
              return this.allowAddingShifts ? addShiftTip : disallowShiftTip;
            }
          });


          scope.weeks[weekIdx] = scope.weeks[weekIdx] || [];
          scope.weeks[weekIdx].push(day);
          shiftsByDaysMap[date.format(k_DaysMapIndex)] = day;
        }

      }

      function cleanShift(shift) {
        var cleanShift = Object.assign({}, shift);
        Object.keys(cleanShift).forEach(function (propName) {
          if (propName.indexOf('_') === 0) {
            delete cleanShift[propName];
          }
        });

        return cleanShift;
      }

      function loadShifts() {
        scope.isLoadingWrapper.isLoading = true;

        setFilter();
        allShifts.length = 0;
        api.get().then(function (shifts) {
          scope.$evalAsync(function () {
            shiftsLoaded(shifts);
          });
        }).catch(function (err) {
          toastr.error(err.Message);
        }).finally(function () {
          scope.isLoadingWrapper.isLoading = false;
        })
      }

      function shiftsLoaded(shifts) {
        shifts.forEach(function (shift) {
          decorateShift(shift);
          var day = shiftsByDaysMap[shift._dayIndex];
          day.shifts.push(shift);
          allShifts.push(shift);
        });
      }

      function decorateShift(shift) {
        shift._checkedIn = moment(shift.CheckedIn, SERVER_DATA_DATE_FORMAT);
        shift._dayIndex = shift._checkedIn.format(k_DaysMapIndex);
        shift._checkedOut = moment(shift.CheckedOut, SERVER_DATA_DATE_FORMAT);
        const calculateShiftsTime = _calculateShiftsTime.bind(shift);
        shift._created = moment(shift.CreatedOn).format(DATE_DISPLAY_FORMAT);

        shift._didModified = shift.ShiftHistory && shift.ShiftHistory.length > 0;
        if (shift._didModified) {
          try {
            const sections = shift.ShiftHistory.split('}').slice(0, -1)
              .map((instance, idx) => instance.substr(idx > 0 ? 2 : 1))
              .map((instance) => instance.split(',')
                .map((x) => x.trim().split(':').map((y) => y.trim())));

            shift._modifications = sections.map((section) => section.reduce((acc, curr, idx) => ({
              ...acc,
              [curr[0]]: moment(curr[1], SHIFT_MODIFICATIONS_DATE_FORMAT).add('h',2).format(idx > 0 ? HOURS_AND_MINUTES_FORMAT : RTL_DATE_TIME_DISPLAY)
            }), {}))
          } catch (e) {
            shift._modifications = [];
          }
        }

        Object.defineProperty(shift, '_startTime', function () {
          let startTime = shift._checkedIn.format(MOMENT_TIME_FORMAT);
          return {
            get: function () {
              return startTime;
            },
            set: function (newValue) {
              const prevTime = startTime;
              startTime = newValue;
              const momentUpdatedStartTime = moment(startTime, MOMENT_TIME_FORMAT);
              const momentPreviousStartTime = moment(prevTime, MOMENT_TIME_FORMAT);
              const diffInMinutes = momentUpdatedStartTime.diff(momentPreviousStartTime, 'minutes');
              this.dirty = true;
              setDateTime(this._checkedIn, momentUpdatedStartTime);
              this.CheckedIn = this._checkedIn.format(SERVER_DATA_DATE_FORMAT);
              calculateShiftsTime();
              //if (!shift.preventEndTimeUpdateOnStartTimeChange) {
              //  shift._endTime = moment(shift._endTime, MOMENT_TIME_FORMAT).add(diffInMinutes, 'minutes').format(MOMENT_TIME_FORMAT);
              //}
            }
          }
        }());

        Object.defineProperty(shift, '_endTime', function () {
          let endTime = shift._checkedOut.format(MOMENT_TIME_FORMAT);
          return {
            get: function () {
              return endTime;
            },
            set: function (newValue) {
              endTime = newValue;
              this.dirty = true;
              const momentEndTime = moment(endTime, MOMENT_TIME_FORMAT);
              setDateTime(shift._checkedOut, momentEndTime);
              this.CheckedOut = this._checkedOut.format(SERVER_DATA_DATE_FORMAT);
              calculateShiftsTime();
              const momentStartTime = moment(shift._startTime, MOMENT_TIME_FORMAT);
              if (!momentEndTime.isAfter(momentStartTime)) {
                shift.preventEndTimeUpdateOnStartTimeChange = true;
                shift._startTime = momentEndTime.add(-1, 'hours').format(MOMENT_TIME_FORMAT);
                shift.preventEndTimeUpdateOnStartTimeChange = false;
              }
            }
          }
        }());

        Object.defineProperty(shift, '_notes', function () {
          let notes = shift.Notes;
          return {
            get: function () {
              return notes;
            },
            set: function (newValue) {
              this.dirty = notes !== newValue;
              notes = newValue;
              this.Notes = newValue;
            }
          }
        }());


        Object.defineProperty(shift, '_shiftType', function () {
          let shiftType = shift.ShiftType;


          return {
            get: function () {
              return scope.possibleShiftTypes.find((currentShiftType) => currentShiftType.value === shiftType) || scope.possibleShiftTypes[0];
            },
            set: function ({ value }) {
              this.dirty = value !== shiftType;
              shiftType = value;
              this.ShiftType = value;
            }
          }
        }());

        calculateShiftsTime();

        function _calculateShiftsTime() {
          var hours = this._checkedOut.diff(this._checkedIn, 'minutes') / 60;
          this._totalShiftTime = hours > 0 ? hours : 0;
          this._totalShiftTimeVisible = parseFloat(this._totalShiftTime.toFixed(2));
          this._notSameDay = !shift._checkedIn.isSame(shift._checkedOut, 'day');

        }
      }

      function createShift(date, startTime) {
        var startTime = setDateTime(date.clone(), startTime || k_DefaultStartTime);
        var newShift = {
          CheckedIn: startTime.format(SERVER_DATA_DATE_FORMAT),
          CheckedOut: startTime.clone().add(1, 'h').format(SERVER_DATA_DATE_FORMAT),
          dirty: true,
          EmployeeShiftId: --s_RandomShift,
          ShiftType: 'Shift',
          ManuallyCreated: 'Manually'
        };

        decorateShift(newShift);

        return newShift;
      }

      function setDateTime(date, time) {
        return date.hour(time.hour()).minutes(time.minutes()).seconds(0)
      }

      function setFilter() {
        const fromRange = moment(scope.dateRange.from, DATE_DISPLAY_FORMAT).endOf('day').format(ODATA_DATE_TIME_FORMAT);
        const toRange = moment(scope.dateRange.to, DATE_DISPLAY_FORMAT).endOf('day').format(ODATA_DATE_TIME_FORMAT);

        api = { get: () => Repository.Custom('EmployeeHours').getEmployeeShifts(scope.employeeId, fromRange, toRange) };

      }


    },
    template: require('./employee.shifts.calendar.directive.tpl.html')
  };
});
