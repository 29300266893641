import './products.picker.directive.less'
import './products.picker.repository'
import { DelayedFunction } from "../../../general/helpers";
import { InfiniteList } from "./lazy.items";

angular.module('easybizy.cash.register').directive('productsPicker', [
  'Repository', 'localize', 'configuration', 'productsPickerRepository', '$timeout', 'entityImageResolver', function (Repository, localize, configuration, productsPickerRepository, $timeout, entityImageResolver) {
    var availableColors = [
      '#414356', '#616161', '#998677', '#C69C74', '#32742C', '#D0D102',
      '#61AE24', '#00A1CB', '#01A4A4', '#113F8C', '#A186C8', '#D70060',
      '#E54028', '#F18D05'];

    var firstLoadAmount = 50;
    return {
      replace: true,
      scope: {
        addItem: '=',
        editMode: '=',
        mainViewWidthWrapper: '=',
        doneLoadingCallback: '&',
        specialItems: '='
      },
      link: function (scope, element) {
        scope.isLoading = true;
        var loadingIndication = { products: false, favorites: false };
        var productOrServices;
        var itemsEngine = null;
        var handleDragMode = generateHandleDragMode();
        loadPersonalConfig();
        productsPickerRepository.init(Repository, configuration, $timeout, itemToId).getProducts(function (err, products, full) {
          if (err) {
            return toastr.error(localize('_ErrorLoadingProducts_'));
          }

          if (!itemsEngine) {
            // We need the container to be drawn already...
            $timeout(function () {
              initItemsEngine(products, full)
            });
          } else {
            appendProducts(products)
          }
        });

        function initItemsEngine(products, full) {
          scope.isLoading = false;
          productOrServices = products;

          itemsEngine = new InfiniteList($itemsContainer, productOrServices, {
            itemToHtml: itemToHtml,
            scrollTrapper: element.find('.products-picker'),
            filterItem: filterItem,
            pageSize: firstLoadAmount,
            loadedMoreDelegate: function () {
              if (scope.editMode) {
                setTimeout(handleDragMode);
              }
            },
            sort: sortItems,
            uniqueResolver: itemToId,
            lowPerformanceMode: configuration.get().GeneralSettings.LowPerformanceVersion,
            onClick: function (item) {
              scope.$evalAsync(function () {
                scope.addItem(item);
              })
            }
          });

          if (full) {
            loadingIndication.products = true;
          }

          doneLoadingItemsOrFavoritesDelegate();
          scope.doneLoadingCallback() && scope.doneLoadingCallback()(productOrServices);
        }

        function appendProducts(products) {
          productOrServices.push.apply(productOrServices, products);
          itemsEngine.appendItems(products);
          loadingIndication.products = true;
          doneLoadingItemsOrFavoritesDelegate();
        }

        scope.specialProductClicked = function (item) {
          if (!item.disabled || !item.disabled()) {
            item.delegate();
          }
        };

        scope.isMobile = isMobilePlatform();
        scope.mobileSearchMode = false;

        scope.toggleSearchMode = () => {
          scope.mobileSearchMode = false;
          scope.mobileSearchText.value = '';
          scope.clearSearch()
        }


        scope.showSearchMessage = function () {
          if (scope.isMobile) {
            scope.mobileSearchMode = !scope.mobileSearchMode;
            $timeout(() => {
              element.find('.products-mobile-search-input').focus()
            }, 500)

          } else {
            toastr.warning(localize.getLocalizedString("_SearchIsDoneWithoutTextboxMessage_"));
          }
        };

        if (scope.isMobile) {
          scope.mobileSearchText = { value: '' }
          scope.$watch('mobileSearchText.value', (newVal) => {
            currentTerm = newVal;
            delayedSearchObject.set(newVal);
          })
        }


        var delayedSearchObject = new DelayedFunction(searchOrBarcode, 50);
        var $itemsContainer = element.find('.items-container ul.concrete-items-container');
        var $trashCanIcon = element.find('.trash-can-wrapper');

        function searchOrBarcode() {
          scope.searchTerm = currentTerm.trim();
          if (scope.searchTerm.length > 0) {
            if (!scope.searchTerm.hasHebrew()) {
              scope.equivalentSearchTerm = scope.searchTerm.stringToPositionalHebrewString();
            }

            filter();
          } else {
            scope.equivalentSearchTerm = '';
          }

          scope.$applyIfNeeded();
        }

        scope.slider = {
          value: 5,
          options: {
            floor: 1,
            ceil: 6,
            ticksArray: [1, 2, 3, 4, 5, 6],
            vertical: true
          }
        };

        Object.defineProperty(scope.slider, 'valueAsClass', {
          get: function () {
            return 'column-size-' + scope.slider.value;
          }
        });


        var configFavorites = [];

        function loadPersonalConfig() {
          var doneLoadingPersonalConfigurations = function (result) {
            scope.slider.value = result.numberOfTilesOnCashRegister || 4;
            configFavorites = result.cashRegisterFavorites ? result.cashRegisterFavorites : [];
            if (result.mainViewWidth) {
              scope.mainViewWidthWrapper.value = result.mainViewWidth;
            }

            loadingIndication.favorites = true;
            doneLoadingItemsOrFavoritesDelegate();
          };


          configuration.getPersonalAsync().then(doneLoadingPersonalConfigurations).catch(function () {
            toastr.error(localize.getLocalizedString('_ErrorLoadingFavorites_'));
            doneLoadingPersonalConfigurations({});
          });

        }

        // Barcode + Filter
        $(document).pos({
          swipe: false
        });


        $(document).on('scan.pos.barcode', handleBarcodeScan);

        var currentTerm = '';
        scope.searchTerm = '';
        if (!scope.isMobile) {
          $(document).on('keypress.productsFilter', function (e) {
            if (!shouldPreventEvent(e)) {
              if (e.which !== 0) {
                currentTerm += String.fromCharCode(e.which);
                delayedSearchObject.set(currentTerm);
              }
            }
          });
        }

        scope.toggleFilters = function () {
          scope.visibleCategories = !scope.visibleCategories;
          if (!scope.visibleCategories) {
            scope.clearSearch()
          } else {
            filter();
          }
        };

        scope.navigationTabs = [
          {
            icon: 'icon-check',
            title: localize.getLocalizedString('_All_'),
            filter: function () {
              return true;
            }
          },
          {
            icon: 'icon-star',
            css: 'favorites-tab',
            title: localize.getLocalizedString('_Favorites_'),
            filter: function (item) {
              return item.Favorite;
            },
            sortable: true
          },
          {
            icon: 'icon-lab',
            title: localize.getLocalizedString('_Products_'),
            filter: function (item) {
              return item.ConcreteType === 'ProductMetadata';
            }
          },
          {
            icon: 'icon-tag',
            title: localize.getLocalizedString('_Services_'),
            filter: function (item) {
              return item.ConcreteType === 'ServiceMetadata';

            }
          }
        ];

        scope.selectedTab = scope.navigationTabs[0];
        scope.filterTab = function (tab) {
          scope.selectedTab = tab;
          filter();
          handleDragMode();
        };

        if (!scope.isMobile) {
          $(document).on('keydown.productsFilter', function (event) {
            if (!shouldPreventEvent(event)) {
              var key = event.keyCode || event.charCode;
              var doPrevent = false;
              if (currentTerm.length > 0 && (key == 8 || key == 46)) {
                currentTerm = scope.searchTerm = currentTerm.slice(0, -1);
                if (scope.equivalentSearchTerm) {
                  scope.equivalentSearchTerm = scope.equivalentSearchTerm.slice(0, -1);
                }

                doPrevent = !wasEventInInputOrText(event.srcElement || event.target);
              }

              if (doPrevent) {
                event.preventDefault();
                filter();
              }

              scope.$applyIfNeeded();
            }
          });
        }

        scope.clearSearch = function () {
          currentTerm = scope.searchTerm = '';
          scope.currentCategory = null;
          // setTimeout(filter);
          filter();
          // scope.$applyIfNeeded();
        };

        scope.$on('$destroy', function () {
          $(document).off('keypress.productsFilter keydown.productsFilter');
          $(document).destroyPos();

        });

        var barcodeToItemMapper = {};
        scope.$watch('editMode', function (newVal) {
          // if (currentlyInSortMode && angular.isDefined(newVal) && !newVal) {
          //   toggleSortMode(false);
          // } else {
          //   if (scope.selectedTab.css == 'favorites-tab') {
          //     toggleSortMode(newVal);
          //   } else {
          //     toggleEditMode(newVal);
          //   }
          // }

          handleDragMode();

          if (newVal == false) {
            setTimeout(saveFavorites);
          }
        });

        scope.activateCategory = function (category) {
          scope.currentCategory = scope.currentCategory == category ? null : category;
          filter();
        };

        var $body = $('body');
        var categoryIdToColor = {};

        function filterItem(item, regex, equivalentRegex) {
          var key = item.Label;
          var isVisible = scope.searchTerm.length == 0 || key.search(regex) >= 0 || (equivalentRegex && key.search(equivalentRegex) >= 0);
          isVisible = isVisible && (!scope.selectedTab.filter || scope.selectedTab.filter(item));
          if (isVisible && scope.availableFilters) {
            scope.availableFilters[item.CategoryId] = item.CategoryName;
          }

          return isVisible && filterCategory(item);
        }

        function filter() {
          var regex = new RegExp(scope.searchTerm, "i");
          var equivalentRegex = null;
          if (scope.equivalentSearchTerm) {
            equivalentRegex = new RegExp(scope.equivalentSearchTerm, "i");
          }

          return itemsEngine.filter(regex, equivalentRegex);
        }

        function filterCategory(item) {
          return !scope.currentCategory || item.CategoryId == scope.currentCategory;
        }

        function shouldPreventEvent(event) {
          return event && wasEventInInputOrText(event.srcElement || event.target)
            || $body.children('.modal').length > 0 || $('.feedback-view.feedback-view-hidden').length < 1
            || $('.help-videos').length > 0;
        }

        function itemToHtml(item, uniqueId, lowPerformanceMode) {
          var toReturn = '';

          var price = '<span class="shekel-sign">₪</span>' +
            (parseFloat(item.RetailPrice) == parseInt(item.RetailPrice) ? parseInt(item.RetailPrice) : item.RetailPrice);

          var itemColor;
          if (item.CategoryId) {
            if (!categoryIdToColor.hasOwnProperty(item.CategoryId)) {
              categoryIdToColor[item.CategoryId] = availableColors.pop()
            }

            itemColor = categoryIdToColor[item.CategoryId];
          }

          var topPart;


          var imageURL = entityImageResolver.resolve(item, true);
          if (imageURL && !lowPerformanceMode) {
            topPart =
              '<div class="item-image" style="background-image: url(\'' + imageURL + '\')" />';
          } else {
            topPart = (itemColor ? '<div class="top-part-color" style="background-color: ' + itemColor + '"></div>' : '') +
              '<div class="item-letters-short-hand">' + item.Label.substr(0, 2) + '</div>';
          }


          toReturn += '<li class="custom-column" data-id="' + uniqueId + '"><div>\
                          <div class="cash-register-item-wrapper">\
                            <div class="top-part relative-container">' +
            topPart +
            '</div>\
            <div class="bottom-part relative-container">\
              <div class="bottom-part-left ui-ellipsis">' + item.Label + '</div>\
                              <div class="bottom-part-right">' + price + '</div>\
                            </div>\
                          </div>\
                       </div></li>';


          return toReturn;
        }

        function saveFavorites() {
          configuration.updateCashRegisterFavorite(favoriteItems.map(itemToId), scope.slider.value, scope.mainViewWidthWrapper.value)
            .catch(function (err) {
              toastr.error(localize.getLocalizedString('_ErrorUpdatingFavorites_'));
            });
        }


        function handleBarcodeScan(event) {
          if (shouldPreventEvent()) {
            return;
          }

          //access `event.code` - barcode data
          delayedSearchObject.cancel();
          var item;
          if (item = barcodeToItemMapper[event.code]) {
            scope.addItem(item);
            setTimeout(function () {
              var el = $('.droppable-table-icon-with-value-content-wrapper div.ui-ellipsis:contains("' + item.Label + '")')
                .closest('.droppable-table-content-row').find('div.number-editor[field~="Quantity"]');
              if (el.find('input').val() == 1) {
                // No need to animate. It is only one!
                return;
              }

              el.addClass('animated flash');
              setTimeout(function () {
                el.removeClass('animated flash');
              }, 1000);
            });
          } else {
            toastr.warning(localize.getLocalizedString('_ItemWasNotFound_'))
          }

          scope.clearSearch();
        }


        function wasEventInInputOrText(target) {
          if ((target.tagName.toUpperCase() === 'INPUT' &&
              (
                target.type.toUpperCase() === 'TEXT' ||
                target.type.toUpperCase() === 'PASSWORD' ||
                target.type.toUpperCase() === 'FILE' ||
                target.type.toUpperCase() === 'SEARCH' ||
                target.type.toUpperCase() === 'EMAIL' ||
                target.type.toUpperCase() === 'NUMBER' ||
                target.type.toUpperCase() === 'DATE')
            ) ||
            target.tagName.toUpperCase() === 'TEXTAREA') {
            return !(target.readOnly || target.disabled);
          } else {
            return false;
          }
        }

        function sortItems(items) {
          if (scope.selectedTab.css != 'favorites-tab' || !favoriteItems || favoriteItems.length == 0) {
            return items;
          }

          items.sort(function (a, b) {
            return favoriteItems.indexOf(a.entity) - favoriteItems.indexOf(b.entity);
          })
        }

        function elementToItem(el) {
          var itemIdKey = $(el).attr('data-id');
          var itemIds = itemIdKey.split('_');
          var toReturn = null;
          productOrServices.some(function (productOrService) {
            if (productOrService.Id == itemIds[1] && (productOrService.ConcreteType.charAt(0) == itemIds[0])) {
              toReturn = productOrService;
              return true;
            }
          });

          return toReturn;
        }

        function itemToId(item) {
          item.dataId = item.ConcreteType.substring(0, 1) + '_' + item.Id;
          return item.dataId;
        }

        var favoriteItems = [];

        function pushItemToFavorites(item) {
          item.Favorite = true;
          if (favoriteItems.indexOf(item) < 0) {
            favoriteItems.push(item);
            scope.favoritesCount = scope.favoritesCount ? scope.favoritesCount + 1 : 1;
          }
        }

        function pullItemToFavorites(item) {
          item.Favorite = false;
          if (!!favoriteItems.remove(item, true)) {
            scope.favoritesCount--;

          }
        }

        function doneLoadingItemsOrFavoritesDelegate() {
          if (loadingIndication.favorites && itemsEngine) {
            itemsEngine.setItemsInRow(scope.slider.value);
          }

          if (!(loadingIndication.products && loadingIndication.favorites)) {
            // Either favorites or products were not loaded yet.
            return;
          }

          if (configFavorites && configFavorites.length > 0) {
            var tmpFavorites = new Array(configFavorites.length)
          }

          var availableFilters = {};
          productOrServices.forEach(function (item) {
            var dataId = itemToId(item);
            var favoriteIndex = configFavorites.indexOf(dataId);
            if (tmpFavorites && favoriteIndex >= 0) {
              tmpFavorites[favoriteIndex] = item;
            }

            availableFilters[item.CategoryId] = item.CategoryName;
            barcodeToItemMapper[item.SerialNumber] = item;
          });

          scope.availableFilters = availableFilters;
          if (tmpFavorites) {
            tmpFavorites.forEach(pushItemToFavorites);
          }
        }

        function generateHandleDragMode() {
          var currentEditMode = false;
          var currentSortMode = false;
          return function () {
            if (scope.editMode) {
              if (scope.selectedTab.sortable) {
                // Remove draggable.
                toggleEditMode(false);
                toggleSortMode(true);
              } else {
                toggleEditMode(true);
              }
            } else {
              toggleEditMode(false);
              toggleSortMode(false);
            }

            /*private*/
            function toggleEditMode(editMode) {
              if (editMode) {
                element.find('.favorites-tab:not(.ui-droppable)').droppable({
                  tolerance: 'touch',
                  accept: '.custom-column',
                  activeClass: 'dragging',
                  hoverClass: 'drop-hover',
                  drop: function (event, ui) {
                    var item = elementToItem(ui.draggable);
                    pushItemToFavorites(item);
                    scope.$applyIfNeeded();
                  }
                });

                $itemsContainer.find('li:not(.ui-draggable)').draggable({
                  scroll: false,
                  zIndex: 10000,
                  helper: 'clone',
                  revert: true,
                  revertDuration: 200,
                  start: function (event, ui) {
                    element.addClass('during-drag');
                  },
                  stop: function (event, ui) {
                    element.removeClass('during-drag');
                    $(this).removeAttr("style");
                  }
                });
              } else if (currentEditMode) {
                element.find('.favorites-tab.ui-droppable').droppable('destroy');
                $itemsContainer.find('li.ui-draggable').draggable('destroy');
              }

              currentEditMode = editMode;
            }

            /*private*/
            function toggleSortMode(sortMode) {
              setTimeout(function () {
                if (sortMode) {
                  $itemsContainer.sortable({
                    items: "li:not(.not-displayed)",
                    tolerance: 'pointer',
                    stop: function (event, ui) {
                      favoriteItems.length = 0;
                      $itemsContainer.find('li').each(function (idx, item) {
                        favoriteItems.push(elementToItem(item));
                      });
                    }
                  });

                  $trashCanIcon.droppable({
                    tolerance: 'touch',
                    accept: '.custom-column',
                    activeClass: 'dragging',
                    hoverClass: 'open',
                    drop: function (event, ui) {
                      var item = elementToItem(ui.draggable);
                      pullItemToFavorites(item);
                      scope.$applyIfNeeded();
                    }
                  })
                } else if (currentSortMode) {
                  $itemsContainer.sortable('destroy');
                  $trashCanIcon.droppable('destroy');
                }

                currentSortMode = sortMode;
              });
            }
          }
        }

      },
      template: require('./products.picker.directive.tpl.html')
    }
  }]);

