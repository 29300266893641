import {getSafely, isNonEmptyObject, isNonEmptyString} from "@tomeravni/easybizy-js-common/common";

angular.module('easybizy.common.common.resources')
  .factory('configuration', ['$window', '$injector', 'Repository', '$q', '$rootScope', '$timeout',
    function ($window, $injector, Repository, $q, $rootScope, $timeout) {
      jQuery.timeago.settings.allowFuture = true;
      var wasConfigurationsLoaded = false; // defined on loader.js.
      const k_configurationsKey = "configurations";
      const k_personalConfigurationsKey = "personal_configurations";
      var configurationValuesMap = null;
      var defaultEmployeeObject = {};
      var personalConfigurationsDidLoad = false;
      var personalConfigurations = {};
      let _loggedInUser;
      let loggedInUserPromise;
      const localize = $injector.get('localize');
      let currentLanguage;

      // Holds requests for personal configurations until it loads.
      var personalConfigurationsResolver = [];

      // Awaiting delegates for updated configurations
      var delegatesWaitingForConfigurationsToBeUpdated = [];
      var configurationsDidUpdate = false;

      const reloadBrowser = function (res) {
        // If configurations were loaded for the first time, reload the browser.
        location.reload();
        return res;
      };

      handleConfigurationsFirstLoading();

      Repository.Custom("Configurations").getDefaultEmployee().then(function (data) {
        data = data || {};
        defaultEmployeeObject = {
          id: data.EmployeeId,
          value: data.EmployeeId,
          name: data.FirstName + " " + data.LastName
        };
      });

      getPersonalAsync();

      function updateConfigurations() {
        const deferred = $q.defer();
        Repository.Custom('Configurations').get()
          .then(function (data) {
            $timeout(function () {
              $rootScope.$broadcast('configurationsLoaded', data);
              $rootScope.$emit('refresh-session', data);
            });

            if (!data || data.ExceptionMessage) {
              throw (data || 'failed loading configurations');
            }

            if (configurationValuesMap && data.BusinessDetails.BusinessName.Value
              !== configurationValuesMap.BusinessDetails.BusinessName.Value) {
              setTimeout(reloadBrowser);
            }


            wasConfigurationsLoaded = true;
            $.localStorage.set(k_configurationsKey, data);
            configurationValuesMap = data;
            configurationsDidUpdate = true;
            deferred.resolve(data);


            while (delegatesWaitingForConfigurationsToBeUpdated.length > 0) {
              const localDelegate = delegatesWaitingForConfigurationsToBeUpdated.pop();
              localDelegate.resolve(data);
            }
          })
          .catch(function (err) {
            wasConfigurationsLoaded = "...תקלה בטעינת הגדרות";
            deferred.reject(err);
            $rootScope.$broadcast('configurationsLoadedError', err);

          });

        return deferred.promise;
      }

      $rootScope.$on('tabFocusChanged', (newVal, props) => {
        if (props.focus) {
          loadValidateLoggedInUser();
        }
      });

      const toReturn = {
        get: get,
        getUpdatedConfigurationAsync: getUpdatedConfigurationAsync,
        getPersonalAsync: getPersonalAsync,
        get loggedInUser() {
          return _loggedInUser ? {..._loggedInUser} : loggedInUserPromise;
        },
        get loggedInUserAsPromise() {
          if (_loggedInUser) {
            return new Promise((resolve) => {
              resolve(_loggedInUser);
            });
          }

          return loggedInUserPromise;
        },
        updateCashRegisterFavorite: updateCashRegisterFavorite,
        update: update,
        isValidFacebook,
        isValidWhatsappAccount,
        defaultEmployee: defaultEmployee,
        setWhatsappToken,
        clearWhatsappToken,
        loadBotConfigurations
      };

      return toReturn;

      function loadValidateLoggedInUser() {
        const currentContext = Object.getSafely(['context', 'name'], _loggedInUser);
        loggedInUserPromise = Repository.Custom("Configurations").loggedInUser().then((loggedInUser) => {
          _loggedInUser = loggedInUser;
          if (currentContext && currentContext !== Object.getSafely(['context', 'name'], loggedInUser)) {
            console.log('context changed, reloading...');
            return reloadBrowser();
          }

          $.localStorage.set(k_personalConfigurationsKey, loggedInUser);
          return loggedInUser;
        });

        return loggedInUserPromise;
      }

      function getUpdatedConfigurationAsync() {
        const deferred = $q.defer();
        if (configurationsDidUpdate) {
          deferred.resolve(configurationValuesMap);
        } else {
          delegatesWaitingForConfigurationsToBeUpdated.push(deferred);
        }

        return deferred.promise;
      }

      function get() {
        return configurationValuesMap;
      }

      function getPersonalAsync() {
        const deferred = $q.defer();

        if (personalConfigurationsDidLoad) {
          deferred.resolve(personalConfigurations);
        } else {
          personalConfigurationsResolver.push(deferred);
          // Already loading.
          if (personalConfigurationsResolver.length === 1) {
            Promise.all([Repository.Custom("Configurations").getPersonalConfigurations(), Repository.Custom("Configurations").getUserData()])
              .then(function ([result, userData]) {
                personalConfigurationsDidLoad = true;
                Object.assign(personalConfigurations, result, {userData});
                while (personalConfigurationsResolver.length > 0) {
                  personalConfigurationsResolver.shift().resolve(result);
                }
              }).catch(function (err) {
              while (personalConfigurationsResolver.length > 0) {
                personalConfigurationsResolver.shift().reject(err);
              }
            })
          }
        }

        return deferred.promise;
      }

      function update() {
        return updateConfigurations(false);
      }

      function updateCashRegisterFavorite(favoriteItems, numberOfTiles, cashRegisterTableSideWidth) {
        const deferred = $q.defer();

        Repository.Custom("Configurations").cashRegisterFavorites(
          {
            Favorites: JSON.stringify(favoriteItems),
            NumberOfTiles: numberOfTiles,
            CashRegisterTableSideWidth: cashRegisterTableSideWidth
          })
          .then(function () {
            personalConfigurationsDidLoad = false;
            getPersonalAsync();
          })
          .catch(function (err) {
            deferred.reject(err);
          });


        return deferred.promise;
      }

      function defaultEmployee() {
        return Object.assign({}, defaultEmployeeObject);
      }

      function isValidFacebook() {
        return configurationValuesMap.PromotionsSettings.FacebookUserDetails &&
          configurationValuesMap.PromotionsSettings.FacebookUserDetails.FacebookId &&
          configurationValuesMap.PromotionsSettings.FacebookUserDetails.FacebookId.Value &&
          configurationValuesMap.PromotionsSettings.IsUsingFacebook;
      }

      function isValidWhatsappAccount() {
        if (isNonEmptyObject(_loggedInUser)) {
          return {
            then: (func) => func(isNonEmptyString(_loggedInUser.facebookProps?.whatsappPhoneId))
          }
        }

        return toReturn.loggedInUserAsPromise.then((res) => {
          return isNonEmptyString(res.facebookProps?.whatsappPhoneId);
        })
      }

      function setWhatsappToken(token) {
        return Repository.Custom('Configurations').updateFacebookConnect({token});
      }

      function clearWhatsappToken() {
        return Repository.Custom('Configurations').clearFacebookConnect();
      }

      function loadBotConfigurations() {
        return Repository.Custom('Configurations').loadBotConfigurations();
      }

      /**
       * This loads both global + personal configurations from cache and uses it as default.
       * If the configurations are not available, it will reload the browser.
       * If the language has changed it will reload the browser.
       */
      function handleConfigurationsFirstLoading() {
        const cachedGlobalConfig = configurationValuesMap = $.localStorage.isSet(k_configurationsKey) ? $.localStorage.get(k_configurationsKey) : null;
        const cachedPersonalConfig = personalConfigurations = $.localStorage.isSet(k_personalConfigurationsKey) ? $.localStorage.get(k_personalConfigurationsKey) : null;
        let savedConfigurations = {
          globalConfig: getSafely(['GeneralSettings'], cachedGlobalConfig) ? cachedGlobalConfig : null,
          personalConfig: cachedPersonalConfig
        }

        const loadingPromises = [updateConfigurations, loadValidateLoggedInUser];
        let shouldReload = false;
        if (Object.values(savedConfigurations).some((val) => !val)) {
          shouldReload = true;
          $.localStorage.removeAll();
        } else {
          updateClientLanguage(savedConfigurations.globalConfig, savedConfigurations.personalConfig);
        }

        Promise.all(loadingPromises.map((delegate) => delegate())).then((res) => {
          if (shouldReload) {
            return reloadBrowser();
          }

          const [globalConfig, personalConfig] = res;
          updateClientLanguage(globalConfig, personalConfig);
        }).catch(function (err) {
          console.log('error loading configurations.');
          wasConfigurationsLoaded = true;
          wasConfigurationsLoaded = "...תקלה בטעינת הגדרות";
        })
      }

      function updateClientLanguage(globalConfig, personalConfig) {
        let language = globalConfig.GeneralSettings.Language.Value;
        let currency = globalConfig.CashRegisterSettings.Currency.Value;
        let personalLanguage = getSafely(['personalConfigurations', 'language'], personalConfig);
        if (isNonEmptyString(personalLanguage)) {
          language = personalLanguage === 'english' ? 'en-US' : 'he-IL';
        }

        if (isNonEmptyString(currentLanguage) && language !== currentLanguage) {
          setTimeout(reloadBrowser)
        }

        currentLanguage = language;
        localize.setLanguage(language);
        document.querySelector('html').setAttribute('lang', language.replace(/-.*/, ''));
        window.k_Currency = currency;
      }
    }]);
