import './add.expense.modal.less';
import { DATE_DISPLAY_FORMAT, ODATA_DATE_TIME_FORMAT, SERVER_DATA_DATE_FORMAT } from "../../constants";

angular.module('easybizy.easybizyModalViewsModel').controller('AddExpenseModalController',
  function ($scope, $modalInstance, saveAction, $timeout, $http, localize, Repository, parameters) {

    var expenseCategoryField = {
      fieldName: 'Category', type: 'combo',
      placeholder: localize.getLocalizedString('_Category_'),
      values: [],
      allowNewCategory: true,
      addNewCategoryTitle: '_AddExpenseCategory_',
      addNewCategoryDelegate: function (category) {
      }
    };

    var recurringExpenseField = {
      fieldName: 'Recurrence', type: 'combo',
      disabled: function () {
        return $scope.selectedExpenseMode.value.value !== 'recurring';
      },
      placeholder: localize.getLocalizedString('_RecurringEvery_'),
      values: [
        { name: localize.getLocalizedString("_Daily_"), value: 'RRULE:FREQ=DAILY' },
        { name: localize.getLocalizedString("_Weekly_"), value: 'RRULE:FREQ=WEEKLY' },
        { name: localize.getLocalizedString("_Every2Weeks_"), value: 'RRULE:FREQ=WEEKLY;INTERVAL=2' },
        { name: localize.getLocalizedString("_Monthly_"), value: 'RRULE:FREQ=MONTHLY' },
        { name: localize.getLocalizedString("_Quarterly_"), value: 'RRULE:FREQ=MONTHLY;INTERVAL=3' },
        { name: localize.getLocalizedString("_HalfYearly_"), value: 'RRULE:FREQ=MONTHLY;INTERVAL=6' }
      ]
    };

    var vatPortionField = {
      fieldName: 'VatPortion', type: 'combo',
      disabled: function () {
        return !$scope.expense.VatRespected.value.value;
      },
      placeholder: localize.getLocalizedString('_RelativeVatPortion_'),
      values: [
        { name: '100%', value: 1 },
        { name: '10%', value: 0.1 },
        { name: '25%', value: 0.25 },
        { name: '33% (' + localize.getLocalizedString('_Third_') + ')', value: 0.33 },
        { name: '50%', value: 0.5 },
        { name: '66%', value: 0.66 },
        { name: '75%', value: 0.75 }
      ]
    };


    $scope.expenseModes = [
      { name: localize.getLocalizedString("_NotRepeating_"), value: null },
      { name: localize.getLocalizedString("_Payments_"), value: 'payments' },
      { name: localize.getLocalizedString("_Recurring_"), value: 'recurring' }
    ];

    $scope.selectedExpenseMode = { value: $scope.expenseModes[0] };

    var k_originalStartedOnLocalized = localize.getLocalizedString('_OriginalStartedOn_');
    var recurringEndsOn = {
      fieldName: 'EndsOn',
      icon: 'icon icon-reset',
      type: 'date',
      checkboxLabel: true,
      placeholder: localize.getLocalizedString('_RecurringExpenseEnds_'),
      validation: "{'required': true, 'date': true}",
      yearRange: "-2:+5",
      getNote: function () {
        return $scope.expense.OriginalEffectivePaymentDate ? k_originalStartedOnLocalized + ': ' + moment($scope.expense.OriginalEffectivePaymentDate, ODATA_DATE_TIME_FORMAT).format(DATE_DISPLAY_FORMAT) : null;
      },
      disabled: function () {
        return $scope.selectedExpenseMode.value.value !== 'recurring';
      }
    };

    var numOfPaymentsField = {
      fieldName: 'NumberOfPayments',
      placeholder: localize.getLocalizedString("_NumberOfPayments_"),
      type: 'number',
      min: 2,
      max: 36,
      disabled: function () {
        return $scope.selectedExpenseMode.value.value !== 'payments';
      }
    };

    var vatRespectedField = {
      fieldName: 'VatRespected',
      icon: "icon icon-banknote-in",
      placeholder: localize.getLocalizedString("_VatRespected_"),
      type: 'radio',
      values: [
        { name: localize.getLocalizedString("_Yes_"), value: true },
        { name: localize.getLocalizedString("_No_"), value: false }
      ]
    };

    $scope.fields = [
      {
        fieldName: 'ExpenseTitle',
        icon: "icon icon-invoice",
        type: 'text',
        placeholder: localize.getLocalizedString('_ExpenseTitle_'),
        validation: "{'required': true, 'max-length': 50}"
      },
      {
        fieldName: 'Amount',
        icon: "icon icon-banknote-out",
        type: 'text',
        placeholder: localize.getLocalizedString('_Amount_'),
        validation: "{'required': true, 'decimal': true}"
      },
      {
        fieldName: 'InvoiceNumber',
        icon: "icon icon-barcode",
        type: 'text',
        placeholder: localize.getLocalizedString('_InvoiceNumber_'),
        validation: "{'required': false}"
      },
      vatRespectedField,
      vatPortionField,
      {
        fieldName: 'EffectivePaymentDate',
        icon: "icon icon-calendar",
        placeholder: localize.getLocalizedString("_EffectivePaymentDate_"),
        validation: "{'date': true}",
        yearRange: "-5:+5",
        type: 'date'
      },
      expenseCategoryField,
      {
        fieldName: 'Importance',
        icon: 'icon icon-important',
        placeholder: localize.getLocalizedString("_Importance_"),
        type: 'rating'
      },
      numOfPaymentsField,
      recurringExpenseField,
      recurringEndsOn
    ];


    $scope.expenseFieldsValidation = {};
    $scope.expenseFieldsToValidate = {}; // just initialize fields to validate.
    $scope.expenseValidateActionWrapper = {}; // this is an object to wrap an action, so the form validator can

    function loadExpensesCategories() {
      Repository.Entity('ExpensesCategory').query().get().then(function (result) {
        result.results.forEach(function (category) {
          expenseCategoryField.values.push({ name: category.ExpensesCategoryName, value: category.ExpensesCategoryId });
        });

        $scope.expense.Category = expenseCategoryField.values[0];

        if (parameters.expense) {
          for (var expenseCategoryIdx = 0; expenseCategoryIdx < expenseCategoryField.values.length; expenseCategoryIdx++) {
            var category = expenseCategoryField.values[expenseCategoryIdx];
            if (category.value === parameters.expense.ExpensesCategoryId) {
              $scope.expense.Category = category;
            }
          }
        }

      }).catch(function (err) {
        toastr.error(localize.getLocalizedString('_ErrorGettingCategories_'));
      });
    }

    function setExpensePortion() {
      if (parameters.expense) {
        for (var vatPortionIdx = 0; vatPortionIdx < vatPortionField.values.length; vatPortionIdx++) {
          var portion = vatPortionField.values[vatPortionIdx];
          if (portion.value == parameters.expense.VatPortion) {
            $scope.expense.VatPortion = portion;
          }
        }
      } else {
        $scope.expense.VatPortion = vatPortionField.values[0];
      }
    }

    // $scope.$watch('expense', (newVal) => console.log(newVal), true)

    $scope.expense = {
      Recurrence: recurringExpenseField.values[3],
      EffectivePaymentDate: moment().format(DATE_DISPLAY_FORMAT),
      Importance: 2.5,
      NumberOfPayments: (parameters.expense && parameters.expense.NumberOfPayments) || 2,
      VatRespected: { value: vatRespectedField.values[0] },
      //VatPortion: { value: (parameters.expense ?  vatPortionField.values[0] : vatPortionField.values[0]) }
    };//parameters.expense.VatPortion

    if (angular.isDefined(parameters.expense)) {
      useExpense(parameters.expense);
    }


    loadExpensesCategories();

    setExpensePortion();

    // $scope.$watch('expense.Recurrence.value', function (newVal) {
    //   if (newVal) {
    //     recurringEndsOn.disabled = false;
    //     var currentEffectiveTime = moment($scope.expense.EffectivePaymentDate, dateDisplayFormat);
    //     if (!$scope.expense.EndsOn) {
    //       $scope.expense.EndsOn = currentEffectiveTime.add(1, 'year').format(dateDisplayFormat);
    //     } else {
    //       var currentEndsOn = moment($scope.expense.EndsOn, dateDisplayFormat);
    //       if (currentEndsOn.isBefore(currentEffectiveTime)) {
    //         $scope.expense.EndsOn = currentEffectiveTime.add(1, 'year').format(dateDisplayFormat);
    //       }
    //     }
    //
    //   } else {
    //     recurringEndsOn.disabled = true;
    //     delete $scope.expense.EndsOn;
    //   }
    // });


    $scope.save = function () {
      var validationFields = $scope.expenseValidateActionWrapper.validate();
      if (validationFields.length != 0) { // is valid form?

        var wrongFieldName =  $scope.fields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
        var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
        toastr.error(localizedError);
        return;
      }

      $scope.isSaving = true;

      var newOrUpdatedExpense = Object.assign({}, $scope.expense);
      delete newOrUpdatedExpense.ExpensesCategory;

      if ($scope.expense.Category.value > 0) {
        newOrUpdatedExpense.ExpensesCategoryId = $scope.expense.Category.value;
      } else {
        newOrUpdatedExpense.ExpensesCategory = { ExpensesCategoryName: $scope.expense.Category.name, State: 'Added' };
      }

      delete newOrUpdatedExpense.Recurrence;
      delete newOrUpdatedExpense.EndsOn;
      delete newOrUpdatedExpense.NumberOfPayments;
      delete newOrUpdatedExpense.VatRespected;
      delete newOrUpdatedExpense.VatPortion;


      newOrUpdatedExpense.VatRespected = $scope.expense.VatRespected.value.value;
      if (newOrUpdatedExpense.VatRespected) {
        newOrUpdatedExpense.VatPortion = $scope.expense.VatPortion.value;
      }

      var expenseMode = $scope.selectedExpenseMode.value && $scope.selectedExpenseMode.value.value;
      if (expenseMode === 'payments') {
        newOrUpdatedExpense.NumberOfPayments = $scope.expense.NumberOfPayments;
      } else if (expenseMode === 'recurring') {
        newOrUpdatedExpense.Recurrence = $scope.expense.Recurrence.value;
        if (recurringEndsOn.checkboxValue) {
          newOrUpdatedExpense.EndsOn = $scope.expense.EndsOn;
        }
      }

      newOrUpdatedExpense.Importance = newOrUpdatedExpense.Importance.toString();
      delete newOrUpdatedExpense.Category;
      delete newOrUpdatedExpense.dirty;
      delete newOrUpdatedExpense.OriginalEffectivePaymentDate;

      saveAction(newOrUpdatedExpense, !parameters.duplicate && parameters.expense)
        .then(function () {
          toastr.success(localize.getLocalizedString("_EntitySavedSuccessfully_"));
          $modalInstance.close();
        })
        .catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorSavingEntity_", err.message));
          $scope.isSaving = false;
        });
    };

    function useExpense(iExpense) {
      $scope.expense = Object.assign({}, iExpense);
      $scope.expense.EffectivePaymentDate = moment($scope.expense.EffectivePaymentDate, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT);
      $scope.expense.VatRespected = { value: vatRespectedField.values[iExpense.VatRespected ? 0 : 1] };

      // Set default recurring
      if ($scope.expense.Recurrence) {
        var splittedRecurrence = $scope.expense.Recurrence.split(';UNTIL=');
        $scope.expense.Recurrence = splittedRecurrence[0];
        if (splittedRecurrence.length > 1) {
          $scope.expense.EndsOn = (moment(splittedRecurrence[1]) || moment().add(1, 'year')).format(DATE_DISPLAY_FORMAT);
          recurringEndsOn.checkboxValue = true;
        } else {
          recurringEndsOn.checkboxValue = false;
        }

        for (var recurrenceValueIdx = 0; recurrenceValueIdx < recurringExpenseField.values.length; recurrenceValueIdx++) {
          var recurrenceValue = recurringExpenseField.values[recurrenceValueIdx];
          if ($scope.expense.Recurrence === recurrenceValue.value) {
            $scope.expense.Recurrence = recurrenceValue;
            break;
          }
        }

        $scope.selectedExpenseMode = { value: $scope.expenseModes[2] };
      } else if ($scope.NumberOfPayments > 1) {
        $scope.selectedExpenseMode = { value: $scope.expenseModes[1] };
      }

      if (parameters.duplicate) {
        delete $scope.expense.ExpenseId;
        $scope.expense.EffectivePaymentDate = moment().format(DATE_DISPLAY_FORMAT);
      }

      delete $scope.expense.State;
      delete $scope.expense.LastUpdated;
      delete $scope.expense.EntityType;


    }

    $scope.cancel = function () {
      $modalInstance.close();
    };
  });
