import Moment from 'moment';

angular.module('easybizy.common.data.filter').factory('dataFilterStructures', function (localize, Repository, configuration) {
  const configurations = configuration.get();
  var ageOptions = [
    { name: localize.getLocalizedString("_All_"), value: "-1", defaultValue: "true" },
    { name: localize.getLocalizedString("_Under 20_"), value: "<20" },
    { name: "20 - 25", value: ">20 && <25" },
    { name: "25 - 30", value: ">25 && <30" },
    { name: "30 - 40", value: ">30 && <40" },
    { name: "40 - 50", value: ">40 && <50" },
    { name: localize.getLocalizedString("_Above 50_"), value: ">50" }
  ];

  var genderOptions = [
    { name: localize.getLocalizedString("_All_"), value: "-1", defaultValue: "true" },
    { name: localize.getLocalizedString("_Male_"), value: "Male" },
    { name: localize.getLocalizedString("_Female_"), value: "Female" }
  ];

  var tagOptions = [{ name: localize.getLocalizedString("_None_"), value: "-1", defaultValue: "true" }];
  var priceListOptions = [{
    name: localize.getLocalizedString("_None_"),
    value: "-1",
    defaultValue: "true"
  }, { name: localize.getLocalizedString("_NoPriceList_"), value: "0" }];
  var formOptions = [{ name: localize.getLocalizedString("_None_"), value: "-1", defaultValue: "true" }];
  var employeeOptions = [{ name: localize.getLocalizedString("_None_"), value: "-1", defaultValue: "true" }];

  Repository.Custom("EntitiesLazyRepository").tags('Customer').get()
    .then(function (result) {
      result.forEach(function (tag) {
        tagOptions.push({ name: tag.name, value: tag.value });
      });
    })
    .catch(function (e) {
      console.log("couldn't get tags..");
    });

  Repository.Entity('PriceList').query().get()
    .then((data) => {
      priceListOptions.pushAll(data.results.map((x) => {
        return { value: x.PriceListId, name: x.PriceListName }
      }));
    }).catch((err) => {
      console.log("couldn't get priceLists..");
    });


  Repository.Custom("EntitiesLazyRepository").employees().get()
    .then(function (result) {
      result.forEach(function (employee) {
        employeeOptions.push({ name: employee.name, value: employee.value });
      });
    })
    .catch(function (e) {
      console.log("couldn't get tags..");
    });

  Repository.Custom('Forms').getForms().then((forms) => {
    forms.forEach(function (form) {
      formOptions.push({ name: form.name, value: form._id });
    });
  }).catch(e => console.log("couldn't get forms.."));


  var membershipOptions = [{ name: localize.getLocalizedString("_None_"), value: "-1", defaultValue: "true" }];

  Repository.Custom("EntitiesLazyRepository").memberships().get()
    .then(function (result) {
      result.forEach(function (membership) {
        membershipOptions.push({ name: membership.name, value: membership.value });
      });
    })
    .catch(function (e) {
      console.log("couldn't get membership..");
    });

  var arrivalsourceOptions = [{ name: localize.getLocalizedString("_None_"), value: "-1", defaultValue: "true" }];

  Repository.Custom("EntitiesLazyRepository").arrivalsources().get()
    .then(function (result) {
      result.forEach(function (arrivalsource) {
        arrivalsourceOptions.push({ name: arrivalsource.name, value: arrivalsource.value });
      });
    })
    .catch(function (e) {
      console.log("couldn't get arrivalsource..");
    });

  var productOptions = [
    { name: localize.getLocalizedString("_None_"), value: "-1", defaultValue: "true" },
    { name: localize.getLocalizedString("_AllProducts_"), value: "0"}
  ];

  Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory(true).entity("product").get()
    .then(function (result) {
      result.forEach(function (product) {
        productOptions.push(product);
      });
    })
    .catch(function (e) {
      toastr.error(e.Message);
      console.log("couldn't get product and Services..");
    });

  var serviceOptions = [
    { name: localize.getLocalizedString("_None_"), value: "-1", defaultValue: "true" },
    { name: localize.getLocalizedString("_AllServices_"), value: "0"}
  ];

  Repository.Custom("EntitiesLazyRepository").productOrServiceByCategory(true).entity("service").get()
    .then(function (result) {
      result.forEach(function (service) {
        serviceOptions.push(service);
      });
    })
    .catch(function (e) {
      console.log("couldn't get product and Services..");
    });

  var celebratingOptions = [
    { name: localize.getLocalizedString("_None_"), value: "-1", defaultValue: "true" },
    { name: localize.getLocalizedString("_Birthday_"), value: "Birthday" }
  ];

  var futureActionTimingOptions = [
    { name: localize.getLocalizedString("_Whenever_"), value: "-1", defaultValue: "true" },
    { name: localize.getLocalizedString("_Today_"), value: "Today", possibleRepeat: 'Daily' },
    { name: localize.getLocalizedString("_Tomorrow_"), value: "Tomorrow", possibleRepeat: 'Daily' },
    { name: localize.getLocalizedString("_This Week_"), value: "This Week", possibleRepeat: 'Weekly' },
    { name: localize.getLocalizedString("_Next Week_"), value: "Next Week", possibleRepeat: 'Weekly' },
    { name: localize.getLocalizedString("_This Month_"), value: "This Month", possibleRepeat: 'Monthly' },
    { name: localize.getLocalizedString("_LastMonth_"), value: "Last Month", possibleRepeat: 'Monthly' },
    { name: localize.getLocalizedString("_Next Month_"), value: "Next Month", possibleRepeat: 'Monthly' }
  ];

  var subscriptionOptions = [
    { name: localize.getLocalizedString("_None_"), value: "-1", defaultValue: "true" },
    { name: localize.getLocalizedString("_HoldsValidSubscription_"), value: "valid" },
    { name: localize.getLocalizedString("_HadSubscriptionButAllClosed_"), value: "hadButAllClosed" },
    { name: localize.getLocalizedString("_DontHaveSubscription_"), value: "dontHave" },
    { name: localize.getLocalizedString("_HoldsSubscriptionLastUsage_"), value: "lastUsage" },
    { name: localize.getLocalizedString("_HoldsSubscriptionLastUsageAllOver_"), value: "lastUsageAllOver" }
  ];

  if (configurations.ProductsAndServicesSettings.ConsiderSubscriptionsExpirationDate) {
    subscriptionOptions.push({ name: localize.getLocalizedString("_Expired_"), value: "expired" });
    subscriptionOptions.push({ name: localize.getLocalizedString("_ExpiresInLessThenAMonth_"), value: "expiresInLessThenAMonth" });
    subscriptionOptions.push({ name: localize.getLocalizedString("_ExpiredDuringTheLastMonth_"), value: "expiredDuringTheLastMonth" });
  }

  var meetingTimingOptions = [
    { name: localize.getLocalizedString("_Whenever_"), value: "-1", defaultValue: "true", Comperator: "Equals" },
    { name: localize.getLocalizedString("_NotSet_"), value: "Not Set", Comperator: "Equals" },
    { name: localize.getLocalizedString("_HasFutureMeeting_"), value: "Has Future Meeting", Comperator: "Equals" },
    { name: localize.getLocalizedString("_Just Set_"), value: "-1", possibleRepeat: 'Always', Comperator: "Equals" },
    { name: localize.getLocalizedString("_NextHour_"), value: "1", possibleRepeat: 'EveryHour', Comperator: "Equals" },
    {
      name: localize.getLocalizedString("_In Two Hours_"),
      availableAsAutoGeneratedPromotion: true,
      value: "2",
      possibleRepeat: 'Every2Hours',
      Comperator: "Equals",
      nameRelativeToMeeting: localize.getLocalizedString('_TwoHoursBeforeTheMeeting_')
    },
    {
      name: localize.getLocalizedString("_Today_"), value: "Today", possibleRepeat: 'Daily', Comperator: "Equals",
      availableAsAutoGeneratedPromotion: true,
      relativeStartTime: Moment().startOf('day').add(12, 'hours').toDate(),
      nameRelativeToMeeting: localize.getLocalizedString('_OnAppointmentDate_')
    },
    {
      name: localize.getLocalizedString("_Tomorrow_"),
      value: "Tomorrow",
      possibleRepeat: 'Daily',
      Comperator: "Equals", availableAsAutoGeneratedPromotion: true,
      relativeStartTime: Moment().startOf('day').add(12, 'hours').toDate(),
      nameRelativeToMeeting: localize.getLocalizedString('_AppointmentIsTomorrow_')
    },
    {
      name: localize.getLocalizedString("_DayAfterTomorrow_"),
      value: "Day After Tomorrow",
      possibleRepeat: 'Daily',
      Comperator: "Equals", availableAsAutoGeneratedPromotion: true,
      relativeStartTime: Moment().startOf('day').add(12, 'hours').toDate(),
      nameRelativeToMeeting: localize.getLocalizedString('_AppointmentIsDayAfterTomorrow_')
    },
    {
      name: localize.getLocalizedString("_InThreeDays_"),
      value: "In 3 Days",
      possibleRepeat: 'Daily',
      Comperator: "Equals", availableAsAutoGeneratedPromotion: true,
      relativeStartTime: Moment().startOf('day').add(12, 'hours').toDate(),
      nameRelativeToMeeting: localize.getLocalizedString('_3DaysBeforeAppointmentDate_')
    },
    {
      name: localize.getLocalizedString("_InFourDays_"),
      value: "In 4 Days",
      possibleRepeat: 'Daily',
      Comperator: "Equals", availableAsAutoGeneratedPromotion: true,
      relativeStartTime: Moment().startOf('day').add(12, 'hours').toDate(),
      nameRelativeToMeeting: localize.getLocalizedString('_4DaysBeforeAppointmentDate_')
    },
    {
      name: localize.getLocalizedString("_InFiveDays_"),
      value: "In 5 Days",
      possibleRepeat: 'Daily',
      Comperator: "Equals", availableAsAutoGeneratedPromotion: true,
      relativeStartTime: Moment().startOf('day').add(12, 'hours').toDate(),
      nameRelativeToMeeting: localize.getLocalizedString('_5DaysBeforeAppointmentDate_')
    },
    {
      name: localize.getLocalizedString("_InSixDays_"),
      value: "In 6 Days",
      possibleRepeat: 'Daily',
      Comperator: "Equals", availableAsAutoGeneratedPromotion: true,
      relativeStartTime: Moment().startOf('day').add(12, 'hours').toDate(),
      nameRelativeToMeeting: localize.getLocalizedString('_6DaysBeforeAppointmentDate_')
    },
    {
      name: localize.getLocalizedString("_InSevenDays_"),
      value: "In 7 Days",
      possibleRepeat: 'Daily',
      Comperator: "Equals", availableAsAutoGeneratedPromotion: true,
      relativeStartTime: Moment().startOf('day').add(12, 'hours').toDate(),
      nameRelativeToMeeting: localize.getLocalizedString('_7DaysBeforeAppointmentDate_')
    },
    {
      name: localize.getLocalizedString("_This Week_"),
      value: "This Week",
      possibleRepeat: 'Weekly',
      Comperator: "Equals", availableAsAutoGeneratedPromotion: true,
      relativeStartTime: Moment().startOf('week').add(1, 'week').add(12, 'hours').toDate(),
      nameRelativeToMeeting: localize.getLocalizedString('_AppointmentIsThisWeek_')

    },
    {
      name: localize.getLocalizedString("_This Month_"),
      value: "This Month",
      possibleRepeat: 'Monthly',
      Comperator: "Equals", availableAsAutoGeneratedPromotion: true,
      relativeStartTime: Moment().startOf('month').add(1, 'month').add(12, 'hours').toDate(),
      nameRelativeToMeeting: localize.getLocalizedString('_AppointmentIsThisMonth_')
    },
    {
      name: localize.getLocalizedString("_Next Week_"),
      value: "Next Week",
      possibleRepeat: 'Weekly',
      Comperator: "Equals", availableAsAutoGeneratedPromotion: true,
      relativeStartTime: Moment().startOf('week').add(1, 'week').add(12, 'hours').toDate(),
      nameRelativeToMeeting: localize.getLocalizedString('_AppointmentIsNextWeek_')
    },
    {
      name: localize.getLocalizedString("_Next Month_"),
      value: "Next Month",
      possibleRepeat: 'Monthly',
      Comperator: "Equals", availableAsAutoGeneratedPromotion: true,
      relativeStartTime: Moment().endOf('month').startOf('day').add(12, 'hours').toDate(),
      nameRelativeToMeeting: localize.getLocalizedString('_AppointmentIsNextMonth_')
    },
    {
      name: localize.getLocalizedString("_EndedAnHourAgo_"),
      value: "hour ago",
      possibleRepeat: 'EveryHour',
      Comperator: "Equals"
    },
    {
      name: localize.getLocalizedString("_Yesterday_"),
      value: "yesterday",
      possibleRepeat: 'Daily',
      Comperator: "Equals"
    }
  ];

  var intervalComperatorOptions = [
    { name: localize.getLocalizedString("_Whenever_"), value: "-1", defaultValue: "true", Comperator: "Equals" },
    {
      name: localize.getLocalizedString("_ExactlyBefore_"),
      value: "Equals",
      possibleRepeat: 'Force_Daily',
      Comperator: "Equals"
    },
    {
      name: localize.getLocalizedString("_BeforeLessThen_"),
      value: "GreaterThan",
      Comperator: "GreaterThan"
    },
    {
      name: localize.getLocalizedString("_BeforeMoreThen_"),
      value: "LessThan",
      possibleRepeat: 'Disabled',
      Comperator: "LessThan"
    }
  ];

  var intervalCountTimingOptions = [
    { name: localize.getLocalizedString("_Whenever_"), value: "-1", defaultValue: "true", Comperator: "Equals" },
    { name: 1, value: "1", Comperator: "Equals" },
    { name: 2, value: "2", Comperator: "Equals" },
    { name: 3, value: "3", Comperator: "Equals" },
    { name: 4, value: "4", Comperator: "Equals" },
    { name: 5, value: "5", Comperator: "Equals" },
    { name: 6, value: "6", Comperator: "Equals" },
    { name: 7, value: "7", Comperator: "Equals" },
    { name: 8, value: "8", Comperator: "Equals" },
    { name: 9, value: "9", Comperator: "Equals" },
    { name: 10, value: "10", Comperator: "Equals" }
  ];

  var intervalNameTimingOptions = [
    { name: localize.getLocalizedString("_Whenever_"), value: "-1", defaultValue: "true", Comperator: "Equals" },
    { name: localize.getLocalizedString("_Hours_"), value: "hour", possibleRepeat: 'ForceHard_EveryHour', Comperator: "Equals" },
    { name: localize.getLocalizedString("_Days_"), value: "day", possibleRepeat: 'Daily', Comperator: "Equals" },
    { name: localize.getLocalizedString("_Weeks_"), value: "week", possibleRepeat: 'Weekly', Comperator: "Equals" },
    { name: localize.getLocalizedString("_Months_"), value: "month", possibleRepeat: 'Monthly', Comperator: "Equals" },
    { name: localize.getLocalizedString("_Years_"), value: "year", possibleRepeat: 'Yearly', Comperator: "Equals" }
  ];


  var lastTimeOptions = [
    {
      name: localize.getLocalizedString("_Whenever_"),
      value: "-1",
      defaultValue: "true",
      Comperator: "LessThanOrEquals"
    },
    {
      name: localize.getLocalizedString("_Today_"),
      value: "today",
      possibleRepeat: 'Daily',
      Comperator: "Equals"
    },
    {
      name: localize.getLocalizedString("_Yesterday_"),
      value: "yesterday",
      possibleRepeat: 'Daily',
      Comperator: "Equals"
    },
    {
      name: localize.getLocalizedString("_The past week_"),
      value: "week",
      possibleRepeat: 'Weekly',
      Comperator: "GreaterThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_The past month_"),
      value: "month",
      possibleRepeat: 'Monthly',
      Comperator: "GreaterThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_The past 2 months_"),
      value: "two months",
      possibleRepeat: 'Monthly',
      Comperator: "GreaterThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_The past 3 months_"),
      value: "three months",
      possibleRepeat: 'Monthly',
      Comperator: "GreaterThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_The past 4 months_"),
      value: "four months",
      possibleRepeat: 'Monthly',
      Comperator: "GreaterThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_The past 5 months_"),
      value: "five months",
      possibleRepeat: 'Monthly',
      Comperator: "GreaterThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_The past 6 months_"),
      value: "six months",
      possibleRepeat: 'Every3Months',
      Comperator: "GreaterThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_The past year_"),
      value: "year",
      possibleRepeat: 'Yearly',
      Comperator: "GreaterThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_The past 2 years_"),
      value: "2 years",
      possibleRepeat: 'Yearly',
      Comperator: "GreaterThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_The past 3 years_"),
      value: "3 years",
      possibleRepeat: 'Yearly',
      Comperator: "GreaterThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_The past 4 years_"),
      value: "4 years",
      possibleRepeat: 'Yearly',
      Comperator: "GreaterThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_More then a day ago_"),
      value: "day",
      possibleRepeat: 'Daily',
      Comperator: "LessThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_More then a week ago_"),
      value: "week",
      possibleRepeat: 'Weekly',
      Comperator: "LessThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_More then a month ago_"),
      value: "month",
      possibleRepeat: 'Monthly',
      Comperator: "LessThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_More then 2 months ago_"),
      value: "two months",
      possibleRepeat: 'Monthly',
      Comperator: "LessThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_More then 3 months ago_"),
      value: "three months",
      possibleRepeat: 'Monthly',
      Comperator: "LessThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_More then 4 months ago_"),
      value: "four months",
      possibleRepeat: 'Monthly',
      Comperator: "LessThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_More then 5 months ago_"),
      value: "five months",
      possibleRepeat: 'Monthly',
      Comperator: "LessThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_More then 6 months ago_"),
      value: "six months",
      possibleRepeat: 'Every3Months',
      Comperator: "LessThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_More then a year ago_"),
      value: "year",
      possibleRepeat: 'Yearly',
      Comperator: "LessThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_More then 2 years ago_"),
      value: "2 years",
      possibleRepeat: 'Yearly',
      Comperator: "LessThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_More then 3 years ago_"),
      value: "3 years",
      possibleRepeat: 'Yearly',
      Comperator: "LessThanOrEqual"
    },
    {
      name: localize.getLocalizedString("_More then 4 years ago_"),
      value: "4 years",
      possibleRepeat: 'Yearly',
      Comperator: "LessThanOrEqual"
    }
  ];

  var lessOrMoreOptions = [
    { name: localize.getLocalizedString("_None_"), value: "-1", defaultValue: "true" },
    { name: localize.getLocalizedString("_Equals_"), value: "Equals" },
    { name: localize.getLocalizedString("_Less than_"), value: "LessThan" },
    { name: localize.getLocalizedString("_More than_"), value: "GreaterThan" }
  ];

  var existOptions = [
    { name: localize.getLocalizedString("_Exist_"), value: "Equals", defaultValue: "true" },
    { name: localize.getLocalizedString("_NotExist_"), value: "NotEquals" }
  ];

  var visitValuesOptions = [
    { name: localize.getLocalizedString("_None_"), value: "-1", defaultValue: "true" },
    { name: "0", value: "0" },
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    //{ name: "5-10", value: "5-10" },
    { name: "10", value: "10" }
  ];

  var averageInvoiceOptions = [
    { name: localize.getLocalizedString("_None_"), value: "-1", defaultValue: "true" },
    { name: "50", value: "50" },
    { name: "75", value: "75" },
    { name: "100", value: "100" },
    { name: "150", value: "150" },
    { name: "50-100", value: "50-100" },
    { name: "100-150", value: "100-150" },
    { name: "150-200", value: "150-200" },
    { name: "200-300", value: "200-300" },
    { name: "300-400", value: "300-400" },
    { name: "400-500", value: "400-500" },
    { name: "500-750", value: "500-750" },
    { name: "750-1000", value: "750-1000" },
    { name: localize.getLocalizedString("_AverageInvoice_"), value: "avg" }
  ];

  var pointsOptions = [
    { name: localize.getLocalizedString("_None_"), value: "-1", defaultValue: "true" },
    { name: localize.getLocalizedString("_Exist_"), value: "0" },
    { name: localize.getLocalizedString("_GreaterThan_", "50"), value: "50" },
    { name: localize.getLocalizedString("_GreaterThan_", "100"), value: "100" },
    { name: localize.getLocalizedString("_GreaterThan_", "150"), value: "150" },
    { name: localize.getLocalizedString("_GreaterThan_", "200"), value: "200" },
    { name: localize.getLocalizedString("_GreaterThan_", "250"), value: "250" },
    { name: localize.getLocalizedString("_GreaterThan_", "300"), value: "300" },
    { name: localize.getLocalizedString("_GreaterThan_", "400"), value: "400" },
    { name: localize.getLocalizedString("_GreaterThan_", "500"), value: "500" }
  ];

  var lastNameOptions = [
    {
      name: localize.getLocalizedString("_Alphabetically_"),
      value: null, defaultValue: "true"
    },
    {
      name: localize.getLocalizedString("_A-D_"),
      value: localize.getLocalizedString("_A-D_Dictionary_")
    },
    {
      name: localize.getLocalizedString("_E-J_"),
      value: localize.getLocalizedString("_E-J_Dictionary_")
    },
    {
      name: localize.getLocalizedString("_K-N_"),
      value: localize.getLocalizedString("_K-N_Dictionary_")
    },
    {
      name: localize.getLocalizedString("_O-S_"),
      value: localize.getLocalizedString("_O-S_Dictionary_")
    },
    {
      name: localize.getLocalizedString("_T-W_"),
      value: localize.getLocalizedString("_T-W_Dictionary_")
    },
    {
      name: localize.getLocalizedString("_X-Z_"),
      value: localize.getLocalizedString("_X-Z_Dictionary_")
    }
  ];

  var dataFilters = {
    Age: { type: 'Age', value: null, values: [], options: ageOptions, affectsSchedule: false },
    Gender: { type: 'Gender', value: null, values: [], options: genderOptions, affectsSchedule: false },
    ProductOptions: { type: 'Product', value: null, values: [], options: productOptions, affectsSchedule: false },
    ServiceOptions: { type: 'Service', value: null, values: [], options: serviceOptions, affectsSchedule: false },
    Celebrating: { type: 'Celebrating', value: null, values: [], options: celebratingOptions, affectsSchedule: false },
    FutureTimingOption: {
      type: 'FutureTimingOption',
      values: [],
      value: null,
      options: futureActionTimingOptions,
      affectsSchedule: true
    },
    MeetingTimingOption: {
      type: 'MeetingTimingOption',
      values: [],
      value: null,
      options: meetingTimingOptions,
      affectsSchedule: true
    },
    SubscriptionOption: {
      type: 'SubscriptionOption',
      values: [],
      value: null,
      options: subscriptionOptions,
      affectsSchedule: false
    },
    LastTimeOption: {
      type: 'LastTimeOption',
      value: null,
      values: [],
      options: lastTimeOptions,
      affectsSchedule: true
    },
    NumOfVisits: { type: 'NumOfVisits', value: null, values: [], options: visitValuesOptions, affectsSchedule: false },
    IntervalCountTimingOptions: {
      type: 'IntervalCountTimingOptions',
      value: null,
      values: [],
      options: intervalCountTimingOptions,
      affectsSchedule: false
    },
    IntervalNameTimingOptions: {
      type: 'IntervalNameTimingOptions',
      value: null,
      values: [],
      options: intervalNameTimingOptions,
      affectsSchedule: true
    },
    IntervalComperatorOptions: {
      type: 'IntervalComperatorOptions',
      value: null,
      values: [],
      options: intervalComperatorOptions,
      affectsSchedule: true
    },
    LessOrMoreOption: {
      type: 'LessOrMoreOption',
      values: [],
      value: null,
      options: lessOrMoreOptions,
      affectsSchedule: false
    },
    ExistOption: {
      type: 'ExistOption',
      values: [],
      value: null,
      options: existOptions,
      affectsSchedule: false
    },
    AverageInvoiceOption: {
      type: 'AverageInvoiceOption',
      values: [],
      value: null,
      options: averageInvoiceOptions,
      affectsSchedule: false
    },
    PointsOption: {
      type: 'PointsOption',
      values: [],
      value: null,
      options: pointsOptions,
      affectsSchedule: false
    },
    Form: { type: 'Form', value: null, values: [], options: formOptions, affectsSchedule: false },
    RelatedToEmployee: {
      type: 'RelatedToEmployee',
      value: null,
      values: [],
      options: employeeOptions,
      affectsSchedule: false
    },
    Tag: { type: 'Tag', value: null, values: [], options: tagOptions, affectsSchedule: false },
    NotTag: { type: 'NotTag', value: null, values: [], options: tagOptions, affectsSchedule: false },
    Membership: { type: 'Membership', value: null, values: [], options: membershipOptions, affectsSchedule: false },
    PriceList: { type: 'PriceList', value: null, values: [], options: priceListOptions, affectsSchedule: false },
    ArrivalSource: {
      type: 'ArrivalSource',
      value: null,
      values: [],
      options: arrivalsourceOptions,
      affectsSchedule: false
    },
    LastName: { type: 'LastName', value: null, values: [], options: lastNameOptions, affectsSchedule: false },
    CreatedOn: { type: 'CreatedOn', value: null, values: [], options: lastTimeOptions, affectsSchedule: false }
  };

  return new function () {
    var that = this;
    this.get = function (filter) {
      return $.extend(true, {}, dataFilters[filter]);
    };

    this.getPossibleValues = function (name) {
      return that.get(name).options;
    };

    this.getAll = function () {
      var toReturn = [];
      angular.forEach(dataFilters, function (filter) {
        toReturn.push($.extend(true, {}, filter));
      });

      return toReturn;

    };
  };
});

angular.module('easybizy.common.data.filter').factory('dataFilterResolver', function (dataFilterStructures, localize) {
  var dataFilters = {
    Age: function () {
      this.filterType = "Age";
      this.text = localize.getLocalizedString("_Age_") + ' [Age]';
      this.generateFields = function (enable) {
        this.fields = {};
        var ageField = dataFilterStructures.get('Age');
        if (enable) {
          ageField.value = ageField.options[1];
        } else {
          ageField.value = ageField.options[0];
        }

        this.fields.Age = ageField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: "Age",
          FilterComperator: "Range",
          Value: this.fields.Age.value.value
        };
      };

      this.set = function (filterValue) {
        this.fields.Age.value = getSelectedValue(filterValue, this.fields.Age.options);
      };
    },
    Gender: function () {
      this.filterType = "Gender";
      this.text = localize.getLocalizedString("_Gender_") + ' [Gender]';
      this.generateFields = function (enable) {
        this.fields = {};
        var genderField = dataFilterStructures.get('Gender');
        if (enable) {
          genderField.value = genderField.options[1];
        } else {
          genderField.value = genderField.options[0];
        }

        this.fields.Gender = genderField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: "Gender",
          FilterComperator: "Equals",
          Value: this.fields.Gender.value.value
        };
      };

      this.set = function (filterValue) {
        this.fields.Gender.value = getSelectedValue(filterValue, this.fields.Gender.options);
      };
    },
    LastVisit: function () {
      this.filterType = "LastVisit";
      this.text = localize.getLocalizedString("_Last visit was_") + ' [LastTimeOption]';
      this.generateFields = function (enable) {
        this.fields = {};
        var lastVisitField = dataFilterStructures.get('LastTimeOption');
        if (enable) {
          lastVisitField.value = lastVisitField.options[11];
        } else {
          lastVisitField.value = lastVisitField.options[0];
        }

        this.fields.LastTimeOption = lastVisitField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: this.filterType,
          FilterComperator: this.fields.LastTimeOption.value.Comperator,
          Value: this.fields.LastTimeOption.value.value
        };
      };

      this.set = function (filterValue) {
        this.fields.LastTimeOption.value = getSelectedValue(filterValue, this.fields.LastTimeOption.options);
      };
    },
    LastCampaign: function () {
      this.filterType = "LastCampaign";
      this.text = localize.getLocalizedString("_Last Campaign was_") + ' [LastTimeOption]';
      this.generateFields = function (enable) {
        this.fields = {};
        var lastCampaignField = dataFilterStructures.get('LastTimeOption');
        lastCampaignField.options.splice(1, 7);
        if (enable) {
          lastCampaignField.value = lastCampaignField.options[3];
        } else {
          lastCampaignField.value = lastCampaignField.options[0];
        }

        this.fields.LastTimeOption = lastCampaignField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: this.filterType,
          FilterComperator: this.fields.LastTimeOption.value.Comperator,
          Value: this.fields.LastTimeOption.value.value
        };
      };

      this.set = function (filterValue) {
        this.fields.LastTimeOption.value = getSelectedValue(filterValue, this.fields.LastTimeOption.options);
      };
    },
    Meeting: function () {
      this.filterType = "Meeting";
      this.text = localize.getLocalizedString("_Meeting Time is_") + ' [MeetingTimingOption]';
      this.generateFields = function (enable) {
        this.fields = {};
        var meetingField = dataFilterStructures.get('MeetingTimingOption');
        if (enable) {
          meetingField.value = meetingField.options[6];
        } else {
          meetingField.value = meetingField.options[0];
        }

        this.fields.MeetingTimingOption = meetingField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: this.filterType,
          FilterComperator: this.fields.MeetingTimingOption.value.Comperator,
          Value: this.fields.MeetingTimingOption.value.value
        };
      };

      this.set = function (filterValue) {
        this.fields.MeetingTimingOption.value = getSelectedValue(filterValue, this.fields.MeetingTimingOption.options);
      };
    },
    CelebratingBirthday: function () {
      this.filterType = "CelebratingBirthday";
      this.text = localize.getLocalizedString("_Celebrating_") + ' [Celebrating] [FutureTimingOption]';

      /*
       This part inserts the needed combo options to the member's field property.
       */
      this.generateFields = function (enable) {
        this.fields = {};
        var celebratingField = dataFilterStructures.get('Celebrating');
        celebratingField.main = true;
        var futureTimingField = dataFilterStructures.get('FutureTimingOption');
        futureTimingField.options.splice(0, 1);

        if (enable) {
          celebratingField.value = celebratingField.options[1];
          futureTimingField.value = futureTimingField.options[1];
        } else {
          celebratingField.value = celebratingField.options[0];
          futureTimingField.value = futureTimingField.options[0];
        }

        this.fields.Celebrating = celebratingField;
        this.fields.FutureTimingOption = futureTimingField;
        return this;
      };


      this.toJSON = function () {
        return {
          FilterType: this.filterType,
          FilterComperator: "Equals",
          Value: this.fields.FutureTimingOption.value.value
        };
      };

      this.set = function (filterValue) {
        this.fields.FutureTimingOption.value = getSelectedValue(filterValue, this.fields.FutureTimingOption.options);
        this.fields.Celebrating.value = this.fields.Celebrating.options[1];
      };
    },
    VisitsInPeriod: function () {
      this.filterType = "VisitsInPeriod";
      this.text = localize.getLocalizedString("_Number of visits_") + ' [LastTimeOption] ' + localize.getLocalizedString("_is_") + ' [LessOrMoreOption] [NumOfVisits]';
      this.generateFields = function (enable) {
        this.fields = {};
        var lastVisitField = dataFilterStructures.get('LastTimeOption');
        //lastVisitField.options.splice(0, 3);
        //lastVisitField.options.splice(5, 6);

        var lessOrMoreField = dataFilterStructures.get('LessOrMoreOption');
        lessOrMoreField.options.splice(0, 2);

        var numOfVisitsField = dataFilterStructures.get('NumOfVisits');
        numOfVisitsField.main = true;


        if (enable) {
          lastVisitField.value = lastVisitField.options[1];
          lessOrMoreField.value = lessOrMoreField.options[0];
          numOfVisitsField.value = numOfVisitsField.options[4];
        } else {
          lastVisitField.value = lastVisitField.options[0];
          lessOrMoreField.value = lessOrMoreField.options[0];
          numOfVisitsField.value = numOfVisitsField.options[0];
        }

        this.fields.LastTimeOption = lastVisitField;
        this.fields.LessOrMoreOption = lessOrMoreField;
        this.fields.NumOfVisits = numOfVisitsField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: this.filterType,
          FilterComperator: this.fields.LessOrMoreOption.value.value,
          Value: this.fields.NumOfVisits.value.value + "(per)" + this.fields.LastTimeOption.value.value
        };
      };

      this.set = function (filterValue, comparator) {
        this.fields.LessOrMoreOption.value = getSelectedValue(comparator, this.fields.LessOrMoreOption.options);
        const numOfVisitsValue = filterValue.substring(0, filterValue.indexOf('('));
        const lastTimeValue = filterValue.substring(filterValue.indexOf(')') + 1, filterValue.length);
        this.fields.NumOfVisits.value = getSelectedValue(numOfVisitsValue, this.fields.NumOfVisits.options);
        this.fields.LastTimeOption.value = getSelectedValue(lastTimeValue, this.fields.LastTimeOption.options);
      };
    },
    Product: function () {
      this.forceConcreteSelection = true;
      this.filterType = "Product";
      this.text = localize.getLocalizedString("_Purchesed Product_") + ' [Product]';
      this.generateFields = function (enable) {
        this.fields = {};
        var productField = dataFilterStructures.get('ProductOptions');
        if (enable) {
          productField.value = productField.options[1];
        } else {
          productField.value = productField.options[0];
        }

        productField.multiSelect = true;

        this.fields.Product = productField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: this.filterType,
          FilterComperator: "Equals",
          Value: getValuesCommaSeparatedValues(mapValueOrValues(this.fields.Product))

          // this.fields.Product.values.length === 0 ?
          // // This considers the value is a category.
          // (Array.isArray(this.fields.Product.value.value) ? getValuesCommaSeparatedValues(this.fields.Product.value.value) : this.fields.Product.value.value)
          // : getValuesCommaSeparatedValues(this.fields.Product.values)


        };
      };

      this.set = function (filterValue) {
        this.fields.Product.value = getSelectedValue(filterValue, this.fields.Product.options);
      };
    },
    Service: function () {
      this.forceConcreteSelection = true;
      this.filterType = "Service";
      this.text = localize.getLocalizedString("_Got the Service_") + ' [Service]';
      this.generateFields = function (enable) {
        this.fields = {};
        var serviceField = dataFilterStructures.get('ServiceOptions');
        if (enable) {
          serviceField.value = serviceField.options[1];
        } else {
          serviceField.value = serviceField.options[0];
        }

        serviceField.multiSelect = true;
        this.fields.Service = serviceField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: this.filterType,
          FilterComperator: "Equals",
          Value: getValuesCommaSeparatedValues(mapValueOrValues(this.fields.Service))
        };
      };

      this.set = function (filterValue) {
        this.fields.Service.value = getSelectedValue(filterValue, this.fields.Service.options);
      };
    },
    ServiceInterval: function () {
      this.forceConcreteSelection = true;
      this.filterType = "ServiceInterval";
      this.text = localize.getLocalizedString("_Got the Service_") + ' [Service] ' + ' [ComperatorOption] ' + ' [CountOption] [NameOption]';
      this.generateFields = function (enable) {
        this.fields = {};
        var serviceField = dataFilterStructures.get('ServiceOptions');
        if (enable) {
          serviceField.value = serviceField.options[1];
        } else {
          serviceField.value = serviceField.options[0];
        }

        serviceField.multiSelect = true;

        var intervalCountTimingOptions = dataFilterStructures.get('IntervalCountTimingOptions');
        var intervalNameTimingOptions = dataFilterStructures.get('IntervalNameTimingOptions');
        var intervalComperatorOptions = dataFilterStructures.get('IntervalComperatorOptions');
        intervalNameTimingOptions.main = true;


        if (enable) {
          intervalCountTimingOptions.value = intervalCountTimingOptions.options[0];
          intervalNameTimingOptions.value = intervalNameTimingOptions.options[0];
          intervalComperatorOptions.value = intervalComperatorOptions.options[0];
        } else {
          intervalCountTimingOptions.value = intervalCountTimingOptions.options[0];
          intervalNameTimingOptions.value = intervalNameTimingOptions.options[0];
          intervalComperatorOptions.value = intervalComperatorOptions.options[0];
        }

        this.fields.Service = serviceField;
        this.fields.CountOption = intervalCountTimingOptions;
        this.fields.NameOption = intervalNameTimingOptions;
        this.fields.ComperatorOption = intervalComperatorOptions;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: this.filterType,
          FilterComperator: this.fields.ComperatorOption.value.value,
          Value: getValuesCommaSeparatedValues(mapValueOrValues(this.fields.Service)) + "(before)" + this.fields.CountOption.value.value + ',' + this.fields.NameOption.value.value
        };
      };

      this.set = function (filterValue, comparator) {
        const lastTimeValue = filterValue.substring(filterValue.indexOf(')') + 1, filterValue.length);
        this.fields.LastTimeOption.value = getSelectedValue(lastTimeValue, this.fields.LastTimeOption.options);
        this.fields.Service.value = getSelectedValue(filterValue, this.fields.Service.options);
      };
    },
    ProductInterval: function () {
      this.forceConcreteSelection = true;
      this.filterType = "ProductInterval";
      this.text = localize.getLocalizedString("_Purchesed Product_") + ' [Product] ' + ' [ComperatorOption] ' + ' [CountOption] [NameOption]';
      this.generateFields = function (enable) {
        this.fields = {};
        var productField = dataFilterStructures.get('ProductOptions');
        if (enable) {
          productField.value = productField.options[1];
        } else {
          productField.value = productField.options[0];
        }

        productField.multiSelect = true;

        var intervalCountTimingOptions = dataFilterStructures.get('IntervalCountTimingOptions');
        var intervalNameTimingOptions = dataFilterStructures.get('IntervalNameTimingOptions');
        var intervalComperatorOptions = dataFilterStructures.get('IntervalComperatorOptions');
        intervalNameTimingOptions.main = true;


        if (enable) {
          intervalCountTimingOptions.value = intervalCountTimingOptions.options[0];
          intervalNameTimingOptions.value = intervalNameTimingOptions.options[0];
          intervalComperatorOptions.value = intervalComperatorOptions.options[0];
        } else {
          intervalCountTimingOptions.value = intervalCountTimingOptions.options[0];
          intervalNameTimingOptions.value = intervalNameTimingOptions.options[0];
          intervalComperatorOptions.value = intervalComperatorOptions.options[0];
        }

        this.fields.Product = productField;
        this.fields.CountOption = intervalCountTimingOptions;
        this.fields.NameOption = intervalNameTimingOptions;
        this.fields.ComperatorOption = intervalComperatorOptions;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: this.filterType,
          FilterComperator: this.fields.ComperatorOption.value.value,
          Value: getValuesCommaSeparatedValues(mapValueOrValues(this.fields.Product)) + "(before)" + this.fields.CountOption.value.value + ',' + this.fields.NameOption.value.value
        };
      };

      this.set = function (filterValue, comparator) {
        const lastTimeValue = filterValue.substring(filterValue.indexOf(')') + 1, filterValue.length);
        this.fields.LastTimeOption.value = getSelectedValue(lastTimeValue, this.fields.LastTimeOption.options);
        this.fields.Service.value = getSelectedValue(filterValue, this.fields.Service.options);
      };
    },
    Subscription: function () {
      this.filterType = "Subscription";
      this.text = localize.getLocalizedString("_SubscriptionStatusIs_") + ' [SubscriptionOption]';
      this.generateFields = function (enable) {
        this.fields = {};
        var subscriptionField = dataFilterStructures.get('SubscriptionOption');
        if (enable) {
          subscriptionField.value = subscriptionField.options[1];
        } else {
          subscriptionField.value = subscriptionField.options[0];
        }

        this.fields.SubscriptionOption = subscriptionField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: this.filterType,
          FilterComperator: this.fields.SubscriptionOption.value.Comperator,
          Value: this.fields.SubscriptionOption.value.value
        };
      };

      this.set = function (filterValue) {
        this.fields.SubscriptionOption.value = getSelectedValue(filterValue, this.fields.SubscriptionOption.options);
      };
    },
    SubscriptionService: function () {
      this.forceConcreteSelection = true;
      this.filterType = "SubscriptionService";
      this.text = localize.getLocalizedString("_SubscriptionService_") + ' [Service] [ExistOption]';
      this.generateFields = function (enable) {
        this.fields = {};
        var serviceField = dataFilterStructures.get('ServiceOptions');
        var existField = dataFilterStructures.get('ExistOption');
        serviceField.main = true;
        serviceField.value = serviceField.options[0];
        existField.value = existField.options[0];

        serviceField.multiSelect = true;
        this.fields.Service = serviceField;
        this.fields.ExistOption = existField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: this.filterType,
          FilterComperator: this.fields.ExistOption.value.value,
          Value: getValuesCommaSeparatedValues(mapValueOrValues(this.fields.Service))
        };
      };

      this.set = function (filterValue) {
        this.fields.Service.value = getSelectedValue(filterValue, this.fields.Service.options);
      };
    },
    Points: function () {
      this.filterType = "Points";
      this.text = localize.getLocalizedString("_Points_") + ' [Points]';
      this.generateFields = function (enable) {
        this.fields = {};

        var pointsField = dataFilterStructures.get('PointsOption');
        pointsField.main = true;

        if (enable) {
          pointsField.value = pointsField.options[1];
        } else {
          pointsField.value = pointsField.options[0];
        }

        this.fields.Points = pointsField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: this.filterType,
          FilterComperator: 'GreaterThan',
          Value: this.fields.Points.value.value
        };
      };

      this.set = function (filterValue, comparator) {
        this.fields.LessOrMoreOption.value = getSelectedValue(comparator, this.fields.LessOrMoreOption.options);
        this.fields.Points.value = getSelectedValue(filterValue, this.fields.Points.options);
      };
    },
    AverageInvoice: function () {
      this.filterType = "AverageInvoice";
      this.text = localize.getLocalizedString("_Average Invoice is_") + ' [LessOrMoreOption] [AverageInvoice]';
      this.generateFields = function (enable) {
        this.fields = {};

        var lessOrMoreField = dataFilterStructures.get('LessOrMoreOption');
        lessOrMoreField.options.splice(0, 1);
        var averageInvoiceField = dataFilterStructures.get('AverageInvoiceOption');

        averageInvoiceField.main = true;


        if (enable) {
          averageInvoiceField.value = averageInvoiceField.options[1];
          lessOrMoreField.value = lessOrMoreField.options[0];
        } else {
          averageInvoiceField.value = averageInvoiceField.options[0];
          lessOrMoreField.value = lessOrMoreField.options[0];
        }

        this.fields.AverageInvoice = averageInvoiceField;
        this.fields.LessOrMoreOption = lessOrMoreField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: this.filterType,
          FilterComperator: this.fields.LessOrMoreOption.value.value,
          Value: this.fields.AverageInvoice.value.value
        };
      };

      this.set = function (filterValue, comparator) {
        this.fields.LessOrMoreOption.value = getSelectedValue(comparator, this.fields.LessOrMoreOption.options);
        this.fields.AverageInvoice.value = getSelectedValue(filterValue, this.fields.AverageInvoice.options);
      };
    },
    Form: function () {
      this.filterType = "Form";
      this.text = localize.getLocalizedString("_DidntFillForm_") + ' [Form]';
      this.generateFields = function (enable) {
        this.fields = {};
        var formField = dataFilterStructures.get('Form');
        formField.multiSelect = false;
        if (enable && formField.options[1]) {
          formField.value = formField.options[1];
        } else {
          formField.value = formField.options[0];
        }

        this.fields.Form = formField;
        return this;
      };


      this.toJSON = function () {
        return {
          FilterType: "Form",
          FilterComperator: "Equals",
          Value: this.fields.Form.value.value
        };
      };

      this.set = function (filterValue) {
        this.fields.Form.value = getSelectedValue(filterValue, this.fields.Form.options);
      };
    },
    RelatedToEmployee: function () {
      this.filterType = "RelatedToEmployee";
      this.text = localize.getLocalizedString("_RelatedToEmployee_") + ' [RelatedToEmployee]';
      this.generateFields = function (enable) {
        this.fields = {};
        var relatedToEmployeeField = dataFilterStructures.get('RelatedToEmployee');
        relatedToEmployeeField.multiSelect = true;
        if (enable && relatedToEmployeeField.options[1]) {
          relatedToEmployeeField.value = relatedToEmployeeField.options[1];
        } else {
          relatedToEmployeeField.value = relatedToEmployeeField.options[0];
        }

        this.fields.RelatedToEmployee = relatedToEmployeeField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: "RelatedToEmployee",
          FilterComperator: "Equals",
          Value: this.fields.RelatedToEmployee.values.length === 0 ? this.fields.RelatedToEmployee.value.value :
            getValuesCommaSeparatedValues(this.fields.RelatedToEmployee.values)
        };
      };

      this.set = function (filterValue) {
        this.fields.RelatedToEmployee.value = getSelectedValue(filterValue, this.fields.RelatedToEmployee.options);
      };
    },
    Tag: function () {
      this.filterType = "Tag";
      this.text = localize.getLocalizedString("_HoldsTag_") + ' [Tag]';
      this.generateFields = function (enable) {
        this.fields = {};
        var tagField = dataFilterStructures.get('Tag');
        tagField.multiSelect = true;
        if (enable && tagField.options[1]) {
          tagField.value = tagField.options[1];
        } else {
          tagField.value = tagField.options[0];
        }

        this.fields.Tag = tagField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: "Tag",
          FilterComperator: "Equals",
          Value: this.fields.Tag.values.length === 0 ? this.fields.Tag.value.value :
            getValuesCommaSeparatedValues(this.fields.Tag.values)
        };
      };

      this.set = function (filterValue) {
        this.fields.Tag.value = getSelectedValue(filterValue, this.fields.Tag.options);
      };
    },
    NotTag: function () {
      this.filterType = "NotTag";
      this.text = localize.getLocalizedString("_NotHoldingTag_") + ' [Tag]';
      this.generateFields = function (enable) {
        this.fields = {};
        var tagField = dataFilterStructures.get('Tag');
        tagField.multiSelect = true;
        if (enable && tagField.options[1]) {
          tagField.value = tagField.options[1];
        } else {
          tagField.value = tagField.options[0];
        }

        this.fields.Tag = tagField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: "Tag",
          FilterComperator: "NotEquals",
          Value: this.fields.Tag.values.length === 0 ? this.fields.Tag.value.value :
            getValuesCommaSeparatedValues(this.fields.Tag.values)
        };
      };

      this.set = function (filterValue) {
        this.fields.Tag.value = getSelectedValue(filterValue, this.fields.Tag.options);
      };
    },
    PriceList: function () {
      this.filterType = "PriceList";
      this.text = localize.getLocalizedString("_PriceLists_") + ' [PriceList]';
      this.generateFields = function (enable) {
        this.fields = {};
        var priceListField = dataFilterStructures.get('PriceList');
        priceListField.multiSelect = true;
        if (enable && priceListField.options[1]) {
          priceListField.value = priceListField.options[1];
        } else {
          priceListField.value = priceListField.options[0];
        }

        this.fields.PriceList = priceListField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: "PriceList",
          FilterComperator: "Equals",
          Value: this.fields.PriceList.values.length === 0 ? this.fields.PriceList.value.value :
            getValuesCommaSeparatedValues(this.fields.PriceList.values)
        };
      };

      this.set = function (filterValue) {
        this.fields.Tag.value = getSelectedValue(filterValue, this.fields.Tag.options);
      };
    },
    ArrivalSource: function () {
      this.filterType = "ArrivalSource";
      this.text = localize.getLocalizedString("_ArrivalSource_") + ' [ArrivalSource]';
      this.generateFields = function (enable) {
        this.fields = {};
        var arrivalsourceField = dataFilterStructures.get('ArrivalSource');
        arrivalsourceField.multiSelect = true;
        if (enable && arrivalsourceField.options[1]) {
          arrivalsourceField.value = arrivalsourceField.options[1];
        } else {
          arrivalsourceField.value = arrivalsourceField.options[0];
        }

        this.fields.ArrivalSource = arrivalsourceField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: "ArrivalSource",
          FilterComperator: "Equals",
          Value: this.fields.ArrivalSource.values.length === 0 ? this.fields.ArrivalSource.value.value :
            getValuesCommaSeparatedValues(this.fields.ArrivalSource.values)
        };
      };

      this.set = function (filterValue) {
        this.fields.ArrivalSource.value = getSelectedValue(filterValue, this.fields.ArrivalSource.options);
      };
    },
    Membership: function () {
      this.filterType = "Membership";
      this.text = localize.getLocalizedString("_HoldsMembership_") + ' [Membership]';
      this.generateFields = function (enable) {
        this.fields = {};
        var membershipField = dataFilterStructures.get('Membership');
        if (enable && membershipField.options[1]) {
          membershipField.value = membershipField.options[1];
        } else {
          membershipField.value = membershipField.options[0];
        }

        this.fields.Membership = membershipField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: "Membership",
          FilterComperator: "Equals",
          Value: this.fields.Membership.value.value
        };
      };

      this.set = function (filterValue) {
        this.fields.Membership.value = getSelectedValue(filterValue, this.fields.Membership.options);
      };
    },
    LastName: function () {
      this.filterType = "LastName";
      this.text = localize.getLocalizedString("_LastNameStartsWith_") + ' [LastName]';
      this.generateFields = function (enable) {
        this.fields = {};
        var lastNameField = dataFilterStructures.get('LastName');
        if (enable) {
          lastNameField.value = lastNameField.options[1];
        } else {
          lastNameField.value = lastNameField.options[0];
        }
        this.fields.LastName = lastNameField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: "LastName",
          FilterComperator: "Range",
          Value: this.fields.LastName.value.value
        };
      };

      this.set = function (filterValue) {
        this.fields.LastName.value = getSelectedValue(filterValue, this.fields.LastName.options);
      };
    },
    CreatedOn: function () {
      this.filterType = "CreatedOn";
      this.text = localize.getLocalizedString("_CreatedOn_") + ' [CreatedOn]';
      this.generateFields = function (enable) {
        this.fields = {};
        var createdOnField = dataFilterStructures.get('CreatedOn');
        if (enable) {
          createdOnField.value = createdOnField.options[1];
        } else {
          createdOnField.value = createdOnField.options[0];
        }
        this.fields.CreatedOn = createdOnField;
        return this;
      };

      this.toJSON = function () {
        return {
          FilterType: "CreatedOn",
          FilterComperator: this.fields.CreatedOn.value.Comperator,
          Value: this.fields.CreatedOn.value.value
        };
      };

      this.set = function (filterValue) {
        this.fields.CreatedOn.value = getSelectedValue(filterValue, this.fields.CreatedOn.options);
      };
    }
  };
  var possibleTimings = ['Always', 'EveryHour', 'Every2Hours', 'Daily', 'Weekly', 'Monthly', 'Every3Months', 'Every6Months', 'EveryYear'];


  return {
    get: function (filter, enable) {
      return filter !== 'All' ? new dataFilters[filter]().generateFields(enable) : getAll();
    },
    getAll: getAll,
    getFilterExclude: getFilterExclude,
    compareTimes: function (firstTime, secondTime) {
      return possibleTimings.indexOf(firstTime) > possibleTimings.indexOf(secondTime);
    }
  };

  function mapValueOrValues(entity) {
    var toReturn = (entity.values.length > 0 ? entity.values : (Array.isArray(entity.value.value) ? entity.value.value : [entity.value])).filter(function (val) {
      return val.valueType !== "Category"
    });

    return toReturn;
  }

  function getValuesCommaSeparatedValues(values) {
    var valuesToReturn = "";
    values.forEach(function (ref) {
      var valueToAdd = null;

      if (!ref.valueType) {
        valueToAdd = ref.value;
      } else if (ref.valueType === 'Category') {
        // valueToAdd = Array.isArray(ref.value) ? ref.value.filter(function (internal) {
        //   return internal.isSelected
        // }).map(function (internal) {
        //   return internal.value
        // }).join(', ') : ref.categoryValue;
        valueToAdd = ref.categoryValue;
      }

      if (valueToAdd) {
        valuesToReturn += (valuesToReturn.length > 0 ? ', ' : '') + valueToAdd;
      }


    });

    return valuesToReturn;
  }


  function getAll(enable) {
    var toReturn = [];
    angular.forEach(dataFilters, function (filter) {
      toReturn.push(new filter().generateFields(enable));
    });

    return toReturn;
  }

  function getFilterExclude(exclude, enable) {
    var toReturn = [];
    if (!exclude.equalsIgnoreCase('All')) {
      angular.forEach(dataFilters, function (filter, key) {
        if (exclude.indexOf(key) == -1) {
          toReturn.push(new filter().generateFields(enable));
        }
      });
    }

    return toReturn;
  }

  function getSelectedValue(filterValue, options) {
    return options.filter(x => x.value === filterValue)[0];
  }

});

