<template>
  <div class="waiting-list-container flex flex-col relative-container">
    <div class="flex justify-between items-center p-4 shrink-0">
      <label>{{ $t('_WaitingList_') }}</label>
      <vue-button :primary="true" :initial-text-size="true" @click="addNewWaitingListItem">{{
          $t('_Add_')
        }}
      </vue-button>
    </div>
    <div class="mt-4 p-4 grow overflow-y-auto">
      <expandable-component :value="showNewItem" :animate-height-change="true">
        <editable-waiting-list-item
          @change="updateNewItemValue"
          :available-employees="availableEmployees"
          :available-services="availableServices"
          :item="editableItem"
          :is-saving-item="isSavingItem"
          @save="saveNewItem"
          @close="removeNewItem"/>
      </expandable-component>
    </div>
  </div>
</template>

<script>
import VueButton from '../../../vue/components/button.vue';
import EditableWaitingListItem from "./waiting.list.editable.item.vue";
import {ExpandableComponent} from "@tomeravni/easybizy-vue-components";

export default {
  components: {ExpandableComponent, VueButton, EditableWaitingListItem},
  emits: ['change'],
  props: {
    delegate: {
      type: Function,
    },
    addNewItem: {
      type: Function,
    },
    cancelNewItem: {
      type: Function,
    },
    editableItem: {
      type: Object
    },
    change: {
      type: Function,
    },
    availableEmployees: {
      type: Array,
    },
    availableServices: {
      type: Array,
    },
    saveItem: {
      type: Function,
    },
    isSavingItem: {
      type: Boolean,
    }
  },
  data() {
    return {
      isOpen: false,
      showNewItem: false,
    }
  },
  watch: {
    isOpen(val) {
      this.delegate?.(val)
    }
  },
  methods: {
    addNewWaitingListItem() {
      this.addNewItem?.();
      this.showNewItem = true;
    },
    removeNewItem() {
      this.showNewItem = false;
      this.cancelNewItem?.();
    },
    updateNewItemValue(args) {
      this.change(args);
    },
    saveNewItem(item) {
      this.saveItem(item)
    }
  }
}
</script>
