import './promotions.manager.less'
import './promotion-edit-view/promotion.edit.view'
import {RTL_DATE_TIME_DISPLAY, SERVER_DATA_DATE_FORMAT} from "../../constants";


/**
 * Created by tomeravni on 1/29/14.
 */

angular.module('easybizy.promotions.manager').controller('PromotionsManagerController', function ($scope, $timeout,
                                                                                                  toolbar, $state, Repository,
                                                                                                  localize) {
  toolbar.set([]);

  $scope.filtersModel = {};
  $scope.filtersModel.filters = [
    {type: "promotionStatus"}
  ];

  let filterState = {promotionStatus: "active",promotionMedium: "all"};
  $scope.filtersModel.filterStateChanged = function (newFiltersState, newFiltersSlug) {
    filterState = newFiltersState;
    refreshPromotions();
  };

  $scope.promotions = [];
  $scope.promotionFields = [];

  $scope.addNewPromotion = function () {
    $state.go('Promotions');
  };


  $scope.selectionChanged = selectionUpdatedDelegate;//updateGraphData;
  $scope.deleteMessage = localize.getLocalizedString("_DeleteCampaignsHistory_", 0);
  $scope.newPromotionText = "+ " + localize.getLocalizedString("_NewPromotion_");

  $scope.possibleViews = [
    {icon: "glyphicon glyphicon-th-list", text: localize.getLocalizedString("_ListView_"), type: "list"},
    {icon: "glyphicon glyphicon-stats", text: localize.getLocalizedString("_ChartView_"), type: "chart"}
  ];

  $scope.selectedView = $scope.possibleViews[0];
  $scope.graphData = {};
  var currentConfigurations = null;

  $scope.selectAllPromotions = true;
  $scope.promotionsApi = Repository.Custom("PromotionsRepository").query();
  $scope.promotionsApi.preventInitializtionLoad = true;

  $scope.trackByPromotionRandom = function () {
    return Math.random() * Math.random() * Math.random();
  }

  $scope.isApproveDeleteMode = false;
  $scope.isDeletingCampaigns = false;
  $scope.deletePromotions = function () {
    if (!$scope.isApproveDeleteMode) {
      $scope.isApproveDeleteMode = true;
      $scope.deleteMessage = localize.getLocalizedString("_DeleteCampaignHistoryApprove_", $scope.graphData.values.length);

      $timeout(function () {
        $scope.isApproveDeleteMode = false;
        $scope.deleteMessage = localize.getLocalizedString("_DeleteCampaignsHistory_", $scope.graphData.values.length);
      }, 15000);
    } else {
      $scope.isDeletingCampaigns = true;

      var campaignsToDelete = $scope.promotions.filter(x => x.isSelected);

      // var campaignsToDelete = Enumerable.From($scope.promotions)
      //   .Where(function (x) {
      //     return x.isSelected;
      //   })
      //   .Select(function (x) {
      //     return x;
      //   })
      //   .ToArray();

      Repository.Custom("PromotionsRepository").deleteSendingReport(campaignsToDelete)
        .then(function () {
          toastr.success(localize.getLocalizedString('_CampaignsHistoryDeletedSuccessfully_'));
          $scope.$evalAsync(function () {
            campaignsToDelete.forEach(function (campaign) {
              $scope.promotions.remove(campaign);
            });

            selectionUpdatedDelegate();
            $scope.isDeletingCampaigns = false;
            $scope.deleteMessage = localize.getLocalizedString("_DeleteCampaignsHistory_", $scope.graphData.values.length);
          });
        })
        .catch(function () {
          toastr.error(localize.getLocalizedString('_ErrorDeletingHistory_'));
        });

    }

  };


  let latestFilterState;

  const refreshPromotions = () => {
    if (JSON.stringify(latestFilterState) === JSON.stringify(filterState)) {
      return;
    }

    latestFilterState = filterState;
    const currentConfigurations = [filterState.promotionMedium, filterState.promotionStatus];

    setFields();
    $scope.promotionsApi.filter(currentConfigurations[0], currentConfigurations[1]);
    $scope.promotionsApi.forceUpdate();
  };

  function selectionUpdatedDelegate() {
    $scope.selectedPromotions = $scope.promotions.filter(function (promotion) {
      return promotion.isSelected;
    });
    $scope.deleteMessage = localize.getLocalizedString("_DeleteCampaignsHistory_", $scope.selectedPromotions.length);
    $scope.isApproveDeleteMode = false;
  }

  Repository.Custom("PromotionsRepository").smsCountByMonths().then(function (data) {
    $scope.graphData.type = 'line';
    $scope.graphData.config = data.metadata;
    $scope.graphData.values = data.results;
    $scope.graphData.values.forEach(function (type) {
      type.data = type.data.map(function (singleResult) {
        return [singleResult.Name, singleResult.Value]
      });
    });
    $scope.graphData.isArranged = true;
  });

  function setFields() {
    $scope.promotionFields = [];
    $scope.promotionFields.pushAll([possibleFields.select, possibleFields.name, possibleFields.type, possibleFields.time, possibleFields.recipients]);
    if (filterState.promotionMedium === 'mail') {
      $scope.promotionFields.pushAll([possibleFields.sent, possibleFields.opened, possibleFields.unsubscribed]);
    } else if (filterState.promotionMedium === 'facebook') {
      $scope.promotionFields.pushAll([possibleFields.likes, possibleFields.comments]);
    }

    $scope.promotionFields.push(possibleFields.actions);
    setColumnsWidth();
  }

  function setColumnsWidth() {
    var columnsWidth = null;
    if (filterState.promotionMedium === 'mail') {
      columnsWidth = ['5%', '19%', '8%', '12%', '10%', '10%', '10%', '10%', '16%'];

    } else if (filterState.promotionMedium === 'facebook') {
      columnsWidth = ['5%', '19%', '8%', '15%', '12%', '12%', '12%', '17%'];

    } else { //if ($scope.promotionTypeValue.value === 'all' || $scope.promotionTypeValue.value === 'sms') {
      columnsWidth = ['5%', '25%', '15%', '20%', '15%', '20%'];
    }

    angular.forEach($scope.promotionFields, function (field, index) {
      field.width = columnsWidth[index];
    });

  }

  var rowActionsResolver = {
    pausePromotion: function (promotionId) {
      return Repository.Custom("PromotionsRepository").pause(promotionId)
        .catch(function (e) {
          toastr.error(e.Message);
          return false;
        });

    },
    restartPromotion: function (promotionId) {
      return Repository.Custom("PromotionsRepository").restart(promotionId)
        .catch(function (e) {
          toastr.error(e.Message);
          return false;
        });

    }
  };

  var promotionWasNotSentMessage = localize.getLocalizedString("_NotSentNoReceptionists_");
  var promotionOnSendingProcess = localize.getLocalizedString("_OnSendingProcess_");

  var possibleFields = {
    select: {
      name: localize.getLocalizedString("_None_"),
      type: 'Checkbox',
      allowSelectAll: true,
      sortable: false,
      fieldName: 'isSelected'
      //command: {
      //  get: function (entity) {
      //    return entity.isSelected;
      //  },
      //  set: function (entity, value) {
      //    entity.isSelected = value;
      //  }
      //}
    },
    name: {
      name: localize.getLocalizedString("_PromotionName_"),
      sortable: true,
      command: {
        get: function (entity) {
          try {
            var campaign = entity.CampaignName;
            return campaign;
          } catch (e) {
            return entity.CampaignName;
          }
        },
        sort: function () {
          return "CampaignName";
        }
      }
    },
    type: {
      name: localize.getLocalizedString("_PromotionType_"),
      sortable: true,
      width: "10%",
      command: {
        get: function (entity) {
          return entity.Type;
        },
        sort: function () {
          return "Type";
        }
      }
    },
    time: {
      name: localize.getLocalizedString("_TimeSent_"),
      sortable: true,
      orderedBy: true,
      isDecending: true,
      command: {
        get: function (entity) {
          if (entity.SendingTime) {
            return moment(entity.SendingTime, SERVER_DATA_DATE_FORMAT).format(RTL_DATE_TIME_DISPLAY)
          } else if (moment(entity.PromotionStartTime, SERVER_DATA_DATE_FORMAT).isAfter(moment()) && entity.status === "Valid") {
            return localize.getLocalizedString("_ScheduledTo_", moment(entity.PromotionStartTime, SERVER_DATA_DATE_FORMAT).format(RTL_DATE_TIME_DISPLAY));
          } else if (entity.status === "Cancelled") {
            return localize.getLocalizedString("_PromotionCancelled_");
          } else if (entity.status === "Running" && moment(entity.PromotionStartTime, SERVER_DATA_DATE_FORMAT).isAfter(moment().add(-1, 'hours'))) {
            return promotionOnSendingProcess;
          } else {
            return promotionWasNotSentMessage + (entity.ErrorMessage ? " - " + entity.ErrorMessage : "");
          }
        },
        sort: function () {
          return "SendingTime";
        }
      }
    },
    recipients: {
      name: localize.getLocalizedString("_Recipients_"),
      sortable: true,
      width: "15%",
      command: {
        get: function (entity) {
          return entity.Recipients;
        },
        sort: function () {
          return "Recipients";
        }
      }
    },
    likes: {
      name: localize.getLocalizedString("_Likes_"),
      sortable: true,
      command: {
        get: function (entity) {
          return entity.Likes !== -1 ? entity.Likes : "-";
        },
        sort: function () {
          return "Likes";
        }
      }
    },
    comments: {
      name: localize.getLocalizedString("_Commented_"),
      sortable: true,
      command: {
        get: function (entity) {
          return entity.Comments !== -1 ? entity.Comments : "-";
        },
        sort: function () {
          return "Comments";
        }
      }
    },
    opened: {
      name: localize.getLocalizedString("_ReadAmount_"),
      sortable: true,
      command: {
        get: function (entity) {
          return entity.Opened !== -1 ? entity.Opened : "-";
        },
        sort: function () {
          return "Opened";
        }
      }
    },
    sent: {
      name: localize.getLocalizedString("_SentAmount_"),
      sortable: true,
      command: {
        get: function (entity) {
          return entity.Sent !== -1 ? entity.Sent : "-";
        },
        sort: function () {
          return "Sent";
        }
      }
    },
    unsubscribed: {
      name: localize.getLocalizedString("_Unsubscribed_"),
      sortable: true,
      command: {
        get: function (entity) {
          return entity.Unsubscribed !== -1 ? entity.Unsubscribed : "-";
        },
        sort: function () {
          return "Unsubscribed";
        }
      }
    },
    actions: {
      name: localize.getLocalizedString("_Actions_"),
      type: "Custom",
      template: "<promotions-manager-row-actions entity='concreteEntity' actions-resolver='actionsResolver'></promotions-manager-row-actions>",
      extendScopeWith: {actionsResolver: rowActionsResolver},
      command: {
        get: function (entity) {
          return entity.PromotionTime;
        }
      }
    }
  };

});


angular.module('easybizy.promotions.manager').directive("promotionsManagerRowActions", ['$modal', 'modalCreator', 'localize', 'confirmService', 'Repository',
  function ($modal, modalCreator, localize, confirmService, Repository) {
    return {
      scope: {
        entity: '=',
        actionsResolver: '='
      },
      restrict: 'E',
      replace: true,
      link: function (scope, element, attrs) {

        scope.previewPromotion = function (editMode) {
          previewPromotion(!!editMode);
          // editPromotion()
        };

        scope.reschedulePromotion = () => {
          reschedulePromotion();
        };

        scope.isActiveRepeating = scope.entity.Status == 'Valid';
        scope.onOffToggled = function (newVal) {
          if (newVal) {
            scope.actionsResolver.restartPromotion(scope.entity.PromotionId).then(_ => scope.entity.Status = 'Valid');
          } else {
            scope.actionsResolver.pausePromotion(scope.entity.PromotionId).then(_ => scope.entity.Status = 'Paused');
          }
        };

        scope.cancelPromotion = (promotionId) => {
          confirmService.confirm(localize.getLocalizedString('_DeletePromotion_'),
            null, function () {
              return Repository.Custom("PromotionsRepository").cancel(promotionId)
                .then(_ => scope.entity.Status = 'Cancelled')
                .catch(function (e) {
                  toastr.error(e.Message);
                  return false;
                });
            });

        };

        function previewPromotion(editMode) {
          var template = modalCreator.embedInModel(localize.getLocalizedString(scope.entity.Type === 'sms' ? '_EditCampaign_' : '_CampaignPreview_'),
            "<promotion-previewer edit-mode='" + editMode + "' promotion-metadata='promotion' content-resolver-wrapper='contentResolver' save='save' cancel='cancel'></promotion-previewer>",
            "promotion-previewer-view");
          var opts = {
            backdrop: 'static',
            keyboard: true,
            backdropClick: false,
            template: template,
            controller: "PromotionPreviewController",
            resolve: {
              promotion: function () {
                return Object.assign({promotionId: scope.entity.PromotionId, type: scope.entity.Type}, scope.entity);
              }
            },
            closeFn: function () {
              console.log("closing");
            }
          };

          $modal.open(opts);
        }

        function reschedulePromotion() {
          const {PromotionStartTime, CampaignName, Repeat} = scope.entity;
          var opts = {
            backdrop: 'static',
            keyboard: true,
            backdropClick: true,
            template: require('../../partial-views/promotions-widget/promotion-send-summary-dialog/promotion.send.summary.dialog.html'),
            controller: 'PromotionSendSummaryDialogController',
            resolve: {
              parameters: function () {
                return {
                  repeating: Repeat,
                  name: CampaignName,
                  startTime: moment(PromotionStartTime, SERVER_DATA_DATE_FORMAT),
                  updateMode: true
                };
              }, saveAction: function () {
                return function (promotionName, sendTime) {
                  return Repository.Custom("PromotionsRepository").reschedule(
                    {
                      promotionId: scope.entity.PromotionId,
                      promotionName: promotionName,
                      promotionStartTime: moment(sendTime).format(SERVER_DATA_DATE_FORMAT)
                    }).then(_ => scope.entity.PromotionStartTime = sendTime, scope.entity.CampaignName = promotionName)
                    .catch(function (e) {
                      toastr.error(e.Message);
                      return false;
                    });


                };
              }
            },
            closeFn: function () {
              console.log("closing");
            }
          };

          $modal.open(opts);
        }

        // function editPromotion() {
        //   var template = modalCreator.embedInModel(localize.getLocalizedString("_EditCampaign_"),
        //     "<promotion-edit-view promotion-metadata='promotion'></promotion-edit-view>",
        //     "promotion-edit-view")
        //   var opts = {
        //     backdrop: 'static',
        //     keyboard: true,
        //     backdropClick: false,
        //     template: template,
        //     controller: "PromotionPreviewController",
        //     resolve: {
        //       promotion: function () {
        //         return new function () {
        //           this.promotionId = scope.entity.PromotionId;
        //           this.type = scope.entity.Type;
        //         };
        //       }
        //     },
        //     closeFn: function () {
        //       console.log("closing");
        //     }
        //   };
        //
        //   $modal.open(opts);
        // }

        /// TODO: Remember this for previewing a whatsapp message
        // <div className="promotions-manager-row-action-item" ng-if="entity.Status === 'Valid' && entity.Type === 'whatsapp'" ng-click="previewWhatsapp()"><span className="icon" ng-class="'icon-preview'"></span> </div>
      },
      template: `<div class="relative-container">
                        <div class="centered-div-container"><div class="centered-div-content left-text-aligned">
                            <div class="promotions-manager-row-action-item" ng-click="previewPromotion()"><span class="icon" ng-class="'icon-preview'"></span> </div>
                            <div class="promotions-manager-row-action-item" ng-if="entity.Status === 'Valid' && entity.Type === 'sms'" ng-click="previewPromotion(true)"><span class="icon icon-edit-inventory"></span></div>
                            <div class="promotions-manager-row-action-item" ng-if="entity.Status === 'Valid' && !entity.Repeat" ng-click="cancelPromotion(entity.PromotionId)"><span class="icon icon-delete-garbage-streamline"></span> </div>
                            <div class="promotions-manager-row-action-item" ng-if="entity.Status === 'Valid'" ng-click="reschedulePromotion()"><span class="icon icon-clock"></span> </div>
                            <on-off-switch value="isActiveRepeating" ng-if="entity.Repeat != null" toggled="onOffToggled"></on-off-switch>
                        </div></div>
                   </div>`

    };
  }]);
