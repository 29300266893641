<template>
  <div class="vue-wizard">
    <div class="wizard-mask"/>
    <Wizard :controller="controller" @change="change" :currentStep="currentStep" @prev="prev" @next="next"
            :isNextVisible="isNextVisible"/>
  </div>
</template>

<script>
import Wizard from './wizard.vue';
import WizardController from "../../wizard.controller";
import Vue from "vue";


export default {
  components: { Wizard },
  data() {
    return {
      currentStep: this.steps ? this.steps[0] : null
    }
  },
  props: {
    controller: {
      type: WizardController,
      required: true
    }
  },
  computed: {
    currentStepIndex() {
      return this.steps.indexOf(this.currentStep);
    },
    nextStep() {
      return (this.currentStepIndex < this.steps.length - 1) ? this.steps[this.currentStepIndex + 1] : null;
    },
    prevStep() {
      return this.currentStepIndex > 0 ? this.steps[this.currentStepIndex - 1] : null;
    }
  },
  methods: {
    change({ step, item }) {
      if (step.type === 'single-select') {
        this.next()
      }
    },
    prev() {
      if (this.prevStep) {
        this.currentStep = this.prevStep;
      }
    },
    next() {
      if (this.nextStep) {
        this.currentStep = this.nextStep;
      }
    },
    isNextVisible(step) {
      return this.steps.indexOf(step) < this.steps.length - 1;
    }
  }
}
</script>

<style lang="less">
.vue-wizard {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);

}

.wizard-mask {
  height: 100%;
  width: 100%;

  position: absolute;
  left: 0;
  top: 0;
}
</style>
