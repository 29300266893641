import './horizontal.history.directive.less'
import { SERVER_DATA_DATE_FORMAT } from "../../../constants";

(function () {
  angular.module('easybizy.common.timeline').directive('horizontalHistory', [
    'localize', '$window', 'Repository', function (localize, $window, Repository) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          items: '=',
          cancel: '=',
          edit: '=',
          duplicate: '=',
          approve: '='
        },
        link: function (scope) {
          scope.getImageAsBackground = function (path) {
            return "url(" + path + ")";
          };

          scope.downloadPdf = function (item) {
            let url = baseUrl + item.PdfPath;
            if (item.DocumentToken) {
              url = `${invoiceURI}/price-quotes/${item.DocumentToken}`;

            }

            if (!item.DocumentToken) {
              Repository.Custom("PriceQuote").getDocumentToken(item.Id).then((token) => {
                item.DocumentToken = token;
                url = `${invoiceURI}/price-quotes/${item.DocumentToken}`;
                $window.open(url, '_blank');
              });
            } else {
              url = `${invoiceURI}/price-quotes/${item.DocumentToken}`;
              $window.open(url, '_blank');
            }
          };

          scope.convertDate = function (date) {
            return moment(date, SERVER_DATA_DATE_FORMAT).format('LLL');
          };

          scope.isActionVisible = function (approvedStatuses, item, inverse) {
            var toReturn = approvedStatuses.indexOf(item.Status) > -1;
            return inverse ? !toReturn : toReturn;
          };

          scope.getIcon = function (item) {
            var toReturn = 'icon icon-price-quote-1';
            if (item.Status == 'Approved') {
              toReturn = 'icon icon-price-quote-approved-1';
            } else if (item.Status == 'Sent' || item.PriceQuoteSendings.length > 0) {
              toReturn = 'icon icon-price-quote-sent';
            }

            return toReturn;
          };

        },
        template: require('./horizontal.history.directive.tpl.html')
      };
    }]);
}());
