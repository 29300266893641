import {DATE_DISPLAY_FORMAT, HOURS_AND_MINUTES_FORMAT, PATH_TO_API} from "../../../constants";
import EXAMPLES from './dynamic_value_examples.json'

angular.module('easybizy.common.promotion.editors').factory('promotionTemplateResolver', [
  '$http', 'Repository', '$timeout', 'localize', '$q', 'promotionsPresetResolver', 'pci', function ($http, Repository, $timeout, localize, $q, promotionsPresetResolver, pci) {
    const dynamicFieldsPlaceholderOpener = this.openTag = "#%";
    const dynamicFieldsPlaceholderCloser = this.closeTag = "%#";
    let formDynamicValues = [];
    let prePaymentDynamicValues = [];

    return new promotionTemplateResolver();

    function promotionTemplateResolver() {
      var requestedTemplates = {};

      var defaultCustomer = {
        FirstName: "F.Name",
        LastName: "L.Name",
        MobileFirst: "Phone number",
        Address: "Address",
        EmailAddress: "Email Address",
        Birthday: "Birthday"
      };

      const defaultCustomValues = {
        MeetingDate: moment().format(DATE_DISPLAY_FORMAT),
        MeetingTime: moment().format(HOURS_AND_MINUTES_FORMAT),
        StartTime: moment().format("DD/MM hh:mm"),
        TodayOrTomorrow: localize.getLocalizedString('_Today_'),
        LoginWithFacebookLink: "https://ezbz.me/example2fB3Uv",
        MeetingApprovalLink: "https://ezbz.me/example2fB3Uv",
        UnsubscribeLink: "https://ezbz.me/example2fB3Uv",
        OnlineBookingLink: "https://ezbz.me/example2fB3Uv",
        Points: "129",
        PrePaymentLink: "https://ezbz.me/example2fB3Uv",
        EmployeeName: localize.getLocalizedString('_EmployeeName_'),
        Location: localize.getLocalizedString('_Location_'),
        ServiceName: localize.getLocalizedString('_ServiceName_'),
        NextMeetingsAndServices: localize.getLocalizedString('_NextMeetingsAndServices_'),
        NextMeetings: localize.getLocalizedString('_NextMeetings_'),
        _Form_IfRequired_: localize.getLocalizedString('_FormIfNeeded_'),
      };

      var dynamicFields = undefined;
      var customFields = undefined;


      this.get = getTemplate;
      this.replaceDynamicFields = replaceDynamicFields;
      this.getDynamicFields = getDynamicFields;
      this.getDummyValueByFieldName = getDummyValueByFieldName;
      this.getFieldByName = getFieldByName

      generateDynamicFields();

      function getTemplate(senderType, preset) {
        var deferred = $q.defer();
        if (requestedTemplates.hasOwnProperty(senderType) && requestedTemplates[senderType].hasOwnProperty(preset)) {
          deferred.resolve(requestedTemplates[senderType][preset]);
        } else {
          // var url = baseUrl + PATH_TO_API + "PromotionCreator/Template?template=" + preset + "&type=" + senderType;
          Repository.Custom("PromotionsRepository").getTemplate(senderType, preset)
            .then(function (data) {
              if (!requestedTemplates.hasOwnProperty(senderType)) {
                requestedTemplates[senderType] = {};
              }

              if (!requestedTemplates[senderType].hasOwnProperty(preset)) {
                requestedTemplates[senderType][preset] = {};
              }

              if (senderType.equalsIgnoreCase('sms')) {
                requestedTemplates[senderType][preset].message = data.Message;
              } else if (senderType.equalsIgnoreCase('mail')) {
                requestedTemplates[senderType][preset].message = data.Content;
                requestedTemplates[senderType][preset].subject = data.Subject;


              } else if (senderType.equalsIgnoreCase('facebook')) {
                requestedTemplates[senderType][preset].message = data.Message;
                requestedTemplates[senderType][preset].images = data.Image ? [
                  {source: data.Image.Url, id: data.Image.Id}
                ] : [];
                requestedTemplates[senderType][preset].TagCustomers = data.TagCustomers;
                requestedTemplates[senderType][preset].PostPerCustomer = data.PostPerCustomer;
                requestedTemplates[senderType][preset].UseCustomerBestRatedImage = data.UseCustomerBestRatedImage;
              }

              deferred.resolve(requestedTemplates[senderType][preset]);
            })
            .catch(function (err) {
              toastr.error(localize.getLocalizedString('_ErrorGettingTemplate_'));
              deferred.reject(err);
            });
        }

        return deferred.promise;
      }

      function replaceDynamicFields(content, isEditMode) {
        if (isEditMode) { // if edit mode we need to replace the custom spans with an actual content.
          var regex = new RegExp(dynamicFieldsPlaceholderOpener + ".+?" + dynamicFieldsPlaceholderCloser);
          content = content.replace(/<span\sclass="replaceable\-content\-field".+?<\/span>/g, function (value) {
            return value.match(regex)[0];
          });
        } else {
          var regex = new RegExp(dynamicFieldsPlaceholderOpener + "[_a-zA-Z0-9]*" + dynamicFieldsPlaceholderCloser, "g");
          content = content.replace(regex, function (value) {
            var fieldName = value.substr(dynamicFieldsPlaceholderOpener.length,
              (value.indexOf(dynamicFieldsPlaceholderCloser) - dynamicFieldsPlaceholderCloser.length));
            var toReturn = "<span class=\"replaceable-content-field\" previous-value=\"" + value + "\">" +
              getDummyValueByFieldName(fieldName) + "</span>";

            return toReturn;
          });
        }

        return content;
      }

      function getDummyValueByFieldName(fieldName) {
        var toReturn = null;
        if (defaultCustomer.hasOwnProperty(fieldName)) {
          toReturn = defaultCustomer[fieldName];
        } else {
          toReturn = defaultCustomValues[fieldName];
        }

        return toReturn;
      }

      function getDynamicFields(preset) {
        if (!dynamicFields) {
          generateDynamicFields();
        }

        if (angular.isString(preset)) {
          preset = promotionsPresetResolver.getPreset(preset);
        }

        var toReturn = [];
        for (var i in dynamicFields) {
          toReturn.push(dynamicFields[i]);
        }

        if (preset.CustomFields) {
          angular.forEach(preset.CustomFields, function (field) {
            toReturn.push(customFields[field]);
          });
        }

        return toReturn;
      }

      function getFieldByName(fieldName) {
        if (!dynamicFields) {
          generateDynamicFields();
        }

        return dynamicFields[fieldName] || customFields[fieldName];
      }

      function generateDynamicFields() {
        dynamicFields = {};
        dynamicFields._FirstName_ = {
          name: localize.getLocalizedString("_FirstName_"),
          fieldName: 'FirstName',
          value: dynamicFieldsPlaceholderOpener + "FirstName" + dynamicFieldsPlaceholderCloser
        };
        dynamicFields._LastName_ = {
          name: localize.getLocalizedString("_LastName_"),
          fieldName: 'LastName',
          value: dynamicFieldsPlaceholderOpener + "LastName" + dynamicFieldsPlaceholderCloser
        };

        dynamicFields.LoginWithFacebookLink = {
          name: localize.getLocalizedString("_LoginWithFacebookLink_"),
          fieldName: 'LoginWithFacebookLink',
          value: dynamicFieldsPlaceholderOpener + "LoginWithFacebookLink" + dynamicFieldsPlaceholderCloser
        };

        dynamicFields.UnsubscribeLink = {
          name: localize.getLocalizedString("_UnsubscribeLink_"),
          fieldName: 'UnsubscribeLink',
          value: dynamicFieldsPlaceholderOpener + "UnsubscribeLink" + dynamicFieldsPlaceholderCloser
        };

        dynamicFields.OnlineBookingLink = {
          name: localize.getLocalizedString("_OnlineBookingLink_"),
          fieldName: 'OnlineBookingLink',
          value: dynamicFieldsPlaceholderOpener + "OnlineBookingLink" + dynamicFieldsPlaceholderCloser
        };

        dynamicFields.Points = {
          name: localize.getLocalizedString("_Points_"),
          fieldName: 'Points',
          value: dynamicFieldsPlaceholderOpener + "Points" + dynamicFieldsPlaceholderCloser
        };

        dynamicFields.FormLink = {
          type: 'combo',
          values: [{name: localize.getLocalizedString('_PickForm_'), value: null}, ...formDynamicValues],
          name: localize.getLocalizedString("_FormLink_"),
          fieldName: 'FormLink',
        };

        let adaptDefaultValue = (dynamicValues) => {
          dynamicValues.slice(1).forEach(({
                                            name,
                                            value
                                          }) => defaultCustomValues[value.replace(dynamicFieldsPlaceholderOpener, '').replace(dynamicFieldsPlaceholderCloser, '')] = name)
        }

        Repository.Custom('Forms').getForms().then((forms) => {
          forms.forEach(({name, _id}) => formDynamicValues.push({
            name,
            value: `${dynamicFieldsPlaceholderOpener}_Form_${_id}_${dynamicFieldsPlaceholderCloser}`
          }));

          dynamicFields.FormLink.values = dynamicFields.FormLink.values.concat(formDynamicValues)
          adaptDefaultValue(dynamicFields.FormLink.values)
        }).catch(e => toastr.error(e.message));

        pci.supportsWebPayments().then((isSupported) => {
          if (isSupported) {
            dynamicFields.PrePaymentLink = {
              type: 'combo',
              values: [{name: localize.getLocalizedString('_PrePayment_'), value: null}, ...prePaymentDynamicValues],
              name: localize.getLocalizedString("_PrePaymentLink_"),
              fieldName: 'PrePaymentLink',
            };

            Repository.Entity("PrePaymentTemplate").query().get()
              .then(function (res) {
                if (res.results) {
                  res.results.forEach(function (template) {
                    prePaymentDynamicValues.push({
                      name: template.Name,
                      value: dynamicFieldsPlaceholderOpener + 'PrePayment_' + template.PrePaymentTemplateId + "ID" + dynamicFieldsPlaceholderCloser
                    });
                  });
                  dynamicFields.PrePaymentLink.values = dynamicFields.PrePaymentLink.values.concat(prePaymentDynamicValues)
                  adaptDefaultValue(dynamicFields.PrePaymentLink.values)
                }
              }).catch(e => toastr.error(e.message));
          }
        });

        customFields = {};
        customFields.MeetingDate = {
          name: localize.getLocalizedString("_MeetingDate_"),
          fieldName: 'MeetingDate',
          value: dynamicFieldsPlaceholderOpener + "MeetingDate" + dynamicFieldsPlaceholderCloser
        };
        customFields.MeetingTime = {
          name: localize.getLocalizedString("_MeetingTime_"),
          fieldName: 'MeetingTime',
          value: dynamicFieldsPlaceholderOpener + "MeetingTime" + dynamicFieldsPlaceholderCloser
        };
        customFields.StartTime = {
          name: localize.getLocalizedString("_MeetingStartTime_"),
          fieldName: 'MeetingStartTime',
          value: dynamicFieldsPlaceholderOpener + "StartTime" + dynamicFieldsPlaceholderCloser
        };
        customFields.NextMeetings = {
          name: localize.getLocalizedString("_NextMeetings_"),
          fieldName: 'NextMeetings',
          value: dynamicFieldsPlaceholderOpener + "NextMeetings" + dynamicFieldsPlaceholderCloser
        };
        customFields.NextMeetingsAndServices = {
          name: localize.getLocalizedString("_NextMeetingsAndServices_"),
          fieldName: 'NextMeetingsAndServices',
          value: dynamicFieldsPlaceholderOpener + "NextMeetingsAndServices" + dynamicFieldsPlaceholderCloser
        };
        customFields.TodayOrTomorrow = {
          name: localize.getLocalizedString("_TodayOrTomorrow_"),
          fieldName: 'TodayOrTomorrow',
          value: dynamicFieldsPlaceholderOpener + "TodayOrTomorrow" + dynamicFieldsPlaceholderCloser
        };
        customFields.EmployeeName = {
          name: localize.getLocalizedString("_EmployeeName_"),
          fieldName: 'EmployeeName',
          value: dynamicFieldsPlaceholderOpener + "EmployeeName" + dynamicFieldsPlaceholderCloser
        };
        customFields.Location = {
          name: localize.getLocalizedString("_Location_"),
          fieldName: 'Location',
          value: dynamicFieldsPlaceholderOpener + "Location" + dynamicFieldsPlaceholderCloser
        };
        customFields.ServiceName = {
          name: localize.getLocalizedString("_ServiceName_"),
          fieldName: 'ServiceName',
          value: dynamicFieldsPlaceholderOpener + "ServiceName" + dynamicFieldsPlaceholderCloser
        };
        customFields.MeetingApprovalLink = {
          name: localize.getLocalizedString("_MeetingApprovalLink_"),
          fieldName: 'MeetingApprovalLink',
          value: dynamicFieldsPlaceholderOpener + "MeetingApprovalLink" + dynamicFieldsPlaceholderCloser
        };
        customFields.FormIfNeeded = {
          name: localize.getLocalizedString("_FormIfNeeded_"),
          fieldName: 'FormIfNeeded',
          value: dynamicFieldsPlaceholderOpener + "_Form_IfRequired_" + dynamicFieldsPlaceholderCloser
        };

        Object.keys(customFields).forEach((key) => {
          const fieldName = customFields[key].fieldName;
          if (EXAMPLES.hasOwnProperty(key)) {
            customFields[key].example = EXAMPLES[fieldName];
          }
        });

        Object.keys(dynamicFields).forEach((key) => {
          const fieldName = dynamicFields[key].fieldName;
          if (EXAMPLES.hasOwnProperty(fieldName)) {
            dynamicFields[key].example = EXAMPLES[fieldName];
          }
        });

        addCustomFieldForXTime(5);
        addCustomFieldForXTime(10);
        addCustomFieldForXTime(15);
        addCustomFieldForXTime(30);
        addCustomFieldForXTime(60);


        function addCustomFieldForXTime(minutes) {
          var field = 'In' + minutes + 'Minutes';
          customFields[field] = {
            name: localize.getLocalizedString("_InXMinutes_", minutes),
            calculated: function () {
              return localize.getLocalizedString('_AtHour_', moment().add(minutes, 'm').format('LT'));
            },
            value: dynamicFieldsPlaceholderOpener + field + dynamicFieldsPlaceholderCloser
          };
        }
      }
    }
  }]);
