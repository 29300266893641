import './custom.reports.actions.directive.less'

angular.module('easybizy.common.custom.reports.actions')
  .directive('customReportsActions',
    function () {
      return {
        restrict: 'E',
        scope: {
          show: '=',
          actions: '='
        },
        replace: true,
        template: require('./custom.reports.actions.directive.tpl.html'),
      };
    });

