import './add.employee.modal.less'
import { DATE_DISPLAY_FORMAT } from "../../constants";

angular.module('easybizy.easybizyModalViewsModel').controller('AddEmployeeModalController',
  function ($scope, $modalInstance, saveAction, $timeout, $http, localize, Repository, parameters) {
    $scope.profileFields = [
      {
        fieldName: 'FirstName', icon: "icon icon-clients-ol", placeholder: localize.getLocalizedString("_FirstName_"),
        validation: "{'required': true, 'max-length': 20}"
      },
      {
        fieldName: 'LastName', icon: "icon icon-client", placeholder: localize.getLocalizedString("_LastName_"),
        validation: "{'required': true, 'max-length': 20}"
      },
      {
        fieldName: 'PhoneNumber', icon: "icon icon-sms", placeholder: localize.getLocalizedString("_Phone_"),
        validation: "{'max-length': 10, 'min-length': 9, 'digits': true}"
      },
      {
        fieldName: 'EmailAddress',
        icon: "icon icon-email-mail-streamline",
        placeholder: localize.getLocalizedString("_Email_"),
        validation: "{'email': true}"
      },
      {
        fieldName: 'DateOfBirth', icon: "icon icon-birthday", placeholder: localize.getLocalizedString("_DateOfBirth_"),
        validation: "{'date': true, 'birthday': true}",
        type: 'date'
      },
      {
        fieldName: 'VisibleOnCalendar',
        icon: "icon icon-calendar",
        placeholder: localize.getLocalizedString("_VisibleOnCalendar_"),
        type: 'checkbox'
      }
    ];

    $scope.dialogTitle = "+ " + localize.getLocalizedString("_AddEmployee_");

    $scope.profileFieldsValidation = {};
    $scope.profileFieldsToValidate = {}; // just initialize fields to validate.
    $scope.profileValidateActionWrapper = {}; // this is an object to wrap an action, so the form validator can
    $scope.profile = {};
    $scope.profile.VisibleOnCalendar = true;

    if (angular.isDefined(parameters.employee)) {
      useEmployeeDetails(parameters.employee);
    } else if (angular.isDefined(parameters.employeeResolver)) {
      parameters.employeeResolver()
        .then(function (res) {
          useEmployeeDetails(res);
        })
        .catch(function (err) {
          localize.getLocalizedString("_FailedLoadingEmployee_", err.Message);
        });
    }

    $scope.uploadImageWrapper = {};
    $scope.uploadImageWrapper.onDoneUploading = function (image) {
      $scope.profile.ImageId = image.id;
    };

    $scope.save = function () {
      var validationFields = $scope.profileValidateActionWrapper.validate();
      if (validationFields.length != 0) { // is valid form?
        var wrongFieldName = $scope.profileFields.filter(x => validationFields[0].hasOwnProperty(x.fieldName))[0];
        var localizedError = localize.getLocalizedString("_FieldIsIncorrect_", wrongFieldName.placeholder, validationFields[0][wrongFieldName.fieldName]);
        toastr.error(localizedError);
        return;
      }

      $scope.isSaving = true;

      var newOrUpdatedEmployee = {
        FirstName: $scope.profile.FirstName,
        LastName: $scope.profile.LastName,
        PhoneNumber: $scope.profile.PhoneNumber,
        EmailAddress: $scope.profile.EmailAddress,
        DateOfBirth: $scope.profile.DateOfBirth,
        ImageId: $scope.profile.ImageId,
        VisibleOnCalendar: $scope.profile.VisibleOnCalendar
      };


      var existingEmployeeParam = parameters && $scope.originalEmployee ? $scope.originalEmployee : undefined;
      if (existingEmployeeParam) {
        newOrUpdatedEmployee.EmployeeId = existingEmployeeParam.EmployeeId;
      }

      saveAction(newOrUpdatedEmployee, existingEmployeeParam)
        .then(function (updatedEmployee) {
          toastr.success(localize.getLocalizedString(!angular.isDefined(existingEmployeeParam)
            ? "_EmployeeCreated_"
            : "_EmployeeUpdated_"));

          $modalInstance.close();
          $scope.isSaving = false;
        })
        .catch(function (err) {
          toastr.error(localize.getLocalizedString("_ErrorSavingEmployee_", err.message));
          $scope.isSaving = false;
        });
    };

    function useEmployeeDetails(employee) {
      $scope.dialogTitle = localize.getLocalizedString("_EditEmployee_");
      $scope.profile.FirstName = employee.FirstName;
      $scope.profile.LastName = employee.LastName;
      $scope.profile.EmailAddress = employee.EmailAddress;
      $scope.profile.PhoneNumber = employee.PhoneNumber;
      $scope.profile.VisibleOnCalendar = employee.VisibleOnCalendar;
      $scope.profile.DateOfBirth = employee.DateOfBirth != null ? moment(employee.DateOfBirth).format(DATE_DISPLAY_FORMAT) : null;
      if (angular.isDefined(employee.Image)) {
        $scope.profile.image = {};
        $scope.profile.ImageId = employee.ImageId;
        $scope.profile.image.src = baseUrl + employee.Image.FilePath;
      }

      $scope.originalEmployee = employee;
    }

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  });
